'use strict';

angular.module('metabuyer.services.orders', ['ngResource'])
  .factory('Orders', function ($resource, pathConstants) {
    var Orders = $resource(
      pathConstants.apiUrls.po.single,
      {
        id: '@id',
        status: '@status'
      },
      {
        'search': {
          method: 'GET',
          url: pathConstants.apiUrls.po.search
        },

        'resendPO': {
          method: 'PUT',
          params:{
            id: "@id"
          },
          url: pathConstants.apiUrls.po.resend
        },
        'setPOStatus': {
          method: 'PUT',
          params:{
            id: "@id"
          },
          url: pathConstants.apiUrls.po.single
        },
        'massUpdatePOStatus' : {
          method: 'POST',
          params:{

          },
          url: pathConstants.apiUrls.po.massUpdatePOStatus
        },
        'closeLineItem': {
          method: 'PUT',
          params:{
            po_id: "@po_id",
            item_id: "@item_id"
          },
          url: pathConstants.apiUrls.po.closeLineItem
        },
        'getPOGRNs': {
          method: 'GET',
          params:{
            id: "@id"
          },
          url: pathConstants.apiUrls.po.getPOGRNs
        },
        'reverseItems': {
          method: 'POST',
          params: {
            po_id: '@po_id'
          },
          url: pathConstants.apiUrls.po.reverse
        },
        'getErpStatus': {
          method: 'GET',
          params : {
            po_number: '@po_number'
          },
          ignoreLoadingBar: true,
          url: pathConstants.apiUrls.po.getErpStatus
        },
        'retryOutboundPo': {
          method: 'POST',
          params: {
            po_id: '@po_id'
          },
          url: pathConstants.apiUrls.po.retryOutboundPo
        },
        'pushPoToBifrost': {
          method: 'POST',
          params: {
            po_id: '@po_id'
          },
          url: pathConstants.apiUrls.po.pushPoToBifrost
        },
        'resyncDelivery': {
          method: 'POST',
          params: {
            po_number: '@po_number'
          },
          url: pathConstants.apiUrls.po.requestResyncDelivery
        },
        'updateItemDetails' : {
          method: 'PUT',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.po.updateItemDetails
        },
        'resendPendingPoEmail': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.po.resendPendingPoEmail
        }
      }
    );
    return Orders;
  })
  .factory('generatePO',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.po.single,
      {
        id: '@id'
      },
      {
        'post': { method: 'POST' }
      }
    );
  })
  .factory('getPOPDFHash',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.po.hash,
      {
        id: '@id'
      },
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('movePdfToInvalid',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.po.movePdfToInvalid,
        {
          id: '@id'
        },
        {
          'put': { method: 'PUT' }
        }
      );
    })
  .factory('getManyPOPDFHash',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.po.hash,
        {},
        {
          'get': { method: 'GET' }
        }
      );
    })
  .factory('listPOs',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.po.list,
      {
      },
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('deliverGoods',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.po.deliverGoods,
        {
        },
        {
          'post': { method: 'POST' }
        }
      );
    })
  .factory('deliverGoodsDetail',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.po.deliverGoodsDetails,
        {
          id: '@id'
        },
        {
          'get': { method: 'GET' }
        }
      );
    })
  .factory('getDeliveryNotePdfHash',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.po.deliveryNoteHash,
        {
          id: '@id'
        },
        {
          'get': { method: 'GET' }
        }
      );
    })
  .factory('pendingSupplierPo',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.po.pendingActionSupplier,
        {
        },
        {
          'get': { method: 'GET' }
        }
      );
    })
  .factory('approvalPurchaseOrder',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.approvals.status,
        {
          approval_id: '@approval_id',
          status: '@status'
        },
        {
          'post': {method: 'POST'}
        }
      );
  });
