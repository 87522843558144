'use strict';

/**
 * @name budgetActions Directive
 * @desc Directive for displaying budget actions
 *
 * @author Justin Cheong <justin.cty90@gmail.com>
 * @copyright 2017 Metacloud Sdn. Bhd.
 */
angular
  .module('metabuyer')
  .directive('budgetActions', function () {
    return {
      restrict: 'E',
      templateUrl: 'components/budget/actions/actions.html',
      scope: {
        data: '=',
        assignments: '=',
        headerDetailsError: '=',
        budgetAssignmentDetailsError: '=',
        budgetItemDetailsError: '=',
        isCreator: '&',
        approvalFlowData: '='
      },
      controller: function (
        $scope, $state, toastr, globalFunc, budgets, $uibModal, $rootScope, purchaseRequisitionsServices, $stateParams,
        approvalPreview, HighlightId, budgetFunctions
      ) {
        $scope.currentUser = $rootScope.currentUser || {_id: null};
        $scope.rejectBudgetExtraParams = {};
        $scope.module = 'budget';
        $scope.isLoading = false;

        $scope.back = back;
        $scope.deleteBudget = deleteBudget;
        $scope.deleteBudgetDraft = deleteBudgetDraft;
        $scope.submitBudget = submitBudget;
        $scope.saveBudget = saveBudget;
        $scope.confirmSaveBudget = confirmSaveBudget;
        $scope.updateBudgetTitle = updateBudgetTitle;
        $scope.reactivateBudget = reactivateBudget;
        $scope.deactivateBudget = deactivateBudget;
        $scope.createRevisionDraft = createRevisionDraft;
        $scope.showVersionList = showVersionList;
        $scope.canReject = canReject;
        $scope.canHold = canHold;
        $scope.canUnHold = canUnHold;
        $scope.canWithdraw = canWithdraw;
        $scope.canDeactivate = canDeactivate;
        $scope.canReactivate = canReactivate;
        $scope.canApprove = canApprove;
        $scope.canDelete = canDelete;
        $scope.canDeleteDraft = canDeleteDraft;
        $scope.canResubmit = canResubmit;
        $scope.approvalAction = approvalAction;
        $scope.refreshBudgetDetails = refreshBudgetDetails;
        $scope.previewBudgetApproval = previewBudgetApproval;
        $scope.canRevise = canRevise;

        function canRevise() {
          var statusList = [
            "approved",
            "expired"
          ];

          var checkBudgetStatus = (statusList.indexOf($scope.data.status.toLowerCase()) > -1);
          var isCreator = ($scope.data.created_by._id === $scope.currentUser._id);

          return (checkBudgetStatus && isCreator);
        }

        // navigate to previous page
        function back() {
          if (!!$scope.data && $scope.data._id) {
            HighlightId.setId($scope.data._id);
          }

          window.history.back();
        }

        // #region Budget action functions
        /**
         * Delete budget by id
         */
        function confirmDeleteBudget() {
          budgets.delete(
            {
              id: $scope.data._id
            },
            function () {
              toastr.success('Budget draft deleted.');
              HighlightId.setId($scope.data._id);
              $state.go('main.user.budget.manage', {status: 'deleted'});
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
            }
          );
        }

        /**
         * Submit budget, moves to pending status
         */
        function submitBudget() {
          $scope.isLoading = true;
          if (!validateBeforeSubmit($scope.data)) {
            $scope.isLoading = false;
            return;
          }

          var submitData = _.cloneDeep($scope.data);

          //Assign selected start date
          submitData['start_date'] = !!submitData['start_date'] ?
            globalFunc.convertDateToTimestamp(submitData['start_date']) : null;

          //Assign selected end date
          submitData['end_date'] = !!submitData['end_date'] ?
            globalFunc.convertDateToTimestamp(submitData['end_date']) : null;

          //Convert dates in the assignments before submit
          _.forEach(submitData['assignments'], function (assignment) {
            assignment['start_date'] = !!assignment['start_date'] ?
              globalFunc.convertDateToTimestamp(assignment['start_date']) : null;
            assignment['end_date'] = !!assignment['end_date'] ?
              globalFunc.convertDateToTimestamp(assignment['end_date']) : null;

            var tempArray = [];
            if (!!assignment.cost_center && !!assignment.cost_center.code) {
              tempArray.push(assignment.cost_center);
              assignment.cost_center = tempArray;
            }

          });

          budgets.post(
            {
              id: $scope.data._id,
              action: 'submit'
            },
            {
              data: submitData
            },
            function (resource) {
              if (!!resource.content && !!resource.content.data) {
                $scope.initialData = _.cloneDeep($scope.data);
              }
              toastr.success('Budget submitted.');
              HighlightId.setId($scope.data._id);
              $state.go('main.user.budget.manage', {status: 'pending'});
            },
            function (error) {
              $scope.isLoading = false;
              globalFunc.objectErrorMessage(error);
            }
          );
        }

        /**
         * Prepare budget data
         *
         * @param {array} submitData Budget data
         */
        function prepareBudgetBeforeSave(submitData) {
          submitData['descr'] = budgetFunctions.getBudgetTitle();
          //Assign selected start date
          submitData['start_date'] = !!submitData['start_date'] ?
            globalFunc.convertDateToTimestamp($scope.data.start_date) : null;

          //Assign selected end date
          submitData['end_date'] = !!submitData['end_date'] ?
            globalFunc.convertDateToTimestamp($scope.data.end_date) : null;

          //Convert dates in the assignments before submit
          _.forEach(submitData['assignments'], function (assignment) {
            assignment['start_date'] = !!assignment['start_date'] ?
              globalFunc.convertDateToTimestamp(assignment['start_date']) : null;
            assignment['end_date'] = !!assignment['end_date'] ?
              globalFunc.convertDateToTimestamp(assignment['end_date']) : null;

            var tempArray = [];
            if (!!assignment.cost_center && !!assignment.cost_center.code) {
              tempArray.push(assignment.cost_center);
              assignment.cost_center = tempArray;
            }
          });
        }

        function confirmSaveBudget(status, isApprovalFlowPreview) {
          swal({
            title: 'Confirm save budget?',
            text: 'This will save the budget.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Confirm',
            closeOnConfirm: true
          }, function (isConfirm) {
            if (isConfirm) {
              saveBudget(status, isApprovalFlowPreview);
            }
          });
        }

        /**
         * Save budget, passes budget status to be updated
         *
         * @param {string}  status                Budget status
         * @param {boolean} isApprovalFlowPreview Flag indicate if is for approval flow preview usage
         * @returns {boolean}
         */
        function saveBudget(status, isApprovalFlowPreview) {
          $scope.data.status = status;

          var submitData = _.clone($scope.data);
          prepareBudgetBeforeSave(submitData);

          budgets.put(
            {
              id: $scope.data._id
            },
            {
              data: submitData
            },
            function (resource) {
              if (!!resource.content && !!resource.content.data) {
                $scope.data = resource.content.data;
                $scope.data.start_date = !!$scope.data.start_date ? new Date($scope.data.start_date) : null;
                $scope.data.end_date = !!$scope.data.end_date ? new Date($scope.data.end_date) : null;
                $scope.initialData = _.cloneDeep($scope.data);
              }
              if (!isApprovalFlowPreview) {
                toastr.success('Budget updated.');
                $state.reload();
              } else {
                previewApproval();
              }
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
            }
          );
        }

        function updateBudgetTitle(budgetTitle) {
          budgetFunctions.setBudgetTitle(budgetTitle);
        }

        function previewApproval() {
          approvalPreview.get({
            company_code: $scope.data.company.code,
            context_type: 'Metabuyer\\Budget\\Models\\Budget',
            context_id: $scope.data._id
          }, function (resource) {
            $scope.approvalFlowData = resource.content.data;
          }, function (error) {
            $scope.approvalFlowData = [];
            globalFunc.objectErrorMessage(error);
          });
        }

        /**
         * Update to reactivate the budget
         */
        function reactivateBudget() {
          swal({
            title: 'Confirm reactivate budget?',
            text: 'This action will move the budget to pending approval process.\r\n' +
                  'Note: If the budget has expired, it will be moved to the expired budget list instead.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Confirm',
            closeOnConfirm: true
          }, function (isConfirm) {
            if (isConfirm) {
              submitBudget();
            }
          });
        }

        /**
         * Update to deactivate the budget
         */
        function deactivateBudget() {
          swal({
            title: 'Confirm deactivate budget?',
            text: 'This action will disallow raising future purchase requisitions with this budget.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Confirm',
            closeOnConfirm: true
          }, function (isConfirm) {
            if (isConfirm) {
              saveBudget('inactive', false);
            }
          });
        }

        /**
         * To confirm if to delete the budget
         */
        function deleteBudget() {
          swal({
            title: 'Confirm delete budget?',
            text: 'This action will remove the budget from draft.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Confirm',
            closeOnConfirm: true
          }, function (isConfirm) {
            if (isConfirm) {
              confirmDeleteBudget();
            }
          });
        }

        /**
         * To confirm if to delete the budget, this is special for revision draft
         */
        function deleteBudgetDraft() {
          swal({
            title: 'Confirm delete budget draft?',
            text: 'This action will reactivate existing deactivated budget.\r\n' +
                  'Note: If the budget has expired, it will be moved to the expired budget list instead.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Confirm',
            closeOnConfirm: true
          }, function (isConfirm) {
            if (isConfirm) {
              confirmDeleteBudget();
            }
          });
        }

        /**
         * Creates a draft revision of the budget
         */
        function confirmRevisionDraft() {
          budgets.post(
            {},
            {
              type: 'revision',
              parent_id: $scope.data._id
            },
            function (resource) {
              if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data._id) {
                toastr.success('Budget revision created.');
                $state.go('main.user.budget.details', {id: resource.content.data._id});
              }
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
            }
          );
        }

        /**
         * To confirm user if creating a revision of the budget
         */
        function createRevisionDraft() {
          swal({
            title: 'Confirm revise budget?',
            text: 'This action will deactivate existing active budget.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Confirm',
            closeOnConfirm: true
          }, function (isConfirm) {
            if (isConfirm) {
              confirmRevisionDraft();
            }
          });
        }

        /**
         * Display budget versions list in modal\
         */
        function showVersionList() {
          $uibModal.open({
            templateUrl: 'components/budget/versions/budgetVersions.html',
            backdrop: 'static',
            keyboard: false,
            scope: $scope,
            resolve: {
              budgetVersions: function ($q, budgets, globalFunc) {
                var deferred = $q.defer();
                  budgets.get(
                    {
                      id: $scope.data._id,
                      action: 'revisions'
                    },
                    function (resource) {
                      if (!!resource.content && !!resource.content.data) {
                        deferred.resolve(resource.content.data);
                      }
                    },
                    function (error) {
                      globalFunc.objectErrorMessage(error);
                    }
                  );
                return deferred.promise;
              },
              currentStatus: function () {
                return $scope.data.status;
              }
            },
            controller: 'budgetVersionsCtrl'
          });
        }

        // #endregion

        /**
         * Validation before try to submit
         */
        function validateBeforeSubmit(data) {
          var validData = true;

          if(!data.descr){
            toastr.error('Budget title cannot be empty.');
            validData =  false;
          }

          if (!data.owner || (!data.expense_type && !data.assignments) || !data.amount ||
            !data.start_date || !data.end_date || (!!data.block_amount && !data.block_amount_reason)) {
            $scope.headerDetailsError = true;
            toastr.error('Please complete all the required fields.');
            validData =  false;
          }

          // Once currency options is available, move this to group checking above
          if (!data.currency) {
            toastr.error('No preferred currency provided.');
            validData = false;
          }

          if (!!$scope.dataItemBreakdownSwitch && !data.cost_center) {
            toastr.error('Cannot add item breakdowns while cost center is empty.');
            validData = false;
          }

          if (data.start_date > data.end_date) {
            toastr.error('Start date must be before end date.');
            validData = false;
          }

          if (!!data.assignments.length && !validateBudgetAssignment(data)) {
            validData = false;
          }

          var assignmentAvailableAmount = budgetFunctions.subtract(data.amount, data.block_amount);
          if (!!data.item_breakdowns.length &&
            !validateItemBreakdowns(data.item_breakdowns, assignmentAvailableAmount)
          ) {
            validData = false;
          }

          if (!!data.assignments) {
            if (!!data.assignable_balance) {
              if (data.assignable_balance < 0) {
                toastr.error('Available Assignable Balance should not be less than zero.');
                validData = false;
              }
            } else {
              var total_assignment_amount = 0;
              _.forEach(data.assignments, function (assignment) {
                total_assignment_amount += assignment.amount;
              });

              var assignable_balance = data.amount - data.block_amount - total_assignment_amount;

              if (assignable_balance < 0) {
                toastr.error('Available Assignable Balance should not be less than zero.');
                validData = false;
              }
              else if (data.amount - data.block_amount <= 0) {
                toastr.error('Blocked Amount should not be equal or more than Budget Amount.');
                 validData = false;
              }

            }
          }
          return validData;
        }

        function validateBudgetAssignment(budget) {
          var validAssignmentData = true;
          var totalAssignmentAmount = 0;
          var totalAssignmentBlockAmount = 0;

          _.forEach(budget.assignments, function (assignment) {

            totalAssignmentAmount = budgetFunctions.add(assignment.amount, totalAssignmentAmount);
            totalAssignmentBlockAmount = budgetFunctions.add(assignment.block_amount, totalAssignmentBlockAmount);

            if (!assignment.descr || !assignment.cost_center || !assignment.expense_type || !assignment.amount ||
              (!!assignment.block_amount && !assignment.block_amount_reason) || !assignment.start_date ||
              !assignment.end_date || !assignment.apportion_type
            ) {
              toastr.error('Please complete all the required fields for budget assignments.');
              validAssignmentData = false;
            }

            if (!!assignment.start_date && !!budget.start_date && assignment.start_date < budget.start_date) {
              toastr.error('Budget assignment start date cannot be prior to budget master start date.');
              validAssignmentData = false;
            }

            if (!!assignment.end_date && !!budget.end_date && assignment.end_date > budget.end_date) {
              toastr.error('Budget assignment end date cannot be after to budget master end date.');
              validAssignmentData = false;
            }

            if (assignment.amount <= assignment.block_amount){
              toastr.error('Blocked Amount should not be equal or more than Budget Amount.');
              validAssignmentData = false;
            }

            var assignmentAvailableAmount = budgetFunctions.subtract(assignment.amount, assignment.block_amount);

            if (!!assignment.item_breakdowns.length &&
              !validateItemBreakdowns(assignment.item_breakdowns, assignmentAvailableAmount)
            ) {
              validAssignmentData = false;
            }
          });

          var budgetActualAmount = budgetFunctions.subtract(budget.amount, budget.block_amount);
          var assignmentActualAmount = budgetFunctions.subtract(totalAssignmentAmount, totalAssignmentBlockAmount);

          if (budgetActualAmount < assignmentActualAmount) {
            toastr.error('Total budget assignment amount cannot be more than budget master amount.');
            validAssignmentData = false;
          }


          if (!validAssignmentData) {
            $scope.budgetAssignmentDetailsError = true;
          }

          return validAssignmentData;
        }

        /**
         * To validate item
         *
         * @param {array}  items           Item data
         * @param {number} inheritedAmount Inherited amounts
         */
        function validateItemBreakdowns(items, inheritedAmount) {
          var validItemData = true;
          var totalItemAmount = 0;

          _.forEach(items, function (item) {
            if ((item.used_amount + item.committed_amount) > item.allocated_amount) {
              toastr.error('Item breakdown "' + item.descr + '" allocated amount cannot be lower than used and committed amount.');
              validItemData = false;
            }

            totalItemAmount = budgetFunctions.add(item.allocated_amount, totalItemAmount);
            if (!item.descr || !item.commodity_code_descr || !item.apportion_type || !item.allocated_amount ||
              (item.UOMIsFraction === 0 && (item.quantity % 1 !== 0))
            ) {
              $scope.budgetItemDetailsError = true;
              toastr.error('Please complete all the required fields with valid input for item breakdowns.');
              validItemData = false;
            }
          });

          if (inheritedAmount < totalItemAmount) {
            $scope.budgetItemDetailsError = true;
            toastr.error('Total item amount cannot be more than inherited amount.');
            return validItemData = false;
          }

          return validItemData;
        }

        /**
         * Check if reject action can be done
         */
        function canReject() {
          var checkBudgetStatus = ($scope.data.status.toLowerCase() === 'pending');
          var isApprover = !!$scope.data.waiting_on ?
            !!(globalFunc.findInArray($scope.data.waiting_on, '_id', $scope.currentUser._id)) : false;

          return (checkBudgetStatus && isApprover);
        }

        /**
         * Check if put on-hold action can be done
         */
        function canHold() {
          var checkBudgetStatus = ($scope.data.status.toLowerCase() === 'pending');
          var isApprover = !!$scope.data.waiting_on ?
            !!(globalFunc.findInArray($scope.data.waiting_on, '_id', $scope.currentUser._id)) : false;

          return (checkBudgetStatus && isApprover);
        }

        /**
         * Check if release on-hold budget action can be done
         */
        function canUnHold() {
          var checkBudgetStatus = ($scope.data.status.toLowerCase() === 'on_hold');
          var isApprover = !!$scope.data.waiting_on ?
            !!(globalFunc.findInArray($scope.data.waiting_on, '_id', $scope.currentUser._id)) : false;

          return (checkBudgetStatus && isApprover);
        }

        /**
         * Check if withdraw budget action can be done
         */
        function canWithdraw() {
          var checkBudgetStatus = ($scope.data.status.toLowerCase() === 'pending');
          var isCreator = ($scope.data.created_by._id === $scope.currentUser._id);

          return (checkBudgetStatus && isCreator);
        }

        /**
         * Check if deactivate budget action can be done
         */
        function canDeactivate() {
          var checkBudgetStatus = ($scope.data.status.toLowerCase() === 'approved');
          var isCreator = ($scope.data.created_by._id === $scope.currentUser._id);

          return (checkBudgetStatus && isCreator);
        }

        /**
         * Check if reactivate budget action can be done
         */
        function canReactivate() {
          var checkBudgetStatus = ($scope.data.status.toLowerCase() === 'inactive');
          var isCreator = ($scope.data.created_by._id === $scope.currentUser._id);
          var hasRevisingDraft = !!$scope.data.revisingBudgetId;

          return (checkBudgetStatus && isCreator && !hasRevisingDraft);
        }

        /**
         * Check if approve budget action can be done
         */
        function canApprove() {
          var checkBudgetStatus = ($scope.data.status.toLowerCase() === 'pending');
          var isApprover = !!$scope.data.waiting_on ?
            !!(globalFunc.findInArray($scope.data.waiting_on, '_id', $scope.currentUser._id)) : false;

          return (checkBudgetStatus && isApprover);
        }

        /**
         * Check if delete budget action can be done
         */
        function canDelete() {
          var checkBudgetStatus = ($scope.data.status.toLowerCase() === 'draft');
          var isCreator = ($scope.data.created_by._id === $scope.currentUser._id);
          var parentBudgetExist = !!$scope.data.parent_id;

          return (checkBudgetStatus && isCreator && !parentBudgetExist);
        }

        /**
         * Check if delete budget action can be done, this is special for revision draft
         */
        function canDeleteDraft() {
          var checkBudgetStatus = ($scope.data.status.toLowerCase() === 'draft');
          var isCreator = ($scope.data.created_by._id === $scope.currentUser._id);
          var parentBudgetExist = !!$scope.data.parent_id;

          return (checkBudgetStatus && isCreator && parentBudgetExist);
        }

        /**
         * Check if resubmit budget action can be done
         */
        function canResubmit() {
          var checkBudgetStatus = ($scope.data.status.toLowerCase() === 'withdraw' ||
            $scope.data.status.toLowerCase() === 'rejected');
          var isCreator = ($scope.data.created_by._id === $scope.currentUser._id);

          return (checkBudgetStatus && isCreator);
        }

        /**
         * Function to run on action taken
         *
         * @param {string} action       Action to be taken to the budget
         * @param {string} message      Message to be thrown on success
         * @param {object} extraPayload In some cases the action needs extra payload passed to the backend
         * @returns {*}
         */
        function approvalAction(action, message, extraPayload) {

          return purchaseRequisitionsServices.approvalAction(
            {
              approval_id: $scope.data.approval_id,
              status: action
            },
            extraPayload,
            function () {
              if (!!message) {
                toastr.success(message);
              }
            },
            function(error) {
              globalFunc.objectErrorMessage(error.data);
            }
          ).$promise;
        }

        /**
         * Function to refresh budget page
         */
        function refreshBudgetDetails() {
          $state.transitionTo($state.current, $stateParams, {
            reload: true,
            inherit: false,
            notify: true
          });
        }

        /**
         * Function to load next waiting on task for this user on budget
         */
        function loadNextData() {
          if ($scope.data.status === 'pending' || $scope.data.status.toLowerCase() === 'on_hold') {
            $scope.onHoldExtraParams = prepareOnHoldExtraParams();

            globalFunc.nextAvailableAction('Metabuyer\\Budget\\Models\\Budget', $scope.data._id).then(function (resource) {
              if (!!resource && !!resource.waitingOnObject && !!resource.waitingOnObject.context_id) {
                $scope.nextBudget = resource.waitingOnObject;
                $scope.totalBudgetOnHand = resource.count;
              }
              else
                $scope.nextBudget = {};
            });
          }
        }

        /**
         * Preparing on hold extra params
         * @returns {object}
         */
        function prepareOnHoldExtraParams() {
          var userIDArray = [];
          userIDArray.push($scope.data.created_by._id);

          return {user_ids: userIDArray};
        }

        /**
         * Preview budget approval
         */
        function previewBudgetApproval() {
          // using angular.equals instead of _.isEqual because lodash has problem when compare deeper layer
          if (!angular.equals($scope.initialData, $scope.data)) {
            swal({
              title: 'Confirm save changes?',
              text: 'In order to preview the approval flow, changes made to the budget will be saved.',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#1ab394',
              confirmButtonText: 'Confirm',
              closeOnConfirm: true
            }, function (isConfirm) {
              if (isConfirm) {
                saveBudget('draft', true);
              }
            });
          } else {
            previewApproval();
          }
        }

        $scope.$on('saveDraftBudget', function () {
          previewBudgetApproval();
        });

        function initialize() {
          $scope.initialData = _.cloneDeep($scope.data);
          budgetFunctions.setBudgetTitle($scope.initialData.descr);
          loadNextData();
        }

        initialize();
      }
    };
  });
