'use strict';

function mappingSupplierModalCtrl($scope, $uibModalInstance, data, globalFunc, supplier, $uibModal) {

  $scope.mappedSupplier = data;
  $scope.supplier = supplier;
  $scope.close = close;
  $scope.updateStatus = updateStatus;

  var updateStatusColumn = [
    {id: 'updated_by', label: 'Updated By', unsearchable: true, unsortable: true},
    {id: 'updated_at', label: 'Updated At', unsearchable: true, unsortable: true},
    {id: 'reason', label: 'Reason', unsearchable: true, unsortable: true}
  ];

  function close(){
    $uibModalInstance.close();
  }

  function initialize() {
    $scope.column = updateStatusColumn;
  }


  function updateStatus() {
    var modalInstance = $uibModal.open({
      templateUrl: 'app/bifrostManagement/mappedSupplierModal/updateStatusTemplate.html',
      backdrop: 'static',
      keyboard: false,
      resolve:{
        supplier: function(){
          return $scope.supplier;
        }
      },
      controller: function ($scope, $uibModalInstance, supplier, $http, pathConstants, toastr, $state) {
        $scope.submit = submit;
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        function submit(reason){
          var path = pathConstants.apiUrls.supplier.updateStatus;
          path = path.replace(":id", data.Id);
          $http.put(
            path,
            {
              reason: reason
            }
          ).then(function onSuccess(){
            toastr.success("Added update status");
            $uibModalInstance.close();
            close();
            $state.reload();
          }).catch(function onError (){
            toastr.error("Failed to add update status");
          });
        }
      }
    });
  }

  initialize();
}

mappingSupplierModalCtrl.$inject = ['$scope', '$uibModalInstance', 'data', 'globalFunc', 'supplier', '$uibModal'];

angular.module('metabuyer')
  .controller('mappingSupplierModalCtrl', mappingSupplierModalCtrl);
