'use strict';

angular.module('metabuyer.services.featureToggle', ['ngResource'])
  .factory('FeatureToggleService', function($http, $auth, $q, pathConstants) {
    return {
      getOne: function(featureKey) {
        var path = pathConstants.apiUrls.featureToggle.single;
        path = path.replace(":key", featureKey);
        return $http.get(path);
      },
      getMultiple: function(featureKeys) {
        var path = pathConstants.apiUrls.featureToggle.multiple;

        var query = '';
        featureKeys.forEach(function (featureKey, index) {
          query += (index === 0) ? '?' : '&';
          query += 'keys[]=' + featureKey
        });

        return $http.get(path + query);
      }
    };
  });
