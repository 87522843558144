'use strict';

/**
 * @name ItemGroupCtrl
 */
function ItemGroupCtrl($scope, $uibModal, dataList, $location,  $http, $filter, pathConstants, $stateParams, globalFunc) {
  $scope.getStatus = getStatus;
  $scope.addItemGroup = addItemGroup;
  $scope.showColumn = showColumn;
  $scope.getParsedCodes = getParsedCodes;
  $scope.search = search;
  $scope.searchText = '';
  $scope.openItemDetailsGroup = openItemDetailsGroup;
  $scope.importItemGroup = importItemGroup;
  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.columns = [
    {id: 'code', label: 'Item Group Code'},
    {id: 'name', label: 'Item Group Name'},
    {id: 'item_categories', label: 'Item Categories'},
    {id: 'item_codes', label: 'Item Codes'},
    {id: 'created_at', label: 'Created At'},
    {id: 'creator_info.display_name', label: 'Created By'},
    {id: 'updated_at', label: 'Updated At'},
    {id: 'updater_info.display_name', label: 'Updated By'},
    {id: 'is_active', label: 'Status'}
  ];

  $scope.storageKey = 'master-data-item-group-selected-columns';

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

    function importItemGroup() {
        $uibModal.open({
            templateUrl: 'app/masterDataManagement/Import-Template.html',
            backdrop: 'static',
            keyboard: false,
            resolve: {
                importLink: function () {
                    return pathConstants.apiUrls.imports.now;
                },
                params: function () {
                    return {
                        'class': 'App\\Metabuyer\\ItemGroup\\Models\\ItemGroup'
                    }
                },
                title: function () {
                    return 'Item Group';
                }
            },
            controller: function ($scope, $uibModalInstance, importLink, title, params) {
                $scope.cancel = function () {
                    $uibModalInstance.close();
                };

                $scope.importLink = importLink;
                $scope.title = title;
                $scope.params = params;
            }
        });
    }

  function search(query) {
    $location.search({query: query});
  }

  function getParsedCodes(data) {
    var str = '';
      str += _.map(data, function(element) {
         return element.code + ' ';
      });
      return str;
  }

  function getStatus(isActive) {
    return !!isActive ? 'Active' : 'Inactive';
  }

    /**
     * shared service
     * @param val
     * @param currentList
     */
    function itemCodesListing(val, currentList) {
        if (!!val && val.length > 2) {

            var moduleParam = {
                module: 'item-master'
            };

            var additionalParams = {
                'criteria[1][code]': val,
                'criteria[1][name]': val,
                'criteria[1][$operator]': 'or',
                offset: 20
            };

            return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
                params: additionalParams,
                ignoreLoadingBar: true
            }).then(function (response) {
                if (response.data.content.data.length === 0) {
                    return [];
                } else {
                    return response.data.content.data.map(function (item) {
                        if(!globalFunc.findInArray(currentList, '_id', item._id))
                            return item;
                    });
                }
            });
        }
    }

    /**
     * shared service
     * @param val
     * @param currentList
     */
    function itemCategoriesListing(val, currentList) {
        if (!!val && val.length > 2) {
            var moduleParam = {
                module: 'item-category'
            };
            var additionalParams = {
                'criteria[0][is_active]': true,
                'criteria[1][name]': val,
                'criteria[1][code]': val,
                'criteria[1][$operator]': 'or',
                criteria_operator: 'and',
                offset: 10
            };
            return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
                params: additionalParams,
                ignoreLoadingBar: true
            }).then(function (response) {
                if (response.data.content.data.length === 0) {
                    return [];
                } else {
                    return response.data.content.data.map(function (item) {
                        if(!globalFunc.findInArray(currentList, '_id', item._id))
                            return item;
                    });
                }
            });
        }
    }

    function openItemDetailsGroup(itemGroup) {
        var modalInstance = $uibModal.open({
            templateUrl: 'app/masterDataManagement/itemGroup/details/details.html',
            backdrop: 'static',
            controller: 'itemGroupDetailsCtrl',
            windowClass: 'receive-goods-modal',
            keyboard: false,
            resolve: {
                item: function () {
                    return itemGroup
                },
                itemCodesListing: function () {
                    return itemCodesListing
                },
                itemCategoriesListing: function () {
                    return itemCategoriesListing
                }
            }
        });

        modalInstance.result.then(function (result) {
            var data = result.content.data;
            for (var i = 0; i < $scope.dataList.length; i++) {
                if ($scope.dataList[i]['uuid'] === data.uuid) {
                    $scope.dataList[i] = data;
                }
            }
        });
    }

  function addItemGroup() {
    var modalInstance = $uibModal.open({
      templateUrl: 'app/masterDataManagement/itemGroup/manage/add.html',
      backdrop: 'static',
      controller: 'addItemGroupCtrl',
      keyboard: false,
      scope: $scope
    });

    modalInstance.result.then(function (result) {
      var data = result.content.data;
      $scope.dataList.unshift({
        code: data.code,
        name: data.name,
        item_categories: data.item_categories,
        item_codes: data.item_codes,
        created_at: data.created_at,
        updated_at: data.updated_at,
        creator_info: data.creator_info,
        updater_info: data.updater_info,
        is_active: data.is_active,
        _id: data._id,
        uuid: data.uuid
      });
    });
  }

  function init() {
   
      if (!!$stateParams.query)
          $scope.searchText = $stateParams.query;
      else
          $scope.searchText = '';
  }
  init();
}

ItemGroupCtrl.$inject = ['$scope', '$uibModal', 'dataList', '$location', '$http', '$filter', 'pathConstants', '$stateParams', 'globalFunc'];

angular
  .module('metabuyer')
  .controller('ItemGroupCtrl', ItemGroupCtrl);
