'use strict';

angular.module('metabuyer.services.uoms', ['ngResource'])
  .factory('singleUom',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.uom.single,
      {
        id: '@id'
      },
      {
        'get': { method: 'GET' },
        'post': { method: 'POST' },
        'put': { method: 'PUT' }
      }
    );
  })
  .factory('uoms',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.uom.single,
      {
      },
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('uomList',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.uom.list,
      {
        query: '@query'
      },
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('uomSearch',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.uom.search,
      {
        query: '@query'
      },
      {
        'get': { method: 'GET' }
      }
    );
  });
