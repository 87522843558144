'use strict';
/**
 * @name itemDetailsV2Ctrl
 *
 * @description
 * Controller for item details
 *
 * @copyright Sunway Metacloud &copy; 2016
 */

function itemDetailsV2Ctrl(
  $scope, details, imgPath, $uibModalInstance, itemDetailsInformation, $rootScope, itemId, globalFunc, enableStarring,
  prV2Function, $state
) {

  $scope.imgBasePath    = imgPath;
  $scope.item           = details;
  $scope.image          = { img_url : details.img_url };
  $scope.expenseType    = prV2Function.getExpenseTypeMode();

  $scope.enableStarring = enableStarring;

  $scope.close          = close;
  $scope.getPRState     = getPRState;
  $scope.isShoppingMode = isShoppingMode;

  function close() {
    $uibModalInstance.close();
  }

  function getPRState(){
    return prV2Function.getEditingState();
  }

  function isShoppingMode() {
    return $state.includes('main.user.purchaseRequisitions.details.catalog');
  }

  // dynamically updating the items view
  $scope.$on('updateDetailsDataV2', function(args, data) {
    if (!!data) {
      $scope.item = data;
      if(!!$scope.item && !!$scope.item.img_url) {
        var randomVal = Math.random();
        $scope.image.img_url = $scope.item.img_url + '?decache=' + randomVal;
      } else {
        $scope.image.img_url = undefined;
      }
    }
  });

}
itemDetailsV2Ctrl.$inject = [
  '$scope', 'details', 'imgPath', '$uibModalInstance', 'itemDetailsInformation', '$rootScope', 'itemId', 'globalFunc',
  'enableStarring', 'prV2Function', '$state'
];

angular
  .module('metabuyer')
  .controller('itemDetailsV2Ctrl', itemDetailsV2Ctrl);
