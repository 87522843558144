'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function ($stateProvider) {

    $stateProvider
      .state('main.user.supplier', {
        url: '/suppliers',
        templateUrl: 'app/suppliersManagement/supplier.html',
        controller: 'supplierCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
          },
          sourcingSupplierFeatureToggle: function ($q, $stateParams, FeatureToggleService, toastr) {
            var deferred = $q.defer();

            FeatureToggleService.getOne(['system.enableSourcingSupplier'])
              .then(function (resource) {
                if (!!resource.data) {
                  deferred.resolve(resource.data);
                } else {
                  deferred.resolve({});
                  console.log("Failed to get toggle data.");
                }
              })
              .catch(function (e) {
                deferred.resolve({});
                toastr.error('Something went wrong')
              });

            return deferred.promise;
          },
          prepareTranslation: function ($translatePartialLoader) {
            $translatePartialLoader.addPart('main/lang');
            $translatePartialLoader.addPart('suppliersManagement/lang');
          },
        }
      })

      .state('main.user.supplier.manage', {
        url: '/manage?{status:[a-zA-Z0-9]+}&{query:[a-zA-Z0-9]*}&{page:[0-9]}&{extraFilterKey:[a-zA-Z0-9]+}&' +
        '{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}&{tab:[a-zA-Z0-9]+}&' +
        '{extraFilterValue:[a-zA-Z0-9]+}',
        templateUrl: 'app/suppliersManagement/manage/manage.html',
        controller: 'supplierManageCtrl',
        resolve: {
          action: function ($stateParams) {
            if ($stateParams.query) {
              return 'search';
            } else {
              return 'show';
            }
          },
          suppliersDataList: function ($q, $state, $stateParams, searchModule, exportSearch) {
            var deferred = $q.defer();
            var status = $stateParams.status;

            var params = {
              module: 'suppliers',
              offset: 20,
              page: $stateParams.page,
              order_by: $stateParams.order_by || 'updated_at',
              order: $stateParams.order
            };

            if (status.toUpperCase() === 'DRAFT') {
              params['module'] = 'suppliers-draft'
            }

            if (!!$stateParams.query) {

              if (!!$stateParams.filter) {

                params['criteria_operator'] = 'and';
                params['criteria[0][' + $stateParams.filter + ']'] = $stateParams.query;

              } else {
                params['criteria_operator'] = !!$stateParams.query ? 'and' : null;
                params['criteria[0][basic_info|descr]'] = $stateParams.query;
                params['criteria[0][basic_info|code]'] = $stateParams.query;
                params['criteria[0][basic_info|reg_no]'] = $stateParams.query;
                params['criteria[0][basic_info|email]'] = $stateParams.query;
                params['criteria[0][default_contract|primary_commodity|descr]'] = $stateParams.query;
                params['criteria[0][default_contract|secondary_commodity|descr]'] = $stateParams.query;
                params['criteria[0][default_contract|currency_code]'] = $stateParams.query;
                params['criteria[0][$operator]'] = !!$stateParams.query ? 'or' : null;

              }

              // status criteria
              if (!!status && !!status.length) {
                if (status !== '*' && status.toUpperCase() !== 'DRAFT') {
                  if (status.toUpperCase() === 'PENDING' || status.toUpperCase() === 'ACTIVE') {
                    params['criteria[1][basic_info|status][0]'] = status.toUpperCase();
                    params['criteria[1][basic_info|status][1]'] = 'ACTIVE_PENDING';
                  } else {
                    params['criteria[1][basic_info|status]'] = status.toUpperCase();
                  }

                  params['criteria[1][$strict]'] = true;
                }
              }
              else if ($stateParams.status === 'all') {
                params['criteria[1][basic_info|status]'] = null;
                params['criteria[1][$operator]'] = 'or';
              }

            }
            /**
             * For listing
             */
            else {
              if (!!status && !!status.length) {
                if (status !== '*' && status.toUpperCase() !== 'DRAFT') {
                  if (status.toUpperCase() === 'PENDING' || status.toUpperCase() === 'ACTIVE') {
                    params['criteria[0][basic_info|status][0]'] = status.toUpperCase();
                    params['criteria[0][basic_info|status][1]'] = 'ACTIVE_PENDING';
                  } else {
                    params['criteria[0][basic_info|status]'] = status.toUpperCase();
                  }

                  params['criteria[0][$strict]'] = true;
                }
              }
              else if ($stateParams.status === 'all') {
                params['criteria[0][basic_info|status]'] = null;
                params['criteria[1][$operator]'] = 'or';
              }
            }

            /**
             * check for 'yours' & 'waiting_on'
             */
            if(!!$stateParams.extraFilterKey && !!$stateParams.extraFilterValue)
              params['criteria[1][' + $stateParams.extraFilterKey + ']'] = $stateParams.extraFilterValue;

            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data) {
                  deferred.resolve(resource.content);
                } else {
                  deferred.resolve({data: []});
                }
              },
              function (error) {
              }
            );

            return deferred.promise;
          },
          $title: function ($stateParams) {
            return 'Supplier - listing' + (!!$stateParams.status ? ' - ' + $stateParams.status : '');
          },
          prepareTranslation: function ($translatePartialLoader) {
            $translatePartialLoader.addPart('suppliersManagement/manage/lang');
          },
        }
      })

      .state('main.user.supplier.details', {
        url: '/detail?{id:[a-zA-Z0-9]}&{query:[a-zA-Z0-9]*}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&' +
        '{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        templateUrl: 'app/suppliersManagement/details/supplierDetails.html',
        controller: 'supplierDetailsCtrl',
        resolve: {
          supplierDetails : function (supplier, $q, $stateParams) {
            var deferred = $q.defer();
            supplier.get(
              {id: $stateParams.id},
              function(resource){
              deferred.resolve(resource.content);
              }, function(error){

              });
            return deferred.promise;
          },
          currencies: function ($stateParams, $q, searchModule) {
            var deferred = $q.defer();

            if (!!JSON.parse(window.localStorage.getItem('currencies'))) {
              deferred.resolve(JSON.parse(window.localStorage.getItem('currencies')));
            } else {
              searchModule.get(
                {
                  module: 'currencies',
                  offset: 0
                },
                function (resource) {
                  if (!!resource && !!resource.content && !!resource.content.data) {
                    window.localStorage.setItem('currencies', JSON.stringify(resource.content.data));
                    deferred.resolve(resource.content.data);
                  } else {
                    deferred.resolve([]);
                  }
                },
                function (error) {
                  deferred.resolve([]);
                });
            }

            return deferred.promise;
          },
          paymentTerms: function (searchModule, $q) {
            var deferred = $q.defer();
            //load the paymentTerms
            if (!JSON.parse(window.localStorage.getItem('paymentTerms'))) {
              searchModule.get(
                {
                  module: 'payment-term',
                  'criteria[is_active]': 1,
                  offset: 0
                },
                function (resource) {
                  if (!!resource && !!resource.content) {
                    window.localStorage.setItem('paymentTerms', JSON.stringify(resource.content));
                    deferred.resolve(resource.content);
                  } else {
                    deferred.resolve([]);
                  }
                },
                function (error) {
                  deferred.resolve([]);
                });
              return deferred.promise;
            }
            else {
              return JSON.parse(window.localStorage.getItem('paymentTerms'));
            }
          },
          paymentMethods: function (searchModule, $q) {
            var deferred = $q.defer();
            //load the paymentMethods
            if (!JSON.parse(window.localStorage.getItem('paymentMethods'))) {
              searchModule.get(
                {
                  module: 'payment-method',
                  offset: 0
                },
                function (resource) {
                  if (!!resource && !!resource.content) {
                    deferred.resolve(resource.content);
                    window.localStorage.setItem('paymentMethods', JSON.stringify(resource.content));
                  } else {
                    deferred.resolve([]);
                  }
                },
                function (error) {
                  deferred.resolve([]);
                });
              return deferred.promise;
            }
            else {
              return JSON.parse(window.localStorage.getItem('paymentMethods'));
            }
          },
          taxes: function (searchModule, $q) {
            var deferred = $q.defer();
            if (!JSON.parse(window.localStorage.getItem('taxes'))) {
              searchModule.get(
                {
                  module: 'tax',
                  offset: 0,
                  'criteria[is_active]': 1
                },
                function (resource) {
                  if (!!resource.content && !!resource.content.data) {
                    deferred.resolve(resource.content.data);
                    window.localStorage.setItem('taxes', JSON.stringify(resource.content.data));
                  }
                  else {
                    deferred.resolve([]);
                  }
                },
                function () {
                  deferred.resolve([]);
                }
              );
            }
            else {
              deferred.resolve(JSON.parse(window.localStorage.getItem('taxes')));
            }
            return deferred.promise;
          },
          commodities: function ($q, listChild, globalFunc) {
            var deferred = $q.defer();

            listChild.get(
              {
                code: 'All'
              },
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data) {
                  deferred.resolve(resource.content);
                }
                else {
                  deferred.resolve([]);
                }
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                  deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          companies: function($q, searchModule, globalFunc) {
            var deferred = $q.defer();

            searchModule.get(
              {
                module: 'companies',
                offset: 0,
                'criteria[call_from]': 'supplier-management'
              },
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data) {
                  deferred.resolve(resource.content);
                }
                else {
                  deferred.resolve([]);
                }
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              });
            return deferred.promise;
          },
          timezones: function ($q, userTimezones) {
            var deferred = $q.defer();
            userTimezones.get(
              {},
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content.data);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          featureToggle: function (FeatureToggleService, $state, $q, toastr) {
            var deferred = $q.defer();

            FeatureToggleService.getMultiple(['syncEngine', 'system.enableSourcingSupplier'])
              .then(function(response) {
                  deferred.resolve(response.data);
                },
                function (e) {
                  deferred.resolve({});
                  console.log('Toggle could not be retrieved.');
                });

            return deferred.promise;
          },
          $title: function ($stateParams) {
            return 'Supplier - Supplier Details';
          },
          prepareTranslation: function ($translatePartialLoader) {
            $translatePartialLoader.addPart('suppliersManagement/newSupplier/lang');
          },
        }
      })
      .state('main.user.supplier.import', {
        templateUrl: 'app/suppliersManagement/import/suppliersImportTemplate.html',
        url: '/import',
        controller: 'suppliersImportCtrl',
        resolve: {
          $title: function ($stateParams) {
            return 'Supplier - Import Supplier';
          },
          prepareTranslation: function ($translatePartialLoader) {
            $translatePartialLoader.addPart('main/lang');
            $translatePartialLoader.addPart('suppliersManagement/lang');
          },
        }
      })
      .state('main.user.supplier.contactImport', {
        templateUrl: 'app/suppliersManagement/contactImport/contactPersonsImportTemplate.html',
        url: '/contact-import',
        controller: 'contactPersonsImportCtrl',
        resolve: {
          $title: function ($stateParams) {
            return 'Import Contact Persons';
          }
        }
      })
      .state('main.user.supplier.sourcingSupplierImport', {
        templateUrl: 'app/suppliersManagement/sourcingSupplier/sourcingSupplierImportTemplate.html',
        url: '/sourcingSupplier-import',
        controller: 'sourcingSupplierImportCtrl',
        resolve: {
          $title: function ($stateParams) {
            return 'Import Sourcing Supplier';
          }
        }
      })
      .state('main.user.supplier.newSupplier', {
        templateUrl: 'app/suppliersManagement/newSupplier/newSupplier.html',
        url: '/newSupplier',
        controller: 'newSupplierCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'Supplier', 'Create')) {
              $state.go('notAuthorized');
            }
          },
          supplier: function() {
            // return empty object to be consistent with editSupplierDraft
            return {};
          },
          taxes: function (searchModule, $q) {
            var deferred = $q.defer();
            if (!JSON.parse(window.localStorage.getItem('taxes'))) {
              searchModule.get(
                {
                  module: 'tax',
                  offset: 0,
                  'criteria[is_active]': 1
                },
                function (resource) {
                  if (!!resource.content && !!resource.content.data) {
                    deferred.resolve(resource.content.data);
                    window.localStorage.setItem('taxes', JSON.stringify(resource.content.data));
                  }
                  else {
                    deferred.resolve([]);
                  }
                },
                function () {
                  deferred.resolve([]);
                }
              );
            } else {
              deferred.resolve(JSON.parse(window.localStorage.getItem('taxes')));
            }
            return deferred.promise;
          },
          currencies: function ($stateParams, $q, searchModule) {
            var deferred = $q.defer();

            if (!!JSON.parse(window.localStorage.getItem('currencies')) && !!JSON.parse(window.localStorage.getItem('currencies')).length) {
              deferred.resolve(JSON.parse(window.localStorage.getItem('currencies')));
            } else {
              searchModule.get(
                {
                  module: 'currencies',
                  offset: 0,
                  'criteria[is_active]': 1
                },
                function (resource) {
                  if (!!resource && !!resource.content && !!resource.content.data) {
                    window.localStorage.setItem('currencies', JSON.stringify(resource.content.data));
                    deferred.resolve(resource.content.data);
                  } else {
                    deferred.resolve([]);
                  }
                },
                function (error) {
                  deferred.resolve([]);
                });
            }
            return deferred.promise;
          },
          paymentMethods: function (searchModule, $q) {
            var deferred = $q.defer();
            //load the paymentMethods
            if (!JSON.parse(window.localStorage.getItem('paymentMethods'))) {
              searchModule.get(
                {
                  module: 'payment-method',
                  offset: 0
                },
                function (resource) {
                  if (!!resource && !!resource.content) {
                    deferred.resolve(resource.content);
                    window.localStorage.setItem('paymentMethods', JSON.stringify(resource.content));
                  } else {
                    deferred.resolve([]);
                  }
                },
                function (error) {
                  deferred.resolve([]);
                });
              return deferred.promise;
            }
            else {
              return JSON.parse(window.localStorage.getItem('paymentMethods'));
            }
          },
          paymentTerms: function (searchModule, $q) {
            var deferred = $q.defer();
            //load the paymentTerms
            searchModule.get(
              {
                module: 'payment-term',
                'criteria[is_active]': 1,
                offset: 0
              },
              function (resource) {
                if (!!resource && !!resource.content) {
                  deferred.resolve(resource.content);
                } else {
                  deferred.resolve([]);
                }
              },
              function (error) {
                deferred.resolve([]);
              });
            return deferred.promise;
          },
          commodities: function ($q, listChild) {
            var deferred = $q.defer();

            listChild.get(
              {
                code: 'All'
              },
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data) {
                  deferred.resolve(resource.content);
                }
                else {
                  deferred.resolve([]);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          timezones: function ($q, userTimezones) {
            var deferred = $q.defer();
            userTimezones.get(
              {},
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content.data);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          sourcingSupplierFeatureToggle: function ($q, $stateParams, FeatureToggleService, toastr) {
            var deferred = $q.defer();

            FeatureToggleService.getOne(['system.enableSourcingSupplier'])
              .then(function (resource) {
                if (!!resource.data) {
                  deferred.resolve(resource.data);
                } else {
                  deferred.resolve({});
                  console.log("Failed to get toggle data.");
                }
              })
              .catch(function (e) {
                deferred.resolve({});
                toastr.error('Something went wrong')
              });

            return deferred.promise;
          },
          $title: function ($stateParams) {
            return 'Supplier - New Supplier' + (!!$stateParams.status ? ' - ' + $stateParams.status : '');
          },
          prepareTranslation: function ($translatePartialLoader) {
            $translatePartialLoader.addPart('suppliersManagement/newSupplier/lang');
            $translatePartialLoader.addPart('suppliersManagement/supplierUser/lang');
          },
        }
      })

      .state('main.user.supplier.editSupplierDraft', {
        templateUrl: 'app/suppliersManagement/newSupplier/newSupplier.html',
        url: '/newSupplier/{id:[a-zA-Z0-9]+}',
        controller: 'newSupplierCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'Supplier', 'Create')) {
              $state.go('notAuthorized');
            }
          },
          supplier: function (supplierDraft, $q, $stateParams) {
            var deferred = $q.defer();
            supplierDraft.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                if (!!resource.content && !!resource.content.data) {
                  deferred.resolve(resource.content.data);
                }
              },
              function () {
                deferred.resolve([]);
              });
            return deferred.promise;
          },
          taxes: function (searchModule, $q) {
            var deferred = $q.defer();
            if (!JSON.parse(window.localStorage.getItem('taxes'))) {
              searchModule.get(
                {
                  module: 'tax',
                  offset: 0,
                  'criteria[is_active]': 1
                },
                function (resource) {
                  if (!!resource.content && !!resource.content.data) {
                    deferred.resolve(resource.content.data);
                    window.localStorage.setItem('taxes', JSON.stringify(resource.content.data));
                  }
                  else {
                    deferred.resolve([]);
                  }
                },
                function () {
                  deferred.resolve([]);
                }
              );
            } else {
              deferred.resolve(JSON.parse(window.localStorage.getItem('taxes')));
            }
            return deferred.promise;
          },
          currencies: function ($stateParams, $q, searchModule) {
            var deferred = $q.defer();

            if (!!JSON.parse(window.localStorage.getItem('currencies')) && !!JSON.parse(window.localStorage.getItem('currencies')).length) {
              deferred.resolve(JSON.parse(window.localStorage.getItem('currencies')));
            } else {
              searchModule.get(
                {
                  module: 'currencies',
                  offset: 0,
                  'criteria[is_active]': 1
                },
                function (resource) {
                  if (!!resource && !!resource.content && !!resource.content.data) {
                    window.localStorage.setItem('currencies', JSON.stringify(resource.content.data));
                    deferred.resolve(resource.content.data);
                  } else {
                    deferred.resolve([]);
                  }
                },
                function (error) {
                  deferred.resolve([]);
                });
            }
            return deferred.promise;
          },
          paymentMethods: function (searchModule, $q) {
            var deferred = $q.defer();
            //load the paymentMethods
            if (!JSON.parse(window.localStorage.getItem('paymentMethods'))) {
              searchModule.get(
                {
                  module: 'payment-method',
                  offset: 0
                },
                function (resource) {
                  if (!!resource && !!resource.content) {
                    deferred.resolve(resource.content);
                    window.localStorage.setItem('paymentMethods', JSON.stringify(resource.content));
                  } else {
                    deferred.resolve([]);
                  }
                },
                function (error) {
                  deferred.resolve([]);
                });
              return deferred.promise;
            }
            else {
              return JSON.parse(window.localStorage.getItem('paymentMethods'));
            }
          },
          paymentTerms: function (searchModule, $q) {
            var deferred = $q.defer();
            //load the paymentTerms
            searchModule.get(
              {
                module: 'payment-term',
                'criteria[is_active]': 1,
                offset: 0
              },
              function (resource) {
                if (!!resource && !!resource.content) {
                  deferred.resolve(resource.content);
                } else {
                  deferred.resolve([]);
                }
              },
              function (error) {
                deferred.resolve([]);
              });
            return deferred.promise;
          },
          commodities: function ($q, listChild) {
            var deferred = $q.defer();

            listChild.get(
              {
                code: 'All'
              },
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data) {
                  deferred.resolve(resource.content);
                }
                else {
                  deferred.resolve([]);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          sourcingSupplierFeatureToggle: function ($q, $stateParams, FeatureToggleService, toastr) {
            var deferred = $q.defer();

            FeatureToggleService.getOne(['system.enableSourcingSupplier'])
              .then(function (resource) {
                if (!!resource.data) {
                  deferred.resolve(resource.data);
                } else {
                  deferred.resolve({});
                  console.log("Failed to get toggle data.");
                }
              })
              .catch(function (e) {
                deferred.resolve({});
                toastr.error('Something went wrong')
              });

            return deferred.promise;
          },
          timezones: function ($q, userTimezones) {
            var deferred = $q.defer();
            userTimezones.get(
              {},
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content.data);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          sourcingSupplierFeatureToggle: function ($q, $stateParams, FeatureToggleService, toastr) {
            var deferred = $q.defer();

            FeatureToggleService.getOne(['system.enableSourcingSupplier'])
              .then(function (resource) {
                if (!!resource.data) {
                  deferred.resolve(resource.data);
                } else {
                  deferred.resolve({});
                  console.log("Failed to get toggle data.");
                }
              })
              .catch(function (e) {
                deferred.resolve({});
                toastr.error('Something went wrong')
              });

            return deferred.promise;
          },
          $title: function ($stateParams) {
            return 'Supplier - New Supplier' + (!!$stateParams.status ? ' - ' + $stateParams.status : '');
          },
          prepareTranslation: function ($translatePartialLoader) {
            $translatePartialLoader.addPart('suppliersManagement/newSupplier/lang');
            $translatePartialLoader.addPart('suppliersManagement/supplierUser/lang');
          },
        }
      })
      /** Companies tagged to supplier */

      .state('main.user.supplier.tagsBySupplier', {
        templateUrl: 'app/suppliersList/supplierTagsBySupplier/manage/manage.html',
        url: '/tagsBySupplier?{query:[a-zA-Z0-9]*}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&' +
          '{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'supplierTagsBySupplierCtrl',
        resolve: {
          detailState: function () {
            return 'main.user.supplier.supplierTagsBySupplierDetail';
          },
          suppliersList: function ($q, searchModule, authenticated, $stateParams) {
            var deferred = $q.defer();

            var params = {
              module: 'suppliers',
              'criteria[0][basic_info|status]': 'active',
              offset: 20,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            if (!authenticated) {
              deferred.resolve(undefined);
              return deferred.promise;
            }

            if (!!$stateParams.query) {

              if (!!$stateParams.filter) {
                var temp = '';
                if ($stateParams.type === 'date') {
                  temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                } else {
                  params['criteria[1][' + $stateParams.filter + ']'] = $stateParams.query;
                }
              } else {
                params['criteria[1][basic_info|descr]'] = $stateParams.query;
                params['criteria[1][basic_info|code]'] = $stateParams.query;
                params['criteria[1][basic_info|reg_no]'] = $stateParams.query;
                params['criteria[1][basic_info|email]'] = $stateParams.query;
                params['criteria[1][default_contract|contacts| name]'] = $stateParams.query;
                params['criteria[1][default_contract|contacts|email]'] = $stateParams.query;
                params['criteria[1][default_contract|primary_business_nature]'] = $stateParams.query;
                params['criteria[1][default_contract|primary_commodity_descr]'] = $stateParams.query;
                params['criteria[1][default_contract|secondary_commodity_descr]'] = $stateParams.query;
                params['criteria[1][creator_info|display_name]'] = $stateParams.query;
                params['criteria[1][updater_info|display_name]'] = $stateParams.query;
                params['criteria[1][$operator]'] = !!$stateParams.query ? 'or' : null;
              }
            }

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Supplier - Supplier List';
          }
        }
      })

      .state('main.user.supplier.supplierTagsBySupplierDetail', {
        templateUrl: 'app/suppliersList/supplierTagsBySupplier/detail/detail.html',
        url: '/supplierTagsBySupplierDetail?{supplierCode:[a-zA-Z0-9]+}&{supplierName:[a-zA-Z0-9]+}&{query:[a-zA-Z0-9]*}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&' +
          '{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'supplierTagsBySupplierDetailCtrl',
        resolve: {
          supplierName: function ($stateParams) {
            return $stateParams.supplierName;
          },
          companiesTaggedToSupplier: function ($q, searchModule, getTagsBySupplierCode, exportSearch, $stateParams) {
            var deferred = $q.defer();


            var exportParams = {
              module: 'supplier-tags',
              'criteria[supplier_code]': $stateParams.supplierCode
            };
            exportSearch.setParams(exportParams);

            var params = {
              module: 'companies',
              offset: 20,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order,
              criteria_operator: 'and',
              'criteria[0][supplier_tags.supplier_code]': $stateParams.supplierCode
            };

            if (!!$stateParams.query) {
              if (!!$stateParams.filter) {
                var temp = 'criteria[0][' + $stateParams.filter + ']';
                if ($stateParams.type === 'date') {
                  temp = 'criteria[0][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                } else {
                  params[temp] = $stateParams.query;
                }
              } else {
                params['criteria[0][descr]'] = !!$stateParams.query ? $stateParams.query : null;
                params['criteria[0][reg_no]'] = !!$stateParams.query ? $stateParams.query : null;
                params['criteria[0][code]'] = !!$stateParams.query ? $stateParams.query : null;
                params['criteria[0][creator_info|display_name]'] = !!$stateParams.query ? $stateParams.query : null;
                params['criteria[0][updater_info|display_name]'] = !!$stateParams.query ? $stateParams.query : null;
                params['criteria[0][$operator]'] = !!$stateParams.query ? 'or' : null;
              }
            }

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Supplier - Companies Tagged to Supplier';
          }
        }
      })

      /** Suppliers tagged to company*/

      .state('main.user.supplier.tagsByCompany', {
        templateUrl: 'app/suppliersList/supplierTagsByCompany/manage/manage.html',
        url: '/tagsByCompany?{query:[a-zA-Z0-9]*}&{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}&' +
          '{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'supplierTagsByCompanyCtrl',
        resolve: {
          detailState: function () {
            return 'main.user.supplier.supplierTagsByCompanyDetail';
          },
          companiesList: function ($q, searchModule, profile, $stateParams, authenticated, globalFunc) {
            var deferred = $q.defer();
            var userCompanyCodes = [];

            _.forEach(profile.companies, function (company) {
              userCompanyCodes.push(company.code);
            });

            var params = {
              module: 'companies',
              offset: 20,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            if (!!$stateParams.query) {
              if (!!$stateParams.filter) {
                var temp = '';
                if ($stateParams.type === 'date') {
                  temp = 'criteria[0][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                } else {
                  params['criteria[0][' + $stateParams.filter + ']'] = $stateParams.query;
                }
              } else {
                params['criteria[0][descr]'] = !!$stateParams.query ? $stateParams.query : null;
                params['criteria[0][reg_no]'] = !!$stateParams.query ? $stateParams.query : null;
                params['criteria[0][code]'] = $stateParams.query;
                params['criteria[0][business_unit|descr]'] = $stateParams.query;
                params['criteria[0][industry|descr]'] = $stateParams.query;
                params['criteria[0][creator_info|display_name]'] = $stateParams.query;
                params['criteria[0][updater_info|display_name]'] = $stateParams.query;
                params['criteria[0][$operator]'] = !!$stateParams.query ? 'or' : null;
              }

              if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN')) {
                params['criteria[1][code][]'] = userCompanyCodes;
                params['criteria_operator'] = 'and';
              }
            } else {
              if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN')) {
                params['criteria[0][code][]'] = userCompanyCodes;
                params['criteria_operator'] = 'and';
              }
            }

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Supplier - Company List';
          }
        }
      })

      .state('main.user.supplier.supplierTagsByCompanyDetail', {
        templateUrl: 'app/suppliersList/supplierTagsByCompany/detail/detail.html',
        url: '/supplierTagsByCompanyDetail?{companyId:[a-zA-Z0-9]+}&{companyCode:[a-zA-Z0-9]+}&' +
          '{companyName:[a-zA-Z0-9]+}&{query:[a-zA-Z0-9]*}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&' +
          '{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'supplierTagsByCompanyDetailCtrl',
        resolve: {
          canEditSupplierTag: function (profile, UserPermissions) {
            return (!!UserPermissions.checkPermissionsAccess(profile, 'SupplierTag', 'Create') &&
              !!UserPermissions.checkPermissionsAccess(profile, 'SupplierTag', 'Update'));
          },
          detailState: function () {
            return 'main.user.supplier.supplierTagging';
          },
          companyName: function ($stateParams) {
            return $stateParams.companyName;
          },
          suppliersTaggedToCompany: function ($q, searchModule, getTagsByCompanyCode, exportSearch, $stateParams) {
            var deferred = $q.defer();

            /** params for exporting suppliers tagged by company details*/

            var exportParams = {
              module: 'supplier-tags',
              'criteria[company_code]': $stateParams.companyCode
            };
            exportSearch.setParams(exportParams);

            var params = {
              module: 'suppliers',
              offset: 20,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order,
              'criteria[0][basic_info|status]': 'active',
              criteria_operator: 'and',
              'criteria[0][supplier_tags.company_code]': $stateParams.companyCode
            };

            if (!!$stateParams.query) {
              if (!!$stateParams.filter) {
                var temp = '';
                if ($stateParams.type === 'date') {
                  temp = 'criteria[0][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                }
                else {
                  params['criteria[1][' + $stateParams.filter + ']'] = $stateParams.query;
                }
              }
              else {
                params['criteria[0][basic_info|descr]'] = $stateParams.query;
                params['criteria[0][basic_info|reg_no]'] = $stateParams.query;
                params['criteria[0][basic_info|email]'] = $stateParams.query;
                params['criteria[0][default_contract|contacts|name]'] = $stateParams.query;
                params['criteria[0][default_contract|contacts|email]'] = $stateParams.query;
                params['criteria[0][default_contract|primary_business_nature]'] = $stateParams.query;
                params['criteria[0][default_contract|primary_commodity_descr]'] = $stateParams.query;
                params['criteria[0][default_contract|secondary_commodity_descr]'] = $stateParams.query;
                params['criteria[0][creator_info|display_name]'] = $stateParams.query;
                params['criteria[0][updater_info|display_name]'] = $stateParams.query;
                params['criteria[0][$operator]'] = !!$stateParams.query ? 'or' : null;
              }
            }

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Supplier - Suppliers Tagged to Company';
          }
        }
      })

      .state('main.user.supplier.supplierTagging', {
        templateUrl: 'app/suppliersList/supplierTagging/supplierTagging-template.html',
        url: '/SupplierTagging?{companyCode:[a-zA-Z0-9]+}',
        controller: 'supplierTaggingCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'SupplierTag', 'Create') ||
              !UserPermissions.checkPermissionsAccess(profile, 'SupplierTag', 'Update')) {
              $state.go('notAuthorized');
            }
          },
          companiesList: function ($q, searchModule, authenticated) {
            var deferred = $q.defer();

            if (!authenticated) {
              deferred.resolve(undefined);
              return deferred.promise;
            }

            searchModule.get(
              {
                module: 'companies',
                offset: 0
              },
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },

          suppliersList: function($q, $stateParams, searchModule) {
            var deferred = $q.defer();

            searchModule.get(
              {
                module: 'suppliers',
                offset: 0,
                'criteria[0][basic_info|status]': 'active'
              },
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },

          $title: function () {
            return 'Supplier - Tag Suppliers';
          }
        }
      })

      .state('main.user.supplier.manageSupplierUser', {
        url: '/supplierUser/manage?{status:[a-zA-Z0-9]+}&{query:[a-zA-Z0-9]*}&{page:[0-9]}&{extraFilterKey:[a-zA-Z0-9]+}&' +
        '{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}&{tab:[a-zA-Z0-9]+}&' +
        '{extraFilterValue:[a-zA-Z0-9]+}',
        templateUrl: 'app/suppliersManagement/supplierUser/manage.html',
        controller: 'supplierUserManageCtrl',
        resolve: {
          action: function ($stateParams) {
            if ($stateParams.query) {
              return 'search';
            } else {
              return 'show';
            }
          },
          dataList: function ($q, $state, $stateParams, searchModule, userFunctions, exportSearch) {
            var deferred = $q.defer();
            var status = $stateParams.status;

            var params = {
              module: 'supplier-user',
              offset: 20,
              page: $stateParams.page,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'updated_at',
              order: !!$stateParams.order ? $stateParams.order : 0
            };

            if (!!$stateParams.query) {
              if (!!$stateParams.filter) {
                params['criteria_operator'] = 'and';
                params['criteria[0][' + $stateParams.filter + ']'] = $stateParams.query;
              } else {
                params['criteria_operator'] = !!$stateParams.query ? 'and' : null;
                params['criteria[0][basic_info|descr]'] = $stateParams.query;
                params['criteria[0][supplier_users|display_name]'] = $stateParams.query;
                params['criteria[0][supplier_users|email]'] = $stateParams.query;
                params['criteria[0][$operator]'] = 'or';
              }

              if ($stateParams.status === 'all') {
                params['criteria[1][supplier_users|status]'] = 'all';
              } else {
                params['criteria[1][supplier_users|status]'] = userFunctions.convertUserStatus(status, false);
              }

            /**
             * For listing
             */
            } else {
              if ($stateParams.status === 'all') {
                params['criteria[0][supplier_users|status]'] = 'all';
              } else {
                params['criteria[0][supplier_users|status]'] = userFunctions.convertUserStatus(status, false);
              }
            }

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data) {
                  deferred.resolve(resource.content);
                } else {
                  deferred.resolve({data: []});
                }
              },
              function (error) {
                deferred.resolve({data: []});
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return 'Supplier User Listing - ' + (!!$stateParams.status ? ' - ' + $stateParams.status : '');
          },
          prepareTranslation: function ($translatePartialLoader) {
            $translatePartialLoader.addPart('suppliersManagement/manage/lang');
          },
        }
      })

      .state('main.user.supplier.addSupplierUser', {
        templateUrl: 'app/suppliersManagement/supplierUser/add/add.html',
        url: '/supplierUser/add',
        controller: 'addSupplierUserCtrl',
        resolve: {
          currencies: function ($stateParams, $q, searchModule) {
            var deferred = $q.defer();

            if (!!JSON.parse(window.localStorage.getItem('currencies'))) {
              deferred.resolve(JSON.parse(window.localStorage.getItem('currencies')));
            } else {
              searchModule.get(
                {
                  module: 'currencies',
                  offset: 0
                },
                function (resource) {
                  if (!!resource && !!resource.content && !!resource.content.data) {
                    window.localStorage.setItem('currencies', JSON.stringify(resource.content.data));
                    deferred.resolve(resource.content.data);
                  } else {
                    deferred.resolve([]);
                  }
                },
                function (error) {
                  deferred.resolve([]);
                });
            }

            return deferred.promise;
          },
          timezones: function ($q, userTimezones) {
            var deferred = $q.defer();
            userTimezones.get(
              {},
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content.data);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Add Supplier User';
          },
          prepareTranslation: function ($translatePartialLoader) {
            $translatePartialLoader.addPart('suppliersManagement/supplierUser/lang');
          },
        }
      })

      .state('main.user.supplier.importSupplierUser', {
        url: '/supplierUser/import',
        templateUrl: 'app/suppliersManagement/supplierUser/import/import.html',
        controller: 'SupplierUserImportCtrl',
        resolve: {
          $title: function ($stateParams) {
            return "Supplier User Import";
          }
        }
      })

      .state('main.user.supplier.supplierUserDetail', {
        templateUrl: 'app/suppliersManagement/supplierUser/detail/detail.html',
        url: '/supplierUser/{id:[a-zA-Z0-9]+}/{supplierId:[a-zA-Z0-9]+}',
        controller: 'supplierUserDetailCtrl',
        resolve: {
          user: function (supplierUserSearchService, $q, $stateParams) {
            var deferred = $q.defer();
            supplierUserSearchService.get(
              {
                supplierId: $stateParams.supplierId,
                userId: $stateParams.id
              },
              function (resource) {
                if (!!resource.content && !!resource.content.data && !_.isEmpty(resource.content.data)) {
                  deferred.resolve(resource.content.data[0]);
                } else {
                  deferred.resolve([]);
                }
              },
              function () {
                deferred.resolve([]);
              });
            return deferred.promise;
          },
          currencies: function ($stateParams, $q, searchModule) {
            var deferred = $q.defer();

            if (!!JSON.parse(window.localStorage.getItem('currencies'))) {
              deferred.resolve(JSON.parse(window.localStorage.getItem('currencies')));
            } else {
              searchModule.get(
                {
                  module: 'currencies',
                  offset: 0
                },
                function (resource) {
                  if (!!resource && !!resource.content && !!resource.content.data) {
                    window.localStorage.setItem('currencies', JSON.stringify(resource.content.data));
                    deferred.resolve(resource.content.data);
                  } else {
                    deferred.resolve([]);
                  }
                },
                function (error) {
                  deferred.resolve([]);
                });
            }

            return deferred.promise;
          },
          timezones: function ($q, userTimezones) {
            var deferred = $q.defer();
            userTimezones.get(
              {},
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content.data);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Supplier User Detail';
          },
          prepareTranslation: function ($translatePartialLoader) {
            $translatePartialLoader.addPart('suppliersManagement/supplierUser/lang');
          },
        }
      })
  }]);
