'use strict';
/**
 * @name userCostCenterCtrl
 * @desc Controller for Admin to select cost center for a user
 */
function userCostCenterCtrl($scope, userCostCenters, $uibModalInstance) {
  $scope.userCostCenters = userCostCenters;
  $scope.closeModal = closeModal;
  $scope.columnCostCenter = {

    selectedItemsLength: function () {

      return _.filter($scope.userCostCenters, function (cc) {
        return cc.selected == true;
      }).length;
    },

    checkAllItems: function () {
      if ($scope.columnCostCenter.selected_all_cc) {
        checkCC_list();
        _.forEach($scope.userCostCenters, function (cc) {
          cc.selected = true;
          $scope.user.cc_list.push(cc.code);
        });
      } else {
        _.forEach($scope.userCostCenters, function (cc) {
          checkCC_list();
          cc.selected = false;
        });
      }
    },

    selectedItem: function (cc) {
      if (!!cc.selected) {
        $scope.columnCostCenter.selected_all_cc = ($scope.userCostCenters.length > 0 &&
          $scope.columnCostCenter.selectedItemsLength() == $scope.userCostCenters.length);
        $scope.user.cc_list.push(cc.code);
      } else {
        $scope.columnCostCenter.selected_all_cc = false;
        for(var i = 0; i < $scope.user.cc_list.length; i++){
          if(cc.code === $scope.user.cc_list[i]){
            $scope.user.cc_list.splice(i,1);
          }
        }
      }
    }

  };

  function checkCC_list(){
    //manually clear the cc_list for the modal
    for(var i =0;i<$scope.user.cc_list.length;i++)
    {
      for(var j=0; j<userCostCenters.length;j++)
      {
        if($scope.user.cc_list[i] === userCostCenters[j].code)
        {
          $scope.user.cc_list.splice(i,1);
        }
      }
    }
  }
  //check if the costCenter has been selected
  var counter = 0;
  for(var i =0;i<$scope.user.cc_list.length;i++)
  {
    for(var j=0; j<userCostCenters.length;j++)
    {
      if($scope.user.cc_list[i] === userCostCenters[j].code)
      {
        userCostCenters[j].selected = true;
        //add a simple counter to make the top value checked in case all Cost Centers were chosen
        counter ++;
      }
    }
  }
  //verify the counter
  if(counter === $scope.userCostCenters.length)
  {
    $scope.columnCostCenter.selected_all_cc = true;
  }
  function closeModal() {
    $uibModalInstance.close();
  }
}

userCostCenterCtrl.$inject = ['$scope', 'userCostCenters', '$uibModalInstance'];

angular
  .module('metabuyer')
  .controller('userCostCenterCtrl', userCostCenterCtrl);
