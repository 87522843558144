'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('main.user', {
        url: 'user',
        abstract: true,
        controller: 'userCtrl',
        template: '<ui-view></ui-view>',
        resolve: {
          profile: function ($q, userSettings, $state, $filter, $rootScope, updateProfile, $auth, $location, toastr, Rollbar, $translate) {
            var deferred = $q.defer();

            /**
             * check the authentication and store the url for redirection after a successful login
             * redirect the user to Login page
             */
            if (!$auth.isAuthenticated()) {
              $rootScope.redirectUrl = $location.url();
              $state.go('login');
            }

            /**
             * loading the user profile
             */
            updateProfile.get({},
              function (resource) {
              if (!!resource.content && !!resource.content.data) {
                /**
                 * store the user in the rootScope
                 * store the user in the Rollbar configuration
                 */
                $rootScope.currentUser = resource.content.data;

                  Rollbar.configure({
                    payload: {
                      person: {
                        id: $rootScope.currentUser._id,
                        username: $rootScope.currentUser.display_name,
                        email: $rootScope.currentUser.email
                      }
                    }
                  });

                /**
                 * we can start loading and preparing the apps here
                 * note we can either hold the user from going to different state to avoid any async problem
                 * or we leave Apps to wait for their files to be loaded
                 */
                // console.log(metabuyerAppsLoader.userAppsLoad());

                // update language from user profile locale setting
                if (!!$rootScope.isV2Enabled) {
                  $translate.use('en');
                } else {
                  if (!!resource.content.data.locale) {
                    if (resource.content.data.locale.language === 'en' ||
                        resource.content.data.locale.language === 'zh') {
                      $translate.use(resource.content.data.locale.language === 'zh' ? 'cn' : resource.content.data.locale.language);
                    }
                  }
                }
                deferred.resolve(resource.content.data);
                } else {
                  Rollbar.error('an error occurred while logging in, user info: ' + $auth.getPayload());
                  toastr.info('an error occurred');
                  userSettings.loggedOut();
                  $state.go('login');
                }
              },
              function (error) {
                //failed to get profile information, console logging to get the data.
                console.log(data);
                console.log(status);
                console.log(headers);
                console.log(config);
              });

            return deferred.promise;
          },
          featureToggle: function (FeatureToggleService, $state, $q, toastr) {
            var deferred = $q.defer();

            FeatureToggleService.getMultiple(['email.disableSend', 'syncEngine', 'company.config.enablePriceComparison', 'system.enableTender'])
              .then(function(response) {
                deferred.resolve(response.data);
              },
              function (e) {
                deferred.resolve({});
                console.log('Toggle could not be retrieved.');
              });

            return deferred.promise;
          },
          prepareTranslation: function ($translatePartialLoader) {
            $translatePartialLoader.addPart('main/lang');
          },
        }
      })
      .state('main.user.detailDelegation', {
        url: '/pending-delegation/{id:.+}',
        templateUrl: 'app/usersManagement/delegations/pendingDelegations/detail/detail-delegation.html',
        controller: 'detailDelegationCtrl',
        resolve: {
          detailDelegation: function ($q, $stateParams, singleDelegation) {
            var deferred = $q.defer();
            singleDelegation.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content.data);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Detail Delegation';
          }

        }
      })
      .state('main.user.featureDisabled', {
        url: '',
        templateUrl: 'app/user/featureDisabled/featureDisabled.html'
      })
      .state('main.user.mainMenu', {
          url: '',
          templateUrl: 'app/user/mainMenu/mainMenuTemplate.html',
          controller: 'mainMenuCtrl',
          abstract: true,
          resolve: {
            prepareTranslation: function ($translatePartialLoader) {
              $translatePartialLoader.addPart('user/mainMenu/lang');
            },
          }
      })
      .state('main.user.mainMenu.waiting-on-you', {
        url: '/waiting-on-you?{cursor:[^~,]}',
        template: '<div ng-include="waitingTemplate"></div>',
        controller: 'UserWaitingOnYouCtrl',
        resolve: {
          waitingList: function ($q, waitingOnUserList, $stateParams, $rootScope) {
            var deferred = $q.defer();
            waitingOnUserList.get(
              {
                cursor: $stateParams.cursor
              },
              function (resource) {
                if (!!resource.content) {
                  $rootScope.waitings = resource.content;
                  deferred.resolve(resource.content);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "User - waiting on you";
          },
          findTemplate: function ($rootScope) {
            $rootScope.waitingTemplate = $rootScope.isMobileMode ? 'app/user/waiting-on-you/waiting-on-you-mobile.html'
              : 'app/user/waiting-on-you/waiting-on-you.html';
          },
          prepareTranslation: function ($translatePartialLoader) {
            $translatePartialLoader.addPart('user/waiting-on-you/lang');
          },
        }
      })
      .state('main.user.mainMenu.notifications', {
        url: '/notifications?{subTab:[a-zA-Z0-9]+}&{cursor:[^~,]}',
        templateUrl: 'app/user/notifications/notifications.html',
        controller: 'UserNotificationsCtrl',
        resolve: {
          notificationListing : function ($q, unreadNotificationList, notificationList, $stateParams) {
            if (!$stateParams.subTab) {
              $stateParams.subTab = 'unread';
            }
            var deferred = $q.defer();
            if($stateParams.subTab == 'all'){
              notificationList.get(
                {
                  cursor: $stateParams.cursor
                },
                function (resource) {
                  if (!!resource.content) {
                    deferred.resolve(resource.content);
                  }
                },
                function (error) {
                  if (error.status === 404) {
                    deferred.resolve([]);
                  }
                }
              );
            }
            else if($stateParams.subTab == 'unread'){
              unreadNotificationList.get(
                {
                  offset: 50
                },
                function (resource) {
                  if (!!resource.content) {
                    deferred.resolve(resource.content);
                  }
                },
                function (error) {
                  if (error.status === 404) {
                    deferred.resolve([]);
                  }
                }
              );
            }

            return deferred.promise;
          },
          $title: function($stateParams){
            return "User - notifications";
          },
          prepareTranslation: function ($translatePartialLoader) {
            $translatePartialLoader.addPart('user/notifications/lang');
          },
        }
      })
      .state('main.user.mainMenu.userProfile', {
        url: '/profile',
        templateUrl: 'app/user/settings/userProfile/userProfileTemplate.html',
        controller: 'userProfileCtrl',
        resolve: {
          supplier: function ($q, $state, $stateParams, $filter, authenticated, supplier, $rootScope, toastr, profile, UserPermissions) {
            if (!!UserPermissions.hasSupplierWebUserRole(profile)) {
              var deferred = $q.defer();
              supplier.get(
                {id: profile.supplier_id},
                function(resource){
                  deferred.resolve(resource.content.data);
                }, function(error){
                  toastr.error('Supplier not found');
                  $state.go($rootScope.previousState.name, $rootScope.previousState.params);
                });
              return deferred.promise;
            }
          },
          currencies: function ($stateParams, $q, Currencies) {
            var deferred = $q.defer();

            if (!!JSON.parse(window.localStorage.getItem('currencies'))) {
              deferred.resolve(JSON.parse(window.localStorage.getItem('currencies')));
            } else {
              Currencies.get(
                {},
                function (resource) {
                  if (!!resource && !!resource.content && !!resource.content.data) {
                    window.localStorage.setItem('currencies', JSON.stringify(resource.content.data));
                    deferred.resolve(resource.content.data);
                  } else {
                    deferred.resolve([]);
                  }
                },
                function (error) {
                  deferred.resolve([]);
                });
            }

            return deferred.promise;
          },
          roleAssignmentsData: function($q, profile, userRoleAssignments) {
            var deferred = $q.defer();
            userRoleAssignments.get(
              {
                id: profile._id
              },
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content.data);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          companiesList: function ($q, getAllCompanies, metabuyerCache) {
              var deferred = $q.defer();
              if (!metabuyerCache.get('positionAssignmentCompaniesList')) {
                  getAllCompanies.get(
                      {
                          'fields[0]': '_id',
                          'fields[1]': 'code',
                          'fields[2]': 'descr'
                      },
                      function (resource) {
                          if (!!resource.content) {
                              metabuyerCache.put('positionAssignmentCompaniesList', resource.content.data);
                              deferred.resolve(metabuyerCache.get('positionAssignmentCompaniesList'));
                          }
                      },
                      function (error) {
                          if (error.status === 404) {
                              deferred.resolve([]);
                          }
                      }
                  );
              } else {
                  deferred.resolve(metabuyerCache.get('positionAssignmentCompaniesList'));
              }
              return deferred.promise;
          },
          $title: function($stateParams){
            return "User - profile";
          },
          prepareTranslation: function ($translatePartialLoader) {
            $translatePartialLoader.addPart('user/settings/lang');
          },
        }
      })
      .state('main.user.mainMenu.userDelegation', {
        url: '/profile/user-delegation',
        templateUrl: 'components/userDelegationV2/userDelegationTemplateV2.html',
        controller: 'userDelegationCtrlV2',
        params: { roleAssignmentsData: null},
        resolve: {
          companyDetail: function () {
            return false;
          },
          userId: function (profile) {
            return profile._id
          },
          userCompanies: function ($q, profile, searchModule, $stateParams) {
            var deferred = $q.defer();
            var params = {
              module: 'users',
              'criteria[0][string_id]': profile._id,
              'criteria[1][is_metabuyer_user]': true,
              offset: 20,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              }
            );

            return deferred.promise;
          },
          roleAssignmentData: function ($stateParams, userRoleAssignments, $q, profile) {
            var deferred = $q.defer();
            if (!$stateParams.roleAssignmentsData) {
              userRoleAssignments.get(
                {
                  id: profile._id
                },
                function (resource) {
                  if (!!resource.content) {
                    deferred.resolve(resource.content.data);
                  }
                },
                function (error) {
                  if (error.status === 404) {
                    deferred.resolve([]);
                  }
                }
              );
              return deferred.promise;
            } else {
              return $stateParams.roleAssignmentsData;
            }
          },
          waitingOnYou: function ($q, profile, searchModule, $stateParams,globalFunc) {
            var deferred = $q.defer();
            var status = globalFunc.availableStatuses('delegations', 'pending');
            var params = {
              module: 'delegations',
              'criteria[0][status]': status,
              'criteria[0][waiting_on]' : profile._id,
              offset: 20,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              }
            );

            return deferred.promise;
          },
          $title: function () {
            return "User - delegation"
          }
        }
      })
      .state('main.user.mainMenu.approvalLimit', {
        url: '/profile/user-approval-limit',
        templateUrl: 'app/usersManagement/approvalLimit/approval-limit.html',
        controller: 'approvalLimitCtrl',
        resolve: {
          userData: function () {
            return {};
          },
          $title: function () {
            return "User - Approval Limit";
          }
        }
      })
      .state('main.user.mainMenu.approvalLimit.manage', {
        url: '/manage',
        templateUrl: 'app/usersManagement/approvalLimit/manage/manage.html',
        controller: 'approvalLimitManageCtrl',
        resolve: {
          userData: function () {
            return {};
          },
          $title: function () {
            return "User - Approval Limit";
          }
        }
      })
      .state('main.user.mainMenu.approvalLimit.detail', {
        url: '/{uuid:[a-zA-Z0-9\-]*}',
        templateUrl: 'app/usersManagement/approvalLimit/detail/detail.html',
        controller: 'approvalLimitDetailCtrl',
        params: { approvalLimit: null },
        resolve: {
          approvalLimitData: function ($stateParams, userData) {
            if (!_.isEmpty($stateParams.approvalLimit)){
              return $stateParams.approvalLimit;
            } else {
              var approvalLimit = _.find(userData.approval_limits, function (e) {
                return e.approval_limit_uuid === $stateParams.uuid;
              });

              return approvalLimit || [];
            }
          },
          $title: function () {
            return "User - Approval Limit";
          }
        }
      })
  }]);
