'use strict';

angular
  .module('metabuyer')
  .directive('tenderComponent', function($uibModal) {
    return {
      restrict: 'E',
      scope: {
        initialData: '=',
        tender: '=',
        editableStatus: '=',
        numericPatternRestrict: '=',
        numericPatternRestrictUnitPrice: '=',
        numericPatternRestrictAllowNegative: '='
      },
      template: '<div ng-include="templateUrlTenderComponent"></div>',
      controller: 'tenderComponentCtrl',
      link: function($scope) {
        $scope.templateUrlTenderComponent = 'components/tenderComponents/component/tenderComponentTemplate.html';
      }
    };
  });
