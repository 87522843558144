'use strict';

angular.module('metabuyer.services.purchaseRequisitionsV2', ['ngResource'])
  .factory('purchaseRequisitionsV2Services', function($resource, pathConstants){
    return $resource(
      pathConstants.apiUrls.purchaseRequisitionsV2,
      {},
      {
        'getSinglePR': {
          method: 'GET',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.getSinglePR
        },
        'deleteSinglePR': {
          method: 'DELETE',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.getSinglePR
        },
        'searchPRs': {
          method: 'GET',
          params: {},
          url: pathConstants.apiUrls.purchaseRequisitionsV2.searchPRs
        },
        'createPR': {
          method: 'POST',
          params: {},
          url: pathConstants.apiUrls.purchaseRequisitionsV2.createPR
        },
        'createPRWithItems': {
          method: 'POST',
          params: {},
          url: pathConstants.apiUrls.purchaseRequisitionsV2.createPRWithItems
        },
        'updatePRCompanyInfo': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.updatePRCompanyInfo
        },
        'checkPRCompanyInfo': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.checkPRCompanyInfo
        },
        'setPPContactPerson': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.setPRContactPerson
        },
        'getContactPersonsList': {
          method: 'GET',
          ignoreLoadingBar: true,
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.getPRContactPersons
        },
        'getAvailableDeliveryAddresses': {
          method: 'GET',
          ignoreLoadingBar: true,
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.getAvailableDeliveryAddresses
        },
        'getAvailableRequestorsList': {
          method: 'GET',
          ignoreLoadingBar: true,
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.getAvailableRequestorsList
        },
        'setExpenseType': {
          method: 'POST',
          ignoreLoadingBar: true,
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.setExpenseType
        },
        'setPaymentTerm': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.setPaymentTerm
        },
        'setRequestedBy': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.setRequestedBy
        },
        'setNeededByDate': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.setNeededByDate
        },
        'updateTitle': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.setPRTitle
        },
        'setRemarks': {
          method: 'PUT',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.setRemarks
        },
        'submitPR': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.submitPR
        },
        'withdrawPR': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.withdrawPR
        },
        'setItem': {
          method: 'PUT',
          params: {
            requisition_item_id: '@requisition_item_id',
            action: '@action'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.item
        },
        'deleteItemField': {
          method: 'DELETE',
          params: {
            id: '@id',
            action: '@action'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.item
        },
        'addLineItem': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.addItem
        },
        'deleteLineItem': {
          method: 'DELETE',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.item
        },
        'createNonCatalogItem': {
          method: 'POST',
          params: {
            id: '@requisition_id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.createNonCatalog
        },
        'setLumpSum': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.lumpSum
        },
        'removeLumpSum': {
          method: 'DELETE',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.lumpSum
        },
        'setLumpSumAccountCode': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.lumpSumAccountCode
        },
        'addCollaborator': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.collaborators
        },
        'removeCollaborator': {
          method: 'DELETE',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.collaborators
        },
        'approvalAction': {
          method: 'POST',
          params: {
            approval_id: '@approval_id',
            status: '@status'
          },
          url: pathConstants.apiUrls.approvals.v2.status
        },
        'printPdf': {
          method: 'GET',
          params: {
            approval_id: '@approval_id',
            status: '@status'
          },
          url: pathConstants.apiUrls.approvals.v2.status
        },
        'duplicate': {
          method: 'POST',
          params: {
            requisition_id: '@requisition_id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.duplicate
        },
        'addSupplierNote': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.supplierNotes
        },
        'updateSupplierNote': {
          method: 'PUT',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.supplierNotes
        },
        'updateSkipAutoSendPO': {
          method: 'PUT',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.updateSkipAutoSendPO
        },
        'moveToDraft': {
          method: 'POST',
          params: {
            requisition_id: '@requisition_id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.moveToDraft
        },
        'requestEditableCopy': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.requestEditableCopy
        },
        'submitEditableCopy': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.submitEditedCopy
        },
        'cancelEditableCopy': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.cancelEditedCopy
        },
        'resetApproval': {
          method: 'POST',
          params: {
            approval_id: '@approval_id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.resetApproval
        },
       'resendPendingPrEmail' : {
          method: 'POST',
          params: {
            requisition_id: '@requisition_id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.resendPendingPrEmail
        },
        'updateIntegrationInfo': {
          method: 'PUT',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.updateIntegrationInfo
        },
        'getPrConfig': {
          method: 'GET',
          url: pathConstants.apiUrls.purchaseRequisitions.config
        },
        'setOtherCharges': {
          method: 'PUT',
          url: pathConstants.apiUrls.purchaseRequisitionsV2.otherCharges
        },
        'removeOtherCharges': {
          method: 'DELETE',
          url: pathConstants.apiUrls.purchaseRequisitionsV2.removeOtherCharges
        },
        'updateAccountAssignments': {
          method: 'PUT',
          url: pathConstants.apiUrls.purchaseRequisitionsV2.accountAssignments
        },
        'getCerfData': {
          method: 'GET',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.cerf.getCerf
        },
        'getAdtafData': {
          method: 'GET',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.adtaf.getAdtaf
        },
        'updateHeaderInfo': {
          method: 'PUT',
          params: {
            requisition_id: '@requisition_id',
            field: '@field'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.headerInfo
        },
        'validateCapApproverLimit': {
          method: 'POST',
          params: {
            requisition_id: '@requisition_id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.validateCapApproverLimit
        }
      }
    );

  })
  .factory('getPRPDFHashV2',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.purchaseRequisitionsV2.pdfHash,
        {
          requisition_id: '@requisition_id'
        },
        {
          'get': { method: 'GET', ignoreLoadingBar: true }
        }
      );
    })
  .factory('addCerf',
    function ($resource, $filter, pathConstants) {
      return $resource(
        pathConstants.apiUrls.cerf.processCerf,
        {
          requisitionItemId: '@requisitionItemId'
        },
        {
          'post': {method: 'POST'}
        }
      )
    })
  .factory('updateCerf',
    function ($resource, $filter, pathConstants) {
      return $resource(
        pathConstants.apiUrls.cerf.processCerf,
        {
          requisitionItemId: '@requisitionItemId'
        },
        {
          'post': {method: 'POST'}
        }
      )
    })
  .factory('addAdtaf',
    function ($resource, $filter, pathConstants) {
      return $resource(
        pathConstants.apiUrls.adtaf.processAdtaf,
        {
          requisitionItemId: '@requisitionItemId'
        },
        {
          'post': {method: 'POST'}
        }
      )
    })
  .factory('updateAdtaf',
    function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.adtaf.processAdtaf,
      {
        requisitionItemId: '@requisitionItemId'
      },
      {
        'put': {method: 'POST'}
      }
    )
    })
  .factory('massUpdatePrstatus', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.purchaseRequisitionsV2.massUpdatePrStatus,
      {},
      {
        'put': {method: 'PUT'}
      }
    );
  })
  .factory('purchaseRequisitionsV2CommentService',
    function ($resource, $filter, pathConstants) {
      return $resource(
        pathConstants.apiUrls.purchaseRequisitionsV2.comments,
        {
          referenceId: '@referenceId'
        },
        {
          get: {
            method: 'GET', ignoreLoadingBar: true},
          post: {
            method: 'POST',
            params: {
              referenceId: '@referenceId'
            },
            url: pathConstants.apiUrls.purchaseRequisitionsV2.comments
          },
          delete: {
            method: 'DELETE',
            params: {
              referenceId: '@referenceId',
              commentId: '@commentId'
            },
            url: pathConstants.apiUrls.purchaseRequisitionsV2.comments
          }
        }
      )
    });
