'use strict';
/**
 * @name reportsLogCtrl
 * @desc Controller for Logs of report
 */

function reportsLogCtrl (
  $scope, logList, $filter, reports, $stateParams,downloadReport,pathConstants, toastr, $window, HighlightId,
  globalFunc, reportTypes
) {
  $scope.logs = logList.data;
  $scope.meta = logList.meta;
  $scope.reportService = reports;
  $scope.changeStringCase = changeStringCase;
  $scope.getReport = getReport;
  $scope.getStatusName = getStatusName;
  $scope.getReportType = getReportType;
  $scope.status = $stateParams.status;

  /**
   * To change the Case of the status and remove the underscore
   * @param string
   * @returns {*}
   */
  function changeStringCase(string){
    var newString = string.replace('_', ' ');
    newString = $filter('titleCase')(newString);
    return newString;
  }

  $scope.showColumn = showColumn;

  $scope.columns = [
    {id: 'requestor_user.display_name', label: 'Requestor', unsortable: true},
    {id: 'date', label: 'Date Generated'},
    {id: 'done_at', label: 'Done At'},
    {id: 'type', label: 'Report Type'},
    {id: 'status', label: 'Status', unsortable: true}
  ];

  $scope.storageKey = 'reports-management-selected-columns';

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  /**
   * returns the name of the status
   * @param code
   * @returns {*}
     */
  function getStatusName(code) {

    /**
     PENDING = 0
     IN_PROGRESS = 1
     DONE = 2
     FAILED = 3
     CANCELLED = 4
     */
    if (code === 0) {
      return 'Pending'
    }
    if (code === 1) {
      return 'In Progress'
    }
    if (code === 2) {
      return 'Done'
    }
    if (code === 3) {
      return 'Failed'
    }
    if (code === 4) {
      return 'Cancelled'
    }
  }

  /**
   * returns the name of the report type
   * @param reportType int
   * @returns {*}
   */
  function getReportType(reportType) {
    var reportTypeString = globalFunc.findInArray(reportTypes.getReportTypes(), 'code', reportType);
    if (!reportTypeString)
      return 'Report';
    return reportTypeString.descr;
  }

  function logDetail(log){
    if(!!log.status && log.status === 2) {
      reports.get({
          id: log._id
        },
        function (success) {
          if(!!success.content && !!success.content.data && success.content.data.hash){
            getReport(success.content.data.hash);
          }
        },
        function (error) {
          toastr.error('Failed to generate report');
        })
    }
  }

  function getReport(hash) {

    var newUrl = $filter('format')(pathConstants.apiUrls.reports.download, {
      id: hash
    });
    window.open(newUrl, '_self');

  }

  function initialize() {
    if(!!$scope.$parent)
      $scope.$parent.activatedTab = $scope.$parent.tabData[0];
    else
      $scope.activatedTab = $scope.tabData[0];

    //highlight row
    $scope.highlightId = HighlightId.getId();
    if (!!$scope.highlightId) {
      //scroll the window to top
      $window.scroll(0, 0);
      //clear current id
      HighlightId.setId(null);
    }

  }
    initialize();
}

reportsLogCtrl.$inject = [
  '$scope', 'logList','$filter', 'reports', '$stateParams','downloadReport','pathConstants','toastr', '$window',
  'HighlightId', 'globalFunc', 'reportTypes'
];

angular
  .module('metabuyer')
  .controller('reportsLogCtrl', reportsLogCtrl);
