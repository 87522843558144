/**
 * @name commentsPanel
 *
 * @author Deniel <deniel@metabuyer.com>
 * @copyright 2019 Metacloud Sdn Bhd
 */

'use strict';

angular.module('metabuyer')
  .directive('replyComment', function () {
    return {
      scope: {
        content: '='
      },
      replace: true,
      template:
        '<div class="reply-comment-message">' +
          '<div ng-if="!!content.reply_to">' +
            '<reply-comment content="content.reply_to"></reply-comment>' +
          '</div>' +
          '<div class="commentator">' +
            '<b>{{ content.commentator.display_name }}' +
            '@ {{ content.created_at | date: \'dd MMM yyyy h:mm a\' }} wrote: </b>' +
          '</div>' +
          '<div class="mt-15">' +
            '<text-show-less-more model="content.comment" type="character" length="250">' +
            '</text-show-less-more>' +
          '</div>' +
        '</div>',
      restrict: 'E'
    }
  });
