(function(){
  'use strict';

  angular.module('metabuyer')
    .directive('allowPattern', function() {
      return {
        restrict: 'A',
        compile: function() {
          return function(scope, element, attrs) {
            element.bind('keypress', function(event) {
              var keyCode = event.which || event.keyCode;
              var keyCodeChar = String.fromCharCode(keyCode);

              if(event.charCode === 0){
                return true;
              } else if(!keyCodeChar.match(new RegExp(attrs.allowPattern, 'i'))) { // 'i' case-sensitive
                event.preventDefault();
                return false;
              }

            })
          }
        }
      }
    })
})();
