'use strict';

function suppliersListDetailsCtrl(
  $scope, $state, toastr, HighlightId, supplierDetails, globalFunc, supplierFunctions,
  searchModule, $rootScope, Approvals, currencies, paymentTerms, paymentMethods, taxes,
  sourcingSupplierFeatureToggle, $translate
) {
  $scope.isSourcingSupplierEnabled = sourcingSupplierFeatureToggle.is_enabled;
  $scope.currencies = currencies;
  $scope.paymentTerms = paymentTerms.data;
  $scope.paymentMethods = paymentMethods.data;
  $scope.taxes = taxes;
  $scope.bumiputraStatus = [
    {descr: 'Yes', value: 1},
    {descr: 'No', value: 0}
  ];
  $scope.approvalStatusInfo = {};

  $scope.businessNatures = [
    {descr: 'Unspecified Business Nature', shortDescr: 'UNSPECIFIED', value: 0},
    {descr: 'Agent', shortDescr: 'AGENT', value: 1},
    {descr: 'Banking & Financial Services', shortDescr: 'BANKING', value: 2},
    {descr: 'Consultancy, Advisory & Other Professional Bodies', shortDescr: 'CONSULTANCY', value: 3},
    {descr: 'Contractor', shortDescr: 'CONTRACTOR', value: 4},
    {descr: 'Dealer', shortDescr: 'DEALER', value: 5},
    {descr: 'Distribute', shortDescr: 'DISTRIBUTOR', value: 6},
    {descr: 'Manufacturer', shortDescr: 'MANUFACTURER', value: 7},
    {descr: 'Marketing', shortDescr: 'MARKETING', value: 8},
    {descr: 'Retailer', shortDescr: 'RETAILER', value: 9},
    {descr: 'Service Provider', shortDescr: 'SERVICE_PROVIDER', value: 10},
    {descr: 'Sub-Contractor', shortDescr: 'SUB_CONTRACTOR', value: 11},
    {descr: 'Trading', shortDescr: 'TRADING', value: 12},
    {descr: 'Other', shortDescr: 'OTHERS', value: 13}
  ];
  $scope.businessEntities = [
    {descr: 'Berhad (Public Limited)', shortDescr: 'PUBLIC_LIMITED', value: '1'},
    {descr: 'Sendirian Berhad (Private Limited)', shortDescr: 'PRIVATE_LIMITED', value: '2'},
    {descr: 'Sole Proprietor & Enterprise', shortDescr: 'SOLE', value: '3'},
    {descr: 'Partnership', shortDescr: 'PARTNERSHIP', value: '4'}
  ];

  $scope.supplierTypes = supplierFunctions.getSupplierTypes();
  $scope.languages = supplierFunctions.getLanguages();

  $scope.isValidPhoneNo = true;
  $scope.isValidFaxNo = true;
  $scope.editClicked = false;

  $scope.supplier = supplierDetails.data;
  $scope.approvalFlowData = {};
  $scope.waitingOnUser = [];
  $scope.statusUpdatePayload = {};


  $scope.back = back;

  function selectReceivingMode(poMethod) {
    if (poMethod === 0)
      return;

    if ([2, 6, 10, 14].indexOf(poMethod) > -1)
      $scope.receive_via_email = true;

    if ([4, 6, 12, 14].indexOf(poMethod) > -1)
      $scope.receive_via_fax = true;

    if ([8, 10, 12, 14].indexOf(poMethod) > -1)
      $scope.receive_via_post = true;
  }

  selectReceivingMode($scope.supplier.default_contract.receiving_mode);


  function getPaidUpCategory() {
    if(!!$scope.supplier.basic_info.paid_up_capital) {
      $scope.paidUpCategoryDescr = supplierFunctions.getPaidUpCategory(Number($scope.supplier.basic_info.paid_up_capital));
    }
  }

  function back() {
    if (!!$scope.supplier && !!$scope.supplier._id) {
      HighlightId.setId($scope.supplier._id);
    }
    $state.go('main.user.suppliersList.manage', {status: $scope.supplier.basic_info.status});
  }


  function loadApprovalFlow() {
    if (!$scope.supplier || !$scope.supplier.approval_id) {
      $scope.approvals = [];
    }
    Approvals.get(
      {
        id: supplierFunctions.getApprovalId($scope.supplier)
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.approvals = resource.content.data;
          $scope.approvalFlowData = resource.content.data;
          globalFunc.getWaitingOnUser(resource.content.data, $scope.waitingOnUser);
        } else {
          $scope.approvals = [];
        }
      },
      function () {
        toastr.error('Error loading Approval Flow');
        $scope.approvals = [];
      });
  }

  function loadApprovalStatusInfo() {
    // if status is ACTIVE_PENDING, approval id should be from 'edit.personal' or 'edit.status'
    if (isStatus($scope.supplier, 'ACTIVE_PENDING')) {
      if ($scope.isEditCompanyContract) {
        isEditCompanyContract();
      } else if ($scope.isEditStatus) {
        isEditStatus('ACTIVE_PENDING');
      } else if ($scope.isEditPersonal) {
        isEditPersonal();
      }
      $scope.approvalStatusInfo.current = 'PENDING';

      // if status is PENDING, approval id is from either 'edit.status' or top level in supplier object
    } else if (isStatus($scope.supplier, 'PENDING')) {
      if ($scope.isEditStatus) {
        isEditStatus('PENDING');
      } else if ($scope.isEditPersonal) {
        isEditPersonal();
      } else {
        $scope.approvalStatusInfo.original = 'CREATE';
        $scope.approvalStatusInfo.new = 'ACTIVE';
      }
      $scope.approvalStatusInfo.current = $scope.supplier.basic_info.status;
    } else if (isStatus($scope.supplier, 'INACTIVE')) {
      $scope.approvalStatusInfo.current = 'DEACTIVATED';
    } else if (isStatus($scope.supplier, 'EDITING')) {
      $scope.approvalStatusInfo.current = 'PROCESSING';
    } else {
      $scope.approvalStatusInfo.current = $scope.supplier.basic_info.status;
    }
    var translateArr = [
      'statuses.all',
      'statuses.active',
      'statuses.pending',
      'statuses.rejected',
      'statuses.withdrawn',
      'statuses.deactivated',
    ];
    $scope.statusDesc = $scope.approvalStatusInfo.current;
    $translate(translateArr).then(function (trans) {
      $scope.statusDesc = trans["statuses." + $scope.statusDesc.toLowerCase()];
    });
  }

  function isStatus(supplier, status) {
    return supplier.basic_info.status === status;
  }

  function isEditStatus(status) {
    switch (status) {
      case 'ACTIVE_PENDING':
        var newStatus = $scope.supplier.edit.status.new_status;
        $scope.approvalStatusInfo.original = $scope.supplier.edit.status.original_status;
        $scope.approvalStatusInfo.new = newStatus === 'INACTIVE' ? 'DEACTIVATED' : newStatus;
        break;
      case 'PENDING':
        $scope.approvalStatusInfo.original = $scope.supplier.edit.status.original_status;
        $scope.approvalStatusInfo.new = $scope.supplier.edit.status.new_status;
        break;
    }
  }

  function isEditPersonal() {
    var status = $scope.supplier.edit.personal.original_status;
    switch (status) {
      case 'INACTIVE':
        $scope.approvalStatusInfo.original = 'DEACTIVATED';
        break;
      case 'BLACKLISTED':
        $scope.approvalStatusInfo.original = 'BLACKLISTED';
        break;
      default:
        $scope.approvalStatusInfo.original = 'ACTIVE';
    }
    $scope.approvalStatusInfo.new = 'ACTIVE';
  }

  function checkSupplierActivePending() {
    if ($scope.supplier.basic_info.status === 'ACTIVE_PENDING')
      supplierFunctions.replaceSupplierEditedFields();
  }

  function initialize() {
    $scope.supplier.update_all_contracts = false;
    //TODO: move assignment and check of data to a module level (Ahmed Saleh, 18/6/2019)
    supplierFunctions.setSupplier($scope.supplier);
    $scope.isEditPersonal = supplierFunctions.pendingEditPersonal($scope.supplier);
    $scope.isEditStatus = supplierFunctions.pendingEditStatus($scope.supplier);
    checkSupplierActivePending();

    $scope.branches = [];
    if (angular.isDefined($scope.supplier.branches)) {
      $scope.branches = $scope.supplier.branches;
    }

    if ((angular.isDefined($scope.supplier.edit_merged) && $scope.supplier.edit_merged !== null) && $scope.supplier.edit_merged.branches) {
      $scope.branches = _.values($scope.supplier.edit_merged.branches);
    }

    /** To initialize business nature by looping and comparing the short description */
    if (angular.isDefined($scope.supplier.default_contract.primary_business_nature)) {
      for (var i = 0; i < $scope.businessNatures.length; i++) {
        if ($scope.businessNatures[i].shortDescr === $scope.supplier.default_contract.primary_business_nature) {
          $scope.supplier.selectedPrimaryBusinessNature = $scope.businessNatures[i].descr;
        }
      }
    }

    if (angular.isDefined($scope.supplier.default_contract.secondary_business_nature)) {
      for (var j = 0; j < $scope.businessNatures.length; j++) {
        if ($scope.businessNatures[j].shortDescr === $scope.supplier.default_contract.secondary_business_nature) {
          $scope.supplier.selectedSecondaryBusinessNature = $scope.businessNatures[j].descr;
        }
      }
    }

    if (angular.isDefined($scope.supplier.basic_info.business_entity)) {
      for (var k = 0; k < $scope.businessEntities.length; k++) {
        if ($scope.businessEntities[k].shortDescr === $scope.supplier.basic_info.business_entity) {
          $scope.supplier.selectedBusinessEntity = $scope.businessEntities[k];
        }
      }
    } else {
      $scope.supplier.selectedBusinessEntity = $scope.businessEntities[0];
    }

    $scope.address = {};
    if (!!$scope.supplier && !!$scope.supplier.basic_info.corresponding_address && !_.isArray($scope.supplier.basic_info.corresponding_address)) {
      $scope.address = _.cloneDeep($scope.supplier.basic_info.corresponding_address);
    }
    $scope.supplier.selectedCurrency = null;
    if (!!$scope.supplier && !!$scope.supplier.default_contract.currency_code) {
      var currCode = _.find($scope.currencies, function (item) {
        return $scope.supplier.default_contract.currency_code == item.code;
      });
      if (!!currCode) {
        $scope.supplier.selectedCurrency = currCode.code + ' - ' + currCode.descr;
      }
    }

    getPaidUpCategory();

    if ($scope.supplier.basic_info.status === 'PENDING' || $scope.supplier.basic_info.status === 'ACTIVE_PENDING') {
      loadApprovalFlow();
    }

    loadApprovalStatusInfo();

    $scope.primaryCommodityKeyword ='';
    if(!!$scope.supplier.default_contract.primary_commodity_code){
      $scope.primaryCommodityKeyword = $scope.supplier.default_contract.primary_commodity_code + ' - ' +
        $scope.supplier.default_contract.primary_commodity_descr;
    }

    $scope.secondaryCommodityKeyword ='';
    if(!!$scope.supplier.default_contract.primary_commodity_code) {
      $scope.secondaryCommodityKeyword = $scope.supplier.default_contract.secondary_commodity_code + ' - ' +
        $scope.supplier.default_contract.secondary_commodity_descr;
    }

    $scope.supplier.selectedPaymentTerms = null;
    if (!!$scope.supplier && !!$scope.supplier.default_contract.payment_term_code) {
      var res =
        globalFunc.findInArray($scope.paymentTerms, 'code', $scope.supplier.default_contract.payment_term_code);
      if(!!res){
        $scope.supplier.selectedPaymentTerms = res.descr;
      }
    }

    $scope.supplier.selectedPaymentMethod = null;
    if (!!$scope.supplier && !!$scope.supplier.default_contract.payment_method_code) {
      var res =
        globalFunc.findInArray($scope.paymentMethods, 'code', $scope.supplier.default_contract.payment_method_code);
      if(!!res){
        $scope.supplier.selectedPaymentMethod = res.descr;
      }
    }

    $scope.supplier.selectedTax = null;
    if (!!$scope.supplier && !!$scope.supplier.default_contract.tax_code) {
      var res =
        globalFunc.findInArray($scope.taxes, 'code', $scope.supplier.default_contract.tax_code, true);
      if(!!res){
        $scope.supplier.selectedTax = res.code + ' - ' + res.descr;
      }
    }

    $scope.selectedSupplierType = {};
    $scope.selectedLanguage = {};
    if ($scope.isSourcingSupplierEnabled) {
      $scope.selectedSupplierType = supplierFunctions.findSupplierType($scope.supplier.basic_info);
      $scope.selectedLanguage = supplierFunctions.findLanguage($scope.supplier.basic_info.language);
    }
    
    // encrypt reg_no
    if (!$rootScope.isV2Enabled) {
      $scope.supplier.basic_info.reg_no = atob($scope.supplier.basic_info.reg_no);
    }
  }

  initialize();

}
suppliersListDetailsCtrl.$inject = [
  '$scope', '$state', 'toastr', 'HighlightId', 'supplierDetails', 'globalFunc',
  'supplierFunctions', 'searchModule', '$rootScope', 'Approvals', 'currencies', 'paymentTerms', 'paymentMethods',
  'taxes', 'sourcingSupplierFeatureToggle', '$translate'];

angular.module('metabuyer')
  .controller('suppliersListDetailsCtrl', suppliersListDetailsCtrl);
