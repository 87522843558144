'use strict';

angular
  .module('metabuyer')
  .directive('catalogCart',function() {
    return {
      restrict: 'EA',
      templateUrl: 'components/catalogShoppingMode/catalogCart/catalogCartTemplate.html',
      controller: function (
        $scope, toastr, pathConstants, $timeout, $rootScope, catalogFunction
      ) {

        $scope.imageBasePath = pathConstants.apiUrls.image.pr;
        $scope.cartItems = null;

        $scope.submitAmount = submitAmount;
        $scope.getCartItems = getCartItems;
        $scope.openCart = openCart;
        $scope.viewCart = viewCart;
        $scope.calculateCartTotal = calculateCartTotal;
        $scope.removeItemShoppingCart = removeItemShoppingCart;
        $scope.proceedToPR = proceedToPR;


        /**
         * force the form to Blur to bypass the $event.stopPropagation()
         * validate the entry with UOM, and reset
         * @param field
         * @param originalAmount
         * @param uom
         */
        function submitAmount(field, originalAmount, uom) {
          if (validateEntry(field.$data, uom)) {
            field.$form.$submit();
          } else {
            field.$data = originalAmount;
            field.$form.$cancel();
          }
        }

        /**
         * validate the entry with the UOM for the Fraction
         * @param amount
         * @param uom
         * @returns {boolean}
         */
        function validateEntry(amount, uom) {
          if (!amount) {
            toastr.error('Please insert a valid number');
            return false;
          }
          if ((angular.isDefined(uom) && !!uom) && (uom.is_fraction === 0 || !uom.is_fraction)) {
            if (amount % 1 !== 0) {
              toastr.error('This Unit of Measurement does not accept Fractions');
              return false;
            }
          }
          return true;
        }

        /**
         * @param item
         */
        function removeItemShoppingCart(item) {
          var cartItems = $scope.cartItems;
          var itemIndex = cartItems.findIndex(function (cartItem) {
            return cartItem.item_id === item.item_id;
          });
          $scope.cartItems.splice(itemIndex, 1);
          $rootScope.$broadcast('catalogCartItemDeleted');
        }

        function getCartItems() {
          $scope.cartItems = catalogFunction.getCatalogCartItems();
          return $scope.cartItems;
        }

        /**
         * function to ensure the popup cart stays active
         */
        function openCart() {
          $('.dropdown-menu').click(function (event) {
            event.stopPropagation();
          });
        }

        /**
         * opening the Cart from the 'view cart message'
         */
        function viewCart() {
          $scope.catalogItemAdded = false;
          $('#shoppingCartDirective').click(function (event) {
            event.stopPropagation();
            $('#shoppingCartPanel').addClass('show');
          });
        }

        /**
         * remove the pop up once the mouse is out
         */
        $scope.removeClass = function () {
          $('#shoppingCartPanel').removeClass('show');
        };

        /**
         * listener to activate the popup once an item is added
         */
        $scope.$on('catalogItemFromListAdded', function () {
          $scope.catalogItemAdded = true;
          $scope.timer = $timeout(function () {
            $scope.catalogItemAdded = false;
          }, 3000);
        });

        /**
         * calculate the Cart total without adding Taxes or Lump sum
         * @returns {number}
         */
        function calculateCartTotal() {
          var total = 0;
          _.forEach(getCartItems(), function (item) {
            total += item.default.pricing.unit_price * item.quantityOrdered;
          });
          return total;
        }

        function proceedToPR() {
          catalogFunction.checkUserCompany(function() {
            var selectedCompany = catalogFunction.getCartCompany();
            if(!!selectedCompany){
              var invalidCartItems = catalogFunction.validateCartCatalogGroups(selectedCompany);
              if(invalidCartItems.length >= 1){
                catalogFunction.deleteInvalidCartItemsConfirmation();
              }else{
                catalogFunction.createPRConfirmation();
              }
            }
          });
        }
      }
    }
  });
