'use strict';

function OrdersDetailCtrl(
  $scope, $q, globalFunc, $state, $location, $anchorScroll, order, pathConstants, Orders, profile, $rootScope, toastr,
  purchaseRequisitionsServices, HighlightId, POSubscriptionCheck, getPOPDFHash, $filter, notificationSubscriber,
  POComments, UserPermissions, $timeout, $uibModal, isIntegratedCompany, integrationSolution, Approvals,
  approvalPurchaseOrder, loadCacheableData, toleranceLimit, movePdfToInvalid, companyModeOfPurchase
) {

  $scope.po = order;
  $scope.service = POComments;
  $scope.po.notify = POSubscriptionCheck.subscribed;
  $scope.integrationSolution = integrationSolution;
  $scope.profile = profile;
  $scope.tenant = profile.tenant;
  $scope.notificationToggle = notificationToggle;
  $scope.imageBasePath = pathConstants.apiUrls.image.pr;
  $scope.userBasePath = $rootScope.userImageBasePath;
  $scope.isV2Enabled = !!$rootScope.isV2Enabled;
  $scope.checkGRNAccess = checkGRNAccess;
  $scope.checkCancelButton = checkCancelButton;
  $scope.canUpdatePO = canUpdatePO;
  $scope.selectedAction = selectedAction;
  $scope.parseConditions = parseConditions;
  $scope.disableResendPO = disableResendPO;
  $scope.changeStringCase = changeStringCase;
  $scope.checkJDEIntegration = checkJDEIntegration;
  $scope.retryOutboundPo = retryOutboundPo;
  $scope.pushPoToBifrost = pushPoToBifrost;
  $scope.requestResyncDelivery = requestResyncDelivery;
  $scope.setShowing = setShowing;
  $scope.checkManagerRole = checkManagerRole;
  $scope.updateItemFields = updateItemFields;
  $scope.getItemDiscount = getItemDiscount;
  $scope.isIntegratedCompany = isIntegratedCompany;
  $scope.isPOIntegrated = isPOIntegrated;
  $scope.onSelectOtherChargeTax = onSelectOtherChargeTax;
  $scope.checkOthersValue = checkOthersValue;
  $scope.removeOthers = removeOthers;
  $scope.accordionCheck = accordionCheck;
  $scope.addOthers = addOthers;
  $scope.datepickerOpened = false;
  $scope.openDatepicker = openDatepicker;
  $scope.getLineItemTotal = getLineItemTotal;
  $scope.allowGRNActions = allowGRNActions;
  $scope.priceUpdated = priceUpdated;
  $scope.getOtherChargeDiscount = getOtherChargeDiscount;
  $scope.getItemTax = getItemTax;
  $scope.updatePriceFields = updatePriceFields;
  $scope.updateOtherChargePriceFields = updateOtherChargePriceFields;
  $scope.getOtherChargeTax = getOtherChargeTax;
  $scope.checkCancelButtonV2 = checkCancelButtonV2;
  $scope.cancelEdit = cancelEdit;
  $scope.regeneratePdf = regeneratePdf;
  $scope.checkIntegrationReference = checkIntegrationReference;
  $scope.recalculateOtherChargesGroup = recalculateOtherChargesGroup;
  $scope.isSuperAdminOrDeveloper = isSuperAdminOrDeveloper;
  $scope.isShowCloseAndCancelPoButton = isShowCloseAndCancelPoButton;
  $scope.canResendPoEmail = canResendPoEmail;
  $scope.resendPoEmail = resendPoEmail;
  $scope.isPOCreator = isPOCreator;

  $scope.startDateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };

  $scope.datepickerOpened = {
    date: false
  };

  $scope.hidePrint = hidePrint;

  function openDatepicker($event, item) {
    $event.preventDefault();
    $event.stopPropagation();

    item.datepickerOpened = true;
  }

  $scope.status_mapping =
    {
      'DELIVERY_DATE_UNCONFIRMED': 3,
      'DECLINED': 4,
      'AWAITING_DELIVERY': 5
    };
  $scope.deliveryNotes = order.delivery_notes;

  $scope.deliveryNoteColumn = [
    {id: 'name', label: 'Name'},
    {id: 'deliveryNote', label: 'Delivery Note'}
  ];

  $scope.accordionCheck = accordionCheck;

  $scope.itemOthersDetail = [
    {
      code: 'insurance'
    },
    {
      code: 'transportation'
    },
    {
      code: 'freight'
    },
    {
      code: 'miscellaneous'
    },
    {
      code: 'bahan_bakar_tax'
    },
    {
      code: 'withholding_tax'
    }
  ];

  $scope.openDeliveryNote = openDeliveryNote;
  $scope.approve = approve;
  $scope.reject = reject;
  $scope.edit = edit;
  $scope.canWithdraw = canWithdraw;
  $scope.withdrawPo = withdrawPo;
  $scope.checkWaitingOn = checkWaitingOn;

  $scope.approvalFlowData = {};
  $scope.waitingOnUser = [];
  $scope.reasonReject = {};
  $scope.modesOfPurchase = [];

  function priceUpdated(item) {
    getItemDiscount(item);
    getItemTax(item);
  }

  function changeStringCase(string){
    if (!!string) {
      var newString = string.replace('_', ' ');
      newString = $filter('titleCase')(newString);
      return newString;
    }
    else {
      return '';
    }
  }

  function openDeliveryNote(deliveryNote) {
    $uibModal.open({
      templateUrl: 'app/orders/delivery-note/modal.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'DeliveryNoteCtrl',
      windowClass: 'receive-goods-modal',
      resolve: {
        deliveryNote: function () {
          return deliveryNote;
        },
        po: function () {
          return $scope.po;
        },
        profile: function() {
          return $scope.profile;
        }
      }
    });
  }

  function checkFocItem(){
    _.forEach($scope.po.items, function(item, index){
      if (!!item.foc_item){
        if (!($scope.po.document_rules && $scope.po.document_rules.allow_foc_item)){
          $scope.po.items.splice(index, 1);
        }
      }
    })
  }

  function getStatusOptions() {
    $scope.poStatusOptions = [];
    switch ($scope.po.status) {
      case 'DECLINED': {
        $scope.poStatusOptions.push(
        {
          status: 3,
          descr: 'Supplier couldn\'t confirm delivery date'
        },
        {
          status: 5,
          descr: 'Supplier accepted PO'
        })
      }
      break;
      case 'SUBMITTED': {
        $scope.poStatusOptions.push(
        {
          status: 5,
          descr: 'Supplier accepted PO'
        },
        {
          status: 3,
          descr: 'Supplier couldn\'t confirm delivery date'
        },
        {
          status: 4,
          descr: 'Supplier declined PO'
        })
      }
      break;
      case 'DELIVERY_DATE_UNCONFIRMED': {
        $scope.poStatusOptions.push(
        {
          status: 5,
          descr: 'Supplier Accepted PO'
        },
        {
          status: 4,
          descr: 'Supplier Declined PO'
        })
      }
      break;
    }
  }

  $scope.printPO = function() {

    getPOPDFHash.get(
      {
        id: $scope.po._id,
        timezone: $rootScope.currentUser.config.TZ
      }
    ).$promise.then(
      function (resource) {
        var newUrl = $filter('format')(pathConstants.apiUrls.po.pdfHash, {
          pdf_hash: resource.url
        });

        setTimeout(function () {
          window.open(newUrl, '_blank')
        }, 1001);
      },
      function(error){
        if (!!error) {
          globalFunc.objectErrorMessage(error);
        } else {
          toastr.error('Failed to print PO');
        }
      }
    );
  };

  function regeneratePdf() {
    movePdfToInvalid.put(
      {
        id: $scope.po._id,
      }
    ).$promise.then(
      function () {
        $scope.printPO();
      },
      function () {
        toastr.error('Failed to regenerate the PDF.')
      }
    )
  }

  function setShowing(section){
    switch (section) {
      case 'bill_to':
        $scope.billToShow = ($scope.billToShow === true) ? false : true;
        break;
      case 'deliver_to':
        $scope.deliverToShow = ($scope.deliverToShow === true) ? false : true;
        break;
      case 'other_details':
        $scope.otherToShow = ($scope.otherToShow === true) ? false : true;
        break;
      case 'showSupplier':
        $scope.supplierToShow = ($scope.supplierToShow === true) ? false : true;
    }
  }

  $scope.backToListingPO = backToListingPO;
  $scope.scrollTo = scrollTo;
  $scope.scrollToClosedLineItemComment = scrollToClosedLineItemComment;
  $scope.loadContactPersons = loadContactPersons;
  $scope.checkStarred = checkStarred;
  $scope.starPO = starPO;

  // Check and assign the right status for details page
  $scope.accessStatus = $scope.po.status;
  if(angular.isUndefined($scope.accessStatus) || $scope.accessStatus === null) {
    $scope.accessStatus = $scope.previousState.params.status;
  } else if ($scope.previousState.params.status !== null) {
    if ($scope.previousState.params.status === 'STARRED')
      $scope.accessStatus = $scope.previousState.params.status;
  }

  function selectedAction(item) {

    //check if current status was set
    if(item.status === $scope.status_mapping[$scope.po.status]) {
      toastr.success('The status has been set');
      return;
    }
    /**
     * If statement only for decision 'Decline PO'
     */
    if(item.status === 4) {
      swal(
      {
        title: 'Reason for supplier declined PO?',
        text: 'Please state a reason for supplier declined PO',
        type: 'input',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: false,
        closeOnCancel: true,
        inputType: 'text',
        inputPlaceholder: 'Write a reason'
      },
      function(inputValue) {
        if (!!inputValue) {
          var trimmedValue = inputValue.trim();
        }

        if (!inputValue || trimmedValue === '') {
          swal.showInputError('You need to state a reason for supplier decline PO!');
          return false;
        }

        Orders.setPOStatus(
          {
            id: order._id
          },
          {
            status: item.status,
            comment: inputValue
          },
          function(success) {
            toastr.success('Supplier declined PO');
            swal.close();
            //check if supplier declined the po
            if (item.status === $scope.status_mapping['DECLINED']) {
              HighlightId.setId($scope.po._id);
              $rootScope.$broadcast('loadSpecificTab', {tab: activateTab('declined')});
              return;
            }
          },
          function (error) {
            toastr.error('Failed to submit status due to ' + error.data.message);
          });
      });
    }
    else {
      Orders.setPOStatus(
        {
          id: order._id
        },
        {
          status: item.status
        },
        function (success) {
          /**
           * status 5 for accepting PO
           * setting the status of the PO
           */
          if (item.status === $scope.status_mapping['AWAITING_DELIVERY']) {
            toastr.success('The supplier has accepted the PO');
            $scope.po.status = 'AWAITING_DELIVERY';
            $rootScope.$broadcast('loadSpecificTab', {tab: activateTab('awaiting_delivery'), noTransition: true});
          }

          if (item.status === $scope.status_mapping['DELIVERY_DATE_UNCONFIRMED']) {
            toastr.success('The status has been set');
            $scope.po.status = 'DELIVERY_DATE_UNCONFIRMED';

            //update history
            $rootScope.$broadcast('refreshComments');
            $rootScope.$broadcast('loadSpecificTab', {tab: activateTab('submitted'), noTransition: true});
          }

          getStatusOptions();
          labelStatus();

        },
        function (error) {
          toastr.error('Failed to submit status due to ' + error.data.message);
        });
    }
  }

  function backToListingPO() {
      window.history.back();
      HighlightId.setId($scope.po._id);
  }

  /**
   * Check for checkStarred {return boolean}.
   * send to globalFunc, starFavourites function
   */
  function starPO(poId) {
    var isStarred = checkStarred(poId);
    globalFunc.starFavourites(isStarred, poId, 'PO');
  }

  /**
   * Check with User > favourites document in Users collection
   * @returns {boolean}
   */
  function checkStarred(poId) {
    if (!!$rootScope.currentUser.favourites && !!$rootScope.currentUser.favourites.PO) {
        var userFavourites = $rootScope.currentUser.favourites.PO;
        return userFavourites.indexOf(poId) > -1;
    }
    else {
        return false;
    }
  }

  function scrollTo(id) {
    var old_hash = $location.hash();
    $location.hash(id);
    $anchorScroll();
    $location.hash(old_hash);
  }

  function notificationToggle() {

    if(!$scope.po.notify) {
      notificationSubscriber.post({
          object_id: $scope.po._id, class: 'Metabuyer\\Models\\PO', method: 'subscribe'
        }
        , function (data) {
          $scope.po.notify = true;
          toastr.success('Turned on notifications successfully');
        }
        , function (error) {
          toastr.error('Failed to turn on notifications');
        });
    }
    else{
      notificationSubscriber.delete({
          object_id: $scope.po._id, class: 'Metabuyer\\Models\\PO', method: 'unsubscribe'
        }
        , function (data) {
          $scope.po.notify = false;
          toastr.success('Turned off notifications successfully');
        }
        , function (error) {
          toastr.error('Failed to turn off notifications');
        });
    }

  }


  function scrollToClosedLineItemComment(id){
    $rootScope.$broadcast('scrollToClosedLineItemComment', {id: id});
  }

  function loadContactPersons(company) {

    if(!company || !company._id){
      return;
    }
    var defer = $q.defer();

    purchaseRequisitionsServices.getContactPersonsList(
      {
        id: company._id
      }, function (resource) {
        var contactPersons = (typeof resource.content !== 'undefined' &&
                              typeof resource.content.data !== 'undefined') ? resource.content.data : [];
        $scope.options['contact_persons'] = contactPersons;
        defer.resolve(contactPersons);
      }, function (error) {
        defer.reject(error);
      }
    );

    $scope.datalists = defer.promise;
  }

  function checkGRNAccess() {
    if (!!$scope.po.document_rules && $scope.po.document_rules.pr_version === 'v2') {
      var poItem = $scope.po.items[0];
      var opexItemCostCenterId = poItem.account_assignments[0].cost_center ? poItem.account_assignments[0].cost_center._id : false;
      var capexItemCostCenterId = poItem.cost_center ? poItem.cost_center._id : false;
      var itemCostCenterId = opexItemCostCenterId || capexItemCostCenterId;

      if (itemCostCenterId) {
        var organizationId = [
          $scope.po.billing.company._id,
          $scope.po.billing.cost_center ? $scope.po.billing.cost_center._id : itemCostCenterId
        ];

        return (UserPermissions.checkPermissionsAccess(profile, 'GRN', 'Create', organizationId) ||
          UserPermissions.checkPermissionsAccess(profile, 'GRN', 'Update', organizationId));
      } else {
        return false;
      }
    } else {
      var organizationIds = [
        $scope.po.billing.company._id,
        $scope.po.billing.cost_center._id
      ];
      return (UserPermissions.checkPermissionsAccess(profile, 'GRN', 'Create', organizationIds) ||
        UserPermissions.checkPermissionsAccess(profile, 'GRN', 'Update', organizationIds));
    }
  }

  function canUpdatePO () {
    return (UserPermissions.checkPermissionsAccess(profile, 'PO', 'Update'));
  }

  function hidePrint(){
    var showButton = true;
    var companyId = !!$scope.header.company_info._id ? $scope.header.company_info._id : null;
    if (!!$scope.isV2Enabled && isPOCreatorModeOfPurchase($scope.modesOfPurchase)) {
      return showButton;
    }
    if (!!$scope.isV2Enabled && 
        globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'PO Requester', 3, companyId)
    ) {
      return showButton;
    }
    if(globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'PO Approver') ||
        (!!$scope.isV2Enabled && (
          globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'PR Requestor') ||
          globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'PR-PO Viewer') ||
          globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'PR-PO-GRN Viewer')
      ))
    )
    {
      showButton = false;
    }
    return showButton;
  }

  function isSuperAdminOrDeveloper() {
    return $scope.isDeveloper || $scope.isTenantSuperAdmin;
  }

  function isShowCloseAndCancelPoButton(){
    if (isSuperAdminOrDeveloper()) {
      return true
    }
    else {
      if (_.has($scope.po.billing.expense_type, 'subtype') && ['GWO', 'SPK'].indexOf($scope.po.billing.expense_type.subtype) === -1)
      {
        return (($scope.po.requestor._id === $rootScope.currentUser._id) ) ? true : false
      } else {
        return false
      }
    }
  }

  function parseConditions(condition,poStatus) {
    var returnValue = '';
    switch(condition) {
      case 1:
        returnValue = (['DECLINED','SUBMITTED', 'DELIVERY_DATE_UNCONFIRMED'].indexOf(poStatus));
        break;
      case 2:
        returnValue = (['PARTIALLY_DELIVERED', 'AWAITING_DELIVERY', 'CLOSED', 'APPROVED'].indexOf(poStatus));
        break;
      case 3:
        returnValue = (['FULLY_DELIVERED', 'CLOSED','PARTIALLY_DELIVERED', 'APPROVED'].indexOf(poStatus));
        break;
      case 4:
        returnValue = (['SUBMITTED', 'DELIVERY_DATE_UNCONFIRMED','AWAITING_DELIVERY', 'APPROVED'].indexOf(poStatus));
        break;
      case 5:
        returnValue = (['PARTIALLY_DELIVERED', 'AWAITING_DELIVERY', 'APPROVED', 'SUBMITTED'].indexOf(poStatus));
        break;
      case 6:
        returnValue = (['SUBMITTED'].indexOf(poStatus));
        break;
      case 7:
        returnValue = (['DELIVERY_DATE_UNCONFIRMED'].indexOf(poStatus));
        break;
      case 8:
        returnValue = (['DECLINED'].indexOf(poStatus));
        break;
    }
    return returnValue > -1;
  }

  function labelStatus() {
    if(!!$scope.po.status) {
      switch($scope.po.status) {
        case 'CANCELLED':
          $scope.po.generalStatus ='Cancelled';
          $scope.po.specificStatus ='Cancelled';
          break;
        case 'ACCEPTED':
          $scope.po.generalStatus ='Accepted';
          $scope.po.specificStatus ='Accepted';
          break;
        case 'CONFIRMED':
          $scope.po.generalStatus ='Confirmed';
          $scope.po.specificStatus ='Confirmed';
          break;
        case 'CLOSED':
          $scope.po.generalStatus ='Closed';
          $scope.po.specificStatus ='Closed';
          break;
        case 'FULLY_DELIVERED':
          $scope.po.generalStatus ='Delivered';
          $scope.po.specificStatus ='Delivered';
          break;
        case 'DECLINED':
          $scope.po.generalStatus ='Declined';
          $scope.po.specificStatus ='Declined';
          break;
        case 'DELIVERY_DATE_UNCONFIRMED':
          $scope.po.generalStatus ='Submitted';
          $scope.po.specificStatus ='Delivery Date Unconfirmed';
          break;
        case 'SUBMITTED':
          $scope.po.generalStatus ='Submitted';
          $scope.po.specificStatus ='Submitted';
          break;
        case 'AWAITING_DELIVERY':
          $scope.po.generalStatus ='Awaiting Delivery';
          $scope.po.specificStatus ='Awaiting Delivery';
          break;
        case 'PARTIALLY_DELIVERED':
          $scope.po.generalStatus ='Awaiting Delivery';
          $scope.po.specificStatus ='Partially Delivered ( ' +
            $filter('formatDecimal')($scope.po.received_percentage*100, 2) + '% )';
          break;
        case 'EXPIRED':
          $scope.po.generalStatus ='Expired';
          $scope.po.specificStatus ='Expired';
          break;
      }
    }
  }

  function activateTab(tab){
    var status = tab;
    if(!status)
      status = _.cloneDeep($scope.po.status.toLowerCase());

    //amend the status name to match the keys
    if(status === 'awaiting_confirmation')
      status = 'submitted';
    else if (status === 'partially_delivered')
      status = 'awaiting_delivery';
    else if (status === 'delivered')
      status = 'closed';
    else if (status === 'fully_delivered')
      status = 'closed';
    else if (status === 'delivery_date_unconfirmed')
      status = 'submitted';

    tab = globalFunc.findInArray($scope.$parent.tabData, 'key', status);
    if(!tab)
      tab = globalFunc.findInArray($scope.$parent.tabData.more, 'key', status);

    return !!tab ? tab : $scope.$parent.tabData[0];
  }

  function disableResendPO(){
    var allowableStatus = [
      'SUBMITTED',
      'DELIVERY_DATE_UNCONFIRMED',
      'AWAITING_DELIVERY',
      'PARTIALLY_DELIVERED',
      'FULLY_DELIVERED',
      'CLOSED'
    ];

    return allowableStatus.indexOf($scope.po.status) >= 0;
  }

  function cancelEdit() {
    $scope.po = _.cloneDeep($scope.originalPO);
    $scope.editPO = false;
  }

  function initialize() {
    loadCompanyTolerance();
    $scope.editPO = false;
    if (!!$scope.po.approval_id) {
      loadApprovalFlow();
    }

    _.forEach($scope.po.items, function(e) {
      e.accordion = {
        'isOpen': true
      };
      if (e.discount === null){
        e.discount = [
          {
            'is_percentage': false,
            'amount': 0
          }
        ]
      }
      getItemDiscount(e);
      _.forEach(e.other_charges, function(charge){
        getOtherChargeDiscount(charge);
        if (!_.isEmpty(charge.tax)) {
          getOtherChargeTax(charge);
        }
      })
    })

    loadCacheableData.loadData({
      module: 'tax',
      'criteria[is_active]': 1,
      offset: 0
    }, 'taxes', 'localStorage').then(function (data) {
      var taxes = data;
      $scope.taxList = [];
      _.forEach(taxes, function (tax) {
        if (tax.country_code === $scope.po.billing.address.country) {
          $scope.taxList.push(tax);
        }
      });

      _.forEach($scope.po.items, function(item){
        if (!item.foc_item)
          item.selectedItemTax = _.cloneDeep(globalFunc.findInArray($scope.taxList, 'code', item.taxes[0].code));

        if (!_.isEmpty(item.selectedItemTax)) {
          getItemTax(item);
        }
      });
      $scope.originalPO = _.cloneDeep($scope.po);
    });

    if($scope.po.status === 'AWAITING_CONFIRMATION')
      $state.params.status = 'SUBMITTED';
    else if ($scope.po.status === 'PARTIALLY_DELIVERED')
      $state.params.status = 'AWAITING_DELIVERY';
    else if ($scope.po.status === 'DELIVERED')
      $state.params.status = 'CLOSED';
    else if ($scope.po.status === 'FULLY_DELIVERED')
      $state.params.status = 'CLOSED';
    else if ($scope.po.status === 'DELIVERY_DATE_UNCONFIRMED')
      $state.params.status = 'SUBMITTED';

    var tabData = {
      tab: activateTab(),
      noTransition: true
    };

    //HACK: as the tabdata controller doesn't load in time and the the tabs will be deleted in the new design,
    //this is a work around to only load it after 300 milisecond
    // TODO: remove this with the new design, Ahmed Saleh (25/9/2017)
    $timeout(function() {
      $rootScope.$broadcast('loadSpecificTab', tabData);
    }, 300);

    $scope.options = {
      contact_persons: []
    };

    loadContactPersons($scope.po.billing.company);
    getStatusOptions();
    labelStatus();
    getErpStatus();
    
    // Support old format of delivery address in PO
    if (!!$scope.po.delivery.address && !!$scope.po.delivery.address.details && !$scope.po.delivery.address.line1) {
      $scope.po.delivery.address.line1 = $scope.po.delivery.address.details.line1;
      $scope.po.delivery.address.line2 = $scope.po.delivery.address.details.line2;
      $scope.po.delivery.address.line3 = $scope.po.delivery.address.details.line3;
      $scope.po.delivery.address.postal_code = $scope.po.delivery.address.details.postal_code;
      $scope.po.delivery.address.city = $scope.po.delivery.address.details.city;
      $scope.po.delivery.address.state = $scope.po.delivery.address.details.state;
      $scope.po.delivery.address.country = $scope.po.delivery.address.details.country;
    }

    //clean up old notes structure
    if(!!$scope.po.notes && _.isArray($scope.po.notes)){
      $scope.po.notes = $scope.po.notes[0];
    }

    $scope.isTenantSuperAdmin = !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN');
    $scope.isDeveloper = !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'DEVELOPER');
    checkFocItem();

    // This header is used only in v2 as a param to reuse the header component from consolidation
    if (!!$scope.po.deliver_to_contact_person) {
      var contactPersons = '';
      _.forEach($scope.po.deliver_to_contact_person, function (contactPerson) {
        if (!_.isEmpty(contactPersons)) {
          contactPersons = contactPersons + '/' + contactPerson;
        } else {
          contactPersons = contactPerson;
        }
      });
    }

    $scope.header = {
      company_info: $scope.po.billing.company,
      shadow_company: !!$scope.po.billing.shadow_company ? $scope.po.billing.shadow_company : $scope.po.billing.company,
      billing_info: $scope.po.billing.address,
      delivery_address: $scope.po.delivery.address,
      supplier: $scope.po.supplier,
      pr_creator: $scope.po.creator_info,
      pr_requestor: $scope.po.requestor,
      currency: $scope.po.currency,
      pr_references: [],
      payment_term: $scope.po.payment_terms,
      contact_person: !!$scope.po.deliver_to_contact_person ? contactPersons : undefined,
      remarks: $scope.po.remarks,
      needed_by_date: $scope.po.needed_at,
      title: $scope.po.title,
      is_po_v2: true,
      countdown_seconds: $scope.po.countdown_seconds,
      mostRecentDate: getMostRecentDate(),
      pc : $scope.po.pc
    };
    $scope.header.supplier.corresponding_address = $scope.po.items[0].supplier.corresponding_address;

    if (!!$scope.po.pr_numbers){
      $scope.po.pr_numbers.forEach(function (e, index){
        var prRefObject = {"number": e, "_id" : $scope.po.pr_ids[index]}
        if (!_.some($scope.header.pr_references, prRefObject))
          $scope.header.pr_references.push(prRefObject)
      })
    }
    else {
      $scope.header.pr_references.push({"number": $scope.po.pr_number, "_id" : $scope.po.originalPR})
    }

    var neededByDates = [];
    $scope.po.items.forEach(function (e){
      if (!neededByDates.includes($filter('date')(e.needed_by_date, 'dd MMM yyyy')))
        neededByDates.push($filter('date')(e.needed_by_date, 'dd MMM yyyy'));
    })
    getModesOfPurchase();
  }

  /**
   * check the creator or requestor or purchasing agent allow to cancel
   * @returns {boolean}
   */
  function checkCancelButton() {
    if ((!!$scope.po.creator_info && $scope.po.creator_info._id === $rootScope.currentUser._id) ||
        (!!$scope.po.requestor && $scope.po.requestor._id === $rootScope.currentUser._id) ||
        (!!$scope.po.pa_allowed_to_cancel_po && globalFunc.checkIfUserHasPARole($rootScope.currentUser))) {
      return true;
    }

    return false;
  }


  /**
   * SuperAdmin or Developer or PO requestor and PO Approver that has PO Manager role can view
   */
  function checkCancelButtonV2() {
    if (isSuperAdminOrDeveloper()) {
      return true
    }
    else {
      if ((_.has($scope.po.billing.expense_type, 'subtype') && ['GWO', 'SPK'].indexOf($scope.po.billing.expense_type.subtype) === -1) || !_.has($scope.po.billing.expense_type, 'subtype'))
      {
        return !!$scope.po.requestor && $scope.po.requestor._id === $rootScope.currentUser._id
        || (globalFunc.findRoleInRoleAssignments(
            profile.role_assignments,
            'PO Manager',
            3, //this is weight, 3 is company level
            !!$scope.header.company_info._id ? $scope.header.company_info._id : null
          )
          && globalFunc.findRoleInRoleAssignments(
              profile.role_assignments,
              'PO Approver',
              3, //this is weight, 3 is company level
              !!$scope.header.company_info._id ? $scope.header.company_info._id : null
            )
          )
      } else {
        return false
      }
    }
  }

  /**
   * This function is to disable receiving, reversing POs and to enable some feature such as resync PO
   * JDE and CMMS integration is disabled
   *
   * @returns {boolean}
   */
  function checkIntegrationSolution() {
    if (!!$scope.po.company_integration_solution) {
      var isJdeJdeIntegration = $scope.po.company_integration_solution === 'MB-JDE-JDE';
      var isCmmsIntegration = $scope.po.company_integration_solution === 'MB-CMMS-ESKER';

      return (isJdeJdeIntegration || isCmmsIntegration);
    }

    return false;
  }

  /**
   * This function is only for MB-JDE-JDE, to get ERP status and JDE PO number
   */
  function getErpStatus() {
    if (!!checkIntegrationSolution() && $scope.po.company_integration_solution === 'MB-JDE-JDE') {
      $scope.po.erpStatus = {};
      var defer = $q.defer();

      Orders.getErpStatus(
        {
          po_number: $scope.po.po_number
        }, function (resource) {
          var data = resource.content.data;
          $scope.po.erpStatus.message = data.message;
          $scope.po.erpStatus.status = 'erp-status-' + data.progress;
          $scope.po.erpStatus.erpOrderNumber = data.erpOrderNumber;
          defer.resolve(data);
        }, function (error) {
          defer.reject(error);
        }
      );
    }
  }

  function retryOutboundPo() {
    if (!!isIntegratedCompany && integrationSolution === 'MB-JDE-JDE') {
      var defer = $q.defer();

      Orders.retryOutboundPo(
        {
          po_id: $scope.po._id
        }, function (resource) {
          defer.resolve(resource);
          toastr.success('Request to retry succeeded.');
          $scope.po.erpStatus.message = 'Requested retry.';
          $scope.po.erpStatus.status = '';
        }, function () {
          toastr.error('Request to retry failed.')
        }
      )
    }
  }

  function pushPoToBifrost() {
    if (!!$scope.po.company_integration_solution) {

      Orders.pushPoToBifrost(
        {
          po_id: $scope.po._id
        }, function (resource) {
          toastr.success('Request to push po to bifrost succeeded.');
          getErpStatus();
        }, function () {
          toastr.error('Request to push po to bifrost failed.')
        }
      )
    }
  }

  // check for JDE integration. return boolean
  function checkJDEIntegration() {
    return (!!$scope.integrationSolution && $scope.integrationSolution === 'MB-JDE-JDE');
  }

  // Request bifrost to resync missing deliveries from JDE
  function requestResyncDelivery() {
    if (!!checkJDEIntegration()) {

      Orders.resyncDelivery(
        {
          po_number: $scope.po.po_number
        }, function (resource) {
          toastr.success('Request to resync delivery from JDE succeeded.');
          getErpStatus();
        }, function () {
          toastr.error('Request to resync delivery from JDE failed.')
        }
      )
    }
  }

  /**
   * Function to approve purchase order to move to next status
   */
  function approve() {
    var approvalId = $scope.po.approval_id;

    return approvalPurchaseOrder.post(
      {
        approval_id: approvalId,
        status: 'approve'
      },
      function () {
        toastr.success('Purchase order is approved.');
        HighlightId.setId($scope.po._id);
        $state.go('main.user.orders.manage', {status: 'all'});
      },
      function (error) {
        if (!!error) {
          globalFunc.objectErrorMessage(error);
        } else {
          toastr.error('Purchase order has error.');
        }
      }
    ).$promise;
  }

  /**
   * Function to reject purchase order to move to next status
   */
  function reject() {
    var approvalId = $scope.po.approval_id;

    return approvalPurchaseOrder.post(
      {
        approval_id: approvalId,
        status: 'reject'
      },
      {
        comment: $scope.reasonReject.status_reason
      },
      function () {
        toastr.success('Purchase order rejected.');
        HighlightId.setId($scope.po._id);
        $state.go('main.user.orders.manage', {status: 'all'});
      },
      function () {
        toastr.error('Purchase order has error.');
      }
    ).$promise;
  }

  function withdrawPo() {
    var approvalId = $scope.po.approval_id;

    approvalPurchaseOrder.post(
      {
        approval_id: approvalId,
        status: 'withdraw'
      },
      {
        comment: $scope.reasonReject.status_reason
      },
      function () {
        toastr.success('Purchase order Successfully withdrawn');
        HighlightId.setId($scope.po._id);
        $state.go('main.user.orders.manage', {status: 'all'});
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function canWithdraw() {
    var checkPoStatus = ['PENDING'].indexOf($scope.po.status) > -1;

    return (
      checkPoStatus &&
      (
        $scope.po.creator_info._id === $rootScope.currentUser._id ||
        (!!$scope.po.requestor && ($scope.po.requestor._id === $rootScope.currentUser._id))
      ));
  }

  function loadApprovalFlow() {
    $scope.approvals = [];
    var approvalId = $scope.po.approval_id;

    Approvals.get(
      {
        id: approvalId
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.approvals = resource.content.data;
          $scope.approvalFlowData = resource.content.data;
          globalFunc.getWaitingOnUser(resource.content.data, $scope.waitingOnUser);
        } else {
          $scope.approvals = [];
        }
      },
      function () {
        toastr.error('Error loading Approval Flow');
        $scope.approvals = [];
      });
  }

  function checkWaitingOn() {
    var find_user = _.find($scope.waitingOnUser, function (approver) {
      return (approver._id === profile._id);
    });

    if (find_user !== undefined) {
      return true;
    }
    return false;
  }

    /**
   * @param item
   * @param field
   * @returns {boolean}
   */
  function checkOthersValue(item, field) {
    var showField = false;
    if (!item.foc_item)
      showField = _.findIndex(item.other_charges, {'name': field}) < 0
    return showField;
  }

  function removeOthers(field, index){
    _.remove($scope.po.items[index].other_charges, function(item) {
      return item.name === field;
    });
  }

  function accordionCheck($event, item){
    if (!item.chargeAdded) {
      $event.stopPropagation();
    }
    else {
      item.chargeAdded = false;
    }
  }

  function addOthers(field, index){
    $scope.po.items[index].chargeAdded = true;
    $scope.po.items[index].other_charges.push({
      'name': field,
      'unit_price' : 0,
      'quantity': 1,
      'amount': 0,
      'discount': {
        'is_percentage': false,
        'amount': 0
      },
      'tax': _.find($scope.taxData, function(tax) { return tax.code === 'NT0'; }),
      'deletable': true
    });
  }

  function isPOIntegrated() {
    return !!$scope.po.company_integration_solution;
  }

  // allow grn actions if v2 and the company is 'currently' integrated
  function allowGRNActions() {
    if ($scope.isV2Enabled && !!$scope.po.is_integrated_company) {
      return false;
    }

    return true;
  }
  /**
   * get the earliest date between PO header and items for Needed at date
   * @returns {number}
   */
  function getMostRecentDate(){
    var earliestDate = new Date('2099').getTime()/1000;
    var defaultMaxDate = _.cloneDeep(earliestDate);

    if(!!$scope.po.needed_by_date){
      earliestDate = (earliestDate < $scope.po.needed_at) ? $scope.po.needed_at : earliestDate;
    }

    _.forEach($scope.po.items, function (item) {
      _.forEach(item.others, function (other) {
        if(other.type === 'needed_at')
          earliestDate = (earliestDate < other.date) ? other.date : earliestDate;
      });
    });

    earliestDate = (earliestDate === defaultMaxDate) ? undefined : earliestDate;

    return earliestDate;
  }

  // GenP Requirements

  /**
   * Function when edit PO. User can update unit price for item and other charges's unit price only
   */
  function edit() {
    $scope.editPO = true;
  }

  /**
   * Checking Manager Role for edit and update Pending PO
   */
  function checkManagerRole() {
    return  !!globalFunc.findRoleInRoleAssignments(
      $rootScope.currentUser.role_assignments,
      'PO Manager',
      3, // this is weight, 3 is company
      !!$scope.header.company_info._id ? $scope.header.company_info._id : null
    );
  }

  function onSelectOtherChargeTax(poId, itemId, taxObj, field, name){
    updateOtherChargeFields(poId, itemId, taxObj._id, field, name);
  }

  function prepareData() {
    var anyError = false;
    var data = _.map($scope.po.items, function (item) {

      _.forEach(item.others, function (other) {
        if (other.type === 'needed_at') {
          $scope.needed_by_date = globalFunc.convertDateToTimestamp(other.date);
        }
      });

      var other_charges = _.cloneDeep(item.other_charges);
      _.forEach(other_charges, function(charge){
        if(!!charge.tax && !!charge.tax._id){
          charge.tax_id = charge.tax._id;
        }

        // TODO: Add backend validations once calculation issues are resolved
        if (!(charge.amount > 0)){
          anyError = true;
          toastr.error(charge.name + " amount cannot be empty");
        }

        charge.total_amount = charge.totalAmount;
        delete charge.tax;
        delete charge.process_qty;
        delete charge.revised_unit_price;
      });

      return {
        po_id : $scope.po._id,
        item_id : item._id,
        item_uuid: item.item.uuid,
        process_qty: item.quantity,
        consolidation_item_line_uuid : item.consolidation_item_line_uuid,
        revised_unit_price : item.unit_price,
        discount: item.discount_amount || 0,  // passes 0 if falsy
        discount_amount: item.discount_amount || 0, // passes 0 if falsy
        discount_percentage: item.discount_percentage || undefined,
        tax : item.selectedItemTax,
        other_charges : other_charges,
        foc_item: item.foc_item,
        currency: item.currency,
        needed_by_date : $scope.needed_by_date
      }
    });

    if (!anyError){
      return {
        items: data,
        company_code: $scope.po.billing.company.code
      };
    }
  }

  /**
   * call api, update line item
   */
  function updateItemFields() {
    var data = prepareData();
    if (!_.isEmpty(data)){
      Orders.updateItemDetails(
        {
          id: $scope.po._id
        },
        data,
        function (resource) {
          if (!!resource.content && !!resource.content.data) {
            toastr.success('PO item is updated successfully');
            $scope.originalPO = _.cloneDeep($scope.po);
            $scope.editPO = false;
            $rootScope.$broadcast('refreshComments');
          }
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      )
    }
  }

  function validateInput(item) {
    var stringAmount = item.discount_amount.toString()
    var percentageCount = (stringAmount.match(/%/g) || []).length;
    var dotCount = (stringAmount.match(/\./g) || []).length;

    if (dotCount > 1 || percentageCount > 1) {
      toastr.error(lang.validation.percentage.invalid);
      item.discount_amount = undefined;
      return false;
    }

    if (!$scope.po.currency.allow_decimal) {
      if (percentageCount === 0 && dotCount > 0) {
        item.discount_amount = undefined;
        toastr.error(lang.validation.decimal.notAllowed);
        return false;
      }
    }

    return true;
  }

  function updatePriceFields(item) {
    if (validateInput(item)) {
      if (String(item.discount_amount).indexOf('%') > -1) {
        var discountPercentage = Number(item.discount_amount.replace('%', ''));

        // truncate discount % to 2 d.p
        var power = Math.pow(10, 2);
        item.discount_percentage = Math.floor(discountPercentage  * power) / power;

        item.discount_amount = math.round((item.discount_percentage / 100) * (item.unit_price * item.quantity), 2);
      } else {
        item.discount_percentage = 0;
      }

      item.discount_amount = Number(item.discount_amount);
    }
  }

  function updateOtherChargePriceFields(charge) {
    if (validateInput(charge)) {
      if (String(charge.discount.amount).indexOf('%') > -1) {
        var discountPercentage = Number(charge.discount.amount.replace('%', ''));

        // truncate discount % to 2 d.p
        var power = Math.pow(10, 2);
        charge.discount_percentage = Math.floor(discountPercentage  * power) / power;

        var calculatedDiscount = (charge.discount_percentage/100) * charge.amount;
        charge.discount.amount = math.round(Number(parseFloat(calculatedDiscount).toPrecision(12)), 2);
      } else {
        charge.discount_percentage = 0;
      }

      charge.discount.amount = Number(charge.discount.amount);
    }
  }

  function getItemTax(item) {
    if (!_.isEmpty(item.taxes)) {
      if (!!item.selectedItemTax) {
        var tax = Number(item.selectedItemTax.rate) / 100;
      } else {
        var tax = Number(item.taxes[0].rate) / 100;
      }

      var calculatedItemTax = roundToTwo((item.unit_price * item.quantity - (roundToTwo(item.discount_amount) || 0))*tax);
      item.selectedItemTax.calculated = calculatedItemTax;
    }
  }

  function getOtherChargeTax(charge) {
    if (!_.isEmpty(charge.tax)) {
      var tax = Number(charge.tax.rate) / 100;

      var calculatedItemTax = roundToTwo((charge.amount - (roundToTwo(charge.discount.amount) || 0))*tax);

      charge.tax.amount = calculatedItemTax;
    }
  }

  /**
   * get item discount
   */
  function getItemDiscount(item) {
    var precision = 2;
    if (!!$scope.po.currency.hasOwnProperty('allow_decimal') && !$scope.po.currency.allow_decimal){
      precision = 0;
    }

    if (!!item.discount_percentage){
      // truncate discount % to 2 d.p
      var power = Math.pow(10, 2);
      item.discount_percentage = Math.floor(item.discount_percentage  * power) / power;

      item.discount_amount = math.round((item.discount_percentage/100) * (item.quantity*item.unit_price), precision);
    } else {
      item.discount_amount = !!item.discount ? item.discount[0].amount : 0;
    }
  }

  function getOtherChargeDiscount(charge){
    var precision = 2;
    if (!!$scope.po.currency.hasOwnProperty('allow_decimal') && !$scope.po.currency.allow_decimal){
      precision = 0;
    }

    if (!!charge.discount_percentage && !_.isEmpty(charge.discount)){
      // truncate discount % to 2 d.p
      var power = Math.pow(10, 2);
      charge.discount_percentage = Math.floor(charge.discount_percentage  * power) / power;

      var calculatedDiscount = (charge.discount_percentage/100) * charge.amount;
      charge.discount.amount = math.round(Number(parseFloat(calculatedDiscount).toPrecision(12)), precision);
    } else {
      charge.discount = !!charge.discount ? charge.discount : {amount: 0};
    }
  }

  function getLineItemTotal(item) {
    $scope.lineItemTotal = 0;
    var total = roundToTwo(item.unit_price * item.quantity);
    if (!!item.selectedItemTax) {
      var tax = Number(item.selectedItemTax.rate) / 100;
    } else {
      var taxRate = (!!item.taxes[0] && !!item.taxes[0].rate) ? item.taxes[0].rate : 0.0;
      var tax = Number(taxRate) / 100;
    }
    $scope.lineItemTotal += total - roundToTwo(item.discount_amount) +
      ((total - item.discount_amount) * tax);

    return $scope.lineItemTotal;
  }

  function recalculateOtherChargesGroup(item){
    if (!_.isEmpty(item.other_charges_group)) {
      _.forEach(item.other_charges, function(e) {
        var charge = _.find(item.other_charges_group.other_charges, function(charge){
          return charge.name.toUpperCase() === e.name.toUpperCase();
        });

        // following backend calculation at RequisitionItemV2.php, setOtherChargesGroup()
        if (!!charge && charge.calculation_method.code === 'PERCENT_OF_EXTENDED_COST') {
          var amount = roundingHelper(item, item.unit_price * item.quantity);
          var chargePercentage = charge.percentage / 100;
          e.amount = roundingHelper(item, amount * chargePercentage);
        }

        // idk why the function name has a get, it does calculations
        getOtherChargeDiscount(e);
        getOtherChargeTax(e);
      });
    }
  }

  function roundingHelper(item, number){
    var allowDecimal = item.currency.allow_decimal;
    if (!!allowDecimal) {
      number = roundToTwo(number);
    } else {
      number = Math.round(number)
    }

    return number;
  }

  function loadCompanyTolerance() {
    if(!!$scope.po.document_rules && $scope.po.document_rules.pr_version === 'v2') {
      toleranceLimit.get(
        {id: $scope.po.billing.company._id},
        function (resource) {
          if (!!resource.content.data.toleranceLimit && !!resource.content.data.toleranceLimit.is_active) {
            $scope.companyTolerance = resource.content.data.toleranceLimit;
          }
        },
        function (error) {
          if (!!error.data && !!error.data.content) {
            toastr.error(error.data.content.error);
          }
        }
      );
    }
  }

  function checkIntegrationReference() {
    var isShowable = false;
    if ((!!$scope.po.integration_reference && !!$scope.po.erp_type) && $scope.po.erp_type === 'sap') {
      isShowable = true;
    }

    return isShowable
  }

  function roundToTwo(num) {
    return +(Math.round(num + "e+2")  + "e-2");
  }

  /**
   * Check if the user is currently eligible to resend PR Approval Email
   */
  function canResendPoEmail() {
    return $scope.po.status.toLowerCase() === 'pending' && 
           (globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN') ||
            globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'ADMIN') ||
            isPOCreator());
  }

  /**
   * Send an email to the current waiting on PO Approver
   */
  function resendPoEmail() {
    var email_text = "Approval email will be resent to the following approvers\n\n";
    $scope.waitingOnUser.forEach(function (currentVal) {
      if (currentVal.is_active)
        email_text += currentVal.display_name + "\n";
    });

    swal({
      title: "Email re-sent",
      text: email_text,
      type: "success",
      showCancelButton: false,
      confirmButtonColor: "#1ab394",
      confirmButtonText: "Ok",
      timer: 5000,
      closeOnConfirm: true
    }, function (isConfirm) {
      if (isConfirm) {
        $rootScope.$broadcast('refreshComments');
      }
    });
    return Orders.resendPendingPoEmail(
      {
        id: $scope.po._id
      }
    ).$promise;
  }

  function isPOCreator() {
    return ($scope.currentUser._id === $scope.po.creator_info._id);
  }

  function getModesOfPurchase() {
    companyModeOfPurchase.get(
      {
        id: $scope.header.company_info._id
      },
      function(resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.modesOfPurchase = resource.content.data;
        }
      },
      function(error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function isPOCreatorModeOfPurchase(modesOfPurchase) {
    var isPoCreator = false;
    if (!!modesOfPurchase && modesOfPurchase.length > 0) {
      _.forEach(modesOfPurchase, function (modeOfPurchase) {
        _.forEach(modeOfPurchase.po_creators, function (poCreator) {
          if (poCreator._id === $scope.currentUser._id)
            isPoCreator = true;
        })
      })
    }
    return isPoCreator;
  }

  // End of GenP Requirements

  initialize();
}

OrdersDetailCtrl.$inject = ['$scope', '$q', 'globalFunc', '$state', '$location', '$anchorScroll',
  'order', 'pathConstants', 'Orders', 'profile', '$rootScope',
  'toastr', 'purchaseRequisitionsServices', 'HighlightId', 'POSubscriptionCheck', 'getPOPDFHash',
  '$filter', 'notificationSubscriber', 'POComments', 'UserPermissions', '$timeout', '$uibModal',
  'isIntegratedCompany', 'integrationSolution', 'Approvals', 'approvalPurchaseOrder', 'loadCacheableData',
  'toleranceLimit', 'movePdfToInvalid', 'companyModeOfPurchase'
];

angular.module('metabuyer')
  .controller('OrdersDetailCtrl', OrdersDetailCtrl);
