'use strict';

function createTenderBtnCtrl($scope, $rootScope, $state, globalFunc, UserPermissions, tenderService) {

  function createTender() {
    $scope.title = 'Loading ...';
    $scope.disabled = true;

    tenderService.create(
      function(resource) {
        $state.go('main.user.tender.detail', { id: resource.content.data._id, PR: resource.content });
        $scope.title = 'Create Tender';
        $scope.disabled = false;
      },
      function(error) {
        globalFunc.objectErrorMessage(error);
        $scope.title = 'Create Tender';
        $scope.disabled = false;
      }
    );

  }

  /**
   * This function return a boolean to show or hiding the create Tender button
   * @returns {boolean}
   */
  function showCreateButton() {
    if ($state.current.name.indexOf('tender') < 0)
      return false;

    if (!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Tender Requester'))
      return false;

    return (!!$rootScope.isV2Enabled || !!UserPermissions.isDeveloper($rootScope.currentUser));
  }

  $scope.disabled = false;
  $scope.title = 'Create Tender';
  $scope.createTender = createTender;
  $scope.showCreateButton = showCreateButton;
}

createTenderBtnCtrl.$inject = ['$scope', '$rootScope', '$state', 'globalFunc', 'UserPermissions', 'tenderService'];

angular
  .module('metabuyer')
  .directive('createTenderBtn', function() {
    return {
      restrict: 'E',
      templateUrl: 'components/tenderComponents/createTenderBtn/createTenderBtnTemplate.html',
      scope: {},
      controller: 'createTenderBtnCtrl'
    };
  })
  .controller('createTenderBtnCtrl', createTenderBtnCtrl);
