'use strict';

angular
  .module('metabuyer')
  .controller('UserActivityCtrl', UserActivityCtrl);

/**
 * @name UserActivityCtrl
 * @desc Controller for User Activity
 */
function UserActivityCtrl($scope){

}
UserActivityCtrl.$injecct = ['$scope'];