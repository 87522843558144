'use strict';
angular
  .module('metabuyer')
  .directive('addContact',function($uibModal, toastr) {
    return{
      restrict: 'A',
      scope: {
        addedContact: '&'
      },
      link: function(scope, element, attrs) {

        var panel = function () {
          var modalInstance = $uibModal.open({
            templateUrl: 'components/addContact/addContactTemplate.html',
            controller: 'addContactCtrl',
            backdrop: 'static',
            keyboard: false,
            scope: scope
          });
        };
        //loading the panel
        scope.showAddContact = function () {
          panel();
        };

        element.bind('click', function($event){
          scope.showAddContact();
        });
      }
    };
  });
