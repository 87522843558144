'use strict';

/**
 * Controller for budget v2 listing
 * @description
 * Contains functions for handling showing table columns and viewing details
 *
 * @param $scope
 * @param dataList
 * @param $stateParams
 * @param $state
 * @param $rootScope
 * @param budgets
 * @param toastr
 * @param HighlightId
 * @param $window
 *
 * @function viewBudgetDetail
 * Function to navigate to budget details by id state
 *
 * @function showColumn
 * Function to display selected available columns
 *
 * @author Ameer Asraf aka Jamal <ameerasraf_92@yahoo.com>
 * @copyright 2020 Metacloud Sdn. Bhd.
 */

function budgetListCtrlV2($scope, dataList, $stateParams, $state, $rootScope, budgets, toastr, HighlightId, $window, globalFunc, companyList, $uibModal) {

  $scope.currentUser = $rootScope.currentUser || {_id: null};
  $scope.companyList = companyList.data;

  $scope.viewBudgetDetail = viewBudgetDetail;
  $scope.showColumn = showColumn;
  $scope.canDelete = canDelete;
  $scope.deleteBudget = deleteBudget;
  $scope.checkWaitingOnUser = checkWaitingOnUser;
  $scope.multiSelectPendingItems = multiSelectPendingItems;
  $scope.getSelectedBudgets = getSelectedBudgets;
  $scope.isDeveloper = !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'DEVELOPER');
  $scope.tabSelection = tabSelection;
  $scope.listItem = listItem;
  $scope.getYearList = getYearList;
  $scope.isTenantAdmin = !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN');
  $scope.isAdmin = !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Admin');
  $scope.exportBudgetAccountCode = exportBudgetAccountCode;
  $scope.showDraftBudgetAccountCodeButton = showDraftBudgetAccountCodeButton;

  function getYearList(years){
    var yearList = '';
    _.forEach(years, function(year, index) {
      yearList += year.value + (index !== years.length-1 ? ', ' : '');
    });

    return yearList;
  }

  function listItem (list, property) {
    return _.unique(_.map(list, function (item) {
      return item[property];
    })).toString();
  }

  function prepareImportParams() {
    $scope.params = {
        'class': 'Metabuyer\\Budget\\Models\\Budget'
    };
    $scope.massAccountCodeParams = {
      'class': 'Metabuyer\\Budget\\Models\\BudgetMassAccountCode',
    };

    if ($scope.status === 'draft') {
      $scope.massAccountCodeParams['additional_params[status]'] = 4;
    }
  }

  /**
   * function to navigate between the sub tabs
   * @param tab
   */
  function tabSelection(tab) {
    switch (tab) {
      case 'all': {
        $scope.selectedListTab = tab;
        $state.go('main.user.budgetV2.manage', {
          status: $scope.status,
          tab: tab,
          extraFilterKey: null,
          extraFilterValue: null,
          page: null
        });
        break;
      }
      case 'yours': {
        $scope.selectedListTab = tab;
        $state.go('main.user.budgetV2.manage', {
          status: $scope.status,
          tab: tab,
          extraFilterKey: 'criteria[creator_info|_id][]',
          extraFilterValue: $rootScope.currentUser._id,
          page: null
        });
        break;
      }
      case 'waiting': {
        $scope.selectedListTab = tab;
        $state.go('main.user.budgetV2.manage', {
          status: $scope.status,
          tab: tab,
          extraFilterKey: 'criteria[waiting_on][]',
          extraFilterValue: $rootScope.currentUser._id,
          page: null
        });
        break;
      }
    }
  }

  function showColumn(id) {
    var i;
    var columns = $scope.columns;
    for (i in columns) {
      if(columns.hasOwnProperty(i)) {
        if (id === columns[i].id) {
          if(!!columns[i].conditional) {
            if($scope.status === columns[i].condition)
              return columns[i].selected;
            if(columns[i].condition instanceof Array) {
              var j;
              var columnCondition = $scope.columns[i].condition;
              for(j in columnCondition) {
                if(columnCondition.hasOwnProperty(j)) {
                  if($scope.status === columnCondition[j]) {
                    return columns[i].selected;
                  }
                }
              }
            }
          }
          else
            return columns[i].selected;
        }
      }
    }
  }

  function viewBudgetDetail(data) {
    $state.go('main.user.budgetV2.details' , {id : data._id});
  }

  /**
   * Check if delete budget action can be done
   *
   * @param {string} creator_info Creator id
   * @param {string} status       Status of the budget
   */
  function canDelete(creator_info, status, company) {
    var checkBudgetStatus = (status === 'draft');
    var isApprover = (creator_info === $scope.currentUser._id);

    // check app location
    var selectedCompany = _.find($scope.companyList, function (e){
      return e.code === company.code
    });

    var isCorrectAppLocation = !!selectedCompany.budget_setting.offline_budget === !!$rootScope.isOffline;


    return (checkBudgetStatus && isApprover && isCorrectAppLocation);
  }

  /**
   * To confirm if to delete the budget
   *
   * @param {string} budgetId Budget Id
   */
  function deleteBudget(budgetId) {
    swal({
      title: 'Confirm delete budget?',
      text: 'This action will remove the budget from draft.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Confirm',
      closeOnConfirm: true
    }, function (isConfirm) {
      if (isConfirm) {
        confirmDeleteBudget(budgetId);
      }
    });
  }

  /**
   * Delete budget by id
   *
   * @param {string} budgetId Budget Id
   */
  function confirmDeleteBudget(budgetId) {

    budgets.delete(
      {
        id: budgetId
      },
      function () {
        toastr.success('Budget draft deleted.');
        $state.reload();
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  /**
   * return if the user is part of the waiting on
   * @param {array} budget Budget data
   * @returns {boolean}
   */
  function checkWaitingOnUser(budget) {
    if (budget.status === 'pending') {
      return _.findIndex(budget.waiting_on, {'_id': $rootScope.currentUser._id}) > -1;
    } else {
      return false;
    }
  }

  /**
   *  Select/deselect all the items in listing for approval action
   * @param {string} status Status of the current tab
   */
  function multiSelectPendingItems(status) {
    _.forEach($scope.dataList, function (budget) {
      if (checkWaitingOnUser(budget)) {
        budget.selected = status;
      }
    });
  }

  /**
   * Return selected budgets
   * @returns {array}
   */
  function getSelectedBudgets() {
    var selectedBudgets = [];
    _.forEach($scope.dataList, function (budget) {
      if (!!budget.selected) {
        selectedBudgets.push(budget);
      }
    });

    return selectedBudgets;
  }

  function exportBudgetAccountCode() {
    var modalInstance = $uibModal.open({
      templateUrl: 'app/budgetManagementV2/export/exportBudgetAccountCode.html',
      controller: 'exportBudgetAccountCodeCtrl',
      backdrop: 'static',
      keyboard: false,
      scope: $scope
    });
  }

  function showDraftBudgetAccountCodeButton(status) {
    var isBudgetRequester = globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Budget Requester') ||
      globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Company Budget Requester');

    return (($scope.isAdmin || $scope.isTenantAdmin) && status === 'approved') || (isBudgetRequester && status === 'draft');
  }

  function initialize() {
    if (!!HighlightId.getId() && HighlightId.getId() !== undefined && HighlightId.getId().length) {

      $scope.highlightId = HighlightId.getId();

      if ($scope.highlightId !== null && $scope.highlightId !== undefined) {

        //scroll the window to top
        $window.scroll(0, 0);
      }

      //clear current id
      HighlightId.setId(null);
    }

    $scope.$parent.status = $stateParams.status;
    $scope.status = $stateParams.status;
    $scope.dataList = dataList.data;
    $scope.meta = dataList.meta;
    $scope.budgetManagementService = dataList;
    $scope.storageKey = 'budget-management-selected-columns';
    $scope.params = 'budget';

    $scope.selectedListTab = $stateParams.tab;
    if(!$scope.selectedListTab && $scope.status === 'pending'){
      $scope.selectedListTab = 'waiting';
      tabSelection($scope.selectedListTab);
    }
    prepareImportParams();
  }

  initialize();

}

budgetListCtrlV2.$inject = ['$scope', 'dataList', '$stateParams', '$state', '$rootScope', 'budgets', 'toastr',
  'HighlightId', '$window', 'globalFunc', 'companyList', '$uibModal'];

angular.module('metabuyer')
  .controller('budgetListCtrlV2', budgetListCtrlV2);
