'use strict';

angular.module('metabuyer.services.azureAdServices', ['ngResource'])
  .factory('azureAdLogin', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.azureAd, {}, {
        'get': {
          method: 'GET',
          url: pathConstants.apiUrls.azureAd.login
        },
        'post': {
          method: 'POST',
          url: pathConstants.apiUrls.azureAd.callback
        },
      }
    );
  })
  .factory('checkSocialLogin', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.azureAd, {}, {
        'post': {
          method: 'POST',
          url: pathConstants.apiUrls.azureAd.checkSocialLogin
        },
      }
    );
  });
