'use strict';

function helpModalCtrl($scope, $uibModalInstance) {
  $scope.close = close;

  function close() {
    $uibModalInstance.close(false);
  }
}

helpModalCtrl.$inject = [
  '$scope', '$uibModalInstance'
];

angular
  .module('metabuyer')
  .controller('helpModalCtrl', helpModalCtrl);
