'use strict';

function SupplierWebEmailLogsCtrl($scope, $state) {

  $scope.tabData = [
    {
      heading: 'Email Logs',
      route: 'main.user.suppliersWebEmailLog.manage',
      key: 'emails',
      params: {
        status: '',
        cursor: null,
        filter: null,
        query: null,
        type: null
      }
    }
  ];

  function initialize() {
    if (!!$state.params && !!$state.params.query) {
      $scope.searchText = $state.params.query;
    }
    else {
      $scope.searchText = '';
    }

    $scope.searchPlaceholder = 'Search Email';
    $scope.searchState = 'main.user.suppliersWebEmailLog.manage';
    $scope.parentState = 'main.user.suppliersWebEmailLog.manage';
    $scope.parentStateParams = $state.params;
  }

  $scope.columns = [
    {id: 'receiver_email', label: 'Receiver Email'},
    {id: 'subject', label: 'Subject'},
    {id: 'created_at', label: 'Email sent', unsearchable: true},
    {id: 'updated_at', label: 'Email updated', unsearchable: true},
    {id: 'status', label: 'Status', unsearchable: true}
  ];

  initialize();

  $scope.$emit('pageTitleChanged', 'Email Logs');
}

SupplierWebEmailLogsCtrl.$inject = ['$scope', '$state'];

angular.module('metabuyer')
  .controller('SupplierWebEmailLogsCtrl', SupplierWebEmailLogsCtrl);
