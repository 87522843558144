'use strict';
angular
  .module('metabuyer')
  .directive('withdraw',function($uibModal, Orders, toastr) {
    return{
      restrict: 'A',
      scope: true,
      link: function(scope, element, attrs) {
        var panel = function () {
          var modalInstance = $uibModal.open({
            templateUrl: 'components/withdraw/withdrawTemplate.html',
            backdrop: 'static',
            keyboard: false,
            controller: 'WithdrawCtrl',
            scope: scope
          });
        };

        //loading the panel
        scope.showWithdraw = function () {
          panel();
        };
      }
    };
  });
