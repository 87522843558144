'use strict';
angular
  .module('metabuyer')
  .directive('monitorDate',function() {
    return{
      restrict: 'A',
      scope: {
        monitor_date: "=monitorDate",
        onChangedDate: "&"
      },
      link: function(scope, element, attrs) {

        var is_first_called = true;
        scope.$watch('monitor_date', function(new_value, old_value){
          if(is_first_called){
            is_first_called = false;
            return;
          }

          scope.onChangedDate();
        });
      }
    };
  });
