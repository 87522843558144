'use strict';
/**
 * @name priceComparisonCtrl
 *
 * @description
 *
 * Main controller for the price comparison State
 *
 *
 * @author Ameer Jamal <ameerasraf.works@gmail.com>
 * @copyright Sunway Metacloud &copy; 2021
 */

function priceComparisonCtrl($scope, $state, globalFunc, $rootScope) {
  var tempState = _.cloneDeep($state.params);

    $scope.tabData = [
      {
        heading: 'All',
        route: 'main.user.priceComparison.manage',
        key: 'all',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'all',
          tab: 'all',
          cursor: null
        })
      },
      {
        heading: 'Draft',
        route: 'main.user.priceComparison.manage',
        key: 'draft',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'draft',
          tab: 'all',
          cursor: null
        })
      },
      {
        heading: 'Pending Approval',
        route: 'main.user.priceComparison.manage',
        key: 'pending',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'pending',
          tab: 'waiting',
          extraFilterKey: 'criteria[waiting_on][]',
          extraFilterValue: $rootScope.currentUser._id,
          cursor: null
        })
      },
      {
        heading: 'Approved',
        route: 'main.user.priceComparison.manage',
        key: 'approved',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'approved',
          tab: 'all',
          cursor: null
        })
      }
    ];

    $scope.tabData.more = [
      {
        heading: 'Withdrawn',
        route: 'main.user.priceComparison.manage',
        key: 'withdrawn',
        type: 'more',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'withdrawn',
          tab: null,
          cursor: null
        })
      },
      {
        heading: 'Rejected',
        route: 'main.user.priceComparison.manage',
        key: 'rejected',
        type: 'more',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'rejected',
          tab: null,
          cursor: null
        })
      },
      {
        heading: 'Deleted',
        route: 'main.user.priceComparison.manage',
        key: 'deleted',
        type: 'more',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'deleted',
          tab: null,
          cursor: null
        })
      }
    ];

  function initialize() {
    $scope.$emit('pageTitleChanged', 'Price Comparison');
    if (!!$state.params && !!$state.params.query) {
      $scope.searchText = $state.params.query;
    }
    else {
      $scope.searchText = '';
    }
    if (!!$state.params && !!$state.params.status) {
      $scope.status = $state.params.status;

      //set the default tab on refreshing
      var tempIndex = _.findIndex($scope.tabData, function(tab){return tab.key === $state.params.status.toLowerCase()});
      if(tempIndex < 0 && !$rootScope.isMobileMode) {
        tempIndex = _.findIndex($scope.tabData.more, function (tab) {
          return tab.key === $state.params.status.toLowerCase()
        });
        if(!!tempIndex)
          $scope.activatedTab = $scope.tabData.more[tempIndex];
      }
      else
        $scope.activatedTab = $scope.tabData[tempIndex];
    }
    if (!!$state.params && !!$state.params.filter) {
      $scope.searchFilter = $state.params.filter;
    }

    $scope.searchPlaceholder = 'Search Price Comparison';
    $scope.searchState = 'main.user.priceComparison.manage';
    $scope.parentState = 'main.user.priceComparison.manage';
    $scope.parentStateParams = $state.params;
  }

    $scope.columns = [
      {id: 'pc_number', label: 'PRICE COMPARISON #'},
      {id: 'title', label: 'TITLE'},
      {id: 'pr_info|pr_reference', label: 'PR#'},
      {
        id: 'waiting_on',
        label: 'WAITING ON',
        conditional: true,
        condition: ['pending'],
        unsortable: true,
        unsearchable: true
      },
      {id: 'grand_total', label: 'AMOUNT', unsearchable: true },
      {id: 'currency|code', label: 'CURRENCY', unsearchable: true},
      {id: 'closed_at', label: 'APPROVED DATE', conditional: true, condition: ['approved'], unsearchable: true},
      {
        id: 'submitted_at',
        label: 'SUBMITTED DATE',
        conditional: true,
        condition: ['pending', 'approved'],
        type: 'date',
        unsearchable: true
      },
      {
        id: 'progress',
        label: 'PROGRESS',
        conditional: true,
        condition: ['pending'],
        unsortable: true,
        unsearchable: true
      },
      {id: 'item_comparisons|supplier|basic_info|descr', label: 'SUPPLIERS'},
      {id: 'pr_info|shadow_company|descr', label: 'BILLING COMPANY'},
      {id: 'pr_info|company|descr', label: 'COMPANY'},
      {id: 'pr_info|expense_type|descr', label: 'EXPENSE TYPE', unsearchable: true},
      {id: 'creator_info|display_name', label: 'PC CREATOR'},
      {id: 'pr_requestor|display_name', label: 'PR REQUESTOR'},
       {
        id: 'created_at',
        label: 'CREATED AT',
        conditional: true,
        condition: ['pending', 'approved', 'rejected'],
        type: 'date',
        unsearchable: true
      },
      {
        id: 'updated_at',
        label: 'UPDATED AT',
        conditional: true,
        condition: ['pending','approved', 'rejected'],
        type: 'date',
        unsearchable: true
      },
      {id: 'status', label: 'STATUS', unsearchable: true},
    ];

  $scope.$on('$stateChangeStart', function (event, toState, toParams) {
    if (!!toParams.status) {
      $scope.parentStateParams = toParams;
      $scope.status = toParams.status;
    }
  });

  initialize();
}
priceComparisonCtrl.$inject = ['$scope', '$state', 'globalFunc', '$rootScope'];

angular
  .module('metabuyer')
  .controller('priceComparisonCtrl', priceComparisonCtrl);
