'use strict';

function bifrostMappingMainCtrl($scope, $state) {
  $scope.$emit('pageTitleChanged', 'Bifrost Mapping');

  var taxMapping = {
    heading: 'Tax',
    route: 'main.user.bifrostMapping.tax',
    key: 'taxMapping',
    id: 'taxTab'
  };

  var companyMapping = {
    heading: 'Company',
    route: 'main.user.bifrostMapping.company',
    key: 'companyMapping',
    id: 'companyTab'
  };

  var paymentTermMapping = {
    heading: 'Payment Term',
    route: 'main.user.bifrostMapping.paymentTerm',
    key: 'paymentTermMapping',
    id: 'paymentTermTab'
  };

  var uomMapping = {
    heading: 'UOM',
    route: 'main.user.bifrostMapping.uom',
    key: 'uomMapping',
    id: 'uomTab'
  };

  var supplierMapping = {
    heading: 'Supplier',
    route: 'main.user.bifrostMapping.supplier',
    key: 'supplierMapping',
    id: 'supplierTab'
  };

  var costCenterMapping = {
    heading: 'Cost Center',
    route: 'main.user.bifrostMapping.costCenter',
    key: 'costCenterMapping',
    id: 'costCenterTab'
  };

  var purchaseGroupMapping = {
    heading: 'Purchase Group',
    route: 'main.user.bifrostMapping.purchaseGroup',
    key: 'purchaseGroupMapping',
    id: 'purchaseGroupTab'
  };

  var purchaseOrganizationMapping = {
    heading: 'Purchase Organization',
    route: 'main.user.bifrostMapping.purchaseOrganization',
    key: 'purchaseOrganizationMapping',
    id: 'purchaseOrganizationTab'
  };

  var companyCodeMapping = {
    heading: 'Company Code',
    route: 'main.user.bifrostMapping.companyCode',
    key: 'companyCodeMapping',
    id: 'companyCodeTab'
  };

  var companyAccountCodesMapping = {
    heading: 'Company Account Codes',
    route: 'main.user.bifrostMapping.companyAccountCodes',
    key: 'companyAccountCodesMapping',
    id: 'companyAccountCodesTab'
  };

  var plantMapping = {
    heading: 'Plant',
    route: 'main.user.bifrostMapping.plant',
    key: 'plantMapping',
    id: 'plantTab'
  };

  var focItemsMapping = {
    heading: 'FOC Items',
    route: 'main.user.bifrostMapping.focItems',
    key: 'focItems',
    id: 'focItemsTab'
  };

  var budgetOwnerMapping = {
    heading: 'Budget Owner',
    route: 'main.user.bifrostMapping.mappingBudgetOwner',
    key: 'budgetOwnerMapping',
    id: 'budgetOwnerTab'
  };

  // Column table for tabbing
  var taxColumns = [
    {id: 'mb_tax_code', label: 'MB Tax Code', unsearchable: true},
    {id: 'code', label: 'Tax Code'},
    {id: 'descr', label: 'Description'},
    {id: 'country_code', label: 'Country Code'},
    {id: 'environment_code', label: 'Environment Code', unsearchable: true},
    {id: 'delete_button', label: '', unsortable: true, conditional: true, unsearchable: true, unSelectable: true}
  ];

  var companyColumns = [
    {id: 'code', label: 'Company Code'},
    {id: 'mb_company_code', label: 'MB Company Code', unsearchable: true},
    {id: 'esker_code', label: 'Esker Code'},
    {id: 'registration_no', label: 'Registration No'},
    {id: 'descr', label: 'Company Name'},
    {id: 'sic_code', label: 'SIC Code'},
    {id: 'delete_button', label: '', unsortable: true, conditional: true, unsearchable: true, unSelectable: true}
  ];

  var paymentTermColumns = [
    {id: 'code', label: 'Payment Term Code'},
    {id: 'mb_payment_term_code', label: 'MB Payment Term Code', unsearchable: true},
    {id: 'company_object', label: 'Company Code', unsearchable: true},
    {id: 'descr', label: 'Description'},
    {id: 'environment_code', label: 'Environment Code', unsearchable: true},
    {id: 'delete_button', label: '', unsortable: true, conditional: true, unsearchable: true, unSelectable: true}
  ];

  var uomColumns = [
    {id: 'code', label: 'UOM Code'},
    {id: 'mb_uom_code', label: 'MB UOM Code', unsearchable: true},
    {id: 'descr', label: 'Description'},
    {id: 'environment_code', label: 'Environment Code', unsearchable: true},
    {id: 'delete_button', label: '', unsortable: true, conditional: true, unsearchable: true, unSelectable: true}
  ];

  var supplierColumns = [
    {id: 'mb_supplier_code', label: 'MB Supplier Code', unsearchable: true},
    {id: 'company_object', label: 'Company Code', unsearchable: true},
    {id: 'number', label: 'Vendor Code', unsearchable: true},
    {id: 'registration_no', label: 'Registration No'},
    {id: 'descr', label: 'Description'},
    {id: 'sic_code', label: 'SIC Code'},
    {id: 'environment_code', label: 'Environment Code', unsearchable: true},
    {id: 'delete_button', label: '', unsortable: true, conditional: true, unsearchable: true, unSelectable: true}
  ];

  var costCenterColumns = [
    {id: 'code', label: 'Cost Center Code'},
    {id: 'mb_company_code', label: 'MB Cost Center Code', unsearchable: true},
    {id: 'descr', label: 'Cost center Name'},
    {id: 'environment_code', label: 'Environment Code', unsearchable: true},
    {id: 'delete_button', label: '', unsortable: true, conditional: true, unsearchable: true, unSelectable: true}
  ];

  var purchaseGroupColumns = [
    {id: 'purchase_group_code', label: 'Purchase Group Code'},
    {id: 'company_code', label: 'Company Code'},
    {id: 'created_at', label: 'Created At'},
    {id: 'updated_at', label: 'Updated At'},
    {id: 'delete_button', label: '', unsortable: true, conditional: true, unsearchable: true, unSelectable: true}
  ];

  var purchaseOrganizationColumns = [
    {id: 'code', label: 'Purchasing Org Code'},
    {id: 'plant', label: 'Plant Code'},
    {id: 'created_at', label: 'Created At'},
    {id: 'updated_at', label: 'Updated At'},
    {id: 'delete_button', label: '', unsortable: true, conditional: true, unsearchable: true, unSelectable: true}
  ];

  var companyCodeColumns = [
    {id: 'company_code', label: 'Company Code'},
    {id: 'mapping_code', label: 'Mapping Code'},
    {id: 'created_at', label: 'Created At'},
    {id: 'updated_at', label: 'Updated At'},
    {id: 'delete_button', label: '', unsortable: true, conditional: true, unsearchable: true, unSelectable: true}
  ];

  var companyAccountCodesColumns = [
    {id: 'inter_id', label: 'Billing Company'},
    {id: 'company_code', label: 'MB Company Code'},
    {id: 'inti_id', label: 'Inti Company Code'},
    {id: 'account_code', label: 'Account Code'},
    {id: 'created_at', label: 'Created At'},
    {id: 'updated_at', label: 'Updated At'},
    {id: 'delete_button', label: '', unsortable: true, conditional: true, unsearchable: true, unSelectable: true}
  ];

  var plantColumns = [
    {id: 'plant_code', label: 'Plant Code'},
    {id: 'descr', label: 'Plant Description'},
    {id: 'billing_company_code', label: 'Billing Company Code'},
    {id: 'company_code', label: 'Company Code'},
    {id: 'created_at', label: 'Created At'},
    {id: 'updated_at', label: 'Updated At'},
    {id: 'delete_button', label: '', unsortable: true, conditional: true, unsearchable: true, unSelectable: true}
  ];

  var focItemsColumns = [
    {id: 'company_code', label: 'Company Code'},
    {id: 'gl_code', label: 'GL Account Code'},
    {id: 'cost_center', label: 'Cost Center'},
    {id: 'created_at', label: 'Created At'},
    {id: 'updated_at', label: 'Updated At'},
    {id: 'delete_button', label: '', unsortable: true, conditional: true, unsearchable: true, unSelectable: true}
  ];

  var budgetOwnerColumns = [
    {id: 'company_code', label: 'Company Code'},
    {id: 'budget_owner', label: 'Budget Owner'},
    {id: 'created_at', label: 'Created At'},
    {id: 'updated_at', label: 'Updated At'},
    {id: 'delete_button', label: '', unsortable: true, conditional: true, unsearchable: true, unSelectable: true}
  ];

  $scope.tabData = [
    taxMapping,
    companyMapping,
    paymentTermMapping,
    uomMapping,
    supplierMapping,
    costCenterMapping,
    purchaseGroupMapping,
    purchaseOrganizationMapping,
    companyCodeMapping,
    companyAccountCodesMapping,
    plantMapping,
    focItemsMapping,
    budgetOwnerMapping,
  ];

  function initialize() {
    setScope($state.current);
  }

  $scope.$on('$stateChangeStart', function( event, data, toParams ) {
    setScope(data);
    if (!!toParams.status) {
      $scope.parentStateParams = toParams;
    }
  });

  function setScope(state) {
    $scope.storageKey = 'tax-mapping-selected-columns';
    $scope.allFilter = true;
    if (!!state.name && state.name.indexOf('tax') > -1) {
      $scope.columns = taxColumns;
      $scope.searchPlaceholder = 'Search Tax Mapping';
      $scope.searchState = 'main.user.bifrostMapping.tax';
      $scope.parentState = 'main.user.bifrostMapping.tax';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = taxMapping;
    } else if (!!state.name && state.name.indexOf('companyAccountCodes') > -1) {
      $scope.storageKey = 'companyAccountCodes-mapping-selected-columns';
      $scope.columns = companyAccountCodesColumns;
      $scope.searchPlaceholder = 'Search Company Account Codes Mapping';
      $scope.searchState = 'main.user.bifrostMapping.companyAccountCodes';
      $scope.parentState = 'main.user.bifrostMapping.companyAccountCodes';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = companyAccountCodesMapping;
    } else if (!!state.name && state.name.indexOf('paymentTerm') > -1) {
      $scope.storageKey = 'paymentterm-mapping-selected-columns';
      $scope.columns = paymentTermColumns;
      $scope.searchPlaceholder = 'Search Payment Term Mapping';
      $scope.searchState = 'main.user.bifrostMapping.paymentTerm';
      $scope.parentState = 'main.user.bifrostMapping.paymentTerm';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = paymentTermMapping;
    } else if (!!state.name && state.name.indexOf('uom') > -1) {
      $scope.storageKey = 'uom-mapping-selected-columns';
      $scope.columns = uomColumns;
      $scope.searchPlaceholder = 'Search UOM Mapping';
      $scope.searchState = 'main.user.bifrostMapping.uom';
      $scope.parentState = 'main.user.bifrostMapping.uom';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = uomMapping;
    } else if (!!state.name && state.name.indexOf('supplier') > -1) {
      $scope.storageKey = 'supplier-mapping-selected-columns';
      $scope.columns = supplierColumns;
      $scope.searchPlaceholder = 'Search Supplier Mapping';
      $scope.searchState = 'main.user.bifrostMapping.supplier';
      $scope.parentState = 'main.user.bifrostMapping.supplier';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = supplierMapping;
    } else if (!!state.name && state.name.indexOf('costCenter') > -1) {
      $scope.storageKey = 'costCenter-mapping-selected-columns';
      $scope.columns = costCenterColumns;
      $scope.searchPlaceholder = 'Search Cost center Mapping';
      $scope.searchState = 'main.user.bifrostMapping.costCenter';
      $scope.parentState = 'main.user.bifrostMapping.costCenter';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = costCenterMapping;
    } else if (!!state.name && state.name.indexOf('purchaseGroup') > -1) {
      $scope.storageKey = 'purchaseGroup-mapping-selected-columns';
      $scope.columns = purchaseGroupColumns;
      $scope.searchPlaceholder = 'Search Purchase Group Mapping';
      $scope.searchState = 'main.user.bifrostMapping.purchaseGroup';
      $scope.parentState = 'main.user.bifrostMapping.purchaseGroup';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = purchaseGroupMapping;
    } else if (!!state.name && state.name.indexOf('purchaseOrganization') > -1) {
      $scope.storageKey = 'purchaseOrganization-mapping-selected-columns';
      $scope.columns = purchaseOrganizationColumns;
      $scope.searchPlaceholder = 'Search Purchase Organization Mapping';
      $scope.searchState = 'main.user.bifrostMapping.purchaseOrganization';
      $scope.parentState = 'main.user.bifrostMapping.purchaseOrganization';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = purchaseOrganizationMapping;
    } else if (!!state.name && state.name.indexOf('companyCode') > -1) {
      $scope.storageKey = 'companyCode-mapping-selected-columns';
      $scope.columns = companyCodeColumns;
      $scope.searchPlaceholder = 'Search Company Code Mapping';
      $scope.searchState = 'main.user.bifrostMapping.companyCode';
      $scope.parentState = 'main.user.bifrostMapping.companyCode';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = companyCodeMapping;
    }  else if (!!state.name && state.name.indexOf('company') > -1) {
      $scope.storageKey = 'company-mapping-selected-columns';
      $scope.columns = companyColumns;
      $scope.searchPlaceholder = 'Search Company Mapping';
      $scope.searchState = 'main.user.bifrostMapping.company';
      $scope.parentState = 'main.user.bifrostMapping.company';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = companyMapping;
    } else if (!!state.name && state.name.indexOf('plant') > -1) {
      $scope.storageKey = 'plant-mapping-selected-columns';
      $scope.columns = plantColumns;
      $scope.searchPlaceholder = 'Search Plant Mapping';
      $scope.searchState = 'main.user.bifrostMapping.plant';
      $scope.parentState = 'main.user.bifrostMapping.plant';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = plantMapping;
    } else if (!!state.name && state.name.indexOf('focItems') > -1) {
      $scope.storageKey = 'focItems-mapping-selected-columns';
      $scope.columns = focItemsColumns;
      $scope.searchPlaceholder = 'Search FOC Items Mapping';
      $scope.searchState = 'main.user.bifrostMapping.focItems';
      $scope.parentState = 'main.user.bifrostMapping.focItems';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = focItemsMapping;
    } else if (!!state.name && state.name.indexOf('mappingBudgetOwner') > -1) {
      $scope.storageKey = 'budgetOwner-mapping-selected-columns';
      $scope.columns = budgetOwnerColumns;
      $scope.searchPlaceholder = 'Search Budget Owner Mapping';
      $scope.searchState = 'main.user.bifrostMapping.budgetOwner';
      $scope.parentState = 'main.user.bifrostMapping.budgetOwner';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = budgetOwnerMapping;
    }
  }

  initialize();
}
bifrostMappingMainCtrl.$inject = ['$scope', '$state'];

angular
  .module('metabuyer')
  .controller('bifrostMappingMainCtrl', bifrostMappingMainCtrl);
