'use strict';

function faqCtrl($scope, $rootScope, $state) {

  $scope.faqSidebarTemplateUrl = 'app/faq/faqSidebar.html';

  $scope.mainPage = true;
  $scope.navigateFaq = navigateFaq;
  $scope.goMetabuyer = goMetabuyer;

  function navigateFaq(data) {
    switch (data) {
      case 'questions':
        $scope.mainPage = true;
        break;
      case 'videos' :
        $scope.mainPage = false;
        break;
    }
  }

  function goMetabuyer() {
    $state.go('main.user.mainMenu.waiting-on-you');
  }

  $scope.videos = [
    {
      'type': 'video',
      'url': 'https://www.youtube.com/embed/a1goxZl0lPs',
      'thumbUrl': 'https://img.youtube.com/vi/a1goxZl0lPs/hqdefault.jpg',
      'title': '1. Intro, Navigation and Training Data Setup'
    },
    {
      'type': 'video',
      'url': 'https://www.youtube.com/embed/QZytZQwhiyM',
      'thumbUrl': 'https://i.ytimg.com/vi/QZytZQwhiyM/hqdefault.jpg',
      'title': '2. Create Requisition Catalogue Item Part 1'
    },
    {
      'type': 'video',
      'url': 'https://www.youtube.com/embed/85Do7e9KbVs',
      'thumbUrl': 'https://img.youtube.com/vi/85Do7e9KbVs/hqdefault.jpg',
      'title': '3. Create Non Catalogue Item'
    },
    {
      'type': 'video',
      'url': 'https://www.youtube.com/embed/LYgsWOr1lww',
      'thumbUrl': 'https://i.ytimg.com/vi/LYgsWOr1lww/hqdefault.jpg',
      'title': '4. Final Part of Requisition'
    },
    {
      'type': 'video',
      'url': 'https://www.youtube.com/embed/81PjyAoHKjM',
      'thumbUrl': 'https://img.youtube.com/vi/81PjyAoHKjM/hqdefault.jpg',
      'title': '5. Approvals'
    },
    {
      'type': 'video',
      'url': 'https://www.youtube.com/embed/Ayh4KtzJc0o',
      'thumbUrl': 'https://i.ytimg.com/vi/Ayh4KtzJc0o/hqdefault.jpg',
      'title': '6. Escalation'
    },
    {
      'type': 'video',
      'url': 'https://www.youtube.com/embed/NVCd4tQamZo',
      'thumbUrl': 'https://i.ytimg.com/vi/NVCd4tQamZo/hqdefault.jpg',
      'title': '7. Delegation'
    }
  ];


  $(document).ready(function() {
    $('body').removeClass('mini-navbar');
  });

}

faqCtrl.$inject = ['$scope', '$rootScope', '$state'];

angular
  .module('metabuyer')
  .controller('faqCtrl', faqCtrl)
  .value('duScrollOffset', 100);
