'use strict';

function changeMappingFieldSizeCtrl ($scope, $state, toastr, changeMappingFieldSize, globalFunc) {

  $scope.submit = submit;
  $scope.confirmSubmit = confirmSubmit;
  $scope.selectIntegration = selectIntegration;
  $scope.getMappingFieldValue = getMappingFieldValue;
  $scope.integration = {};
  $scope.mappingField = {};
  $scope.currentValue = undefined;
  $scope.newValue = undefined;

  $scope.integrations = [
    {
      name: 'MB-JDE-ESKER',
      editable: [
        'Description of line (part 1)',
        'Description of line (part 2)'
      ]
    },
    {
      name: 'MB-IFCA-ESKER',
      editable: [
        'Item Description'
      ]
    },
    {
      name: 'MB-JDE-JDE',
      editable: []
    }
  ];

  function submit() {
    var params = {
      integration: $scope.integration.selected.name,
      mappingFieldDescription: $scope.mappingField.selected,
      newValue: $scope.newValue
    };

    changeMappingFieldSize.put(
      {},
      params,
      function(response) {
        $scope.currentValue = response.size;
        toastr.success('Mapping field template has been updated successfully');
      }, function(error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function confirmSubmit() {
    var error = false;
    if (!$scope.newValue) {
      toastr.error('Please fill in the new value for mapping field');
      error = true;
    }
    if ($scope.newValue === $scope.oldValue) {
      toastr.error('New value must not be same as old value');
      error = true;
    }

    if (error) {
      return;
    }

    swal({
      title: 'Confirm to update\n' + $scope.integration.selected.name + '\n' + $scope.mappingField.selected + '\nto\n' + $scope.newValue + ' ?',
      text: 'This action is not revertable and will be recorded.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Confirm',
      closeOnConfirm: true
    }, function (isConfirm) {
      if (isConfirm) {
        submit();
      }
    });
  }

  function selectIntegration() {
    $scope.mappingField.selected = undefined;
    $scope.currentValue = undefined;
  }

  function getMappingFieldValue() {
    var params = {
      integration: $scope.integration.selected.name,
      mappingFieldDescription: $scope.mappingField.selected
    };

    changeMappingFieldSize.get(
      params,
      function (resource) {
        $scope.currentValue = resource.size;
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      });
  }

}
changeMappingFieldSizeCtrl.$inject = ['$scope', '$state', 'toastr', 'changeMappingFieldSize', 'globalFunc'];

angular
  .module('metabuyer')
  .controller('changeMappingFieldSizeCtrl', changeMappingFieldSizeCtrl);
