'use strict';

angular.module('metabuyer.services.itemMaster', ['ngResource'])
  .factory('itemMasterMasterDataList',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.itemMaster.single,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'},
        'post': {method: 'POST'},
        'put': {method: 'PUT'}
      }
    );
  });
