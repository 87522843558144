'use strict';


function faqQuestionsCtrl($scope) {

}

faqQuestionsCtrl.$inject = [
  '$scope'
];


angular
  .module('metabuyer')
  .directive('faqQuestions', function () {
    return {
      restrict: 'E',
      templateUrl: 'components/faqQuestions/questions.html',
      controller: 'faqQuestionsCtrl'
    };
  })
  .controller('faqQuestionsCtrl',  faqQuestionsCtrl);
