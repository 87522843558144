'use strict';
/**
 * @name SupplierUserImportCtrl
 * @desc Controller for supplier user import
 */
function SupplierUserImportCtrl($scope) {
  $scope.isApprovalNotRequired = false;
  $scope.changeApprovalNotRequired = changeApprovalNotRequired;

  function changeApprovalNotRequired(isApprovalNotRequired) {
    $scope.params.isApprovalNotRequired = isApprovalNotRequired;
  }

  $scope.params = {
    class: 'App\\Metabuyer\\Supplier\\Models\\SupplierUser',
    'additional_params[]': '',
    'isApprovalNotRequired': $scope.isApprovalNotRequired,
  };

}

SupplierUserImportCtrl.$inject = ['$scope'];

angular.module('metabuyer')
  .controller('SupplierUserImportCtrl', SupplierUserImportCtrl);
