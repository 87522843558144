'use strict';
angular
  .module('metabuyer')
  .directive('fadeoutRow',function() {
    return {
      restrict: "A",
      scope: {
        enable: "=fadeoutRow"
      },
      link: function (scope, elem, attrs) {

        if (scope.enable) {
          elem.effect("highlight", {}, 1000 * 60 * 60 * 12);
        }
      }
    }
  });
