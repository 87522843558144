'use strict';

/**
 * @name SupplierWebUsersDetailCtrl
 * @desc Controller for user detail
 */
function SupplierWebUsersDetailCtrl(
  $scope, $uibModal, $state, profile, toastr, pathConstants, uploadImage, $auth, $rootScope,
  positionAssignment, HighlightId, globalFunc, supplierWebUserStatus, setAsMetabuyerUser, supplierWebRoles
) {

  $scope.user = profile;
  $scope.user.roleList = [];
  $scope.back = back;
  $scope.checkLockedUser = checkLockedUser;
  $scope.editSupplierWebUser = editSupplierWebUser;
  $scope.setUserAsMetabuyerUser = setUserAsMetabuyerUser;
  $scope.historyDependencies = {
    embeddedParams: {
      'object[0][object_id]': $scope.user._id,
      'object[0][object_type]': 'Metabuyer\\Models\\Users',
      offset: 10,
      order_by: '-created_at' // Created at, descending
    }
  };
  $scope.imageBasePath = $rootScope.userImageBasePath;

  $scope.isEditMode = false;
  $scope.positionAssign = null;

  if(!$scope.user.status){
    $scope.user.status = '';
  }

  if(!$scope.user.role){
    $scope.user.role = '';
  }

  function back() {
    if (!!$scope.user && !!$scope.user._id) {
      HighlightId.setId($scope.user._id);
    }
    window.history.back();
  }

  function generateSystemArray(userObject, systemArray){
    systemArray = [];
    if($scope.user[userObject] && $scope.user[userObject].length){
      for(var i = 0; i < $scope.user[userObject].length ; i++){
        if($scope.user[userObject][i].company_code === null){
          systemArray.push($scope.user[userObject][i]);
        }
      }
      $scope.systemObject[userObject] = systemArray;
    }
  }

  function checkLockedUser() {
    $uibModal.open({
      templateUrl: "app/usersManagement/detail/admin-unlocking-user-panelTemplate.html",
      backdrop: 'static',
      keyboard: false,
      controller: adminUnlockUserCtrl,
      scope: $scope
    })
  }

  function editSupplierWebUser() {
    $state.go('main.user.suppliersWebUsers.edit',
      {
        id: $scope.user._id,
        supplierWebRoles: $scope.supplierWebRoles
      });
  }

  // Function to set user as Metabuyer user
  function setUserAsMetabuyerUser() {
    setAsMetabuyerUser.put(
      {
        id: $scope.user._id
      },
      function (resource) {
        $scope.user.is_metabuyer_user = true;
        toastr.success(resource.content.message);
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  $scope.changeUserStatus = function (newStatus) {
    if (newStatus.toUpperCase() === 'INACTIVE') {
      swal({
        title: "User Deactivation",
        text: "Are you sure to DEACTIVATE this user?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes, Deactivate It!",
        closeOnConfirm: true
      }, function(isConfirm){
        if (isConfirm) {
          supplierWebUserStatus.put(
            {
              id: $scope.user._id,
              status: 2
            },
            function (resource) {
              $scope.user.status = 'INACTIVE';
            },
            function (error) {
              toastr.error('Failed to deactivate the user');
            });
        }
      });
    }
    else if (newStatus.toUpperCase() === 'ACTIVE') {
      swal({
        title: "User Activation",
        text: "Are you sure to ACTIVATE this user?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1AB394",
        confirmButtonText: "Yes, Activate It!",
        closeOnConfirm: true
      }, function(isConfirm){
        if (isConfirm) {
          supplierWebUserStatus.put(
            {
              id: $scope.user._id,
              status: 1
            },
            function (resource) {
              $scope.user.status = 'ACTIVE';
            },
            function (error) {
              toastr.error('Failed to activate the user');
            });
        }
      });
    }

  };

  $scope.reject = function () {

  };

  // config flowjs
  $scope.flowConfig = new Flow({
    target: uploadImage.uploadImagePath($scope.user._id),
    singleFile: true,
    testChunks: false,
    chunkSize : 10000000,
    headers: {
      'Authorization': 'Bearer ' + $auth.getToken()
    }
  });


  $scope.isUploadImageSuccess = function (file, message) {
    if (message) {
      var response = JSON.parse(message);
      toastr.success(response.content.message);
    }
  };

  /**
   * Get selected supplier web roles
   *
   * @param supplierWebRoles
   */
  function getSelectedSupplierWebRoles(supplierWebRoles) {
    $scope.selectedSupplierWebRoles = [];
    var selectedSupplierWebRole = {};
    // First get system role assignment if any
    var systemRoleAssignment = globalFunc.findInArray($scope.user.role_assignments, 'weight', 1);

    if (!!systemRoleAssignment) {
      _.forEach(supplierWebRoles, function(supplierWebRole) {
        selectedSupplierWebRole = globalFunc.findInArray(systemRoleAssignment.roles, 'name', supplierWebRole.name);

        // Push selected supplier web role if any
        if (!!selectedSupplierWebRole) {
          $scope.selectedSupplierWebRoles.push(selectedSupplierWebRole);
        }
      });
    }
  }

  function initialize() {

    $state.params.status = !!$scope.user.status ? $scope.user.status.toLowerCase() : 'all';

    var activeTab = $scope.$parent.tabData[0];
    if ($state.params.status === 'active') {
      activeTab = $scope.$parent.tabData[1]
    } else if ($state.params.status === 'inactive') {
      activeTab = $scope.$parent.tabData[2]
    } else if ($state.params.status === 'deleted') {
      activeTab = $scope.$parent.tabData[3]
    }

    var tabData = {
      tab: activeTab,
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);

    $scope.fontWeights = [
      {descr: 'Normal', value: 'normal'},
      {descr: 'Bold', value: 'bold'}
    ];

    if (!!$scope.user && !!$scope.user.config && !_.isUndefined($scope.user.config.font_weight)) {
      $scope.selectedFontWeight = globalFunc.findInArray(
        $scope.fontWeights,
        'value',
        $scope.user.config.font_weight
      );
    }

    // Get the supplier web roles
    $scope.supplierWebRoles = supplierWebRoles.data;
    getSelectedSupplierWebRoles($scope.supplierWebRoles);
  }

  initialize();

}

SupplierWebUsersDetailCtrl.$inject = [
  '$scope', '$uibModal', '$state', 'profile', 'toastr', 'pathConstants', 'uploadImage', '$auth',
  '$rootScope', 'positionAssignment', 'HighlightId', 'globalFunc', 'supplierWebUserStatus', 'setAsMetabuyerUser',
  'supplierWebRoles'
];

angular
  .module('metabuyer')
  .controller('SupplierWebUsersDetailCtrl', SupplierWebUsersDetailCtrl);
