/**
 * @name columnsSelector
 *
 *
 * @description
 *
 * Directive to handle the Selection, Storing and Loading of the headers in the manage/list pages
 *
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Sunway Metacloud &copy; 2016
 *
 */

'use strict';

angular.module('metabuyer')
  .directive('columnsSelector', function () {
    return {
      scope: {
        availableColumns: '=',
        storageKey: '=',
        condition: '=?'
      },
      templateUrl: 'components/columnsSelector/columnsSelectorTemplate.html',
      restrict: 'E',
      controller: 'columnsSelectorCtrl'
    }
  })


  /**
   * @name columnsSelectorCtrl
   *
   * @description
   * The controller of the columnsSelector directive handles the data of the button and the events, as well as storing
   * the data and retrieving from local storage
   *
   * @author Ahmed Saleh <a.saleh@vventures.asia>
   * @copyright Sunway Metacloud &copy; 2016
   */
  .controller('columnsSelectorCtrl', function($scope, globalFunc) {

    /**
     * function to filter the columns to be selected or not
     * @param columnList
     * @param condition
     * @returns {Array}
     */
    function filterColumns(columnList, condition) {
      var columns = [];
      _.forEach(columnList, function (col) {
        if (!!col.conditional) {
          if (col.condition === condition) {
            columns.push(col);
          }
          if (col.condition instanceof Array) {
            for (var i in col.condition) {
              if (col.condition[i] === condition) {
                columns.push(col);
              }
            }
          }
        } else {
          columns.push(col);
        }
      });
      return columns;
    }

    /**
     * Columns data
     * @type {Array}
     */
    $scope.selectedColumns = [];
    $scope.buttonText = {
      buttonDefaultText: 'Columns'
    };
    $scope.columnsSettings = {
      buttonClasses: 'btn btn-white',
      smartButtonMaxItems: 3
    };

    $scope.columnsEvents = {
      onItemSelect: function (selected) {
        for (var i in $scope.columns) {
          if (selected.id === $scope.columns[i].id) {
            $scope.columns[i].selected = true;
          }
        }
        refreshSelectedColumns();
      },
      onItemDeselect: function (selected) {
        for (var i in $scope.columns) {
          if (selected.id === $scope.columns[i].id) {
            $scope.columns[i].selected = false;
          }
        }
        refreshSelectedColumns();
      }
    };

    function refreshSelectedColumns() {
      $scope.selectedColumns = [];
      for (var i in $scope.columns) {
        if ($scope.columns[i].selected === true) {
          $scope.selectedColumns.push($scope.columns[i]);
        }
      }
      /**
       * storing in the local storage
       */
      window.localStorage.setItem($scope.storageKey, JSON.stringify(_.map($scope.selectedColumns, function (item) {
        return item.id
      })));
    }

    /**
     * loading the selected columns
     */
    function loadSelectedColumns() {

      var tempItems = JSON.parse(window.localStorage.getItem($scope.storageKey));
      if (!!tempItems) {
        for (var i in $scope.columns) {
          for (var j in tempItems) {
            if (tempItems[j] === $scope.columns[i].id) {
              $scope.columns[i].selected = true;
              $scope.selectedColumns.push($scope.columns[i]);
            }
          }
        }
      } else {
        for (var i in $scope.columns) {
          $scope.columns[i].selected = true;
          if(!!$scope.columns[i].unSelectedByDefault &&
             $scope.columns[i].unSelectedByDefault === true) {
            $scope.columns[i].selected = false;
            continue;
          }
          $scope.selectedColumns.push($scope.columns[i]);
        }
      }
    }

    function initialize() {
      $scope.columns = filterColumns($scope.availableColumns, $scope.condition);
      loadSelectedColumns();
      $scope.columnOptions =  globalFunc.removeValueFromArray($scope.columns, 'unSelectable', true);
    }

    initialize();
  });
