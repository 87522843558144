'use strict';

function tenderComponentCtrl(
  $scope, globalFunc, $rootScope, $uibModal, $state, HighlightId, pathConstants, $filter, tenderFunction, toastr, $timeout
) {
  $scope.addComponent = addComponent;
  $scope.deleteComponent = deleteComponent;
  $scope.autoSaveComponent = autoSaveComponent;
  $scope.getAvailableGroupOptions = getAvailableGroupOptions;

  $scope.showHideCond = function(data, fieldName) {
    var data = (data === undefined) ? null : data;
    var fieldName = (fieldName === undefined) ? null : fieldName;

    var canEdit = true;

    if ($scope.tender.is_vo && $scope.tender.status === 'draft' && tenderFunction.hasRoleRequester()) {

      if (!['NEW_WORKS', 'ADDITIONAL_SUM'].includes($scope.tender.detail.type.key)) {
        canEdit = false;
      } else {

        canEdit = true;

        if (data !== null) {

          canEdit = false;

          if (['name', 'description', 'tender_group_id'].includes(fieldName)) {
            canEdit = true;
          }

          if (['deleteBtn'].includes(fieldName) && data.is_vo !== undefined) {
            canEdit = true;
          }

        }
      }

    } else {
      canEdit = $scope.editableStatus;
    }

    return canEdit;
  };

  function getAvailableGroupOptions(current) {
    return $scope.initialTenderGroups;
  }

  function checkTenderGroupLimit() {

    var listGroups = $scope.components.filter(function(item) {
      if (Object.keys(item.group || {}).length > 0) {
        return true;
      }
    });

    var groups = listGroups.reduce(function(obj, item) {
      obj[item.group._id] = obj[item.group._id] || [];
      obj[item.group._id].push(item.group.code);

      return obj;
    }, {});

    var totalTenderGroup = [];

    if (groups !== undefined) {
      var totalTenderGroup = Object.keys(groups).map(function(key) {
        return { group: key, _id: groups[key] };
      });
    }

    if (totalTenderGroup.length > $scope.limitTenderGroup.count) {
      return false;
    }

    return true;
  }

  function autoSaveComponent(fieldIndex, fieldValue, fieldName, oldValue) {

    var _id = $scope.components[fieldIndex]._id;
    var payload = {};

    if (fieldName === 'tender_group_id') {
      var fieldValue = fieldValue._id;

      var checkLimit = checkTenderGroupLimit();

      if (!checkLimit) {
        toastr.error($scope.limitTenderGroup.msg);
        return false;
      }
    }

    payload[fieldIndex] = {
      _id: _id
    };

    payload[fieldIndex][fieldName] = fieldValue;

    tenderFunction.saveApi({ components: payload }, '').then(function(response) {
      if (response.http_code === 200) {
        tenderFunction.updateTotalAmount(response.content.data.total_amount);
        tenderFunction.updateTenderGroupsByComponents(response.content.data.groups);
        tenderFunction.updateCumulativeVOAmount(response.content.data.cumulative_vo_amount);
        tenderFunction.updateRevisedTotalAmount(response.content.data.revised_total_amount);

        $rootScope.$broadcast('updateTenderGroupsByComponents', {
          data: tenderFunction.getTenderGroupsByComponents()
        });
      }
    })

  }

  function addComponent() {

    tenderFunction.saveApi({ components: [{}], return_single_component: true }, '').then(function(response) {

      if (response.http_code === 200) {
        $scope.components.push(response.content.data.component);
      }

    })

  }

  function deleteComponent(index) {
    swal({
      title: 'Confirmation',
      text: 'Confirm Delete This Component?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Confirm',
      closeOnConfirm: true
    }, function(isConfirm) {
      if (isConfirm) {

        var value = $scope.components[index];
        var payload = {};
        payload[index] = {
          _id: value._id,
          is_deleted: true
        };

        tenderFunction.saveApi({ components: payload }, 'Successfully deleted').then(function(response) {
          if (response.http_code === 200) {
            tenderFunction.updateTotalAmount(response.content.data.total_amount);
            $scope.components.splice(index, 1);

            tenderFunction.updateTenderGroupsByComponents(response.content.data.groups);
            tenderFunction.updateRevisedTotalAmount(response.content.data.revised_total_amount);

            $rootScope.$broadcast('updateTenderGroupsByComponents', {
              data: tenderFunction.getTenderGroupsByComponents()
            });

          }
        })
      }
    })
  }

  function initialize() {
    $scope.components = tenderFunction.getComponentLists();
    $scope.initialTenderGroups = $scope.initialData.tender_groups;
    $scope.limitTenderGroup = $scope.initialData.tender_group_component_limit;
  }

  initialize();

  $scope.$on('updateTenderComponents', function(event, result) {
    $scope.components = result.data;
  });

  $scope.$watch('components', function(newValue, oldValue) {

    for (var i = 0; i < newValue.length; i++) {

      if (newValue[i].group !== undefined && oldValue[i].group !== undefined) {
        var newGroup = newValue[i].group;
        var oldGroup = oldValue[i].group;

        if (newGroup._id !== oldGroup._id) {
          var checkLimit = checkTenderGroupLimit();

          if (!checkLimit) {
            $scope.components[i].group = (oldGroup === undefined) ? { _id: null } : oldGroup;
            toastr.error($scope.limitTenderGroup.msg);
            return false;
          }
        }
      } else {

        var checkLimit = checkTenderGroupLimit();

        if (!checkLimit) {
          $scope.components[i].group = {};
          toastr.error($scope.limitTenderGroup.msg);
          return false;
        }

      }

    }

  }, true);


}

tenderComponentCtrl.$inject = [
  '$scope', 'globalFunc', '$rootScope', '$uibModal', '$state', 'HighlightId', 'pathConstants', '$filter', 'tenderFunction', 'toastr', '$timeout'
];

angular
  .module('metabuyer')
  .controller('tenderComponentCtrl', tenderComponentCtrl);
