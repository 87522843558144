'use strict';

function approvalLimitDetailCtrl($scope, userData, approvalLimitData, approvalLimitStatus, toastr, globalFunc, profile,
                                 userApprovalTrack, loggedInUserApprovalTrack, $state) {

  $scope.userData = !_.isEmpty(userData) ? userData : profile;
  $scope.approvalLimitData = approvalLimitData;
  ($state.current.name.indexOf('mainMenu') > -1) ?
    $scope.service = loggedInUserApprovalTrack :  $scope.service = userApprovalTrack;
  $scope.approvalLimitStatus = {
    selected: approvalLimitData.status === 'ACTIVE'
  };
  var trackedBalance = {};
  $scope.updateStatus = updateStatus;
  $scope.back = back;
  $scope.isAdmin = globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Admin') || globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN');

  function calculateBalance(page) {

    var currentBalance;
    //first page
    if(page === 1)
      currentBalance = $scope.approvalLimitData.amount;
    else
      currentBalance = trackedBalance[page].value;

    for(var i = 0; i < $scope.userLimitTableData.length; i++) {
      var chargedAmount = math.round($scope.userLimitTableData[i].pr_stage.charged_amount, 4);
      var returnedAmount = math.round($scope.userLimitTableData[i].pr_stage.returned_amount, 4);
      //PR stage
      currentBalance -= chargedAmount;
      currentBalance += returnedAmount;
      $scope.userLimitTableData[i].pr_stage.balance = math.round(currentBalance, 4);

      //consolidation stage
      if (!!$scope.userLimitTableData[i].consolidation_stage) {
        var consolidationChargedAmount = math.round($scope.userLimitTableData[i].consolidation_stage.charged_amount || 0.0, 4);
        var consolidationReleased = math.round($scope.userLimitTableData[i].consolidation_stage.released_line_amount || 0.0, 4);
        currentBalance -= consolidationChargedAmount;
        currentBalance += consolidationReleased;
        $scope.userLimitTableData[i].consolidation_stage.balance = math.round(currentBalance, 4);
      }

      //po stage
      if (!!$scope.userLimitTableData[i].po_stage) {
        var poUtilized = math.round($scope.userLimitTableData[i].po_stage.approved_amount || 0.0, 4);
        var poReleased = math.round($scope.userLimitTableData[i].po_stage.released_line_amount || 0.0, 4);

        currentBalance -= poUtilized;
        currentBalance += poReleased;
      }
      $scope.userLimitTableData[i].final_balance = math.round(currentBalance, 4);
    }
    //store the starting balance for the next page
    var targetPage = page+1;
    trackedBalance[targetPage]= { value : currentBalance};
  }

  function updateStatus() {
    var newStatus = !!$scope.approvalLimitStatus.selected ? 'ACTIVE' : 'INACTIVE';
    approvalLimitStatus.put(
      {
        user_uuid: $scope.userData.uuid,
        approval_limit_uuid: $scope.approvalLimitData.approval_limit_uuid
      },
      { status: newStatus },
      function (resource) {
        toastr.success("Status successfully updated");
        $scope.approvalLimitData.status = newStatus;
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
        $scope.approvalLimitStatus.selected = !$scope.approvalLimitStatus.selected;
      }
    )
  }

  function back() {
    window.history.back();
  }

  function initialize() {
    $scope.params = {
      uuid: userData.uuid, //user uuid
      approval_limit_uuid: $scope.approvalLimitData.approval_limit_uuid
    };
    $scope.service.get(
      $scope.params, function (resource) {
      if(!!resource && !!resource.data) {
        $scope.userLimitTableData = resource.data;
        $scope.meta = resource.meta;
        calculateBalance(resource.meta.current_page);
      }
    });
  }

  /**
   * replace the date on the event to avoid incorrect binding and calculate the correct values
   */
  $scope.$on('embeddedPaginationResultsEmit', function (event, paginatedResults) {
    $scope.userLimitTableData = paginatedResults.data;
    $scope.meta = paginatedResults.meta;
    calculateBalance(paginatedResults.meta.current_page);
  });

  initialize();
}

approvalLimitDetailCtrl.$inject = ['$scope', 'userData', 'approvalLimitData', 'approvalLimitStatus',
                                   'toastr', 'globalFunc', 'profile', 'userApprovalTrack', 'loggedInUserApprovalTrack',
                                   '$state', '$rootScope'];

angular
  .module('metabuyer')
  .controller('approvalLimitDetailCtrl', approvalLimitDetailCtrl);
