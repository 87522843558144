'use strict';

angular.module('metabuyer.services.itemCategory', ['ngResource'])
  .factory('itemCategoryMasterDataList',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.itemCategory.single,
      {
        uuid: '@uuid'
      },
      {
        'get': {method: 'GET'},
        'post': {method: 'POST'},
        'put': {method: 'PUT'}
      }
    );
  });
