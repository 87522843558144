'use strict';
/**
 * @name requisitionsDetailsCtrl
 *
 * @description
 *
 * Controller for the requisitions details state
 *
 *
 * @authors Justin <justin.cty90@gmail.com> Ahmed Saleh <a.s.alsalali@gmail.com>
 * @copyright Sunway Metacloud &copy; 2016
 */

function requisitionsV2DetailsCtrl(
  $scope, purchaseRequisition, metabuyerCache, $rootScope, $state, companyCurrencies, prV2Function, approvalPreview,
  globalFunc, profile, Approvals, searchModule, singleCommentsHistory, NewPRFirstLoad, PRV2Attachments, $filter,
  pathConstants, $timeout, isIntegratedCompany, companyMappingStatus, uomList, integrationSolution, UserPermissions,
  prConfig, purchaseRequisitionsV2CommentService, $http, purchaseRequisitionsV2Services
) {

  $scope.PR                           = purchaseRequisition.data;
  $scope.integrationSolution          = integrationSolution;
  $scope.editingPRState               = 'editable';
  $scope.commentService               = purchaseRequisitionsV2CommentService;
  $scope.deleteService                = singleCommentsHistory;
  $scope.singleAttachmentService      = PRV2Attachments;
  $scope.attachmentParam              = {
    requisition_id: prV2Function.getPRData()._id,
    attachmentId: null
  };
  $scope.attachmentUrl                = $filter('format')(pathConstants.apiUrls.purchaseRequisitionsV2.attachment.list,
                                        {requisition_id: prV2Function.getPRData()._id});
  $scope.waiting_on_user              = [];
  $scope.approvals                    = {};
  $scope.currentUser                  = $rootScope.currentUser || {_id:null};
  $scope.prAttachments                = [];
  $scope.isFirstCreate                = NewPRFirstLoad.isFirstLoad;
  $scope.profile                      = profile;
  $scope.prItemLimit                  = prConfig.pr_item_limit;

  $scope.getUpdatePRField             = getUpdatePRField;
  $scope.isShoppingMode               = isShoppingMode;
  $scope.back                         = back;
  $scope.getContactPerson             = getContactPerson;
  $scope.approvalsPreview             = approvalsPreview;
  $scope.checkWaitingOn               = checkWaitingOn;
  $scope.getEditingPRState            = getEditingPRState;
  $scope.isCurrentUserIsPAInApproval  = isCurrentUserIsPAInApproval;
  $scope.isCompanyIntegrated          = isCompanyIntegrated;
  $scope.hideMode                     = hideMode;
  $scope.canEditAttachment            = canEditAttachment;
  $scope.pcEditMode                   = pcEditMode;

  function hideMode(){
    return prV2Function.hidePRHeader();
  }

  function pcEditMode(){
    if (!!$rootScope.isV2Enabled) {
      return $scope.PR.status === 'edit' && prV2Function.getDocumentRule('is_price_comparison_process') && prV2Function.isPriceComparisonEnabledinCompanySettings();
    }

    return false;
  }

  /**
   * this function will check the state of the PR
   * as well as the Type of the logged in user
   */
  function checkIfPRIsEditable(){
    prV2Function.setEditableState(($scope.PR.status === 'draft') ? 'editable' : 'notEditable');
    if ($scope.currentUser._id !== $scope.PR.creator_info._id)
      prV2Function.setEditableState('notEditable');
  }

  /**
   * function returns checker for the states to hide or show the PR details
   * @returns {*|boolean}
   */
  function isShoppingMode() {
    $scope.PR = prV2Function.getPRData();
    return $state.includes('main.user.purchaseRequisitionsV2.details.catalog');
  }

  function back() {

    var prStatus = $scope.PR.status;

    $state.go('main.user.purchaseRequisitionsV2.manage', {
      status: prStatus,
      tab: 'waiting',
      extraFilterKey: prStatus === 'pending' ? 'criteria[waiting_on][]' : null,
      extraFilterValue: prStatus === 'pending' ? $rootScope.currentUser._id : null,
      cursor: null
    });
  }

  function getUpdatePRField() {
    return prV2Function.getLoading('updatePRField');
  }

  /**
   * return integration status for the PR company
   */
  function isCompanyIntegrated() {
    return prV2Function.getIntegrationStatus();
  }

  /**
   * Load All available costCenters fro the user profile and cache them
   * @returns {Array}
   */
  function loadCostCenters() {
    var allCostCenters = [];
    if (metabuyerCache.get('userCostCenters')) {
      allCostCenters = metabuyerCache.get('userCostCenters');
    } else {
      allCostCenters = $rootScope.currentUser.costCenters;
      metabuyerCache.put('userCostCenters', allCostCenters);
    }
    return allCostCenters;
  }

  /**
   * refine the list of Cost Centers to the ones available within the Company of the PR
   * the cost centers object in the User only holds the company Code, not ID, so the matching is using the unique code of the Company
   * @param allCostCenters
   * @returns {Array}
   */
  function loadSelectableCostCenters(allCostCenters) {
    var costCenters = [];
    var filteredCostCenters = [];
    _.forEach(allCostCenters, function (CC) {
      if (!!$scope.PR && !!$scope.PR.company &&
        (CC.shadow_company.code === $scope.PR.shadow_company.code) && (CC.is_active === 1)) {
        costCenters.push(CC);
      }
      if (!!$scope.PR && !!$scope.PR.company &&
        (!_.isEmpty(CC.shadow_company)) && (!CC.shadow_company._id) && (CC.is_active === 1)) {
        costCenters.push(CC);
      }
      if (!!$scope.PR && !!$scope.PR.company &&
        (CC.shadow_company == '') && (CC.is_active === 1)) {
        costCenters.push(CC);
      }
    });

    _.forEach(profile.role_assignments, function (roleAssignment) {
      _.forEach(costCenters, function (singleCostCenter) {
        if (singleCostCenter._id === roleAssignment.organization_id ||
            (singleCostCenter.company_id === $scope.PR.company._id &&
             singleCostCenter.company_id === roleAssignment.organization_id )) {
          if (UserPermissions.hasPermission(profile, 'PR', 'Create', roleAssignment.organization_id)) {
            if (filteredCostCenters.indexOf(singleCostCenter) < 0) {
                filteredCostCenters.push(singleCostCenter);
            }
          }
        }
      });
    });

    if(!!filteredCostCenters.length){
      return filteredCostCenters;
    }


    return costCenters;
  }

  function loadApprovalFlow() {
    if ($scope.PR.status !== 'draft') {
      Approvals.get({
        id: $scope.PR.approval_id
      }, function(resource){
        if(!!resource && !!resource.content && !!resource.content.data){
          $scope.approvals = resource.content.data;
          $scope.approvalFlowData = resource.content.data;
          globalFunc.getWaitingOnUser(resource.content.data, $scope.waiting_on_user);
          prV2Function.setApprovalFlow(resource.content.data);
        }
        else
          $scope.approvals = [];
      }, function (){
        $scope.approvals = [];
      });
    }
  }

  /**
   * Loading the Currencies options for the Company of the PR to select from
   * @param company
   */
  function loadCurrencies(company) {

    if (!!company._id) {
      companyCurrencies.get({
          id: company._id
        },
        function (resource) {
          if (!!resource.content && !!resource.content.data) {
            prV2Function.setCurrenciesData(resource.content.data);
          }
        }, function (error) {
          globalFunc.objectErrorMessage(error);
        });
    }
  }

  /**
   * get the approval flow to display it in draft
   */
  function approvalsPreview(){
    approvalPreview.get({
      company_code: $scope.PR.company.code,
      context_type: 'App\\Metabuyer\\Requisition\\Models\\Requisition',
      context_id: $scope.PR._id
    }, function(resource){
      // send to action directive
      $scope.approvalFlowData = resource.content.data;
      $scope.approvals = resource.content.data;
      prV2Function.setLoading('updatePRField', false);
      $timeout(function () {
        loadScrollbar();
      }, 100);
    }, function(error){
      globalFunc.objectErrorMessage('Failed to preview the approval flow');
      globalFunc.objectErrorMessage(error);
      prV2Function.setLoading('updatePRField', true);
    });

    purchaseRequisitionsV2Services.validateCapApproverLimit(
      {
        requisition_id: $scope.PR._id
      },
      function(resource){},
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function loadTax() {
    var localStorageTaxes = JSON.parse(window.localStorage.getItem('taxes'));
    if (!localStorageTaxes) {
      searchModule.get(
        {
          module: 'tax',
          'criteria[is_active]': 1,
          offset: 0
        },
        function (resource) {
          if (!!resource.content && !!resource.content.data) {
            window.localStorage.setItem('taxes', JSON.stringify(resource.content.data));
            prV2Function.setTaxData(resource.content.data);
          }
        }
      );
    }
    else
      prV2Function.setTaxData(localStorageTaxes);
  }

  function loadUOMs() {
    var localStorageTaxes = JSON.parse(window.localStorage.getItem('UOMs'));
    if (!localStorageTaxes) {
      uomList.get(
        {},
        function (resource) {
          if (!!resource.content && !!resource.content.data) {
            window.localStorage.setItem('UOMs', JSON.stringify(resource.content.data));
            prV2Function.setUOMData(resource.content.data);
          }
        }
      );
    }
    else
      prV2Function.setUOMData(localStorageTaxes);
  }

  /**
   * check the waiting on value
   * @returns {boolean}
   */
  function checkWaitingOn() {
    var find_user = _.find($scope.waiting_on_user, function (approver) {
      return (approver._id === profile._id);
    });

    return (find_user !== undefined);
  }

  /**
   * get the contact persons from service
   * the contactPerson setter is in the header directive
   * @returns {*}
   */
  function getContactPerson() {
    return prV2Function.getContactPerson();
  }

  function getPRAttachment() {
    PRV2Attachments.getListPRAttachment({
      requisition_id: prV2Function.getPRData()._id
    }, function(resource) {
      if (!!resource && !!resource.content)
        $scope.prAttachments = resource.content.data;
    }, function(error) {
      globalFunc.objectErrorMessage(error);
    })
  }

  /**
   * get the editable state for
   * @returns {*}
   */
  function getEditingPRState(){
    return prV2Function.getEditingState();
  }

  function activateTab(){
    var status = _.cloneDeep($scope.PR.status.toLowerCase()) || prV2Function.getPRData().status.toLowerCase();

    var tab = globalFunc.findInArray($scope.$parent.tabData, 'key', status);
    if(!tab)
      tab = globalFunc.findInArray($scope.$parent.tabData.more, 'key', status);
    return !!tab ? tab : $scope.$parent.tabData[0];
  }

  function isCurrentUserIsPAInApproval() {
    return (globalFunc.checkIfUserHasPARole($rootScope.currentUser));
  }

  function loadScrollbar() {
    var scrollbar = document.getElementById('scrollbar');
    var approvalFlow = document.getElementById('approval-flow');
    scrollbar.style.width = approvalFlow.scrollWidth + 'px';
  }

  function isPrIntegrated() {
    return !!prV2Function.getDocumentRule('is_integrated');
  }

  /**
   * Function to allow only PR creator and approver of that PR to add or delete attachment
   * @returns boolean
   */
  function canEditAttachment() {
    var disableEdit = true;
    //array to store user ID
    var users = [];
    // get the current user ID
    $scope.currentUser = $rootScope.currentUser._id;
    // get current PR data
    $scope.prData = prV2Function.getPRData();

    // return false if no PR data is found
    if (!$scope.prData) {
      return disableEdit;
    }

    // get the creator info ID
    users.push($scope.prData.creator_info._id);

    // get approval_related_users
    if (!!$scope.prData.approval_related_users) {
      _.forEach($scope.prData.approval_related_users, function (relatedUsers) {
          if (!users.includes(relatedUsers._id)) {
          users.push(relatedUsers._id);
        }
        });
    }

    var spkEnable = false;
    if ($scope.prData.expense_type.subtype != 'undefined' &&
    ($scope.prData.expense_type.subtype === 'SPK' || $scope.prData.expense_type.subtype === 'GWO')) {
      _.forEach(profile.role_assignments, function (roleAssignment) {
        if ($scope.prData.company._id === roleAssignment.organization_id &&
          globalFunc.findInArray(roleAssignment.roles, 'name', 'PR Requestor')) {
            spkEnable = true;
        }
      });
    }

    if (spkEnable) {
      users.push($rootScope.currentUser._id);
    }

    // check if current user is pr creator/ pr approver of this pr
    if (users.includes($scope.currentUser)) {
      disableEdit = false;
    }
    return disableEdit;
  }

  function initialize(){
    $scope.prBudgetId = (!!$scope.PR.budget && !!$scope.PR.budget._id) ? $scope.PR.budget._id : null;
    //set the company integration and mapping status
    prV2Function.setIntegrationStatus(isIntegratedCompany);
    prV2Function.setIntegrationSolution(integrationSolution);
    prV2Function.setCompanyMappingStatus(companyMappingStatus);

    var tabData = {
      tab: activateTab(),
      noTransition: true
    };

    //HACK: as the tabdata controller doesn't load in time and the the tabs will be deleted in the new design,
    //this is a work around to only load it after 300 milisecond
    // TODO: remove this with the new design, Ahmed Saleh (25/9/2017)
    $timeout(function() {
      $rootScope.$broadcast('loadSpecificTab', tabData);
    }, 300);

    checkIfPRIsEditable();
    loadApprovalFlow();
    getPRAttachment();
    loadUOMs();

    loadTax();

    var allCostCenters =  loadCostCenters();

    $scope.costCenters = loadSelectableCostCenters(allCostCenters);
    prV2Function.setPRsCCs($scope.costCenters);
    loadCurrencies($scope.PR.company);
    // prepare the list of items in the main PR object to the children objects
    if (!$scope.PR.pr_items)
      $scope.PR.pr_items = [];

    // clean up the error messages
    prV2Function.setErrorsOnSubmit([]);
    NewPRFirstLoad.unset();
    if ($scope.PR.status !== 'draft' && $scope.PR.status !== 'deleted') {
      $timeout(function () {
        loadScrollbar();
      }, 2000);
    }
  }

  $http.get($filter('format')(pathConstants.apiUrls.companies.single, {id: $scope.PR.company._id}), {
    params: {}
  }).then(function (response) {
    $scope.company = response.data.content.data;
  });

  initialize();

}
requisitionsV2DetailsCtrl.$inject = [
  '$scope', 'purchaseRequisition', 'metabuyerCache', '$rootScope', '$state', 'companyCurrencies',
  'prV2Function', 'approvalPreview', 'globalFunc', 'profile', 'Approvals', 'searchModule', 'singleCommentsHistory',
  'NewPRFirstLoad', 'PRV2Attachments', '$filter', 'pathConstants', '$timeout', 'isIntegratedCompany',
  'companyMappingStatus', 'uomList', 'integrationSolution', 'UserPermissions', 'prConfig', 'purchaseRequisitionsV2CommentService', '$http',
  'purchaseRequisitionsV2Services'
];

angular
  .module('metabuyer')
  .controller('requisitionsV2DetailsCtrl', requisitionsV2DetailsCtrl);
