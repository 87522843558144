'use strict';

function purchaseRequisitionsV2ActionCtrl(
  $scope, pathConstants, purchaseRequisitionsV2Services, $state, HighlightId, prV2Function, $rootScope, globalFunc, toastr,
  $stateParams, $filter, UserPermissions, generatePO, notificationSubscriber, PRNewComments, $uibModal, Companies, priceComparisonResource, $http
){

  $scope.submitBodyContent          = "";
  $scope.localApprovalData          = {};
  $scope.currentUser                = $rootScope.currentUser || { _id: null };
  $scope.nextPR                     = { context_id: 'loading' };
  $scope.userBasePath               = $rootScope.userImageBasePath;
  $scope.waitingOnUser              = [];
  $scope.templateUrlPath            = 'components/purchaseRequisitionV2Components/actionsV2/purchaseRequisitionV2Actions.html';
  $scope.module                     = 'PR';
  $scope.disableGeneratePO          = false;
  $scope.allowedToHoldPr            = !!prV2Function.getPRData().company.config.allowed_to_hold_PR;

  if ($rootScope.isMobileMode) {
    $scope.templateUrlPath = 'components/purchaseRequisitionV2Components/actionsV2/purchaseRequisitionV2Actions-mobile.html';
    if ($scope.actionType === 'button')
      $scope.templateUrlPath = 'components/purchaseRequisitionV2Components/actionsV2/purchaseRequisitionV2Actions-mobile-button.html';
  }
  $scope.itemMainCheckBox = {
    selected : false
  }

  $scope.checkBodyContent           = checkBodyContent;
  $scope.deletePR                   = deletePR;
  $scope.generatePurchaseOrder      = generatePurchaseOrder;
  $scope.approvalAction             = approvalAction;
  $scope.submitPurchaseRequisition  = submitPurchaseRequisition;
  $scope.transitionToListingPending = transitionToListingPending;
  $scope.approvalFlowData           = approvalFlowData;
  $scope.checkWaitingOn             = checkWaitingOn;
  $scope.isPRCreator                = isPRCreator;
  $scope.isPcCreator                = isPcCreator;
  $scope.checkIfUserCanCreatePC     = checkIfUserCanCreatePC;
  $scope.canSubmit                  = canSubmit;
  $scope.canUnHold                  = canUnHold;
  $scope.canApprove                 = canApprove;
  $scope.canDelete                  = canDelete;
  $scope.canPriceComparison         = canPriceComparison;
  $scope.canViewPriceComparison     = canViewPriceComparison;
  $scope.actionCondition            = actionCondition;
  $scope.refreshPRDetail            = refreshPRDetail;
  $scope.prAction                   = prAction;
  $scope.notificationToggle         = notificationToggle;
  $scope.duplicatePR                = duplicatePR;
  $scope.canMoveToDraftPR           = canMoveToDraftPR;
  $scope.moveToDraftPR              = moveToDraftPR;
  $scope.allowEditingPR             = allowEditingPR;
  $scope.setEditableMode            = setEditableMode;
  $scope.canHold                    = canHold;
  $scope.canReject                  = canReject;
  $scope.canWithdraw                = canWithdraw;
  $scope.canGeneratePO              = canGeneratePO;
  $scope.submitEditableDraft        = submitEditableDraft;
  $scope.requestEditableDraft       = requestEditableDraft;
  $scope.cancelEditingPR            = cancelEditingPR;
  $scope.allowedToRevertApproval    = allowedToRevertApproval;
  $scope.revertApproval             = revertApproval;
  $scope.revertApprovalErrorHandler = revertApprovalErrorHandler;
  $scope.canUpdateCollaborators     = canUpdateCollaborators;
  $scope.getIntegrationData         = getIntegrationData;
  $scope.updateIntegrationInfo      = updateIntegrationInfo;
  $scope.resendPrEmail              = resendPrEmail;
  $scope.canResendPrEmail           = canResendPrEmail;
  $scope.isIntegrationDetailsEditable = isIntegrationDetailsEditable;
  $scope.openIntegrationModal = openIntegrationModal;
  $scope.broadcastBack = broadcastBack;
  $scope.hasCmmsIntegration = hasCmmsIntegration;
  $scope.goToQuotation = goToQuotation;
  $scope.goToPriceComparison = goToPriceComparison;
  $scope.isPriceComparisonEnabled = prV2Function.getDocumentRule('is_price_comparison_process');
  $scope.itemMainCheckboxClicked = itemMainCheckboxClicked;
  $scope.price_comparison = [];
  $scope.isPriceComparisonEnabledinCompanySettings = prV2Function.isPriceComparisonEnabledinCompanySettings();
  $scope.paEditCanSave = $rootScope.isV2Enabled ? true : false;
  $scope.saveEditableDraft = saveEditableDraft;

  function canMoveToDraftPR() {
    var allowResubmit = prV2Function.getDocumentRule('allow_move_to_draft');
    // Cannot resubmit if has CMMS integration
    if (!allowResubmit) {
      return false;
    }

    if (($scope.PR.status.toLowerCase() === 'rejected' || $scope.PR.status.toLowerCase() === 'withdrawn') &&
      isPRCreator() && prV2Function.companyCanResubmitWithdrawnPR() && ($scope.PR.pc_id == '' || $scope.PR.pc_id === undefined)
    ) {
      return true;
    }

    return false;
  }

  function hasCmmsIntegration() {
    return prV2Function.hasCmmsIntegration();
  }

  /**
   * Check if the user is currently eligible to resend PR Approval Email
   * */
  function canResendPrEmail() {
    return $scope.PR.status.toLowerCase() === 'pending' &&
           (globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN') ||
            globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'ADMIN') ||
            isPRCreator());
  }

  /**
   * Sends an email to the current waiting on PR Approver
   * */
  function resendPrEmail() {
    var email_text = "Approval email will be resent to the following approvers\n\n";
    $scope.waitingOnUser.forEach(function (currentVal) {
      if (currentVal.is_active)
        email_text += currentVal.display_name + "\n";
    });

    swal ({
      title: "Email re-sent",
      text: email_text,
      type: "success",
      showCancelButton: false,
      confirmButtonColor: "#1ab394",
      confirmButtonText: "Ok",
      timer: 5000,
      closeOnConfirm: true
    }, function (isConfirm) {
      if (isConfirm) {
        $rootScope.$broadcast('refreshComments');
      }
    });
    return purchaseRequisitionsV2Services.resendPendingPrEmail(
      {
        requisition_id: $scope.PR._id
      }
    ).$promise;
  }

  /**
   * Check if the current PR has valid status to update the collaborator
   *
   * @param string currentPrStatus String of PR status
   * @returns {boolean}
   */
  function canUpdateCollaborators(currentPrStatus) {
    var validPRStatus = [
      'draft',
      'pending',
      'edit',
      'on_hold',
      'withdrawn',
      'rejected',
      'approved'
    ];

    return !_.isEmpty(_.intersection(validPRStatus, [currentPrStatus]));
  }

  function allowedToRevertApproval () {
    return $scope.PR.status.toLowerCase() === 'pending' && globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN');
  }

  function revertApproval(extraPayload) {
    return purchaseRequisitionsV2Services.resetApproval(
      {
        approval_id: $scope.PR.approval_id
      },
      extraPayload
    ).$promise;
  }

  function revertApprovalErrorHandler(message){
    globalFunc.objectErrorMessage(message);
  }

  function approvalFlowData() {
    $scope.localApprovalData = prV2Function.getApprovalFlow();
    if (!!$scope.localApprovalData && !!$scope.localApprovalData.waiting_on && !!$scope.localApprovalData.waiting_on.length)
      globalFunc.getWaitingOnUser($scope.localApprovalData, $scope.waitingOnUser);
    return $scope.localApprovalData;
  }

  function loadNextData() {
    if ($scope.PR.status === 'pending' || $scope.PR.status.toLowerCase() === 'on_hold') {
      /**
       * add the 2 extra params for Hold and Reject PRs
       * @type {any}
       */
      $scope.onHoldPRExtraParams = prepareOnHoldExtraParams();
      $scope.rejectPRExtraParams = {};
      $scope.revertExtraParams = {};
      globalFunc.nextAvailableAction('Metabuyer\\PR\\PR', $scope.PR._id).then(function (data) {
        if (!!data && !!data.waitingOnObject && !!data.waitingOnObject.context_id) {
          $scope.nextPR = data.waitingOnObject;
          $scope.totalPRs = data.count;
        }
        else
          $scope.nextPR = {};
      });
    }
  }

  /**
   * Refresh PR detail page
   */
  function refreshPRDetail(){
    $state.transitionTo($state.current, $stateParams, {
      reload: true,
      inherit: false,
      notify: true
    });
  }

  function notificationToggle() {

    if(!$scope.PR.notify) {
      notificationSubscriber.post({
          object_id: $scope.PR._id, class: 'Metabuyer\\PR\\PR', method: 'subscribe'
        }
        , function (data) {
          $scope.PR.notify = true;
          toastr.success('Turned on notifications successfully');
        }
        , function (error) {
          toastr.error('Failed to turn on notifications');
        });
    }
    else{
      notificationSubscriber.delete({
          object_id: $scope.PR._id, class: 'Metabuyer\\PR\\PR', method: 'unsubscribe'
        }
        , function (data) {
          $scope.PR.notify = false;
          toastr.success('Turned off notifications successfully');
        }
        , function (error) {
          toastr.error('Failed to turn off notifications');
        });
    }

  }

  function checkCanDuplicate() {
    $scope.canDuplicate = false;

    // if user is the creator of the PR, allow to duplicate regardless of company setting
    if (isPRCreator() || $scope.PR.requestor._id === $scope.currentUser._id) {
      $scope.canDuplicate = true;
    }

    if (isUserFromSameCompany()) {
      $scope.canDuplicate = true;
    }

    if (globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN') ||
      globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'ADMIN')) {

      Companies.get(
        {
          id: $scope.PR.company._id
        },
        function (resource) {
          if (!!resource && !!resource.content && !!resource.content.data) {
            var result = resource.content.data;

            // first layer check with company setting
            if (!!result.requisition_settings.duplicate_others_pr_within_cost_center.is_allowed) {
              // second layer check if user has the permission to duplicate within cost center / company
              if (!!UserPermissions.checkPermissionsAccess($scope.currentUser, 'PR', 'Create', [$scope.PR.company._id])) {
                if (!$scope.PR.cost_center) { // 'Multiple' cost center
                  $scope.canDuplicate = true;
                } else {
                  _.forEach($scope.currentUser.costCenters, function (costCenter) {
                    if (!!$scope.PR.cost_center && costCenter._id === $scope.PR.cost_center._id) {
                      $scope.canDuplicate = true;
                    }
                  })
                }
              }
            }
          }
        }
      );
    }
  }

  // check if current user has pr requestor role and current user can access pr's company
  function isUserFromSameCompany() {
    return checkUserAccessToCompany() && checkUserRolePermissionInCompany();
  }

  // only PA and PR Requestor allow to duplicate PR
  function checkUserRolePermissionInCompany() {
    var groupFound = _.find($scope.currentUser.role_assignments, function(assignment){
      if (assignment.organization_id === prV2Function.getPRData().company._id) {
        var requesterFound =_.find(assignment.roles, function(role){
          return (role.name === 'PR Requestor' || role.name === 'PA' );
        });
        return !!requesterFound;
      }
    });

    return !!groupFound;
  }

  function checkUserPaRolePermissionInCompany() {

    var checkPRStatus = ($scope.PR.status.toLowerCase() === 'pending');
    var isApprover    = globalFunc.findInArray($scope.localApprovalData.waiting_on, '_id', $rootScope.currentUser._id);
    var canPc = false;

    if (checkPRStatus && isApprover) {
      var currentStep = $scope.localApprovalData.current_step;
      var step = $scope.localApprovalData.steps[currentStep];

      if (!!step.positions) {
        _.forEach(step.positions, function(position) {
          if (!!position.rules && position.rules.no_pricing_available === 'Y' || position.position_type === 'ad-hoc') {
            canPc = true;
            return
          }
        });
      }
    }

    return canPc;
  }

  function duplicatePR() {
    if ($scope.pritemlimit > 0 && $scope.PR.pr_items.length > $scope.pritemlimit) {
      toastr.error('Unable to duplicate. PR line item count is over the line item limit. Only '
        + $scope.pritemlimit + ' line items allowed per PR');

      return;
    }

    purchaseRequisitionsV2Services.duplicate({
      requisition_id: $scope.PR._id
    }, function (resource) {
      if (!!resource && !!resource.content && !!resource.content.data)
        $state.go('main.user.purchaseRequisitionsV2.details', { id: resource.content.data._id });
    }, function (error) {
      globalFunc.objectErrorMessage(error.data);
    })
  }

  function moveToDraftPR(){
    purchaseRequisitionsV2Services.moveToDraft(
      {
        requisition_id: $scope.PR._id
      }, function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data)
          $state.reload();
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      })
  }

  /**
   * Preparing on hold extra params
   * @returns {{user_ids: Array}}
   */
  function prepareOnHoldExtraParams() {
    var userIDArray = [];
    userIDArray.push($scope.PR.creator_info._id);
    if ($scope.PR.requestor._id !== $scope.PR.creator_info._id)
      userIDArray.push($scope.PR.requestor._id);

    return {user_ids: userIDArray};
  }

  function isPRCreator() {
    return ($scope.currentUser._id === $scope.PR.creator_info._id);
  }

  /**
   * check the statuses of the items in which the PR is still approvable
   * a list of string for all the statuses that can be approved
   * @returns {boolean}
   */
  function checkItemsApprovability() {
    var items = prV2Function.getPRData().pr_items;
    var unavailableItems = prV2Function.getPRData().unavailable_item_lines || [];
    var approvalableStatuses = ['0', '11', '12', '13', '14', '18', '19'];

    if (!!unavailableItems && unavailableItems.length > 0) {
      return false;
    }
    if (!items || items.length < 1) {
      return false;
    }
    for (var i in items) {
      if (_.isUndefined(items[i].item_validity_status))
        return false;

      if (approvalableStatuses.indexOf(items[i].item_validity_status.toString()) === -1)
        return false;
    }
    return true;
  }

  /**
   * Function to check PR data for changes and approvability
   * @returns {boolean}
   */
  function checkPRDataApprovability(){
    var pr = prV2Function.getPRData();
    if(!!pr.pr_related_data_changes)
      return false;

    return true;
  }

  function hasWarningBlockDisableApproveEffect() {
    var pr = prV2Function.getPRData();
    return !!pr.warning_block &&
      !!pr.warning_block.effects &&
      pr.warning_block.effects.indexOf('DISABLE_APPROVE') !== -1;
  }

  function canGeneratePO() {
    return (
      ($scope.PR.status.toLowerCase() === 'approved') &&
      (!$scope.PR.pos || (!!$scope.PR.pos && !$scope.PR.pos.length)) &&
      (
        globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN')
      )
    );
  }

  function canApprove() {
    var checkPRStatus = ($scope.PR.status.toLowerCase() === 'pending');
    var isApprover    = globalFunc.findInArray($scope.localApprovalData.waiting_on, '_id', $rootScope.currentUser._id);
    var PRApprovable  = checkItemsApprovability();
    var PRDataChanged = checkPRDataApprovability();
    var hasWarningBlockEffect = hasWarningBlockDisableApproveEffect();

    return checkPRStatus && !!isApprover && PRDataChanged && PRApprovable && checkUserAccessToCompany() && !hasWarningBlockEffect;
  }

  function canUnHold() {
    var approverAbleToHoldPR = true;
    var checkPRStatus = ($scope.PR.status.toLowerCase() === 'on_hold');
    var isApprover = globalFunc.findInArray($scope.localApprovalData.waiting_on, '_id', $scope.currentUser._id);
    var selectedCompany = globalFunc.findInArray($scope.currentUser.companies, '_id', $scope.PR.company._id);

    if (!!selectedCompany && !!selectedCompany.config && !!selectedCompany.config.allowedToHoldPR) {
      approverAbleToHoldPR = selectedCompany.config.allowedToHoldPR;
    }

    return (
      checkPRStatus &&
      (
        (((approverAbleToHoldPR && !!isApprover) || canOverrideForAction('hold')) && checkUserAccessToCompany()) ||
        !!globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN')
      ));
  }

  function canHold() {
    var approverAbleToHoldPR = true;
    var checkPRStatus = ($scope.PR.status.toLowerCase() === 'pending');
    var isApprover = globalFunc.findInArray($scope.localApprovalData.waiting_on, '_id', $scope.currentUser._id);
    var selectedCompany = globalFunc.findInArray($scope.currentUser.companies, '_id', $scope.PR.company._id);
    if (!!selectedCompany && !!selectedCompany.config && !!selectedCompany.config.allowedToHoldPR) {
      approverAbleToHoldPR = selectedCompany.config.allowedToHoldPR;
    }

    return (
      checkPRStatus &&
      (
        (((approverAbleToHoldPR && !!isApprover) || canOverrideForAction('hold')) && checkUserAccessToCompany()) ||
        !!globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN')
      ));
  }

  function canReject() {
    var checkPRStatus = ($scope.PR.status.toLowerCase() === 'pending');
    var isApprover    = globalFunc.findInArray($scope.localApprovalData.waiting_on, '_id', $scope.currentUser._id);

    return (checkPRStatus && !!isApprover && checkUserAccessToCompany());
  }

  function canWithdraw() {
    var checkPRStatus = ($scope.PR.status.toLowerCase() === 'pending');
    return (
      checkPRStatus &&
      (
        $scope.PR.creator_info._id === $scope.currentUser._id ||
        (!!$scope.PR.requestor && ($scope.PR.requestor._id === $scope.currentUser._id)) ||
        canOverrideForAction('withdraw')
      ) && ($scope.PR.pc_id == '' || $scope.PR.pc_id === undefined)
      );
  }

  function canSubmit() {
    var pr = getUpdatedPRData();
    return (
      isPRCreator() &&
      (!!pr.pr_items && !!pr.pr_items.length) &&
      pr.status.toLowerCase() === 'draft'
    );
  }

  function checkBudget(pr) {
    var prItems = pr.pr_items;
    var budgetMandatory = prV2Function.getDocumentRule('is_budget_mandatory');

    if (!!budgetMandatory){
      for (var i in prItems) {
        if (_.isEmpty(prItems[i].budget)) {
          return false
        }
      }
    }

    return true;
  }

  function checkAccountCode(pr) {
    var prItems = pr.pr_items;
    var requisitionSettings = pr.company.requisition_settings;

    if (!!requisitionSettings && !!requisitionSettings.account_code) {
      if (requisitionSettings.account_code.is_mandatory) {
        return !globalFunc.findInArray(prItems, 'account_code', null);
      }

      return true;
    }

    return true;
  }

  function checkErpOrderType(pr) {
    var hasIntegrationSolution = pr.company.config.integration_solution === 'MB-JDE-JDE';

    if (prV2Function.getIntegrationStatus() && hasIntegrationSolution) {
      return !!pr.erp_order_type;
    }

    return true;
  }

  function checkErpCostCenter(pr) {
    var requisitionSettings = pr.company.requisition_settings;

    if (!!requisitionSettings && !!requisitionSettings.erp_cost_center) {
      if (!!requisitionSettings.erp_cost_center && requisitionSettings.erp_cost_center.is_mandatory) {
        return !!pr.erp_cost_center;
      }

      return true;
    }

    return true;
  }

  function checkShadowCompany(pr) {
    var selectedBillingCompany = !!pr.shadow_company ? pr.shadow_company : pr.company.shadow_companies[0];
    _.forEach(pr.company.shadow_companies, function (company) {
      if (selectedBillingCompany._id !== company._id) {
        return false;
      }
    });

    return true;
  }

  /**
   * check permission and pr status to delete
   * @returns {*|boolean}
   */
  function canDelete() {
    var pr = getUpdatedPRData();
    var allowDelete = prV2Function.getDocumentRule('allow_delete') !== false;
    return (isPRCreator() && pr.status.toLowerCase() === 'draft' && allowDelete);
  }

  /**
   * validate to see if PC creator is a PR requestor or is in the approval flow and has PC requester role
   * @returns {*|boolean}
   */
  function checkIfUserCanCreatePC() {
    if(globalFunc.arrayOfObjectToString($scope.PR.approval_related_users, '_id').includes($rootScope.currentUser._id) || $rootScope.currentUser._id === $scope.PR.requestor._id)
    {
      return true;
    }
    else{
      return false;
    }
  }

  /**
   * check if user has PC requester role
   * @returns {*|boolean}
   */
  function isPcCreator() {
    var groupFound = _.find($rootScope.currentUser.role_assignments, function(assignment){
    if (assignment.organization_id === $scope.PR.company._id) {
      var requesterFound =_.find(assignment.roles, function(role){

        return role.name === 'Price Comparison Requester';
      });
      return !!requesterFound;
    }

  });
    return !!groupFound;
  }

  function canPriceComparison() {
    if (!!UserPermissions.checkPermissionsAccess($scope.currentUser, 'PriceComparison', 'Create', [$scope.PR.company._id]) && checkUserPaRolePermissionInCompany() && prV2Function.getPRData().no_pricing_available && ($scope.PR.pc_id == '' ||  $scope.PR.pc_id == undefined)) {
      return true;
    }
    return false
  }

  function canViewPriceComparison() {
    if ($scope.PR.pc_id != '') {

      if ($scope.price_comparison.status == 'PENDING' || $scope.price_comparison.status == 'DRAFT' || $scope.price_comparison.status == 'WITHDRAWN' || $scope.price_comparison.status == 'APPROVED' || $scope.price_comparison.status == 'REJECTED') {
        return true;
      }
    }
    return false
  }

  function getUpdatedPRData() {
    return prV2Function.getPRData();
  }

  function checkWaitingOn() {
    return true;
  }

  function checkBodyContent() {
    var bodyContent = "This is an <span class='text-danger'>UNBUDGETED</span> purchase which may require approval " +
                      "from higher authority i.e. SVP/EVP/CFO/PCOO.<br>" +
                      "Valid justification must be provided. Confirm to proceed?<br><br>" +
                      "Pembelian ini <span class='text-danger'>TANPA ANGGARAN</span>, dimana akan memerlukan persetujuan " +
                      "dari otoritas yang lebih tinggi yaitu SVP/EVP/CFO/PCOO.<br>" +
                      "Penjelasan yang valid harus diberikan. Konfirmasi untuk melanjutkan?"
    var expenseTypeCode = "";
    var pr = getUpdatedPRData();
    if (!!pr) {
      if (!!pr.expense_type && !!pr.expense_type.code) {
        expenseTypeCode = pr.expense_type.code;
      }
    }
    if (expenseTypeCode === 'CAPEXUNBUD') {
      $scope.submitBodyContent = bodyContent;
    }
  }

  /**
   * Function for deleting PR
   */
  function deletePR(){
    swal(
      {
        title: 'Confirm delete PR',
        text: 'This PR will be deleted',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Delete',
        closeOnConfirm: true
      },
      function (isConfirm) {
        if (isConfirm) {
          if (!!$scope.PR) {
            purchaseRequisitionsV2Services.deleteSinglePR({
              requisition_id: $scope.PR._id
            }, function () {
              toastr.success('PR deleted');
              $state.go('main.user.purchaseRequisitionsV2.manage', {status: 'draft', tab: 'all'});
            }, function (error) {
              globalFunc.objectErrorMessage(error);
            })
          }
        }
      }
    );
  }

  /**
   * Function for generating PO manually in cases where PO failed to generate
   * @param PRId
   */
  function generatePurchaseOrder(PRId){
    $scope.disableGeneratePO = true;

    generatePO.post({
      id: PRId
    }, function (resource) {
      if (!!resource && !!resource.content && !!resource.content.message) {
        toastr.success(resource.content.message);
        $state.reload();
      }
    }, function (error) {
      $scope.disableGeneratePO = false;
      globalFunc.objectErrorMessage(error);
    })
  }

  /**
   * allow the editing button to appear
   * @returns {*}
   */
  function allowEditingPR() {
    if (prV2Function.getPRData().status === 'pending') {
      var isPA = prV2Function.checkCurrentApproverIsPA(
        !!prV2Function.getPRData() ? prV2Function.getPRData().waiting_on : null,
        !!$rootScope.currentUser ? $rootScope.currentUser._id : null,
        !!$rootScope.currentUser ? $rootScope.currentUser.role_assignments : null,
        prV2Function.getPRData().company._id
      );

      var isProcurementSpecialist = prV2Function.isProcurementSpecialist(
        !!$rootScope.currentUser ? $rootScope.currentUser.role_assignments : null
      );

      return isPA || isProcurementSpecialist;
    }

    return false;
  }

  function setEditableMode(mode){
    prV2Function.setEditableState(mode);
  }

  function prAction(title, action) {
    swal(
      {
        title: title,
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (is_confirm) {
        if (is_confirm) {
          if (action === 1)
            approvalAction('approve', 'You have approved this purchase requisition');
          if (action === 3)
            approvalAction('release', 'You have removed the hold from this purchase requisition', {});
        }
      }
    );
  }

  /**
   * function returns the approval resource so it can be used on other module
   *
   * @param status
   * @param text
   * @param extraPayload in some cases the action needs a comment or such extra payload passed to the backend
   * @returns {*|{method, params, url}}
   */
  function approvalAction(status, text, extraPayload) {

    return purchaseRequisitionsV2Services.approvalAction(
      {
        approval_id: $scope.PR.approval_id,
        status: status
      },
      extraPayload,
      function () {
        if (!!text)
          toastr.success(text);
        else
          toastr.success('You have submitted this purchase requisition.');
      }
    ).$promise;
  }

  /**
   * the condition to pass to the directive to check if the action is ready to fire
   * @returns {boolean}
   */
  function actionCondition(){
    // check if PA and missing Tax
    // if (checkPurchaseAgentRole() && checkIfMissingTax()) {
    //   toastr.error('Item(s) are missing a Tax. Please complete them before approving');
    //   return false;
    // }
    return true;
  }

  function submitNewComment() {
    var content = $scope.PR.comment.content;
    var userIds = $scope.PR.comment.mentionedIdArray;

    PRNewComments.post({
        referenceId: $scope.PR._id
      },
      {
        comment: content,
        mentionedUserIds: userIds
      });
  }

  /**
   * Submit PR
   * @param ignoreOverBudget (0-do not ignore over budget; 1-do ignore over budget)
   */
  function submitPurchaseRequisition(ignoreOverBudget) {
    submitValidation();
    $scope.grandTotal = prV2Function.getPRData().grand_total;

    if (!!prV2Function.getPRData().comment && prV2Function.getPRData().comment !=='') {
      submitNewComment();
    }

    var params = {      data: {
        ignore_over_budget: ignoreOverBudget
      }};

    return purchaseRequisitionsV2Services.submitPR({
      requisition_id: $scope.PR._id
    }, params, function (response) {
      transitionToListingPending();
    }, function (error) {
      var message = (!!error.data && !!error.data.content && !!error.data.content.message) ?
        error.data.content.message : null;
      switch (message) {
        // case where over budget detected, but is allowed
        case 'Overbudget is allowed.':
          userToConfirmOverbudget();
          break;
        // case where over budget detected, but is not allowed further action
        case 'Overbudget is not allowed.':
          alertUserOnOverBudgetNotAllowed();
          break;
      }
    }).$promise;
  }

  /**
   * pop up to ask user if the over budget pr submit is confirmed
   */
  function userToConfirmOverbudget() {
    swal(
      {
        title: 'Confirm submission?',
        text: 'This requisition exceeds the available budget amount, confirm the submission?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        closeOnConfirm: true
      },
      function (isConfirm) {
        if (isConfirm) {
          submitPurchaseRequisition(true);
        }
      }
    );
  }

  /**
   * pop to notify the user over-budget is not allowed
   */
  function alertUserOnOverBudgetNotAllowed() {
    swal(
      {
        title: 'Available budget exceeded.',
        text: 'This requisition exceeds the available budget amount.',
        type: 'info',
        confirmButtonColor: '#D0D0D0',
        confirmButtonText: 'Close',
        closeOnConfirm: true
      }
    );
  }

  function submitValidation() {
    var pr = getUpdatedPRData();
    var errors = [];
    if (!!pr) {
      // validate expense type
      if (!pr.expense_type || (!!pr.expense_type && !pr.expense_type.code)) {
        errors.push('expense_type');
        toastr.error('Expense type is required.');
      }

      if (!pr.contact_person) {
        errors.push('contact_person');
        toastr.error('Contact person is required.');
      }

      if (_.isEmpty(pr.title)) {
        errors.push('title');
        toastr.error('PR Title is required.');
      }

      if (!checkAccountCode(pr)) {
        errors.push('account_code');
        toastr.error('Account code is required.');
      }

      if (!checkErpOrderType(pr)) {
        errors.push('erp_order_type');
        toastr.error('ERP Order Type is required.');
      }

      if (!checkErpCostCenter(pr)) {
        errors.push('erp_cost_center');
        toastr.error('ERP Cost Center is required.');
      }

      if (!checkBudget(pr)){
        errors.push('budget');
        toastr.error('Budget is required.');
      }

      if(!checkShadowCompany(pr)) {
        errors.push('billing company');
        toastr.error('Billing company is inactive.');
      }

    }

    prV2Function.setErrorsOnSubmit(errors);
    return !!(errors.length);
  }

  function transitionToListingPending(){
    $state.go('main.user.purchaseRequisitionsV2.manage', {status: 'pending', tab: 'all'});
    HighlightId.setId($scope.PR._id);
  }

  function broadcastBack() {
    $rootScope.$broadcast('backFromPr');
  }

  function requestEditableDraft(){
    purchaseRequisitionsV2Services.requestEditableCopy({
      requisition_id: prV2Function.getPRData()._id
    },{}, function (resource) {
      if(!!resource.content && !!resource.content.data) {
        prV2Function.setPRData(resource.content.data);
        setEditableMode('editable');
        // Refresh attachment stuff
        $scope.$emit('loadAtt');
        $rootScope.$broadcast('paEditMode');
      }
      else
        globalFunc.objectErrorMessage('Something went wrong')
    }, function (e) {
      globalFunc.objectErrorMessage(e);
    });
  }

  function submitEditableDraft(){
    swal(
      {
        title: 'Confirm submit edited PR?',
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        closeOnConfirm: true
      },
      function (isConfirm) {
        if (isConfirm) {
          $scope.grandTotal = prV2Function.getPRData().grand_total;

          if ($scope.isPriceComparisonEnabledinCompanySettings) {
            priceComparisonResource.submitEditableCopy({
              requisition_id: prV2Function.getPRData()._id
            }, {}, function (resource) {
              if (!!resource.content && !!resource.content.data) {
                prV2Function.setPRData(resource.content.data);
                setEditableMode('notEditable');
                $state.reload();
              }
              else
                globalFunc.objectErrorMessage('Something went wrong')
            }, function (e) {
              globalFunc.objectErrorMessage(e);
            });

          } else {
            purchaseRequisitionsV2Services.submitEditableCopy({
              requisition_id: prV2Function.getPRData()._id
            }, {}, function (resource) {
              if (!!resource.content && !!resource.content.data) {
                prV2Function.setPRData(resource.content.data);
                setEditableMode('notEditable');
                $state.reload();
              }
              else
                globalFunc.objectErrorMessage('Something went wrong')
            }, function (e) {
              globalFunc.objectErrorMessage(e);
            });
          }

        }
      }
    );
  }

  function saveEditableDraft(){
    $state.reload();
  }

  function cancelEditingPR() {
    purchaseRequisitionsV2Services.cancelEditableCopy({
      requisition_id: prV2Function.getPRData()._id
    }, {}, function (resource) {
      if(!!resource.content && !!resource.content.data) {
        prV2Function.setPRData(resource.content.data);
        setEditableMode('notEditable');
        $state.reload();
      }
      else
        globalFunc.objectErrorMessage('Something went wrong')
    }, function (e) {
      globalFunc.objectErrorMessage(e);
    });
  }

  function getIntegrationData() {
    return prV2Function.getIntegrationDependencies;
  }

  /**
   * The condition for the feature are:
   * 1- integrated company.
   * 2- Super tenant admin role.
   * 3- Erp cost center has not been selected.
   *
   * @returns {boolean}
   */
  function updateIntegrationInfo() {
    return (!!prV2Function.getIntegrationStatus() &&
    !!globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN')
    && (!prV2Function.getPRData().erp_cost_center || !prV2Function.getPRData().erp_cost_center._id))
  }
  /**
   * Check user access to the company
   * @returns {boolean}
   */
  function checkUserAccessToCompany() {
    return !!globalFunc.findInArray(
      $scope.currentUser.companies,
      'code',
      prV2Function.getPRData().company.code
    );
  }

  function canOverrideForAction(action) {
    var overrideIsAllowed = false;

    if (!!$scope.PR.company.requisition_settings) {
      if (action === 'withdraw' && !!$scope.PR.company.requisition_settings.pa_override_withdraw_pr) {
        overrideIsAllowed = $scope.PR.company.requisition_settings.pa_override_withdraw_pr.is_allowed;
      }
      else if (action === 'hold' && !!$scope.PR.company.requisition_settings.pa_override_hold_pr){
        overrideIsAllowed = $scope.PR.company.requisition_settings.pa_override_hold_pr.is_allowed;
      }

      return (
        (
          !!globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'PA') ||
          !!globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'PROCUREMENT_SPECIALIST')
        ) &&
        overrideIsAllowed);
    }

    return false;
  }

  function isIntegrationDetailsEditable() {
    return (!!prV2Function.getIntegrationStatus() &&
      $scope.PR.status.toLowerCase() !== 'draft' &&
      !!globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN'));
  }

  function openIntegrationModal() {
    $uibModal.open({
      templateUrl: "components/purchaseRequisitionV2Components/integrationsV2/editInfoV2/editIntegrationV2Modal.html",
      backdrop: 'static',
      keyboard: false,
      controller: editIntegrationInfoV2Modal
    })
  }

  function goToQuotation() {
    $state.go('main.user.purchaseRequisitionsV2.details.quotation');
  }

  function goToPriceComparison() {
    if (!!$scope.PR.pc_id) {
      $state.go('main.user.priceComparison.detail', { id: $scope.PR.pc_id });
    } else {
      swal({
          title: 'Create Price Comparison?',
          text: 'Would you like to create Price Comparison?',
          type: 'info',
          showCancelButton: true,
          confirmButtonColor: '#1ab394',
          confirmButtonText: 'Confirm',
          closeOnConfirm: true
        },
        function(isConfirm) {
          if (isConfirm) {
            priceComparisonResource.post({
              pr_id: $scope.PR._id
            },
            function(resource) {
              if (!!resource.content && !!resource.content.data) {
                var pc = resource.content.data;
                $state.go('main.user.priceComparison.detail', { id: pc._id, priceComparison: pc });
              }
            },
            function(error) {
                globalFunc.objectErrorMessage(error);
            });
          }
        }
      );
    }
  }

  function itemMainCheckboxClicked(value) {
    $rootScope.$broadcast('itemMainCheckboxClickedFrom', value);
  }

  $rootScope.$on('itemMainCheckboxClickedTo', function (event, value) {
    $scope.itemMainCheckBox.selected = value;
  });

  function initialize() {
    loadNextData();
    checkCanDuplicate();

    if ($scope.PR.pc_id !== undefined && $scope.PR.pc_id !== '') {
      $http.get($filter('format')(pathConstants.apiUrls.priceComparison.single, {id: $scope.PR.pc_id}), {
        params: {}
      }).then(function (response) {
        $scope.price_comparison = response.data.content.data;
      });
    }
  }

  initialize();

  $scope.$watch('editingState', function (newValue) {
    // Editing mode on the actions only need to change the title
    // and show hide the button
    $scope.editingState = newValue;
  });
}

purchaseRequisitionsV2ActionCtrl.$inject = [
  '$scope', 'pathConstants', 'purchaseRequisitionsV2Services', '$state', 'HighlightId', 'prV2Function', '$rootScope',
  'globalFunc', 'toastr', '$stateParams', '$filter', 'UserPermissions', 'generatePO', 'notificationSubscriber',
  'PRNewComments', '$uibModal', 'Companies', 'priceComparisonResource', '$http'
];

angular
  .module('metabuyer')
  .directive('purchaseRequisitionV2Actions',function() {
    return {
      restrict: 'E',
      scope: {
        editingState: '=',
        PR: '=pr',
        actionType: '@',
        pritemlimit: '=?'
      },
      template: '<div ng-include="templateUrlPath"></div>',
      controller: 'purchaseRequisitionsV2ActionCtrl'
    }
  })
  .controller('purchaseRequisitionsV2ActionCtrl', purchaseRequisitionsV2ActionCtrl);
