'use strict';

/**
 *
 * @param $scope
 * @param $rootScope
 * @param itemGroupMasterResource
 * @param $uibModalInstance
 * @param toastr
 * @param globalFunc
 */
function addItemGroupCtrl($scope, $rootScope, $uibModalInstance, toastr, globalFunc, itemGroupMasterResource, $http, $filter, pathConstants) {

  $scope.submitted = false;
  $scope.closeModal = closeModal;
  $scope.submitItemGroup = submitItemGroup;
  $scope.onSelectItemCategories = onSelectItemCategories;
  $scope.onSelectItemCodes = onSelectItemCodes;
  $scope.onRemoveItemCategories = onRemoveItemCategories;
  $scope.onRemoveItemCodes = onRemoveItemCodes;
  $scope.refreshItemCategories = refreshItemCategories;
  $scope.refreshItemCodes = refreshItemCodes;
  $scope.itemGroup = {};
  $scope.itemGroup.is_active = true;
  $scope.selectedItemCodes = [];
  $scope.selectedItemCategories = [];
  $scope.itemCodesList = [];

  function closeModal() {
    $uibModalInstance.close();
  }

  function preparePayload() {
    var data = {
      code: $scope.itemGroup.code,
      name: $scope.itemGroup.name,
      is_active: $scope.itemGroup.is_active,
      item_codes: $scope.selectedItemCodes.length > 0 ? $scope.selectedItemCodes : undefined,
      item_categories: $scope.selectedItemCategories.length > 0 ? $scope.selectedItemCategories : undefined
    };

    return data;
  }

  function submitItemGroup() {
    $scope.submitted = true;
    if (!validate($scope.itemGroup)) {
      var data = preparePayload();
      itemGroupMasterResource.post(
        data,
        function (success) {
          toastr.success('Item Group successfully created.');
          $uibModalInstance.close(success);
          $scope.submitted = false;
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    }
  }

  function validate(itemGroupDetails) {
    var anyError = false;
    if (!itemGroupDetails.code) {
      toastr.error('Item Group code is required.');
      anyError = true;
    }
    if (!itemGroupDetails.name) {
      toastr.error('Item Group name is required.');
      anyError = true;
    }
    return anyError;
  }

  function onSelectItemCategories(category){
    $scope.selectedItemCategories.push({
      _id: category._id,
      code: category.code,
      name: category.name,
      descr: category.descr
    });
    // Clear typeahead array when selecting
    $scope.itemCategoriesList = [];
  }

  function onRemoveItemCategories(cat){
    var delIndex = _.findIndex($scope.selectedItemCategories, function(e){
      return e._id === cat._id;
    });
    $scope.selectedItemCategories.splice(delIndex, 1);
  }

  function refreshItemCategories(val){
    if (!!val && val.length > 2){
      itemCategoriesListing(val).then(function(response){
        $scope.itemCategoriesList = response;
      })
    }
  }

  /**
   * the type ahead for the item categories
   * @param val
   * @returns {*|webdriver.promise}
   */
  function itemCategoriesListing(val) {
    if (!!val && val.length > 2) {
      $scope.loading = true;

      var moduleParam = {
        module: 'item-category'
      };
      var additionalParams = {
        'criteria[0][is_active]': true,
        'criteria[1][name]': val,
        'criteria[1][code]': val,
        'criteria[1][$operator]': 'or',
        criteria_operator: 'and',
        offset: 10
      };
      return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: additionalParams,
        ignoreLoadingBar: true
      }).then(function (response) {
        if (response.data.content.data.length === 0) {
          return [];
        } else {
          return response.data.content.data.map(function (item) {
            return item;
          });
        }
      });
    }
  }

  function onSelectItemCodes(itemCode){
    $scope.selectedItemCodes.push({
      _id: itemCode._id,
      code: itemCode.code,
      name: itemCode.name
    });
    // Clear typeahead array when selecting
    $scope.itemCodesList = [];
  }

  function onRemoveItemCodes(code){
    var delIndex = _.findIndex($scope.selectedItemCodes, function(e){
      return e._id === code._id;
    });
    $scope.selectedItemCodes.splice(delIndex, 1);
  }

  function refreshItemCodes(val){
    if (!!val && val.length > 2){
      itemCodesListing(val).then(function(response){
        $scope.itemCodesList = response;
      })
    }
  }

  /**
   * the type ahead for item master search
   * @param val
   * @returns {*|webdriver.promise}
   */
  function itemCodesListing(val) {
    if (!!val && val.length > 2) {
      $scope.loading = true;

      var moduleParam = {
        module: 'item-master'
      };

      var additionalParams = {
        'criteria[1][code]': val,
        'criteria[1][name]': val,
        'criteria[1][$operator]': 'or',
        offset: 100
      };

      return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: additionalParams,
        ignoreLoadingBar: true
      }).then(function (response) {
        if (response.data.content.data.length === 0) {
          $scope.supplierSearching = false;
          return [];
        } else {
          return response.data.content.data.map(function (item) {
            $scope.supplierSearching = false;
            return item
          });
        }
      });
    }
  }
}

addItemGroupCtrl.$inject = ['$scope', '$rootScope', '$uibModalInstance', 'toastr', 'globalFunc', 'itemGroupMasterResource', '$http', '$filter', 'pathConstants'];
angular.module('metabuyer')
  .controller('addItemGroupCtrl', addItemGroupCtrl);
