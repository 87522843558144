'use strict';

/**
 * Controller for PO Consolidation Item listing
 * @param $scope
 * @param $rootScope
 * @param $filter
 * @param dataList
 * @param $state
 * @param $stateParams
 * @param metabuyerCache
 * @param globalFunc
 * @param items
 * @param defaultDeliveryInfo
 */

function poConsolidationItemManageCtrl($scope, $rootScope, $filter, dataList, $state, $stateParams, metabuyerCache, globalFunc,
                                       items, defaultDeliveryInfo, $q) {
  $scope.dataList = dataList && dataList.data || [];
  $scope.meta = dataList && dataList.meta || {
    cursor: {
      current_page: 1,
      from: 0,
      to: 0,
      next: null,
      prev: null,
      last_page: 1,
      per_page: 50,
      total: 0
    }
  };
  $scope.modalContent = "";
  $scope.showColumn = showColumn;
  $scope.storageKey = 'po-item-consolidation-selected-column';
  $scope.tickCheckBox = tickCheckBox;
  $scope.goToPR = goToPR;
  $scope.getSelectedItems = getSelectedItems;
  $scope.proceedToPoCreation = proceedToPoCreation;
  $scope.goToConsolidation = goToConsolidation;
  $scope.selectAllCurrentPageItems = selectAllCurrentPageItems;
  $scope.getModalContent = getModalContent;
  $scope.isInvalid = isInvalid;
  $scope.existingProp = [];
  $scope.items = items;
  $scope.isQuotationExpired = false;

  function goToConsolidation(data){
    if (!data)
      return;
    $state.go('main.user.poConsolidation.manage', {items: data, status: 'consolidation'});
  }

  function showColumn (id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  /**
   * cache the clicked checkboxes
   * @param data
   */
  function tickCheckBox(data) {
    if (!data)
      return;

    var list = metabuyerCache.get('POConsolidationItem');
    if (!list) {
      list = [];
    }

    var index = list.indexOf(data.item_id);
    if (!!data.selected) {
      if (list.indexOf(data._id) === -1)
        list.push(data._id);
    } else {
      list.splice(index, 1);
    }
    metabuyerCache.put('POConsolidationItem', list);
  }

  /**
   * returns store the selected PRs
   * @returns {Array}
   */
  function getSelectedItems() {
    var selectedItems = [];
    _.forEach($scope.dataList, function (prItem) {
      if (!!prItem.selected) {
        selectedItems.push(prItem);
      }
    });
    return selectedItems;
  }

  /**
   * validates delivery address for each item
   * @returns {boolean}
   */
  function checkDeliveryAddress() {
    var noError = true;

    _.forEach($scope.dataList, function (prItem) {
      if (_.isEmpty(prItem.delivery_address)) {
        globalFunc.objectErrorMessage('Delivery address is empty for item ' + prItem.description);
        noError = false;
        return false;
      }
    });

    return noError;
  }

  function proceedToPoCreation() {
    var deferred = $q.defer();
    var items = getSelectedItems();
    if (checkDeliveryAddress()) {
      if (items) {
        $state.go('main.user.poConsolidation.details', {items: items});
        deferred.resolve();
      } else {
        globalFunc.objectErrorMessage('please select items');
        deferred.resolve();
      }
    }
  }

  /**
   * Select / unselect all items
   * @param checked
   */
  function selectAllCurrentPageItems(checked) {
    _.forEach($scope.dataList, function (item) {
      item.selected = checked;
      tickCheckBox(item);
    })
  }

  function goToPR(id) {
    if (!id)
      return;

    $state.go('main.user.purchaseRequisitionsV2.details', {id: id});
  }

  /**
   * Validate of the selection for the items is valid or not
   * @returns {boolean}
   */
  function isInvalid(){
    var items = getSelectedItems();
    var invalidFields = [];
    var invalid = false;
    _.forEach(items, function(item){
      invalid = checkInvalidItems(items,item,'supplier', invalidFields) || invalid;
      invalid = checkInvalidItems(items,item,'currency', invalidFields) || invalid;
      invalid = checkInvalidItems(items,item,'company', invalidFields) || invalid;
      invalid = checkInvalidItems(items,item,'payment_term', invalidFields) || invalid;
      invalid = checkInvalidItems(items,item,'billing_info', invalidFields) || invalid;
      invalid = checkInvalidItems(items,item,'shadow_company', invalidFields) || invalid;
      invalid = checkInvalidItems(items,item,'delivery_address', invalidFields) || invalid;
      invalid = checkInvalidItems(items,item,'mode_of_purchase', invalidFields) || invalid;
    });
    return invalid;
  }

  function checkInvalidItems(items, currItem, prop, fields){
    if (!fields.includes(prop)){
      var validItems = _.filter(items, function(e){
        return e[prop]['_id'] === currItem[prop]['_id'];
      });
      if (validItems.length < items.length){
        fields.push(prop);
        //to prevent same error messages showing 2 times
        if (!$scope.existingProp.includes(prop)){
          globalFunc.objectErrorMessage('Items cannot have a different ' + (prop === 'shadow_company' ? 'Billing Company' : changeStringCase(prop)));
          $scope.existingProp.push(prop);
        } else {
          $scope.existingProp = [];
        }
        return true
      }
    }
    return false
  }

  function changeStringCase(string) {
    var newString = string.replace(/_/g, ' ');
    newString = $filter('titleCase')(newString);
    return newString;
  }

  $scope.$on('poItemsFetched', function(event, content, refreshFilter) {
    $scope.dataList = content.data;
    $scope.meta = content.meta;
    $rootScope.$broadcast('reinitializePagePagination', $scope.meta.cursor);

    if (refreshFilter){
      $rootScope.$broadcast('poNewParams');
    }
  });

  function prepareExpiredQuotationMessage (item) {
    var text = item.pr_custom_reference + ' : Line ID# ' +
      item.item_line_number + ' quotation is expired<br>';
    return text;
  }

  function checkQuotationValidityDate (items) {
    var expiredQuotationMessage = "";
    _.forEach(items, function (item, idx) {
      if (!item.no_other_quotation_available && !!item.quotation_validity_to) {
        var quotationExpiry = new Date(item.quotation_validity_to);
        if (quotationExpiry < Date.now()) {
          $scope.isQuotationExpired = true;
          expiredQuotationMessage += prepareExpiredQuotationMessage(item);
        }
      }
    })
    return expiredQuotationMessage;
  }

  function getModalContent () {
    var items = getSelectedItems();
    var expiredQuotationMessage = checkQuotationValidityDate(items);
    var bodyContent = "";
    if (!isInvalid()) {
    if ($scope.isQuotationExpired) {
      bodyContent = 
        "<p class='text-center text-danger'>" + 
          "The line items below have expired quotations. Please cross check with the respective vendor on the pricing validity.<br><br>" +
          "Quotation bagi item berikut sudah kadaluarsa. Silahkan cek dengan vendor terkait untuk kevalidan harganya.<br><br>" +
        "</p>";
        } else {
      bodyContent = 
        "<p class='text-center'>" +
          "Would you like to create PO with the following " + items.length + " item(s)<br>" +
        "</p>";
      }
    }

    $scope.modalContent = bodyContent +
      "<p class='text-center'>" + 
        expiredQuotationMessage +
      "<p>";
  }

  function initialize() {
    $scope.status = $stateParams.status;
    if (!!$scope.dataList) {
      _.forEach($scope.dataList, function (itemData) {
        if (_.isEmpty(itemData.delivery_address)) {
          // if empty delivery address at line item level, defaulted to header address
          itemData.delivery_address = defaultDeliveryInfo;
        }
      })
    }
  }

  initialize();

}

poConsolidationItemManageCtrl.$inject = ['$scope', '$rootScope', '$filter', 'dataList', '$state', '$stateParams', 'metabuyerCache',
  'globalFunc', 'items', 'defaultDeliveryInfo', '$q'];

angular.module('metabuyer')
  .controller('poConsolidationItemManageCtrl', poConsolidationItemManageCtrl);
