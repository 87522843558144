'use strict';

/**
 * @name positionDetailsController
 * @desc Controller for master data position details controller
 */
function positionDetailsController(
  $scope, $uibModalInstance, toastr, isEditable, data, positionManagement, $rootScope, isEditWindow,
  positionManagementSingle, positionHistory, approvalTemplateHistory, $filter, globalFunc, UserPermissions
) {

  $scope.editClicked = isEditable;
  $scope.positionDetails = [];
  $scope.positionDetails.code = data.code;
  $scope.positionDetails.description = data.description;
  $scope.positionDetails.id = data.id;
  $scope.positionDetails.name = data.name;
  $scope.positionDetails.position_type = data.position_type;
  $scope.positionDetails.status = data.status;
  $scope.positionDetails.status_desc = data.status_desc;
  $scope.inEditMode = isEditWindow;
  $scope.tab = 1;
  $scope.limitDetail = 10;
  $scope.metaDetail = [];
  $scope.pageDetail = 0;
  $scope.moduleName = 'Position';
  $scope.toggleTab = toggleTab;
  $scope.cancel = cancel;
  $scope.editPosition = editPosition;
  $scope.updatePosition = updatePosition;
  $scope.addPosition = addPosition;
  $scope.isAddPositionMode = isAddPositionMode;
  $scope.validateBeforeCreate = validateBeforeCreate;
  $scope.searchHistory = searchHistory;
  $scope.hasUserPermission = globalFunc.checkUserPermission($scope.$root.currentUser, $scope.moduleName);
  var isDeveloper = UserPermissions.isDeveloper($scope.$root.currentUser);


  function toggleTab(tab) {
    return $scope.tab = tab;
  }

  $scope.status = [
    {
      id: 0,
      name: "Active"
    },
    {
      id: 1,
      name: "Inactive"
    }
  ];

  $scope.type = [
    {
      id: "approver",
      name: "Approver"
    },
    {
      id: "watcher",
      name: "Watcher"
    }
  ];

  //this toggle is only for developer's role
  if (isDeveloper) {
    $scope.type.push({
      id: "ad-hoc",
      name: "Ad-hoc"
    });
  }


  function editPosition() {
    $scope.editClicked = true;
  }

  function updatePosition() {
    if (validateBeforeCreate()) {
      var param = {
        code: $scope.positionDetails.code,
        name: $scope.positionDetails.name,
        position_type: $scope.positionDetails.position_type,
        status: $scope.positionDetails.status,
        description: $scope.positionDetails.description,
        updated_by: $rootScope.currentUser.display_name
      };
      positionManagementSingle.put(
        {
          'position': param,
          id: $scope.positionDetails.id
        }
      ).$promise.then(
        function () {
          $scope.search($scope.meta['currentPageNumber']);
          cancel();
          toastr.success('Position has been successfully updated.');
        },
        function (resource) {
          if (!!resource.data && !!resource.data.content && !!resource.data.content.message && !!resource.data.content.message.errors) {
            toastr.error(_.map(resource.data.content.message.errors, _.property('detail')).toString());
          }
          else {
            toastr.error('Failed to update selected position.');
          }
        }
      );
    }
  }

  function addPosition() {
    if (!!validateBeforeCreate()) {
      var param = {
        code: $scope.positionDetails.code,
        name: $scope.positionDetails.name,
        status: $scope.positionDetails.status,
        created_by: $rootScope.currentUser.display_name,
        description: $scope.positionDetails.description,
        position_type: $scope.positionDetails.position_type
      };
      positionManagement.post(
        {
          'position': param
        }
      ).$promise.then(
        function () {
          $scope.search($scope.meta['currentPageNumber']);
          cancel();
          toastr.success('New position has been successfully created.');
        },
        function (resource) {
          if (!!resource.data.content && !!resource.data.content.message && !!resource.data.content.message.errors) {
            toastr.error(_.map(resource.data.content.message.errors, _.property('detail')).toString());
          }
          else {
            toastr.error('Failed to create new position.');
          }
        }
      );
    }
  }

  function isAddPositionMode() {
    return !!_.isEmpty($scope.positionDetails.id) ? true : false;
  }

  function validateBeforeCreate() {
    if (!!_.isEmpty($scope.positionDetails.name) || !!_.isEmpty($scope.positionDetails.code) || !!_.isEmpty($scope.positionDetails.position_type) ||
      typeof $scope.positionDetails.status !== 'number') {
      toastr.error('Please fill in all the fields.');
      return false;
    }
    return true;
  }

  function populateLog(data) {
    _.forEach(data, function (historyData) {
      historyData.log = [];

      if (historyData.event === 'updated') {
        if (typeof(historyData.new_values.name) !== 'undefined' && typeof(historyData.old_values.name) !== 'undefined') {
          historyData.log.push('name : ' + historyData.old_values.name + ' => ' + historyData.new_values.name);
        }
        if (typeof(historyData.new_values.description) !== 'undefined' && typeof(historyData.old_values.description) !== 'undefined') {
          historyData.log.push('description : ' + historyData.old_values.description + ' => ' + historyData.new_values.description);
        }
        if (typeof(historyData.new_values.position_type) !== 'undefined' && typeof(historyData.old_values.position_type) !== 'undefined') {
          historyData.log.push('type : ' + historyData.old_values.position_type + ' => ' + historyData.new_values.position_type);
        }
        if (typeof(historyData.new_values.status) !== 'undefined' && typeof(historyData.old_values.status) !== 'undefined') {
          historyData.log.push('status : ' + (historyData.old_values.status === '0' || historyData.old_values.status === 0 ?
              'active' : 'inactive') + ' => ' + (historyData.new_values.status === '0' ? 'active' : 'inactive'));
        }
      }
      historyData.logForExport = historyData.log.join('\r\n');
    })
    return data;
  }

  function processData() {
    $scope.positionHistoryData = populateLog($scope.positionHistoryData);
    $scope.metaDetail['currentPageNumber'] = $scope.positionHistoryData['meta']['current'];
    $scope.metaDetail['totalPageNumber'] = $scope.positionHistoryData['meta']['pages'];
    $scope.metaDetail['totalRec'] = $scope.positionHistoryData['meta']['count'];
    $scope.metaDetail['startRecNo'] = $scope.limitDetail * $scope.pageDetail + 1;
    $scope.metaDetail['endRecNo'] = $scope.metaDetail['totalRec'] - ((1 + $scope.metaDetail['currentPageNumber']) * $scope.limitDetail) >= 0 ?
      (1 + $scope.metaDetail['currentPageNumber']) * $scope.limitDetail :
      (($scope.metaDetail['currentPageNumber']) * $scope.limitDetail) + $scope.metaDetail['totalRec'] % $scope.limitDetail;
  }

  function searchHistory(pageDetail, callFrom) {
    var isExport = !!callFrom && callFrom === 'export' ? true : false
    $scope.pageDetail = !!pageDetail ? pageDetail : $scope.pageDetail;
    approvalTemplateHistory.get(
      {
        'sort[1][key]': 'created_at',
        'sort[1][direction]': 'DESC',
        'filter_groups[0][filters][0][key]': 'auditable_id',
        'filter_groups[0][filters][0][value]': data.id,
        'filter_groups[0][filters][0][operator]': 'eq',
        'filter_groups[1][filters][0][key]': 'auditable_type',
        'filter_groups[1][filters][0][value]': 'Api\\Positions\\Models\\Position',
        'filter_groups[1][filters][0][operator]': 'eq',
        limit: isExport ? 0 : $scope.limitDetail,
        page: isExport ? 0 : pageDetail
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          if (isExport) {
            $scope.positionHistoryDataExport = resource.content.data;
            $scope.positionHistoryDataExport = populateLog($scope.positionHistoryDataExport);
            exportCSV();
          } else {
            $scope.positionHistoryData = resource.content.data;
            $scope.positionHistoryData['meta'] = resource.content.meta.cursor;
            processData();
          }
        }
      },
      function (resource) {
        if (!!resource.data && !!resource.data.content && !!resource.data.content.message && !!resource.data.content.message.errors) {
          toastr.error(_.map(resource.data.content.message.errors, _.property('detail')).toString());
        }
        else {
          toastr.error('Failed to load history.');
        }
      }
    )
  }

  function exportCSV() {
    $scope.arrayData = [['Username', 'Action', 'Date', 'Changes']];
    _.forEach($scope.positionHistoryDataExport, function (data) {
      $scope.arrayData.push([
        (!!data.user && !!data.user.display_name ? data.user.display_name : ''),
        data.event,
        $filter('date')(globalFunc.convertToLocalDate(data.created_at), 'dd MMM yyyy h:mm a'),
        data.logForExport
      ])
    });
    $scope.fileName = globalFunc.getCsvFilenameWithDate('Position - ' + $scope.positionDetails.code);
    $scope.triggerHistoryExport = true;
  }

  function cancel() {
    $uibModalInstance.close();
  }

  function initialize(){
    if (!!positionHistory && !!positionHistory.data) {
      $scope.positionHistoryData = positionHistory.data;
      $scope.positionHistoryData['meta'] = positionHistory.meta.cursor;
      processData();
    }
  }

  initialize();
}

positionDetailsController.$inject = [
  '$scope', '$uibModalInstance', 'toastr', 'isEditable', 'data', 'positionManagement', '$rootScope', 'isEditWindow',
  'positionManagementSingle', 'positionHistory', 'approvalTemplateHistory', '$filter', 'globalFunc', 'UserPermissions'
];

angular
  .module('metabuyer')
  .controller('positionDetailsController', positionDetailsController);
