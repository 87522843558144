'use strict';

angular
  .module('metabuyer')
  .controller('UserWaitingOnYouCtrl', UserWaitingOnYouCtrl);

/**
 * @name UserWaitingOnYouCtrl
 * @desc Controller for User Waiting on you
 */
function UserWaitingOnYouCtrl($scope, $state, waitingList, waitingOnUserList, $rootScope, UserPermissions, profile) {
  $scope.dataList = waitingList.data;
  $scope.meta = waitingList.meta;
  $scope.waitingService = waitingOnUserList;
  //TODO: 'nofifyWaitingOnFavico' is calling parent parent controller (user.controller) to use the function, have this centralized (Ahmed Saleh)
  $scope.nofifyWaitingOnFavico($rootScope.waitings);
  $rootScope.$broadcast('newWaitingList');
  $scope.redirectTo = function (item) {
    switch (item.context_type) {
      case 'Metabuyer\\PR\\PR':
      {
        $state.go('main.user.purchaseRequisitions.details', {
          id: item.context_id,
          status: 'pending',
          action: 'access_directly',
          from: "waiting-on-you",
          title: undefined
        });
        break;
      }
      case 'App\\Metabuyer\\Requisition\\Models\\Requisition':
        $state.go('main.user.purchaseRequisitionsV2.details', {
          id: item.context_id,
          status: 'pending',
          action: 'access_directly',
          from: "waiting-on-you",
          title: undefined
        });
        break;
      case 'App\\Metabuyer\\Supplier\\Models\\Supplier':
      case 'App\\Metabuyer\\Supplier\\Models\\SupplierCompanyContract':
      {
        $state.go('main.user.supplier.details', {
          id: item.context_id,
          title: undefined
        });
        break;
      }
      case 'App\\Metabuyer\\Models\\Catalog':
      {
        $state.go('main.user.items.detail', {
          id: item.context_id,
          //assuming waiting on will only results on a pending item import.
          //this is to be fixed once a panel is created to access the archived approved operations
          status: 'pending',
          title: undefined
        });
        break;
      }
      case 'Metabuyer\\Budget\\Models\\Budget': {
        // navigate user to budget v2 module with developer role
        if (!!$rootScope.isV2Enabled) {
          $state.go('main.user.budgetV2.details', {
            id: item.context_id
          });
        } else {
          $state.go('main.user.budget.details', {
            id: item.context_id
          });
        }
        break;
      }
      case 'Metabuyer\\PurchaseOrder\\Models\\PurchaseOrder': {
        $state.go('main.user.orders.detail', {
          id: item.context_id,
          title: undefined
        });
        break;
      }
      case 'Metabuyer\\Services\\Delegation\\Model\\Delegation': {
        $state.go('main.user.detailDelegation', {
          id: item.context_id
        });
        break;
      }
      case 'App\\Metabuyer\\CatalogV2\\Models\\CatalogV2': {
        $state.go('main.user.catalogs.detail', {
          id: item.context_id
        });
        break;
      }
      case 'App\\Metabuyer\\Alc\\Models\\Alc': {
        $state.go('main.user.alc.details', {
          id: item.context_id
        });
        break;
      }
      case 'App\\Metabuyer\\Supplier\\Models\\SupplierUser': {
        $state.go('main.user.supplier.supplierUserDetail', {
          id: item.context_id,
          supplierId: item.supplierId
        });
        break;
      }
      case 'App\\Metabuyer\\Tender\\Models\\Tender': {
        $state.go('main.user.tender.detail', {
          id: item.context_id
        });
        break;
      }
    }
  };

  function dataCleanUp() {
    _.forEach($scope.dataList, function (item) {
      if (item.context_type === 'App\\Metabuyer\\Supplier\\Models\\Supplier') {
        item.displayCategory = 'Supplier';
      }
      if (item.context_type === 'App\\Metabuyer\\Supplier\\Models\\SupplierCompanyContract') {
        item.displayCategory = 'Company Supplier';
      }
      if (item.context_type === 'App\\Metabuyer\\Models\\Catalog') {
        item.displayCategory = 'Catalog Approval';
      }
      if (item.context_type === 'Metabuyer\\PR\\PR' ||
          item.context_type === 'App\\Metabuyer\\Requisition\\Models\\Requisition') {
        item.displayCategory = 'Purchase Requisition';
      }
      if (item.context_type === 'Metabuyer\\Budget\\Models\\Budget') {
        item.displayCategory = 'Budget';
      }
      if (item.context_type === 'Metabuyer\\Services\\Delegation\\Model\\Delegation') {
        item.displayCategory = 'Delegation';
      }
      if (item.context_type === 'Metabuyer\\PurchaseOrder\\Models\\PurchaseOrder') {
        item.displayCategory = 'Purchase Order';
      }
      if (item.context_type === 'App\\Metabuyer\\CatalogV2\\Models\\CatalogV2') {
        item.displayCategory = 'Catalog';
      }
      if (item.context_type === 'App\\Metabuyer\\Alc\\Models\\Alc') {
        item.displayCategory = 'ALC';
      }
      if (item.context_type === 'App\\Metabuyer\\Supplier\\Models\\SupplierUser') {
        item.displayCategory = 'Supplier User';
        item.supplierId = item.context_reference;
        item.context_reference = '';
      }
      if (item.context_type === 'App\\Metabuyer\\Tender\\Models\\Tender') {
        item.displayCategory = 'Tender';
      }
    });
  }

  dataCleanUp();
  
  $scope.$watch('waitings', function (newValue, oldValue) {
    $scope.dataList = newValue.data;
    dataCleanUp();
  });

}
UserWaitingOnYouCtrl.$inject = ['$scope', '$state', 'waitingList', 'waitingOnUserList', '$rootScope', 'UserPermissions', 'profile'];
