'use strict';

function SupplierWebPoDetailCtrl(
  $scope, $q, globalFunc, $state, $location, $anchorScroll, order, pathConstants, Orders, profile, starringPO, $rootScope, toastr, purchaseRequisitionsServices, HighlightId, POSubscriptionCheck, getPOPDFHash, $filter,
  notificationSubscriber, POComments, UserPermissions, $timeout, $uibModal, pendingOrder, $stateParams
) {

  $scope.po = order;
  $scope.deliveryNotes = order.delivery_notes;
  $scope.pendingOrder = pendingOrder.data;
  $scope.service = POComments;
  $scope.po.notify = POSubscriptionCheck.subscribed;
  $scope.profile = profile;
  $scope.imageBasePath = pathConstants.apiUrls.image.pr;
  $scope.userBasePath = $rootScope.userImageBasePath;
  $scope.canUpdatePO = canUpdatePO;
  $scope.supplierAcceptPO = supplierAcceptPO;
  $scope.supplierDeclinePO = supplierDeclinePO;
  $scope.openDeliverGoodsModal = openDeliverGoodsModal;
  $scope.selectedAction = selectedAction;
  $scope.parseConditions = parseConditions;
  $scope.status_mapping =
    {
      'DELIVERY_DATE_UNCONFIRMED': 3,
      'DECLINED': 4,
      'AWAITING_DELIVERY': 5
    };
  $scope.changeStringCase = changeStringCase;
  $scope.openDeliveryNote = openDeliveryNote;
  $scope.refreshPo = refreshPo;
  $scope.hasRemainingShippable = hasRemainingShippable;

  function hasRemainingShippable() {
    var hasPending = false;
    _.forEach($scope.po.items, function (item) {
      if (item.remaining_shippable_quantity > 0) {
        hasPending = true;
      }
    });

    return hasPending;
  }

  function refreshPo() {
    $state.transitionTo($state.current, $stateParams, {
      reload: true,
      inherit: false,
      notify: true
    });
  }

  function loadNextPo() {
   $scope.totalPO = pendingOrder.data.length;
   $scope.declineExtraParams = {};

    if (!!$scope.pendingOrder) {
      _.forEach($scope.pendingOrder, function (po) {
        if (po._id !== $scope.po._id) {
          $scope.nextPo = po;
          return false;
        }
      });
    }
  }

  function changeStringCase(string) {
    var newString = string.replace('_', ' ');
    newString = $filter('titleCase')(newString);
    return newString;
  }

  function openDeliveryNote(dn) {
    $uibModal.open({
      templateUrl: 'app/suppliersWeb/po/deliveryNote/delivery-note.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'SupplierDeliveryNoteCtrl',
      windowClass: 'receive-goods-modal',
      resolve: {
        deliveryNote: function () {
          return dn;
        }
      }
    });
  }

  function getStatusOptions() {
    $scope.poStatusOptions = [];
    switch ($scope.po.status) {
      case 'DECLINED': {
        $scope.poStatusOptions.push(
          {
            status: 3,
            descr: 'Couldn\'t confirm delivery date'
          },
          {
            status: 5,
            descr: 'Accept this PO'
          })
      }
        break;
      case 'SUBMITTED': {
        $scope.poStatusOptions.push(
          {
            status: 5,
            descr: 'Accept this PO'
          },
          {
            status: 4,
            descr: 'Decline this PO'
          })
      }
        break;
      case 'DELIVERY_DATE_UNCONFIRMED': {
        $scope.poStatusOptions.push(
          {
            status: 5,
            descr: 'Accept this PO'
          },
          {
            status: 4,
            descr: 'Decline this PO'
          })
      }
        break;
    }
  }

  $scope.printPO = function() {

    getPOPDFHash.get(
      {
        id: $scope.po._id,
        timezone: $rootScope.currentUser.config.TZ
      }
    ).$promise.then(
      function (resource) {
        var newUrl = $filter('format')(pathConstants.apiUrls.po.pdfHash, {
          pdf_hash: resource.url
        });

        setTimeout(function () {
          window.open(newUrl, '_blank')
        }, 1001);
      },
      function(){
        toastr.error('Failed to print PO');
      }
    );
  };

  $scope.backToListingPO = backToListingPO;
  $scope.starred = starred;
  $scope.scrollTo = scrollTo;
  $scope.scrollToClosedLineItemComment = scrollToClosedLineItemComment;
  $scope.loadContactPersons = loadContactPersons;

  // Check and assign the right status for details page
  $scope.accessStatus = $scope.po.status;
  if(angular.isUndefined($scope.accessStatus) || $scope.accessStatus === null) {
    $scope.accessStatus = $scope.previousState.params.status;
  } else if ($scope.previousState.params.status !== null) {
    if ($scope.previousState.params.status === 'STARRED')
      $scope.accessStatus = $scope.previousState.params.status;
  }

  function selectedAction(item) {

    //check if current status was set
    if(item.status === $scope.status_mapping[$scope.po.status]) {
      toastr.success('The status has been set');
      return;
    }
    /**
     * If statement only for decision 'Decline PO'
     */
    if(item.status === 4) {
      swal(
        {
          title: 'Reason for supplier declined PO?',
          text: 'Please state a reason for supplier declined PO',
          type: 'input',
          showCancelButton: true,
          confirmButtonColor: '#1ab394',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          closeOnConfirm: false,
          closeOnCancel: true,
          inputType: 'text',
          inputPlaceholder: 'Write a reason'
        },
        function(inputValue) {
          if (!!inputValue) {
            var trimmedValue = inputValue.trim();
          }

          if (!inputValue || trimmedValue === '') {
            swal.showInputError('You need to state a reason for supplier decline PO!');
            return false;
          }

          Orders.setPOStatus(
            {
              id: order._id
            },
            {
              status: item.status,
              comment: inputValue
            },
            function(success) {
              toastr.success('Supplier declined PO');
              swal.close();
              //check if supplier declined the po
              if (item.status === $scope.status_mapping['DECLINED']) {
                HighlightId.setId($scope.po._id);
                $rootScope.$broadcast('loadSpecificTab', {tab: activateTab('declined')});
                return;
              }
            },
            function (error) {
              toastr.error('Failed to submit status due to ' + error.data.message);
            });
        });
    }
    else {
      Orders.setPOStatus(
        {
          id: order._id
        },
        {
          status: item.status
        },
        function (success) {
          /**
           * status 5 for accepting PO
           * setting the status of the PO
           */
          if (item.status === $scope.status_mapping['AWAITING_DELIVERY']) {
            toastr.success('The supplier has accepted the PO');
            $scope.po.status = 'AWAITING_DELIVERY';
            $rootScope.$broadcast('loadSpecificTab', {tab: activateTab('awaiting_delivery'), noTransition: true});
          }

          if (item.status === $scope.status_mapping['DELIVERY_DATE_UNCONFIRMED']) {
            toastr.success('The status has been set');
            $scope.po.status = 'DELIVERY_DATE_UNCONFIRMED';

            //update history
            $rootScope.$broadcast('refreshComments');
            $rootScope.$broadcast('loadSpecificTab', {tab: activateTab('submitted'), noTransition: true});
          }

          getStatusOptions();
          labelStatus();

        },
        function (error) {
          toastr.error('Failed to submit status due to ' + error.data.message);
        });
    }
  }

  function backToListingPO() {
    window.history.back();
    HighlightId.setId($scope.po._id);
  }

  function starred(po) {
    starringPO.update(
      {
        id: po._id,
        action: !!po.is_starred ? 'unstar' : 'star'
      }
    ).
    $promise.then(function (v) {
        //successful
        if (!!po.is_starred) {
          po.is_starred = false;
        }
        else {
          po.is_starred = true;
        }
      },
      function (error) {
        toastr.error(error.message);
      }
    );
  }

  function scrollTo(id) {
    var old_hash = $location.hash();
    $location.hash(id);
    $anchorScroll();
    $location.hash(old_hash);
  }

  function scrollToClosedLineItemComment(id){
    $rootScope.$broadcast('scrollToClosedLineItemComment', {id: id});
  }

  function loadContactPersons(company) {

    if(!company || !company._id){
      return;
    }
    var defer = $q.defer();

    purchaseRequisitionsServices.getContactPersonsList(
      {
        id: company._id
      }, function (resource) {
        var contactPersons = (typeof resource.content !== 'undefined' &&
          typeof resource.content.data !== 'undefined') ? resource.content.data : [];
        $scope.options['contact_persons'] = contactPersons;
        defer.resolve(contactPersons);
      }, function (error) {
        defer.reject(error);
      }
    );

    $scope.datalists = defer.promise;
  }

  function canUpdatePO () {
    return (UserPermissions.checkPermissionsAccess(profile, 'U', 'PO'));
  }

  function supplierAcceptPO() {
    return Orders.massUpdatePOStatus(
      {},
      {
        status: 5, // awaiting delivery
        poIds: [$scope.po._id]
      },
      function (resource) {
        toastr.success(resource.content.message);
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }).$promise;
  }

  function supplierDeclinePO(params) {
    params['status'] = 4; // declined
    params['poIds'] = [$scope.po._id];

    return Orders.massUpdatePOStatus(
      {},
      params,
      function (resource) {
        toastr.success(resource.content.message);
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }).$promise;
  }

  function openDeliverGoodsModal() {
    $uibModal.open({
      templateUrl: 'app/suppliersWeb/po/deliverGoods/deliverGoodsTemplate.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'deliverGoodsCtrl',
      resolve: {
        purchaseOrderDetails: function() {
          return $scope.po;
        },
        profile: function() {
          return $scope.profile;
        }
      }
    });
  }

  function parseConditions(condition,poStatus) {
    var returnValue = '';
    switch (condition) {
      case 1:
        returnValue = (['DECLINED','SUBMITTED', 'DELIVERY_DATE_UNCONFIRMED'].indexOf(poStatus));
        break;
      case 2:
        returnValue = (['PARTIALLY_DELIVERED', 'AWAITING_DELIVERY', 'CLOSED'].indexOf(poStatus));
        break;
      case 3:
        returnValue = (['FULLY_DELIVERED', 'CLOSED','PARTIALLY_DELIVERED'].indexOf(poStatus));
        break;
      case 4:
        returnValue = (['SUBMITTED', 'DELIVERY_DATE_UNCONFIRMED','AWAITING_DELIVERY'].indexOf(poStatus));
        break;
      case 5:
        returnValue = (['PARTIALLY_DELIVERED', 'AWAITING_DELIVERY'].indexOf(poStatus));
        break;
      case 6:
        returnValue = (['SUBMITTED'].indexOf(poStatus));
        break;
      case 7:
        returnValue = (['DELIVERY_DATE_UNCONFIRMED'].indexOf(poStatus));
        break;
      case 8:
        returnValue = (['DECLINED'].indexOf(poStatus));
        break;
    }
    return returnValue > -1;
  }

  function labelStatus() {
    if(!!$scope.po.status) {
      switch($scope.po.status) {
        case 'CANCELLED':
          $scope.po.generalStatus ='Cancelled';
          $scope.po.specificStatus ='Cancelled';
          break;
        case 'ACCEPTED':
          $scope.po.generalStatus ='Accepted';
          $scope.po.specificStatus ='Accepted';
          break;
        case 'CONFIRMED':
          $scope.po.generalStatus ='Confirmed';
          $scope.po.specificStatus ='Confirmed';
          break;
        case 'CLOSED':
          $scope.po.generalStatus ='Closed';
          $scope.po.specificStatus ='Closed';
          break;
        case 'FULLY_DELIVERED':
          $scope.po.generalStatus ='Delivered';
          $scope.po.specificStatus ='Delivered';
          break;
        case 'DECLINED':
          $scope.po.generalStatus ='Declined';
          $scope.po.specificStatus ='Declined';
          break;
        case 'DELIVERY_DATE_UNCONFIRMED':
          $scope.po.generalStatus ='Delivery Date Unconfirmed';
          $scope.po.specificStatus ='Delivery Date Unconfirmed';
          break;
        case 'SUBMITTED':
          $scope.po.generalStatus ='Awaiting Acknowledgement';
          $scope.po.specificStatus ='Awaiting Acknowledgement';
          break;
        case 'AWAITING_DELIVERY':
          $scope.po.generalStatus ='Processing';
          $scope.po.specificStatus ='Processing';
          break;
        case 'PARTIALLY_DELIVERED':
          $scope.po.generalStatus ='Awaiting Delivery';
          $scope.po.specificStatus ='Partially Delivered ( ' +
            $filter('formatDecimal')($scope.po.received_percentage*100, 2) + '% )';
          break;
      }
    }
  }

  function activateTab(tab){
    var status = tab;
    if(!status)
      status = _.cloneDeep($scope.po.status.toLowerCase());

    //amend the status name to match the keys
    if(status === 'awaiting_confirmation')
      status = 'submitted';
    else if (status === 'partially_delivered')
      status = 'awaiting_delivery';
    else if (status === 'delivered')
      status = 'closed';
    else if (status === 'fully_delivered')
      status = 'closed';
    else if (status === 'delivery_date_unconfirmed')
      status = 'submitted';

    // If PO is reverse by user, activate reversed tab
    if (!!$scope.po.is_reversed) {
      status = 'reversed';
    }

    tab = globalFunc.findInArray($scope.$parent.tabData, 'key', status);
    if(!tab)
      tab = globalFunc.findInArray($scope.$parent.tabData.more, 'key', status);

    return !!tab ? tab : $scope.$parent.tabData[0];
  }

  function initialize() {

    // Load next PO
    loadNextPo();

    if($scope.po.status === 'AWAITING_CONFIRMATION')
      $state.params.status = 'SUBMITTED';
    else if ($scope.po.status === 'PARTIALLY_DELIVERED')
      $state.params.status = 'AWAITING_DELIVERY';
    else if ($scope.po.status === 'DELIVERED')
      $state.params.status = 'CLOSED';
    else if ($scope.po.status === 'FULLY_DELIVERED')
      $state.params.status = 'CLOSED';
    else if ($scope.po.status === 'DELIVERY_DATE_UNCONFIRMED')
      $state.params.status = 'SUBMITTED';

    var tabData = {
      tab: activateTab(),
      noTransition: true
    };

    $timeout(function() {
      $rootScope.$broadcast('loadSpecificTab', tabData);
    }, 300);

    $scope.options = {
      contact_persons: []
    };

    loadContactPersons($scope.po.billing.company);
    getStatusOptions();
    labelStatus();

    // Support old format of delivery address in PO
    if (!!$scope.po.delivery.address && !!$scope.po.delivery.address.details && !$scope.po.delivery.address.line1) {
      $scope.po.delivery.address.line1 = $scope.po.delivery.address.details.line1;
      $scope.po.delivery.address.line2 = $scope.po.delivery.address.details.line2;
      $scope.po.delivery.address.line3 = $scope.po.delivery.address.details.line3;
      $scope.po.delivery.address.postal_code = $scope.po.delivery.address.details.postal_code;
      $scope.po.delivery.address.city = $scope.po.delivery.address.details.city;
      $scope.po.delivery.address.state = $scope.po.delivery.address.details.state;
      $scope.po.delivery.address.country = $scope.po.delivery.address.details.country;
    }

    //clean up old notes structure
    if(!!$scope.po.notes && _.isArray($scope.po.notes)){
      $scope.po.notes = $scope.po.notes[0];
    }

    $scope.isSupplierWebPO = true;
  }

  initialize();
}

SupplierWebPoDetailCtrl.$inject = ['$scope', '$q', 'globalFunc', '$state', '$location', '$anchorScroll',
  'order', 'pathConstants', 'Orders', 'profile', 'starringPO', '$rootScope', 'toastr',
  'purchaseRequisitionsServices', 'HighlightId', 'POSubscriptionCheck', 'getPOPDFHash', '$filter',
  'notificationSubscriber', 'POComments', 'UserPermissions', '$timeout', '$uibModal', 'pendingOrder', '$stateParams'];

angular.module('metabuyer')
  .controller('SupplierWebPoDetailCtrl', SupplierWebPoDetailCtrl);
