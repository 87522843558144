'use strict';
angular
  .module('metabuyer')
  .directive('closePo',function($uibModal) {
    return{
      restrict: 'A',
      scope: true,
      link: function(scope) {

        var panel = function () {
          var modalInstance = $uibModal.open({
            templateUrl: 'components/closePO/closePOTemplate.html',
            backdrop: 'static',
            keyboard: false,
            controller: 'ClosePOCtrl',
            scope: scope
          });
        };
        //loading the panel
        scope.showClosePO = function () {
          panel();
        };
      }
    };
  });
