'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function ($stateProvider) {

    $stateProvider
      .state('main.user.maintenance', {
        url: '/maintenance',
        controller: 'maintenanceCtrl',
        templateUrl: 'app/maintenance/maintenance.html',
        resolve: {
          accessRights: function (profile, $state, globalFunc) {
            if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN') && 
              !globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'ADMIN')) {
              $state.go('notAuthorized');
            }
          },
          $title: function(){
            return "Maintenance";
          },
          bifrostFeatureToggle: function ($q, $stateParams, FeatureToggleService, toastr) {
            var deferred = $q.defer();

            FeatureToggleService.getOne(['bifrost'])
              .then(function (resource) {
                if (!!resource.data) {
                  deferred.resolve(resource.data);
                } else {
                  deferred.resolve({});
                  console.log("Failed to get toggle data.");
                }
              })
              .catch(function (e) {
                deferred.resolve({});
                toastr.error('Something went wrong')
              });

            return deferred.promise;
          }
        },
      })
      .state('main.user.maintenance.changeGrnDoNumber', {
        url: '/maintenance/change-grn-do-number',
        controller: 'changeGrnDoNumberCtrl',
        templateUrl: 'app/maintenance/changeGrnDoNumber/changeGrnDoNumber.html',
        resolve: {
          accessRights: function (profile, $state, globalFunc) {
            if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN')) {
              $state.go('notAuthorized');
            }
          },
          $title: function(){
            return "Change Grn Do Number";
          }
        }
      })
      .state('main.user.maintenance.changeMappingFieldSize', {
        url: '/maintenance/change-mapping-field-size',
        controller: 'changeMappingFieldSizeCtrl',
        templateUrl: 'app/maintenance/changeMappingFieldSize/changeMappingFieldSize.html',
        resolve: {
          accessRights: function (profile, $state, globalFunc) {
            if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN')) {
              $state.go('notAuthorized');
            }
          },
          $title: function(){
            return "Change Mapping Field Size";
          }
        }
      })
      .state('main.user.maintenance.reoutboundTool', {
        url: '/maintenance/reoutbound-tool',
        controller: 'reoutboundToolCtrl',
        templateUrl: 'app/maintenance/reoutboundTool/reoutboundTool.html',
        resolve: {
          accessRights: function (profile, $state, globalFunc) {
            if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN')) {
              $state.go('notAuthorized');
            }
          },
          $title: function(){
            return "Reoutbound Maintenance Tool";
          }
        }
      })
      .state('main.user.maintenance.supplierCompanyContract', {
        url: '/maintenance/supplier-company-contract',
        controller: 'supplierCompanyContractCtrl',
        templateUrl: 'app/maintenance/supplierCompanyContract/supplierCompanyContract.html',
        resolve: {
          accessRights: function (profile, $state, globalFunc) {
            if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN')) {
              $state.go('notAuthorized');
            }
          },
          suppliers: function ($q, showSuppliers, globalFunc) {
            var deferred = $q.defer();

            showSuppliers.get(
              {},
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              }
            );

            return deferred.promise;
          },
          $title: function () {
            return "Suppliers";
          }
        }
      })
      .state('main.user.maintenance.usersUuidMapping', {
        url: '/maintenance/users-uuid-mapping',
        controller: 'usersUuidMappingCtrl',
        templateUrl: 'app/maintenance/usersUuidMapping/usersUuidMapping.html',
        resolve: {
          accessRights: function (profile, $state, globalFunc) {
            if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN')) {
              $state.go('notAuthorized');
            }
          },
          usersList: function ($q, usersUuid, globalFunc) {
            var deferred = $q.defer();
            usersUuid.get(
              {},
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              }
            );

            return deferred.promise;
          },
          $title: function () {
            return 'Users Uuid Mapping';
          }
        }
      })
      .state('main.user.maintenance.integrationConfigurations', {
        url: '/maintenance/integration-configurations',
        controller: 'integrationConfigurationsCtrl',
        templateUrl: 'app/maintenance/integrationConfigurations/integrationConfigurations.html',
        resolve: {
          accessRights: function (profile, $state, globalFunc) {
            if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN')) {
              $state.go('notAuthorized');
            }
          },
          $title: function(){
            return "Change Integration Configuration";
          }
        }
      })
      .state('main.user.maintenance.changeAllUsersPassword', {
        url: '/maintenance/change-all-users-password',
        controller: 'changeAllUsersPasswordCtrl',
        templateUrl: 'app/maintenance/changeAllUsersPassword/changeAllUsersPassword.html',
        resolve: {
          accessRights: function (profile, $state, globalFunc) {
            if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN')) {
              $state.go('notAuthorized');
            }
          },
          $title: function(){
            return "Change All Users' password";
          }
        }
      })
      .state('main.user.maintenance.holiday', {
        url: '/holiday?{query:[^~,]}&{order_by:[^~,]}&{page:[0-9]}&{order:[^~,]}',
        controller: 'holidayCtrl',
        templateUrl: 'app/maintenance/holiday/holidayListView.html',
        resolve: {
          accessRights: function (profile, $state, globalFunc) {
            if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN')) {
              $state.go('notAuthorized');
            }
          },
          holidaysDataList: function ($q, $stateParams, searchModule) {
            var deferred = $q.defer();

            var params = {
              module: 'holiday',
              'criteria[0][description]': $stateParams.query,
              offset: 10,
              order_by: 'date',
              page: $stateParams.page,
              order: !!$stateParams.order ? $stateParams.order : 0
            };

            searchModule.get(
              params,
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data) {
                  deferred.resolve(resource.content);
                } else {
                  deferred.resolve({data: []});
                }
              },
              function (error) {
              }
            );
            return deferred.promise;
          },
          action: function ($stateParams) {
            if ($stateParams.query) {
              return 'search';
            } else {
              return 'show';
            }
          },
          $title: function(){
            return 'Holiday';
          }
        }
      })
      .state('main.user.maintenance.massUpdateBudgetOwner', {
        url: '/mass-update-budget-owner?{query:[^~,]}&{order_by:[^~,]}&{page:[0-9]}&{order:[^~,]}',
        controller: 'massUpdateBudgetOwnerCtrl',
        templateUrl: 'app/maintenance/massUpdateBudgetOwner/massUpdateBudgetOwner.html',
        resolve: {
          accessRights: function (profile, $state, globalFunc) {
            if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN') && 
              !globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'ADMIN')) {
              $state.go('notAuthorized');
            }
          },
          $title: function(){
            return 'Mass Update Budget Owner';
          }
        }
      })
  }]);
