'use strict';

angular.module('metabuyer.services.supplierWebDashboard', ['ngResource'])
  .factory('supplierWebDashboard', function($resource, $filter, pathConstants){
    return $resource(
      pathConstants.apiUrls.supplierWeb.adminStatistics,
      {},
      {
        'get': {method: 'GET'}
      }
    );
  });
