'use strict';

function poConsolidationActionCtrl(
  $scope, $state, poConsolidationCheckToleranceLimit, $uibModal, globalFunc, toastr, $filter
) {
  $scope.canSubmit = canSubmit;
  $scope.goToListing = goToListing;
  $scope.checkToleranceLimit = checkToleranceLimit;
  $scope.preparedData = {};
  $scope.back = back;

  function goToListing(){
    $state.go('main.user.poConsolidation.itemListing', {status: 'items'});
  }

  function back() {
    $state.go('main.user.poConsolidation.itemListing', {status: 'items'});
  }

  function openConfirmationModal(){
    $uibModal.open({
      templateUrl: 'components/poConsolidation/confirmationModal/confirmationModal.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'confirmationModalCtrl',
      scope: $scope,
      windowClass: 'consolidation-confirmation-modal',
      resolve: {
        items: function () {
          return $scope.consolidationObject.items
        },
        header: function() {
          return $scope.consolidationObject.header
        },
        preparedItems: function() {
          return $scope.preparedData
        }
      }
    })
  }

  function validate() {
    var valid = true;
    _.forEach($scope.consolidationObject.items, function(item, index){
      if (!item.foc_item){
        var lineItemTotal = (item.revised_unit_price * item.qty);

        // check line item
        if (item.calculatedDiscount > lineItemTotal){
          toastr.error('Item discount must not exceed the line total');
          valid = false;
        }
      }

      // check other charges
      _.forEach(item.other_charges, function(charge){
        if (charge.discount_amount > charge.amount){
          toastr.error($filter('changeStringCase')(charge.name) + ' discount for line item ' + (index+1) + ' must not exceed the line total');
          valid = false;
        }

        if (_.isEmpty(charge.tax)){
          toastr.error($filter('changeStringCase')(charge.name) + ' tax for line item ' + (index+1) + ' can not be empty');
          valid = false;
        }
      })

      if ((!!item.utilization && !!item.utilization.remaining_qty) && item.qty > item.utilization.remaining_qty) {
        toastr.error("item quantity to process can not greater than quantity available");
        valid = false;
      }
    });
    return valid
  }

  function prepareData() {
    var data = _.map($scope.consolidationObject.items, function(item){
      var other_charges = _.cloneDeep(item.other_charges);
      _.forEach(other_charges, function(charge){
        if(!!charge.tax && !!charge.tax._id){
          charge.tax_id = charge.tax._id;
        }
        delete charge.tax;
        delete charge.process_qty;
        delete charge.revised_unit_price;
        delete charge.currency;
        delete charge.calculatedDiscount;
      });
      if (!item.foc_item) {
        return {
          process_qty: item.qty,
          revised_unit_price: parseFloat(item.revised_unit_price),
          discount: !!item.calculatedDiscount ? item.calculatedDiscount : 0,
          discount_amount: !!item.calculatedDiscount ? item.calculatedDiscount : 0,
          discount_percentage: !!item.discount_percentage ? item.discount_percentage : null,
          tax_id: item.tax && item.tax._id,
          po_consolidation_uuid: item.consolidation_uuid,
          catalog_uuid: item.catalog_uuid,
          catalog_item_uuid: item.catalog_item_uuid,
          item_uuid: item.item_uuid || null,
          uom: item.uom,
          foc_item: item.foc_item,
          other_charges: other_charges,
          note_to_supplier: !!item.note_to_supplier ? item.note_to_supplier : undefined,
          note: !!item.note ? item.note.value : undefined,
          currency: item.currency,
          is_limit_qty: item.is_limit_qty,
          item_line_number: item.item_line_number,
          needed_by_date: globalFunc.convertDateToTimestamp(item.needed_by_date),
          other_charges_group: item.other_charges_group || undefined
        }
      } else {
        return {
          account_assignments: item.account_assignments,
          expense_type: item.expense_type,
          foc_source: item.foc_source,
          process_qty: item.qty,
          revised_unit_price: parseFloat(item.revised_unit_price),
          discount: 0,
          discount_amount: 0,
          po_consolidation_uuid: item.consolidation_uuid,
          item_uuid: item.item_uuid || null,
          catalog_item_uuid: item.catalog_item_uuid,
          is_catalog_item: item.is_catalog_item,
          item_code: item.item_code,
          supplier_code: item.supplier.code,
          uom: item.uom,
          foc_item: item.foc_item,
          other_charges: other_charges,
          item_name: item.name,
          item_description: item.description,
          category: item.category,
          company: item.company,
          note_to_supplier: !!item.note_to_supplier ? item.note_to_supplier : undefined,
          note: !!item.note ? item.note.value : undefined,
          packing_info: item.packing_info,
          is_limit_qty: item.is_limit_qty,
          needed_by_date: globalFunc.convertDateToTimestamp(item.needed_by_date),
          lead_time : item.lead_time,
          other_charges_group: item.other_charges_group || undefined,
          is_stock: item.is_stock_boolean
        }
      }
    });
    return {
      items: data,
      title: $scope.consolidationObject.header.title,
      remarks: $scope.consolidationObject.header.remarks,
      company_code: $scope.consolidationObject.header.company_info.code
    };
  }

  function checkToleranceLimit() {
    if (!!validate()){
      var data = prepareData();
      $scope.preparedData = data;

      poConsolidationCheckToleranceLimit.post(
        {},
        data,
        function (response) {
          openConfirmationModal()
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
          if (!!error && !!error.data.content) {
            var itemUuid = [];
            _.forEach(error.data.content, function (itemId, key) {
              if (key === 'message') {
                return;
              }
              itemUuid.push(itemId);
            })

            _.forEach($scope.consolidationObject.items, function (item) {
              if (itemUuid.includes(item.item_uuid)) {
                item.invalidItem = true
              }
            })
          }
        }
      );
    }
  }


  function canSubmit() {
    return true;
  }

}
poConsolidationActionCtrl.$inject = [ '$scope', '$state', 'poConsolidationCheckToleranceLimit', '$uibModal', 'globalFunc', 'toastr', '$filter' ];

angular
  .module('metabuyer')
  .directive('poConsolidationAction',function() {
    return {
      restrict: 'E',
      scope: {
        editingState: '=',
        consolidationObject: '='
      },
      templateUrl: 'components/poConsolidation/actions/poConsolidationAction.html',
      controller: 'poConsolidationActionCtrl'
    }
  })
  .controller('poConsolidationActionCtrl', poConsolidationActionCtrl);
