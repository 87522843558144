'use strict';

function bifrostDataReinboundMainCtrl($scope) {

  $scope.$emit('pageTitleChanged', 'Bifrost Data Reinbound');

}
bifrostDataReinboundMainCtrl.$inject = ['$scope'];

angular
  .module('metabuyer')
  .controller('bifrostDataReinboundMainCtrl', bifrostDataReinboundMainCtrl);
