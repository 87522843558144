'use strict';

function createPurchaseRequisitionV2ButtonCtrl($scope, purchaseRequisitionsV2Services, $rootScope, $state,
                                             prV2Function, globalFunc, UserPermissions) {

  function createPR(company) {
    $scope.title = 'Loading ...';
    $scope.disabled = true;

    /**
     * add the new field required by the backend
     */
    company.company_id = company._id;

    purchaseRequisitionsV2Services.createPR({
        company: {company_id : company._id}
      },
      function (resource) {
        prV2Function.setPRData({});
        $state.go('main.user.purchaseRequisitionsV2.details', {id: resource.content.data._id, PR: resource.content});
        $scope.title = 'Create PR';
        $scope.disabled = false;
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
        $scope.title = 'Create PR';
        $scope.disabled = false;
      }
    );

  }

  /**
   * This function return a boolean to show or hiding the create PR button
   * @returns {boolean}
   */
  function showCreateButton() {
    if ($state.current.name.indexOf('purchaseRequisitions') < 0)
      return false;

    return (!!$rootScope.isV2Enabled || !!UserPermissions.isDeveloper($rootScope.currentUser));
  }

  $scope.disabled = false;
  $scope.title = 'Create PR';
  $scope.listOfCompanies = $rootScope.listOfCompaniesForLoggedInUser;
  $scope.createPR = createPR;
  $scope.showCreateButton = showCreateButton;
}


createPurchaseRequisitionV2ButtonCtrl.$inject= ['$scope', 'purchaseRequisitionsV2Services', '$rootScope', '$state',
                                              'prV2Function', 'globalFunc', 'UserPermissions'];

angular
  .module('metabuyer')
  .directive('createPurchaseRequisitionV2Button',function() {
    return{
      restrict: 'E',
      templateUrl: 'components/purchaseRequisitionV2Components/createPurchaseRequisitionV2Button/createPurchaseRequisitionV2ButtonTemplate.html',
      scope: {},
      controller: 'createPurchaseRequisitionV2ButtonCtrl'
    };
  })
  .controller('createPurchaseRequisitionV2ButtonCtrl', createPurchaseRequisitionV2ButtonCtrl);
