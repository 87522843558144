'use strict';

angular
  .module('metabuyer')
  .directive('hoverApprovalFlow', function($compile, $interpolate){
    return{
      restrict: 'C',
      link: function(scope,element,attrs){
        scope.tooltipApproval = function() {
          var content = $(element).find('.tooltip-content');
          // Delete interpolate,
          // return $interpolate(content.html())(scope);
          return content.html();
        };
      }
    }
  });
