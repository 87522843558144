'use strict';

angular.module('metabuyer')
  .controller('CancelPOCtrl', function ($scope, $uibModalInstance, Orders, toastr, HighlightId, $state, globalFunc) {

    $scope.cancel_reason = "Others";
    $scope.commentText = "";
    $scope.cancel = cancel;
    $scope.prepareCancelPurchaseOrder = prepareCancelPurchaseOrder;

    //cancel action
    function cancel(){
      $uibModalInstance.close();
    }

    /**
     * Prepare cancel purchase order with confirmations
     */
    function prepareCancelPurchaseOrder() {
      var showSwalConfirmation = false;

      // Loop for each po items to check if any items are split by amount
      _.forEach($scope.po.items, function(poItems) {
        if (!!poItems.split_type && poItems.split_type === 'amount') {
          showSwalConfirmation = true;
        }
      });

      // If PO has an item that is split by amount, confirmation is needed
      if (showSwalConfirmation) {
        // Sweet alert confirmation if reversing item that has linked PO
        swal({
          title: 'Confirm cancel PO?',
          text: 'Item(s) on related PO(s) will also be cancelled.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true
        }, function (isConfirm) {
          if (!!isConfirm) {
            cancelPurchaseOrder();
          }
        });
      }
      else {
        cancelPurchaseOrder();
      }
    }

    /**
     * Cancel purchase order
     */
    function cancelPurchaseOrder(){

      $uibModalInstance.close();

      Orders.setPOStatus(
        {
          id: $scope.po._id
        },
        {
          status: 9,
          reason: $scope.cancel_reason,
          comment: $scope.commentText
        }
      ).$promise.then(
        function (resource) {
          toastr.success('PO cancelled successfully.');

          if (!!resource.content && !!resource.content.data) {
            HighlightId.setId(resource.content.data._id);
          }

          $state.go('main.user.orders.manage', {status: 'Cancelled'});

        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        });
    }
  });
