'use strict';

angular.module('metabuyer.services.categoryType', ['ngResource'])
  .factory('categoryTypeMasterDataList',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.categoryType.single,
        {
          uuid: '@id'
        },
        {
          'get': {method: 'GET'},
          'post': {method: 'POST'},
          'put': {method: 'PUT'}
        }
      );
    });
