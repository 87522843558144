'use strict';

angular.module('metabuyer.services.qaTools', ['ngResource'])
  .factory('resetUsersApprovalTracks', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.qaTools.resetUsersApprovalTracks,
      {
      },
      {
        'delete': { method: 'DELETE' }
      }
    );
  });
