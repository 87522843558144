'use strict';

angular
  .module('metabuyer')
  .directive('actionModalMobile', function ($uibModal, preventDoubleClickingService) {
    return {
      restrict: 'A',
      scope: {
        title: '@',
        actionFunction: '&',
        nextActionId: '@?',
        actionCondition: '&?',
        module: '@',
        successHandler: '&?',
        errorHandler: '&?',
        modalBodyUrl: '@?',
        commentBlock: '@?',
        commentPlaceHolder: '@?',
        extraParams: '=?',
        nextActionAmount: '=?',
        allActionsCompleted: '=?'
      },
      link: function ($scope, element, attrs) {

        var panel = function () {

          $uibModal.open({
            templateUrl: 'components/actionModal/actionModalTemplate-mobile.html',
            scope: $scope,
            backdrop: 'static',
            keyboard: false,
            windowClass: 'action-modal-mobile',
            controller: 'actionModalCtrl',
            resolve: {
              successHandler: function () {
                return !!attrs.successHandler ? $scope.successHandler : undefined;
              },
              errorHandler: function () {
                return !!attrs.errorHandler ? $scope.errorHandler : undefined;
              }
            }
          });
        };

        element.bind('click', function () {
          if (preventDoubleClickingService.checkClick())
            panel();
        });
      }
    }
  });
