'use strict';

/**
 * @name approvalFlowController
 * @desc Controller for Approval Flow Parent Controller
 */
function approvalFlowController ($scope, $state, $rootScope) {

  $scope.$emit('pageTitleChanged', 'Approval Flow');

  $scope.tabData = [
    {
      heading: 'Approval Flow',
      route: 'main.user.approvalFlow.manage',
      key: 'approvalFlow'
    },
    {
      heading: 'Approval Flow Preview',
      route: 'main.user.approvalFlow.preview',
      key: 'preview'
    }
  ];

  if (!!$rootScope.isOffline) {
    $scope.tabData.pop();
  }

  function initialize() {
    if (!!$state.current.name) {
      if ($state.current.name.indexOf('preview') > -1) {
        $scope.parentState = 'main.user.approvalFlow.preview';
        $scope.parentStateParams = $state.params;
        $scope.activatedTab = $scope.tabData[1];
      } else {
        $scope.parentState = 'main.user.approvalFlow.manage';
        $scope.parentStateParams = $state.params;
        $scope.activatedTab = $scope.tabData[0];
      }
    }
  }

  initialize();
}
approvalFlowController.$inject = ['$scope', '$state', '$rootScope'];

angular
  .module('metabuyer')
  .controller('approvalFlowController', approvalFlowController);
