'use strict';
angular
  .module('metabuyer')
  .directive('imageError',function(Orders, toastr) {
    return{
      restrict: 'A',
      link: function(scope, element, attrs) {
        element.bind('error', function() {
          if (attrs.src != attrs.imageError) {
            attrs.$set('src', attrs.imageError);
          }
        });
      }
    };
  });
