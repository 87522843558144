'use strict';

/**
 * @name catalogCategoriesAdvancedSearchCtrl
 *
 * @description Controller for Catalog advanced search
 *
 * @author Chie Yang Teh <tehcy@sunway.com.my>
 * @copyright Sunway Metacloud &copy; 2023
 */
function catalogCategoriesAdvancedSearchCtrl($scope, toastr, $rootScope, advancedSearch, exportSearch, itemsCategories, metabuyerCache, catalogFunction ) {

  function setCategoriesView() {

    var groupIds = [];

    _.forEach($rootScope.currentUser.companies, function (company) {
      _.forEach(company.catalog_groups, function (group) {
        if (groupIds.indexOf(group._id) < 0) {
          groupIds.push(group._id);
        }
      })
    });

    $scope.groupIds = groupIds;

    itemsCategories.get(
      {
        code: 'all',
        groupIds: [groupIds]
      },
        function (resource) {
          if (!!resource.content && !!resource.content.data) {
            metabuyerCache.put('categoriesData', resource.content.data);
            $scope.categoriesData = resource.content.data;
            $scope.categories = [{ key: 'ALL', value: 'All'}];
            $scope.categoriesData.forEach(function (item) {
              $scope.categories.push({ key: item.code, value: item.title, families: item.families });
            });
            divideCategories();
          }
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
  }

  var toastrErrorMessage = null;
  $scope.errors = {};

  $scope.columns = [
    {id: 'catalog_group|descr', label: 'Catalog Group', selected: true},
    {id: 'catalog_code|code', label: 'Catalog Code', selected: true},
    {id: 'catalog_code|descr', label: 'Catalog Description', selected: true},
    {id: 'supplier|descr', label: 'Supplier', selected: true},
    {id: 'item_number', label: '# item', selected: true},
    {id: 'import_effective_date', label: 'Effective At', selected: true},
    {id: 'creator_info|display_name', label: 'Creator', selected: true},
    {id: 'created_at', label: 'Created Date', selected: true},
    {id: 'updater_info|display_name', label: 'Updated By', selected: true},
    {id: 'updated_at', label: 'Updated Date', selected: true},
  ];

  var initialSearchFields = {
    categories: null,
    subCategories: null,
    catalogGroupName: null,
    catalogCode: null,
    catalogDescr: null,
    supplierName: null,
  
  };

  $scope.catalogTitleTransformer = catalogTitleTransformer;
  $scope.showColumn = showColumn;
  $scope.submitAdvSearch = submitAdvSearch;
  $scope.resetForm = resetForm;
  $scope.searchModule = advancedSearch;
  $scope.catalogDisplayHandler = '';
  $scope.plusItemQty = plusItemQty;
  $scope.minusItemQty = minusItemQty;
  $scope.addItemToCart = addItemToCart;
  $scope.cart = [];

  /**
   * catalog title transformer
   * @param title
   */
  function catalogTitleTransformer (title) {
    if (!title) {
      return title;
    }
    title = title.replace(".zip", "");
    return title.replace(".csv", "");
  }

  /**
   * function to validate form input values
   * @returns {boolean|Object}
   */
   function validateForm() {

    /**
     * logic for range fields (total price, total cost of pr, and all date fields)
     * if `from` field is not empty, set `to` field to same value as `from` value
     * if `to` field is not empty, `from` field is mandatory.
     * if both are empty, skip validation
     *
     */

    //effective date range
    if (!!$scope.effectiveDateTo) {
      if (!!$scope.effectiveDateFrom) {
        if ($scope.effectiveDateFrom > $scope.effectiveDateTo) {
          $scope.errors.effectiveDate = 'Invalid Range';
        }
      } else {
        $scope.errors.effectiveDate = '\'From\' field is mandatory';
      }
    } else if (!!$scope.effectiveDateFrom) {
      $scope.effectiveDateTo = $scope.effectiveDateFrom;
    }

    if (_.isEmpty($scope.errors)) {
      var searchData = _.clone($scope.searchFields);
      return buildParam(searchData);
    } else {
      toastrErrorMessage = 'Invalid value in one or more fields';
      return false;
    }
  }

  /**
   * function to build criteria parameters
   * @param data object of form input values
   * @returns {boolean|Object}
   */
  function buildParam(data) {

    var params = {};

    if(!!data.categories) {
      params['criteria[0][categories]'] = data.categories.key;
    }

    if(!!data.subCategories) {
      params['criteria[0][subCategories]'] = data.subCategories.key;
    }

    if (!!data.catalogGroupName) {
      params['criteria[0][catalog_data|catalog_group|descr]'] = data.catalogGroupName;
    }

    if (!!data.catalogCode) {
      params['criteria[0][catalog_data|catalog_code|code]'] = data.catalogCode;
    }

    if (!!data.catalogDescr) {
      params['criteria[0][catalog_data|catalog_code|descr]'] = data.catalogDescr;
    }

    if (!!data.supplierName) {
      params['criteria[0][details|supplier_descr]'] = data.supplierName;
    }


    // if status 'all' is selected, the form cannot be empty. so the criteria for 'all' status
    // will only be added if `params` is not empty.
    if (_.isEmpty(params)) {
      toastrErrorMessage = 'Fill in at least one field';
      return false;
    } else {
      params['criteria[0][catalogGroupIds]'] =[$scope.groupIds];
      params['module'] = 'items';
      params['offset'] = 20;
      params['criteria[0][$operator]'] = 'and';
      return params;
    }
  }

  function divideCategories() {
    if ($scope.categories) {
      $scope.segments = [];
      _.forEach($scope.categories, function (category) {
        var temp = {
          'code': category.key,
          'title': category.value,
          'families': category.families
        };
        $scope.segments.push(temp);
      });
    }
  }

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }

  $scope.updateSubcategories = function(selectedCategory) {
    $scope.subCategories = [];
    $scope.searchFields.subCategories = null;
    // Use a conditional statement or data retrieval mechanism to update subcategory options.
    if (selectedCategory.key === 'ALL') {
      $scope.subCategories = [
        { key: 'ALL', value: 'All Subcategories' },
        // Add other subcategory options for 'All'.
    ];
        $scope.disableSelection = true;
    } else {
      $scope.disableSelection = false;
      selectedCategory.families.forEach(function (item) {
        $scope.subCategories.push({ key: item.code, value: item.title });
      });
    }
  };

  /**
   * function to submit search form
   */
  function submitAdvSearch() {
    var searchParam = validateForm();

    if (!!searchParam) {
      $scope.embeddedParams = searchParam;

      /**
       * Setting the params to prepare for exporting
       */
      exportSearch.setParams(searchParam);

      advancedSearch.get(
        searchParam,
        function (resource) {
          $scope.catalogDisplayHandler = 'showItems';
          $scope.dataList = resource.content.data;
          $scope.meta = resource.content.meta;
          $scope.emptyInput = false;
        },
        function (error) {
          toastr.error('Something went wrong');
          $scope.dataList = [];
        }
      );
    } else {
      toastr.error(toastrErrorMessage);
      toastrErrorMessage = null;
      $scope.emptyInput = true;
    }
  }

  /**
   * function to reset the search form
   */
  function resetForm() {
    $scope.searchFields = _.cloneDeep(initialSearchFields);
    $scope.dataList = [];
    $scope.disableSelection = true;
    $scope.catalogDisplayHandler = '';
  }

  function setDefaultQuantity() {
    for (var key in $scope.dataList) {
      $scope.dataList[key].quantityOrdered = 1;
    }
  }

  function plusItemQty(index, count) {
    if (!count || count < 1) {
      count = 0
    }

    count = parseInt(count) + 1;
    $scope.dataList[index].quantityOrdered = count;
  }

  function minusItemQty(index, count) {
    if (!count || count < 1) {
      count = 2
    }

    count = parseInt(count) - 1;
    if (!count < 1) {
      $scope.dataList[index].quantityOrdered = count;
    }
  }

  function addItemToCart(item, qty, isItemAddedFromPopUpModal) {
    if (!qty || qty < 1) {
      globalFunc.objectErrorMessage("Cannot be empty or negative value");
      return;
    }

    // check pr item limit
    if ($scope.itemLimit > 0 && !!$scope.cart && $scope.cart.length >= $scope.itemLimit) {
      globalFunc.objectErrorMessage("Cart item limit reached. Only " + $scope.itemLimit + " items allowed per cart");
      return;
    }

    var clonedItem = _.cloneDeep(item);
    clonedItem.quantityOrdered = qty;
    $scope.cart.push(clonedItem);
    catalogFunction.setCatalogCartItems($scope.cart);

    if(!isItemAddedFromPopUpModal) {
      $rootScope.$broadcast('catalogItemFromListAdded');
    } else {
      toastr.success('Item has been successfully added.');
    }
    refreshAddCartButton();
  }

  function refreshAddCartButton() {
    _.forEach($scope.dataList, function (product) {
      if (checkItemInCart(product._id)) {
        product.selected = true;
      } else {
        product.selected = false;
      }
    })
  }

  function checkItemInCart(id) {
    var cartItems = $scope.cart;
    var exist = false;
    for (var x = 0; x < cartItems.length; x++) {
      if (cartItems[x]._id === id) {
        exist = true;
      }
    }
    return exist;
  }

  function initialize() {
    $scope.resetForm();
    setCategoriesView();

    $scope.$watch('dataList', function () {
      setDefaultQuantity();
      refreshAddCartButton();
    });

    $rootScope.$on('catalogCartItemDeleted', function () {
      refreshAddCartButton();
    });
  
    $rootScope.$on('addItemFromPopUpModal', function (events, item) {
      addItemToCart(item, 1, true);
    });
  }
  initialize();
}

catalogCategoriesAdvancedSearchCtrl.$inject = [
  '$scope', 'toastr', '$rootScope', 'advancedSearch', 'exportSearch', 'itemsCategories', 'metabuyerCache', 'catalogFunction'
];

angular.module('metabuyer')
  .controller('catalogCategoriesAdvancedSearchCtrl', catalogCategoriesAdvancedSearchCtrl);
