'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function ($stateProvider) {

    $stateProvider
      .state('main.user.suppliersDashboard', {
        url: '/suppliers-dashboard',
        controller: 'suppliersDashboardController',
        templateUrl: 'app/suppliersWebAdmin/dashboard.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            if (!UserPermissions.hasSupplierWebAdminRole(profile) ||
              !profile.tenant.config.supplier_web) {
              $state.go('notAuthorized');
            }
          },
          userHistory: function ($q, supplierUserHistory) {
            var deferred = $q.defer();
            supplierUserHistory.get(
              {},
              function (resource) {
                deferred.resolve(resource.content.data || {data: []});
              },
              function () {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          statistics: function ($q, $state, $stateParams, supplierWebDashboard) {
            var deferred = $q.defer();

            supplierWebDashboard.get(
              {},
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
              }
            );

            return deferred.promise;
          },
          $title: function () {
            return 'Suppliers Dashboard';
          }
        }
      })
      .state('main.user.suppliersWebManagement', {
        url: '/suppliers-management',
        controller: 'SuppliersWebMgmtCtrl',
        templateUrl: 'app/suppliersWebAdmin/management/index.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            if (!UserPermissions.hasSupplierWebAdminRole(profile) ||
              !profile.tenant.config.supplier_web) {
              $state.go('notAuthorized');
            }
          },
          $title: function () {
            return 'Supplier Web Management';
          }
        }
      })
      .state('main.user.suppliersWebManagement.tnc', {
        url: '/suppliers-management/terms-and-conditions',
        controller: 'SuppliersWebTncCtrl',
        templateUrl: 'app/suppliersWebAdmin/management/tnc/index.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            if (!UserPermissions.hasSupplierWebAdminRole(profile) ||
              !profile.tenant.config.supplier_web) {
              $state.go('notAuthorized');
            }
          },
          $title: function () {
            return 'Supplier Web Management - Terms and Conditions';
          }
        }
      })
      .state('main.user.suppliersWebUsers', {
        url: '/suppliers-dashboard/users',
        controller: 'SuppliersWebUserCtrl',
        templateUrl: 'app/suppliersWebAdmin/users/index.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            if (!UserPermissions.hasSupplierWebAdminRole(profile) ||
              !profile.tenant.config.supplier_web) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.suppliersWebUsers.list', {
        url: '/list?{query:[a-zA-Z0-9]+}&{status:[a-zA-Z0-9]+}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'SuppliersWebUserListCtrl',
        templateUrl: 'app/suppliersWebAdmin/users/list/list.html',
        resolve: {
          $title: function () {
            return 'Suppliers Users';
          },
          users : function ($rootScope, $q, $state, $stateParams, Users, searchModule, globalFunc) {
            var status = globalFunc.availableStatuses('users', $stateParams.status);
            var deferred = $q.defer();
            var params = {
              module: 'users',
              offset: 20,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order,
              'criteria[0][is_supplier_web_user]': true,
              'criteria[0][status]': $stateParams.status !== 'all' ? status : null
            };

            if (!!$stateParams.query) {
              var querySearch = '';

              if (!!$stateParams.filter) {
                querySearch = 'criteria[1][' + $stateParams.filter + ']';
                params[querySearch] = $stateParams.query;
              } else {
                params = {
                  module: 'users',
                  offset: 20,
                  'criteria[2][status]': $stateParams.status !== 'all' ? status : null,
                  'criteria[1][display_name]': $stateParams.query,
                  'criteria[1][email]': $stateParams.query,
                  'criteria[1][supplier.descr]': $stateParams.query,
                  'criteria[0][is_supplier_web_user]': true,
                  criteria_operator: !!$stateParams.query ? 'and' : null,
                  'criteria[1][$operator]': 'or',
                  page: $stateParams.page,
                  order_by: $stateParams.order_by,
                  order: $stateParams.order
                };
              }
            }

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function () {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          }
        }
      })
      .state('main.user.suppliersWebUsers.detail', {
        url: '/{id:[a-zA-Z0-9]+}',
        templateUrl: 'app/suppliersWebAdmin/users/detail/detail.html',
        controller: 'SupplierWebUsersDetailCtrl',
        resolve: {
          profile: function ($q, $stateParams, supplierWebUser) {
            var deferred = $q.defer();
            supplierWebUser.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content.data);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          supplierWebRoles: function (supplierWebRoles, profile, $q) {
            var deferred = $q.defer();

            supplierWebRoles.get(
              {
                org_id: profile.tenant_id
              },
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data) {
                  deferred.resolve(resource.content);
                } else {
                  deferred.resolve({data: []});
                }
              },
              function (error) {
              }
            );

            return deferred.promise;
          },
          $title: function () {
            return 'Suppliers Users - Detail';
          }
        }
      })
      .state('main.user.suppliersWebUsers.edit', {
        url: '/edit-user?{id:[a-zA-Z0-9]+}',
        params: {supplierWebRoles: null},
        templateUrl: 'app/suppliersWebAdmin/users/edit/edit.html',
        controller: 'SupplierWebUsersEditCtrl',
        resolve: {
          editedUser: function ($q, $stateParams, supplierWebUser) {
            var deferred = $q.defer();
            supplierWebUser.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content.data);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          supplierWebRoles: ['$stateParams', function($stateParams) {
            return $stateParams.supplierWebRoles;
          }],
          $title: function () {
            return 'Suppliers Users - Edit';
          }
        }
      })
      .state('main.user.suppliersWebEmailLog', {
        url: '/suppliers-dashboard/email-logs',
        controller: 'SupplierWebEmailLogsCtrl',
        templateUrl: 'app/suppliersWebAdmin/email-log/index.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            if (!UserPermissions.hasSupplierWebAdminRole(profile) ||
              !profile.tenant.config.supplier_web) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.suppliersWebEmailLog.manage', {
        templateUrl: 'app/suppliersWebAdmin/email-log/manage/manage.html',
        url: '/manage?&{query:[a-zA-Z0-9]+}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'SuppliersWebEmailLogManageCtrl',
        resolve: {
          emailLogs: function ($q, globalFunc, $stateParams, searchModule) {

            var deferred = $q.defer();
            var statusCode = 2;

            var params = {
              module: 'email-logs',
              offset: 20,
              'criteria[0][receiver_email]': $stateParams.query,
              'criteria[0][subject]': $stateParams.query,
              'criteria[0][$operator]': 'or',
              'criteria[1][status]': statusCode,
              criteria_operator: 'and',
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            if (!!$stateParams.filter) {
              var temp = '';
              if ($stateParams.type === 'date') {
                temp = 'criteria[' + $stateParams.filter + '][0][]';
                params[temp] = [$stateParams.query[0], $stateParams.query[1]];
              } else if ($stateParams.type === 'double') {
                temp = 'criteria[' + $stateParams.filter + '][0][]';
                params[temp] = [$stateParams.query[0], $stateParams.query[1]];
              } else {
                temp = 'criteria[0][' + $stateParams.filter + ']';
                params[temp] = $stateParams.query;
              }
            }

            searchModule.get(
              params,
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data)
                  deferred.resolve(resource.content);
                else
                  deferred.resolve({data: []});
              },
              function () {
                deferred.resolve([]);
              }
            );

            return deferred.promise;
          },
          $title: function() {
            return 'Email Logs';
          }
        }
      })
  }]);
