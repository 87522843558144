'use strict';

angular.module('metabuyer')
  .factory('NewPRFirstLoad', function() {
    var newPRFirstLoad = {};

    newPRFirstLoad.isFirstLoad = false;

    newPRFirstLoad.set = function() {
      return newPRFirstLoad.isFirstLoad = true;
    };

    newPRFirstLoad.unset = function() {
      return newPRFirstLoad.isFirstLoad = false;
    };

    return newPRFirstLoad;
  });
