'use strict';
/**
 * @name notAuthorizedCtrl
 * @desc Controller for displaying page for unauthorized access
 * @author Justin 29/12/2015
 */
function notAuthorizedCtrl($scope, $rootScope, $state) {

  //applying CSS for the page on the controller level
  $rootScope.bodyClasses = 'gray-bg';
}

notAuthorizedCtrl.$inject = ['$scope', '$rootScope', '$state'];

angular
  .module('metabuyer')
  .controller('notAuthorizedCtrl', notAuthorizedCtrl);
