'use strict';

/**
 * @name underMaintenanceCtrl
 *
 * @desc Controller for displaying page for underMaintenance
 *
 * @author Ahmed Saleh <a.s.alsalali@gmail.com>
 * @copyright  Metacloud Sdn. Bhd. &copy; 2016
 */

function underMaintenanceCtrl($rootScope) {

  //applying CSS for the page on the controller level
  $rootScope.bodyClasses = 'gray-bg';
}

underMaintenanceCtrl.$inject = ['$rootScope'];

angular
  .module('metabuyer')
  .controller('underMaintenanceCtrl', underMaintenanceCtrl);
