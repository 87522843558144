'use strict';

/**
 * Controller for alc v2 details
 * @description
 * Contains functions for handling alc information and editing
 *
 * @param $scope
 * @param alcData
 * @param Alc
 * @param $http
 * @param $filter
 * @param pathConstants
 * @param loadCacheableData
 * @param AlcComments
 * @param globalFunc
 * @param Approvals
 * @param profile
 * @param HighlightId
 * @param $state
 * @param toastr
 * @param updateStatusAlc
 * @param positions
 * @param $rootScope
 * @param approvalPreview
 * @param AlcDraft
 * @param resendPendingAlcEmail
 *
 * @author Robby Fratina <rfrob30@gmail.com>
 * @copyright 2020 Metacloud Sdn. Bhd.
 */
function alcDetailsCtrl($scope, alcData, Alc, $http, $filter, pathConstants, loadCacheableData, AlcComments, globalFunc,
                        Approvals, profile, HighlightId, $state, toastr, updateStatusAlc, positions, $rootScope,
                        approvalPreview, AlcDraft, resendPendingAlcEmail) {
  $scope.templateMode = 'modify';
  var clonedAlc = {};
  $scope.searchParams = {};
  $scope.commentService = AlcComments;
  $scope.searchAlcGroup = searchAlcGroup;
  $scope.searchPosition = searchPosition;
  $scope.searchItemMaster = searchItemMaster;
  $scope.openDatepicker = openDatepicker;
  $scope.selectItem = selectItem;
  $scope.selectAlcGroup = selectAlcGroup;
  $scope.selectPosition = selectPosition;
  $scope.isApproved = false;
  $scope.currencyList = [];
  $scope.approvals = {};
  $scope.rejector = {};
  $scope.waiting_on_user = [];
  $scope.checkWaitingOn = checkWaitingOn;
  $scope.approveAlc = approveAlc;
  $scope.rejectAlc = rejectAlc;
  $scope.changeStatus = changeStatus;
  $scope.getApprovalPreview = getApprovalPreview;
  $scope.getPositionListing = getPositionListing;
  $scope.profile = profile;
  $scope.canResendAlcEmail = canResendAlcEmail;
  $scope.resendAlcEmail = resendAlcEmail;
  $scope.isAlcCreator = isAlcCreator;
  $scope.isAlcRequester = isAlcRequester;
  $scope.approveLock = false;
  $scope.datepickerOpened = {
    start_date: false,
    end_date: false,
    start_date_assignment: false,
    end_date_assignment: false
  };

  $scope.startDateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };

  $scope.endDateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };

  loadCacheableData.loadData({
    module: 'currencies',
    'criteria[is_active]': 1,
    offset: 0
  }, 'currencies', 'localStorage').then(function(data){
    $scope.currencyList = data;
  });

  function selectItem(item) {
    $scope.alcData.item_master = item;
  }

  function selectAlcGroup(item) {
    $scope.alcData.alc_group = item;
  }

  function selectPosition(item) {
    $scope.alcData.position = item;
  }

  function isApproved(){
    if (!!$scope.alcData.status && $scope.alcData.status === 'APPROVED'){
      $scope.searchParams.alcGroup = $scope.alcData.alc_group.code + ' - ' + $scope.alcData.alc_group.name;
      $scope.searchParams.position = $scope.alcData.position.name;
      $scope.searchParams.item = $scope.alcData.item_master.code + ' - ' + $scope.alcData.item_master.name;
      return true;
    }
    return false;
  }

  /**
   * For date picker
   *
   * @param {Object} $event
   * @param {string} which - start or end date
   */
  function openDatepicker($event, which) {
    $event.preventDefault();
    $event.stopPropagation();

    if (!!$scope.alcData.end_date) {
      $scope.startDateOptions.maxDate = $scope.alcData.end_date;
    }

    $scope.endDateOptions.minDate = new Date();

    $scope.datepickerOpened[which] = true;
  }

  function searchAlcGroup(val){
    $scope.supplierSearching = true;

    var moduleParam = {
      module: 'alc-group'
    };

    var additionalParams = {
      'criteria[0][code]': val,
      'criteria[1][name]': val,
      'criteria[1][descr]': val,
      criteria_operator: 'or',
      offset: 5
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams,
      ignoreLoadingBar: true
    }).then(function (response) {
      if (response.data.content.data.length === 0) {
        $scope.supplierSearching = false;
        return [];
      } else {
        return response.data.content.data.map(function (item) {
          $scope.supplierSearching = false;
          return item
        });
      }
    });
  }

  function searchItemMaster(val){
    $scope.supplierSearching = true;

    var moduleParam = {
      module: 'item-master'
    };

    var additionalParams = {
      'criteria[1][code]': val,
      'criteria[1][name]': val,
      'criteria[1][$operator]': 'or',
      offset: 5
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams,
      ignoreLoadingBar: true
    }).then(function (response) {
      if (response.data.content.data.length === 0) {
        $scope.supplierSearching = false;
        return [];
      } else {
        return response.data.content.data.map(function (item) {
          $scope.supplierSearching = false;
          return item
        });
      }
    });
  }

  function searchPosition(val) {
    return $http.get(pathConstants.apiUrls.approvalEngine.position.get, {
      params: {
        'sort[1][key]': 'updated_at',
        'sort[1][direction]': 'DESC',
        'filter_groups[0][filters][0][key]': 'name',
        'filter_groups[0][filters][0][value]': val,
        'filter_groups[0][filters][0][operator]': 'ct',
        'filter_groups[0][filters][1][key]': 'code',
        'filter_groups[0][filters][1][value]': val,
        'filter_groups[0][filters][1][operator]': 'ct',
        limit: 5
      }
    }).then(function (response) {
      if (response.data.content.data.length === 0) {
        $scope.supplierSearching = false;
        return [];
      } else {
        return response.data.content.data.position.map(function (item) {
          $scope.supplierSearching = false;
          return item
        });
      }
    });
  }

  // Approval Flow Data
  function loadApprovalFlow() {
    if ($scope.alcData && $scope.alcData.approval_id && $scope.status !== 'DRAFT') {
      Approvals.get(
        {
          id: $scope.alcData.approval_id
        },
        function (resource) {
          if (!!resource && !!resource.content && !!resource.content.data) {
            $scope.approvals = resource.content.data;
            $scope.requestor.display_name = resource.content.data.requested_by.display_name;
            if ($scope.status === 'PENDING') {
              $scope.approvals = resource.content.data;
              globalFunc.getWaitingOnUser(resource.content.data, $scope.waiting_on_user);
            }
            else if ($scope.status === 'REJECTED') {
              for (var i in $scope.approvals) {
                if (!!$scope.approvals[i].step_status && $scope.approvals[i].step_status === 'REJECTED') {
                  $scope.rejector = $scope.approvals[i].rejectedRoles[0];
                }
              }
            }
          } else {
            $scope.approvals = [];
          }
        },
        function () {
          $scope.approvals = [];
        });
    }
  }

  //check user waiting on
  function checkWaitingOn() {
    var find_user = _.find($scope.waiting_on_user, function (approver) {
      return (approver._id == profile._id);
    });

    if (find_user != undefined) {
      return true;
    }

    return false;
  }

  // Approve Alc
  function approveAlc() {
    $scope.approveLock = true; //lock the approve button
    Alc.approve(
      {
        approval_id: $scope.alcData.approval_id,
        status: 'approve'
      },
      {},
      function () {
        toastr.success('You have approved this ALC.');
        Alc.get({uuid: $scope.alcData.uuid},
          function (resource) {
            if (!!resource && !!resource.content && !!resource.content.data &&
                !!resource.content.data.status) {

              var status = resource.content.data.status;
              HighlightId.setId($scope.alcData._id);
              $state.go('main.user.alc.manage', { status: status.toUpperCase() });
            } else {
              $state.go('main.user.alc.manage', {status: 'PENDING'});
            }
          },
          function () {
            //Store catalog id to fadeout on pending table
            HighlightId.setId($scope.alcData._id);
            $state.go('main.user.alc.manage', {status: 'PENDING'});
          }
        );

      }, function () {
        toastr.error('ALC cannot be approved.');
      }
    );
  }

  // Reject ALC
  function rejectAlc() {
    swal({
        title: 'Confirm reject item?',
        text: 'This item will be rejected by you. Please write a reason',
        type: 'input',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: false,
        closeOnCancel: true,
        inputType: 'text',
        inputPlaceholder: 'Write a reason'
      },
      function(inputValue) {
        if(!!inputValue){
          var trimmedValue = inputValue.trim();
        }

        if (!inputValue) {
          swal.showInputError('You need to write a reason for rejecting!');
          return false;
        }

        if(trimmedValue === '') {
          swal.showInputError('You need to write a reason for rejecting!');
          return false
        }
        Alc.approve(
          {
            approval_id: $scope.alcData.approval_id,
            status: 'reject'
          },
          {
            comment: inputValue
          },
          function () {
            toastr.success('Rejected ALC');

            Alc.get({uuid: $scope.alcData.uuid},
              function (resource) {
                swal.close();
                if (!!resource && !!resource.content && !!resource.content.data &&
                  !!resource.content.data.status) {

                  HighlightId.setId($scope.alcData._id);

                  if (resource.content.data.status === 'APPROVED') {
                    $state.go('main.user.alc.manage', {status: 'APPROVED'});
                  } else if (resource.content.data.status === 'PENDING') {
                    $state.go('main.user.alc.manage', {status: 'PENDING'});
                  } else {
                    $state.go('main.user.alc.manage', {status: 'REJECTED'});
                  }
                } else {
                  $state.go('main.user.alc.manage', {status: 'PENDING'});
                }
              },
              function (error) {
                HighlightId.setId($scope.alcData._id);
                $state.go('main.user.alc.manage', {status: 'PENDING'});
              }
            );
          }, function (error) {
            toastr.error('ALC cannot be rejected.');
          }
        );
    });
  }

  // Change Status of the ALC
  function changeStatus(status, summaryId) {
    updateStatusAlc.put(
      {
        uuid: $scope.alcData.uuid,
        status: status
      },
      function () {
        toastr.success('Successfully changed status of ALC');
        HighlightId.setId(summaryId);
        if(status === 'activate' || status === 'deactivate')
          $state.go('main.user.alc.manage', {
            'status'    : 'PENDING',
            'order_by'  : 'updated_at',
            'order'     : 0
          });
        else
          $state.go('main.user.alc.manage', {'status': status.toUpperCase()});
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function updateHeader(isApprovalFlowPreview) {
    var data = prepareData();
    AlcDraft.put({
        uuid: $scope.alcData.uuid
      }, data,
      function (resource) {
        if (!isApprovalFlowPreview) {
          $state.reload();
        } else {
          previewApprovalFlow();
        }

      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      });
  }

  function prepareData() {
    var data = {
      code: !!$scope.alcData.code ? $scope.alcData.code : undefined,
      name: !!$scope.alcData.name ? $scope.alcData.name : undefined,
      alc_group_code: !!$scope.alcData.alc_group ? $scope.alcData.alc_group.code : undefined,
      item_master_code: !!$scope.alcData.item_master ? $scope.alcData.item_master.code : undefined,
      currency_code: !!$scope.alcData.currency ? $scope.alcData.currency.code : undefined ,
      approval_limit_pr: !!$scope.alcData.approval_limit_pr ? $scope.alcData.approval_limit_pr : undefined,
      approval_limit_month: !!$scope.alcData.approval_limit_month ? $scope.alcData.approval_limit_month : undefined,
      start_date: !!$scope.alcData.start_date ? globalFunc.convertDateToTimestamp($scope.alcData.start_date) : undefined,
      end_date: !!$scope.alcData.end_date ? globalFunc.convertDateToTimestamp($scope.alcData.end_date) : undefined,
      remarks: !!$scope.alcData.remarks ? $scope.alcData.remarks : undefined,
      status: $scope.alcData.status,
      is_revised: !!$scope.alcData.is_revised ? $scope.alcData.is_revised : undefined
    };

    if ($scope.alcData.status === 'DRAFT') {
      data.draft_uuid = !!$scope.alcData.uuid ? $scope.alcData.uuid : undefined
    }

    if (!!$scope.alcData.position) {
      data.position = {
        code: $scope.alcData.position.code,
        name: $scope.alcData.position.name
      };
    } else {
      data.position = undefined;
    }

    return data;
  }

  function approvalPreviewValidation() {
    var anyError = false;
    if (_.isEmpty($scope.alcData.alc_group) || _.isEmpty($scope.alcData.position) || _.isEmpty($scope.alcData.approval_limit_pr)) {
      anyError = true
    }
    return anyError;
  }

  /**
   * Get the approval flow to display it in draft
   */
  function getApprovalPreview() {
    if (!approvalPreviewValidation()) {
      // using angular.equals instead of _.isEqual because lodash has problem when compare deeper layer
      if (!angular.equals($scope.alcData, alcData)) {
        swal({
          title: 'Confirm save changes?',
          text: 'In order to preview the approval flow, changes made to the alc will be saved.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#1ab394',
          confirmButtonText: 'Confirm',
          closeOnConfirm: true
        }, function (isConfirm) {
          if (isConfirm) {
            updateHeader(true);
          }
        });
      } else {
        previewApprovalFlow()
      }
    } else {
      getPositionListing();
      $scope.approvals = [];
      toastr.error('Approval Flow is not ready. No steps meet the specified criteria.')
    }
  }

  function previewApprovalFlow(isInitPreview) {
    approvalPreview.get({
      company_code: $scope.alcData.companies ? $scope.alcData.companies[0].code : $scope.alcData.alc_group.companies[0].code,
      context_type: 'App\\Metabuyer\\Alc\\Models\\Alc',
      context_id: $scope.alcData._id
    }, function (resource) {
      $scope.approvals = resource.content.data;
    }, function (error) {
      if (!isInitPreview) {
        $scope.approvals = [];
        globalFunc.objectErrorMessage(error);
      }
    });
  }

  function canResendAlcEmail() {
    return $scope.alcData.status.toLowerCase() === 'pending' &&
           (globalFunc.findRoleInRoleAssignments($scope.profile.role_assignments, 'TENANT_SUPER_ADMIN') ||
            globalFunc.findRoleInRoleAssignments($scope.profile.role_assignments, 'ADMIN') ||
            isAlcCreator());
  }

  function resendAlcEmail() {
    var email_text = "Approval email will be resent to the following approvers\n\n";
    $scope.approvals.waiting_on.forEach(function (currentVal) {
      if (currentVal.is_active)
        email_text += currentVal.display_name + "\n";
    });

    swal({
      title: "Email re-sent",
      text: email_text,
      type: "success",
      showCancelButton: false,
      confirmButtonColor: "#1ab394",
      confirmButtonText: "Ok",
      timer: 5000,
      closeOnConfirm: true
    }, function (isConfirm) {
      if (isConfirm) {
        $rootScope.$broadcast('refreshComments');
      }
    });

    return resendPendingAlcEmail.post(
      {
        id: $scope.alcData._id
      },
      function (resource) {
        // Success
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    ).$promise;
  }

  function isAlcCreator() {
    return ($scope.profile._id === $scope.alcData.creator_info._id);
  }

  function isAlcRequester() {
    return globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Alc Requester', null, $scope.alcData.alc_group._id);
  }

  function getPositionListing() {
    $scope.positionListing = !!positions && positions.data && !!positions.data.position ?
    _.map(positions.data.position, function (pos) {
      if(pos.status === 0) { return pos }
    }) : null;
  }

  function initialize() {
    $scope.alcData = _.cloneDeep(alcData);
    $scope.isApproved = isApproved();

    $scope.status = $scope.alcData.status;
    $scope.next_status = $scope.alcData.next_status;
    $scope.requestor = {
      "_id": "",
      "display_name": $scope.alcData.created_by,
      "img_url": ""
    };
    loadApprovalFlow();
    getPositionListing();

    // loads draft approval flow if alc group, position, approval limit are set
    if ($scope.status === 'DRAFT') {
      if (!_.isEmpty($scope.alcData.alc_group) && !_.isEmpty($scope.alcData.position) && !_.isEmpty($scope.alcData.approval_limit_pr)) {
        previewApprovalFlow(true);
      }
    }
  }

  $scope.$on('switchAlcTemplate', function (event, value, option) {
    if (value === 'editable') {
      $scope.templateMode = 'new';
      clonedAlc = _.cloneDeep($scope.alcData);
      $scope.alcData.start_date = new Date(parseInt($scope.alcData.start_date));
      $scope.alcData.end_date = new Date(parseInt($scope.alcData.end_date));
      $scope.alcGroupText = $scope.alcData.alc_group.code + ' - ' + $scope.alcData.alc_group.name;
      $scope.positionText = $scope.alcData.position.code + ' - ' + $scope.alcData.position.name;
      $scope.itemText = $scope.alcData.item_master.code + ' - ' + $scope.alcData.item_master.name;

    } else if (option === 'cancel') {
      $scope.templateMode = 'modify';
      $scope.alcData = _.cloneDeep(clonedAlc);
      $scope.alcData.status = 'ACTIVE';
    } else {
      $scope.templateMode = 'modify'
    }
  });

  initialize();
}

alcDetailsCtrl.$inject = [
  '$scope', 'alcData', 'Alc', '$http', '$filter', 'pathConstants', 'loadCacheableData', 'AlcComments', 'globalFunc',
  'Approvals', 'profile', 'HighlightId', '$state', 'toastr', 'updateStatusAlc', 'positions', '$rootScope', 'approvalPreview',
  'AlcDraft', 'resendPendingAlcEmail'
];

angular.module('metabuyer')
  .controller('alcDetailsCtrl', alcDetailsCtrl);

