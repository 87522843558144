'use strict';

function approvalLimitCtrl() {
}

approvalLimitCtrl.$inject = [];

angular
  .module('metabuyer')
  .controller('approvalLimitCtrl', approvalLimitCtrl);
