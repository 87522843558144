'use strict';
/**
 * @name showRolesCtrl
 * @desc Controller to see roles for a company
 */
function showRolesCtrl($scope, listRoles, company, $uibModalInstance) {
  $scope.closeModal = closeModal;
  $scope.listRoles = listRoles;
  $scope.company_name = company;

  function closeModal() {
    $uibModalInstance.close();
  }
}

showRolesCtrl.$inject = ['$scope', 'listRoles', 'company', '$uibModalInstance'];

angular
  .module('metabuyer')
  .controller('showRolesCtrl', showRolesCtrl);
