'use strict';

angular.module('metabuyer.services.bifrost', ['ngResource'])
  .factory('bifrostLogs',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.bifrost.getMappingInfo,
        {
          module: '@module'
        },
        {
          'get': { method: 'GET' }
        }
      );
    }
  ).factory('purchaseOrder',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.bifrost.purchaseOrder,
        {
          module: '@module'
        },
        {
          'get': { method: 'GET' }
        }
      )
   })
  .factory('bifrostSupplier',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.bifrost.supplier,
        {
          module: '@module'
        },
        {
          'get': { method: 'GET' }
        }
      )
    })
  .factory('supplierMappingList',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.bifrost.getMappingList,
        {
          module: '@module'
        },
        {
          'get': { method: 'GET' }
        }
      )
    })
  .factory('mappedSupplier',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.bifrost.mappedSupplier,
        {
          module: '@module'
        },
        {
          'get': { method: 'GET'}
        }
      )
    })
  .factory('searchIntegrationEnvironment',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.bifrost.environment,
        {
          module: '@module'
        },
        {
          'get': { method: 'GET'}
        }
      )
    })
  .factory('integrationSchedule',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.bifrost.schedule,
        {
          'get': { method: 'GET'}
        }
      )
    })
  .factory('bifrostMappingByModule',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.bifrost.mappingModule,
        {
          module: '@module'
        },
        {
          'get': { method: 'GET' }
        }
      );
    }
  )
  .factory('integrationStoragePath',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.bifrost.storagePath,
        {
          'get': { method: 'GET' },
          'put': { method: 'PUT' }
        }
      )
    })
  .factory('bifrostInboundInfo',
    function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.bifrost.inboundInfo,
      {
        'get': { method: 'GET' }
      }
    )
    })
  .factory('bifrostDownloadInboundFile',
    function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.bifrost.downloadInboundFile,
      {
        'get': { method: 'GET' }
      }
    )
    })
  .factory('bifrostOutboundInfo',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.bifrost.outboundInfo,
        {
          'get': { method: 'GET' }
        }
      )
    })
  .factory('bifrostDownloadOutboundFile',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.bifrost.downloadOutboundFile,
        {
          'get': { method: 'GET' }
        }
      )
    })
  .factory('bifrostFileServices',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.bifrost.storage,
        {},
        {
          'get' : {method : 'GET'},
          'delete' : {method : 'DELETE'}
        }
      )
    })
  .factory('bifrostFixedAssetSap',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.bifrost.fixedAssetSap,
        {},
        {
          'post': { method: 'POST' }
        }
      )
    })
  .factory('bifrostFixedAssetMsgp',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.bifrost.fixedAssetMsgp,
        {},
        {
          'post': { method: 'POST' }
        }
      )
    })
  .factory('bifrostProcessStatus',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.bifrost.bifrostProcessStatus,
        {
          'get': { method: 'GET' }
        }
      )
    })
