'use strict';

/**
 * @name tenderAdvancedSearchCtrl
 *
 * @description Controller for Tender advanced search
 *
 * @author Nazrul Wazir <nazrulhmw@sunway.com.my>
 * @copyright Sunway Metacloud &copy; 2022
 */
function tenderAdvancedSearchCtrl($scope, searchModule, listChild, toastr, $rootScope, advancedSearch, exportSearch, metabuyerCache) {

  $scope.tenderStatusOption = [{
      id: 1,
      key: 'ALL',
      value: 'All'
    },
    {
      id: 2,
      key: 'APPROVED',
      value: 'Approved'
    },
    {
      id: 3,
      key: 'PENDING',
      value: 'Pending Approval'
    },
    {
      id: 4,
      key: 'DEACTIVATED',
      value: 'Deactivated'
    },
    {
      id: 5,
      key: 'EXPIRED',
      value: 'Expired'
    },
    {
      id: 6,
      key: 'DELETED',
      value: 'Deleted'
    },
    {
      id: 7,
      key: 'WITHDRAWN',
      value: 'Withdrawn'
    },
    {
      id: 8,
      key: 'DRAFT',
      value: 'Draft'
    },
    {
      id: 9,
      key: 'REJECTED',
      value: 'Rejected'
    }
  ];

  $scope.tenderTypeOption = [{
      id: 1,
      key: 'NEW_WORKS',
      value: 'New Works'
    },
    {
      id: 2,
      key: 'CHANGE_VENDOR',
      value: 'Change Vendor'
    },
    {
      id: 3,
      key: 'ADDITIONAL_SUM',
      value: 'Additional Sum'
    },
    {
      id: 4,
      key: 'DATE_CHANGE',
      value: 'Date Change'
    },
  ];

  $scope.columns = [
    { id: 'tenderNumber', label: 'Tender Number', selected: true },
    { id: 'tenderName', label: 'Tender Name', selected: true },
    { id: 'tenderDescription', label: 'Tender Description', selected: true },
    { id: 'tenderAmount', label: 'Tender Amount', selected: true },
    { id: 'tenderBlockedAmount', label: 'Tender Blocked Amount', selected: true },
    { id: 'tenderCurrency', label: 'Currency', selected: true },
    { id: 'tenderRemarks', label: 'Remarks', selected: true },
    { id: 'tenderGroup', label: 'Tender Group', selected: true },
    { id: 'tenderType', label: 'Tender Type', selected: true },
    { id: 'tenderPurpose', label: 'Purpose', selected: true },
    { id: 'validityStartDate', label: 'Validity Start Date', selected: true },
    { id: 'validityEndDate', label: 'Validity End Date', selected: true },
    { id: 'submissionDate', label: 'Tender Submission Date', selected: true },
    { id: 'approvedDate', label: 'Tender Approved Date', selected: true },
  ];

  $scope.submitAdvSearch = submitAdvSearch;
  $scope.addSearchCriteria = addSearchCriteria;
  $scope.resetForm = resetForm;
  $scope.validateAmountRange = validateAmountRange;
  $scope.errors = {};

  var initialSearchFields = {
    tenderNumber: null,
    tenderName: null,
    tenderDescription: null,
    tenderStatus: null,
    tenderAmountFrom: null,
    tenderAmountTo: null,
    tenderBlockedAmount: null,
    tenderCurrency: null,
    tenderRemarks: null,
    tenderGroup: null,
    tenderType: null,
    tenderPurpose: null,
    startDateStart: null,
    startDateEnd: null,
    endDateStart: null,
    endDateEnd: null,
    submissionDateStart: null,
    submissionDateEnd: null,
    approvedDateStart: null,
    approvedDateEnd: null
  };

  /**
   * function to validate amount range fields, and update/remove error message
   * @param field
   */
  function validateAmountRange(field) {
    if (field === 'totalAmount') {
      if (!!$scope.tenderAmountFrom && !!$scope.tenderAmountTo && ($scope.tenderAmountFrom > $scope.tenderAmountTo)) {
        $scope.errors.totalAmount = 'Invalid Range';
      } else {
        if ('totalAmount' in $scope.errors) {
          delete $scope.errors.totalAmount;
        }
      }
    } else if (field === 'tenderBlockedAmount') {
      if (!!$scope.tenderBlockedAmountFrom && !!$scope.tenderBlockedAmountTo && ($scope.tenderBlockedAmountFrom > $scope.tenderBlockedAmountTo)) {
        $scope.errors.tenderBlockedAmount = 'Invalid Range';
      } else {
        if ('tenderBlockedAmount' in $scope.errors) {
          delete $scope.errors.tenderBlockedAmount
        }
      }
    }
  }

  function validateForm() {

    var throwErr = false;

    if (!!$scope.searchFields.startDateStart && _.isNull($scope.searchFields.startDateEnd)) {
      throwErr = true;
      toastr.error('Please select validity start date');
    }

    if (!!$scope.searchFields.startDateEnd && _.isNull($scope.searchFields.startDateStart)) {
      throwErr = true;
      toastr.error('Please select validity start date');
    }

    if (!!$scope.searchFields.endDateStart && _.isNull($scope.searchFields.endDateEnd)) {
      throwErr = true;
      toastr.error('Please select validity end date');
    }

    if (!!$scope.searchFields.endDateEnd && _.isNull($scope.searchFields.endDateStart)) {
      throwErr = true;
      toastr.error('Please select validity end date');
    }

    if (!!$scope.searchFields.submissionDateStart && _.isNull($scope.searchFields.submissionDateEnd)) {
      throwErr = true;
      toastr.error('Please select submission date');
    }

    if (!!$scope.searchFields.submissionDateEnd && _.isNull($scope.searchFields.submissionDateStart)) {
      throwErr = true;
      toastr.error('Please select submission date');
    }

    if (!!$scope.searchFields.approvedDateStart && _.isNull($scope.searchFields.approvedDateEnd)) {
      throwErr = true;
      toastr.error('Please select approved date');
    }

    if (!!$scope.searchFields.approvedDateEnd && _.isNull($scope.searchFields.approvedDateStart)) {
      throwErr = true;
      toastr.error('Please select approved date');
    }

    if (!throwErr) {
      var searchData = _.clone($scope.searchFields);
      return buildParam(searchData);
    }
  }

  /**
   * function to build criteria parameters
   * @param data object of form input values
   * @returns {boolean|Object}
   */
  function buildParam(data) {

    var params = {};

    if (!!data.tenderNumber) {
      params['criteria[0][code]'] = data.tenderNumber;
    }

    if (!!data.tenderName) {
      params['criteria[0][detail|name]'] = data.tenderName;
    }

    if (!!data.tenderDescription) {
      params['criteria[0][detail|description]'] = data.tenderDescription;
    }

    if (!!data.tenderStatus) {
      params['criteria[0][detail|status]'] = data.tenderStatus.key;
    }

    if (!!data.tenderAmountFrom) {
      params['criteria[0][detail|total_amount][from]'] = data.tenderAmountFrom;
      params['criteria[0][detail|total_amount][to]'] = data.tenderAmountTo;
    }

    if (!!data.tenderBlockedAmountFrom) {
      params['criteria[0][detail|blocked_amount][from]'] = data.tenderBlockedAmountFrom;
      params['criteria[0][detail|blocked_amount][to]'] = data.tenderBlockedAmountTo;
    }

    if (!!data.tenderCurrency) {
      params['criteria[0][detail|currency]'] = data.tenderCurrency;
    }

    if (!!data.tenderRemarks) {
      params['criteria[0][detail|remarks]'] = data.tenderRemarks;
    }

    if (!!data.tenderGroup) {
      params['criteria[0][groups|code]'] = data.tenderGroup;
    }

    if (!!data.tenderName) {
      params['criteria[0][detail|name]'] = data.tenderName;
    }

    if (!!data.tenderType) {
      params['criteria[0][detail|type]'] = data.tenderType.key;
    }

    if (!!data.tenderPurpose) {
      params['criteria[0][detail|purpose]'] = data.tenderPurpose;
    }

    if (!!data.startDateStart) {
      params['criteria[0][detail|validity_start_date][from]'] = moment(data.startDateStart).valueOf();
      params['criteria[0][detail|validity_start_date][to]'] = moment(data.startDateEnd).endOf('day').valueOf();
    }

    if (!!data.endDateStart) {
      params['criteria[0][detail|validity_end_date][from]'] = moment(data.endDateStart).valueOf();
      params['criteria[0][detail|validity_end_date][to]'] = moment(data.endDateEnd).endOf('day').valueOf();
    }

    if (!!data.submissionDateStart) {
      params['criteria[0][detail|submission_date][from]'] = moment(data.submissionDateStart).valueOf();
      params['criteria[0][detail|submission_date][to]'] = moment(data.submissionDateEnd).endOf('day').valueOf();
    }

    if (!!data.approvedDateStart) {
      params['criteria[0][detail|approved_date][from]'] = moment(data.approvedDateStart).valueOf();
      params['criteria[0][detail|approved_date][to]'] = moment(data.approvedDateEnd).endOf('day').valueOf();
    }

    // if status 'all' is selected, the form cannot be empty. so the criteria for 'all' status
    // will only be added if `params` is not empty.
    if (_.isEmpty(params)) {
      toastr.error('Please fill in at least one field');
      return false;
    } else {
      if (!!data.tenderStatus && data.tenderStatus.key === 'ALL') {
        params['criteria[0][detail|status]'] = data.tenderStatus.key;
      }
      params['module'] = 'tender';
      params['offset'] = 50;
      params['criteria[0][$operator]'] = 'and';
      return params;
    }
  }

  function addSearchCriteria(key, value) {
    $scope.searchFields[key] = value;
  }

  function submitAdvSearch() {
    var searchParam = validateForm();

    if (!!searchParam) {
      $scope.embeddedParams = searchParam;

      /**
       * Setting the params to prepare for exporting
       */
      exportSearch.setParams(searchParam);

      advancedSearch.get(
        searchParam,
        function(resource) {
          $scope.dataList = resource.content.data;
          $scope.meta = resource.content.meta;
          $scope.emptyInput = false;
        },
        function(error) {
          toastr.error('Something went wrong');
          $scope.dataList = [];
        }
      );
    } else {
      toastr.error(toastrErrorMessage);
      toastrErrorMessage = null;
      $scope.emptyInput = true;
    }

    $('#show-search-form').removeClass('hidden');
    $('#hide-search-form').addClass('hidden');
  }
  /**
   * function to reset the search form
   */
  function resetForm() {
    $scope.searchFields = _.cloneDeep(initialSearchFields);
    $scope.dataList = [];
  }

  function initialize() {
    // initialize pagination parameters
    $scope.dataList = [];
    $scope.embeddedParams = [];
    $scope.meta = {};
    $scope.searchModule = advancedSearch;

    resetForm();
  }

  var dateLists = [{
      start: 'startDateStart',
      end: 'startDateEnd'
    },
    {
      start: 'endDateStart',
      end: 'endDateEnd'
    },
    {
      start: 'submissionDateStart',
      end: 'submissionDateEnd'
    },
    {
      start: 'approvedDateStart',
      end: 'approvedDateEnd'
    }
  ];

  _.forEach(dateLists, function(value, key) {

    var startDate = value.start;
    var endDate = value.end;

    $scope.$watch('searchFields.' + value.end, function(newVal, oldVal) {
      var startDt = new Date($scope.searchFields[startDate]);

      if (new Date(startDt) > new Date(newVal)) {
        $scope.searchFields[endDate] = oldVal === null ? '' : oldVal;
      }

    }, true)

    $scope.$watch('searchFields.' + value.start, function(newVal, oldVal) {
      if ($scope.searchFields[endDate] === null) {
        return true;
      }

      if (new Date(newVal).getTime() > new Date($scope.searchFields[endDate]).getTime()) {
        $scope.searchFields[startDate] = oldVal;
      }

    }, true)

  });

  initialize();
}

tenderAdvancedSearchCtrl.$inject = [
  '$scope', 'searchModule', 'listChild', 'toastr', '$rootScope', 'advancedSearch', 'exportSearch', 'metabuyerCache'
];

angular.module('metabuyer')
  .controller('tenderAdvancedSearchCtrl', tenderAdvancedSearchCtrl);
