'use strict';

angular.module('metabuyer')
  .directive('alcUtilization',['getUtilization','globalFunc', function(getUtilization, globalFunc) {
    return {
      restrict: 'E',
      scope: {
        alcData: '=',
        alcUuid: '=',
        companies: '=',
        currency: '='
      },
      templateUrl: 'components/alc/utilization/utilizationTemplate.html',
      link: function ($scope, elem, attrs) {
        $scope.loadData = loadUtilization;
        $scope.yearUpdated = yearUpdated;
        $scope.data = [];

        function yearUpdated(){
          loadUtilization($scope.yearSelect.selected)
        }

        function formatRecords(records) {
          var data = [];
          _.forEach(records, function (singleRecord) {
            if(!globalFunc.findInArray(data, 'companyCode', singleRecord.companyCode)) {
              var obj = _.cloneDeep(singleRecord);
              obj.yearMonths = [];
              obj.YearMonthValue = [];
              obj.yearMonths.push(singleRecord.yearMonth);
              obj.YearMonthValue.push(singleRecord.value);
              data.push(obj);
            } else {
              _.forEach(data, function (obj) {
                obj.yearMonths.push(singleRecord.yearMonth);
                obj.YearMonthValue.push(singleRecord.value);
              });
            }
          });
          return data;

        }

        function createUtilizationTable(records) {

          var formattedRecords = formatRecords(records);
          var year = new Date().getFullYear();

          _.forEach($scope.companies, function (singleCompany) { //TODO: optimize me
            _.forEach(formattedRecords, function (singleRecord) {
              if (singleRecord.companyCode === singleCompany.code) {
                var obj = _.cloneDeep(singleCompany);
                var i = 1;
                obj.values = [];
                obj.values.push(singleCompany.code);

                while (i <= 12) {
                  var month = globalFunc.getMonthText(i, 'number');
                  var yearMonth = year + month;
                  var value = 0;
                  var index = singleRecord.yearMonths.indexOf(yearMonth);
                  if (index > -1) {
                    value = singleRecord.YearMonthValue[index];
                  }
                  obj.values.push(value);
                  i++;
                }
                $scope.data.push(obj);
              }
            });
          });

        }


        function loadUtilization(year) {
          getUtilization.get({
            uuid: $scope.alcUuid,
            year: year || undefined
          }, function (resource) {
            $scope.data = [];
            if(!!resource.content && !!resource.content.data) {
              createUtilizationTable(resource.content.data);
            }

          }, function (error) {
            console.log(error);
          });
        }

        function initialize() {
          var defaultYear = new Date().getFullYear();
          if (defaultYear > $scope.alcData.end_date.getFullYear()) {
            defaultYear = $scope.alcData.end_date.getFullYear();
          }

          $scope.yearSelect = {
            selected: defaultYear
          };

          var yearDiff = moment($scope.alcData.end_date).diff($scope.alcData.start_date, 'years')
          $scope.years = [];
          if (!!yearDiff) {
            for (var year = 0; year <= yearDiff; year++) {
              $scope.years.push($scope.alcData.start_date.getFullYear() + year)
            }
          } else {
            $scope.years.push($scope.alcData.end_date.getFullYear());
          }

          loadUtilization($scope.yearSelect.selected); // defaults to current year
        }

        initialize();
      }
    }
  }]);
