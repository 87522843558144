'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('main.user.alc', {
        url: '/alc',
        controller: 'alcCtrl',
        templateUrl: 'app/alc/alc.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $state, $rootScope) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'Alc', 'Read')) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.alc.manage', {
        templateUrl: 'app/alc/manage/manage.html',
        url: '/manage?&{status:[a-zA-Z0-9]+}&{query:[a-zA-Z0-9]+}&{page:[0-9]}&{order_by:[^~,]}&' +
        '{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'alcListCtrl',
        params: { params: null},
        resolve: {
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();
            var params = {
              module: 'alc',
              offset: 20,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            //setting the default status in case the search parameter would replace it
            params['criteria[0][status]'] = $stateParams.status;

            if (!!$stateParams.params) {
              params['criteria[0][name]'] = $stateParams.params.searchName;
              params['criteria[0][code]'] = $stateParams.params.searchCode;
              params['criteria[0][alc_group|code]'] =
                !!$stateParams.params.searchAlcGroup ? $stateParams.params.searchAlcGroup.code : undefined;
              params['criteria[0][status]'] =
                $stateParams.params.searchStatus ?  $stateParams.params.searchStatus.key.toLowerCase() : 'all'; // default status to all if it's undefined to filter out revision in BE
              params['criteria[0][$operator]'] = 'and';

              if (!!$stateParams.params.searchPosition) {
                params['criteria[1][position|code]'] = $stateParams.params.searchPosition.code;
                params['criteria[1][$strict]'] = true;
              }

            }
            else {
              params['criteria[0][name]'] = $stateParams.query;
              params['criteria[0][code]'] = $stateParams.query;
              params['criteria[0][descr]'] = $stateParams.query;
              params['criteria[0][$operator]'] = !!$stateParams.query ? 'or' : null;
              params.criteria_operator = !!$stateParams.query ? 'or' : null;
            }

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          customParams: function(dataList, $stateParams) {
            var params = _.cloneDeep($stateParams);
            if (_.isEmpty(dataList.data)) {
              $stateParams.params = null;
            }

            return params
          },
          $title: function ($stateParams) {
            return 'ALC - Listing' + (!!$stateParams.status ? ' - ' + $stateParams.status : '');
          }
        }
      })
      .state('main.user.alc.details', {
        templateUrl: 'app/alc/details/details.html',
        url: '/details/{id:[a-zA-Z0-9\-]*}',
        controller: 'alcDetailsCtrl',
        resolve: {
          alcData: function ($q, Alc, $stateParams, globalFunc) {
            if (!!$stateParams.id){
              var deferred = $q.defer();
              Alc.get(
                {uuid: $stateParams.id},
                function (resource) {
                  if (!!resource.content && !!resource.content.data) {
                    deferred.resolve(resource.content.data);
                  }
                },
                function (error) {
                  globalFunc.objectErrorMessage(error);
                }
              );
              return deferred.promise;
            }
            else {
              return {};
            }
          },
          positions: function ($q, positionManagement) {
            var deferred = $q.defer();
            positionManagement.get(
              {
                'sort[0][key]': 'name',
                'sort[0][direction]': 'ASC'
              },
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content);
                }
              },
              function (error) {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'ALC - Details';
          }
        }
      })
      .state('main.user.alc.add', {
        templateUrl: 'app/alc/details/details.html',
        url: '/add-new',
        controller: 'addAlcCtrl',
        resolve: {
          $title: function () {
            return 'ALC - Details';
          },
          positions: function ($q, $state, positionManagement, UserPermissions, profile) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'Alc', 'Create')) {
              $state.go('notAuthorized');
            }
            var deferred = $q.defer();
            positionManagement.get(
              {
                'sort[0][key]': 'name',
                'sort[0][direction]': 'ASC'
              },
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content);
                }
              },
              function (error) {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          }
        }
      })
  }]);
