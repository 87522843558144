'use strict';
/**
 * @name tenderManageCtrl
 * @desc Controller for users manage
 */
function tenderManageCtrl(
  $scope, $stateParams, $state, dataList, pathConstants, $uibModal, $filter, HighlightId, globalFunc, $rootScope
) {

  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.showColumn = showColumn;
  $scope.storageKey = 'tender-listing02-selected-columns';
  $scope.navigateToCreationPage = navigateToCreationPage;
  $scope.starringTender = starringTender;
  $scope.checkStarred = checkStarred;
  $scope.tabSelection = tabSelection

  function navigateToCreationPage() {
    $state.go('main.user.tender.add');
  }

  /**
   * function to navigate between the sub tabs
   * @param tab
   */
  function tabSelection(tab) {
    switch (tab) {
      case 'all':
        {
          $scope.selectedListTab = tab;
          $state.go('main.user.tender.manage', {
            status: $scope.status,
            tab: tab,
            extraFilterKey: null,
            extraFilterValue: null,
            page: null
          });
          break;
        }
      case 'yours':

        var extraFilterKey = '[creator_info|_id][]';

        if ($scope.status === 'pending') {
          extraFilterKey = '[submitted_by][]';
        }

        {
          $scope.selectedListTab = tab;
          $state.go('main.user.tender.manage', {
            status: $scope.status,
            tab: tab,
            extraFilterKey: extraFilterKey,
            extraFilterValue: $rootScope.currentUser._id,
            page: null
          });
          break;
        }
      case 'waiting':
        {
          $scope.selectedListTab = tab;
          $state.go('main.user.tender.manage', {
            status: $scope.status,
            tab: tab,
            extraFilterKey: '[waiting_on][]',
            extraFilterValue: $rootScope.currentUser._id,
            page: null
          });
          break;
        }
    }
  }

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional) {
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function starringTender(tenderId) {
    var isStarred = checkStarred(tenderId);
    globalFunc.starFavourites(isStarred, tenderId, 'Tender');
  }

  function checkStarred(tenderId) {
    if (!!$rootScope.currentUser.favourites && !!$rootScope.currentUser.favourites.Tender) {
      var userFavourites = $rootScope.currentUser.favourites.Tender;
      return userFavourites.indexOf(tenderId) > -1;
    }
    return false;
  }

  function initialize() {
    $scope.columns = $scope.columns.filter(function (columnn) {
      return columnn.id !== 'suppliers|supplier_id'
    });
    $scope.highlightId = HighlightId.getId();
    if (!!$scope.highlightId) {
      HighlightId.setId(null);
    }

    if ($stateParams.status === 'all' && $stateParams.tab === 'yours' && $stateParams.extraFilterKey === undefined) {
      $state.go('main.user.tender.manage', { status: 'all', tab: 'yours', 'extraFilterKey': '[creator_info|_id][]', 'extraFilterValue': $rootScope.currentUser._id });
    }

    $scope.$parent.status = $stateParams.status;
    $scope.status = $stateParams.status;
    $scope.dataList = dataList.data;
    $scope.meta = dataList.meta;
    $scope.budgetManagementService = dataList;
    $scope.params = 'tender';

    $scope.selectedListTab = $stateParams.tab;
    if (!$scope.selectedListTab && $scope.status === 'pending') {
      $scope.selectedListTab = 'waiting';
      tabSelection($scope.selectedListTab);
    }
  }

  initialize();
}

tenderManageCtrl.$inject = [
  '$scope', '$stateParams', '$state', 'dataList', 'pathConstants', '$uibModal', '$filter', 'HighlightId', 'globalFunc', '$rootScope'
];

angular
  .module('metabuyer')
  .controller('tenderManageCtrl', tenderManageCtrl);
