'use strict';

/**
 * @name compareBudgetVersionV2Ctrl
 * @desc The controller for comparing budget versions data
 *
 * @copyright 2021 Metacloud Sdn. Bhd.
 */
function compareBudgetVersionV2Ctrl($scope, $uibModal, $uibModalInstance, currentStatus,
                                  versions, selectedVersion) {
  $scope.close = close;
  $scope.compareDate = compareDate;
  $scope.tab = 1;
  $scope.toggleTab = toggleTab;
  $scope.glAccounts = [];

  function toggleTab(tab) {
    return $scope.tab = tab;
  }

  function getGlAccounts() {
    $scope.glAccounts = _.cloneDeep($scope.selectedVersion[1].gl_accounts);

    // Add added flag to new alc's companies
    _.forEach($scope.glAccounts, function(e){
      e.added = true;
    })

    // combine the previous list and current list without duplicates and add added/removed flag
    _.forEach($scope.selectedVersion[0].gl_accounts, function(prevGlAccount) {
      var glAccount = _.find($scope.glAccounts, function(e) {
        if (e.account_code === prevGlAccount.account_code) {
          e.added = false;
          if (e.amount !== prevGlAccount.amount) {
            e.previousAmount = prevGlAccount.amount;
            e.updated = true;
          }
          return true
        }
      });

      if (_.isEmpty(glAccount)) {
        var removedGlAccount = _.cloneDeep(prevGlAccount);
        removedGlAccount.removed = true;
        $scope.glAccounts.push(removedGlAccount);
      }
    });
  }

  /**
   * On modal close
   */
  function close() {
    $uibModalInstance.close();

    // Open budget version modal if currently viewing approved budget
    $uibModal.open({
      templateUrl: 'components/budget/versions/budgetVersions.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        budgetVersions: function () {
          return versions;
        },
        currentStatus: function () {
          return currentStatus;
        }
      },
      controller: 'budgetVersionsCtrl'
    });
  }

  function prepareBillingCompanyData(data) {
    if (data.is_all_shadow_companies) {
      data.shadowCompaniesText = 'All';
    } else if (!_.isEmpty(data.shadow_companies)) {
      data.shadowCompaniesText = _.pluck(data.shadow_companies, 'descr').join(', ');
    }
  }

  function prepareYearData(data) {
    if (!_.isEmpty(data.years)) {
      data.yearsText = _.pluck(data.years, 'value').join(', ');
    }
  }

  /**
   * Implode the cost center values of budget master
   *
   * @param {array} data Budget data to be processed
   */
  function prepareCostCenterData(data) {
    if (data.is_all_cost_center) {
      data.cost_center = 'All';
    } else if (!!data.cost_center && _.isArray(data.cost_center)) {
      data.cost_center = _.pluck(data.cost_center, 'descr').join(', ');
    }
  }

  function compareDate(date1, date2) {
    var convertedDate1 = new Date(date1).setHours(0, 0, 0);
    var convertedDate2 = new Date(date2).setHours(0, 0, 0);

    return convertedDate1 === convertedDate2;
  }

  function initialize() {
    $scope.selectedVersion = selectedVersion;
    prepareYearData($scope.selectedVersion[0]);
    prepareYearData($scope.selectedVersion[1]);
    prepareCostCenterData($scope.selectedVersion[0]);
    prepareCostCenterData($scope.selectedVersion[1]);
    prepareBillingCompanyData($scope.selectedVersion[0]);
    prepareBillingCompanyData($scope.selectedVersion[1]);
    getGlAccounts();
  }

  initialize();
}

compareBudgetVersionV2Ctrl.$inject = [
  '$scope', '$uibModal', '$uibModalInstance', 'currentStatus', 'versions', 'selectedVersion'
];

angular.module('metabuyer')
  .controller('compareBudgetVersionV2Ctrl', compareBudgetVersionV2Ctrl);
