'use strict';

angular.module('metabuyer.services.research', ['ngResource'])
  .factory('newResearchService', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.research.new,
      {
        id: '@id'
      },
      {
        'post': {method: 'POST'}
      }
    );
  })
  .factory('researchServices', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.research.single,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'},
        'put': {method: 'PUT'}
      }
    );
  })


;
