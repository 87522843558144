'use strict';

angular.module('metabuyer.services.kharon', ['ngResource'])
  .factory('pushToKharon', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.kharon.pushToKharon,
      {},
      {
        'post': {method: 'POST'}
      }
    );
  })
  .factory('kharonCommandLogs', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.kharon.commandLogs,
      {},
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('kharonSchedulerConfigs', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.kharon.schedulerConfigs,
      {},
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('kharonUpdateSchedulerConfig', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.kharon.updateSchedulerConfig,
      {},
      {
        'put': {method: 'PUT'}
      }
    );
  })
  .factory('kharonUsers', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.kharon.users,
      {},
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('kharonUser', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.kharon.user,
      {},
      {
        'put': {method: 'PUT'},
        'post': {method: 'POST'}
      }
    );
  });
