'use strict';
/**
 * @name securityQuestionSetupCtrl
 * @desc Controller for to update Security Question
 */
function securityQuestionSetupCtrl($rootScope, $scope, $state, $translate, $auth, toastr, globalFunc, securityQuestion, getSecurityQuestionList, ManageLocalStorage) {
  $scope.isLoading = false;
  $scope.securityQuestionList = [];
  $scope.selected = {
    security_question: null
  }
  getSecurityQuestions();
  securityAnswerInputResolver();

  $scope.logout = function () {
    ManageLocalStorage.clearLocalStorage();
    window.localStorage.removeItem("satellizer_token");
    window.localStorage.removeItem("email");
    $auth.logout();
    $rootScope.currentUser = null;
    $state.go('login');
  }

  $scope.submitSecurityQuestion = function () {
    $scope.isLoading = true;
    securityQuestion.put(
      {},
      {
        security_question: $scope.selected.security_question,
        security_answer: btoa(unescape(encodeURIComponent($scope.security_answer))),
      },
      function (response) {
        toastr.success(response.content.message);
          $state.go('main.user.mainMenu.waiting-on-you');
      },
      function (error) {
        $scope.isLoading = false;
        globalFunc.objectErrorMessage(error);
      }
    )
  }
  function getSecurityQuestions() {
    getSecurityQuestionList.get(
      {},
      {},
      function (response) {
        $scope.securityQuestionList = response.content.data;
        if ($translate.use() === 'cn') {
          _.forEach($scope.securityQuestionList, function(securityQuestion, index){
            securityQuestion.question = securityQuestion.question_chinese;
            $scope.securityQuestionList['index'] = securityQuestion;
          });
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function securityAnswerInputResolver() {
    var isOkay = false;
    if (typeof CSS.supports) {
      if (CSS.supports('-webkit-text-security','disc')) {
        isOkay = true;
      }
    }
    if (!isOkay) {
      var input = document.createElement('input');
      input.className = "hide";
      document.getElementById('appending_here').appendChild(input);
      document.getElementById('security_answer').type = 'password';
    }
  }
}

securityQuestionSetupCtrl.$inject = ['$rootScope', '$scope', '$state', '$translate', '$auth', 'toastr', 'globalFunc','securityQuestion', 'getSecurityQuestionList', 'ManageLocalStorage'];

angular
  .module('metabuyer')
  .controller('securityQuestionSetupCtrl', securityQuestionSetupCtrl);
