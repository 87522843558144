'use strict';

function compareCompanyContractVersionCtrl(
  $scope, $uibModalInstance, $uibModal, versions, globalFunc, selectedVersion, currentStatus,
  supplierFunctions, contract
) {
  $scope.closeComparisonModal = closeComparisonModal;
  $scope.compareArrayValue = compareArrayValue;
  $scope.currentStatus = currentStatus;

  function closeModal() {
    $uibModalInstance.close();
  }

  function closeComparisonModal() {
    // Remove row highlighting
    closeModal();
    $uibModal.open({
      templateUrl: 'components/supplierComponents/contractDetails/companyContractVersions/companyContractVersions.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        contractDetails: function () {
          return contract;
        },
        contractVersions: function () {
          return versions;
        },
        contractStatus: function () {
          return currentStatus;
        }
      },
      controller: 'companyContractVersionsController'
    });
  }


  /**
   * compare Arrays value
   * @param array1
   * @param array2
   * @param fieldToCompare
   * @returns {boolean}
   */
  function compareArrayValue(array1, array2, fieldToCompare) {
    var arrIteration;

    if (!array1 || !array2)
      return false;

    if (array1.length !== array2.length)
      return false;

    for (arrIteration in array1) {
      if (array1.hasOwnProperty(arrIteration)) {
        var checkValInArray2 = globalFunc.findInArray(array2, fieldToCompare, array1[arrIteration][fieldToCompare]);
        if (!checkValInArray2)
          return false;
      }
    }

    return true;
  }

  function initialize() {
    $scope.paymentTerms = supplierFunctions.getPaymentTerms();
    $scope.paymentMethods = supplierFunctions.getPaymentMethods();
    $scope.currencies = supplierFunctions.getCurrencies();
    $scope.taxes = supplierFunctions.getTaxes();
    $scope.businessNatures = supplierFunctions.getSupplierBusinessNatureList();
    $scope.contractVersions = selectedVersion;
    $scope.totalVersions = versions.length;

    if (!!$scope.contractVersions) {
      $scope.paymentTerm1 =
        globalFunc.findInArray($scope.paymentTerms, 'code', $scope.contractVersions[0].payment_term_code);
      $scope.paymentTerm2 =
        globalFunc.findInArray($scope.paymentTerms, 'code', $scope.contractVersions[1].payment_term_code);
      $scope.paymentMethod1 =
        globalFunc.findInArray($scope.paymentMethods, 'code', $scope.contractVersions[0].payment_method_code);
      $scope.paymentMethod2 =
        globalFunc.findInArray($scope.paymentMethods, 'code', $scope.contractVersions[1].payment_method_code);
      $scope.currency1 =
        globalFunc.findInArray($scope.currencies, 'code', $scope.contractVersions[0].currency_code);
      $scope.currency2 =
        globalFunc.findInArray($scope.currencies, 'code', $scope.contractVersions[1].currency_code);
      $scope.tax1 =
        globalFunc.findInArray($scope.taxes, 'code', $scope.contractVersions[0].tax_code);
      $scope.tax2 =
        globalFunc.findInArray($scope.taxes, 'code', $scope.contractVersions[1].tax_code);
      $scope.priBusinessNature1 =
        globalFunc.findInArray($scope.businessNatures, 'shortDescr', $scope.contractVersions[0].primary_business_nature);
      $scope.priBusinessNature2 =
        globalFunc.findInArray($scope.businessNatures, 'shortDescr', $scope.contractVersions[1].primary_business_nature);
      $scope.secBusinessNature1 =
        globalFunc.findInArray($scope.businessNatures, 'shortDescr', $scope.contractVersions[0].secondary_business_nature);
      $scope.secBusinessNature2 =
        globalFunc.findInArray($scope.businessNatures, 'shortDescr', $scope.contractVersions[1].secondary_business_nature);

      if (!!$scope.contractVersions[1].receiving_mode && !!$scope.contractVersions[0].receiving_mode) {
        var receiving_mode1 = $scope.contractVersions[0].receiving_mode;
        $scope.receiving_mode1 = {
          email: (receiving_mode1 & 2) === 2? 'Yes' : 'No',
          fax: (receiving_mode1 & 4) === 4? 'Yes' : 'No',
          post: (receiving_mode1 & 8) === 8? 'Yes' : 'No'
        };

        var receiving_mode2 = $scope.contractVersions[1].receiving_mode;
        $scope.receiving_mode2 = {
          email: (receiving_mode2 & 2) === 2? 'Yes' : 'No',
          fax: (receiving_mode2 & 4) === 4? 'Yes' : 'No',
          post: (receiving_mode2 & 8) === 8? 'Yes' : 'No'
        }
      }

      var contacts1 = $scope.contractVersions[0].contacts;
      var contacts2 = $scope.contractVersions[1].contacts;
      $scope.contactsChanged = !angular.equals(contacts1, contacts2);
    }
  }

  initialize();
}

compareCompanyContractVersionCtrl.$inject = [
  '$scope', '$uibModalInstance', '$uibModal', 'versions', 'globalFunc', 'selectedVersion', 'currentStatus',
  'supplierFunctions', 'contract'
];

angular.module('metabuyer')
  .controller('compareCompanyContractVersionCtrl', compareCompanyContractVersionCtrl);
