'use strict';

angular.module('metabuyer')
  .controller('addSupplierUserCtrl', addSupplierUserCtrl);

addSupplierUserCtrl.$inject = ['$scope', '$state', 'HighlightId', 'timezones', 'currencies', 'toastr', 'supplierUserService', '$translate'];

function addSupplierUserCtrl($scope, $state, HighlightId, timezones, currencies, toastr, supplierUserService, $translate) {
  $scope.create = create;
  $scope.back = back;

  $scope.timezones = timezones;
  $scope.currencies = currencies;
  $scope.saveAs = false;
  $scope.user = {};

  function create() {
    if (validateUser()) {
      var payload = _.clone($scope.user);

      delete payload.isExistingEmail;
      delete payload.isValidPhone;

      supplierUserService.post(
        {},
        payload,
        function (resource) {
          $translate("messages.supplierUserSuccessfullyCreated").then(function (trans) {
            toastr.success(trans);
          });
          $state.go('main.user.supplier.manageSupplierUser', {status: 'pending'});
          HighlightId.setId($scope.user.sourcing_supplier_id);
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      )
    } else {
      toastr.error('Please complete all required fields before proceeding');
    }
  }

  function validateUser() {
    $scope.saveAs = true;

    if (!$scope.user.display_name || !$scope.user.display_name.length) {
      return false;
    }

    if (!$scope.user.email || !$scope.user.email.length) {
      return false;
    }

    if ($scope.user.isExistingEmail) {
      return false;
    }

    if (!$scope.user.isValidPhone) {
      return false;
    }

    if (!$scope.user.currency || !$scope.user.currency.length) {
      return false;
    }

    if (!$scope.user.config || !$scope.user.config.TZ.length) {
      return false;
    }

    return true;
  }

  function back() {
    $state.go('main.user.supplier.manageSupplierUser', {status: 'all'});
  }
}
