'use strict';

function auditViewerCtrl($scope, listRawAudits) {
  $scope.loadAudits = loadAudits;
  $scope.service = listRawAudits;


  function loadAudits(id) {
    listRawAudits.get({
        id: id
      },
      function (resource) {
        $scope.res = resource.data;
        $scope.resMetaData = resource.meta;
        $scope.embeddedParams = {
          id: id
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      })
  }
}

auditViewerCtrl.$inject = ['$scope', 'listRawAudits'];

angular.module('metabuyer')
  .controller('auditViewerCtrl', auditViewerCtrl);
