'use strict';

angular.module('metabuyer')
  .factory('Contacts',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.contactPersons.contacts,
      {
      },
      {
        'getContact': {
          method: 'GET',
          params: {
          },
          url: pathConstants.apiUrls.contactPersons.contacts
        },
        'updateContact': {
          method: 'PUT',
          params: {
            id: "@id"
          },
          url: pathConstants.apiUrls.contactPersons.single
        },
        'addContact': {
          method: 'POST',
            params:{
          },
          url: pathConstants.apiUrls.contactPersons.contacts
        },
        'removeContact': {
          method: 'DELETE',
          params:{
          },
          url: pathConstants.apiUrls.contactPersons.single
        }
      }
    );
  });
