'use strict';

function kharonListCtrl($scope, $state, dataList, $uibModal, type, globalFunc) {
  $scope.title = type;
  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.showColumn = showColumn;
  $scope.capitalizeFirstCharacter = globalFunc.capitalizeFirstCharacter;

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional) {
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }
}

kharonListCtrl.$inject = ['$scope', '$state', 'dataList', '$uibModal', 'type', 'globalFunc'];

angular.module('metabuyer')
  .controller('kharonListCtrl', kharonListCtrl);
