(function(){
  'use strict';

  angular.module('metabuyer')
    .directive('editableFocus', function() {
      return {
        restrict: 'E',
        scope: {
          model: '=',
          focus: '=',
          isTextarea: '=',
          textPlaceholder: '=',
          inputPlaceholder: '=',
          updateFunction: '&',
          enableShowLessShowMore: '='
        },
        templateUrl: 'components/editableFocus/editableFocusTemplate.html',
        controller: function($scope) {
          $scope._ = {
            isEmpty: _.isEmpty // check empty string && null && undefined
          };
        },
        link: function(scope, element, attr){
          var input;
          if(!scope.isTextarea) {
            input = element.find('input');
            input.bind('keydown', function (event) {
              if (event.which === 13 || event.keyCode === 13) {
                event.preventDefault();
                scope.focus = false;
                scope.$apply();
              }

            });

            element.find('textarea').remove();
          } else if(!!scope.isTextarea) {
            input = element.find('textarea');
            input.addClass('google-contact-textarea');
            if(!!scope.model) {
              input[0].style.height = '200px';
            }
            input.on('keyup', function() {
              this.style.height = "5px";
              this.style.height = (this.scrollHeight)+"px";
            });

            element.find('input').remove();
          }

          input.on('blur', function (event) {
            scope.focus = false;
            scope.updateFunction();
            scope.$apply();
          });
        }
      }
    })
})();
