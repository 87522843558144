'use strict';

angular.module('metabuyer.services.BusinessUnits', ['ngResource'])
  .factory('listingBusinessUnits',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.BusinessUnits.list,
      {
      },
      {
        'get': { method: 'GET' },
        'post': { method: 'POST' }
      }
    );
  })
  .factory('activeBusinessUnits',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.BusinessUnits.active,
      {
      },
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('singleBU',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.BusinessUnits.single,
      {
        id: '@id'
      },
      {
        'get': { method: 'GET' },
        'put': { method: 'PUT' },
        'delete': { method: 'DELETE' }
      }
    );
  });
