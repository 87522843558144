'use strict';
/**
 * @name approvalWaitingOn
 * @desc directive for the approval waiting on
 * //TODO: added documentation explaining this directive (Ahmed Saleh)
 */
angular
  .module('metabuyer')
  .directive('approvalWaitingOn',function() {
    return {
      restrict: 'EA',
      template : '<div ng-include="template"></div>',
      scope: {
        data: '='
      },
      controller: function ($scope, $location, $anchorScroll, $rootScope) {
        $scope.waiting_on_user = '';
        $scope.moreMin = false;
        $scope.scrollTo = scrollTo;
        $scope.template= $rootScope.isMobileMode ? 'components/approvalWaitingOn/approvalWaitingOnTemplate-mobile.html':
          'components/approvalWaitingOn/approvalWaitingOnTemplate.html';

        function scrollTo(id) {
          var old_hash = $location.hash();
          $location.hash(id);
          $anchorScroll();
          $location.hash(old_hash);
        }
        function checkIfUserIsOriginalApprover(user, unapprovedRoles){
          var check = false;
          for(var i in unapprovedRoles){
            for(var j in unapprovedRoles[i].users)
            {
              if(unapprovedRoles[i].users[j]._id === user._id){
                check = true;
              }
            }
          }
          return check;
        }

        $scope.initialize = function () {
          if (!$scope.data || !$scope.data.waiting_on) {
            return false;
          }
          else {
            var waiting_on_list = [];
            var temp_waiting_on_list = '';
            var min;
            var currentStep;
            var unapprovedRoles = [];
            /**
             * determining the step and minimum
             */
            for (var i = 0; i < $scope.data.steps.length ; i++) {
              if (Number($scope.data.steps[i].seq) === Number($scope.data.current_step)) {
                currentStep = $scope.data.steps[i];
              }
            }

            if(!!currentStep && !!currentStep.min){
              min = currentStep.min;
            }

            /**
             * determining the unapproved roles
             */
            var currentRoles = [];
            if (!!currentStep && !!currentStep.roles) {
              for(var j = 0; j < currentStep.roles.length; j++){
                currentRoles.push(currentStep.roles[j].role_id.toString());
              }
            }
            if (!!currentStep && !!currentStep.approvedRoles) {
              var approvedRoles = Object.keys(currentStep.approvedRoles);
              var index;
              if(approvedRoles.length === 0){
                unapprovedRoles = currentRoles;
              }
              else{
                for(k = 0; k < approvedRoles.length; k++){
                  index = currentRoles.indexOf(approvedRoles[k]);
                  if(index > -1){
                    currentRoles.splice(index, 1);
                  }
                }
                unapprovedRoles = currentRoles;
              }
            }
            else {
              unapprovedRoles = currentRoles;
            }

            /**
             * check if escalated
             */
            var escalated = false;
            for(var k = 0; k < $scope.data.waiting_on.length; k++) {
              if (checkIfUserIsOriginalApprover($scope.data.waiting_on[k], unapprovedRoles) === false) {
                escalated = true;
                break;
              }
            }
            /**
             * creating the list of the waiting on users in case it was not escalated
             */
            //TODO: filter the waiting on by their roles to create a text right now this only reads from the flow,
            //TODO: which will give a wrong display in case of Escalation(Ahmed Saleh)
            if(!escalated) {
              for (i = 0; i < unapprovedRoles; i++) {
                for (j = 0; j < unapprovedRoles[i].users.length; j++) {
                  if (unapprovedRoles[i].users.length > 1 && Number(j) === 0) {
                    temp_waiting_on_list += '('
                  }
                  if (unapprovedRoles[i].users.length > 1 && Number(j) !== 0 && unapprovedRoles[i].users.length > j) {
                    temp_waiting_on_list += ' or '
                  }
                  temp_waiting_on_list += unapprovedRoles[i].users[j].display_name;
                  if (unapprovedRoles[i].users.length === Number(j) + 1) {
                    if (unapprovedRoles[i].users.length !== 1) {
                      temp_waiting_on_list += ')';
                    }
                    waiting_on_list.push(temp_waiting_on_list);
                    temp_waiting_on_list = '';
                  }
                }
              }
            }
            /**
             * its escalated so the viewing will be simple
             * TODO: update this once Backend updates (Ahmed Saleh)
             */
              else {
              for (i= 0; i < $scope.data.waiting_on.length; i++) {
                  waiting_on_list.push($scope.data.waiting_on[i].display_name);
                  temp_waiting_on_list = '';
              }
            }

           /**
            * To formulate the join condition
            */
            var waiting_on_text = '';
            var join_condition;
            for (i = 0; i < waiting_on_list.length; i++) {
              if (waiting_on_list.length <= min) {
                join_condition = ' & '
              }
              else {
                join_condition = ' or '
              }
              waiting_on_text += waiting_on_list[i];
              if (i !== waiting_on_list.length - 1 && waiting_on_list.length !== 1) {
                waiting_on_text += join_condition;
              }
            }
            $scope.waiting_on_user = waiting_on_text;
            if (!!$scope.data.onHoldBy && !!$scope.data.onHoldBy.display_name) {
              $scope.onHoldBy = $scope.data.onHoldBy.display_name;
            }

            $scope.watcher  = $scope.data.watcher || [];
          }
        };
        $scope.$watch('data', function () {
          $scope.initialize();
        }, true);

      }
    }
});
