'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function ($stateProvider) {

    $stateProvider
      .state('main.user.poConsolidation', {
        url: '/consolidate',
        controller: 'poConsolidationCtrl',
        templateUrl: 'app/poConsolidation/poConsolidation.html',
        resolve: {
          accessRights: function (profile, $state, globalFunc) {
            if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'PO Requester')) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.poConsolidation.manage', {
        url: '/manage?{query:[a-zA-Z0-9]+}&{status:[a-zA-Z0-9]+}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&' +
        '{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}&{tab:[a-zA-Z0-9]+}',
        controller: 'poConsolidationManageCtrl',
        params: {items: null},
        templateUrl: 'app/poConsolidation/manage/manage.html',
        resolve: {
          dataList: function ($q, $state, $stateParams, searchModule, exportSearch) {
            var deferred = $q.defer();

            var params = {
              module: 'purchaseOrderConsolidation',
              offset: 50,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            //prepare status
            if ($stateParams.status === 'partially_utilized') {
              params['criteria[0][status][0]'] = 'PARTIALLY_UTILIZED';
              params['criteria[0][status][1]'] = 'ACTIVE';
              params['criteria[0][$operator]'] = 'or';
            } else {
              params['criteria[0][status]'] = $stateParams.status.toUpperCase();
              params['criteria[0][$operator]'] = 'or';
            }

            //prepare search query
            if (!!$stateParams.query) {
              if (!!$stateParams.filter) {
                if ($stateParams.filter === 'requisition|reference') {
                  params['criteria[1][' + $stateParams.filter + ']'] = $stateParams.query;
                  params['criteria[1][requisition|custom_reference]'] = $stateParams.query;
                  params['criteria[1][$operator]'] = 'or';
                } else if ($stateParams.filter === 'company|descr') {
                  params['criteria[1][' + $stateParams.filter + ']'] = $stateParams.query;
                  params['criteria[1][company|descr]'] = $stateParams.query;
                  params['criteria[1][company|code]'] = $stateParams.query;
                  params['criteria[1][$operator]'] = 'or';
                } else if ($stateParams.filter === 'shadow_company|descr') {
                  params['criteria[1][' + $stateParams.filter + ']'] = $stateParams.query;
                  params['criteria[1][shadow_company|descr]'] = $stateParams.query;
                  params['criteria[1][shadow_company|code]'] = $stateParams.query;
                  params['criteria[1][$operator]'] = 'or';
                } else {
                  params['criteria[1][' + $stateParams.filter + ']'] = $stateParams.query;
                }
              } else {
                params['criteria[1][requisition|reference]'] = $stateParams.query;
                params['criteria[1][requisition|custom_reference]'] = $stateParams.query;
                params['criteria[1][requisition|title]'] = $stateParams.query;
                params['criteria[1][company|descr]'] = $stateParams.query;
                params['criteria[1][company|code]'] = $stateParams.query;
                params['criteria[1][shadow_company|descr]'] = $stateParams.query;
                params['criteria[1][shadow_company|code]'] = $stateParams.query;
                params['criteria[1][$operator]'] = 'or';
              }
            }

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function () {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return (!!$stateParams.status ? $stateParams.status : "");
          }
        }
      })
      .state('main.user.poConsolidation.itemListing', {
        templateUrl: 'app/poConsolidation/itemListing/manage.html',
        url: '/itemListing?{query:[a-zA-Z0-9]+}&{status:[a-zA-Z0-9]+}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&' +
          '{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}&{tab:[a-zA-Z0-9]+}',
        controller: 'poConsolidationItemManageCtrl',
        params: {
          references: null,
          defaultDeliveryInfo: null
        },
        resolve: {
          defaultDeliveryInfo: function ($stateParams) {
            return $stateParams.defaultDeliveryInfo
          },
          dataList: function ($q, $stateParams, searchModule) {
            var deferred = $q.defer();
            if (!!$stateParams.references) {
              var params = {
                module: 'purchaseOrderConsolidationItem',
                offset: 50,
                order_by: $stateParams.order_by,
                order: $stateParams.order,
                'criteria[0][requisition|reference]' :  [$stateParams.references]
              };

              searchModule.get(
                params,
                function (resource) {
                  deferred.resolve(resource.content || {data: []});
                },
                function () {
                  deferred.resolve([]);
                }
              );
              return deferred.promise;
            }
          },
          $title: function ($stateParams) {
            return "Items - listing" + (!!$stateParams.status ? " - " +
              $stateParams.status.toLowerCase() : "");
          }
        }
      })
      .state('main.user.poConsolidation.details', {
        url: '/POConsolidation',
        controller: 'poCreationCtrl',
        templateUrl: 'app/poConsolidation/details/details.html',
        params: {items: null},
        resolve: {
          items: function ($stateParams) {
            return $stateParams.items;
          },
          $title: function(){
            return 'PO Consolidation';
          }
        }
      })
  }]);
