'use strict';
/**
 * @name assetCategory
 * @desc directive for the Asset Categories within a Company
 */
angular
  .module('metabuyer')
  .directive('assetCategory',function() {
    return {
      restrict: 'E',
      templateUrl: 'components/assetCategory/assetCategoryTemplate.html',
      scope: {
        data: '=',
        code: '=',
        companyCode: '='
      },
      controller: function(
        $scope, accountCodesList, toastr, $rootScope, $uibModal, pathConstants,
        globalFunc, searchModule, $location, $stateParams, singleAssetCategory, $http, $filter
      ) {
        $scope.dataList = $scope.data.data;
        $scope.meta = $scope.data.meta;
        $scope.accountCodesListService = accountCodesList;
        $scope.isOffline = $rootScope.isOffline;
        $scope.parent = {};
        $scope.submitted = false;
        $scope.root = {
          id: '',
          code: ''
        };
        $scope.parentCode = {
          status: '',
          code: ''
        };

        $scope.newAssetCategory = { code: '', name: '', accountCode: '', assetUsefulLife: '' };

        $scope.search = search;
        $scope.showColumn = showColumn;
        $scope.query = $stateParams.query;
        $scope.searchText = $stateParams.query;
        $scope.addNewAssetCategory = addNewAssetCategory;
        $scope.getActiveStatusName = getActiveStatusName;
        $scope.assetCategoryDetails = assetCategoryDetails;
        $scope.searchAccountCodes = searchAccountCodes;
        $scope.selectAccountCode = selectAccountCode;
        $scope.accountCodeSearch = '';

        $scope.importLink = pathConstants.apiUrls.accountCode.import;
        $scope.columns = [
            {id: 'code', label: 'Asset Category Code'},
            {id: 'name', label: 'Asset Category Name'},
            {id: 'account_code', label: 'Account Code'},
            {id: 'asset_useful_life', label: 'Asset Useful Life'},
            {id: 'created_at', label: 'Created At'},
            {id: 'creator.display_name', label: 'Created By'},
            {id: 'updated_at', label: 'Updated At'},
            {id: 'updater.display_name', label: 'Updated By'},
            {id: 'is_active', label: 'Status'}
          ];

        $scope.storageKey = 'company-management-asset-category-selected-columns';

        function showColumn(id){
          for (var i in $scope.columns) {
            if (id === $scope.columns[i].id) {
              if(!!$scope.columns[i].conditional){
                if($scope.status === $scope.columns[i].condition){
                  return $scope.columns[i].selected;
                }
                if($scope.columns[i].condition instanceof Array){
                  for(var j in $scope.columns[i].condition){
                    if($scope.status === $scope.columns[i].condition[j]){
                      return $scope.columns[i].selected;
                    }
                  }
                }
              }else{
                return $scope.columns[i].selected;
              }
            }
          }
        }

        function selectAccountCode(newAccountCode){
          $scope.newAssetCategory.accountCode = newAccountCode;
        }

        function searchAccountCodes(val) {
          var moduleParam = {
            module: 'account-code'
          };

          var additionalParams = {
            'criteria[1][name]': val,
            'criteria[1][descr]': val,
            'criteria[1][code]': val,
            'criteria[1][$operator]': 'or',
            'criteria[0][company_code]': $scope.companyCode,
            limit: 5
          }

          return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
            params: additionalParams
          }).then(function (response) {
            if (response.data.content.data.length === 0) {
              return [];
            } else {
              return response.data.content.data.map(function (item) {
                return item
              });
            }
          });
        }

        function assetCategoryDetails(index) {
          var assetCategoryUuid = $scope.dataList[index].uuid;
          var modalInstance = $uibModal.open({
            templateUrl: 'components/assetCategory/details/details.html',
            backdrop: 'static',
            keyboard: false,
            controller: 'assetCategoryDetailsCtrl',
            size: 'lg',
            resolve: {
              assetCategoryDetails: function($q, singleAssetCategory) {
                var deferred = $q.defer();
                singleAssetCategory.get(
                  {
                    uuid: assetCategoryUuid
                  },
                  function (resource) {
                    deferred.resolve(resource.content || {data: []});
                  },
                  function (error) {
                    if (error.status === 404) {
                      deferred.resolve([]);
                    }
                  }
                );
                return deferred.promise;
              },
              companyId: function(){
                return $stateParams.id;
              },
              companyCode: function(){
                return $scope.companyCode
              },
            }
          });
          modalInstance.result.then(function (newValue) {
            if(!!newValue){
              var index = _.findIndex($scope.dataList, function(data){
                return data._id === newValue._id;
              })
              newValue.account_code = newValue.account_code.code;
              $scope.dataList[index] = newValue;
            }
          });
        }

        /**
         * returns the is_active name
         * @param code
         * @returns {*}
         */
        function getActiveStatusName(code){
          if (code === false) {
            return 'Deactivated'
          }
          if (code === true) {
            return 'Active'
          }
          return 'N/A';
        }

        function prepareAssetCategory() {
          return {
            'code': $scope.newAssetCategory.code,
            'name': $scope.newAssetCategory.name,
            'account_code': $scope.newAssetCategory.accountCode.code,
            'asset_useful_life': $scope.newAssetCategory.assetUsefulLife,
            'company_code': $scope.code
          }
        }

        function addNewAssetCategory() {
          $scope.submitted = true;
          var newData = prepareAssetCategory();
          singleAssetCategory.post(
            newData,
            function (response){
              if (!!response.content && !!response.content.data){
                response.content.data.account_code = response.content.data.account_code.code;
                $scope.dataList.unshift(response.content.data);
                $scope.submitted = false;
                toastr.success('Asset category added successfully.')
              }
            },
            function (error){
              globalFunc.objectErrorMessage(error);
            }
          )
        }

        function prepareImportParams () {
            $scope.params = {
                'class': 'App\\Metabuyer\\AssetCategory\\Models\\AssetCategory'
            };
            _.forEach ($scope.dataList, function(account, index){
                var temp = 'additional_params[]['+ index+ ']';
                $scope.params[temp] = account.code;
            });
        }

        prepareImportParams();

        function search(query) {
          $location.search({query: query});
        }
      }
    }
  });
