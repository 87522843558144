'use strict';

angular.module('metabuyer')
  .controller('ResendPOCtrl', function ($scope, $rootScope, $uibModalInstance, Orders, toastr, companyContract, globalFunc) {

    //cancel
    $scope.cancelResendPO = cancelResendPO;
    $scope.getPurchaseOrderEmailAddress = getPurchaseOrderEmailAddress;
    $scope.canResendPo = canResendPo;
    $scope.resendPo = resendPo;
    $scope.getResendPoTitle = getResendPoTitle;
    $scope.checkReceivingModeEmail = checkReceivingModeEmail;

    function cancelResendPO() {
      $uibModalInstance.close();
    }

    /**
     * Get the purchase order email address for resending
     *
     * @returns {*}
     */
    function getPurchaseOrderEmailAddress() {

      return ((!!$scope.po.contact_person && !!$scope.po.contact_person.email) ? $scope.po.contact_person.email :
        (!!$scope.po.supplier.contact_person && !!$scope.po.supplier.contact_person.email) ?
          $scope.po.supplier.contact_person.email : $scope.po.supplier.email);
    }

    /**
     * Check if this PO can be resent
     *
     * @returns {boolean}
     */
    function canResendPo() {

      var viaEmail = checkReceivingModeEmail(companyContract.receiving_mode);
      // TODO: update this condition to cater for fax in the future (Justin 7/6/2018)
      return (((!!$scope.po.contact_person && !!$scope.po.contact_person.email) ||
        (!!$scope.po.supplier.contact_person && !!$scope.po.supplier.contact_person.email) ||
        !!$scope.po.supplier.email && !!viaEmail));
    }

    /**
     * Resend the purchase order
     *
     * @param {string} poId The purchase order id
     */
    function resendPo(poId) {

      $uibModalInstance.close();

      Orders.resendPO(
        {
          id: poId
        }, function (resource){
        toastr.success(resource.content.message);

        //emit refresh history
        $rootScope.$broadcast('refreshComments');

      }, function (error){
        globalFunc.objectErrorMessage(error);
      });
    }

    /**
     * Get the resend po title string
     *
     * @returns {string}
     */
    function getResendPoTitle() {

      if (!(_.isEmpty(companyContract))) {
        var viaEmail = checkReceivingModeEmail(companyContract.receiving_mode);
        if (!viaEmail) {
          return 'This PO cannot be re-sent to supplier because the supplier receiving method is not via email.';
        }
        else {
          return 'This PO will be re-sent to Supplier ' + $scope.po.supplier.descr + ' via';
        }
      } else {
        return 'This PO cannot be re-sent to supplier because the company is not found.';
      }
    }

    /**
     * Check receiving mode via email
     *
     * @returns {boolean}
     */
    function checkReceivingModeEmail(receivingMode) {

      return ((receivingMode & 2) == 2);

    }
  });

