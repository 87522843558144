'use strict';

function escalationAdvancedSearchCtrl($scope, searchModule, listChild, toastr, $rootScope, advancedSearch, exportSearch,
                                      metabuyerCache, $uibModal) {

  var searchCacheKey = 'escalationAdvancedSearch';
  var searchFields = {
    userName : null,
    superior : null,
    company : null
  };
  $scope.userName = null;
  $scope.superior = null;
  $scope.company = null;
  $scope.closeTreeSearch = false;

  $scope.errors = {};
  var toastrErrorMessage = null;

  $scope.resetForm = resetForm;
  $scope.submitAdvSearch = submitAdvSearch;
  $scope.hasError = hasError;
  $scope.addSearchCriteria = addSearchCriteria;
  $scope.modify = modify;

  function modify(userObject) {
    $uibModal.open({
      templateUrl: 'app/usersManagement/escalation/escalationModificationPanelTemplate.html',
      controller: 'escalationModificationPanel',
      backdrop: 'static',
      keyboard: false,
      scope: $scope,
      resolve: {
        company: function () {
          return userObject.company_code;
        },
        userInfo: function () {
          return userObject;
        }
      }
    }).result.then(function (superiorSelected) {
      if (!!superiorSelected) {
        userObject.superior_display_name = superiorSelected[0];
        userObject.superior_email = superiorSelected[1];
      }
    });
  }

  /**
   * function to reset the search form
   */
  function resetForm() {
    $scope.userName = null;
    $scope.superior = null;
    $scope.company = null;
    $scope.errors = {};
    $scope.dataList = [];
    metabuyerCache.remove(searchCacheKey);
  }

  /**
   * function to submit search form
   */
  function submitAdvSearch() {
    var searchParam = validateForm();

    if (!!searchParam) {
      $scope.embeddedParams = searchParam;

      /**
       * Setting the params to prepare for exporting
       */
      exportSearch.setParams(searchParam);

      advancedSearch.get(
        searchParam,
        function (resource) {
          $scope.dataList = resource.content.data;
          $scope.meta = resource.content.meta;
          $scope.emptyInput = false;
        },
        function (error) {
          toastr.error('Something went wrong');
          $scope.dataList = [];
        }
      );
    } else {
      toastr.error(toastrErrorMessage);
      toastrErrorMessage = null;
      $scope.emptyInput = true;
    }

    $('#show-search-form').removeClass('hidden');
    $('#hide-search-form').addClass('hidden');

  }

  /**
   * function to check if a specific field has error.
   * @param field field name
   * @returns {boolean}
   */
  function hasError(field) {
    return (field in $scope.errors);
  }

  /**
   * function to validate form input values
   * @returns {boolean|Object}
   */
  function validateForm() {

    if (_.isEmpty($scope.errors)) {
      var searchData = {
        userName: $scope.userName,
        superior: $scope.superior,
        company: $scope.company
      };
      return buildParam(searchData);
    } else {
      toastrErrorMessage = 'Invalid value in one or more fields';
      return false;
    }
  }

  /**
   * function to build criteria parameters
   * @param data object of form input values
   * @returns {boolean|Object}
   */
  function buildParam(data) {

    var params = {};

    if (!!data.userName) {
      params['criteria[0][display_name]'] = data.userName;
    }
    if (!!data.company) {
      params['criteria[0][company_descr]'] = data.company;
    }
    if (!!data.superior) {
      params['criteria[0][superior_display_name]'] = data.superior;
    }
    if (_.isEmpty(params)) {
      toastrErrorMessage = 'Fill in at least one field';
      return false;
    } else {

      params['module'] = 'escalation';
      params['offset'] = 50;
      params['criteria[0][$operator]'] = 'and';
      params['criteria[1][user_status][]'] = [0, 1, 3, 4]; // all except inactive
      params['criteria_operator'] = 'and';
      return params;
    }
  }

  $scope.columns = [
    {id: 'display_name', label: 'Username', selected: true},
    {id: 'email', label: 'Email', selected: true},
    {id: 'company descr', label: 'Company', selected: true},
    {id: 'superior_display_name', label: 'Superior', selected: true},
    {id: 'superior_email', label: 'Superior email', selected: true}
  ];

  function addSearchCriteria(key, value){

    var tempSearchCriteriaCache = metabuyerCache.get(searchCacheKey);

    if (!!tempSearchCriteriaCache)
      searchFields = _.cloneDeep(tempSearchCriteriaCache);

    searchFields[key] = value;
    metabuyerCache.put(searchCacheKey, searchFields);

  }

  function initialize() {
    // initialize pagination parameters
    $scope.dataList = [];
    $scope.embeddedParams = [];
    $scope.meta = {};
    $scope.searchModule = advancedSearch;

    var searchCriteriaCache = metabuyerCache.get(searchCacheKey);
    if(!!searchCriteriaCache && !_.isEmpty(searchCriteriaCache)){
      retainSearchCriteria();
    }
  }

  function retainSearchCriteria(){

    var searchCriteriaCache = metabuyerCache.get(searchCacheKey);

    $scope.userName = searchCriteriaCache.userName;
    $scope.superior = searchCriteriaCache.superior;
    $scope.company = searchCriteriaCache.company;
  }
  initialize();
}

escalationAdvancedSearchCtrl.$inject = ['$scope', 'searchModule', 'listChild', 'toastr', '$rootScope',
  'advancedSearch', 'exportSearch', 'metabuyerCache', '$uibModal'];

angular.module('metabuyer')
  .controller('escalationAdvancedSearchCtrl', escalationAdvancedSearchCtrl);
