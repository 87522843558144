/**
 * @name userDelegation
 *
 * @requires $scope
 * @requires $state
 * @requires $stateParams
 * @requires pathConstants (Metabuyer service)
 * @requires toastr
 * @requires $http
 * @requires $rootScope
 * @requires delegatedToAUser (Metabuyer service)
 * @requires massDelegate (Metabuyer service)
 * @requires delegations (Metabuyer service)
 *
 * @description Directive for delegation settings under profile and user management
 * This directive is for any delegation logic involved
 *
 * Logic involved:
 * search users from the available users, and delegate the tasks to certain user for a period of time
 *
 * @function users
 * Function to get list of users by email or username for typeahead
 *
 * @function delegate
 * Function to Delegate to another user
 *
 * @function checkToSubmit
 * Function to check the submitted data
 *
 * @function removeDelegationFunction
 * Function to remove Delegation
 *
 * @listener:
 * $watcher for disabling the Delegation
 *
 * @author Justin Cheong Tian Yee <justin.cty90@gmail.com>, Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

function userDelegationCtrl($scope, $state, $stateParams, pathConstants, toastr, $http, $rootScope, delegatedToAUser,
                            delegations, globalFunc, $uibModal, massRemoveDelegation, UserPermissions, $translatePartialLoader) {
        $translatePartialLoader.addPart('user/settings/lang');

        $scope.getSelectedCompanies = getSelectedCompanies;
        $scope.getSelectedAlcs = getSelectedAlcs;
        $scope.getSelectedCatalogGroups = getSelectedCatalogGroups;
        $scope.selectAllCompanies = selectAllCompanies;
        $scope.selectAllAlcs = selectAllAlcs;
        $scope.selectAllCatalogGroups = selectAllCatalogGroups;
        $scope.openDelegationModal = openDelegationModal;
        $scope.checkSelectedCompany = checkSelectedCompany;
        $scope.checkSelectedAlcs = checkSelectedAlcs;
        $scope.checkSelectedCatalogGroups = checkSelectedCatalogGroups;
        $scope.checkSelectedNonCompany = checkSelectedNonCompany;
        $scope.massRemove = massRemove;
        $scope.checkCompanyCheckbox = checkCompanyCheckbox;
        $scope.checkAlcCheckbox = checkAlcCheckbox;
        $scope.checkCatalogGroupCheckbox = checkCatalogGroupCheckbox;
        $scope.removeAlc = removeAlc;
        $scope.removeCatalogGroups = removeCatalogGroups;
        $scope.checkPermittedRoleToUpdate = checkPermittedRoleToUpdate;
        $scope.isV2Enabled = $rootScope.isV2Enabled;
        $scope.groupedAssignments = $scope.groupedAssignments || [];

        /**
         * Generate delegatee object
         *
         * @param data
         */
        function generateDelegateeObject(data) {
          if (!!data && !!data.length) {
            _.forEach($scope.user.companies, function (company) {
              var delegation = globalFunc.findInArray(data, 'company_code', company.code);
              if (!!delegation) {
                company.delegation_id = delegation._id;
                company.delegatee = delegation.delegatee;
                company.delegatedReason = delegation.reason;
                company.delegateStatus = delegation.status;
                company.delegateStartDate = delegation.start_date;
                company.delegateEndDate = delegation.end_date;
              }
            });

            _.forEach($scope.nonCompanyDelegations, function (nonCompany) {
              var delegation = globalFunc.findInArray(data, 'class', nonCompany.class);
              if (!!delegation) {
                if (delegation.class !== "App\\Metabuyer\\AlcGroup\\Models\\AlcGroup" ||
                delegation.class !== "Metabuyer\\Models\\CatalogGroup") {
                  nonCompany.delegation_id = delegation._id;
                  nonCompany.delegatee = delegation.delegatee;
                  nonCompany.delegatedReason = delegation.reason;
                  nonCompany.delegateStatus = delegation.status;
                  nonCompany.delegateStartDate = delegation.start_date;
                  nonCompany.delegateEndDate = delegation.end_date;
                }
              }
            });

            //Catalog Group only
            _.forEach($scope.catalogGroupRoles, function (group) {
              var delegation = globalFunc.findInArray(data, 'company_code', group.code);
              if (!!delegation) {
                group.delegation_id = delegation._id;
                group.delegatee = delegation.delegatee;
                group.delegatedReason = delegation.reason;
                group.delegateStatus = delegation.status;
                group.delegateStartDate = delegation.start_date;
                group.delegateEndDate = delegation.end_date;
              }
            });

            //ALC only
            _.forEach($scope.alcGroupRoles, function (group) {
              var delegation = globalFunc.findInArray(data, 'company_code', group.code);
              if (!!delegation) {
                group.delegation_id = delegation._id;
                group.delegatee = delegation.delegatee;
                group.delegatedReason = delegation.reason;
                group.delegateStatus = delegation.status;
                group.delegateStartDate = delegation.start_date;
                group.delegateEndDate = delegation.end_date;
              }
            });
          }
        }

        /**
         * Get selected Alcs as an array of company codes
         *
         * @returns {Object}
         */
        function getSelectedAlcs() {
          var selectedAlc = {};
          selectedAlc.codes = [];
          selectedAlc.companies = [];
          selectedAlc.class = ['App\\Metabuyer\\AlcGroup\\Models\\AlcGroup'];
          selectedAlc.delegationIds = [];

          _.forEach($scope.alcGroupRoles, function (alcGroup) {
            if (!!alcGroup.selected) {
              selectedAlc.codes.push(alcGroup.code);
              selectedAlc.companies.push(alcGroup);

              if (!!alcGroup.delegation_id) {
                selectedAlc.delegationIds.push(alcGroup.delegation_id);
              }
            }
          });

          return selectedAlc;
        }

        /**
         * Get selected Catalog Groups as an array of company codes
         *
         * @returns {Object}
         */
         function getSelectedCatalogGroups() {
          var selectedCatalogGroup = {};
          selectedCatalogGroup.codes = [];
          selectedCatalogGroup.companies = [];
          selectedCatalogGroup.class = ['Metabuyer\\Models\\CatalogGroup'];
          selectedCatalogGroup.delegationIds = [];

          _.forEach($scope.catalogGroupRoles, function (catalogGroup) {
            if (!!catalogGroup.selected) {
              selectedCatalogGroup.codes.push(catalogGroup.code);
              selectedCatalogGroup.companies.push(catalogGroup);

              if (!!catalogGroup.delegation_id) {
                selectedCatalogGroup.delegationIds.push(catalogGroup.delegation_id);
              }
            }
          });

          return selectedCatalogGroup;
        }

        /**
         * Get selected Companies as an array of company codes
         *
         * @returns {Object}
         */
        function getSelectedCompanies() {
          var selectedCompanies = {};
          selectedCompanies.codes = [];
          selectedCompanies.companies = [];
          selectedCompanies.delegationIds = [];

          _.forEach($scope.user.companies, function (company) {
            if (!!company.selected) {
              selectedCompanies.codes.push(company.code);
              selectedCompanies.companies.push(company);

              if (!!company.delegation_id) {
                selectedCompanies.delegationIds.push(company.delegation_id);
              }
            }
          });

          return selectedCompanies;
        }

        function getSelectedNonCompanyDelegation() {
          var selectedNonCompanyDelegation = {};
          selectedNonCompanyDelegation.codes = [];
          selectedNonCompanyDelegation.class = [];
          selectedNonCompanyDelegation.delegationIds = [];
          _.forEach($scope.nonCompanyDelegations, function(delegation) {
            if (!!delegation.selected) {
              selectedNonCompanyDelegation.codes.push(delegation.code);
              selectedNonCompanyDelegation.class.push(delegation.class);

              if (!!delegation.delegation_id) {
                selectedNonCompanyDelegation.delegationIds.push(delegation.delegation_id);
              }
            }
          });

          return selectedNonCompanyDelegation;
        }

        /**
         * To select all companies
         *
         * @param select
         */
        function selectAllCompanies(select) {
          _.forEach($scope.user.companies, function (company) {
            if(company.descr !== 'System') {
              // Set all selected to true or false
              company.selected = select;
              if(!!select){
                $scope.checkboxCount = $scope.maxCompanyCount;
              }else{
                $scope.checkboxCount = 0;
              }
            }
          });
        }

        /**
         * To select all Alcs
         *
         * @param select
         */
        function selectAllAlcs(select) {
          _.forEach($scope.alcGroupRoles, function (alc) {
              // Set all selected to true or false
            alc.selected = select;
              if(!!select){
                $scope.alcCheckboxCount = $scope.maxAlcCount;
              }else{
                $scope.alcCheckboxCount = 0;
              }
          });
        }

        /**
         * To select all Catalog Groups
         *
         * @param select
         */
        function selectAllCatalogGroups(select) {
          _.forEach($scope.catalogGroupRoles, function (catalogGroup) {
              // Set all selected to true or false
            catalogGroup.selected = select;
              if(!!select){
                $scope.catalogGroupCheckboxCount = $scope.maxCatalogGroupCount;
              }else{
                $scope.catalogGroupCheckboxCount = 0;
              }
          });
        }

        /**
         * To open the company delegation modal
         */
        function openDelegationModal(type) {
          var modalInstance = $uibModal.open({
            templateUrl: 'components/userDelegation/userDelegationModal.html',
            backdrop: 'static',
            keyboard: false,
            scope: $scope,
            controller: userDelegationModalCtrl,
            resolve: {
              selectedCompanies: function () {
                if(type ==='alcGroup')
                  return getSelectedAlcs().codes;

                if(type === 'catalogGroup')
                  return getSelectedCatalogGroups().codes;

                return getSelectedCompanies().codes;
              },
              selectedNonCompanies: function () {
                if(type ==='alcGroup')
                  return getSelectedAlcs();
                else if (type==='catalogGroup')
                  return getSelectedCatalogGroups();


                return getSelectedNonCompanyDelegation();
              },
              type: function () {
                return type;
              }
            }
          });

          modalInstance.result.then(function (newValue) {
            if (!!newValue) {
              loadDelegations();

              _.forEach($scope.user.companies, function(company){
                company.selected = false;
              });
              $scope.checkboxCount = 0;
              _.forEach($scope.nonCompanyDelegations, function(nonCompany){
                nonCompany.selected = false;
              });
              _.forEach($scope.alcGroupRoles, function(nonCompany){
                nonCompany.selected = false;
              });
            }
          });
        }

        /**
         * To check selected company to add or remove
         *
         * @param action
         * @returns {boolean}
         */
        function checkSelectedCompany(action) {
          var selectedCompanies = getSelectedCompanies();
          var countCompanyHasDelegation = selectedCompanies.delegationIds.length;
          selectedCompanies = selectedCompanies.companies.length;

          if (action === 'add') {
            return (countCompanyHasDelegation === 0 && !!selectedCompanies);
          } else if (action === 'remove') {
            return (!!selectedCompanies && selectedCompanies === countCompanyHasDelegation);
          }
        }

        /**
         * To check selected company to add or remove
         *
         * @param action
         * @returns {boolean}
         */
        function checkSelectedAlcs(action) {
          var selectedAlcs = getSelectedAlcs();
          var countAlcHasDelegation = selectedAlcs.delegationIds.length;
          selectedAlcs = selectedAlcs.companies.length;

          if (action === 'add') {
            return (countAlcHasDelegation === 0 && !!selectedAlcs);
          } else if (action === 'remove') {
            return (!!selectedAlcs && selectedAlcs === countAlcHasDelegation);
          }
        }

        /**
         * To check selected catalog group to add or remove
         *
         * @param action
         * @returns {boolean}
         */
         function checkSelectedCatalogGroups(action) {
          var selectedCatalogGroup = getSelectedCatalogGroups();
          var countCatalogGroupHasDelegation = selectedCatalogGroup.delegationIds.length;
          selectedCatalogGroup = selectedCatalogGroup.companies.length;

          if (action === 'add') {
            return (countCatalogGroupHasDelegation === 0 && !!selectedCatalogGroup);
          } else if (action === 'remove') {
            return (!!selectedCatalogGroup && selectedCatalogGroup === countCatalogGroupHasDelegation);
          }
        }


        /**
         * To check selected non company to add or remove
         *
         * @param action
         * @returns {boolean}
         */
        function checkSelectedNonCompany(action) {
          var selectedDelegations = getSelectedNonCompanyDelegation();

          var countDelegation = selectedDelegations.delegationIds.length;
          selectedDelegations = selectedDelegations.class.length;

          if (action === 'add') {
            return (countDelegation === 0 && !!selectedDelegations);
          } else if (action === 'remove') {
            return (!!selectedDelegations && countDelegation === selectedDelegations);
          }
        }

        /**
         * To mass remove delegation
         */
        function massRemove() {
          var delegationIds = getSelectedCompanies().delegationIds;
          var selectedNonCompanyDelegationIds = getSelectedNonCompanyDelegation().delegationIds;
          if (!!selectedNonCompanyDelegationIds.length) {
            delegationIds = selectedNonCompanyDelegationIds;
          }

          if (!!delegationIds.length) {
            swal(
              {
                title: 'Confirm remove delegation(s)?',
                text: 'Selected delegation(s) will be removed.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Remove',
                closeOnConfirm: true
              },
              function (confirm) {
                if (!!confirm) {
                  massRemoveDelegation.delete({
                    'ids[]': delegationIds
                  }, function (resource) {
                    if (!!resource && !!resource.content) {
                      clearSelectedCompaniesData();
                      toastr.success(resource.content.message);
                    }
                  }, function (error) {
                    globalFunc.objectErrorMessage(error.data);
                  });
                }
              }
            );
          } else {
            toastr.error('There is no item selected.')
          }
        }

        /**
         * To mass remove delegation
         */
        function removeAlc() {
          var delegationIds = getSelectedAlcs().delegationIds;
          if (!!delegationIds.length) {
            swal(
              {
                title: 'Confirm remove delegation(s)?',
                text: 'Selected delegation(s) will be removed.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Remove',
                closeOnConfirm: true
              },
              function (confirm) {
                if (!!confirm) {
                  massRemoveDelegation.delete({
                    'ids[]': delegationIds
                  }, function (resource) {
                    if (!!resource && !!resource.content) {
                      clearSelectedAlc(delegationIds);
                      toastr.success(resource.content.message);
                    }
                  }, function (error) {
                    globalFunc.objectErrorMessage(error.data);
                  });
                }
              }
            );
          } else {
            toastr.error('There is no item selected.')
          }
        }

        /**
         * To mass remove catalog group delegation
         */
         function removeCatalogGroups() {
          var delegationIds = getSelectedCatalogGroups().delegationIds;
          if (!!delegationIds.length) {
            swal(
              {
                title: 'Confirm remove delegation(s)?',
                text: 'Selected delegation(s) will be removed.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Remove',
                closeOnConfirm: true
              },
              function (confirm) {
                if (!!confirm) {
                  massRemoveDelegation.delete({
                    'ids[]': delegationIds
                  }, function (resource) {
                    if (!!resource && !!resource.content) {
                      clearSelectedCatalogGroup(delegationIds);
                      toastr.success(resource.content.message);
                    }
                  }, function (error) {
                    globalFunc.objectErrorMessage(error.data);
                  });
                }
              }
            );
          } else {
            toastr.error('There is no item selected.')
          }
        }

        /**
         * To clear data for removed delegations
         */
        function clearSelectedCompaniesData() {
          _.forEach(getSelectedCompanies().codes, function (selectedCompanyCode) {
            _.forEach($scope.user.companies, function (company) {
              if (company.code === selectedCompanyCode) {
                company.selected = false;
                company.delegation_id = undefined;
                company.delegatee = undefined;
                company.delegatedReason = undefined;
                company.delegateStatus = undefined;
                company.delegateStartDate = undefined;
                company.delegateEndDate = undefined;
                checkCompanyCheckbox(company.select);
              }
            });
          });

          // If non company delegation was selected
          _.forEach(getSelectedNonCompanyDelegation().codes, function (selectedDelegationCode) {
            _.forEach($scope.nonCompanyDelegations, function (nonCompany) {
              if (nonCompany.code === selectedDelegationCode) {
                nonCompany.selected = false;
                nonCompany.delegation_id = undefined;
                nonCompany.delegatee = undefined;
                nonCompany.delegatedReason = undefined;
                nonCompany.delegateStatus = undefined;
                nonCompany.delegateStartDate = undefined;
                nonCompany.delegateEndDate = undefined;
              }
            });
          });
        }

        function clearSelectedAlc(selectedAlcGroup) {
          _.forEach($scope.alcGroupRoles, function (alcGroup) {
            if (alcGroup.delegation_id === selectedAlcGroup[0]) {
              alcGroup.selected = false;
              alcGroup.delegation_id = undefined;
              alcGroup.delegatee = undefined;
              alcGroup.delegatedReason = undefined;
              alcGroup.delegateStatus = undefined;
              alcGroup.delegateStartDate = undefined;
              alcGroup.delegateEndDate = undefined;
            }
          });
        }

        function clearSelectedCatalogGroup(selectedCatalogGroup) {
          _.forEach($scope.catalogGroupRoles, function (catalogGroup) {
            if (catalogGroup.delegation_id === selectedCatalogGroup[0]) {
              catalogGroup.selected = false;
              catalogGroup.delegation_id = undefined;
              catalogGroup.delegatee = undefined;
              catalogGroup.delegatedReason = undefined;
              catalogGroup.delegateStatus = undefined;
              catalogGroup.delegateStartDate = undefined;
              catalogGroup.delegateEndDate = undefined;
            }
          });
        }

        /**
         * To load the delegations
         */
        function loadDelegations() {
          var companyCodes = [];
          var classes = [];
          _.forEach($scope.user.companies, function (company) {
            if (!!company && !!company.code) {
              companyCodes.push(company.code);
            }
          });

          _.forEach($scope.nonCompanyDelegations, function (nonCompany) {
            if (!!nonCompany && !!nonCompany.class) {
              classes.push(nonCompany.class);
            }
          });


          if (companyCodes.length > 0) {
            delegatedToAUser.get(
              {
                userId: $scope.user._id,
                'company_codes[]': companyCodes,
                'classes[]': classes
              },
              function (resource) {
                if (!!resource && !!resource.content && (resource.content.data instanceof Array)) {
                  generateDelegateeObject(resource.content.data);
                }
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
              }
            );
          }
        }

        function getAlcGroupRoles() {
          $scope.alcGroupRoles = [];
          _.forEach($scope.groupedAssignments, function(group){
            _.forEach(group.roleAssignments, function (role){
              if (role.weight === 11) {
                role.name = group.name;
                role.manager = globalFunc.findInArray(
                  $scope.selectedSuperiors,
                  'company_code',
                  role.code
                );
                $scope.alcGroupRoles.push(role);
              }
            })
          });
        }

        function getCatalogGroupRoles() {
          $scope.catalogGroupRoles = [];
          _.forEach($scope.groupedAssignments, function(group){
            _.forEach(group.roleAssignments, function (role){
              if (role.weight === 12) {
                role.name = group.descr;
                role.manager = globalFunc.findInArray(
                  $scope.selectedSuperiors,
                  'company_code',
                  role.code
                );
                $scope.catalogGroupRoles.push(role);
              }
            })
          });
        }

        function initialize() {
          $scope.delegation = {};
          //Change when company checkbox is checked
          $scope.checkboxCount = 0;
          $scope.maxCompanyCount = 0;
          $scope.maxAlcCount = 0;
          // Non company delegation objects (more can be added later)
          // TODO: upgrade this to handle complex cases like Alc groups and catalog groups
          $scope.nonCompanyDelegations = [
            {descr: 'Supplier', code: 'supplier', class: 'Metabuyer\\Models\\Supplier'},
            {descr: 'Catalog', code: 'catalog', class: 'Metabuyer\\Models\\CatalogGroup'},
            {descr: 'Alc Groups', code: 'alcGroup', class: 'App\\Metabuyer\\AlcGroup\\Models\\AlcGroup'}
          ];

          loadDelegations();
          getMaxCheckboxCount();

          if (!!$rootScope.isV2Enabled) {
            getAlcGroupRoles();
            getCatalogGroupRoles();
            $scope.maxAlcCount = $scope.alcGroupRoles.length;
          }
        }

        /**
        * To get and initialize the max number of companies checkbox
        */
        function getMaxCheckboxCount(){
          _.forEach($scope.user.companies, function(company){
            if(company.descr !== 'System'){
              $scope.maxCompanyCount++;
            }
          })
        }

        function checkCompanyCheckbox(companySelected){
          if(!!companySelected){
            $scope.checkboxCount++;
            if($scope.checkboxCount === $scope.maxCompanyCount){
              $scope.selectAll = true;
            }
          }else{
            $scope.checkboxCount--;
            $scope.selectAll = false;
          }
        }

        function checkAlcCheckbox(companySelected, _id){
          /**
           * force clear all ticks as multiple assignment for delegation is for alc is not supported
           */
          _.forEach($scope.alcGroupRoles, function (group) {
            if(group._id !== _id)
              group.selected = false;
          });

          if(!!companySelected){
            $scope.checkboxCount++;
            if($scope.alcCheckboxCount === $scope.maxAlcCount){
              $scope.alcSelectAll = true;
            }
          }else{
            $scope.alcCheckboxCount--;
            $scope.alcSelectAll = false;
          }
        }

        function checkCatalogGroupCheckbox(companySelected, _id){
          /**
           * force clear all ticks as multiple assignment for delegation is for alc is not supported
           */
          _.forEach($scope.catalogGroupRoles, function (group) {
            if(group._id !== _id)
              group.selected = false;
          });

          if(!!companySelected){
            $scope.checkboxCount++;
            if($scope.catalogGroupCheckboxCount === $scope.maxCatalogGroupCount){
              $scope.catalogGroupSelectAll = true;
            }
          }else{
            $scope.catalogGroupCheckboxCount--;
            $scope.catalogGroupSelectAll = false;
          }
        }

        function checkPermittedRoleToUpdate() {
          return !globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Sourcing and Bidding Admin');
        }

  initialize();
}

userDelegationCtrl.$inject = [
  '$scope', '$state', '$stateParams', 'pathConstants', 'toastr', '$http', '$rootScope', 'delegatedToAUser', 'delegations',
  'globalFunc', '$uibModal', 'massRemoveDelegation', 'UserPermissions', '$translatePartialLoader'];

angular
  .module('metabuyer')
  .directive('userDelegation', function () {
    return {
      restrict: 'E',
      templateUrl: 'components/userDelegation/userDelegationTemplate.html',
      controller: 'userDelegationCtrl',
      scope: {
        user: '=?',
        groupedAssignments: '='
      }
    }
  })
  .controller('userDelegationCtrl', userDelegationCtrl);
