'use strict';

function supplierBranchDetailCtrl($scope, param, branchIndex, branches, $uibModalInstance, globalFunc, editable, $timeout, $rootScope, toastr) {
  $scope.branchesDetails = _.cloneDeep(param);
  $scope.editClicked = editable;
  $scope.closeModal = closeModal;
  $scope.saveBranchDetails = saveBranchDetails;
  $scope.validateEmail = validateEmail;
  $scope.removeContact = removeContact;
  $scope.addContact = addContact;
  $scope.onUpdatedContact = onUpdatedContact;
  $scope.onUpdatedState = onUpdatedState;
  $scope.onUpdatedCountry = onUpdatedCountry;
  $scope.countries = globalFunc.countryCode();
  $scope.MYStates = globalFunc.malaysiaState();
  $scope.selectedRceivingMode = selectedReceivingMode;

  $scope.branchStatus = [
    {descr: 'Active', value: 1, passValue: 'ACTIVE'},
    {descr: 'Inactive', value: 0, passValue: 'INACTIVE'}
  ];

  function selectedReceivingMode(poMethod) {
    if (poMethod === 0)
      return;

    if ([2, 6, 10, 14].indexOf(poMethod) > -1)
      $scope.receive_via_email = true;

    if ([4, 6, 12, 14].indexOf(poMethod) > -1)
      $scope.receive_via_fax = true;

    if ([8, 10, 12, 14].indexOf(poMethod) > -1)
      $scope.receive_via_post = true;
  }

  selectedReceivingMode($scope.branchesDetails.receiving_mode);

  function validate() {
    var result = [];
    result.decision = false;
    if (!$scope.branchesDetails.descr) {
      result.reason = 'fill in Supplier Branch Name';
      return result;
    }

    if (!validateBranchUnique($scope.branchesDetails.descr, branchIndex)) {
      result.reason = 'use unique Supplier Branch Name';
      return result;
    }

    if ((!$scope.receive_via_fax && !$scope.receive_via_email
      && !$scope.receive_via_post)) {
      result.reason = 'select one method of receiving PO';
      return result;
    }

    if (!!$scope.receive_via_email) {
      if (!$scope.branchesDetails.email) {
        result.reason = 'fill in Branch Email Address';
        return result;
      }
    }

    if (!!$scope.branchesDetails.email && !validateEmail($scope.branchesDetails.email)) {
      result.reason = 'enter Branch Email Address with the correct format';
      return result;
    }

    if (!!$scope.receive_via_fax) {
      if (!$scope.branchesDetails.fax) {

        result.reason = 'fill in Branch Fax Number';
        return result;
      }
    }

    if (!$scope.branchesDetails.phone) {
      result.reason = 'fill in Branch Phone Number';
      return result;
    }

    if (!$scope.branchesDetails.corresponding_address || !$scope.branchesDetails.corresponding_address.line1 || !$scope.branchesDetails.corresponding_address.city ||
      !$scope.branchesDetails.corresponding_address.state || !$scope.branchesDetails.corresponding_address.country ||
      !$scope.branchesDetails.corresponding_address.postal_code) {
      result.reason = 'fill in Branch Corresponding Address';
      return result;
    }

    if (!validatePostalCode($scope.branchesDetails.corresponding_address.postal_code)) {
      result.reason = 'enter a correct postal code (alphanumeric only)';
      return result;
    }

    if ($scope.branchesDetails.contacts.length == 0) {
      result.reason = 'create a Branch contact person';
      return result;
    }

    if ($scope.branchesDetails.status !== 'ACTIVE' && $scope.branchesDetails.status !== 'INACTIVE') {
      result.reason = 'select Branch Status';
      return result;
    }

    result.decision = true;
    return result;
  }

  function validateBranchUnique(branchName, branchIndex) {
    var i = 0;
    for (i; i < branches.length; i++) {
      if (i != branchIndex && branchName == branches[i].descr) {
        return false;
      }
    }
    return true;
  }

  function validateEmail(email) {
    var regex = /^(?=.{1,64}@)(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  }

  function validatePostalCode(postalCode) {
    var regex = /^[a-z0-9]+$/i;
    return regex.test(postalCode);
  }

  function closeModal() {
    $uibModalInstance.close();
  }

  function onUpdatedCountry(country) {
    $scope.branchesDetails.corresponding_address.state = undefined;
    if (!!country.descr) {
      $scope.branchesDetails.corresponding_address.country = country.descr;
      $scope.selectedMalaysia = (country.descr === 'Malaysia');
    }
  }

  function onUpdatedState(state) {
    if (!!state) {
      $scope.branchesDetails.corresponding_address.state = state.descr;
    }
  }

  function saveBranchDetails() {
    if (validate().decision) {

      var temp = 0;

      if ($scope.receive_via_email) {
        temp += 2;
      }
      if ($scope.receive_via_fax) {
        temp += 4;
      }
      if ($scope.receive_via_post) {
        temp += 8;
      }
      $scope.branchesDetails.receiving_mode = temp;

      $uibModalInstance.close($scope.branchesDetails);
    } else {
      toastr.error('Please ' + validate().reason + ' to create a new branch');
    }
  }

  function removeContact(contact) {
    var index = _.findIndex($scope.branchesDetails.contacts, function (item) {
      return item._id == contact._id;
    });

    $scope.branchesDetails.contacts.splice(index, 1);
  }

  function addContact(contact) {
    var spliceIndex = $scope.branchesDetails.contacts[0];
    if (!!spliceIndex) {
      $scope.branchesDetails.contacts.push(contact);
    }
    /**
     * If the first index is null, undefined or empty
     */
    else {
      $scope.branchesDetails.contacts.splice(spliceIndex, 1, contact);
    }
  }

  function onUpdatedContact(contact) {
    var index = _.findIndex($scope.branchesDetails.contacts, function (item) {
      return item._id == contact._id;
    });
    $scope.branchesDetails.contacts.splice(index, 1, contact);
  }

  /**
   * Run in the first load
   */
  function initialize() {
    // check if the country is Malaysia
    $scope.selectedMalaysia = ($scope.branchesDetails.corresponding_address.country.toLowerCase() === 'malaysia');
  }

  initialize();

}

supplierBranchDetailCtrl.$inject = [
  '$scope', 'param', 'branchIndex', 'branches', '$uibModalInstance', 'globalFunc', 'editable', '$timeout', '$rootScope', 'toastr'
];

angular.module('metabuyer')
  .controller('supplierBranchDetailCtrl', supplierBranchDetailCtrl);
