'use strict';

angular.module('metabuyer')
  .controller('LoginCtrl', function($scope, $auth, $location, $rootScope, ManageLocalStorage,
                                    $state, globalFunc, $uibModal, pathConstants, policyDisclaimer, $translate,
                                    $window, checkSocialLogin, $http) {
    $scope.policyDisclaimer = policyDisclaimer;
    $scope.isLoginClicked = false;
    $scope.isSourcingModeEnabled = !!pathConstants.isSourcingModeEnabled;
    $scope.isDomainUser = true;
    if (!$scope.isSourcingModeEnabled) {
      $scope.isDomainUser = false;
    }
    $scope.systemVersionNumber = false;
    $scope.loginInputReadOnly = false;

    $scope.login = function() {
      $scope.isLoginClicked = true;
      $scope.error = null;
      $scope.errorCount = null;
      $rootScope.tempEmail = $scope.email;

      var forceWaitInMilliseconds = 100;
      if (!!$scope.isSourcingModeEnabled) {
        forceWaitInMilliseconds = 1000;
      }

      setTimeout(function() {
        $auth.login({ email: $scope.email, password: btoa($scope.password.trim()), "Accept-Language": $translate.use() })
          .then(function(response) {

            /**
             * verify the cached data
             */
            var cachedEmail = window.localStorage.getItem('email');
            if (!cachedEmail || cachedEmail !== $scope.email) {
              ManageLocalStorage.clearLocalStorage('All');
            }else {
              ManageLocalStorage.clearLocalStorage();
            }

            window.localStorage.setItem('email', $scope.email);
            window.localStorage.setItem('userId', response.data.userId);
            if (!!$rootScope.redirectUrl && globalFunc.isRedirectable($rootScope.redirectUrl)) {
              var tempUrl  = angular.copy($rootScope.redirectUrl);
              $rootScope.redirectUrl = null;
              // $location.url(tempUrl);
            } else {
              $rootScope.redirectUrl = response.data.redirect_url;
              // $location.url(response.data.redirect_url);
            }
            $rootScope.tempEmail = null;
            if (!response.data.twoFAVerify) {
              $location.url('/user/waiting-on-you');
              return;
            }
            // first time login with mfa
            $state.go('twoFAAuthenticate', {
              'is_login': true,
            });
          })
          .catch(function(response) {
            $scope.isLoginClicked = false;
            if (!!response.data.g2fa) {
              // normal login with mfa
              $state.go('twoFAAuthenticate', {
                'user_id': response.data.userId || '',
                'g2fa': response.data.g2fa,
              });
              return;
            }
            if (response && response.data && response.data.message) {
              $scope.error = response.data.message;
              $scope.errorCount = response.data.login_attempts_remaining;
            } else if (response.data === null) {
              $scope.error = 'An unexpected error has occurred. Please try again later.';
            }
          });
      }, forceWaitInMilliseconds);
    };

    $scope.openPolicyDisclaimerModal = function() {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/policyDisclaimer/modal/policyDisclaimerModal.html',
        backdrop: 'static',
        keyboard: false,
        controller: 'policyDisclaimeModalCtrl',
        size: 'lg',
        resolve: {
          data: function() {
            return policyDisclaimer;
          }
        }
      });
    }

    $scope.openHelpModal = function() {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/help/modal/helpModal.html',
        backdrop: 'static',
        keyboard: false,
        controller: 'helpModalCtrl',
        size: 'md'
      });
    }

    $scope.checkIsDomainUser = function() {
      var payload = {
        email: $scope.email,
      };
      checkSocialLogin.post(
        payload,
        function(resource) {
          if (!!resource.normal_login) {
            $scope.isDomainUser = false;
            $scope.loginInputReadOnly = true;
          } else {
            $window.location.href = resource.redirect_uri;
          }

        },
        function(error) {
          globalFunc.objectErrorMessage(error);
          $scope.isDomainUser = false;
          $scope.loginInputReadOnly = true;
        }
      )
    }

    $scope.back = function () {
      $scope.isDomainUser = true;
      $scope.error = null;
      $scope.errorCount = null;
    }

    $http.get(pathConstants.apiUrls.systemVersionNumber).then(function (resource) {
      $scope.systemVersionNumber = resource.data.content.data.number;
    });
  });
