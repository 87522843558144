'use strict';

angular.module('metabuyer')
  .controller('newSupplierCtrl', newSupplierCtrl);

newSupplierCtrl.$inject = [
  '$scope', '$q', 'toastr', 'globalFunc', 'supplierFunctions', 'ngIntlTelInput', '$state', 'taxes', 'searchModule',
  'currencies', 'paymentMethods', 'paymentTerms', 'listChild', '$rootScope', '$http',
  'pathConstants', 'newSupplier', 'newDraft', 'supplier', 'supplierDraft', '$uibModal', 'sourcingSupplierFeatureToggle',
  'timezones', 'approvalFlowPreview', '$translate', 'supplierDraftAttachment', 'singleAttachment', '$filter'
];

function newSupplierCtrl(
  $scope, $q, toastr, globalFunc, supplierFunctions, ngIntlTelInput, $state, taxes, searchModule,
  currencies, paymentMethods, paymentTerms, listChild, $rootScope, $http,
  pathConstants, newSupplier, newDraft, supplier, supplierDraft, $uibModal, sourcingSupplierFeatureToggle,
  timezones, approvalFlowPreview, $translate, supplierDraftAttachment, singleAttachment, $filter
) {

  $scope.isSourcingSupplierEnabled = !!sourcingSupplierFeatureToggle.is_enabled;
  $scope.taxes = taxes;
  $scope.currencies = currencies;
  $scope.paymentMethods = paymentMethods.data;
  $scope.paymentTerms = paymentTerms.data;
  $scope.address = {};
  $scope.supplier = {};
  $scope.supplier.default_contract = {};
  $scope.supplier.basic_info = {};
  $scope.supplier.basic_info.corresponding_address = {};
  $scope.closeTreeSearchPrimary = false;
  $scope.closeTreeSearchSecondary = false;
  $scope.checkAddress = false;
  $scope.commodityLevel = [3, 4];
  $scope.user = $rootScope.currentUser;
  $scope.selectedContactPersons = [];
  $scope.approvals = [];
  $scope.supplier.additionalFile = [];
  $scope.freightTerms = [];
  $scope.gstValidation = false;
  $scope.draftId = supplier._id;
  $scope.selectedSupplierType = null;
  $scope.timezones = timezones;
  $scope.supplierUsers = [];
  $scope.saveAs = false;
  $scope.selectedLanguage = null;
  $scope.approvals = [];
  $scope.approvalFlowContext = {
    status: 'draft'
  };
  $scope.isV2Enabled = $rootScope.isV2Enabled;
  $scope.attachments = [];

  // Standard list for businessNature and businessEntity
  $scope.businessNatures = [
    {descr: 'Unspecified Business Nature', shortDescr: 'UNSPECIFIED', value: 0},
    {descr: 'Agent', shortDescr: 'AGENT', value: 1},
    {descr: 'Banking & Financial Services', shortDescr: 'BANKING', value: 2},
    {descr: 'Consultancy, Advisory & Other Professional Bodies', shortDescr: 'CONSULTANCY', value: 3},
    {descr: 'Contractor', shortDescr: 'CONTRACTOR', value: 4},
    {descr: 'Dealer', shortDescr: 'DEALER', value: 5},
    {descr: 'Distributor', shortDescr: 'DISTRIBUTOR', value: 6},
    {descr: 'Manufacturer', shortDescr: 'MANUFACTURER', value: 7},
    {descr: 'Marketing', shortDescr: 'MARKETING', value: 8},
    {descr: 'Retailer', shortDescr: 'RETAILER', value: 9},
    {descr: 'Service Provider', shortDescr: 'SERVICE_PROVIDER', value: 10},
    {descr: 'Sub-Contractor', shortDescr: 'SUB_CONTRACTOR', value: 11},
    {descr: 'Trading', shortDescr: 'TRADING', value: 12},
    {descr: 'Other', shortDescr: 'OTHERS', value: 13}
  ];
  $scope.businessEntities = [
    {descr: 'Berhad (Public Limited)', shortDescr: 'PUBLIC_LIMITED', value: '1'},
    {descr: 'Sendirian Berhad (Private Limited)', shortDescr: 'PRIVATE_LIMITED', value: '2'},
    {descr: 'Sole Proprietor & Enterprise', shortDescr: 'SOLE', value: '3'},
    {descr: 'Partnership', shortDescr: 'PARTNERSHIP', value: '4'}
  ];

  $scope.poReceivingMode = [
    {id: 'via_system', descr: 'System', value: 0},
    {id: 'via_email', descr: 'Email', value: 1},
    {id: 'via_fax', descr: 'Fax', value: 2},
    {id: 'via_post', descr: 'Post', value: 4}
  ];

  $scope.bumiputraStatus = [
    {descr: 'Yes', value: 1},
    {descr: 'No', value: 0}
  ];

  // disable configuration for supplier user detail
  $scope.disableConfig = {
    supplier: true,
    email: false,
    supplier_user_delegate: true
  }

  $scope.supplierTypes = supplierFunctions.getSupplierTypes(false, $rootScope.currentUser);
  $scope.languages = supplierFunctions.getLanguages();

  $scope.backToListingSupplier = backToListingSupplier;
  $scope.getPaidUpCategory = getPaidUpCategory;
  $scope.validatePrimaryBusinessNature = validatePrimaryBusinessNature;
  $scope.validateSecondaryBusinessNature = validateSecondaryBusinessNature;
  $scope.searchPrimaryCommodityCode = searchPrimaryCommodityCode;
  $scope.openChildCommodityCodePrimary = openChildCommodityCodePrimary;
  $scope.searchSecondaryCommodityCode = searchSecondaryCommodityCode;
  $scope.openChildCommodityCodeSecondary = openChildCommodityCodeSecondary;
  $scope.selectedBumiputra = selectedBumiputra;
  $scope.checkHyphenFirstCharacter = checkHyphenFirstCharacter;
  $scope.settingContacts = settingContacts;
  $scope.removeSecondaryCommodity = removeSecondaryCommodity;
  $scope.validateNumbers = validateNumbers;
  $scope.onSelectedState = onSelectedState;
  $scope.onSelectedCountry = onSelectedCountry;
  $scope.removeContact = removeContact;
  $scope.onUpdatedContact = onUpdatedContact;
  $scope.onAddedContact = onAddedContact;
  $scope.onSelectedTax = onSelectedTax;
  $scope.onSelectedCurrency = onSelectedCurrency;
  $scope.onSelectedBusinessEntity = onSelectedBusinessEntity;
  $scope.onSelectedPaymentTerm = onSelectedPaymentTerm;
  $scope.onSelectedPaymentMethod = onSelectedPaymentMethod;
  $scope.createNewSupplier = createNewSupplier;
  $scope.onSelectedTagToCompany = onSelectedTagToCompany;
  $scope.duplicationCheck = duplicationCheck;
  $scope.checkCommodityCode = checkCommodityCode;
  $scope.saveDraft = saveDraft;
  $scope.deleteDraft = deleteDraft;
  $scope.openSageIntegrationModal = openSageIntegrationModal;
  $scope.openCompanyContractModal = openCompanyContractModal;
  $scope.onSelectedSupplierType = onSelectedSupplierType;
  $scope.addSupplierUser = addSupplierUser;
  $scope.removeSupplierUser = removeSupplierUser;
  $scope.loadValidationRules = loadValidationRules;
  $scope.onSelectedLanguage = onSelectedLanguage;
  $scope.previewApprovalFlow = previewApprovalFlow;
  $scope.addAttachment = addAttachment;
  $scope.deleteAttachment = deleteAttachment;
  $scope.downloadAttachment =downloadAttachment;

  function deleteAttachment(attachmentIndex) {
    var attachment = $scope.attachments[attachmentIndex];
    var attachment_id = attachment.attachment_id;
    if (typeof attachment.attachment_id === 'undefined') {
      $scope.attachments.splice(attachmentIndex,1);
    } else {
      if (!!attachment_id) {
        supplierDraftAttachment.deleteAttachment({
          draft_id: $scope.draftId,
          attachment_id: attachment_id
        }, function (resource) {
          $scope.attachments.splice(attachmentIndex,1);
          toastr.success('Attachment deleted')
        }, function (error) {
          globalFunc.objectErrorMessage(error);
        });
      }
    }
  }

  /**
   * Open modal to add attachment
   */
  function addAttachment() {
    var modalInstance = $uibModal.open({
      templateUrl: 'app/suppliersManagement/newSupplier/addAttachment/addSupplierDraftAttachment.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        draftId: function() {
          return $scope.draftId;
        }
      },
      controller: 'addSupplierDraftAttachmentCtrl'
    });
    modalInstance.result.then(function (newAttachment) {
      if (!!newAttachment) {
        $scope.attachments.push(newAttachment);
      }
    });
  }

  /**
   * Download attachment
   *
   * @param {object} attachmentId
   */
  function downloadAttachment(attachmentId) {
    if (!!attachmentId) {
      singleAttachment.get({
        attachmentId: attachmentId
      }, function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data)
          getAttachmentFile(resource.content.data.temporary_url);
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * Get the attachment file
   *
   * @param {string} url
   */
  function getAttachmentFile(url) {
    var newUrl = $filter('format')(pathConstants.apiUrls.attachments.download, {
      hash: url
    });
    $http({
      url: newUrl
    }).then(function () {
      window.open(newUrl, '_self');
    }, function (error) {
      globalFunc.objectErrorMessage(error);
    });
  }

  function openSageIntegrationModal(){
    $uibModal.open({
      templateUrl: 'app/suppliersManagement/sageIntegrationModal/sageIntegrationModal.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'sageIntegrationModalCtrl',
      size: 'lg',
      resolve: {
        companies: function($q, searchModule){
          var deferred = $q.defer();
          var params = {
            module: 'companies',
            offset: 20,
            'criteria[0][config|integration_solution]': 'MB-SAGE-ESKER',
            criteria_operator: 'or',
            order_by: 'descr',
            order: 1
          }

          searchModule.get(
            params,
            function (resource) {
              if(!!resource && !!resource.content && !!resource.content.data){
                deferred.resolve(resource.content);
              } else {
                deferred.resolve({data: []});
              }
            },
            function () {
              deferred.resolve({data: []});
            }
          );

          return deferred.promise;
        },
        supplierMapping: function ($q, supplierMappingList) {
          var deferred = $q.defer();
          supplierMappingList.get(
            {
              supplier_code: $scope.supplier.basic_info.code
            },
            function (resource) {
              deferred.resolve(resource.content || []);
              $scope.buttonClicked = false;
            },
            function (error) {
              deferred.resolve([]);
              $scope.buttonClicked = false;
            }
          );
          return deferred.promise;
        },
        supplier: function (){
          return $scope.supplier;
        },
        action: function(){
          return 'create';
        }
      }
    })
  }

  function onSelectedTagToCompany(company) {
    $scope.supplier.basic_info.tagged_to_company_code = company;
  }

  /** validation fo all the submit data*/
  function submitSupplierValidation() {
    if ($scope.isSourcingSupplierEnabled) {
      if (_.isEmpty($scope.selectedSupplierType)) {
        return false;
      }

      if (!!$scope.selectedSupplierType.isSourcingSupplier) {
        var supplierUserValid = true;
        var checkDuplicate = [];
        _.forEach($scope.supplierUsers, function (user) {
          if (!user.display_name || !user.display_name.length) {
            supplierUserValid = false;
            return;
          }

          if (!user.email || !user.email.length) {
            supplierUserValid = false;
            return;
          }

          if (user.isExistingEmail) {
            supplierUserValid = false;
            return;
          }

          if (!user.isValidPhone) {
            supplierUserValid = false;
            return;
          }

          if (!user.currency || !user.currency.length) {
            supplierUserValid = false;
            return;
          }

          if (!user.config || !user.config.TZ.length) {
            supplierUserValid = false;
            return;
          }
          if (checkDuplicate.includes(user.email)) {
            supplierUserValid = false;
            return;
          }
          checkDuplicate.push(user.email);
        });
        if (!supplierUserValid) {
          return false;
        }
      }
    }

    if (angular.isDefined($scope.supplier.default_contract.tax_code) &&
      ($scope.supplier.default_contract.tax_code.code !== 'NRC' && $scope.supplier.default_contract.tax_code.code !== 'NT0')) {
      if ((!$scope.supplier.basic_info.gst_code && $scope.mandatoryRules.gst_code) || $scope.supplier.basic_info.gst_code.length < 3) {
        return false;
      }
    }
    if (angular.isUndefined($scope.supplier.default_contract.bumi_status) && $scope.mandatoryRules.bumi_status) {
      $scope.noBumiStatus = true;
      return false;
    }
    if ($scope.validatedBN === true) {
      return false;
    }
    if (checkHyphenFirstCharacter($scope.updateDescr, 'descr')) {
      return false;
    }

    if (!$scope.supplier.basic_info.descr || !$scope.supplier.basic_info.descr.length) {
      return false;
    }
    if (!$scope.supplier.basic_info.reg_no || $scope.supplier.basic_info.reg_no.length < 3) {
      return false;
    }
    if (!$scope.supplier.default_contract.tax_code && $scope.mandatoryRules.tax_code) {
      return false;
    }
    if (!$scope.supplier.default_contract.primary_commodity_code && $scope.mandatoryRules.primary_commodity_code) {
      return false;
    }
    if (!$scope.supplier.default_contract.primary_business_nature && $scope.mandatoryRules.primary_business_nature) {
      return false;
    }
    if (!$scope.receive_via_email && !$scope.receive_via_fax && !$scope.receive_via_post && $scope.mandatoryRules.receiving_mode) {
      return false;
    }
    if (!$scope.supplier.basic_info.business_entity && $scope.mandatoryRules.business_entity) {
      return false;
    }
    if (!$scope.supplier.default_contract.payment_term_code && $scope.mandatoryRules.payment_term_code) {
      return false;
    }
    if (!$scope.supplier.default_contract.currency_code && $scope.mandatoryRules.currency_code) {
      return false;
    }
    if (!$scope.isValidPhoneNo) {
      return false;
    }
    if (!$scope.isValidFaxNo) {
      return false;
    }
    if (!$scope.supplier.basic_info.phone || !$scope.supplier.basic_info.phone.length) {
      return false;
    }

    if(!!$scope.receive_via_email){
      if (!$scope.supplier.basic_info.email || !$scope.supplier.basic_info.email.length) {
        return false;
      }
    }

    if (!!$scope.receive_via_fax) {
      if (!$scope.supplier.basic_info.fax || !$scope.supplier.basic_info.fax.length) {
        return false;
      }
    }

    if ( !$scope.supplier.basic_info.corresponding_address.state && $scope.mandatoryRules.state) {
      return false;
    }

    if (!$scope.supplier.basic_info.corresponding_address || !$scope.supplier.basic_info.corresponding_address.line1 || !$scope.supplier.basic_info.corresponding_address.city || !$scope.supplier.basic_info.corresponding_address.country || !$scope.supplier.basic_info.corresponding_address.postal_code) {
      return false;
    }
    if ((!$scope.selectedContactPersons || !$scope.selectedContactPersons.length) && $scope.mandatoryRules.contacts) {
      return false;
    }

    if (_.isEmpty($scope.selectedLanguage) && $scope.mandatoryRules.language) {
      return false;
    }
    return true;
  }

  function onSelectedPaymentMethod(paymentMethod) {
    $scope.supplier.default_contract.payment_method_code = paymentMethod;
  }

  function onSelectedPaymentTerm(paymentTerm) {
    $scope.supplier.default_contract.payment_term_code = paymentTerm;
  }

  function onSelectedBusinessEntity(businessEntity) {
    $scope.supplier.basic_info.business_entity = businessEntity;
  }

  function onSelectedTax(tax) {
    $scope.gstValidation = true;

    $scope.supplier.default_contract.tax_code = tax;

    if (tax.code === 'NT0' || tax.code === 'NRC') {
      $scope.gstValidation = false;
    }
  }

  function onSelectedCountry(country) {
    $scope.supplier.basic_info.corresponding_address.country = country;
    $scope.selectedMalaysia = (country.descr === "Malaysia");
    if (!$scope.selectedMalaysia) {
      $scope.supplier.basic_info.corresponding_address.state = null;
    }
    try {
      $('#addSupplierCompanyFax').intlTelInput('setCountry', country.code.toLowerCase());//update country in Fax field
      $('#addSupplierCompanyPhone').intlTelInput('setCountry', country.code.toLowerCase());//update country in Company phone field
    } catch (e) {
      // ignore the error for some country that does not exist in the plugin
    }
  }

  function onSelectedState(state) {
    $scope.supplier.basic_info.corresponding_address.state = state;
  }

  function onSelectedSupplierType(supplierType) {
    $scope.selectedSupplierType = supplierType;
    if (!!$scope.selectedSupplierType.isP2OSupplier) {
      $scope.supplierUsers = [];
    } else {
      if (_.isEmpty($scope.supplierUsers)) {
        addSupplierUser();
      }

      // unset fields for sourcing field
      $scope.receive_via_email = false;
      $scope.receive_via_fax = false;
      $scope.receive_via_phone = false;
      $scope.supplier.basic_info.gst_code = undefined;
      $scope.supplier.basic_info.paid_up_capital = undefined;
      $scope.supplier.basic_info.tagged_to_company_code = undefined;
      $scope.supplier.default_contract.bumi_status = undefined;
      $scope.supplier.default_contract.payment_method_code = undefined;
      $scope.supplier.default_contract.payment_term_code = undefined;
      $scope.supplier.default_contract.tax_code = undefined;
      $scope.selectedContactPersons = [];
    }
    loadValidationRules();
  }

  function onSelectedLanguage(language) {
    $scope.selectedLanguage = language;
  }

  /** Contact Person(s)*/
  function onUpdatedContact(contact, index) {
    $scope.selectedContactPersons.splice(index, 1, contact);
  }

  function onAddedContact(contact) {
    $scope.selectedContactPersons.splice($scope.selectedContactPersons.length, 0, contact);
  }

  function removeContact(index) {
    $scope.selectedContactPersons.splice(index, 1);
  }

  /** set the first contact in additional contact as primary contact*/
  function settingContacts() {
    var temp_contact_id = [];
    var temp_contact_object = [];

    /**
     * Loop each selected contact person
     * Push the ids into temp_contact_id array containing ids to pass to payload
     * Push the objects into temp_contact_object array containing the contact objects for display
     */
    _.forEach($scope.selectedContactPersons, function (item) {
      if (angular.isDefined(item._id)) {
        temp_contact_id.push(item._id);
        temp_contact_object.push(item);
      } else {
        temp_contact_id.push(item);
      }
    });

    $scope.supplier.additional_contacts_ids = temp_contact_id;
    $scope.supplier.additional_contacts = temp_contact_object;

    $scope.selectedContactPersons = $scope.supplier.additional_contacts;

    /**
     * Sets the first contact as primary contact and splices the contact from additional contacts
     * to place into contact_person_id to pass to payload
     */
    $scope.supplier.contact_person_id = temp_contact_id[0];
    $scope.supplier.additional_contacts_ids.splice(0, 1);

  }

  function checkHyphenFirstCharacter(string, field) {
    if (!!string && field === 'descr') {
      $scope.descrFirstCharHyphen = supplierFunctions.checkHyphenFirstCharacter(string);
      return $scope.descrFirstCharHyphen;
    }
    else if (!!string && field === 'reg_no') {
      $scope.rocFirstCharHyphen = supplierFunctions.checkHyphenFirstCharacter(string);
      return $scope.rocFirstCharHyphen;
    }
    else if (!!string && field === 'old_reg_no') {
      $scope.oldRocFirstCharHyphen = supplierFunctions.checkHyphenFirstCharacter(string);
      return $scope.oldRocFirstCharHyphen;
    }
    return;
  }

  function selectedBumiputra(value) {
    $scope.supplier.default_contract.bumi_status = value;
    $scope.noBumiStatus = false;
  }

  function onSelectedCurrency(currency) {
    $scope.supplier.default_contract.currency_code = currency;
  }

  /** search for the commodity code*/
  function searchPrimaryCommodityCode(keyword) {
    if (!!keyword && keyword.length > 1) {
      searchModule.get(
        {
          module: 'commodity',
          'criteria[0][code]': keyword,
          'criteria[0][descr]': keyword,
          'criteria[0][descr_chinese]': keyword,
          'criteria[0][$operator]': 'or',
          'criteria[1][is_active]': 1,
          order: 1,
          order_by: 'code',
          offset: 0
        }, function (resource) {
          if (!!resource && !!resource.content && !!resource.content.data)
            $scope.primaryCommodityCodes = resource.content.data;
        }, function () {
          $scope.primaryCommodityCodes = [];
        });
      return $scope.primaryCommodityCodes;
    }
  }

  function searchSecondaryCommodityCode(keyword) {
    if (!!keyword && keyword.length > 1) {
      searchModule.get(
        {
          module: 'commodity',
          'criteria[0][code]': keyword,
          'criteria[0][descr]': keyword,
          'criteria[0][descr_chinese]': keyword,
          'criteria[0][$operator]': 'or',
          'criteria[1][is_active]': 1,
          order: 1,
          order_by: 'code',
          offset: 0
        }, function (resource) {
          if (!!resource && !!resource.content && !!resource.content.data)
            $scope.secondaryCommodityCodes = resource.content.data;
        }, function () {
          $scope.secondaryCommodityCodes = [];
        });
      return $scope.secondaryCommodityCodes;
    }
  }

  /** get commodity's child data*/
  function openChildCommodityCodePrimary(cc) {
    listChild.get({
        code: cc
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data.length > 0) {
          $scope.primaryCommodityCodes = resource.content.data;
        } else {
          toastr.error("There are no child on this commodity code");
        }
      },
      function () {
        toastr.error("Failed to get commodity codes data");
      })
  }

  function openChildCommodityCodeSecondary(cc) {
    listChild.get({
        code: cc
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data.length > 0) {
          $scope.secondaryCommodityCodes = resource.content.data;
        } else {
          toastr.error("There are no child on this commodity code");
        }
      },
      function () {
        toastr.error("Failed to get commodity codes data");
      })
  }

  function removeSecondaryCommodity() {
    $scope.supplier.default_contract.secondary_commodity_code = null;
    $scope.secondaryCommodityKeyword = null;
  }

  /** companies list for tagging */
  function loadCompaniesForTagging(callback) {

    var embeddedParams = {
      id: _.isEmpty($scope.draftId) ? 0 : $scope.draftId,
      disable_paginate: 1
    };

    supplierFunctions.getCompanyContracts(embeddedParams).then(function (success) {
      $scope.companies = success.data;
    });
  }

  function paymentMethodValidation() {
    var validPaymentMethod = false;
    if (!!$scope.supplier.default_contract.payment_method_code && !!$scope.supplier.default_contract.payment_method_code.code) {
      if (globalFunc.findInArray(paymentMethods.data, 'code', $scope.supplier.default_contract.payment_method_code.code)) {
        validPaymentMethod = true;
      }
    }
    return validPaymentMethod;
  }

  function paymentTermValidation() {
    var validPaymentTerm = false;
    if (!!$scope.supplier.default_contract.payment_term_code && !!$scope.supplier.default_contract.payment_term_code.code) {
      if (globalFunc.findInArray(paymentTerms.data, 'code', $scope.supplier.default_contract.payment_term_code.code)) {
        validPaymentTerm = true;
      }
    }
    return validPaymentTerm;
  }

  function validatePrimaryBusinessNature(businessNature) {
    $scope.supplier.default_contract.primary_business_nature = businessNature;

    if (angular.isDefined($scope.supplier.default_contract.secondary_business_nature)) {
      if (($scope.supplier.default_contract.secondary_business_nature === $scope.supplier.default_contract.primary_business_nature)) {
        toastr.error('Primary business nature cannot be the same as secondary business nature');
        $scope.validatedBN = true;
      } else {
        $scope.validatedBN = false;
      }
    }
  }

  function validateSecondaryBusinessNature(businessNature) {
    $scope.supplier.default_contract.secondary_business_nature = businessNature;
    if (angular.isDefined($scope.supplier.default_contract.primary_business_nature)) {
      if (($scope.supplier.default_contract.primary_business_nature === $scope.supplier.default_contract.secondary_business_nature)) {
        toastr.error('Primary business nature cannot be the same as secondary business nature');
        $scope.validatedBN = true;
      } else {
        $scope.validatedBN = false;
      }
    }
  }

  function checkCommodityCode() {
    if (!!$scope.supplier.default_contract.primary_commodity_code && !!$scope.supplier.default_contract.secondary_commodity_code) {
      if ($scope.supplier.default_contract.primary_commodity_code.code === $scope.supplier.default_contract.secondary_commodity_code.code) {
        return true;
      }
    }
    return false;
  }

  function getPaidUpCategory() {
    $scope.paidUpCategoryDescr = supplierFunctions.getPaidUpCategory($scope.supplier.basic_info.paid_up_capital);
  }

  function backToListingSupplier() {
    $state.go('main.user.supplier.manage', {status: "draft"});
  }

  function saveDraft(supplier) {
    var preparedSupplier = preparePayload(supplier);
    if (!$scope.draftId) {
      createNewDraft(preparedSupplier);
    } else {
      updateExistingDraft(preparedSupplier);
    }
  }

  function createNewDraft(supplier, reason) {
    newDraft.post(
      {},
      supplier,
      function (resource) {
        $scope.supplier._id = resource.content.data._id;
        $scope.draftId = $scope.supplier._id;

        if ($scope.attachments.length > 0) {
          var fd = new FormData();
          for (var i in $scope.attachments) {
            fd.append("file[]", $scope.attachments[i]);
          }
          fd.append('model', 'Supplier');
          $http.post(
            $filter('format')(pathConstants.apiUrls.supplier.newDraftAttachment.add, {draft_id: $scope.draftId}), fd, {
              transformRequest: angular.identity,
              headers: {'Content-Type': undefined}
            }
          ).then(function (response) {
            if (reason === undefined) {
              toastr.success('Supplier draft successfully saved.');
              $state.go('main.user.supplier.editSupplierDraft', {id: $scope.draftId}, {$notify: false})
            } else {
              newSupplierPost(supplier, reason)
            }
          }, function (error) {
            if (reason === undefined) {
              toastr.success('Supplier draft successfully saved.');
              toastr.error(error);
            }
          });
        } else {
          if (reason === undefined) {
            toastr.success('Supplier draft successfully saved.');
            $state.go('main.user.supplier.editSupplierDraft', {id: $scope.draftId}, {$notify: false})
          } else {
            newSupplierPost(supplier, reason)
          }
        }
      }, function(error) {
        toastr.error(error);
    });
  }

  function updateExistingDraft(supplier) {
    var payload = {
      basic_info: supplier.basic_info,
      default_contract: supplier.default_contract,
      branches: supplier.branches,
      company_code_to_sync_to_sage: !!supplier.company_code_to_sync_to_sage ? supplier.company_code_to_sync_to_sage : [],
      company_contracts :  !!supplier.company_contracts ? supplier.company_contracts : [],
    }

    if (!_.isEmpty(supplier.supplier_users)) {
      payload.supplier_users = supplier.supplier_users;
    }

    supplierDraft.put(
      {id: $scope.draftId},
      payload,
      function (resource) {
        $scope.supplier._id = resource.content.data._id;
        $scope.draftId = $scope.supplier._id;
        $state.reload();
        toastr.success('Supplier draft successfully saved.');
      }, function (error) {
        toastr.error(error);
    });
  }

  function deleteDraft() {
    swal(
      {
        title: 'Confirm delete Supplier draft?',
        text: 'This supplier draft will be deleted. This action is not revertable.',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (confirm) {
        if (!confirm) {
          return;
        }
        supplierDraft.delete(
          {id: $scope.draftId},
          function (resource) {
            toastr.success(resource.message);
            $state.go('main.user.supplier.manage', {status: 'active'});
          }, function (error) {
            toastr.error(error);
          }
        )
      }
    );
  }

  function createNewSupplier(supplier, action) {
    //TODO: SUPPLIER V2 rename saveAs to something else its a bool validation checking at newSupplier.html file
    $scope.saveAs = action;

    if (!submitSupplierValidation(supplier)) {
      $translate("messages.completeAllRequiredFields").then(function (trans) {
        toastr.error(trans);
      });
      return;
    }

    if (!!supplier.default_contract.secondary_commodity_code && !!supplier.default_contract.secondary_commodity_code.code) {
      if (supplier.default_contract.primary_commodity_code.code === supplier.default_contract.secondary_commodity_code.code) {
        toastr.error('Primary Commodity Code cannot be the same as Secondary Commodity Code');
        return;
      }
    }

    var supplierObject = preparePayload(supplier);

    if ($scope.draftId === undefined) {
      supplierObject.company_contracts.creation_only = true;
    }

    submitSupplier(supplierObject);
  }

  function submitSupplier(supplierObject) {
    var isSubmitClicked = false;

    swal(
      {
        title: 'Confirm submit supplier?',
        text: 'This supplier will be submitted by you. Please state a reason.',
        showCancelButton: true,
        type: 'input',
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: false,
        closeOnCancel: true,
        inputType: 'text',
        inputPlaceholder: 'Write a reason'
      },
      function (reason) {
        if (!reason || reason === ''){
          swal.showInputError('Reason is mandatory for submitting a new supplier.');
          return;
        } else if (isSubmitClicked){
          return;
        }
        isSubmitClicked = true;
        if (!!$scope.draftId) {
          newSupplierPost(supplierObject, reason);
        } else {
          createNewDraft(supplierObject, reason);
        }
      }
    );
  }

  function newSupplierPost(supplierObject, reason) {
    newSupplier.post({
        comment: reason,
        draft_id: $scope.draftId
      },
      supplierObject,
      function (resource) {
        swal.close();
        toastr.success('Supplier was created successfully');
        $state.go('main.user.supplier.manage', {
          status: 'active'
        });
      },
      function (error) {
        $scope.gstValidation = false;
        if (submitSupplierValidation() === false) {
          toastr.error('Please complete all required fields before proceeding');
          return;
        }
        if ($scope.selectedSupplierType && !$scope.selectedSupplierType.isSourcingSupplier && !paymentMethodValidation() && !$scope.isV2FeatureEnabled) {
          toastr.error('Invalid payment method.');
          return;
        }
        if ($scope.selectedSupplierType && !$scope.selectedSupplierType.isSourcingSupplier && !paymentTermValidation()) {
          toastr.error('Invalid payment term.');
          return;
        }

        if($scope.supplier){
          duplicationCheck('Supplier','basic_info.descr',$scope.supplier.basic_info.descr,'supplierDescr');
        }

        if(supplier.basic_info){
          duplicationCheck('Supplier','basic_info.reg_no',supplier.basic_info.reg_no,'roc');
        }
        
        if (!!error.data && !!error.data.content && !!error.data.content.error) {
          if (typeof error.data.content.error === 'string' &&
            !globalFunc.hasJsonStructure(error.data.content.error)) {
            toastr.error(error.data.content.error);
          }
          else {
            globalFunc.laravelValidatorErrDisplay(JSON.parse(error.data.content.error));
          }
        }
      }
    );
  }

  function duplicationCheck(model, field, value, checker) {
    globalFunc.duplicationCheck(model, field, value, checker, function (callback) {
      switch (callback) {
        case 'rocCheckTrue':
          $scope.rocCheck = true;
          break;
        case 'rocCheckFalse':
          $scope.rocCheck = false;
          break;
        case 'supplierDescrCheckTrue':
          $scope.descrCheck = true;
          break;
        case 'supplierDescrCheckFalse':
          $scope.descrCheck = false;
          break;
      }
    });
  }

  function preparePayload(supplier) {
    var temp = 0;
    if ($scope.receive_via_email) {
      temp += 2;
    }
    if ($scope.receive_via_fax) {
      temp += 4;
    }
    if ($scope.receive_via_post) {
      temp += 8;
    }

    var stateDescr = '';
    if ($scope.selectedMalaysia && !!supplier.basic_info.corresponding_address.state) {
      stateDescr = supplier.basic_info.corresponding_address.state.descr;
    }

    var companyContractData  = [];
    if (!_.isEmpty(supplier.company_contracts))
      _.forEach(supplier.company_contracts, function (companyContract) {
        if (!_.isEmpty(companyContract.code)) {
          companyContractData = [];
        } else {
          companyContractData = companyContract;
        }
      });

    var supplierData = {
      basic_info: {
        descr: supplier.basic_info.descr,
        reg_no: supplier.basic_info.reg_no,
        old_reg_no: !!supplier.basic_info.old_reg_no ? supplier.basic_info.old_reg_no : undefined,
        gst_code: supplier.basic_info.gst_code,
        email: supplier.basic_info.email,
        phone: supplier.basic_info.phone,
        fax: supplier.basic_info.fax,
        business_entity: (!!supplier.basic_info.business_entity) ? supplier.basic_info.business_entity.shortDescr : undefined,
        paid_up_capital: (!!supplier.basic_info.paid_up_capital) ? supplier.basic_info.paid_up_capital : undefined,
        tagged_to_company_code: (!!supplier.basic_info.tagged_to_company_code && !!supplier.basic_info.tagged_to_company_code.code) ? supplier.basic_info.tagged_to_company_code.code : undefined,
        corresponding_address: {
          line1: supplier.basic_info.corresponding_address.line1,
          line2: supplier.basic_info.corresponding_address.line2,
          line3: supplier.basic_info.corresponding_address.line3,
          postal_code: supplier.basic_info.corresponding_address.postal_code,
          city: supplier.basic_info.corresponding_address.city,
          state: $scope.selectedMalaysia ? stateDescr : supplier.basic_info.corresponding_address.state,
          country: (!!supplier.basic_info.corresponding_address.country) ? supplier.basic_info.corresponding_address.country.descr : undefined
        }
      },
      default_contract: {
        company_code: supplier.default_contract.company_code,
        currency_code: !!supplier.default_contract.currency_code ? supplier.default_contract.currency_code.code : undefined ,
        tax_code: (!!supplier.default_contract.tax_code) ? supplier.default_contract.tax_code.code : undefined,
        receiving_mode: temp,
        payment_method_code: (!!supplier.default_contract.payment_method_code) ? supplier.default_contract.payment_method_code.code : undefined,
        payment_term_code: (!!supplier.default_contract.payment_term_code) ? supplier.default_contract.payment_term_code.code : undefined,
        primary_business_nature: (!!supplier.default_contract.primary_business_nature) ? supplier.default_contract.primary_business_nature.shortDescr : undefined,
        secondary_business_nature: !!supplier.default_contract.secondary_business_nature ? supplier.default_contract.secondary_business_nature.shortDescr : undefined,
        primary_commodity_code: (!!supplier.default_contract.primary_commodity_code) ? supplier.default_contract.primary_commodity_code.code : undefined  ,
        secondary_commodity_code: !!supplier.default_contract.secondary_commodity_code ? supplier.default_contract.secondary_commodity_code.code : undefined,
        bumi_status: supplier.default_contract.bumi_status,
        contacts: !!$scope.selectedContactPersons ? $scope.selectedContactPersons : undefined
      },
      branches: $scope.supplier.branches,
      company_code_to_sync_to_sage: $scope.supplier.company_code_to_sync_to_sage,
      company_contracts: {'company_codes': companyContractData}
    }

    if (!!$scope.isSourcingSupplierEnabled) {
      supplierData.basic_info.language = !_.isEmpty($scope.selectedLanguage) ? $scope.selectedLanguage.value : undefined;
      supplierData.basic_info.is_sourcing_supplier = (!!$scope.selectedSupplierType && !!$scope.selectedSupplierType.isSourcingSupplier);
      supplierData.basic_info.is_p2o_supplier = (!!$scope.selectedSupplierType && !!$scope.selectedSupplierType.isP2OSupplier);
      if (!!supplierData.basic_info.is_sourcing_supplier && !_.isEmpty($scope.supplierUsers)) {
        var supplierUsers = _.cloneDeep($scope.supplierUsers);
        _.forEach(supplierUsers, function(user) {
          delete user.isExistingEmail;
          delete user.isValidPhone;
        });
        supplierData.supplier_users = supplierUsers;
      }
    }

    return supplierData;
  }

  function loadBasicInfoData() {
    $scope.supplier.basic_info = supplier.basic_info;
    if (!!supplier.basic_info.corresponding_address) {
      if (!!supplier.basic_info.corresponding_address.country) {
        var country = globalFunc.findInArray($scope.countries, 'descr', supplier.basic_info.corresponding_address.country);
        $scope.supplier.basic_info.corresponding_address.country = country;
        $scope.selectedMalaysia = (country.descr === "Malaysia");
      }
      if ($scope.selectedMalaysia && !!supplier.basic_info.corresponding_address.state) {
        supplier.basic_info.corresponding_address.state = globalFunc.findInArray($scope.myStates, 'descr', supplier.basic_info.corresponding_address.state)
      }
    }
    if (!!supplier.basic_info.business_entity) {
      $scope.supplier.basic_info.business_entity = globalFunc.findInArray($scope.businessEntities, 'shortDescr', supplier.basic_info.business_entity);
    }

    if (!!$scope.isSourcingSupplierEnabled) {
      if (_.has($scope.supplier.basic_info, 'is_sourcing_supplier') &&
        _.has($scope.supplier.basic_info, 'is_p2o_supplier')) {
        $scope.selectedSupplierType = supplierFunctions.findSupplierType($scope.supplier.basic_info);
      }

      if (_.has($scope.supplier.basic_info, 'language') && !!$scope.supplier.basic_info.language) {
        $scope.selectedLanguage = supplierFunctions.findLanguage($scope.supplier.basic_info.language);
      }
    }
  }

  function loadDefaultContractData() {
    $scope.supplier.default_contract = supplier.default_contract;

    if (!!supplier.default_contract.currency_code) {
      $scope.supplier.default_contract.currency_code = globalFunc.findInArray($scope.currencies, 'code', supplier.default_contract.currency_code);
    }

    if (!!supplier.default_contract.primary_commodity_code) {
      var primaryCommodity = {
        code: supplier.default_contract.primary_commodity_code,
        unique_name: supplier.default_contract.primary_commodity_code,
        descr: supplier.default_contract.primary_commodity_descr
      };

      $scope.supplier.default_contract.primary_commodity_code = primaryCommodity;
      $scope.primaryCommodityKeyword = primaryCommodity.code + ' ' + primaryCommodity.descr;
    }

    if (!!supplier.default_contract.secondary_commodity_code) {
      var secondaryCommodity = {
        code: supplier.default_contract.secondary_commodity_code,
        unique_name: supplier.default_contract.secondary_commodity_code,
        descr: supplier.default_contract.secondary_commodity_descr
      };

      $scope.supplier.default_contract.secondary_commodity_code = secondaryCommodity;
      $scope.secondaryCommodityKeyword = secondaryCommodity.code + ' ' + secondaryCommodity.descr;
    }

    if (!!supplier.default_contract.primary_business_nature) {
      $scope.supplier.default_contract.primary_business_nature = globalFunc.findInArray($scope.businessNatures, 'shortDescr', supplier.default_contract.primary_business_nature);
    }

    if (!!supplier.default_contract.secondary_business_nature) {
      $scope.supplier.default_contract.secondary_business_nature = globalFunc.findInArray($scope.businessNatures, 'shortDescr', supplier.default_contract.secondary_business_nature);
    }

    if (!!supplier.default_contract.payment_term_code) {
      $scope.supplier.default_contract.payment_term_code = globalFunc.findInArray($scope.paymentTerms, 'code', supplier.default_contract.payment_term_code);
    }

    if (!!supplier.default_contract.payment_method_code) {
      $scope.supplier.default_contract.payment_method_code = globalFunc.findInArray($scope.paymentMethods, 'code', supplier.default_contract.payment_method_code);
    }

    if (!!supplier.default_contract.receiving_mode) {
      if ([2, 6, 10, 14].indexOf(supplier.default_contract.receiving_mode) > -1)
        $scope.receive_via_email = true;

      if ([4, 6, 12, 14].indexOf(supplier.default_contract.receiving_mode) > -1)
        $scope.receive_via_fax = true;

      if ([8, 10, 12, 14].indexOf(supplier.default_contract.receiving_mode) > -1)
        $scope.receive_via_post = true;
    }
    if (!!supplier.default_contract.tax_code) {
      var tax = globalFunc.findInArray($scope.taxes, 'code', supplier.default_contract.tax_code);
      onSelectedTax(tax);
    }

    if (!!supplier.default_contract.contacts) {
      $scope.selectedContactPersons = supplier.default_contract.contacts;
    }
  }

  function addSupplierUser() {
    $scope.supplierUsers.push({
      display_name: '',
      email: '',
      phone: ''
    });
  }

  function removeSupplierUser(index) {
    $scope.supplierUsers.splice(index, 1);
  }

  function openCompanyContractModal() {
    var modalInstance = $uibModal.open({
      templateUrl: 'app/suppliersManagement/companyContract/companyContract.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'companyContractModalCtrl',
      size: 'lg',
      resolve: {
        companies: function () {
          return $scope.companies;
        },
        companyContracts: function () {
          return $scope.companyContracts
        },
        supplierData: function (){
          return $scope.supplier;
        },
        editClicked: function () {
          return false;
        },
        draftId: function () {
          return $scope.draftId;
        },
        allowUpdateContractApi: function () {
          return false;
        },
      }
    });

    modalInstance.result.then(function (newValue) {
      if (!_.isEmpty(newValue)) {
        $scope.supplier.company_contracts = {
          company_codes: newValue
        }
      }
    }, function () {
    });
  }

  function loadDraftCompanyContract() {
    if (!!$scope.draftId) {
      supplierDraft.get(
        {
          id:  $scope.draftId
        },
        function (resource) {
          if (!!resource.content && !!resource.content.data) {
            $scope.companyContracts = resource.content.data.company_contracts;
          }
        });
    } else {
      $scope.companyContracts = [];
    }
  }

  function loadValidationRules() {
    var type = 'P2O Supplier';

    if (!!$rootScope.isV2Enabled) {
      type = 'v2Enabled';
    } else if ($scope.isSourcingSupplierEnabled) {
      if (!_.isEmpty($scope.selectedSupplierType)) {
        type = $scope.selectedSupplierType.descr;
      }
    }

    $scope.mandatoryRules = supplierFunctions.getMandatoryValidationRules(type);

    if (!!$scope.isSourcingSupplierEnabled) {
      $scope.mandatoryRules['language'] = true;
    }
  }

  function previewApprovalFlow() {
    var data = preparePayload($scope.supplier);

    approvalFlowPreview.post(
      {},
      {
        data: data,
        action: 'ACTIVE'
      },
      function (resource) {
        $scope.approvals = resource.content.data;
        $timeout(function () {
          loadScrollbar();
        }, 100);
      }, function (error) {
        globalFunc.objectErrorMessage('Failed to preview the approval flow');
        globalFunc.objectErrorMessage(error);
      });
  }

  function initialize() {
    $scope.isV2FeatureEnabled =  $rootScope.isV2Enabled;
    $scope.paymentMethods = supplierFunctions.filterInactiveCode(paymentMethods.data);
    $scope.paymentTerms = supplierFunctions.filterInactiveCode(paymentTerms.data);
    $scope.countries = globalFunc.countryCode();
    $scope.myStates = globalFunc.malaysiaState();
    $scope.receive_via_system = true;
    $scope.isValidPhoneNo = true;
    $scope.isValidFaxNo = true;
    $scope.supplier.is_global_supplier = true;
    $scope.firstCharHyphen = false;
    $scope.validatedBN = false;
    $scope.supplier.branches = [];
    if (!$scope.isV2FeatureEnabled) {
      $scope.supplier.default_contract = {
        currency_code: globalFunc.findInArray($scope.currencies, 'code', 'MYR')
      };
    }

    if ($scope.supplierTypes.length === 1) {
      if (!$scope.supplierTypes[0].isP2OSupplier && !!$scope.supplierTypes[0].isSourcingSupplier) {
        $scope.selectedSupplierType = $scope.supplierTypes[0];
      }
    }

    addSupplierUser();
    // Load draft data if it exists
    if (!_.isEmpty(supplier)) {
      $scope.draftId = supplier._id;

      if (!!supplier.basic_info) {
        loadBasicInfoData();
      }

      if (!!supplier.default_contract) {
        loadDefaultContractData();
      }

      if (!!supplier.branches) {
        $scope.supplier.branches = supplier.branches;
      }

      if (!!supplier.company_code_to_sync_to_sage){
        $scope.supplier.company_code_to_sync_to_sage = supplier.company_code_to_sync_to_sage;
      }

      if (!!supplier.company_contracts){
        $scope.supplier.company_contracts = supplier.company_contracts;
      }

      if (!!supplier.basic_info.is_sourcing_supplier) {
        if(!_.isEmpty(supplier.supplier_users)) {
          $scope.supplierUsers = supplier.supplier_users;
        }
      }

      // for displaying supplier attachments
      if (!!supplier.attachments) {
        $scope.attachments = [];
        supplier.attachments.forEach(function (attachment) {
          if (attachment.type === 'MAIN') {
            $scope.attachments.push(attachment);
          }
        })
      }
    }

    loadCompaniesForTagging(function () {
      if (!!supplier && !!supplier.basic_info && !!supplier.basic_info.tagged_to_company_code) {
        $scope.supplier.basic_info.tagged_to_company_code = globalFunc.findInArray($scope.companies, 'code', supplier.basic_info.tagged_to_company_code);
      }
    });

    loadDraftCompanyContract();
    loadValidationRules();
  }

  initialize();
  submitSupplierValidation();

  /** Validate number format with given element id and returns boolean in field name*/
  function validateNumbers(elementId, fieldName) {
    $scope[fieldName] = globalFunc.numberFormatValidation(elementId);
  }
}
