/**
 * @name preventDoubleClickingService
 *
 * @description
 * Small service used in controller to prevent clicking on the same link (anchor) twice
 *
 * @author Ahmed Saleh <a.s.alsalali@gmail.com>
 * @copyright  Metacloud Sdn. Bhd. &copy; 2017
 */

'use strict';

function preventDoubleClickingService ($interval) {

  //The time is set to 300 milliseconds
  //TODO: move the value of the time to a configuration file once available (Ahmed Saleh, 18/1/17)
  var globalTimer = 300;
  var allowClicking = true;
  return {
    checkClick: function () {
      if (allowClicking) {
        allowClicking = false;

        $interval(function () {
          allowClicking = true;
        }, globalTimer, 0);

        return true
      } else {
        return false;
      }
    }
  }
}

preventDoubleClickingService.$inject = ['$interval'];

angular
  .module('metabuyer')
  .factory('preventDoubleClickingService',preventDoubleClickingService);
