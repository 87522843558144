'use strict';

function securityFunction() {
  var selectedCompany = {};
  var selectedCostCenter = {};
  var organizationLevel = '';

  return {
    setOrganizationLevel: function (orgLevel) {
      organizationLevel = orgLevel;
    },
    setSelectedCompany: function (company) {
      selectedCompany = company;
    },
    getSelectedCompany: function () {
      return selectedCompany;
    },
    setSelectedCostCenter: function (costCenter) {
      selectedCostCenter = costCenter;
    },
    getSelectedCostCenter: function () {
      return selectedCostCenter;
    }
  }
}

angular
    .module('metabuyer')
    .factory('securityFunction', securityFunction);