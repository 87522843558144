'use strict';

/**
 * @name userRoleAssignmentsCtrl
 * @desc Controller for editing user's role assignments and superior
 *
 * @author Justin Cheong <justin.cty90@gmail.com>
 * @copyright 2018 Metacloud Sdn. Bhd.
 */
function userRoleAssignmentsCtrl($scope, $q, $resource, $state, globalFunc, toastr, userDetails, $uibModal,
                                 companiesList, singleUser, $rootScope, roleAssignmentsData, userRoleAssignments,
                                 catalogGroups, alcGroups) {

  $scope.saveUser = saveUser;
  $scope.cancelEditUser = cancelEditUser;
  $scope.customTemplateUrl = 'app/usersManagement/roleAssignments/history/history.html';

  function saveUser() {
    swal({
        title: 'Confirm update role assignments?',
        text: 'This will update the role assignments for this user.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ab394  ',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (!!isConfirm) {
          var submitData = [];
          var selectedSuperiors = {};

          // Validate the selected role assignments before preparing payload or passing to backend
          if(!validateRoleAssignments()) {
            toastr.error('Please fill in all the fields before saving.');
            return;
          }

          // Foreach selected role assignments
          _.forEach($scope.selectedRoleAssignments, function (company) {
            _.forEach(company.roleAssignments, function (assignment) {
              const organizationTypeTenant = 1;
              assignment.weight = company.code === 'system' ? organizationTypeTenant : assignment.weight; 
              var roles = assignment.selectedRoles;
              if (!!assignment.keepRoles) {
                roles = assignment.selectedRoles.concat(assignment.keepRoles);
              }
              var assignmentPayload = {
                'organization_id': assignment.organization_id,
                'weight': assignment.weight,
                'roles': globalFunc.getArrayOfKeyValues(roles, 'name')
              };
              submitData.push(assignmentPayload);
            });

            if (company.code !== 'system') {
              selectedSuperiors[company.code] = {
                'id': !!company.manager ? company.manager._id : '',
                'weight': company.roleAssignments[0].weight
              };
            }
          });

          userRoleAssignments.put(
            {
              id: $scope.user._id
            },
            {
              'roleAssignments': submitData
            },
            function () {
              updateUserSuperiors(selectedSuperiors);
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
            }
          );
        }
      });
  }

  /**
   * Validate the selected role assignments
   */
  function validateRoleAssignments() {
    if (_.isEmpty($scope.selectedRoleAssignments)) {
      return false;
    }
    else {
      var loopChecked = false;
      _.forEach($scope.selectedRoleAssignments, function(company) {
        if (!!company && _.isEmpty(company.roleAssignments)) {
          loopChecked = true;
          return false;
        }
        _.forEach(company.roleAssignments, function(assignment) {
          if (!!assignment && _.isEmpty(assignment.selectedRoles)) {
            loopChecked = true;
            return false;
          }
        });
      });

      if (!loopChecked) {
        return true;
      }
    }
  }

  function updateUserSuperiors(selectedSuperiors) {
    singleUser.put(
      {
        id: $scope.user._id
      },
      {
        superiors: selectedSuperiors
      },
      function () {
        toastr.success('User role assignments updated successfully.');
        window.history.back();
      },
      function (err) {
        globalFunc.objectErrorMessage(err);
      });
  }

  function cancelEditUser() {
    window.history.back();
  }

  function initialize() {
    $scope.saveDisabled = true;
    $scope.user = userDetails;
    $scope.roleAssignmentsData = roleAssignmentsData;
    $scope.roleAssignmentsData = globalFunc.removeValueFromArray($scope.roleAssignmentsData, 'code', 'Supplier');
    $scope.roleAssignmentsData = globalFunc.removeValueFromArray($scope.roleAssignmentsData, 'code', 'Catalog');
    $scope.companiesList = companiesList;
    $scope.catalogGroupList = catalogGroups;
    $scope.alcGroupList = alcGroups;
    //HACK, add an identifier for the active search endpoint
    _.forEach($scope.alcGroupList, function (alcGroup) {
      alcGroup.type = 'alcGroup';
    });
    _.forEach($scope.catalogGroupList, function (catalogGroup) {
      catalogGroup.type = 'catalogGroup';
    });

    // to hide ADMIN & TENANT_SUPER_ADMIN selected options from User Access Manager;
    // to prevent removing ADMIN & TENANT_SUPER_ADMIN roles
    var isUserAccessManager = globalFunc.findRoleInRoleAssignments($scope.$root.currentUser.role_assignments, 'User Access Manager');
    if (isUserAccessManager) {
      _.forEach($scope.roleAssignmentsData, function (company) {
        if (company.code === 'system') {
          _.filter(company.roleAssignments, function (assignment) {
            if (assignment.code === 'system') {
              var roles = assignment.roles.filter(function (role) {
                if (role.name !== 'ADMIN' && role.name !== 'TENANT_SUPER_ADMIN') {
                  return role;
                }
              });
              var keepRoles = assignment.roles.filter(function (role) {
                if (role.name === 'ADMIN' || role.name === 'TENANT_SUPER_ADMIN') {
                  return role;
                }
              });
              assignment.roles = roles;
              assignment.keepRoles = keepRoles;
            }
            return assignment;
          });
        }
      });
    }

    var tabData = {
      tab: $scope.$parent.tabData[0],
      noTransition: true
    };

    $rootScope.$broadcast('loadSpecificTab', tabData);
  }

  initialize();
}

userRoleAssignmentsCtrl.$inject = ['$scope', '$q', '$resource', '$state', 'globalFunc', 'toastr', 'userDetails',
  '$uibModal', 'companiesList', 'singleUser', '$rootScope', 'roleAssignmentsData', 'userRoleAssignments',
  'catalogGroups', 'alcGroups'];

angular
  .module('metabuyer')
  .controller('userRoleAssignmentsCtrl', userRoleAssignmentsCtrl);
