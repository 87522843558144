'use strict';

angular
  .module('metabuyer')
  .directive('nonCatalogItemV2',function(loadCacheableData) {
    return {
      restrict: 'EA',
      templateUrl: 'components/purchaseRequisitionV2Components/nonCatalogV2/nonCatalogItemV2Template.html',
      scope: {
        nonCatalog: '=?',
        action: '=',
        addedItem: '='
      },
      controller: 'catalogNonCatalogItemV2Ctrl'
    }
  });
