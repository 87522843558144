'use strict';

function tenderApproverListCtrl(
  $scope, globalFunc, $rootScope, $uibModal, $state, HighlightId, pathConstants, $filter, tenderFunction, tenderService, toastr, $http
) {
  $scope.approvers = [];
  $scope.approverNameQuery = {
    selected: ''
  };
  $scope.approvalDatePicker = [];
  $scope.autoSaveApproverList = autoSaveApproverList;
  $scope.getApproverNameSearch = getApproverNameSearch;
  $scope.updateApproverName = updateApproverName;
  $scope.removeApproverUser = removeApproverUser;
  $scope.loadApproverSearch = loadApproverSearch;
  $scope.openDatePickerApproval = openDatePickerApproval;

  function loadApproverSearch() {
    document.querySelector("#approverNameQuery").focus();
  }

  $scope.showHideCond = function(data, fieldName) {
    var data = (data === undefined) ? null : data;
    var fieldName = (fieldName === undefined) ? null : fieldName;

    var canEdit = true;

    if ($scope.tender.is_vo && $scope.tender.status === 'draft' && tenderFunction.hasRoleRequester()) {

      if (['CHANGE_VENDOR'].includes($scope.tender.detail.type.key)) {
        canEdit = false;
      }

      if (['DATE_CHANGE'].includes($scope.tender.detail.type.key)) {
        if (['deleteBtn', 'role', 'remarks', 'add_btn'].includes(fieldName)) {
          canEdit = false;
        }
      }

    } else {
      canEdit = $scope.editableStatus;
    }

    return canEdit;
  };

  function autoSaveApproverList(fieldIndex, fieldValue, fieldName) {
    var _id = $scope.approvers[fieldIndex]._id;
    var userId = $scope.approvers[fieldIndex].user_id;
    var payload = {};
    payload[fieldIndex] = {
      _id: _id,
      user_id: userId
    };

    if (fieldName === 'approval_date') {
      var fieldValue = fieldValue ? moment(fieldValue).format('DD/MM/YYYY') : null;
    }

    payload[fieldIndex][fieldName] = fieldValue;

    tenderFunction.saveApi({ approvers: payload }, '').then(function(response) {

      if (response.http_code === 200) {
        tenderFunction.updateApprovalDate(response.content.data.approval_date);
      }
    })

  }

  function updateApproverName(selected) {

    var payload = [{
      user_id: selected.key
    }];

    tenderFunction.saveApi({ approvers: payload, return_single_approver: true }, '').then(function(response) {

      if (response.http_code === 200) {
        tenderFunction.updateApprovalDate(response.content.data.latest_approval_date);
        $scope.approvers.push(response.content.data);
        $scope.approvalDatePicker.push({ approvalDate: false })
      }
    })

    $scope.approverNameQuery = {
      selected: ''
    };

  }

  function getApproverNameSearch(query) {

    return tenderService.findApprover({
      id: tenderFunction.getTenderId(),
      keywords: query
    }).$promise.then(function(response) {
      if (response.content.data.length === 0) {
        return [];
      } else {
        return response.content.data;
      }
    });
  }

  function removeApproverUser(index) {

    swal({
      title: 'Confirmation',
      text: 'Confirm Delete This User Approver?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Confirm',
      closeOnConfirm: true
    }, function(isConfirm) {
      if (isConfirm) {

        var value = $scope.approvers[index];
        var payload = {};
        payload[index] = {
          _id: value._id,
          user_id: value.user_id,
          is_deleted: true
        };

        tenderFunction.saveApi({ approvers: payload }, 'Successfully deleted').then(function(response) {
          if (response.http_code === 200) {
            tenderFunction.updateApprovalDate(response.content.data.approval_date);
            $scope.approvers.splice(index, 1);
            $scope.approvalDatePicker.splice(index, 1);
          }
        })
      }
    })
  }

  function openDatePickerApproval($event, where) {
    $event.preventDefault();
    $event.stopPropagation();
    Object.keys($scope.approvalDatePicker).forEach(function(key) {
      $scope.approvalDatePicker[key].approvalDate = false;
    });
    $scope.approvalDatePicker[where].approvalDate = true;
  }

  function initialize() {
    $scope.approvers = tenderFunction.getApproverLists();

    if ($scope.approvers.length > 0) {
      _.forEach($scope.approvers, function(value, key) {
        if (!!value.approval_date) {
          $scope.approvers[key].approval_date = new Date(value.approval_date);
        }
        $scope.approvalDatePicker.push({ approvalDate: false });
      });
    }
  }

  initialize();

  $scope.$on('updateTenderApprovers', function(event, result) {
    $scope.approvers = result.data;
  });

}

tenderApproverListCtrl.$inject = [
  '$scope', 'globalFunc', '$rootScope', '$uibModal', '$state', 'HighlightId', 'pathConstants', '$filter', 'tenderFunction', 'tenderService', 'toastr', '$http'
];

angular
  .module('metabuyer')
  .controller('tenderApproverListCtrl', tenderApproverListCtrl);
