'use strict';

/**
 * Controller for creating new catalog group
 * @param $scope
 * @param toastr
 * @param globalFunc
 * @param $state
 * @param companyGroupsList
 * @param searchModule
 * @param HighlightId
 */
function createCatalogGroupCtrl($scope, toastr, globalFunc, $state, companyGroupsList, searchModule, HighlightId) {
  $scope.submitCatalogGroup = submitCatalogGroup;
  $scope.cancel = cancel;
  $scope.loadCompanies = loadCompanies;
  $scope.selectCompany = selectCompany;
  $scope.duplicationCheck = duplicationCheck;

  $scope.catalogGroupStatuses = [
    { isActive: 'Active' },
    { isActive: 'Deactivated' }
  ];

  function cancel(){
    window.history.back();
  }

  function loadCompanies(){
    $scope.loadingCompaniesList = true;
    searchModule.get(
      {
        module: 'companies',
        offset: 0
      },
      function (resource) {
        $scope.loadingCompaniesList = false;
        if(!!resource.content && !!resource.content.data){
          $scope.companies = resource.content.data;
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
        $scope.loadingCompaniesList = false;
      }
    );
  }

  //TODO: have company assigning when adding catalog group (Justin 11/11/16)
  function selectCompany(company, action){
    if(action === 'assign'){
      $scope.selectedCompanyArray.push(company);
    }
    else{
      $scope.selectedCompanyArray = globalFunc.removeValueFromArray($scope.selectedCompanyArray, '_id', company._id);
    }
  }

  function validate() {
    if ($scope.selectedCompanyArray.length === 0) {
      return false;
    }

    if (!$scope.catalogGroup.code && !$scope.catalogGroup.descr) {
      return false;
    }

    return true;
  }

  function submitCatalogGroup(code, descr, status){
    $scope.submitted = true;
    if(validate()) {

      //TODO: pass tempStatus when status is affecting catalog group (Justin 14/11/16)
      // set status in integer to pass it as parameter
      // var tempStatus;
      // switch (status.isActive.toLowerCase()) {
      //   case 'active':
      //     tempStatus = 1;
      //     break;
      //   case 'deactivated':
      //     tempStatus = 0;
      //     break;
      //   case 'disabled':
      //     tempStatus = 3;
      //     break;
      // }

      companyGroupsList.post(
        {
          code: code,
          descr: descr,
          company: $scope.selectedCompanyArray,
          is_active: 1
        },
        function (resource) {
          toastr.success('Catalog group added');
          $state.go('main.user.items.catalogGroup');
          HighlightId.setId(resource.content.data._id);
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      )
    }
    else{
      toastr.error('Please fill all the required fields before submitting');
    }
  }

  /**
   * @function duplicationCheck
   * Calls the global function's duplication check
   * Checks callback function return for assigning scope values for validation
   **/
  function duplicationCheck(model,field,value,checker){
    globalFunc.duplicationCheck(model,field,value,checker, function(callback){
      switch (callback){
        case 'codeCheckTrue':
          $scope.codeCheck = true;
          break;
        case 'codeCheckFalse':
          $scope.codeCheck = false;
          break;
      }
    });
  }

  function initialize(){
    $scope.catalogGroup = {};
    $scope.selectedCompanies = [];
    $scope.selectedCompanyArray = [];
    loadCompanies();
  }

  initialize();
}

createCatalogGroupCtrl.$inject = ['$scope', 'toastr', 'globalFunc', '$state', 'companyGroupsList', 'searchModule',
                                  'HighlightId'];

angular.module('metabuyer')
  .controller('createCatalogGroupCtrl', createCatalogGroupCtrl);
