'use strict';

angular.module('metabuyer.services.tender', ['ngResource'])
  .factory('tenderGroupService', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.tender, {}, {
        'list' : {
          method: 'GET',
          url: pathConstants.apiUrls.tender.group.list
        },
        'create': {
          method: 'POST',
          url: pathConstants.apiUrls.tender.group.create
        },
        'update': {
          method: 'PUT',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.tender.group.update
        },
        'detail': {
          method: 'GET',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.tender.group.detail
        },
      }
    );
  })
  .factory('tenderService',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.tender, {}, {
          'initial': {
            method: 'GET',
            url: pathConstants.apiUrls.tender.initial
          },
          'detail': {
            method: 'GET',
            params: {
              id: '@id'
            },
            url: pathConstants.apiUrls.tender.detail
          },
          'create': {
            method: 'POST',
            url: pathConstants.apiUrls.tender.create
          },
          'save': {
            method: 'PUT',
            params: {
              id: '@id'
            },
            url: pathConstants.apiUrls.tender.save
          },
          'findApprover': {
            method: 'GET',
            params: {
              id: '@id'
            },
            url: pathConstants.apiUrls.tender.findApprover
          },
          'findSupplier': {
            method: 'GET',
            params: {
              id: '@id'
            },
            url: pathConstants.apiUrls.tender.findSupplier
          },
          'uploadMemoAttachment': {
            method: 'POST',
            url: pathConstants.apiUrls.tender.uploadMemoAttachment
          },
          'getMemoAttachment': {
            method: 'GET',
            params: {
              tenderId: '@tenderId',
              memoId: '@memoId'
            },
            url: pathConstants.apiUrls.tender.getMemoAttachment
          },
          'manageMemoAttachment': {
            method: 'PUT',
            params: {
              tenderId: '@tenderId'
            },
            url: pathConstants.apiUrls.tender.manageMemoAttachment
          },
          'sendEmailMemo': {
            method: 'PUT',
            params: {
              tenderId: '@tenderId'
            },
            url: pathConstants.apiUrls.tender.sendEmailMemo
          },
          'submit': {
            method: 'PUT',
            params: {
              id: '@id'
            },
            url: pathConstants.apiUrls.tender.submit
          },
          'delete': {
            method: 'DELETE',
            params: {
              id: '@id'
            },
            url: pathConstants.apiUrls.tender.delete
          },
          'moveToDraft': {
            method: 'PUT',
            params: {
              id: '@id'
            },
            url: pathConstants.apiUrls.tender.moveToDraft
          },
          'deactivate': {
            method: 'PUT',
            params: {
              id: '@id'
            },
            url: pathConstants.apiUrls.tender.deactivate
          },
          'reactivate': {
            method: 'PUT',
            params: {
              id: '@id'
            },
            url: pathConstants.apiUrls.tender.reactivate
          },
          'reactivateExpired': {
            method: 'PUT',
            params: {
              id: '@id'
            },
            url: pathConstants.apiUrls.tender.reactivateExpired
          },
          'createVO': {
            method: 'PUT',
            params: {
              id: '@id'
            },
            url: pathConstants.apiUrls.tender.createVO
          },
          'cancelVO': {
            method: 'PUT',
            params: {
              id: '@id'
            },
            url: pathConstants.apiUrls.tender.cancelVO
          },
          'voHistories': {
            method: 'GET',
            params: {
              id: '@id'
            },
            url: pathConstants.apiUrls.tender.voHistories
          },
          'revisions': {
            method: 'GET',
            params: {
              id: '@id'
            },
            url: pathConstants.apiUrls.tender.revisions
          },
          'starringItem': {
            method: 'PUT',
            params: {
              id: '@id',
              user_id: '@user_id'
            },
            url: pathConstants.apiUrls.tender.starringItem
          },
          'removeStarringItem': {
            method: 'DELETE',
            params: {
              id: '@id',
              user_id: '@user_id'
            },
            url: pathConstants.apiUrls.tender.starringItem
          },
          'downloadPdf': {
            method: 'GET',
            ignoreLoadingBar: true,
            params: {
              id: '@id'
            },
            url: pathConstants.apiUrls.tender.generatePdf
          },
          'findByCompany': {
            method: 'GET',
            params: {
              companyCode: '@companyCode'
            },
            url: pathConstants.apiUrls.tender.findByCompany
          },
          'findByCatalog': {
            method: 'POST',
            params: {
              catalog_group_id: '@catalog_group_id',
              supplier_code: '@supplier_code'
            },
            url: pathConstants.apiUrls.tender.findByCatalog
          },
          'consumption': {
            method: 'GET',
            params: {
              id: '@id',
              curr: '@curr'
            },
            url: pathConstants.apiUrls.tender.consumption
          },
          'resendPendingEmail': {
            method: 'POST',
            params: {
              id: '@id'
            },
            url: pathConstants.apiUrls.tender.resendPendingTenderEmail
          }
        }
      );
    })
  .factory('tenderCommentService',
    function($resource, $filter, pathConstants) {
      return $resource(
        pathConstants.apiUrls.tender.comments, {
          referenceId: '@referenceId'
        }, {
          get: {
            method: 'GET',
            ignoreLoadingBar: true
          },
          post: {
            method: 'POST',
            params: {
              referenceId: '@referenceId'
            },
            url: pathConstants.apiUrls.tender.comments
          },
          delete: {
            method: 'DELETE',
            params: {
              referenceId: '@referenceId',
              commentId: '@commentId'
            },
            url: pathConstants.apiUrls.tender.comments
          }
        }
      )
    })
  .factory('tenderApprovalAction', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.approvals.status, {
        approval_id: '@approval_id',
        status: '@status',
        comment: '@comment'
      }, {
        'post': { method: 'POST' }
      }
    );
  })
  .factory('tenderAttachmentService',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.tender.attachment.list, {
          id: '@id'
        }, {
          'getListTenderAttachment': {
            method: 'GET'
          }, //get attachment list
          'post': {
            method: 'POST'
          },
          'get': {
            method: 'GET',
            params: {
              id: '@id',
              attachment_id: '@attachment_id'
            },
            url: pathConstants.apiUrls.tender.attachment.single
          },
          'delete': {
            method: 'DELETE',
            params: {
              id: '@id',
              attachment_id: '@attachment_id'
            },
            url: pathConstants.apiUrls.tender.attachment.single
          },
          'put': {
            method: 'PUT',
            params: {
              id: '@id',
              attachment_id: '@attachment_id'
            },
            url: pathConstants.apiUrls.tender.attachment.single
          }
        }
      );
    });
