'use strict';

function bifrostDataReinboundManageCtrl($scope, toastr, pathConstants, $http, $rootScope) {

  $scope.openFromDatePicker = openFromDatePicker;
  $scope.openToDatePicker = openToDatePicker;
  $scope.editableData = [];
  $scope.requestReinbound = requestReinbound;

  function openFromDatePicker($event){
    $event.preventDefault();
    $event.stopPropagation();
    $scope.editableData.dateFromPanel = true;
  }

  function openToDatePicker($event){
    $event.preventDefault();
    $event.stopPropagation();
    $scope.editableData.dateToPanel = true;
  }

  function requestReinbound(){
    var dateFrom = formatDate($scope.editableData.dateFrom);
    var dateTo = formatDate($scope.editableData.dateTo);

    $http.post(pathConstants.apiUrls.bifrost.reinboundDelivery, {
      date_from: dateFrom,
      date_to: dateTo,
    }).then(function (response) {
      toastr.success('Successfully request to reinbound JDE Delivery.');
    }, function () {
      toastr.error('Failed to request reinbound JDE Delivery.');
    });
  }

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = '' + d.getFullYear();
      year = year.substring(2);

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('/');
  }

  function initialize() {
    $scope.dateOptions = {
      formatYear: 'yy',
      startingDay: 1
    };
    $scope.datePickerTimezone = $rootScope.datePickerTimezone;

    $scope.editableData.dateFromPanel = false;
    $scope.editableData.dateToPanel = false;
  }

  initialize();
}

bifrostDataReinboundManageCtrl.$inject = ['$scope', 'toastr', 'pathConstants', '$http', '$rootScope'];

angular
  .module('metabuyer')
  .controller('bifrostDataReinboundManageCtrl', bifrostDataReinboundManageCtrl);

