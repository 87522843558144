'use strict';

angular.module('metabuyer.services.minion', ['ngResource'])
  .factory('checkMinionConnection',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.minion.checkConnection,
        {},
        {
          'get': { method: 'GET' }
        }
      );
    }
  );
