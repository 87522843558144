'use strict';

/**
 * Main advanced Search controller
 *
 * @param $rootScope
 * @param $state
 * @param $scope
 * @param $http
 * @param pathConstants
 * @param $filter
 * @param globalFunc
 */
function advancedSearchCtrl($rootScope, $state, $scope, $http, pathConstants, $filter, globalFunc) {
  $scope.searchSuppliers = searchSuppliers;
  $scope.searchTenders = searchTenders;
  //TODO: replace this using the $state provider (Ahmed Saleh, 12/4/2019)
  $scope.back = function back() {
    window.history.back();
  };

  $scope.showSearchForm = function () {
    $('#show-search-form').addClass('hidden');
    $('#hide-search-form').removeClass('hidden');
  };
  $scope.hideSearchForm = function () {
    $('#show-search-form').removeClass('hidden');
    $('#hide-search-form').addClass('hidden');
  };

  /**
   * typeahead for supper search
   * @param val
   * @returns {*}
   */
  function searchSuppliers(val) {
    if (!!val && val.length > 2) {
      $scope.supplierSearching = true;

      var moduleParam = {
        module: 'suppliers'
      };

      var additionalParams = {
        'criteria[0][basic_info|descr]': val,
        'criteria[0][basic_info|status][0]': 'active',
        'criteria[0][basic_info|status][1]': 'blacklisted',
        'criteria[0][basic_info|status][2]': 'inactive',
        'criteria[0][basic_info|status][3]': 'active_pending',
        criteria_operator: 'and',
        offset: 10
      };

      if (!!$rootScope.isSourcingSupplierEnabled) {
        additionalParams['criteria[0][basic_info|is_p2o_supplier]'] = true;
      }

      return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: additionalParams
      }).then(function (response) {
        return response.data.content.data.map(function (item) {
          return item;
        });
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      });

    }
  }

   /**
   * typeahead for tender search
   * @param val
   * @returns {*}
   */
  function searchTenders(val) {
    if (!!val && val.length > 2) {
      $scope.tenderSearching = true;

      var moduleParam = {
        module: 'tender'
      };

      var additionalParams = {
        'criteria[0][code]': val,
        'criteria[0][detail|name]': val,
        'criteria[0][$operator]': 'or',
        'criteria[1][detail|status]': 'APPROVED',
        'criteria[1][$operator]': 'and',
        criteria_operator: 'and',
        offset: 10
      };

      return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: additionalParams
      }).then(function (response) {
        return response.data.content.data.map(function (item) {
          return item;
        });
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      });

    }
  }
}

advancedSearchCtrl.$inject = ['$rootScope', '$state', '$scope', '$http', 'pathConstants', '$filter', 'globalFunc'];

angular.module('metabuyer')
.controller('advancedSearchCtrl', advancedSearchCtrl);
