'use strict';

angular.module('metabuyer.services.paymentTerms', ['ngResource'])
  .factory('paymentTermsList',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.paymentTerms.list,
      {
      },
      {
        'get': { method: 'GET' }
      }
    );
  })
.factory('paymentTermsMasterDataList',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.paymentTerms.masterList,
      {
      },
      {
        'get': { method: 'GET' },
        'post': {method: 'POST'}
      }
    );
  })
  .factory('singlePaymentTerm',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.paymentTerms.single,
      {
        id: '@id'
      },
      {
        'get': { method: 'GET' },
        'put': {method: 'PUT'},
        'delete': {method: 'DELETE'}
      }
    );
  })
  .factory('searchPaymentTerms',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.paymentTerms.search,
      {
        query: '@query'
      },
      {
        'get': { method: 'GET' }
      }
    );
  });
