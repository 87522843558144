'use strict';

function priceComparisonConfirmationModalCtrl($scope, $uibModalInstance, data, globalFunc, toastr, $state, submitPriceComparison, HighlightId) {

  $scope.data = data;
  $scope.new_data = [];
  $scope.submitPcObject = submitPcObject;
  $scope.submitAction = submitAction;
  $scope.close = close;
  $scope.pcPayload = {};
  $scope.quotationCheck = quotationCheck;
  $scope.checkForeignCurrency = checkForeignCurrency;

  $scope.total_amount = 0;
  $scope.total_tax = 0;
  $scope.total_amount_Inclusive_tax = 0;

  var new_data_id = [];

  _.forEach(data.pr_info.items, function (pr) {
    _.forEach($scope.data.item_comparisons, function (pc) {
      _.forEach(pc.items, function (item) {

        if(item.is_selected === true && pr.item_code === item.original_item.item_code) {
          if (!new_data_id.includes(item.item_id)) {
            item.insurance.total_amount = parseFloat(item.insurance.total_amount);
            item.transportation.total_amount = parseFloat(item.transportation.total_amount);
            item.freight.total_amount = parseFloat(item.freight.total_amount);
            item.withholding_tax.total_amount = parseFloat(item.withholding_tax.total_amount);
            item.bahan_bakar_tax.total_amount = parseFloat(item.bahan_bakar_tax.total_amount);
            item.miscellaneous.total_amount = parseFloat(item.miscellaneous.total_amount);

            item.insurance.total_amount_pr_currency = parseFloat(item.insurance.total_amount_pr_currency);
            item.transportation.total_amount_pr_currency = parseFloat(item.transportation.total_amount_pr_currency);
            item.freight.total_amount_pr_currency = parseFloat(item.freight.total_amount_pr_currency);
            item.withholding_tax.total_amount_pr_currency = parseFloat(item.withholding_tax.total_amount_pr_currency);
            item.bahan_bakar_tax.total_amount_pr_currency = parseFloat(item.bahan_bakar_tax.total_amount_pr_currency);
            item.miscellaneous.total_amount_pr_currency = parseFloat(item.miscellaneous.total_amount_pr_currency);

            if (checkForeignCurrency(pc)) {
              $scope.total_amount += item.amount_pr_currency - item.discount_pr_currency + (
                (item.insurance.total_amount_pr_currency) + 
                (item.transportation.total_amount_pr_currency) + 
                (item.freight.total_amount_pr_currency) + 
                (item.withholding_tax.total_amount_pr_currency) + 
                (item.bahan_bakar_tax.total_amount_pr_currency) + 
                (item.miscellaneous.total_amount_pr_currency)
              );

              $scope.total_tax += item.tax_amount_pr_currency + (
                (item.insurance.tax_amount_pr_currency) + 
                (item.transportation.tax_amount_pr_currency) + 
                (item.freight.tax_amount_pr_currency) + 
                (item.withholding_tax.tax_amount_pr_currency) + 
                (item.bahan_bakar_tax.tax_amount_pr_currency) + 
                (item.miscellaneous.tax_amount_pr_currency)
              );
              $scope.total_amount_Inclusive_tax += item.total_amount_with_other_charges_pr_currency;
            }
            else {
              $scope.total_amount += item.amount - item.discount_amount + (
                (item.insurance.total_amount) + 
                (item.transportation.total_amount) + 
                (item.freight.total_amount) + 
                (item.withholding_tax.total_amount) + 
                (item.bahan_bakar_tax.total_amount) + 
                (item.miscellaneous.total_amount)
              );
              $scope.total_tax += item.tax_amount + (
                (item.insurance.tax_amount) + 
                (item.transportation.tax_amount) + 
                (item.freight.tax_amount) + 
                (item.withholding_tax.tax_amount) + 
                (item.bahan_bakar_tax.tax_amount) + 
                (item.miscellaneous.tax_amount)
              );
              $scope.total_amount_Inclusive_tax += item.total_amount_with_other_charges;
            }
            item.pc_detail = pc;

            new_data_id.push(item.item_id);
            $scope.new_data.push(item);
          }
        }
      });
    });
  });

  function checkForeignCurrency(comparison) {
    return comparison.price_comparison_currency.code !== comparison.requisition_currency.code;
  }

  function close() {
    $uibModalInstance.close();
  }

  function submitAction() {
    var data = preparePriceComparison();
    data.remark = $scope.pcPayload.remark ? $scope.pcPayload.remark : '';
    
    return submitPriceComparison.post({
    id: $scope.priceComparison._id
    },
    data,
    function (resource) {
      toastr.success('Successfully submitted price comparison.')
      $state.go('main.user.priceComparison.manage', {status: 'pending'});
      HighlightId.setId($scope.priceComparison._id);
    },
    function (error) {
      globalFunc.objectErrorMessage(error);
    }).$promise;
  }

  function submitPcObject() {
    var data = preparePriceComparison();
    return submitPriceComparison.post({
    id: $scope.priceComparison._id
    },
    data,
    function (resource) {
      toastr.success('Successfully submitted price comparison.')
      $state.go('main.user.priceComparison.manage', {status: 'pending'});
      HighlightId.setId($scope.priceComparison._id);
    },
    function (error) {
      globalFunc.objectErrorMessage(error);
    }).$promise;
  }

  function preparePriceComparison() {
    var data = _.cloneDeep($scope.priceComparison);
    _.forEach(data.item_comparisons, function(e){
      if (!_.isEmpty(e.other_details)) {
        e.other_details.validity_to =
        !!e.other_details.validity_to ? globalFunc.convertDateToTimestamp(e.other_details.validity_to) : null;
        e.other_details.validity_from =
          !!e.other_details.validity_from ? globalFunc.convertDateToTimestamp(e.other_details.validity_from) : null;
        delete e.other_details['validity_from_is_open'];
        delete e.other_details['validity_to_is_open'];
      }
    });

    data.pr_info.title = $scope.pr.title;
    return data;
  }

  function quotationCheck() {
    var total = 0;
    var max = 0;

    if($scope.data.currency.code === 'IDR') {
      max = 15000000;
    }
    else {
      max = 5000;
    }

    _.forEach($scope.data.item_comparisons, function(comparison, comparisonIndex) {

      _.forEach(comparison.items, function(item, itemIndex) {
          if (item.is_selected) {
            if (comparison.price_comparison_currency.code == 'IDR') {
              total += item.amount;
            }
            else {
              total += item.amount_pr_currency;
            }
          }
      });
    });

    if ($scope.data.item_comparisons.length === 1 && (total <=  max || total >= max)) {
      return false;
    }

    if ($scope.data.item_comparisons.length === 2 && total >=  max) {
      return false;
    }

    return true;
  }
}

priceComparisonConfirmationModalCtrl.$inject = [
  '$scope', '$uibModalInstance', 'data', 'globalFunc', 'toastr', '$state', 'submitPriceComparison', 'HighlightId'
];

angular
  .module('metabuyer')
  .controller('priceComparisonConfirmationModalCtrl', priceComparisonConfirmationModalCtrl);
