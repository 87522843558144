'use strict';

function translationModalCtrl($scope, $uibModalInstance, translationModalData, translationModalTitle) {
    $scope.translationModalData = translationModalData;
    $scope.translationModalTitle = translationModalTitle;
    $scope.closeModal = closeModal;
    $scope.updateTranslation = updateTranslation;

    function closeModal() {
        $uibModalInstance.close();
    }

    function updateTranslation() {
      $scope.$emit('updateTranslation');
    }
}

translationModalCtrl.$inject = ['$scope', '$uibModalInstance','translationModalData', 'translationModalTitle'];

angular
  .module('metabuyer')
  .controller('translationModalCtrl', translationModalCtrl);
