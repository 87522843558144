/**
 * @name prWarningBlockV2
 * Consolidated clean directive that supports PR V2 errors type
 *
 * @requires $scope
 * @copyright Sunway Metacloud &copy; 2020
 */

'use strict';

angular
  .module('metabuyer')
  .directive('prWarningBlockV2', function ($rootScope) {
    return {
      restrict: 'E',
      templateUrl: function () {
        return $rootScope.isMobileMode ? 'components/prWarningBlockV2/prWarningBlockV2Template-mobile.html' :
          'components/prWarningBlockV2/prWarningBlockV2Template.html';
      },
      scope: { },
      controller: function ($scope, prV2Function) {
        $scope.showPRWarnings = showPRWarnings;

        function showPRWarnings() {
          $scope.prWarnings = [];
          $scope.prWarnings = prV2Function.getPRWarnings();
          return ($scope.prWarnings.length > 0);
        }
      }
    }
  });
