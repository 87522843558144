/**
 * @name metabuyerClockChecker
 *
 * @description
 *
 * This service manages the clock functions needed
 *
 *
 * @requires toastr
 *
 *
 *
 *
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Metacloud Sdn. Bhd. &copy; 2016
 */

'use strict';

function metabuyerClockChecker (toastr) {

  var clockChecker = {};


  return clockChecker;
}
metabuyerClockChecker.$inject = ['toastr'];

angular
  .module('metabuyer')
  .factory('metabuyerClockChecker',metabuyerClockChecker);
