'use strict';

function bifrostDetailsCtrl($scope, $uibModalInstance, data, globalFunc,
                            $http, pathConstants, toastr) {

  $scope.details = data.details;
  $scope.close = close;
  $scope.hasError = hasError;
  $scope.downloadFile = downloadFile;

  function downloadFile(name) {
    // Todo: make this dynamic to support other file
    var url = pathConstants.apiUrls.bifrost.storageDownloadFile + name + '?type=ABMC';

    $http({
      method: 'GET',
      url: url
    }).then(function (response) {
      if (!!response && !!response.data) {
        if (!!response.data.error) {
          toastr.error('Failed to download file. Error: ' + response.data.error);
          return;
        }

        var link = document.createElement('a');
        document.body.appendChild(link);
        var blob = new Blob([response.data]);
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.click();
        toastr.success('File ' + name + ' is downloaded.');
      } else {
        toastr.error('Failed to download file.');
      }
    }, function () {
      toastr.error('Failed to download file.');
    });
  }

  function hasError(status) {
    return !status;
  }

  function close(){
    $uibModalInstance.close();
  }

  function checkStatus(data) {
    _.forEach(data, function(detail) {
      _.forEach(detail, function(item) {
        if (item.success !== undefined) {
          return;
        }
        _.forEach(item, function(i) {
          if (i.success !== undefined) {
            return;
          }
          if (!!i.errors && i.errors !== "") {
            item.success = false;
          } else {
            item.success = true;
          }
        });
      });
    });
  }

  checkStatus($scope.details);
}

bifrostDetailsCtrl.$inject = ['$scope', '$uibModalInstance', 'data', 'globalFunc',
  '$http', 'pathConstants', 'toastr'];

angular.module('metabuyer')
  .controller('bifrostDetailsCtrl', bifrostDetailsCtrl);
