'use strict';

angular.module('metabuyer')
  .controller('supplierUserDetailCtrl', supplierUserDetailCtrl);

supplierUserDetailCtrl.$inject = ['$rootScope', '$scope', '$state', 'HighlightId', 'timezones', 'currencies', 'toastr', 'supplierUserService', 'user', 'supplierUserActionService', 'userFunctions', 'Approvals', 'globalFunc', 'approvalSupplier', 'supplierUserCommentsService',
                                  '$translate'];

function supplierUserDetailCtrl($rootScope, $scope, $state, HighlightId, timezones, currencies, toastr, supplierUserService, user, supplierUserActionService, userFunctions, Approvals, globalFunc, approvalSupplier, supplierUserCommentsService,
                                $translate) {
  $scope.update = update;
  $scope.toggleEditMode = toggleEditMode;
  $scope.performAction = performAction;
  $scope.getUserStatus = getUserStatus;
  $scope.approve = approve;
  $scope.canApprove = canApprove;
  $scope.reject = reject;
  $scope.canReject = canReject;
  $scope.withdraw = withdraw;
  $scope.canWithdraw = canWithdraw;
  $scope.showWarningMessages = showWarningMessages;
  $scope.back = back;

  $scope.commentService = supplierUserCommentsService;
  $scope.timezones = timezones;
  $scope.currencies = currencies;
  $scope.saveAs = false;
  $scope.user = user;
  $scope.isEdit = false;
  $scope.approvals = [];
  $scope.isUserApproval = false;
  $scope.warningMessages = [];
  $scope.returnStatus = 'all';
  $scope.statusUpdatePayload = {};
  $scope.status = userFunctions.convertUserStatus(user.status, true) || '';
  $scope.isAdmin = false;

  function update() {
    $scope.saveAs = true;
    if (validateUser()) {
      var payload = _.clone($scope.user);
      payload['previous_state'] = $scope.clonedUser;

      delete payload.isExistingEmail;
      delete payload.isValidPhone;

      supplierUserService.put(
        {id: payload._id},
        payload,
        function (resource) {
          $translate("messages.supplierUserSuccessfullyCreated").then(function (trans) {
            toastr.success(trans);
          });
          $state.go('main.user.supplier.manageSupplierUser', {status: 'pending'});
          HighlightId.setId($scope.user.sourcing_supplier_id);
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      )
    } else {
      toastr.error('Please complete all required fields before proceeding');
    }
  }

  function getUserStatus(status) {
    var status = userFunctions.convertUserStatus(status, true) || '';

    return status.toUpperCase()
  }

  function validateUser() {
    $scope.saveAs = true;

    if (!$scope.user.display_name || !$scope.user.display_name.length) {
      return false;
    }

    if (!$scope.user.email || !$scope.user.email.length) {
      return false;
    }

    if ($scope.user.isExistingEmail) {
      return false;
    }

    if (!$scope.user.isValidPhone) {
      return false;
    }

    if (!$scope.user.currency || !$scope.user.currency.length) {
      return false;
    }

    if (!$scope.user.config || !$scope.user.config.TZ.length) {
      return false;
    }

    return true;
  }

  function toggleEditMode() {
    $scope.isEdit = !$scope.isEdit;
    setDisable();

    if (!$scope.isEdit) {
      $scope.user = _.cloneDeep($scope.clonedUser);
      $scope.user.isValidPhone = true;
    }
  }

  function performAction(action) {
    var payload = _.clone($scope.user);
    payload['status_reason'] = $scope.statusUpdatePayload.status_reason;

    delete payload.isExistingEmail;
    delete payload.isValidPhone;

    supplierUserActionService.put(
      {action: action},
      payload,
      function (resource) {
        // TODO: temporary do like this
        if (action === 'deactivate') {
          $translate("messages.supplierUserSuccessfullyDeactivate").then(function (trans) {
            toastr.success(trans);
          });
        } else {
          toastr.success('Supplier User successfully ' + action);
        }
        $state.go('main.user.supplier.manageSupplierUser', {status: 'pending'});
        HighlightId.setId($scope.user.sourcing_supplier_id);
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    ).$promise;
  }

  function setDisable() {
    if (!!$scope.isEdit) {
      $scope.disableConfig = {
        supplier: true,
        email: true,
        display_name: false,
        phone: false,
        currency: false,
        timezone: false,
        supplier_user_delegate: false,
      }
    } else {
      $scope.disableConfig = {
        supplier: true,
        email: true,
        display_name: true,
        phone: true,
        currency: true,
        timezone: true,
        supplier_user_delegate: true,
      }
    }
  }

  function loadApprovalFlow(approvalId) {
    if (!$scope.clonedUser || !$scope.clonedUser.approval_id) {
      $scope.approvals = [];
    }

    Approvals.get(
      {
        id: approvalId
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.approvals = resource.content.data;
        } else {
          $scope.approvals = [];
        }
      },
      function () {
        toastr.error('Error loading Approval Flow');
        $scope.approvals = [];
      });
  }

  function approve() {
    var statusReason = $scope.statusUpdatePayload.status_reason;
    return approvalSupplier.post(
      {
        approval_id: $scope.clonedUser.approval_id,
        status: 'approve'
      },
      {
        comment: statusReason,
      },
      function () {
        toastr.success('Supplier user approved.');
        $state.go('main.user.supplier.manageSupplierUser', {status: 'active'});
        HighlightId.setId($scope.user.sourcing_supplier_id);
      }, function () {
        toastr.error('Supplier user cannot be approved.');
      }
    ).$promise;
  }

  function canApprove() {
    var isApprover = globalFunc.findInArray($scope.approvals.waiting_on, '_id', $rootScope.currentUser._id);
    var checkStatus = getUserStatus($scope.user.status) === 'PENDING';
    return (isApprover && checkStatus && $scope.isUserApproval);
  }

  function reject() {
    var statusReason = $scope.statusUpdatePayload.status_reason;
    return approvalSupplier.post(
      {
        approval_id: $scope.clonedUser.approval_id,
        status: 'reject'
      },
      {
        comment: statusReason,
      },
      function () {
        toastr.success('Supplier user rejeted.');
        $state.go('main.user.supplier.manageSupplierUser', {status: 'rejected'});
        HighlightId.setId($scope.user.sourcing_supplier_id);
      }, function () {
        toastr.error('Supplier user cannot be rejected.');
      }
    ).$promise;
  }

  function canReject() {
    var isApprover = globalFunc.findInArray($scope.approvals.waiting_on, '_id', $rootScope.currentUser._id);
    var checkStatus = getUserStatus($scope.user.status) === 'PENDING';
    return (isApprover && checkStatus && $scope.isUserApproval);
  }

  function withdraw() {
    var statusReason = $scope.statusUpdatePayload.status_reason;
    var userStatus = !!$scope.user.from_status ? getUserStatus($scope.user.from_status) : 'withdrawn';
    $scope.returnStatus = userStatus === 'DRAFT' || userStatus === 'REJECTED' ? 'withdrawn' : userStatus.toLowerCase();
    return approvalSupplier.post(
      {
        approval_id: $scope.clonedUser.approval_id,
        status: 'withdraw'
      },
      {
        comment: statusReason,
      },
      function () {
        $translate("messages.supplierUserWithdrawn").then(function (trans) {
          toastr.success(trans);
        });
        $state.go('main.user.supplier.manageSupplierUser', {status: $scope.returnStatus});
        HighlightId.setId($scope.user.sourcing_supplier_id);
      }, function () {
        toastr.error('Supplier user cannot be withdrawn.');
      }
    ).$promise;
  }

  function canWithdraw() {
    var isCreator = !_.isEmpty($scope.approvals) && ($scope.approvals.created_by._id === $rootScope.currentUser._id);
    var checkStatus = getUserStatus($scope.user.status) === 'PENDING';
    return (isCreator && checkStatus && $scope.isUserApproval);
  }

  function showWarningMessages() {
    return !_.isEmpty($scope.warningMessages);
  }

  function back() {
    $state.go('main.user.supplier.manageSupplierUser', {status: 'all'});
  }

  function isSourcingAdmin() {
    var isAdmin = globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Admin');
    var isSourcingBiddingAdmin = !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Sourcing and Bidding Admin');
    $scope.isAdmin = isAdmin || isSourcingBiddingAdmin;
  }

  function initialize() {
    $scope.supplier = $scope.user.supplier;
    $scope.clonedUser = _.cloneDeep($scope.user);
    setDisable();
    isSourcingAdmin();

    if (getUserStatus(user.status) === 'PENDING') {
      if (!!$scope.clonedUser && !!$scope.clonedUser.approval_id) {
        $scope.isUserApproval = true;
        loadApprovalFlow($scope.clonedUser.approval_id);
      } else if (!!$scope.clonedUser.supplier && !!$scope.clonedUser.supplier.approval_id) {
        $scope.isUserApproval = false;
        $scope.warningMessages = "";
        $translate(["messages.approvalFlowSupplierFullyApprove"]).then(function (trans) {
          $scope.warningMessages = [trans['messages.approvalFlowSupplierFullyApprove']]
        });
        loadApprovalFlow($scope.clonedUser.supplier.approval_id);
      }
    }
  }

  initialize();
}
