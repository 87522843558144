'use strict';
/**
 * @name exportsCtrl
 * @desc Controller for exports main Controller
 */
function exportsCtrl ($scope) {

  $scope.$emit('pageTitleChanged', 'Exports');

  $scope.tabData = [
    {
      heading: 'Exports Log',
      route: 'main.user.exports.log'
    }
  ];

  $scope.$emit('actionsChanged', []);
}
exportsCtrl.$inject = ['$scope'];

angular
  .module('metabuyer')
  .controller('exportsCtrl', exportsCtrl);
