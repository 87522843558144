'use strict';

angular.module('metabuyer')
  .directive('supplierUserListing', function() {
    return {
      templateUrl: 'components/supplierComponents/supplierUserListing/supplierUserListing.html',
      controller: supplierUserListingCtrl,
      scope: {
        supplier: '<',
      }
    }
  }
)

supplierUserListingCtrl.$inject = ['$scope', '$translatePartialLoader'];

function supplierUserListingCtrl($scope, $translatePartialLoader) {
  $translatePartialLoader.addPart('suppliersManagement/supplierUser/lang');
  $scope.supplier_users = $scope.supplier.supplier_users;
  if(!_.isEmpty($scope.supplier.supplier_users)) {
    $scope.supplier_users = $scope.supplier.supplier_users;
    var supplierUsers = [];
    var statusPending = 0;
    var statusActive = 1;
    var statusDraft = 5;
    _.forEach ($scope.supplier.supplier_users, function(supplierUser, index){
      if (supplierUser.status === statusPending ||
          supplierUser.status === statusActive ||
          supplierUser.status === statusDraft)
      {
        supplierUsers.push(supplierUser);
      }
    });
    $scope.supplier_users = supplierUsers;
  }
}
