'use strict';

/**
 * @name itemDetails
 *
 * @description
 * itemDetails directive
 *
 * @param details
 * if the details item is provided by the existing item object,
 * just pass the details info
 *
 * @param imgPath
 * imgPath used to display image
 *
 * @id id
 * if the item details is not provided
 * we can use item_id to pass
 * the directive will send request to get the details
 *
 */

angular
  .module('metabuyer')
  .directive('itemDetails',function($uibModal) {
    return{
      restrict: 'AE',
      scope: {
        details         : '=',
        imgPath         : '=',
        id              : '=',
        enableStarring  : '=?'
      },
      link: function($scope, element) {

        function displayPanel() {
          $uibModal.open({
            templateUrl: 'components/purchaseRequisitionComponents/itemDetails/itemDetails.html',
            controller: 'itemDetailsCtrl',
            backdrop: 'static',
            keyboard: false,
            size: 'lg',
            resolve: {
              details: function() {
                return $scope.details;
              },
              imgPath: function() {
                return $scope.imgPath;
              },
              itemId: function() {
                return $scope.id;
              },
              enableStarring: function(){
                return $scope.enableStarring;
              }
            }
          });
        }

        element.bind("click", function(){
          displayPanel();
        });
      }
    };
  });
