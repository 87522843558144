'use strict';

angular.module('metabuyer.services.export', ['ngResource'])
  /**
   * @factory exports
   * @payload
   * collection :
   * 0 for Currencies
   * 1 for Company
   * 2 for CostCenter
   * 3 for AccountCode
   * 4 for ExchangeRate
   * 5 for UOM
   * 6 for PaymentTerm
   * 7 for PaymentMethod
   * 8 for Tax
   * 9 for ExpenseTypes
   * 10 for Supplier
   * 11 for Commodity
   * 12 for Users
   * 13 for Catalogs
   * 16 for Catalog Items
   * 17 for PR
   * 18 for PO
   * 19 for Industries
   * 20 for Business Units
   */
  .factory('exports', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.export.generate,
      {

      },
      {
        'get': {method: 'GET'},
        'post': {method: 'POST'}
      }
    );
  })
  .factory('downloadExportCSV', function($resource, $filter, pathConstants){
    return $resource(
      pathConstants.apiUrls.export.download,
      {
        export_hash: '@export_hash'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('checkExportStatus', function($resource, $filter, pathConstants){
    return $resource(
      pathConstants.apiUrls.export.checkStatus,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'}
      }
    );
  });
