'use strict';

angular.module('metabuyer.services.analytics', ['ngResource'])
  .factory('topSpendingByCommodity',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.analytics.topSpendingByCommodityCode,
        {},
        {
          'get': { method: 'GET' }
        }
      );
    }
  )
  .factory('analyticsGlobalFunc', function() {
    return {
      getErrorMessages: function (response) {
        var messages = [];
        if (!!response && !!response.data.content && !!response.data.content.error) {
          var errors = JSON.parse(response.data.content.error);
          _.forEach(errors, function (error) {
            messages.push(error);
          });
        }
        return messages.join(", ");
      }
    }
  })
  .factory('topSpendingByCommodityLevel',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.analytics.topSpendingByCommodityCodeLevel,
      {},
      {
        'get': { method: 'GET' }
      }
    );
  }
  )
  .factory('totalSpendingByItemType',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.analytics.totalSpendingByItemType,
        {},
        {
          'get': { method: 'GET' }
        }
      );
    }
  )
  .factory('totalSpendingByItemTypeDrillDown',
    function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.analytics.totalSpendingByItemTypeDrillDown,
      {},
      {
        'get': { method: 'GET' }
      }
    );
  }
  )
  .factory('trendOfSpendingReport',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.analytics.trendOfSpendingReport,
      {},
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('trendOfSpendingReportDrillDown',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.analytics.trendOfSpendingReportDrillDown,
        {},
        {
          'get': { method: 'GET' }
        }
      );
    })
  .factory('topPurchaseItem',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.analytics.topPurchaseItem,
      {},
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('topPurchaseItemDrillDown',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.analytics.topPurchaseItemDrillDown,
        {},
        {
          'get': { method: 'GET' }
        }
      );
    })
  .factory('topSpendingBySupplier',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.analytics.topSpendingBySupplier,
      {},
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('topSpendingBySupplierDrillDown',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.analytics.topSpendingBySupplierDrillDown,
      {},
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('analyticsDashboard',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.analytics.dashboard,
      {},
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('analyticsMetrix',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.analytics.metrix,
      {},
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('analyticsExclusionPo',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.analytics.exclusionPo,
      {},
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('totalSpendingByExpenseType',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.analytics.totalSpendingByExpenseType,
      {},
      {
        'get': { method: 'GET' }
      }
    );
  }).factory('totalSpendingByExpenseTypeDrillDown',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.analytics.totalSpendingByExpenseTypeDrillDown,
      {},
      {
        'get': { method: 'GET' }
      }
    );
  }
);
