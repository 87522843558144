'use strict';

angular
  .module('metabuyer')
  .directive('tenderCatalog', function($uibModal) {
    return {
      restrict: 'E',
      scope: {
        initialData: '=',
        tender: '=',
        editableStatus: '='
      },
      template: '<div ng-include="templateUrlTenderCatalog"></div>',
      controller: 'tenderCatalogCtrl',
      link: function($scope) {
        $scope.templateUrlTenderCatalog = 'components/tenderComponents/catalog/tenderCatalog.html';
      }
    };
  });
