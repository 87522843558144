/**
 * @name ManageLocalStorage
 *
 * @requires metabuyerCache (metabuyer service)
 *
 * @description This is a service for removing the stored data in the local storage
 *
 *
 * @authors Ahmed Saleh
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

angular.module('metabuyer')
  .factory('ManageLocalStorage', function(metabuyerCache) {

    var manageLocalStorage = {};
    manageLocalStorage.clearLocalStorage = clearLocalStorage;

    /**
     * list of Sensitive data to be removed on all cases
     * @type {string[]}
     */
    var sensitiveKeys = ['currencies', 'expenseTypes', 'paymentMethods', 'taxes', 'uoms', 'workSpace', 'roles',
      'activeSuppliersForTagging', 'socialLogin'];

    /**
     * removing either Sensitive data or All data (including Cache), in both Cases the satellizer_token is Not removed
     * @param flag
     */
    function clearLocalStorage(flag) {

      if (flag === 'All') {

        metabuyerCache.removeAll();

        for (var key in localStorage) {
          if (key !== 'satellizer_token')
            window.localStorage.removeItem(key);
        }
      } else {
        angular.forEach(sensitiveKeys, function (key) {
          window.localStorage.removeItem(key);
        });
      }
    }

    return manageLocalStorage;
  });
