'use strict';

function SupplierWebGrnManageCtrl($scope, $state, $stateParams, $filter, $window, HighlightId,
                                 pathConstants, $resource, $rootScope, metabuyerCache, toastr, grnListing) {

  $scope.showColumn = showColumn;
  $scope.meta = grnListing.meta;

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function initialize() {
    $scope.grns = grnListing.data;
  }

  initialize();
}

SupplierWebGrnManageCtrl.$inject = ['$scope', '$state', '$stateParams', '$filter', '$window',
  'HighlightId', 'pathConstants', '$resource', '$rootScope', 'metabuyerCache', 'toastr', 'grnListing'];

angular.module('metabuyer')
  .controller('SupplierWebGrnManageCtrl', SupplierWebGrnManageCtrl);
