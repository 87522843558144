'use strict';

/**
 * A controller for Non Stock item, Cerf and ADTAF
 *
 * @param $rootScope
 * @param $scope
 * @param prV2Function
 * @param prMode
 * @param item
 * @param $uibModalInstance
 * @param toastr
 * @param lang
 * @param addCerf
 * @param addAdtaf
 * @param action
 * @param updateCerf
 * @param globalFunc
 */
function itemFormCtrl ($rootScope, $scope, prV2Function,  prMode, item, $uibModalInstance, toastr, lang, addCerf, addAdtaf, action, updateCerf, globalFunc) {
  $scope.showQuotation = false;
  $scope.previousPage = previousPage;
  var  availablePages = [];
  $scope.activePage = activePage;
  $scope.title = 'Dynamic';
  $scope.mode = prMode;
  $scope.submitNonCatalog = submitNonCatalog;
  $scope.getMainAssetNumber = getMainAssetNumber;
  $scope.close = close;
  $scope.showSaveAndNext = showSaveAndNext;
  $scope.showSave = showSave;
  $scope.clearCerfForm = clearCerfForm;
  $scope.nonCatalog = item;
  $scope.nonCatalog.item_details = item.item_details;
  $scope.cerfObject = {};
  $scope.adtafObject = {};
  $scope.preparedCerfObject = {};
  $scope.action = action;
  $scope.showBack = showBack;
  $scope.prCurrency = prV2Function.getPRData().currency;
  $scope.company = prV2Function.getPRData().company;
  $scope.expenseType = prV2Function.getPRData().expense_type;
  $scope.allow_add_budget = prV2Function.getDocumentRule('allow_add_budget');
  $scope.is_budget_mandatory = $scope.allow_add_budget === true && prV2Function.getDocumentRule('is_budget_mandatory');
  $scope.addedItem = {'item': {}};
  $scope.pages = [
    {
      id: 0,
      title: $scope.action === 'create' ? 'New Item Creation' : 'Update Item',
      hideTitle: true
    },
    {
      id: 1,
      title: 'Capital Expenditure Requisition Form (CERF)'
    },
    {
      id: 2,
      title: 'Asset Disposal/Transfer Authorization Form (ADTAF)'
    }
  ];

  /**
   * determine how to show the panels and what to send them
   */
  // var rules = prV2Function.getAllDocumentRules();

  $scope.showCerf = true;
  $scope.showAdtaf = showAdtaf;

  function initializeSearchVars() {
    $scope.assetCategorySearch = {search: ''};
    $scope.assetMainNumberSearch = {search: ''};
    $scope.assetSubNumberSearch = {search: ''};
    $scope.replacementAssetMainNumberSearch = {search: ''};
    $scope.replacementAssetSubNumberSearch = {search: ''};
  }

  function clearCerfForm() {
    var cerfUuid = $scope.cerfObject.uuid;
    $scope.cerfObject = {
      uuid: cerfUuid || undefined,
      asset_category: $scope.cerfObject.asset_category
    };

    if (!$scope.isProjectCodeMandatory && $scope.expenseType.code === 'CAPEXUNBUD')
      $scope.assetCategorySearch = {search: $scope.assetCategorySearch.search};

    $scope.assetMainNumberSearch = {search: ''};
    $scope.assetSubNumberSearch = {search: ''};
    $scope.replacementAssetMainNumberSearch = {search: ''};
    $scope.replacementAssetSubNumberSearch = {search: ''};
  }

  function goToNextPage() {
    $scope.currentPage++;
    $scope.selectedPage = $scope.pages[$scope.currentPage];
  }

  function showAdtaf() {
    return $scope.cerfObject.type_of_acquisition === 'Replacement';
  }

  function validateCerf(data) {
    if (!$scope.nonCatalog._id) {
      toastr.error('Please submit catalog first');
      return false;
    }
    if (_.isEmpty(data.asset_category) && !_.isEmpty($scope.assetCategorySearch.search)) {
      toastr.error('Asset Category is invalid.');
      $scope.assetCategorySearch.search = "";
      return false;
    }
    if (!data.asset_category) {
      toastr.error('Please select Asset Category.');
      return false;
    } else {
      if (_.isEmpty(data.asset_category.name)) {
        toastr.error('Asset Category is invalid.');
        $scope.assetCategorySearch.search = "";
        return false;
      }
    }
    if (!data.purpose_of_acquisition_1) {
      toastr.error('Please select purpose of acquisition 1.');
      return false;
    }
    if (!data.purpose_of_acquisition_2) {
      toastr.error('Please select purpose of acquisition 2.');
      return false;
    }
    if (!data.type_of_acquisition) {
      toastr.error('Please select type of acquisition.');
      return false;
    }
    if (!data.asset_category.asset_useful_life && data.asset_category.asset_useful_life !== 0) {
      toastr.error('Please enter a useful life.');
      return false;
    }
    if (!!$scope.isFinanceVerifier && !data.main_asset_number) {
      toastr.error('Please enter main asset no.');
      return false;
    }
    if (data.type_of_acquisition === 'Replacement') {
      if (!data.replacement_asset_main_number.asset_main_number) {
        toastr.error('Please select replacement asset main number.');
        return false;
      }
      if (!!$scope.isFinanceVerifier && !data.replacement_asset_sub_number.asset_sub_number) {
        toastr.error('Please select replacement asset sub number.');
        return false;
      }
      if (!$scope.isFinanceVerifier) {
        if (!_.isEmpty(data.replacement_asset_main_number) && _.isEmpty(data.replacement_asset_main_number.name)) {
          toastr.error('Replacement Asset Main Number is not valid.');
          $scope.replacementAssetMainNumberSearch.search = "";
          return false;
        }

        if (!_.isEmpty(data.replacement_asset_sub_number) && _.isEmpty(data.replacement_asset_sub_number.name)) {
          toastr.error('Replacement Asset Sub Number is not valid.');
          $scope.replacementAssetSubNumberSearch.search = "";
          return false;
        }
      }
    }
    if (data.type_of_acquisition === 'Addition to a Main Asset') {
      if (!data.asset_main_number || data.asset_main_number.asset_main_number === "") {
        toastr.error('Please select Main Asset Number.');
        return false;
      }

      if (!$scope.isFinanceVerifier) {
        if (!_.isEmpty(data.asset_main_number) && _.isEmpty(data.asset_main_number.name)) {
          toastr.error('Asset Main Number is not valid.');
          $scope.assetMainNumberSearch.search = "";
          return false;
        }
      }

      if (!!$scope.isFinanceVerifier) {
        if (!data.asset_sub_number.asset_sub_number || data.asset_sub_number.asset_sub_number === "") {
          toastr.error('Please select Asset Sub Number.');
          return false;
        }
      }
    }
    return true;
  }

  function validateAdtaf() {
    if (!$scope.nonCatalog._id) {
      toastr.error('Please submit catalog first');
      return false;
    }
    if (!$scope.adtafObject.mode_of_disposal){
      toastr.error(lang.validation.required.input + 'mode of disposal');
      return false;
    }
    if (!$scope.adtafObject.reason){
      toastr.error(lang.validation.required.input + 'reason for disposal');
      return false;
    }
    return true;
  }

  function validate(){
    if ($scope.selectedPage.id === 2 && !validateAdtaf()) {
      toastr.error('ADTAF incomplete.');
      return false;
    }

    return true;
  }

  function activePage(index){
    $scope.currentPage = index;
    $scope.selectedPage = $scope.pages[index];
  }

  function previousPage() {
    //check with pages are available
    $scope.currentPage--;
    $scope.selectedPage = $scope.pages[$scope.currentPage];
  }

  $scope.submitHelper = function () {
    //check with each pages are available
    if (!validate()) {
      return false;
    }
    if ($scope.selectedPage.id === 0) {
      if (!!$scope.nonCatalog._id){
        updateNonCatalog();
      }
      else {
        submitNonCatalog();
      }
    }
    else if ($scope.selectedPage.id === 1) {
      if (!$scope.cerfObject._id) {
        var data = prepareCerf();
        $scope.$broadcast('submittingCerf');
        if (!!validateCerf(data)) {
          submitCerf(data);
        } else {
          toastr.error("Please fill in mandatory input");
          return;
        }
      } else {
        _updateCerf()
      }
    }
    else if ($scope.selectedPage.id === 2) {
      submitAdtaf();
    }
  };

  function prepareCerf() {
    var keyInData = {};
    var res = _.cloneDeep($scope.cerfObject);
    res.company_code = $scope.company.code;

    // for key in asset data (not selected from typeahead)
    if (_.isEmpty($scope.cerfObject.asset_sub_number) || $scope.subNumberExists) {
      keyInData.asset_sub_number = $scope.assetSubNumberSearch.search;
      res.asset_sub_number = prepareAssetData(keyInData);
    }

    if (_.isEmpty($scope.cerfObject.asset_main_number) || $scope.mainNumberExists) {
      keyInData.asset_main_number = $scope.assetMainNumberSearch.search;
      res.asset_main_number = prepareAssetData(keyInData, 'main');
    }

    if (_.isEmpty($scope.cerfObject.replacement_asset_main_number) && !_.isEmpty($scope.replacementAssetMainNumberSearch.search)) {
      keyInData.asset_main_number = $scope.replacementAssetMainNumberSearch.search;
      res.replacement_asset_main_number = prepareAssetData(keyInData, 'main');
    }

    if (_.isEmpty($scope.cerfObject.replacement_asset_sub_number) && !_.isEmpty($scope.replacementAssetSubNumberSearch.search)) {
      keyInData.asset_sub_number = $scope.replacementAssetSubNumberSearch.search;
      res.replacement_asset_sub_number = prepareAssetData(keyInData);
    }

    $scope.preparedCerfObject = res;
    return res
  }

  function prepareAssetData(item, flag) {
    var res = {};

    if (flag === 'main') {
      res.asset_main_number = item.asset_main_number;
    } else {
      res.asset_sub_number = item.asset_sub_number;
    }

    res.name = item.name || '';
    res.date_of_purchase = item.capitalization_date || '';
    res.cumulative_depreciation = item.cumulative_depreciation || '';
    res.net_book_value = item.net_book_value || '';

    return res;
  }

  function submitCerf(data) {
    // update item in PR
    var pr = prV2Function.getPRData();
    var prItem = _.find(pr.pr_items, function (item) {
      return item._id === $scope.nonCatalog._id;
    });

    addCerf.post(
      {
        requisitionItemId: prItem._id
      },
      data, function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          toastr.success('Cerf created');
          prV2Function.setPRData(resource.content.data);
          goToNextPage();
          if (!showAdtaf()) {
            close();
          }
        }
      }, function (error) {
        globalFunc.objectErrorMessage(error);
        $scope.submitted = false;
      });
  }

  function _updateCerf(){
    var data = prepareCerf();
    updateCerf.put(
      {
        id: $scope.cerfObject._id
      },
      data,
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.message) {
          var pr = prV2Function.getPRData();
          var prItem = _.find(pr.pr_items, function(item){
            return item._id === $scope.nonCatalog._id;
          })

          if (!!prItem){
            prItem.cerf = $scope.cerfObject;
          }

          toastr.success(resource.content.message);
          goToNextPage();
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function prepareAdtaf() {
    $scope.adtafObject.transfer_cost = Number($scope.adtafObject.transfer_cost);
    $scope.adtafObject.transfer_quantity = Number($scope.adtafObject.transfer_quantity);
  }

  function submitAdtaf() {
    prepareAdtaf();
    $scope.adtafObject.item_id = $scope.nonCatalog._id;
    $scope.adtafObject.pr_id = prV2Function.getPRData()._id;
    addAdtaf.post(
      {
        requisitionItemId: $scope.adtafObject.item_id,
      },
      $scope.adtafObject, function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data){
          toastr.success('ADTAF created');
          prV2Function.setPRData(resource.content.data);

          close();
        }
      }, function (error) {
        $scope.submitted = false;
        globalFunc.objectErrorMessage(error);
      });
  }


  function setAvailablePages() {
    availablePages.push($scope.pages[0], $scope.pages[1]);
  }

  function submitNonCatalog(){
    $scope.$broadcast('submitNonCatalog', {action:$scope.action});
  }

  function updateNonCatalog(){
    $scope.$broadcast('updateNonCatalog', {action:$scope.action});
  }

  function getMainAssetNumber() {
    // todo ameer - get main asset number from sap. need api. pending confirmation from buni | 29 July.
    return 0;
  }

  function close(){
    $uibModalInstance.dismiss();
  }

  $scope.$on('close', function() {
    close();
  });

  $scope.$on('clearNonCatalogItem', function() {
    $scope.nonCatalog = {};
    $scope.nonCatalog.no_pricing_available = false;
    $scope.nonCatalog.foc_item = false;
  });


  $scope.$on('goToNextPage', function(event) {
    goToNextPage();
  });

  function showSaveAndNext() {
    if (prV2Function.getExpenseTypeMode() === 'OPEX') {
      return false;
    }
    if($scope.action === 'update') {
      return false;
    }
    if ($scope.selectedPage.id === 1) {
      return !!showAdtaf();
    }
    if ($scope.selectedPage.id === 2) {
      return false;
    }
    return true;
  }

  function showSave() {
    if (prV2Function.getExpenseTypeMode() === 'OPEX') {
      return true;
    }
    if($scope.action === 'update') {
      return true;
    }
    if ($scope.selectedPage.id === 1) {
      return !showAdtaf();
    }
    if ($scope.selectedPage.id === 2) {
      return true;
    }
  }

  function showBack() {
    if ($scope.selectedPage.id === 1 || $scope.selectedPage.id === 2){
      return true
    }
    return false;
  }

  function initialize() {
    initializeSearchVars();
    $scope.isFinanceVerifier = !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Finance Verifier');
    $scope.currentPage = 0;
    $scope.selectedPage = $scope.pages[0];
    $scope.isProjectCodeMandatory = $scope.company.requisition_settings.project_code_capex_mandatory ? $scope.company.requisition_settings.project_code_capex_mandatory.is_enabled : false;
    setAvailablePages();
  }

  initialize();
}

itemFormCtrl.$inject = ['$rootScope', '$scope', 'prV2Function', 'prMode', 'item', '$uibModalInstance', 'toastr', 'lang', 'addCerf', 'addAdtaf', 'action', 'updateCerf', 'globalFunc'];


angular.module('metabuyer')
  .controller('itemFormCtrl', itemFormCtrl);
