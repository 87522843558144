'use strict';

/**
 * @name SuppliersWebMgmtCtrl
 */
function SuppliersWebMgmtCtrl($scope, profile, UserPermissions, $rootScope, $stateParams, $state) {

  $scope.$emit('pageTitleChanged', 'Supplier Web Management');
  $scope.status = true;
  $scope.$broadcast('setStatus', $stateParams.status);

  $scope.tabData = [];

  var tncTab = {
    heading: 'Terms & Conditions',
    route: 'main.user.suppliersWebManagement.tnc',
    key: 'tnc',
    params: {
      cursor: null
    }
  };

  var surveyTab = {
    heading: 'Survey Form',
    route: 'main.user.suppliersWebManagement.survey',
    key: 'survey',
    params: {
      cursor: null
    }
  };

  if (!!UserPermissions.hasSupplierWebAdminRole(profile)) {
    $scope.tabData.push(tncTab);
  }

  function initialize() {
    if (!!$state.current.name) {
      if ($state.current.name.indexOf('tnc') > -1) {
        $scope.activatedTab = tncTab;
      } else if ($state.current.name.indexOf('survey') > -1) {
        $scope.activatedTab = surveyTab;
      }
    }
  }

  initialize();

}
SuppliersWebMgmtCtrl.$inject = ['$scope', 'profile', 'UserPermissions', '$rootScope', '$stateParams', '$state'];

angular
  .module('metabuyer')
  .controller('SuppliersWebMgmtCtrl', SuppliersWebMgmtCtrl);
