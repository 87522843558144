'use strict';

angular.module('metabuyer.services.roleAssignments', ['ngResource'])
  .factory('organizationRoles',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.roleAssignment.organizationRoles,
        {
          org_id: '@org_id'
        },
        {
          'get': { method: 'GET' }
        }
      );
    }
  )
  .factory('supplierWebRoles',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.roleAssignment.supplierWebRoles,
        {
          org_id: '@org_id'
        },
        {
          'get': { method: 'GET' }
        }
      );
    }
  );
