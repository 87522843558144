'use strict';

function prItemBudgetView($uibModal){
  return {
    restrict: 'E',
    templateUrl: 'components/purchaseRequisitionV2Components/itemV2List/budgetView/prItemBudgetViewTemplate.html',
    scope: {
      item: '='
    },
    controller: function($scope, prV2Function, budgets) {

      $scope.$on('PRDataUpdated', function(){
        var prItems = prV2Function.getPRData().pr_items;
        var item = _.find(prItems, function(item){
          return item._id === $scope.item._id
        });
        if (prV2Function.getPRData().status === 'draft') {
          getBudget();
        } else {
          if (item !== undefined) {
            $scope.budget = item.budget;
          }
        }
      });

      function getBudget(){
        var prItem = _.find(prV2Function.getPRData().pr_items, function(e) {
          return e._id === $scope.item._id;
        });

        budgets.get({
          id: prItem.budget._id,
          ignore_gl_account: true
        },
        function (resource) {
          if (!!resource && !!resource.content && !!resource.content.data){
            $scope.budget = resource.content.data;
            _.merge($scope.item.budget, $scope.budget );
          }
        },
        function () {

        })
      }

      function initialize(){
        if (prV2Function.getPRData().status === 'draft') {
          getBudget();
        } else {
          $scope.budget = $scope.item.budget;
        }

        $scope.currency = prV2Function.getPRData().currency;
      }

      initialize();
    }
  }
}


angular
  .module('metabuyer')
  .directive('prItemBudgetView', prItemBudgetView);
