angular
  .module('metabuyer')
  .directive('apportion', function () {
    return {
      templateUrl: 'components/budget/apportion/apportionButton.html',
      restrict: 'E',
      scope: {
        apportionType: '=',
        editingMode: '=',
        startDate: '=',
        endDate: '=',
        availableAmount: '=',
        apportionAllocation: '=',
        apportionCarryForward: '=',
        apportionRelatedValues: '=?',
        parentId: '='
      },
      controller: function ($scope, $uibModal, budgetFunctions, globalFunc) {

        $scope.openApportionDetails = openApportionDetails;

        /**
         * Opens the modal for apportion details
         */
        function openApportionDetails() {

          var modalInstance = $uibModal.open({
            templateUrl: function () {
              return $scope.editingMode === 'editable' ?
                'components/budget/apportion/apportionAllocation.editableTemplate.html' :
                'components/budget/apportion/apportionAllocation.nonEditableTemplate.html';
            },
            backdrop: 'static',
            windowClass: 'budget-apportion-details',
            keyboard: false,
            scope: $scope,
            resolve: {
              apportionType: function () {
                return $scope.apportionType;
              },
              availableAmount: function () {
                return $scope.availableAmount;
              },
              apportionAllocation: function () {
                return !!$scope.apportionAllocation ? $scope.apportionAllocation : [];
              },
              apportionCarryForward: function () {
                return $scope.apportionCarryForward;
              },
              parentId: function () {
                return $scope.parentId;
              }
            },
            controller: 'budgetApportionAllocationCtrl'
          });

          modalInstance.result.then(function (apportionData) {
            if (!!apportionData) {
              $scope.apportionAllocation = apportionData.apportion_allocation;
              $scope.apportionCarryForward = apportionData.apportion_carry_forward;
            }
          });
        }

        // Watch the apportion related values for changes and recalculate if changed
        $scope.$watch('apportionRelatedValues', function (newValue) {
          if(!!newValue) {
            getApportionAllocation();
          }
        });

        function getApportionAllocation() {
          var startDate = globalFunc.convertDateToTimestamp(_.cloneDeep($scope.startDate));
          var endDate = globalFunc.convertDateToTimestamp(_.cloneDeep($scope.endDate));
          var isRevisionApportionAllocation = false;
          // If budget has parent id, generate apportion allocation for revision
          if (!!$scope.parentId) {
            isRevisionApportionAllocation = true;
          }
          // Generate apportion allocation from backend if there is budget status
          if (!!$scope.apportionRelatedValues) {
            budgetFunctions.getApportionAllocationData(
              startDate,
              endDate,
              $scope.apportionType,
              $scope.availableAmount,
              isRevisionApportionAllocation,
              $scope.apportionAllocation
            ).then(function (resource) {
              $scope.apportionAllocation = resource;
            });
          }
        }
      }
    }
  });
