'use strict';

function budgetProjectCodeCtrl($scope, $http, $filter, pathConstants, toastr) {
  $scope.exportProjectCode = exportProjectCode;

  function exportProjectCode() {
    var params = {
      budget_id: $scope.budget._id
    };

    $http.post($filter('format')(pathConstants.apiUrls.budget.projectCodesExport, null), {
      data: params,
      ignoreLoadingBar: true
    }).then(function (response) {
      var blob = new Blob([response.data]);
      var link = document.createElement('a');
      document.body.appendChild(link);
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Project-Code-' +
        $filter('date')(new Date(), 'dd-MMM-yyyy') +'.csv';
      link.click();
      toastr.success('Project Code has been successfully exported.');
    }, function () {
      toastr.error('Failed to generate file.');
    });
  }

  function init() {
    $scope.projectCodes = $scope.originalBudgetData.project_codes || [];
    $scope.budget.project_codes = $scope.projectCodes;
  }

  init();
}

budgetProjectCodeCtrl.$inject = ['$scope', '$http', '$filter', 'pathConstants', 'toastr'];

angular
  .module('metabuyer')
  .directive('budgetProjectCode', function () {
    return {
      restrict: 'E',
      templateUrl: 'components/budgetV2/projectCode/projectCode.html',
      controller: 'budgetProjectCodeCtrl'
    }
  })
  .controller('budgetProjectCodeCtrl', budgetProjectCodeCtrl);


