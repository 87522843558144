'use strict';

function integrationConfigurationsCtrl ($scope, $state, toastr, integrationConfiguration, globalFunc) {

  $scope.submit = submit;
  $scope.confirmSubmit = confirmSubmit;
  $scope.selectIntegration = selectIntegration;
  $scope.getIntegrationConfigValue = getIntegrationConfigValue;
  $scope.integration = {};
  $scope.configField = {};
  $scope.currentValue = undefined;
  $scope.newValue = undefined;

  $scope.editableField = [
    {
      name: 'Max DO length',
      field: 'max_do_number_length'
    }
  ];

  $scope.integrations = [
    {
      name: 'MB-JDE-ESKER'
    },
    {
      name: 'MB-IFCA-ESKER'
    },
    {
      name: 'MB-JDE-JDE'
    }
  ];

  function submit() {
    var params = {
      integration: $scope.integration.selected.name,
      field: $scope.configField.selected.field,
      newValue: $scope.newValue
    };

    integrationConfiguration.put(
      {},
      params,
      function(response) {
        $scope.currentValue = response.value;
        toastr.success('Integration Configuration has been updated successfully');
      }, function(error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function confirmSubmit() {
    var error = false;
    if (!$scope.newValue) {
      toastr.error('Please fill in the new value for mapping field');
      error = true;
    }
    if ($scope.newValue === $scope.oldValue) {
      toastr.error('New value must not be same as old value');
      error = true;
    }

    if (error) {
      return;
    }

    swal({
      title: 'Confirm to update\n' + $scope.integration.selected.name + '\n' + $scope.configField.selected.name + '\nto\n' + $scope.newValue + ' ?',
      text: 'This action is not revertable and will be recorded.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Confirm',
      closeOnConfirm: true
    }, function (isConfirm) {
      if (isConfirm) {
        submit();
      }
    });
  }

  function selectIntegration() {
    $scope.configField.selected = undefined;
    $scope.currentValue = undefined;
    $scope.newValue = undefined;
  }

  function getIntegrationConfigValue() {
    var params = {
      integration: $scope.integration.selected.name,
      field: $scope.configField.selected.field
    };

    integrationConfiguration.get(
      params,
      function (response) {
        $scope.currentValue = response.value;
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      });
  }

}
integrationConfigurationsCtrl.$inject = ['$scope', '$state', 'toastr', 'integrationConfiguration', 'globalFunc'];

angular
  .module('metabuyer')
  .controller('integrationConfigurationsCtrl', integrationConfigurationsCtrl);
