'use strict';

function sourcingCategoryDetailsCtrl($scope, $uibModalInstance, item,
                              sourcingCategoryMasterUpdateStatus, globalFunc, toastr, sourcingCategoryMasterResource,
                              $rootScope, $uibModal, dataList) {
    $scope.editClicked = false;
    $scope.sourcingCategory = _.cloneDeep(item);
    $scope.tab = 1;
    $scope.cancel = cancel;
    $scope.editSourcingCategory = editSourcingCategory;
    $scope.updateSourcingCategory = updateSourcingCategory;
    $scope.updateStatus = updateStatus;
    $scope.editing = false;
    $scope.openTranslationModal = openTranslationModal;

    var translationModalInstance;

    $scope.historyDependencies = {
        embeddedParams: {
            'object[0][object_id]': $scope.sourcingCategory._id,
            'object[0][object_type]': 'App\\Metabuyer\\SourcingCategory\\Models\\SourcingCategory',
            offset: 5,
            order_by: '-created_at'
        }
    };
    $scope.toggleTab = toggleTab;

    function toggleTab(tab) {
        return $scope.tab = tab;
    }

    function cancel() {
        $uibModalInstance.close();
    }

    function editSourcingCategory() {
        $scope.editClicked = true;
    }

    function validate() {
      if (!$scope.sourcingCategory.descr) {
        toastr.error('The description field is required.')
        return false;
      }

      return true;
    }

    function updateSourcingCategory(isTranslationModal) {
        if (validate()) {
          var data = preparePayload(isTranslationModal);
          sourcingCategoryMasterResource.put(
              { uuid: $scope.sourcingCategory.uuid },
              data,
              function (success) {
                  toastr.success('Sourcing Category successfully updated.');

                  if (!!isTranslationModal) {
                    translationModalInstance.close(success);
                  } else {
                    $uibModalInstance.close(success);
                  }
              },
              function (error) {
                  globalFunc.objectErrorMessage(error);
              }
          );
        }
    }

    function updateStatus() {
        sourcingCategoryMasterUpdateStatus.put(
            { uuid: $scope.sourcingCategory.uuid },
            { is_active: $scope.sourcingCategory.is_active },
            function () {
                toastr.success('Status updated successfully');
            },
            function (err) {
                //revert
                $scope.sourcingCategory.is_active = !$scope.sourcingCategory.is_active;
                globalFunc.objectErrorMessage(err);
            }
        );
    }

    function preparePayload(isTranslationModal) {
        return {
            descr: $scope.sourcingCategory.descr,
            descr_chinese: $scope.sourcingCategory.descr_chinese || null,
            is_active: $scope.sourcingCategory.is_active,
            isTranslationUpdate: isTranslationModal,
        };
    }

    function openTranslationModal() {
        translationModalInstance = $uibModal.open({
            templateUrl: 'app/masterDataManagement/translationModal/translationModal.html',
            backdrop: 'static',
            controller: 'translationModalCtrl',
            keyboard: false,
            scope: $scope,
            resolve: {
                translationModalData: function () {
                    return $scope.sourcingCategory
                },
                translationModalTitle: function () {
                    return 'Sourcing Category';
                }
            }
        });

        translationModalInstance.result.then(function (result) {
          var data = result.content.data;
          for (var i = 0; i < dataList.length; i++) {
              if (dataList[i]['uuid'] === data.uuid) {
                  dataList[i] = data;
              }
          }
        });
    }

    $scope.$on('updateTranslation', function() {
      updateSourcingCategory(true);
    })

    function init() {

    }

    init();
}

sourcingCategoryDetailsCtrl.$inject = ['$scope', '$uibModalInstance', 'item',
                                'sourcingCategoryMasterUpdateStatus', 'globalFunc', 'toastr', 'sourcingCategoryMasterResource',
                                '$rootScope', '$uibModal', 'dataList'];

angular
  .module('metabuyer')
  .controller('sourcingCategoryDetailsCtrl', sourcingCategoryDetailsCtrl);
