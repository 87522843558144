'use strict';
/**
 * @deprecated, this uses the Old APIs
 */
angular
  .module('metabuyer')
  .directive('paginationService',function() {
    return {
      restrict: 'E',
      templateUrl: 'components/pagination/paginationTemplate.html',
      scope: {
        cursor: '=',
        list: '=',
        service: '=',
        id: '=',
        status: '=',
        paramsSource: '=?params',
        offset: '=?offset'
      },
      controller: function ($scope, $location) {
        $scope.disablePaginationButtons = false;
        $scope.pageCount = 0;
        $scope.goNextPage = goNextPage;
        $scope.goPreviousPage = goPreviousPage;

        var params = {};

        function initialize(){
          //TODO: prepare the data accordingly in case of a support of pages (at this moment only Elements) (Ahmed Saleh)
          if(!!$scope.cursor) {
            $scope.currentPageCode = $scope.cursor.current;
            $scope.nextPageCode = $scope.cursor.next;
            $scope.previousPageCode = $scope.cursor.prev;

            if (!!$scope.cursor.current) {
              $scope.currentCountStart = atob($scope.cursor.current);
            } else {
              $scope.currentCountStart = 1;
            }

            if ($scope.offset === undefined) {
              $scope.offset = $scope.list.length;
            }
            if (!$scope.previousPageCode) {
              $scope.currentCountStart = 1;
            } else {
              //TODO: determine the page count in which the count should start from (Ahmed Saleh)
            }

            if ($scope.cursor.count !== $scope.list.length) {
              $scope.currentCountEnd = $scope.list.length + Number($scope.currentCountStart);
              /**
               * in case the number starts with 1, it will add an extra digit incorrectly
               */
              if(Number($scope.currentCountStart)===1) {
                $scope.currentCountEnd--;
              }
              else{
                $scope.currentCountStart ++;
              }
            } else {
              $scope.currentCountEnd = $scope.list.length;
            }
            $scope.totalCount = $scope.cursor.count;

            params = {
              status: $scope.status,
              id: $scope.id,
              offset: $scope.offset
            };

          } else {
            $scope.currentPageCode = null;
            $scope.nextPageCode = null;
            $scope.previousPageCode = null;

            $scope.currentCountStart = 0;

            $scope.offset = 20;
            $scope.currentCountStart = 0;

          }
        }

        function goNextPage() {
          var nextCursor = { cursor: $scope.nextPageCode };
          angular.extend(params, $scope.paramsSource, nextCursor);
          $scope.disablePaginationButtons = true;
          $location.search('cursor', $scope.nextPageCode);

          //$scope.service.get( params ,
          //  function (resource) {
          //    if (!!resource.content) {
          //      console.log($location);
          //      $scope.list = resource.content.data;
          //      $scope.disablePaginationButtons = false;
          //      if (!!resource.content.meta) {
          //        if (!!resource.content.meta.cursor) {
          //          $scope.pageCount++;
          //          $scope.currentCountStart = $scope.pageCount * $scope.offset + 1;
          //          $scope.currentCountEnd = $scope.currentCountStart + $scope.offset;
          //          if ($scope.currentCountEnd > $scope.totalCount) {
          //            $scope.currentCountEnd = $scope.totalCount;
          //          }
          //          $scope.currentPageCode = resource.content.meta.cursor.current;
          //          $scope.nextPageCode = resource.content.meta.cursor.next;
          //          $scope.previousPageCode = resource.content.meta.cursor.prev;
          //        }
          //      }
          //    }
          //  },
          //  function (error) {
          //    $scope.disablePaginationButtons = false;
          //    //something went wrong
          //  }
          //);
        }

        function goPreviousPage() {
          var prevCursor = { cursor: $scope.previousPageCode };
          angular.extend(params, $scope.paramsSource, prevCursor);
          $scope.disablePaginationButtons = true;
          $location.search('cursor', $scope.previousPageCode);
          //$scope.service.get( params ,
          //  function (resource) {
          //    if (!!resource.content) {
          //      $location.search('cursor', $scope.previousPageCode);
          //      $scope.list = resource.content.data;
          //      $scope.disablePaginationButtons = false;
          //      if (!!resource.content.meta) {
          //        if (!!resource.content.meta.cursor) {
          //          $scope.pageCount--;
          //          $scope.currentCountStart = $scope.pageCount * $scope.offset + 1;
          //          $scope.currentCountEnd = $scope.currentCountStart + $scope.offset - 1;
          //          if ($scope.currentCountEnd > $scope.totalCount) {
          //            $scope.currentCountEnd = $scope.totalCount;
          //          }
          //          $scope.currentPageCode = resource.content.meta.cursor.current;
          //          $scope.nextPageCode = resource.content.meta.cursor.next;
          //          $scope.previousPageCode = resource.content.meta.cursor.prev;
          //        }
          //      }
          //    }
          //  },
          //  function (error) {
          //    $scope.disablePaginationButtons = false;
          //    //something went wrong
          //  }
          //);
        }

        // Receive the broadcast for pagination
        // used for promised data
        $scope.$on('pagination', function (event, args) {
          $scope.list = args.data;
          $scope.cursor = args.meta.cursor;
          initialize();
        });

        // Run initialize function in the first load
        initialize()
      }
    };
  });
