'use strict';

angular
  .module('metabuyer')
  .directive('catalogItemDetails',function($uibModal) {
    return{
      restrict: 'AE',
      scope: {
        details : '=',
        cartMode : '=',
      },
      link: function($scope, element) {

        function displayPanel(){
          $uibModal.open({
            templateUrl: 'components/catalogShoppingMode/catalogItemDetails/catalogItemDetailsTemplate.html',
            controller: 'catalogItemDetailsCtrl',
            backdrop: 'static',
            keyboard: false,
            windowClass: 'item-details-modal',
            resolve: {
              item: function () {
                return $scope.details;
              },
              isCatalogCartMode: function () {
                return $scope.cartMode;
              }
            }
          });
        }
        element.bind("click", function(){
          displayPanel();
        });
      }
    };
  });
