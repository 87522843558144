/**
 * @name exportSearch
 *
 * @description
 * This service is for passing the search criteria when exporting after searching
 *
 * @authors Justin Cheong
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

function exportSearch () {
  var exportParams = {};
  return {
    setParams: function(params) {
      exportParams = angular.copy(params);
      exportParams.module = null;
      exportParams.offset = null;
    },
    getParams: function(){
      return exportParams;
    }
  }
}

angular
  .module('metabuyer')
  .factory('exportSearch',exportSearch);
