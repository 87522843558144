/**
 * @name dynamicHeight
 *
 * @requires $rootScope
 *
 * @description Directive to handling the height and detect the device height
 *
 * @author Deniel
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

angular.module('metabuyer')
  .directive('dynamicHeight', function ($window) {
    return {
      restrict: 'A',
      link: function ($scope, element, attrs) {

        function setHeight() {
          var windowsHeight = window.innerHeight;
          var newHeight = windowsHeight - element[0].offsetTop - 10;

          if (newHeight > 200) {
            angular.element(element).css('height', newHeight + 'px');
          } else {
            angular.element(element).css('height', 200 + 'px');
          }

          angular.element(element).css('overflow', 'auto');
        }

        $(document).ready(function () {
          angular.element($window).bind('resize', function () {
            setHeight();

            // to avoid error $digest already in progress
            _.defer(function () {
              $scope.$apply();
            });
          });

          setHeight();
        });
      }
    }
  });
