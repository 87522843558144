'use strict';

/**
 * @name budgetApportionAllocationCtrl
 * @desc Controller for displaying apportion details, editable and non editable
 *
 * @author Justin Cheong <justin.cty90@gmail.com>
 * @copyright 2017 Metacloud Sdn. Bhd.
 */
function budgetApportionAllocationCtrl($scope, apportionType, availableAmount, apportionAllocation,
                                       apportionCarryForward, $uibModalInstance, toastr, parentId) {

  $scope.saveApportion = saveApportion;
  $scope.close = close;
  $scope.getTemplateUrl = getTemplateUrl;
  $scope.getTotalApportionAllocation = getTotalApportionAllocation;
  $scope.validateDateRange = validateDateRange;

  /**
   * Run on saving changes
   */
  function saveApportion() {
    if (!validateBeforeSave()) {
      return;
    }
    var apportionData = {
      apportion_carry_forward: $scope.apportionCarryForward,
      apportion_allocation: $scope.apportionAllocation
    };
    $uibModalInstance.close(apportionData);
  }

  /**
   * Check if total allocation exceeded available allocation amount
   *
   * @returns {boolean}
   */
  function validateBeforeSave() {
    if (getTotalApportionAllocation() - availableAmount > 0) {
      toastr.error('Total allocation exceeded the available allocation amount.');
      return false;
    }
    return true;
  }

  function close() {
    var apportionData = {
      apportion_carry_forward: apportionCarryForward,
      apportion_allocation: apportionAllocation
    };
    $uibModalInstance.close(apportionData);
  }

  function getTotalApportionAllocation() {
    var totalAllocation = 0;
    _.forEach($scope.apportionAllocation, function(allocation) {
      if (!!allocation.amount) {
        totalAllocation = Number(math.format(
          math.add(
            math.bignumber(totalAllocation),
            math.bignumber(allocation.amount)
          ),
          {precision: 64}
        ));
      }
    });

    return totalAllocation;
  }

  /**
   * Validate the date range to determine if amount is able to be edited
   *
   * @param {number} endDate End date in milliseconds
   * @returns {boolean}
   */
  function validateDateRange(endDate) {
    var end = moment(endDate);
    var today = moment();
    // Return true if today more than end date
    return (today > end);
  }

  /**
   * Get template url based on editable state
   *
   * @param {string} state
   */
  function getTemplateUrl(state) {
    switch (state) {
      case 'editable':
        $scope.budgetTemplateUrl = 'components/budget/apportion/apportion.editableTemplate.html';
        break;
      case 'notEditable' :
        $scope.budgetTemplateUrl = 'components/budget/apportion/apportion.nonEditableTemplate.html';
        break;
    }
  }

  // Watches the getEditingBudgetState to determine editable/non editable template
  $scope.$watch('getEditingBudgetState()', function (newValue) {
    getTemplateUrl(newValue);
  });

  function initialize() {
    $scope.apportionAllocation = _.cloneDeep(apportionAllocation);
    $scope.apportionCarryForward = _.cloneDeep(apportionCarryForward);
    $scope.apportionType = _.cloneDeep(apportionType);
    $scope.availableAmount = _.cloneDeep(availableAmount);
    $scope.parentId = _.cloneDeep(parentId);
  }

  initialize();
}

budgetApportionAllocationCtrl.$inject = ['$scope', 'apportionType', 'availableAmount', 'apportionAllocation',
  'apportionCarryForward', '$uibModalInstance', 'toastr', 'parentId'];

angular.module('metabuyer')
  .controller('budgetApportionAllocationCtrl', budgetApportionAllocationCtrl);
