'use strict';

function supplierTaggingCtrl($scope, $rootScope, globalFunc, supplierTagViaCompany, toastr,suppliersList,
                             getTagsByCompanyCode, companiesList, $stateParams, $filter, UserPermissions,
                             supplierTagHistory)
{
  $scope.suppliersList = suppliersList.data;
  $scope.selectCompany = selectCompany;
  $scope.loadSupplierTags = loadSupplierTags;
  $scope.selectSupplier = selectSupplier;
  $scope.back = back;
  $scope.createTag = createTag;
  $scope.removeSupplier = removeSupplier;
  $scope.removeAllSelectedSupplier = removeAllSelectedSupplier;
  $scope.hasRemoveSupplierTagAccess = hasRemoveSupplierTagAccess;

  /**
   * Function to assign the scope of company code
   * @param company
   */
  function selectCompany (company) {
    refreshSuppliers();
    $scope.selectedCompanyCode = company.code;
    loadSupplierTags(company.code);
  }

  function loadSupplierTags(companyCode) {
    $scope.loading = true;
    getTagsByCompanyCode.get(
      {
        companyCode: companyCode
      },
      function (resource) {

        if(!!resource && !!resource.content && !!resource.content.data) {
          $scope.loading = false;
          $rootScope.$broadcast('refreshComments');
          var temp = [];
          var tags = resource.content.data;
          _.forEach($scope.suppliersList, function (supplier) {
            _.forEach(tags, function (tag) {
              if (supplier.basic_info.code === tag.supplier_code) {
                temp.push(supplier);
              }
            });
          });
          $scope.selectedSuppliers = globalFunc.sortObjectsArray(temp, 'descr');
          if (!!$scope.selectedSuppliers && $scope.selectedSuppliers.length > 0) {
            _.forEach($scope.selectedSuppliers, function (supplier) {
              $scope.suppliersList = globalFunc.removeValueFromArray($scope.suppliersList, '_id', supplier._id);
            })
          }
        }
      });
  }

  function selectSupplier(selectedSupplier) {
    $scope.suppliersList = globalFunc.removeValueFromArray($scope.suppliersList, '_id', selectedSupplier._id);
    $scope.selectedSuppliers.push(selectedSupplier);
    $scope.selectedSuppliers = $filter('orderBy')($scope.selectedSuppliers, 'descr');
  }

  function removeSupplier (selectedSupplier) {
    if (!hasRemoveSupplierTagAccess()) {
      return;
    }

    $scope.selectedSuppliers = globalFunc.removeValueFromArray($scope.selectedSuppliers, '_id', selectedSupplier._id);
    $scope.suppliersList.push(selectedSupplier);
    $scope.suppliersList = $filter('orderBy')($scope.suppliersList, 'descr');
  }

  function removeAllSelectedSupplier () {
    swal({
      title: 'Confirm remove all selected supplier(s)?',
      text: 'All selected supplier(s) will be deselected.',
      showCancelButton: true,
      confirmButtonColor: '#1ab394',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      closeOnConfirm: true,
      closeOnCancel: true
    }, function () {
      $scope.suppliersList = $scope.suppliersList.concat($scope.selectedSuppliers);
      $scope.selectedSuppliers = [];
      $scope.suppliersList = $filter('orderBy')($scope.suppliersList, 'descr');
      toastr.success('All selected supplier(s) was deselected.');
    });
  }

  function hasRemoveSupplierTagAccess() {
    if (!!$rootScope.currentUser &&
      !!UserPermissions.hasPermission($rootScope.currentUser, 'Supplier Tag', 'Delete')) {
      return true;
    }

    return false;
  }

  function back (){
    window.history.back();
  }

  function resetAll () {
    $scope.tag.selectedCompany = undefined;
    $scope.selectedCompanyCode = undefined;
    $scope.selectedSuppliers =[];
    refreshSuppliers();
  }

  /**
   * Function to validate input fields
   * @returns {boolean}
   */
  function validate () {
    if (!$scope.selectedCompanyCode) {
      return false;
    }
    return true;
  }

  /**
   * Function to create a supplier tag
   */
  function createTag () {

    if (validate()) {
      var supplierCodesArray = [];
      _.forEach ($scope.selectedSuppliers, function(supplier){
        supplierCodesArray.push(supplier.basic_info.code);
      });

      var submitData = {
        companyCode: $scope.selectedCompanyCode,
        supplierCodes: supplierCodesArray
      };

      supplierTagViaCompany.post(
        {},
        submitData,
        function () {
          toastr.success('Supplier tagging created successfully');
          resetAll();
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    }
    else {
      toastr.error('Please complete the selections for tagging');
    }
  }

  /**
   * Function to prepare the import parameters for supplier tagging
   */
  function prepareImportParams () {
    $scope.params = {
      'class': 'Metabuyer\\Models\\SupplierTag'
    };

    _.forEach ($scope.companiesList, function(company, index){
      var temp = 'additional_params[companyCodes]['+ index+ ']';
      $scope.params[temp] = company.code;
    });
  }

  function refreshSuppliers () {
    $scope.selectedSuppliers = [];
    $scope.suppliersList = [];
    $scope.suppliersList = _.cloneDeep(suppliersList.data);
  }

  function initialize () {
    $scope.tag = {};
    if (!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN')) {
      $scope.companiesList = $rootScope.currentUser.companies;
    }
    else{
      $scope.companiesList = companiesList.data;
    }

    if (!!$stateParams.companyCode) {
      $scope.tag.selectedCompany = globalFunc.findInArray($scope.companiesList, 'code', $stateParams.companyCode);
      selectCompany($scope.tag.selectedCompany);
    }
    else {
      $scope.selectedSuppliers = [];
      $scope.tag.selectedCompany = undefined;
      $scope.selectedCompanyCode = undefined;
    }

    $scope.suppliersList = _.cloneDeep(suppliersList.data);
    prepareImportParams();

    $scope.commentService = supplierTagHistory;
  }

  initialize();
}

supplierTaggingCtrl.$inject = [
  '$scope', '$rootScope', 'globalFunc', 'supplierTagViaCompany', 'toastr', 'suppliersList', 'getTagsByCompanyCode',
  'companiesList', '$stateParams', '$filter', 'UserPermissions', 'supplierTagHistory'
];

angular.module('metabuyer')
  .controller('supplierTaggingCtrl', supplierTaggingCtrl);
