'use strict';

/**
 * @name budgetVersionsCtrl
 * @desc The controller for budget versions
 *
 * @author Justin Cheong <justin.cty90@gmail.com>
 * @copyright 2017 Metacloud Sdn. Bhd.
 */
function budgetVersionsCtrl($rootScope, $scope, $uibModalInstance, $uibModal, budgetVersions, currentStatus) {
  $scope.versions = budgetVersions;
  $scope.checkedVersion = {};
  $scope.currentStatus = currentStatus;
  $scope.isV2Enabled = $rootScope.isV2Enabled;

  $scope.close = close;
  $scope.selectVersion = selectVersion;
  $scope.compareVersions = compareVersions;
  $scope.compareVersionsV2 = compareVersionsV2;
  $scope.getCheckedVersionLength = getCheckedVersionLength;

  /**
   * Function to select version for comparison,
   * first version selected will be compared to second version selected
   *
   * @param {object} budget   Budget version object selected
   * @param {boolean} checked Boolean check for selected version
   * @param {int} index index of the selected version
   */
  function selectVersion(budget, checked, index) {
    if (!!checked)
      $scope.checkedVersion[index] = budget;
    else {
      if (!!$scope.checkedVersion[index]) {
        delete $scope.checkedVersion[index];
      }
    }
  }

  /**
   * Check number of selected version to compare
   *
   * @returns {Number}
   */
  function getCheckedVersionLength() {
    return Object.keys($scope.checkedVersion).length;
  }

  /**
   * Function to open comparison modal, passes versions, checkedVersions and currentStatus of budget
   */
  function compareVersions() {
    // convert from object to array
    $scope.checkedVersion = _.values($scope.checkedVersion);
    // reverse the order of array
    $scope.checkedVersion.reverse();
    close();
    $uibModal.open({
      templateUrl: 'components/budget/versions/compareBudgetVersion.html',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'revision-wrapper',
      size: 'lg',
      resolve: {
        versions: function() {
          return $scope.versions
        },
        selectedVersion: function() {
          return $scope.checkedVersion;
        },
        currentStatus: function() {
          return $scope.currentStatus;
        }
      },
      controller: 'compareBudgetVersionCtrl'
    });
  }

  function compareVersionsV2() {
    // convert from object to array
    $scope.checkedVersion = _.values($scope.checkedVersion);
    // reverse the order of array
    $scope.checkedVersion.reverse();
    close();
    $uibModal.open({
      templateUrl: 'components/budget/versions/compareBudgetVersionV2.html',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'revision-wrapper',
      size: 'lg',
      resolve: {
        versions: function() {
          return $scope.versions
        },
        selectedVersion: function() {
          return $scope.checkedVersion;
        },
        currentStatus: function() {
          return $scope.currentStatus;
        }
      },
      controller: 'compareBudgetVersionV2Ctrl'
    });
  }

  function close() {
    $uibModalInstance.close();
  }
}

budgetVersionsCtrl.$inject = ['$rootScope', '$scope', '$uibModalInstance', '$uibModal', 'budgetVersions', 'currentStatus'];

angular.module('metabuyer')
  .controller('budgetVersionsCtrl', budgetVersionsCtrl);
