'use strict';

function companyContractVersionsController(
  $scope, $uibModalInstance, $uibModal, contractDetails, contractVersions, contractStatus
) {
  $scope.versions = contractVersions;
  $scope.checkedVersion = [];
  $scope.currentStatus = contractStatus;
  $scope.contract = contractDetails;

  $scope.closeModal = closeModal;
  $scope.selectVersion = selectVersion;
  $scope.compareVersions = compareVersions;

  function selectVersion(version, checked, index) {
    if (!!checked) {
      version.index = index;
      $scope.checkedVersion.push(version);
    } else {
      var unCheckedObject = version;
      var index = $scope.checkedVersion.indexOf(unCheckedObject);
      $scope.checkedVersion.splice(index, 1);
    }
  }

  function compareVersions() {
    closeModal();
    $uibModal.open({
      templateUrl: 'components/supplierComponents/contractDetails/companyContractVersions/comparison.html',
      controller: 'compareCompanyContractVersionCtrl',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'revision-wrapper',
      size: 'lg',
      resolve: {
        versions: function () {
          return $scope.versions;
        },
        selectedVersion: function () {
          return $scope.checkedVersion.sort(function (a,b) {
            // sort latest first
            return a.index - b.index;
          });
        },
        currentStatus: function () {
          return $scope.currentStatus;
        },
        contract: function () {
          return $scope.contract;
        }
      }
    });
  }

  function closeModal() {
    $uibModalInstance.close();
  }
}

companyContractVersionsController.$inject = [
  '$scope', '$uibModalInstance', '$uibModal', 'contractDetails', 'contractVersions', 'contractStatus'
];

angular.module('metabuyer')
  .controller('companyContractVersionsController', companyContractVersionsController);
