'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('main.user.emailLogs', {
        url: '/email-logs',
        controller: 'emailLogsCtrl',
        templateUrl: 'app/emailLogs/emailLogs.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            var check = false;
            //TODO: we need to enhance this section later
            if (!!profile.role_assignments && profile.role_assignments.length > 0) {
              for (var index in profile.role_assignments) {
                for (var i in profile.role_assignments[index].roles) {

                  if(profile.role_assignments[index].roles[i].name.toLowerCase() == 'admin' ||
                    profile.role_assignments[index].roles[i].name.toLowerCase() == 'tenant_super_admin'){
                    check = true;
                    break;
                  }
                }
              }
            }
            if (!check) {
              $state.go('notAuthorized');
            }
          },
          featureToggle: function (FeatureToggleService, $state, toastr) {
            FeatureToggleService.getOne('email.disableSend')
              .then(function(resource) {
                if (resource.data.is_enabled) {
                  $state.go('main.user.featureDisabled');
                }
              })
              .catch(function(e){
                console.log('Toggle could not be retrieved.');
              })
          }
        }
      })
      .state('main.user.emailLogs.emailLogsList', {
        templateUrl: 'app/emailLogs/manage/manage.html',
        url: '/manage?&{query:[a-zA-Z0-9]+}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'emailLogsManageCtrl',
        resolve: {
          dataList: function ($q, globalFunc, $stateParams, searchModule) {

            var deferred = $q.defer();
            var params = {};
            if (!!$stateParams.filter) {
              // Check status
              if ($stateParams.filter.toLowerCase() === 'status') {
                var statusCode = globalFunc.getStatusCode($stateParams.filter);
                if (statusCode === null) {
                  statusCode = 1;
                }
              }

              var temp = '';
              if ($stateParams.type === 'date') {
                temp = 'criteria[' + $stateParams.filter + '][0][]';
                params[temp] = [$stateParams.query[0], $stateParams.query[1]];
              } else if ($stateParams.type === 'double') {
                temp = 'criteria[' + $stateParams.filter + '][0][]';
                params[temp] = [$stateParams.query[0], $stateParams.query[1]];
              } else {
                temp = 'criteria[0][' + $stateParams.filter + ']';
                params[temp] = (!!statusCode || statusCode === 0) ? statusCode : $stateParams.query;
              }

              params = {
                module: 'email-logs',
                offset: 20,
                page: $stateParams.page,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              };
              params[temp] = (!!statusCode || statusCode === 0) ? statusCode : $stateParams.query;
            } else {
              params = {
                module: 'email-logs',
                offset: 20,
                'criteria[0][receiver_email]': $stateParams.query,
                'criteria[0][subject]': $stateParams.query,
                criteria_operator: !!$stateParams.query ? 'or' : null,
                page: $stateParams.page,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              };
            }

            searchModule.get(
              params,
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data)
                  deferred.resolve(resource.content);
                else
                  deferred.resolve({data: []});
              },
              function () {
                deferred.resolve([]);
              }
            );

            return deferred.promise;
          },
          $title: function() {
            return 'Email Logs';
          }
        }
      })
  }]);
