'use strict';

function supplierTagsByCompanyCtrl($scope, companiesList, $stateParams, $state, detailState, HighlightId)
{

  $scope.showColumn = showColumn;
  $scope.supplierTagDetails = supplierTagDetails;

  function showColumn (id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function supplierTagDetails (company) {
    $state.go(detailState,
      {
        companyCode: company.code,
        companyName: company.descr,
        companyId: company._id
      });
  }

  function initialize () {
    $scope.dataList = companiesList.data;
    $scope.storageKey = 'supplier-tags-by-company-selected-columns';
    $scope.meta = companiesList.meta;
    $scope.companiesService = companiesList;
    $scope.status = $stateParams.status;
    $scope.highlightId = HighlightId.getId();
    if (!!$scope.highlightId) {
      HighlightId.setId(null);
    }
  }

  initialize();
}

supplierTagsByCompanyCtrl.$inject = [
  '$scope', 'companiesList', '$stateParams', '$state', 'detailState', 'HighlightId'
];

angular.module('metabuyer')
  .controller('supplierTagsByCompanyCtrl', supplierTagsByCompanyCtrl);
