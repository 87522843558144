'use strict';

function priceComparisonDetailCtrl($scope, globalFunc, prV2Function, pathConstants, $http, priceComparison, requisition,
  approvalPreview, $timeout, purchaseRequisitionsV2CommentService, singleCommentsHistory,
  NewPRFirstLoad, profile, PRV2Attachments, $filter, Approvals, priceComparisonCommentService, $rootScope, priceComparisonFunction, $state, purchaseRequisitionsV2Services) {
  $scope.priceComparison = priceComparison;
  $scope.commentService = purchaseRequisitionsV2CommentService;
  $scope.deleteService = singleCommentsHistory;
  $scope.pr = requisition;

  $scope.attachmentParam = {
    id: $scope.priceComparison._id,
    attachmentId: null,
    maxFilesize: 10
  };

  $scope.isFirstCreate = NewPRFirstLoad.isFirstLoad;
  $scope.profile = profile;

  $scope.isPC = true;
  $scope.profile = profile;
  $scope.checkWaitingOn = checkWaitingOn;
  $scope.approvalsPreview = approvalsPreview;
  $scope.loadApprovalFlow = loadApprovalFlow;
  $scope.approvals = {};
  $scope.approvalFlowData = {};
  $scope.waiting_on_user = [];
  $scope.previewPcApproval = previewPcApproval;

  function loadApprovalFlow() {
    if ($scope.priceComparison.status !== 'DRAFT') {
      $scope.isPC = false;
      Approvals.get({
        id: $scope.priceComparison.approval_id
      }, function(resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.approvals = resource.content.data;
          $scope.approvalFlowData = resource.content.data;
          globalFunc.getWaitingOnUser(resource.content.data, $scope.waiting_on_user);
        } else
          $scope.approvals = [];
      }, function() {
        $scope.approvals = [];
      });
    } else {
      $scope.isPC = true;
    }
  }

  function checkWaitingOn() {
    var find_user = _.find($scope.waiting_on_user, function(approver) {
      return (approver._id === profile._id);
    });

    return (find_user !== undefined);
  }

  function loadScrollbar() {
    var scrollbar = document.getElementById('scrollbar');
    var approvalFlow = document.getElementById('approval-flow');
    scrollbar.style.width = approvalFlow.scrollWidth + 'px';
  }

  /**
   * Preview PC approval
   */
  function previewPcApproval() {
    // using angular.equals instead of _.isEqual because lodash has problem when compare deeper layer
    if (!angular.equals($scope.initialData, $scope.priceComparison)) {
      swal({
        title: 'Confirm save changes?',
        text: 'In order to preview the approval flow, changes made to the price comparison will be saved.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        closeOnConfirm: true
      }, function (isConfirm) {
        if (isConfirm) {
          // here have to broadcast
          $rootScope.$broadcast('saveDraftPc');
          $scope.initialData = _.cloneDeep($scope.priceComparison);
        }
      });
    } else {
      approvalsPreview();
    }
  }

  $scope.$on('refreshPcApprovalsPreview', function () {
    approvalsPreview();
  });

  /**
   * get the approval flow to display it in draft
   */
  function approvalsPreview() {

    var params = {
      company_code: $scope.pr.company.code,
      context_type: 'App\\Metabuyer\\Requisition\\Models\\Requisition',
      context_id: $scope.pr._id,
      'extra_params[grand_total]': $scope.priceComparison.grand_total
    };

    if ($scope.priceComparison.status === 'DRAFT') {
      params['extra_params[isPreview]'] = true;
    }

    approvalPreview.get(params, function(resource) {
      // send to action directive
      $scope.approvalFlowData = resource.content.data;
      $scope.approvals = resource.content.data;
      prV2Function.setLoading('updatePRField', false);
      $timeout(function() {
        loadScrollbar();
      }, 100);
    }, function(error) {
      globalFunc.objectErrorMessage('Failed to preview the approval flow');
    });

    purchaseRequisitionsV2Services.validateCapApproverLimit(
      {
        requisition_id: $scope.pr._id
      },
      function(resource){},
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }
  
  $scope.commentService = priceComparisonCommentService;

  function initialize() {
    loadApprovalFlow();
    $scope.initialData = _.cloneDeep($scope.priceComparison);
  }

  initialize();

}

priceComparisonDetailCtrl.$inject = ['$scope', 'globalFunc', 'prV2Function', 'pathConstants', '$http', 'priceComparison',
  'requisition', 'approvalPreview', '$timeout', 'purchaseRequisitionsV2CommentService', 'singleCommentsHistory', 'NewPRFirstLoad',
  'profile', 'PRV2Attachments', '$filter', 'Approvals', 'priceComparisonCommentService', '$rootScope', 'priceComparisonFunction', '$state', 'purchaseRequisitionsV2Services'];

angular
  .module('metabuyer')
  .controller('priceComparisonDetailCtrl', priceComparisonDetailCtrl);
