'use strict';

/**
 * To handle deliver note modal logic
 *
 * @author Justin Cheong <justin.cty90@gmail.com>
 * @copyright 2018 Metacloud Sdn. Bhd.
 */
angular
  .module('metabuyer')
  .directive('deliveryNotePanel', function ($uibModal) {
    return {
      restrict: 'A',
      scope: true,
      link: function (scope, element, attrs) {

        var panel = function (dn_id, selectedPo) {

          var modalInstance = $uibModal.open({
            templateUrl: 'components/deliveryNote/deliveryNoteTemplate.html',
            backdrop: 'static',
            keyboard: false,
            scope: scope,
            size: 'lg',
            resolve: {
              dn: function ($q, deliverGoodsDetail) {
                var deferred = $q.defer();
                if (!dn_id) {
                  deferred.resolve({});
                  return deferred.promise;
                }

                deliverGoodsDetail.get(
                  {
                    id: dn_id
                  },
                  function (resource) {
                    if (!!resource && !!resource.content && !!resource.content.data) {
                      deferred.resolve(resource.content.data);
                    }
                    else {
                      deferred.resolve({});
                    }
                  },
                  function (error) {
                    deferred.resolve({});
                  });

                return deferred.promise;
              },
              po: function () {
                return selectedPo;
              }
            },
            controller: function ($scope, $uibModalInstance, dn, po, getDeliveryNotePdfHash, $filter, toastr,
                                  pathConstants, $rootScope) {

              $scope.dn = dn;
              $scope.po = po;

              $scope.close = close;
              $scope.printDN = printDN;

              function close() {
                $uibModalInstance.close();
              }

              function printDN() {

                var tabWindow = window.open('', '_blank');

                getDeliveryNotePdfHash.get(
                  {
                    id: $scope.dn._id,
                    timezone: $rootScope.currentUser.config.TZ
                  }
                ).$promise.then(
                  function (resource) {
                    var newUrl = $filter('format')(pathConstants.apiUrls.po.pdfHash, {
                      pdf_hash: resource.url
                    });

                    setTimeout(function () {
                        tabWindow.location.href = newUrl;
                      },
                      1001
                    );
                  },
                  function () {
                    toastr.error('Failed to print Delivery Note.');
                  }
                );
              }
            }
          });
        };

        //loading the panel to comment
        scope.showDNInfo = function (dn_id, selectedPo) {
          panel(dn_id, selectedPo);
        };
      }
    };
  });
