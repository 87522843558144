'use strict';

angular.module('metabuyer.services.privateAccess', ['ngResource'])
  .factory('privateAccess',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.privateAccess.token,
        {

        },
        {
          'get': { method: 'GET' },
          'put': { method: 'PUT' },
          'post': { method: 'POST' },
          'activeUsers': {
            method: 'GET',
            url: pathConstants.apiUrls.privateAccess.activeUsers
          }
        }
      );
    }
  );
