'use strict';

/**
 * @name tenderVersionsCtrl
 * @desc The controller for tender versions
 *
 * @author Nazrul Wazir <nazrulhmw@sunway.com.my>
 * @copyright 2022 Metacloud Sdn. Bhd.
 */
function tenderVersionsCtrl($rootScope, $scope, $uibModalInstance, $uibModal, tenderVersions, tender, mode) {

  $scope.tenderVersions = tenderVersions;
  $scope.tender = tender;
  $scope.mode = mode;
  $scope.close = close;
  $scope.checkedVersion = {};
  $scope.getCheckedVersionLength = getCheckedVersionLength;
  $scope.selectVersion = selectVersion;
  $scope.compareVersions = compareVersions;

  function close() {
    $uibModalInstance.close();
  }

  /**
   * Function to select version for comparison,
   * first version selected will be compared to second version selected
   *
   * @param {object} tender   Tender version object selected
   * @param {boolean} checked Boolean check for selected version
   * @param {int} index index of the selected version
   */
  function selectVersion(tender, checked, index) {
    if (!!checked)
      $scope.checkedVersion[index] = tender;
    else {
      if (!!$scope.checkedVersion[index]) {
        delete $scope.checkedVersion[index];
      }
    }
  }

  /**
   * Check number of selected version to compare
   *
   * @returns {Number}
   */
  function getCheckedVersionLength() {
    return Object.keys($scope.checkedVersion).length;
  }

  function compareVersions() {
    // convert from object to array
    $scope.checkedVersion = _.values($scope.checkedVersion);

    if (mode === 'revision') {
      $scope.checkedVersion.reverse();
    }

    close();
    $uibModal.open({
      templateUrl: 'components/tenderComponents/versions/compareTenderVersion.html',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'revision-wrapper',
      size: 'lg',
      resolve: {
        tenderVersions: function() {
          return $scope.tenderVersions
        },
        tender: function() {
          return $scope.tender;
        },
        mode: function() {
          return $scope.mode;
        },
        selectedVersion: function() {
          return $scope.checkedVersion;
        },
      },
      controller: 'compareTenderVersionCtrl'
    });
  }

}

tenderVersionsCtrl.$inject = ['$rootScope', '$scope', '$uibModalInstance', '$uibModal', 'tenderVersions', 'tender', 'mode'];

angular.module('metabuyer')
  .controller('tenderVersionsCtrl', tenderVersionsCtrl);
