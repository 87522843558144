'use strict';

/**
 *
 * @param $scope
 * @param $rootScope
 * @param sourcingExpenseTypeMasterResource
 * @param $uibModalInstance
 * @param toastr
 * @param globalFunc
 */
function addSourcingExpenseTypeCtrl($scope, $rootScope, $uibModalInstance, toastr, globalFunc, sourcingExpenseTypeMasterResource, $http, $filter, pathConstants) {

  $scope.submitted = false;
  $scope.closeModal = closeModal;
  $scope.submitSourcingExpenseType = submitSourcingExpenseType;

  $scope.sourcingExpenseType = {
    is_active: true
  };

  function closeModal() {
    $uibModalInstance.close();
  }

  function preparePayload() {
    var data = {
      code: $scope.sourcingExpenseType.code,
      descr: $scope.sourcingExpenseType.descr,
      descr_chinese: $scope.sourcingExpenseType.descr_chinese || null,
      is_active: $scope.sourcingExpenseType.is_active,
    };

    return data;
  }

  function submitSourcingExpenseType() {
    $scope.submitted = true;
    if (!validate($scope.sourcingExpenseType)) {
      var data = preparePayload();
      sourcingExpenseTypeMasterResource.post(
        data,
        function (success) {
          toastr.success('Sourcing Expense Type successfully created.');
          $uibModalInstance.close(success);
          $scope.submitted = false;
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    }
  }

  function validate(sourcingExpenseTypeDetails) {
    var anyError = false;
    if (!sourcingExpenseTypeDetails.code) {
      toastr.error('Sourcing Expense Type code is required.');
      anyError = true;
    }
    if (!sourcingExpenseTypeDetails.descr) {
      toastr.error('Sourcing Expense Type description is required.');
      anyError = true;
    }
    return anyError;
  }
}



addSourcingExpenseTypeCtrl.$inject = ['$scope', '$rootScope', '$uibModalInstance', 'toastr', 'globalFunc', 'sourcingExpenseTypeMasterResource', '$http', '$filter', 'pathConstants'];
angular.module('metabuyer')
  .controller('addSourcingExpenseTypeCtrl', addSourcingExpenseTypeCtrl);
