'use strict';

function poFunction($rootScope) {

  var globalPOData = {};

  return {
    initializePOData: function (po) {
      this.clearPOData();
      this.setPOData(po);
    },
    clearPOData: function () {
      globalPOData = {};
    },
    setPOData: function (po) {
      globalPOData = po;
    },
    getPOData: function () {
      return globalPOData;
    },
    getDocumentRule: function (key) {
      var po = this.getPOData();

      if (!!po.document_rules && !!po.document_rules[key]) {
        return po.document_rules[key];
      }

      return null;
    },
    /**
     * function to calculate and find the near needed at date
     *
     * @returns {string|null|*}
     */
    findClosestNeededAtDate: function() {
      var po = this.getPOData();
      var closestDate = po.needed_at;

      _.forEach(po.items, function (item) {
        if (!!item.others) {
          _.forEach(item.others, function(other) {
            if (other.type === 'needed_at' && closestDate > other.date) {
              closestDate = other.date;
            }
          });
        }
      });

      return closestDate;
    }
  }
}

poFunction.$inject = ['$rootScope'];

angular
  .module('metabuyer')
  .factory('poFunction', poFunction);
