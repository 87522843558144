'use strict';

function addAlcCtrl($scope, $state, $rootScope, $http, $filter, pathConstants, loadCacheableData, positions, globalFunc, toastr, AlcDraft) {
  $scope.templateMode = 'new';
  $scope.searchParams = {};
  $scope.searchAlcGroup = searchAlcGroup;
  $scope.searchPosition = searchPosition;
  $scope.searchItemMaster = searchItemMaster;
  $scope.openDatepicker = openDatepicker;
  $scope.selectItem = selectItem;
  $scope.selectAlcGroup = selectAlcGroup;
  $scope.selectPosition = selectPosition;
  $scope.getApprovalPreview = getApprovalPreview;
  $scope.currencyList = [];
  $scope.profile = $rootScope.currentUser;
  $scope.datepickerOpened = {
    start_date: false,
    end_date: false,
    start_date_assignment: false,
    end_date_assignment: false
  };
  $scope.positionListing = !!positions && positions.data && !!positions.data.position ?
    _.map(positions.data.position, function (pos) {
      if(pos.status === 0) { return pos }
    }) : null;
  $scope.startDateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };

  $scope.endDateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };

  loadCacheableData.loadData({
    module: 'currencies',
    'criteria[is_active]': 1,
    offset: 0
  }, 'currencies', 'localStorage').then(function(data){
    $scope.currencyList = data;
  });

  function prepareData() {
    var data = {
      code: !!$scope.alcData.code ? $scope.alcData.code : undefined,
      name: !!$scope.alcData.name ? $scope.alcData.name : undefined,
      alc_group_code: !!$scope.alcData.alc_group ? $scope.alcData.alc_group.code : undefined,
      item_master_code: !!$scope.alcData.item_master ? $scope.alcData.item_master.code : undefined,
      currency_code: !!$scope.alcData.currency ? $scope.alcData.currency.code : undefined ,
      approval_limit_pr: !!$scope.alcData.approval_limit_pr ? $scope.alcData.approval_limit_pr : undefined,
      approval_limit_month: !!$scope.alcData.approval_limit_month ? $scope.alcData.approval_limit_month : undefined,
      start_date: !!$scope.alcData.start_date ? globalFunc.convertDateToTimestamp($scope.alcData.start_date) : undefined,
      end_date: !!$scope.alcData.end_date ? globalFunc.convertDateToTimestamp($scope.alcData.end_date) : undefined,
      remarks: !!$scope.alcData.remarks ? $scope.alcData.remarks : undefined,
      status: $scope.alcData.status,
      is_revised: !!$scope.alcData.is_revised ? $scope.alcData.is_revised : undefined
    };

    if ($scope.alcData.status === 'DRAFT') {
      data.draft_uuid = !!$scope.alcData.uuid ? $scope.alcData.uuid : undefined
    }

    if (!!$scope.alcData.position) {
      data.position = {
        code: $scope.alcData.position.code,
        name: $scope.alcData.position.name
      };
    } else {
      data.position = undefined;
    }

    return data;
  }

  function createAlcDraft(alcData) {
    AlcDraft.post(
      alcData,
      function (resource) {
        toastr.success('ALC draft successfully saved.');
        $state.go('main.user.alc.details', {id: resource.content.data.uuid});
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function approvalPreviewValidation() {
    var anyError = false;
    if (_.isEmpty($scope.alcData.alc_group) || _.isEmpty($scope.alcData.position) || _.isEmpty($scope.alcData.approval_limit_pr)) {
      anyError = true
    }
    return anyError;
  }

  /**
   * Get the approval flow to display it in draft
   */
  function getApprovalPreview() {
    if (!approvalPreviewValidation()) {
      // using angular.equals instead of _.isEqual because lodash has problem when compare deeper layer
      swal({
        title: 'Confirm create draft?',
        text: 'In order to preview the approval flow, Alc needs to be created as draft.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        closeOnConfirm: true
      }, function (isConfirm) {
        if (isConfirm) {
          var alcData = prepareData();
          createAlcDraft(alcData);
        }
      });
    } else {
      $scope.approvals = [];
      toastr.error('Approval Flow is not ready. No steps meet the specified criteria.')
    }
  }

  function selectItem(item) {
    $scope.alcData.item_master = item;
  }

  function selectAlcGroup(item) {
    $scope.alcData.alc_group = item;
  }

  function selectPosition(item) {
    $scope.alcData.position = item;
  }

  /**
   * For date picker
   *
   * @param {Object} $event
   * @param {string} which - start or end date
   */
  function openDatepicker($event, which) {
    $event.preventDefault();
    $event.stopPropagation();

    if (!!$scope.alcData.end_date) {
      $scope.startDateOptions.maxDate = $scope.alcData.end_date;
    }

    $scope.endDateOptions.minDate = new Date();

    $scope.datepickerOpened[which] = true;
  }

  function searchAlcGroup(val){
    $scope.supplierSearching = true;

    var moduleParam = {
      module: 'alc-group'
    };

    var additionalParams = {
      'criteria[0][code]': val,
      'criteria[1][name]': val,
      'criteria[1][descr]': val,
      criteria_operator: 'or',
      offset: 5
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams,
      ignoreLoadingBar: true
    }).then(function (response) {
      if (response.data.content.data.length === 0) {
        $scope.supplierSearching = false;
        return [];
      } else {
        return response.data.content.data.map(function (item) {
          $scope.supplierSearching = false;
          return item
        });
      }
    });
  }

  function searchItemMaster(val){
    $scope.supplierSearching = true;

    var moduleParam = {
      module: 'item-master'
    };

    var additionalParams = {
      'criteria[1][code]': val,
      'criteria[1][name]': val,
      'criteria[1][$operator]': 'or',
      offset: 100
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams,
      ignoreLoadingBar: true
    }).then(function (response) {
      if (response.data.content.data.length === 0) {
        $scope.supplierSearching = false;
        return [];
      } else {
        return response.data.content.data.map(function (item) {
          $scope.supplierSearching = false;
          return item
        });
      }
    });
  }

  function searchPosition(val) {
    return $http.get(pathConstants.apiUrls.approvalEngine.position.get, {
      params: {
        'sort[1][key]': 'updated_at',
        'sort[1][direction]': 'DESC',
        'filter_groups[0][filters][0][key]': 'name',
        'filter_groups[0][filters][0][value]': val,
        'filter_groups[0][filters][0][operator]': 'ct',
        'filter_groups[0][filters][1][key]': 'code',
        'filter_groups[0][filters][1][value]': val,
        'filter_groups[0][filters][1][operator]': 'ct',
        limit: 5
      }
    }).then(function (response) {
      if (response.data.content.data.length === 0) {
        $scope.supplierSearching = false;
        return [];
      } else {
        return response.data.content.data.position.map(function (item) {
          $scope.supplierSearching = false;
          return item
        });
      }
    });
  }


  function initialize() {
    $scope.alcData = {};

    // default status to null for new alc, this field is used for UI
    $scope.status = null;
  }

  initialize();

}

addAlcCtrl.$inject = [
  '$scope', '$state', '$rootScope', '$http', '$filter', 'pathConstants', 'loadCacheableData', 'positions', 'globalFunc', 'toastr', 'AlcDraft'
];

angular.module('metabuyer')
  .controller('addAlcCtrl', addAlcCtrl);

