/**
 * @name actionModal
 *
 * @description
 * Directive that loads the Action modal for Approval and handles the success, error, and redirection
 *
 *
 * @requires $uibModal
 *
 * TODO: Description for all of the params
 * @param string   title               Action modal title
 * @param $promise actionFunction      Function (service) to handle this action. This needs to be a $promise object
 * @param function actionCondition     Action function condition. Only fire `actionFunction` if this returns true
 * @param string   module              Module name. ie: 'pr', 'Supplier'
 * @param function successHandler      Success handler
 * @param function errorHandler        Error handler
 * @param string   modalBodyUrl        Action Modal body url
 * @param          commentBlock
 * @param string   commentPlaceHolder  Placeholder value for comment field
 * @param          extraParams
 * @param          nextActionAmount
 * @param          allActionsCompleted
 * @param          sendEmail
 * @param string   commentKey          Key used for comment
 * @param string   bodyMessage         Action modal body message
 * @param bool     enableAttachment    Option to enable attachment
 * @param string   attachmentReference Context id for the attachment. This is required if `enableAttachment` is true
 * @param string   commentLimit        Used to specify the limits of character count for comment boxes.
 *
 * @authors Ahmed Saleh (a.s.alsalali@gmail.com)
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

angular
  .module('metabuyer')
  .directive('actionModal', function($uibModal, preventDoubleClickingService) {
    return {
      restrict: 'A',
      scope: {
        title: '@',
        actionFunction: '&',
        nextActionId: '@?',
        actionCondition: '&?',
        module: '@',
        successHandler: '&?',
        errorHandler: '&?',
        modalBodyUrl: '@?',
        commentBlock: '@?',
        commentPlaceHolder: '@?',
        extraParams: '=?',
        nextActionAmount: '=?',
        allActionsCompleted: '=?',
        sendEmail: '=?',
        commentKey: '@?',
        bodyMessage: '@?',
        bodyContent: '=?',
        enableAttachment: '=?',
        attachmentReference: '=?',
        attachmentCompulsory: '=?',
        commentLimit: '@?'
      },
      link: function ($scope, element, attrs) {

        var panel = function () {

          $uibModal.open({
            templateUrl: 'components/actionModal/actionModalTemplate.html',
            scope: $scope,
            backdrop: 'static',
            keyboard: false,
            windowClass: 'action-modal',
            controller: 'actionModalCtrl',
            resolve: {
              successHandler: function () {
                return !!attrs.successHandler ? $scope.successHandler : undefined;
              },
              errorHandler: function () {
                return !!attrs.errorHandler ? $scope.errorHandler : undefined;
              }
            }
          });
        };

        element.bind('click', function () {
          if (preventDoubleClickingService.checkClick() && (!$scope.actionCondition || $scope.actionCondition())) {
            panel();
          }
        });
      }
    };
  });
