'use strict';

function UpdatePRInterceptor($q, $rootScope){
  var interceptor = {
    'response': function (response) {
      //PR detail will catch it for alert message
      $rootScope.$broadcast("changedField");
      return response;
    },

    'responseError': function (rejection) {
      return $q.reject(rejection);
    }
  };

  return interceptor;
}

UpdatePRInterceptor.$inject = ['$q', '$rootScope'];

angular
  .module('metabuyer')
  .factory('UpdatePRInterceptor', UpdatePRInterceptor);


