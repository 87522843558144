'use strict';

angular.module('metabuyer.services.starringServices', ['ngResource'])
  .factory('prStarring',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.purchaseRequisitions.starring,
        {
          id: '@id',
          user: '@user'
        },
        {
          'post': { method: 'POST' },
          'delete': { method: 'DELETE' }
        }
      );
    }
  )
  .factory('starringPO',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.po.starring,
        {
          id: '@id',
          user: '@user'
        },
        {
            'post': { method: 'POST' },
            'delete': { method: 'DELETE' }
        }
      );
    }
  )
  .factory('tenderStarring',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.tender.starringItem,
        {
          id: '@id',
          user: '@user'
        },
        {
          'post': { method: 'POST' },
          'delete': { method: 'DELETE' }
        }
      );
    }
  )
