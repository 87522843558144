'use strict';
angular
  .module('metabuyer')
  .directive('actionScrollFix',function($window) {
  return {
    restrict: "A",
    link: function (scope, elem, attrs) {
      var windowElem = angular.element($window);

      var is_fix = false;
      var get_fix_offset = false;

      var fix_offset = 0;

      //bind scroll event
      windowElem.bind("scroll", function () {

        if(get_fix_offset == false){
          get_fix_offset = true;
          fix_offset = elem.offset().top;
        }

        var current_offset = elem.offset().top;

        if (windowElem.scrollTop() > current_offset + 60) {

          if (is_fix == false) {
            elem.addClass("action-scroll-fix");

            is_fix = true;
          }
        } else {
          if (is_fix == true && windowElem.scrollTop() <= fix_offset) {
            elem.removeClass("action-scroll-fix");
            is_fix = false;
          }
        }
      });

      //bind resize event
      windowElem.bind("resize", function(){

        get_fix_offset = false;

        elem.removeClass("action-scroll-fix");

        var current_offset = elem.offset().top;

        if(windowElem.scrollTop() > current_offset + 60) {
          get_fix_offset = true;
          is_fix = true;
          fix_offset = current_offset;
          elem.addClass("action-scroll-fix");
        }
      });
    }
  }
});
