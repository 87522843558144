'use strict';

/**
 *
 * @param $scope
 * @param $uibModalInstance
 * @param $http
 * @param pathConstants
 * @param $filter
 * @param toastr
 * @param opexIo
 * @param companyOpexIoService
 * @param globalFunc
 */
function detailOpexIOCtrl($scope, $uibModalInstance, $http, pathConstants, $filter, toastr, opexIo, companyOpexIoService, globalFunc, $rootScope) {
  $scope.editClicked = false;
  $scope.opexIo = opexIo.data;
  $scope.tab = 1;
  $scope.cancel = cancel;
  $scope.editOpexIo = editOpexIo;
  $scope.historyDependencies = {
    embeddedParams: {
      'object[0][object_id]': $scope.opexIo._id,
      'object[0][object_type]': 'Metabuyer\\Models\\CompanyOpexIo',
      offset: 5,
      order_by: '-created_at'
    }
  };
  $scope.toggleTab = toggleTab;
  $scope.updateOpexIo = updateOpexIo;

  function toggleTab(tab) {
    return $scope.tab = tab;
  }

  function cancel() {
    $uibModalInstance.close();
  }

  function editOpexIo() {
    $scope.editClicked = true;
  }

  function updateOpexIo() {
    companyOpexIoService.put(
      {
        id: $scope.opexIo.uuid
      },
      {
        name: $scope.opexIo.name,
        is_active: $scope.opexIo.is_active
      },
      function (resource) {
        toastr.success('Company Opex Io has been updated');
        $uibModalInstance.close(resource.content.data);
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
        return false;
      }
    );
  }
}

detailOpexIOCtrl.$inject = ['$scope', '$uibModalInstance', '$http', 'pathConstants', '$filter', 'toastr', 'opexIo', 'companyOpexIoService', 'globalFunc', '$rootScope'];

angular
  .module('metabuyer')
  .controller('detailOpexIOCtrl', detailOpexIOCtrl);



