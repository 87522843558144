'use strict';

function approvalLimitManageCtrl($state, $scope, userData, $uibModal, profile, globalFunc, userApprovalLimitsBalance) {

  // if user data is not passed in, user is viewing their profile hence use profile data
  $scope.userData = !_.isEmpty(userData) ? userData : profile;
  $scope.approvalLimits = $scope.userData.approval_limits;
  $scope.filteredApprovalLimits = [];
  $scope.openAddApprovalLimitModal = openAddApprovalLimitModal;
  $scope.pageChanged = pageChanged;
  $scope.back = back;
  $scope.goToApprovalLimitTrackPage = goToApprovalLimitTrackPage;

  function pageChanged() {
    var begin = (($scope.currentPage.number - 1) * $scope.numPerPage);
    var end = begin + $scope.numPerPage;
    $scope.filteredApprovalLimits = $scope.approvalLimits.slice(begin, end);
  }

  function openAddApprovalLimitModal() {
    var approvalLimit = $uibModal.open({
      templateUrl: 'app/usersManagement/approvalLimit/add/add.html',
      controller: 'addApprovalLimitCtrl',
      backdrop: 'static',
      keyboard: false,
      scope: $scope,
      resolve: {
        userData: function () {
          return $scope.userData;
        }
      }
    });

    approvalLimit.result.then(function (result) {
      if (!_.isEmpty(result)) {
        $scope.approvalLimits.unshift(result);
        sortByUpdatedAt();
        pageChanged();  // refreshes filtered listing
      }
    });
  }

  function back() {
    window.history.back();
  }

  function goToApprovalLimitTrackPage(data) {
    // mainMenu is the route for profile's approval limit
    if ($state.current.name.indexOf('mainMenu') > -1) {
      $state.go('main.user.mainMenu.approvalLimit.detail', {
        uuid: data.approval_limit_uuid,
        approvalLimit: data
      });
    } else {
      $state.go('main.user.approvalLimit.detail', {
        uuid: data.approval_limit_uuid,
        approvalLimit: data
      });
    }
  }

  function sortByUpdatedAt() {
    $scope.approvalLimits.sort(function(a, b) {
      if ( a.updated_at > b.updated_at ){
        return -1;
      }
      if ( a.updated_at < b.updated_at ){
        return 1;
      }
      return 0;
    });
  }

  function getUserApprovalLimitsBalance() {
    userApprovalLimitsBalance.get(
      {
        user_uuid: $scope.userData.uuid
      },
      function (resource) {
        if (resource.content && resource.content.data) {
          var approvalLimitsUtilArray = resource.content.data;
          _.forEach(approvalLimitsUtilArray, function(util) {
            var limit = _.find($scope.approvalLimits, function(e) {
              return e.approval_limit_uuid === util._id;
            });

            if (limit){
              limit.balance = util.balance;
            }
          });
        }
      },
      function (err) {
        globalFunc.objectErrorMessage(err);
      }
    )
  }

  function initialize() {
    // pagination section
    $scope.numPerPage = 10;
    $scope.maxSize = 3;
    $scope.currentPage = {
      number: 1
    };

    sortByUpdatedAt();

    if (!_.isEmpty($scope.approvalLimits)) {
      $scope.filteredApprovalLimits = $scope.approvalLimits.slice(0, $scope.numPerPage);
    }

    $scope.isAdmin = globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Admin') || globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN');
    $scope.isUserAccessManager = globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'User Access Manager');

    getUserApprovalLimitsBalance();
  }

  initialize();
}

approvalLimitManageCtrl.$inject = ['$state', '$scope', 'userData', '$uibModal', 'profile', 'globalFunc', 'userApprovalLimitsBalance'];

angular
  .module('metabuyer')
  .controller('approvalLimitManageCtrl', approvalLimitManageCtrl);
