'use strict';

/**
 * To view supplier mapping list
 *
 * @author Justin Cheong <justin.cty90@gmail.com>
 * @copyright 2018 Metacloud Sdn. Bhd.
 */
angular
  .module('metabuyer')
  .directive('viewSupplierMappingList', function () {
    return {
      restrict: 'E',
      templateUrl: 'components/viewSupplierMappingList/supplierMappingButton.html',
      scope: {
        supplierCode: '='
      },
      controller: function ($scope, supplierMappingList, $uibModal) {
        $scope.showSupplierMappingList = showSupplierMappingList;
        $scope.isSupplierMappingModalOpened = false;

        function showSupplierMappingList(supplierCode) {
          if($scope.isSupplierMappingModalOpened){
            return;
          }

          $scope.isSupplierMappingModalOpened = true;
          $uibModal.open({
            templateUrl: 'components/viewSupplierMappingList/viewSupplierMappingList.html',
            backdrop: 'static',
            keyboard: false,
            size: 'lg',
            controller: 'viewSupplierMappingCtrl',
            resolve: {
              supplierCode: function () {
                return supplierCode;
              },
              supplierMapping: function ($q, supplierMappingList) {
                var deferred = $q.defer();
                supplierMappingList.get(
                  {
                    supplier_code: supplierCode
                  },
                  function (resource) {
                    deferred.resolve(resource.content || []);
                    $scope.buttonClicked = false;
                  },
                  function (error) {
                    deferred.resolve([]);
                    $scope.buttonClicked = false;
                  }
                );
                return deferred.promise;
              }
            }
          }).closed.then(function(){
            $scope.isSupplierMappingModalOpened = false;
          });
        }
      }
    };
  });
