'use strict';

/**
 * To handle deliver goods logic
 *
 * @author Justin Cheong <justin.cty90@gmail.com>
 * @copyright 2018 Metacloud Sdn. Bhd.
 */
angular.module('metabuyer')
  .controller('deliverGoodsCtrl', function ($scope, $state, toastr, pathConstants,
                                            $http, $filter, globalFunc, profile, $rootScope,
                                            HighlightId, purchaseOrderDetails, $uibModalInstance) {

    $scope.close = close;
    $scope.profile = profile;
    $scope.userBasePath = $rootScope.userImageBasePath;
    $scope.checkAllItemsDelivered = checkAllItemsDelivered;
    $scope.supplierDeliverGoods = supplierDeliverGoods;
    $scope.submitted = false;
    $scope.validShippingQuantity = validShippingQuantity;
    $scope.canBeSubmitted = canBeSubmitted;

    function canBeSubmitted(items) {
      var valid = true;
      _.forEach(items, function(item){
        if (item.shipping_quantity > item.remaining_shippable_quantity) {
          valid = false;
        }
      });
      return valid;
    }

    /**
     * Calculate item validity at line item
     *
     * @param item
     * @returns {boolean}
     */
    function validShippingQuantity (item) {
      if (item.shipping_quantity === 0) {
        return true;
      }

      if (item.shipping_quantity <= item.remaining_shippable_quantity) {
        return true;
      }
      return false;
    }

    function close() {
      $uibModalInstance.close();
    }

    function checkAllItemsDelivered() {
      if ($scope.checkAllDeliver) {
        _.forEach($scope.po.items, function (item) {
          item.shipping_quantity = Number(item.remaining_shippable_quantity);
        });
      } else {
        _.forEach($scope.po.items, function (item) {
          item.shipping_quantity = 0;
        });
      }
    }

    /**
     * Supplier deliver goods
     *
     * @param items
     * @param doFile
     * @param doNumber
     * @param personName
     * @param identificationNumber
     * @param vehicleNumber
     * @param comment
     */
    function supplierDeliverGoods(items, doFile, doNumber, personName, identificationNumber,
                                  vehicleNumber, comment) {
      $scope.submitted = true;
      var formData = new FormData();

      // Only push items with delivered quantity set
      _.forEach(items, function(item) {
        if (item.shipping_quantity !== 0) {
          formData.append('items[' + item.index + ']', item.shipping_quantity);
        }
      });

      formData.append('poId', $scope.po._id);
      if (!!doFile) {
        formData.append('doFile', doFile);
      }
      formData.append('doNumber', doNumber);
      formData.append('personName', !!personName ? personName : '');
      formData.append('identificationNumber', !!identificationNumber ? identificationNumber : '');
      formData.append('vehicleNumber', !!vehicleNumber ? vehicleNumber : '');
      formData.append('comment', !!comment ? comment : '');

      $http.post($filter('format')(pathConstants.apiUrls.po.deliverGoods), formData, {
        transformRequest: angular.identity,
        headers: {'Content-Type': undefined}
      }).then(function onSuccess(resource) {
        toastr.success(resource.data.content.message);
        $state.reload();
        $uibModalInstance.close();
      }).catch(function onError(error) {
        $scope.submitted = false;
        toastr.error(error.data.content.error);
      });
    }

    function initialize() {
      $scope.checkAllDeliver = false;
      $scope.po = purchaseOrderDetails;
      _.forEach($scope.po.items, function (item, index) {
          item.shipping_quantity = 0;
          item.index = index;
      });
    }

    initialize();
  });
