'use strict';

angular.module('metabuyer.services.reports', ['ngResource'])
  .factory('reports', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.reports.generate,
      {
        id: '@id'
      },
      {
        'get': { method: 'GET' },
        'post': { method: 'POST' }
      }
    );
  })
  .factory('reportsV2', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.reports.v2.generate,
      {
        id: '@id'
      },
      {
        'post': { method: 'POST' }
      }
    );
  })
  .factory('reportsV2Config', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.reports.v2.config,
      {},
      {
        'get': { method: 'GET' },
        'put': { method: 'PUT' }
      }
    );
  })
  .factory('downloadReport', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.reports.download,
      {
        id: '@id'
      },
      {
        'get': { method: 'GET' },
        'download': {
          method: "GET",
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.reports.download
        }
      }
    );
  })
  .factory('checkReportStatus', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.reports.checkStatus,
      {
        id: '@id'
      },
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('reportTypes', function ($rootScope, globalFunc) {
    var reportTypes = [];
    var user = $rootScope.currentUser;
    if (!$rootScope.isV2Enabled) {
      reportTypes = reportTypes.concat([
        {
          code: 'SPEND',
          descr: 'Spend',
          allowPrRange: false,
          allowItemCategories: false
        },
        {
          code: 'RECEIVING',
          descr: 'Receiving Details',
          allowPrRange: false,
          allowItemCategories: false
        },
        {
          code: 'REQUISITION_COUNTER',
          descr: 'PR / PO Count Per Company',
          allowPrRange: false,
          allowItemCategories: false
        },
        {
          code: 'REQUISITION',
          descr: 'PR Report',
          allowPrRange: false,
          allowItemCategories: false
        },
        {
          code: 'REQUISITION_DETAILS',
          descr: 'PR Details Report',
          allowPrRange: false,
          allowItemCategories: false
        },
        {
          code: 'BUDGET',
          descr: 'Budget',
          allowPrRange: false,
          allowItemCategories: false
        },
        {
          code: 'SUPPLIER_TAGGING',
          descr: 'Supplier Tags',
          allowPrRange: false,
          allowItemCategories: false
        }
      ]);
    }

    if (globalFunc.findInArray($rootScope.currentUser.roles, 'name', 'TENANT_SUPER_ADMIN')) {
      reportTypes.push({
        code: 'COMPANY_USER_COUNT',
        descr: 'Companies Users Count'
      })
    }

    // Excel Report List
    if (!!$rootScope.isV2Enabled) {
      var types = [
        {
          code: 'EXCEL_APPROVED_CAPEX_PR_DETAIL_REPORT',
          descr: 'Approved Capex PR Detail',
          allowedRoles: ['Report Manager', 'Report - CAPEX SPENDING'],
          allowPrRange: true,
          allowItemCategories: false,
          allowUnbudgeted: true

        },
        {
          code: 'EXCEL_APPROVED_CAPEX_PR_SUMMARY_REPORT_ERP_BUDGET_CODE',
          descr: 'Approved Capex PR Summary Report (By ERP Budget Code)',
          allowedRoles: ['Report Manager', 'Report - CAPEX SPENDING'],
          allowPrRange: false,
          allowItemCategories: false,
          allowUnbudgeted: true
        },
        {
          code: 'EXCEL_APPROVED_CAPEX_PR_SUMMARY_REPORT_ASSET_CATEGORY',
          descr: 'Approved Capex PR Summary Report (By Asset Category)',
          allowedRoles: ['Report Manager', 'Report - CAPEX SPENDING'],
          allowPrRange: false,
          allowItemCategories: false,
          allowUnbudgeted: false
        },
        {
          code: 'EXCEL_APPROVED_OPEX_PR_DETAIL_REPORT',
          descr: 'Approved Opex PR Detail',
          allowedRoles: ['Report Manager', 'Report - OPEX SPENDING'],
          allowPrRange: true,
          allowItemCategories: true,
          allowUnbudgeted: true
        },
        {
          code: 'EXCEL_APPROVED_OPEX_PR_SUMMARY_REPORT',
          descr: 'Approved Opex PR Summary Report (By ERP Budget Code)',
          allowedRoles: ['Report Manager', 'Report - OPEX SPENDING'],
          allowPrRange: false,
          allowItemCategories: false,
          allowUnbudgeted: true
        },
        {
          code: 'EXCEL_DISPOSAL_REPORT',
          descr: 'Disposal Detail',
          allowedRoles: ['Report Manager', 'Report - ASSET Disposal'],
          allowPrRange: false,
          allowItemCategories: false,
          allowUnbudgeted: false
        },
        {
          code: 'EXCEL_PR_DETAIL_REPORT',
          descr: 'PR Detail',
          allowedRoles: ['Report Manager', 'Report - PR'],
          allowPrRange: true,
          allowItemCategories: true,
          allowUnbudgeted: false
        },
        {
          code: 'EXCEL_PO_DETAIL_REPORT',
          descr: 'PO Detail',
          allowedRoles: ['Report Manager', 'Report - PO'],
          allowPrRange: false,
          allowItemCategories: false,
          allowUnbudgeted: false
        },
        {
          code: 'EXCEL_PURCHASE_STATUS_REPORT',
          descr: 'Purchase Status',
          allowedRoles: ['Report Manager', 'Report - PR'],
          allowPrRange: true,
          allowItemCategories: true,
          allowUnbudgeted: false
        },
        {
          code: 'EXCEL_BUDGET_V2_REPORT',
          descr: 'Budget',
          allowedRoles: ['Report Manager'],
          allowPrRange: false,
          allowItemCategories: false,
          allowUnbudgeted: false
        },
        {
          code: 'EXCEL_GWO_SUMMARY_REPORT',
          descr: 'GWO Summary',
          allowedRoles: ['Report Manager', 'Report - PO'],
          allowPrRange: false,
          allowItemCategories: false,
          allowUnbudgeted: false
        },
        // {
        //   code: 'EXCEL_AUDIT_TRAIL_REPORT',
        //   descr: 'Audit Trail Report',
        //   allowedRoles: ['Report Manager', 'Report - Procurement', 'Report - Tender'],
        //   allowPrRange: false,
        //   allowItemCategories: false
        // },
        {
          code: 'EXCEL_TENDER_LISTING_REPORT',
          descr: 'Tender Listing Report',
          allowedRoles: ['Report Manager', 'Report - Tender'],
          allowPrRange: false,
          allowItemCategories: false,
          allowUnbudgeted: false
        },
        {
          code: 'EXCEL_TENDER_SUMMARY_REPORT',
          descr: 'Tender Summary Report',
          allowedRoles: ['Report Manager', 'Report - Tender'],
          allowPrRange: false,
          allowItemCategories: false,
          allowUnbudgeted: false
        },
        {
          code: 'EXCEL_TENDER_DETAILS_REPORT',
          descr: 'Tender Details Report',
          allowedRoles: ['Report Manager', 'Report - Tender'],
          allowPrRange: false,
          allowItemCategories: false,
          allowUnbudgeted: false
        },
        {
          code: 'EXCEL_BUDGET_CONSUMPTION_REPORT',
          descr: 'Budget Consumption Report',
          allowedRoles: ['Report Manager'],
          allowPrRange: false,
          allowItemCategories: false,
          allowUnbudgeted: false
        },
        // {
        //   code: 'EXCEL_PURCHASE_LEAD_TIME_REPORT',
        //   descr: 'Purchase Lead TIme Report',
        //   allowedRoles: ['Report - Procurement'],
        //   allowPrRange: false,
        //   allowItemCategories: false
        // },
      ];

      if (globalFunc.findRoleInRoleAssignments(user.role_assignments, 'Report Manager', 1)) {
        reportTypes = reportTypes.concat(types);
      } else {
        _.forEach(types, function (type) {
          for (var i = 0; i < type.allowedRoles.length; i++) {
            if (globalFunc.findRoleInRoleAssignments(user.role_assignments, type.allowedRoles[i])) {
              reportTypes.push(type);
              break; //avoid multiple pushes
            }
          }
        });
      }
    }

    return {
      getReportTypes: function () {
        return reportTypes;
      }
    }
  });
