'use strict';

angular
  .module('metabuyer')
  .directive('poItemlistPanel', ['$rootScope','$document', '$window', '$location', 'ExchangeRate', 'toastr', 'poFunction',
    function ($rootScope, $document, $window, $location, ExchangeRate, toastr, poFunction) {
      return {
        restrict: 'E',
        template: '<div ng-include="OrdersDetailTemplate"></div>',
        controller: function ($scope, $element) {
          //<editor-fold desc="Total">

          /* Calculations:
           Amount = Quantity * Unit price
           Discount = an array of (a number or (Amount * Discount (%)))
           Tax = (Amount - Discount) * Tax (%)
           Total Amount = Amount - Discount + Tax
           */

          /* Notes:
           total_ (totalAmount) totalAmount field in the UI
           sum_ (sumDiscount) sum of objects in arrays
           grand_ (grandAmount, grandDiscount, grandTotalAmount, grandTax) fields last line in itemList
           */

          $scope.setReferenceNumber = function(item){
            var string = '';
            
            string += item.pr_reference;
            string += ' / Line ';
            string += item.pr_item_line_number;

            if (!!item.pc_number) {
              string += ' / ';
              string += item.pc_number;
            }

            return string;
          }

          var calcTotal = function() {
            $scope.grandAmount = 0;
            $scope.grandDiscount = 0;
            $scope.roundedGrandDiscount = 0;
            $scope.grandTax = 0;
            $scope.newTotalAmountBeforeTax = 0;
            $scope.newTotalAmount = 0;
            $scope.grandTotalAmount = 0;
            $scope.totalLumpSum = 0;
            $scope.roundedTotalLumpSum = 0;
            $scope.lumpsumTax = 0;
            $scope.unrounded_othercharges_grand_total = 0;
            $scope.grandtotal_without_othercharges = 0;

            var tempLumpSumTax;  
            if ($scope.po.items != null) {
              for (var i = 0; i < $scope.po.items.length; i++) {
                var currentIndexPO = $scope.po.items[i];

                //get total unrounded other charges for all line items
                $scope.unrounded_othercharges_grand_total += currentIndexPO.unrounded_othercharges_total;
                $scope.grandtotal_without_othercharges += currentIndexPO.amount_with_discount_and_tax;

                // for v2, need to recalculate amount
                if (!!$rootScope.isV2Enabled) {
                  currentIndexPO.amount = roundToTwo(currentIndexPO.unit_price * currentIndexPO.quantity);
                }

                if (_.isEmpty(currentIndexPO.backup_discount)) {
                  currentIndexPO.backup_discount = [];
                }

                // Calculate item's discount array
                if (!!currentIndexPO.discount && Number(currentIndexPO.discount !== 0) &&
                      currentIndexPO.discount !== null) {
                  currentIndexPO.sumDiscount = 0;
                  if (!!$rootScope.isV2Enabled) {
                    currentIndexPO.sumDiscount = roundToTwo(currentIndexPO.discount_amount);
                    currentIndexPO.roundedDiscountSum = currentIndexPO.sumDiscount;
                  } else {
                    calculateItemDiscount(currentIndexPO);

                    currentIndexPO.roundedDiscountSum = roundToTwo(currentIndexPO.sumDiscount);
                  }

                  $scope.grandDiscount += (currentIndexPO.roundedDiscountSum  || 0);
                }
                else{
                  currentIndexPO.sumDiscount = 0;
                }

                // Calculate item's tax
                if (!!currentIndexPO.taxes && !!currentIndexPO.taxes[0]) {
                  if (!!currentIndexPO.selectedItemTax) {
                    var tax = Number(currentIndexPO.selectedItemTax.rate) / 100;
                  } else {
                    var tax = Number(currentIndexPO.taxes[0].rate) / 100;
                  }

                  var calculatedItemTax = roundToTwo((currentIndexPO.amount - (roundToTwo(currentIndexPO.sumDiscount) || 0))*tax);

                  currentIndexPO.taxes[0].calculated = calculatedItemTax;
                }

                currentIndexPO.roundedAmount = roundToTwo(currentIndexPO.amount);

                currentIndexPO.roundedDiscountSum = 0;
                currentIndexPO.roundedDiscountSum = roundToTwo(currentIndexPO.sumDiscount);
                // Calculate item's totalAmount
                currentIndexPO.totalAmount = currentIndexPO.roundedAmount - (currentIndexPO.roundedDiscountSum || 0);

                // Calculate grandAmount, grandDiscount, grandTax & grandTotalAmount
                $scope.grandAmount += roundToTwo(currentIndexPO.amount);

                //$scope.grandDiscount is calculated when loop currentIndexPO.discount array
                var calculatedTax = !!currentIndexPO.taxes && currentIndexPO.taxes[0] ? roundToTwo(currentIndexPO.taxes[0].calculated) || 0 : 0;

                $scope.grandTax += calculatedTax;
                $scope.newTotalAmountBeforeTax += (currentIndexPO.roundedAmount - currentIndexPO.roundedDiscountSum);
                $scope.newTotalAmount += (currentIndexPO.roundedAmount - currentIndexPO.roundedDiscountSum + calculatedTax);
                $scope.grandTotalAmount += roundToTwo((currentIndexPO.roundedAmount - (currentIndexPO.roundedDiscountSum || 0) +
                  ((!!currentIndexPO.taxes && currentIndexPO.taxes[0]) ? currentIndexPO.taxes[0].calculated || 0 : 0)));
                //</editor-fold>

                //<editor-fold desc="Calculate item's freights amount, discount, tax and totalAmount">
                if (!!currentIndexPO.other_charges && !!poFunction.getDocumentRule('allow_all_other_charges')) {
                  _.forEach(currentIndexPO.other_charges, function (otherCharge) {

                    var newOtherChargesAmount;
                    if(!!otherCharge.is_percentage){
                      newOtherChargesAmount = roundToTwo((otherCharge.amount * currentIndexPO.amount)/100);
                    }
                    else{
                      newOtherChargesAmount = roundToTwo(otherCharge.amount);
                    }

                    // calculate other charges displayAmount
                    otherCharge.displayAmount = otherCharge.amount;
                    if (!!otherCharge.is_percentage) {
                      otherCharge.displayAmount = otherCharge.amount + "%";
                    }

                    // Calculate otherCharge's discount
                    var newOtherChargesDiscount;
                    if (!_.isEmpty(otherCharge.discount)) {
                      if (!!otherCharge.discount.is_percentage) {
                        otherCharge.discount.calculated = roundToTwo(Number(newOtherChargesAmount) *
                          (otherCharge.discount.amount / 100));
                        otherCharge.discount.displayAmount = otherCharge.discount.amount + "%";
                        newOtherChargesDiscount = otherCharge.discount.calculated;
                      }
                      else{
                        otherCharge.discount.calculated = roundToTwo(otherCharge.discount.amount);
                        newOtherChargesDiscount = otherCharge.discount.amount;
                      }

                      otherCharge.discount_amount = otherCharge.discount.calculated;
                    }
                    else{
                      newOtherChargesDiscount = 0;
                    }

                    var tax = 0;
                    if (!_.isEmpty(otherCharge.tax)) {
                      tax = Number(otherCharge.tax.rate) / 100
                    }

                    if(!_.isEmpty(otherCharge.tax)) {
                      var newOtherChargesTax = roundToTwo((otherCharge.amount - newOtherChargesDiscount)*tax);
                      // to cater for IDR with does not have decimal place
                      if (!currentIndexPO.currency.allow_decimal) {
                        newOtherChargesTax = math.round(newOtherChargesTax, 0);
                      }
                    }
                    else{
                      newOtherChargesTax = 0;
                    }

                    /**
                     * other charges's total amount is calculated here
                     */

                    otherCharge.totalAmountBeforeTax = roundToTwo(newOtherChargesAmount) - roundToTwo(newOtherChargesDiscount);
                    otherCharge.totalAmount = otherCharge.totalAmountBeforeTax + roundToTwo(newOtherChargesTax);

                    // Calculate grandAmount, grandDiscount, grandTax & grandTotalAmount
                    $scope.grandAmount += roundToTwo(newOtherChargesAmount);
                    $scope.grandDiscount += roundToTwo(newOtherChargesDiscount);
                    $scope.grandTax += roundToTwo(newOtherChargesTax);

                    // Delete totalAmount + tax

                    $scope.grandTotalAmount += roundToTwo(newOtherChargesAmount - newOtherChargesDiscount + newOtherChargesTax);
                    $scope.newTotalAmountBeforeTax += otherCharge.totalAmountBeforeTax;
                    $scope.newTotalAmount += otherCharge.totalAmount;
                  });
                }

                if (!!currentIndexPO.freight && !poFunction.getDocumentRule('allow_all_other_charges')) {
                  _.forEach(currentIndexPO.freight, function (freightItem) {

                    var newFreightAmount;
                    if(!!freightItem.is_percentage){
                      newFreightAmount = Number((freightItem.amount * currentIndexPO.amount)/100);
                    }
                    else{
                      newFreightAmount = Number(freightItem.amount);
                    }

                    // calculate freight displayAmount
                    freightItem.displayAmount = freightItem.amount;
                    if (!!freightItem.is_percentage) {
                      freightItem.displayAmount = freightItem.amount + "%";
                    }

                    // Calculate freightItem's discount
                    if (!_.isEmpty(freightItem.discount)) {
                      if(!!currentIndexPO.exchange_rate){
                        freightItem.discount.calculated = Number(freightItem.discount.amount) * currentIndexPO.exchange_rate;
                        if (!!freightItem.discount.is_percentage) {
                          freightItem.discount.calculated = Number(newFreightAmount) *
                            (freightItem.discount.amount / 100);
                          freightItem.discount.displayAmount = freightItem.discount.amount + "%";
                          var newFreightDiscount = freightItem.discount.calculated;
                        }
                        else{
                          freightItem.discount.calculated = freightItem.discount.amount * currentIndexPO.exchange_rate;
                          newFreightDiscount = freightItem.discount.calculated;
                        }
                      }
                      else{
                        if (!!freightItem.discount.is_percentage) {
                          freightItem.discount.calculated = Number(newFreightAmount) *
                            (freightItem.discount.amount / 100);
                          freightItem.discount.displayAmount = freightItem.discount.amount + "%";
                          newFreightDiscount = freightItem.discount.calculated;
                        }
                        else{
                          freightItem.discount.calculated = freightItem.discount.amount * currentIndexPO.exchange_rate;
                          newFreightDiscount = freightItem.discount.amount;
                        }
                      }
                    }
                    else{
                      newFreightDiscount = 0;
                    }

                    // Calculate freightItem's tax
                    if (!_.isEmpty(freightItem.tax)) {
                      freightItem.tax.calculated = Number((Number(newFreightAmount) - (
                        freightItem.discount ? freightItem.discount.calculated || 0 : 0
                        )) * (parseFloat(freightItem.tax.rate) || 0) / 100);
                    }

                    /**
                     * Freight's total amount is calculated here
                     */
                    freightItem.totalAmount = Number(newFreightAmount) - Number(newFreightDiscount);

                    if(angular.isDefined(freightItem.tax)) {
                      var newFreightTax = Number(freightItem.tax.calculated);
                      if (!!currentIndexPO.exchange_rate) {
                        newFreightTax = Number(newFreightTax * Number(currentIndexPO.exchange_rate));
                      }
                    }
                    else{
                      newFreightTax = 0;
                    }

                    // Calculate grandAmount, grandDiscount, grandTax & grandTotalAmount
                    $scope.grandAmount += Number(newFreightAmount);
                    $scope.grandDiscount += Number(newFreightDiscount);
                    $scope.grandTax += Number(newFreightTax);
                    // Delete totalAmount + tax
                    // (freightItem.tax ? freightItem.tax.calculated || 0 : 0)
                    $scope.grandTotalAmount += Number(newFreightAmount - newFreightDiscount) + newFreightTax;
                    $scope.newTotalAmountBeforeTax += Number(newFreightAmount - newFreightDiscount);
                    $scope.newTotalAmount += freightItem.totalAmount;
                  });
                }

                if (!!currentIndexPO.transportation && !poFunction.getDocumentRule('allow_all_other_charges')) {
                  _.forEach(currentIndexPO.transportation, function (transportationItem) {

                    var newTransportationAmount;
                    if(!!transportationItem.is_percentage){
                      newTransportationAmount = Number((transportationItem.amount * currentIndexPO.amount)/100);
                    }
                    else{
                      newTransportationAmount = Number(transportationItem.amount);
                    }

                    transportationItem.displayAmount = transportationItem.amount;
                    if (!!transportationItem.is_percentage) {
                      transportationItem.displayAmount = transportationItem.amount + "%";
                    }

                    // Calculate transportationItem's discount
                    if (!_.isEmpty(transportationItem.discount)) {
                      var newTransportationDiscount;
                      if(!!currentIndexPO.exchange_rate){
                        transportationItem.discount.calculated = Number(transportationItem.discount.amount) * currentIndexPO.exchange_rate;
                        if (!!transportationItem.discount.is_percentage) {
                          transportationItem.discount.calculated = Number(newTransportationAmount) *
                            (transportationItem.discount.amount / 100);
                          transportationItem.discount.displayAmount = transportationItem.discount.amount + "%";
                          newTransportationDiscount = transportationItem.discount.calculated;
                        }
                        else{
                          transportationItem.discount.calculated = transportationItem.discount.amount * currentIndexPO.exchange_rate;
                          newTransportationDiscount = transportationItem.discount.calculated;
                        }
                      }
                      else{
                        if (!!transportationItem.discount.is_percentage) {
                          transportationItem.discount.calculated = Number(newTransportationAmount) *
                            (transportationItem.discount.amount / 100);
                          transportationItem.discount.displayAmount = transportationItem.discount.amount + "%";
                          newTransportationDiscount = transportationItem.discount.calculated;
                        }
                        else{
                          transportationItem.discount.calculated = transportationItem.discount.amount * currentIndexPO.exchange_rate;
                          newTransportationDiscount = transportationItem.discount.amount;
                        }
                      }
                    }
                    else{
                      newTransportationDiscount = 0;
                    }

                    // Calculate transportationItem's tax
                    if (!_.isEmpty(transportationItem.tax)) {
                      transportationItem.tax.calculated = Number((Number(newTransportationAmount) - (
                        transportationItem.discount ? transportationItem.discount.calculated || 0 : 0
                        )) * (parseFloat(transportationItem.tax.rate) || 0) / 100);
                    }

                    /**
                     * Transportation's total amount is calculated here
                     */
                    transportationItem.totalAmount = Number(newTransportationAmount) - Number(newTransportationDiscount);

                    if(angular.isDefined(transportationItem.tax)) {
                      var newTransportationTax = Number(transportationItem.tax.calculated);
                      if (!!currentIndexPO.exchange_rate) {
                        newTransportationTax = Number(newTransportationTax * Number(currentIndexPO.exchange_rate));
                      }
                    }
                    else{
                      newTransportationTax = 0;
                    }

                    // Calculate grandAmount, grandDiscount, grandTax & grandTotalAmount
                    $scope.grandAmount += Number(newTransportationAmount);
                    $scope.grandDiscount += Number(newTransportationDiscount);
                    $scope.grandTax += Number(newTransportationTax);
                    // Delete totalAmount + tax
                    // (freightItem.tax ? freightItem.tax.calculated || 0 : 0)
                    $scope.grandTotalAmount += Number(newTransportationAmount - newTransportationDiscount) + newTransportationTax;
                    $scope.newTotalAmountBeforeTax += Number(newTransportationAmount - newTransportationDiscount);
                    $scope.newTotalAmount += transportationItem.totalAmount;
                  });
                }
                //</editor-fold>
              }

              if(!!$scope.grandDiscount){
                $scope.roundedGrandDiscount = roundToTwo($scope.grandDiscount);
              }

              // calculate the lump sum discount After the grand total
              if(!!$scope.po.lump_sum_discount && !!$scope.po.lump_sum_discount.amount) {
                if ($scope.po.lump_sum_discount.is_percentage) {
                  $scope.totalLumpSum = (Number($scope.po.lump_sum_discount.lump_sum_percentage)/100 * $scope.grandAmount);
                  $scope.roundedTotalLumpSum = math.round($scope.totalLumpSum, 2)
                } else {
                  $scope.totalLumpSum = $scope.po.lump_sum_discount.amount;
                  $scope.roundedTotalLumpSum = math.round($scope.totalLumpSum, 2)
                }

                $scope.grandTotalAmount = $scope.grandTotalAmount - $scope.totalLumpSum;

                // calculate tax in lump sum
                if(!!$scope.po.lump_sum_discount.tax){
                  tempLumpSumTax = (Number($scope.po.lump_sum_discount.tax.rate)/100 * $scope.totalLumpSum);
                  $scope.lumpsumTax = tempLumpSumTax;
                  $scope.grandTotalAmount = $scope.grandTotalAmount - tempLumpSumTax;
                }
              }
            }
          };

          var backupDiscountEntry;
          var calculateItemDiscount = function(item){
            if (!!item.discount) {
              for (var i = 0; i < item.discount.length; i++) {
                var itemDiscount = item.discount[i];
                if (!item.backup_discount[i]) {
                  item.backup_discount[i] = _.cloneDeep(item.discount[i]);
                }

                var entryIsPercentage;

                // first load, set itemDiscount.entry
                if (!itemDiscount.amount) {
                  itemDiscount.amount = 0;
                  itemDiscount.is_percentage = false;
                }

                if (!itemDiscount.entry) {
                  itemDiscount.entry = itemDiscount.amount + (!!itemDiscount.is_percentage ? '%' : '');
                  backupDiscountEntry = itemDiscount.entry;
                } else if (itemDiscount.entry == '') {
                  itemDiscount.amount = 0;
                  itemDiscount.is_percentage = false;
                } else {
                  if (itemDiscount.entry != '') {
                    entryIsPercentage = itemDiscount.entry.indexOf('%') > -1;
                    itemDiscount.is_percentage = entryIsPercentage;
                  }

                  // calculate itemDiscount.amount
                  var tempAmount;
                  if (entryIsPercentage) {
                    tempAmount = itemDiscount.entry.substring(0, itemDiscount.entry.length - 1);
                    itemDiscount.amount = _.isNaN(parseFloat(tempAmount)) ? 0 : tempAmount;
                  } else {
                    tempAmount = parseFloat(itemDiscount.entry);
                    itemDiscount.amount = _.isNaN(tempAmount)? 0 : tempAmount;
                  }
                }

                if (!!itemDiscount.is_percentage) {
                  item.sumDiscount += item.amount * itemDiscount.amount / 100;
                } else {
                  item.sumDiscount += itemDiscount.amount;
                }
              }
            }
          };

          function initialize(){
            if (!$rootScope.isMobileMode) {
              if (!!$scope.po.document_rules && !!$scope.po.document_rules.pr_version && $scope.po.document_rules.pr_version === 'v2')
                $scope.OrdersDetailTemplate = 'components/poItemList/poItemlistV2Template.html';
              else
                $scope.OrdersDetailTemplate = 'components/poItemList/poItemlistTemplate.html';
            }
            else if (!!$rootScope.isMobileMode) {
              $scope.OrdersDetailTemplate = 'components/poItemList/poItemlistTemplate-mobile.html';
            }

            $scope.expenseCategory = $scope.po.billing ? !!$scope.po.billing.expense_type ?
              $scope.po.billing.expense_type.category : '' : '';
          }

          function roundToTwo(num) {
            var num_sign = num >= 0 ? 1 : -1;
            return parseFloat((Math.round((num * Math.pow(10, 2)) + (num_sign * 0.0001)) / Math.pow(10, 2)).toFixed(2));
          }

          initialize();
          //</editor-fold>

          //<editor-fold desc="Run on init">

          $scope.$watch('po.billing.currency', function(newCurrency) {
            if (!!newCurrency && !!newCurrency.code) {
              $scope.newCurrencyCode = newCurrency.code;
              if (!_.isEmpty($scope.po.items) && !_.isEmpty($scope.po.items[0].currency) && newCurrency.code !== $scope.po.items[0].currency.code) {
                ExchangeRate.get({
                  id: $scope.po.billing.company._id,
                  source_id: $scope.po.items[0].currency._id,
                  target_id: newCurrency._id
                }, function(resource) {
                  // TODO: check !resource && !!resource.content && !!resource.content.data! && !!resource.content.data.status
                  if (!!resource && !!resource.content){
                    if(resource.content.data !== false){
                      $scope.exchangeRate = parseFloat(resource.content.data);
                      _.forEach($scope.po.items, function (item) {
                        if (!$rootScope.isV2Enabled) {
                          if (!item.original_unit_price) {
                            item.original_unit_price = item.unit_price;
                          }
                          item.unit_price = item.original_unit_price;
                        }
                      });
                    } else {
                      toastr.error(resource.content.message);
                      _.forEach($scope.po.items, function(item) {
                        if (!item.original_unit_price) {
                          item.original_unit_price = item.unit_price;
                        } else {
                          item.unit_price = item.original_unit_price;
                        }
                      });
                    }

                  }
                }, function(error) {
                  console.log(error);
                });
              } else {
                _.forEach($scope.po.items, function(item) {
                  if (_.isEmpty((item.po_consolidation_uuid))) {
                    if (!item.original_unit_price) {
                      item.original_unit_price = item.unit_price;
                    } else {
                      item.unit_price = item.original_unit_price;
                    }
                  }
                });
              }
            }
          });

          $scope.$watch('po.items', function (newValue, oldValue) {
            calcTotal();

            if (!!newValue && !!oldValue) {
              if (newValue.length > oldValue.length) {
                _.forEach($scope.po.items, function(item) {
                  if (!item.original_unit_price) {
                    item.original_unit_price = item.unit_price;
                  }
                  if (!!$scope.exchangeRate) {
                    item.unit_price = item.original_unit_price;
                  }
                })
              }
            }
          }, true);
          //</editor-fold>
        }
      };
    }
  ]);
