/**
 * @name metabuyer.services.genericSearch
 *
 * @description
 *
 * Service for adding and removing escalation
 *
 * @requires $resource
 * @requires pathConstants (Metabuyer service)
 *
 * @factory searchModule
 * @param module
 * the module selected to be searched
 *
 *
 * @author Ahmed Saleh <a.s.alsalali@gmail.com>
 * @copyright Verve Technologies Sdn. Bhd. &copy; 2016
 */

'use strict';

angular.module('metabuyer.services.genericSearch', ['ngResource'])
  .factory('searchModule',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.genericSearch.search,
        {
          module: '@module'
        },
        {
          'get': {method: 'GET', ignoreLoadingBar: true}
        }
      );
    });
