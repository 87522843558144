'use strict';
/**
 * @name logViewerManagement
 * @desc Controller for Log Viewer Main Controller
 */
function logViewerMainCtrl($scope) {

  $scope.$emit('pageTitleChanged', 'Laravel Log Viewer');

  $scope.tabData = [
    {
      heading: 'Log Viewer',
      route: 'main.user.logViewerManagement.manage',
      key: 'manage',
    }
  ];

  $scope.activatedTab = 'manage';
}
logViewerMainCtrl.$inject = ['$scope'];

angular
  .module('metabuyer')
  .controller('logViewerMainCtrl', logViewerMainCtrl);
