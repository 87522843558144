'use strict';

function categoryTypeDetailsCtrl($scope, $uibModalInstance, categoryType, $http, pathConstants, $filter,
                                   categoryTypeMasterDataList, toastr, $rootScope) {
  $scope.editClicked = false;
  $scope.categoryType = categoryType.data;
  $scope.categoryTypeMasterDataList = categoryTypeMasterDataList;
  $scope.tab = 1;
  $scope.cancel = cancel;
  $scope.editCategoryType = editCategoryType;
  $scope.historyDependencies = {
    embeddedParams: {
      'object[0][object_id]': $scope.categoryType._id,
      'object[0][object_type]': 'App\\Metabuyer\\CategoryType\\Models\\CategoryType',
      offset: 5,
      order_by: '-created_at'
    }
  };
  $scope.toggleTab = toggleTab;
  $scope.updateCategoryType = updateCategoryType;

  function toggleTab(tab) {
    return $scope.tab = tab;
  }

  function cancel() {
    $uibModalInstance.close();
  }

  function editCategoryType() {
    $scope.editClicked = true;
  }

  function updateCategoryType() {
    $scope.submitted = true;
    if (!!validate($scope.categoryType)){
      categoryTypeMasterDataList.put(
        {
          id: $scope.categoryType.uuid
        },
        {
          code: $scope.categoryType.code,
          name: $scope.categoryType.name,
          is_active: $scope.categoryType.is_active
        },
        function (resource) {
          toastr.success('Category type has been updated');
          $uibModalInstance.close(resource.content.data);
          $scope.submitted = false;
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
          return false;
        }
      );
    }
  }

  function validate(categoryTypeDetails) {
    if (!categoryTypeDetails.name) {
      toastr.error('Category type name is required.');
      return false;
    }
    return true;
  }

  function init() {
  }

  init();
}

categoryTypeDetailsCtrl.$inject = ['$scope', '$uibModalInstance', 'categoryType', '$http', 'pathConstants',
  '$filter', 'categoryTypeMasterDataList', 'toastr', '$rootScope'];

angular
  .module('metabuyer')
  .controller('categoryTypeDetailsCtrl', categoryTypeDetailsCtrl);
