'use strict';

function MinionCtrl($scope, $state) {

  $scope.tabData = [
    {
      heading: 'Dashboard',
      route: 'main.user.minion.dashboard',
      key: 'dashboard'
    },
    {
      heading: 'Config',
      route: 'main.user.minion.config',
      key: 'config'
    }
    // Add more tab here
  ];

  function initialize(){

  }

  initialize();

  $scope.$emit('pageTitleChanged', 'Minion');
}

MinionCtrl.$inject = ['$scope', '$state'];

angular.module('metabuyer')
  .controller('MinionCtrl', MinionCtrl);
