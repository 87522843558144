'use strict';

function poConsolidationItems() {
  return {
    restrict: 'E',
    templateUrl: 'components/poConsolidation/items/poConsolidationItemsTemplate.html',
    controller: 'poConsolidationItemsCtrl',
    scope: {
      items: '=',
      header: '='
    }
  }
}


function poConsolidationItemsCtrl($scope, loadCacheableData, $uibModal, pathConstants, toleranceLimit, lang, toastr) {
  $scope.imageBasePath = pathConstants.apiUrls.image.pr;
  $scope.selectAllItems = selectAllItems;
  $scope.unSelectAllItems = unSelectAllItems;
  $scope.unChecked = unChecked;
  $scope.itemMainCheckboxClicked = itemMainCheckboxClicked;
  $scope.addOthers = addOthers;
  $scope.removeOthers = removeOthers;
  $scope.checkOthersValue = checkOthersValue;
  $scope.duplicateItem = duplicateItem;
  $scope.itemMainCheckBox = {
    selected : false
  };
  $scope.taxData = {};
  $scope.openFocModal = openFocModal;
  $scope.status = {};
  $scope.getTotal = getTotal;
  $scope.roundToTwo = roundToTwo;
  $scope.accordionCheck = accordionCheck;
  $scope.calculateDiscount = calculateDiscount;
  $scope.calculateOtherCharges = calculateOtherCharges;
  $scope.otherChargeNotRoundedAmount = otherChargeNotRoundedAmount;
  $scope.getLineTotal = getLineTotal;
  $scope.getLineTotalWithOtherCharges = getLineTotalWithOtherCharges;
  $scope.getPercentageValue = getPercentageValue;
  $scope.getAmountValue = getAmountValue;
  $scope.maximumAmount = maximumAmount;
  $scope.openDatepicker = openDatepicker;
  $scope.removeFocItem = removeFocItem;
  $scope.updatePriceFields = updatePriceFields;
  $scope.calculateOtherChargesDiscount = calculateOtherChargesDiscount;
  $scope.calculateOtherChargeTax = calculateOtherChargeTax;
  $scope.otherChargeAmountUpdated = otherChargeAmountUpdated;
  $scope.recalculateOtherChargesGroup = recalculateOtherChargesGroup;
  $scope.preventRoundNumberToFloatBehavior = preventRoundNumberToFloatBehavior;
  $scope.itemOthersDetail = [
    {
      code: 'insurance'
    },
    {
      code: 'transportation'
    },
    {
      code: 'freight'
    },
    {
      code: 'miscellaneous'
    },
    {
      code: 'bahan_bakar_tax'
    },
    {
      code: 'withholding_tax'
    }
  ];

  $scope.startDateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };

  $scope.endDateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };

  $scope.clonedItems = {};

  function removeFocItem(index){
    $scope.items.splice(index, 1);
  }

  function preventRoundNumberToFloatBehavior(value, decimal) {
    // 80.46 / 100 = 8045.999999999999
    // Fix Math.floor(80.46 / 100) / 100 = 80.45 instead of 80.46
    return value = +(Math.floor(value + ("e+" + decimal)) + "e-" + decimal);
}

  /**
   * For date picker
   *
   * @param {Object} $event
   * @param {string} item - start or end date
   */
  function openDatepicker($event, item) {
    $event.preventDefault();
    $event.stopPropagation();
    item.datepickerOpened = true;
  }

  function initialize(){
    _.forEach($scope.items, function(e) {
      e.selected = false;
      e.foc_item = e.foc_item || false;
      e.revised_unit_price = e.unit_price;
      e.other_charges = e.other_charges || [];
      e.qty = e.utilization.remaining_qty;
      e.accordion = {
        'isOpen': true
      };
      e.chargeAdded = false;
      e.datepickerOpened = {
        start_date: false,
        end_date: false,
        start_date_assignment: false,
        end_date_assignment: false
      };
      e.needed_by_date = new Date(e.needed_by_date);
      e.datepickerOpened = false;
      e.neededByDateOptions = {
        formatYear: 'yy',
        startingDay: 1,
        minDate: e.needed_by_date
      }
    });

    loadCacheableData.loadData({
      module: 'tax',
      'criteria[is_active]': 1,
      offset: 0
    }, 'taxes', 'localStorage').then(function(data){
      var taxes = data;
      $scope.taxData = [];
      _.forEach(taxes, function (tax) {
        if (tax.country_code === $scope.header.company_country) {
          tax.value = parseFloat(tax.rate);
          $scope.taxData.push(tax);
        }
      });

      _.forEach($scope.items, function(item) {
        if (!item.foc_item) {
          item.tax = _.find($scope.taxData, function(tax) { return tax._id === item.tax._id; })
        }

        if (!!item.currency.hasOwnProperty('allow_decimal') && !item.currency.allow_decimal){
          item.calculatedDiscount = Math.round(item.discount * (item.qty / item.utilization.total_qty));
        }
        else{
          item.calculatedDiscount = roundToTwo(item.discount * (item.qty / item.utilization.total_qty));
        }

        // find how many decimal places after .
        var remainingQtyDecLength = 0;
        var stringifiedQty = item.utilization.remaining_qty.toString();
        var separatorIndex = stringifiedQty.indexOf('.');

        if (separatorIndex > -1) {
          remainingQtyDecLength = stringifiedQty.substring(separatorIndex + 1).length;
        }
        item.utilization.remaining_qty= preventRoundNumberToFloatBehavior(item.utilization.remaining_qty, remainingQtyDecLength);
        item.qty = preventRoundNumberToFloatBehavior(item.qty, remainingQtyDecLength);
        item.originalQty = item.qty;
        item.originalDiscount = item.calculatedDiscount;

        if (item.other_charges.length > 0){
          _.forEach(item.other_charges, function(charge){
            charge.tax = _.find($scope.taxData, function(tax) { return tax._id === charge.tax_id; });
            // this is for other charges group scenario
            // if other charges group currency is not same with line item currency
            charge.amount = charge.amount * charge.exchange_rate;

            if (!!item.currency.hasOwnProperty('allow_decimal') && item.currency.allow_decimal){
              charge.amount = math.round((item.qty / item.utilization.total_qty * charge.amount), 2);
              charge.unrounded_amount = charge.amount;
              if (!!charge.discount_percentage) {
                charge.discount_amount = math.round((charge.amount * charge.discount_percentage / 100), 2);
                charge.unrounded_discount_amount = charge.discount_amount;
              } else {
                // this is for other charges group scenario
                // if other charges group currency is not same with line item currency
                charge.discount_amount = charge.discount_amount * charge.exchange_rate;
                charge.discount_amount = math.round((item.qty / item.utilization.total_qty * charge.discount_amount), 2);
                charge.unrounded_discount_amount = charge.discount_amount;
              }
              charge.tax_amount = math.round(((charge.amount - charge.discount_amount)*charge.tax.value / 100), 2);
              charge.unrounded_tax_amount = charge.tax_amount;
            } else{
              //TO include unrounded when allow decimal is false
              charge.unrounded_amount = (item.qty / item.utilization.total_qty * charge.amount);
              charge.amount = math.round(charge.unrounded_amount, 0);
              if (!!charge.discount_percentage) {
                charge.unrounded_discount_amount = (charge.amount * charge.discount_percentage / 100);
                charge.discount_amount = math.round(charge.unrounded_discount_amount, 0);
              } else {
                charge.unrounded_discount_amount = (item.qty / item.utilization.total_qty * charge.discount_amount);
                charge.discount_amount = math.round(charge.unrounded_discount_amount, 0);
              }
              charge.unrounded_tax_amount = ((charge.amount - charge.discount_amount)*charge.tax.value / 100);
              charge.tax_amount = math.round(charge.unrounded_tax_amount, 0);
            }
            charge.total_unrounded = charge.unrounded_amount - charge.unrounded_discount_amount + charge.unrounded_tax_amount;
          })
        }

        // enable/disable editing based on item tolerance limit data
        if (!_.isEmpty(item.tolerance_limit_data)) {
          item.isAllowedToleranceLimit = true;
        } else {
          item.isAllowedToleranceLimit = false;
        }
      })
    });

    $scope.clonedItems = _.cloneDeep($scope.items);
  }

  function validateInput(item) {

    var percentageCount = (item.calculatedDiscount.match(/%/g) || []).length;
    var dotCount = (item.calculatedDiscount.match(/\./g) || []).length;

    if (dotCount > 1 || percentageCount > 1) {
      toastr.error(lang.validation.percentage.invalid);
      item.calculatedDiscount = undefined;
      return false;
    }

    if (!item.currency.allow_decimal) {
      if (percentageCount === 0 && dotCount > 0) {
        item.calculatedDiscount = undefined;
        toastr.error(lang.validation.decimal.notAllowed);
        return false;
      }
    }

    return true;
  }

  function updatePriceFields(item) {
    if (validateInput(item)) {
      if (String(item.calculatedDiscount).indexOf('%') > -1) {
        var discountPercentage = Number(item.calculatedDiscount.replace('%', ''));
        var totalQty = item.utilization.total_qty;

        item.discount_percentage = preventRoundNumberToFloatBehavior(item.discount_percentage, 2);

        item.calculatedDiscount = math.round((item.discount_percentage / 100) * (item.qty * item.revised_unit_price), 2);
      } else {
        item.discount_percentage = 0;
      }

      if (!item.discount_percentage) {
        item.originalQty = Number(item.qty);
        item.originalDiscount = Number(item.calculatedDiscount);
      }

      item.calculatedDiscount = Number(item.calculatedDiscount);
    }
  }

  function getTotal(){
    var total = {
      amount: 0,
      discount: 0,
      tax: 0,
      total_amount: 0
    };
    _.forEach($scope.items, function(item){
      if (!item.foc_item){
        if (!!item.currency.hasOwnProperty('allow_decimal') && item.currency.allow_decimal) {
          var calculatedItemTax = roundToTwo(roundToTwo((item.revised_unit_price * item.qty - item.calculatedDiscount)) * item.tax.value / 100);
        } else {
          var calculatedItemTax = math.round((((item.revised_unit_price * item.qty - item.calculatedDiscount)) * item.tax.value / 100), 0);
        }

        total.amount += roundToTwo(item.revised_unit_price * item.qty);
        total.discount += item.calculatedDiscount;
        total.tax += item.tax && calculatedItemTax || 0;
        total.total_amount += roundToTwo((item.revised_unit_price * item.qty)) - item.calculatedDiscount + calculatedItemTax;
        item.line_item_total = total.total_amount;
      }

      _.forEach(item.other_charges, function(charge){
        var tax = 0;
        if (!!charge.tax && !!charge.tax.value){
          tax = charge.tax.value / 100
        }
        if (!!item.currency.hasOwnProperty('allow_decimal') && item.currency.allow_decimal) {
          total.amount += roundToTwo(charge.amount) || 0;
          total.discount += roundToTwo(charge.discount_amount) || 0;
          total.tax += charge.tax_amount;
          total.total_amount += roundToTwo(charge.amount - charge.discount_amount + (total.tax));
          item.line_item_total += roundToTwo(charge.amount - charge.discount_amount + (total.tax));
        } else {
          total.amount += charge.unrounded_amount || 0;
          total.discount += roundToTwo(charge.discount_amount) || 0;
          total.tax += charge.tax_amount;
          total.total_amount += charge.unrounded_amount - charge.unrounded_discount_amount + (total.tax);
          item.line_item_total += charge.unrounded_amount - charge.unrounded_discount_amount + (total.tax);
        }
      });

      total.total_amount = total.amount - total.discount + total.tax;

    });
    $scope.items.total = total;
    return total
  }

  $scope.setReferenceNumber = function(item){
    var string = '';
    
    string += item.pr_custom_reference || item.pr_reference;
    string += ' / Line ';
    string += item.item_line_number;

    if (!!item.pc_number) {
      string += ' / ';
      string += item.pc_number;
    }

    return string;
  }

  function openFocModal(){
    $uibModal.open({
      templateUrl: 'components/poConsolidation/focModal/focModal.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'focModalCtrl',
      size: 'lg',
      resolve: {
        items: function () {
          return $scope.items
        }
      }
    })
  }

  /**
   * @param item
   * @param field
   * @returns {boolean}
   */
  function checkOthersValue(item, field) {
    var showField = false;
    if (field === 'note')
      showField = !item[field];
    //only show other charges for non FOC item
    else if (!item.foc_item)
      showField = _.findIndex(item.other_charges, {'name': field}) < 0;
    return showField;
  }

  function removeOthers(field, index){
    if (field === 'note'){
      delete $scope.items[index][field]
    }
    else{
      _.remove($scope.items[index].other_charges, function(item) {
        return item.name === field;
      });
    }
  }

  function accordionCheck($event, item){
    if (!item.chargeAdded) {
      $event.stopPropagation();
    }
    else {
      item.chargeAdded = false;
    }
  }

  function addOthers(field, index){
    if (field === 'note'){
      $scope.items[index][field] = {
        status: true,
      }
    }
    else {
      $scope.items[index].chargeAdded = true;
      $scope.items[index].other_charges.push({
        'name': field,
        'process_qty': 1,
        'amount': 0,
        'revised_unit_price': 0,
        'discount_amount': 0,
        'tax': _.find($scope.taxData, function(tax) { return tax.code === 'NT0'; }),
        'deletable': true,
        'is_new': true
      })
    }
  }

  function duplicateItem(item) {
    var clonedItem = _.cloneDeep(item);

    clonedItem.revised_unit_price = 0;
    clonedItem.foc_item = true;
    clonedItem.newFoc = true;
    clonedItem.other_charges = [];
    clonedItem.company = _.cloneDeep($scope.items[0].company);
    clonedItem.item_uuid = null;
    clonedItem.foc_source = 'DUPLICATE';
    delete clonedItem.budget;

    $scope.items.push(clonedItem)
  }

  /**
   * function to handle the main check box, in case of selected or unselected
   * @param value
   */
  function itemMainCheckboxClicked(value){
    if(value){
      selectAllItems();
    }else{
      $scope.itemMainCheckBox.selected = false;
      unSelectAllItems();
    }
  }

  /**
   * makes all the items' checkboxes selected
   */
  function selectAllItems(){
    for (var i in $scope.items){
      $scope.items[i].selected = true;
    }
  }

  /**
   * makes all the items' checkboxes unselected
   */
  function unSelectAllItems(){
    for (var i in $scope.items){
      $scope.items[i].selected = false;
    }
  }

  /**
   * makes the main checkbox uncheck if one item unselected
   */
  function unChecked(){
    $scope.itemMainCheckBox.selected = true;
    _.forEach ($scope.items, function(item){
      if (item.selected === false){
        $scope.itemMainCheckBox.selected = false;
        return;
      }
    });
  }

  function otherChargeAmountUpdated(charge, allowDecimal) {
    var discount;
    if (!!charge.discount_percentage){
      if (!!allowDecimal) {
        discount = roundToTwo((charge.discount_percentage/100) * charge.amount);
        charge.calculatedDiscount = discount;
      } else {
        discount = (charge.discount_percentage/100) * charge.amount;
        charge.calculatedDiscount = discount;
      }
    }
  }

  function calculateOtherChargeTax(charge, allowDecimal) {
    if (!!allowDecimal) {
      charge.tax_amount = math.round(((charge.amount - charge.discount_amount)*charge.tax.value / 100), 2);
    } else {
      charge.tax_amount = math.round(((charge.amount - charge.discount_amount)*charge.tax.value / 100), 0);
    }
  }

  function calculateOtherChargesDiscount(charge, item, isPrice) {
    charge.calculatedDiscount = String(charge.discount_amount);
    charge.currency = item.currency;
    var precision = 2;

    if (!item.currency.allow_decimal) {
      precision = 0;
    }

    // preprocess for validate func
    if (!!isPrice && !!charge.discount_percentage) {
      charge.calculatedDiscount = String(charge.discount_percentage) + '%';
    }

    if (validateInput(charge)) {
      if (String(charge.calculatedDiscount).indexOf('%') > -1) {
        var discountPercentage = Number(charge.calculatedDiscount.replace('%', ''));

        discountPercentage = preventRoundNumberToFloatBehavior(discountPercentage, 2);

        charge.calculatedDiscount = math.round((discountPercentage / 100) * charge.amount, precision);
      } else {
        charge.discount_percentage = 0;
      }

      charge.discount_amount = Number(charge.calculatedDiscount);
    }

    if (!charge.amount) {
      charge.amount = 0;
    }

  }

  function roundingHelper(item, number){
    var allowDecimal = item.currency.allow_decimal;
    if (!!allowDecimal) {
      number = roundToTwo(number);
    } else {
      number = Math.round(number)
    }

    return number;
  }

  function recalculateOtherChargesGroup(item){
    if (!_.isEmpty(item.other_charges_group)) {
      _.forEach(item.other_charges, function(e) {
        var charge = _.find(item.other_charges_group.other_charges, function(charge){
          return charge.name.toUpperCase() === e.name.toUpperCase();
        });

        // following backend calculation at RequisitionItemV2.php, setOtherChargesGroup()
        if (!!charge && charge.calculation_method.code === 'PERCENT_OF_EXTENDED_COST') {
          var amount = roundingHelper(item, item.revised_unit_price * item.qty);
          var chargePercentage = charge.percentage / 100;
          e.amount = roundingHelper(item, amount * chargePercentage);
        } else if (!!charge && charge.calculation_method.code === 'FLAT_AMOUNT_PER_UNIT') {
          e.amount = roundingHelper(item, charge.unit_price * item.qty);
        }

        // to cater other charges group scenario with different currencies, to follow line item currency
        e.amount = roundingHelper(item, e.amount * e.exchange_rate);
        e.discount_amount = roundingHelper(item, e.discount_amount * e.exchange_rate);

        // recalculate other charges tax
        calculateOtherChargeTax(e, !!item.currency.allow_decimal);
        calculateOtherChargesDiscount(e, item, true);
      });
    }
  }

  function calculateDiscount(item, isPrice) {
    var discount;

    if (!(item.qty > 0)) {
      item.calculatedDiscount = 0;
      return
    }

    var precision = 2;
    if (!!item.currency.hasOwnProperty('allow_decimal') && !item.currency.allow_decimal){
      precision = 0;
    }

    if (!!item.discount_percentage){
      item.discount_percentage = preventRoundNumberToFloatBehavior(item.discount_percentage , 2);

      discount = math.round((item.discount_percentage/100) * (item.qty*item.revised_unit_price), precision);
      item.calculatedDiscount = discount;
    } else if (!isPrice) {
      item.calculatedDiscount = math.round(item.originalDiscount * (item.qty / item.originalQty), precision);
    }
  }

  function calculateOtherCharges(item, index) {
    if ((item.qty > 0) && (item.qty < item.utilization.total_qty + 1)) {
      _.forEach(item.other_charges, function (charge, otherChargesIndex) {
        var oriItem = $scope.clonedItems[index];
        var oriAmt = oriItem.other_charges[otherChargesIndex].amount;
        var oriDiscAmt = oriItem.other_charges[otherChargesIndex].discount_amount;
        var oriDiscPercent = oriItem.other_charges[otherChargesIndex].discount_percentage;

        if (!!oriItem.currency.hasOwnProperty('allow_decimal') && oriItem.currency.allow_decimal){
          charge.amount = math.round((item.qty / item.utilization.total_qty * oriAmt), 2);
          if (!!charge.discount_percentage) {
            charge.discount_amount = math.round((charge.amount * oriDiscPercent / 100), 2);
          } else {
            charge.discount_amount = math.round((item.qty / item.utilization.total_qty * oriDiscAmt), 2);
          }
          charge.tax_amount = math.round(((charge.amount - charge.discount_amount)*charge.tax.value / 100), 2);
        }
        else{
          charge.amount = math.round((item.qty / item.utilization.total_qty * oriAmt), 0);
          if (!!charge.discount_percentage) {
            charge.discount_amount = math.round((charge.amount * oriDiscPercent / 100), 0);
          } else {
            charge.discount_amount = math.round((item.qty / item.utilization.total_qty * oriDiscAmt), 0);
          }
          charge.tax_amount = math.round(((charge.amount - charge.discount_amount)*charge.tax.value / 100), 0);
        }
      });
    }
  }

  //to deal with the one IDR different
  function otherChargeNotRoundedAmount(item, index) {
    //try to do for unrounded amount
    if ((item.qty > 0) && (item.qty < item.utilization.total_qty + 1)) {
    _.forEach(item.other_charges, function (charge, otherChargesIndex) {
    var oriItem = $scope.clonedItems[index];
    var oriAmt = oriItem.other_charges[otherChargesIndex].amount;
    var oriDiscAmt = oriItem.other_charges[otherChargesIndex].discount_amount;
    var oriDiscPercent = oriItem.other_charges[otherChargesIndex].discount_percentage;
    charge.unrounded_amount = (item.qty / item.utilization.total_qty * oriAmt);
    if (!!charge.discount_percentage) {
            charge.unrounded_discount_amount = (charge.unrounded_amount * oriDiscPercent / 100);
          } else {
            charge.unrounded_discount_amount = (item.qty / item.utilization.total_qty * oriDiscAmt);
          }
          charge.unrounded_tax_amount = ((charge.unrounded_amount - charge.unrounded_discount_amount)*charge.tax.value / 100);
          charge.total_unrounded = charge.unrounded_amount - charge.unrounded_discount_amount + charge.unrounded_tax_amount;
    });
  }
}

  function getLineTotal(index, useOriginalValues) {
    if (useOriginalValues) {
      var amt = ($scope.items[index].amount - $scope.items[index].discount);
      return amt + (amt * $scope.items[index].tax.rate / 100);
    }

    return (
      ($scope.items[index].revised_unit_price * $scope.items[index].qty) -
      $scope.items[index].calculatedDiscount +
      (
        ($scope.items[index].revised_unit_price * $scope.items[index].qty - $scope.items[index].calculatedDiscount) *
        $scope.items[index].tax.value / 100)
    );
  }

  function getLineTotalWithOtherCharges(index) {
    if (!$scope.items[index].foc_item) {
      var amountWithDiscount = (($scope.items[index].revised_unit_price * $scope.items[index].qty) - $scope.items[index].calculatedDiscount);
      var totalAmount = amountWithDiscount + (amountWithDiscount * $scope.items[index].tax.rate / 100);

      _.forEach($scope.items[index].other_charges, function (charge) {
        var tax = 0;

        if (!!charge.tax && !!charge.tax.value) {
          tax = charge.tax.value / 100
        }

        totalAmount += charge.amount - charge.discount_amount + ((charge.amount - charge.discount_amount) * tax);
      });

      return totalAmount;
    }
  }

  function getPercentageValue(index) {
    var totalAmount = getLineTotalWithOtherCharges(index);

    if (!!$scope.companyTolerance) {
      return totalAmount * ($scope.companyTolerance.percentage / 100);
    }

    return;
  }

  function getAmountValue(index) {
    var totalAmount = getLineTotalWithOtherCharges(index);

    if (!!$scope.companyTolerance) {
      return (totalAmount / $scope.items[index].tolerance_limit_data.amount) * $scope.companyTolerance.amount;
    }

    return;
  }

  function maximumAmount(index) {
    var originalAmount = 0;
    var tolerance = 0;

    //calculate if the tolerance limit is set
    if (!!$scope.items[index] && !!$scope.items[index].tolerance_limit_data) {
      var maxToleranceAmt = $scope.items[index].tolerance_limit_data.total_with_other_charges +
        $scope.items[index].tolerance_limit_data.lowest;
      tolerance = ($scope.items[index].qty / $scope.items[index].originalQty) * maxToleranceAmt;

    }

    return tolerance;
  }

  function roundToTwo(num) {
    return +(Math.round(num + "e+2")  + "e-2");
  }

  initialize();
}

poConsolidationItemsCtrl.$inject = [
  '$scope', 'loadCacheableData', '$uibModal', 'pathConstants', 'toleranceLimit', 'lang', 'toastr'
];
angular
  .module('metabuyer')
  .directive('poConsolidationItems', poConsolidationItems)
  .controller('poConsolidationItemsCtrl', poConsolidationItemsCtrl);
