'use strict';

angular
  .module('metabuyer')
  .directive('tenderHeader', function($uibModal) {
    return {
      restrict: 'E',
      scope: {
        initialData: '=',
        tender: '=',
        editableStatus: '=',
        numericPatternRestrict: '='
      },
      template: '<div ng-include="templateUrl"></div>',
      controller: 'tenderHeaderCtrl',
      link: function($scope) {
        $scope.templateUrl = 'components/tenderComponents/header/tenderHeaderTemplate.html';
      }
    };
  });
