/**
 * @name reLoginService
 *
 * @description
 *
 * This service prompt the user with a re-Login modal
 *
 *
 * @requires $uibModal
 * @requires $rootScope
 * @requires currentStateData: mandatory when calling the service to redirect in case of successful re-login
 * @requires email: mandatory when calling the service for passing it to the server along with the password to re-login
 * @requires message: mandatory when calling the service to display the reason for re-logining in
 *
 *
 * @function showModal
 * Returns the modal that calls the template and controller with resolved data
 *
 *
 * @author Ahmed Saleh <a.s.alsalali@gmail.com>
 * @copyright  Metacloud Sdn. Bhd. &copy; 2016
 */

'use strict';

function reLoginService ($uibModal, $rootScope) {

  function showModal(currentStateData, email, message) {
    if(!$rootScope.reLoginDialogOpen) {
      $rootScope.reLoginDialogOpen = true;
      $uibModal.open({
        templateUrl: 'components/reLogInModal/reLogInModalTemplate.html',
        backdrop: 'static',
        keyboard: false,
        controller: reLogInCtrl,
        resolve: {
          scope: function () {
            return $rootScope;
          },
          currentStateData: function () {
            return currentStateData;
          },
          email: function () {
            return email;
          },
          message: function () {
            return message;
          }
        }
      });
    }
  }

  return showModal;
}

reLoginService.$inject = ['$uibModal', '$rootScope'];

angular
  .module('metabuyer')
  .factory('reLoginService',reLoginService);
