'use strict';
/**
 * @name MasterDataManageTaxesCtrl
 * @desc Controller for master data tax controller
 */
function MasterDataManageTaxesCtrl($scope, dataList, $stateParams, toastr, $uibModal,
                                   taxesMasterDataList, $location, $filter, globalFunc,pathConstants, $timeout, $rootScope) {

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  /**
   * returns the is_active name
   * @param code
   * @returns {*}
   */
  function getActiveStatusName(code){
    /**
     DEACTIVATED = 0; 
     ACTIVE = 1; 
     DELETED = 2; 
     DISABLED = 3; 
     */
    if (code === 0 || code === 'DEACTIVATED') {
      return 'Deactivated'
    }
    if (code === 1 || code === 'ACTIVE') {
      return 'Active'
    }
    if (code === 2 || code === 'DELETED') {
      return 'Deleted'
    }
    if (code === 3 || code === 'DISABLED') {
      return 'Disabled'
    }
    return 'N/A';
  }

  function initialize(){
    $scope.dataList = dataList.data;
    $scope.meta = dataList.meta;
    $scope.taxesMasterDataList = taxesMasterDataList;
    $scope.addNewTax = addNewTax;
    $scope.search = search;
    $scope.showColumn = showColumn;
    $scope.getActiveStatusName = getActiveStatusName;
    $scope.countryCode = globalFunc.countryCode();
    $scope.params = {};
    $scope.importTax = importTax;
    $scope.importLink = pathConstants.apiUrls.tax.import;

    // Datepicker
    $scope.openDatepicker = openDatepicker;

    $scope.startDateOptions = {
      formatYear: 'yy',
      startingDay: 1
    };

    $scope.endDateOptions = {
      formatYear: 'yy',
      startingDay: 1
    };

    $scope.datepickerOpened = {
      start_date: false,
      end_date: false
    };

    $scope.openTaxDetails = openTaxDetails;

    $scope.columns = [
      {id: 'code', label: 'Code'},
      {id: 'descr', label: 'Description'},
      {id: 'rate', label: 'Rate %'},
      {id: 'country_code', label: 'Country code'},
      {id: 'effective_at', label: 'Start date'},
      {id: 'expiry_at', label: 'End date'},
      {id: 'is_active', label: 'Status'}
    ];

    $scope.storageKey = 'master-data-tax-management-selected-columns';

    /**
     * setting the model with the searched text
     */
    if(!!$stateParams.search){
      $scope.searchText = $stateParams.search;
    }
    else{
      $scope.searchText = '';
    }
  }

  function validate(newTax){
    if (!newTax){
      return false;
    }
    if (!newTax.code){
      return false;
    }
    if (!newTax.descr) {
      return false;
    }
    if (!newTax.rate || (newTax.rate < 0)) {
      return false;
    }
    if (!newTax.countryCode) {
      return false;
    }
    if (!newTax.effective_at) {
      return false;
    }
    if (!newTax.expiry_at) {
      return false;
    }
    if (newTax.effective_at >= newTax.expiry_at){
      return false;
    }

    return true;
  }

  function addNewTax(newTax) {
    $scope.submitted = true;

    if(validate(newTax)) {
      var submitData = {
        code: newTax.code,
        descr: newTax.descr,
        is_active: 1,
        country_code: newTax.countryCode.descr,
        effective_at: globalFunc.convertDateToTimestamp(newTax.effective_at),
        expiry_at: globalFunc.convertDateToTimestamp(newTax.expiry_at),
        rate: newTax.rate
      };
      taxesMasterDataList.post(
        {},
        submitData,
        function (resource) {
          var addedTax = resource.content.data;
          toastr.success('The tax was added successfully');
          $scope.dataList.unshift({
            _id: addedTax._id,
            code: addedTax.code,
            descr: addedTax.descr,
            rate: addedTax.rate,
            country_code: addedTax.country_code,
            effective_at: addedTax.effective_at,
            expiry_at: addedTax.expiry_at,
            is_active: 1
          });

          //to clear the ng-model fields
          $scope.newTax = {
            code: '',
            descr: '',
            rate: '',
            effective_at: '',
            expiry_at: ''
          };
          $scope.submitted = false;
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        });
    }
    else{
      toastr.error('Please fill in all the fields');
    }
  }

  function openDatepicker($event, which) {

    /**
     * Setting minimum selection for start date to be today
     */
    $scope.startDateOptions.minDate = new Date();

    /**
     * Setting the minimum selection for end date to be one day ahead of selected start date
     */
    var startDateClone = _.cloneDeep($scope.newTax.effective_at);
    var startDate = new Date(startDateClone);
    startDate.setDate(startDate.getDate() + 1);
    $scope.endDateOptions.minDate = startDate;

    if($scope.datepickerOpened[which]){
      $event.preventDefault();
      $event.stopPropagation();
    }
    else{
      $timeout(function(){
        $scope.datepickerOpened[which] = true;
      })
    }
  }

  function openTaxDetails(index) {
    var taxId = $scope.dataList[index]._id;
    var modalInstance = $uibModal.open({
      templateUrl: 'app/masterDataManagement/taxes/detail/detail.html',
      controller: 'taxDetailsCtrl',
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      resolve: {
        taxDetails: function($q, taxesMasterDataList) {
          var deferred = $q.defer();
          taxesMasterDataList.get(
            {
              id: taxId
            },
            function (resource) {
              deferred.resolve(resource.content || {data: []});
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        }
      }
    });

    modalInstance.result.then(function (newValue) {
      if(!!newValue){
        _.forEach($scope.dataList, function(dataList){
          if(dataList._id === newValue._id){
            dataList.code = newValue.code;
            dataList.descr = newValue.descr;
            dataList.rate = newValue.rate;
            dataList.is_active = newValue.is_active;
            dataList.effective_at = newValue.effective_at;
            dataList.expiry_at = newValue.expiry_at;
            dataList.updated_at = newValue.updated_at;
            dataList.country_code = newValue.country_code;
          }
        });
      }
    });
  }

  function importTax() {
    var modalInstance = $uibModal.open({
      templateUrl: 'app/masterDataManagement/Import-Template.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        importLink: function () {
          return $scope.importLink;
        },
        title: function () {
          return 'Tax';
        }
      },
      controller: function ($scope, $uibModalInstance, importLink, title) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        $scope.importLink = importLink;
        $scope.title = title;
      }
    });
  }

  function search(query) {
    $location.search({query: query});
  }

  initialize();
}

MasterDataManageTaxesCtrl.$inject = [
  '$scope', 'dataList', '$stateParams', 'toastr', '$uibModal', 'taxesMasterDataList',
  '$location', '$filter', 'globalFunc', 'pathConstants', '$timeout', '$rootScope'
];

angular
  .module('metabuyer')
  .controller('MasterDataManageTaxesCtrl', MasterDataManageTaxesCtrl);
