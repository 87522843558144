'use strict';

function supplierCompanyContractCtrl($scope, suppliers, $title, showSuppliers, companyContractJob, toastr, globalFunc) {

  $scope.searchSuppliers = searchSuppliers;
  $scope.confirmRunSupplierCompanyJob = confirmRunSupplierCompanyJob;
  $scope.runSupplierCompanyJob = runSupplierCompanyJob;
  $scope.searchSupplier = '';

  function initialize() {
    $scope.embeddedParams = {};
    $scope.showSuppliers = showSuppliers;
    $scope.suppliers = suppliers.data;
    $scope.suppliersMetaData = suppliers.meta;
    $scope.title = $title;
    $scope.isDisabled = false;
  }

  function searchSuppliers(searchSupplier) {
    showSuppliers.get({
        'search-term': searchSupplier
      },
      function (resource) {
        $scope.suppliers = resource.data;
        $scope.suppliersMetaData = resource.meta;
        $scope.embeddedParams = {
          'search-term': searchCompany
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      })
  }

  function confirmRunSupplierCompanyJob() {
    swal({
      title: 'Confirm to run the job manually?',
      text: 'This action will run the company supplier job',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Confirm',
      closeOnConfirm: true
    }, function (isConfirm) {
      if (isConfirm) {
        $scope.isDisabled = true;
        runSupplierCompanyJob();
      }
    });
  }

  function runSupplierCompanyJob() {
    companyContractJob.post(
      {},
      function (response) {
        toastr.success(response.message);
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  initialize();
}

supplierCompanyContractCtrl.$inject = ['$scope', 'suppliers','$title', 'showSuppliers', 'companyContractJob', 'toastr', 'globalFunc'];

angular
  .module('metabuyer')
  .controller('supplierCompanyContractCtrl', supplierCompanyContractCtrl);
