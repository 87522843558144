'use strict';

function ngrnCtrl($scope,  $uibModalInstance, ngrn, po, getGRNPDFHash, $filter, toastr, pathConstants, $rootScope){

  $scope.ngrn = ngrn;
  $scope.po = po;

  $scope.cancel = cancel;
  $scope.printNGRN = printNGRN;

  function cancel(){
    $uibModalInstance.close();
  }
  function printNGRN() {

    var tabWindow = window.open('', '_blank');

    getGRNPDFHash.get(
      {
        id: $scope.ngrn._id,
        timezone: $rootScope.currentUser.config.TZ
      }
    ).$promise.then(
      function (resource) {
        var newUrl = $filter('format')(pathConstants.apiUrls.po.pdfHash, {
          pdf_hash: resource.url
        });

        setTimeout(function (){
            tabWindow.location.href = newUrl;
          },
          1001
        );
      },
      function(){
        toastr.error('Failed to print NGRN');
      }
    );
  }

  function initialize() {
    var tempErpComments = [];
    var commentControl = false;

    $scope.po.comments.data.forEach(function(element) {
      if (element.commentator.display_name.toLowerCase() === 'bifrost') {
        var isNgrnIssued = element.action.toLowerCase() === 'ngrn issued';
        var hasGrnNumber = !!element.comment ?
          element.comment.toLowerCase().includes($scope.ngrn.grn_number.toLowerCase()) : false;

        if (isNgrnIssued) {
          commentControl = hasGrnNumber;
        } else {
          commentControl = false;
        }

        if (commentControl) {
          tempErpComments.push({'action' : element.action, 'comment' : element.comment});
        }
      }
    });
    $scope.listErpComments = tempErpComments;
  }

  initialize();
}

ngrnCtrl.$inject = ['$scope', '$uibModalInstance', 'ngrn', 'po', 'getGRNPDFHash', '$filter', 'toastr', 'pathConstants' , '$rootScope'];

angular.module('metabuyer')
  .controller('ngrnCtrl', ngrnCtrl);
