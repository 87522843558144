'use strict';

angular.module('metabuyer')
  .directive('supplierCompanyList', function($uibModal) {
    return {
      templateUrl: 'components/supplierComponents/companiesList/supplierCompaniesListTemplate.html',
      controller: supplierCompanyListCtrl,
      scope: {
        supplier: '=',
        paymentTerms: '=',
        paymentMethods: '=',
        taxes: '=',
        currencies: '=',
        commodities: '=',
        readOnly:'=?',
        preFilterName:'=?'
      }
    };
  });

supplierCompanyListCtrl.$inject = ['$scope', 'metabuyerCache', 'searchModule', 'getAllCompanies', '$uibModal',
                                   'supplierFunctions', 'listCompanyContract', 'companyContractRevisions', '$q' , '$stateParams',
                                   '$translatePartialLoader', '$translate', '$rootScope', 'globalFunc'];

function supplierCompanyListCtrl ($scope, metabuyerCache, searchModule, getAllCompanies, $uibModal, supplierFunctions,
                                  listCompanyContract, companyContractRevisions, $q , $stateParams,
                                  $translatePartialLoader, $translate, $rootScope, globalFunc) {
  $translatePartialLoader.addPart('../components/supplierComponents/companiesList/lang');

  $scope.openDetails = openDetails;
  $scope.companies = [];
  $scope.companyContract = companyContract;
  $scope.mainCallback = mainCallback;
  $scope.listCompanyContract = listCompanyContract;
  $scope.searchCompany = '';
  $scope.isCompanySupplierModalOpened = false;
  $scope.loadContracts = loadContracts;
  
  var supplierCompanyListColumns = [
    {id: 'company_descr', label: 'Company Name', translationId: 'companySuppliers.columns.companyName', selected: true , unsortable: true, unsearchable: true},
    {id: 'status', label: 'Status', translationId: 'companySuppliers.columns.status', selected: true},
    {id: 'updater_info', label: 'Updated By', translationId: 'companySuppliers.columns.updatedBy', selected: true , unsortable: true, unsearchable: true},
    {id: 'updated_at', label: 'Updated At', translationId: 'companySuppliers.columns.updatedAt', selected: true , unsortable: true, unsearchable: true}
  ];

  $scope.columns = supplierCompanyListColumns;
  var translateColumnArr = [
    'companySuppliers.columns.companyName',
    'companySuppliers.columns.status',
    'companySuppliers.columns.updatedBy',
    'companySuppliers.columns.updatedAt',
  ];
  $translate(translateColumnArr).then(function (trans) {
    for(var columnIndex in $scope.columns) {
      var columnData = $scope.columns[columnIndex];
      if (trans[columnData.translationId] === undefined)
        continue;
      $scope.columns[columnIndex].label = trans[columnData.translationId];
    }
  });

  function mainCallback() {
    companyContract();
  }

  function openDetails(companyContract) {

    if (!!$rootScope.isV2Enabled) {
      //disable when user click
      return;
    }

    if (!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN') &&
        !globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'ADMIN')) {
        return;
    }

    if($scope.isCompanySupplierModalOpened){
      return;
    }
    var templateUrl = 'components/supplierComponents/contractDetails/contractDetailsTemplate.html';
    if($scope.readOnly === true)
      templateUrl = 'components/supplierComponents/contractDetails/contractDetailsReadOnlyTemplate.html';

    $scope.isCompanySupplierModalOpened = true;
    var modalInstance = $uibModal.open({
      templateUrl: templateUrl,
      controller: 'contractDetailsCtrl',
      keyboard: true,
      backdrop: 'static',
      windowClass: 'supplier-contract-modal',
      resolve: {
        baseContract: function () {
          return companyContract.company_contract;
        },
        paymentTerms: function () {
          return $scope.paymentTerms;
        },
        paymentMethods: function () {
          return $scope.paymentMethods;
        },
        taxes: function () {
          return $scope.taxes;
        },
        currencies: function () {
          return $scope.currencies;
        },
        commodities: function () {
          return $scope.commodities;
        },
        pendingChanges: function () {
          if (supplierFunctions.pendingEditCompanyContract($scope.supplier)) {
            // only 1 company contract in an edit company contract
            var companyContractEdit = _.first($scope.supplier.edit.company_contracts);

            if (companyContractEdit.company.company_code === companyContract.company.company_code) {
              return companyContractEdit;
            }
          }
          return null;
        },
        contractRevisions: function () {
          var deferred = $q.defer();
          companyContractRevisions.get(
            {
              id: $scope.supplier._id,
              company_code: companyContract.company.company_code
            },
            function (resource) {
              return deferred.resolve(resource.data);
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
              deferred.resolve([]);
            }
          );
          return deferred.promise;
        }
      }
    }).closed.then(function(){
      $scope.isCompanySupplierModalOpened = false;
    });
  }

  function companyContract() {
    var modalInstance = $uibModal.open({
      templateUrl: 'components/supplierComponents/massEditCompanyContract/MassEditCompanyContract.html',
      controller: 'massEditCompanyContractCtrl',
      size: 'lg',
      resolve: {
        paymentTerms: function () {
          return $scope.paymentTerms;
        },
        paymentMethods: function () {
          return $scope.paymentMethods;
        },
        taxes: function () {
          return $scope.taxes;
        },
        supplier: function () {
          return $scope.supplier;
        }
      }
    });
  }

  function loadContracts() {
    $scope.embeddedParams = {
      id: $scope.supplier._id,
      order_by: $stateParams.order_by,
      order: $stateParams.order,
      offset: 50,
      page: $stateParams.page,
      'search-term': $scope.searchCompany
    };

    supplierFunctions.getCompanyContracts($scope.embeddedParams).then(function (success) {
      $scope.companiesList = success.data;
      $scope.companiesListMetaData = success.meta;
    });
  }

  function initialize() {
    //get the list of companies for mass edit
    var list = metabuyerCache.get('massEditCompaniesList');
    if (!list) {
      getAllCompanies.get({
        'fields[0]': '_id',
        'fields[1]': 'code',
        'fields[2]': 'descr'
      }, function (success) {
        metabuyerCache.put('massEditCompaniesList', success.content.data);
        $scope.companies = success.content.data;
      }, function (error) {
      })
    } else {
      $scope.companies = metabuyerCache.get('massEditCompaniesList');
    }

    loadContracts();
  }

  initialize();
}