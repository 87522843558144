'use strict';

/**
 * @name prItemAdvancedSearchCtrl
 *
 * @description Controller for PR Item advanced search
 *
 * @author Chie Yang Teh <tehcy@sunway.com.my>
 * @copyright Sunway Metacloud &copy; 2023
 */
function prItemAdvancedSearchCtrl($scope, toastr, $rootScope, advancedSearch, exportSearch, itemsCategories, metabuyerCache, catalogFunction, prFunction, $purchaseRequisition, paymentTerms, prConfig, purchaseRequisitionsServices, globalFunc ) {


 function showItemDetails(item) {

  $uibModal.open({
    templateUrl: 'components/purchaseRequisitionComponents/purchaseRequisitionShoppingMode/detailsTemplate.html',
    controller: 'CatalogItemDetailCtrl',
    backdrop: 'static',
    keyboard: false,
    windowClass: 'item-details-modal',
    resolve: {
      item: function () {
        return item;
      },
      itemDetails: function () {
        return item.details;
      },
      addItemToPRFunction: function () {
        return addItemToPRResource;
      },
      paymentTerms: function () {
        return paymentTerms.data;
      },
      prItemLimit: function () {
        return $scope.prItemLimit
      }
    }
  });
}

  function setCategoriesView() {

    $scope.groupIds = [];
    $scope.groupIds = prFunction.getCatalogGroup();

    itemsCategories.get(
      {
        code: 'all',
        groupIds: [$scope.groupIds]
      },
        function (resource) {
          if (!!resource.content && !!resource.content.data) {
            metabuyerCache.put('categoriesData', resource.content.data);
            $scope.categoriesData = resource.content.data;
            $scope.categories = [{ key: 'ALL', value: 'All'}];
            $scope.categoriesData.forEach(function (item) {
              $scope.categories.push({ key: item.code, value: item.title, families: item.families });
            });
            divideCategories();
          }
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
  }

  var toastrErrorMessage = null;
  $scope.errors = {};

  $scope.columns = [
    {id: 'catalog_group|descr', label: 'Catalog Group', selected: true},
    {id: 'catalog_code|code', label: 'Catalog Code', selected: true},
    {id: 'catalog_code|descr', label: 'Catalog Description', selected: true},
    {id: 'supplier|descr', label: 'Supplier', selected: true},
    {id: 'item_number', label: '# item', selected: true},
    {id: 'import_effective_date', label: 'Effective At', selected: true},
    {id: 'creator_info|display_name', label: 'Creator', selected: true},
    {id: 'created_at', label: 'Created Date', selected: true},
    {id: 'updater_info|display_name', label: 'Updated By', selected: true},
    {id: 'updated_at', label: 'Updated Date', selected: true},
  ];

  var initialSearchFields = {
    categories: null,
    subCategories: null,
    catalogGroupName: null,
    catalogCode: null,
    catalogDescr: null,
    supplierName: null,
  };

  $scope.catalogTitleTransformer = catalogTitleTransformer;
  $scope.showColumn = showColumn;
  $scope.submitAdvSearch = submitAdvSearch;
  $scope.resetForm = resetForm;
  $scope.searchModule = advancedSearch;
  $scope.catalogDisplayHandler = '';
  $scope.plusItemQty = plusItemQty;
  $scope.minusItemQty = minusItemQty;
  $scope.addItemToPR = addItemToPR;
  $scope.cart = [];
  $scope.isItemLimitReached = isItemLimitReached;
  $scope.prItemLimit = prConfig.pr_item_limit;
  $scope.PRData = PRData;

  /**
   * catalog title transformer
   * @param title
   */
  function catalogTitleTransformer (title) {
    if (!title) {
      return title;
    }
    title = title.replace(".zip", "");
    return title.replace(".csv", "");
  }

  /**
   * function to validate form input values
   * @returns {boolean|Object}
   */
   function validateForm() {

    /**
     * logic for range fields (total price, total cost of pr, and all date fields)
     * if `from` field is not empty, set `to` field to same value as `from` value
     * if `to` field is not empty, `from` field is mandatory.
     * if both are empty, skip validation
     *
     */

    //effective date range
    if (!!$scope.effectiveDateTo) {
      if (!!$scope.effectiveDateFrom) {
        if ($scope.effectiveDateFrom > $scope.effectiveDateTo) {
          $scope.errors.effectiveDate = 'Invalid Range';
        }
      } else {
        $scope.errors.effectiveDate = '\'From\' field is mandatory';
      }
    } else if (!!$scope.effectiveDateFrom) {
      $scope.effectiveDateTo = $scope.effectiveDateFrom;
    }

    if (_.isEmpty($scope.errors)) {
      var searchData = _.clone($scope.searchFields);
      return buildParam(searchData);
    } else {
      toastrErrorMessage = 'Invalid value in one or more fields';
      return false;
    }
  }

  /**
   * function to build criteria parameters
   * @param data object of form input values
   * @returns {boolean|Object}
   */
  function buildParam(data) {

    var params = {};

    if(!!data.categories) {
      params['criteria[0][categories]'] = data.categories.key;
    }

    if(!!data.subCategories) {
      params['criteria[0][subCategories]'] = data.subCategories.key;
    }

    if (!!data.catalogGroupName) {
      params['criteria[0][catalog_data|catalog_group|descr]'] = data.catalogGroupName;
    }

    if (!!data.catalogCode) {
      params['criteria[0][catalog_data|catalog_code|code]'] = data.catalogCode;
    }

    if (!!data.catalogDescr) {
      params['criteria[0][catalog_data|catalog_code|descr]'] = data.catalogDescr;
    }

    if (!!data.supplierName) {
      params['criteria[0][details|supplier_descr]'] = data.supplierName;
    }

    // if status 'all' is selected, the form cannot be empty. so the criteria for 'all' status
    // will only be added if `params` is not empty.
    if (_.isEmpty(params)) {
      toastrErrorMessage = 'Fill in at least one field';
      return false;
    } else {
      params['criteria[0][catalogGroupIds]'] =[$scope.groupIds];
      params['module'] = 'items';
      params['offset'] = 20;
      params['criteria[0][$operator]'] = 'and';
      return params;
    }
  }

  function PRData(){
    return prFunction.getPRData();
  }

  function divideCategories() {
  

    if ($scope.categories) {
      $scope.segments = [];
      _.forEach($scope.categories, function (category) {
        var temp = {
          'code': category.key,
          'title': category.value,
          'families': category.families
        };
        $scope.segments.push(temp);
      });
    }
  }

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  $scope.updateSubcategories = function(selectedCategory) {
    $scope.subCategories = [];
    $scope.searchFields.subCategories = null;
    // Use a conditional statement or data retrieval mechanism to update subcategory options.
    if (selectedCategory.key === 'ALL') {
      $scope.subCategories = [
        { key: 'ALL', value: 'All Subcategories' },
        // Add other subcategory options for 'All'.
    ];
        $scope.disableSelection = true;
    } else {
      $scope.disableSelection = false;
      selectedCategory.families.forEach(function (item) {
        $scope.subCategories.push({ key: item.code, value: item.title });
      });
    }
};


  /**
   * function to submit search form
   */
  function submitAdvSearch() {
    var searchParam = validateForm();

    if (!!searchParam) {
      $scope.embeddedParams = searchParam;

      /**
       * Setting the params to prepare for exporting
       */
      exportSearch.setParams(searchParam);

      advancedSearch.get(
        searchParam,
        function (resource) {
          $scope.catalogDisplayHandler = 'showItems';
          $scope.dataList = resource.content.data;
          $scope.meta = resource.content.meta;
          $scope.emptyInput = false;
        },
        function (error) {
          toastr.error('Something went wrong');
          $scope.dataList = [];
        }
      );
    } else {
      toastr.error(toastrErrorMessage);
      toastrErrorMessage = null;
      $scope.emptyInput = true;
    }
  }

  /**
   * function to reset the search form
   */
  function resetForm() {
    $scope.searchFields = _.cloneDeep(initialSearchFields);
    $scope.dataList = [];
    $scope.disableSelection = true;
    $scope.catalogDisplayHandler = '';
  }

  function setDefaultQuantity() {
    for (var key in $scope.dataList) {
      $scope.dataList[key].quantityOrdered = 1;
    }
  }

  function plusItemQty(index, count) {
    if (!count || count < 1) {
      count = 0
    }

    count = parseInt(count) + 1;
    $scope.dataList[index].quantityOrdered = count;
  }

  function minusItemQty(index, count) {
    if (!count || count < 1) {
      count = 2
    }

    count = parseInt(count) - 1;
    if (!count < 1) {
      $scope.dataList[index].quantityOrdered = count;
    }
  }

   /**
   * function to call the resource and update the html when adding items to the PR
   * @param item
   * @param qty
   * @param product
   */
   function addItemToPR(item, qty, product){
    if (!qty || qty < 1) {
      globalFunc.objectErrorMessage("Cannot be empty or negative value");
      return;
    }

    var pr = prFunction.getPRData();
    // check pr item limit
    if (isItemLimitReached()) {
      globalFunc.objectErrorMessage("PR line item limit reached. Only " + $scope.prItemLimit + " line items allowed per PR");
      return;
    }

    var validateLumpSum = prFunction.validateLumpSum(prepareAddItem(item), -1);

    if (!checkPaymentTerm(item)) {
      swal({
        title: 'Invalid Payment Term',
        text: 'The payment term for this item is inactive. Please check.',
        type: 'info',
        showCancelButton: true,
        showConfirmButton: false
      });
    }
    else {
      if (!!pr.lump_sum_discount && !validateLumpSum.status) {
        swal({
          title: 'Confirm add item?',
          text: validateLumpSum.message,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Confirm',
          closeOnConfirm: true
        }, function (isConfirm) {
          if (isConfirm) {
            // remove lump sum
            prFunction.removeLumpSum(pr._id, function (resource) {
              if (!!resource)
                sendingAddNewItemRequest(item, qty);
            })
          }
        });
      }
      else {
        if (product.more > 1) {
          showItemDetails(product);
        }
        else
        {
          sendingAddNewItemRequest(item, qty);
        }
      }
    }
  }

  /**
   * validate new item
   * if the pr got lump sum
   * @param item
   * @param qty
   */
  function sendingAddNewItemRequest(item, qty) {
    addItemToPRResource(item, qty).$promise.then(function(resource){
      if (!!resource && !!resource.content && !!resource.content.data) {
        $rootScope.$broadcast('itemAdded');
        prFunction.setPRData(resource.content.data);
        refreshAddCartButton();
      }

      prFunction.setLoading('lineItemTable', false);
      prFunction.setLoading('addingItem', false);
      $rootScope.$broadcast('checkIntegrationData')
    },function (error){
      globalFunc.objectErrorMessage(error.data);
      prFunction.setLoading('lineItemTable', false);
      prFunction.setLoading('addingItem', false);
    });
  }

  function checkPaymentTerm(item) {
    var isPaymentTermValid = false;
    if (!!item.payment_term.code) {
      if (globalFunc.findInArray($scope.validPaymentTerm, 'code',
          item.payment_term.code)) {
        isPaymentTermValid = true;
      }
    }
    return isPaymentTermValid;
  }
  
  function isItemLimitReached() {
    var pr = prFunction.getPRData();
    return ($scope.prItemLimit > 0 && !!pr.pr_items && pr.pr_items.length >= $scope.prItemLimit);
  }

  /**
   * prepare new item for validation
   * @param item
   */
  function prepareAddItem(item) {
    var newItem = {};
    newItem.tax = item.tax? item.tax : null;
    newItem.tax_id = item.tax_id? item.tax_id : null;
    newItem.supplier = {_id: (item.supplier_id? item.supplier_id : null)};
    newItem.payment_term = {
      _id: (item.payment_term_id? item.payment_term_id : null),
      code: (item.payment_term_code? item.payment_term_code: null)
    };
    newItem.detail_id = item.detail_id? item.detail_id : null;
    newItem.currency = {_id:(item.pricing.currency_id? item.pricing.currency_id: null)};

    return newItem;
  }

  /**
   * function that posts the Item to add to the PR
   * @param itemDetailsInfo
   * @param qty
   * @returns {*|{method, params, url}}
   */
  function addItemToPRResource(itemDetailsInfo, qty) {
    prFunction.setLoading('lineItemTable', true);
    prFunction.setLoading('addingItem', true);
    prFunction.setLoading('updatePRField', true);
    return purchaseRequisitionsServices.addLineItem({
    id: $scope.PR._id
    }, {
      'item_details_id': itemDetailsInfo.detail_id,
      'qty': qty
    });
  }

  $scope.$watch('dataList', function () {
    setDefaultQuantity();
    refreshAddCartButton();
  });

  function refreshAddCartButton() {
    if (!!$scope.dataList) {
      _.forEach($scope.dataList, function (product) {
        if (product.default)
          product.selected = checkItemInCart(product.default.detail_id);
      })
    }
  }
  /**
   * check if the item has been added to the PR previously
   * @param id
   * @returns {boolean}
   */
  function checkItemInCart(id) {
    return prFunction.getPRItemsIDs().indexOf(id) !== -1;
  }

  function initialize() {
    $scope.validPaymentTerm = paymentTerms.data;
    $scope.PR = prFunction.getPRData();
    $scope.resetForm();
    setCategoriesView();
  }
  initialize();
}

prItemAdvancedSearchCtrl.$inject = [
  '$scope', 'toastr', '$rootScope', 'advancedSearch', 'exportSearch', 'itemsCategories', 'metabuyerCache', 'catalogFunction', 'prFunction', '$purchaseRequisition', 'paymentTerms', 'prConfig', 'purchaseRequisitionsServices', 'globalFunc'
];

angular.module('metabuyer')
  .controller('prItemAdvancedSearchCtrl', prItemAdvancedSearchCtrl);
