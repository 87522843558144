'use strict';

/**
 * @name alcDetailsHeader Directive
 * @desc Directive for displaying alc details header, editable and non editable
 *
 * @author Robby Fratina <rfrob30@gmail.com>
 * @copyright 2020 Metacloud Sdn. Bhd.
 */
angular
  .module('metabuyer')
  .directive('alcDetailsHeader', function () {
    return {
      restrict: 'E',
      scope: true,
      template: '<div ng-include="alcTemplateUrl"></div>',
      controller: function ($scope, $state) {
        $scope.getTemplateUrl = getTemplateUrl;
        $scope.positionUpdated = positionUpdated;
        $scope.alcGroupUpdated = alcGroupUpdated;

        if (!_.isEmpty($scope.alcData)) {
          $scope.alcData.start_date = !!$scope.alcData.start_date ? convertToLocalDate($scope.alcData.start_date) : null;
          $scope.alcData.end_date = !!$scope.alcData.end_date ? convertToLocalDate($scope.alcData.end_date) : null;
        }

        function positionUpdated() {
          if (!_.isEmpty($scope.alcData.position)) {
            delete $scope.alcData.position;
          }
        }

        function alcGroupUpdated() {
          if (!_.isEmpty($scope.alcData.alc_group)) {
            delete $scope.alcData.alc_group;
          }
        }

        function convertToLocalDate(date) {
          return new Date(parseInt(date));
        }

        function getTemplateUrl(state) {
          if ($scope.alcData.status === 'DRAFT') {
            $scope.alcTemplateUrl = 'components/alc/detailsHeader/detailsHeaderEditableTemplate.html';
          } else {
            switch (state) {
              case 'editable':
                $scope.alcTemplateUrl = 'components/alc/detailsHeader/detailsHeaderEditableTemplate.html';
                break;
              case 'notEditable' :
                $scope.alcTemplateUrl = 'components/alc/detailsHeader/detailsHeaderNonEditableTemplate.html';
                break;
              default:
                $scope.alcTemplateUrl = 'components/alc/detailsHeader/detailsHeaderNonEditableTemplate.html';
                break;
            }
          }
        }

        if($state.includes('main.user.alc.add')){
          getTemplateUrl('editable');
        } else {
          getTemplateUrl('notEditable');
        }


        $scope.$on('switchAlcTemplate', function (event, value) {
          if(value === 'editable')
            $scope.disableCodeEdit = true;

          getTemplateUrl(value);
        });


      }
    }
  });
