'use strict';

angular.module('metabuyer')
  .controller('ClosePOCtrl', function ($scope, $uibModalInstance, Orders, toastr, HighlightId, $interval, $state) {
    $scope.next_close = true;
    $scope.back_close = false;
    $scope.commentText = "";
    $scope.enable_next = false;
    $scope.check_for_all = false;
    $scope.close_po_data = [];

    $scope.next = next;
    $scope.cancel = cancel;
    $scope.backOnClosePO = backOnClosePO;
    $scope.checkedLineItem = checkedLineItem;
    $scope.checkedAllLineItems = checkedAllLineItems;
    $scope.prepareClosePurchaseOrder = prepareClosePurchaseOrder;
    $scope.initialize = initialize;

    //next action
    function next(){
      $scope.next_close = false;
      $scope.back_close = true;
    }

    //cancel action
    function cancel(){
      $uibModalInstance.close();
    }

    //back action
    function backOnClosePO(){
      $scope.next_close = true;
      $scope.back_close = false;
    }

    function checkedLineItem(){
      $scope.check_for_all = true;
      $scope.enable_next = false;
      _.forEach ($scope.close_po_data, function(item){
        if(item.selected){
          $scope.enable_next = true;
        }
        if (!item.selected && !item.po_item.closed){
          $scope.check_for_all = false;
          return;
        }
      });

    }

    //check all line items
    function checkedAllLineItems(){
      if($scope.check_for_all){
        $scope.enable_next = true;

      } else {
        $scope.enable_next = false;
      }

      _.forEach($scope.close_po_data, function(item) {
        if (!!item.po_item) {
          if (!!item.po_item.cancelled && item.po_item.cancelled === true) {
            return;
          }
          if (!!item.po_item.order && !!item.po_item.order.delivered) {
            if (!item.po_item.closed && (item.po_item.order.delivered !== item.po_item.quantity)) {
              item.selected = $scope.enable_next;
            }
          }
          else {
            /**
             * no delivery occurred
             * @type {boolean}
             */
            item.selected = $scope.enable_next;
          }
        }
      });
    }

    /**
     * Prepare close purchase order data and confirmation
     */
    function prepareClosePurchaseOrder(){

      var indexes = [];
      var showSwalConfirmation = false;
      _.forEach($scope.close_po_data, function (item) {
        if(!item.selected){
          return;
        }

        if(item.po_item.closed){
          return;
        }

        // If selected item is split by amount
        if (item.selected && !!item.po_item.split_type && item.po_item.split_type === 'amount') {
          showSwalConfirmation = true;
        }

        indexes.push(item.itemIndex);
      });
      /**
       * Extra check to prevent the closing in case of data not being updated
       */
      if (indexes.length < 1) {
        toastr.error('An error has occurred. Unable to close the PO.');
        return;
      }

      // If there is an item selected that is split by amount, confirmation is needed
      if (showSwalConfirmation) {
        // Sweet alert confirmation if closing item that has linked PO
        swal({
          title: 'Confirm close item(s)?',
          text: 'Item(s) on related PO(s) will also be closed.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true
        }, function (isConfirm) {
          if (!!isConfirm) {
            closePurchaseOrder(indexes);
          }
        });
      }
      else {
        // If PO v2, validate comment to be non empty
        if (!!$scope.po.document_rules && !!$scope.po.document_rules.pr_version && $scope.po.document_rules.pr_version === 'v2') {
          if (_.isEmpty($scope.commentText)) {
            toastr.error('Comments to close PO is required.');
            return;
          }
        }
        closePurchaseOrder(indexes);
      }
    }

    /**
     * Close purchase order
     *
     * @param indexes The array of item index selected
     */
    function closePurchaseOrder(indexes) {
      var flagErrorOccurred = false;
      /**
       * Set the interval for closing multiple items
       */
      var closing = $interval(function () {
        Orders.closeLineItem({po_id: $scope.po._id, item_id: indexes, comment: $scope.commentText},
          function (success) {
          },
          function (error) {
            flagErrorOccurred = true;
            $interval.cancel(closing);
          });
      }, 1200, 1).then(function(){

        if(flagErrorOccurred === false){
          toastr.success('PO / line items closed successfully.');
          HighlightId.setId($scope.po._id);
          if(indexes.length === $scope.close_po_data.length){
            $state.go('main.user.orders.manage', {status: 'CLOSED'});
          } else {
            $state.go('main.user.orders.manage', {status: 'AWAITING_DELIVERY'});
          }
        }else{
          toastr.error('Failed to close the PO / line items.');
        }
        $uibModalInstance.close();
      });
    }

    function initialize() {
      _.forEach($scope.po.items, function(item, i){
        item.remaining_quantity = math.subtract(math.bignumber(item.quantity), math.bignumber(item.order.delivered));
        item.remaining_quantity = math.format(item.remaining_quantity, {precision: 64});
        $scope.close_po_data.push(
          {
            po_item: item,
            itemIndex: i
          }
        );
      });
    }

    initialize();
  });
