/**
 * @name embeddedPaginationService
 *
 * @requires $scope
 * @requires $rootScope
 *
 * @description
 *
 * Directive for embedded pagination used without supporting URL
 * This pagination is different from the Pagination service as it depends on loading the data within a different module
 *
 * $watcher
 * to check if new Data has been sent into the directive in order to refresh the count, as well as initializes it
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @author Justin Cheong <justin.cty90@gmail.com>
 * @copyright 2018 Metacloud Sdn. Bhd.
 */

'use strict';
angular
  .module('metabuyer')
  .directive('embeddedPaginationService', function () {
    return {
      restrict: 'E',
      templateUrl: 'components/pagination/paginationTemplate.html',
      scope: {
        list: '=',
        service: '=',
        paramsSource: '=?',
        offset: '=?offset',
        meta: '=',
        onPaginate: '&?'
      },
      controller: function ($scope, $rootScope) {
        $scope.disablePaginationButtons = false;
        $scope.pageCount = 0;
        $scope.goNextPage = goNextPage;
        $scope.goPreviousPage = goPreviousPage;

        function initialize() {
          //TODO: prepare the data accordingly in case of a support of pages (at this moment only Elements) (Ahmed Saleh)
          if (!!$scope.meta && $scope.meta.cursor) {
            $scope.currentPageCode = $scope.meta.cursor.current || $scope.meta.cursor.current_page;
            $scope.nextPageCode = $scope.meta.cursor.next;
            $scope.previousPageCode = $scope.meta.cursor.prev;

            if (!!$scope.meta.cursor.current) {
              $scope.currentCountStart = atob($scope.currentPageCode);
            } else {
              $scope.currentCountStart = 0;
            }

            if ($scope.offset === undefined) {
              $scope.offset = $scope.list.length;
            }
            if (!$scope.previousPageCode && $scope.list.length > 0) {
              $scope.currentCountStart = 1;
            }

            if ($scope.meta.cursor.count !== $scope.list.length) {
              $scope.currentCountEnd = $scope.list.length + Number($scope.currentCountStart);

              if (Number($scope.currentCountStart) === 1) {
                $scope.currentCountEnd--;
              } else {
                $scope.currentCountStart++;
              }
            } else {
              $scope.currentCountEnd = $scope.list.length;
            }
            $scope.totalCount = $scope.meta.cursor.count || $scope.meta.cursor.total;

          } else {
            $scope.currentPageCode = null;
            $scope.nextPageCode = null;
            $scope.previousPageCode = null;

            $scope.currentCountStart = 0;
            $scope.currentCountStart = 0;

          }
        }

        /**
         * Function to reload the data for the listing
         */
        function reloadData() {
          $scope.service.get($scope.paramsSource,
            function (resource) {
              if (!!resource.content) {
                $scope.list = resource.content.data;
                //check if there is a callback for pagination
                if(!!$scope.onPaginate) {
                  $scope.onPaginate({items: resource.content.data});
                }
                if (!!resource.content.meta && resource.content.meta.cursor) {
                  $scope.meta.cursor = resource.content.meta.cursor;
                  $scope.currentPageCode = resource.content.meta.cursor.current ||
                                           resource.content.meta.cursor.current_page;

                  // to support number and encoded page number
                  if (typeof $scope.currentPageCode === 'number') {
                    $scope.currentCountStart = (
                      (Number($scope.currentPageCode) * $scope.offset) - $scope.offset
                    )  + 1;
                  } else {
                    $scope.currentCountStart = Number(atob($scope.currentPageCode)) + 1;
                  }
                  $scope.currentCountEnd = $scope.currentCountStart + $scope.list.length - 1;
                  $scope.nextPageCode = resource.content.meta.cursor.next;
                  $scope.previousPageCode = resource.content.meta.cursor.prev;
                }
              }
            });
        }

        function goNextPage() {
          if (typeof $scope.nextPageCode === 'number') {
            $scope.paramsSource['page'] = $scope.nextPageCode;
          } else {
            $scope.paramsSource['cursor'] = $scope.nextPageCode;
          }
          $scope.disablePaginationButtons = true;
          $scope.service.get($scope.paramsSource,
            function (resource) {
              if (!!resource.content) {
                $scope.list = resource.content.data;
                //check if there is a callback for pagination
                if(!!$scope.onPaginate) {
                  $scope.onPaginate({items: resource.content.data});
                }
                $scope.disablePaginationButtons = false;
                if (!!resource.content.meta && !!resource.content.meta.cursor) {
                  $scope.meta.cursor = resource.content.meta.cursor;
                  $scope.pageCount++;
                  $scope.currentPageCode = resource.content.meta.cursor.current || resource.content.meta.cursor.current_page;

                  // to support number and encoded page number
                  if (typeof $scope.currentPageCode === 'number') {
                    $scope.currentCountStart = (
                      (Number($scope.currentPageCode) * $scope.offset) - $scope.offset
                    )  + 1;
                  } else {
                    $scope.currentCountStart = Number(atob($scope.currentPageCode)) + 1;
                  }

                  $scope.currentCountEnd = $scope.currentCountStart + $scope.list.length - 1;
                  if ($scope.currentCountEnd > $scope.totalCount) {
                    $scope.currentCountEnd = $scope.totalCount;
                  }
                  $scope.nextPageCode = resource.content.meta.cursor.next;
                  $scope.previousPageCode = resource.content.meta.cursor.prev;
                }
              }
            },
            function () {
              $scope.disablePaginationButtons = false;
            }
          );
        }

        function goPreviousPage() {
          if (typeof $scope.previousPageCode === 'number') {
            $scope.paramsSource['page'] = $scope.previousPageCode;
          } else {
            $scope.paramsSource['cursor'] = $scope.previousPageCode;
          }

          $scope.disablePaginationButtons = true;
          $scope.service.get($scope.paramsSource,
            function (resource) {
              if (!!resource.content) {
                $scope.list = resource.content.data;
                //check if there is a callback for pagination
                if(!!$scope.onPaginate) {
                  $scope.onPaginate({items: resource.content.data});
                }
                $scope.disablePaginationButtons = false;
                if (!!resource.content.meta && resource.content.meta.cursor) {
                  $scope.meta.cursor = null;
                  $scope.meta.cursor = resource.content.meta.cursor;
                  $scope.pageCount--;
                  $scope.currentPageCode = resource.content.meta.cursor.current || resource.content.meta.cursor.current_page;

                  if (typeof $scope.currentPageCode === 'number') {
                    $scope.currentCountStart = (
                      (Number($scope.currentPageCode) * $scope.offset) - $scope.offset
                    )  + 1;
                  } else {
                    $scope.currentCountStart = Number(atob($scope.currentPageCode)) + 1;
                  }

                  $scope.currentCountEnd = $scope.currentCountStart + $scope.list.length - 1;
                  if ($scope.currentCountEnd > $scope.totalCount) {
                    $scope.currentCountEnd = $scope.totalCount;
                  }
                  $scope.nextPageCode = resource.content.meta.cursor.next;
                  $scope.previousPageCode = resource.content.meta.cursor.prev;
                }
              }
            },
            function () {
              $scope.disablePaginationButtons = false;
            }
          );
        }

        /**
         *  Receive broadcast for initializing the pagination
         */
        $scope.$on('initializePagination', function () {
          initialize();
        });

        /**
         * Receive broadcast for reloading data
         */
        $scope.$on('reloadData', function () {
          reloadData();
        });

        /**
         *  Receive broadcast for get the new list and reset the counts
         */
        $scope.$on('resetPagination', function (event, args) {
          if (!!args.data) {
            $scope.list = args.data;
          }
          if (!!args.meta) {
            $scope.cursor = args.meta.cursor;
          }
          $scope.offset = args.offset;
          $scope.pageCount = 0;
          initialize();
        });

        $scope.$watch('meta', function () { 
          initialize();
        });

        $scope.$watch('meta.cursor.current', function () {
          initialize();
        });
      }
    };
  });
