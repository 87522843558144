'use strict';

function otherChargesGroup() {
  return {
    restrict: 'AE',
    templateUrl: 'components/purchaseRequisitionV2Components/otherChargesGroup/otherChargesGroup.html',
    controller: function ($scope, prV2Function, $http, $filter, pathConstants) {
      $scope.PR = prV2Function.getPRData();
      $scope.getOtherChargesGroupSearch = getOtherChargesGroupSearch;
      $scope.submissionValidation = submissionValidation;

      function submissionValidation(field) {
        var errors = prV2Function.getErrorsOnSubmit();
        return (errors.indexOf(field) > -1);
      }

      // drop-down listing
      function getOtherChargesGroupSearch(query, item){

        var moduleParam = {
          module: 'other-charges-groups'
        };

        var additionalParams = {
          'criteria[0][code]': query,
          'criteria[0][name]': query,
          'criteria[1][company_code]': $scope.PR.company.code,
          'criteria[2][is_active]': true,
          'criteria[3][supplier|code]': item.supplier.code
        };
        return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
          params: additionalParams,
          ignoreLoadingBar: true
        }).then(function (response) {
          if (response.data.content.data.length === 0) {
            return [];
          } else {
              return response.data.content.data.map(function (item) {
                return item;
              });
          }
        });
      }
    }
  }
}

angular
  .module('metabuyer')
  .directive('otherChargesGroup', otherChargesGroup);
