'use strict';

function cerfCtrl($rootScope, $scope, globalFunc, searchModule, pathConstants, $http, $filter, singleAssetCategoryByCode) {
  $scope.searchAssetCategories = searchAssetCategories;
  $scope.onSelectAssetCategory = onSelectAssetCategory;
  $scope.onSelectAssetMainNumber = onSelectAssetMainNumber;
  $scope.searchAssetMainNumber = searchAssetMainNumber;
  $scope.onSelectAssetSubNumber = onSelectAssetSubNumber;
  $scope.searchAssetSubNumber = searchAssetSubNumber;
  $scope.onSelectReplacementAssetMainNumber = onSelectReplacementAssetMainNumber;
  $scope.onSelectReplacementAssetSubNumber = onSelectReplacementAssetSubNumber;
  $scope.onSelectAcquisitionType = onSelectAcquisitionType;
  $scope.checkAssetUsefulLife = checkAssetUsefulLife;
  $scope.replaceReplacementAssetNumber = replaceReplacementAssetNumber;
  $scope.replaceAssetCategory = replaceAssetCategory;
  $scope.formSubmitted = false;
  $scope.isProjectCodeMandatory = $scope.company.requisition_settings.project_code_capex_mandatory && $scope.company.requisition_settings.project_code_capex_mandatory.is_enabled;
  $scope.capexEvaluationOneListing = [
    {
      code: null,
      descr: 'N/A'
    },
    {
      code: "BD20",
      descr: "Bldg; with UL=20yrs"
    },
    {
      code: "BD30",
      descr: "Bldg; with UL=30yrs"
    },
    {
      code: "BD50",
      descr: "Bldg; with UL=50yrs"
    },
    {
      code: "BI",
      descr: "BI"
    },
    {
      code: "COMM",
      descr: "Vconf/PABX/VOIP/Network Infra"
    },
    {
      code: "COMP",
      descr: "Computer/Handheld/RAM/CD/DVD"
    },
    {
      code: "CONV",
      descr: "Data Migration"
    },
    {
      code: "CPM",
      descr: "Finance CPM & Conso System"
    },
    {
      code: "CR",
      descr: "Checkroll"
    },
    {
      code: "CSR",
      descr: "CSR Efforts"
    },
    {
      code: "CXC",
      descr: "CxC"
    },
    {
      code: "DS",
      descr: "Downstream"
    },
    {
      code: "EPRO",
      descr: "eProcurement Metabuyer"
    },
    {
      code: "EINV",
      descr: "E-Invoicing Middleware"
    },
    {
      code: "FICO",
      descr: "SAP FICO"
    },
    {
      code: "HRMS",
      descr: "SAP HRMS"
    },
    {
      code: "IPRS",
      descr: "iPRS"
    },
    {
      code: "ITSM",
      descr: "ITSM"
    },
    {
      code: "LX",
      descr: "Lintramax"
    },
    {
      code: "MIHW",
      descr: "MISC HW-USB/UPS/AVR/Tape/Print"
    },
    {
      code: "MISW",
      descr: "Misc Software"
    },
    {
      code: "MS",
      descr: "Microsoft SW"
    },
    {
      code: "MSGP",
      descr: "MSGP"
    },
    {
      code: "MV-C",
      descr: "MV – Commercial"
    },
    {
      code: "MV-E",
      descr: "MV – Employee"
    },
    {
      code: "NCSR",
      descr: "Non CSR"
    },
    {
      code: "NETW",
      descr: "Network Tool-router/switch/SAN"
    },
    {
      code: "OP",
      descr: "OPMAS"
    },
    {
      code: "PM10",
      descr: "Plant & Mach Other; UL=10yrs"
    },
    {
      code: "PM20",
      descr: "Plant & Mach Other; UL=5yrs"
    },
    {
      code: "PM4Y",
      descr: "Plant & Mach; UL=4yrs"
    },
    {
      code: "PMRV",
      descr: "Plant & Mach; UL=10yrs"
    },
    {
      code: "SAP",
      descr: "SAP"
    },
    {
      code: "SERV",
      descr: "Svr/Svr Rack/CRAC/Inergen"
    },
    {
      code: "WIZ",
      descr: "WizProperty"
    }
  ];

  $scope.capexEvaluationTwoListing = [
    {
      code: null,
      descr: 'N/A'
    },
    {
      code: "CONV",
      descr: "Data Migration"
    },
    {
      code: "ILIC",
      descr: "IT LIC-subscribe/renew HW SW"
    },
    {
      code: "IOP",
      descr: "IT day to day operations"
    },
    {
      code: "IPRO",
      descr: "IT Project-HW SW initial req"
    },
    {
      code: "ISEC",
      descr: "IT Security-audit,monitor,supt"
    },
    {
      code: "IUP",
      descr: "IT Upgrade-enhance exist HW SW"
    }
  ];

  $scope.purposeOfAcquisitionOneListing = [
    {
      code: "CR",
      descr: "Corporate Responsibility"
    },
    {
      code: "RSPO",
      descr: "RSPO Compliance"
    },
    {
      code: "NONE",
      descr: "None"
    }
  ];

  $scope.purposeOfAcquisitionTwoListing = [
    {
      code: "ENV",
      descr: "Improve of Environment"
    },
    {
      code: "IMPVPROD",
      descr: "Improve Productivity"
    },
    {
      code: "PROJECT",
      descr: "New Project"
    },
    {
      code: "WELFARE",
      descr: "Staff Welfare"
    },
    {
      code: "COMPLY",
      descr: "Compliance"
    },
    {
      code: "SUSTAIN",
      descr: "Sustainability"
    },
    {
      code: "OTHER",
      descr: "Others"
    }
  ];

  function replaceAssetCategory(){
    if (!_.isEmpty($scope.cerfObject.asset_category)) {
      delete $scope.cerfObject.asset_category;
    }
  }

  function replaceReplacementAssetNumber(key) {
    delete $scope.cerfObject[key];
  }

  function onSelectReplacementAssetMainNumber(item){
    $scope.cerfObject.replacement_asset_main_number = prepareAssetData(item, 'main');
    $scope.replacementMainNumberExists = false;

    // auto populate replacement asset sub number
    onSelectReplacementAssetSubNumber(item);
    $scope.replacementMainSearch.search = item.asset_main_number + ' - ' + item.name;
    $scope.replacementSubSearch.search = item.asset_sub_number + ' - ' + item.name;
  }

  function onSelectReplacementAssetSubNumber(item){
    $scope.cerfObject.replacement_asset_sub_number = prepareAssetData(item);
    $scope.replacementSubNumberExists = false;
  }

  function onSelectAssetMainNumber(item){
    $scope.cerfObject.asset_main_number = prepareAssetData(item, 'main');
    $scope.mainNumberExists = false;
  }

  function onSelectAssetSubNumber(item){
    $scope.cerfObject.asset_sub_number = prepareAssetData(item);
    $scope.subNumberExists = false;
  }

  function prepareAssetData(item, flag) {
    var res = {};

    if (flag === 'main') {
      res.asset_main_number = item.asset_main_number;
    } else {
      res.asset_sub_number = item.asset_sub_number;
    }

    res.name = item.name;
    res.date_of_purchase = item.capitalization_date;
    res.cumulative_depreciation = item.cumulative_depreciation;
    res.net_book_value = item.net_book_value;
    res.purchase_cost = item.purchase_cost;

    return res;
  }

  function checkAssetUsefulLife() {
    if (!_.isEmpty($scope.cerfObject) && !_.isEmpty($scope.cerfObject.asset_category)) {
      return !$scope.cerfObject.asset_category.asset_useful_life && $scope.cerfObject.asset_category.asset_useful_life !== 0;
    }
  }

  function onSelectAssetCategory(item){
    $scope.cerfObject.asset_category = {
      code: item.code,
      name: item.name,
      account_code: item.account_code,
      asset_useful_life: item.asset_useful_life
    };
  }

  function onSelectAcquisitionType() {
    if (!!$scope.cerfObject && $scope.cerfObject.type_of_acquisition !== 'Replacement'){
      delete $scope.cerfObject.replacementMainSearch;
      delete $scope.cerfObject.replacementSubSearch;
    }

    $scope.assetMainSearch.search = '';
    $scope.assetSubSearch.search = '';
    delete $scope.cerfObject.asset_main_number;
  }

  function searchAssetCategories(query){
    var moduleParam = {
      module: 'asset-category'
    };

    var params = {
      'criteria[0][code]': query,
      'criteria[0][name]': query,
      'criteria[0][$operator]': 'or',
      'criteria[1][company|code]': $scope.company.code,
      'criteria[1][$strict]': true,
      'criteria[2][is_active]': true,
      criteria_operator: 'and',
      offset: 10
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: params
      }).then(function (response) {
      if (response.data.content.data.length === 0) {
        return [];
      } else {
        return response.data.content.data.map(function (item) {
          return item;
        });
      }
    });
  }

  function searchAssetMainNumber(query){
    var moduleParam = {
      module: 'company-asset'
    };

    var params = {
      'criteria[0][asset_main_number]': query,
      'criteria[0][name]': query,
      'criteria[0][$operator]': 'or',
      'criteria[1][company|code]': $scope.company.code,
      'criteria[2][is_active]': true,
      criteria_operator: 'and',
      offset: 10
    };

    if (!!$scope.cerfObject.asset_category && !!$scope.cerfObject.asset_category.code)
      params['criteria[3][asset_category|code]'] = $scope.cerfObject.asset_category.code;

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: params
      }).then(function (response) {
      if (response.data.content.data.length === 0) {
        return [];
      } else {
        return response.data.content.data.map(function (item) {
          return item;
        });
      }
    });
  }

  function searchAssetSubNumber(query, isReplacement){
    var mainNumber = $scope.cerfObject.asset_main_number;
    var assetMainNumber = '';

    if (!!isReplacement) {
      var mainNumber = $scope.cerfObject.replacement_asset_main_number;
    }

    if (_.isEmpty(mainNumber)) {
      return;
    } else {
      if (!_.isEmpty(mainNumber.asset_main_number)) {
        assetMainNumber = mainNumber.asset_main_number;
      } else {
        assetMainNumber = $scope.assetMainNumberSearch.search;
      }
    }

    var moduleParam = {
      module: 'company-asset'
    };

    var params = {
      'criteria[0][asset_sub_number]': query,
      'criteria[0][name]': query,
      'criteria[0][$operator]': 'or',
      'criteria[1][company|code]': $scope.company.code,
      'criteria[2][is_active]': true,
      'criteria[3][asset_main_number]': assetMainNumber,
      criteria_operator: 'and',
      offset: 10
    };

    if (!!$scope.cerfObject.asset_category && !!$scope.cerfObject.asset_category.code)
      params['criteria[4][asset_category|code]'] = $scope.cerfObject.asset_category.code;

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: params
      }).then(function (response) {
      if (response.data.content.data.length === 0) {
        return [];
      } else {
        return response.data.content.data.map(function (item) {
          return item;
        });
      }
    });
  }

  $scope.$on('submittingCerf', function() {
    $scope.formSubmitted = true;
  });

  function initialize() {
    $scope.isFinanceVerifier = !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Finance Verifier');

    if ((!_.isEmpty($scope.nonCatalog.budget) || !_.isEmpty($scope.nonCatalog.project_code)) && $scope.expenseType.category === 'CAPEX') {

      // if capex unbudgeted and setting is off, there is no auto population feature from either budget/project
      if ($scope.expenseType.code === 'CapexUnBud' && !$scope.isProjectCodeMandatory) {
        return
      }

      $scope.cerfObject.asset_category = $scope.isProjectCodeMandatory ? $scope.nonCatalog.project_code.asset_category : $scope.nonCatalog.budget.asset_category;

      if (!_.isEmpty($scope.cerfObject.asset_category)) {
        $scope.assetCategorySearch.search = $scope.cerfObject.asset_category.code + ' - ' + $scope.cerfObject.asset_category.name;
      }

      if (!_.isEmpty($scope.cerfObject.asset_category) && _.isEmpty($scope.cerfObject.asset_category.asset_useful_life)) {
        singleAssetCategoryByCode.get({
            code: $scope.cerfObject.asset_category.code,
            companyCode: $scope.company.code
          },
          function (resource) {
            if (!!resource.content && !!resource.content.data) {
              var result = resource.content.data;
              $scope.cerfObject.asset_category.asset_useful_life = result.asset_useful_life;
            }
          },
          function (error) {
            globalFunc.error(error);
          }
        );
      } else {
        if (!_.isEmpty($scope.cerfObject.asset_category)) {
          $scope.cerfObject.asset_category.asset_useful_life = $scope.nonCatalog.budget.asset_category.asset_useful_life;
        }
      }
    }

    if (!!$scope.cerfObject.asset_main_number) {
      $scope.assetMainSearch.search = ($scope.cerfObject.asset_main_number.name.length === 0)
        ? $scope.cerfObject.asset_main_number.asset_main_number
        : $scope.cerfObject.asset_main_number.asset_main_number + ' - ' + $scope.cerfObject.asset_main_number.name;
      $scope.mainNumberExists = true;
    }

    if (!!$scope.cerfObject.asset_sub_number) {
      $scope.assetSubSearch.search = ($scope.cerfObject.asset_sub_number.name.length === 0)
        ? $scope.cerfObject.asset_sub_number.asset_sub_number
        : $scope.cerfObject.asset_sub_number.asset_sub_number + ' - ' + $scope.cerfObject.asset_sub_number.name;
      $scope.subNumberExists = true;
    }

    if (!!$scope.cerfObject.replacement_asset_main_number) {
      $scope.replacementMainSearch.search = ($scope.cerfObject.replacement_asset_main_number.name.length === 0)
        ? $scope.cerfObject.replacement_asset_main_number.asset_main_number
        : $scope.cerfObject.replacement_asset_main_number.asset_main_number + ' - ' + $scope.cerfObject.replacement_asset_main_number.name;
      $scope.replacementMainNumberExists = true;
    }

    if (!!$scope.cerfObject.replacement_asset_sub_number) {
      $scope.replacementSubSearch.search = ($scope.cerfObject.replacement_asset_sub_number.name.length === 0)
        ? $scope.cerfObject.replacement_asset_sub_number.asset_sub_number
        : $scope.cerfObject.replacement_asset_sub_number.asset_sub_number + ' - ' + $scope.cerfObject.replacement_asset_sub_number.name;
      $scope.replacementSubNumberExists = true;
    }
  }

  initialize();
}

cerfCtrl.$inject = ['$rootScope', '$scope', 'globalFunc', 'searchModule', 'pathConstants', '$http', '$filter',
'singleAssetCategoryByCode'];

angular
  .module('metabuyer')
  .directive('cerf', function () {
    return {
      restrict: 'EA',
      templateUrl: 'components/purchaseRequisitionV2Components/cerf/cerfTemplate.html',
      controller: 'cerfCtrl',
      scope: {
        cerfObject: '=?',
        company: '=?',
        nonCatalog: '=?',
        expenseType: '=?',
        assetMainSearch: '=?',
        assetSubSearch: '=?',
        replacementMainSearch: '=?',
        replacementSubSearch: '=?',
        assetCategorySearch: '=?'
      }
    }
  })
  .controller('cerfCtrl', cerfCtrl);
