/**
 * @name loading
 *
 * @description
 * Directive to display loading process
 *
 * sample setting
 * $scope.loadingSetting = {
 *   text: 'Saving...',
 *   show: false, // boolean
 *   fontSize: 18, // optional, if empty will follow the element font size
 *   color: '#000000' // optional, if empty will follow the element color
 * };
 *
 * To use the directive
 * pass the setting configuration to the setting param
 * <loading setting="loadingSetting"></loading>
 *
 * @author Deniel
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

angular.module('metabuyer')
  .directive('loading', function ($timeout) {
    return {
      restrict: 'E',
      replace: 'true',
      templateUrl: 'components/loading/loading.html',
      scope: {
        setting: '='
      },
      link: function ($scope, element, attrs) {
        $scope.text = $scope.setting.text;

        function changeStatus(show) {
          if(!!show)
            showText();
          else
            hideText();
        }

        function showText() {
          element.css('display', 'block');
          element.css('opacity', '1');
          element.css('height', 'auto');
        }

        function hideText() {
          $timeout(function() {
            element.css('opacity', '0');
            element.css('height', '0');
          }, 1000);
        }

        function initialize() {
          if (!!$scope.setting) {
            if (!!$scope.setting.fontSize)
              element.css('font-size', Number($scope.setting.fontSize));

            if (!$scope.setting.show)
              element.css('display', 'none');

            if (!!$scope.setting.color)
              element.css('color', $scope.setting.color);
          }
        }

        initialize();

        $scope.$watch('setting', function(newData){
          $scope.text = newData.text;
          changeStatus(newData.show);
        }, true);
      }
    }
  });
