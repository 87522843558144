'use strict';

function confirmationModalCtrl($scope, $state, $uibModalInstance, items, header, preparedItems, consolidate, globalFunc, toastr, pathConstants ) {
    $scope.imageBasePath = pathConstants.apiUrls.image.pr;
    $scope.items = items;
    $scope.header = header;
    $scope.preparedItems = preparedItems;
    $scope.submitPoObject = submitPoObject;
    $scope.close = close;
    $scope.sending = false;

    function close(){
        $uibModalInstance.close();
    }

  function submitPoObject() {
    var data = $scope.preparedItems;
    $scope.sending = true;

    consolidate.post(
      {},
      data,
      function (response) {
        if (!!response && !!response.content) {
          $scope.sending = false;
          toastr.info('Uploading files...');
          $scope.$emit('poCreated', response.content._id);
          $uibModalInstance.close();
          swal(response.content.message);
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
        $uibModalInstance.close();

        var itemUuid = [];
        _.forEach(error.content, function (itemId, key) {
          if (key === 'message') {
            return;
          }
          itemUuid.push(itemId);
        });

        _.forEach($scope.items, function (item) {
          if (itemUuid.includes(item.item_uuid)) {
            item.invalidItem = true
          }
        })
      }
    );
  }
}

confirmationModalCtrl.$inject = [
    '$scope', '$state', '$uibModalInstance', 'items', 'header', 'preparedItems', 'consolidate', 'globalFunc' , 'toastr', 'pathConstants'
];

angular
  .module('metabuyer')
  .controller('confirmationModalCtrl', confirmationModalCtrl);
