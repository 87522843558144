'use strict';

angular.module('metabuyer.services.notes', ['ngResource'])

.factory('supplierNote',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.purchaseRequisitions.supplierNotes,
      {
        id: '@id'
      },
      {
        'get': { method: 'GET'},
        'patch': { method: 'PATCH'}
      }
    );
  })
  .factory('prSupplier',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.purchaseRequisitions.suppliers,
        {
          id: '@id'
        },
        {
          'get': { method: 'GET' }
        }
      );
    });
