'use strict';
/**
 * @name masterExport Directive
 * @desc Directive for generating export CSV
 *
 * @isolatedScope moduleName
 * To accept the module name to be displayed with button
 *
 * @isolatedScope collection
 * To determine which module to generate CSV for
 *
 * @isolatedScope companyId
 * Exclusive for company exchange rate, to pass company id for exporting
 *
 * @isolatedScope companyCode
 * Exclusive for company exchange rate, to pass company code for exporting
 *
 * @isolatedScope checkingList
 * To determine whether the PR list exist for exporting
 *
 * @author Justin Cheong Tian Yee <justin.cty90@gmail.com>
 * @copyright Sunway Metacloud &copy; 2016
 * @internal Ensure that exportSearch.setParams() are called for every new export button
 * so that listing query does not taint the export query
 */
angular
  .module('metabuyer')
  .directive('masterExport',function() {
    return {
      restrict: 'E',
      templateUrl: 'components/masterExportCSV/masterExportCSV-template.html',
      scope: {
        moduleName: '@',
        collection: '@',
        companyId: '=?',
        companyCode: '=?',
        disableButton: '=?',
        advancedSearch: '=?'
      },
      controller: function ($scope, $state, toastr, exports, checkExportStatus, $interval, pathConstants,
                            $filter, globalFunc, $stateParams, exportSearch, $uibModalStack) {

        $scope.generateExportCSV = generateExportCSV;

        /**
         * Function to download the export, this will be called automatically once generation is done
         * @param hash
         */
        function downloadExport(hash){
          var newUrl = $filter('format')(pathConstants.apiUrls.export.download, {
            export_hash: hash
          });
          window.open(newUrl, '_self');
        }

        /**
         * Function to check the export status with intervals
         * @param id
         */
        function checkExport(id){

          checkExportStatus.get(
            {
              id: id
            },
            function(resource){
              if(!!resource.content && !!resource.content.data &&
                !!resource.content.message && !!resource.content.data.status){

                /**
                 * Clearing the toastr before every call for status checking to simulate 'refreshing' the toastr
                 */
                toastr.clear();

                var checkingStatus = true;

                /**
                 * Status for export CSV
                 * 0 = PENDING
                 * 1 = IN_PROGRESS
                 * 2 = DONE
                 * 3 = FAILED
                 * 4 = CANCELLED
                 */
                switch(resource.content.data.status){
                  case 0:
                    toastr.info(resource.content.message, {timeOut: 0, extendedTimeOut: 0});
                    break;
                  case 1:
                    toastr.info(resource.content.message, {timeOut: 0, extendedTimeOut: 0});
                    break;
                  case 2:
                    /**
                     * If has is returned
                     */
                    if(!!resource.content.data.hash){
                      toastr.success(resource.content.message,
                        {timeOut: 10000, extendedTimeOut: 0, onClose: downloadExport(resource.content.data.hash)});

                      if ($scope.collection === '40') {
                        $uibModalStack.dismissAll();
                      }
                    }
                    /**
                     * Else condition to handle case if hash not returned by backend
                     */
                    else{
                      toastr.error('There was an error with the export. Please try again');
                    }
                    checkingStatus = false;
                    $interval.cancel($scope.startChecking);
                    break;
                  case 3:
                    checkingStatus = false;
                    toastr.error(resource.content.message, {timeOut: 0, extendedTimeOut: 0});
                    $interval.cancel($scope.startChecking);
                    break;
                  case 4:
                    checkingStatus = false;
                    toastr.info(resource.content.message, {timeOut: 0, extendedTimeOut: 0});
                    $interval.cancel($scope.startChecking);
                    break;
                }

                $scope.checkingExportStatus = checkingStatus;
              }
            },
            function(error){
              globalFunc.objectErrorMessage(error);
            }
          )
        }

        /**
         * @function generateExportCSV
         * This function is to generate the export CSV with parameters
         *
         * @params collection
         * Values:
         *         0 for Currencies
         *         1 for Companies
         *         2 for CostCenters
         *         3 for AccountCodes
         *         4 for ExchangeRates
         *         5 for UOMs
         *         6 for PaymentTerms
         *         7 for PaymentMethods
         *         8 for Taxes
         *         9 for ExpenseTypes
         *         10 for Suppliers
         *         101 for Supplier Tags
         *         102 for SupplierContact
         *         11 for Commodities
         *         12 for Users
         *         121 for UserSuperior
         *         13 for Catalogs
         *         16 for Catalog Items
         *         17 for PR
         *         18 for PO
         *         19 for Industries
         *         20 for Business Units
         *         21 for Delegation
         *         40 for Budget Account Code
         *         41 for Kharon Sync Log
         *         42 for Item Group
         *         43 for Supplier User
         *         44 for Sourcing Project
         *         45 for Sourcing Category
         *         46 for Sourcing Expense Type
         *         47 for Supplier Company Contract
         *         48 for PC
         */
        function generateExportCSV(){
          $scope.checkingExportStatus = true;

          var params = {};
          /**
           * To check if there are params set before the export, in order to pass criteria
           */
          if(!!exportSearch.getParams()) {
            params = exportSearch.getParams();
          }
          else {
            params['criteria[company_id]'] = (($scope.collection === '4' || $scope.collection === '2') ? (!!$scope.companyId ?
              $scope.companyId : '') : null);
            params['criteria[company_code]'] = (($scope.collection === '2' || $scope.collection === '3') ?
              (!!$scope.companyCode ? $scope.companyCode : '') : null);
          }

          if ($scope.collection === '32'){
            params = {
              'criteria[0][company|code]': !!$scope.companyCode ? $scope.companyCode : ''
            }
          }

          if (!!$scope.advancedSearch) {
            params['advancedSearch'] = $scope.advancedSearch;
          }

          if ($scope.collection === '40') {
            var status = $scope.advancedSearch.key;

            params = {
              'criteria[0][company|code][]': $scope.companyCode,
              'criteria[1][expense_type_category]': 'OPEX',
              'criteria[2][status]': status === 'all' ? undefined : status,
              'sort_by': 'created_at'
            }
          }

          /**
           * Criteria[company_id] is passed only when exporting exchange rate
           * If exchange rate is company level, pass the id, else pass empty string to indicate tenant level
           */
          exports.post(
              params,
              {
                collection: $scope.collection
              },
              function (resource) {
                if(!!resource.content && !!resource.content.data && !!resource.content.message){

                  toastr.info('Data is being exported. The download will start automatically', {timeOut: 0, extendedTimeOut: 0});

                  /**
                   * Calling the function checkExport with interval of 5 seconds
                   * Set the promise to a scope in order to cancel the call once generation is complete
                   */
                    //TODO: Support multiple interval calling
                  $scope.startChecking = $interval(function () {
                    checkExport(resource.content.data._id)
                  }, 5000);
                }
              },
              function (error) {
                $scope.checkingExportStatus = false;
                toastr.error('Data failed to be exported');
              }
            )
        }
      }
    }
  });
