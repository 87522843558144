'use strict';

angular.module('metabuyer.services.advancedSearch', ['ngResource'])
  .factory( 'advancedSearch', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.advancedSearch,
      {
        module : '@module'
      },
      {
        'get'   : {method: 'GET'}
      }
    );
  });

