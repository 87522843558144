'use strict';

/**
 * Controller for budget v2 listing
 * @description
 * Contains functions for handling showing table columns and viewing details
 *
 * @param $scope
 * @param dataList
 * @param $stateParams
 * @param $state
 * @param $rootScope
 * @param budgets
 * @param toastr
 * @param HighlightId
 * @param $window
 *
 * @function showColumn
 * Function to display selected available columns
 *
 * @author Robby Fratina <rfrob30@gmail.com>
 * @copyright 2020 Metacloud Sdn. Bhd.
 */

function alcListCtrl($scope, dataList, $stateParams, $state, $rootScope, toastr,
  HighlightId, $window, globalFunc, pathConstants, $filter, $http, customParams) {

  $scope.currentUser = $rootScope.currentUser || {_id: null};

  $scope.searchParams = {};
  $scope.showColumn = showColumn;
  $scope.searchAlc = searchAlc;
  $scope.searchAlcGroup = searchAlcGroup;
  $scope.searchPosition = searchPosition;
  $scope.selectAlcGroup = selectAlcGroup;
  $scope.selectPosition = selectPosition;
  $scope.getStatusName  = getStatusName;
  $scope.statuses = [
    {key: 'DRAFT', descr: 'Draft'},
    {key: 'ACTIVE', descr: 'Approved'},
    {key: 'DEACTIVATED', descr: 'Deactivated'},
    {key: 'PENDING', descr: 'Pending Approval'},
    {key: 'REJECTED', descr: 'Rejected'},
    {key: 'ALL', descr: 'All'}
  ];
  $scope.multiSelectPendingItems = multiSelectPendingItems;
  $scope.getSelectedAlc = getSelectedAlc;
  $scope.selectedAlcCount = selectedAlcCount;
  $scope.selectAlc = selectAlc;
  $scope.alcIsSelected = alcIsSelected;
  $scope.addToSelectedAlc = addToSelectedAlc;
  $scope.removeFromSelectedAlc = removeFromSelectedAlc;
  $scope.checkPendingApproval = checkPendingApproval;
  $scope.selectedAlc = [];

  function selectAlcGroup(item){
    $scope.searchParams.alcGroup = item;
  }

  function selectPosition(item){
    $scope.searchParams.position = item;
  }

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional) {
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function searchAlc(){
    var params = {
      module: 'alc',
      searchName: $scope.searchParams.name,
      searchCode: $scope.searchParams.code,
      searchAlcGroup: $scope.searchParams.alcGroup,
      searchPosition: $scope.searchParams.position,
      searchStatus: $scope.searchParams.status
    };

    var status = !_.isEmpty($scope.searchParams.status) ? $scope.searchParams.status.key.toLowerCase() : 'all';
    if (status === 'active'){
      status = 'approved';
    }

    $state.go('main.user.alc.manage', {status: status, params: params});
  }

  function searchAlcGroup(val){
    $scope.supplierSearching = true;

    var moduleParam = {
      module: 'alc-group'
    };

    var additionalParams = {
      'criteria[0][code]': val,
      'criteria[1][name]': val,
      'criteria[1][descr]': val,
      criteria_operator: 'or',
      offset: 5
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams,
      ignoreLoadingBar: true
    }).then(function (response) {
      if (response.data.content.data.length === 0) {
        $scope.supplierSearching = false;
        return [];
      } else {
        return response.data.content.data.map(function (item) {
          $scope.supplierSearching = false;
          return item
        });
      }
    });
  }

  function searchPosition(val) {
    return $http.get(pathConstants.apiUrls.approvalEngine.position.get, {
      params: {
        'sort[1][key]': 'updated_at',
        'sort[1][direction]': 'DESC',
        'filter_groups[0][filters][0][key]': 'code',
        'filter_groups[0][filters][0][value]': val,
        'filter_groups[0][filters][0][operator]': 'ct',
        limit: 100
      }
    }).then(function (response) {
      if (response.data.content.data.length === 0) {
        $scope.supplierSearching = false;
        return [];
      } else {
        return response.data.content.data.position.map(function (item) {
          $scope.supplierSearching = false;
          return item
        });
      }
    });
  }

  function prepareImportParams () {
    $scope.params = {
        'class': 'App\\Metabuyer\\Alc\\Models\\Alc'
    };
    _.forEach ($scope.dataList, function(alc, index){
      var temp = 'additional_params[]['+ index+ ']';
      $scope.params[temp] = alc.code;
  });
}

  /**
   * Select all alc but only pending on current user
   *
   * @param checked
   */
  function multiSelectPendingItems(checked) {
    var userId = $scope.currentUser._id;

    $scope.dataList.forEach(function (alcFromList) {
      alcFromList.selected = checked;
      var waitingOn = alcFromList.status == 'PENDING' ? alcFromList.waiting_on : [];
      var index = _.findIndex(waitingOn, function(approvalId) {
        return approvalId === userId;
      });
      if (checked && index > -1) {
        $scope.addToSelectedAlc(alcFromList);
      } else {
        $scope.removeFromSelectedAlc(alcFromList);
      }
    })
  }

  /**
   * Select / unselects a ALC
   *
   * @param alcFromList
   */
  function selectAlc(alcFromList) {
    if($scope.alcIsSelected(alcFromList)) {
      $scope.removeFromSelectedAlc(alcFromList);
    } else {
      $scope.addToSelectedAlc(alcFromList);
    }
  }

  /**
   * returns store the selected ALC
   * @returns {Array}
   */
  function getSelectedAlc() {
    return $scope.selectedAlc;
  }

  /**
   * Gets the number of selected ALC
   * @returns {Number}
   */
  function selectedAlcCount() {
    return $scope.selectedAlc.length;
  };

  /**
   * Check if a ALC is selected
   *
   * @param alc
   * @returns {boolean}
   */
  function alcIsSelected(alc) {
    var index = _.findIndex($scope.selectedAlc, function(selected) {
      return selected._id === alc._id;
    });

    return index > -1;
  }
  
  /**
   * Add a ALC to selected ALC
   * @param alc
   */
  function addToSelectedAlc(alc) {
    if (!alcIsSelected(alc)) {
      $scope.selectedAlc.push(alc);
    }
  }

  /**
   * Remove a ALC from selected ALC
   * @param alc
   */
  function removeFromSelectedAlc(alc) {
    _.remove($scope.selectedAlc, function (item) {return item._id === alc._id});
  }

  /**
   * Check ALC is pending on current user and show checkbox
   *
   * @param alc
   * @returns {boolean}
   */
  function checkPendingApproval(alc) {
    var userId = $scope.currentUser._id;
    var waitingOn = alc.status == 'PENDING' ? alc.waiting_on : [];
    var index = _.findIndex(waitingOn, function(approvalId) {
      return approvalId === userId;
    });

    return index > -1;
  }

  function initialize() {
    if (!!HighlightId.getId() && HighlightId.getId() !== undefined && HighlightId.getId().length) {

      $scope.highlightId = HighlightId.getId();

      if ($scope.highlightId !== null && $scope.highlightId !== undefined) {

        //scroll the window to top
        $window.scroll(0, 0);
      }

      //clear current id
      HighlightId.setId(null);
    }

    if (!!customParams.status) {
      $scope.searchParams.status = _.find($scope.statuses, function(status) {
        return status.key === customParams.status.toUpperCase();
      });
    }

    if (!!customParams.params) {
      $scope.searchParams.name = customParams.params.searchName;
      $scope.searchParams.code = customParams.params.searchCode;
      $scope.searchParams.alcGroup = customParams.params.searchAlcGroup;
      $scope.searchParams.position = customParams.params.searchPosition;
      $scope.searchParams.status = customParams.params.searchStatus;
    }

    $scope.$parent.status = customParams.status;
    $scope.status = customParams.status;
    $scope.dataList = dataList.data;
    $scope.meta = dataList.meta;
    $scope.storageKey = 'alc-selected-columns';
    $scope.params = 'alc';
    prepareImportParams();
  }

  function getStatusName(status) {
    if(status === 'active')
      return 'Approved';

    return status
  }

  initialize();

}

alcListCtrl.$inject = ['$scope', 'dataList', '$stateParams', '$state', '$rootScope', 'toastr',
  'HighlightId', '$window', 'globalFunc', 'pathConstants', '$filter', '$http', 'customParams'];

angular.module('metabuyer')
  .controller('alcListCtrl', alcListCtrl);
