'use strict';

/**
 * controller for preparing the data to pass it to the actionModal directive
 *
 * @param $scope
 * @param multiApprovalActions
 * @param globalFunc
 * @param $state
 * @param toastr
 * @param metabuyerCache
 * @param $rootScope
 * @param $q
 */

function multiApprovalActionCtrl ($scope, multiApprovalActions, globalFunc, $state, toastr, metabuyerCache, $rootScope, $q) {
  $scope.reasonReject = {};

  /**
   * list the Approval IDs for the selected PRs
   * @returns {{approval_ids: Array}}
   */
  function getData() {
    var IDs = [];
    _.forEach($scope.getSelectedItems(), function (item) {
      if (!!item.approval_id) {
        IDs.push(item.approval_id);
      }
    });
    return {
      approval_ids: IDs,
      comment: $scope.reasonReject.status_reason || ''
    };
  }

  function getListOfApprovalIDs(){
    var IDs = [];
    _.forEach($scope.getSelectedItems() , function (item) {
      if (!!item.approval_id) {
        IDs.push(item.approval_id);
      }
    });
    return IDs;
  }

  /**
   * return the resource function for the directive
   * @param action
   * @param text
   * @returns {*}
   */
  function backendMassApproveAction(action, text) {
    return multiApprovalActions.post(
      {
        action: action
      },
      getData(),
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.message) {
          if (_.isObject(resource.content.message)) {
            if (!!resource.content.message.failed)
              displayFailedErrorData(action, resource.content.message.failed);
          } else {
            toastr.success(resource.content.message);
          }
        } else {
          toastr.success('The action was performed successfully');
        }
        metabuyerCache.remove('PRClicked');
      }, function (errors) {
        errors = errors.data;
        // cannot use global error message
        // it is using different data format
        // the error message returns approval id and the error message
        if (!!errors && !!errors.content && !!errors.content.error && !!errors.content.error.message) {
          displayFailedErrorData(action, errors.content.error.message);
        }
        metabuyerCache.remove('PRClicked');
      }
    ).$promise;
  }

  function frontendMassApproveAction(status) {
    var promises = [];
    var ids = getListOfApprovalIDs();
    angular.forEach(ids, function (id) {
      var promise = $scope.service.post(
        {
          approval_id: id,
          status: status
        });

      promises.push(promise.$promise);
    });
    return $q.all(promises).then(function (resource) {
      toastr.success('The action was performed successfully');
    }).catch(function (error) {
      toastr.error('Mass approving failed.');
    });
  }

  /**
   * Display error for failed data
   *
   * @param action
   * @param data
   */
  function displayFailedErrorData(action, data) {
    var contents = $scope.getSelectedItems();
    _.forEach(data, function (error) {
      var object = globalFunc.findInArray(contents, 'approval_id', error.approval_id);

      if (!!object) {
        var objectName = object.reference || object.descr || object.catalog_descr;
        toastr.error('Failed to ' + action + ' ' + objectName + ': '+ error.message);
      }
    })
  }

  /**
   * return handler for the multiAction
   * @returns {*|void|promise}
   */
  function getMultiActionHandler(){
    return $state.reload();
  }

  /**
   * prepare any extra params for each action once the <span> is clicked
   */
  function prepareExtraParams(){
    $scope.extraParams = getData();
  }


  function getMultiActionFunction(status, length, type, message) {
    if (!!$scope.frontend)
      return frontendMassApproveAction(status);
    else
      return backendMassApproveAction(status, length + ' ' + type + message);

  }

  function getShowOnHold(statusString) {
    return statusString === "true" ? true
      : statusString === "false" ? false
      : statusString;
  }

  function getHideRejected(statusString) {
    return statusString === "true" ? true
      : statusString === "false" ? false
      : statusString;
  }

  $scope.getMultiActionFunction       = getMultiActionFunction;
  $scope.getMultiActionHandler        = getMultiActionHandler;
  $scope.prepareExtraParams           = prepareExtraParams;
  $scope.getShowOnHold                = getShowOnHold;
  $scope.getHideRejected              = getHideRejected;
  $scope.templateUrlPath              = 'components/multiApprovalAction/multiApprovalActionTemplate.html';
  $scope.commentBlockCondition        = !!$scope.disabledCommentBlock ? false : true;
  $scope.isV2Enabled                  = $rootScope.isV2Enabled;

  if ($rootScope.isMobileMode) {
    $scope.templateUrlPath = 'components/multiApprovalAction/multiApprovalActionTemplate-mobile.html';
  }
}

/**
 * @name multiApprovalAction
 * @desc directive for performing Approval action on multiple items
 * this directive uses the action modal directive
 *
 */
angular
  .module('metabuyer')
  .controller('multiApprovalActionCtrl', multiApprovalActionCtrl)
  .directive('multiApprovalAction',function() {
    return {
      restrict: 'EA',
      template: '<div ng-include="templateUrlPath"></div>',
      controller: 'multiApprovalActionCtrl',
      scope: {
        getSelectedItems: '&',
        showOnHold: '@?',
        type: '@',
        frontend: '@?',
        service: '=',
        hideRejected:'@?'
      }
    };
  });
