'use strict';

angular.module('metabuyer')
  .controller('ReceiveGoodsCtrl', function (
    $scope, $uibModalInstance, Orders, toastr, HighlightId, pathConstants, $http, $filter,$timeout,
    $state,globalFunc, $rootScope
  ) {
    $scope.next_receive_goods = true;
    $scope.back_receive_goods = false;
    $scope.check_for_all = false;
    $scope.enable_upload_do = true;
    $scope.submit_data = {};
    $scope.do_uploader = {};
    $scope.received_date = null;
    $scope.receive_goods_data = [];
    $scope.reopenStatus = false;
    $scope.copyCommentData = $scope.commentData;
    $scope.do_dates = { do_date: {}};
    $scope.doDatepickerOpened = false;
    $scope.submitted = false;

    $scope.uploadPO = uploadPO;
    $scope.cancel = cancel;
    $scope.backOnReceiveGoods = backOnReceiveGoods;
    $scope.checkAllItemReceived = checkAllItemReceived;
    $scope.prepareReceiveGoods = prepareReceiveGoods;
    $scope.reopenItem = reopenItem;
    $scope.getClosedDetail = getClosedDetail;
    $scope.openDatePicker = openDatePicker;
    $scope.parseToInt = parseToInt;
    $scope.doCheck = false;
    $scope.dateCheck = false;
    $scope.userBasePath = $rootScope.userImageBasePath;
    $scope.selectDeliveryNote = selectDeliveryNote;
    $scope.selectedDeliveryNoteNumber = null;
    $scope.selectedDn = null;
    $scope.canEditReceivingQuantity = canEditReceivingQuantity;
    $scope.validReceivingQuantity = validReceivingQuantity;

    //Variable to capture current date
    var currentDate = new Date();
    $scope.tempcurrentDate = currentDate;
    //Formatting the current date to MMM dd, yyyy
    $scope.formattedCurrentDate = currentDate;

    $scope.dateOptions = {
      formatYear: 'yy',
      startingDay: 1,
      maxDate: new Date
    };

    $scope.datePicker = {
      openDatepicker: function($event,index) {
        if($scope.receive_goods_data[index].datepickerOpened) {
          $event.preventDefault();
          $event.stopPropagation();
        }
        else{
          $scope.receive_goods_data[index].datepickerOpened = true;
        }
      }
    };

    function validReceivingQuantity(item) {
      if (item.received_amount === 0) {
        return true;
      }

      // If shipping exists, receive amount cannot more than shipped
      if (!!$scope.deliveryNotes.length && !!$scope.selectedDn) {
        return item.received_amount <= item.shipping_quantity;
      }

      // Item cannot more than remaining quantity
      return item.received_amount > 0 &&
        (item.received_amount <= item.po_item.remaining_quantity);
    }

    function canEditReceivingQuantity() {
      // If there is existing delivery, user must select 1 first
      if (!!$scope.deliveryNotes.length) {
        return !!$scope.selectedDeliveryNoteNumber;
      }

      return true;
    }

    function selectDeliveryNote(selectedDn) {
      // Reset receiving quantity
      _.forEach($scope.receive_goods_data, function(poItem){
        poItem.received_amount = 0;
      });

      // Set receive quantity based on delivery note shipping
      _.forEach($scope.receive_goods_data, function(poItem) {
        _.forEach(selectedDn.items, function(item){
          if (poItem.po_item._id === item._id) {
            poItem.received_amount = item.shipping_quantity;
            poItem.shipping_quantity = item.shipping_quantity;
          }
        });
      });

      $scope.selectedDeliveryNoteNumber = selectedDn.dn_number;
      $scope.selectedDn = selectedDn;
      $scope.submit_data.do_number = selectedDn.do_number;
    }

    function openDatePicker($event) {
      if($scope.doDatepickerOpened){
        $event.preventDefault();
        $event.stopPropagation();
      }
      else{
        $timeout(function(){
          $scope.doDatepickerOpened = true;
        })
      }
    }

    //next action
    function uploadPO(){
      $scope.next_receive_goods = false;
      $scope.back_receive_goods = true;
    }

    //cancel action
    function cancel(){
      $uibModalInstance.close();
    }

    //back action
    function backOnReceiveGoods(){
      $scope.next_receive_goods = true;
      $scope.back_receive_goods = false;
    }

    function parseToInt(number) {
      return Number(number);
    }

    function checkAllItemReceived(){
      if($scope.check_for_all){
        _.forEach($scope.receive_goods_data, function(item, key){
          item.received_amount = Number(item.po_item.remaining_quantity);
          //ensure the item closed flag is removed
          reopenItem(key);
        });
      } else {
        _.forEach($scope.receive_goods_data, function(item){
          item.received_amount = 0;
        });
      }
    }

    $scope.$watch('receive_goods_data', function(value){
      var fill_up_wrong = _.find($scope.receive_goods_data, function(item){
        return item.received_amount != 0 &&
          (item.received_amount > item.po_item.remaining_quantity);
      });

      if(fill_up_wrong != undefined){
        $scope.enable_upload_do = false;
      }
      else{
        var fill_up_right = _.find($scope.receive_goods_data, function(item){
          return (angular.isDefined(item.received_amount && item.received_amount != 0));
        });

        if(fill_up_right != undefined){
          $scope.enable_upload_do = true;
        } else {
          $scope.enable_upload_do = false;
        }
      }

      //enable check for all
      var validateCheckAll = _.find($scope.receive_goods_data, function(item){
        return Number(item.received_amount) + Number(item.po_item.order.delivered) != item.po_item.quantity;
      });

      $scope.check_for_all = (validateCheckAll === undefined);

    }, true);


    function validateFileType(){
      if(angular.isDefined($scope.submit_data.do_file)){
        var extensions = globalFunc.validDoFileType();
        var fileType = $scope.submit_data.do_file.type;
        return (extensions.indexOf(fileType) > -1);
      }

      /**
       * no file is uploaded, its not Mandatory so no need to verify
       */
      return true;
    }
  
    function maxFileSize() {
      if (angular.isDefined($scope.submit_data.do_file)) {
        var size = $scope.submit_data.do_file.size;
        return (size >= 20971520);
      }
    }

    /**
     * Prepare receive goods data and confirmation
     */
    function prepareReceiveGoods() {
      $scope.submitted = true;

      if (maxFileSize() === true) {
        toastr.error("The file size cannot be more than 20 MB");
        return;
      }
      // If DN is pending but not selected
      if (!!$scope.deliveryNotes.length && !$scope.selectedDeliveryNoteNumber) {
        toastr.error('Delivery note is not selected.');
        return;
      }

      if (validateFileType() === false) {
        toastr.error('Invalid file format! Allowed extensions are: .pdf,.png,.doc,.jpg,.jpeg,.txt');
        return;
      }

      if (validateEntries() === false) {
        return;
      }

      var fd = new FormData();
      var showSwalConfirmation = false;

      if (angular.isDefined($scope.do_dates.do_date) && angular.isDefined($scope.submit_data.do_number)) {
        var doDate = new Date($scope.do_dates.do_date);
        // set time to midnight as we only want to capture the date
        doDate.setHours(0,0,0,0);
        fd.append('do_date', doDate.getTime());
      }

      if (angular.isDefined($scope.submit_data.do_file)) {
        fd.append('do_file', $scope.submit_data.do_file);
      }

      if (angular.isDefined($scope.submit_data.do_number)) {
        fd.append('do_number', $scope.submit_data.do_number);
      }

      if (!!$scope.selectedDeliveryNoteNumber) {
        fd.append('dn_number', $scope.selectedDeliveryNoteNumber);
      }

      _.forEach($scope.receive_goods_data, function (item) {
        if (item.received_amount && (item.received_amount <= item.po_item.remaining_quantity)) {
          fd.append('items[' + item.POIndex + ']', item.received_amount);
        }

        if (!!item.received_amount && !!item.po_item.split_type && item.po_item.split_type === 'amount') {
          showSwalConfirmation = true;
        }
      });

      _.forEach($scope.receive_goods_data, function (item) {
        if (item.received_amount && (item.received_amount <= item.po_item.remaining_quantity)) {
          var receivedDate = new Date(item.received_date);
          fd.append('items_dates[' + item.POIndex + ']', receivedDate.getTime());
        }
      });

      if (angular.isDefined($scope.submit_data.comment)) {
        fd.append('comment', $scope.submit_data.comment);
      }

      // If there is an item received that is split by amount, confirmation is needed
      if (showSwalConfirmation) {
        // Sweet alert confirmation if receiving item that has linked PO
        swal({
          title: 'Confirm receive item(s)?',
          text: 'Item(s) on related PO(s) will also be marked as received.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true
        }, function (isConfirm) {
          if (!!isConfirm) {
            submitDeliveryOrder(fd);
          }
        });
      }
      else {
        submitDeliveryOrder(fd);
      }
    }

    /**
     * Function to submit delivery order
     *
     * @param formData The form data for submit delivery order
     */
    function submitDeliveryOrder(formData) {
      $http.post($filter('format')(pathConstants.apiUrls.po.receive, {po_id: $scope.po._id}), formData, {
        transformRequest: angular.identity,
        headers: {'Content-Type': undefined}
      })
        .then(function onSuccess(result) {
          toastr.success('DO has been submitted.');

          if (!!result.data && !!result.data.content && !!result.data.content.message) {
            swal(result.data.content.message);
          }

          /**
           * Loops for each receive goods item
           */
          for (var i in $scope.receive_goods_data) {
            /**
             * Check if the PO is still partially delivered, break out of loop if true
             */
            var totalAmountReceived = math.subtract(math.bignumber($scope.receive_goods_data[i].received_amount),
              math.bignumber($scope.receive_goods_data[i].po_item.order.delivered));
            totalAmountReceived = math.format(totalAmountReceived, {precision: 64});
            if (totalAmountReceived < Number($scope.receive_goods_data[i].po_item.quantity)) {
              /**
               * If PO is still partially delivered, state transition to awaiting delivery
               */
              HighlightId.setId($scope.po._id);
              $state.go('main.user.orders.manage', {status: 'AWAITING_DELIVERY'});
              $uibModalInstance.close();
              return false;
            }
          }
          $uibModalInstance.close();
          HighlightId.setId($scope.po._id);
          $state.go('main.user.orders.manage', {status: 'CLOSED'});
        })
        .catch(function onError(error) {
          if (!!error.data.content && angular.isDefined(error.data.content.error) && !!error.data.content.error) {
            if (typeof error.data.content.error === 'string') {
              var errorstr = error.data.content.error.toLowerCase();
              if (errorstr.indexOf('do number') > -1 && errorstr.indexOf('used') > -1) {
                $scope.doCheck = true;
                $scope.backupDONumber = angular.copy($scope.submit_data.do_number);
              }

              if (errorstr.indexOf('date') > -1)
                $scope.dateCheck = true;

              toastr.error(error.data.content.error);

            }
            else
              globalFunc.objectErrorMessage(error);
          }
          else {
            toastr.error('Failed to submit DO.');
          }
        });
    }

    function reopenItem(index) {
      $scope.receive_goods_data[index].po_item.closed = false;
    }

    function getClosedDetail(id) {
      if(typeof id !== undefined || typeof id !== null){
        var closedItemComment = _.find($scope.copyCommentData, function(comment){
          return comment._id == id;
        });

        if(typeof closedItemComment !== undefined || typeof closedItemComment !== null) {
          if(_.has(closedItemComment, 'updated_at')){
            var _date = $filter('date')(new Date(closedItemComment.updated_at), 'dd MMM yyyy');
            return _date + ', ' + 'by: ' + closedItemComment.commentator.data.display_name;
          }
        } else
          return '';
      }
      return '';
    }

    function initialize(){
      _.forEach($scope.po.items, function(item, i){

        item.remaining_quantity = math.subtract(math.bignumber(item.quantity), math.bignumber(item.order.delivered));
        item.remaining_quantity = math.format(item.remaining_quantity, {precision: 64});

        if(item.remaining_quantity <= 0)
          return;

        $scope.received_date = $scope.formattedCurrentDate;
        $scope.do_dates.do_date = $scope.formattedCurrentDate;

        $scope.receive_goods_data.push(
          {
            po_item: item,
            received_amount: 0,
            received_date: $scope.received_date,
            POIndex: i
          }
        );
      });

      // Generate pending DN
      $scope.deliveryNotes = [];
      _.forEach($scope.po.delivery_notes, function(dn){
        if (dn.status === 'new') {
          $scope.deliveryNotes.push(dn);
        }
      });
    }

    /**
     * function to validation the Amount existed as well as the if the fraction is valid for a specific UOM
     * @param amount
     * @param uom
     * @returns {boolean}
       */
    function validateAmountAndFraction(amount, uom) {
      if(!amount){
        toastr.error('please add amount of good received');
        return false;
      }
      if (!uom.is_fraction || uom.is_fraction === 0) {
        if (amount % 1 !== 0) {
          toastr.error('Unit of Measurement does not allow amount in fraction');
          return false;
        }
      }
      return true;
    }

    /**
     * Loop on all GRN and validate each GRN entry
     * @returns {boolean}
     */
    function validateEntries() {

      if (!$scope.submit_data.do_number || !$scope.submit_data.do_number.length) {
        return false;
      }

      if (!$scope.do_dates.do_date) {
        return false;
      }

      if ($scope.receive_goods_data.length === 1 && Number($scope.receive_goods_data[0].received_amount) === 0)
        return false;

      for (var i in $scope.receive_goods_data) {
        if (Number($scope.receive_goods_data[i].received_amount) !== 0) {
          if (!!$scope.receive_goods_data[i].shipping_quantity &&
            $scope.receive_goods_data[i].received_amount > $scope.receive_goods_data[i].shipping_quantity) {
            toastr.error('Invalid received amount.');
            return false;
          }
          return validateAmountAndFraction(
            $scope.receive_goods_data[i].received_amount,
            $scope.receive_goods_data[i].po_item.uom
          );
        }
      }
    }

    initialize();

  });

