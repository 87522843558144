'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('main.user.faqV2', {
        templateUrl: 'app/faqV2/faq.html',
        url: '/faqV2',
        controller: 'faqV2Ctrl',
        resolve: {
          style: function ($rootScope) {
            $rootScope.bodyClasses = '';
          },
          Authorization: function ($auth, toastr, $state) {
            if (!$auth.isAuthenticated()) {
              toastr.error('Please log in.');
              $state.go('login');
            }
          },
          $title: function($stateParams) {
            return "FAQ";
          },
          dataList: function($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'faqV2',
                offset: 50,
                order_by: 'attachments|updated_at',
                order: -1,
              },
              function(resource) {
                deferred.resolve(resource.content);
              },
              function(error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          }
        }
      });
  }]);
