function userDelegationModalCtrlV2($scope, $uibModalInstance, $rootScope, massDelegate, toastr, globalFunc,
                                 selectedCompanies, selectedNonCompanies, getUsersInCompanies, type,
                                   getAlcGroupActiveUsers, getCatalogGroupActiveUsers, delegatorId) {

  /**
   * To get the users array for ui-select based on company(s)
   */
  function getUsersArray() {
    var params = {};
    _.forEach(selectedCompanies, function (companyCode, index) {
      var temp = 'companyCodes[' + index + ']';
      params[temp] = companyCode;
    });

    getUsersInCompanies.get(
      params,
      function (resource) {
        $scope.users = (!!resource && !!resource.content && !!resource.content.data) ?
          resource.content.data : [];

        removeLoggedUserAndEditUser();
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
        $scope.users = [];
      }
    );
  }

  /**
   * To get the users array for ui-select based on company(s)
   */
  function getAlcUsersArray() {
    getAlcGroupActiveUsers.get(
      {
        'code': selectedNonCompanies.codes[0] //this field is filled with only one alc group, as All is not supported
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.users = resource.content.data;
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
        $scope.users = [];
      }
    );
  }

  /**
   * To get the users array for ui-select based on catalog group
   */
  function getCatalogGroupUsersArray() {
    getCatalogGroupActiveUsers.get(
      {
        'groupCode': selectedNonCompanies.codes[0] //this field is filled with only one alc group, as All is not supported
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.users = resource.content.data;
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
        $scope.users = [];
      }
    );
  }

  /**
   * To remove current logged in user and current edit user from user array
   */
  function removeLoggedUserAndEditUser() {
    var currentLoggedInUser = _.findIndex($scope.users, function (user) {
      return user._id === $rootScope.currentUser._id;
    });

    if (currentLoggedInUser !== -1)
      $scope.users.splice(currentLoggedInUser, 1);

    // If the current user not editing himself i.e. admin editing a user
    if ($scope.user._id !== $rootScope.currentUser._id) {
      var currentEditUser = _.findIndex($scope.users, function (user) {
        return user._id === $scope.user._id;
      });
      $scope.users.splice(currentEditUser, 1);
    }
  }

  function openDatePicker($event, which) {

    /**
     * Setting minimum selection for start date to be today
     */
    $scope.startDateOptions.minDate = new Date();

    /**
     * Setting the minimum selection for end date to be one day ahead of selected start date
     */
    var startDateClone = _.cloneDeep($scope.delegation.startDate);
    var startDate = new Date(startDateClone);
    startDate.setDate(startDate.getDate());
    $scope.endDateOptions.minDate = startDate;

    $event.preventDefault();
    $event.stopPropagation();

    $scope.datepickerOpened[which] = true;
  }

  function closeModal() {
    $uibModalInstance.close();
  }

  function onUserSelect(userObject) {
    $scope.selectedUser = userObject;
  }

  /**
   * To check the submitted data
   */
  function checkToSubmit() {

    // Assign selected start date
    var startDate = globalFunc.convertDateToTimestamp($scope.delegation.startDate);

    // Assign selected end date
    $scope.delegation.endDate = moment($scope.delegation.endDate).endOf('day').toDate();
    var endDate = globalFunc.convertDateToTimestamp($scope.delegation.endDate);

    // Return error if end date is before start date
    if (endDate <= startDate) {
      toastr.error('End date cannot be before start date.');
      return;
    }

    delegate(startDate, endDate, $scope.selectedUser._id);

  }

  function getSelectedClass() {
    if (!!selectedNonCompanies.class.length) {
      var selectedNonCompaniesClass = [];
      _.forEach(selectedNonCompanies.class, function(delegationClass) {
        selectedNonCompaniesClass.push(delegationClass);
      });
      return selectedNonCompaniesClass;
    }
    else {
      return [];
    }
  }

  /**
   * To delegate to another user
   *
   * @param startDate
   * @param endDate
   * @param delegateeID
   * @returns {*}
   */
  function delegate(startDate, endDate, delegateeID) {
    return massDelegate.post(
      {
        userId: delegatorId
      },
      {
        delegatee_id: delegateeID,
        reason: $scope.delegation.reason,
        company_codes: !!selectedCompanies ? selectedCompanies : [],
        class: getSelectedClass(),
        start_date: startDate,
        end_date: endDate
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $uibModalInstance.close(resource.content.data);
          toastr.success(resource.content.message);
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      });
  }

  function initialize() {

    $scope.datePickerTimezone = $rootScope.datePickerTimezone;
    $scope.delegation = {};

    $scope.onUserSelect = onUserSelect;
    $scope.openDatePicker = openDatePicker;
    $scope.startDateOptions = {
      formatYear: 'yy',
      minDate: new Date(),
      startingDay: 1
    };
    $scope.endDateOptions = {
      formatYear: 'yy',
      startingDay: 1
    };
    $scope.datepickerOpened = {
      start_date: false,
      end_date: false
    };

    $scope.closeModal = closeModal;

    $scope.checkToSubmit = checkToSubmit;

    if(type === 'alcGroup')
      getAlcUsersArray();
    else if (type === 'catalogGroup')
      getCatalogGroupUsersArray();
    else
      getUsersArray();
  }

  initialize();
}

userDelegationModalCtrlV2.$inject = [
  '$scope', '$uibModalInstance', '$rootScope', 'massDelegate', 'toastr', 'globalFunc',
  'selectedCompanies', 'selectedNonCompanies', 'getUsersInCompanies', 'type', 'getAlcGroupActiveUsers',
  'getCatalogGroupActiveUsers', 'delegatorId'
];

angular
  .module('metabuyer')
  .controller('userDelegationModalCtrlV2', userDelegationModalCtrlV2);
