'use strict';
/**
 * @name showManagersCtrl
 * @desc Controller to see managers for a user
 */
function showManagersCtrl($scope, listManagers, company, $uibModalInstance) {
  $scope.closeModal = closeModal;
  $scope.listManagers = listManagers;
  $scope.company_name = company;

  function closeModal() {
    $uibModalInstance.close();
  }
}

showManagersCtrl.$inject = ['$scope', 'listManagers', 'company', '$uibModalInstance'];

angular
  .module('metabuyer')
  .controller('showManagersCtrl', showManagersCtrl);
