'use strict';

angular.module('metabuyer.services.alc', ['ngResource'])
  .factory('AlcDraft', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.alc.draft,
      {
        uuid: '@uuid'
      },
      {
        'get': {method: 'GET'},
        'post': {method: 'POST'},
        'put': {method: 'PUT'}
      }
    );
  })
  .factory('Alc',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.alc.single,
      {
        uuid: '@uuid'
      },
      {
        'get': {method: 'GET'},
        'post': {method: 'POST'},
        'put': {method: 'PUT'},
        'approve': { // this is used
          method: 'POST',
          params: {
            approval_id: '@approval_id',
            status: '@status'
          },
          url: pathConstants.apiUrls.approvals.status
        }
      }
    );
  })
  .factory('getAlc',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.alc.list,
      {},
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('updateStatusAlc', function ($resource, pathConstants) {
      return $resource(
          pathConstants.apiUrls.alc.update,
          {
            uuid: '@uuid',
            status: '@status'
          },
          {
            'put': {method: 'PUT'}
          }
      );
  })
  .factory('getUtilization', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.alc.utilization,
      {
        uuid: '@uuid'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('updateAlcHeader', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.alc.updateAlcHeader,
      {
        uuid: '@uuid'
      },
      {
        'put': {method: 'PUT'}
      }
    );
  })
  .factory('resendPendingAlcEmail', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.alc.resendPendingAlcEmail,
      {
        id: '@id'
      },
      {
        'post': {method: 'POST'}
      }
    );
  });
