'use strict';

angular.module('metabuyer.services.costcenters', ['ngResource'])
  .factory('CostCenters', function($resource, $filter, pathConstants){
    var CostCenters = $resource(
      pathConstants.apiUrls.costCenters.single,
      {
        id: '@id'
      },
      {
        'put': { method: 'PUT' },
        'get': { method: 'GET' },
        'delete': { method: 'DELETE' }
      }
    );

    return CostCenters;
  })
  .factory('addCostCenters', function($resource, $filter, pathConstants){
    var addCostCenters = $resource(
      pathConstants.apiUrls.costCenters.new,{},
      {
        'put': { method: 'PUT' },
        'post': { method: 'POST' }
      }
    );

    return addCostCenters;
  })
  .factory('deactivateCostCenters', function($resource, $filter, pathConstants){
    var deactivateCostCenters = $resource(
      pathConstants.apiUrls.costCenters.deactivate,
      {
        id: '@id'
      },
      {
        'put': { method: 'PUT' }
      }
    );

    return deactivateCostCenters;
  })
  .factory('activateCostCenters', function($resource, $filter, pathConstants){
    var activateCostCenters = $resource(
      pathConstants.apiUrls.costCenters.activate,
      {
        id: '@id'
      },
      {
        'put': { method: 'PUT' }
      }
    );

    return activateCostCenters;
  });
