'use strict';

function contractDetailsCtrl($scope, baseContract, $uibModalInstance, globalFunc, paymentTerms, paymentMethods, taxes,
                             currencies, commodities, companyContractResource, toastr, searchModule, listChild,
                             supplierFunctions, contractRevisions, $uibModal, pendingChanges, $state, $http,
                             $filter, pathConstants, singleAttachment, $rootScope) {

  $scope.contract = _.cloneDeep(baseContract);
  $scope.paymentTerms = paymentTerms;
  $scope.paymentMethods = paymentMethods;
  $scope.taxes = taxes;
  $scope.currencies = currencies;
  $scope.commodities = commodities;
  $scope.receive_via_email = false;
  $scope.receive_via_post = false;
  $scope.receive_via_fax = false;
  $scope.primaryBusinessNature = {};
  $scope.secondaryBusinessNature = {};
  $scope.closeTreeSearchPrimary = false;
  $scope.closeTreeSearchSecondary = false;
  $scope.commodityLevel = [3, 4];
  $scope.editClicked = false;
  $scope.contractRevisions = [];
  $scope.pendingChanges = pendingChanges;
  $scope.statusUpdatePayload = {};
  $scope.isStatusChange = false;
  $scope.isSourcingSupplierEnabled = !!$rootScope.isSourcingSupplierEnabled;

  $scope.bumiputraStatus = [
    {descr: 'Yes', value: 1},
    {descr: 'No', value: 0}
  ];

  $scope.businessNatures = [
    {descr: 'Unspecified Business Nature', shortDescr: 'UNSPECIFIED', value: 0},
    {descr: 'Agent', shortDescr: 'AGENT', value: 1},
    {descr: 'Banking & Financial Services', shortDescr: 'BANKING', value: 2},
    {descr: 'Consultancy, Advisory & Other Professional Bodies', shortDescr: 'CONSULTANCY', value: 3},
    {descr: 'Contractor', shortDescr: 'CONTRACTOR', value: 4},
    {descr: 'Dealer', shortDescr: 'DEALER', value: 5},
    {descr: 'Distribute', shortDescr: 'DISTRIBUTOR', value: 6},
    {descr: 'Manufacturer', shortDescr: 'MANUFACTURER', value: 7},
    {descr: 'Marketing', shortDescr: 'MARKETING', value: 8},
    {descr: 'Retailer', shortDescr: 'RETAILER', value: 9},
    {descr: 'Service Provider', shortDescr: 'SERVICE_PROVIDER', value: 10},
    {descr: 'Sub-Contractor', shortDescr: 'SUB_CONTRACTOR', value: 11},
    {descr: 'Trading', shortDescr: 'TRADING', value: 12},
    {descr: 'Other', shortDescr: 'OTHERS', value: 13}
  ];

  $scope.removeContact = removeContact;
  $scope.onAddedContact = onAddedContact;
  $scope.onUpdatedContact = onUpdatedContact;
  $scope.downloadAttachment = downloadAttachment;
  $scope.close = close;
  $scope.updateCompanyContracts = updateCompanyContracts;
  $scope.validateBusinessNature = validateBusinessNature;
  $scope.searchPrimaryCommodityCode = searchPrimaryCommodityCode;
  $scope.searchSecondaryCommodityCode = searchSecondaryCommodityCode;
  $scope.openChildCommodityCodePrimary = openChildCommodityCodePrimary;
  $scope.openChildCommodityCodeSecondary = openChildCommodityCodeSecondary;
  $scope.removeSecondaryCommodity = removeSecondaryCommodity;
  $scope.onSelectedTax = onSelectedTax;
  $scope.onSelectedCurrency = onSelectedCurrency;
  $scope.onSelectedPaymentTerm = onSelectedPaymentTerm;
  $scope.onSelectedPaymentMethod = onSelectedPaymentMethod;
  $scope.editSupplierCompanyContract = editSupplierCompanyContract;
  $scope.showVersionList = showVersionList;
  $scope.reapplyWithdrawnEdit = reapplyWithdrawnEdit;
  $scope.updateCompanySupplierStatus = updateCompanySupplierStatus;
  $scope.checkSupplierRequesterRole = checkSupplierRequesterRole;
  $scope.allowEditingSupplier = allowEditingSupplier;
  $scope.isTenantSuperAdmin = isTenantSuperAdmin;
  $scope.editStatusSupplierCompanyContract = editStatusSupplierCompanyContract;

  function editSupplierCompanyContract() {
    $scope.editClicked = true;
  }

  function editStatusSupplierCompanyContract() {
    $scope.editClicked = true;
    $scope.newStatus = 'ACTIVE';
  }

  function onSelectedPaymentMethod(paymentMethod) {
    $scope.contract.selectedPaymentMethod = paymentMethod;
  }

  function onSelectedPaymentTerm(paymentTerm) {
    $scope.contract.payment_term_code = paymentTerm.code;
  }

  function onSelectedCurrency(currency) {
    $scope.contract.selectedCurrency = currency;
    $scope.contract.selectedCurrencyText = currency.code + ' - ' + currency.desc;
  }

  function onSelectedTax(tax) {
    $scope.contract.selectedTax = tax;
    $scope.contract.selectedTaxText = tax.code + ' - ' + tax.desc;

  }

  function searchPrimaryCommodityCode(keyword) {
    if (!!keyword && keyword.length > 1) {
      searchModule.get(
        {
          module: 'commodity',
          'criteria[0][code]': keyword,
          'criteria[0][descr]': keyword,
          'criteria[0][$operator]': 'or',
          'criteria[1][is_active]': 1,
          order: 1,
          order_by: 'code',
          offset: 0
        }, function (resource) {
          if (!!resource && !!resource.content && !!resource.content.data)
            $scope.primaryCommodityCodes = resource.content.data;
        }, function () {
          $scope.primaryCommodityCodes = [];
        });
      return $scope.primaryCommodityCodes;
    }
  }

  function searchSecondaryCommodityCode(keyword) {
    if (!!keyword && keyword.length > 1) {
      searchModule.get(
        {
          module: 'commodity',
          'criteria[0][code]': keyword,
          'criteria[0][descr]': keyword,
          'criteria[0][$operator]': 'or',
          'criteria[1][is_active]': 1,
          order: 1,
          order_by: 'code',
          offset: 0
        }, function (resource) {
          if (!!resource && !!resource.content && !!resource.content.data)
            $scope.secondaryCommodityCodes = resource.content.data;
        }, function () {
          $scope.secondaryCommodityCodes = [];
        });
      return $scope.secondaryCommodityCodes;
    }
  }

  /** get commodity's child data*/
  function openChildCommodityCodePrimary(cc) {
    listChild.get({
        code: cc
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data.length > 0) {
          $scope.primaryCommodityCodes = resource.content.data;
        } else {
          toastr.error("There are no child on this commodity code");
        }
      },
      function () {
        toastr.error("Failed to get commodity codes data");
      })
  }

  function openChildCommodityCodeSecondary(cc) {
    listChild.get({
        code: cc
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data.length > 0) {
          $scope.secondaryCommodityCodes = resource.content.data;
        } else {
          toastr.error("There are no child on this commodity code");
        }
      },
      function () {
        toastr.error("Failed to get commodity codes data");
      })
  }

  function removeSecondaryCommodity() {
    $scope.contract.secondary_commodity_code = null;
    $scope.secondaryCommodityKeyword = null;
    $scope.contract.secondary_commodity = null;
  }

  function validateBusinessNature(primary, secondary) {
    $scope.contract.primary_business_nature = $scope.primaryBusinessNature.selected.shortDescr;
    if (!!secondary) {
      $scope.contract.secondary_business_nature = $scope.secondaryBusinessNature.selected.shortDescr;
      if (primary.descr === secondary.descr) {
        toastr.error('Primary business nature cannot be the same as secondary business nature');
        $scope.validatedBN = true;
      } else {
        $scope.validatedBN = false;
      }
    }
  }

  function close() {
    $uibModalInstance.close();
  }

  function onUpdatedContact(contact, index) {
    $scope.contract.contacts.splice(index, 1, contact);
  }

  function removeContact(index) {
    $scope.contract.contacts.splice(index, 1);
  }

  function onAddedContact(contact) {
    $scope.contract.contacts.splice($scope.contract.contacts.length, 0, contact);
  }

  function selectReceivingMode(poMethod) {
    if (poMethod === 0)
      return;

    if ([2, 6, 10, 14].indexOf(poMethod) > -1)
      $scope.receive_via_email = true;

    if ([4, 6, 12, 14].indexOf(poMethod) > -1)
      $scope.receive_via_fax = true;

    if ([8, 10, 12, 14].indexOf(poMethod) > -1)
      $scope.receive_via_post = true;
  }

    function updateCompanyContracts(contract) {
      var supplier = supplierFunctions.getSupplier();
      var cacheKey = supplier.basic_info.code + contract.company.company_code;
      window.localStorage.removeItem(cacheKey);
      $scope.hasWithdrawnChanges = false;
      var supplierCompanyContractObject = preparePayload(contract);

      if(!!$scope.newStatus){
        supplierCompanyContractObject.new_status = $scope.newStatus;
      }

      companyContractResource.patch({
        id: supplier._id
      },
      supplierCompanyContractObject,
      function (resource) {
        $uibModalInstance.close();
        toastr.success(resource.message);
        $state.go('main.user.supplier.manage', {status: 'active'});
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  /**
   * Download attachment
   *
   * @param {object} attachmentId
   */
  function downloadAttachment(attachmentId) {
    if (!!attachmentId) {
      singleAttachment.get({
        attachmentId: attachmentId
      }, function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data)
          getAttachmentFile(resource.content.data.temporary_url);
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * Get the attachment file
   *
   * @param {string} url
   */
  function getAttachmentFile(url) {
    var newUrl = $filter('format')(pathConstants.apiUrls.attachments.download, {
      hash: url
    });
    $http({
      url: newUrl
    }).then(function () {
      window.open(newUrl, '_self');
    }, function (error) {
      globalFunc.objectErrorMessage(error);
    });
  }

  function preparePayload(contract) {
    var temp = 0;
    if ($scope.receive_via_email) {
      temp += 2;
    }
    if ($scope.receive_via_fax) {
      temp += 4;
    }
    if ($scope.receive_via_post) {
      temp += 8;
    }

    return {
      payment_term_code: !!contract.payment_term_code ? contract.payment_term_code : undefined,
      payment_method_code: (!!contract.selectedPaymentMethod && !!contract.selectedPaymentMethod.code) ? contract.selectedPaymentMethod.code : undefined,
      receiving_mode: temp,
      tax_code: (!!contract.selectedTax && !!contract.selectedTax.code) ? contract.selectedTax.code : undefined,
      tax_rate: (!!contract.selectedTax && !!contract.selectedTax.rate) ? contract.selectedTax.rate : undefined,
      contacts: !!contract.contacts ? contract.contacts : undefined,
      currency_code: contract.selectedCurrency.code,
      bumi_status: contract.bumi_status,
      primary_business_nature: contract.primary_business_nature,
      secondary_business_nature: !!contract.secondary_business_nature ? contract.secondary_business_nature : '',
      primary_commodity_code: contract.primary_commodity.code,
      primary_commodity_descr: contract.primary_commodity.descr,
      secondary_commodity_descr: !!contract.secondary_commodity ? contract.secondary_commodity.descr : '',
      secondary_commodity_code: !!contract.secondary_commodity ? contract.secondary_commodity.code : '',
      company_code: contract.company.company_code
    }
  }

  function showVersionList() {
    $uibModal.open({
      templateUrl: 'components/supplierComponents/contractDetails/companyContractVersions/companyContractVersions.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        contractDetails: function () {
          return $scope.contract;
        },
        contractVersions: function () {
          return $scope.contractRevisions;
        },
        contractStatus: function () {
          return $scope.contract.status;
        }
      },
      controller: 'companyContractVersionsController'
    });
  }

  function prepareRevisions() {
    var supplier = supplierFunctions.getSupplier();
    if((!contractRevisions || contractRevisions.length === 0) && !supplierFunctions.pendingEditCompanyContract(supplier)) {
      return [];
    }

    var contract_current = _.cloneDeep($scope.contract);
    var revisions = [contract_current];
    if ($scope.contract.status === 'ACTIVE_PENDING' && !!pendingChanges) {
      revisions.push(baseContract);
    }

    if (!!contractRevisions && contractRevisions.length > 0) {
      revisions = revisions.concat(contractRevisions);
    }

    $scope.contractRevisions = revisions;
  }

  function reapplyWithdrawnEdit() {
    var supplier = supplierFunctions.getSupplier();
    var cacheKey = supplier.basic_info.code + $scope.contract.company.company_code;
    var withdrawnChanges = window.localStorage.getItem(cacheKey);
    withdrawnChanges = JSON.parse(withdrawnChanges);
    $scope.pendingChanges = withdrawnChanges;
    initialize();

    $scope.editClicked = true;
    $scope.hasWithdrawnChanges = false;
  }

  function updateCompanySupplierStatus(contract, status) {
    var supplier = supplierFunctions.getSupplier();
    var supplierCompanyContractObject = preparePayload(contract);

    supplierCompanyContractObject.new_status = status;
    supplierCompanyContractObject.status_reason = $scope.statusUpdatePayload.status_reason;
    return companyContractResource.patch({
        id: supplier._id
      },
      supplierCompanyContractObject,
      function (resource) {
        $uibModalInstance.close();
        toastr.success(resource.message);
        $state.go('main.user.supplier.manage', {status: 'active'});
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    ).$promise
  }

  function checkSupplierRequesterRole() {
    var isSupplierRequester = globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments,
      'Supplier Requester');

    return isSupplierRequester;
  }

  function allowEditingSupplier() {
    var isAdmin = globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Admin');
    var isSupplierManager = globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Supplier Manager');
    var isSupplierRequester = globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Supplier Requester');
    var isCompanySupplierRequester = globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Company Supplier Requester');

    return isAdmin || isTenantSuperAdmin() || isSupplierManager || isSupplierRequester || isCompanySupplierRequester;
  }

  function isTenantSuperAdmin() {
    return globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN');
  }

  function initialize() {
    $scope.isValidPhoneNo = true;
    $scope.isValidFaxNo = true;

    // replace changed fields if pending changes exists
    if (!!$scope.pendingChanges) {
      for (var property in $scope.contract) {
        if (property === 'status') {
          continue;
        }
        if ($scope.contract.hasOwnProperty(property)) {
          if (angular.isDefined($scope.pendingChanges[property])) {
            $scope.contract[property] = $scope.pendingChanges[property];
          }
        }
      }
    }

    // check if last withdrawn changes is still cached
    if (!$scope.pendingChanges) {
      var supplier = supplierFunctions.getSupplier();
      var cacheKey = supplier.basic_info.code + $scope.contract.company.company_code;
      var withdrawnChanges = window.localStorage.getItem(cacheKey);
      if (!!withdrawnChanges) {
        $scope.hasWithdrawnChanges = true;
      }
    }

    var supplier = supplierFunctions.getSupplier();
    if (!!supplier.edit && !!supplier.edit.company_contracts && !!supplier.edit.company_contracts[0].status) {
      $scope.isStatusChange = true;
    }

    prepareRevisions();

    if (!!$scope.contract && !!$scope.contract.payment_term_code) {
      $scope.contract.selectedPaymentTerm =
        globalFunc.findInArray($scope.paymentTerms, 'code', $scope.contract.payment_term_code);
    }

    if (!!$scope.contract && !!$scope.contract.payment_method_code) {
      $scope.contract.selectedPaymentMethod =
        globalFunc.findInArray($scope.paymentMethods, 'code', $scope.contract.payment_method_code);
    }

    if (!!$scope.contract && !!$scope.contract.tax_code) {
      $scope.contract.selectedTax =
        globalFunc.findInArray($scope.taxes, 'code', $scope.contract.tax_code, true);
    }

    if (!!$scope.contract && !!$scope.contract.currency_code) {
      $scope.contract.selectedCurrency =
        globalFunc.findInArray($scope.currencies, 'code', $scope.contract.currency_code);
    }

    if (angular.isDefined($scope.contract.primary_business_nature)) {
      for (var i = 0; i < $scope.businessNatures.length; i++) {
        if ($scope.businessNatures[i].shortDescr === $scope.contract.primary_business_nature) {
          $scope.primaryBusinessNature.selected = $scope.businessNatures[i];
          $scope.primaryBusinessNatureText = $scope.businessNatures[i].descr;
        }
      }
    } else {
      $scope.primaryBusinessNature.selected = $scope.businessNatures[0];
    }

    if (angular.isDefined($scope.contract.secondary_business_nature)) {
      for (var j = 0; j < $scope.businessNatures.length; j++) {
        if ($scope.businessNatures[j].shortDescr === $scope.contract.secondary_business_nature) {
          $scope.secondaryBusinessNature.selected = $scope.businessNatures[j];
          $scope.secondaryBusinessNatureText = $scope.businessNatures[j].descr;
        }
      }
    } else {
      $scope.secondaryBusinessNature.selected = $scope.businessNatures[0];
    }

    if (!!$scope.contract && $scope.contract.primary_commodity_code) {
      $scope.contract.primary_commodity = {
        code: $scope.contract.primary_commodity_code,
        descr: $scope.contract.primary_commodity_descr
      };
      $scope.primaryCommodityKeyword = $scope.contract.primary_commodity_code + ' ' + $scope.contract.primary_commodity_descr;
    }

    if (!!$scope.contract && $scope.contract.secondary_commodity_code) {
      $scope.contract.secondary_commodity = {
        code: $scope.contract.secondary_commodity_code,
        descr: $scope.contract.secondary_commodity_descr
      };
      $scope.secondaryCommodityKeyword = $scope.contract.secondary_commodity_code + ' ' + $scope.contract.secondary_commodity_descr;
    } else {
      $scope.contract.secondary_commodity = {
        code: '',
        descr: ''
      };
      $scope.secondaryCommodityKeyword = '';
    }

    selectReceivingMode($scope.contract.receiving_mode);

    if ($scope.isSourcingSupplierEnabled) {
      $scope.supplierType = supplierFunctions.findSupplierType(supplier.basic_info);
    }
  }

  initialize();
}

contractDetailsCtrl.$inject = [
  '$scope', 'baseContract', '$uibModalInstance', 'globalFunc', 'paymentTerms', 'paymentMethods', 'taxes', 'currencies',
  'commodities', 'companyContractResource', 'toastr', 'searchModule', 'listChild', 'supplierFunctions', 'contractRevisions',
  '$uibModal', 'pendingChanges', '$state', '$http', '$filter', 'pathConstants','singleAttachment', '$rootScope'
];

angular.module('metabuyer')
  .controller('contractDetailsCtrl', contractDetailsCtrl);
