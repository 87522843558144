/**
 * @name actionModalCtrl
 *
 * @description the controller for the modal for listing actions
 * in case of a successful request, the controller will check for the redirection, in case its set to true,
 * transition to the next ID provided.
 * else the success handler (if passed) will activate
 *
 *
 * @requires $scope
 * @requires $uibModalInstance
 * @requires $state
 *
 * @authors Ahmed Saleh (a.s.alsalali@gmail.com)
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

function actionModalCtrl (
  $scope, $uibModalInstance, $state, successHandler, errorHandler, globalFunc, pathConstants, $http,
  $filter, $translatePartialLoader, toastr
) {
  $translatePartialLoader.addPart('main/lang');
  $translatePartialLoader.addPart('suppliersManagement/newSupplier/lang');

  $scope.state        = '';
  $scope.modalHasBody = false;
  $scope.submitted    = false;
  $scope.attachment   = {};

  $scope.confirm = confirm;
  $scope.cancel = cancel;
  $scope.checkNextAction = checkNextAction;
  $scope.commentExceedsLimit = commentExceedsLimit;

  /**
   * function to add the extra comment to the payload passed back to the PR
   */
  function prepareExtraParams() {

    var key = !!$scope.commentKey ? $scope.commentKey : 'comment';
    if (!!$scope.extraParams) {
      var temp = {
        email: $scope.sendEmail
      };
      temp[key] = $scope.commentText;
      angular.extend($scope.extraParams, temp);
    }
  }

  function confirm() {
    if(commentExceedsLimit()) {
      return;
    }

    if (!!$scope.attachment && !!$scope.attachment.file) {
      if(exceedSizeLimit()){
        toastr.error('Maximum File Size is 20MB');
        return;
      }
      uploadAttachment($scope.attachment.file)
        .then(
          function (resource) {
            if (!!resource && !!resource.data && !!resource.data.content && !!resource.data.content.data) {
              angular.extend($scope.extraParams, {
                'attachment': resource.data.content.data
              });
              afterConfirm();
            }
          },
          function (error) {
            globalFunc.objectErrorMessage(error);
          }
        );
    } else {
      afterConfirm();
    }
  }

  function afterConfirm() {
    $scope.submitted = true;
    if(!!$scope.commentBlock) {
      prepareExtraParams();
    }

    if ($scope.module === 'proceedToPoCreation') {
      $scope.actionFunction();
      return true;
    }

    $scope.actionFunction().then(function (resource) {
      $uibModalInstance.close();
      /**
       * transition to the Next Action if its selected
       */

      if (!!$scope.nextActionId && !!$scope.goNextAction) {
        /**
         * In case the get for next action ids still loading and confirm is pressed
         */
        if($scope.nextActionId === 'loading')
          successHandler(resource);
        /**
         * Normal condition when transition is selected
         */
        else
          $state.go($scope.state, {id: $scope.nextActionId});
      }
      /**
       * In case it is the last approvable action, show modal to notify user
       */
      else if (!$scope.nextActionId && !!$scope.allActionsCompleted) {

        successHandler(resource);
        swal(
          {
            title: 'All PRs Approved',
            text: 'Good work, all your PRs have been approved',
            type: 'info',
            confirmButtonColor: '#D0D0D0',
            confirmButtonText: 'Close',
            closeOnConfirm: true
          }
        );
      }
      /**
       * use the success handler in case the transition not selected
       */
      else if (!!$scope.successHandler) {
        $scope.successHandler({resource: resource});
      }
    }, function (error) {
      $uibModalInstance.close();
      if (!!errorHandler) {
        errorHandler(error.content);
      } else {
        globalFunc.objectErrorMessage(error);
      }
    })
  }

  /**
   * Upload attachment
   *
   * @param {object} attachmentFile
   */
  function uploadAttachment(attachmentFile) {
    var fd = new FormData();
    fd.append('file', attachmentFile._file);
    fd.append('model', $scope.module);
    return $http.post(
      $filter('format')(pathConstants.apiUrls.attachments.reference, {referenceId: $scope.attachmentReference}), fd, {
        transformRequest: angular.identity,
        headers: {'Content-Type': undefined}
      }
    );
  }

  function cancel() {
    $uibModalInstance.close();
  }

  function checkNextAction(value){
    $scope.goNextAction = value;
  }

  function commentExceedsLimit() {
    return (!!$scope.commentLimit && !!$scope.commentText && ($scope.commentText.length > $scope.commentLimit));
  }
  function exceedSizeLimit(){
    var fileSizeLimit = $scope.module === 'Supplier' ? 20 : 5; //is MB
    return $scope.attachment.file.size > (fileSizeLimit * 1024 * 1024);
  }

  function initialize() {
    $scope.action = 'approval';

    /**
     * preparing the data for the transition and messages
     */
    if (!!$scope.module && $scope.module.toLowerCase() === 'pr') {
      $scope.state = 'main.user.purchaseRequisitions.details';
      $scope.message = 'purchase requisition';
    } else if (!!$scope.module && $scope.module.toLowerCase() === 'budget') {
      $scope.state = 'main.user.budget.details';

      if (!!$scope.$parent.nextActionAmount && ($scope.$parent.nextActionAmount - 1) > 1) {
        $scope.message = 'budgets';
      } else {
        $scope.message = 'budget';
      }
    } else if (!!$scope.module && $scope.module.toLowerCase() === 'po') {
      $scope.state = 'main.user.suppliersWeb.po.detail';
      $scope.message = 'purchase order';
      $scope.action = 'action';
    } else if (!!$scope.module && $scope.module.toLowerCase() === 'budgetv2') {
      $scope.state = 'main.user.budgetV2.details';

      if (!!$scope.$parent.nextActionAmount && ($scope.$parent.nextActionAmount - 1) > 1) {
        $scope.message = 'budgets';
      } else {
        $scope.message = 'budget';
      }
    }

    /**
     * auto select the Next as
     * TODO: this will be updated once we apply user preferences (8/9/2016, Ahmed Saleh)
     */
    if (!!$scope.nextActionId) {
      $scope.goNextAction = true;
    }

    /**
     * embed the URL to the body of the Modal
     */
    if (!!$scope.modalBodyUrl) {

    }

    if(!!$scope.commentBlock){
      $scope.commentText = '';
    }

    $scope.$watch('commentText', function() {
      commentExceedsLimit();
    });

  }

  initialize();

}

actionModalCtrl.$inject = [
  '$scope', '$uibModalInstance', '$state', 'successHandler', 'errorHandler', 'globalFunc', 'pathConstants', '$http',
  '$filter', '$translatePartialLoader', 'toastr'
];

angular.module('metabuyer')
  .controller('actionModalCtrl', actionModalCtrl);
