'use strict';


function suppliersImportCtrl($scope, toastr, pathConstants, $state, $http, $filter, globalFunc) {

  $scope.downloadLog = downloadLog;
  $scope.importingSuppliers = importingSuppliers;
  $scope.dataIsArray = dataIsArray;
  $scope.isSuccessfullyImported = isSuccessfullyImported;
  $scope.getLineNumber = getLineNumber;

  function isSuccessfullyImported(str) {
    if (_.isString(str))
      str = str.toLowerCase();
    return str.indexOf('successfully') > -1;
  }

  function downloadLog(hash) {
    var newUrl = $filter('format')(pathConstants.apiUrls.attachments.downloadErrorLog, {
      hash: hash
    });
    window.open(newUrl, '_self');
  }

  /**
   *
   * @param arr
   */
  function dataIsArray(arr) {
    return angular.isArray(arr);
  }

  /**
   * Get the line number based on the CSV file
   *
   * @param lineNumber
   */
  function getLineNumber(lineNumber) {
    return parseInt(lineNumber) +1;
  }

  function importingSuppliers(){
    $scope.uploadError = false;
    $scope.status = null;
    $scope.errorMessage = '';
    $scope.errorArray = [];

    var fd = new FormData();
    fd.append('file',$scope.submit_data.items);
    fd.append('class', 'App\\Metabuyer\\Supplier\\Models\\Supplier');

    $http.post(pathConstants.apiUrls.imports.now, fd, {
      transformRequest: angular.identity,
      headers: {'Content-Type': undefined}
    })
      .then(function onSuccess (data,status){
        $scope.status = status;
        if(!!data.data.content){
          if(!!data.data.content.message)
            toastr.success(data.data.content.message);
          else
            toastr.success("File successfully uploaded");

          $state.go('main.user.supplier.manage', { status: 'pending' });
        }
      })
      .catch(function onError (error) {
        $scope.uploadError = true;
        if(!!error.data.content && !!error.data.content.error){
          if (_.isString(error.data.content.error) && !_.isString(error.data.content.message)) {
            $scope.errorMessage = error.data.content.error;
            $scope.errorArray = globalFunc.reformatFromObjectToArray(error.data.content.message);
          } else if(!_.isString(error.data.content.error)) {
            $scope.errorArray = globalFunc.reformatFromObjectToArray(error.data.content.error);
          } else {
            $scope.errorMessage = error.data.content.error;
          }
        }
      });
  }
}

suppliersImportCtrl.$inject = ['$scope', 'toastr', 'pathConstants', '$state', '$http', '$filter', 'globalFunc'];


angular.module('metabuyer')
  .controller('suppliersImportCtrl', suppliersImportCtrl);
