'use strict';

function catalogFunction(
  $rootScope, $uibModal, globalFunc, toastr, purchaseRequisitionsServices, $state, metabuyerCache
) {
  var invalidCartItems = [];
  var selectedCompany = '';

  return {
    setCatalogCartItems: function (cartItems) {
      metabuyerCache.put('catalog-cart-items', cartItems);
    },
    getCatalogCartItems: function () {
      return metabuyerCache.get('catalog-cart-items');
    },
    resetCatalogCartItems: function () {
      metabuyerCache.remove('catalog-cart-items');
    },
    checkUserCompany: function (callback) {
      var self = this;
      var companies = $rootScope.currentUser.companies;
      if (companies.length === 1) {
        selectedCompany = companies["0"];
        self.setCartCompany(selectedCompany);
        callback();
      } else {
        var instantModal = $uibModal.open({
          templateUrl: 'components/catalogShoppingMode/catalogCart/companySelectionTemplate.html',
          controller: 'companySelectionCtrl',
          backdrop: 'static',
          keyboard: false,
          windowClass: 'company-selection-modal'
        });
        instantModal.result.then(function (data) {
          self.setCartCompany(data);
          callback();
        });
      }
    },
    setCartCompany: function (company) {
      selectedCompany = company;
    },
    getCartCompany: function () {
      return selectedCompany;
    },
    validateCartCatalogGroups: function (companyDetails) {
      var catalogCartItems = this.getCatalogCartItems();
      var companyCatalogGroupIds = _.pluck(companyDetails.catalog_groups, '_id');
      invalidCartItems = _.filter(catalogCartItems, function (item) {
        return !item.details.some(function (itemDetail) {
          return itemDetail.valid_for.catalog_group.some(function (detailCatalogGroup) {
            return companyCatalogGroupIds.indexOf(detailCatalogGroup) > -1;
          });
        });
      }, this);

      return invalidCartItems;
    },
    deleteInvalidCartItemsConfirmation: function () {
      var message = 'Would your like to proceed PR creation without: ';
      invalidCartItems.forEach(function (item, index) {
        message += item.item_name;
        if (index !== (invalidCartItems.length - 1)) {
          message += ', ';
        } else {
          message += ' ?'
        }
      });

      var self = this;
      swal({
          title: 'There is an invalid item(s) in your cart!',
          text: message,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#1ab394',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel PR Creation',
          closeOnConfirm: true,
          closeOnCancel: true
        },
        function (isConfirm) {
          if (isConfirm) {
            self.removeInvalidCartItem();
            self.createNewPR();
          } else {
            toastr.info('PR Creation has been cancelled.');
          }
        });
    },
    removeInvalidCartItem: function () {
      var catalogCartItems = this.getCatalogCartItems();
      _.forEach(invalidCartItems, function (invalidCartItem) {
        var itemIndex = catalogCartItems.indexOf(invalidCartItem);
        if(itemIndex != -1){
          catalogCartItems.splice(itemIndex, 1);
        }
      });
      toastr.warning('Item(s) has been removed due to invalid catalog group.');
      $rootScope.$broadcast('catalogCartItemDeleted');
    },
    createPRConfirmation: function () {
      var self = this;
      swal({
          title: 'Confirm Create PR?',
          text: 'A new PR will be created with the applicable item(s) inside your cart.',
          type: 'info',
          showCancelButton: true,
          confirmButtonColor: '#1ab394',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true,
          closeOnCancel: true
        },
        function (isConfirm) {
          if (isConfirm) {
            self.createNewPR();
          } else {
            toastr.info('PR Creation has been cancelled.');
          }
        });
    },
    /**
     * Get the first valid item detail id for the company.
     * returns null if no available valid item detail for the company
     *
     * @param item
     * @param company
     */
    getValidItemDetailIdForCompany: function(item, company) {
      var companyCatalogGroupIds = _.pluck(company.catalog_groups, '_id');

      var validItemDetails = _.filter(item.details, function (itemDetail) {
        return companyCatalogGroupIds.some(function (catalogGroupId) {
          return itemDetail.valid_for.catalog_group.indexOf(catalogGroupId) > -1;
        })
      });

      var firstValidDetailId = validItemDetails.length > 0 ? _.first(validItemDetails)._id : null;
      return firstValidDetailId;
    },
    createNewPR: function () {
      var self = this;
      var company = self.getCartCompany();
      var items = self.getCatalogCartItems();

      var transformedItems = [];
      items.forEach(function(item) {
        var detailId = self.getValidItemDetailIdForCompany(item, company);
        transformedItems.push({
          item_details_id: detailId,
          qty: item.quantityOrdered
        });
      });

      purchaseRequisitionsServices.createPRWithItems({
          company: {company_id : company._id},
          items: transformedItems
        },
        function (resource) {
          self.resetCatalogCartItems();
          if (invalidCartItems.length > 0) {
            self.setCatalogCartItems(invalidCartItems);
          }
          $state.go('main.user.purchaseRequisitions.details', {id: resource.content.data._id, PR: resource.content});
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    }
  }
}

catalogFunction.$inject = [
  '$rootScope', '$uibModal', 'globalFunc', 'toastr', 'purchaseRequisitionsServices', '$state', 'metabuyerCache'
];

angular
  .module('metabuyer')
  .factory('catalogFunction', catalogFunction);
