'use strict';

function bifrostEnvironmentsCtrl($scope, $http, pathConstants, globalFunc, toastr, dataList, $uibModal, $state) {
  $scope.dataList = dataList.data;
  $scope.editEnvironmentApi = editEnvironmentApi;
  $scope.testApi = testApi;

  function editEnvironmentApi(environment) {
    var modalInstance = $uibModal.open({
      templateUrl: 'app/settings/bifrostSettings/environments/editEnvironmentApiModal.html',
      backdrop: 'static',
      keyboard: false,
      resolve:{
        environment: function(){
          return environment;
        }
      },
      controller: function ($scope, $uibModalInstance, supplier, $http, pathConstants, toastr, $state) {
        $scope.id = environment.id;

        if (!!environment.jde_api) {
          $scope.username = environment.jde_api.username;
          $scope.password = environment.jde_api.password;
          $scope.confirmPassword = environment.jde_api.password;
          $scope.role = environment.jde_api.role;
          $scope.environment = environment.jde_api.env;
          $scope.po_creation_uri = environment.jde_api.po_creation_base_url;
          $scope.do_retrieval_uri = environment.jde_api.do_retrieval_base_url;
          $scope.processing_version = environment.jde_api.processing_version;
          $scope.version = environment.jde_api.version;
          $scope.jde_api_id = environment.jde_api.id;
          $scope.is_enabled = false;
          if (environment.jde_api.is_enabled === 1) {
            $scope.is_enabled = true;
          }
        }

        $scope.submit = submit;
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        function submit(){
          $scope.submitted = true;


          if (!$scope.username || !$scope.password || !$scope.confirmPassword || !$scope.role ||
            !$scope.environment || !$scope.po_creation_uri || !$scope.do_retrieval_uri || !$scope.processing_version) {
            toastr.error('Please fill up all fields.');
            return;
          }

          if ($scope.password !== $scope.confirmPassword) {
            toastr.error('Password does not match the confirm password.');
            return;
          }

          swal({
            title: 'Confirm update environment API?',
            text: 'This action is not revertable.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Confirm',
            closeOnConfirm: true
          }, function (isConfirm) {
            if (isConfirm) {
              confirmSubmit();
            }
          });
        }

        function confirmSubmit() {
          var path = pathConstants.apiUrls.bifrost.environment;

          $http.put(
            path,
            {
              id: $scope.id,
              username: $scope.username,
              password: $scope.password,
              role: $scope.role,
              environment: $scope.environment,
              po_creation_uri: $scope.po_creation_uri,
              do_retrieval_uri: $scope.do_retrieval_uri,
              processing_version: $scope.processing_version,
              version: $scope.version,
              jde_api_id: $scope.jde_api_id,
              is_enabled: $scope.is_enabled,
            }
          ).then(function onSuccess(){
            toastr.success("Updated environment API.");
            $uibModalInstance.close();
            $state.reload();
            close();
          }).catch(function onError (response){
            var error = "Failed to update environment API.";
            if (!!response.data) {
              error = response.data;
            }
            toastr.error(error);
          });
        }
      }
    });
  }

  function testApi(id) {
    var path = pathConstants.apiUrls.bifrost.environment;

    path += '/' + id + '/api-test';

    $http.get(
      path,{
        params: {
          id: id
        }
      }).then(function (resource){
        var result = resource.data;
        if (result.soap === 'Success') {
          toastr.success('SOAP API test success.')
        }
        if (result.soap !== 'Success') {
          toastr.error('SOAP API test failed.')
        }
        if (result.rest === 'Success') {
          toastr.success('REST API test success.')
        }
        if (result.rest !== 'Success') {
          toastr.error('REST API test failed.')
        }
    });
  }

  function initialize() {
  }

  initialize();
}

bifrostEnvironmentsCtrl.$inject = ['$scope', '$http', 'pathConstants', 'globalFunc', 'toastr', 'dataList', '$uibModal', '$state'];

angular
  .module('metabuyer')
  .controller('bifrostEnvironmentsCtrl', bifrostEnvironmentsCtrl);
