'use strict';

function userFunctions() {
  return {
    /**
       * Converts user status from string to integer or vice versa
       *
       * @param {string}  status   String representation of user status
       * @param {boolean} toString Indicator of conversion, true (int to string), false (string to int)
       * Values:
       *         0 for pending
       *         1 for active
       *         2 for inactive
       *         3 for rejected
       *         4 for deleted
       *         5 for draft
       *         6 for withdrawn
       */
    convertUserStatus: function (status, toString) {
      var userStatuses = {
        'pending': 0,
        'active': 1,
        'inactive': 2,
        'rejected': 3,
        'deleted': 4,
        'draft': 5,
        'withdrawn': 6
      };

      if (!!toString) {
        userStatuses = _.invert(userStatuses)
      }

      return userStatuses[status];

    }
  }
}

userFunctions.$inject = [];

angular
  .module('metabuyer')
  .factory('userFunctions', userFunctions)
