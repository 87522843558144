'use strict';

function CatalogsCodeDetailCtrl($scope, $state, catalog, toastr, catalogCode, globalFunc) {
  $scope.catalog = catalog.data;
  $scope.catalogBackup = angular.copy(catalog.data);
  $scope.editMode = editMode;
  $scope.submit = submit;
  $scope.cancel = cancel;
  $scope.statusList = [
    {
      code: 'ACTIVE',
      descr: 'Active'
    },
    {
      code: 'DEACTIVATED',
      descr: 'Deactivated'
    }
  ];

  function cancel() {
    if ($scope.editClicked) {
      $scope.editClicked = false;
      $scope.catalog = angular.copy($scope.catalogBackup);
      toastr.success('Changes reverted!');
      $state.go('main.user.catalogs.editCatalogCode',{id: $scope.catalog._id});
    } else {
      $state.go('main.user.catalogs.catalogCode');
    }
  }

  function submit(newData) {
    if (!validateData(newData)) {
      toastr.error('All fields are required.');
    }

    if (newData.is_active !== $scope.catalogBackup.is_active && newData.is_active === 'DEACTIVATED') {
      swal(
        {
          title: "Confirm Deactivate the Catalog Code",
          text: "This update will deactivate the Catalog Code",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1ab394  ",
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          closeOnConfirm: true,
          closeOnCancel: true
        },
        function (confirmed) {
          if (!!confirmed) {
            save(newData);
          } else {
            return false;
          }
        }
      );
    } else {
      save(newData);
    }
  }

  function save(newData) {
    var param = prepareData(newData);
    catalogCode.put(
      {
        id: newData._id
      },
      param,
      function(response){
        toastr.success('Catalog code details updated');
        if (!!response && !!response.content && !!response.content.data) {
          $scope.catalogBackup = angular.copy(response.content.data);
        }
        $state.go('main.user.catalogs.catalogCode');
      },
      function(error){
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function prepareData(newData) {
    var param = {};
    param.code = newData.code;
    param.descr = newData.descr;
    param.is_active = newData.is_active === "ACTIVE" ? 1 : 0;

    return param;
  }

  function validateData(data) {
    if (!data.descr) {
      return false;
    }

    return true;
  }

  function editMode() {
    $scope.editClicked = true;
  }

  function initialize() {
    // init function
    $scope.editClicked = false;
  }

  initialize();
}

CatalogsCodeDetailCtrl.$inject = ['$scope', '$state', 'catalog', 'toastr', 'catalogCode', 'globalFunc'];

angular.module('metabuyer')
  .controller('CatalogsCodeDetailCtrl', CatalogsCodeDetailCtrl);
