'use strict';

angular.module('metabuyer.services.auditTrail', ['ngResource'])
  .factory('auditList',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.auditList.get,
      {
      },
      {
        'get': { method: 'GET' }
      }
    );
  }).factory('listRawAudits',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.auditList.raw,
      {
        id: '@id'
      },
      {
        'get': { method: 'GET' }
      }
    );
  });
