'use strict';
/**
 * @name securityQuestionAnswerCtrl
 * @desc Controller for to submit Security Question to reset password
 */
function securityQuestionAnswerCtrl($rootScope, $scope, $state, $translate, toastr, globalFunc,
                                    getSecurityQuestionByEmail, getSecurityQuestionList, submitSecurityQuestionByEmail) {
  $scope.isLoading = true;
  $scope.securityQuestionList = [];
  $scope.selected = {
    security_question: null
  }
  $scope.errorCount = null;
  $scope.error = null;
  getSecurityQuestions();
  securityAnswerInputResolver();

  $scope.submitsecurityQuestionAnswer = function () {
    $scope.isLoading = true;
    submitSecurityQuestionByEmail.post(
      {},
      {
        email: $rootScope.tempEmail,
        security_question: $scope.selected.security_question,
        security_answer: btoa(unescape(encodeURIComponent($scope.security_answer))),
      },
      function (response) {
        $rootScope.tempEmail = null;
        toastr.success(response.content.message);
        $state.go('main.user.mainMenu.waiting-on-you');
      },
      function (error) {
        $scope.error = null;
        $scope.errorCount = null;
        if (!!error.data.answer_attempts_remaining) {
          $scope.errorCount = error.data.answer_attempts_remaining;
        }
        if (!!error.data.content.error) {
          $scope.error = error.data.content.error;
        }
        $scope.isLoading = false;
      }
    )
  }

  $scope.toLoginPage = function () {
    $rootScope.tempEmail = null;
    $state.go('login');
  }

  function getSecurityQuestion() {
    getSecurityQuestionByEmail.get(
      {
        email: $rootScope.tempEmail
      },
      {
      },
      function (response) {
        if (!!response.content.data.sq_id) {
          var index = _.findIndex($scope.securityQuestionList, function (securityQuestion) {
            return securityQuestion._id === response.content.data.sq_id;
          });
          $scope.selected.security_question = $scope.securityQuestionList[index] || null
        }
        $scope.isLoading = false;
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
        $scope.isLoading = false;
      }
    )
  }

  function getSecurityQuestions() {
    getSecurityQuestionList.get(
      {},
      {},
      function (response) {
        $scope.securityQuestionList = response.content.data;
        if ($translate.use() === 'cn') {
          _.forEach($scope.securityQuestionList, function(securityQuestion, index){
            securityQuestion.question = securityQuestion.question_chinese;
            $scope.securityQuestionList['index'] = securityQuestion;
          });
        }
        getSecurityQuestion();
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
        $scope.isLoading = false;
      }
    )
  }

  function securityAnswerInputResolver() {
    var isOkay = false;
    if (typeof CSS.supports) {
      if (CSS.supports('-webkit-text-security','disc')) {
        isOkay = true;
      }
    }
    if (!isOkay) {
      var input = document.createElement('input');
      input.className = "hide";
      document.getElementById('appending_here').appendChild(input);
      document.getElementById('security_answer').type = 'password';
    }
  }
}

securityQuestionAnswerCtrl.$inject = ['$rootScope', '$scope', '$state', '$translate', 'toastr', 'globalFunc',
                                      'getSecurityQuestionByEmail', 'getSecurityQuestionList', 'submitSecurityQuestionByEmail'];

angular
  .module('metabuyer')
  .controller('securityQuestionAnswerCtrl', securityQuestionAnswerCtrl);
