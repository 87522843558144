'use strict';

function tenderActionHeaderCtrl(
  $scope, globalFunc, $rootScope, $uibModal, $state, HighlightId, pathConstants, $filter, tenderFunction, tenderService, tenderApprovalAction, toastr
) {

  $scope.currentUser = $rootScope.currentUser || { _id: null };
  $scope.back = back;
  $scope.autoSaveActionHeader = autoSaveActionHeader;
  $scope.tenderStatus = tenderFunction.getTenderStatus();
  $scope.actionCanSubmit = actionCanSubmit;
  $scope.actionCanDelete = actionCanDelete;
  $scope.submitTender = submitTender;
  $scope.actionCanApprove = actionCanApprove;
  $scope.approvalAction = approvalAction;
  $scope.refreshTenderDetails = refreshTenderDetails;
  $scope.actionCanReject = actionCanReject;
  $scope.rejectTenderExtraParams = {};
  $scope.actionCanResubmit = actionCanResubmit;
  $scope.moveToDraft = moveToDraft;
  $scope.actionCanWithdraw = actionCanWithdraw;
  $scope.confirmWithdrawTender = confirmWithdrawTender;
  $scope.withdrawTenderExtraParams = {};
  $scope.deleteTender = deleteTender;
  $scope.actionCanDeactivate = actionCanDeactivate;
  $scope.deactivateTender = deactivateTender;
  $scope.statusUpdatePayload = {};
  $scope.actionCanReactivate = actionCanReactivate;
  $scope.reactivateTender = reactivateTender;
  $scope.actionCanReactivateExpired = actionCanReactivateExpired;
  $scope.reactivateExpiredModal = reactivateExpiredModal;
  $scope.createVO = createVO;
  $scope.actionCanCreateVO = actionCanCreateVO;
  $scope.actionCanCancelVO = actionCanCancelVO;
  $scope.cancelVO = cancelVO;
  $scope.gotoVoHistory = gotoVoHistory;
  $scope.showVersionList = showVersionList;
  $scope.printViaPDF = printViaPDF;
  $scope.canResendTenderEmail = canResendTenderEmail;
  $scope.resendPendingTenderEmail = resendPendingTenderEmail;

  $scope.showHideCond = function(fieldName) {
    var fieldName = (fieldName === undefined) ? null : fieldName;

    var canEdit = true;

    if ($scope.tender.is_vo && $scope.tender.status === 'draft' && tenderFunction.hasRoleRequester()) {

      if (['CHANGE_VENDOR', 'DATE_CHANGE'].includes($scope.tender.detail.type.key)) {
        canEdit = false;
      }

    } else {
      canEdit = $scope.editableStatus;
    }

    return canEdit;
  };

  function printViaPDF() {

    /**
     * Pass current logged in user's timezone
     */
    tenderService.downloadPdf({
      id: $scope.tender._id,
    }, function(resource) {
      var newUrl = $filter('format')(pathConstants.apiUrls.globalPDFHashDownload, {
        pdf_hash: resource.url
      });

      setTimeout(function() {
        window.open(newUrl, '_blank')
      }, 1001);
    }, function() {
      toastr.error('Failed to print Tender');
    });

  }

  /**
   * Display tender versions list in modal
   */
  function showVersionList() {

    $uibModal.open({
      templateUrl: 'components/tenderComponents/versions/tenderVersions.html',
      backdrop: 'static',
      keyboard: false,
      scope: $scope,
      resolve: {
        tenderVersions: function() {
          return $scope.revisions;
        },
        tender: function() {
          return $scope.tender;
        },
        mode: function() {
          return 'revision';
        }
      },
      controller: 'tenderVersionsCtrl'
    });
  }

  function back() {

    if (!!$scope.tender && $scope.tender._id) {
      HighlightId.setId($scope.tender._id);
    }

    var tenderStatus = $scope.tender.detail.status;
    var extraFilterKey = '[creator_info|_id][]';
    var extraFilterValue = $rootScope.currentUser._id;
    var tab = 'yours';

    if (['pending', 'active_pending'].includes(tenderStatus)) {
      tenderStatus = 'pending';
      tab = 'waiting';
      extraFilterKey = '[waiting_on][]';
    }

    $state.go('main.user.tender.manage', {
      status: tenderStatus,
      tab: tab,
      extraFilterKey: extraFilterKey,
      extraFilterValue: extraFilterValue,
      cursor: null
    });
  }

  function gotoVoHistory() {
    $state.go('main.user.tender.voHistory', { id: tenderFunction.getTenderId() });
  }

  function autoSaveActionHeader(fieldName, fieldValue) {
    var payload = tenderFunction.convertDotToObj(fieldName, fieldValue);
    tenderFunction.saveApi(payload, '');
  }

  function actionCanSubmit() {
    return tenderFunction.actionCanSubmit();
  }

  function actionCanDelete() {
    return tenderFunction.actionCanDelete();
  }

  function actionCanApprove() {
    return tenderFunction.actionCanApprove();
  }

  function actionCanReject() {
    return tenderFunction.actionCanReject();
  }

  function actionCanResubmit() {
    return tenderFunction.actionCanResubmit();
  }

  function actionCanWithdraw() {
    return tenderFunction.actionCanWithdraw();
  }

  function actionCanDeactivate() {
    return tenderFunction.actionCanDeactivate();
  }

  function actionCanReactivate() {
    return tenderFunction.actionCanReactivate();
  }

  function actionCanReactivateExpired() {
    return tenderFunction.actionCanReactivateExpired();
  }

  function actionCanCreateVO() {
    return tenderFunction.actionCanCreateVO();
  }

  function actionCanCancelVO() {
    return tenderFunction.actionCanCancelVO();
  }

  function reactivateExpiredModal() {
    var modalInstance = $uibModal.open({
      templateUrl: 'app/tender/detail/reactivateExpired/manage.html',
      controller: 'manageReactivateExpiredTender',
      backdrop: 'static',
      keyboard: false,
      size: 'md',
      resolve: {
        tender: function() {
          return $scope.tender;
        }
      }
    });
  }

  function reactivateTender() {
    return tenderService.reactivate({
        id: tenderFunction.getTenderId()
      }, {
        comment: $scope.statusUpdatePayload.comment
      },
      function(res) {
        toastr.success(res.message);
        $state.go('main.user.tender.manage', { status: 'pending', tab: 'yours', extraFilterKey: '[waiting_on][]', extraFilterValue: $rootScope.currentUser._id });
      },
      function(error) {
        if (error.data.content.error) {
          toastr.error(error.data.content.error);
        } else {
          toastr.error(error.data.message);
        }
      }
    ).$promise
  }

  function deactivateTender() {
    return tenderService.deactivate({
        id: tenderFunction.getTenderId()
      }, {
        comment: $scope.statusUpdatePayload.comment
      },
      function(res) {
        toastr.success(res.message);
        $state.go('main.user.tender.manage', { status: 'pending', tab: 'yours', extraFilterKey: '[waiting_on][]', extraFilterValue: $rootScope.currentUser._id });
      },
      function(error) {
        if (error.data.content.error) {
          toastr.error(error.data.content.error);
        } else {
          toastr.error(error.data.message);
        }
      }
    ).$promise
  }

  function moveToDraft() {
    return tenderService.moveToDraft({
      id: tenderFunction.getTenderId()
    }, function(response) {
      toastr.success('Successfully move to draft');
      $state.reload();
    }, function(error) {
      if (error.data.content.error) {
        toastr.error(error.data.content.error);
      } else {
        toastr.error(error.data.message);
      }
    }).$promise;
  }

  function refreshTenderDetails() {
    $state.transitionTo($state.current, $stateParams, {
      reload: true,
      inherit: false,
      notify: true
    });
  }

  /**
   * Function to load next waiting on task for this user on tender
   */
  function loadNextData() {
    if ($scope.tender.status === 'pending') {
      $scope.onHoldExtraParams = prepareOnHoldExtraParams();

      globalFunc.nextAvailableAction('App\\Metabuyer\\Tender\\Models\\Tender', $scope.tender._id).then(function(resource) {
        if (!!resource && !!resource.waitingOnObject && !!resource.waitingOnObject.context_id) {
          $scope.nextTender = resource.waitingOnObject;
          $scope.totalTenderOnHand = resource.count;
        } else
          $scope.nextTender = {};
      });
    }
  }

  /**
   * Preparing on hold extra params
   * @returns {object}
   */
  function prepareOnHoldExtraParams() {
    var userIDArray = [];
    userIDArray.push($scope.tender.created_by._id);

    return { user_ids: userIDArray };
  }

  function approvalAction(action, message, extraPayload) {
    var comment = typeof extraPayload !== 'undefined' ? extraPayload.comment : '';
    return tenderApprovalAction.post({}, {
        approval_id: tenderFunction.getApprovalId(),
        status: action,
        comment: comment
      },
      function() {
        if (!!message) {
          toastr.success(message);

          if (action === 'approve' && tenderFunction.isLastApprover() && (tenderFunction.isCategoryUnitPrice() || tenderFunction.isCatalogRequired())) {
            toastr.success('Please ensure that at least a Catalog is linked to this Tender.');
          }

          $state.go('main.user.tender.manage', { status: 'pending', tab: 'yours', extraFilterKey: '[waiting_on][]', extraFilterValue: $rootScope.currentUser._id });
        }
      },
      function(error) {
        globalFunc.objectErrorMessage(error.data);
      }
    ).$promise;
  }

  function submitTender() {
    return tenderService.submit({
      id: tenderFunction.getTenderId()
    }, function(response) {
      toastr.success('Successfully submitted');
      $state.go('main.user.tender.manage', { status: 'pending', tab: 'yours', extraFilterKey: '[submitted_by][]', extraFilterValue: $rootScope.currentUser._id });
    }).$promise;
  }

  function createVO() {
    return tenderService.createVO({
      id: tenderFunction.getTenderId()
    }, function(response) {
      toastr.success('Successfully add VO');
      $state.go('main.user.tender.manage', { status: 'draft', tab: 'yours', extraFilterKey: '[creator_info|_id][]', extraFilterValue: $rootScope.currentUser._id });
    }, function(error) {
      globalFunc.objectErrorMessage(error);
    }).$promise;
  }

  function deleteTender() {
    return tenderService.delete({
      id: tenderFunction.getTenderId()
    }, function(response) {
      toastr.success('Successfully deleted');
      $state.go('main.user.tender.manage', { status: 'draft', tab: 'yours', extraFilterKey: '[creator_info|_id][]', extraFilterValue: $rootScope.currentUser._id });
    }, function(error) {
      globalFunc.objectErrorMessage(error);
    }).$promise;
  }

  function cancelVO() {
    return tenderService.cancelVO({
      id: tenderFunction.getTenderId()
    }, function(response) {
      toastr.success('Successfully cancel');
      $state.go('main.user.tender.manage', { status: 'approved', tab: 'yours', extraFilterKey: '[creator_info|_id][]', extraFilterValue: $rootScope.currentUser._id });
    }, function(error) {
      globalFunc.objectErrorMessage(error);
    }).$promise;
  }

  /**
   * Withdraw tender
   */
  function confirmWithdrawTender() {
    return tenderApprovalAction.post({}, {
        approval_id: $scope.tender.approval_id,
        status: 'withdraw',
        comment: $scope.withdrawTenderExtraParams.comment
      },
      function() {
        toastr.success('The tender has been withdrawn.');
        $state.go('main.user.tender.manage', { status: 'withdrawn', tab: 'yours', extraFilterKey: '[creator_info|_id][]', extraFilterValue: $rootScope.currentUser._id });
      },
      function(error) {
        globalFunc.objectErrorMessage(error);
      }
    ).$promise;
  }
  
  /**
   * Check resend tender email eligibility
   */
  function canResendTenderEmail() {
    return $scope.tenderStatus.toLowerCase() === 'pending' && 
             (globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN') ||
              globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'ADMIN') ||
              isTenderCreator());
  }

  /**
   * Resend Pending Approval Email
   */
  function resendPendingTenderEmail() {
    var email_text = "Approval email will be resent to the following approvers\n\n";
    $scope.tender.waiting_on.forEach(function (currentVal) {
      email_text += currentVal.display_name + "\n";
    });

    swal({
      title: "Email re-sent",
      text: email_text,
      type: "success",
      showCancelButton: false,
      confirmButtonColor: "#1ab394",
      confirmButtonText: "Ok",
      timer: 5000,
      closeOnConfirm: true
    });

    return tenderService.resendPendingEmail({
      id: $scope.tender._id
    }, function(response) {
      $rootScope.$broadcast('refreshComments');
    }, function(error) {
      globalFunc.objectErrorMessage(error);
    }).$promise;
  }

  /**
   * Check if current user is the creator or not
   */
  function isTenderCreator() {
    return ($scope.currentUser._id === $scope.tender.creator_info._id);
  }

  function initialize() {
    loadNextData();
  }

  initialize();

}

tenderActionHeaderCtrl.$inject = [
  '$scope', 'globalFunc', '$rootScope', '$uibModal', '$state', 'HighlightId', 'pathConstants', '$filter', 'tenderFunction', 'tenderService', 'tenderApprovalAction', 'toastr'
];

angular
  .module('metabuyer')
  .controller('tenderActionHeaderCtrl', tenderActionHeaderCtrl);
