'use strict';

angular.module('metabuyer.services.appConfig', ['ngResource'])
  .factory('offlineConfigService',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.appConfig.offlineConfig,
      {},
      {
        'get': {method: 'GET'}
      }
    );
  });
