'use strict';


function deliveryaddress (){
  return {
    restrict: 'AE',
    templateUrl: 'components/deliveryaddress/deliveryaddressTemplate.html',
    controller: function($scope) {
    }
  }
}

angular
  .module('metabuyer')
  .directive('deliveryaddress', deliveryaddress);
