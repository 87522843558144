'use strict';
/**
 * @name accountLockedCtrl
 * @desc Controller for displaying page for locked accounts
 */
function accountLockedCtrl($scope, $rootScope, $state, ManageLocalStorage, $auth, pathConstants) {

  $scope.userInactive = $rootScope.userInactive;

  //applying CSS for the page on the controller level
  $rootScope.bodyClasses = 'gray-bg';

  $scope.logout = logout;
  $scope.goToAnswerPage = goToAnswerPage;
  $scope.isSourcingModeEnabled = !!pathConstants.isSourcingModeEnabled;

  function logout() {
    ManageLocalStorage.clearLocalStorage();
    window.localStorage.removeItem("satellizer_token");
    window.localStorage.removeItem("email");
    $auth.logout();
    $rootScope.currentUser = null;
    $state.go('login');
  }

  function goToAnswerPage() {
    ManageLocalStorage.clearLocalStorage();
    window.localStorage.removeItem("satellizer_token");
    window.localStorage.removeItem("email");
    $auth.logout();
    $rootScope.currentUser = null;
    $state.go('securityQuestionAnswer');
  }
}

accountLockedCtrl.$inject = ['$scope', '$rootScope', '$state', 'ManageLocalStorage', '$auth', 'pathConstants'];

angular
  .module('metabuyer')
  .controller('accountLockedCtrl', accountLockedCtrl);
