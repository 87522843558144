'use strict';

angular.module('metabuyer.services.catalogGroup' , ['ngResource'])
  .factory('catalogGroupList',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.catalogGroups.activeList, {},
        {
          'get' : { method : 'GET' }
        }
      );
    })
  .factory('getCatalogGroupActiveUsers',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.catalogGroups.activeUsers,
        {},
        {
          'get': {method: 'GET'}
        }
      );
    });
