'use strict';

function nonCatalogAttachment() {
    return {
      restrict: 'E',
      templateUrl: 'components/purchaseRequisitionV2Components/nonCatalogAttachment/nonCatalogAttachmentTemplate.html',
      controller: 'nonCatalogAttachmentCtrl',
      scope: {
        maxFile: '=',
        url: '=',
        param: '=',
        acceptedFiles: '=',
        autoUpload: '=',
        attachments: '=',
        singleAttachmentService: '=',
        module: '=',
        isEditable: '='
      }
    }
  }

function nonCatalogAttachmentCtrl($scope, globalFunc, $auth, $filter, prV2Function, pathConstants, $http, toastr,
                                  $rootScope) {
    $scope.download = download;
    $scope.deleteFile = deleteFile;
    $scope.showDropZone = $scope.isEditable === undefined ? true : $scope.isEditable;
    $scope.itemData = null;
    $scope.dropzoneConfig = {
      options: {
        // passed into the Dropzone constructor
        //maxFilesize in MB
        autoProcessQueue: !!$scope.autoUpload ? true : false,
        maxFilesize: 20,
        maxFiles: $scope.maxFile,
        url :$filter('format')($scope.url, $scope.param),
        // simultaneous uploads in a time
        parallelUploads: 1,
        init: function() {
          /**
           * Append attachment model name to the form data
           */
          this.on('sending', function(file, xhr, formData){
            formData.append('model', $scope.attachmentModel);
          });

          var dropzone = this;

          $scope.$on('clearNonCatalogItem', function(event){
            dropzone.removeAllFiles();
          });

          $scope.$on('uploadNonCatalogAttachment', function(event, itemData){
            if (dropzone.getQueuedFiles().length > 0){
              // take itemData to be used as param if we dont have it yet
              if (!$scope.param){
                $scope.itemData = itemData || null;
              }
              dropzone.processQueue();
            }
          })

        },
        headers: {
          'Authorization': 'Bearer ' + $auth.getToken()
        },
        previewTemplate: '<div class="dz-preview dz-file-preview">' +
        '<div class="dz-details">' +
        '<div class="dz-filename"><span data-dz-name></span></div>' +
        '<div class="dz-size" data-dz-size></div>' +
        '<img data-dz-thumbnail />' +
        '</div>' +
        '<div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>' +
        '<div style="z-index: 999; position: absolute; width: 30px; height: 30px; font-size: 25px; text-align: center; right: -10px; top: -10px;">' +
        '<a data-dz-remove type="button" class="btn-xs btn-white pull-right"><i class="fa fa-trash-o"></i></a>' +
        '</div>' +
        '<div class="dz-error-message"><span data-dz-errormessage></span></div>' +
        '</div>',
        acceptedFiles: $scope.acceptedFiles
      },
      eventHandlers: {
        processing: function(file) {
          if (!!$scope.itemData){
            var param = {
              requisition_item_id: $scope.itemData.item_id
            };
            this.options.url = $filter('format')($scope.url, param)
          }
        },

        sending: function (file, xhr, formData) {
          if (!!$scope.itemData){
            formData.append('itemImage', file)
          }
        },
        error: function (file, error) {
          globalFunc.objectErrorMessage(error);
          this.removeFile(file);
        },
        success: function (file, resource) {
          var _this = this;
            setTimeout(function () {
              _this.removeFile(file);
            }, 1000);
          if (!!resource.content && !!resource.content.data){
            toastr.success("Successfully Added Attachment");
            $rootScope.$broadcast('refreshComments');

            if ($scope.module === 'quotation') {
              if (!_.isEmpty($scope.attachments)){
                $scope.attachments.push(resource.content.data)
              } else {
                $scope.attachments = [resource.content.data];
              }
            } else {
              // current indicator if it was an attachment upload and not an item image upload
              if (!!resource.content.data.extension) {
                $rootScope.$broadcast('addToAttachmentsOnPrV2', resource.content.data);
              } else{
                $rootScope.$broadcast('updateDetailsDataV2', globalFunc.findInArray(
                  resource.content.data.pr_items, '_id', $scope.itemData.item_id)
                );
              }
            }
          }
      }
    }
  };

  function download(file_id) {
    var param = _.cloneDeep($scope.param);
    param['attachment_id'] = file_id;

    $scope.singleAttachmentService.get(
      param
    ).$promise.then(
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data.temporary_url) {
          var newUrl = $filter('format')(pathConstants.apiUrls.attachments.downloadAttachment, {
            hash: resource.content.data.temporary_url
          });
        }
        // Use HTTP to allow and use the application/json
        // and download with the newUrl
        $http({
          url: newUrl
        }).then(function () {
          window.open(newUrl, '_self');
        }, function (response) {
        });
      }, function () {
        toastr.error('Failed to download attachment.');
      }
    );
  }

  function deleteFile(file) {
    var file_index = _.findIndex($scope.attachments, function (file_item) {
      return file._id === file_item._id;
    });

    $scope.attachments.splice(file_index, 1);
    var param = _.cloneDeep($scope.param);
    param['attachment_id'] = file._id;

    $scope.singleAttachmentService.delete(
      param
      ).$promise.then(
      function (resource) {
        toastr.success(resource.content.message);
        if (!$scope.attachments.length) {
          $scope.showDropZone = true;
        }
      }, function () {
        toastr.error('Failed to delete attachment.');
        $scope.attachments.splice(file_index, 0, file);
      });
  }

  $scope.$watch('isEditable', function(newValue, oldValue) {
    if (newValue === undefined) {
      newValue = true;
    }
    $scope.showDropZone = newValue;
  });

}

nonCatalogAttachmentCtrl.$inject = [ '$scope', 'globalFunc', '$auth', '$filter', 'prV2Function', 'pathConstants',
  '$http', 'toastr', '$rootScope'];

angular
    .module('metabuyer')
    .directive('nonCatalogAttachment', nonCatalogAttachment)
    .controller('nonCatalogAttachmentCtrl', nonCatalogAttachmentCtrl);
