'use strict';

/**
 * @name budgetDetailsHeader v2 Directive
 * @desc Directive for displaying budget details header, editable and non editable
 *
 * @author Ameer Asraf aka Jamal <ameerasraf_92@yahoo.com>
 * @copyright 2020 Metacloud Sdn. Bhd.
 */
angular
  .module('metabuyer')
  .directive('budgetDetailsHeaderV2', function () {
    return {
      restrict: 'E',
      scope: true,
      template: '<div ng-include="budgetTemplateUrl"></div>',
      controller: function ($scope, toastr) {
        $scope.getTemplateUrl = getTemplateUrl;
        $scope.showBudgetItems = showBudgetItems;
        $scope.hasParentBudget = hasParentBudget;
        $scope.changeAssignmentDashboardSetting = changeAssignmentDashboardSetting;
        $scope.showItemListing = showItemListing;
        $scope.hasCostCenter = hasCostCenter;
        $scope.clearExpenseType = clearExpenseType;
        $scope.mandatoryAssetCategory = mandatoryAssetCategory;
        $scope.mandatoryAssetCategoryClassCheck = mandatoryAssetCategoryClassCheck;
        $scope.currencyUpdated = currencyUpdated;
        $scope.checkBlockedAmount = checkBlockedAmount;
        $scope.checkBudgetAmount = checkBudgetAmount;
        $scope.disableBlockedReason = true;
        $scope.previousBudgetCode = $scope.budget.currency.code;

        // Resets amount fields if currency changes
        function currencyUpdated(budget) {
          if (budget.currency.code !== $scope.previousBudgetCode) {
            $scope.previousBudgetCode = budget.currency.code;
            budget.amount = null;
            budget.block_amount = null;
            $scope.disableBlockedReason = true;
            budget.block_amount_reason = null;
          }
        }

        function checkBudgetAmount(budget) {
          if (!!budget.amount) {
            // validate decimals
            var entry = budget.amount.toString();
            var validValue = checkDecimal(entry, !budget.currency.allow_decimal);
            if (!validValue){
              budget.amount = Number(entry.slice(0, entry.indexOf('.')));
            }
          }
        }

        function checkBlockedAmount(budget) {
          if (!budget.block_amount) {
            $scope.disableBlockedReason = true;
            budget.block_amount_reason = null;
          } else {
            $scope.disableBlockedReason = false;

            // validate decimals
            var entry = budget.block_amount.toString();
            var validValue = checkDecimal(entry, !budget.currency.allow_decimal);
            if (!validValue){
              budget.block_amount = Number(entry.slice(0, entry.indexOf('.')));
            }
          }
        }

        function checkDecimal(entry, preventDecimal){
          var entry = entry.toString();
          if (!!preventDecimal) {
            var dotCount = (entry.match(/\./g) || []).length;
            if (dotCount > 0){
              toastr.error('Decimal is not allowed for this currency.');
              return false;
            }
          }

          return true;
        }

        function hasCostCenter(){
          return !_.isEmpty($scope.budget.cost_center);
        }

        function changeAssignmentDashboardSetting(dashboard) {
          _.forEach($scope.budget.assignments, function (assignment) {
            assignment.pr_dashboard_display = dashboard;
          });
        }

        function showBudgetItems() {
          $scope.showBudgetItemListing = !$scope.showBudgetItemListing;
        }

        /**
         * Check if budget is a revision
         */
        function hasParentBudget() {
          return !!$scope.budget.parent_id;
        }

        /**
         * Indicator to show item listing
         */
        function showItemListing() {
          // don't show if budget is revised budget or status is in pending/on-hold/draft/revision
          return !$scope.budget.parent_id &&
            ['pending', 'draft', 'revision'].indexOf($scope.budget.status) === -1;
        }

        function getTemplateUrl(state) {
          switch (state) {
            case 'editable':
              $scope.budgetTemplateUrl = 'components/budgetV2/detailsHeader/detailsHeader.editableTemplate.html';
              break;
            case 'notEditable' :
              $scope.budgetTemplateUrl = 'components/budgetV2/detailsHeader/detailsHeader.nonEditableTemplate.html';
              break;
          }
        }

        // Watches the getEditingBudgetState to determine editable/non editable template
        $scope.$watch('getEditingBudgetState()', function (newValue) {
          getTemplateUrl(newValue);
        });

        function clearExpenseType() {
          delete $scope.budget.expense_type;
        }

        function mandatoryAssetCategory() {
          return $scope.budget.expense_type_category === 'CAPEX';
        }

        function mandatoryAssetCategoryClassCheck() {
          return $scope.budget.expense_type_category === 'CAPEX' &&
            !$scope.budget.asset_category && $scope.headerDetailsError;
        }
      }
    }
  });
