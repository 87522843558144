'use strict';
/**
 * @name searchBar Directive
 *
 * @desc Directive for searchBars
 *
 * placeholder: is to accept placeholder
 * searchState: to determine which state to search
 * advancedSearchState: to determine which state to search for advanced search if it exists. see src/app/advancedSearch/
 * searchOrderBy: to determine what is the order by of the search results, based on the sortable fields
 * searchOrder: to determine what is the order of the search results, 0 for descending 1 for ascending
 * searchStatus: to determine what status to send on search
 * typeOfQuery: to determine what type of query( query or title )
 * textModel: placing the searched text in case of the page refreshed
 * filters: array of filters to list down the filters for each manage page
 * searchFilter: the selected filter, to set it in case of refresh
 * condition: the conditions in of the filters based on the selected state
 * parentState: the state to back to once a reset function is called
 * parentStateParams: the parameters to be passed to the state of the parent state (removing both Filter and Query)
 *
 *
 * @authors Ahmed Saleh <a.s.alsalali@gmail.com>
 * @copyright Sunway Metacloud &copy; 2016
 */
angular
  .module('metabuyer')
  .directive('searchBar',function() {
    return {
      restrict: 'E',
      templateUrl: 'components/searchBar/searchBarTemplate.html',
      scope: {
        placeholder: '@',
        searchState: '@',
        advancedSearchState: '@?',
        searchOrderBy: '@',
        searchOrder: '@',
        searchStatus: '@',
        textModel: '@?',
        filters: '=?',
        searchFilter: '@?',
        condition: '=?',
        parentState: '@?',
        parentStateParams: '=?',
        allFilter: '=?',
        allFilterLast: '=?'
      },
      controller: function ($scope, $state, $translate) {

        $scope.selectFilter = selectFilter;
        $scope.openDatepicker = openDatepicker;
        $scope.reset = reset;
        $scope.search = search;
        $scope.goAdvancedSearch = goAdvancedSearch;
        $scope.searchDate = {
          open: false
        };

        function reset() {
          $scope.query = '';
          $scope.searchDate.value = null;
          if(!!$scope.parentState){

            if(!!$scope.parentStateParams) {
              $scope.parentStateParams.filter = null;
              $scope.parentStateParams.query = null;
              $scope.parentStateParams.type = null;
              $scope.parentStateParams.page = null;
            }
            $state.go($scope.parentState, $scope.parentStateParams);
          }
        }

        function search(query) {
          var tab = {};
          var params;
          tab.route = $scope.searchState;
          tab.heading = 'Search';
          tab.params = {
            status: $scope.searchStatus
          };
          var arr = [];

          if (!!query) {
            params = _.cloneDeep($scope.parentStateParams);
            params.filter = !!$scope.selectedFilter ? $scope.selectedFilter.id : null;
            params.query = query;
            params.cursor = null;
            params.type = undefined;
            params.status = (!$scope.searchStatus ? 'all' : $scope.searchStatus);
            params.page = 1; // reset pagination for search

            $scope.active = true;
            var double = !!$scope.selectedFilter ? $scope.selectedFilter.type === 'double' : false;
            if(!params.filter || !double || !params.query)
              $state.go($scope.searchState, params);
            else {

              var baseAmount = params.query;
              var number = baseAmount.toString().split('.')[0];
              var decimals = baseAmount.toString().split('.')[1];
              var startDecimals;
              var endDecimals;
              if (!!decimals) {
                if (decimals.length === 1) {
                  startDecimals = decimals + '000';
                  endDecimals = decimals + '999';
                } else if (decimals.length === 2) {
                  startDecimals = decimals + '00';
                  endDecimals = decimals + '99';
                } else if (decimals.length === 3) {
                  startDecimals = decimals + '0';
                  endDecimals = decimals + '9';
                } else {
                  startDecimals = decimals;
                  endDecimals = decimals;
                }
              } else {
                startDecimals = '0000';
                endDecimals = '9999';
              }
              arr =[];
              arr.push(number+'.'+startDecimals);
              arr.push(number+'.'+endDecimals);
              params.query = arr;
              params.type = 'double';
              $state.go($scope.searchState, params);
            }
          }
          /**
           * in case the search was using Date
           */
          else if (!!$scope.searchDate.value) {
            arr = [];
            var startOfDay = (Date.parse(moment($scope.searchDate.value).startOf('day')) / 1000);
            var endOfDay = (Date.parse(moment($scope.searchDate.value).endOf('day')) / 1000);
            arr.push(startOfDay);
            arr.push(endOfDay);

            params = {
              status: tab.params.status,
              query: arr,
              type: 'date',
              filter: !!$scope.selectedFilter ? $scope.selectedFilter.id : null
            };

            $scope.active = true;
            $state.go($scope.searchState, params);
          }

        }

        /**
         * remove the searched test or date and activate which Input to show
         * @param item
         */
        function selectFilter(item) {
          if (!!item.type && $scope.searchType !== item.type) {
            $scope.query = '';
            $scope.searchDate = {};
          }
          $scope.searchType = !!item.type ? item.type : 'text';
          $scope.selectedFilter = item;
        }

        /**
         * filter the dropdown based on the sortable and condition
         */
        function prepareFilteredList(){
          // If allFilter was provided, use the value provided, else true by default
          $scope.allFilterFlag = !_.isUndefined($scope.allFilter) ? $scope.allFilter : true;
          $scope.allFilterLastFlag = !_.isUndefined($scope.allFilterLast) ? true : $scope.allFilterLast;

          if (!!$scope.filters) {
            $scope.filtersList = [];
            for (var i in $scope.filters) {
              /**
               * the condition to check if unsearchable column as well as the date
               * the dates will have a different treatment once supported
               */
              if (!$scope.filters[i].unsearchable) {
                if ($scope.filters[i].conditional) {
                  for(var j in $scope.filters[i].condition){
                    if ($scope.condition === $scope.filters[i].condition[j]) {
                      $scope.filtersList.push($scope.filters[i]);
                    }
                  }
                }
                else {
                  $scope.filtersList.push($scope.filters[i]);
                }
              }
            }
            if ($scope.allFilterFlag === true) {
              $scope.filtersList.splice(0, 0, {
                label: 'All',
                translationId: 'statuses.all',
                id: null
              });
            }
            if ($scope.allFilterLastFlag === true) {
              $scope.filtersList.push($scope.filtersList.shift());
            }
            selectFilter($scope.filtersList[0]);
            $translate('statuses.all').then(function(trans) {
              for(var filterIndex in $scope.filtersList) {
                var filterData = $scope.filtersList[filterIndex];
                if (filterData.translationId !== 'statuses.all')
                  continue;
                $scope.filtersList[filterIndex].label = trans;
              }
            });
          }
        }

        function openDatepicker($event) {

          $event.preventDefault();
          $event.stopPropagation();
          $scope.searchDate.open = true;
        }

        function goAdvancedSearch() {
            $state.go($scope.advancedSearchState);
        }

        function initialize() {

          prepareFilteredList();

          /**
           * setting the selected dropdown option in case of Refresh
           */
          for (var i in $scope.filtersList) {
            if ($scope.filtersList[i].id === $scope.searchFilter) {
              selectFilter($scope.filtersList[i]);
              break;
            }
          }

          if (!!$scope.selectedFilter && $scope.selectedFilter.type === 'date') {
            $scope.searchType = 'date';
          }
          else if (!!$scope.selectedFilter && $scope.selectedFilter.type === 'double') {
            $scope.searchType = 'double';
          }
          else {
            $scope.searchType = 'text';
          }
          /**
           * checking if the data can be parsed into an array or is it a text
           */
          if ($scope.searchType === 'double') {
            $scope.query = Number($scope.textModel);
          } else {
            try {
              $scope.searchDate.value = (JSON.parse($scope.textModel)[0]) * 1000;
            } catch (e) {
              $scope.query = $scope.textModel;
            }
          }
        }

        initialize();

        /**
         * listener to change the list in case of a change of a status in the manage screen
         */
        $scope.$watch('condition', function(newV, oldV){
          if(oldV !== newV) {
            $scope.selectedFilter = {};
            prepareFilteredList();
          }
        });

        /**
         * listener to change the list in case of a change of a status in the manage screen
         */
        $scope.$watch('filters', function(newV, oldV){
          if(!!newV && newV !== oldV) {
            $scope.query = '';
            $scope.searchDate = {};
            prepareFilteredList();
          }
        });
        $scope.$watch('textModel', function (newvalue, oldvalue) {
          // listener that triggered when the searchbar directive property(textModel) has changed
          $scope.query = newvalue;// update the new value in the search bar
        });

      }
    }
  });
