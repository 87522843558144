'use strict';

/**
 *
 * @param $scope
 * @param $uibModalInstance
 * @param $http
 * @param pathConstants
 * @param $filter
 * @param toastr
 * @param research
 * @param researchServices
 * @param globalFunc
 */
function detailResearch($scope, $uibModalInstance, $http, pathConstants, $filter, toastr, research,
                        researchServices, globalFunc) {
  $scope.editClicked = false;
  $scope.research = research.data;
  $scope.tab = 1;
  $scope.cancel = cancel;
  $scope.editResearch = editResearch;
  $scope.updateResearch = updateResearch;

  function cancel() {
    $uibModalInstance.close();
  }

  function editResearch() {
    $scope.editClicked = true;
  }

  function updateResearch() {
    researchServices.put(
      {
        id: $scope.research._id
      },
      {
        descr: $scope.research.descr
      },
      function (resource) {
        toastr.success('Research data has been updated');
        $uibModalInstance.close(resource.data);
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
        return false;
      }
    );
  }
}

detailResearch.$inject = ['$scope', '$uibModalInstance', '$http', 'pathConstants', '$filter', 'toastr', 'research',
  'researchServices', 'globalFunc'];

angular
  .module('metabuyer')
  .controller('detailResearch', detailResearch);



