'use strict';

function maintenanceCtrl ($scope, $state, bifrostFeatureToggle, $rootScope, profile, globalFunc) {

  $scope.$emit('pageTitleChanged', 'Maintenance');
  $scope.$emit('actionsChanged', []);
  $scope.bifrostFeatureToggle = bifrostFeatureToggle.is_enabled;

  $scope.tabs = [
    {
      label: 'GRN - Change GRN DO Number',
      route: 'main.user.maintenance.changeGrnDoNumber',
      key: 'changeGrnDoNumber'
    },
    {
      label: 'Company Suppliers - Display Suppliers with incomplete Company Suppliers',
      route: 'main.user.maintenance.supplierCompanyContract',
      key: 'supplierCompanyContract'
    },
    {
      label: 'Holiday',
      route: 'main.user.maintenance.holiday',
      key: 'holiday'
    },
    {
      label: 'User - Change all Users\' password',
      route: 'main.user.maintenance.changeAllUsersPassword',
      key: 'userChangeAllPassword'
    }
  ];

  if (!!bifrostFeatureToggle.is_enabled) {
    $scope.bifrostTabs = [
      {
        label: 'Integration - User UUID Mapping for CMMS',
        route: 'main.user.maintenance.usersUuidMapping',
        key: 'usersUuidMapping'
      },
      {
        label: 'Integration - Change Mapping Field Size',
        route: 'main.user.maintenance.changeMappingFieldSize',
        key: 'changeMappingFieldSize'
      },
      {
        label: 'Integration - Re-outbound Tool',
        route: 'main.user.maintenance.reoutboundTool',
        key: 'reoutboundTool'
      },

      {
        label: 'Integration - Integration Configurations',
        route: 'main.user.maintenance.integrationConfigurations',
        key: 'integrationConfigurations'
      },
    ];

    $scope.tabs = $scope.tabs.concat($scope.bifrostTabs);
  }

  if ($rootScope.isV2Enabled) {
    $scope.v2Tabs = [
      {
        label: 'Budget - Mass update Budget Owner',
        route: 'main.user.maintenance.massUpdateBudgetOwner',
        key: 'massUpdateBudgetOwner'
      }
    ];

    if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN') && 
    globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'ADMIN')) {
      $scope.tabs = [];
      $scope.tabs = $scope.tabs.concat($scope.v2Tabs);
    } else {
      $scope.tabs = $scope.tabs.concat($scope.v2Tabs);
    }
  }

  $scope.selectTab = function (tab) {
    $state.go(tab.route);
  };

}
maintenanceCtrl.$inject = ['$scope', '$state', 'bifrostFeatureToggle', '$rootScope', 'profile', 'globalFunc'];

angular
  .module('metabuyer')
  .controller('maintenanceCtrl', maintenanceCtrl);
