'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('main.user.approvalFlow', {
        url: '/approval-flow',
        controller: 'approvalFlowController',
        templateUrl: 'app/approvalFlow/approvalFlow.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'ApprovalTemplate', 'Read')) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.approvalFlow.manage', {
        templateUrl: 'app/approvalFlow/manage/manage.html',
        url: '/manage',
        controller: 'approvalFlowManagementController',
        resolve: {
          companies: function ($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'companies',
                offset: 0,
                order_by: 'descr',
                order: 1
              },
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function() {
            return 'Approval Flow - Manage';
          }
        }
      })
      .state('main.user.approvalFlow.detail', {
        templateUrl: 'app/approvalFlow/detail/detail.html',
        url: '/detail/{id:[a-zA-Z0-9]+}',
        controller: 'approvalFlowDetailController',
        resolve: {
          approvalFlowDetail: function($q, approvalTemplateSingle, $stateParams) {
            var deferred = $q.defer();
            approvalTemplateSingle.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content.data);
                }
              },
              function (error) {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function() {
            return 'Approval Flow - Detail';
          }
        }
      })
      .state('main.user.approvalFlow.preview', {
        templateUrl: 'app/approvalFlow/preview/detail.html',
        url: '/preview',
        controller: 'approvalFlowPreviewController',
        resolve: {
          accessRights: function (profile, UserPermissions, $state, $rootScope) {
            if (!!$rootScope.isOffline) {
              $state.go('notAuthorized');
            }
          },
          companies: function ($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'companies',
                offset: 0,
                order_by: 'descr',
                order: 1
              },
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Approval Flow - Preview';
          }
        }
      })
  }]);
