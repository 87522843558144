'use strict';
/**
 *
 * @description
 *
 * State for the Advanced Search
 *
 *
 * @author Harith <ainamirulharith@gmail.com>
 * @copyright Sunway Metacloud &copy; 2016
 */
angular.module('metabuyer').config(['$stateProvider', function($stateProvider) {

  $stateProvider
    .state('main.user.advancedSearch', {
      url: '/advanced-search',
      abstract: true,
      templateUrl: 'app/advancedSearch/advancedSearchManagement.html',
      controller: advancedSearchCtrl,
      resolve: {
        prepareTranslation: function ($translatePartialLoader) {
          $translatePartialLoader.addPart('advancedSearch/lang');
        },
      }
    })
    .state('main.user.advancedSearch.purchaseRequisition', {
      url: '/advanced-search/purchase-requisition',
      templateUrl: 'app/advancedSearch/purchaseRequisition/purchaseRequisitionAdvancedSearch.html',
      controller: purchaseRequisitionAdvancedSearchCtrl,
      resolve: {
        $title: function() {
          return 'Advanced Search PR';
        }
      }
    })
    .state('main.user.advancedSearch.purchaseRequisitionV2', {
      url: '/advanced-search/requisition',
      templateUrl: 'app/advancedSearch/purchaseRequisitionV2/purchaseRequisitionV2AdvancedSearch.html',
      controller: purchaseRequisitionV2AdvancedSearchCtrl,
      resolve: {
        $title: function() {
          return 'Advanced Search PR';
        }
      }
    })
    .state('main.user.advancedSearch.escalation', {
      url: '/advanced-search/escalation',
      templateUrl: 'app/advancedSearch/escalation/escalationAdvancedSearch.html',
      controller: escalationAdvancedSearchCtrl,
      resolve: {
        $title: function() {
          return 'Advanced Search Escalation';
        }
      }
    })
    .state('main.user.advancedSearch.delegation', {
      url: '/advanced-search/delegation',
      templateUrl: 'app/advancedSearch/delegation/delegationAdvancedSearch.html',
      controller: delegationAdvancedSearchCtrl,
      resolve: {
        $title: function() {
          return 'Advanced Search Delegation';
        }
      }
    })
    .state('main.user.advancedSearch.orders', {
      url: '/advanced-search/orders',
      templateUrl: 'app/advancedSearch/orders/ordersAdvancedSearch.html',
      controller: ordersAdvancedSearchCtrl,
      resolve: {
        $title: function() {
          return 'Advanced Search PO';
        }
      }
    })
    .state('main.user.advancedSearch.supplier', {
      url: '/advanced-search/supplier',
      templateUrl: 'app/advancedSearch/supplier/supplierAdvancedSearch.html',
      controller: supplierAdvancedSearchCtrl,
      resolve: {
        $title: function() {
          return 'Advanced Search Supplier';
        },
        prepareTranslation: function ($translatePartialLoader) {
          $translatePartialLoader.addPart('advancedSearch/supplier/lang');
        },
      }
    })
    .state('main.user.advancedSearch.manageCatalog', {
      url: '/advanced-search/items',
      templateUrl: 'app/advancedSearch/catalog/catalogAdvancedSearch.html',
      controller: catalogAdvancedSearchCtrl,
      resolve: {
        $title: function () {
          return 'Advanced Search Catalog';
        }
      }
    })
    .state('main.user.advancedSearch.managePrItem', {
      url: '/advanced-search/pr-items',
      templateUrl: 'app/advancedSearch/catalogCategories/prItemAdvancedSearch.html',
      controller: prItemAdvancedSearchCtrl,
      resolve: {
        $title: function () {
          return 'Advanced Search Item';
        },
        $purchaseRequisition: function (prFunction) {
          return prFunction.getPRData();
      },
        paymentTerms: function ($q, searchModule) {
          var deferred = $q.defer();
          //load the paymentTerms
          searchModule.get(
            {
              module: 'payment-term',
              'criteria[is_active]': 1,
              offset: 0
            },
            function (resource) {
              if (!!resource && !!resource.content) {
                deferred.resolve(resource.content);
              } else {
                deferred.resolve([]);
              }
            },
            function (error) {
              deferred.resolve([]);
            });
          return deferred.promise;
        },
        prConfig: function ($q, purchaseRequisitionsServices) {
          var deferred = $q.defer();
          purchaseRequisitionsServices.getPrConfig(
            {},
            function (resource) {
              if (!!resource && !!resource.content) {
                deferred.resolve(resource.content.data.config);
              } else {
                // default to unlimited
                deferred.resolve({pr_item_limit: -1});
              }
            },
            function (error) {
              // default to unlimited
              deferred.resolve({pr_item_limit: -1});
            }
          );

          return deferred.promise;
        }
      },
    })
    .state('main.user.advancedSearch.catalogV2', {
      url: '/advanced-search/catalogV2',
      templateUrl: 'app/advancedSearch/catalogV2/catalogV2AdvancedSearch.html',
      controller: catalogV2AdvancedSearchCtrl,
      resolve: {
        $title: function () {
          return 'Advanced Search Catalog';
        }
      }
    })
    .state('main.user.advancedSearch.manageCatalogCategories', {
      url: '/advanced-search/catalogCategories',
      templateUrl: 'app/advancedSearch/catalogCategories/catalogCategoriesAdvancedSearch.html',
      controller: catalogCategoriesAdvancedSearchCtrl,
      resolve: {
        $title: function () {
          return 'Advanced Search Catalog Categories';
        }
      }
    })
    .state('main.user.advancedSearch.budgetV2', {
      url: '/advanced-search/budgetV2',
      templateUrl: 'app/advancedSearch/budgetV2/budgetV2AdvancedSearch.html',
      controller: budgetV2AdvancedSearchCtrl,
      resolve: {
        $title: function () {
          return 'Advanced Search Budget';
        }
      }
    })
    .state('main.user.advancedSearch.tender', {
      url: '/advanced-search/tender',
      templateUrl: 'app/advancedSearch/tender/tenderAdvancedSearch.html',
      controller: tenderAdvancedSearchCtrl,
      resolve: {
        $title: function() {
          return 'Advanced Search Tender';
        }
      }
    });
}]);
