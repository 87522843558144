'use strict';

/**
 * @name exportTemplateController
 * @desc Controller for approval flow export
 */
function exportTemplateController (
  $scope, companies, $uibModalInstance, toastr, approvalTemplateExport, globalFunc, pathConstants, $http, $filter, getAlcGroup, catalogGroupList, $rootScope
) {

  $scope.isSourcingEnabled = !!$rootScope.isSourcingSupplierEnabled;

  $scope.companies = companies;
  $scope.checkTemplateType = checkTemplateType;
  $scope.template = {};
  $scope.template.type = [];
  $scope.template.group = [];
  $scope.groups = [];

  $scope.modulesWithCompany = ['PR', 'BUDGET', 'BUDGETCREATE', 'BUDGETREVISION','SUPPLIERCOMPANYCONTRACT', 'PURCHASEORDER'];
  $scope.modulesWithGroup = ['Catalog', 'APPROVALLIMITCONTROL'];

  $scope.isCatalogGroupRequired = isCatalogGroupRequired;

  function initializeTemplateTypes() {
    $scope.templateTypes = [
      {
        code: 'PR',
        descr: 'PR'
      },
      {
        code: 'Supplier',
        descr: 'Supplier'
      },
      {
        code: 'Catalog',
        descr: 'Catalog'
      },
      {
        code: 'BUDGET',
        descr: 'Budget'
      },
      {
        code: 'SUPPLIERCOMPANYCONTRACT',
        descr: 'Company Supplier'
      },
      {
        code: 'PURCHASEORDER',
        descr: 'Purchase Order'
      },
      {
        code: 'APPROVALLIMITCONTROL',
        descr: 'Approval Limit Control'
      },
      {
        code: 'DELEGATION',
        descr: 'Delegation'
      }
    ];
    if (!!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN') ||
      !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'ADMIN')) {
      $scope.templateTypes.push({
          code: 'BUDGETCREATE',
          descr: 'Budget Creation'
        },
        {
          code: 'BUDGETREVISION',
          descr: 'Budget Revision'
        });
    }
    if (!!$scope.isSourcingEnabled) {
      $scope.templateTypes.push({
        code: 'SOURCINGANDBIDDINGTEMPLATEPUBLISHING',
        descr: 'Sourcing and Bidding Template Publishing'
      },
      {
        code: 'SOURCINGANDBIDDINGEVENTPUBLISHING',
        descr: 'Sourcing and Bidding Event Publishing'
      },
      {
        code: 'SOURCINGANDBIDDINGEVENTAWARDING',
        descr: 'Sourcing and Bidding Event Awarding'
      });
    }

    if (!!$rootScope.isV2Enabled) {
      $scope.templateTypes.push({
        code: 'TENDER',
        descr: 'Tender'
      });
    }
  }

  $scope.close = close;
  $scope.exportTemplate = exportTemplate;

  function close() {
    $uibModalInstance.close();
  }

  function checkTemplateType() {
    switch($scope.template.type.code) {
      case 'Catalog':
        clearGroupCompany();
        loadCatalogGroup();
        break;
      case 'APPROVALLIMITCONTROL':
        clearGroupCompany();
        loadAlcGroup();
        break;
      default:
        clearGroupCompany();
    }
  }

  function loadCatalogGroup() {
    $scope.groups = [];
    catalogGroupList.get(
      {
        offset: 0
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.groups = resource.content.data;
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function loadAlcGroup() {
    $scope.groups = [];
    getAlcGroup.get(
      {
        offset: 0
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.groups = resource.content.data;
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function clearGroupCompany(){
    $scope.template.group = {code:'', descr: ''};
  }

  function exportTemplate() {
    if (exportValidation()) {
      var params = {
        module: $scope.template.type.code,
        only_active: (!!$scope.template.onlyActive) ? 1 : 0
      };

      if ($scope.modulesWithCompany.indexOf($scope.template.type.code) > -1) {
        params.company_code = $scope.template.company.code;
      }

      if ($scope.modulesWithGroup.indexOf($scope.template.type.code) > -1 && isCatalogGroupRequired()) {
        params.group_code = $scope.template.group.code;
      }

      $http({
        method: 'POST',
        url: pathConstants.apiUrls.approvalEngine.approvalTemplate.export,
        params: params
      }).then(function (response) {
        var blob = new Blob([response.data]);
        var link = document.createElement('a');
        document.body.appendChild(link);
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Template-' +
          $scope.template.type.code +
          (!!$scope.template.company && !!$scope.template.company.code ? '-' + $scope.template.company.code + '-' : '') +
          (!!$scope.template.group && !!$scope.template.group.code ? '-' + $scope.template.group.code + '-' : '') +
          $filter('date')(new Date(), 'dd-MMM-yyyy') +'.csv';
        link.click();
        toastr.success('Template file has been successfully generated.');
      }, function () {
        toastr.error('Failed to generate template file.');
      });
    }
  }

  function isCatalogGroupRequired() {
    return $rootScope.currentUser.tenant.config.approval_catalog_group_required === true;
  }

  function exportValidation() {
    if (!!$scope.template) {
      if (!$scope.template.type || !$scope.template.type.code) {
        toastr.error('The type field is required');
        return false;
      }

      if (($scope.modulesWithCompany.indexOf($scope.template.type.code) > -1) &&
        (!$scope.template.company || !$scope.template.company.code)) {
        toastr.error('The company field is required');
        return false;
      }

      if (($scope.modulesWithGroup.indexOf($scope.template.type.code) > -1) &&
        (!$scope.template.group || !$scope.template.group.code) &&
        isCatalogGroupRequired()
      ) {
        toastr.error('The group field is required');
        return false;
      }
    }

    return true;
  }
  initializeTemplateTypes();
}

exportTemplateController.$inject = [
  '$scope', 'companies', '$uibModalInstance', 'toastr', 'approvalTemplateExport', 'globalFunc', 'pathConstants',
  '$http', '$filter', 'getAlcGroup', 'catalogGroupList', '$rootScope'
];

angular
  .module('metabuyer')
  .controller('exportTemplateController', exportTemplateController);
