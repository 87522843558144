'use strict';

/**
 * @name addressesPanel
 *
 * @description
 * Function that returns the addressesPanel directive
 * Directive that can be embedded in modules for Selecting available address or adding new ones
 *
 *  @params
 *  selectedAddressId (mandatory):
 *  2-way-binded, to return the selected address or assign the new address
 *
 *  addressType (optional):
 *  optional, in case of need to add a billing or delivery type for the newly created addresses
 *
 *  cacheKey (mandatory):
 *  the list of addresses has to be cached before opening the directive to avoid multiple server calls to supplier loading the directive multiple times on a single panel
 *  the address list is loaded in a 2-way so that any addition is not removed from the session, and can be used in a different identical directive
 *
 * @authors Ahmed Saleh (a.s.alsalali@gmail.com)
 * @copyright Sunway Metacloud &copy; 2016
 */
function addressesPanel() {
  return {
    restrict: 'EA',
    scope: {
      selectedAddressId: '=',
      addressType: '@?',
      cacheKey: '@',
      companyCode: '=',
      cachedAddresses: '='
    },
    replace: true,
    templateUrl: 'components/addressesPanel/addressesPanelTemplate.html',
    controller: 'addressPanelCtrl'
  }
}

/**
 * @name addressPanelCtrl
 *
 * @description
 * Function that returns the addressPanelCtrl Controller
 * Controller for the  addressesPanel Directive
 *
 * @param $scope
 * @param metabuyerCache
 * @param globalFunc
 * @param addressList
 *
 *
 */
function addressPanelCtrl($scope, metabuyerCache, globalFunc, addressList) {

  /**
   * toggling which tab is active
   * @param tab
   * @returns {*}
   */
  function toggleTab(tab) {
    return $scope.tab = tab;
  }

  /**
   * validate the entries before submitting a new Address
   * @param address
   * @returns {boolean}
   */
  function validate(address) {

    if (!address)
      return false;

    if (!address.line1)
      return false;

    if (!address.postal_code)
      return false;

    if (!address.city)
      return false;

    if (!address.country || !address.country.descr)
      return false;

    if (!address.phone)
      return false;

    if (!address.state)
      return false;

    return true;

  }

  /**
   * assigning the newly selected address without binding
   * @param address
   */
  function selectAddress(address) {
    $scope.selectedAddress = angular.copy(address);
    $scope.selectedAddressId = address._id;
  }

  /**
   * adding the new address to the top of the list
   * @param address
   */
  function setAddressAsSelected(address){
    if ($scope.addressesList.length > 0)
      $scope.addressesList.unshift(address);
    else
      $scope.addressesList.push(address);
  }

  /**
   * adding new address to the server
   */
  function addAddress() {
    $scope.submittedAddress = true;

    if (validate($scope.newAddress)) {

      var data = angular.copy($scope.newAddress);
      data.country = data.country.descr;
      data.companyCode = $scope.companyCode;
      addressList.post(data,
        function (resource) {
          if (!!resource.content && !!resource.content.data) {
            $scope.newAddress.type = $scope.addressType;
            $scope.newAddress._id = resource.content.data._id;
            $scope.newAddress.reference = resource.content.data.reference;
            selectAddress($scope.newAddress);
            setAddressAsSelected($scope.newAddress);
            toggleTab(1);
            // clean up the form
            $scope.newAddress = {};
            $scope.submittedAddress = false;
            $scope.addressForm.$setPristine();
            $scope.addressForm.$setUntouched();
          } else {
            globalFunc.objectErrorMessage('Something went wrong.');
          }
        }, function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    } else {
      globalFunc.objectErrorMessage('Fill the required field.');
    }
  }

  /**
   * checking if the contry selected has list of states or not
   * @param country
   * @returns {boolean}
   */
  function onSelectedCountry(country) {
    $scope.newAddress.state = null;
    return country.descr === 'Malaysia' ? $scope.selectedMalaysia = true : $scope.selectedMalaysia = false;
  }

  function onSelectedState(state) {
    $scope.newAddress.state = state.descr;
  }

  $scope.newAddress         = {};
  $scope.tab                = 1;
  $scope.addressesList      = $scope.cachedAddresses ? $scope.cachedAddresses : metabuyerCache.get($scope.cacheKey) || [];
  $scope.countries          = globalFunc.countryCode();
  $scope.MYStates           = globalFunc.malaysiaState();

  $scope.toggleTab          = toggleTab;
  $scope.selectAddress      = selectAddress;
  $scope.addAddress         = addAddress;
  $scope.onSelectedCountry  = onSelectedCountry;
  $scope.onSelectedState    = onSelectedState;



}
addressPanelCtrl.$inject =['$scope', 'metabuyerCache', 'globalFunc', 'addressList'];

angular
  .module('metabuyer')
  .controller('addressPanelCtrl', addressPanelCtrl)
  .directive('addressesPanel', addressesPanel);

