'use strict';

angular.module('metabuyer')
  .controller('WithdrawCtrl', function (
    $scope, $uibModalInstance, purchaseRequisitionsServices, toastr, HighlightId, $state, globalFunc
  ) {

    $scope.commentText = '';

    $scope.cancelWithdraw = cancelWithdraw;
    $scope.withdraw = withdraw;

    //cancel action
    function cancelWithdraw(){
      $uibModalInstance.close();
    }

    //withdraw action
    function withdraw(){
      var module = !!$scope.module ? $scope.module : 'PR';
      var approvalId = (module === 'budget') ? $scope.data.approval_id : $scope.PR.approval_id;

      purchaseRequisitionsServices.approvalAction(
        {
          approval_id: approvalId,
          status: 'withdraw',
        },
        {
          comment: $scope.commentText, 
          additional_info: {
            is_pr: true
          }
        },
        function (resource) {
          $uibModalInstance.close();
          $scope.sending = false;

          if (module === 'budget') {
            $state.reload();
          } else {
            HighlightId.setId($scope.PR._id);
            if ($state.includes('main.user.purchaseRequisitionsV2.details')){
              $state.go('main.user.purchaseRequisitionsV2.manage', {status: 'withdrawn', tab: 'withdrawn'});
            }
            else{
              $state.go('main.user.purchaseRequisitions.manage', {status: 'withdrawn', tab: 'withdrawn'});
            }
          }

          toastr.success('The ' + module + ' has been withdrawn.');
          $scope.message_error = {};

        }, function (error) {
          $uibModalInstance.close();
          $scope.sending = false;
          globalFunc.objectErrorMessage(error);
        }
      );

      $scope.sending = true;
    }
  });
