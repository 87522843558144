'use strict';

angular.module('metabuyer')
  .directive('ngfileModel', ['$parse', function ($parse) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        var model = $parse(attrs.ngfileModel);
        var isMultiple = attrs.multiple;
        var modelSetter = model.assign;

        element.bind('change', function () {
          var values = [];
          angular.forEach(element[0].files, function (item) {
            var value = {
              // File Name
              name: item.name,
              //File Size
              size: item.size,
              // File Input Value
              _file: item
            };
            values.push(value);
          });

          scope.$apply(function () {
            var val = values;
            if (!isMultiple)
              val = values[0];

            modelSetter(scope, val);
          });
        });
      }
    };
  }
  ]);
