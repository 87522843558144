'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('main.user.kharon', {
        url: '/kharon',
        controller: 'kharonManagementCtrl',
        templateUrl: 'app/kharonManagement/kharonManagement.html',
        resolve: {
          accessRights: function (profile, $state, globalFunc) {
            if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN')) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.kharon.syncLogs', {
        templateUrl: 'app/kharonManagement/manage/manage.html',
        url: '/kharonLogs?&{query:[a-zA-Z0-9]+}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}{page:[0-9]+}',
        controller: 'kharonListCtrl',
        resolve: {
          dataList: function ($q, $stateParams, kharonCommandLogs, globalFunc, exportSearch) {
            var deferred = $q.defer();

            var params = {
              query: $stateParams.query,
              filter: $stateParams.filter,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            var exportParams = {
              'criteria[0][query]': params.query,
              'criteria[0][filter]': params.filter,
              'order_by': params.order,
              'order': params.order
            };

            exportSearch.setParams(exportParams);

            kharonCommandLogs.get(
              params,
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Kharon - Management';
          },
          type: function () {
            return 'Sync Logs';
          }
        }
      })
  }]);
