'use strict';

function companySelectionCtrl (
  $scope, $rootScope, $uibModalInstance
) {

  $scope.companies = $rootScope.currentUser.companies;
  $scope.setSelectedCompanyDetails = setSelectedCompanyDetails;
  $scope.selectedCompanyName = '';

  $scope.closeModal = closeModal;

  function closeModal(company) {
    $uibModalInstance.close(company);
  }

  function setSelectedCompanyDetails(company) {
    $scope.selectedCompanyName = company;
    closeModal($scope.selectedCompanyName);
  }

}

companySelectionCtrl.$injecct = [
  '$scope', '$rootScope', '$uibModalInstance'
];

angular
  .module('metabuyer')
  .controller('companySelectionCtrl',companySelectionCtrl);
