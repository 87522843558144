/**
 * @name supplierGroup
 *
 * @description
 * Directive for supplier groups used in add and edit supplier
 *
 * @param hideReason (boolean)
 * to show/hide the reason
 *
 * @param editable (boolean)
 * editable status
 *
 * @param data (array)
 * supplier group status data. two ways binding
 *
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

angular.module('metabuyer')
  .directive('supplierGroup', function (searchModule, globalFunc, $q) {
    return {
      restrict: 'E',
      replace: 'true',
      templateUrl: 'components/supplierGroups/supplierGroups.html',
      scope: {
        hideReason: '=',
        editable: '=',
        data: '=',
        isGlobal: '='
      },
      link: function ($scope) {

        $scope.query                    = '';
        $scope.groups                   = [];
        $scope.selectedGroupForListing  = [];
        $scope.multiselectTranslation   = {
          selectAll       : 'Select all',
          selectNone      : 'Select none',
          reset           : 'Reset',
          search          : 'Type here to search...',
          nothingSelected : 'Select'
        };
        $scope.groupStatuses = [
          {
            status: 1,
            descr: 'active'
          },
          {
            status: 2,
            descr: 'black_listed'
          },
          {
            status: 6,
            descr: 'inactive'
          }
        ];

        $scope.updateQuery          = updateQuery;
        $scope.removeGroup          = removeGroup;
        $scope.onSelectGroup        = onSelectGroup;
        $scope.search               = search;
        $scope.onSelectAll          = onSelectAll;
        $scope.onSelectNone         = onSelectNone;

        function onSelectNone() {
          $scope.selectedGroupForListing = [];
          setDataValue();
        }

        function checkIsGlobalSupplier() {
          var checkStatus = true;

          _.forEach($scope.selectedGroupForListing, function(groupListing) {
            if (Number(groupListing.supplierGroupStatus) !== 1)
              checkStatus = false;
          });

          $scope.isGlobal = checkStatus;
          if (!!$scope.selectedGroupForListing.length)
            $scope.isGlobal = checkStatus && ($scope.groups.length === $scope.selectedGroupForListing.length);
        }

        function onSelectAll() {
          _.forEach($scope.groups, function (group) {
            group.supplierGroupStatus = 1;
            var checkCurrentStatus = globalFunc.findInArray($scope.selectedGroupForListing, '_id', group._id);
            if (!!checkCurrentStatus && !!checkCurrentStatus.supplierGroupStatus)
              group.supplierGroupStatus = Number(checkCurrentStatus.supplierGroupStatus);
          });
          $scope.selectedGroupForListing = angular.copy($scope.groups);
          setDataValue();
        }

        function search(data) {
          if (!data)
            return;
          return (angular.lowercase(data.code).indexOf(angular.lowercase($scope.query)) > -1 ||
            angular.lowercase(data.descr).indexOf(angular.lowercase($scope.query)) > -1);
        }

        /**
         * run when selecting company
         * @param group
         */
        function onSelectGroup(group) {
          if (!!group.ticked) {
            group.supplierGroupStatus = 1;
            $scope.selectedGroupForListing.push(group);
            setDataValue();
          } else if (!group.ticked) {
            removeGroup(group._id);
          }
        }

        /**
         * Load Supplier Group Data
         */
        function loadBUGroup() {
          var params = {
            module: 'business-units',
            offset: 0
          };

          searchModule.get(
            params,
            function(resource) {
              if (!!resource && !!resource.content) {
                $scope.groups = resource.content.data;
                initialSupplierGroup();
                checkIsGlobalSupplier();
              }
            },
            function() {
              $scope.companies = [];
            }
          );
        }

        /**
         * remove company manually from the list
         * @param groupId
         */
        function removeGroup(groupId) {
          _.forEach($scope.groups, function(group) {
            if (group._id === groupId) {
              group.ticked = false;

              // remove from selected Group in listing
              $scope.selectedGroupForListing = globalFunc.removeValueFromArray(
                $scope.selectedGroupForListing, '_id', groupId
              );
            }
          });
          setDataValue();
        }

        /**
         * To update the query value manually
         * @param query
         */
        function updateQuery(query) {
          $scope.query = query;
        }

        function initialSupplierGroup() {
          if ((!!$scope.data && $scope.data.constructor === Array && $scope.data.length === 0) ||
              !$scope.data ||
              $scope.data.constructor === Object)
            $scope.selectedGroupForListing = convertDataToArray($scope.data);
          else
            $scope.selectedGroupForListing = $scope.data;
          setDataValue();
        }

        /**
         * Convert object to array for supplier group
         * @param supplierGroup
         * @returns {Array}
         */
        function convertDataToArray(supplierGroup) {
          var i;
          var group = [];
          for (i in supplierGroup) {
            if (supplierGroup.hasOwnProperty(i)) {
              var buGroup = globalFunc.findInArray($scope.groups, 'code', i);
              if (!!buGroup) {
                buGroup.supplierGroupStatus = Number(supplierGroup[i].status);
                if (!!supplierGroup[i].reason)
                  buGroup.reason = supplierGroup[i].reason;
                group.push(buGroup);
                // set ticked value for multiselect
                $scope.groups = globalFunc.modifyArrayValue($scope.groups, 'code', i, 'ticked', true);
              }
            }
          }
          return group;
        }

        function setDataValue() {
          $scope.data = $scope.selectedGroupForListing;
        }

        function initialize() {
          loadBUGroup();
        }

        initialize();

        $scope.$watch('data', function (newVal, oldVal) {
          if (newVal != oldVal)
            checkIsGlobalSupplier();
        }, true);
      }
    }
  });
