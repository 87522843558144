'use strict';
/**
 * Controller for listing supplier groups
 * @param $scope
 * @param $stateParams
 * @param dataList
 * @param listingBusinessUnits
 */
function supplierGroupsCtrl($scope, $stateParams, dataList, listingBusinessUnits) {

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function initialize(){
    $scope.status = $stateParams.status;
    $scope.title = $stateParams.title;
    $scope.dataList = dataList.data;
    $scope.meta = dataList.meta;
    $scope.listingBUService = listingBusinessUnits;
    $scope.showColumn = showColumn;

    $scope.storageKey = 'supplier-groups-listing-selected-columns';
  }

  initialize();

}

supplierGroupsCtrl.$inject = ['$scope','$stateParams', 'dataList', 'listingBusinessUnits'];

angular.module('metabuyer')
  .controller('supplierGroupsCtrl', supplierGroupsCtrl);
