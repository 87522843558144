'use strict';

function tenderUtilizationCtrl(
  $scope, globalFunc, $rootScope, $state, HighlightId, pathConstants, $filter, tenderFunction, tenderService, toastr, $http
) {

  $scope.option = {
    animate: { enabled: false },
    readOnly: true,
    size: 150,
    unit: '%',
    skin: {
      type: 'tron',
      width: 5,
      color: '#1ab394',
      spaceWidth: 3
    },
    barColor: '#1ab394',
    trackWidth: 20,
    barWidth: 20,
    step: 0.01,
    textColor: '',
    dynamicOptions: true
  };

  $scope.consumptionData = [];

  $scope.optionForAvailable = _.cloneDeep($scope.option);
  $scope.optionForCommited = _.cloneDeep($scope.option);
  $scope.optionForUsed = _.cloneDeep($scope.option);

  function initialize() {

    $scope.showTenderDashboard = false;

    if (!!$scope.tenderId && !!$scope.currencyCode) {
      getTenderConsumptionData();
    }

  }

  function getTenderConsumptionData() {

    var prId = ($scope.prId === undefined) ? undefined : $scope.prId;

    tenderService.consumption({
      id: $scope.tenderId,
      curr: $scope.currencyCode,
      prId: prId
    }).$promise.then(
      function(response) {
        $scope.showTenderDashboard = true;
        $scope.consumptionData = response.content.data.dashboard;

        if ($scope.consumptionData.percentage.available_amount < 0) {
          $scope.optionForAvailable.barColor = '#FF0000';
        } else {
          $scope.optionForAvailable.barColor = $scope.option.barColor;
        }

        if ($scope.consumptionData.percentage.used_amount > 100) {
          $scope.optionForUsed.barColor = '#FF0000';
        } else {
          $scope.optionForUsed.barColor = $scope.option.barColor;
        }

        if ($scope.consumptionData.percentage.committed_amount > 100) {
          $scope.optionForCommited.barColor = '#FF0000';
        } else {
          $scope.optionForCommited.barColor = $scope.option.barColor;
        }

      },
      function(error) {
        if (!!error.data && !!error.data.content && !!error.data.content.error) {
          $scope.consumptionData = [];
          $scope.showTenderDashboard = false;
        }
      }
    );
  }

  initialize();

  $scope.$on('updateTenderUtilizationDashboard', function(event, result) {
    
    $scope.tenderId = result.tenderId;
    $scope.currencyCode = result.currencyCode;

    initialize();
  });
}

tenderUtilizationCtrl.$inject = [
  '$scope', 'globalFunc', '$rootScope', '$state', 'HighlightId', 'pathConstants', '$filter', 'tenderFunction', 'tenderService', 'toastr', '$http'
];

angular
  .module('metabuyer')
  .controller('tenderUtilizationCtrl', tenderUtilizationCtrl);
