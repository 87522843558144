'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function ($stateProvider, pathConstants) {

    $stateProvider
      .state('main.user.orders', {
        url: '/orders',
        controller: 'OrdersCtrl',
        templateUrl: 'app/orders/orders.html',
        template: '<div ng-include="ordersTemplate"></div>',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'PO')) {
              $state.go('notAuthorized');
            }
          },
          findTemplate: function ($rootScope) {
            $rootScope.ordersTemplate = $rootScope.isMobileMode ?
              'app/orders/orders-mobile.html' :
              'app/orders/orders.html';
          },
          isDeveloper: function (profile, UserPermissions) {
            if (!!UserPermissions.isDeveloper(profile))
              return true;
            else
              return false;
          }
        }
      })
      .state('main.user.orders.manage', {
        templateUrl: 'app/orders/manage/manage.html',
        url: '/manage?{query:[a-zA-Z0-9]+}&{status:[a-zA-Z0-9]+}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}&{tab:[a-zA-Z0-9]+}&{extraFilterKey:[a-zA-Z0-9]+}&' +
        '{extraFilterValue:[a-zA-Z0-9]+}',
        controller: 'OrdersManageCtrl',
        template: '<div ng-include="OrdersManageTemplate"></div>',
        resolve: {
          orders: function ($rootScope, $q, $state, $stateParams, Orders, searchModule, profile, globalFunc, exportSearch, UserPermissions) {
            var deferred = $q.defer();
            var params = {};

            var status = globalFunc.availableStatuses('po', $stateParams.status);

            /**
             * First determine if search or listing
             */
            if (!!$stateParams.query) {
              /**
               * If searching , check if searching with filter
               */
              if (!!$stateParams.filter) {
                params = {
                  module: 'PO',
                  offset: 50,
                  criteria_operator: 'and',
                  page: $stateParams.page,
                  order_by: $stateParams.order_by,
                  order: $stateParams.order
                };

                var temp = '';
                if ($stateParams.type === 'date') {
                  temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                }
                else if ($stateParams.type === 'double') {
                  temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                }
                else {
                  params['criteria[1][' + $stateParams.filter + ']'] = $stateParams.query;
                  if ($stateParams.filter === 'po_number') {
                    params["criteria[1][po_custom_number]"] = $stateParams.query;
                    params["criteria[1][$operator]"] = 'or';
                  }
                }
              }
              /**
               * Else for listing and sorting without filter
               * Pass criteria[status] in an array with or condition based on status
               * Submitted tab will list submitted, delivery_date_unconfirmed and awaiting_confirmation status
               * Awaiting delivery tab will list awaiting_delivery, partially_delivered and in_port status
               * Closed tab will list closed and delivered status
               * Declined tab will list declined status
               * Cancelled tab will list cancelled status
               * Starred tab will list PO if stared_by current user
               */
              else {
                params = {
                  module: 'PO',
                  offset: 50,
                  'criteria[1][po_number]': $stateParams.query,
                  'criteria[1][po_custom_number]': $stateParams.query,
                  'criteria[1][pr_number]': $stateParams.query,
                  'criteria[1][title]': $stateParams.query,
                  'criteria[1][supplier_object|descr]': $stateParams.query,
                  'criteria[1][billing|company|descr]': $stateParams.query,
                  'criteria[1][billing|shadow_company|descr]': $stateParams.query,
                  'criteria[1][creator_info|display_name]': $stateParams.query,
                  'criteria[1][requestor|display_name]': $stateParams.query,
                  'criteria[1][do_number]': $stateParams.query,
                  criteria_operator: !!$stateParams.query ? 'and' : null,
                  'criteria[1][$operator]': 'or',
                  page: $stateParams.page,
                  order_by: $stateParams.order_by,
                  order: $stateParams.order
                };
              }
            }
            /**
             * Else for listing
             */
            else {
              params = {
                module: 'PO',
                offset: 50,
                page: $stateParams.page,
                order_by: $stateParams.order_by,
                order: $stateParams.order,
                'criteria[1][creator_info|_id][]': ($stateParams.extraFilterKey === 'criteria[creator_info|_id][][]') ?
                  $stateParams.extraFilterValue : null,
                'criteria[1][collaborators.collaborator_id]': ($stateParams.extraFilterKey === 'criteria[collaborators_ids][]' && !!$stateParams.extraFilterValue) ?
                  true : null,
                'criteria[1][waiting_on][]': ($stateParams.extraFilterKey === 'criteria[waiting_on][]') ?
                  $stateParams.extraFilterValue : null,
                'criteria_operator': 'and'
              }
            }

            if (!!status) {
              /**
               * Pass param into status based on tab status
               */
              switch (status) {
                case 'ALL':
                  params['criteria[0][$operator]'] = 'or';
                  break;
                case 'SUBMITTED':
                  params['criteria[0][status][0]'] = 'SUBMITTED';
                  params['criteria[0][status][1]'] = 'DELIVERY_DATE_UNCONFIRMED';
                  params['criteria[0][$operator]'] = 'or';
                  break;
                case 'AWAITING_DELIVERY':
                  params['criteria[0][status][0]'] = 'AWAITING_DELIVERY';
                  params['criteria[0][status][1]'] = 'PARTIALLY_DELIVERED';
                  params['criteria[0][$operator]'] = 'or';
                  break;
                case 'APPROVED':
                  if ($rootScope.isV2Enabled){
                    params['criteria[0][status][0]'] = 'APPROVED';
                    params['criteria[0][status][1]'] = 'SUBMITTED';
                    params['criteria[0][status][2]'] = 'PARTIALLY_DELIVERED';
                    params['criteria[0][$operator]'] = 'or';
                  }
                  else{
                    params['criteria[0][$operator]'] = 'or';
                  }
                  break;
                case 'PENDING':
                  if ($rootScope.isV2Enabled){
                    params['criteria[0][status][0]'] = 'PENDING';
                  }
                  else{
                    params['criteria[0][$operator]'] = 'or';
                  }
                  break;
                case 'CLOSED':
                  params['criteria[0][status][0]'] = 'CLOSED';
                  params['criteria[0][status][1]'] = 'FULLY_DELIVERED';
                  params['criteria[0][$operator]'] = 'or';
                  break;
                case 'DECLINED':
                  params['criteria[0][status][0]'] = 'DECLINED';
                  break;
                case 'CANCELLED':
                  params['criteria[0][status][0]'] = 'CANCELLED';
                  break;
                case 'STARRED':
                  params['criteria[0][tab]'] = 'starred';
                  break;
                case 'EXPIRED':
                  params['criteria[0][status][0]'] = 'EXPIRED';
                  break;
                case 'REJECTED':
                  params['criteria[0][status][0]'] = 'REJECTED';
                  break;
                case 'WITHDRAWN':
                  params['criteria[0][status][0]'] = 'WITHDRAWN';
                  break;
              }
            }

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function () {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "PO - listing" + (!!$stateParams.status ? " - " +
                $stateParams.status.toLowerCase() : "");
          },
          findTemplate: function ($rootScope) {
            $rootScope.OrdersManageTemplate = $rootScope.isMobileMode ?
              'app/orders/manage/manage-mobile.html' :
              'app/orders/manage/manage.html';
          }
        }
      })
      .state('main.user.orders.grnManage', {
        templateUrl: 'app/orders/grnListing/manage.html',
        url: '/grnManage?{query:[a-zA-Z0-9]+}&{status:[a-zA-Z0-9]+}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'grnManageCtrl',
        resolve: {
          grns: function ($rootScope, $q, $state, $stateParams, searchModule) {
            var deferred = $q.defer();
            $stateParams.status = 'grn';

            var params = {
              module: 'GRN',
              offset: 50,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            if (!!$stateParams.query) {
              if (!!$stateParams.filter)
                params['criteria[0][' + $stateParams.filter + ']'] = $stateParams.query;
              else {
                params = {
                  module: 'GRN',
                  offset: 50,
                  'criteria[0][grn_number]': $stateParams.query,
                  'criteria[0][po_number]': $stateParams.query,
                  'criteria[0][po_custom_number]': $stateParams.query,
                  'criteria[0][billing.company.descr]': $stateParams.query,
                  'criteria[1][billing.shadow_company.descr]': $stateParams.query,
                  'criteria[0][supplier_object.descr]': $stateParams.query,
                  'criteria[0][purchase_order.requisition.reference]': $stateParams.query,
                  'criteria[0][$operator]': 'or',
                  page: $stateParams.page,
                  order_by: $stateParams.order_by,
                  order: $stateParams.order
                }
              }
            }
            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function () {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'GRN - listing';
          }
        }
      })
      .state('main.user.orders.detail', {
        templateUrl: 'app/orders/detail/detail.html',
        url: '/{id:[a-zA-Z0-9]+}?{title:[a-zA-Z0-9]*}&{action:[a-zA-Z0-9]*}',
        controller: 'OrdersDetailCtrl',
        template: '<div ng-include="OrdersDetailTemplate"></div>',
        resolve: {
          order: function ($rootScope, $q, $state, toastr, $stateParams, $filter, Orders,
                           authenticated, poFunction) {
            var deferred = $q.defer();

            if (!authenticated) {
              deferred.resolve({});
              return deferred.promise;
            }

            var method = Orders.get;
            method(
              {id: $stateParams.id},
              function (resource) {
                if(!!resource && !!resource.content && !!resource.content.data){
                  poFunction.initializePOData(resource.content.data);
                  deferred.resolve(resource.content.data);
                } else {
                  deferred.resolve({});
                }
              },
              function (error) {
                toastr.error("PO not found");
                if(!!$rootScope.previousState && !!$rootScope.previousState.name){
                  $state.go($rootScope.previousState.name, $rootScope.previousState.params);
                } else {
                  $state.go('main.user.orders.manage', {status: $stateParams.status || "SUBMITTED"});
                }
              }
            );
            return deferred.promise;
          },
          findTemplate: function ($rootScope, order) {
            if (!$rootScope.isMobileMode) {
              if (!!order.document_rules && !!order.document_rules.pr_version && order.document_rules.pr_version === 'v2')
                $rootScope.OrdersDetailTemplate = 'app/orders/detail/detailV2.html';
              else
                $rootScope.OrdersDetailTemplate = 'app/orders/detail/detail.html';
            }
            else if (!!$rootScope.isMobileMode) {
              $rootScope.OrdersDetailTemplate = 'app/orders/detail/detail-mobile.html';
            }
          },
          POSubscriptionCheck: function($q, order, subscriptionCheck){
            //Removing the unused feature to be fixed from backend (Ahmed Saleh, 19/07/2018)
            return true;
          },
          isIntegratedCompany: function (order) {
            return order.is_integrated_company;
          },
          integrationSolution: function (order) {
            return order.integration_solution;
          },
          companyMappingStatus: function ($q, order, $http, metabuyerCache, pathConstants, isIntegratedCompany) {
            var deferred = $q.defer();

            if (!isIntegratedCompany) {
              deferred.resolve(false);
              return deferred.promise;
            }

            var companyMap = metabuyerCache.get('Integration - ' + order.billing.company.code);

            if (!!companyMap) {
              deferred.resolve(companyMap.isMapped);
            } else {

              $http.get(pathConstants.apiUrls.bifrost.companyEnvironment, {
                params: {
                  'module': 'company-environment',
                  'company-code': order.billing.company.code
                },
                ignoreLoadingBar: true
              }).then(function (resource) {
                if (!!resource.data) {
                  if (resource.data.length > 0) {
                    metabuyerCache.put('Integration - ' + order.billing.company.code, {isMapped: true});
                    deferred.resolve(true);
                  }
                  else {
                    metabuyerCache.put('Integration - ' + order.billing.company.code, {isMapped: false});
                    deferred.resolve(false);
                  }
                }
              }, function (err) {
                metabuyerCache.put('Integration - ' + order.billing.company.code, {isMapped: false});
                deferred.resolve(false);
              });
            }
            return deferred.promise;
          },
          $title: function($stateParams){
            return "PO - detail" + (!!$stateParams.id? " - " + $stateParams.id : "");
          }
        }
      });
  }]);
