'use strict';

angular.module('metabuyer.services.industries', ['ngResource'])
  .factory('listingIndustries',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.industry.list,
      {
      },
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('newIndustry',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.industry.new,
      {
      },
      {
        'post': { method: 'POST' }
      }
    );
  })
  .factory('singleIndustry',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.industry.single,
      {
        id: '@id'
      },
      {
        'get': { method: 'GET' },
        'put': { method: 'PUT' },
        'delete': { method: 'DELETE' }
      }
    );
  })
  .factory('getIndustriesCompanies',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.industry.companies,
        {
          code: '@code'
        },
        {
          'get': { method: 'GET' }
        }
      );
    });

