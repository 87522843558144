'use strict';

angular.module('metabuyer.services.attachments', ['ngResource'])
  .factory('Attachments', function($resource, pathConstants) {
    var Attachments = $resource(
      pathConstants.apiUrls.attachments.listPRAttachment, {
        pr_id: '@pr_id'
      }, {
        'get': {
          method: 'GET'
        }, //get attachment list
        'submit': {
          method: 'POST'
        },
        'getListPRAttachment': {
          method: 'GET',
          params: {
            pr_id: '@pr_id'
          },
          ignoreLoadingBar: true,
          url: pathConstants.apiUrls.attachments.listPRAttachment
        },
        'getListPOAttachment': {
          method: 'GET',
          params: {
            po_id: '@po_id'
          },
          ignoreLoadingBar: true,
          url: pathConstants.apiUrls.po.listAttachment
        }
      }
    );
    return Attachments;
  })
  .factory('attachmentsByReference',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.attachments.reference, {
          referenceId: '@referenceId'
        }, {
          'get': { method: 'GET', ignoreLoadingBar: true },
          'post': { method: 'POST' }
        }
      );
    })
  .factory('singleAttachment',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.attachments.single, {
          attachmentId: '@attachmentId'
        }, {
          'get': { method: 'GET' },
          'put': { method: 'PUT' },
          'delete': { method: 'DELETE' }
        }
      );
    })
  // TODO: Refactor PO side for attachments (Justin 7/9/17)
  .factory('AttachmentSingle', function($resource, pathConstants) {
    var AttachmentSingle = $resource(
      pathConstants.apiUrls.attachments.single, {
        pr_id: '@pr_id',
        attachment_id: '@attachment_id'
      }, {
        'get': {
          method: 'GET'
        }, //get single attachment detail
        'update': {
          method: 'PUT'
        },
        'getSinglePOAttachment': {
          method: 'GET',
          params: {
            po_id: '@po_id',
            attachment_id: '@attachment_id'
          },
          url: pathConstants.apiUrls.po.singleAttachment
        },
        'updateSinglePOAttachment': {
          method: 'PUT',
          params: {
            po_id: '@po_id',
            attachment_id: '@attachment_id'
          },
          url: pathConstants.apiUrls.po.singleAttachment
        },
        'deleteSinglePOAttachment': {
          method: 'DELETE',
          params: {
            po_id: '@po_id',
            attachment_id: '@attachment_id'
          },
          url: pathConstants.apiUrls.po.singleAttachment
        }
      }
    );
    return AttachmentSingle;
  })
  .factory('PRNewAttachments', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.attachments.listPRNewAttachment, {
        pr_id: '@pr_id'
      }, {
        'getListPRAttachment': {
          method: 'GET'
        }, //get attachment list
        'post': {
          method: 'POST'
        },
        'get': {
          method: 'GET',
          params: {
            pr_id: '@pr_id',
            attachment_id: '@attachment_id'
          },
          url: pathConstants.apiUrls.attachments.singlePRNewAttachment
        },
        'delete': {
          method: 'DELETE',
          params: {
            pr_id: '@pr_id',
            attachment_id: '@attachment_id'
          },
          url: pathConstants.apiUrls.attachments.singlePRNewAttachment
        },
        'put': {
          method: 'PUT',
          params: {
            pr_id: '@pr_id',
            attachment_id: '@attachment_id'
          },
          url: pathConstants.apiUrls.attachments.singlePRNewAttachment
        }
      }
    );
  })
  .factory('PRV2Attachments', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.purchaseRequisitionsV2.attachment.list, {
        requisition_id: '@requisition_id'
      }, {
        'getListPRAttachment': {
          method: 'GET'
        }, //get attachment list
        'post': {
          method: 'POST'
        },
        'get': {
          method: 'GET',
          params: {
            pr_id: '@pr_id',
            attachment_id: '@attachment_id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.attachment.single
        },
        'delete': {
          method: 'DELETE',
          params: {
            pr_id: '@pr_id',
            attachment_id: '@attachment_id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.attachment.single
        },
        'put': {
          method: 'PUT',
          params: {
            pr_id: '@pr_id',
            attachment_id: '@attachment_id'
          },
          url: pathConstants.apiUrls.purchaseRequisitionsV2.attachment.single
        }
      }
    );
  })
  .factory('PCAttachments', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.priceComparison.getAttachmentList, {
        id: '@id',
        itemIndex: '@itemIndex',
        editable: '@editable'
      }, {
        'getLists': {
          method: 'GET'
        }, //get attachment list
        'post': {
          method: 'POST'
        },
        'get': {
          method: 'GET',
          params: {
            id: '@id',
            attachmentId: '@attachmentId'
          },
          url: pathConstants.apiUrls.priceComparison.getAttachment
        },
        'delete': {
          method: 'DELETE',
          params: {
            id: '@id',
            attachmentId: '@attachmentId',
            itemIndex: '@itemIndex',
            editable: '@editable'
          },
          url: pathConstants.apiUrls.priceComparison.deleteAttachment
        },
        'put': {
          method: 'PUT',
          params: {
            id: '@id',
            attachmentId: '@attachmentId'
          },
          url: pathConstants.apiUrls.priceComparison.updateAttachment
        }
      }
    );
  });
