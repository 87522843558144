'use strict';

function catalogItemDetailsCtrl (
  $scope, item, isCatalogCartMode, $uibModalInstance, $rootScope, toastr
) {

  $scope.mainItem = item;
  $scope.itemDetails = item.details;
  $scope.itemCommodity = !!$scope.itemDetails[0] ? $scope.itemDetails[0].commodity : null;
  $scope.isCatalogCartMode = isCatalogCartMode;
  $scope.close = close;
  $scope.addItemToCart = addItemToCart;

  function addItemToCart(item, detailId) {
    var selectedDetail = item.details.filter(function(itemDetail) {
      return itemDetail._id === detailId;
    });

    var itemWithSelectedDetail = _.cloneDeep(item);
    itemWithSelectedDetail.details = selectedDetail;

    $rootScope.$broadcast('addItemFromPopUpModal', itemWithSelectedDetail);
  }

  function close () {
    $uibModalInstance.close();
  }

  function initialize() {
    $scope.lastCommodity = $scope.itemCommodity;
  }

  initialize();
}

catalogItemDetailsCtrl.$injecct = [
  '$scope', 'item', 'isCatalogCartMode', '$uibModalInstance', '$rootScope', 'toastr'
];

angular
  .module('metabuyer')
  .controller('catalogItemDetailsCtrl',catalogItemDetailsCtrl);
