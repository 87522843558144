'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('main.user.catalogItemList',{
        templateUrl: 'app/catalogView/manage/catalogCategories.html',
        url: '/categories',
        controller: 'catalogCategoriesCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'ImportItemDetail')) {
              $state.go('notAuthorized');
            }
            if (!UserPermissions.checkPermissionsAccess(profile, 'ImportItemSummary')) {
              $state.go('notAuthorized');
            }
          },
          paymentTerms: function ($q, searchModule) {
            var deferred = $q.defer();
            //load the paymentTerms
            searchModule.get(
              {
                module: 'payment-term',
                'criteria[is_active]': 1,
                offset: 0
              },
              function (resource) {
                if (!!resource && !!resource.content) {
                  deferred.resolve(resource.content);
                } else {
                  deferred.resolve([]);
                }
              },
              function (error) {
                deferred.resolve([]);
              });
            return deferred.promise;
          },
          prConfig: function ($q, purchaseRequisitionsServices) {
            var deferred = $q.defer();
            purchaseRequisitionsServices.getPrConfig(
              {},
              function (resource) {
                if (!!resource && !!resource.content) {
                  deferred.resolve(resource.content.data.config);
                } else {
                  // default to unlimited
                  deferred.resolve({pr_item_limit: -1});
                }
              },
              function (error) {
                // default to unlimited
                deferred.resolve({pr_item_limit: -1});
              }
            );

            return deferred.promise;
          },
          $title: function () {
            return 'Catalog'
          }
        }
      })
  }]);
