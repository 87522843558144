'use strict';

function bifrostStoragePathCtrl($scope, dataList, $http, pathConstants, toastr, $uibModal) {

  $scope.dataList = dataList.data;
  $scope.editStoragePath = editStoragePath;

  // edit single storage path
  function editStoragePath(data) {
    $uibModal.open({
      templateUrl: 'app/settings/bifrostSettings/storagePaths/editStoragePathModal.html',
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      resolve:{
        data: function(){
          return data;
        }
      },
      controller: function ($scope, $uibModalInstance, $http, pathConstants, toastr, $state) {
        if (!!data) {
          $scope.id = data.id;
          $scope.module = data.module;
          $scope.erp_name = data.erp_name;
          $scope.old_path = data.path;
          $scope.new_path = '';
        }

        $scope.submit = submit;
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        function submit(){
          $scope.submitted = true;

          if (!$scope.new_path) {
            toastr.error('Please fill up the new storage path.');
            return;
          }

          swal({
            title: 'Confirm update Storage Path?',
            text: 'This action is not revertable.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Confirm',
            closeOnConfirm: true
          }, function (isConfirm) {
            if (isConfirm) {
              confirmSubmit();
            }
          });
        }

        function confirmSubmit() {
          var path = pathConstants.apiUrls.bifrost.storagePath;

          $http.put(
            path,
            {
              id: $scope.id,
              old_path: $scope.old_path,
              new_path: $scope.new_path
            }
          ).then(function onSuccess(response){
            toastr.success("Updated Storage Path.");
            $uibModalInstance.close();
            $state.reload();
            close();
          }).catch(function onError (response){
            var error = "Failed to update Storage Path.";
            if (!!response.data) {
              error = response.data;
            }
            toastr.error(error);
          });
        }
      }
    });
  }
}

bifrostStoragePathCtrl.$inject = ['$scope', 'dataList', '$http', 'pathConstants', 'toastr', '$uibModal'];

angular
  .module('metabuyer')
  .controller('bifrostStoragePathCtrl', bifrostStoragePathCtrl);
