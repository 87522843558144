'use strict';
/**
 * @name viewHistoryController
 * @desc Controller to see history for a company
 */
function viewHistoryController($scope, company, histories, $uibModalInstance, commentsHistory, globalFunc) {
  $scope.cancel = cancel;
  $scope.histories = histories.data;
  $scope.histories = onPaginateFunction(histories.data);
  $scope.meta = histories.meta;
  $scope.commentsHistory = commentsHistory;
  $scope.prepareLogFields = prepareLogFields;
  $scope.onPaginateFunction = onPaginateFunction;
  $scope.embeddedParams = {
    referenceId: company._id,
    offset: 5,
    order_by: '-created_at' // Created at, descending
  };

  function cancel() {
    $uibModalInstance.close();
  }

  var arrayMapper = {
      'Tolerance Limit Item' : 'item_master_code'
  };

  function mapper(key) {
     if (!Object.hasOwnProperty(key)) {
         return 'code'; //default key for comparison
     }

     return arrayMapper[key];
  }

    /**
     * array to array changes analysis
     * @param key
     * @param value
     * @returns {{}}
     * @deprecated
     */
  function arrayParser(key, value) {
      var item = {};
      var field = mapper(key);

      if (!!value.old && !!value.new) {
          item.key = key;
          item.from = [];
          item.to = [];

          _.forEach(value.new, function (newValueArray) {
              if(!globalFunc.findInArray(value.old, field, newValueArray[field])){
                  item.to.push({
                      key: field,
                      value: newValueArray[field]
                  })
              }
          });

      } else {

          // if (!value.old) {
          //     item.from = null;
          // } else {
          //     item.from = value.old;
          //
          // }
          //
          // if (!value.new) {
          //     item.from = null;
          // } else {
          //     item.from = value.new;
          //
          // }
      }

      return item;

  }

  function onPaginateFunction(items) {

      _.forEach(items, function (item) {
          item.updatedFields = prepareLogFields(item.related);
      });
      return items;
  }

  function prepareLogFields(related) {
      var fields = [];
      var item = {};
      Object.keys(related).forEach(function (k) {
          var v = related[k];

          item = {
              key: k,
              from: v.old,
              to: v.new
          };

          fields.push(item);
      });
      return fields;
  }
}

viewHistoryController.$inject = ['$scope', 'company', 'histories', '$uibModalInstance', 'commentsHistory', 'globalFunc'];

angular
  .module('metabuyer')
  .controller('viewHistoryController', viewHistoryController);
