'use strict';

/**
 * Controller for budget details
 * @description
 * Contains functions for handling budget information and editing
 *
 * @param $scope
 * @param toastr
 * @param companyExpenseTypes
 * @param userBudget
 * @param globalFunc
 * @param budgetData
 * @param singleCommentsHistory
 * @param budgetCommentList
 * @param attachmentsByReference
 * @param singleAttachment
 * @param $rootScope
 * @param $filter
 * @param pathConstants
 * @param $stateParams
 * @param budgetFunctions
 * @param Approvals
 * @param companyCurrencies
 *
 * @author H.W. Liew <liewhanwahwork@gmail.com>
 * @author Justin Cheong <justin.cty90@gmail.com>
 * @author Khor Jin Ee <jinee585@gmail.com>
 * @copyright 2017 Metacloud Sdn. Bhd.
 */
function budgetDetailsCtrl(
  $scope, toastr, companyExpenseTypes, userBudget, globalFunc, budgetData, singleCommentsHistory,
  budgetCommentList, attachmentsByReference, singleAttachment, $rootScope, $filter, pathConstants, $stateParams,
  budgetFunctions, Approvals, companyCurrencies, $timeout, profile
) {

  $scope.costCenterList = [];
  $scope.expenseTypeList = [];
  $scope.profile = profile;

  $scope.apportionList = [
    'N/A',
    'monthly',
    'quarterly',
    'yearly'
  ];

  $scope.ownerList = [];

  $scope.startDateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };

  $scope.endDateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };

  $scope.datepickerOpened = {
    start_date: false,
    end_date: false,
    start_date_assignment: false,
    end_date_assignment: false
  };

  $scope.commodityLevel = [1, 2, 3, 4];
  $scope.budgetAssignSwitch = false;
  $scope.showItemListing = false;

  $scope.commentService = budgetCommentList;
  $scope.deleteService = singleCommentsHistory;

  $scope.singleAttachmentService = singleAttachment;
  $scope.attachmentParam = {
    referenceId: budgetData._id
  };
  $scope.attachmentUrl = $filter('format')(pathConstants.apiUrls.attachments.reference,
    {referenceId: budgetData._id});
  $scope.headerDetailsError = false;
  $scope.budgetAssignmentDetailsError = false;
  $scope.budgetItemDetailsError = false;
  $scope.currencyList = [];
  $scope.costCenterExtraSettings = {
    scrollable: true,
    idProp: '_id',
    externalIdProp: '',
    displayProp: 'descr',
    enableSearch: true,
    showCheckAll: true,
    showUncheckAll: true,
    closeOnSelect: true
  };

  $scope.getCostCenter = getCostCenter;
  $scope.openDatepicker = openDatepicker;
  $scope.getBudgetOwners = getBudgetOwners;
  $scope.isCreator = isCreator;
  $scope.isWaitingOnCurrentUser = isWaitingOnCurrentUser;
  $scope.getEditingBudgetState = getEditingBudgetState;
  $scope.showItems = showItems;
  $scope.toggleSwitch = toggleSwitch;
  $scope.checkApportionAllocation = checkApportionAllocation;
  $scope.approvalsPreview = approvalsPreview;
  $scope.getAssignmentRemovableState = getAssignmentRemovableState;
  $scope.getItemBreakdownRemovableState = getItemBreakdownRemovableState;

  /**
   * Check if item breakdown is able to be turned off
   */
  function getItemBreakdownRemovableState() {
    return budgetFunctions.getItemBreakdownRemovableState();
  }

  /**
   * Get cost center listing
   */
  function getCostCenter() {

    var createBudgetCompanies = globalFunc.getUserCompanyListForRole(profile, ['Budget Requester', 'Budget Owner']);
    var createBudgetCompanyCodes = _.pluck(createBudgetCompanies, 'code');

    var createBudgetCostCenters = [];
    if (createBudgetCompanyCodes.indexOf($scope.budget.company.code) > -1) {
        createBudgetCostCenters = $rootScope.currentUser.costCenters;
    } else {
        createBudgetCostCenters = globalFunc.getUserCostCenterListForRole(profile, 'Budget Requester');
    }

    _.forEach(createBudgetCostCenters, function (costCenter) {
      if (!!$scope.budget.company.code && $scope.budget.company.code === costCenter.company_code && !!costCenter.is_active) {
        $scope.costCenterList.push(
          {
            'descr': costCenter.descr,
            'code': costCenter.code,
            '_id': costCenter._id
          }
        );
      }
    });
  }

  /**
   * For date picker
   *
   * @param {Object} $event
   * @param {string} which - start or end date
   */
  function openDatepicker($event, which) {
    $event.preventDefault();
    $event.stopPropagation();

    if (!!$scope.budget.end_date) {
      $scope.startDateOptions.maxDate = $scope.budget.end_date;
    }

    $scope.endDateOptions.minDate = new Date();

    $scope.datepickerOpened[which] = true;
  }

  /**
   * Get budget owner listing
   *
   * @param {string} companyCode
   */
  function getBudgetOwners(companyCode) {
    userBudget.get(
      {
        company_code: companyCode
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.ownerList = resource.content.data;
        }
      },
      function () {
        toastr.error('Failed to retrieve budget owner listing.');
      }
    )
  }

  /**
   * Get currency listing
   *
   * @param {string} companyId
   */
  function getCurrencies(companyId) {
    if (budgetData.status !== 'draft') {
      return;
    }

    companyCurrencies.get({
        id: companyId
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.currencyList = resource.content.data;
        }
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      });
  }

  /**
   * Get budget attachments
   */
  function getBudgetAttachments() {
    attachmentsByReference.get({
      referenceId: $scope.budget._id
    }, function(resource) {
      if (!!resource && !!resource.content)
        $scope.budgetAttachments = resource.content.data;
    }, function(error) {
      globalFunc.objectErrorMessage(error);
    })
  }

  /**
   * Check if current user is budget creator
   *
   * @returns {boolean}
   */
  function isCreator() {
    return $rootScope.currentUser._id === $scope.budget.created_by._id;
  }

  /**
   * Check if approval is waiting on current user
   *
   * @returns {boolean}
   */
  function isWaitingOnCurrentUser() {
    // TODO: Add approval related logic for showing buttons in globalFunc in new approval (Justin 11/9/17)
    return (1 === 1);
  }

  /**
   * Set budget editable state
   */
  function setBudgetEditableState(){
    budgetFunctions.setEditableState(($scope.budget.status === 'draft') ? 'editable' : 'notEditable');
    if (!isCreator())
      budgetFunctions.setEditableState('notEditable');
  }

  /**
   * Set budget assignment removable state
   */
  function setAssignmentRemovableState() {
    var usedInPR = false;
    if (!!$scope.budget.assignments) {
      _.forEach($scope.budget.assignments, function (assignment) {
        if ((!!assignment.used_amount && assignment.used_amount > 0) ||
          (!!assignment.committed_amount && assignment.committed_amount > 0)
        ) {
          usedInPR = true;
        }
      });
    }

    if (getEditingBudgetState() === 'editable' && !usedInPR) {
      budgetFunctions.setAssignmentRemovableState(true);
    } else {
      budgetFunctions.setAssignmentRemovableState(false);
    }
  }

  /**
   * Get the current state for the budget, either editable or not
   *
   * @returns {*}
   */
  function getEditingBudgetState(){
    return budgetFunctions.getEditingState();
  }

  /**
   * Get the current state for the budget assignment, either removable or not
   *
   * @returns {*}
   */
  function getAssignmentRemovableState(){
    return budgetFunctions.getAssignmentRemovableState();
  }

  /**
   * Retrieves the expense types
   *
   * @param {string} companyId
   */
  function getExpenseTypes(companyId) {
    companyExpenseTypes.get(
      {
        id: companyId
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.expenseTypeList = resource.content.data;
        }
      },
      function () {
        toastr.error('Failed to retrieve expense type listing.');
      }
    )
  }

  /**
   * Flag to show item to be shown or not
   */
  function showItems() {
    $scope.showItemListing = !$scope.showItemListing;
  }

  /**
   * Run when toggle value change for budget assignment
   *
   * @param {boolean} toggle Switch to on/off
   * @param {string}  type   Is either assignment or itembreakdowns
   */
  function toggleSwitch(toggle, type) {
    if (!toggle &&
      ((type === 'assignment' && !!$scope.budget.assignments.length) ||
      (type === 'itembreakdown' && !!$scope.budget.item_breakdowns.length))
    ) {
      swal({
        title: 'Confirm turn off the section?',
        text: 'Turn off this will erase the ' + type + ' data?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        closeOnConfirm: true
      }, function (isConfirm) {
        if (isConfirm) {
          if (type === 'assignment') {
            $scope.budget.assignments = [];
          } else {
            $scope.budget.item_breakdowns = [];
          }
        } else {
          if (type === 'assignment') {
            $scope.budgetAssignSwitch = true;
          } else {
            $scope.budgetItemBreakdownSwitch = true;
          }
          $scope.$apply();
        }
      });
    }
  }

  /**
   * To check if apportion allocation values will change due to field changes
   *
   * @param {*}      oldValue   old value of the field
   * @param {*}      newValue   new value of the field
   * @param {string} fieldModel Name of the model to be modified
   * @returns {boolean}
   */
  function checkApportionAllocation(oldValue, newValue, fieldModel) {
    budgetFunctions.checkApportionAllocation(
      oldValue,
      newValue,
      ($scope.budget.apportion_allocation)).then(function (resource) {
      $scope.apportionRelatedValues = resource;
      $scope.budget[fieldModel] = resource;
      $scope.originalBudgetData[fieldModel] = resource;
    });
  }

  /**
   * Run to get approval flow data for this budget
   */
  function getApprovalFlow() {
    if ($scope.budget.status !== 'draft' && $scope.budget.status !== 'deleted') {
      Approvals.get({
        id: $scope.budget.approval_id
      }, function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.approvalFlowData = resource.content.data;
        }
        else
          $scope.approvalFlowData = [];
      }, function () {
        toastr.error('Failed to get approval flow data for this budget.');
      });
    }
  }

  function activateTab(){
    status = _.cloneDeep($scope.budget.status.toLowerCase());

    var tab = globalFunc.findInArray($scope.$parent.tabData, 'key', status);
    if(!tab)
      tab = globalFunc.findInArray($scope.$parent.tabData.more, 'key', status);

    return !!tab ? tab : $scope.$parent.tabData[0];
  }

  /**
   * Get the approval flow to display it in draft
   */
  function approvalsPreview() {
    $rootScope.$broadcast('saveDraftBudget');
  }

  function convertToLocalDate (date) {
    return new Date (date);
  }

  function initialize() {
    $scope.originalBudgetData = _.cloneDeep(budgetData);
    $scope.budget = _.cloneDeep(budgetData);
    $scope.$parent.status = $stateParams.status;

    var tabData = {
      tab: activateTab(),
      noTransition: true
    };

    // TODO: remove this with the new design (Justin 27/11/17)
    $timeout(function() {
      $rootScope.$broadcast('loadSpecificTab', tabData);
    }, 300);

    setBudgetEditableState();
    setAssignmentRemovableState();
    if (!!$scope.budget.item_breakdowns) {
      budgetFunctions.setItemBreakdownRemovableState($scope.budget.item_breakdowns);
    }
    var newDate = new Date();
    newDate.setHours(8, 0, 0, 0);
    $scope.budget.start_date = !!budgetData.start_date ? convertToLocalDate(budgetData.start_date) :newDate;
    $scope.budget.end_date = !!budgetData.end_date ? convertToLocalDate(budgetData.end_date) : newDate;
    $scope.budgetItemBreakdownSwitch = !!$scope.budget.item_breakdowns.length;
    $scope.budgetAssignSwitch = !!$scope.budget.assignments.length;
    getExpenseTypes(budgetData.company._id);
    getCostCenter();
    getBudgetOwners(budgetData.company.code);
    getCurrencies(budgetData.company._id);

    if (!!$scope.budget.assignments) {
      _.forEach($scope.budget.assignments, function (budgetAssignment, index) {
        budgetAssignment.start_date = !!budgetData.assignments[index].start_date ?
          convertToLocalDate(budgetData.assignments[index].start_date) : null;
        budgetAssignment.end_date = !!budgetData.assignments[index].end_date ?
          convertToLocalDate(budgetData.assignments[index].end_date) : null;
      });
    }

    getBudgetAttachments();
    getApprovalFlow();

    $scope.$watch('budget.cost_center.length', function () {
      _.forEach($scope.budget.assignments, function (budgetAssignment, index) {
        if (!!budgetAssignment.cost_center) {
          var temp = globalFunc.findInArray($scope.budget.cost_center, '_id', budgetAssignment.cost_center._id);
        }
        if (!temp) {
          $scope.budget.assignments[index].cost_center = [];
        }
      });
    });

    $scope.$watch('budget.expense_type', function (newValue) {
      if (!!newValue) {
        _.forEach($scope.budget.assignments, function (budgetAssignment, index) {
          $scope.budget.assignments[index].expense_type = newValue;
        });
      }
    });

    $scope.$watch('budget.apportion_type', function (newValue) {
      if (!!newValue) {
        _.forEach($scope.budget.assignments, function (budgetAssignment, index) {
          if (newValue !== 'N/A') {
            $scope.budget.assignments[index].apportion_type = newValue;
          }
        });
      }
    });
  }

  initialize();

}

budgetDetailsCtrl.$inject = [
  '$scope', 'toastr', 'companyExpenseTypes', 'userBudget', 'globalFunc',
  'budgetData', 'singleCommentsHistory', 'budgetCommentList', 'attachmentsByReference', 'singleAttachment',
  '$rootScope', '$filter', 'pathConstants', '$stateParams', 'budgetFunctions', 'Approvals', 'companyCurrencies',
  '$timeout', 'profile'
];

angular.module('metabuyer')
  .controller('budgetDetailsCtrl', budgetDetailsCtrl);

