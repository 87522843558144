'use strict';

function kharonManagementCtrl($scope, $state) {
  $scope.$emit('pageTitleChanged', 'Kharon Listing');
  $scope.tabData = [];

  var kharonLogs = {
    heading: 'Kharon Sync Logs',
    route: 'main.user.kharon.syncLogs',
    key: 'kharonLogs',
    id: 'kharonLogsTab'
  };
  var dataServiceColumns = [
    {id: 'id', label: '#', unsearchable: true},
    {id: 'entity_module', label: 'Module'},
    {id: 'submodule', label: 'Sub Module'},
    {id: 'current_status', label: 'Status'},
    {id: 'source_system', label: 'Source System'},
    {id: 'target_system', label: 'Target System'},
    {id: 'entity_reference', label: 'Reference'},
    {id: 'file_name', label: 'File Name', unsearchable: true, unsortable: true},
    {id: 'sync_direction', label: 'Sync Direction', unsearchable: true},
    {id: 'sync_initiator', label: 'Sync Initiator'},
    {id: 'created_at', label: 'Sync Initiated At', unsearchable: true},
    {id: 'updated_at', label: 'Sync Updated At', unsearchable: true}
  ];

  $scope.tabData.push(kharonLogs);

  function initialize() {
    setScope($state.current);
  }

  $scope.$on('$stateChangeStart', function( event, data, toParams ) {
    setScope(data);
    if (!!toParams.status) {
      $scope.parentStateParams = toParams;
    }

    if (!!toParams && !!toParams.query){
      $scope.searchText = toParams.query;
    }
    else {
      $scope.searchText = '';
    }

  });

  function setScope(state) {
    $scope.storageKey = 'kharon-sync-logs-selected-columns';
    $scope.allFilter = true;

    if (!!state.name && state.name.indexOf('syncLogs') > -1) {
      $scope.columns = dataServiceColumns;
      $scope.searchPlaceholder = 'Search Kharon Logs';
      $scope.searchState = 'main.user.kharon.syncLogs';
      $scope.parentState = 'main.user.kharon.syncLogs';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = kharonLogs;
    }
  }

  initialize();
}

kharonManagementCtrl.$inject = ['$scope', '$state'];

angular.module('metabuyer')
  .controller('kharonManagementCtrl', kharonManagementCtrl);
