'use strict';

/**
 *
 * @param $scope
 * @param items
 * @param globalFunc
 * @param prV2Function
 * @param pathConstants
 * @param $http
 */

function priceComparisonOtherDetails($scope, pathConstants, loadCacheableData, $rootScope, globalFunc, toastr, priceComparisonFunction, PCAttachments) {
  $scope.checkIfUserCanCreatePC = $rootScope.checkIfUserCanCreatePC;
  $scope.isCreatedPC = $rootScope.isCreatedPC;
  $scope.isPcCreatorRole = $rootScope.isPcCreatorRole;
  $scope.datePickerTimezone = $rootScope.datePickerTimezone;
  $scope.openDatePicker = openDatePicker;
  $scope.updateDate = updateDate;
  $scope.clearValidityDate = clearValidityDate;
  $scope.getTotalHeaderWidth = getTotalHeaderWidth;
  $scope.checkForeignCurrency = checkForeignCurrency;
  $scope.getRemarkRowWidth = getRemarkRowWidth;
  $scope.canEditAttachment = canEditAttachment;

  $scope.dateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };

  $scope.singleAttachmentService = PCAttachments;
  $scope.attachmentUrl = pathConstants.apiUrls.priceComparison.singleAttachment;

  loadCacheableData.loadData({
    module: 'payment-term',
    'criteria[is_active]': 1,
    offset: 0
  }, 'payment-term', 'localStorage').then(function(data) {
    $scope.paymentTermsList = data;
  });

  function checkForeignCurrency(comparison) {
    if (comparison.price_comparison_currency !== undefined) {
      return comparison.price_comparison_currency.code !== comparison.requisition_currency.code;
    }

    return false;
  }

  function getTotalHeaderWidth() {
    return 400 * ($scope.priceComparison.item_comparisons.length + 1) + 6;
  }

  function getRemarkRowWidth() {
    return 400 * ($scope.priceComparison.item_comparisons.length) + 1;
  }

  function clearValidityDate($event, otherDetail, key) {
    $event.preventDefault();
    $event.stopPropagation();
    otherDetail[key] = null;
  }

  function openDatePicker($event, otherDetail, key) {
    $event.preventDefault();
    $event.stopPropagation();
    otherDetail[key] = true;
  }

  function updateDate(comparison) {
    //Validation for the dates not to cross
    if (!!comparison.other_details.validity_from &&
      !!comparison.other_details.validity_to) {
      if (comparison.other_details.validity_to <
        comparison.other_details.validity_from) {
        comparison.other_details.validity_to = undefined;
        toastr.error('End date cannot be before start date.');
        return;
      }
    }
  }

  function canEditAttachment(comparison) {
    var disableEdit = false;
    // if it is a new comparison allow attachment edit
    if (comparison.quotation === undefined) {
      disableEdit = true;
    } else if (comparison.quotation.edit === true) {
      disableEdit = true;
    }

    // other users viewing draft PC cannot edit attachment
    if (!$scope.isPcCreatorRole || !$scope.checkIfUserCanCreatePC || !$scope.isCreatedPC) {
      disableEdit = false;
    }
    return disableEdit;
  }

  function getTemplateUrl(state) {

    if ($scope.priceComparison.status === 'DRAFT') {
      $scope.priceComparisonTemplateUrl = 'components/priceComparisonComponents/otherDetails/priceComparisonOtherDetails.html';
    } else {
      switch (state) {
        case 'editable':
          $scope.priceComparisonTemplateUrl = 'components/priceComparisonComponents/otherDetails/priceComparisonOtherDetails.html';
          break;
        case 'notEditable':
          $scope.priceComparisonTemplateUrl = 'components/priceComparisonComponents/otherDetails/priceComparisonOtherDetails.nonEditable.html';
          break;
        default:
          $scope.priceComparisonTemplateUrl = 'components/priceComparisonComponents/otherDetails/priceComparisonOtherDetails.nonEditable.html';
          break;
      }
    }
  }

  function initialize() {
    getTemplateUrl('notEditable');
  }

  initialize();
}

priceComparisonOtherDetails.$inject = ['$scope', 'pathConstants', 'loadCacheableData', '$rootScope', 'globalFunc', 'toastr', 'priceComparisonFunction', 'PCAttachments'];

angular
  .module('metabuyer')
  .directive('priceComparisonOtherDetails', function() {
    return {
      restrict: 'AE',
      scope: true,
      template: '<div ng-include="priceComparisonTemplateUrl"></div>',
      controller: 'priceComparisonOtherDetails'
    }
  })
  .controller('priceComparisonOtherDetails', priceComparisonOtherDetails);
