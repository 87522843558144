'use strict';
angular.module('metabuyer')
  .directive('onlyNumber', function () {
    return {
      restrict: 'EA',
      require: 'ngModel',
      scope:{
        onlyNumber: "=onlyNumber"
      },
      link: function (scope, element, attrs, ngModel) {
        scope.$watch("onlyNumber", function(newValue, oldValue) {

          newValue = String(newValue).trim();

          if(attrs.isDigit == "true"){
            scope.onlyNumber = newValue.replace(/[^0-9]/g, '');
            return;
          }

          var spiltArray = String(newValue).split("");

          if(spiltArray.length == 0){
            scope.onlyNumber = "";
            return;
          }

          if(attrs.allowNegative == "false") {
            if(spiltArray[0] == '-') {
              newValue = newValue.replace("-", "");
              scope.onlyNumber = newValue;
              return;
            }
          }

          if(attrs.allowDecimal == "false") {
            if(attrs.allowNegative != "false"){
              if (spiltArray.length === 1 && (spiltArray[0] == '-')){
                return;
              } else if(spiltArray.length > 1 && (spiltArray[0] == '-')){
                var n = newValue.substr(1);
                if (isNaN(n)) {
                  if(isNaN(oldValue)){
                    return;
                  }
                  scope.onlyNumber = oldValue;
                  return;
                }
              }
            }

            newValue = parseInt(newValue);
            scope.onlyNumber = newValue;
            return;
          }

          if(attrs.allowDecimal != "false") {
            if(attrs.allowNegative != "false"){
              if (spiltArray.length === 1 && (spiltArray[0] == '-')){
                return;
              } else if(spiltArray.length > 1) {
                if(spiltArray[0] == '-'){
                  var n = newValue.substr(1);
                  if (isNaN(n)) {
                    if(isNaN(oldValue)){
                      return;
                    }
                    scope.onlyNumber = oldValue;
                    return;
                  }
                }
              }

              if (isNaN(newValue)) {
                if(isNaN(oldValue)){
                  scope.onlyNumber = "";
                  return;
                }
                scope.onlyNumber = oldValue;
                return;
              }
            }

            if(attrs.decimalUpto) {
              var n = String(newValue).split(".");
              if(n[1]) {
                var n2 = n[1].slice(0, attrs.decimalUpto);
                newValue = [parseInt(n[0]), n2].join(".");
                scope.onlyNumber = newValue;
              }
            } else {
              if(String(newValue).indexOf(".") == -1){
                newValue = parseInt(newValue);
                scope.onlyNumber = newValue;
              }
            }
          }

          if (isNaN(newValue)) {
            if(isNaN(oldValue)){
              return;
            }
            scope.onlyNumber = oldValue;
            return;
          }

          scope.onlyNumber = newValue;
        });
      }
    };
});
