'use strict';

function usersUuidMappingCtrl ($scope, $state, toastr, reoutboundTool, globalFunc, $http, pathConstants, usersList, usersUuid) {
  $scope.searchUsers = searchUsers;
  $scope.onSelect = onSelect;
  $scope.submit = submit;
  $scope.removeMapping = removeMapping;

  function searchUsers(param) {
    if (!!param && param.length > 2) {
      $scope.userSearching = true;

      return $http.get(pathConstants.apiUrls.genericSearch.searchBasePath + '/users', {
        params: {
          module: 'users',
          'criteria[0][display_name]': param,
          'criteria[1][email]': param,
          criteria_operator: 'or'
        }
      }).then(function (response) {
        $scope.userSearching = false;
        if (!!response.data && !!response.data.content && !!response.data.content.data) {
          $scope.userListing = response.data.content.data;
        } else {
          $scope.userListing = [];
        }
        return response.data.content.data;
      }, function (error) {
        $scope.userSearching = false;
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  function onSelect(user) {
    $scope.selectedUser = user;
  }

  function submit() {
    usersUuid.post(
      {id: $scope.selectedUser._id, map_cmms: true},
      function (resource) {
        toastr.success('User\'s uuid has been mapped.');
        refreshTable(resource);
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      });
  }

  function removeMapping(user) {
    usersUuid.post(
      {id: user._id, map_cmms: false},
      function (resource) {
        toastr.success('User\'s uuid has been mapped.');
        refreshTable(resource);
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      });
  }

  function refreshTable(userList) {
      $scope.users = userList.data;
      $scope.usersMetaData = userList.meta
  }

  function initialize() {
    $scope.embeddedParams = {};
    $scope.users = usersList.data;
    $scope.usersMetaData = usersList.meta;
  }

  initialize();
}
usersUuidMappingCtrl.$inject = ['$scope', '$state', 'toastr', 'reoutboundTool', 'globalFunc', '$http', 'pathConstants', 'usersList', 'usersUuid'];

angular
  .module('metabuyer')
  .controller('usersUuidMappingCtrl', usersUuidMappingCtrl);
