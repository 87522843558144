'use strict';

function changeAllUsersPasswordCtrl($scope, changeAllUsersPassword, toastr, globalFunc) {
  $scope.changeAllPassword = changeAllPassword;

  $scope.error = false;
  $scope.mismatchError = false;
  $scope.wrongFormatError = false;
  $scope.skipChangeOnLogin = false;

  function changeAllPassword() {
    $scope.mismatchError = false;
    $scope.error = false;
    if (checkPasswordFormat()) {
      if (!checkMatchPassword()) {
        $scope.mismatchError = true;
        $scope.error = true;
      }
    } else {
      $scope.error = true;
    }

    if ($scope.error) {
      return;
    }

    swal({
      title: 'Confirm to change all users\' password?',
      text: 'This action will change every user\'s password.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Confirm',
      closeOnConfirm: true
    }, function (isConfirm) {
      if (isConfirm) {
        $scope.isDisabled = true;
        changeAllUsersPassword.post(
          {},
          {
            password: $scope.password_new,
            password_confirm: $scope.password_confirm,
            skip_change_on_login: $scope.skipChangeOnLogin
          },
          function (response) {
            console.log(response);
            toastr.success(response.message);
          },
          function (error) {
            globalFunc.objectErrorMessage(error);
          }
        )
      }
    });
  }

  function checkPasswordFormat() {
    var letter = /[a-z]/;
    var capitalLetter = /[A-Z]/;
    var number = /[0-9]/;
   
    return (letter.test($scope.password_new) && number.test($scope.password_new) &&
    $scope.password_new.length > 7 && capitalLetter.test($scope.password_new));
  }

  function checkMatchPassword() {
    return $scope.password_new === $scope.password_confirm;
  }
}

changeAllUsersPasswordCtrl.$inject = ['$scope', 'changeAllUsersPassword', 'toastr', 'globalFunc'];

angular
  .module('metabuyer')
  .controller('changeAllUsersPasswordCtrl', changeAllUsersPasswordCtrl);
