'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('main.user.devTools', {
        url: '/developer',
        controller: 'devToolsCtrl',
        templateUrl: 'app/devTools/devTools.html',
        resolve: {
          accessRights: function ( UserPermissions, $state) {
            if (!UserPermissions.isDeveloper()) {
              $state.go('notAuthorized');
            }
          },
          $title: function () {
            return 'Developer Tools'
          }
        }
      })
      .state('main.user.devTools.auditViewer', {
        url: '/auditViewer',
        controller: 'auditViewerCtrl',
        templateUrl: 'app/devTools/auditViewer/auditViewerTemplate.html'
      })
  }]);
