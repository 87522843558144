'use strict';

angular.module('metabuyer.services.sourcingCategory', ['ngResource'])
  .factory('sourcingCategoryMasterResource',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.sourcingCategory.single,
        {
          uuid: '@uuid'
        },
        {
          'post': {method: 'POST'},
          'put': {method: 'PUT'}
        }
      );
    })
  .factory('sourcingCategoryMasterUpdateStatus',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.sourcingCategory.updateStatus,
        {
          uuid: '@uuid'
        },
        {
          'put': {method: 'PUT'}
        }
      );
    });
