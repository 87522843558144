'use strict';
/**
 * @name exportsLogCtrl
 * @desc Controller for Logs of report
 */

function exportsLogCtrl ($scope, $filter, $stateParams,pathConstants,
                         toastr, exports) {

  $scope.selectedExportType = false;

  $scope.status = $stateParams.status;
  $scope.exportService = exports;

  $scope.onSelectedExportModule = onSelectedExportModule;
  $scope.downloadExport = downloadExport;

  /**
   * @type {{descr: string, value: number, type: string}[]}
   * The type is for determining what to pass into the listing parameters
   */
  $scope.exportModules = [
    { descr: 'Account Code', value: 3, type: 'AccountCode' },
    { descr: 'Business Units', value: 20, type: 'BusinessUnit' },
    { descr: 'Catalogs', value: 13, type: 'ImportItemSummary' },
    { descr: 'Catalog Items', value: 16, type: 'ImportItemDetail' },
    { descr: 'Commodity code', value: 10, type: 'Commodity' },
    { descr: 'Company', value: 1, type: 'Company' },
    { descr: 'Cost Center', value: 2, type: 'CostCenter' },
    { descr: 'Currencies', value: 0, type: 'Currencies' },
    { descr: 'Exchange Rate', value: 4, type: 'ExchangeRate' },
    { descr: 'Expense Types', value: 9, type: 'ExpenseTypes' },
    { descr: 'Industries', value: 19, type: 'Industry' },
    { descr: 'Payment Methods', value: 7, type: 'PaymentMethod' },
    { descr: 'Payment Terms', value: 6, type: 'PaymentTerm' },
    { descr: 'Purchase Requisitions', value: 17, type: 'PR' },
    { descr: 'Purchase Orders', value: 18, type: 'PO' },
    { descr: 'Suppliers', value: 11, type: 'Supplier' },
    { descr: 'Tax', value: 8, type: 'Tax' },
    { descr: 'UOM', value: 5, type: 'UOM' },
    { descr: 'Users', value: 12, type: 'Users' }
  ];

  function onSelectedExportModule(selectedModule){
    getExportListByType(selectedModule.type);
    $scope.selectedTitle = selectedModule.descr;
    $scope.selectedExportType = true;
  }

  function getExportListByType(selectedType) {
    exports.get(
      {
        type: selectedType
      },
      function (resource) {
        $scope.type = selectedType;
        $scope.embeddedParams = {
          type: $scope.type,
          offset: 10
        };
        $scope.logs = resource.content.data;
        $scope.meta = resource.content.meta;
      },
      function (error) {
        toastr.error('Failed to get export list');
      }
    );
  }

  /**
   *  Receive broadcast for updated embedded pagination data to update on controller side
   */
  $scope.$on('updateEmbeddedPagination', function(events, content){
    if(!!content && !!content.data)
    $scope.logs = content.data;
  });

  function downloadExport(logObject){
    if(!!logObject.status && logObject.status.toLowerCase() === 'done') {
      var newUrl = $filter('format')(pathConstants.apiUrls.export.download, {
        export_hash: logObject.hash
      });
      window.open(newUrl, '_self');
    }
  }

}

exportsLogCtrl.$inject = [
  '$scope','$filter', '$stateParams','pathConstants','toastr', 'exports'
];

angular
  .module('metabuyer')
  .controller('exportsLogCtrl', exportsLogCtrl);
