'use strict';

function modalMoreController ($scope, $uibModalInstance, title, subtitle, type, modalData, filter) {
  $scope.close = close;
  $scope.title = title;
  $scope.subtitle = subtitle;
  $scope.modalContents = modalData;
  $scope.dataType = type;
  $scope.listAllKey = listAllKey;
  $scope.filterArrayData = filterArrayData;

  function close() {
    $uibModalInstance.close();
  }

  function listAllKey(array) {
    var header = [];

    _.forEach(array[0], function(arr, key){
      header.push(key);
    });

    return header;
  }

  function filterArrayData(array, filter) {
    var newArr = [];

    _.forEach(array, function(arr) {
      var newObj = {};

      _.forEach(arr, function(value, key) {
        if(filter.indexOf(key) > -1)
          newObj[key] = value;
      });

      newArr.push(newObj);
    });

    return newArr;
  }

  function initialize() {
    // filter the data shown
    if($scope.dataType === 'array')
      $scope.modalContents = filterArrayData($scope.modalContents, filter);

    if($scope.dataType === 'array')
      $scope.tableHeader = listAllKey($scope.modalContents);
  }

  initialize();
}

modalMoreController.$inject = ['$scope', '$uibModalInstance', 'title', 'subtitle', 'type', 'modalData', 'filter'];

angular.module('metabuyer')
  .controller('modalMoreController', modalMoreController);
