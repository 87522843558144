'use strict';

angular.module('metabuyer.services.GRN', ['ngResource'])
  .factory('getGRN',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.po.grn,
        {
          id: '@id'
        },
        {
          'get': {method: 'GET'}
        }
      );
    })
  .factory('getGRNPDFHash',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.po.getGRNPDFHash,
        {
          id: '@id'
        },
        {
          'get': {method: 'GET'}
        }
      );
    });
