'use strict';
/**
 *
 * @description
 *
 * State for the purchase requisitions module
 *
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Sunway Metacloud &copy; 2016
 */

angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
    /**
     * @description
     *
     * Main state for the purchase requisitions,
     * Abstract and only handles the access to the Module
     *
     */
      .state('main.user.purchaseRequisitionsV2', {
        url: '/requisitionsV2',
        controller: 'purchaseRequisitionsV2MainCtrl',
        template: '<div ng-include="purchaseRequisitionsTemplate"></div>',
        abstract: true,
        resolve: {
          accessRights: function (profile, UserPermissions, $state, $rootScope) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'PR') || !$rootScope.isV2Enabled) {
              $state.go('notAuthorized');
            }
          },
          findTemplate: function ($rootScope) {
            $rootScope.purchaseRequisitionsTemplate = $rootScope.isMobileMode ?
              'app/purchaseRequisitionsV2/purchaseRequisitions-mobile.html' :
              'app/purchaseRequisitionsV2/purchaseRequisitions.html';
          }
        }
      })
      .state('main.user.purchaseRequisitionsV2.manage', {
        url: '/manage?{status:[a-zA-Z0-9]+}&{tab:[a-zA-Z0-9]+}&{extraFilterKey:[a-zA-Z0-9]+}&' +
        '{extraFilterValue:[a-zA-Z0-9]+}&{page:[0-9]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}&' +
        '{query:[a-zA-Z0-9]+}&{order:[0-9]+}&{order_by:[a-zA-Z0-9]+}',
        controller: 'requisitionsV2ManageCtrl',
        template: '<div ng-include="PRManageTemplate"></div>',
        resolve: {
          dataList: function ($q, searchModule, $stateParams, profile, exportSearch) {
            var deferred = $q.defer();
            var params = {
              module: 'Requisition',
              offset: 50,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };
            /**
             * all tab
             */
            if ($stateParams.status === 'all') {
              /**
               * searching (all tab)
               */
              if (!!$stateParams.query) {
                params['criteria[2][creator_info|_id][]'] = ($stateParams.extraFilterKey === 'criteria[creator_info|_id][][]') ?
                  $stateParams.extraFilterValue : null;
                params['criteria[2][collaborators.collaborator_id]'] =
                  ($stateParams.extraFilterKey === 'criteria[collaborators_ids][]' && !!$stateParams.extraFilterValue) ?
                    true : null;
                params['criteria[0][header_info|status][0]'] = 'draft';
                params['criteria[0][header_info|status][1]'] = 'pending';
                params['criteria[0][header_info|status][2]'] = 'approved';
                params['criteria[0][header_info|status][3]'] = 'deleted';
                params['criteria[0][header_info|status][4]'] = 'withdrawn';
                params['criteria[0][header_info|status][5]'] = 'rejected';
                params['criteria[0][header_info|status][6]'] = 'on_hold';
                params['criteria_operator'] = 'and';
                /**
                 * searching with a specific filter (all tab)
                 */
                if (!!$stateParams.filter) {
                  var temp = '';
                  if ($stateParams.type === 'date') {
                    temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                    params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                  }
                  //TODO: To have backend support searching double or change it (Justin)
                  else if ($stateParams.type === 'double') {
                    temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                    params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                  }
                  else {
                    if ($stateParams.filter === 'Item search') {
                      params['criteria[1][' + 'pr_items|item_details|item_name' + ']'] = $stateParams.query;
                      params['criteria[1][' + 'pr_items|item_details|description' + ']'] = $stateParams.query;
                      params['criteria[1][' + '$operator' + ']'] = 'or';
                    } else if ($stateParams.filter === 'header_info|reference') {
                      params['criteria[1][' + 'header_info|reference' + ']'] = $stateParams.query;
                      params['criteria[1][' + 'header_info|custom_reference' + ']'] = $stateParams.query;
                    } else
                      params['criteria[1][' + $stateParams.filter + ']'] = $stateParams.query;
                  }
                }
                /**
                 * searching all filters(all tab)
                 */
                else {
                  params['criteria[1][header_info|reference]'] = $stateParams.query;
                  params['criteria[1][header_info|custom_reference]'] = $stateParams.query;
                  params['criteria[1][header_info|title]'] = $stateParams.query;
                  params['criteria[1][creator_info|display_name]'] = $stateParams.query;
                  params['criteria[1][requestor|display_name]'] = $stateParams.query;
                  params['criteria[1][header_info|company|descr]'] = $stateParams.query;
                  params['criteria[1][header_info|cost_center|descr]'] = $stateParams.query;
                  params['criteria[1][header_info|shadow_company|descr]'] = $stateParams.query;
                  params['criteria[1][withdrawer|display_name]'] = $stateParams.status === 'withdrawn' ? $stateParams.query : null;
                  params['criteria[1][$operator]'] = !!$stateParams.query ? 'or' : null;
                }
              }
              /**
               * listing
               */
              else {
                params['criteria[1][creator_info|_id][]'] = ($stateParams.extraFilterKey === 'criteria[creator_info|_id][][]') ?
                  $stateParams.extraFilterValue : null;
                params['criteria[1][collaborators.collaborator_id]'] =
                  ($stateParams.extraFilterKey === 'criteria[collaborators_ids][]' && !!$stateParams.extraFilterValue) ?
                    true : null;
                params['criteria[0][header_info|status][0]'] = 'draft';
                params['criteria[0][header_info|status][1]'] = 'pending';
                params['criteria[0][header_info|status][2]'] = 'approved';
                params['criteria[0][header_info|status][3]'] = 'deleted';
                params['criteria[0][header_info|status][4]'] = 'withdrawn';
                params['criteria[0][header_info|status][5]'] = 'rejected';
                params['criteria[0][header_info|status][6]'] = 'on_hold';
                params['criteria_operator'] = 'and';
              }
            }
            /**
             * tabs other than all tab
             */
            else {
              /**
               * searching
               */
              if (!!$stateParams.query) {
                params['criteria[2][creator_info|_id][]'] = ($stateParams.extraFilterKey === 'criteria[creator_info|_id][][]') ?
                  $stateParams.extraFilterValue : null;
                params['criteria[2][collaborators.collaborator_id]'] =
                  ($stateParams.extraFilterKey === 'criteria[collaborators_ids][]' && !!$stateParams.extraFilterValue) ?
                    true : null;
                params['criteria[2][waiting_on][]'] = ($stateParams.extraFilterKey === 'criteria[waiting_on][]') ?
                  $stateParams.extraFilterValue : null;
                /**
                 * searching with a specific filter
                 */
                if (!!$stateParams.filter) {
                  params['criteria_operator'] = 'and';
                  var temp = '';
                  if ($stateParams.type === 'date') {
                    temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                    params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                  }
                  //TODO: To have backend support searching double or change it (Justin)
                  else if ($stateParams.type === 'double') {
                    temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                    params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                  }
                  else {
                    if ($stateParams.filter === 'Item search') {
                      params['criteria[1][' + 'pr_items|item_details|item_name' + ']'] = $stateParams.query;
                      params['criteria[1][' + 'pr_items|item_details|description' + ']'] = $stateParams.query;
                      params['criteria[1][' + '$operator' + ']'] = 'or';
                    } else if ($stateParams.filter === 'header_info|reference') {
                      params['criteria[1][' + 'header_info|reference' + ']'] = $stateParams.query;
                      params['criteria[1][' + 'header_info|custom_reference' + ']'] = $stateParams.query;
                    } else
                      params['criteria[1][' + $stateParams.filter + ']'] = $stateParams.query;
                  }
                }
                /**
                 * searching all filters
                 */
                else {
                  params['criteria_operator'] = !!$stateParams.query ? 'and' : null;
                  params['criteria[1][$operator]'] = !!$stateParams.query ? 'or' : null;
                  params['criteria[1][header_info|reference]'] = $stateParams.query;
                  params['criteria[1][header_info|custom_reference]'] = $stateParams.query;
                  params['criteria[1][header_info|title]'] = $stateParams.query;
                  params['criteria[1][creator_info|display_name]'] = $stateParams.query;
                  params['criteria[1][requestor|display_name]'] = $stateParams.query;
                  params['criteria[1][header_info|company|descr]'] = $stateParams.query;
                  params['criteria[1][header_info|cost_center|descr]'] = $stateParams.query;
                  params['criteria[1][header_info|shadow_company|descr]'] = $stateParams.query;
                  params['criteria[1][withdrawer|display_name]'] = $stateParams.status === 'withdrawn' ? $stateParams.query : null;
                }
              }
              /**
               * listing
               */
              else {
                params['criteria[1][creator_info|_id][]'] = ($stateParams.extraFilterKey === 'criteria[creator_info|_id][][]') ?
                  $stateParams.extraFilterValue : null;
                params['criteria[1][collaborators.collaborator_id]'] =
                  ($stateParams.extraFilterKey === 'criteria[collaborators_ids][]' && !!$stateParams.extraFilterValue) ?
                    true : null;
                params['criteria[1][waiting_on][]'] = ($stateParams.extraFilterKey === 'criteria[waiting_on][]') ?
                  $stateParams.extraFilterValue : null;
                params['criteria_operator'] = 'and';
              }
            }

            if ($stateParams.status === 'starred') {
              params['criteria[0][tab]'] = "starred";
            }
            else {
              params['criteria[0][header_info|status]'] = $stateParams.status;
              params['criteria[0][$operator]'] = 'or';
            }

            if (($stateParams.status === 'approved' || $stateParams.status === 'pending') && $stateParams.filter !== 'priceComparison_number|pc_number') {
              params['criteria[1][priceComparison_number|pc_number]'] = $stateParams.query;
            }

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                //TODO: handle the error
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          status: function ($stateParams) {
            return !!$stateParams.status ? $stateParams.status.toLowerCase() : '';
          },
          $title: function () {
            return 'Purchase Requisitions'
          },
          findTemplate: function ($rootScope) {
            $rootScope.PRManageTemplate = $rootScope.isMobileMode ?
              'app/purchaseRequisitionsV2/manage/manage-mobile.html' :
              'app/purchaseRequisitionsV2/manage/manage.html';
          }
        }
      })
      /**
       * @description
       *
       * details state for the purchase requisitions,
       * Resolves the main PR in which passed to the rest of the components
       *
       */
      .state('main.user.purchaseRequisitionsV2.details', {
        url: '/{id:[a-zA-Z0-9]+}',
        controller: 'requisitionsV2DetailsCtrl',
        template: '<div ng-include="PRDetailsTemplate"></div>',
        params: {PR: null},
        resolve: {
          purchaseRequisition: function ($q, purchaseRequisitionsServices, purchaseRequisitionsV2Services, $stateParams, prV2Function, globalFunc, $auth) {

            var deferred = $q.defer();
            /**
             * the PR data is available, no need for a call to the backend to get the PR info
             */
            if (!!$stateParams.PR && !!$stateParams.PR.data && $stateParams.PR.data._id === $stateParams.id) {
              if (!prV2Function.getPRData() || !prV2Function.getPRData()._id || prV2Function.getPRData()._id !== $stateParams.id) {
                //clean set of the PR object
                prV2Function.initializePRData($stateParams.PR.data);
              }
              $stateParams.PR.data = prV2Function.getPRData();
              deferred.resolve($stateParams.PR);
            }
            /**
             * the PR data is missing, transition is from a list or a Url
             */
            else {
              purchaseRequisitionsV2Services.getSinglePR(
                {
                  requisition_id: $stateParams.id
                },
                function (resource) {
                  if (!!resource.content) {
                    deferred.resolve(resource.content);
                    prV2Function.initializePRData(resource.content.data);

                  } else {
                    //TODO: it was successful but something is a miss, Have the best way to redirect in a globalFunc all resolved errors
                    deferred.resolve([]);
                  }
                },
                function (error) {
                  //HACK: the event doesn't stop the propagation, so checking the logging status to show the appropriate error message
                    if ($auth.isAuthenticated())
                        globalFunc.objectErrorMessage(error);
                    else
                      globalFunc.loggingWarning();
                }
              );
            }
            return deferred.promise;
          },
          $title: function (purchaseRequisition) {
            return 'PR - Details ' + !!purchaseRequisition.data ? purchaseRequisition.data.reference : '';
          },
          findTemplate: function ($rootScope) {
            $rootScope.PRDetailsTemplate = $rootScope.isMobileMode ?
              'app/purchaseRequisitionsV2/details/details-mobile.html' :
              'app/purchaseRequisitionsV2/details/details.html';
          },
          isIntegratedCompany: function (globalFunc, profile, purchaseRequisition) {
            return purchaseRequisition.data.is_integrated_company;
          },
          integrationSolution: function (globalFunc, profile, purchaseRequisition) {
            return purchaseRequisition.data.integration_solution;
          },
          companyMappingStatus: function ($q, purchaseRequisition, $http, metabuyerCache, pathConstants, isIntegratedCompany) {
            var deferred = $q.defer();

            if (!isIntegratedCompany) {
              deferred.resolve(false);
              return deferred.promise;
            }


            var companyMap = metabuyerCache.get('Integration - ' + purchaseRequisition.data.company.code);

            if (!!companyMap) {
              deferred.resolve(companyMap.isMapped);
            } else {

              $http.get(pathConstants.apiUrls.bifrost.companyEnvironment, {
                params: {
                  'module': 'company-environment',
                  'company-code': purchaseRequisition.data.company.code
                },
                ignoreLoadingBar: true
              }).then(function (resource) {
                if (!!resource.data) {
                  if (resource.data.length > 0) {
                    metabuyerCache.put('Integration - ' + purchaseRequisition.data.company.code, {isMapped: true});
                    deferred.resolve(true);
                  }
                  else {
                    metabuyerCache.put('Integration - ' + purchaseRequisition.data.company.code, {isMapped: false});
                    deferred.resolve(false);
                  }
                }
              }, function (err) {
                metabuyerCache.put('Integration - ' + purchaseRequisition.data.company.code, {isMapped: false});
                deferred.resolve(false);
              });
            }
            return deferred.promise;
          },
          prConfig: function ($q, purchaseRequisitionsV2Services) {
            var deferred = $q.defer();
            purchaseRequisitionsV2Services.getPrConfig(
              {},
              function (resource) {
                if (!!resource && !!resource.content) {
                  deferred.resolve(resource.content.data.config);
                } else {
                  // default to unlimited
                  deferred.resolve({pr_item_limit: -1});
                }
              },
              function (error) {
                // default to unlimited
                deferred.resolve({pr_item_limit: -1});
              }
            );

            return deferred.promise;
          }
        }
      })
      .state('main.user.purchaseRequisitionsV2.details.catalog', {
        url: '/catalog',
        abstract: true,
        templateUrl: 'components/purchaseRequisitionV2Components/purchaseRequisitionV2ShoppingMode/catalogV2PanelTemplate.html',
        controller: 'catalogV2HeaderCtrl',
        resolve: {
          purchaseRequisition: function (purchaseRequisition) {
            return purchaseRequisition;
          },
          paymentTerms: function (loadCacheableData) {
            //load the paymentTerms
            return loadCacheableData.loadData({
              module: 'payment-term',
              'criteria[is_active]': 1,
              offset: 0
            }, 'payment-term', 'localStorage').then(function(data){
              return data;
            });
          },
          prConfig: function ($q, purchaseRequisitionsV2Services) {
            var deferred = $q.defer();
            purchaseRequisitionsV2Services.getPrConfig(
              {},
              function (resource) {
                if (!!resource && !!resource.content) {
                  deferred.resolve(resource.content.data.config);
                } else {
                  // default to unlimited
                  deferred.resolve({pr_item_limit: -1});
                }
              },
              function (error) {
                // default to unlimited
                deferred.resolve({pr_item_limit: -1});
              }
            );

            return deferred.promise;
          }
        }
      })
      .state('main.user.purchaseRequisitionsV2.details.catalog.tab', {
        url: '/',
        templateUrl: 'components/purchaseRequisitionV2Components/purchaseRequisitionV2ShoppingMode/catalogV2.html',
        scope: false,
        resolve: {
          $title: function () {
            return "PR - detail - catalog";
          }
        }
      })
      .state('main.user.purchaseRequisitionsV2.details.quotation', {
        url: '/quotation',
        templateUrl: 'components/purchaseRequisitionV2Components/quotation/quotationViewTemplate.html',
        controller: 'quotationViewCtrl',
        resolve: {
          purchaseRequisitionv2: function ($q, purchaseRequisitionsServices, purchaseRequisitionsV2Services, $stateParams, prV2Function, globalFunc, $auth) {

            if(typeof prV2Function.getPRData()._id === "undefined"){
              var deferred = $q.defer();

              purchaseRequisitionsV2Services.getSinglePR(
                {
                  requisition_id: $stateParams.id
                },
                function (resource) {
                  if (!!resource.content) {
                    deferred.resolve(resource.content);
                    prV2Function.initializePRData(resource.content.data);

                  } else {
                    deferred.resolve([]);
                  }
                },
                function (error) {
                    if ($auth.isAuthenticated())
                        globalFunc.objectErrorMessage(error);
                    else
                      globalFunc.loggingWarning();
                }
              );

            return deferred.promise;
            }
          },
          $title: function () {
            return "PR - detail - quotation";
          },
          items: function(prV2Function,purchaseRequisitionv2){
            return prV2Function.getPRData().pr_items;
          },
          usedQuotations: function($q, singleQuotation, prV2Function,purchaseRequisitionv2) {
            var purchaseRequisition = prV2Function.getPRData();
            var deferred = $q.defer();

            var promises = [];
            var quotationsObjects = [];
            var quotationIds = [];

            if (!!purchaseRequisition.quotation_ids) {
              quotationIds = purchaseRequisition.quotation_ids
            }
            if (!quotationIds) {
              deferred.resolve([]);
            } else {
              _.forEach(quotationIds, function (singleQuotationId) {
                var promise = singleQuotation.get({
                    id: singleQuotationId
                  }
                );
                promises.push(promise.$promise);
              });
              $q.all(promises).then(function (resource) {
                _.forEach(resource, function (quotation) {
                  if (!!quotation && !!quotation.content && !!quotation.content.data) {
                    quotationsObjects.push(quotation.content.data);
                  }
                });
              }).catch().finally(function () {
                deferred.resolve(quotationsObjects);
              });
            }
            return deferred.promise;
          },
          defaultQuotations: function(prV2Function,purchaseRequisitionv2, $q, singleQuotation, generateDefaultQuotation) {
            var deferred = $q.defer();
            var purchaseRequisition = prV2Function.getPRData();

            var promises = [];
            var quotationsObjects = [];
            var quotationIds = [];
            // create default quotation
            if (!!purchaseRequisition && !!purchaseRequisition && !purchaseRequisition.default_quotation_ids) {
              var defaultQuotationIds = generateDefaultQuotation.post(
                {},
                {
                  requisition_id: purchaseRequisition.data._id
                },
                function (resource) {
                  if (!!resource && !!resource.content && !!resource.content.data) {
                    var quotations = resource.content.data;
                    if (!purchaseRequisition.data.default_quotation_ids){
                      purchaseRequisition.data.default_quotation_ids = [];
                    }
                    _.forEach(quotations, function (quotation) {
                        quotationsObjects.push(quotation);
                        purchaseRequisition.data.default_quotation_ids.push(quotation._id);
                      });
                    deferred.resolve(quotationsObjects)
                  }
                },
                function (error) {
                  deferred.resolve([])
                });
                return deferred.promise;
            } else {
              quotationIds = purchaseRequisition.default_quotation_ids;
              if (!quotationIds) {
                deferred.resolve([]);
              } else {
                _.forEach(quotationIds, function (singleQuotationId) {
                  var promise = singleQuotation.get({
                      id: singleQuotationId
                    }
                  );
                  promises.push(promise.$promise);
                });
                $q.all(promises).then(function (resource) {
                  _.forEach(resource, function (quotation) {
                    if (!!quotation && !!quotation.content && !!quotation.content.data) {
                      quotationsObjects.push(quotation.content.data);
                    }
                  });
                }).catch().finally(function () {
                  deferred.resolve(quotationsObjects);
                });
              }

              return deferred.promise;
            }
            }
      }});
  }]);
