'use strict';
/**
 * @name newAddressCtrl
 * @desc Controller for new address controller
 */
function newAddressCtrl(
  $scope, $uibModalInstance, addressType, toastr, addressList, globalFunc, costCenterDetails,
  CostCenters, company
) {

  $scope.addressType = addressType;
  $scope.company = company;
  $scope.cancel = cancel;
  $scope.saveNewAddress = saveNewAddress;
  $scope.duplicatingAddress = duplicatingAddress;
  $scope.address = {};
  $scope.countries = globalFunc.countryCode();
  $scope.MYStates = globalFunc.malaysiaState();
  $scope.onSelectedState = onSelectedState;
  $scope.onSelectedCountry = onSelectedCountry;

  function cancel() {
    $uibModalInstance.close();
  }

  /**
   * To check selected state and country
   */

  function onSelectedState(state){
    $scope.address.state = state.descr;
  }

  function onSelectedCountry(country){
    $scope.address.state = undefined;
    $scope.address.country = country.descr;
    if(country.descr === "Malaysia"){
      $scope.selectedMalaysia = true;
    }
    else
    {
      $scope.selectedMalaysia = false;
    }
  }

  /**
   * To save address to address book endpoint
   */
  function saveNewAddress(addressType) {
    if(validate($scope.address)){
      var data = angular.copy($scope.address);
      data['companyCode'] = $scope.company.code;
      addressList.post(data,
        function(resource){
          if(!!resource && !!resource.content && !!resource.content.data && !!resource.content.data._id){
            $scope.address['type'] = addressType;
            $scope.address['_id'] = resource.content.data._id;
            $scope.address['reference'] = resource.content.data.reference;
            saveAddressToCostCenter($scope.address);
          }
        }, function(error){
          globalFunc.objectErrorMessage(error);
        }
      );
    } else {
      toastr.error("Fill the required field");
    }
  }

  /**
   * Save address id (mongoId) to the cost center
   * @param addressType (billing or delivery)
   */
  function saveAddressToCostCenter(addressData){

    var update_data = {};
    update_data['delivery_addresses_ids'] = [];
    update_data['billing_addresses_ids'] = [];

    if(!!costCenterDetails.delivery_addresses){
      _.forEach(costCenterDetails.delivery_addresses, function(deliveryAddress){
        update_data['delivery_addresses_ids'].push(deliveryAddress._id);
      });
    }

    if(!!costCenterDetails.billing_addresses){
      _.forEach(costCenterDetails.billing_addresses, function(billingAddress){
        update_data['billing_addresses_ids'].push(billingAddress._id);
      });
    }

    if(addressData.type === 'billing') {
      update_data['billing_addresses_ids'].push(addressData._id);
    } else {
      update_data['delivery_addresses_ids'].push(addressData._id);
    }

    CostCenters.put(
      {
        id: costCenterDetails._id,
        descr: costCenterDetails.descr
      }, update_data ,
      function(){
        toastr.success('The address was added successfully');
        addressData.selected = true;
        $uibModalInstance.close(addressData);
        if(!$scope.company.related_addresses_ids){
          $scope.company.related_addresses_ids = [];
        }
        $scope.company.related_addresses_ids.push(addressData._id);
      },
      function(){
        toastr.error('Failed to add address');
      }
    );
  }

  function validate() {
    return ($scope.address.line1 && $scope.address.postal_code && $scope.address.state &&
    $scope.address.city && $scope.address.country && $scope.address.phone);

  }

  function duplicatingAddress(checkDuplicate){
    var companyDetails = costCenterDetails.company.data.corresponding_address.data;
    if(checkDuplicate){
      $scope.address.line1 = companyDetails.line1;
      $scope.address.line2 = companyDetails.line2;
      $scope.address.line3 = companyDetails.line3;
      $scope.address.city = companyDetails.city;
      $scope.address.state = companyDetails.state;
      $scope.address.country = companyDetails.country;
      $scope.address.postal_code = companyDetails.postal_code;
    } else {
      $scope.address = {};
    }
  }

}

newAddressCtrl.$inject = [
  '$scope', '$uibModalInstance', 'addressType', 'toastr', 'addressList', 'globalFunc',
  'costCenterDetails', 'CostCenters', 'company'
];

angular
  .module('metabuyer')
  .controller('newAddressCtrl', newAddressCtrl);
