'use strict';
/**
 * @name securityQuestionModalCtrl
 * @desc Controller for to update Security Question
 */
function securityQuestionModalCtrl($scope, $uibModalInstance, $translate, toastr, globalFunc, profile, securityQuestion, getSecurityQuestionList) {
  $scope.isLoading = true;
  $scope.securityQuestionList = [];
  $scope.selected = {
    security_question: null
  }
  getSecurityQuestions();
  securityAnswerInputResolver();

  $scope.submitSecurityQuestion = function () {
    securityQuestion.put(
      {},
      {
        security_question: $scope.selected.security_question,
        security_answer: btoa(unescape(encodeURIComponent($scope.security_answer))),
      },
      function (response) {
        toastr.success(response.content.message);
        $uibModalInstance.close();
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function getSecurityQuestions() {
    getSecurityQuestionList.get(
      {},
      {},
      function (response) {
        $scope.securityQuestionList = response.content.data;
        if ($translate.use() === 'cn') {
          _.forEach($scope.securityQuestionList, function(securityQuestion, index){
            securityQuestion.question = securityQuestion.question_chinese;
            $scope.securityQuestionList['index'] = securityQuestion;
          });
        }
        var index = _.findIndex($scope.securityQuestionList, function(securityQuestion) {
          return securityQuestion._id === profile.security_question.question_id;
        });
        $scope.selected.security_question = $scope.securityQuestionList[index] || null
        $scope.isLoading = false;
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function securityAnswerInputResolver() {
    var isOkay = false;
    if (typeof CSS.supports) {
      if (CSS.supports('-webkit-text-security','disc')) {
        isOkay = true;
      }
    }
    if (!isOkay) {
      var input = document.createElement('input');
      input.className = "hide";
      document.getElementById('appending_here').appendChild(input);
      document.getElementById('security_answer').type = 'password';
    }
  }

  $scope.closeModal = function () {
    $uibModalInstance.close();
  }
}

securityQuestionModalCtrl.$inject = ['$scope', '$uibModalInstance', '$translate', 'toastr', 'globalFunc', 'profile', 'securityQuestion', 'getSecurityQuestionList'];

angular
  .module('metabuyer')
  .controller('securityQuestionModalCtrl', securityQuestionModalCtrl);
