'use strict';

angular.module('metabuyer.services.paymentMethods', ['ngResource'])
  .factory('paymentMethodsList',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.paymentMethods.list,
      {
      },
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('paymentMethodsMasterDataList',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.paymentMethods.masterList,
      {
      },
      {
        'get': { method: 'GET' },
        'post': {method: 'POST'}
      }
    );
  })
  .factory('searchPaymentMethods',
    function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.paymentMethods.search,
      {
        query: '@query'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('singlePaymentMethod',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.paymentMethods.single,
        {
          id: '@id'
        },
        {
          'get': {method: 'GET'},
          'put': {method: 'PUT'},
          'delete': {method: 'DELETE'}
        }
      );
    });
