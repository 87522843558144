'use strict';

angular.module('metabuyer.services.maintenance', ['ngResource'])
  .factory('changeGrnDoNumber', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.maintenance.changeGrnDoNumber,
      {},
      {
        'put': {
          method: 'PUT'
        }
      }
    );
  })
  .factory('changeMappingFieldSize', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.maintenance.changeMappingFieldSize,
      {},
      {
        'get': {
          method: 'GET'
        },
        'put': {
          method: 'PUT'
        }
      }
    )
  })
  .factory('reoutboundTool', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.maintenance.reoutboundTool,
      {},
      {
        'get': {
          method: 'GET'
        },
        'put': {
          method: 'PUT'
        }
      }
    )
  })
  .factory('showSuppliers', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.maintenance.showSuppliers,
      {},
      {
        'get': {
          method: 'GET'
        }
      }
    )
  })
  .factory('companyContractJob', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.maintenance.companyContractJob,
      {},
      {
        'post': {
          method: 'POST'
        }
      }
    )
  })
  .factory('usersUuid', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.maintenance.usersUuid,
      {},
      {
        'get': {
          method: 'GET'
        },
        'post': {
          method: 'POST'
        }
      }
    )
  })
  .factory('integrationConfiguration', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.maintenance.integrationConfig,
      {},
      {
        'get': {
          method: 'GET'
        },
        'put': {
          method: 'PUT'
        }
      }
    )
  })
  .factory('holidayServices', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.holiday.singleHoliday,
      {},
      {
        'post': {
          method: 'POST',
        },
        'get': {
          method: 'GET',
        },
        'put': {
          method: 'PUT',
        },
        'delete': {
          method: 'DELETE',
        }
      }
    )
  })
  .factory('changeAllUsersPassword', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.maintenance.changeAllUsersPassword,
      {},
      {
        'post': {
          method: 'POST'
        }
      }
    )
  })
;
