'use strict';

function bifrostProcessStatusCtrl($scope, $state) {

  function initialize(){

    $scope.searchPlaceholder = 'Search bifrost processing data';
    $scope.searchState = 'main.user.bifrostProcessStatus.manage';
    $scope.parentState = 'main.user.bifrostProcessStatus.manage';
    $scope.parentStateParams = $state.params;
    $scope.columns = manageColumns;

  }

  var manageColumns = [
    {id: 'id', label: 'ID', unsortable: true},
    {id: 'erp_type', label: 'ERP Type', unsortable: false},
    {id: 'module', label: 'Module', unsortable: false},
    {id: 'file_name', label: 'File Name', unsortable: false},
    {id: 'file_path', label: 'File Path', unsortable: false},
    {id: 'processing_status', label: 'Processing Status', unsortable: false},
    {id: 'transfer_status', label: 'Transfer Status', unsortable: false},
    {id: 'created_at', label: 'Created At', unsortable: false},
    {id: 'updated_at', label: 'Updated At', unsortable: false}
  ];

  initialize();

  $scope.$emit('pageTitleChanged', 'Bifrost Processing Status');

  $scope.$on('$stateChangeStart', function(event, toState, toParams){
    $scope.parentStateParams = toParams;
  });
}
bifrostProcessStatusCtrl.$inject = ['$scope', '$state'];


angular.module('metabuyer')
  .controller('bifrostProcessStatusCtrl', bifrostProcessStatusCtrl);
