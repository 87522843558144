'use strict';
/**
 * @name onlineUsersCtrl
 * @desc Controller to View and disconnect active users
 */
function onlineUsersCtrl($scope, $stateParams, activeUsersList, activeUsers, toastr) {
  $scope.dataList = activeUsersList.data;
  $scope.meta = activeUsersList.meta;
  $scope.userManageService = activeUsers;
  $scope.status = $stateParams.status;
  $scope.disconnectUser = disconnectUser;

  function disconnectUser(info, index){
    swal({
        title: "Confirm disconnecting a User",
        text: "This user will be disconnected. Please state reason",
        type: "input",
        showCancelButton: true,
        confirmButtonColor: "#1ab394",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        closeOnConfirm: false,
        closeOnCancel: true,
        inputType: "text",
        inputPlaceholder: "Write a comment"
      },
      function(inputValue) {
        if (inputValue === false) {
          return false;
        }
        if(inputValue.indexOf(' ') == 0){
          swal.showInputError("Reason cannot start with white spaces!");
          return false;
        }
        if(inputValue === "") {
          swal.showInputError("You need to state a reason for disconnecting!");
          return false
        }
        activeUsers.delete(
          {
            session_id: info.session_id,
            comment: inputValue
          },
          function (resource) {
            if (!!resource.content) {
              swal.close();
              $scope.dataList.splice(index,1);
              refreshUsersList();
              toastr.success('Disconnected user');
            }
          },
          function (error) {
            if(!!error.data && !!error.data.content)
            {
              toastr.error(error.data.content.error);
            }
            else{
              toastr.error('User disconnection failed');
            }
          }
        );
      });
  }
  function refreshUsersList(){
    activeUsers.get(
      {
        session_id: 'active'
      },
      function (resource) {
        if (!!resource.content) {
          $scope.dataList = resource.content.data;
          $scope.meta = resource.content.meta;
        }
      },
      function (error) {
      }
    );
  }

}

onlineUsersCtrl.$inject = ['$scope', '$stateParams', 'activeUsersList', 'activeUsers', 'toastr'];

angular
  .module('metabuyer')
  .controller('onlineUsersCtrl', onlineUsersCtrl);
