'use strict';

function sageIntegrationModalCtrl($scope, $rootScope, $uibModalInstance, companies, supplierMapping, supplier, action, updateSageCompanyCode, globalFunc, toastr) {
  $scope.close = close;
  $scope.create = create;
  $scope.allCheckBoxClick = allCheckBoxClick;
  $scope.selectCompany = selectCompany;
  $scope.getMappingStatus = getMappingStatus;
  $scope.selectedCompanies = [];
  $scope.companies = companies.data;
  $scope.supplierMapping = !!supplierMapping.data ? supplierMapping.data : [];
  $scope.supplier = supplier;
  $scope.action = action;
  $scope.allCheckBox = {
    selected: false
  };

  function getMappingStatus(company){
    var status = 'Not Mapped';
    var mapped = _.findIndex($scope.supplier.company_code_to_sync_to_sage, function(e){
      return company.code === e;
    });

    if (mapped > -1 && (!!company.mapping && !!company.mapping.number)){
      status = 'tick';
    } else if (mapped > -1){
      status = 'Pending Mapping';
    }

    return status;
  }

  function selectCompany(company) {
    company.selected = !!company.selected;
  }

  function allCheckBoxClick(value){
    if(value) {
      selectAllItems();
    } else {
      $scope.allCheckBox = false;
      unSelectAllItems();
    }
  }

  function selectAllItems(){
    for (var i in $scope.companies){
      if (getMappingStatus($scope.companies[i]) === 'Not Mapped'){
        $scope.companies[i].selected = true;
      }
    }
  }

  function unSelectAllItems(){
    for (var i in $scope.companies){
      if (getMappingStatus($scope.companies[i]) === 'Not Mapped'){
        $scope.companies[i].selected = false;
      }
    }
  }

  function close(){
    $uibModalInstance.close();
  }

  function updateSage(){
    updateSageCompanyCode.put({
      id: $scope.supplier._id
    },
    {
      company_code_to_sync_to_sage: $scope.supplier.company_code_to_sync_to_sage
    },
    function (res) {
      toastr.success(res.message);
    },
    function (error) {
      globalFunc.objectErrorMessage(error);
    })
  }

  function create(){
    swal({
      title: 'Confirm SAGE Integration',
      text: 'Confirm SAGE Integration?',
      showCancelButton: true,
      confirmButtonColor: '#1ab394',
      confirmButtonText: 'Confirm',
      closeOnConfirm: true
    }, function (isConfirm) {
      if (isConfirm) {
        var selectedCompaniesCode = getSelectedCompanies();
        $scope.supplier.company_code_to_sync_to_sage = selectedCompaniesCode;
        if ($scope.action === 'update'){
          updateSage();
        }
        close();
      }
    })
  }

  function getSelectedCompanies(){
    var selectedCompanies = []
    _.forEach($scope.companies, function(e){
      if (e.selected === true && e.mapped !== true) {
        selectedCompanies.push(e.code);
      }
    })
    return selectedCompanies;
  }

  function initialize(){
    _.forEach($scope.companies, function(company){
      if (!!$scope.supplierMapping && $scope.supplierMapping.length > 0){
        var mapping = _.find($scope.supplierMapping, function(e){
          return company.code === e.company.code && e.environment.code.toLowerCase() === 'sage';
        });

        if (!!mapping) {
          company.mapping = mapping;
          company.selected = true;
          company.mapped = true;
        }
      }
      else{
        if (!!$scope.supplier.company_code_to_sync_to_sage && $scope.supplier.company_code_to_sync_to_sage.includes(company.code)){
          company.selected = true;
        }
      }
    })
  }

  initialize();

}

sageIntegrationModalCtrl.$inject = [
  '$scope', '$rootScope', '$uibModalInstance', 'companies', 'supplierMapping', 'supplier', 'action', 'updateSageCompanyCode', 'globalFunc', 'toastr'
]

angular
.module('metabuyer')
.controller('sageIntegrationModalCtrl', sageIntegrationModalCtrl);
