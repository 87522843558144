'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function ($stateProvider) {

    $stateProvider
      .state('main.user.bifrostMapping', {
        url: '/bifrost-mapping',
        controller: 'bifrostMappingMainCtrl',
        templateUrl: 'app/bifrostMapping/index.html',
        resolve: {
          accessRights: function (profile, $state, globalFunc) {
            if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN')) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.bifrostMapping.tax', {
        templateUrl: 'app/bifrostMapping/manage/manage.html',
        url: '/tax?&{query:[a-zA-Z0-9]+}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}{page:[0-9]+}',
        controller: 'bifrostMappingManageCtrl',
        resolve: {
          dataList: function ($q, $stateParams, bifrostMappingByModule) {
            var deferred = $q.defer();
            var params = {
              module: 'tax-environment',
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            if (!!$stateParams.query) {
              params['query'] = $stateParams.query;

              if (!!$stateParams.filter) {
                params['filter'] = $stateParams.filter;
              }
            }

            bifrostMappingByModule.get(
              params,
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              });

            return deferred.promise;
          },
          $title: function () {
            return 'Bifrost Mapping - Tax';
          },
          type: function () {
            return 'Tax Mapping';
          },
          module: function() {
            return 'tax-environment';
          },
        }
      })
      .state('main.user.bifrostMapping.company', {
        templateUrl: 'app/bifrostMapping/manage/manage.html',
        url: '/company?&{query:[a-zA-Z0-9]+}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}{page:[0-9]+}',
        controller: 'bifrostMappingManageCtrl',
        resolve: {
          dataList: function ($q, $stateParams, bifrostMappingByModule) {
            var deferred = $q.defer();
            var params = {
              module: 'company-environment',
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            if (!!$stateParams.query) {
              params['query'] = $stateParams.query;

              if (!!$stateParams.filter) {
                params['filter'] = $stateParams.filter;
              }
            }

            bifrostMappingByModule.get(
              params,
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              });

            return deferred.promise;
          },
          $title: function () {
            return 'Bifrost Mapping - Company';
          },
          type: function () {
            return 'Company Mapping';
          },
          module: function() {
            return 'company-environment';
          },
        }
      })
      .state('main.user.bifrostMapping.paymentTerm', {
        templateUrl: 'app/bifrostMapping/manage/manage.html',
        url: '/payment-term?&{query:[a-zA-Z0-9]+}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}{page:[0-9]+}',
        controller: 'bifrostMappingManageCtrl',
        resolve: {
          dataList: function ($q, $stateParams, bifrostMappingByModule) {
            var deferred = $q.defer();
            var params = {
              module: 'payment-term-environment',
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            if (!!$stateParams.query) {
              params['query'] = $stateParams.query;

              if (!!$stateParams.filter) {
                params['filter'] = $stateParams.filter;
              }
            }

            bifrostMappingByModule.get(
              params,
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              });

            return deferred.promise;
          },
          $title: function () {
            return 'Bifrost Mapping - Payment Term';
          },
          type: function () {
            return 'Payment Term Mapping';
          },
          module: function() {
            return 'payment-term-environment';
          },
        }
      })
      .state('main.user.bifrostMapping.uom', {
        templateUrl: 'app/bifrostMapping/manage/manage.html',
        url: '/uom?&{query:[a-zA-Z0-9]+}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}{page:[0-9]+}',
        controller: 'bifrostMappingManageCtrl',
        resolve: {
          dataList: function ($q, $stateParams, bifrostMappingByModule) {
            var deferred = $q.defer();
            var params = {
              module: 'uom-environment',
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            if (!!$stateParams.query) {
              params['query'] = $stateParams.query;

              if (!!$stateParams.filter) {
                params['filter'] = $stateParams.filter;
              }
            }

            bifrostMappingByModule.get(
              params,
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              });

            return deferred.promise;
          },
          $title: function () {
            return 'Bifrost Mapping - UOM';
          },
          type: function () {
            return 'UOM Mapping';
          },
          module: function() {
            return 'uom-environment';
          },
        }
      })
      .state('main.user.bifrostMapping.supplier', {
        templateUrl: 'app/bifrostMapping/manage/manage.html',
        url: '/supplier?&{query:[a-zA-Z0-9]+}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}{page:[0-9]+}',
        controller: 'bifrostMappingManageCtrl',
        resolve: {
          dataList: function ($q, $stateParams, bifrostMappingByModule) {
            var deferred = $q.defer();
            var params = {
              module: 'supplier-environment',
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            if (!!$stateParams.query) {
              params['query'] = $stateParams.query;

              if (!!$stateParams.filter) {
                params['filter'] = $stateParams.filter;
              }
            }

            bifrostMappingByModule.get(
              params,
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              });

            return deferred.promise;
          },
          $title: function () {
            return 'Bifrost Mapping - Supplier';
          },
          type: function () {
            return 'Supplier Mapping';
          },
          module: function() {
            return 'supplier-environment';
          },
        }
      })
      .state('main.user.bifrostMapping.costCenter', {
      templateUrl: 'app/bifrostMapping/manage/manage.html',
      url: '/cost_center?&{query:[a-zA-Z0-9]+}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}{page:[0-9]+}',
      controller: 'bifrostMappingManageCtrl',
      resolve: {
        dataList: function ($q, $stateParams, bifrostMappingByModule) {
          var deferred = $q.defer();
          var params = {
            module: 'cost-center-environment',
            page: $stateParams.page,
            order_by: $stateParams.order_by,
            order: $stateParams.order
          };

          if (!!$stateParams.query) {
            params['query'] = $stateParams.query;

            if (!!$stateParams.filter) {
              params['filter'] = $stateParams.filter;
            }
          }

          bifrostMappingByModule.get(
            params,
            function (resource) {
              deferred.resolve(resource);
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
              deferred.resolve([]);
            });

          return deferred.promise;
        },
        $title: function () {
          return 'Bifrost Mapping - Cost Center';
        },
        type: function () {
          return 'Cost Center Mapping';
        },
        module: function() {
          return 'cost-center-environment';
        }
      }
    })
    .state('main.user.bifrostMapping.purchaseGroup', {
      templateUrl: 'app/bifrostMapping/manage/manage.html',
      url: '/purchase_group?&{query:[a-zA-Z0-9]+}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}{page:[0-9]+}',
      controller: 'bifrostMappingManageCtrl',
      resolve: {
        dataList: function ($q, $stateParams, bifrostMappingByModule) {
          var deferred = $q.defer();
          var params = {
            module: 'purchase-group-environment',
            page: $stateParams.page,
            order_by: $stateParams.order_by,
            order: $stateParams.order
          };

          if (!!$stateParams.query) {
            params['query'] = $stateParams.query;

            if (!!$stateParams.filter) {
              params['filter'] = $stateParams.filter;
            }
          }

          bifrostMappingByModule.get(
            params,
            function (resource) {
              deferred.resolve(resource);
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
              deferred.resolve([]);
            });

          return deferred.promise;
        },
        $title: function () {
          return 'Bifrost Mapping - Purchase Group';
        },
        type: function () {
          return 'Purchase Group Mapping';
        },
        module: function() {
          return 'purchase-group-environment';
        }
      }
    })
    .state('main.user.bifrostMapping.purchaseOrganization', {
      templateUrl: 'app/bifrostMapping/manage/manage.html',
      url: '/purchase_organization?&{query:[a-zA-Z0-9]+}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}{page:[0-9]+}',
      controller: 'bifrostMappingManageCtrl',
      resolve: {
        dataList: function ($q, $stateParams, bifrostMappingByModule) {
          var deferred = $q.defer();
          var params = {
            module: 'purchase-organization-environment',
            page: $stateParams.page,
            order_by: $stateParams.order_by,
            order: $stateParams.order
          };

          if (!!$stateParams.query) {
            params['query'] = $stateParams.query;

            if (!!$stateParams.filter) {
              params['filter'] = $stateParams.filter;
            }
          }

          bifrostMappingByModule.get(
            params,
            function (resource) {
              deferred.resolve(resource);
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
              deferred.resolve([]);
            });

          return deferred.promise;
        },
        $title: function () {
          return 'Bifrost Mapping - Purchase Organization';
        },
        type: function () {
          return 'Purchase Organization Mapping';
        },
        module: function() {
          return 'purchase-organization-environment';
        }
      }
    })
    .state('main.user.bifrostMapping.plant', {
      templateUrl: 'app/bifrostMapping/manage/manage.html',
      url: '/plant?&{query:[a-zA-Z0-9]+}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}{page:[0-9]+}',
      controller: 'bifrostMappingManageCtrl',
      resolve: {
        dataList: function ($q, $stateParams, bifrostMappingByModule) {
          var deferred = $q.defer();
          var params = {
            module: 'plant-environment',
            page: $stateParams.page,
            order_by: $stateParams.order_by,
            order: $stateParams.order
          };

          if (!!$stateParams.query) {
            params['query'] = $stateParams.query;

            if (!!$stateParams.filter) {
              params['filter'] = $stateParams.filter;
            }
          }

          bifrostMappingByModule.get(
            params,
            function (resource) {
              deferred.resolve(resource);
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
              deferred.resolve([]);
            });

          return deferred.promise;
        },
        $title: function () {
          return 'Bifrost Mapping - Plant';
        },
        type: function () {
          return 'Plant Mapping';
        },
        module: function() {
          return 'plant-environment';
        }
      }
    })
    .state('main.user.bifrostMapping.focItems', {
      templateUrl: 'app/bifrostMapping/manage/manage.html',
      url: '/foc_items?&{query:[a-zA-Z0-9]+}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}{page:[0-9]+}',
      controller: 'bifrostMappingManageCtrl',
      resolve: {
        dataList: function ($q, $stateParams, bifrostMappingByModule) {
          var deferred = $q.defer();
          var params = {
            module: 'foc-items-environment',
            page: $stateParams.page,
            order_by: $stateParams.order_by,
            order: $stateParams.order
          };

          if (!!$stateParams.query) {
            params['query'] = $stateParams.query;

            if (!!$stateParams.filter) {
              params['filter'] = $stateParams.filter;
            }
          }

          bifrostMappingByModule.get(
            params,
            function (resource) {
              deferred.resolve(resource);
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
              deferred.resolve([]);
            });

          return deferred.promise;
        },
        $title: function () {
          return 'Bifrost Mapping - FOC Items';
        },
        type: function () {
          return 'FOC Items Mapping';
        },
        module: function() {
          return 'foc-items-environment';
        }
      }
    })
    .state('main.user.bifrostMapping.companyCode', {
      templateUrl: 'app/bifrostMapping/manage/manage.html',
      url: '/company_code?&{query:[a-zA-Z0-9]+}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}{page:[0-9]+}',
      controller: 'bifrostMappingManageCtrl',
      resolve: {
        dataList: function ($q, $stateParams, bifrostMappingByModule) {
          var deferred = $q.defer();
          var params = {
            module: 'company-mapping-environment',
            page: $stateParams.page,
            order_by: $stateParams.order_by,
            order: $stateParams.order
          };

          if (!!$stateParams.query) {
            params['query'] = $stateParams.query;

            if (!!$stateParams.filter) {
              params['filter'] = $stateParams.filter;
            }
          }

          bifrostMappingByModule.get(
            params,
            function (resource) {
              deferred.resolve(resource);
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
              deferred.resolve([]);
            });

          return deferred.promise;
        },
        $title: function () {
          return 'Bifrost Mapping - Company Code';
        },
        type: function () {
          return 'Company Code Mapping';
        },
        module: function() {
          return 'company-mapping-environment';
        }
      }
    })
    .state('main.user.bifrostMapping.companyAccountCodes', {
      templateUrl: 'app/bifrostMapping/manage/manage.html',
      url: '/company_account_codes?&{query:[a-zA-Z0-9]+}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}{page:[0-9]+}',
      controller: 'bifrostMappingManageCtrl',
      resolve: {
        dataList: function ($q, $stateParams, bifrostMappingByModule) {
          var deferred = $q.defer();
          var params = {
            module: 'company-account-codes-environment',
            page: $stateParams.page,
            order_by: $stateParams.order_by,
            order: $stateParams.order
          };

          if (!!$stateParams.query) {
            params['query'] = $stateParams.query;

            if (!!$stateParams.filter) {
              params['filter'] = $stateParams.filter;
            }
          }

          bifrostMappingByModule.get(
            params,
            function (resource) {
              deferred.resolve(resource);
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
              deferred.resolve([]);
            });

          return deferred.promise;
        },
        $title: function () {
          return 'Bifrost Mapping - Company Account Codes';
        },
        type: function () {
          return 'Company Account Codes Mapping';
        },
        module: function() {
          return 'company-account-codes-environment';
        }
      }
    })
    .state('main.user.bifrostMapping.mappingBudgetOwner', {
      templateUrl: 'app/bifrostMapping/manage/manage.html',
      url: '/budget_owner?&{query:[a-zA-Z0-9]+}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}{page:[0-9]+}',
      controller: 'bifrostMappingManageCtrl',
      resolve: {
        dataList: function ($q, $stateParams, bifrostMappingByModule) {
          var deferred = $q.defer();
          var params = {
            module: 'budget-owner-environment',
            page: $stateParams.page,
            order_by: $stateParams.order_by,
            order: $stateParams.order
          };

          if (!!$stateParams.query) {
            params['query'] = $stateParams.query;

            if (!!$stateParams.filter) {
              params['filter'] = $stateParams.filter;
            }
          }

          bifrostMappingByModule.get(
            params,
            function (resource) {
              deferred.resolve(resource);
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
              deferred.resolve([]);
            });

          return deferred.promise;
        },
        $title: function () {
          return 'Bifrost Mapping - Budget Owner';
        },
        type: function () {
          return 'Budget Owner Mapping';
        },
        module: function() {
          return 'budget-owner-environment';
        }
      }
    });
  }]);
