'use strict';

/**
 * Main activity module controller
 *
 */
function glAccountCodeGroupDetailsCtrl($scope, company, globalFunc, searchModule, $state, companyGroupAccountCodes, $location) {
  $scope.companyDetails = company.data;
  $scope.dataList = companyGroupAccountCodes.data;
  $scope.meta = companyGroupAccountCodes.meta;
  $scope.firstLoad = false;
  $scope.showColumn = showColumn;
  $scope.back = back;
  $scope.loadAccountCodeGroup = loadAccountCodeGroup;
  $scope.listItem = listItem;
  $scope.columns = [
    {id: 'module', label: 'Module', unsearchable: true, unsortable: true},
    {id: 'group_code', label: 'Group Code', unsearchable: true, unsortable: true},
    {id: 'account_codes', label: 'Account Codes', unsearchable: true, unsortable: true}
  ];

  $scope.storageKey = 'gl-account-code-group-manage-view';

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {

        return $scope.columns[i].selected;
      }
    }
  }

  function listItem(list, firstProperty, secondProperty) {
    return _.unique(_.map(list, function (item) {
      return item[firstProperty] + ' ' + item[secondProperty] + "\n";
    })).toString();
  }

  function back() {
    $state.go('main.user.companiesMgmt.detailCompanies', {id: $scope.companyDetails._id});
  }

  function loadAccountCodeGroup(query) {
    $location.search({query: query});
  }
}

glAccountCodeGroupDetailsCtrl.$inject = ['$scope', 'company', 'globalFunc', 'searchModule', '$state',
  'companyGroupAccountCodes', '$location'];

angular.module('metabuyer')
  .controller('glAccountCodeGroupDetailsCtrl', glAccountCodeGroupDetailsCtrl);
