'use strict';
angular
  .module('metabuyer')
  .directive('editAddress',function($uibModal, toastr) {
    return{
      restrict: 'A',
      scope: {
        editAddress: "=editAddress",
        updatedAddress: "&"
      },
      link: function(scope, element) {

        var panel = function () {
          $uibModal.open({
            templateUrl: 'components/editAddress/editAddressTemplate.html',
            backdrop: 'static',
            keyboard: false,
            controller: 'editAddressCtrl',
            scope: scope
          });
        };
        //loading the panel
        scope.showEditAddress = function () {
          panel();
        };

        element.bind("click", function($event){
          scope.showEditAddress();
        });
      }
    };
  });
