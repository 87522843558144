'use strict';

function delegationAdvancedSearchCtrl($scope, searchModule, listChild, toastr, $rootScope, advancedSearch, exportSearch,
                                      metabuyerCache) {

  var searchCacheKey = 'delegationAdvancedSearch';
  var searchFields = {
    delegator: null,
    delegatee: null,
    company: null,
    startDateFrom: null,
    endDateTo: null
  };
  $scope.delegator = null;
  $scope.delegatee = null;
  $scope.company = null;
  $scope.dateRange = null;
  $scope.datePickerTimezone = $rootScope.datePickerTimezone;
  $scope.datepickerOpened = {
    dateRange: false
  };
  var dateOptions = {
    formatYear: 'yy',
    startingDay: 1,
  };
  $scope.datePickerOptions = {
    startDateFrom: _.clone(dateOptions),
    endDateTo: _.clone(dateOptions),
  };
  $scope.errors = {};
  var toastrErrorMessage = null;

  $scope.resetForm = resetForm;
  $scope.submitAdvSearch = submitAdvSearch;
  $scope.hasError = hasError;
  $scope.addSearchCriteria = addSearchCriteria;
  $scope.openDatePicker = openDatePicker;
  $scope.getDelegationStatus = getDelegationStatus;

  /**
   * function to reset the search form
   */
  function resetForm() {
    $scope.delegator = null;
    $scope.delegatee = null;
    $scope.company = null;
    $scope.startDateFrom = null;
    $scope.endDateTo = null;
    $scope.errors = {};
    $scope.dataList = [];
    metabuyerCache.remove(searchCacheKey);
  }

  /**
   * function to submit search form
   */
  function submitAdvSearch() {
    var searchParam = validateForm();
    if (!!searchParam) {
      $scope.embeddedParams = searchParam;

      /**
       * Setting the params to prepare for exporting
       */
      exportSearch.setParams(searchParam);

      advancedSearch.get(
        searchParam,
        function (resource) {
          $scope.dataList = resource.content.data;
          $scope.meta = resource.content.meta;
          $scope.emptyInput = false;
        },
        function (error) {
          toastr.error('Something went wrong');
          $scope.dataList = [];
        }
      );
    } else {
      toastr.error(toastrErrorMessage);
      toastrErrorMessage = null;
      $scope.emptyInput = true;
    }

    $('#show-search-form').removeClass('hidden');
    $('#hide-search-form').addClass('hidden');

  }

  /**
   * function to check if a specific field has error.
   * @param field field name
   * @returns {boolean}
   */
  function hasError(field) {
    return (field in $scope.errors);
  }

  /**
   * function to validate form input values
   * @returns {boolean|Object}
   */
  function validateForm() {
    //needed by date range
    if (!!$scope.endDateTo) {
      if (!!$scope.startDateFrom) {
        if ($scope.endDateTo < $scope.startDateFrom) {
          $scope.errors.dateRange = 'Invalid Range';
        }
      } else {
        $scope.errors.dateRange = '\'From\' field is mandatory';
      }
    } else if (!!$scope.createdDateFrom) {
      $scope.createdDateTo = $scope.createdDateFrom;
    }

    if (_.isEmpty($scope.errors)) {
      var searchData = {
        delegator: $scope.delegator,
        delegatee: $scope.delegatee,
        company: $scope.company,
        startDateFrom: $scope.startDateFrom,
        endDateTo: $scope.endDateTo
      };
      return buildParam(searchData);
    } else {
      toastrErrorMessage = 'Invalid value in one or more fields';
      return false;
    }
  }

  /**
   * function to set datepicker fields' options.
   * set minDate/maxDate if the related date range field is already set and remove error message
   *
   * @param $event
   * @param field
   */
  function openDatePicker($event, field) {
    $event.preventDefault();
    $event.stopPropagation();

    if (field === 'startDateFrom') {
      $scope.datePickerOptions.startDateFrom.maxDate = $scope.endDateTo || null;
      $scope.datepickerOpened.startDateFrom = true;
      delete $scope.errors.dateRange;
    } else if (field === 'endDateTo') {
      $scope.datePickerOptions.endDateTo.minDate = $scope.startDateFrom || null;
      $scope.datepickerOpened.endDateTo = true;
      delete $scope.errors.dateRange;
    }
  }

  /**
   * function to build criteria parameters
   * @param data object of form input values
   * @returns {boolean|Object}
   */
  function buildParam(data) {

    var params = {};

    if (!!data.delegator) {
      params['criteria[0][user_display_name]'] = data.delegator;
    }
    if (!!data.company) {
      params['criteria[0][company_descr]'] = data.company;
    }
    if (!!data.delegatee) {
      params['criteria[0][delegatee_display_name]'] = data.delegatee;
    }
    if (!!data.startDateFrom) {
      params['criteria[0][start_date][from]'] = moment(data.startDateFrom).valueOf();
    }
    if (!!data.endDateTo) {
      params['criteria[0][end_date][to]'] = moment(data.endDateTo).endOf('day').valueOf();
    }
    if (_.isEmpty(params)) {
      toastrErrorMessage = 'Fill in at least one field';
      return false;
    } else {

      params['module'] = 'delegation';
      params['offset'] = 50;
      params['criteria[0][$operator]'] = 'and';
      return params;
    }
  }

  $scope.columns = [
    {id: 'creator_display_name', label: 'Creator', selected: true},
    {id: 'user_display_name', label: 'Delegator', selected: true},
    {id: 'delegatee_display_name', label: 'Delegatee', selected: true},
    {id: 'company_code', label: 'Company Code', selected: true},
    {id: 'company_descr', label: 'Company Name', selected: true},
    {id: 'created_at', label: 'Created At', selected: true},
    {id: 'start_date', label: 'Start Date', selected: true},
    {id: 'end_date', label: 'End Date', selected: true},
    {id: 'reason', label: 'Reason', selected: true},
    {id: 'status', label: 'Status', selected: true}
  ];

  function addSearchCriteria(key, value) {

    var tempSearchCriteriaCache = metabuyerCache.get(searchCacheKey);

    if (!!tempSearchCriteriaCache)
      searchFields = _.cloneDeep(tempSearchCriteriaCache);

    searchFields[key] = value;
    metabuyerCache.put(searchCacheKey, searchFields);

  }

  function initialize() {
    // initialize pagination parameters
    $scope.dataList = [];
    $scope.embeddedParams = [];
    $scope.meta = {};
    $scope.searchModule = advancedSearch;

    var searchCriteriaCache = metabuyerCache.get(searchCacheKey);
    if (!!searchCriteriaCache && !_.isEmpty(searchCriteriaCache)) {
      retainSearchCriteria();
    }
  }

  function getDelegationStatus(status) {
      switch (status) {
          case 0:
              return 'Planned';
          case 1:
              return 'Active';
          case 2:
              return 'Cancelled';
          case 3:
              return 'Finished';
          case 4:
              return 'Pending';
          case 5:
              return 'Rejected';
      }
  }

  function retainSearchCriteria() {

    var searchCriteriaCache = metabuyerCache.get(searchCacheKey);
    $scope.delegator = searchCriteriaCache.delegator;
    $scope.delegatee = searchCriteriaCache.delegatee;
    $scope.company = searchCriteriaCache.company;
    $scope.startDateFrom = searchCriteriaCache.startDateFrom;
    $scope.endDateTo = searchCriteriaCache.endDateTo;
  }

  initialize();
}

delegationAdvancedSearchCtrl.$inject = [
  '$scope', 'searchModule', 'listChild', 'toastr', '$rootScope', 'advancedSearch', 'exportSearch', 'metabuyerCache'
];

angular.module('metabuyer')
  .controller('delegationAdvancedSearchCtrl', delegationAdvancedSearchCtrl);
