'use strict';
/**
 * @name tenderCtrl
 * @desc Controller for tenders
 */
function tenderCtrl($scope, $state, Users, profile, $rootScope, UserPermissions, globalFunc) {
  $scope.$emit('pageTitleChanged', 'Tenders');

  var tempState = _.cloneDeep($state.params);

  $scope.tabData = [{
      heading: 'All',
      route: 'main.user.tender.manage',
      key: 'all',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'all',
        tab: 'yours',
        extraFilterKey: '[creator_info|_id][]',
        extraFilterValue: $rootScope.currentUser._id,
        cursor: null
      })
    },
    {
      heading: 'Draft',
      route: 'main.user.tender.manage',
      key: 'draft',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'draft',
        tab: 'yours',
        extraFilterKey: '[creator_info|_id][]',
        extraFilterValue: $rootScope.currentUser._id,
        cursor: null
      })
    },
    {
      heading: 'Pending',
      route: 'main.user.tender.manage',
      key: 'pending',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'pending',
        tab: 'waiting',
        extraFilterKey: '[waiting_on][]',
        extraFilterValue: $rootScope.currentUser._id,
        cursor: null
      })
    },
    {
      heading: 'Approved',
      route: 'main.user.tender.manage',
      key: 'approved',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'approved',
        tab: 'yours',
        extraFilterKey: '[creator_info|_id][]',
        extraFilterValue: $rootScope.currentUser._id,
        cursor: null
      })
    },
    {
      heading: 'Expired',
      route: 'main.user.tender.manage',
      key: 'expired',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'expired',
        tab: 'yours',
        extraFilterKey: '[creator_info|_id][]',
        extraFilterValue: $rootScope.currentUser._id,
        cursor: null
      })
    },
    {
      heading: 'Manage Tender Group',
      route: 'main.user.tender.group.manage',
      key: 'group',
      cursor: null,
      filter: null,
      query: null,
      type: null
    }
  ];

  $scope.tabData.more = [{
      heading: 'Deactivated',
      route: 'main.user.tender.manage',
      key: 'deactivated',
      type: 'more',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'deactivated',
        tab: 'yours',
        extraFilterKey: '[creator_info|_id][]',
        extraFilterValue: $rootScope.currentUser._id,
        cursor: null
      })
    },
    {
      heading: 'Deleted',
      route: 'main.user.tender.manage',
      key: 'deleted',
      type: 'more',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'deleted',
        tab: 'yours',
        extraFilterKey: '[creator_info|_id][]',
        extraFilterValue: $rootScope.currentUser._id,
        cursor: null
      })
    },
    {
      heading: 'Withdrawn',
      route: 'main.user.tender.manage',
      key: 'withdrawn',
      type: 'more',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'withdrawn',
        tab: 'yours',
        extraFilterKey: '[creator_info|_id][]',
        extraFilterValue: $rootScope.currentUser._id,
        cursor: null
      })
    },
    {
      heading: 'Starred',
      route: 'main.user.tender.manage',
      key: 'starred',
      type: 'more',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'starred',
        tab: 'yours',
        extraFilterKey: '[creator_info|_id][]',
        extraFilterValue: $rootScope.currentUser._id,
        cursor: null
      })
    },
    {
      heading: 'Rejected',
      route: 'main.user.tender.manage',
      key: 'rejected',
      type: 'more',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'rejected',
        tab: 'yours',
        extraFilterKey: '[creator_info|_id][]',
        extraFilterValue: $rootScope.currentUser._id,
        cursor: null
      })
    }
  ];

  /**
   * making sure the Stats and placeHolder changes if the Child scope changed
   */
  $scope.$on('$stateChangeStart', function(event, data, toParams) {

    if (!!data.name && data.name.indexOf('group') > -1) {
      $scope.searchState = 'main.user.tender.group.manage';
      $scope.searchPlaceholder = 'Search Tender Groups';
      $scope.parentState = 'main.user.tender.group.manage';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[5];
      $scope.columns = tenderGroupColums;
    } else {
      $scope.searchState = 'main.user.tender.manage';
      $scope.searchPlaceholder = 'Search Tenders';
      $scope.parentState = 'main.user.tender.manage';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[0];
      $scope.columns = tenderColums;
    }

    if (!!toParams.status) {
      $scope.status = toParams.status;
      $scope.parentStateParams = toParams;
    }
  });

  var tenderColums = [
    { id: 'star', label: 'FAVORITE', unsortable: true, unsearchable: true, unSelectable: true },
    { id: 'code', label: 'TENDER NUMBER' },
    { id: 'detail|name', label: 'TENDER NAME' },
    { id: 'detail|description', label: 'TENDER DESCRIPTION' },
    {
      id: 'waiting_on',
      label: 'WAITING ON',
      conditional: true,
      condition: ['pending'],
      unsortable: true,
      unsearchable: true
    },
    { id: 'detail|total_amount', label: 'TENDER AMOUNT' },
    { id: 'detail|contingency_sum', label: 'CONTINGENCY SUM', unsearchable: true },
    { id: 'detail|type', label: 'TENDER TYPE' },
    {
      id: 'progress',
      label: 'PROGRESS',
      conditional: true,
      condition: ['pending', 'approved'],
      unsortable: true,
      unsearchable: true
    },
    { id: 'detail|category', label: 'TENDER CATEGORY' },
    { id: 'detail|validity_start_date', label: 'START DATE', type: 'date' },
    { id: 'detail|validity_end_date', label: 'END DATE', type: 'date' },
    { id: 'detail|approved_date', label: 'APPROVED DATE', unsearchable: true },
    { id: 'detail|submission_date', label: 'SUBMITTED DATE', unsearchable: true },
    { id: 'groups|code', label: 'TENDER GROUP' },
    { id: 'suppliers|supplier_id', label: 'SUPPLIERS' },
    { id: 'detail|catalog_required', label: 'CATALOG REQUIRED' },
    { id: 'detail|status', label: 'STATUS', unsearchable: true }
  ];

  var tenderGroupColums = [
    { id: 'code', label: 'CODE' },
    { id: 'description', label: 'DESCRIPTION' },
    { id: 'creator_info.display_name', label: 'CREATED BY' },
    { id: 'created_at', label: 'CREATED AT' },
    { id: 'updater_info.display_name', label: 'UPDATED BY' },
    { id: 'updated_at', label: 'UPDATED AT' },
    { id: 'status', label: 'STATUS' },
  ];

  function initialize() {

    if (!!$state.params && !!$state.params.status) {
      $scope.status = $state.params.status;
      var tempIndex = _.findIndex($scope.tabData, function(tab) { return tab.key === $state.params.status.toLowerCase() });
      if (tempIndex < 0 && !$rootScope.isMobileMode) {
        tempIndex = _.findIndex($scope.tabData.more, function(tab) {
          return tab.key === $state.params.status.toLowerCase()
        });
        if (!!tempIndex)
          $scope.activatedTab = $scope.tabData.more[tempIndex];
      } else
        $scope.activatedTab = $scope.tabData[tempIndex];
    }

    $scope.advancedSearchState = 'main.user.advancedSearch.tender';

    if (!!$state.current.name && $state.current.name.indexOf('group') > -1) {
      $scope.searchState = 'main.user.tender.group.manage';
      $scope.searchPlaceholder = 'Search Tender Groups';
      $scope.parentState = 'main.user.tender.group.manage';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[5];
      $scope.columns = tenderGroupColums;
    } else {
      $scope.searchState = 'main.user.tender.manage';
      $scope.searchPlaceholder = 'Search Tenders';
      $scope.parentState = 'main.user.tender.manage';
      $scope.parentStateParams = $state.params;
      $scope.columns = tenderColums;
    }

    if (!!$state.params && !!$state.params.status) {
      $scope.status = $state.params.status;
    }
    if (!!$state.params && !!$state.params.filter) {
      $scope.searchFilter = $state.params.filter;
    }

    if (!!$state.params && !!$state.params.query) {
      $scope.searchText = $state.params.query;
    } else {
      $scope.searchText = '';
    }
  }

  initialize();
}
tenderCtrl.$inject = ['$scope', '$state', 'Users', 'profile', '$rootScope', 'UserPermissions', 'globalFunc'];

angular
  .module('metabuyer')
  .controller('tenderCtrl', tenderCtrl);
