'use strict';
/**
 * @name addSupplierDraftAttachmentCtrl
 *
 * @description
 * Controller for add attachment for Supplier
 *
 * @copyright Sunway Metacloud &copy; 2019
 */

function addSupplierDraftAttachmentCtrl(
  $rootScope, $scope, globalFunc, $uibModalInstance, $http, $filter, pathConstants, draftId, toastr
) {

  $scope.attachment           = {};
  $scope.fileSizeLimit        = 20;//in MB

  $scope.close                = close;
  $scope.saveAttachment       = saveAttachment;
  $scope.isSaving             = false;
  $scope.draftId              = draftId;
  function close() {
    $uibModalInstance.close();
  }

  /**
   * Save the attachment
   */
  function saveAttachment() {
    if($scope.isSaving){
      return;
    }
    if(exceedSizeLimit()){
      toastr.error('Maximum File Size is 20MB');
      return;
    }
    $scope.isSaving = true;
    var file = $scope.attachment.file._file;
    $scope.attachment.file._file.title = file.name;
    $scope.attachment.file._file.uploaded_at = file.lastModified;
    $scope.attachment.file._file.uploaded_by = $rootScope.currentUser.display_name;
    if (!!$scope.draftId) {
      uploadAttachment($scope.attachment.file)
        .then(
          function (resource) {
            if (!!resource && !!resource.data && !!resource.data.content && !!resource.data.content.data) {
              var res = resource.data.content.data;
              var addedAttachment = {
                attachment_id: res._id,
                title: res.title,
                uploaded_by: res.creator.display_name,
                uploaded_at: res.created_at
              };
              $uibModalInstance.close(addedAttachment);
            } else {
              $uibModalInstance.close($scope.attachment.file._file);
            }
            toastr.success('Attachment uploaded successfully');
            $scope.isSaving = false;
          },
          function (error) {
            globalFunc.objectErrorMessage(error);
            $scope.isSaving = false;
          }
        );
    } else {
      $uibModalInstance.close($scope.attachment.file._file);
    }
  }

  /**
   * Upload attachment
   *
   * @param {object} attachmentFile
   */
  function uploadAttachment(attachmentFile) {
    var fd = new FormData();
    fd.append('file[]', attachmentFile._file);
    fd.append('model', 'Supplier');
    return $http.post(
      $filter('format')(pathConstants.apiUrls.supplier.newDraftAttachment.add, {draft_id: $scope.draftId}), fd, {
        transformRequest: angular.identity,
        headers: {'Content-Type': undefined}
      }
    );
  }

  function exceedSizeLimit(){
    return $scope.attachment.file.size > ($scope.fileSizeLimit * 1000 * 1000);
  }
}
addSupplierDraftAttachmentCtrl.$inject = [
  '$rootScope', '$scope', 'globalFunc', '$uibModalInstance', '$http', '$filter', 'pathConstants', 'draftId', 'toastr'
];

angular
  .module('metabuyer')
  .controller('addSupplierDraftAttachmentCtrl', addSupplierDraftAttachmentCtrl);
