'use strict';
/**
 * @name unauthorizedBudgetAccessCtrl
 * @desc Controller for displaying page for unauthorized access to budget module
 * @author H.W. Liew <liewhanwahwork@gmail.com>
 * @copyright 2017 Metacloud Sdn. Bhd.
 */
function unauthorizedBudgetAccessCtrl($scope, $rootScope, $state) {

  //applying CSS for the page on the controller level
  $rootScope.bodyClasses = 'gray-bg';
}

unauthorizedBudgetAccessCtrl.$inject = ['$scope', '$rootScope', '$state'];

angular
  .module('metabuyer')
  .controller('unauthorizedBudgetAccessCtrl', unauthorizedBudgetAccessCtrl);
