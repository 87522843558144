'use strict';

/**
 *
 * @param $scope
 * @param $uibModalInstance
 * @param $http
 * @param pathConstants
 * @param $filter
 * @param toastr
 * @param specialEvent
 * @param specialEventServices
 * @param globalFunc
 */
function detailSpecialEvent($scope, $uibModalInstance, $http, pathConstants, $filter, toastr, specialEvent,
                            specialEventServices, globalFunc) {
  $scope.editClicked = false;
  $scope.specialEvent = specialEvent.data;
  $scope.tab = 1;
  $scope.cancel = cancel;
  $scope.editSpecialEvent = editSpecialEvent;
  $scope.updateSpecialEvent = updateSpecialEvent;

  function cancel() {
    $uibModalInstance.close();
  }

  function editSpecialEvent() {
    $scope.editClicked = true;
  }

  function updateSpecialEvent() {
    specialEventServices.put(
      {
        id: $scope.specialEvent._id
      },
      {
        descr: $scope.specialEvent.descr
      },
      function (resource) {
        toastr.success('Special Event data has been updated');
        $uibModalInstance.close(resource.data);
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
        return false;
      }
    );
  }
}

detailSpecialEvent.$inject = ['$scope', '$uibModalInstance', '$http', 'pathConstants', '$filter', 'toastr', 'specialEvent',
  'specialEventServices', 'globalFunc'];

angular
  .module('metabuyer')
  .controller('detailSpecialEvent', detailSpecialEvent);



