'use strict';

function taxDetailsCtrl($scope, $uibModalInstance, taxDetails, $state, globalFunc,
                        taxesMasterDataList, $filter, toastr, $timeout, $rootScope) {

  $scope.editClicked = false;
  $scope.taxDetails = taxDetails.data;
  $scope.originalCode = $scope.taxDetails.code;
  $scope.countryCode = globalFunc.countryCode();

  $scope.tab = 1;
  $scope.historyDependencies = {
    embeddedParams: {
      'object[0][object_id]': $scope.taxDetails._id,
      'object[0][object_type]': 'Metabuyer\\Models\\Tax',
      offset: 5,
      order_by: '-created_at' // Created at, descending
    }
  };

  $scope.toggleTab        = toggleTab;
  $scope.duplicationCheck = duplicationCheck;
  $scope.cancel           = cancel;
  $scope.editTax          = editTax;
  $scope.updateTax        = updateTax;
  $scope.openDatepicker   = openDatepicker;

  function toggleTab(tab) {
    $scope.tab = tab;
  }

  function cancel() {
    $uibModalInstance.close();
  }

  function duplicationCheck(model, field, value, checker) {
    globalFunc.duplicationCheck(model, field, value, checker, function (callback) {
      switch (callback) {
        case 'codeCheckTrue':
          if ($scope.taxDetails.code != $scope.originalCode) {
            $scope.codeCheck = true;
          }
          else {
            $scope.codeCheck = false;
          }
          break;
        case 'codeCheckFalse':
          $scope.codeCheck = false;
          break;
      }
    });
  }

  function openDatepicker($event, which) {

    /**
     * Setting minimum selection for start date to be today
     */
    $scope.startDateOptions.minDate = new Date();

    /**
     * Setting the minimum selection for end date to be one day ahead of selected start date
     */
    var startDateClone = _.cloneDeep($scope.taxDetails.effective_at);
    var startDate = new Date(startDateClone);
    startDate.setDate(startDate.getDate() + 1);
    $scope.endDateOptions.minDate = startDate;

    if ($scope.datepickerOpened[which]) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    else {
      $timeout(function () {
        $scope.datepickerOpened[which] = true;
      })
    }
  }

  function editTax() {
    $scope.editClicked = true;
  }

  function validate(newTax) {
    if (!newTax) {
      return false;
    }
    if (!newTax.code) {
      return false;
    }
    if (!newTax.descr) {
      return false;
    }
    if (newTax.rate < 0) {
      return false;
    }
    if (!newTax.selectedCountryCode) {
      return false;
    }
    if (!newTax.effective_at) {
      return false;
    }
    if (!newTax.expiry_at) {
      return false;
    }
    if (newTax.effective_at >= newTax.expiry_at) {
      return false;
    }

    return true;
  }

  function updateTax(taxDetails) {
    $scope.submitted = true;
    if (!!$scope.codeCheck) {
      toastr.error(taxDetails.code + ' is already in use');
    }
    else if (validate(taxDetails)) {

      swal({
          title: 'Confirm Updating the Tax',
          text: 'This will update the details for this Tax',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#1ab394',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true,
          closeOnCancel: true
        },
        function (confirmed) {
          if (!!confirmed) {

            // set status in integer to pass it as parameter
            var tempStatus;
            switch (taxDetails.taxStatus.isActive.toLowerCase()) {
              case 'active':
                tempStatus = 1;
                break;
              case 'deactivated':
                tempStatus = 0;
                break;
              case 'disabled':
                tempStatus = 3;
                break;
            }

            var submitData = {
              code: taxDetails.code,
              descr: taxDetails.descr,
              rate: taxDetails.rate,
              country_code: taxDetails.selectedCountryCode.descr,
              effective_at: globalFunc.convertDateToTimestamp(taxDetails.effective_at),
              expiry_at: globalFunc.convertDateToTimestamp(taxDetails.expiry_at),
              is_active: tempStatus
            };

            taxesMasterDataList.put(
              {
                id: $scope.taxDetails._id
              },
              submitData,
              function (resource) {
                toastr.success('Tax has been updated');

                $uibModalInstance.close(resource.content.data);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                return false;
              }
            );
          } else {
            return false;
          }
        }
      );
    }
    else {
      toastr.error('Please fill up all the required fields first');
    }
  }

  function initialize() {
    if (!!$scope.taxDetails.is_active) {
      $scope.taxDetails.taxStatus = {isActive: globalFunc.capitalizeFirstCharacter($scope.taxDetails.is_active)};
    }

    $scope.taxStatuses = [
      {isActive: 'Active'},
      {isActive: 'Deactivated'}
    ];

    $scope.startDateOptions = {
      formatYear: 'yy',
      startingDay: 1
    };

    $scope.endDateOptions = {
      formatYear: 'yy',
      startingDay: 1
    };
    $scope.datepickerOpened = {
      start_date: false,
      end_date: false
    };

    if (!!$scope.countryCode) {
      var temp = _.find($scope.countryCode, function (item) {
        return $scope.taxDetails.country_code === item.descr
      });

      if (!!temp) {
        $scope.taxDetails.selectedCountryCode = temp;
      }
    }
  }

  initialize();
}

taxDetailsCtrl.inject = [
  '$scope', '$uibModalInstance', 'taxDetails', '$state', 'globalFunc', 'taxesMasterDataList',
  '$filter', 'toastr', '$timeout', '$rootScope'
];

angular
  .module('metabuyer')
  .controller('taxDetailsCtrl', taxDetailsCtrl);
