'use strict';
/**
 * @name approvalFlowCtrl
 * @desc Controller for Approval Flow Management Parent Controller
 */
function PRPOSettingsCtrl ($scope) {

  $scope.tabData = [
    {
      heading: 'Company Prefix',
      route: 'main.user.settings.PRPOSettings.prefix'
    }
  ];
}
PRPOSettingsCtrl.$inject = ['$scope'];

angular
  .module('metabuyer')
  .controller('PRPOSettingsCtrl', PRPOSettingsCtrl);
