'use strict';

/**
 * @name MasterDataManageStockInformationGroupsCtrl
 * @desc Controller for master data stock information groups controller
 */
function MasterDataManageStockInformationGroupsCtrl($scope, dataList, stockInformationGroupMasterDataList, toastr,
                                                    $uibModal, $location, pathConstants, globalFunc, $stateParams,
                                                    searchModule, $rootScope) {
  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.stockInformationGroupMasterDataList = stockInformationGroupMasterDataList;
  $scope.addNewStockInformationGroup = addNewStockInformationGroup;
  $scope.openStockInformationGroupDetails = openStockInformationGroupDetails;
  $scope.search = search;
  $scope.showColumn = showColumn;
  $scope.importStockInformationGroup = importStockInformationGroup;
  $scope.params = {};
  $scope.stockInformationGroup = {};
  $scope.convertBoolean = convertBoolean;
  $scope.getActiveStatusName = getActiveStatusName;
  $scope.selectCompany = selectCompany;
  $scope.selectedCompanies = [];
  $scope.submitted = false;

  $scope.columns = [
    {id: 'code', label: 'Code'},
    {id: 'name', label: 'Name'},
    {id: 'descr', label: 'Description'},
    {id: 'is_active', label: 'Status'},
    {id: 'updated_at', label: 'Updated At'},
    {id: 'updater_info.display_name', label: 'Updated By'},
    {id: 'created_at', label: 'Created At'},
    {id: 'creator_info.display_name', label: 'Created By'}
  ];

  $scope.storageKey = 'master-data-stock-information-groups-management-selected-columns';

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional) {
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function importStockInformationGroup() {
    $uibModal.open({
      templateUrl: 'app/masterDataManagement/Import-Template.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        importLink: function () {
          return pathConstants.apiUrls.imports.now;
          ;
        },
        params: function () {
          return {
            'class': 'App\\Metabuyer\\StockInformationGroup\\Models\\StockInformationGroup',
          }
        },

        title: function () {
          return 'Stock Information Group';
        }
      },
      controller: function ($scope, $uibModalInstance, importLink, title, params) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        $scope.importLink = importLink;
        $scope.title = title;
        $scope.params = params;
      }
    });
  }

  function addNewStockInformationGroup() {
    $scope.submitted = true;
    if (!validate($scope.stockInformationGroup)) {
      return;
    }

    var companyCodes = $scope.selectedCompanies.map(function (value, key) {
      return value.code;
    });

    stockInformationGroupMasterDataList.post(
      {
        code: $scope.stockInformationGroup.code,
        name: $scope.stockInformationGroup.name,
        descr: $scope.stockInformationGroup.descr,
        company_codes: companyCodes
      },
      function (resource) {
        toastr.success('A new Stock Information Group has been added successfully');
        var addedStockInformationGroup = resource.content.data;
        $scope.dataList.unshift({
          _id: addedStockInformationGroup._id,
          uuid: addedStockInformationGroup.uuid,
          code: addedStockInformationGroup.code,
          name: addedStockInformationGroup.name,
          descr: addedStockInformationGroup.descr,
          created_at: addedStockInformationGroup.created_at,
          updated_at: addedStockInformationGroup.updated_at,
          creator_info: addedStockInformationGroup.creator_info,
          updater_info: addedStockInformationGroup.updater_info
        });

        $scope.submitted = false;
        $scope.selectedCompanies = [];
        $scope.stockInformationGroup.code = '';
        $scope.stockInformationGroup.name = '';
        $scope.stockInformationGroup.descr = '';
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function search(query) {
    $location.search({query: query});
  }

  function validate(stockInformationGroup) {
    if (!stockInformationGroup) {
      return false;
    }
    if (!stockInformationGroup.code) {
      return false;
    }
    if ($scope.selectedCompanies.length === 0) {
      return false;
    }
    if (!stockInformationGroup.name) {
      return false;
    }

    return true;
  }
  function openStockInformationGroupDetails(index) {
    var stockInformationGroupUuid = $scope.dataList[index].uuid;
    var modalInstance = $uibModal.open({
      templateUrl: 'app/masterDataManagement/stockInformationGroups/details/details.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'stockInformationGroupDetailsCtrl',
      size: 'lg',
      resolve: {
        stockInformationGroupDetails: function ($q, stockInformationGroupMasterDataList) {
          var deferred = $q.defer();
          stockInformationGroupMasterDataList.get(
            {
              uuid: stockInformationGroupUuid
            },
            function (resource) {
              deferred.resolve(resource.content || {data: []});
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        },
        companies: function () {
          return $scope.companies;
        }
      }
    });

    modalInstance.result.then(
      function (newValue) {
        if (!!newValue) {
          _.forEach($scope.dataList, function (dataList) {
            if (dataList._id === newValue._id) {
              dataList.code = newValue.code;
              dataList.name = newValue.name;
              dataList.descr = newValue.descr;
              dataList.is_active = newValue.is_active;
              dataList.updater_info.display_name = newValue.updater_info.display_name;
              dataList.updated_at = newValue.updated_at;
            }
          });
        }
      }, function () {
      }
    );
  }

  function convertBoolean(value) {
    return value === true ? 'Yes' : value === false ? 'No' : value;
  }

  function getActiveStatusName(value) {
    return !value ? 'Deactivated' : 'Active';
  }

  //region Company assignment
  function loadCompanies() {
    $scope.loadingCompaniesList = true;
    searchModule.get(
      {
        module: 'companies',
        offset: 0
      },
      function (resource) {
        $scope.loadingCompaniesList = false;
        if (!!resource.content && !!resource.content.data) {
          $scope.companies = resource.content.data;
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
        $scope.loadingCompaniesList = false;
      }
    );
  }

  function selectCompany(company, action) {
    $scope.submitted = false;
    if (action === 'assign') {
      $scope.selectedCompanies.push(company);
    }
    else {
      $scope.selectedCompanies = globalFunc.removeValueFromArray($scope.selectedCompanies, '_id', company._id);
    }
  }
  //endregion

  function initialize() {
    /**
     * setting the model with the searched text
     */
    if (!!$stateParams.search) {
      $scope.searchText = $stateParams.search;
    }
    else {
      $scope.searchText = '';
    }

    $scope.selectedCompanies = [];
    loadCompanies();
  }

  initialize();
}

MasterDataManageStockInformationGroupsCtrl.$inject = [
  '$scope', 'dataList', 'stockInformationGroupMasterDataList', 'toastr', '$uibModal',
  '$location', 'pathConstants', 'globalFunc', '$stateParams', 'searchModule', '$rootScope'
];

angular
  .module('metabuyer')
  .controller('MasterDataManageStockInformationGroupsCtrl', MasterDataManageStockInformationGroupsCtrl);
