'use strict';
/**
 * @name itemMasterDetailsCtrl
 * @desc Controller for master data ItemMaster details controller
 */
function itemMasterDetailsCtrl($scope, $uibModalInstance, itemMasterDetails,
                                itemMasterMasterDataList, searchModule, globalFunc, $filter, toastr, $http, pathConstants, $rootScope) {
  $scope.editClicked = false;
  $scope.getItemCategoryList = getItemCategoryList;
  $scope.onSelectItemCategory = onSelectItemCategory;
  $scope.itemMasterDetails = itemMasterDetails.data;
  $scope.itemCategoryListing = itemCategoryListing;

  $scope.itemMasterStatuses = [
    { isActive: 'Active', value: true },
    { isActive: 'Deactivated', value: false }
  ];

  $scope.itemMasterIsPCM = [
    { isPcm: 'Yes', value: true },
    { isPcm: 'No', value: false }
  ];

  $scope.originalCode = $scope.itemMasterDetails.code;
  $scope.tab = 1;
  $scope.historyDependencies = {
    embeddedParams: {
      'object[0][object_id]': $scope.itemMasterDetails._id,
      'object[0][object_type]': 'App\\Metabuyer\\ItemMaster\\Models\\ItemMaster',
      offset: 5,
      order_by: '-created_at' // Created at, descending
    }
  };

  $scope.toggleTab         = toggleTab;
  $scope.cancel            = cancel;
  $scope.editItemMaster   = editItemMaster;
  $scope.updateItemMaster = updateItemMaster;
  $scope.duplicationCheck  = duplicationCheck;

  function cancel() {
    $uibModalInstance.close();
  }

  function toggleTab(tab) {
    return $scope.tab = tab;
  }

  function editItemMaster(){
    $scope.editClicked = true;
  }

  function validate(itemMasterDetails){
    if (!itemMasterDetails){
      return false;
    }
    if (!itemMasterDetails.code){
      return false;
    }
    if (!itemMasterDetails.name){
      return false;
    }
    if (!itemMasterDetails.isPCM){
      return false;
    }
    if (!itemMasterDetails.itemMasterStatus){
      return false;
    }
    return true;
  }

  function updateItemMaster(itemMasterDetails) {
    if (!!$scope.codeCheck) {
      toastr.error(itemMasterDetails.code + ' is already in use');
    }
    else if(validate($scope.itemMasterDetails)){
      swal({
          title: 'Confirm Updating the Item master',
          text: 'This will update the details for this Item master',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#1ab394',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true,
          closeOnCancel: true
        },
        function (confirmed) {
          if (!!confirmed) {
            var submitData = {
              is_pcm: itemMasterDetails.isPCM.value,
              item_category_code : itemMasterDetails.item_category.code,
              is_active: itemMasterDetails.itemMasterStatus.value
            };

            itemMasterMasterDataList.put(
              {
                id: $scope.itemMasterDetails._id
              },
              submitData,
              function (resource) {
                toastr.success('Item master has been updated');
                $uibModalInstance.close(resource.content.data);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                return false;

              }
            );
          } else {
            return false;
          }
        }
      );
    }
  }

  /**
   * @function duplicationCheck
   * Calls the global function's duplication check
   * Checks callback function return for assigning scope values for validation
   **/
  function duplicationCheck(model,field,value,checker){
    globalFunc.duplicationCheck(model,field,value,checker, function(callback){
      switch (callback){
        case 'codeCheckTrue':
          if($scope.itemMasterDetails.code != $scope.originalCode){
          $scope.codeCheck = true;
          }
          else{
            $scope.codeCheck = false;
          }
          break;
        case 'codeCheckFalse':
          $scope.codeCheck = false;
          break;
      }
    });
  }

  /**
   * returns the is_active name
   * @param code
   * @returns {*}
   */
  function getActiveStatusName(code){
    return code === true ? 'Active' : code === false ? 'Deactivated' : code;
  }

  function getPcmStatus(code){
    return code === true ? 'Yes' : code === false ? 'No' : code;
  }

  function onSelectItemCategory(item) {
    $scope.itemMasterDetails.item_category = item;
  };

  function getItemCategoryList(){
    searchModule.get(
      {
        module: 'item-category',
        offset: 0,
        'criteria[0][is_active]': true
      },
      function(resource){
        $scope.itemCategoryList = resource.content.data;
      },
      function(error){
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function itemCategoryListing(query){
    var moduleParam = {
      module: 'item-category'
    };
    var additionalParams = {
      'criteria[0][is_active]': true,
      'criteria[1][name]': query,
      criteria_operator: 'and',
      offset: 10
    };
    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams,
      ignoreLoadingBar: true
    }).then(function (response) {
      if (response.data.content.data.length === 0) {
        return [];
      } else {
        return response.data.content.data.map(function (item) {
          return item;
        });
      }
    });
  }

  function initialize() {
    $scope.itemMasterDetails.itemMasterStatus = {isActive: getActiveStatusName($scope.itemMasterDetails.is_active), value: $scope.itemMasterDetails.is_active};
    $scope.itemMasterDetails.isPCM = {isPcm: getPcmStatus($scope.itemMasterDetails.is_pcm), value: $scope.itemMasterDetails.is_pcm};

    getItemCategoryList();
  }

  initialize();
}

itemMasterDetailsCtrl.$inject = [
  '$scope', '$uibModalInstance', 'itemMasterDetails', 'itemMasterMasterDataList', 'searchModule', 'globalFunc',
  '$filter', 'toastr', '$http', 'pathConstants', '$rootScope'
];

angular
  .module('metabuyer')
  .controller('itemMasterDetailsCtrl', itemMasterDetailsCtrl);
