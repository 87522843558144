'use strict';

angular.module('metabuyer.services.catalogsServices', ['ngResource'])
.factory('checkImportCatalogsV2', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.catalogsV2.checkImport,
      {
        supplier_id: '@supplier_id',
        catalog_code_id: '@catalog_code_id',
        catalog_group_id: '@catalog_group_id'
      },
      {
        'post': {method: 'POST'}
      }
    );
})
.factory('updateStatusCatalogsV2', function ($resource, pathConstants) {
    return $resource(
        pathConstants.apiUrls.catalogsV2.updateStatus,
        {
          id: '@id',
          status: '@status'
        },
        {
          'put': {method: 'PUT'}
        }
    );
})
.factory('downloadCatalogV2Attachment', function ($resource, pathConstants) {
  return $resource(
      pathConstants.apiUrls.catalogsV2.download,
      {
        catalog_id: '@referenceId',
        attachment_id: '@attachmentId'
      },
      {
        'get': {method: 'GET'}
      }
  );
})
.factory('resendPendingCatalogEmail', function ($resource, pathConstants) {
  return $resource(
      pathConstants.apiUrls.catalogsV2.resendPendingCatalogEmail,
      {
        id: '@id'
      },
      {
        'post': {method: 'POST'}
      }
  );
});
