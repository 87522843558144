'use strict';

/**
 * @name prVersionCtrl
 * @desc The controller for PR version
 */
function alcVersionCtrl($scope, currentVersion, previousVersion, $uibModalInstance, versionNumber) {

  $scope.version           = versionNumber;
  $scope.currentAlc        = _.cloneDeep(currentVersion);
  $scope.prevAlc           = _.cloneDeep(previousVersion);
  $scope.alcGroupCompanies = [];
  $scope.close             = close;

  function close() {
    $uibModalInstance.close();
  }

  function getAlcGroupCompaniesData() {
    $scope.alcGroupCompanies = $scope.currentAlc.companies;

    // Add added flag to new alc's companies
    _.forEach($scope.alcGroupCompanies, function(e){
      e.added = true;
    })

    // combine the previous list and current list without duplicates and add added/removed flag
    _.forEach($scope.prevAlc.companies, function(prevCompany) {
      var company = _.find($scope.alcGroupCompanies, function(e) {
        if (e.code === prevCompany.code) {
          e.added = false;
          return true
        }
      });

      if (_.isEmpty(company)) {
        prevCompany.removed = true;
        $scope.alcGroupCompanies.push(prevCompany);
      }
    });
  }
  function intialize() {
    getAlcGroupCompaniesData();
  }

  intialize();
}

alcVersionCtrl.$inject = ['$scope', 'currentVersion', 'previousVersion', '$uibModalInstance', 'versionNumber', 'globalFunc'];

angular.module('metabuyer')
  .controller('alcVersionCtrl', alcVersionCtrl);
