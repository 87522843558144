'use strict';
/**
 * @name approvalFlowCtrl
 * @desc Controller for Approval Flow Management Parent Controller
 */
function settingsCtrl ($scope) {

  $scope.$emit('pageTitleChanged', 'Settings');
  $scope.$emit('actionsChanged', []);
}
settingsCtrl.$inject = ['$scope'];

angular
  .module('metabuyer')
  .controller('settingsCtrl', settingsCtrl);
