'use strict';

angular
  .module('metabuyer')
  .directive('icheck', ['$timeout',
    function ($timeout) {
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, element, $attrs, ngModel) {
          return $timeout(function () {
            var value;
            value = $attrs['value'];

            $scope.$watch($attrs['ngModel'], function (newValue) {
              $(element).iCheck('update');
            });

            $(element).iCheck({
              checkboxClass: 'icheckbox_minimal-grey',
              radioClass: 'iradio_square-green'
            }).on('ifChanged', function (event) {
              if ($(element).attr('type') === 'checkbox' && $attrs['ngModel']) {
                $scope.$apply(function () {
                  ngModel.$setViewValue(event.target.checked);
                  $scope.$eval($attrs.ngChange);
                  $scope.$eval($attrs.ngClick);
                });
              }
              if ($(element).attr('type') === 'radio' && $attrs['ngModel']) {
                return $scope.$apply(function () {
                  ngModel.$setViewValue(value);
                  $scope.$eval($attrs.ngChange);
                  $scope.$eval($attrs.ngClick);
                });
              }
            });
            var lastCheckIndex =-1;
            $('tbody .iCheck-helper').click(function (evt) {
              var $this = $(this);
              var element = $this.prev('input[type="checkbox"]');
              var parent = $this.closest('tr');
              if (element.length) {

                var pIndex = parent.index();
                if (evt.shiftKey) {
                  if (lastCheckIndex >= 0) {
                    var isChecked,
                    //last_checked = $('tbody input[type="checkbox"]:nth(' + lastCheckIndex.last + ')').prop('checked'),
                      current_checked = ! $('tbody input[type="checkbox"]:nth(' + pIndex + ')').prop('checked');
                    // here, current checkbox has already been clicked, that's why we use the opposite
                    //console.log(last_checked, current_checked);
                    isChecked = current_checked ? 'uncheck' : 'check';
                    $('tbody input[type="checkbox"]').each(function(i, e) {

                      if ((i - pIndex) * (i - lastCheckIndex) <= 0) {
                        // this checkbox is in the range
                        $(e).iCheck(isChecked);
                      }
                    });
                  }
                } else {
                  lastCheckIndex = pIndex;
                }
                lastCheckIndex = pIndex;
              }
            });

          });
        }
      };
    }]);
