'use strict';

/**
 * @name subscriptionsCtrl
 * @desc Controller for user subscriptions
 */
function subscriptionsCtrl($scope, $state){

}
subscriptionsCtrl.$inject = ['$scope', '$state'];
angular
  .module('metabuyer')
  .controller('subscriptionsCtrl', subscriptionsCtrl);
