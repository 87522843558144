'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('main.user.reports', {
        url: '/reports',
        controller: 'reportsCtrl',
        templateUrl: 'app/reports/reports.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            //TODO: Need to enhance this section
            if (!UserPermissions.checkPermissionsAccess(profile, 'ReportLog', 'Read')) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.reports.log', {
        templateUrl: 'app/reports/logs/logs.html',
        url: '/logs?{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'reportsLogCtrl',
        resolve: {
          logList: function ($q, searchModule, $stateParams, profile) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'reports',
                'criteria[requestor_user._id]': profile._id,
                page: $stateParams.page,
                offset: 20,
                order_by: !!$stateParams.order_by ? $stateParams.order_by : 'done_at',
                order: !!$stateParams.order ? $stateParams.order : 0
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Reports - Logs';
          }
        }
      })
      .state('main.user.reports.generate', {
        templateUrl: 'app/reports/generate/generate.html',
        url: '/generate',
        controller: 'reportsGenerationCtrl',
        resolve: {
          $title: function(){
            return 'Reports - Generate';
          },
          businessList: function ($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'business-units',
                offset: 0,

                // Get only active BU = 1
                'criteria[is_active]': 1
              },
              function (resource) {
                deferred.resolve(resource.content.data);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          industryList: function($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(

              {
                module: 'industries',
                offset: 0,
                  // Get only active Industry = 1
              },
              function(resource) {
                deferred.resolve(resource.content.data);
              },
              function(error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          modeOfPurchaseList: function($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'mode-of-purchase',
                'criteria[0][is_active]': true,
                offset: 0
              },
              function(resource) {
                deferred.resolve(resource.content.data);
              },
              function(error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
        }
      })
      .state('main.user.reports.generateV2', {
        templateUrl: 'app/reports/generateV2/generate.html',
        url: '/generate-reports',
        controller: 'reportsGenerationV2Ctrl',
        resolve: {
          $title: function(){
            return 'Reports - Generate';
          },
          businessList: function ($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'business-units',
                offset: 0,
                'criteria[is_active]': 1
              },
              function (resource) {
                deferred.resolve(resource.content.data);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          industryList: function($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(

              {
                module: 'industries',
                offset: 0
              },
              function(resource) {
                deferred.resolve(resource.content.data);
              },
              function(error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          modeOfPurchaseList: function($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'mode-of-purchase',
                offset: 0
              },
              function(resource) {
                deferred.resolve(resource.content.data);
              },
              function(error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          expenseTypesList: function($q, metabuyerCache, expenseTypeUserList, globalFunc) {
            var deferred = $q.defer();
            if (!!metabuyerCache.get('expenseTypes')) {
              deferred.resolve(metabuyerCache.get('expenseTypes'));
            } else {
              expenseTypeUserList.get({},
                function (resource) {
                  if (!!resource.content && !!resource.content.data) {
                    deferred.resolve(resource.content.data);
                    metabuyerCache.put('expenseTypes', resource.content.data)
                  }
                }, function (error) {
                  globalFunc.objectErrorMessage(error);
                  deferred.resolve([]);
                }
              )
            }
            return deferred.promise;
          },
        }
      })
  }]);

