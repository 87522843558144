'use strict';
/**
 * @name itemDetailsCtrl
 *
 * @description
 * Controller for item details
 *
 * @copyright Sunway Metacloud &copy; 2016
 */

function itemDetailsCtrl(
  $scope, details, imgPath, $uibModalInstance, itemDetailsInformation, $rootScope, itemId, globalFunc, enableStarring, prFunction, $state
) {

  $scope.imgBasePath    = imgPath;
  $scope.item           = details;
  $scope.details        = details.item_details;
  $scope.enableStarring = enableStarring;

  $scope.close          = close;
  $scope.getPRState     = getPRState;
  $scope.isShoppingMode = isShoppingMode;

  function close() {
    $uibModalInstance.close();
  }

  function getPRState(){
    return prFunction.getEditingState();
  }

  function isShoppingMode() {
    return $state.includes('main.user.purchaseRequisitions.details.catalog');
  }

  function loadItemDetails() {
    itemDetailsInformation.get({
      user_id: $rootScope.currentUser._id,
      item_id: itemId
    }, function(resource) {
      if (!!resource && !!resource.content && !!resource.content.data)
        $scope.details = resource.content.data;
    }, function(error) {
      $scope.details = {};
      globalFunc.objectErrorMessage(error);
    });
  }

  /**
   * check supplier value in item_details.
   * to support non catalog
   */
  function checkSupplierValue() {
    if (!$scope.details.supplier_descr)
    $scope.details.supplier_descr = $scope.item.supplier.descr;
  }

  function initialize() {
    if (!!itemId && !details) {
      loadItemDetails();
    }
    checkSupplierValue();
  }

  // dynamically updating the items view
  $scope.$on('updateDetailsData', function(args, data) {
    if (!!data && data.item_details) {
      $scope.item = data;
      if(!!$scope.item.item && !!$scope.item.item.img_url) {
        //force the image to reload
        var randomVal = Math.random();
        $scope.item.item.img_url = $scope.item.item.img_url + '?decache=' + randomVal;
      } else {
        $scope.item.item.img_url = undefined;
      }
      $scope.details = data.item_details;
      initialize();
    }
  });

  initialize();

}
itemDetailsCtrl.$inject = [
  '$scope', 'details', 'imgPath', '$uibModalInstance', 'itemDetailsInformation', '$rootScope', 'itemId', 'globalFunc', 'enableStarring', 'prFunction', '$state'
];

angular
  .module('metabuyer')
  .controller('itemDetailsCtrl', itemDetailsCtrl);
