'use strict';

function grnCtrl($scope,  $uibModalInstance, grn, po, getGRNPDFHash, $filter, toastr, pathConstants, $rootScope) {

  $scope.grn = grn;
  $scope.po = po;

  $scope.cancel = cancel;
  $scope.printGRN = printGRN;
  $scope.getInvoiceData = getInvoiceData;


  function cancel(){
    $uibModalInstance.close();
  }

  function printGRN() {

    var tabWindow = window.open('', '_blank');

    getGRNPDFHash.get(
      {
        id: $scope.grn._id,
        timezone: $rootScope.currentUser.config.TZ
      }
    ).$promise.then(
      function (resource) {
        var newUrl = $filter('format')(pathConstants.apiUrls.po.pdfHash, {
          pdf_hash: resource.url
        });

        setTimeout(function (){
            tabWindow.location.href = newUrl;
          },
          1001
        );
      },
      function(){
        toastr.error('Failed to print GRN');
      }
    );
  }

  function roundToTwo(num) {
    return +(Math.round(num + "e+2")  + "e-2");
  }

  function getInvoiceData() {
    var invoiceNumber = '';
    var invoiceDate = '';
    var invoiceAmount = 0;
    var vendorDocumentNumber = '';

    var allowDecimal = !!$scope.grn.items[0].currency.allow_decimal ? $scope.grn.items[0].currency.allow_decimal : true;
    var currencyCode = !!$scope.grn.items[0].currency ? $scope.grn.items[0].currency.code : 'MYR';

    $scope.grn.items.forEach(function (item) {
      invoiceNumber = !!item.invoice_number ? item.invoice_number : '';
      invoiceDate = !!item.invoice_date ? item.invoice_date : '';
      invoiceAmount += !!item.invoice_amount ? item.invoice_amount : 0;
      vendorDocumentNumber = !!item.vendor_document_number ? item.vendor_document_number : invoiceNumber;

      if (allowDecimal) {
        invoiceAmount = roundToTwo(invoiceAmount);
      } else {
        invoiceAmount = Math.round(invoiceAmount);
      }
    });

    $scope.grn.invoiceNumber = invoiceNumber;
    $scope.grn.invoiceDate = invoiceDate;
    $scope.grn.invoiceAmount = invoiceAmount;
    $scope.grn.currencyCode = currencyCode;
    $scope.grn.vendorDocumentNumber = vendorDocumentNumber;
  }

  function initialize() {
    var tempErpComments = [];
    var commentControl = true;

    $scope.po.comments.data.forEach(function(element) {
      if (element.commentator.display_name.toLowerCase() === 'bifrost') {
        var isGrnIssued = element.action.toLowerCase() === 'grn issued';
        var hasGrnNumber = !!element.comment ?
          element.comment.toLowerCase().includes($scope.grn.grn_number.toLowerCase()) : false;

        if (isGrnIssued) {
          commentControl = hasGrnNumber;
        }

        if (commentControl && element.action.toLowerCase() !== 'ngrn issued') {
          tempErpComments.push({'action' : element.action, 'comment' : element.comment});
        }
      }
    });
    $scope.listErpComments = tempErpComments;

    getInvoiceData();
  }

  initialize();
}

grnCtrl.$inject = ['$scope', '$uibModalInstance', 'grn', 'po', 'getGRNPDFHash', '$filter', 'toastr', 'pathConstants', '$rootScope'];

angular.module('metabuyer')
  .controller('grnCtrl', grnCtrl);
