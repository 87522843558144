'use strict';

/**
 * @name supplierBranching
 *
 * @description
 * Directive for supplier branching used in add and edit supplier
 *
 * @param data (array)
 * to pass branch data to directive
 *
 * @param editable (boolean)
 * editable status
 *
 * @param searchable (boolean)
 * param to activate search bar
 *
 * @param selectedSupplierBranch (array)
 * Selected supplier branch. call back access parent function
 *
 * @author Khor Jin Ee <jinee585@gmail.com>
 * @copyright Sunway Metacloud &copy; 2017
 */
angular.module('metabuyer')
  .directive('branchListing', function (searchModule, globalFunc, $q, $uibModal, $translatePartialLoader) {
    return {
      restrict: 'E',
      templateUrl: 'components/branchListing/branchListing.html',
      scope: {
        data: '=',
        editable: '=',
        searchable: '=',
        selectable: '=',
        selectedSupplierBranch: '&'
      },
      controller: function () {
        $translatePartialLoader.addPart('suppliersManagement/newSupplier/lang');
      },
      link: function ($scope) {
        $scope.branchDetailsModal = branchDetailsModal;
        $scope.deleteBranches = deleteBranches;
        $scope.addBranchesModal = addBranchesModal;
        $scope.selectedSupplierBranch = selectedSupplierBranch;

        var indexDeletedRows =[];

        function deleteBranches() {
          indexDeletedRows =[];

          var remainingBranches = [];
          _.forEach($scope.data, function(data, index) {
            if(data.checked == true) {
              indexDeletedRows.push(index);
            } else {
              remainingBranches.push($scope.data[index]);
            }
          });

          consolidateRemainingBranches(remainingBranches);
        }

        function selectedSupplierBranch(branch) {
          $scope.$parent.callBackFunction(branch);
        }

        function consolidateRemainingBranches(remainingBranches) {
          $scope.data = remainingBranches.slice();
        }

        function addBranchesModal() {
          var modalInstance = $uibModal.open({
            templateUrl: 'app/suppliersManagement/branches/add/add.html',
            controller: 'addSupplierBranches',
            backdrop: 'static',
            keyboard: false,
            size: 'lg',
            resolve: {
              branches: function () {
                return $scope.data;
              }
            }
          });
          modalInstance.result.then(function (branchValue) {
            if(!!branchValue) {
              $scope.data.push(branchValue);
            }
          });
        }

        function branchDetailsModal(branch,index) {
          var modalInstance = $uibModal.open({
            templateUrl: 'app/suppliersManagement/branches/details/details.html',
            controller: 'supplierBranchDetailCtrl',
            backdrop: 'static',
            keyboard: false,
            size: 'lg',
            resolve: {
              param: function () {
                return branch;
              },
              editable: function () {
                return $scope.editable;
              },
              // Return all branches related to this supplier
              branches: function () {
                return $scope.data;
              },
              // Return the branch index
              branchIndex: function() {
                return index;
              }
            }
          });
          modalInstance.result.then(function (branchDetails) {
            if(!!branchDetails) {
              $scope.data[index] = branchDetails;
            }
          });
        }
      }
    }
  });
