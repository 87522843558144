'use strict';
/***
 * @name addErpCostCenterCtrl
 * @desc Controller to add Erp Cost Center for a company
 */
function addErpCostCenterCtrl(
  $scope, toastr, globalFunc, $uibModalInstance, companyErpCostCenterList
) {
  $scope.submitted = false;
  $scope.addErpCostCenter = addErpCostCenter;
  $scope.cancel = cancel;

  function validateAddErpCostCenter() {
    if (!$scope.erp) {
      globalFunc.objectErrorMessage('All fields are empty.');
      return false;
    }

    if (!$scope.erp.descr || !$scope.erp.code) {
      globalFunc.objectErrorMessage('Please fill in the required fields.');
      return false;
    }
    return true;
  }

  function addErpCostCenter() {
    $scope.submitted = true;

    if (!!validateAddErpCostCenter()) {
      var submit_data = {
        descr: $scope.erp.descr,
        code: $scope.erp.code,
        company_code: $scope.companyDetails.code
      };

      companyErpCostCenterList.post(
        {
          id:$scope.companyDetails._id
        },
        submit_data,
        function (success) {
          toastr.success('New Erp Cost Center successfully added!');
          $uibModalInstance.close(success);
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    }
  }

  function cancel() {
    $uibModalInstance.close();
  }

}

addErpCostCenterCtrl.$inject = ['$scope', 'toastr', 'globalFunc', '$uibModalInstance', 'companyErpCostCenterList'];

angular
  .module('metabuyer')
  .controller('addErpCostCenterCtrl', addErpCostCenterCtrl);
