'use strict';

angular.module('metabuyer.services.poConsolidation', ['ngResource'])
  .factory('consolidate', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.poConsolidation.consolidate,
      {},
      {
        'post': {method: 'POST'}
      }
    );
  })
  .factory('poConsolidationCheckToleranceLimit', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.poConsolidation.checkToleranceLimit,
      {},
      {
        'post': {method: 'POST'}
      }
    );
  })
  .factory('updateConsolidationStatus', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.poConsolidation.updateConsolidationStatus,
      {
        id: '@id'
      },
      {
        'put': {method: 'PUT'}
      }
    );
  })
  .factory('poApprovalPreview', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.poConsolidation.poApprovalPreview,
      {},
      {
        'post': {method: 'POST', ignoreLoadingBar: true}
      }
    );
  })
  .factory('massUpdateConsolidationStatus', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.poConsolidation.massUpdateConsolidationStatus,
      {},
      {
        'put': {method: 'PUT'}
      }
    );
  });
