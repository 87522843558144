'use strict';
/**
 * @name UsersImportUserCtrl
 * @desc Controller for users import user
 */
function UsersImportUserCtrl($scope, Users, pathConstants, toastr) {

  $scope.params = {
    class: 'Metabuyer\\Models\\Users',
    'additional_params[]': ''
  };

  $scope.downloadCSV = function () {
    var newUrl = pathConstants.apiUrls.users.downloadCSV;
  
    try {
      // Download the file
      setTimeout(function () {
        window.open(newUrl, '_self');
      }, 1001);
      toastr.success("The download will start automatically");
    } catch (error) {
      // Handle errors that occur when opening the URL
      toastr.error('Failed to download file');
    }  
  };
}

UsersImportUserCtrl.$inject = ['$scope', 'Users', 'pathConstants', 'toastr'];

angular.module('metabuyer')
  .controller('UsersImportUserCtrl', UsersImportUserCtrl);
