'use strict';

function sourcingExpenseTypeDetailsCtrl($scope, $uibModalInstance, item,
                              sourcingExpenseTypeMasterUpdateStatus, globalFunc, toastr, sourcingExpenseTypeMasterResource,
                              $rootScope, $uibModal, dataList) {
    $scope.editClicked = false;
    $scope.sourcingExpenseType = _.cloneDeep(item);
    $scope.tab = 1;
    $scope.cancel = cancel;
    $scope.editSourcingExpenseType = editSourcingExpenseType;
    $scope.updateSourcingExpenseType = updateSourcingExpenseType;
    $scope.updateStatus = updateStatus;
    $scope.editing = false;
    $scope.openTranslationModal = openTranslationModal;

    var translationModalInstance;

    $scope.historyDependencies = {
        embeddedParams: {
            'object[0][object_id]': $scope.sourcingExpenseType._id,
            'object[0][object_type]': 'App\\Metabuyer\\SourcingExpenseType\\Models\\SourcingExpenseType',
            offset: 5,
            order_by: '-created_at'
        }
    };
    $scope.toggleTab = toggleTab;

    function toggleTab(tab) {
        return $scope.tab = tab;
    }

    function cancel() {
        $uibModalInstance.close();
    }

    function editSourcingExpenseType() {
        $scope.editClicked = true;
    }

    function validate() {
      if (!$scope.sourcingExpenseType.descr) {
        toastr.error('The description field is required.');
        return false;
      }

      return true;
    }

    function updateSourcingExpenseType(isTranslationModal) {
      if (validate()) {
        var data = preparePayload(isTranslationModal);
        sourcingExpenseTypeMasterResource.put(
            { uuid: $scope.sourcingExpenseType.uuid },
            data,
            function (success) {
                toastr.success('Sourcing Expense Type successfully updated.');

                if(!!isTranslationModal) {
                  translationModalInstance.close(success);
                } else {
                  $uibModalInstance.close(success);
                }
            },
            function (error) {
                globalFunc.objectErrorMessage(error);
            }
        );
      }
    }

    function updateStatus() {
        sourcingExpenseTypeMasterUpdateStatus.put(
            { uuid: $scope.sourcingExpenseType.uuid },
            { is_active: $scope.sourcingExpenseType.is_active },
            function () {
                toastr.success('Status updated successfully');
            },
            function (err) {
                //revert
                $scope.sourcingExpenseType.is_active = !$scope.sourcingExpenseType.is_active;
                globalFunc.objectErrorMessage(err);
            }
        );
    }

    function preparePayload(isTranslationModal) {
        return {
            descr: $scope.sourcingExpenseType.descr,
            descr_chinese: $scope.sourcingExpenseType.descr_chinese || null,
            is_active: $scope.sourcingExpenseType.is_active,
            isTranslationUpdate: isTranslationModal,
        };
    }

    function openTranslationModal() {
        translationModalInstance = $uibModal.open({
            templateUrl: 'app/masterDataManagement/translationModal/translationModal.html',
            backdrop: 'static',
            controller: 'translationModalCtrl',
            keyboard: false,
            scope: $scope,
            resolve: {
                translationModalData: function () {
                    return $scope.sourcingExpenseType
                },
                translationModalTitle: function () {
                    return 'Sourcing Expense Type';
                }
            }
        });

        translationModalInstance.result.then(function (result) {
          var data = result.content.data;
          for (var i = 0; i < dataList.length; i++) {
              if (dataList[i]['uuid'] === data.uuid) {
                  dataList[i] = data;
              }
          }
        });
    }

    $scope.$on('updateTranslation', function() {
      updateSourcingExpenseType(true);
    })

    function init() {

    }

    init();
}

sourcingExpenseTypeDetailsCtrl.$inject = ['$scope', '$uibModalInstance', 'item',
                                'sourcingExpenseTypeMasterUpdateStatus', 'globalFunc', 'toastr', 'sourcingExpenseTypeMasterResource',
                                '$rootScope', '$uibModal', 'dataList'];

angular
  .module('metabuyer')
  .controller('sourcingExpenseTypeDetailsCtrl', sourcingExpenseTypeDetailsCtrl);
