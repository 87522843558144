'use strict';
/**
 * @name positionManagementMainCtrl
 * @desc Controller for Position Management Parent Controller
 */
function positionManagementMainCtrl($scope, $state) {

  $scope.$emit('pageTitleChanged', 'Position Management');

  $scope.tabData = [
    {
      heading: 'Positions',
      route: 'main.user.positionManagement.manage',
      key: 'manage'
    },
    {
      heading: 'Position Assignment',
      route: 'main.user.positionManagement.assign',
      key: 'assign'
    }
  ];

  function initialize() {

    if (!!$state.current.name) {
      if ($state.current.name.indexOf('assign') > -1) {
        $scope.parentState = 'main.user.positionManagement.assign';
        $scope.parentStateParams = $state.params;
        $scope.activatedTab = $scope.tabData[1];
     } else  {
        $scope.parentState = 'main.user.positionManagement.manage';
        $scope.parentStateParams = $state.params;
        $scope.activatedTab = $scope.tabData[0];
      }
    }

    if (!!$state.params && !!$state.params.query) {
      $scope.searchText = $state.params.query;
    } else {
      $scope.searchText = '';
    }

    if (!!$state.params && !!$state.params.code) {
      $scope.code = $state.params.code
    }

    if (!!$state.params && !!$state.params.filter) {
      $scope.searchFilter = $state.params.filter;
    }
  }

  initialize();
}
positionManagementMainCtrl.$inject = ['$scope', '$state'];

angular
  .module('metabuyer')
  .controller('positionManagementMainCtrl', positionManagementMainCtrl);
