'use strict';

angular.module('metabuyer.services.assetCategory', ['ngResource'])
  .factory('singleAssetCategory',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.assetCategory.single,
        {
          uuid: '@uuid'
        },
        {
          'get': {method: 'GET'},
          'put': {method: 'PUT'},
          'post': {method: 'POST'}
        }
      );
    })
  .factory('assetCategoryList',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.assetCategory.get,
        {
          code: '@code'
        },
        {
          'get': {method: 'GET'}
        }
      );
    })
  .factory('singleAssetCategoryByCode',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.assetCategory.getByCode,
        {
          code: '@code',
          company_code: '@companyCode'
        },
        {
          'get': {method: 'GET'}
        }
      );
    });
