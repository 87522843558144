'use strict';

angular.module('metabuyer')
  .factory('Currencies',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.currencies.list,
      {
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('currenciesMasterDataList',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.currencies.masterList,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'},
        'post': {method: 'POST'},
        'put': {method: 'PUT'}
      }
    );
  })
  .factory('currenciesSearch',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.currencies.search,
      {
        query: '@query'
      },
      {
        'get': { method: 'GET' }
      }
    );
  });
