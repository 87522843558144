'use strict';
/**
 * @name bifrostFileMainCtrl
 * @desc Controller for bifrost file viewer
 */
function bifrostFileMainCtrl($scope, $state, Users, profile, $rootScope, UserPermissions, globalFunc) {
  $scope.$emit('pageTitleChanged', 'Bifrost File Viewer');

  var tempState = _.cloneDeep($state.params);

  $scope.tabData = [{
    heading: 'All',
    route: 'main.user.bifrostFile.manage',
    key: 'all',
    params: globalFunc.filterUrlParams(tempState, {
      status: 'all',
      cursor: null
    })
  }];

  if ($rootScope.isV2Enabled) {
    $scope.tabData.push({
      heading: 'MSGP',
      route: 'main.user.bifrostFile.manage',
      key: 'MSGP',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'MSGP',
        cursor: null
      })
    }, {
      heading: 'MSGP-MY',
      route: 'main.user.bifrostFile.manage',
      key: 'MSGP-MY',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'MSGP-MY',
        cursor: null
      })
    }, {
      heading: 'SAP',
      route: 'main.user.bifrostFile.manage',
      key: 'SAP',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'SAP',
        cursor: null
      })
    }, {
      heading: 'Azure Blob',
      route: 'main.user.bifrostFile.manage',
      key: 'AZURE',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'AZURE',
        cursor: null
      })
    })
  }

  /**
   * making sure the Stats and placeHolder changes if the Child scope changed
   */
  $scope.$on('$stateChangeStart', function(event, data, toParams) {

    $scope.searchState = 'main.user.bifrostFile.manage';
    $scope.searchPlaceholder = 'Search File';
    $scope.parentState = 'main.user.bifrostFile.manage';
    $scope.parentStateParams = $state.params;
    $scope.activatedTab = $scope.tabData[0];
    $scope.columns = bifrostFileColumn;

    if (!!toParams.status) {
      $scope.status = toParams.status;
      $scope.parentStateParams = toParams;
    }
  });

  var bifrostFileColumn = [
    { id: 'name', label: 'File', unsearchable: true, unsortable: true },
    { id: 'path', label: 'Location', unsearchable: true, unsortable: true} ,
    { id: 'type', label: 'Type', unsearchable: true, unsortable: true },
    { id: 'size', label: 'Size', unsearchable: true, unsortable: true },
    { id: 'created_at', label: 'Created At', unsearchable: true, unsortable: true},
    { id: 'download', label: 'Download', unsearchable: true, unsortable: true },
    { id: 'delete', label: 'Delete', unsearchable: true, unsortable: true }
  ];

  function initialize() {

    if (!!$state.params && !!$state.params.status) {
      $scope.status = $state.params.status;
      var tempIndex = _.findIndex($scope.tabData, function(tab) { return tab.key === $state.params.status.toLowerCase() });
      $scope.activatedTab = $scope.tabData[tempIndex];
    }

    $scope.searchState = 'main.user.bifrostFile.manage';
    $scope.searchPlaceholder = 'Search File';
    $scope.parentState = 'main.user.bifrostFile.manage';
    $scope.parentStateParams = $state.params;
    $scope.columns = bifrostFileColumn;

    if (!!$state.params && !!$state.params.status) {
      $scope.status = $state.params.status;
    }
    if (!!$state.params && !!$state.params.filter) {
      $scope.searchFilter = $state.params.filter;
    }

    if (!!$state.params && !!$state.params.query) {
      $scope.searchText = $state.params.query;
    } else {
      $scope.searchText = '';
    }
  }

  initialize();
}
bifrostFileMainCtrl.$inject = ['$scope', '$state', 'Users', 'profile', '$rootScope', 'UserPermissions', 'globalFunc'];

angular
  .module('metabuyer')
  .controller('bifrostFileMainCtrl', bifrostFileMainCtrl);
