'use strict';

/**
 * @name companyGlobalFunc
 *
 * @description Service for company global functions
 * This service is for functions that are re-used in company modules, to minimize redundancy
 *
 * @author Deniel Ariesta <deniel.ariesta@gmail.com>
 * @author Justin Cheong <justin.cty90@gmail.com>
 * @copyright 2017 Metacloud Sdn. Bhd.
 */

function companyGlobalFunc (){
  var globalCompanyData = {};
  var POInitialStatuses = [
    {
      descr: 'Submitted',
      value: 0
    },
    {
      descr: 'Awaiting Delivery',
      value: 5
    }
  ];

  var POV2InitialStatuses = [
    {
      descr: 'Approved',
      value: 13
    }
  ];

  return {
    reformatCurrenciesSelected: function (company) {
      var companyCurrency = [];
      _.forEach(company.currencies, function (currency) {
        companyCurrency.push(currency.code);
      });
      return companyCurrency;
    },
    setCompanyData: function (company) {
      globalCompanyData = company;
    },
    getCompanyData: function () {
      return globalCompanyData;
    },
    getPOInitialStatuses: function () {
      return POInitialStatuses;
    },
    getPOV2InitialStatuses: function () {
      return POV2InitialStatuses;
    }
  }
}

companyGlobalFunc.$inject = [];

angular
  .module('metabuyer')
  .factory('companyGlobalFunc',companyGlobalFunc);
