'use strict';

angular.module('metabuyer')
  .controller('supplierUserManageViewCtrl', supplierUserManageViewCtrl);

supplierUserManageViewCtrl.$inject = [
  '$scope', '$rootScope', '$stateParams', 'globalFunc', 'dataList', 'userFunctions', 'action'
];

function supplierUserManageViewCtrl ($scope, $rootScope, $stateParams, globalFunc, dataList, userFunctions, action)
{
  $scope.getUserStatus = getUserStatus;
  $scope.getStatusDisplayName = getStatusDisplayName;
  $scope.getLastAction = getLastAction;

  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.status = $stateParams.status;
  $scope.actionType = action;
  $scope.query = $stateParams.query;

  function getUserStatus(status) {
    var status = userFunctions.convertUserStatus(status, true);

    return status.toUpperCase()
  }

  function getStatusDisplayName(status) {
    if (status === 'inactive') {
      return 'deactivated';
    } else {
      return status;
    }
  }

  function getLastAction(actionHistory) {
    if (actionHistory.length === 0) {
      return "";
    }
    return _.last(actionHistory).toUpperCase();
  }

  function initialize() {
    if ($scope.status === 'pending') {
      $scope.storageKey = 'supplier-users-pending-management-selected-columns';
    } else {
      $scope.storageKey = 'supplier-users-management-selected-columns';
    }
  }
  initialize();
}
