'use strict';

function adtafViewCtrl($scope, $uibModalInstance, purchaseRequisitionsV2Services, addAdtaf,
   globalFunc, editingState, item, toastr, adtaf, lang, prV2Function, prCurrency, $rootScope, companyId) {

  $scope.pr = prV2Function.getPRData();
  $scope.editMode = false;
  $scope.formSubmitted = false;
  $scope.edit = edit;
  $scope.close = close;
  $scope.save = save;
  $scope.reasonUpdated = reasonUpdated;
  $scope.modeOfDisposalUpdated = modeOfDisposalUpdated;
  $scope.othersError = othersError;
  $scope.editingState = editingState;
  $scope.prCurrency = prCurrency;
  $scope.item = item;
  $scope.reasonsOfDisposal = [
    {
      value: 'Poor Condition / Beyond repair',
      descr: 'Poor Condition / Beyond repair'
    },
    {
      value: 'Not economical to repair',
      descr: 'Not economical to repair'
    },
    {
      value: 'Not sighted / Missing / Stolen',
      descr: 'Not sighted / Missing / Stolen'
    },
    {
      value: 'Obsolete / High maintenance cost',
      descr: 'Obsolete / High maintenance cost'
    },
    {
      value: 'Intercompany transfer (Please specify)',
      descr: 'Intercompany transfer (Please specify)'
    },
    {
      value: 'Others (Please specify)',
      descr: 'Others (Please specify)'
    }
  ]
  $scope.modeOfDisposals = [
    {
      value: 'Written Off',
      descr: 'Written Off'
    },
    {
      value: 'Disposal With Proceed',
      descr: 'Disposal With Proceed'
    },
    {
      value: 'Transfer',
      descr: 'Transfer'
    }
  ]

  function reasonUpdated() {
    delete $scope.adtafObject.reason_explanation;
  }

  function modeOfDisposalUpdated() {
    delete $scope.adtafObject.transfer_cost;
    delete $scope.adtafObject.transfer_quantity;
    delete $scope.adtafObject.transferee_cost_centre;
    delete $scope.adtafObject.transferee_company_code;
    delete $scope.adtafObject.estimate_sales;
  }

  function validate() {
    if (!$scope.adtafObject.mode_of_disposal){
      toastr.error(lang.validation.required.input + 'mode of disposal');
      return true;
    }
    if (!$scope.adtafObject.reason){
      toastr.error(lang.validation.required.input + 'reason for disposal');
      return true;
    }
    return false;
  }

  function othersError(){
    if (!!$scope.adtafObject.reason && ($scope.adtafObject.reason.descr === 'Intercompany transfer (Please specify)' || $scope.adtafObject.reason.descr === 'Others (Please specify)')) {
      if (_.isEmpty($scope.adtafObject.reason_explanation)) {
        return true;
      }
    }
    return false;
  }

  function edit() {
    $scope.editMode = !$scope.editMode;
  }

  function prepareAdtaf(data){
    data.transfer_cost = Number(data.transfer_cost);
    data.transfer_quantity = Number(data.transfer_quantity);
    return data;
  }

  function manage(data){
    $scope.formSubmitted = true;
    var newData = prepareAdtaf(data);
    addAdtaf.post(
      {
        requisitionItemId: $scope.item._id,
      },
      newData,
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data){
          toastr.success('ADTAF created');
          prV2Function.setPRData(resource.content.data);
          $scope.formSubmitted = false;
        }
        edit();
        close();
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function save() {
    if (!validate()){
      var data = prepareAdtafObject();
      manage(data);
    }
  }

  function prepareAdtafObject(){
    var res = _.cloneDeep($scope.adtafObject);
    res.transferee_company_code = !!$scope.adtafObject.transferee_company_code ? $scope.adtafObject.transferee_company_code : undefined;
    return res;
  }

  function close() {
    $uibModalInstance.dismiss();
  }

  function getTemplateUrl(state) {
    if (state === 'editable') {
      $scope.myTemplateUrl = 'components/purchaseRequisitionV2Components/adtafView/adtafView.EditableTemplate.html';
    } else if (state === 'notEditable' && !!$scope.isFinanceVerifier && $scope.pr.status !== 'approved') {
      $scope.myTemplateUrl = 'components/purchaseRequisitionV2Components/adtafView/adtafView.EditableTemplate.html';
    } else {
      $scope.myTemplateUrl = 'components/purchaseRequisitionV2Components/adtafView/adtafView.NonEditableTemplate.html';
    }
  }

  function initialize(){
    $scope.isFinanceVerifier = !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Finance Verifier', null, companyId);
    if (!_.isEmpty($scope.item.adtaf)) {
      $scope.adtafObject = _.cloneDeep($scope.item.adtaf);
    } else {
      $scope.adtafObject = {};
    }
  }

  initialize();

  $scope.$watch('editingState', function (newValue) {
    getTemplateUrl(newValue);
  });

  // this watcher makes sure there is only 2 decimal places for the field
  $scope.$watch('adtafObject.transfer_quantity', function (newValue, oldValue) {
    if (!!newValue && !(/^\d*(\.\d{0,2})?$/.test(newValue))){
      $scope.adtafObject.transfer_quantity = oldValue;
    }
  });

  $scope.$watch('adtafObject.transfer_cost', function (newValue, oldValue) {
    if (!!newValue && !!$scope.prCurrency.allow_decimal && !(/^\d*(\.\d{0,2})?$/.test(newValue))){
      $scope.adtafObject.transfer_cost = oldValue;
    }
  });
}

adtafViewCtrl.$inject = ['$scope', '$uibModalInstance', 'purchaseRequisitionsV2Services',
                         'addAdtaf', 'globalFunc', 'editingState', 'item',
                         'toastr', 'adtaf', 'lang', 'prV2Function', 'prCurrency', '$rootScope', 'companyId'];

angular
  .module('metabuyer')
  .directive('adtafView', function ($uibModal, $q, purchaseRequisitionsV2Services) {
    return {
      restrict: 'AE',
      scope: {
        item: '=?',
        editingState: '=?',
        adtafId: '=?',
        prCurrency: '=?',
        companyId: '=?'
      },
      link: function ($scope, element) {

        function displayPanel() {
          $uibModal.open({
            template: '<div ng-include="myTemplateUrl"></div>',
            controller: 'adtafViewCtrl',
            backdrop: 'static',
            keyboard: false,
            size: 'lg',
            resolve: {
              item: function () {
                return $scope.item;
              },
              editingState: function () {
                return $scope.editingState;
              },
              prCurrency: function() {
                return $scope.prCurrency;
              },
              adtaf: function () {
                var deferred = $q.defer();
                if (!$scope.adtafId){
                  return {}
                }
                purchaseRequisitionsV2Services.getAdtafData({
                    id: $scope.adtafId
                  },
                  function (resource) {
                    if (!!resource && !!resource.content) {
                      deferred.resolve(resource.content);
                    } else {
                      deferred.resolve({})
                    }
                  },
                  function (error) {
                    globalFunc.objectErrorMessage(error);
                  }
                );
                return deferred.promise;
              },
              companyId: function () {
                return $scope.companyId;
              }
            }
          });
        }

        element.bind("click", function () {
          displayPanel();
        });
      }
    };
  })
  .controller('adtafViewCtrl', adtafViewCtrl);
