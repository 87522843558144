'use strict';


function supplierbranch (){
  return {
    restrict: 'E',
    templateUrl: 'components/supplierbranch/supplierbranchTemplate.html',
    controller: function($scope) {
    }
  }
}

angular
  .module('metabuyer')
  .directive('supplierbranch',supplierbranch);
