'use strict';

function stockCtrl($scope, prV2Function) {
  var pr = prV2Function.getPRData();
  $scope.category = !!pr.expense_type && !!pr.expense_type.category ? pr.expense_type.category : null;
  $scope.current_order = $scope.item.qty;

  $scope.$on('PRDataUpdated', function () {
    $scope.current_order = $scope.item.qty;
    populateStockData()
  });

  $scope.$watch('item.stock', function (newVal) {
    $scope.stock = newVal;
    populateStockData(newVal)
  });

  function populateStockData(data) {
    $scope.stock = data || {};
    var keys = [];

    if (!_.isUndefined($scope.item.stock_information)) {
      keys = Object.keys($scope.item.stock_information);

      _.forEach(keys, function(key){
        $scope.stock[key] = Number($scope.item.stock_information[key]);
      });

    } else {
      keys = Object.keys($scope.stock);
      _.forEach(keys, function(key){
        $scope.stock[key] = Number($scope.stock[key]);
      });
    }

    $scope.current_order = Number($scope.current_order);
  }
}

stockCtrl.$inject = ['$scope', 'prV2Function'];

angular
  .module('metabuyer')
  .directive('stockInformation', function () {
    return {
      restrict: 'E',
      templateUrl: 'components/stockInformation/stockInformationTemplate.html',
      controller: 'stockCtrl'
    }
  })
  .controller('stockCtrl', stockCtrl);
