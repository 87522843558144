'use strict';
/**
 * @name positionManagementCtrl
 * @desc Controller for listing of positions
 */
function positionManagementCtrl($scope, $uibModal, positionManagementListing, positionManagement, toastr,
                                positionManagementSingle, globalFunc, pathConstants, $http, $filter) {

  $scope.importParams = {
    class: 'Metabuyer\\ApprovalFlow\\Positions\\Models\\Position',
    'additional_params[]': ''
  };

  $scope.columns = [
    {id: 'row', label: '#', unsortable: true},
    {id: 'code', label: 'Code'},
    {id: 'name', label: 'Position'},
    {id: 'position_type', label: 'Type'},
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    },
    {id: 'updated_by', label: 'Updated By', unsortable: true, unsearchable: true},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {id: 'created_by', label: 'Created By', unsortable: true, unsearchable: true},
    {id: 'status', label: 'Status'}
    // {id: 'remove', label: 'Remove', unsortable: true}
  ];

  $scope.processedData = [];
  $scope.meta = [];
  $scope.positionName = '';
  $scope.positionCode = '';
  $scope.editClicked = false;
  $scope.page = 0;
  $scope.limit = 30;
  $scope.currentStatus = {
    col: null,
    order: null
  };
  $scope.timezoneDiff = '';
  $scope.colSorting = null;
  $scope.colOrder = null;
  $scope.storageKey = 'position-management-manage-selected-columns';
  $scope.statusListing = [
    {
      code: '0',
      descr: 'Active'
    },
    {
      code: '1',
      descr: 'Inactive'
    }
  ];
  $scope.status = {};
  $scope.status.list = [];
  $scope.moduleName = 'Position';
  $scope.visibleColumn = visibleColumn;
  $scope.showDetails = showDetails;
  $scope.add = add;
  $scope.processData = processData;
  $scope.returnDate = returnDate;
  $scope.returnStatus = returnStatus;
  $scope.search = search;
  $scope.removePosition = removePosition;
  $scope.exportPosition = exportPosition;
  $scope.resetSelection = resetSelection;
  $scope.hasUserPermission = globalFunc.checkUserPermission($scope.$root.currentUser, $scope.moduleName);

  function visibleColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function showDetails(data) {
    $scope.editClicked = false;
    $uibModal.open({
      templateUrl: 'app/positionManagement/manage/details.html',
      backdrop: 'static',
      keyboard: false,
      scope: $scope,
      controller: 'positionDetailsController',
      resolve: {
        isEditable: function () {
          return false;
        },
        data: function () {
          return data;
        },
        positionHistory: function ($q, approvalTemplateHistory) {
          var deferred = $q.defer();
          approvalTemplateHistory.get(
            {
              'sort[1][key]': 'created_at',
              'sort[1][direction]': 'DESC',
              'filter_groups[0][filters][0][key]': 'auditable_id',
              'filter_groups[0][filters][0][value]': data.id,
              'filter_groups[0][filters][0][operator]': 'eq',
              'filter_groups[1][filters][0][key]': 'auditable_type',
              'filter_groups[1][filters][0][value]': 'Api\\Positions\\Models\\Position',
              'filter_groups[1][filters][0][operator]': 'eq',
              limit: 10,
              page: 0
            },
            function (resource) {
              if (!!resource.content) {
                deferred.resolve(resource.content);
              }
            },
            function (error) {
              deferred.resolve([]);
            }
          );
          return deferred.promise;
        },
        isEditWindow: function () {
          return true;
        }
      }
    });
  }

  function add() {
    $scope.editClicked = true;
    $uibModal.open({
      templateUrl: 'app/positionManagement/manage/details.html',
      backdrop: 'static',
      keyboard: false,
      scope: $scope,
      controller: 'positionDetailsController',
      resolve: {
        isEditable: function () {
          return true;
        },
        data: function () {
          return [];
        },
        positionHistory: function () {
          return null;
        },
        isEditWindow: function () {
          return false;
        }
      }
    });
  }

  function processData() {
    $scope.processedData = [];
    $scope.processedData = $scope.positionData.position;
    $scope.meta['currentPageNumber'] = $scope.positionData['meta']['current'];
    $scope.meta['totalPageNumber'] = $scope.positionData['meta']['pages'];
    $scope.meta['totalRec'] = $scope.positionData['meta']['count'];
    $scope.meta['startRecNo'] = $scope.limit * $scope.page + 1;
    $scope.meta['endRecNo'] = $scope.meta['totalRec'] - ((1 + $scope.meta['currentPageNumber']) * $scope.limit) >= 0 ?
      (1 + $scope.meta['currentPageNumber']) * $scope.limit :
      (($scope.meta['currentPageNumber']) * $scope.limit) + $scope.meta['totalRec'] % $scope.limit;
    $scope.positionData = $scope.processedData;
  }

  function returnDate(date) {
    return globalFunc.convertToLocalDate(date);
  }

  function returnStatus(status) {
    var statusString = '';
    switch (status) {
      case 0:
        statusString = 'Active';
        break;
      case 1:
        statusString = 'Inactive';
        break;
    }
    return statusString;
  }

  function search(page, sortingCol, sortingOrder) {
    $scope.page = page;
    $scope.colSorting = sortingCol;
    $scope.colOrder = sortingOrder;
    positionManagement.get(
      {
        'sort[0][key]': sortingCol,
        'sort[0][direction]': sortingOrder,
        'sort[1][key]': 'updated_at',
        'sort[1][direction]': 'DESC',
        'filter_groups[0][filters][0][key]': 'name',
        'filter_groups[0][filters][0][value]': !!$scope.positionName ? $scope.positionName : '',
        'filter_groups[0][filters][0][operator]': 'ct',
        'filter_groups[0][filters][1][key]': 'code',
        'filter_groups[0][filters][1][value]': !!$scope.positionCode ? $scope.positionCode : '',
        'filter_groups[0][filters][1][operator]': 'ct',
        'filter_groups[0][filters][2][key]': 'status',
        'filter_groups[0][filters][2][value]': !!$scope.status.list.code ? $scope.status.list.code : '',
        'filter_groups[0][filters][2][operator]': 'ct',
        limit: $scope.limit,
        page: page
      }
    ).$promise.then(
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.positionData = resource.content.data;
          $scope.positionData['meta'] = resource.content.meta.cursor;
          processData();
        }
      },
      function (resource) {
        if (!!resource.data && !!resource.data.content && !!resource.data.content.message && !!resource.data.content.message.errors) {
          toastr.error(_.map(resource.data.content.message.errors, _.property('detail')).toString());
        }
        else {
          toastr.error('Failed to search.');
        }
      }
    );
  }

  function removePosition(position) {
    swal({
        title: 'Warning!',
        text: 'Are you sure you want to delete position \'' + position.name + '\' ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (isConfirm) {
          positionManagementSingle.delete(
            {
              id: position.id
            }
          ).$promise.then(
            function () {
              search($scope.meta['currentPageNumber']);
              toastr.success('Position has been successfully deleted.');
            },
            function (resource) {
              if (!!resource.data && !!resource.data.content && !!resource.data.content.message && !!resource.data.content.message.errors) {
                toastr.error(_.map(resource.data.content.message.errors, _.property('detail')).toString());
              }
              else {
                toastr.error('Failed to delete selected position.');
              }
            }
          );
        }
      });
  }

  function exportPosition() {
    $http({
      method: 'POST',
      url: pathConstants.apiUrls.approvalEngine.position.export,
      params: {
        'sort[0][key]': $scope.colSorting,
        'sort[0][direction]': $scope.colOrder,
        'sort[1][key]': 'updated_at',
        'sort[1][direction]': 'DESC',
        'filter_groups[0][filters][0][key]': 'name',
        'filter_groups[0][filters][0][value]': !!$scope.positionName ? $scope.positionName : '',
        'filter_groups[0][filters][0][operator]': 'ct',
        'filter_groups[0][filters][1][key]': 'code',
        'filter_groups[0][filters][1][value]': !!$scope.positionCode ? $scope.positionCode : '',
        'filter_groups[0][filters][1][operator]': 'ct',
        'filter_groups[0][filters][2][key]': 'status',
        'filter_groups[0][filters][2][value]': !!$scope.status.list.code ? $scope.status.list.code : '',
        'filter_groups[0][filters][2][operator]': 'ct'
      }
    }).then(function (response) {
      var blob = new Blob([response.data]);
      var link = document.createElement('a');
      document.body.appendChild(link);
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Position ' + $filter('date')(new Date(), 'dd-MMM-yyyy') +'.csv';
      link.click();
      toastr.success('Position file has been successfully generated.');
    }, function () {
      toastr.error('Failed to generate position file.');
    });
  }

  function resetSelection() {
    $scope.positionName = '';
    $scope.positionCode = '';
    $scope.status.list = [];
  }

  function initialize() {
    $scope.timezoneDiff = globalFunc.getTimezoneDiff();
    $scope.positionData = positionManagementListing.data;
    $scope.positionData['meta'] = positionManagementListing.meta.cursor;
    processData();
  }

  initialize();

}

positionManagementCtrl.$inject = ['$scope', '$uibModal', 'positionManagementListing', 'positionManagement',
  'toastr', 'positionManagementSingle', 'globalFunc', 'pathConstants', '$http', '$filter'];

angular
  .module('metabuyer')
  .controller('positionManagementCtrl', positionManagementCtrl);

