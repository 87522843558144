'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function($stateProvider) {
    $stateProvider
      .state('main.user.priceComparison', {
        url: '/price-comparison',
        controller: 'priceComparisonCtrl',
        template: '<div ng-include="priceComparisonTemplate"></div>',
        resolve: {
          accessRights: function() {
            // todo check for role and permission here
          },
          findTemplate: function($rootScope) {
            $rootScope.priceComparisonTemplate = 'app/priceComparison/priceComparison.html';
          }
        }
      })
      .state('main.user.priceComparison.manage', {
        templateUrl: 'app/priceComparison/manage/manage.html',
        url: '/manage?{status:[a-zA-Z0-9]+}&{tab:[a-zA-Z0-9]+}&{extraFilterKey:[a-zA-Z0-9]+}&' +
          '{extraFilterValue:[a-zA-Z0-9]+}&{page:[0-9]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}&' +
          '{query:[a-zA-Z0-9]+}&{order:[0-9]+}&{order_by:[a-zA-Z0-9]+}',
        controller: 'priceComparisonManageCtrl',
        template: '<div ng-include="priceComparisonManageTemplate"></div>',
        resolve: {
          dataList: function($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();
            var params = {
              module: 'price-comparison',
              offset: 20,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            var extraFilterKeyCreatorCheck = ($stateParams.extraFilterKey === 'criteria[creator_info|_id][][]');
            var extraFilterKeyCollCheck = ($stateParams.extraFilterKey === 'criteria[collaborators_ids][]' && !!$stateParams.extraFilterValue);

            /**
             * all tab
             */
            if ($stateParams.status === 'all') {
              /**
               * searching (all tab)
               */
              if (!!$stateParams.query) {
                params['criteria[2][creator_info|_id][]'] = extraFilterKeyCreatorCheck ?
                  $stateParams.extraFilterValue : null;
                params['criteria[2][collaborators.collaborator_id]'] =
                  extraFilterKeyCollCheck ?
                  true : null;
                params['criteria[0][status][0]'] = 'draft';
                params['criteria[0][status][1]'] = 'pending';
                params['criteria[0][status][2]'] = 'approved';
                params['criteria[0][status][3]'] = 'deleted';
                params['criteria[0][status][4]'] = 'withdrawn';
                params['criteria[0][status][5]'] = 'rejected';
                params['criteria[0][status][6]'] = 'on_hold';
                params['criteria_operator'] = 'and';
                /**
                 * searching with a specific filter (all tab)
                 */
                if (!!$stateParams.filter) {
                  var temp = '';
                  if ($stateParams.type === 'date') {
                    temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                    params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                  } else if ($stateParams.type === 'double') {
                    temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                    params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                  } else {
                    params['criteria[1][' + $stateParams.filter + ']'] = $stateParams.query;
                  }
                }
                /**
                 * searching all filters(all tab)
                 */
                else {
                  params['criteria[1][pc_number]'] = $stateParams.query;
                  params['criteria[1][title]'] = $stateParams.query;
                  params['criteria[1][title]'] = $stateParams.query;
                  params['criteria[1][pr_info|pr_reference]'] = $stateParams.query;
                  params['criteria[1][item_comparisons|supplier|basic_info|descr]'] = $stateParams.query;
                  params['criteria[1][pr_info|shadow_company|descr]'] = $stateParams.query;
                  params['criteria[1][pr_info|company|descr]'] = $stateParams.query;
                  params['criteria[1][creator_info|display_name]'] = $stateParams.query;
                  params['criteria[1][withdrawer|display_name]'] = $stateParams.status === 'withdrawn' ? $stateParams.query : null;
                  params['criteria[1][$operator]'] = !!$stateParams.query ? 'or' : null;
                }
              }
              /**
               * listing
               */
              else {
                params['criteria[1][creator_info|_id][]'] = extraFilterKeyCreatorCheck ?
                  $stateParams.extraFilterValue : null;
                params['criteria[1][collaborators.collaborator_id]'] =
                  extraFilterKeyCollCheck ?
                  true : null;
                params['criteria[0][status][0]'] = 'draft';
                params['criteria[0][status][1]'] = 'pending';
                params['criteria[0][status][2]'] = 'approved';
                params['criteria[0][status][3]'] = 'deleted';
                params['criteria[0][status][4]'] = 'withdrawn';
                params['criteria[0][status][5]'] = 'rejected';
                params['criteria[0][status][6]'] = 'on_hold';
                params['criteria_operator'] = 'and';
              }
            }
            /**
             * tabs other than all tab
             */
            else {
              /**
               * searching
               */
              if (!!$stateParams.query) {
                params['criteria[2][creator_info|_id][]'] = extraFilterKeyCreatorCheck ?
                  $stateParams.extraFilterValue : null;
                params['criteria[2][collaborators.collaborator_id]'] =
                  extraFilterKeyCollCheck ?
                  true : null;
                params['criteria[2][waiting_on][]'] = ($stateParams.extraFilterKey === 'criteria[waiting_on][]') ?
                  $stateParams.extraFilterValue : null;
                /**
                 * searching with a specific filter
                 */
                if (!!$stateParams.filter) {
                  params['criteria_operator'] = 'and';
                  var temp = '';
                  if ($stateParams.type === 'date') {
                    temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                    params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                  }
                  //TODO: To have backend support searching double or change it (Justin)
                  else if ($stateParams.type === 'double') {
                    temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                    params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                  } else {
                    params['criteria[1][' + $stateParams.filter + ']'] = $stateParams.query;
                  }
                }
                /**
                 * searching all filters
                 */
                else {
                  params['criteria_operator'] = !!$stateParams.query ? 'and' : null;
                  params['criteria[1][$operator]'] = !!$stateParams.query ? 'or' : null;
                  params['criteria[1][pc_number]'] = $stateParams.query;
                  params['criteria[1][title]'] = $stateParams.query;
                  params['criteria[1][title]'] = $stateParams.query;
                  params['criteria[1][pr_info|pr_reference]'] = $stateParams.query;
                  params['criteria[1][item_comparisons|supplier|basic_info|descr]'] = $stateParams.query;
                  params['criteria[1][pr_info|shadow_company|descr]'] = $stateParams.query;
                  params['criteria[1][pr_info|company|descr]'] = $stateParams.query;
                  params['criteria[1][creator_info|display_name]'] = $stateParams.query;
                  params['criteria[1][withdrawer|display_name]'] = $stateParams.status === 'withdrawn' ? $stateParams.query : null;
                }
              }
              /**
               * listing
               */
              else {
                params['criteria[1][creator_info|_id][]'] = extraFilterKeyCreatorCheck ?
                  $stateParams.extraFilterValue : null;
                params['criteria[1][collaborators.collaborator_id]'] =
                  extraFilterKeyCollCheck ?
                  true : null;
                params['criteria[1][waiting_on][]'] = ($stateParams.extraFilterKey === 'criteria[waiting_on][]') ?
                  $stateParams.extraFilterValue : null;
                params['criteria_operator'] = 'and';
              }
            }

            if ($stateParams.status === 'starred') {
              params['criteria[0][tab]'] = "starred";
            } else {
              if ($stateParams.status !== 'all') {
                params['criteria[0][status]'] = $stateParams.status;
              }
              params['criteria[0][$operator]'] = 'or';
            }

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function(resource) {
                deferred.resolve(resource.content || { data: [] });
              },
              function(error) {
                //TODO: handle the error
                deferred.resolve([]);
              }
            );
            return deferred.promise;

          },
          status: function($stateParams) {
            return !!$stateParams.status ? $stateParams.status.toLowerCase() : '';
          },
          $title: function() {
            return 'Price Comparison - listing';
          },
          findTemplate: function($rootScope) {
            $rootScope.priceComparisonManageTemplate = 'app/priceComparison/manage/manage.html';
          }
        }
      })
      .state('main.user.priceComparison.detail', {
        templateUrl: 'app/priceComparison/detail/detail.html',
        url: '/{id:[a-zA-Z0-9]+}',
        controller: 'priceComparisonDetailCtrl',
        params: { priceComparison: null },
        resolve: {
          priceComparison: function($q, $stateParams, priceComparisonResource, priceComparisonFunction) {
            var deferred = $q.defer();

            if (!!$stateParams.priceComparison) {
              deferred.resolve($stateParams.priceComparison);
            } else {
              priceComparisonResource.get({
                  id: $stateParams.id
                },
                function(resource) {
                  if (!!resource.content) {
                    deferred.resolve(resource.content.data);
                    priceComparisonFunction.initialize(resource.content.data);
                  }
                }
              )
            }

            return deferred.promise;
          },
          requisition: function($q, $auth, purchaseRequisitionsV2Services, priceComparison, globalFunc) {
            var deferred = $q.defer();

            purchaseRequisitionsV2Services.getSinglePR({
                requisition_id: priceComparison.pr_id
              },
              function(resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content.data);
                } else {
                  deferred.resolve([]);
                }
              },
              function(error) {
                if ($auth.isAuthenticated())
                  globalFunc.objectErrorMessage(error);
                else
                  globalFunc.loggingWarning();
              }
            );

            return deferred.promise;
          },
          $title: function() {
            return 'Price Comparison - detail';
          }
        }
      })
  }]);
