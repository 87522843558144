'use strict';

angular.module('metabuyer')
  .directive('textShowLessMore', function () {
    return {
      restrict: 'E',
      templateUrl: 'components/textShowLessMore/textShowLessMoreTemplate.html',
      scope: {
        model: '=',
        type: '@',
        length: '@',
        breakOnWords: '@?' // split by character case, true: keep the last word
      },
      link: function (scope, element, attribute) {
        scope.$watch('model', function (newValue, oldValue) {
          if (!!newValue) {
            switch (scope.type) {
              default:
              case 'word':
              case 'words': {
                if (!scope.length) scope.length = 25;

                if (!!scope.model) {
                  var inputLines = scope.model.split(/\n/);

                  if (inputLines.length === 1) {
                    var inputWords = scope.model.split(/\s+/);

                    if (inputWords.length > scope.length) {
                      scope.showExpander = true;
                      scope.updatedModel = inputWords.slice(0, scope.length).join(' ') + '...';
                    } else {
                      scope.showExpander = false;
                      scope.updatedModel = undefined;
                    }
                  } else {
                    inputWords = 0;
                    _.forEach(inputLines, function (line, index) {
                      var wordsInLine = line.split(/\s+/);
                      inputWords += wordsInLine.length;
                      if (inputWords > scope.length) {
                        scope.showExpander = true;
                        var inputLines = scope.model.split(/\n/);
                        scope.updatedModel = inputLines.slice(0, index).join('\r\n');
                        scope.updatedModel += '...';
                        return false;
                      }
                    })
                  }
                }

                return scope.model;
              }

              case 'character':
              case 'char':
              case 'characters': {
                if (!scope.length) scope.length = 100;

                if (scope.model && scope.model.length > scope.length) {
                  scope.showExpander = true;
                  scope.updatedModel = scope.model.substring(0, scope.length);
                  if (!scope.breakOnWords)
                    scope.breakOnWords = true;
                  if (!scope.breakOnWords) {
                    var lastSpace = scope.updatedModel.lastIndexOf(' ');
                    if (lastSpace !== -1) {
                      scope.updatedModel = scope.updatedModel.substr(0, lastSpace);
                    }
                  } else {
                    while (scope.updatedModel.charAt(scope.updatedModel.length - 1) === ' ') {
                      scope.updatedModel = scope.updatedModel.substr(0, scope.updatedModel.length - 1);
                    }
                  }
                  return scope.updatedModel += '...';
                }

                if (scope.model && scope.model.length <= scope.length) {
                  scope.showExpander = false;
                  scope.updatedModel = scope.model.substring(0, scope.length);
                  if (!scope.breakOnWords)
                    scope.breakOnWords = true;
                  if (!scope.breakOnWords) {
                    var lastSpace = scope.updatedModel.lastIndexOf(' ');
                    if (lastSpace !== -1) {
                      scope.updatedModel = scope.updatedModel.substr(0, lastSpace);
                    }
                  } else {
                    while (scope.updatedModel.charAt(scope.updatedModel.length - 1) === ' ') {
                      scope.updatedModel = scope.updatedModel.substr(0, scope.updatedModel.length - 1);
                    }
                  }
                  return scope.updatedModel;
                }

                return scope.model;
              }
            }
          }
        });
      }
    }
  });
