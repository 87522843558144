'use strict';

function policyDisclaimeModalCtrl($scope, $uibModalInstance, data) {
  $scope.policyDisclaimer = data.policy_disclaimer;
  $scope.locale = data.locale;

  $scope.languages = [
    {
      descr: 'English',
      value: 'en'
    },
    {
      descr: 'Chinese',
      value: 'zh'
    }
  ];
  $scope.selectedLanguage = $scope.languages[0];

  $scope.cancel = cancel;
  $scope.onSelectLanguage = onSelectLanguage;

  function cancel() {
    $uibModalInstance.close(false);
  }

  function onSelectLanguage(language) {
    $scope.selectedLanguage = language;
  }

  console.log($scope.selectedLanguage);
}

policyDisclaimeModalCtrl.$inject = [
  '$scope', '$uibModalInstance', 'data'
];

angular
  .module('metabuyer')
  .controller('policyDisclaimeModalCtrl', policyDisclaimeModalCtrl);
