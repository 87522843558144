/**
 * @name metabuyerInterceptor
 *
 * @requires $q
 * @requires $rootScope
 * @requires $injector
 *
 *
 * @description
 *
 * This Interceptors handles All the Success and Rejection requests as well as setting up the User Company in the header
 *
 * mb_company is the variable in which the workspace Company is stored, it accepts a company ID
 *
 * In case of Successful requests the header Authorization will be updated, unless its the Automatic updates for the notifications
 * The Authorization is not refreshed in case of the Notifications by checking the endpoint is 'api/users/notifications/unread'
 *
 * rejection codes:
 * 409: for user getting kicked out when other user login with the same user data
 * 306: password reset is required
 * 423: account is locked by the Admin
 * 401: invalid verified by having the token stored and not removed it means the session has expired
 * 401: invalid verified by Not having a token stored which means the redirect was not correct
 * 503: Metabuyer is under maintenance
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright  Metacloud Sdn. Bhd. &copy; 2016
 */

'use strict';
function metabuyerInterceptor ($q, $rootScope, $injector) {
  return {
    'request': function (config) {
      /**
       * Adding the Company code to the header after a successful log in
       */

      // added parameter in url
      // for backend to debugging
      // if(config.url.indexOf('http://localhost:8000') > -1){
      //   var debugKey = 'metabuyer';
      //   if(config.url.indexOf('?') > -1)
      //     config.url += '&';
      //   else
      //     config.url += '?';
      //
      //   config.url += 'XDEBUG_SESSION_START=' + debugKey;
      // }

      return config;
    },
    'response': function (response) {
      /**
       * The session to be updated only if the user clicked on anything, in case if its the automatic notification it should be ignored
       */
      if (!!response.config) {
        if (String(response.config.url.indexOf('api/users/notifications/unread')) < 1 && String(response.config.url.indexOf('api/waiting')) < 1) {
          if (!!response.headers('authorization')) {
            $injector.get('$auth').setToken(response.headers('authorization'), true);
          }
        }
      }

      return response;
    },

    'responseError': function (rejection) {
      var auth = $injector.get('$auth');
      var reLoginService = $injector.get('reLoginService');

      /**
       * 401: invalid verified by Not having a token stored which means the redirect was not correct
       */
      if (rejection.status === 401 && !window.localStorage.satellizer_token && !$rootScope.reLoginDialogOpen) {
        $injector.get('$state').go('login');
      }

      /**
       * handling the cases where a  reLogin modal is needed
       */
      if (!$rootScope.reLoginDialogOpen) {

        var message = '';
        var email = window.localStorage.getItem('email');
        var currentStateData = {
          name: $injector.get('$state').current.name,
          params: $injector.get('$state').params,
          url: $injector.get('$state').href($injector.get('$state').current.name, $injector.get('$state').params)
        };


        /**
         * checking for a kicked out state
         */
        if (rejection.status === 409) {
          if(!!rejection.data && !!rejection.data.message){
            message = rejection.data.message;
          }
          else{
            message = 'Another Login was made using this user or was kicked out by the Admin. Please Contact the Admin if this is wrong';
          }

          if (_.isEmpty(window.localStorage.getItem('satellizer_token'))) {
            $injector.get('$state').go('login');
          } else {
            reLoginService(currentStateData, window.localStorage.email, message);
          }

        }

        /**
         * 401: invalid verified by having the token stored and not removed it means the session has expired by the Admin
         */
        if (rejection.status === 401 && !!window.localStorage.satellizer_token) {
          message = 'The session has expired';
          reLoginService(currentStateData, window.localStorage.email, message);
        }
      }


      /**
       * redirect to the reset page state
       */
      if (rejection.status === 306) {
        $injector.get('$state').go('passwordReset');
      }


      /**
       * redirect to the reset page state
       */
      if (rejection.status === 309) {
        $injector.get('$state').go('securityQuestionSetup', {
          'locale': rejection.data.locale || null
        });
      }

      /**
       * redirect to the login page state
       */
      if (rejection.status === 310) {
        if(!$rootScope.validationStatus310 && !!rejection.data && !!rejection.data.message){
          var errMsg = rejection.data.message || 'Please login again.';
          $injector.get('toastr').error(errMsg);
          $rootScope.validationStatus310 = true;
        }
        $injector.get('$state').go('login');
        auth.logout();
      }

      /**
       * redirect to under maintenance page state
       */
      if (rejection.status === 503) {
        $injector.get('$state').go('underMaintenance');

        /**
         * ensure the token is removed so more calls is being fired since the system is under maintenance
         */
        auth.logout();
      }

      /**
       * redirect to account locked page state
       */
      if (rejection.status === 423) {
        $rootScope.userInactive = rejection.data.userInactive || false;
        $injector.get('$state').go('accountLocked');
      }

      return $q.reject(rejection);
    }
  }
}

metabuyerInterceptor.$inject = ['$q', '$rootScope', '$injector'];
angular
  .module('metabuyer')
  .factory('metabuyerInterceptor',metabuyerInterceptor);

