"use strict";

/**
 * Form to load all data needed for Non Catalog, Cerf, ADTAF
 *
 *
 * @param $uibModal
 * @param prV2Function
 * @param loadCacheableData
 * @param toastr
 * @returns {{restrict: string, scope: {item: string}, link: link}}
 */

function itemForm($uibModal, prV2Function, loadCacheableData, toastr)
{
  return {
    restrict: "A",
    scope: {
      item: "=?",
      action: "@action",
    },
    controller: function ($scope, $element)
    {
      //this variable to control what data to be loaded and which to be skipped
      var mode = prV2Function.getExpenseTypeMode();

      function displayPanel()
      {
        $uibModal.open({
          templateUrl: "components/purchaseRequisitionV2Components/itemForm/itemFormTemplate.html",
          controller: "itemFormCtrl",
          backdrop: "static",
          size: "lg",
          windowClass: "consolidation-confirmation-modal",
          resolve: {
            prMode: function ()
            {
              return mode;
            },
            item: function ()
            {
              return $scope.item || {};
            },
            nonCatalog: function ()
            {
              return $scope.nonCatalog;
            },
            action: function ()
            {
              return $scope.action;
            },
          },
        });
      }

      $element.bind("click", function ()
      {
        if (!mode)
        {
          toastr.error("Please select expense type before adding non catalog item.");
          return;
        }
        displayPanel();
      });
    },
  };
}

itemForm.$inject = ["$uibModal", "prV2Function", "loadCacheableData", "toastr"];

angular.module("metabuyer").directive("itemForm", itemForm);
