'use strict';

function itemGroupDetailsCtrl($scope, $uibModalInstance, item, itemCodesListing, itemCategoriesListing,
                              itemGroupMasterUpdateStatus, globalFunc, toastr, itemGroupMasterResource, $rootScope) {
    $scope.editClicked = false;
    $scope.itemGroup = _.cloneDeep(item);
    $scope.tab = 1;
    $scope.cancel = cancel;
    $scope.editItemGroup = editItemGroup;
    $scope.updateItemGroup = updateItemGroup;
    $scope.refreshItemCodes = refreshItemCodes;
    $scope.onSelectItemCodes = onSelectItemCodes;
    $scope.onRemoveItemCodes = onRemoveItemCodes;
    $scope.onSelectItemCategories = onSelectItemCategories;
    $scope.onRemoveItemCategories = onRemoveItemCategories;
    $scope.refreshItemCategories = refreshItemCategories;
    $scope.updateStatus = updateStatus;
    $scope.editing = false;

    $scope.selectedItemCodes = [];
    $scope.selectedItemCategories = [];
    $scope.itemCodesList = [];
    $scope.historyDependencies = {
        embeddedParams: {
            'object[0][object_id]': $scope.itemGroup._id,
            'object[0][object_type]': 'App\\Metabuyer\\ItemGroup\\Models\\ItemGroup',
            offset: 5,
            order_by: '-created_at'
        }
    };
    $scope.toggleTab = toggleTab;

    function toggleTab(tab) {
        $rootScope.$broadcast('reloadAuditData');
        return $scope.tab = tab;
    }

    function cancel() {
        $uibModalInstance.close();
    }

    function editItemGroup() {
        $scope.editClicked = true;
    }

    function refreshItemCodes(val) {
        if (!!val && val.length > 2) {
            itemCodesListing(val, $scope.selectedItemCodes).then(function (response) {
                $scope.itemCodesList = response;
            })
        }
    }

    function onSelectItemCodes(itemCode){
        $scope.selectedItemCodes.push({
            _id: itemCode._id,
            code: itemCode.code,
            name: itemCode.name
        });
        // Clear typeahead array when selecting
        $scope.itemCodesList = [];
    }

    function onRemoveItemCodes(code){
        var delIndex = _.findIndex($scope.selectedItemCodes, function(e){
            return e._id === code._id;
        });
        $scope.selectedItemCodes.splice(delIndex, 1);
    }

    function onSelectItemCategories(category){
        $scope.selectedItemCategories.push({
            _id: category._id,
            code: category.code,
            name: category.name,
            descr: category.descr
        });
        // Clear typeahead array when selecting
        $scope.itemCategoriesList = [];
    }

    function updateItemGroup() {
        var data = preparePayload();
        itemGroupMasterResource.put(
            { uuid: $scope.itemGroup.uuid },
            data,
            function (success) {
                toastr.success('Item Group successfully created.');
                $uibModalInstance.close(success);
            },
            function (error) {
                globalFunc.objectErrorMessage(error);
            }
        );
    }

    function onRemoveItemCategories(cat){
        var delIndex = _.findIndex($scope.selectedItemCategories, function(e){
            return e._id === cat._id;
        });
        $scope.selectedItemCategories.splice(delIndex, 1);
    }

    function refreshItemCategories(val){
        if (!!val && val.length > 2){
            itemCategoriesListing(val, $scope.selectedItemCategories).then(function(response){
                $scope.itemCategoriesList = response;
            })
        }
    }

    function updateStatus() {
        itemGroupMasterUpdateStatus.put(
            { uuid: $scope.itemGroup.uuid },
            { is_active: $scope.itemGroup.is_active },
            function () {
                toastr.success('Status updated successfully');
            },
            function (err) {
                //revert
                $scope.itemGroup.is_active = !$scope.itemGroup.is_active;
                globalFunc.objectErrorMessage(err);
            }
        );
    }

    function preparePayload() {
        return {
            name: $scope.itemGroup.name,
            item_codes: $scope.selectedItemCodes.length > 0 ? $scope.selectedItemCodes : undefined,
            item_categories: $scope.selectedItemCategories.length > 0 ? $scope.selectedItemCategories : undefined
        };
    }

    function init() {
        _.forEach(item.item_codes, function (singleItem) {
            onSelectItemCodes(singleItem);
        });

        _.forEach(item.item_categories, function (singleItem) {
            onSelectItemCategories(singleItem);
        });
    }

    init();
}

itemGroupDetailsCtrl.$inject = ['$scope', '$uibModalInstance', 'item', 'itemCodesListing', 'itemCategoriesListing',
                                'itemGroupMasterUpdateStatus', 'globalFunc', 'toastr', 'itemGroupMasterResource', '$rootScope'];

angular
  .module('metabuyer')
  .controller('itemGroupDetailsCtrl', itemGroupDetailsCtrl);
