'use strict';
angular
  .module('metabuyer')
  .filter('noFractionCurrency',
  ['$filter', '$locale', function(filter, locale) {
    var currencyFilter = filter('formatDecimal');
    var formats = locale.NUMBER_FORMATS;
    return function(amount) {
      var value = currencyFilter(amount);
      var sep = value.indexOf(formats.DECIMAL_SEP);
      if(amount >= 0) {
        return value.substring(0, sep);
      }
      return value.substring(0, sep) + ')';
    };
  }])
  .filter('decimalCurrency',
  ['$filter', '$locale', function(filter, locale) {
    var currencyFilter = filter('formatDecimal');
    var formats = locale.NUMBER_FORMATS;
    return function(amount) {
      var value = currencyFilter(amount);
      var sep = value.indexOf(formats.DECIMAL_SEP);
      if(amount >= 0) {
        return '.' + value.split(formats.DECIMAL_SEP)[1];
      }
    };
  }]);