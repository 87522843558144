'use strict';

function reoutboundToolCtrl ($scope, $state, toastr, reoutboundTool, globalFunc) {
  $scope.submit = submit;
  $scope.confirmSubmit = confirmSubmit;
  $scope.onSelectModule = onSelectModule;
  $scope.module = {};
  $scope.poNumbers = undefined;
  $scope.errorMessage = '';

  $scope.modules = [
    {
      name: 'Purchase Order',
      value: 'purchase-order'
    },
    {
      name: 'Delivery Order',
      value: 'delivery'
    }
  ];

  function onSelectModule(module) {
    $scope.selectedModule = module.value;
    reoutboundTool.get({
      module: $scope.selectedModule
      },
      function (resource) {
        $scope.dataList = resource.data;
        $scope.meta = resource;
      }, function (error) {
        globalFunc.objectErrorMessage(error);
        $scope.dataList = [];
      });
  }

  function submit() {
    reoutboundTool.put(
      {},
      {
        module: $scope.selectedModule,
        po_numbers: $scope.poNumbers
      }, function(response) {
        $scope.errorMessage = '';
        toastr.success(response.message);
      }, function(error) {
        $scope.errorMessage = error.data.content.error;
        globalFunc.objectErrorMessage('Failed to re-outbound PO.');
      }
    );
    reoutboundTool.get({
        module: $scope.selectedModule
      },
      function (resource) {
        $scope.dataList = resource.data;
        $scope.meta = resource;
      }, function (error) {
        globalFunc.objectErrorMessage(error);
        $scope.dataList = [];
      });
  }

  function confirmSubmit() {
    var error = false;
    if (!$scope.module.selected) {
      toastr.error('Please select a module');
      error = true;
    }
    if (!$scope.poNumbers) {
      toastr.error('Please fill in the PO numbers');
      error = true;
    }
    if (error) {
      return;
    }

    swal({
      title: 'Confirm to re-outbound POs?',
      text: 'This action is not revertable and will be recorded.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Confirm',
      closeOnConfirm: true
    }, function (isConfirm) {
      if (isConfirm) {
        submit();
      }
    });
  }
}
reoutboundToolCtrl.$inject = ['$scope', '$state', 'toastr', 'reoutboundTool', 'globalFunc'];

angular
  .module('metabuyer')
  .controller('reoutboundToolCtrl', reoutboundToolCtrl);
