/**
 * @name loadingFullWidth
 *
 * @description
 *
 * Loading with full width to prevent an user action
 * todo: move to active/nonactive this directive to http-request
 *
 */

'use strict';

angular
  .module('metabuyer')
  .directive('loadingFullWidth',function() {
    return {
      restrict: 'E',
      templateUrl: 'components/loadingFullWidth/loading-full-width.html',
      scope: {
        data: '='
      },
      controller: function () {
        // nothing to do now, only html
      }
    }
  });
