'use strict';

function tenderVoHistory(
  $scope, globalFunc, $rootScope, $uibModal, $state, HighlightId, pathConstants, $filter, tenderFunction, tenderService, toastr, $http
) {

  $scope.historyTabs = $scope.histories.histories;
  $scope.onClickTab = onClickTab;
  $scope.selectedTab = 0;

  function onClickTab(index) {
    $scope.selectedTab = index;
  }

  function initialize() {

  }

  initialize();
}

tenderVoHistory.$inject = [
  '$scope', 'globalFunc', '$rootScope', '$uibModal', '$state', 'HighlightId', 'pathConstants', '$filter', 'tenderFunction', 'tenderService', 'toastr', '$http'
];

angular
  .module('metabuyer')
  .controller('tenderVoHistory', tenderVoHistory);
