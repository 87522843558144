/**
 * @name userSettings
 *
 * @description
 *
 * This service handles storing, modifying and removing the user's settings across Metabuyer. Also, storing and verifying the data in the local storage.
 *
 * @requires anTinycon
 * @requires $rootScope
 * @requires ManageLocalStorage (Metabuyer service)
 *
 *
 * @function loggedIn
 * This function 'will' be used once a preference is stored and will be applied on the System
 *
 * @function loggedOut
 * This function is called once a user logging out, or forced to logged out.
 * this function will clean the following:
 * 1- favicon, reset it to 0
 * 2- local storage (which can be updated upon once the user has the ability to store preferences)
 * 3- remove the Cached profile as well as the Cost Center
 * 4- remove the Token
 *
 *
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Verve Technologies Sdn. Bhd. &copy; 2015
 */

'use strict';

function userSettings (anTinycon, $rootScope, ManageLocalStorage, metabuyerCache) {

  var settings = {};
  settings.loggedIn = loggedIn;
  settings.loggedOut = loggedOut;

  function loggedIn() {
  }

  function loggedOut() {
    anTinycon.setBubble(0);
    $rootScope.currentUser = {};
    metabuyerCache.removeAll();
    ManageLocalStorage.clearLocalStorage();
    window.localStorage.removeItem('satellizer_token');
    // Remove user setting class for font weight upon logout
    $('body').removeClass('user-setting-font-weight-bold');

    // reset redirect url
    $rootScope.redirectUrl = '';
  }
  return settings;
}
userSettings.$inject = ['anTinycon', '$rootScope', 'ManageLocalStorage', 'metabuyerCache'];

angular
  .module('metabuyer')
  .factory('userSettings',userSettings);
