'use strict';

function bifrostEntriesCtrl($scope, $uibModalInstance, data, globalFunc) {

  $scope.entries = data.entries;
  $scope.close = close;

  function close(){
    $uibModalInstance.close();
  }


  function initialize() {

  }

  initialize();
}

bifrostEntriesCtrl.$inject = ['$scope', '$uibModalInstance', 'data', 'globalFunc'];

angular.module('metabuyer')
  .controller('bifrostEntriesCtrl', bifrostEntriesCtrl);
