'use strict';


function itemDetail (){
  return {
    restrict: 'E',
    templateUrl: 'components/purchaseRequisitionV2Components/itemDetail/itemDetail.html',
    scope: {
      item:'='
    },
    controller: function($scope, prV2Function) {
      $scope.PRData = PRData;
      $scope.getTotalWithOtherCharges = getTotalWithOtherCharges;
      var otherChargesField = [
        'freight',
        'transportation',
        'insurance',
        'bahan_bakar_tax',
        'withholding_tax',
        'miscellaneous'
      ];

      function PRData() {
        var localPRData = prV2Function.getPRData();
        return localPRData;
      }

      function getTotalWithOtherCharges(){
        var total = $scope.item.total_amount_pr_currency;
        _.forEach(otherChargesField, function(otherCharge){
          if (!_.isEmpty($scope.item[otherCharge])){
            total += $scope.item[otherCharge].total_amount_pr_currency;
          }
        });
        return total;
      }
    }
  }
}

angular
  .module('metabuyer')
  .directive('itemDetail', itemDetail);
