'use strict';

/**
 * @name budgetDetailsHeader Directive
 * @desc Directive for displaying budget details header, editable and non editable
 *
 * @author Justin Cheong <justin.cty90@gmail.com>
 * @copyright 2017 Metacloud Sdn. Bhd.
 */
angular
  .module('metabuyer')
  .directive('budgetDetailsHeader', function () {
    return {
      restrict: 'E',
      scope: true,
      template: '<div ng-include="budgetTemplateUrl"></div>',
      controller: function ($scope) {
        $scope.getTemplateUrl = getTemplateUrl;
        $scope.showBudgetItems = showBudgetItems;
        $scope.hasParentBudget = hasParentBudget;
        $scope.changeAssignmentDashboardSetting = changeAssignmentDashboardSetting;
        $scope.showItemListing = showItemListing;
        $scope.convertAbsolute = convertAbsolute;

        function changeAssignmentDashboardSetting(dashboard) {
          _.forEach($scope.budget.assignments, function (assignment) {
            assignment.pr_dashboard_display = dashboard;
          });
        }

        function showBudgetItems() {
          $scope.showBudgetItemListing = !$scope.showBudgetItemListing;
        }

        /**
         * Check if budget is a revision
         */
        function hasParentBudget() {
          return !!$scope.budget.parent_id;
        }

        /**
         * Indicator to show item listing
         */
        function showItemListing() {
          // don't show if budget is revised budget or status is in pending/on-hold/draft/revision
          return !$scope.budget.parent_id &&
            ['pending', 'on_hold', 'draft', 'revision'].indexOf($scope.budget.status) === -1;
        }

        function convertAbsolute(num) {
          return Math.abs(num);
        }

        function getTemplateUrl(state) {
          switch (state) {
            case 'editable':
              $scope.budgetTemplateUrl = 'components/budget/detailsHeader/detailsHeader.editableTemplate.html';
              break;
            case 'notEditable' :
              $scope.budgetTemplateUrl = 'components/budget/detailsHeader/detailsHeader.nonEditableTemplate.html';
              break;
          }
        }

        // Watches the getEditingBudgetState to determine editable/non editable template
        $scope.$watch('getEditingBudgetState()', function (newValue) {
          getTemplateUrl(newValue);
        });
      }
    }
  });
