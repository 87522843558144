'use strict';

function activitiesDetailsCtrl(activity, $scope, globalFunc) {
  $scope.singleActivity = activity;
  $scope.buckets = [];


  /**
   * find which one is
   * @param obj
   */
  function checkForRecords(obj) {
    var arr = $scope.singleActivity.monthly_pr_amount;
    _.forEach(arr, function (monthPRAmount) {
      if (monthPRAmount._id.month === obj.month) {
        obj.sum = monthPRAmount.sum;
      }
    })
  }

  function init() {
    $scope.$emit('updatedActivityTitle', $scope.singleActivity.reference);
    //prepare the month and year for activity
    var today = new Date();
    var month = today.getMonth();
    ++month; //because fuck javascript
    var year = today.getFullYear();
    year = year.toString();

    if (year === $scope.singleActivity.year) {
      $scope.singleActivity.calculatedMonth = globalFunc.getMonthText(month, 'name');
      $scope.singleActivity.month = globalFunc.getMonthText(month, 'number');
      checkForRecords($scope.singleActivity);
      $scope.singleActivity.currentMonthValue = $scope.singleActivity.sum;
    } else {
      $scope.singleActivity.calculatedMonth = '-';
      $scope.singleActivity.currentMonthValue = '-';
    }


    //prepare the monthly view
    var basicObject = {
      found: false,
      company_code: '',
      cost_center_code: '',
      month: '',
      year: '',
      sum: 0
    };

    var i = 1;
    while (i <= 12) {
      var obj = _.cloneDeep(basicObject);
      obj.company_code = activity.company.code;
      obj.cost_center_code = activity.cost_center.code;
      obj.year = activity.year;
      obj.month = globalFunc.getMonthText(i, 'number');
      checkForRecords(obj);
      $scope.buckets.push(obj);
      i++;
    }
  }

  init();
}


activitiesDetailsCtrl.$inject = ['activity', '$scope', 'globalFunc'];

angular.module('metabuyer')
  .controller('activitiesDetailsCtrl', activitiesDetailsCtrl);
