'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function ($stateProvider) {
    $stateProvider
      .state('main.user.minion', {
        url: '/minion',
        controller: 'MinionCtrl',
        templateUrl: 'app/minion/minion.html',
        resolve: {
          accessRights: function (profile, globalFunc, $state) {
            if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN')) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.minion.dashboard', {
        url: '/dashboard',
        controller: 'MinionDashboardCtrl',
        templateUrl: 'app/minion/dashboard/dashboard.html',
        resolve: {
          $title: function () {
            return 'Dashboard';
          }
        }
      })
      .state('main.user.minion.config', {
        url: '/config',
        controller: 'MinionConfigCtrl',
        templateUrl: 'app/minion/config/config.html',
        resolve: {
          $title: function () {
            return 'Config';
          }
        }
      });
  }]);
