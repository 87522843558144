'use strict';

function bifrostProcessStatusManageCtrl($scope, toastr, pathConstants, $http, bifrostProcessStatus , dataList, $state , $stateParams) {

  $scope.searchParams = {};
  $scope.showColumn = showColumn;
  $scope.searchProcessStatus = searchProcessStatus;
  $scope.resetFilter = resetFilter;

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        return $scope.columns[i].selected;
      }
    }
  }

  function searchProcessStatus() {

    var params = {
      searchErpType: $scope.searchParams.erp_type,
      searchModule: $scope.searchParams.module,
      searchFileName: $scope.searchParams.file_name,
      searchProcessingStatus: $scope.searchParams.processing_status,
      searchTransferStatus: $scope.searchParams.transfer_status,
      skipInitialData : true
    };
    
    $state.go('main.user.bifrostProcessStatus.manage', {params: params} , {inherit: false});
  }

  function resetFilter() {
    if(!!$state.params && !_.isEmpty($state.params.params)){
      $scope.searchParams.file_name = null;
      $scope.searchParams.erp_type = null;
      $scope.searchParams.module = null;
      $scope.searchParams.processing_status = null;
      $scope.searchParams.transfer_status = null;

      $state.go('main.user.bifrostProcessStatus.manage', {} , {inherit: false});
    }
  }

  function initialize() {

    $scope.dataList = dataList.data;
    $scope.meta = dataList.meta; 
    $scope.storageKey = 'bifrost-process-status-selected-columns';
    $scope.erpTypes = dataList.erp_types;
    $scope.moduleList = dataList.modules;
    $scope.processStatus = dataList.processing_status;
    $scope.transferStatus = dataList.transfer_status;

    if(!!$state.params && !_.isEmpty($state.params.params)){
      $scope.searchParams.file_name = $state.params.params.searchFileName
      $scope.searchParams.erp_type = $state.params.params.searchErpType
      $scope.searchParams.module = $state.params.params.searchModule
      $scope.searchParams.processing_status = $state.params.params.searchProcessingStatus
      $scope.searchParams.transfer_status = $state.params.params.searchTransferStatus
    }
  }

  initialize();
}

bifrostProcessStatusManageCtrl.$inject = ['$scope', 'toastr', 'pathConstants', '$http', 'bifrostProcessStatus', 'dataList', '$state', '$stateParams'];

angular
  .module('metabuyer')
  .controller('bifrostProcessStatusManageCtrl', bifrostProcessStatusManageCtrl);
