'use strict';

/**
 * @name massUpdateStatusModal
 * @desc The controller for mass update budget owner
 *
 * @author Nazrul Wazir <nazrulhmw@sunway.com.my>
 * @copyright 2023 Metacloud Sdn. Bhd.
 */
function massUpdateStatusModalCtrl($rootScope, $scope, $uibModalInstance, $uibModal, $http, $filter, pathConstants, toastr, selectedBudgets, success, $state, budgetOwner, failedData, data) {
  $scope.selectedBudgets = selectedBudgets;
  $scope.success = success;
  $scope.close = close;
  $scope.budgetOwner = budgetOwner;
  $scope.failedData = failedData;
  $scope.data = data;

  function close() {
    $uibModalInstance.close();

    if (!$scope.success) {
      $uibModal.open({
        templateUrl: 'app/maintenance/massUpdateBudgetOwner/massUpdateModal/massUpdateModal.html',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          data: function() {
            return $scope.data;
          },
          selectedBudgets: function() {
            return $scope.selectedBudgets;
          }
        },
        controller: 'massUpdateModalCtrl'
      });
    } else {
      $state.reload();
    }
  }

}

massUpdateStatusModalCtrl.$inject = ['$rootScope', '$scope', '$uibModalInstance', '$uibModal', '$http', '$filter', 'pathConstants', 'toastr', 'selectedBudgets', 'success', '$state', 'budgetOwner', 'failedData', 'data'];

angular.module('metabuyer')
  .controller('massUpdateStatusModalCtrl', massUpdateStatusModalCtrl);
