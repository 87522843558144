'use strict';
/**
 * @name addBUCtrl
 * @desc Controller for add BU
 */
function addBUCtrl($scope, $q, $resource, $state, $location, $anchorScroll, pathConstants, listingBusinessUnits,
                                 toastr, globalFunc, $rootScope) {

  $scope.submitted = false;
  $scope.duplicationCheck = duplicationCheck;
  $scope.cancelAddBU = cancelAddBU;

  $scope.BU = {};

  $scope.response_error = {};

  function validateInputData(){

    if(!$scope.BU.name || !$scope.BU.name.length) {
      return false;
    }

    if(!$scope.BU.code || !$scope.BU.code.length) {
      return false;
    }

    return true;
  }

  function cancelAddBU(){
    $state.go('main.user.companiesMgmt.manageBusinessUnits');
  };

  //upload company detail
  $scope.addBU = function(){

    $scope.submitted = true;

    if(validateInputData() == false){
      toastr.error('Please fill all required fields before proceeding');
      return;
    }

    var submit_data = {
      descr: $scope.BU.name,
      code: $scope.BU.code
      };

    listingBusinessUnits.post(
      {},
      submit_data,
      function (resource) {
        toastr.success("Business Unit has been added");
        $state.go("main.user.companiesMgmt.manageBusinessUnits");
      },
      function (err) {
        if (typeof err.data.message === 'string') {
          toastr.error(err.data.message);
        } else {
          // loop the error object
          angular.forEach(err.data.message, function (errorDatas) {
            // Get the object key
            angular.forEach(errorDatas, function () {
              // show the array value of the Error from the json
              toastr.error(errorDatas[0]);
            });
          });
        }
      }
    )
  };

  $scope._ = {
    isEmpty: _.isEmpty
  };

  /**
   * @function duplicationCheck
   * Calls the global function's duplication check
   * Checks callback function return for assigning scope values for validation
   **/
  function duplicationCheck(model,field,value,checker){
    globalFunc.duplicationCheck(model,field,value,checker, function(callback){
      switch (callback){
        case 'codeCheckTrue':
          $scope.codeCheck = true;
          break;
        case 'codeCheckFalse':
          $scope.codeCheck = false;
          break;
      }
    });
  }

  function initialize(){
    var tabData = {
      tab: $scope.$parent.tabData[3],
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);
  }

  initialize();
}

addBUCtrl.$inject = ['$scope', '$q', '$resource', '$state', '$location', '$anchorScroll',
  'pathConstants', 'listingBusinessUnits', 'toastr', 'globalFunc', '$rootScope'];

angular
  .module('metabuyer')
  .controller('addBUCtrl', addBUCtrl);


