'use strict';

angular.module('metabuyer.services.auditTrail', ['ngResource'])
  .factory('auditTrailList',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.auditTrail.list,
      {
        type: '@type'
      },
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('singleAuditTrail',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.auditTrail.single,
      {
        id: '@id'
      },
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('auditControllers',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.auditTrail.controllers,
        {},
        {
          'get': { method: 'GET' }
        }
      );
    })
  .factory('auditControllersActions',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.auditTrail.actions,
      {
        controller: '@controller'
      },
      {
        'get': {method: 'GET'}
      }
    );
  });
