/**
 * @name companyGroupsManagementCtrl
 *
 * @requires $scope
 * @requires searchModule
 * @requires $timeout
 * @requires $uibModalInstance
 * @requires globalFunc
 * @requires dataList
 * @requires companyGroupsList
 * @requires companyGroupsAction
 *
 * @description Controller for the Modal of Company Groups Management
 *
 *
 * @authors Ahmed Saleh (a.s.alsalali@gmail.com)
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

function companyGroupsManagementCtrl ($scope, dataList, companyGroup, $timeout, companyGroupsList, $uibModalInstance, companyGroupsAction, globalFunc, pathConstants, $uibModal, toastr) {

  $scope.importLink = pathConstants.apiUrls.imports.now;

  function close(){
    $uibModalInstance.close();
  }

  /**
   * adding groups function
   * @param title
   */
  function addGroup(code, descr) {
    $scope.saving = true;
    companyGroupsList.post({
      code: code,
      descr: descr
    }, function (resource) {
      $scope.saving = false;
      if (!!resource.content && !!resource.content.data) {
        $scope.selectedGroup = resource.content.data;
        $scope.groupsList.push($scope.selectedGroup);
      }
    }, function (error) {
      globalFunc.objectErrorMessage(error);
    });
  }

  /**
   * add a new object for groups to work with
   */
  function createGroup() {
    $scope.selectedGroup = [];
  }

  /**
   * update the title for the group
   * @param title
   * @param id
   */
  function updateGroupTitle(descr, id){
    $scope.saving = true;
    companyGroupsList.put({
      groupId: id,
      code: $scope.selectedGroup.code,
      descr: descr
    }, function (resource) {
      $scope.saving = false;
      var temp = findIndex();
      if(temp > 0) {
        $scope.groupsList[temp].descr = descr
      }
    }, function (error) {
      $scope.saving = false;
      /**
       * returning data to original state
       */
      var temp = findIndex();
      if(temp > 0) {
        $scope.groupsList[temp].code = dataList[temp].code;
        $scope.groupsList[temp].descr = dataList[temp].descr;
      }
      globalFunc.objectErrorMessage(error);
    });
  }


  /**
   * assign the selected group as active one to work with
   * @param group
   */
  function selectGroup(group) {
    $scope.selectedGroup = angular.copy(group);
    loadGroupsCompanies(group);
  }

  /**
   * load the companies once a group is selected
   * @param group
   */
  function loadGroupsCompanies(group) {
    $scope.selectedGroup.companies = [];
    var temp;
    _.forEach($scope.companies, function (company) {
      temp = checkCompany(company, group);
      if (!!temp) {
        $scope.selectedGroup.companies.push(temp);
      }
    });
    $scope.selectedGroup.companies = globalFunc.sortObjectsArray($scope.selectedGroup.companies, 'descr');
  }

  /**
   * check inside the company array
   * @param company
   * @param group
   * @returns {*}
   */
  function checkCompany(company, group) {
    var temp = null;
    if (!!company.catalog_groups) {
      _.forEach(company.catalog_groups, function (companyGroup) {
        if (companyGroup._id === group._id) {
          temp = company;
        }
      });
    }
    return temp;
  }

  /**
   * add / remove a company from a group
   * @param data
   * @param action
   */
  function updateCompanies(data, action){
    if(!!$scope.selectedGroup && !!$scope.selectedGroup._id) {
      $scope.saving = true;
      companyGroupsAction.put({
          groupId: $scope.selectedGroup._id,
          companyId: data.code
        },
        {
          action: action
        },
        function (resource) {
          if(action === 'assign')
            toastr.success('Company assigned');
          else
            toastr.success('Company unassigned');

          $scope.saving = false;
          if (!data.catalog_groups) {
            data.catalog_groups = [];
          }
          /**
           * adding/removing the group to/from the company model, so there is no need to reload the data
           */
          if(action === 'assign') {
            data.catalog_groups.push($scope.selectedGroup);
          }
          else{
            data.catalog_groups = globalFunc.removeValueFromArray(data.catalog_groups, '_id', $scope.selectedGroup._id);
          }
          data.catalog_groups = globalFunc.sortObjectsArray(data.catalog_groups, 'descr')
        },
        function (error) {
          $scope.saving = false;
          /**
           * returning data to original state
           */
          var temp = findIndex();
          if (temp > 0) {
            $scope.groupsList[temp].companies = dataList[temp].companies;
          }

          globalFunc.objectErrorMessage(error);
        });
    }
  }

  function importCatalogGroup() {
    var modalInstance = $uibModal.open({
      templateUrl: 'app/masterDataManagement/Import-Template.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        importLink: function () {
          return $scope.importLink;
        },
        title: function () {
          return 'Catalog Group Assignment';
        }
      },
      controller: function ($scope, $uibModalInstance, importLink, title) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        $scope.importLink = importLink;
        $scope.title = title;
        $scope.params = {
          class: 'Metabuyer\\Models\\CatalogGroup',
          'additional_params[]': ''
        };
      }
    });
  }


  /**
   * cool down timer to update the selected Group
   * @param type
   * @param data
   */
  function saveChanges(code, descr){

    $timeout.cancel(countdown);
    countdown =
      $timeout(function(){
        /**
         * Adding different Actions here.
         */

          if(!code || code.trim() === ''){
            globalFunc.objectErrorMessage('Please Add a code');
          }else if(!descr || descr.trim() === ''){
            globalFunc.objectErrorMessage('Please Add a description');
          }
          else {
            if(!$scope.selectedGroup._id){
              addGroup(code, descr);
            }else{
              updateGroupTitle(descr,$scope.selectedGroup._id);
            }
          }

      }, 3000);
  }

  /**
   * deleting the groups and remove it from the List
   */
  function deleteGroup(){
    $scope.saving = true;
    companyGroupsList.delete({
      groupId: $scope.selectedGroup._id
    }, function (resource) {
      $scope.saving = false;
      var temp = findIndex();
      if(temp > 0) {
        $scope.groupsList.splice(temp, 1);
      }
    }, function (error) {
      globalFunc.objectErrorMessage(error);
    });
  }

  function findIndex(){
    for(var i in $scope.groupsList){
      if ($scope.groupsList[i]._id === $scope.selectedGroup._id){
        return i;
      }
    }
    return -1;
  }


  function initialize(){
    $scope.showDeleteButton = false;
  }

  initialize();

  $scope.groupsList         = dataList.data;
  $scope.companies          = companyGroup.data;
  $scope.selectGroup        = selectGroup;
  $scope.deleteGroup        = deleteGroup;
  $scope.saveChanges        = saveChanges;
  $scope.createGroup        = createGroup;
  $scope.importCatalogGroup = importCatalogGroup;
  $scope.updateCompanies    = updateCompanies;
  $scope.close              = close;
  var countdown;


}

companyGroupsManagementCtrl.$inject = ['$scope', 'dataList', 'companyGroup', '$timeout', 'companyGroupsList', '$uibModalInstance', 'companyGroupsAction', 'globalFunc', 'pathConstants', '$uibModal', 'toastr'];

angular.module('metabuyer')
  .controller('companyGroupsManagementCtrl', companyGroupsManagementCtrl);
