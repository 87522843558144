'use strict';

function tenderCatalogCtrl(
  $scope, globalFunc, $rootScope, $uibModal, $state, HighlightId, pathConstants, $filter, tenderFunction, tenderService, toastr, $http
) {

  function initialize() {
    $scope.catalogs = tenderFunction.getCatalogs();
  }

  initialize();
}

tenderCatalogCtrl.$inject = [
  '$scope', 'globalFunc', '$rootScope', '$uibModal', '$state', 'HighlightId', 'pathConstants', '$filter', 'tenderFunction', 'tenderService', 'toastr', '$http'
];

angular
  .module('metabuyer')
  .controller('tenderCatalogCtrl', tenderCatalogCtrl);
