'use strict';

/**
 * @name massUpdateModalCtrl
 * @desc The controller for mass update budget owner
 *
 * @author Nazrul Wazir <nazrulhmw@sunway.com.my>
 * @copyright 2023 Metacloud Sdn. Bhd.
 */
function massUpdateModalCtrl($rootScope, $scope, $uibModalInstance, $uibModal, $http, $filter, pathConstants, data, selectedBudgets, toastr, massUpdateBudgetOwner, globalFunc) {

  $scope.data = data;
  $scope.selectedBudgets = selectedBudgets;
  $scope.close = close;
  $scope.budgetOwnerSearchText = '';
  $scope.validBudgetOwnerIsSelected = true;
  $scope.invalidBudgetOwnerSelected = false;
  $scope.searchBudgetOwner = searchBudgetOwner;
  $scope.validateFieldSelection = validateFieldSelection;
  $scope.onBudgetOwnerSelect = onBudgetOwnerSelect;
  $scope.budgetOwner = null;
  $scope.submitBudgetOwner = submitBudgetOwner;

  function submitBudgetOwner() {

    if (_.isNull($scope.budgetOwner)) {
      toastr.error('Please select a user to be assigned as new Budget Owner');
      return false;
    }

    if ($scope.data.noBudgetOwner) {
      var text = 'Update Budget Owner for ' + $scope.selectedBudgets.length + ' Budgets ' + ' to "' + $scope.budgetOwner.display_name + '"';
    } else {
      var text = 'Update Budget Owner for ' + $scope.selectedBudgets.length + ' Budgets from "' + $scope.data.budgetOwner.display_name + '" to "' + $scope.budgetOwner.display_name + '"';
    }

    swal({
        title: 'Confirm Update',
        text: text,
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function(isConfirm) {
        if (isConfirm) {

          var budgetIds = [];

          $scope.selectedBudgets.forEach(function(budget) {
            budgetIds.push(budget._id)
          })

          massUpdateBudgetOwner.post({
              user_id: $scope.budgetOwner._id,
              budget_ids: budgetIds
            },
            function(resource) {
              close();
              $uibModal.open({
                templateUrl: 'app/maintenance/massUpdateBudgetOwner/massUpdateStatusModal/massUpdateStatusModal.html',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                  selectedBudgets: function() {
                    return $scope.selectedBudgets;
                  },
                  success: function() {
                    return true;
                  },
                  budgetOwner: function() {
                    return $scope.budgetOwner
                  },
                  failedData: function() {
                    return []
                  },
                  data: function() {
                    return $scope.data
                  }
                },
                controller: 'massUpdateStatusModalCtrl'
              });
            },
            function(error) {
              if (error.data.http_code === 400) {
                close();
                $uibModal.open({
                  templateUrl: 'app/maintenance/massUpdateBudgetOwner/massUpdateStatusModal/massUpdateStatusModal.html',
                  backdrop: 'static',
                  keyboard: false,
                  resolve: {
                    selectedBudgets: function() {
                      return $scope.selectedBudgets;
                    },
                    success: function() {
                      return false;
                    },
                    budgetOwner: function() {
                      return $scope.budgetOwner
                    },
                    failedData: function() {
                      return error.data.content.data
                    },
                    data: function() {
                      return $scope.data
                    }
                  },
                  controller: 'massUpdateStatusModalCtrl'
                });
              } else {
                globalFunc.objectErrorMessage(error);
              }
            })
        }
      }
    );
  }

  function close() {
    $uibModalInstance.close();
  }

  function onBudgetOwnerSelect(budgetOwner) {
    return $scope.budgetOwner = budgetOwner;
  }

  function validateFieldSelection(field) {}

  function searchBudgetOwner(val) {
    if (!!val && val.length > 2) {

      var moduleParam = {
        module: 'users'
      };

      var additionalParams = {
        'criteria[0][display_name]': val,
        'criteria[0][is_metabuyer_user]': true,
        'criteria[0][status]': 1,
        criteria_operator: 'and',
        offset: 100
      };

      return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: additionalParams
      }).then(function(response) {
        $scope.loading = false;
        return response.data.content.data.map(function(item) {
          $scope.loading = false;
          return item;
        });
      }, function(error) {
        globalFunc.objectErrorMessage(error);
      });
    }
  }
}

massUpdateModalCtrl.$inject = ['$rootScope', '$scope', '$uibModalInstance', '$uibModal', '$http', '$filter', 'pathConstants', 'data', 'selectedBudgets', 'toastr', 'massUpdateBudgetOwner', 'globalFunc'];

angular.module('metabuyer')
  .controller('massUpdateModalCtrl', massUpdateModalCtrl);
