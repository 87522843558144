'use strict';

angular.module('metabuyer.services.sourcingProject', ['ngResource'])
  .factory('sourcingProjectMasterResource',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.sourcingProject.single,
        {
          uuid: '@uuid'
        },
        {
          'post': {method: 'POST'},
          'put': {method: 'PUT'}
        }
      );
    })
  .factory('sourcingProjectMasterUpdateStatus',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.sourcingProject.updateStatus,
        {
          uuid: '@uuid'
        },
        {
          'put': {method: 'PUT'}
        }
      );
    });
