'use strict';

function editContactCtrl($scope, $uibModalInstance, toastr, Contacts, globalFunc, $rootScope) {

  $scope.checkInput = checkInput;

  //cancel action
  $scope.cancel = function () {
    $uibModalInstance.close();
  };

  function checkInput() {
    if ($scope.isV2FeatureEnabled) {
      return !$scope.edit_data.name && !$scope.edit_data.email && !$scope.edit_data.phone && !$scope.edit_data.fax;
    }

    return false;
  }

  function validateContact() {
    if (!$scope.edit_data.name && !$rootScope.isV2Enabled) {
      return false;
    }

    if (!$scope.edit_data.phone && !$rootScope.isV2Enabled) {
      return false;
    }

    if (!$scope.isValidPhoneNo && !$rootScope.isV2Enabled) {
      return false;
    }

    // temporary fix
    // if (!$scope.isValidFaxNo) {
    //   return false;
    // }

    return true;
  }

  /**
   * Validate number format with given element id and returns boolean in field name
   *
   * @param {string} elementId
   * @param {string} fieldName
   */
  function validateNumbers(elementId, fieldName) {
    $scope[fieldName] = globalFunc.numberFormatValidation(elementId);
  }

  //proceed action
  function updateContactInfo() {
    $scope.clicked_submit = true;
    if (!validateContact()) {
      toastr.error('Please fill all required fields');
      return;
    }

    if (!$scope.isValidFaxNo) {
      toastr.error('Invalid Fax Number');
      return;
    }

    if (!!$scope.edit_data.email) {
      if (!globalFunc.emailValidation($scope.edit_data.email)) {
        toastr.error('Please enter a valid email address');
        return;
      }
    }

    $scope.updatedContact({contact: $scope.edit_data});

    $uibModalInstance.close();
  }

  function initialize() {
    $scope.edit_data = _.cloneDeep($scope.editContact);
    $scope.validateContact = validateContact;
    $scope.validateNumbers = validateNumbers;
    $scope.updateContactInfo = updateContactInfo;
    $scope.emailFormatValidation = globalFunc.emailValidation;
    $scope.isValidPhoneNo = true;
    $scope.isValidFaxNo = true;
    $scope.isV2FeatureEnabled = $rootScope.isV2Enabled;
  }

  initialize();
}

editContactCtrl.$inject = ['$scope', '$uibModalInstance', 'toastr', 'Contacts', 'globalFunc', '$rootScope'];

angular.module('metabuyer')
  .controller('editContactCtrl', editContactCtrl);
