'use strict';

function companyContractModalCtrl($scope, $rootScope, $uibModalInstance, companies, supplierData, supplierFunctions,
                                  supplierDraft, draftId, updateSupplierCompanyContract, toastr, $state, HighlightId,
                                  editClicked, companyContracts , allowUpdateContractApi
                                  ) {
  $scope.close = close;
  $scope.activate = activate;
  $scope.selectCompany = selectCompany;
  $scope.checkEditableStatus = checkEditableStatus;
  $scope.selectedCompanies = [];
  $scope.companyListPageChanged = companyListPageChanged;
  $scope.numPerPage = 50;
  $scope.maxSize = 5;
  $scope.supplier = supplierData;
  $scope.status = {
    0 : 'Deactivated',
    1 : 'Active'
  };
  $scope.editClicked = editClicked;
  $scope.activated = false;

  function selectCompany(company) {
    company.isSelected = !company.isSelected;
  }

  function activate() {
    swal({
      title: 'Confirmation',
      text: 'Confirm Create Company Contract?',
      showCancelButton: true,
      confirmButtonColor: '#1ab394',
      confirmButtonText: 'Confirm',
      closeOnConfirm: true
    }, function (isConfirm) {
      if (isConfirm) {
        $scope.activated = true;
        // active supplier can update supplier's company contract
        if ($scope.supplier.basic_info.status && allowUpdateContractApi) {
          var param = getSelectedCompanies();
          var supplierId = draftId;

          if($scope.supplier.basic_info.status.toLowerCase() !== 'draft'){
            var supplierId = $scope.supplier._id;
          }

          updateSupplierCompanyContract.put({
            id: supplierId
            },{
            'company_code' : param,
            'status' : $scope.supplier.basic_info.status.toLowerCase()
            }, function (resource) {
            toastr.success('Company contract is updated successfully');
            HighlightId.setId(supplierId);
            $state.go('main.user.supplier.manage', {status: $scope.supplier.basic_info.status.toLowerCase()});
            }
          )
        } else {
          $uibModalInstance.close(getSelectedCompanies());
        }

      }
    })
  }

  function getSelectedCompanies() {
    var selectedCompanies = [];
    _.forEach($scope.companies, function (company) {
      if (company.isSelected === true) {
        selectedCompanies.push(company.company_code);
      }
    });
    return selectedCompanies;
  }

  function close() {
    $uibModalInstance.close();
  }

  function checkEditableStatus(status) {
    // for newly created supplier. no status
    if (!status) {
      return false;
    }

    // allow to edit if in editing state
    if ($scope.editClicked) {
      return false;
    }

    // only draft and active status can edit company list
    var editableStatus = ['active', 'draft'];
    if (_.indexOf(editableStatus, status.toLowerCase()) !== -1) {
      return false;
    }

    return true;
  }

  function loadCompanyContracts() {
    $scope.companies = companies;
    $scope.companyContracts = companyContracts;
  }

  function companyListPageChanged() {
    var begin = (($scope.currentCompanyPage.number - 1) * $scope.numPerPage);
    var end = begin + $scope.numPerPage;
    $scope.filterCompanyList = $scope.companies.slice(begin, end);
  }

  function preparePagination() {
    $scope.currentCompanyPage = {
      number: 1
    };
    $scope.filterCompanyList = $scope.companies.slice(0, $scope.numPerPage);
  }

  function initialize() {
    loadCompanyContracts();
    preparePagination();
    }

  initialize();

}

companyContractModalCtrl.$inject = [
  '$scope', '$rootScope', '$uibModalInstance', 'companies', 'supplierData', 'supplierFunctions',
  'supplierDraft', 'draftId', 'updateSupplierCompanyContract', 'toastr', '$state', 'HighlightId', 'editClicked',
  'companyContracts', 'allowUpdateContractApi'
];

angular
  .module('metabuyer')
  .controller('companyContractModalCtrl', companyContractModalCtrl);
