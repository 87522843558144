'use strict';

/**
 *
 * @param $scope
 * @param $rootScope
 * @param modeOfPurchaseMasterDataList
 * @param $uibModalInstance
 * @param toastr
 * @param globalFunc
 */
function addModeOfPurchaseCtrl($scope, $rootScope, modeOfPurchaseMasterDataList, $uibModalInstance, toastr, globalFunc, $http, $filter, pathConstants) {

  $scope.submitted = false;
  $scope.cancel = cancel;
  $scope.submitModeOfPurchase = submitModeOfPurchase;
  $scope.selectedUser = selectedUser;
  $scope.clearUserLine = clearLine;
  $scope.userListing = userListing;
  $scope.toggleModeOfPurchase = toggleModeOfPurchase;
  $scope.validatePrefix = validatePrefix;

  $scope.modeOfPurcase = {};
  $scope.modeOfPurcase.selectedUserList = [];
  $scope.modeOfPurcase.selectedUserIdList = [];
  $scope.modeOfPurcase.is_active = true;

  function cancel() {
    $uibModalInstance.close();
  }

  function validatePrefix(val){
    if (!(/^[A-Z]?$/.test(val)))
      toastr.error("Prefix can only be a capital letter.")
  }

  function toggleModeOfPurchase(val) {
    $scope.modeOfPurcase.is_active = !!val;
  }

  function selectedUser(val) {
    $scope.userSearch = '';
    var res = val.display_name + ' <' + val.email + '>';
    var userId = val._id;

    if (!!validateUser(userId)) {
      $scope.modeOfPurcase.selectedUserList.push(res);
      $scope.modeOfPurcase.selectedUserIdList.push(userId);
    }
  }

  function clearLine(index) {
    $scope.modeOfPurcase.selectedUserList.pop(index);
    $scope.modeOfPurcase.selectedUserIdList.pop(index);
  }

  function validateUser(userId) {
    if ($scope.modeOfPurcase.selectedUserIdList.length !== 0) {
      for (var i = 0; i < $scope.modeOfPurcase.selectedUserIdList.length; i++) {
        if ($scope.modeOfPurcase.selectedUserIdList[i] === userId) {
          toastr.error('this user is already added');
          return false;
        }
      }
    }
    return true;
  }

  function userListing(val) {
    var moduleParam = {
      module: 'users'
    };

    var additionalParams = {
      'criteria[1][display_name]': val,
      'criteria[1][email]': val,
      'criteria[1][$operator]': 'or',
      'criteria[0][is_metabuyer_user]': true,
      'criteria[0][status]': 1,
      criteria_operator: 'and',
      offset: 10
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams
    }).then(function (response) {
      $scope.loading = false;
      return response.data.content.data.map(function (item) {
        $scope.loading = false;
        return item;
      });
    }, function (error) {
      globalFunc.objectErrorMessage(error);
    });
  }

  function submitModeOfPurchase() {
    if (!!validate($scope.modeOfPurcase)) {
      var data = {
        code: $scope.modeOfPurcase.code,
        name: $scope.modeOfPurcase.name,
        po_creators: $scope.modeOfPurcase.selectedUserIdList,
        status: $scope.modeOfPurcase.is_active,
        prefix: $scope.modeOfPurcase.prefix
      };
      modeOfPurchaseMasterDataList.post(
        data,
        function (success) {
          toastr.success('Mode of purchase created.');
          $uibModalInstance.close(success);
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    }
  }

  function validate(modeOfPurcaseDetails) {
    if (!modeOfPurcaseDetails.code) {
      toastr.error('Code field is empty');
      return false;
    }
    if (!modeOfPurcaseDetails.name) {
      toastr.error('Name field is empty');
      return false;
    }
    if (modeOfPurcaseDetails.selectedUserList.length === 0) {
      toastr.error('PO Creators field is empty');
      return false;
    }
    if (!modeOfPurcaseDetails.prefix) {
      toastr.error('Prefix field is empty');
      return false;
    }
    return true;
  }

}

addModeOfPurchaseCtrl.$inject = ['$scope', '$rootScope', 'modeOfPurchaseMasterDataList', '$uibModalInstance', 'toastr',
  'globalFunc', '$http','$filter', 'pathConstants'];
angular.module('metabuyer')
  .controller('addModeOfPurchaseCtrl', addModeOfPurchaseCtrl);
