/**
 * @name forgotPasswordCtrl
 *
 * @requires $scope
 * @requires forgottenPassword (Metabuyer service)
 * @requires toastr
 *
 * @description
 *
 * Controller to enable the users request a new password to their email
 *
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Metacloud Sdn. Bhd. &copy; 2016
 */

'use strict';

function forgotPasswordCtrl($scope, forgottenPassword, toastr) {
  
  $scope.sendEmail = sendEmail;
  $scope.passwordResent = false;

  /**
   * function to send an email to the server for a request to send a new password to the email of the user.
   * @param email
   */
  function sendEmail(email) {
    forgottenPassword.post({
        email: email
      }
      , function (success) {
        $scope.passwordResent = true;
        if (!!success.content) {
          $scope.message = success.content.message;
        }
      }
      , function (error) {
        if(!!error.data && !!error.data.content){
          toastr.error(error.data.content.error);
        }
      });
  }

}

forgotPasswordCtrl.$inject = ['$scope', 'forgottenPassword', 'toastr'];

angular
  .module('metabuyer')
  .controller('forgotPasswordCtrl', forgotPasswordCtrl);
