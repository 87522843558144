'use strict';
/**
 * @name addSupplierAttachmentCtrl
 *
 * @description
 * Controller for add attachment for Supplier
 *
 * @copyright Sunway Metacloud &copy; 2019
 */

function addSupplierAttachmentCtrl(
  $scope, globalFunc, $uibModalInstance, $http, $filter, pathConstants, supplier, toastr, $translatePartialLoader
) {
  $translatePartialLoader.addPart('main/lang');
  $translatePartialLoader.addPart('suppliersManagement/lang');

  $scope.attachment           = {};
  $scope.fileSizeLimit        = 20;//in MB

  $scope.close                = close;
  $scope.saveAttachment       = saveAttachment;
  $scope.isSaving             = false;
  function close() {
    $uibModalInstance.close();
  }

  /**
   * Save the attachment
   */
  function saveAttachment() {
    if($scope.isSaving){
      return;
    }
    if(exceedSizeLimit()){
      toastr.error('Maximum File Size is 20MB');
      return;
    }
    $scope.isSaving = true;
    uploadAttachment($scope.attachment.file)
      .then(
        function (resource) {
          if (!!resource && !!resource.data && !!resource.data.content && !!resource.data.content.data) {
            var res = resource.data.content.data;
            var addedAttachment = {
              attachment_id: res._id,
              title: res.title,
              uploaded_by: res.creator.display_name,
              uploaded_at: res.created_at
            };
            $uibModalInstance.close(addedAttachment);
          }
          toastr.success('Attachment uploaded successfully');
          $scope.isSaving = false;
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
          $scope.isSaving = false;
        }
      );
  }

  /**
   * Upload attachment
   *
   * @param {object} attachmentFile
   */
  function uploadAttachment(attachmentFile) {
    var fd = new FormData();
    fd.append('file', attachmentFile._file);
    fd.append('model', 'Supplier');
    return $http.post(
      $filter('format')(pathConstants.apiUrls.supplier.attachment.add, {supplier_id: supplier._id}), fd, {
        transformRequest: angular.identity,
        headers: {'Content-Type': undefined}
      }
    );
  }

  function exceedSizeLimit(){
    return $scope.attachment.file.size > ($scope.fileSizeLimit * 1024 * 1024);
  }
}
addSupplierAttachmentCtrl.$inject = [
  '$scope', 'globalFunc', '$uibModalInstance', '$http', '$filter', 'pathConstants', 'supplier', 'toastr', '$translatePartialLoader'
];

angular
  .module('metabuyer')
  .controller('addSupplierAttachmentCtrl', addSupplierAttachmentCtrl);
