'use strict';

angular.module('metabuyer.services.waitingOnUserList', ['ngResource'])
  .factory('waitingOnUserList', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.waitingOnUser.list,
      {
        category: '@category',
        offset: '@offset'
      },
      {
        'get': {
          method: 'GET',
          ignoreLoadingBar: true
        }
      }
    );
  });
