'use strict';

angular.module('metabuyer')
  .factory('HighlightId', function(){

      var highlightPRId = {};

    highlightPRId.setId = function (value) {
      this.id = value;
    };

    highlightPRId.getId = function () {
      return this.id;
    };

    return highlightPRId;
  });
