'use strict';

angular.module('metabuyer.services.availability', ['ngResource'])
  .factory('availability',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.available.single,
      {
        model: '@model',
        field: '@field'
      },
      {
        'get': { method: 'GET'}
      }
    );
  });
