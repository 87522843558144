'use strict';
/**
 * @name securityCtrl
 * @desc Controller for Security controller
 */
function securityCtrl($scope, toastr, $state, globalFunc, companyCostCenters, securityFunction, getAllCompanies) {
  $scope.$emit('pageTitleChanged', 'Security');

  $scope.companies = [];
  $scope.costCenters = [];
  $scope.selectedCompany = {};
  $scope.selectedCostCenter = {};
  $scope.showCostCenterCheckbox = false;
  $scope.showCostCenterDropdown = false;
  $scope.selectCompany = selectCompany;
  $scope.selectCostCenter = selectCostCenter;
  $scope.refreshRoleList = refreshRoleList;

  function selectCompany(company) {
    var orgLevel = 'company';
    securityFunction.setSelectedCompany(company);

    if (company.descr === 'System') {
      $scope.showCostCenterCheckbox = false;
      orgLevel = 'system';
    } else {
      $scope.showCostCenterCheckbox = true;
      getCostCenter(company);
    }

    $scope.selectedCompany = company;
    $scope.selectedCostCenter = {};
    $scope.costCenterRoleOnly = false;
    securityFunction.setOrganizationLevel(orgLevel);
    $state.go('main.user.security.rolesList', {code: company.code, organizationLevel: orgLevel});
  }

  function selectCostCenter(costCenter) {
    securityFunction.setSelectedCostCenter(costCenter);
    securityFunction.setOrganizationLevel('cost_center');
    $scope.selectedCostCenter = costCenter;
    $state.go('main.user.security.rolesList', {code: costCenter.code, organizationLevel: 'cost_center'});
  }

  function getCostCenter(company) {
    companyCostCenters.get(
      {
        id: company._id
      },
      function (resource) {
        $scope.costCenters = resource.content.data;
      },
      function (error) {
        toastr.error('failed to get cost center');
      }
    );
  }

  function refreshRoleList() {
    if (!$scope.showCostCenterDropdown) {
      securityFunction.setSelectedCostCenter({});
      $scope.selectedCostCenter = {};
      selectCompany($scope.selectedCompany);
    }
  }

  function getCompanies() {
    getAllCompanies.get(
      {
        'fields[0]': 'code',
        'fields[1]': 'descr'
      },
      function (resource) {
        $scope.companies = globalFunc.sortObjectsArray(resource.content.data, 'descr');

        // add 'System' to companies dropdown option
        $scope.companies.unshift({
          descr: 'System',
          code: 'system'
        });
      },
      function (error) {
        toastr.error('Something went wrong. Please refresh the page.');
      }
    );
  }

  function initialize() {
    getCompanies();
  }

  initialize();
}
securityCtrl.$inject = ['$scope', 'toastr', '$state', 'globalFunc', 'companyCostCenters', 'securityFunction', 'getAllCompanies'];

angular
  .module('metabuyer')
  .controller('securityCtrl', securityCtrl);
