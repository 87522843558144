'use strict';

/**
 * @name adLoginCallbackCtrl
 *
 * @desc Controller for handle Azure Ad Callback
 *
 * @author Nazrul Hakim <nazrulhmw@sunway.com.my>
 * @copyright  Metacloud Sdn. Bhd. &copy; 2024
 */

function adLoginCallbackCtrl($scope, $rootScope, $auth, $location, pathConstants, azureAdLogin, toastr, globalFunc, ManageLocalStorage, $state) {

  var paramsObject = $location.search();

  if (!!paramsObject.id) {
    var payload = {
      id: paramsObject.id,
      validate: true
    };

    azureAdLogin.post(
      payload,
      function(resource) {
        if (!!resource.token) {

          /**
           * verify the cached data
           */
          var cachedEmail = window.localStorage.getItem('email');
          if (!cachedEmail || cachedEmail !== $scope.email) {
            ManageLocalStorage.clearLocalStorage('All');
          } else {
            ManageLocalStorage.clearLocalStorage();
          }

          window.localStorage.setItem('email', resource.email);
          window.localStorage.setItem('userId', resource.userId);
          window.localStorage.setItem('socialLogin', true);
          $auth.setToken(resource.token)

          // first time login with mfa
          $state.go('twoFAAuthenticate', {
            'is_login': true,
          });
        } else {
          // normal login with mfa
          $state.go('twoFAAuthenticate', {
            'user_id': resource.userId || '',
          });
        }
      },
      function(error) {
        globalFunc.objectErrorMessage(error);
        $location.url('login');
      }
    )
  } else {
    $location.url('login');
  }

}

adLoginCallbackCtrl.$inject = ['$scope', '$rootScope', '$auth', '$location', 'pathConstants', 'azureAdLogin', 'toastr', 'globalFunc', 'ManageLocalStorage', '$state'];

angular
  .module('metabuyer')
  .controller('adLoginCallbackCtrl', adLoginCallbackCtrl);
