'use strict';

/**
 * @name prVersionCtrl
 * @desc The controller for PR version
 */
function prVersionCtrl($scope, currentPR, previousVersion, $uibModalInstance, versionNumber, globalFunc) {

  $scope.lineItemDiff     = [];

  $scope.version          = versionNumber;
  $scope.currentPR        = currentPR;
  $scope.prevPR           = previousVersion;

  // Function list
  $scope.close = close;

  function close() {
    $uibModalInstance.close();
  }

  /**
   * diff two arrays with defining the field to compare
   * @param prevArr
   * @param newArr
   * @param fieldToCompare
   * @returns {Array}
   */
  function diffArray(prevArr, newArr, fieldToCompare) {
    var diff = [];

    // get deleted data
    // and get updated data
    _.forEach(prevArr, function(prev) {
      var deleted = globalFunc.findInArray(newArr, fieldToCompare, prev[fieldToCompare]);
      if (!deleted) {
        prev.diffStatus = 'deleted';
        diff.push(prev);
      } else {
        deleted.diffStatus = 'updated';
        deleted.prevVersion = prev;
        diff.push(deleted);
      }
    });

    // get added data
    // and get updated data
    _.forEach(newArr, function(item) {
      var added = globalFunc.findInArray(prevArr, fieldToCompare, item[fieldToCompare]);
      if (!added) {
        item.diffStatus = 'added';
        diff.push(item);
      }
      // if exist in prevArr (updated) and not exist in diff
      else if (!!added && !globalFunc.findInArray(diff, fieldToCompare, item[fieldToCompare])) {
        item.diffStatus = 'updated';
        item.prevVersion = added;
        diff.push(item);
      }
    });

    return diff;
  }

  function initialize(){
    if (!!$scope.currentPR && !!$scope.prevPR)
      $scope.lineItemDiff = diffArray($scope.prevPR.pr_items, $scope.currentPR.pr_items, '_id');
  }

  initialize();
}

prVersionCtrl.$inject = ['$scope', 'currentPR', 'previousVersion', '$uibModalInstance', 'versionNumber', 'globalFunc'];

angular.module('metabuyer')
  .controller('prVersionCtrl', prVersionCtrl);
