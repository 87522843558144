'use strict';

function devToolsCtrl($scope) {

  $scope.$emit('pageTitleChanged', 'Audit Viewer');

  $scope.tabData = [
    {
      heading: 'Audit Viewer',
      route: 'main.user.devTools.auditViewer',
      key: 'auditViewer',
      id: 'auditViewer'
    }
  ];
}

devToolsCtrl.$inject = ['$scope'];

angular.module('metabuyer')
  .controller('devToolsCtrl', devToolsCtrl);
