'use strict';
angular
  .module('metabuyer')
  .directive('exportArrayToCsv', function () {
    return {
      restrict: 'E',
      scope: {
        arrayData: '=arrayData',
        fileName: '=fileName',
        trigger: '=trigger'
      },
      controller: function ($scope) {

        function convertToLineText() {
          var finalVal = '';
          for (var i = 0; i < $scope.arrayData.length; i++) {
            var value = $scope.arrayData[i];
            for (var j = 0; j < value.length; j++) {
              var innerValue = value[j] === null ? '' : value[j].toString();
              var result = innerValue.replace(/"/g, '""');
              if (result.search(/("|,|\n)/g) >= 0)
                result = '"' + result + '"';
              if (j > 0)
                finalVal += ',';
              finalVal += result;
            }

            finalVal += '\n';
          }
          return finalVal;
        }

        function exportCsv() {
          var finalVal = convertToLineText();
          var blobLog = new Blob([finalVal]);
          var linkLog = document.createElement('a');
          document.body.appendChild(linkLog);
          linkLog.href = window.URL.createObjectURL(blobLog);
          linkLog.download = $scope.fileName + '.csv';
          linkLog.click();
          $scope.trigger = false;
        }

        $scope.$watch('trigger', function (trigger) {
          if (trigger == true)
            exportCsv();
        });

      }
    };
  });
