'use strict';

function itemAttachments() {
  return {
    restrict: 'E',
    templateUrl: 'components/priceComparisonComponents/itemAttachments/itemAttachmentsTemplate.html',
    controller: 'itemAttachmentsCtrl',
    scope: {
      maxFile: '=',
      url: '=',
      param: '=',
      acceptedFiles: '=',
      autoUpload: '=',
      attachments: '=',
      singleAttachmentService: '=',
      module: '=',
      itemComparison: '=',
      allowUpload: '=',
      disableEdit: '=',
      itemIndex: '=',
      pcId: '='
    }
  }
}

function itemAttachmentsCtrl($scope, globalFunc, $auth, $filter, prV2Function, pathConstants, $http, toastr, $rootScope) {

  $scope.download = download;
  $scope.deleteFile = deleteFile;
  $scope.showDropzoneId = {};
  $scope.showDropzoneId[$scope.itemIndex] = false;
  $scope.cannotDeleteDropzoneId = {};
  $scope.cannotDeleteDropzoneId[$scope.itemIndex] = true;

  // when user adds new quotation
  if ($scope.disableEdit) {
    // can add and delete attachement
    $scope.showDropzoneId[$scope.itemIndex] = true;
    $scope.cannotDeleteDropzoneId[$scope.itemIndex] = false;
  }

  // when user edits existing quotation
  // listen when user clicks on 'edit', 'save' and 'cancel'
  $scope.$on('checkEdit', function (event, data) {
    if (data.editable === true) {
      // can add and delete attachement
      $scope.showDropzoneId[data.itemIndex] = true;
      $scope.cannotDeleteDropzoneId[data.itemIndex] = false;
    } else if (data.editable === false) {
      // cannot add and cannot delete attachment
      $scope.showDropzoneId[data.itemIndex] = false;
      $scope.cannotDeleteDropzoneId[data.itemIndex] = true;
    }
  });

  $scope.itemData = null;

  $scope.dropzoneConfig = {
    options: {
      // passed into the Dropzone constructor
      //maxFilesize in MB
      autoProcessQueue: !!$scope.autoUpload ? true : false,
      maxFilesize: 20,
      maxFiles: $scope.maxFile,
      url: $filter('format')($scope.url, { id: $scope.pcId }),
      // simultaneous uploads in a time
      parallelUploads: 1,
      init: function() {
        var dropzone = this;
      },
      headers: {
        'Authorization': 'Bearer ' + $auth.getToken()
      },
      previewTemplate: '<div class="dz-preview dz-file-preview">' +
        '<div class="dz-details">' +
        '<div class="dz-filename"><span data-dz-name></span></div>' +
        '<div class="dz-size" data-dz-size></div>' +
        '<img data-dz-thumbnail />' +
        '</div>' +
        '<div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>' +
        '<div style="z-index: 999; position: absolute; width: 30px; height: 30px; font-size: 25px; text-align: center; right: -10px; top: -10px;">' +
        '<a data-dz-remove type="button" class="btn-xs btn-white pull-right"><i class="fa fa-trash-o"></i></a>' +
        '</div>' +
        '<div class="dz-error-message"><span data-dz-errormessage></span></div>' +
        '</div>',
      acceptedFiles: $scope.acceptedFiles
    },
    eventHandlers: {
      processing: function(file) {
        var param = { id: $scope.pcId };
        param['itemIndex'] = $scope.itemIndex;

        if ($scope.itemComparison.quotation === undefined) {
          param['editable'] = 1;
        } else {
          param['editable'] = $scope.itemComparison.editable ? 1 : 0;
        }

        this.options.url = $filter('format')($scope.url, param)
      },

      sending: function(file, xhr, formData) {
        if (!!$scope.itemData) {
          formData.append('itemImage', file)
        }
      },
      error: function(file, error) {
        globalFunc.objectErrorMessage(error);
        this.removeFile(file);
      },
      success: function(file, resource) {
        var _this = this;
        setTimeout(function() {
          _this.removeFile(file);
        }, 1000);
        if (!!resource.content && !!resource.content.data) {
          toastr.success("Successfully Added Attachment");
          $rootScope.$broadcast('refreshComments');

          if (!_.isEmpty($scope.itemComparison.attachments)) {
            $scope.itemComparison.attachments.push(resource.content.data)
          } else {
            $scope.itemComparison.attachments = [resource.content.data];
          }
        }
      }
    }
  };

  function download(file_id) {
    var param = { id: $scope.pcId }
    param['attachmentId'] = file_id;

    $scope.singleAttachmentService.get(
      param
    ).$promise.then(
      function(resource) {
        if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data.temporary_url) {
          var newUrl = $filter('format')(pathConstants.apiUrls.attachments.downloadAttachment, {
            hash: resource.content.data.temporary_url
          });
        }
        // Use HTTP to allow and use the application/json
        // and download with the newUrl
        $http({
          url: newUrl
        }).then(function() {
          window.open(newUrl, '_self');
        }, function(response) {});
      },
      function() {
        toastr.error('Failed to download attachment.');
      }
    );
  }

  function deleteFile(file) {
    swal({
      title: 'Confirmation',
      text: 'This action cannot be undone. Are you sure you want to delete this attachment?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Confirm',
      closeOnConfirm: true
    }, function(isConfirm) {
      if (isConfirm) {

        var file_index = _.findIndex($scope.attachments, function(file_item) {
          return file._id === file_item._id;
        });

        $scope.attachments.splice(file_index, 1);
        var param = { id: $scope.pcId };
        param['attachmentId'] = file._id;
        param['itemIndex'] = $scope.itemIndex;

        if ($scope.itemComparison.quotation === undefined) {
          param['editable'] = 1;
        } else {
          param['editable'] = $scope.itemComparison.editable ? 1 : 0;
        }

        $scope.singleAttachmentService.delete(
          param
        ).$promise.then(
          function(resource) {
            toastr.success(resource.content.message);
            if (!$scope.attachments.length) {
              $scope.showDropzoneId[$scope.itemIndex] = true;
            }
          },
          function() {
            toastr.error('Failed to delete attachment.');
            $scope.attachments.splice(file_index, 0, file);
          });
      }
    })

  }
}

itemAttachmentsCtrl.$inject = ['$scope', 'globalFunc', '$auth', '$filter', 'prV2Function', 'pathConstants', '$http', 'toastr', '$rootScope'];

angular
  .module('metabuyer')
  .directive('itemAttachments', itemAttachments)
  .controller('itemAttachmentsCtrl', itemAttachmentsCtrl);
