'use strict';

function cerfViewCtrl($scope, $rootScope, $uibModalInstance, updateCerf, itemId, globalFunc,
                      item, addCerf, editingState, prId, toastr, lang, budget,
                      expenseType, purchaseRequisitionsV2Services, getCompanyAsset, companyCode ,pathConstants,
                      $http, $filter, prV2Function, bifrostFixedAssetSap, bifrostFixedAssetMsgp,
                      singleAssetCategoryByCode, companyId) {
  $scope.editMode = false;
  $scope.close = close;
  $scope.edit = edit;
  $scope.save = save;
  $scope.item = item;
  $scope.itemId = itemId;
  $scope.expenseType = expenseType;
  $scope.editingState = editingState;
  $scope.prId = prId;
  $scope.companyCode = companyCode;
  $scope.updateAcquisitionType = updateAcquisitionType;
  $scope.getMainAssetNumber = getMainAssetNumber;
  $scope.searchAssetCategories = searchAssetCategories;
  $scope.updateAssetCategory = updateAssetCategory;
  $scope.searchAssetMainNumber = searchAssetMainNumber;
  $scope.updateAssetMainNumber = updateAssetMainNumber;
  $scope.updateAssetSubNumber = updateAssetSubNumber;
  $scope.searchAssetSubNumber = searchAssetSubNumber;
  $scope.updateReplacementAssetMainNumber = updateReplacementAssetMainNumber;
  $scope.updateReplacementAssetSubNumber = updateReplacementAssetSubNumber;
  $scope.replaceMainAssetNumber = replaceMainAssetNumber;
  $scope.replaceSubAssetNumber = replaceSubAssetNumber;
  $scope.replaceReplacementMainAssetNumber = replaceReplacementMainAssetNumber;
  $scope.replaceReplacementAssetSubNumber = replaceReplacementAssetSubNumber;
  $scope.replaceAssetCategory = replaceAssetCategory;
  $scope.checkAssetUsefulLife = checkAssetUsefulLife;
  $scope.formSubmitted = false;
  $scope.checkPostAssetAllowed = checkPostAssetAllowed;
  $scope.clearForm = clearForm;
  $scope.capexEvaluationOneListing = [
    {
      code: null,
      descr: 'N/A'
    },
    {
      code: "BD20",
      descr: "Bldg; with UL=20yrs"
    },
    {
      code: "BD30",
      descr: "Bldg; with UL=30yrs"
    },
    {
      code: "BD50",
      descr: "Bldg; with UL=50yrs"
    },
    {
      code: "BI",
      descr: "BI"
    },
    {
      code: "COMM",
      descr: "Vconf/PABX/VOIP/Network Infra"
    },
    {
      code: "COMP",
      descr: "Computer/Handheld/RAM/CD/DVD"
    },
    {
      code: "CONV",
      descr: "Data Migration"
    },
    {
      code: "CPM",
      descr: "Finance CPM & Conso System"
    },
    {
      code: "CR",
      descr: "Checkroll"
    },
    {
      code: "CSR",
      descr: "CSR Efforts"
    },
    {
      code: "CXC",
      descr: "CxC"
    },
    {
      code: "DS",
      descr: "Downstream"
    },
    {
      code: "EPRO",
      descr: "eProcurement Metabuyer"
    },
    {
      code: "EINV",
      descr: "E-Invoicing Middleware"
    },
    {
      code: "FICO",
      descr: "SAP FICO"
    },
    {
      code: "HRMS",
      descr: "SAP HRMS"
    },
    {
      code: "IPRS",
      descr: "iPRS"
    },
    {
      code: "ITSM",
      descr: "ITSM"
    },
    {
      code: "LX",
      descr: "Lintramax"
    },
    {
      code: "MIHW",
      descr: "MISC HW-USB/UPS/AVR/Tape/Print"
    },
    {
      code: "MISW",
      descr: "Misc Software"
    },
    {
      code: "MS",
      descr: "Microsoft SW"
    },
    {
      code: "MSGP",
      descr: "MSGP"
    },
    {
      code: "MV-C",
      descr: "MV – Commercial"
    },
    {
      code: "MV-E",
      descr: "MV – Employee"
    },
    {
      code: "NCSR",
      descr: "Non CSR"
    },
    {
      code: "NETW",
      descr: "Network Tool-router/switch/SAN"
    },
    {
      code: "OP",
      descr: "OPMAS"
    },
    {
      code: "PM10",
      descr: "Plant & Mach Other; UL=10yrs"
    },
    {
      code: "PM20",
      descr: "Plant & Mach Other; UL=5yrs"
    },
    {
      code: "PM4Y",
      descr: "Plant & Mach; UL=4yrs"
    },
    {
      code: "PMRV",
      descr: "Plant & Mach; UL=10yrs"
    },
    {
      code: "SAP",
      descr: "SAP"
    },
    {
      code: "SERV",
      descr: "Svr/Svr Rack/CRAC/Inergen"
    },
    {
      code: "WIZ",
      descr: "WizProperty"
    }
  ];

  $scope.capexEvaluationTwoListing = [
    {
      code: null,
      descr: 'N/A'
    },
    {
      code: "CONV",
      descr: "Data Migration"
    },
    {
      code: "ILIC",
      descr: "IT LIC-subscribe/renew HW SW"
    },
    {
      code: "IOP",
      descr: "IT day to day operations"
    },
    {
      code: "IPRO",
      descr: "IT Project-HW SW initial req"
    },
    {
      code: "ISEC",
      descr: "IT Security-audit,monitor,supt"
    },
    {
      code: "IUP",
      descr: "IT Upgrade-enhance exist HW SW"
    }
  ];

  $scope.purposeOfAcquisitionOneListing = [
    {
      code: "CR",
      descr: "Corporate Responsibility"
    },
    {
      code: "RSPO",
      descr: "RSPO Compliance"
    },
    {
      code: "NONE",
      descr: "None"
    }
  ];

  $scope.purposeOfAcquisitionTwoListing = [
    {
      code: "ENV",
      descr: "Improve of Environment"
    },
    {
      code: "IMPVPROD",
      descr: "Improve Productivity"
    },
    {
      code: "PROJECT",
      descr: "New Project"
    },
    {
      code: "WELFARE",
      descr: "Staff Welfare"
    },
    {
      code: "COMPLY",
      descr: "Compliance"
    },
    {
      code: "SUSTAIN",
      descr: "Sustainability"
    },
    {
      code: "OTHER",
      descr: "Others"
    }
  ];

  function checkPostAssetAllowed() {
    var allowPostAsset = false;
    if (!!$scope.cerfObject) {
      if (($scope.cerfObject.type_of_acquisition === 'Replacement' || $scope.cerfObject.type_of_acquisition === 'New') &&
        _.isEmpty($scope.assetMainNumberSearch.search) && _.isEmpty($scope.assetSubNumberSearch.search)) {
          allowPostAsset = true;
      }

      if ($scope.cerfObject.type_of_acquisition === 'Addition to a Main Asset' &&
        !_.isEmpty($scope.assetMainNumberSearch.search) && !!$scope.cerfObject.asset_main_number &&
        !_.isEmpty($scope.cerfObject.asset_main_number.asset_main_number) &&
        _.isEmpty($scope.assetSubNumberSearch.search)) {
          allowPostAsset = true;
        }
    }

    return allowPostAsset;
  }

  function initializeSearchVars() {
    $scope.assetCategorySearch = {search: ''};
    $scope.assetMainNumberSearch = {search: ''};
    $scope.assetSubNumberSearch = {search: ''};
    $scope.replacementAssetMainNumberSearch = {search: ''};
    $scope.replacementAssetSubNumberSearch = {search: ''};
  }

  function clearForm() {
    var cerfUuid = $scope.cerfObject.uuid;
    $scope.cerfObject = {
      uuid: cerfUuid || undefined,
      asset_category: $scope.cerfObject.asset_category
    };

    if (!$scope.isProjectCodeMandatory && $scope.pr.expense_type.code === 'CAPEXUNBUD')
      $scope.assetCategorySearch = {search: $scope.assetCategorySearch.search};

    $scope.assetMainNumberSearch = {search: ''};
    $scope.assetSubNumberSearch = {search: ''};
    $scope.replacementAssetMainNumberSearch = {search: ''};
    $scope.replacementAssetSubNumberSearch = {search: ''};
  }

  function updateReplacementAssetMainNumber(item){
    $scope.cerfObject.replacement_asset_main_number = prepareAssetData(item, 'main');
    $scope.replacementMainNumberExists = false;

    // auto populate replacement asset sub nubmer
    updateReplacementAssetSubNumber(item);
    $scope.replacementAssetMainNumberSearch.search = item.asset_main_number + ' - ' + item.name;
    $scope.replacementAssetSubNumberSearch.search = item.asset_sub_number + ' - ' + item.name;
  }

  function updateReplacementAssetSubNumber(item){
    $scope.cerfObject.replacement_asset_sub_number = prepareAssetData(item);
    $scope.replacementSubNumberExists = false;
  }

  function updateAssetMainNumber(item){
    $scope.cerfObject.asset_main_number = prepareAssetData(item, 'main');
    $scope.mainNumberExists = false;
  }

  function updateAssetSubNumber(item){
    $scope.cerfObject.asset_sub_number = prepareAssetData(item);
    $scope.subNumberExists = false;
  }

  function replaceMainAssetNumber(){
    if (!_.isEmpty($scope.cerfObject.asset_main_number)) {
      delete $scope.cerfObject.asset_main_number;
    }

    if (!_.isEmpty($scope.cerfObject.asset_sub_number)) {
      delete $scope.cerfObject.asset_sub_number;
      $scope.assetSubNumberSearch.search = "";
    }
  }

  function replaceSubAssetNumber(){
    if (!_.isEmpty($scope.cerfObject.asset_sub_number)) {
      delete $scope.cerfObject.asset_sub_number;
      $scope.assetSubNumberSearch.search = "";
    }
  }

  function replaceReplacementMainAssetNumber(){
    if (!_.isEmpty($scope.cerfObject.replacement_asset_main_number)) {
      delete $scope.cerfObject.replacement_asset_main_number;
    }

    if (!_.isEmpty($scope.cerfObject.replacement_asset_sub_number)) {
      delete $scope.cerfObject.replacement_asset_sub_number;
      $scope.replacementAssetSubNumberSearch.search = "";
    }
  }

  function replaceReplacementAssetSubNumber(){
    if (!_.isEmpty($scope.cerfObject.replacement_asset_sub_number)) {
      delete $scope.cerfObject.replacement_asset_sub_number;
      $scope.replacementAssetSubNumberSearch.search = "";
    }
  }

  function replaceAssetCategory(){
    if (!_.isEmpty($scope.cerfObject.asset_category)) {
      delete $scope.cerfObject.asset_category;
    }
  }

  function prepareAssetData(item, flag) {
    var res = {};

    if (flag === 'main') {
      res.asset_main_number = item.asset_main_number;
    } else {
      res.asset_sub_number = item.asset_sub_number;
    }

    res.name = item.name || '';
    res.date_of_purchase = item.capitalization_date || '';
    res.cumulative_depreciation = item.cumulative_depreciation || '';
    res.net_book_value = item.net_book_value || '';
    res.purchase_cost = item.purchase_cost;

    return res;
  }

  function updateAssetCategory(item){
    $scope.cerfObject.asset_category = {
      code: item.code,
      name: item.name,
      account_code: item.account_code,
      asset_useful_life: item.asset_useful_life
    };
  }

  function updateAcquisitionType() {
    if (!!$scope.cerfObject && $scope.cerfObject.type_of_acquisition !== 'Replacement'){
      delete $scope.cerfObject.replacement_asset_main_number;
      delete $scope.cerfObject.replacement_asset_sub_number;
    }

    $scope.assetMainNumberSearch.search = '';
    $scope.assetSubNumberSearch.search = '';
    delete $scope.cerfObject.asset_main_number;
  }

  function create(data) {
    addCerf.post(
      {
        requisitionItemId: $scope.itemId
      },
      data,
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data){
          toastr.success('Cerf created');
          prV2Function.setPRData(resource.content.data);
          $scope.item.isCerfReplacement = !!data.replacement_asset_main_number;
        }
        edit();
        close();
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function update(data) {
    addCerf.post(
      {
        requisitionItemId: $scope.itemId
      },
      data,
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.message){
          toastr.success('Cerf updated');
          prV2Function.setPRData(resource.content.data);
          $scope.item.isCerfReplacement = !!data.replacement_asset_main_number;
        }
        edit();
        close();
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function validate(){
    var anyError = false;
    if (!!$scope.isFinanceVerifier || $scope.expenseType.code === 'CapexUnBud'){
      if (!$scope.cerfObject.asset_category){
        toastr.error(lang.validation.required.input + 'asset category');
        anyError = true;
      }
      if (!$scope.cerfObject.purpose_of_acquisition_1){
        toastr.error(lang.validation.required.input + 'purpose of acquisition 1');
        anyError = true;
      }
      if (!$scope.cerfObject.purpose_of_acquisition_2){
        toastr.error(lang.validation.required.input + 'purpose of acquisition 2');
        anyError = true;
      }
      if (!$scope.cerfObject.type_of_acquisition){
        toastr.error(lang.validation.required.input + 'type of acquisition');
        anyError = true;
      }
      return anyError;
    }
  }

  function prepareCerfObject() {
    var keyInData = {};
    var res = _.cloneDeep($scope.cerfObject);

    res.item_id = $scope.itemId;
    res.pr_id = $scope.prId;
    res.company_code = $scope.companyCode;

    // for key in asset data (not selected from typeahead)
    if (_.isEmpty($scope.cerfObject.asset_sub_number) && !_.isEmpty($scope.assetSubNumberSearch.search)) {
      keyInData.asset_sub_number = $scope.assetSubNumberSearch.search;
      res.asset_sub_number = prepareAssetData(keyInData);
    }

    if (_.isEmpty($scope.cerfObject.asset_main_number) && !_.isEmpty($scope.assetMainNumberSearch.search)) {
      keyInData.asset_main_number = $scope.assetMainNumberSearch.search;
      res.asset_main_number = prepareAssetData(keyInData, 'main');
    }

    if (_.isEmpty($scope.cerfObject.replacement_asset_main_number) && !_.isEmpty($scope.replacementAssetMainNumberSearch.search)) {
      keyInData.asset_main_number = $scope.replacementAssetMainNumberSearch.search;
      res.replacement_asset_main_number = prepareAssetData(keyInData, 'main');
    }

    if (_.isEmpty($scope.cerfObject.replacement_asset_sub_number) && !_.isEmpty($scope.replacementAssetSubNumberSearch.search)) {
      keyInData.asset_sub_number = $scope.replacementAssetSubNumberSearch.search;
      res.replacement_asset_sub_number =prepareAssetData(keyInData);
    }

    return res;
  }

  function save() {
    $scope.formSubmitted = true;
    if (!validate()){
      var data = prepareCerfObject();
      if (!!validated(data)) {
        if ($scope.mode === 'create')
          create(data);
        else
          update(data);

        $scope.formSubmitted = false;
      }
    } else {
      toastr.error("Please fill in mandatory input");
      return;
    }
  }

  function checkAssetUsefulLife(cerf) {
    if (!_.isEmpty(cerf.asset_category)) {
      return !cerf.asset_category.asset_useful_life && cerf.asset_category.asset_useful_life !== 0;
    } else {
      return true;
    }
  }

  function validated(data) {
    if (_.isEmpty(data.asset_category) && !_.isEmpty($scope.assetCategorySearch.search)) {
      toastr.error('Asset Category is invalid.');
        $scope.assetCategorySearch.search = "";
        return false;
    }

    if (_.isEmpty(data.asset_category)) {
      toastr.error('Asset Category is compulsory.');
      $scope.assetCategorySearch.search = "";
      return false;
    } else {
      if (_.isEmpty(data.asset_category.name)) {
        toastr.error('Asset Category is invalid.');
        $scope.assetCategorySearch.search = "";
        return false;
      }
    }

    if (!data.purpose_of_acquisition_1) {
      toastr.error('Please select purpose of acquisition 1.');
      return false;
    }

    if (!data.purpose_of_acquisition_2) {
      toastr.error('Please select purpose of acquisition 2.');
      return false;
    }

    if (!data.type_of_acquisition) {
      toastr.error('Please select type of acquisition.');
      return false;
    }

    if (checkAssetUsefulLife(data)) {
      toastr.error('Please enter a useful life.');
      return false;
    }

    if (!!$scope.isFinanceVerifier && !data.asset_main_number) {
      toastr.error('Please enter main asset no.');
      return false;
    }

    if (!$scope.isFinanceVerifier) {
      if (!_.isEmpty(data.asset_main_number) && _.isEmpty(data.asset_main_number.name) && !_.isEmpty($scope.assetMainNumberSearch.search)) {
        toastr.error('Asset Main Number is not valid.');
        $scope.assetMainNumberSearch.search = "";
        return false;
      }
    }

    if (data.type_of_acquisition === 'Replacement') {
      if (_.isEmpty(data.replacement_asset_main_number)) {
        toastr.error('Please select replacement asset main number.');
        return false;
      }
      if (_.isEmpty(data.replacement_asset_sub_number)) {
        toastr.error('Please select replacement asset sub number.');
        return false;
      }

      if (!$scope.isFinanceVerifier) {
        if (!_.isEmpty(data.replacement_asset_main_number) && _.isEmpty(data.replacement_asset_main_number.name) && !_.isEmpty($scope.replacementAssetMainNumberSearch.search)) {
          toastr.error('Replacement Asset Main Number is not valid.');
          $scope.replacementAssetMainNumberSearch.search = "";
          return false;
        }

        if (!_.isEmpty(data.replacement_asset_sub_number) && _.isEmpty(data.replacement_asset_sub_number.name) && !_.isEmpty($scope.replacementAssetSubNumberSearch.search)) {
          toastr.error('Replacement Asset Sub Number is not valid.');
          $scope.replacementAssetSubNumberSearch.search = "";
          return false;
        }
      }
    }

    if (data.type_of_acquisition === 'Addition to a Main Asset') {
      if (!data.asset_main_number.asset_main_number) {
        toastr.error('Please select Main Asset Number.');
        return false;
      }
      if (!!$scope.isFinanceVerifier) {
        if (!data.asset_sub_number.asset_sub_number) {
          toastr.error('Please select Asset Sub Number.');
          return false;
        }
      }
    }

    return true;
  }

  function close() {
    $uibModalInstance.dismiss();
  }

  function edit() {
    $scope.editMode = !$scope.editMode;
  }

  function getTemplateUrl(state) {
    if (state === 'editable') {
      $scope.myTemplateUrl = 'components/purchaseRequisitionV2Components/cerfView/cerfView.EditableTemplate.html';
    } else if (state === 'notEditable' && !!$scope.isFinanceVerifier && $scope.pr.status !== 'approved') {
      $scope.myTemplateUrl = 'components/purchaseRequisitionV2Components/cerfView/cerfView.EditableTemplate.html';
    } else {
      $scope.myTemplateUrl = 'components/purchaseRequisitionV2Components/cerfView/cerfView.NonEditableTemplate.html';
    }
  }

  function getMainAssetNumber() {
    var fixedAssetFactory = null;
    var assetMainNumber = '';

    if (!$scope.cerfObject.asset_category.code) {
      toastr.error('Asset Category is a required field..');
      return;
    }

    var isMsgp = false;

    if (!!$scope.pr.document_rule.is_integrated) {
      if ($scope.pr.document_rule.integration_type === 'MB-GENP-MSGP-INDEPENDENT') {
        fixedAssetFactory = bifrostFixedAssetMsgp;
        isMsgp = true;
        
        if ($scope.cerfObject.type_of_acquisition === 'Addition to a Main Asset') {
          toastr.error('Unable to post due CERF Type of Acquisition is "Addition to Main Asset"');
          return;
        }
        
      } else {
        fixedAssetFactory = bifrostFixedAssetSap;

        if ($scope.cerfObject.type_of_acquisition === 'Addition to a Main Asset') {
          assetMainNumber = !!$scope.assetMainNumberSearch.search ? $scope.assetMainNumberSearch.search : '';
        }
      }
    } else {
      toastr.error('Turn on integration option in company settings');

      return;
    }

    var itemIndex = _.findIndex($scope.pr.pr_items, function (item) {
      return item._id === $scope.item._id;
    });

    var capexEvaluation1 = !!$scope.cerfObject.capex_evaluation_1 ? $scope.cerfObject.capex_evaluation_1.code : undefined;
    var capexEvaluation2 = !!$scope.cerfObject.capex_evaluation_2 ? $scope.cerfObject.capex_evaluation_2.code : undefined;

    if (isMsgp) {
      capexEvaluation1 = !!$scope.cerfObject.capex_evaluation_1 ? (!!$scope.cerfObject.capex_evaluation_1.code ? $scope.cerfObject.capex_evaluation_1.code : 'N/A') : 'N/A';
      capexEvaluation2 = !!$scope.cerfObject.capex_evaluation_2 ? (!!$scope.cerfObject.capex_evaluation_2.code ? $scope.cerfObject.capex_evaluation_2.code : 'N/A') : 'N/A';
    }

    var payload = {
      'billing_company': $scope.pr.shadow_company.code,
      'company_code': $scope.pr.company.code,
      'cost_center_code': !!$scope.item.account_assignments[0].cost_center ? $scope.item.account_assignments[0].cost_center.code : undefined,
      'asset_category': $scope.cerfObject.asset_category.code,
      'pr_quantity': $scope.item.qty,
      'capex_purpose_1': !!$scope.cerfObject.purpose_of_acquisition_1 ? $scope.cerfObject.purpose_of_acquisition_1.code : undefined,
      'capex_purpose_2': !!$scope.cerfObject.purpose_of_acquisition_2 ? $scope.cerfObject.purpose_of_acquisition_2.code : undefined,
      'capex_evaluation_1': capexEvaluation1,
      'capex_evaluation_2': capexEvaluation2,
      'item_description': $scope.item.descr,
      'budget_code': !!$scope.item.budget ? $scope.item.budget.erp_budget_id : undefined,
      'extended_description': $scope.pr.reference,
      'line_pr_quantity': $scope.item.qty,
      'pr_line_item_number': itemIndex + 1,
      'pr_number': $scope.pr.reference,
      'asset_main_no_text': '',
      'asset_main_number': assetMainNumber,
      'asset_name': $scope.item.descr,
      'additional_descr': !!$scope.cerfObject.asset_description ? $scope.cerfObject.asset_description : '',
    }

    fixedAssetFactory.post(
      payload,
      function (resource) {
        if (!!resource.content & !!resource.content.data) {
          if (resource.content.data.message === 'Success') {
            var mainAssetNumber = resource.content.data.asset_main_number;
            var assetSubNumber = resource.content.data.asset_sub_number;
            $scope.assetMainNumberSearch.search = mainAssetNumber;
            $scope.assetSubNumberSearch.search = assetSubNumber;

            $scope.cerfObject.asset_main_number = {
              'asset_main_number': mainAssetNumber,
              'name': '',
              'date_of_purchase': '',
              'cumulative_depreciation': '',
              'net_book_value': ''

            };
            $scope.cerfObject.asset_sub_number = {
              'asset_sub_number': assetSubNumber,
              'name': '',
              'date_of_purchase': '',
              'cumulative_depreciation': '',
              'net_book_value': ''
            };
          } else {
            if (!!resource.content.erp_type && resource.content.erp_type === 'msgp') {
              toastr.error('MSGP: ' + resource.content.data.message);
            } else {
              toastr.error('SAP: ' + resource.content.data.message);
            }
          }
        }
      },
      function (error) {
        if (!!error.data.content.data.erp_type && error.data.content.data.erp_type === 'msgp') {
          toastr.error('MSGP: ' + error.data.content.data.message);
        } else {
          toastr.error('SAP: ' + error.data.content.data.message);
        }
      })
  }

  $scope.$watch('editingState', function (newValue) {
    getTemplateUrl(newValue);
  });

  function getCerf(item){
    purchaseRequisitionsV2Services.getCerfData({
        id: item.cerf_id
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.cerfObject = resource.content.data;
          item.isCerfReplacement = !!resource.content.data.replacement_asset_main_number;
          if (expenseType.code === 'CapexBud' && !_.isEmpty(budget) && _.isEmpty($scope.cerfObject.asset_category)){
            $scope.cerfObject.asset_category = budget.asset_category;
          }
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function searchAssetCategories(query){
    var moduleParam = {
      module: 'asset-category'
    };

    var params = {
      'criteria[0][code]': query,
      'criteria[0][name]': query,
      'criteria[0][$operator]': 'or',
      'criteria[1][company|code]': $scope.companyCode,
      'criteria[1][$strict]': true,
      'criteria[2][is_active]': true,
      criteria_operator: 'and',
      offset: 10
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: params
      }).then(function (response) {
      if (response.data.content.data.length === 0) {
        return [];
      } else {
        return response.data.content.data.map(function (item) {
          return item;
        });
      }
    });
  }

  function searchAssetMainNumber(query){
    var moduleParam = {
      module: 'company-asset'
    };

    var params = {
      'criteria[0][asset_main_number]': query,
      'criteria[0][name]': query,
      'criteria[0][$operator]': 'or',
      'criteria[1][company|code]': $scope.companyCode,
      'criteria[2][is_active]': true,
      criteria_operator: 'and',
      offset: 10
    };

    if (!!$scope.cerfObject.asset_category && !!$scope.cerfObject.asset_category.code)
      params['criteria[3][asset_category|code]'] = $scope.cerfObject.asset_category.code;

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: params
      }).then(function (response) {
      if (response.data.content.data.length === 0) {
        return [];
      } else {
        return response.data.content.data.map(function (item) {
          return item;
        });
      }
    });
  }

  function searchAssetSubNumber(query, isReplacement){
    var mainNumber = $scope.cerfObject.asset_main_number;
    var assetMainNumber = '';

    if (!!isReplacement) {
      var mainNumber = $scope.cerfObject.replacement_asset_main_number;
    }

    if (_.isEmpty(mainNumber)) {
      return;
    } else {
      if (!_.isEmpty(mainNumber.asset_main_number)) {
        assetMainNumber = mainNumber.asset_main_number;
      } else {
        assetMainNumber = $scope.assetMainNumberSearch.search;
      }
    }

    var moduleParam = {
      module: 'company-asset'
    };

    var params = {
      'criteria[0][asset_sub_number]': query,
      'criteria[0][name]': query,
      'criteria[0][$operator]': 'or',
      'criteria[1][company|code]': $scope.companyCode,
      'criteria[2][is_active]': true,
      'criteria[3][asset_main_number]': assetMainNumber,
      criteria_operator: 'and',
      offset: 10
    };

    if (!!$scope.cerfObject.asset_category && !!$scope.cerfObject.asset_category.code)
      params['criteria[4][asset_category|code]'] = $scope.cerfObject.asset_category.code;

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: params
      }).then(function (response) {
      if (response.data.content.data.length === 0) {
        return [];
      } else {
        return response.data.content.data.map(function (item) {
          return item;
        });
      }
    });
  }

  function initialize(){
    initializeSearchVars();
    $scope.isFinanceVerifier = !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Finance Verifier', null, companyId);
    $scope.pr = prV2Function.getPRData();
    $scope.isProjectCodeMandatory = $scope.pr.document_rule.is_project_code_mandatory;

    if ($scope.isProjectCodeMandatory) {
      $scope.allowEditAssetCategory = false;
    } else {
      $scope.allowEditAssetCategory = true;
    }

    if (!_.isEmpty($scope.item.cerf)) {
      var obj = _.cloneDeep($scope.item.cerf);
      $scope.cerfObject = obj;
      $scope.item.isCerfReplacement = !!obj.replacement_asset_main_number;
      $scope.mode = 'update';
    } else {
      if (!!$scope.item.cerf_id) {
        getCerf($scope.item);
        $scope.mode = 'update';
      } else {
        $scope.cerfObject = {};
        $scope.mode = 'create';
        if (expenseType.category === 'CAPEX'
            && (!_.isEmpty(budget) || !_.isEmpty($scope.item.project_code))
            && _.isEmpty($scope.cerfObject.asset_category)) {

            // if capex unbudgeted and setting is off, there is no auto population feature from either budget/project
            if (expenseType.code === 'CapexUnBud' && !$scope.isProjectCodeMandatory) {
                return
            }

          $scope.cerfObject.asset_category = $scope.isProjectCodeMandatory ? $scope.item.project_code.asset_category : budget.asset_category;

          if (_.isEmpty($scope.cerfObject.asset_category.asset_useful_life)) {
            singleAssetCategoryByCode.get({
                code: $scope.cerfObject.asset_category.code,
                companyCode: $scope.companyCode
              },
              function (resource) {
                if (!!resource.content && !!resource.content.data) {
                  var result = resource.content.data;
                  $scope.cerfObject.asset_category.asset_useful_life = result.asset_useful_life;
                }
              },
              function (error) {
                globalFunc.error(error);
              }
            );
          } else {
            $scope.cerfObject.asset_category.asset_useful_life = budget.asset_category.asset_useful_life;
          }
        }
      }
    }

    if (!!$scope.cerfObject.asset_category) {
      $scope.assetCategorySearch.search = !$scope.cerfObject.asset_category.code ? $scope.cerfObject.asset_category :
        $scope.cerfObject.asset_category.code + ' - ' + $scope.cerfObject.asset_category.name;
    }

    if (!!$scope.cerfObject.asset_main_number) {
      $scope.assetMainNumberSearch.search = ($scope.cerfObject.asset_main_number.name.length === 0)
        ? $scope.cerfObject.asset_main_number.asset_main_number
        : $scope.cerfObject.asset_main_number.asset_main_number + ' - ' + $scope.cerfObject.asset_main_number.name;
      $scope.mainNumberExists = true;
    }

    if (!!$scope.cerfObject.asset_sub_number) {
      $scope.assetSubNumberSearch.search = ($scope.cerfObject.asset_sub_number.name.length === 0)
        ? $scope.cerfObject.asset_sub_number.asset_sub_number
        : $scope.cerfObject.asset_sub_number.asset_sub_number + ' - ' + $scope.cerfObject.asset_sub_number.name;
      $scope.subNumberExists = true;
    }

    if (!!$scope.cerfObject.replacement_asset_main_number) {
      $scope.replacementAssetMainNumberSearch.search = ($scope.cerfObject.replacement_asset_main_number.name.length === 0)
        ? $scope.cerfObject.replacement_asset_main_number.asset_main_number
        : $scope.cerfObject.replacement_asset_main_number.asset_main_number + ' - ' + $scope.cerfObject.replacement_asset_main_number.name;
      $scope.replacementMainNumberExists = true;
    }

    if (!!$scope.cerfObject.replacement_asset_sub_number) {
      $scope.replacementAssetSubNumberSearch.search = ($scope.cerfObject.replacement_asset_sub_number.name.length === 0)
        ? $scope.cerfObject.replacement_asset_sub_number.asset_sub_number
        : $scope.cerfObject.replacement_asset_sub_number.asset_sub_number + ' - ' + $scope.cerfObject.replacement_asset_sub_number.name;
      $scope.replacementSubNumberExists = true;
    }
  }

  initialize();
}

cerfViewCtrl.$inject = ['$scope', '$rootScope', '$uibModalInstance', 'updateCerf', 'itemId',
  'globalFunc', 'item', 'addCerf', 'editingState', 'prId', 'toastr' , 'lang', 'budget',
  'expenseType', 'purchaseRequisitionsV2Services', 'getCompanyAsset', 'companyCode',  'pathConstants', '$http',
  '$filter', 'prV2Function', 'bifrostFixedAssetSap', 'bifrostFixedAssetMsgp',
  'singleAssetCategoryByCode', 'companyId'];

angular
  .module('metabuyer')
  .controller('cerfViewCtrl', cerfViewCtrl)
  .directive('cerfView', function ($uibModal) {
    return {
      restrict: 'AE',
      scope: {
        itemId: '=?',
        item: '=?',
        editingState: '=?',
        prId: '=?',
        companyCode: '=?',
        budget: '=?',
        expenseType: '=?',
        companyId: '=?'
      },
      link: function ($scope, element) {

        function displayPanel() {
          $uibModal.open({
            template: '<div ng-include="myTemplateUrl"></div>',
            controller: 'cerfViewCtrl',
            backdrop: 'static',
            keyboard: false,
            size: 'lg',
            resolve: {
              prId: function () {
                return $scope.prId;
              },
              itemId: function () {
                return $scope.itemId;
              },
              item: function () {
                return $scope.item;
              },
              editingState: function () {
                return $scope.editingState;
              },
              budget: function() {
                return $scope.budget;
              },
              expenseType: function() {
                return $scope.expenseType;
              },
              companyCode: function () {
                return $scope.companyCode;
              },
              companyId: function () {
                return $scope.companyId;
              }
            }
          });
        }

        element.bind('click', function () {
          displayPanel();
        });
      }
    };
  });
