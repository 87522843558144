'use strict';

angular.module('metabuyer.services.approvalEngine.approvalTemplates', ['ngResource'])
  .factory('approvalTemplates', ['$resource', 'pathConstants',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.approvalEngine.approvalTemplate.get,
        {
          limit: '@limit',
          page: '@page',
          sort: '@sort',
          includes: '@includes',
          filter_groups: '@filter_groups',
          position: '@position'
        },
        {
          'get': { method: 'GET' }
        }
      );
    }])
  .factory('approvalTemplateSingle', ['$resource', 'pathConstants',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.approvalEngine.approvalTemplate.detail,
        {
          id: '@id'
        },
        {
          'get': { method: 'GET' },
          'put': { method: 'PUT' },
          'delete': { method: 'DELETE' }
        }
      );
    }])
  .factory('approvalTemplateExport', ['$resource', 'pathConstants',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.approvalEngine.approvalTemplate.export,
        {
          id: '@id'
        },
        {
          'post': { method: 'POST' }
        }
      );
    }])
  .factory('getApprovalZip', ['$resource', 'pathConstants',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.approvalEngine.approvalTemplate.hash,
        {
          page: '@page',
          includes: '@includes',
          sort: '@sort',
          filter_groups: '@filter_groups'
        },
        {
          'get': { method: 'GET'}
        }
      );
    }])
  .factory('checkApprovalZipStatus', ['$resource', 'pathConstants',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.approvalEngine.approvalTemplate.checkStatus,
        {
          id: '@id'
        },
        {
          'get': { method: 'GET'}
        }
      );
    }])
  .service('DataListService', function() {
    var dataList = [];
    var initializeSearchField = [];

    return {
        setDataList: function(data, searchField) {
          dataList = data;
          initializeSearchField = searchField;
        },
        getDataList: function() {
          return dataList;
        },
        getSearchField: function() {
          return initializeSearchField;
        }
    };
  });
