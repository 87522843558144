'use strict';

/**
 * @name SourcingCategoryCtrl
 */
function SourcingCategoryCtrl($scope, $uibModal, dataList, $location,  $http, $filter, pathConstants, $stateParams, globalFunc) {
  $scope.getStatus = getStatus;
  $scope.addSourcingCategory = addSourcingCategory;
  $scope.showColumn = showColumn;
  $scope.search = search;
  $scope.searchText = '';
  $scope.openSourcingCategoryDetailsGroup = openSourcingCategoryDetailsGroup;
  $scope.importSourcingCategory = importSourcingCategory;
  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.columns = [
    {id: 'code', label: 'Code'},
    {id: 'descr', label: 'Description'},
    {id: 'descr_chinese', label: 'Description (Chinese)'},
    {id: 'created_at', label: 'Created At'},
    {id: 'creator_info.display_name', label: 'Created By'},
    {id: 'updated_at', label: 'Updated At'},
    {id: 'updater_info.display_name', label: 'Updated By'},
    {id: 'is_active', label: 'Status'}
  ];

  $scope.storageKey = 'master-data-sourcing-category-selected-columns';

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function importSourcingCategory() {
      $uibModal.open({
          templateUrl: 'app/masterDataManagement/Import-Template.html',
          backdrop: 'static',
          keyboard: false,
          resolve: {
              importLink: function () {
                  return pathConstants.apiUrls.imports.now;
              },
              params: function () {
                  return {
                      'class': 'App\\Metabuyer\\SourcingCategory\\Models\\SourcingCategory'
                  }
              },
              title: function () {
                  return 'Sourcing Category';
              }
          },
          controller: function ($scope, $uibModalInstance, importLink, title, params) {
              $scope.cancel = function () {
                  $uibModalInstance.close();
              };

              $scope.importLink = importLink;
              $scope.title = title;
              $scope.params = params;
          }
      });
  }

  function search(query) {
    $location.search({query: query});
  }

  function getStatus(isActive) {
    return !!isActive ? 'Active' : 'Inactive';
  }


  function openSourcingCategoryDetailsGroup(sourcingCategory) {
      var modalInstance = $uibModal.open({
          templateUrl: 'app/masterDataManagement/sourcingCategory/details/details.html',
          backdrop: 'static',
          controller: 'sourcingCategoryDetailsCtrl',
          keyboard: false,
          resolve: {
              item: function () {
                  return sourcingCategory
              },
              dataList: function() {
                return $scope.dataList;
              }
          }
      });

      modalInstance.result.then(function (result) {
          var data = result.content.data;
          for (var i = 0; i < $scope.dataList.length; i++) {
              if ($scope.dataList[i]['uuid'] === data.uuid) {
                  $scope.dataList[i] = data;
              }
          }
      });
  }

  function addSourcingCategory() {
    var modalInstance = $uibModal.open({
      templateUrl: 'app/masterDataManagement/sourcingCategory/manage/add.html',
      backdrop: 'static',
      controller: 'addSourcingCategoryCtrl',
      keyboard: false,
      scope: $scope
    });

    modalInstance.result.then(function (result) {
      var data = result.content.data;
      $scope.dataList.unshift({
        code: data.code,
        descr: data.descr,
        descr_chinese: data.descr_chinese || null,
        created_at: data.created_at,
        updated_at: data.updated_at,
        creator_info: data.creator_info,
        updater_info: data.updater_info,
        is_active: data.is_active,
        uuid: data.uuid
      });
    });
  }

  function init() {
      if (!!$stateParams.query)
          $scope.searchText = $stateParams.query;
      else
          $scope.searchText = '';
  }
  init();
}

SourcingCategoryCtrl.$inject = ['$scope', '$uibModal', 'dataList', '$location', '$http', '$filter', 'pathConstants', '$stateParams', 'globalFunc'];

angular
  .module('metabuyer')
  .controller('SourcingCategoryCtrl', SourcingCategoryCtrl);
