'use strict';
/**
 * @name companyAssetCtrl
 * @desc Controller for account code list in companies
 */
function companyAssetCtrl($scope, companyDetails, companyAssets, $state, $rootScope) {
  $scope.companyAssets = companyAssets;
  $scope.companyDetails = companyDetails.data;
  $scope.back = back;

  function back(){
    if (!!$scope.companyDetails && !!$scope.companyDetails._id)
      $state.go('main.user.companiesMgmt.detailCompanies', {id: $scope.companyDetails._id});
    else
      window.history.back();
  }

  function initialize(){
    var tabData = {
      tab: $scope.$parent.tabData[0],
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);
  }

  initialize();
}

companyAssetCtrl.$inject = [
  '$scope', 'companyDetails', 'companyAssets', '$state', '$rootScope'
];

angular.module('metabuyer')
  .controller('companyAssetCtrl', companyAssetCtrl);
