'use strict';

angular.module('metabuyer')
  .controller('supplierUserDetailViewCtrl', supplierUserDetailViewCtrl);

supplierUserDetailViewCtrl.$inject = ['$rootScope', '$scope', '$state', 'HighlightId', 'timezones', 'currencies', 'toastr', 'supplierUserService', 'user', 'Approvals', 'userFunctions', 'supplierUserCommentsService'];

function supplierUserDetailViewCtrl($rootScope, $scope, $state, HighlightId, timezones, currencies, toastr, supplierUserService, user, Approvals, userFunctions, supplierUserCommentsService) {
  $scope.showWarningMessages = showWarningMessages;
  $scope.getUserStatus = getUserStatus;
  $scope.back = back;

  $scope.commentService = supplierUserCommentsService;
  $scope.timezones = timezones;
  $scope.currencies = currencies;
  $scope.saveAs = false;
  $scope.user = user;
  $scope.approvals = [];
  $scope.isUserApproval = false;
  $scope.warningMessages = [];
  $scope.disableConfig = {
    supplier: true,
    email: true,
    display_name: true,
    phone: true,
    currency: true,
    timezone: true,
    supplier_user_delegate: true,
  }

  function loadApprovalFlow(approvalId) {
    if (!$scope.clonedUser || !$scope.clonedUser.approval_id) {
      $scope.approvals = [];
    }

    Approvals.get(
      {
        id: approvalId
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.approvals = resource.content.data;
        } else {
          $scope.approvals = [];
        }
      },
      function () {
        toastr.error('Error loading Approval Flow');
        $scope.approvals = [];
      });
  }

  function showWarningMessages() {
    return !_.isEmpty($scope.warningMessages);
  }

  function getUserStatus(status) {
    var status = userFunctions.convertUserStatus(status, true) || '';

    return status.toUpperCase()
  }

  function back() {
    $state.go('main.user.suppliersList.manageSupplierUser', {status: 'all'});
  }

  function initialize() {
    $scope.supplier = $scope.user.supplier;
    $scope.clonedUser = _.cloneDeep($scope.user);

    if (getUserStatus(user.status) === 'PENDING') {
      if (!!$scope.clonedUser && !!$scope.clonedUser.approval_id) {
        $scope.isUserApproval = true;
        loadApprovalFlow($scope.clonedUser.approval_id);
      } else if (!!$scope.clonedUser.supplier && !!$scope.clonedUser.supplier.approval_id) {
        $scope.isUserApproval = false;
        $scope.warningMessages = ['This approval flow belongs to Supplier. Fully approve supplier in order to fully approve this user.']
        loadApprovalFlow($scope.clonedUser.supplier.approval_id);
      }
    }
  }

  initialize();
}
