/**
 * @name fieldsSelector
 *
 *
 * @description
 *
 * Directive to handle the Selection, Storing and Loading of the headers in the manage/list pages
 *
 *
 * @author Dave Woo <wooyj@sunway.com.my>
 * @copyright Sunway Metacloud &copy; 2022
 *
 */

 'use strict';

 angular.module('metabuyer')
   .directive('fieldsSelector', function () {
     return {
       scope: {
         availableFields: '=',
         storageKey: '=',
         condition: '=?'
       },
       templateUrl: 'components/fieldsSelector/fieldsSelectorTemplate.html',
       restrict: 'E',
       controller: 'fieldsSelectorCtrl',
     }
   })
 
 
   /**
    * @name fieldsSelectorCtrl
    *
    * @description
    * The controller of the fieldsSelector directive handles the data of the button and the events, as well as storing
    * the data and retrieving from local storage
    *
    * @author Dave Woo <wooyj@sunway.com.my>
    * @copyright Sunway Metacloud &copy; 2022
    */
   .controller('fieldsSelectorCtrl', function($scope, globalFunc) {
 
     /**
      * function to filter the fields to be selected or not
      * @param fieldList
      * @returns {Array}
      */
     function filterFields(fieldList, condition) {
       var fields = [];
       _.forEach(fieldList, function (col) {
         if (!!col.conditional) {
           if (col.condition === condition) {
             fields.push(col);
           }
           if (col.condition instanceof Array) {
             for (var i in col.condition) {
               if (col.condition[i] === condition) {
                 fields.push(col);
               }
             }
           }
         } else {
           fields.push(col);
         }
       });
       return fields;
     }
 
     /**
      * Fields data
      * @type {Array}
      */
     $scope.selectedFields = [];
     $scope.buttonText = {
       buttonDefaultText: 'Fields'
     };
     $scope.fieldsSettings = {
       buttonClasses: 'btn btn-white',
       smartButtonMaxItems: 3
     };
 
     $scope.fieldsEvents = {
       onItemSelect: function (selected) {
         for (var i in $scope.fields) {
           if (selected.id === $scope.fields[i].id) {
             $scope.fields[i].selected = true;
           }
         }
         refreshSelectedFields();
       },
       onItemDeselect: function (selected) {
         for (var i in $scope.fields) {
           if (selected.id === $scope.fields[i].id) {
             $scope.fields[i].selected = false;
           }
         }
         $scope.$emit("onItemDeselectFieldsEmpty",selected);
         refreshSelectedFields();
       }
     };
 
     function refreshSelectedFields() {
       $scope.selectedFields = [];
       for (var i in $scope.fields) {
         if ($scope.fields[i].selected === true) {
           $scope.selectedFields.push($scope.fields[i]);
         }
       }
       /**
        * storing in the local storage
        */
       window.localStorage.setItem($scope.storageKey, JSON.stringify(_.map($scope.selectedFields, function (item) {
         return item.id
       })));
     }
 
     /**
      * loading the selected fields
      */
     function loadSelectedFields() {
 
       var tempItems = JSON.parse(window.localStorage.getItem($scope.storageKey));
       if (!!tempItems) {
         for (var i in $scope.fields) {
           for (var j in tempItems) {
             if (tempItems[j] === $scope.fields[i].id) {
               $scope.fields[i].selected = true;
               $scope.selectedFields.push($scope.fields[i]);
             }
           }
         }
       } else {
         for (var i in $scope.fields) {
           $scope.fields[i].selected = true;
           if(!!$scope.fields[i].unSelectedByDefault &&
              $scope.fields[i].unSelectedByDefault === true) {
             $scope.fields[i].selected = false;
             continue;
           }
           $scope.selectedFields.push($scope.fields[i]);
         }
       }
     }
 
     function initialize() {
       $scope.fields = filterFields($scope.availableFields, $scope.condition);
       loadSelectedFields();
       $scope.fieldOptions =  globalFunc.removeValueFromArray($scope.fields, 'unSelectable', true);
     }
 
     initialize();
   });
 