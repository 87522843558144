'use strict';

function ManageCatalogCodeCtrl($scope, dataList, catalogCode, $uibModal, globalFunc, toastr, pathConstants, $rootScope) {

  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.catalogCodesManageService = dataList;
  $scope.getActiveStatusName = getActiveStatusName;
  $scope.newCatalogCode = newCatalogCode;
  $scope.importCatalogCodes = importCatalogCodes;
  $scope.viewCatalogCodeDetails = viewCatalogCodeDetails;

  $scope.importLink = pathConstants.apiUrls.imports.now;

  $scope.showColumn = showColumn;

  $scope.storageKey = 'catalog-code-management-selected-columns';

  function showColumn(id){
    var i;
    var columns = $scope.columns;
    for (i in columns) {
      if(columns.hasOwnProperty(i)) {
        if (id === columns[i].id) {
          if(!!columns[i].conditional){
            if($scope.status === columns[i].condition)
              return columns[i].selected;
            if(columns[i].condition instanceof Array){
              var j;
              var columnCondition = $scope.columns[i].condition;
              for(j in columnCondition){
                if(columnCondition.hasOwnProperty(j)) {
                  if($scope.status === columnCondition[j])
                    return columns[i].selected;
                }
              }
            }
          }
          else
            return columns[i].selected;
        }
      }
    }
  }

  /**
   * returns the is_active name
   * @param code
   * @returns {*}
   */
  function getActiveStatusName(code){
    /**
     DEACTIVATED = 0; 
     ACTIVE = 1; 
     DELETED = 2; 
     DISABLED = 3; 
     */
    if (code === 0 || code === 'INACTIVE') {
      return 'Inactive'
    }
    if (code === 1 || code === 'ACTIVE') {
      return 'Active'
    }
    if (code === 2 || code === 'DELETED') {
      return 'Deleted'
    }
    if (code === 3 || code === 'DISABLED') {
      return 'Disabled'
    }
    return 'N/A';
  }

  function newCatalogCode(){
    var modalInstance = $uibModal.open({
      templateUrl: 'app/items/catalog-code/add/addCatalogCode.html',
      backdrop: 'static',
      keyboard: false,
      controller: function($scope, $uibModalInstance) {
        $scope.submitCatalogCode = submitCatalogCode;
        $scope.closeModal = closeModal;

        function closeModal(){
          $uibModalInstance.close();
        }

        function submitCatalogCode(code, descr){
          catalogCode.post(
            {
              code: code,
              descr: descr
            },
            function(resource){
              toastr.success('Catalog code added');
              $uibModalInstance.close(resource.content.data);
            },
            function(error){
              globalFunc.objectErrorMessage(error);
            }
          )
        }
      }
    });

    modalInstance.result.then(function (newValue) {
      if(!!newValue){
        $scope.dataList.unshift({
          _id: newValue._id,
          code: newValue.code,
          descr: newValue.descr,
          created_at: newValue.created_at,
          updated_at: newValue.updated_at,
          updater: [$scope.user],
          creator: [$scope.user],
          is_active: 1
        });
      }
    }, function () {
    });

  }

  function importCatalogCodes(){
    var modalInstance = $uibModal.open({
      templateUrl: "app/masterDataManagement/Import-Template.html",
      backdrop: 'static',
      keyboard: false,
      resolve: {
        importLink: function () {
          return $scope.importLink;
        },
        title: function () {
          return "Catalog Codes";
        }
      },
      controller: function ($scope, $uibModalInstance, importLink, title) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        $scope.importLink = importLink;
        $scope.title = title;
        $scope.params = {
          class: 'Metabuyer\\Models\\CatalogCode',
          'additional_params[]': ''
        };
      }
    });
  }

  function viewCatalogCodeDetails(data){
    var modalInstance = $uibModal.open({
      templateUrl: 'app/items/catalog-code/details/singleCatalogCode.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        catalogCodeDetails: function($q){
          var deferred = $q.defer();
          catalogCode.get(
            {
              id: data._id
            },
            function(resource){
              deferred.resolve(resource.content || {data: []});
            },
            function(){
              deferred.resolve([]);
            }
          );
          return deferred.promise;
        }
      },
      controller: function($scope, $uibModalInstance, catalogCodeDetails) {
        $scope.closeModal = closeModal;
        $scope.catalogCodeDetails = catalogCodeDetails.data;
        $scope.saveChanges = saveChanges;

        function closeModal(){
          $uibModalInstance.close();
        }

        function validateDesc(){
          if(!$scope.catalogCodeDetails.descr){
            return false;
          }
          return true;
        }

        function saveChanges(){

          if(validateDesc() == false) {
            toastr.error('Please fill required field before proceeding');
            return;
          }

          catalogCode.put(
                {
                  id: $scope.catalogCodeDetails._id
                },
                {
                  code: $scope.catalogCodeDetails.code,
                  descr: $scope.catalogCodeDetails.descr
                },
                function(resource){
                  toastr.success('Catalog code details updated');
                  $uibModalInstance.close(resource.content.data);
                },
                function(error){
                  globalFunc.objectErrorMessage(error);
                }
            )
        }

      }
    });

    modalInstance.result.then(function (newValue) {
      if(!!newValue){
        _.forEach($scope.dataList, function(dataList){
          if(dataList._id === newValue._id){
            dataList.code = newValue.code;
            dataList.descr = newValue.descr;
            dataList.is_active = newValue.is_active;
            dataList.updater[0].display_name = newValue.updater.display_name;
            dataList.updated_at = newValue.updated_at;
          }
        });
      }
    }, function () {
    });
  }

}

ManageCatalogCodeCtrl.$inject = ['$scope', 'dataList', 'catalogCode', '$uibModal', 'globalFunc', 'toastr', 'pathConstants', '$rootScope'];

angular.module('metabuyer')
  .controller('ManageCatalogCodeCtrl', ManageCatalogCodeCtrl);
