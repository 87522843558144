'use strict';
angular
  .module('metabuyer')
  .directive('focusInput',function($uibModal, Orders, toastr, $timeout) {
    return{
      restrict: 'A',
      scope: {
        focusInput: "=focusInput"
      },
      link: function(scope, element, attrs) {
        scope.$watch("focusInput", function(value){
          if(!!value){
            $timeout(function(){
              element[0].focus();
            });
          }
        })
      }
    };
  });
