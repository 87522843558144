'use strict';

angular.module('metabuyer.services.pdfPreview', ['ngResource'])
  .factory('pdfPreview', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.pdfPreview.preview,
      {
        id: '@id'
      },
      {
        'post': {method: 'POST'}
      }
    );
  });
