'use strict';
/**
 * @name addCommodityCtrl
 * @desc Controller for adding commodity code
 */

function addCommodityCtrl($rootScope, $scope ,toastr, commodityMasterDataList, commodityList, segmentList, availability,
                          $uibModalInstance) {
  $scope.closeModal = closeModal;
  $scope.addCommodity = addCommodity;
  $scope.masterDataSegments = segmentList.data;
  $scope.masterDataFamilies = [];
  $scope.masterDataClasses = [];
  $scope.masterDataCommodities = [];

  $scope.isV2Enabled = !!$rootScope.isV2Enabled;
  $scope.segmentCode = undefined;
  $scope.familyCode = undefined;
  $scope.classCode = undefined;
  $scope.commodityCode = undefined;

  $scope.fullCode = undefined;

  $scope.firstSixCode = undefined;

  $scope.selectedMasterDataSegments = selectedMasterDataSegments;
  $scope.selectedMasterDataFamilies = selectedMasterDataFamilies;
  $scope.selectedMasterDataClasses = selectedMasterDataClasses;
  $scope.duplicationCheck = duplicationCheck;

  // Statuses for dropdown in object
  $scope.commodityStatuses = [
    { isActive: 'Active' },
    { isActive: 'Deactivated' },
    { isActive: 'Disabled' }
  ];

  $scope.commodityPosting = [
    {value: 1, text: 'POSTING'},
    {value: 0, text: 'NON POSTING'}
  ];

  $scope.commodityTypes = [
    {value: 'OI' , descr: 'Other Items'},
    {value: 'IT' , descr: 'Information Technology'},
    {value: 'CP' , descr: 'Car Purchase'},
    {value: 'ME' , descr: 'M&E (for SunCity PI only)'}
  ];

  //to push a N/A field for segment
  var empty = {_id: null, code: -1, descr: 'N/A'};
  $scope.masterDataSegments.unshift(empty);

  function selectedMasterDataSegments(item) {
    if (item.code === -1) {
      $scope.masterDataSegments.selected = undefined;
      $scope.masterDataFamilies.selected = undefined;
      $scope.masterDataClasses.selected = undefined;
    } else {
      commodityList.get(
        {
          code : item.unique_name
        },
        function(resource){
          $scope.masterDataFamilies = resource.content.data;
          $scope.segmentCode = item.unique_name;
        },
        function(error){
          globalFunc.objectErrorMessage(error);
        }
      );

      $scope.masterDataFamilies.selected = undefined;
      $scope.masterDataClasses.selected = undefined;
    }
  }

  function selectedMasterDataFamilies(item) {
    commodityList.get(
      {
        code : item.unique_name
      },
      function(resource){
        $scope.masterDataClasses = resource.content.data;
        $scope.familyCode = item.unique_name.substring(2);
      },
      function(error){
        globalFunc.objectErrorMessage(error);
      }
    );

    $scope.masterDataClasses.selected = undefined;
  }

  function selectedMasterDataClasses(item) {
    commodityList.get(
      {
        code : item.unique_name
      },
      function(resource){
        $scope.masterDataCommodities = resource.content.data;
        $scope.classCode = item.unique_name.substring(4);
        $scope.firstSixCode = item.unique_name;
      },
      function(error){
        globalFunc.objectErrorMessage(error);
      }
    );
    $scope.masterDataCommodities.selected = undefined;
  }

  function validate(commodity){
    if(!commodity){
      return false;
    }
    if (!(commodity.descr)) {
      return false;
    }
    if (!(commodity.status)) {
      return false;
    }
    if (!(commodity.domain)) {
      return false;
    }
    if (!(commodity.select_commodity_type)) {
      return false;
    }
    if (!(commodity.commodity) || commodity.commodity.length != 2) {
      return false;
    }
    if (!(commodity.posting)) {
      return false;
    }
    return true;
  }

  function addCommodity(commodity){
    $scope.submitted = true;
    if(validate(commodity)){
      var postingValue = 0;
      if($scope.commodity.posting.text === 'POSTING'){
        postingValue = 1;
      }
      if($scope.commodity.posting.text === 'NON POSTING'){
        postingValue = 0;
      }

        // set status in integer to pass it as parameter
        var tempStatus;
        switch($scope.commodity.status.isActive.toLowerCase()){
          case 'active':
            tempStatus = 1;
            break;
          case 'deactivated':
            tempStatus = 0;
            break;
          case 'disabled':
            tempStatus = 3;
            break;
        }

      $scope.fullCode = $scope.firstSixCode + $scope.commodity.commodity;
      $scope.commodityCode = $scope.fullCode.substring(6);

      var submitData = {
        code: $scope.fullCode,
        descr: $scope.commodity.descr,
        descr_chinese: $scope.commodity.descr_chinese || null,
        status: tempStatus,
        domain: $scope.commodity.domain,
        type: $scope.commodity.select_commodity_type.value,
        segment: $scope.segmentCode,
        family: $scope.familyCode,
        class: $scope.classCode,
        commodity: $scope.commodityCode,
        unique_name: $scope.fullCode,
        parent_unique_name: $scope.firstSixCode,
        posting: postingValue
      };

        commodityMasterDataList.post(
          {},
          submitData,
          function () {
            toastr.success('Commodity has been added');
            closeModal();
          },
          function () {
            toastr.error('Commodity failed to be added');
            return false;
          }
        )
    }
    else{
      toastr.error('Please fill in required fields');
      return false;
    }
  }

  /**
   * @function duplicationCheck
   * Calls the global function's duplication check
   * Checks callback function return for assigning scope values for validation
   **/
  function duplicationCheck(model,field,value,checker)
  {
    if(!!value) {
      availability.get(
        {
          model: model,
          field: field,
          value: $scope.firstSixCode + value
        },
        function (resource) {
          if(resource) {
            if(checker === 'code'){
              $scope.codeCheck = false;
            }
          }
        },
        function (error) {
          if(error) {
            if(checker === 'code'){
              $scope.codeCheck = true;
            }
          }
        }
      );
    }
  }

  function closeModal() {
    $uibModalInstance.close();
  }
}

addCommodityCtrl.$inject = ['$rootScope', '$scope', 'toastr', 'commodityMasterDataList', 'commodityList', 'segmentList',
  'availability', '$uibModalInstance'];

angular
  .module('metabuyer')
  .controller('addCommodityCtrl', addCommodityCtrl);
