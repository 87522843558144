'use strict';

/**
 * Bifrost management states
 * @description
 * Contains the states for bifrost management page
 *
 * @states main.user.bifrost
 * Main bifrost status
 *
 * @states main.user.bifrost.manage
 * State for budget listing
 *
 * @author Ahmed Saleh <a.s.alsalali@gmail.com>
 * @copyright 2018 Metacloud Sdn. Bhd.
 */
angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('main.user.bifrost', {
        url: '/bifrost',
        controller: 'bifrostManagementCtrl',
        templateUrl: 'app/bifrostManagement/bifrostManagement.html',
        resolve: {
          accessRights: function (profile, $state, globalFunc) {
            if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN')) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.bifrost.dataOutbound', {
        templateUrl: 'app/bifrostManagement/manage/manage.html',
        url: '/dataOutbound?&{query:[a-zA-Z0-9]+}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}{page:[0-9]+}',
        controller: 'bifrostListCtrl',
        resolve: {
          dataList: function ($q, $stateParams, bifrostLogs, globalFunc) {
            var deferred = $q.defer();
            var params = {};

            if (!!$stateParams.filter) {
              params = {
                module: 'data-outbound',
                page: $stateParams.page,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              };
              params[$stateParams.filter] = $stateParams.query;
            } else {
              params = {
                module: 'data-outbound',
                page: $stateParams.page,
                'id': $stateParams.query,
                'details': $stateParams.query,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              };
            }

            bifrostLogs.get(
              params,
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Bifrost - Management';
          },
          type: function(){
            return 'Data outbound';
          }
        }
      })
      .state('main.user.bifrost.dataInbound', {
        templateUrl: 'app/bifrostManagement/manage/manage.html',
        url: '/dataInbound?&{query:[a-zA-Z0-9]+}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}{page:[0-9]+}',
        controller: 'bifrostListCtrl',
        resolve: {
          dataList: function ($q, $stateParams, bifrostLogs, globalFunc) {
            var deferred = $q.defer();
            var params = {};

            if (!!$stateParams.filter) {
              params = {
                module: 'data-inbound',
                page: $stateParams.page,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              };
              params[$stateParams.filter] = $stateParams.query;
            } else {
              params = {
                module: 'data-inbound',
                page: $stateParams.page,
                'id': $stateParams.query,
                'details': $stateParams.query,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              };
            }

            bifrostLogs.get(
              params,
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Bifrost - Data Inbound Listing';
          },
          type: function(){
            return 'Data inbound';
          }
        }
      })
      .state('main.user.bifrost.dataSync', {
        templateUrl: 'app/bifrostManagement/manage/manage.html',
        url: '/dataSync?&{query:[a-zA-Z0-9]+}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}{page:[0-9]+}',
        controller: 'bifrostListCtrl',
        resolve: {
          dataList: function ($q, $stateParams, bifrostLogs, globalFunc) {
            var deferred = $q.defer();
            var params = {};

            if (!!$stateParams.filter) {
              params = {
                module: 'data-sync',
                page: $stateParams.page,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              };
              params[$stateParams.filter] = $stateParams.query;
            } else {
              params = {
                module: 'data-sync',
                page: $stateParams.page,
                'id': $stateParams.query,
                'details': $stateParams.query,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              };
            }

            bifrostLogs.get(
              params,
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Bifrost - Management';
          },
          type: function(){
            return 'Data sync';
          }
        }
      })
      .state('main.user.bifrost.purchaseOrder', {
        templateUrl: 'app/bifrostManagement/purchaseOrder/purchaseOrder.html',
        url: '/purchaseOrder?&{query:[a-zA-Z0-9]+}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}{page:[0-9]+}',
        controller: 'bifrostPurchaseOrderCtrl',
        resolve: {
          dataList: function ($q, $stateParams, purchaseOrder, globalFunc) {
            var deferred = $q.defer();
            var params = {
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            if (!!$stateParams.query) {
              params[$stateParams.filter] = $stateParams.query;
            }

            purchaseOrder.get(
              params,
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Bifrost - Management';
          },
          type: function(){
            return 'Purchase Order';
          }
        }
      })
      .state('main.user.bifrost.supplier', {
      templateUrl: 'app/bifrostManagement/supplier/supplier.html',
      url: '/supplier?&{query:[a-zA-Z0-9]+}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}{page:[0-9]+}',
      controller: 'bifrostSupplierCtrl',
      resolve: {
        dataList: function ($q, $stateParams, bifrostSupplier, globalFunc) {
          var deferred = $q.defer();
          var params = {
            page: $stateParams.page,
            order_by: $stateParams.order_by,
            order: $stateParams.order
          };

          if (!!$stateParams.query) {
            params[$stateParams.filter] = $stateParams.query;
          }

          bifrostSupplier.get(
            params,
            function (resource) {
              deferred.resolve(resource);
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
              deferred.resolve([]);
            }
          );
          return deferred.promise;
        },
        $title: function () {
          return 'Bifrost - Management';
        },
        type: function(){
          return 'Supplier';
        }
      }
    })
      .state('main.user.bifrost.mappedSupplier', {
        templateUrl: 'app/bifrostManagement/mappedSupplier/mappedSupplier.html',
        url: '/mapped-supplier?&{query:[a-zA-Z0-9]+}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}{page:[0-9]+}',
        controller: 'bifrostMappedSupplierCtrl',
        resolve: {
          dataList: function ($q, $stateParams, mappedSupplier, globalFunc) {
            var deferred = $q.defer();
            var params = {
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            mappedSupplier.get(
              params,
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Bifrost - Mapped Supplier';
          },
          type: function(){
            return 'Supplier';
          }
        }
      })
      .state('main.user.bifrost.jdeDataInboundInfo', {
        templateUrl: 'app/bifrostManagement/jdeDataInboundInfo/jdeDataInboundInfo.html',
        url: '/dataInboundInfo?&{query:[a-zA-Z0-9]+}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}{page:[0-9]+}',
        controller: 'bifrostDataInboundInfoCtrl',
        resolve: {
          dataList: function ($q, $stateParams, globalFunc, bifrostInboundInfo) {
            var deferred = $q.defer();
            var params = {
              provider: 'JDE',
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            if (!!$stateParams.query) {
              params[$stateParams.filter] = $stateParams.query;
            }

            bifrostInboundInfo.get(
              params,
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Bifrost - Data Inbound Info';
          },
          type: function(){
            return 'Data Inbound';
          }
        }
      })
      .state('main.user.bifrost.jdeDataOutboundInfo', {
        templateUrl: 'app/bifrostManagement/jdeDataOutboundInfo/jdeDataOutboundInfo.html',
        url: '/dataOutboundInfo?&{query:[a-zA-Z0-9]+}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}{page:[0-9]+}',
        controller: 'bifrostJdeDataOutboundInfoCtrl',
        resolve: {
          dataList: function ($q, $stateParams, globalFunc, bifrostOutboundInfo) {
            var deferred = $q.defer();
            var params = {
              provider: 'JDE',
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            if (!!$stateParams.query) {
              params[$stateParams.filter] = $stateParams.query;
            }

            bifrostOutboundInfo.get(
              params,
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Bifrost - Data Outbound Info';
          },
          type: function(){
            return 'Data Outbound';
          }
        }
      })
      .state('main.user.bifrost.ifcaDataOutboundInfo', {
        templateUrl: 'app/bifrostManagement/ifcaDataOutboundInfo/ifcaDataOutboundInfo.html',
        url: '/ifcaDataOutboundInfo?&{query:[a-zA-Z0-9]+}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}{page:[0-9]+}',
        controller: 'bifrostIfcaDataOutboundInfoCtrl',
        resolve: {
          dataList: function ($q, $stateParams, globalFunc, bifrostOutboundInfo) {
            var deferred = $q.defer();
            var params = {
              provider: 'IFCA',
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            if (!!$stateParams.query) {
              params[$stateParams.filter] = $stateParams.query;
            }

            bifrostOutboundInfo.get(
              params,
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Bifrost - Data Outbound Info';
          },
          type: function(){
            return 'Data Outbound';
          }
        }
      })
  }]);
