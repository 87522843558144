'use strict';

function priceComparisonFunction(priceComparisonResource, $q, lang, globalFunc, $rootScope, $state) {

  var pcObject = {};
  var initialData = {};
  var submitErrors = [];
  var pcEditableState = 'editable';

  return {
    initialize: function(pc) {
      this.clearData();
      this.setData(pc);
    },
    clearData: function() {
      pcObject = {};
    },
    setData: function(pc) {
      pcObject = pc;
    },
    getData: function() {
      return pcObject;
    },
    getEditableState: function() {
      return this.setEditableState();
    },
    setEditableState: function() {

      var priceComparison = this.getData();
      var editDeleteStatus = {
        edit: false,
        delete: false,
      };

      if (!!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN') ||
        !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'ADMIN')) {
        editDeleteStatus.edit = true;
        editDeleteStatus.delete = true;
      }

      if (!canEditDelete && priceComparison.pr_info !== undefined) {
        var canEditDelete = _.find($rootScope.currentUser.role_assignments, function(assignment) {
          if (assignment.organization_id === priceComparison.pr_info.company._id) {

            var pcRequesterFound = _.find(assignment.roles, function(role) {
              return role.name === 'Price Comparison Requester';
            });

            return !!pcRequesterFound;
          }
        });

      }

      if (!!canEditDelete) {
        editDeleteStatus.edit = true;
        editDeleteStatus.delete = true;
      }

      return editDeleteStatus;
    },
    canEdit: function() {
      return this.getEditableState().edit;
    },
    canDelete: function() {
      return this.getEditableState().delete;
    },
  }
}

priceComparisonFunction.$inject = ['priceComparisonResource', '$q', 'lang', 'globalFunc', '$rootScope',
  '$state'
];

angular
  .module('metabuyer')
  .factory('priceComparisonFunction', priceComparisonFunction);
