'use strict';

/**
 *
 * @param $scope
 * @param $uibModalInstance
 * @param $http
 * @param pathConstants
 * @param $filter
 * @param toastr
 * @param studentLife
 * @param studentLifeServices
 * @param globalFunc
 */
function detailStudentLife($scope, $uibModalInstance, $http, pathConstants, $filter, toastr, studentLife,
                           studentLifeServices, globalFunc) {
  $scope.editClicked = false;
  $scope.studentLife = studentLife.data;
  $scope.tab = 1;
  $scope.cancel = cancel;
  $scope.editStudentLife = editStudentLife;
  $scope.updateStudentLife = updateStudentLife;

  function cancel() {
    $uibModalInstance.close();
  }

  function editStudentLife() {
    $scope.editClicked = true;
  }

  function updateStudentLife() {
    studentLifeServices.put(
      {
        id: $scope.studentLife._id
      },
      {
        descr: $scope.studentLife.descr
      },
      function (resource) {
        toastr.success('Student Life data has been updated');
        $uibModalInstance.close(resource.data);
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
        return false;
      }
    );
  }
}

detailStudentLife.$inject = ['$scope', '$uibModalInstance', '$http', 'pathConstants', '$filter', 'toastr', 'studentLife',
  'studentLifeServices', 'globalFunc'];

angular
  .module('metabuyer')
  .controller('detailStudentLife', detailStudentLife);



