'use strict';

function UserPermissions ($rootScope, globalFunc) {

  function validatePermission(profile, domain) {

    if(!!profile && !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN'))
      return true;

    if (!profile) {
      return false;
    }

    if (!profile.role_assignments) {
      return false;
    }

    if (profile.role_assignments.length < 1) {
      return false;
    }

    if (!_.isString(domain)) {
      return false;
    }


    return true;
  }

  // get list of companies and cost centers by permission, domain and profile
  //TODO: Need to enhance it
  function getCompaniesByPermission(profile, domain, action, org) {
    var organizationArray = [];
    for (var index in profile.role_assignments) {
      for (var i in profile.role_assignments[index].roles) {
        for (var j in profile.role_assignments[index].roles[i].permissions) {
          var permission = profile.role_assignments[index].roles[i].permissions[j].name.split(' ');
          if(org === 'company'){
            if (profile.role_assignments[index].weight == 3 && (permission[0] == action && permission[1] == domain)) {
              organizationArray.push(profile.role_assignments[index].organization_id);
            }
          }
          else if(org === 'cost_center'){
            if (profile.role_assignments[index].weight == 4 && (permission[0] == action && permission[1] == domain)){
              organizationArray.push(profile.role_assignments[index].organization_id);
            }
          }
        }
      }
    }
    return organizationArray;
  }

  /**
   * types of permissions:
   *
   * @param profile         The user
   * @param domain          the module/domain. eg: PR, PO
   * @param action          the action. eg: 'Create', 'Delete'
   * @param organizationIds array of company/cost center ids that the user needs to have in their role assignment
   * @returns {boolean}
   */
  //TODO: Need to enhance it
  function hasPermission(profile, domain, action, organizationIds) {
    if (!!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN'))
      return true;

    var userRoleAssignments = profile.role_assignments;

    if (!!organizationIds) {
      userRoleAssignments = profile.role_assignments.filter(function (roleAssignment) {
        return organizationIds.indexOf(roleAssignment.organization_id) > -1;
      });
    }

    for (var index in userRoleAssignments) {
      for (var i in userRoleAssignments[index].roles) {
        for (var j in userRoleAssignments[index].roles[i].permissions) {
          var permission = userRoleAssignments[index].roles[i].permissions[j].name.split(' ');

          if (action) {
            if (permission[1] == domain && permission[0] == action) {
              return true;
            }
          } else {
            if (permission[1] == domain) {
              return true;
            }
          }
        }
      }
    }

    return false;
  }

  function hasSupplierWebUserRole(profile) {
    if (!!profile &&
      (!!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'SUPPLIER_WEB_USER') ||
        !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'SUPPLIER_WEB_COMPANY_USER'))) {
      return true;
    }
    return false;
  }

  function hasSupplierCompanyUserRole(profile) {
    if (!!profile &&
      !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'SUPPLIER_WEB_COMPANY_USER')) {
      return true;
    }

    return false;
  }

  function hasSupplierWebAdminRole(profile) {
    if(!!profile && !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'SUPPLIER_WEB_ADMIN')) {
      return true;
    }

    return false;
  }

  function hasCmmsUserRole(profile)
  {
    if (!!profile && !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'CMMS User')) {
      return true;
    }

    return false;
  }

  function hasTenantSuperAdminRole(profile)
  {
    if (!!profile && !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN')) {
      return true;
    }

    return false;
  }

  function isDeveloper(profile){
    if (!!profile && !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'DEVELOPER', 1)) {
      return true;
    }

    return false;
  }

  /**
   * Function to check permissions by module. `action` and `organization` are optional
   *
   * @param profile      The user
   * @param domain       the module/domain. eg: PR, PO
   * @param action       the action. eg: 'Create', 'Delete'
   * @param organization array of company/cost center ids that the user needs to have in their role assignment
   * @returns {*}
   */
  function checkPermissionsAccess(profile, domain, action, organization) {
    if (userPermission.validatePermission(profile, domain) === false)
      return false;

    return userPermission.hasPermission(profile, domain, action, organization);
  }

  // get list of companies by permission and profile on budget
  function getBudgetCompanies(profile, action) {
    var budgetEnabledCompanies = [];

    var companiesWithPermission = userPermission.getCompaniesByPermission(profile, 'Budget', action, 'company');
    var costCenterWithPermission = userPermission.getCompaniesByPermission(profile, 'Budget', action, 'cost_center');

    if (!!costCenterWithPermission) {
      var companyIds = [];
      for (var i = 0; i < profile.costCenters.length; i++) {
        if (costCenterWithPermission.indexOf(profile.costCenters[i]._id) !== -1) {
          companyIds.push(profile.costCenters[i].company_id);
        }
      }
      companiesWithPermission = companiesWithPermission.concat(companyIds);
    }

    _.forEach(profile.companies, function(company) {
      if (!!company.budget_setting && !!company.budget_setting.enable &&
        (companiesWithPermission.indexOf(company._id) !== -1)) {
        budgetEnabledCompanies.push(company);
      }
    });

    return budgetEnabledCompanies;
  }

  var userPermission = {};
  userPermission.validatePermission = validatePermission;
  userPermission.getCompaniesByPermission = getCompaniesByPermission;
  userPermission.hasPermission = hasPermission;
  userPermission.checkPermissionsAccess = checkPermissionsAccess;
  userPermission.getBudgetCompanies = getBudgetCompanies;
  userPermission.hasSupplierWebAdminRole = hasSupplierWebAdminRole;
  userPermission.hasSupplierWebUserRole = hasSupplierWebUserRole;
  userPermission.hasSupplierCompanyUserRole = hasSupplierCompanyUserRole;
  userPermission.hasCmmsUserRole = hasCmmsUserRole;
  userPermission.hasTenantSuperAdminRole = hasTenantSuperAdminRole;
  userPermission.isDeveloper = isDeveloper;

  return userPermission;
}
UserPermissions.$inject = ['$rootScope', 'globalFunc'];
angular
  .module('metabuyer')
  .factory('UserPermissions',UserPermissions);


