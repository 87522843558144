'use strict';

/**
 *
 * @name catalogPanelCtrl
 *
 * @description Controller for Catalog Panel, it takes care of loading the items, assigning the tabs
 * as well as requesting the detailed information about an item
 *
 *
 * @requires $scope
 * @requires $rootScope
 * @requires catalogShoppingSearch
 * @requires $http
 * @requires pathConstants
 * @requires globalFunc
 * @requires $filter
 * @requires purchaseRequisitionsServices
 * @requires registerViewedItem
 * @requires $uibModal
 * @requires prFunction
 * @requires starringItem
 *
 *
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Sunway Metacloud &copy; 2016
 */

function catalogV2HeaderCtrl(
  $scope, $rootScope,catalogShoppingSearchV2, $http, pathConstants, globalFunc, $filter, purchaseRequisitionsV2Services,
  registerViewedItem, $uibModal, prV2Function, starItemUuid, itemsCategories, metabuyerCache, paymentTerms, $anchorScroll,
  prConfig, viewItemUuid
) {


 function showItemDetails(item) {

   $uibModal.open({
     templateUrl: 'components/purchaseRequisitionV2Components/purchaseRequisitionV2ShoppingMode/detailsV2Template.html',
     controller: 'CatalogItemV2DetailCtrl',
     backdrop: 'static',
     keyboard: false,
     windowClass: 'item-details-modal',
     resolve: {
       item: function () {
         return item.catalogItem;
       },
       itemDetails: function () { //remove this, its not used
         return item.catalogItem;
       },
       addItemToPRFunction: function () {
         return addItemToPRResource;
       },
       paymentTerms: function () {
         return paymentTerms;
       },
       prItemLimit: function () {
         return $scope.prItemLimit
       },
       //this is the main item combined the catalog item and company item
       parentItem: function () {
         return item;
       },
       starringFunction: function () {
         return $scope.itemStarring;
       }
     }
   });

   /**
    * add to the list of recently viewed items
    */
   viewItemUuid.put(
     {},
     {
       item_uuid: item.catalogItem.uuid
     });
 }


  /**
   * function to prepare the breadCrumb on case of category selection
   * @param code
   * @param previousBreadCrumb
   * @returns {{}}
   */
  function prepareBreadCrumb(code, previousBreadCrumb) {


    var breadCrumb = {};
    var familyCode = '';

    var segmentCode = code.substring(0, 2).concat('000000');
    var segment = globalFunc.findInArray($scope.categories, 'code', segmentCode);

    // type Segment
    if (code.substring(2) === '000000') {
      breadCrumb.current = segment;
      breadCrumb.childern = segment.families;
    }
    // type Family
    else if (code.substring(4) === '0000') {
      breadCrumb.parent = segment;
      breadCrumb.current = globalFunc.findInArray(segment.families, 'code', code);
      breadCrumb.childern = breadCrumb.current.classes;
    }
    // type Class
    else if (code.substring(6) === '00') {
      familyCode = code.substring(0, 4).concat('0000');
      breadCrumb.grandParent = segment;
      breadCrumb.parent = globalFunc.findInArray(segment.families, 'code', familyCode);
      breadCrumb.current = globalFunc.findInArray(globalFunc.findInArray(segment.families, 'code', familyCode).classes, 'code', code);
      breadCrumb.childern = globalFunc.findInArray(globalFunc.findInArray(segment.families, 'code', familyCode).classes, 'code', code).commodities;

    }
    // type Commodity
    else
      breadCrumb = previousBreadCrumb;

    return breadCrumb;
  }

  function countExactandRelatedItems(catalogList, query) {
    $scope.exactCounter = 0;
    $scope.relatedCounter = 0;
    var pattern = new RegExp(query, 'i');
    if (query) {
      $scope.query = query;
      catalogList.forEach(function (item) {
          if (!item.item_name.match(pattern)) {
            $scope.relatedCounter += 1;
          } else {
            $scope.exactCounter += 1;
          }
        }
      );
    }
  }

  /**
   * function that posts the Item to add to the PR
   * @param itemDetailsInfo
   * @param qty
   * @returns {*|{method, params, url}}
   */
  function addItemToPRResource(itemDetailsInfo, qty) {
    prV2Function.setLoading('lineItemTable', true);
    prV2Function.setLoading('addingItem', true);
    prV2Function.setLoading('updatePRField', true);
    return purchaseRequisitionsV2Services.addLineItem({
      requisition_id: $scope.PR._id,
      action: 'add-item'
    }, {
      'item_uuid': itemDetailsInfo.uuid,
      'qty': qty
    });
  }

  /**
   * function to call the resource and update the html when adding items to the PR
   * @param item
   * @param qty
   */
  function addItemToPR(item, qty){
    if (!qty || qty < 1) {
      globalFunc.objectErrorMessage("Cannot be empty or negative value");
      return;
    }

    var pr = prV2Function.getPRData();

    // check pr item limit
    if (isItemLimitReached()) {
      globalFunc.objectErrorMessage("PR line item limit reached. Only " + $scope.prItemLimit + " line items allowed per PR");
      return;
    }

    var validateLumpSum = prV2Function.validateLumpSum(prepareAddItem(item), -1);

    if (!checkPaymentTerm(item)) {
      swal({
        title: 'Invalid Payment Term',
        text: 'The payment term for this item is inactive. Please check.',
        type: 'info',
        showCancelButton: true,
        showConfirmButton: false
      });
    }
    else {
      if (!!pr.lump_sum_discount && !validateLumpSum.status) {
        swal({
          title: 'Confirm add item?',
          text: validateLumpSum.message,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Confirm',
          closeOnConfirm: true
        }, function (isConfirm) {
          if (isConfirm) {
            // remove lump sum
            prV2Function.removeLumpSum(pr._id, function (resource) {
              if (!!resource)
                sendingAddNewItemRequest(item, qty);
            })
          }
        });
      }
      else
        sendingAddNewItemRequest(item, qty);
    }
  }

  /**
   * Increment the item quantity
   * @param index
   * @param id
   */
  function plusItemQty(index, count) {
    if (!count || count < 1) {
      count = 0
    }

    count = parseInt(count) + 1;
    $scope.catalogList[index].catalogItem.quantityOrdered = count;
  }

  /**
   * Decrement the item quantity
   * @param index
   * @param id
   */
  function minusItemQty(index, count) {
    if (!count || count < 1) {
      count = 2
    }

    count = parseInt(count) - 1;
    if (!count < 1) {
      $scope.catalogList[index].catalogItem.quantityOrdered = count;
    }
  }

  /**
   * validate new item
   * if the pr got lump sum
   * @param item
   * @param qty
   */
  function sendingAddNewItemRequest(item, qty) {
    addItemToPRResource(item, qty).$promise.then(function(resource){
      if (!!resource && !!resource.content && !!resource.content.data) {
        $rootScope.$broadcast('itemAdded');
        prV2Function.setPRData(resource.content.data);
        refreshAddCartButton();
      }

      prV2Function.setLoading('lineItemTable', false);
      prV2Function.setLoading('addingItem', false);
      $rootScope.$broadcast('checkIntegrationData')
    },function (error){
      globalFunc.objectErrorMessage(error.data);
      prV2Function.setLoading('lineItemTable', false);
      prV2Function.setLoading('addingItem', false);
    });
  }

  /**
   * the type ahead for the supplier search
   * @param val
   * @returns {*|webdriver.promise}
   */
  function supplierListing(val) {
    if(!!val && val.length > 2) {
      $scope.loading = true;

      var moduleParam = {
        module: 'suppliers'
      };

      var prData = prV2Function.getPRData();
      var additionalParams = {
        'criteria[0][basic_info|descr]': val,
        'criteria[0][basic_info|reg_no]': val,
        'criteria[0][$operator]': 'or',
        'criteria[1][basic_info|status][0]': 'active',
        'criteria[1][basic_info|status][1]': 'inactive',
        'criteria[1][basic_info|status][2]': 'blacklisted',
        'criteria[1][basic_info|status][3]': 'active_pending',
        'criteria[2][$operator]': 'and',
        'criteria[2][$strict]': true,
        'criteria[2][$is_pr_item_filter]': true,
        'criteria[2][company_contracts|company|company_code][0]': prData.company.code,
        'criteria[2][company_contracts|company|company_code][1]': prData.shadow_company.code,
        'criteria[3][$strict]': true,
        'criteria[3][$is_pr_item_filter]': true,
        'criteria[3][company_contracts|status]': 'ACTIVE',
        criteria_operator: 'and',
        offset: 10
      };

      if (!!$rootScope.isSourcingSupplierEnabled) {
        additionalParams['criteria[2][basic_info|is_p2o_supplier]'] = true;
      }

      return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: additionalParams
      }).then(function (response) {
        $scope.loading = false;
        return response.data.content.data.map(function (item) {
          return modifySupplierObject(item);
        });
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * the type ahead for the item categories
   * @param val
   * @returns {*|webdriver.promise}
   */
  function itemCategorySearch(val) {
    if (!!val && val.length > 2) {
      $scope.loading = true;

      var moduleParam = {
        module: 'item-category'
      };
      var additionalParams = {
        'criteria[1][is_active]': true,
        'criteria[0][name]': val,
        'criteria[0][code]': val,
        'criteria[0][descr]': val,
        'criteria[0][$operator]': 'or',
        criteria_operator: 'and',
        offset: 10
      };
      return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: additionalParams,
        ignoreLoadingBar: true
      }).then(function (response) {
        $scope.loading = false;
        if (response.data.content.data.length === 0) {
          return [];
        } else {
          return response.data.content.data.map(function (item) {
            return item;
          });
        }
      });
    }
  }

  /**
   * Function to modify supplier object and pass label class as html
   */
  function modifySupplierObject(supplier) {
    supplier.labelStatus = 'warning';
    if (supplier.basic_info.status.toLowerCase() === 'active') {
      supplier.labelStatus = 'primary';
    }

    return supplier;
  }

  /**
   * Function to format the ng-model after typeahead selected
   */
  function formatModel(model) {
    var indexOfHtml = model.indexOf('<');
    $scope.searchedSupplier.text = model.substring(0, indexOfHtml);
  }


  /**
   * view or hide the Category list
   */
  function setCategoriesView() {
    if (!metabuyerCache.get('categories')) {
      itemsCategories.get(
        {
          code: 'all',
          groupIds: [prV2Function.getCatalogGroup()]
        },
        function (resource) {
          if (!!resource.content && !!resource.content.data) {
            metabuyerCache.put('categories', resource.content.data);
            $scope.categories = resource.content.data;
            divideCategories();
          }
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    } else {
      $scope.categories = metabuyerCache.get('categories');
      divideCategories();
    }
  }

  /**
   * validate and set which display for the catalog is active
   * @param code
   * @param value
   */
  function setCatalogDisplay (value, code){
    $scope.supplierSearchDisplay = (code === 'supplier');
    $scope.searchItemsCategories = (code === 'item-categories');
    $scope.catalogDisplayHandler = value;
  }

  /**
   * selected a filter and update the filter message
   * @param filter
   */
  function selectFilter(filter) {
    $scope.selectedFilter = filter;
    $scope.selectedFilter.text = 'Search ' + filter.label + ' catalog';
  }


  /**
   * prepare the payload to be searched
   * @param query
   */
  function searchCatalog(query) {
    $scope.searchActivated = true;
    $scope.selectedTab = 'search_results';
    $scope.paramsSource.code = $scope.selectedFilter.code;
    $scope.paramsSource.query = query;
    $scope.paramsSource.mb_supplier_code = null;

    if ($scope.selectedFilter.type === 'root')
      $scope.paramsSource.type = 'search';

    if ($scope.selectedFilter.type === 'tab') {
      $scope.paramsSource.type = $scope.selectedFilter.code;
      loadItems($scope.paramsSource.type, false, query, $scope.selectedFilter.code);
    } else if ($scope.selectedFilter.type === 'supplier') {
      $scope.paramsSource.code = null;
      $scope.paramsSource.mb_supplier_code = $scope.selectedFilter.code;
      loadItems('search', $scope.selectedFilter.code, query, false);
    }
    else
      loadItems('search', false, query, false, $scope.selectedFilter.code);
  }


  /**
   * the main function for loading items
   * @param type
   * @param supplierCode
   * @param query
   * @param tab
   * @param code
   */
  function loadItems(type, supplierCode, query, tab, code) {
    $scope.catalogList = [];
    $scope.meta = {};
    $scope.catalogDisplayHandler = 'showMessage';
    $scope.message = 'Loading items';
    catalogShoppingSearchV2.get(
      {
        user_id: $rootScope.currentUser._id,
        type: type,
        mb_supplier_code: !!supplierCode ? supplierCode : null,
        query: !!query ? query : null,
        group: !!tab ? tab : null,
        code: !!code ? code : null,
        groupIds: [prV2Function.getCatalogGroup()],
        companyCode: prV2Function.getPRData().company.code,
        billingCompanyCode: prV2Function.getPRData().shadow_company.code,
        isPcm: prV2Function.getPRData().expense_type.is_pcm,
        expenseTypeCategory: !!prV2Function.getPRData().expense_type.category ?
          prV2Function.getPRData().expense_type.category : undefined,
        itemCategory: !!$scope.paramsSource.itemCategory ? $scope.paramsSource.itemCategory : undefined,
        tenderId: !!prV2Function.getPRData().tender_id ? prV2Function.getPRData().tender_id : undefined
      },
      function (resource) {
        $scope.showLoadingMessage = false;

        if (!!resource.content && !!resource.content.data) {
          $scope.catalogDisplayHandler = 'showItems';
          $scope.meta = resource.content.meta;
          $scope.catalogList = resource.content.data;
          countExactandRelatedItems($scope.catalogList, query);
        } else {
          //TODO: handle the error
        }

        if (!$scope.catalogList || $scope.catalogList.length < 1) {
          $scope.catalogDisplayHandler = 'showMessage';
          $scope.message = 'There are no Items under the selected category at the moment!';
        }
      },
      function (error) {
        $scope.catalogDisplayHandler = '';
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  /**
   * updated the drop down for the list to be searched
   * @param code
   * @param label
   * @param type
   * @param makeAllAsDefault
   */
  function refreshSearchFilters(code, label, type, makeAllAsDefault) {
    $scope.searchFilter = [];
    /**
     * add the All option and setting it for the search filter
     */
    $scope.searchFilter.push(
      {
        label: 'All',
        code: '00000000',
        type: 'root'
      }
    );
    selectFilter($scope.searchFilter[0]);

    if (!!label && !!code) {
      $scope.searchFilter.push({
        label: label,
        code: code,
        type: type
      });

    if(!makeAllAsDefault)
      selectFilter($scope.searchFilter[1]);
    }
  }


  /**
   * this function is the main to be called as it calls each other individually to perform different tasks
   * @param code
   * @param label
   * @param doNotLoadItems
   * @param type
   * @param makeAllAsDefault
   * @param status
   */
  function browseCatalog(code, label, doNotLoadItems, type, makeAllAsDefault, status) {
    var text = '';
    $scope.invalidSupplierSelected = false;

    if (!!status && status.toLowerCase() !== 'active') {
      if (status.toLowerCase() === 'inactive') {
        text = 'The selected Supplier has already been deactivated. Please select another Supplier.';
      } else if (status.toLowerCase() === 'blacklisted') {
        text = 'The selected Supplier has already been blacklisted. Please select another Supplier.';
      } else {
        text = 'The selected Supplier is not valid, please select another supplier.';
      }

      globalFunc.objectErrorMessage(text);
      $scope.item = undefined;
      $scope.invalidSupplierSelected = true;
      return false;
    }


    $scope.search.title = '';
    $scope.paramsSource.query = '';

    setCatalogDisplay('showItems', type);

    refreshSearchFilters(code, label, type, makeAllAsDefault);

    if (!doNotLoadItems) {
      if (type === 'supplier') {
        $scope.paramsSource.type = 'search';
        $scope.paramsSource.mb_supplier_code = code;
        $scope.paramsSource.code = null;
        $scope.paramsSource.itemCategory = null;
        loadItems('search', code);
      }
      else if(type ==='item-categories'){
        $scope.paramsSource.type = 'search';
        $scope.paramsSource.mb_supplier_code = null;
        $scope.paramsSource.code = null;
        $scope.paramsSource.itemCategory = code;
        loadItems('search');
      }
      else {
        $scope.paramsSource.type = code;
        $scope.paramsSource.mb_supplier_code = null;
        $scope.paramsSource.itemCategory = null;
        loadItems(code);
      }
    } else {
      $scope.catalogList = [];
      $scope.meta = undefined;
    }
    if (type === 'all_categories') {
      setCategoriesView(true);
    }

    if (type === 'category')
      $scope.currentCategory = prepareBreadCrumb(code, $scope.currentCategory);

    $scope.searchActivated = false;
  }

  /**
   * get loading value from prFunction service
   * @param loadingObject
   * @returns {*|{}}
   */
  function checkLoading(loadingObject) {
    return prV2Function.getLoading(loadingObject);
  }
  /**
   * check if the item has been added to the PR previously
   * @param id
   * @returns {boolean}
   */
  function checkItemInCart(id) {
    return prV2Function.getPRItemsIDs().indexOf(id) !== -1;
  }

  /**
   * watcher to apply the selection for the item, and updates in case of a pagination occurred
   */
  $scope.$watch('catalogList', function(){
    refreshAddCartButton();
    setDefaultQuantity();
  });

  /**
   * set default quantity for item
   */
  function setDefaultQuantity() {
    for (var key in $scope.catalogList) {
      $scope.catalogList[key].catalogItem.quantityOrdered = 1;
    }
  }

  function refreshAddCartButton() {
    if (!!$scope.catalogList) {
      _.forEach($scope.catalogList, function (product) {
        if (product.default)
          product.selected = checkItemInCart(product.default.detail_id);
      })
    }
  }

  /**
   * starring items using uuid
   *
   * @param item
   * @param action
   */
  function itemStarring (item, action) {
    starItemUuid.put(
      {},
      {
        item_uuid: item.catalogItem.uuid,
        set: action
      },
      function () {
        item.starred = !item.starred;
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      });
  }

  /**
   * function for clearing the search
   * it clears the text as well as requests for items from all suppliers
   */
  function clearSupplierSearch(){
    $scope.invalidSupplierSelected = false;
    $scope.searchedSupplier = {
      text: ''
    };
    browseCatalog('', '', false, 'supplier');
  }

  /**
   * function for clearing the item category search
   * it clears the text as well as requests for items from all suppliers
   */
  function clearItemCategorySearch(){
    $scope.itemCategory = {
      text: ''
    };
    browseCatalog('', '', true, 'item-categories');
  }

  /**
   * divide the Categories into Segments and Families for display
   */
  function divideCategories() {
    if ($scope.categories) {
      $scope.segments = [];
      _.forEach($scope.categories, function (category) {
        var temp = {
          'code': category.code,
          'title': category.title,
          'families': category.families
        };
        $scope.segments.push(temp);
      });
      setCatalogDisplay('showCategories');
    }
  }

  function checkPaymentTerm(item) {
    var isPaymentTermValid = false;
    if (!!item.payment_term.code) {
      if (globalFunc.findInArray($scope.validPaymentTerm, 'code',
          item.payment_term.code)) {
        isPaymentTermValid = true;
      }
    }
    return isPaymentTermValid;
  }

  function checkItemExpiryDate(expiryDate) {
      if (!(typeof expiryDate === 'number')) {
          expiryDate = Number(expiryDate);
      }

      var itemExpiryDate = moment(expiryDate);
      var currentDate = moment();
      var duration = moment.duration(itemExpiryDate.diff(currentDate));
      if(duration.asDays() <= 14)
          return true;
      else
          return false;
  }

    /**
     * prepare new item for validation
     * @param item
     */
  function prepareAddItem(item) {
    var newItem = {};
    newItem.tax = item.tax? item.tax : null;
    newItem.tax_id = item.tax_id? item.tax_id : null;
    newItem.supplier = {_id: (item.supplier_id? item.supplier_id : null)};
    newItem.payment_term = {
      _id: (item.payment_term_id? item.payment_term_id : null),
      code: (item.payment_term_code? item.payment_term_code: null)
    };
    newItem.detail_id = item.detail_id? item.detail_id : null;
    newItem.currency = {_id:(item.currency_id? item.currency_id: null)};

    return newItem;
  }

  function isItemLimitReached() {
    var pr = prV2Function.getPRData();
    return ($scope.prItemLimit > 0 && !!pr.pr_items && pr.pr_items.length >= $scope.prItemLimit);
  }

  function showPagination(){
    return ($scope.catalogDisplayHandler !== 'showCategories') ? !!$scope.meta : false;//if the page is not Categories, then Check whether $scope.meta is empty
  }

  function catalogTitleTransformer(title) {
    return title.replace(".csv", "");
  }

  function initialize() {
    $scope.validPaymentTerm = paymentTerms;
    // start the catalog browsing with the Starred
    $scope.selectedTab = 'starred';
    browseCatalog('starred', 'Starred Items', false, 'tab', true);

    // set addItem loading status as false
    prV2Function.setLoading('addingItem', false);
  }



  /**
   *  Receive broadcast for updated embedded pagination data to update on controller side
   */
  $scope.$on('updateEmbeddedPagination', function(events, content){
    if(!!content && !!content.meta)
    $scope.meta = content.meta;

    $anchorScroll();
  });

  $scope.selectFilter               = selectFilter;
  $scope.browseCatalog              = browseCatalog;
  $scope.setCategoriesView          = setCategoriesView;
  $scope.supplierListing            = supplierListing;
  $scope.addItemToPR                = addItemToPR;
  $scope.plusItemQty                = plusItemQty;
  $scope.minusItemQty               = minusItemQty;
  $scope.showItemDetails            = showItemDetails;
  $scope.searchCatalog              = searchCatalog;
  $scope.checkLoading               = checkLoading;
  $scope.itemStarring               = itemStarring;
  $scope.clearSupplierSearch        = clearSupplierSearch;
  $scope.clearItemCategorySearch    = clearItemCategorySearch;
  $scope.checkPaymentTerm           = checkPaymentTerm;
  $scope.checkItemExpiryDate        = checkItemExpiryDate;
  $scope.countExactandRelatedItems  = countExactandRelatedItems;
  $scope.formatModel                = formatModel;
  $scope.showPagination             = showPagination;
  $scope.isItemLimitReached         = isItemLimitReached;
  $scope.itemCategorySearch         = itemCategorySearch;
  $scope.catalogTitleTransformer    = catalogTitleTransformer;

  $scope.searchFilter = [];
  $scope.selectedFilter = {};
  $scope.catalogDisplayHandler = '';
  $scope.segments = [];
  $scope.showMessage = false;
  $scope.itemsService = catalogShoppingSearchV2;
  $scope.paramsSource = {
    user_id: $rootScope.currentUser._id,
    type: 'all_categories',
    offset: 20,
    groupIds: [prV2Function.getCatalogGroup()],
    companyCode : prV2Function.getPRData().company.code,
    isPcm: !!prV2Function.getPRData().expense_type.is_pcm ?
      !!prV2Function.getPRData().expense_type.is_pcm : undefined, //force the value to be boolean
    expenseTypeCategory: !!prV2Function.getPRData().expense_type.category ?
      prV2Function.getPRData().expense_type.category : undefined
  };
  $scope.supplierSearchDisplay = false;
  $scope.loading = false;
  $scope.searchActivated = false;
  $scope.searchedSupplier = {
    text: ''
  };
  $scope.itemCategory = {
    text: ''
  };
  $scope.search = {
    title: ''
  };
  $scope.invalidSupplierSelected = false;


  /**
   * bind the PR to the Parent Object (main PR)
   */
  $scope.PR = prV2Function.getPRData();
  $scope.expenseTypeType = !!$scope.PR.expense_type ? $scope.PR.expense_type.category : undefined;
  $scope.prItemLimit = prConfig.pr_item_limit;
  $scope.categories = [];

  initialize();

$(window).scroll(function() {
    var scroll = $(window).scrollTop();
    if (scroll >= 178) {
        $(".catalog-panel-pre-change").addClass("catalog-panel-post-change");
    } else {
        $(".catalog-panel-pre-change").removeClass("catalog-panel-post-change");
    }
});

}

catalogV2HeaderCtrl.$inject = [
  '$scope', '$rootScope', 'catalogShoppingSearchV2', '$http', 'pathConstants', 'globalFunc', '$filter',
  'purchaseRequisitionsV2Services', 'registerViewedItem', '$uibModal', 'prV2Function', 'starItemUuid', 'itemsCategories',
  'metabuyerCache', 'paymentTerms', '$anchorScroll', 'prConfig', 'viewItemUuid'
];

angular
  .module('metabuyer')
  .controller('catalogV2HeaderCtrl', catalogV2HeaderCtrl);
