/**
 * @name CatalogItemDetailCtrl
 *
 * @description
 *
 * Controller for showing an item Details and listing the available variations of the item
 * Also, enables the editing in case of a Non Catalog item
 *
 * @requires $scope
 * @requires $rootScope
 * @requires item (resolved from caller controller, represents the main item)
 * @requires itemDetails (resolved from caller controller, represents the list of details an item has)
 * @requires pr (resolved from caller controller, represents the PR)
 * @requires $uibModalInstance
 * @requires paymentTermsList
 * @require prFunction (Metabuyer service)
 *
 *
 * @function detailAddToPR
 * adds the item to the PR, once successful its updates the PR
 *
 * @function close
 * closes the modal
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

function CatalogItemV2DetailCtrl (
  $scope, item, itemDetails,$uibModalInstance, addItemToPRFunction, prV2Function, globalFunc, $rootScope,
  paymentTerms, toastr, $state, prItemLimit, parentItem, starringFunction, catalogItemQuantityControlInfo
) {

  $scope.mainItem = item;
  $scope.itemDetails = item;
  $scope.itemCommodity = !!itemDetails[0] ? itemDetails[0].commodity : null;
  $scope.parentItem = parentItem;

  $scope.checkPaymentTerm = checkPaymentTerm;
  $scope.itemStarring = itemStarring;
  $scope.close = close;
  $scope.addItemToPR = addItemToPR;
  $scope.checkLoading = checkLoading;
  $scope.isShoppingMode = isShoppingMode;

  function addItemToPR(item) {
    var pr = prV2Function.getPRData();

    // check pr item limit
    if ($scope.prItemLimit > 0 && !!pr.pr_items && pr.pr_items.length >= $scope.prItemLimit) {
      globalFunc.objectErrorMessage("Only " + $scope.prItemLimit + " line items allowed per PR");
      return;
    }

    var validateLumpSum = prV2Function.validateLumpSum(item, -1);

    if (!checkPaymentTerm(item)) {
      swal({
        title: 'Invalid Payment Term',
        text: 'The payment term for this item is inactive. Please check.',
        type: 'info',
        showCancelButton: true,
        showConfirmButton: false
      });
    }
    else {
      if (!!pr.lump_sum_discount && !validateLumpSum.status) {
        swal({
          title: 'Confirm add item?',
          text: validateLumpSum.message,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Confirm',
          closeOnConfirm: true
        }, function (isConfirm) {
          if (isConfirm) {
            // remove lump sum
            prV2Function.removeLumpSum(pr._id, function (resource) {
              if (!!resource)
                sendingAddNewItemRequest(item, 1);
            })
          }
        });
      }
      else
        sendingAddNewItemRequest(item, 1);
    }
  }

  function sendingAddNewItemRequest(item, qty) {
    addItemToPRFunction(item, qty).$promise.then(function(resource){
      if (!!resource && !!resource.content && !!resource.content.data)
          prV2Function.setPRData(resource.content.data);
      prV2Function.setLoading('lineItemTable', false);
      prV2Function.setLoading('addingItem', false);
      toastr.success('Item has been successfully added.');
    },function (e){
      globalFunc.objectErrorMessage(e.data);
      prV2Function.setLoading('lineItemTable', false);
      prV2Function.setLoading('addingItem', false);
    });
  }

  function itemStarring (item, action) {
    starringFunction(item, action)
  }

  /**
   * get loading value from prFunction service
   * @param loadingObject
   * @returns {*|{}}
   */
  function checkLoading(loadingObject) {
    return prV2Function.getLoading(loadingObject);
  }


  function close () {
    $uibModalInstance.close();
  }

  function checkPaymentTerm(item) {
    var isPaymentTermValid = false;
    if (!!item.payment_term.code) {
      if (globalFunc.findInArray($scope.validPaymentTerm, 'code',
        item.payment_term.code)) {
        isPaymentTermValid = true;
      }
    }
    return isPaymentTermValid;
  }

  function isShoppingMode() {
    return $state.includes('main.user.purchaseRequisitionsV2.details.catalog');
  }

  function loadQunatityControllinfo() {
    $scope.quantityControlInfo = {
      status: 'loading'
    };

    catalogItemQuantityControlInfo.get({
      uuid: $scope.mainItem.uuid
    }, function (resource) {
      if (!!resource.content && !!resource.content.data) {
        if (!_.isEmpty(resource.content.data.quantity_limit)) {
          $scope.quantityControlInfo = {
            status: 'available',
            data: resource.content.data
          };
        } else {
          $scope.quantityControlInfo = {
            status: false
          };
        }
      }
      else {
        $scope.quantityControlInfo = {
          status: 'not_found'
        }
      }
    }, function (error) {
      globalFunc.objectErrorMessage(error);
      $scope.quantityControlInfo = {
        status: false
      }
    })
  }


  function initialize() {
    $scope.validPaymentTerm = paymentTerms;
    $scope.lastCommodity = $scope.itemCommodity;
    $scope.prItemLimit = prItemLimit;
    if($rootScope.isV2Enabled)
      loadQunatityControllinfo();
  }

  initialize();
}

CatalogItemV2DetailCtrl.$inject = [
  '$scope', 'item', 'itemDetails','$uibModalInstance', 'addItemToPRFunction', 'prV2Function', 'globalFunc',
  '$rootScope', 'paymentTerms', 'toastr', '$state', 'prItemLimit', 'parentItem', 'starringFunction',
  'catalogItemQuantityControlInfo'
];

angular
  .module('metabuyer')
  .controller('CatalogItemV2DetailCtrl',CatalogItemV2DetailCtrl);
