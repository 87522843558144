'use strict';
angular
  .module('metabuyer')
  .directive('receiveGoods',function($uibModal, globalFunc, $rootScope) {
    return {
      restrict: 'A',
      scope: true,
      link: function(scope) {

        var panel = function () {
          var modalInstance = $uibModal.open({
            templateUrl: 'components/receiveGoods/receiveGoodsTemplate.html',
            backdrop: 'static',
            keyboard: false,
            controller: 'ReceiveGoodsCtrl',
            scope: scope,
            windowClass: 'receive-goods-modal'
          });

        };
        //loading the panel
        scope.showReceiveGoods = function () {
          if (scope.po.integration_solution === 'MB-JDE-JDE') {
            if (globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN')) {
              swal(
                {
                  title: 'Confirm receiving goods',
                  text: 'By executing this, you are aware this feature is to resolve the JDE Integration PO timing ' +
                  'issue. Any processes done are logged in the system. ',
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#1ab394  ',
                  confirmButtonText: 'Confirm',
                  cancelButtonText: 'Cancel',
                  closeOnConfirm: true,
                  closeOnCancel: true
                },
                function (confirmed) {
                  if (!!confirmed) {
                    panel();
                  } else {
                    return false;
                  }
                }
              );
            }
          }
          else
            panel();
        };
      }
    };
  });
