'use strict';

/**
 * @name catalogV2AdvancedSearchCtrl
 *
 * @description Controller for Catalog v2 advanced search
 *
 * @author Dave Woo <wooyj@sunway.com.my>
 * @copyright Sunway Metacloud &copy; 2022
 */
function catalogV2AdvancedSearchCtrl($scope, toastr, $rootScope,advancedSearch, exportSearch ) {

  var toastrErrorMessage = null;
  $scope.errors = {};
  $scope.statuses = [
    { key: 'ALL', value: 'All'},
    { key: 'APPROVED', value: 'Approved'},
    { key: 'COMPOSE', value: 'Compose'},
    { key: 'ERROR', value: 'Error'},
    { key: 'EXPIRED', value: 'Expired'},
    { key: 'DEACTIVATED', value: 'Deactivated'},
    { key: 'PENDING', value: 'Pending Approval'},
    { key: 'REJECTED', value: 'Rejected'},
    { key: 'CANCELLED', value: 'Cancelled'},
  ]

  // to hide Error Status for GenP only
  if (!!$rootScope.isV2Enabled) {
    var index = _.findIndex($scope.statuses, function(status) {
      return status.key === 'ERROR';
    });
    $scope.statuses.splice(index, 1);
  }

  $scope.columns = [
    {id: 'catalog_group|descr', label: 'Catalog Group', selected: true},
    {id: 'catalog_code|code', label: 'Catalog Code', selected: true},
    {id: 'catalog_name', label: 'Catalog Name', selected: true},
    {id: 'catalog_code|descr', label: 'Catalog Description', selected: true},
    {id: 'supplier|descr', label: 'Supplier Name', selected: true},
    {id: 'distributors|descr', label: 'Distributor Name', selected: true},
    {id: 'tenderCode', label: 'Tender Name', selected: true},
    // {id: 'contractCode', label: 'Contract Code'},
    {id: 'file_header|itemcount', label: '# item', type: 'double', selected: true, unsortable: true},
    {id: 'import_effective_date', label: 'Effective At', selected: true},
    {id: 'creator_info|display_name', label: 'Creator', selected: true},
    {id: 'created_at', label: 'Created Date', selected: true},
    {id: 'updater_info|display_name', label: 'Updated By', selected: true},
    {id: 'updated_at', label: 'Updated Date', selected: true},
    {id: 'approver|display_name', label: 'Approved By', selected: true},
    {id: 'approved_at', label: 'Approved Date', selected: true},
    {id: 'status', label: 'Status', selected: true},
  ];

  var initialSearchFields = {
    catalogName: null,
    catalogGroupName: null,
    catalogGroupCode: null,
    status: $scope.statuses[0],
    catalogDescr: null,
    catalogCode: null,
    supplierName: null,
    supplierCode: null,
    distributorCode: null,
    tenderCode: null,
    contractCode: null,
    effectiveDateFrom: null,
    effectiveDateTo: null,
    createdBy: null,
    createdDateFrom: null,
    createdDateTo: null,
    updatedBy: null,
    updatedDateFrom: null,
    updatedDateTo: null,
    approvedBy: null,
    approvedDateFrom: null,
    approvedDateTo: null
  };
  $scope.datePickerTimezone = $rootScope.datePickerTimezone;
  $scope.datepickerOpened = {
    effectiveDateFrom: false,
    effectiveDateTo: false,
    createdDateFrom: false,
    createdDateTo: false,
    updatedDateFrom: false,
    updatedDateTo: false,
    approvedDateFrom: false,
    approvedDateTo: false
  };
  var dateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };
  $scope.datePickerOptions = {
    effectiveDateFrom: _.clone(dateOptions),
    effectiveDateTo: _.clone(dateOptions),
    createdDateFrom: _.clone(dateOptions),
    createdDateTo: _.clone(dateOptions),
    updatedDateFrom: _.clone(dateOptions),
    updatedDateTo: _.clone(dateOptions),
    approvedDateFrom: _.clone(dateOptions),
    approvedDateTo: _.clone(dateOptions)
  };



  $scope.catalogTitleTransformer = catalogTitleTransformer;
  $scope.getImportStatusName = getImportStatusName;
  $scope.showColumn = showColumn;
  $scope.openDatePicker = openDatePicker;
  $scope.submitAdvSearch = submitAdvSearch;
  $scope.resetForm = resetForm;
  $scope.searchModule = advancedSearch;



  /**
   * catalog title transformer
   * @param title
   */
  function catalogTitleTransformer (title) {
    if (!title) {
      return title;
    }
    title = title.replace(".zip", "");
    return title.replace(".csv", "");
  }

  /**
   * function to validate form input values
   * @returns {boolean|Object}
   */
   function validateForm() {

    /**
     * logic for range fields (total price, total cost of pr, and all date fields)
     * if `from` field is not empty, set `to` field to same value as `from` value
     * if `to` field is not empty, `from` field is mandatory.
     * if both are empty, skip validation
     *
     */

    //effective date range
    if (!!$scope.effectiveDateTo) {
      if (!!$scope.effectiveDateFrom) {
        if ($scope.effectiveDateFrom > $scope.effectiveDateTo) {
          $scope.errors.effectiveDate = 'Invalid Range';
        }
      } else {
        $scope.errors.effectiveDate = '\'From\' field is mandatory';
      }
    } else if (!!$scope.effectiveDateFrom) {
      $scope.effectiveDateTo = $scope.effectiveDateFrom;
    }

    //created date range
    if (!!$scope.createdDateTo) {
      if (!!$scope.createdDateFrom) {
        if ($scope.createdDateFrom > $scope.createdDateTo) {
          $scope.errors.createdDate = 'Invalid Range';
        }
      } else {
        $scope.errors.createdDate = '\'From\' field is mandatory';
      }
    } else if (!!$scope.createdDateFrom) {
      $scope.createdDateTo = $scope.createdDateFrom;
    }

    //update date range
    if (!!$scope.updatedDateTo) {
      if (!!$scope.updatedDateFrom) {
        if ($scope.updatedDateFrom > $scope.updatedDateTo) {
          $scope.errors.updatedDate = 'Invalid Range';
        }
      } else {
        $scope.errors.updatedDate = '\'From\' field is mandatory';
      }
    } else if (!!$scope.updatedDateFrom) {
      $scope.updatedDateTo = $scope.updatedDateFrom;
    }

    // pr created date range
    if (!!$scope.approvedDateTo) {
      if (!!$scope.approvedDateFrom) {
        if ($scope.approvedDateFrom > $scope.approvedDateTo) {
          $scope.errors.approvedDate = 'Invalid Range';
        }
      } else {
        $scope.errors.approvedDate = '\'From\' field is mandatory';
      }
    } else if (!!$scope.approvedDateFrom) {
      $scope.approvedDateTo = $scope.approvedDateFrom;
    }

    if (_.isEmpty($scope.errors)) {
      var searchData = _.clone($scope.searchFields);
      return buildParam(searchData);
    } else {
      toastrErrorMessage = 'Invalid value in one or more fields';
      return false;
    }
  }

  /**
   * function to build criteria parameters
   * @param data object of form input values
   * @returns {boolean|Object}
   */
  function buildParam(data) {

    var params = {};

    if (!!data.catalogName) {
      params['criteria[0][catalog_name]'] = data.catalogName;
    }

    if (!!data.catalogGroupName) {
      params['criteria[0][catalog_group|descr]'] = data.catalogGroupName;
    }

    if (!!data.catalogGroupCode) {
      params['criteria[0][catalog_group|code]'] = data.catalogGroupCode;
    }

    if (!!data.status) {
      params['criteria[0][status]'] = data.status.key;
    }

    if (!!data.catalogDescr) {
      params['criteria[0][catalog_code|descr]'] = data.catalogDescr;
    }

    if (!!data.catalogCode) {
      params['criteria[0][catalog_code|code]'] = data.catalogCode;
    }

    if (!!data.supplierName) {
      params['criteria[0][supplier|descr]'] = data.supplierName;
    }

    if (!!data.supplierCode) {
      params['criteria[0][supplier|reg_no]'] = data.supplierCode;
    }

    if (!!data.tenderCode) {
      params['criteria[0][tender|code]'] = data.tenderCode.original_code;
    }
    
    if (!!data.distributorName) {
      params['criteria[0][distributors|descr]'] = data.distributorName;
    }

    if (!!data.createdBy) {
      params['criteria[0][creator_info|display_name]'] = data.createdBy;
    }

    if (!!data.updatedBy) {
      params['criteria[0][updater_info|display_name]'] = data.updatedBy;
    }

    if (!!data.approvedBy) {
      params['criteria[0][approved_by]'] = data.approvedBy;
    }

    if (!!data.effectiveDateFrom) {
      params['criteria[0][import_effective_date][from]'] = moment(data.effectiveDateFrom).valueOf();
      params['criteria[0][import_effective_date][to]'] = moment(data.effectiveDateTo).endOf('day').valueOf();
    }

    if (!!data.createdDateFrom) {
      params['criteria[0][created_at][from]'] = moment(data.createdDateFrom).valueOf();
      params['criteria[0][created_at][to]'] = moment(data.createdDateTo).endOf('day').valueOf();
    }

    if (!!data.updatedDateFrom) {
      params['criteria[0][updated_at][from]'] = moment(data.updatedDateFrom).valueOf();
      params['criteria[0][updated_at][to]'] = moment(data.updatedDateTo).endOf('day').valueOf();
    }

    if (!!data.approvedDateFrom) {
      params['criteria[0][approved_at][from]'] = moment(data.approvedDateFrom).valueOf();
      params['criteria[0][approved_at][to]'] = moment(data.approvedDateTo).endOf('day').valueOf();
    }

    // if status 'all' is selected, the form cannot be empty. so the criteria for 'all' status
    // will only be added if `params` is not empty.
    if (_.isEmpty(params)) {
      toastrErrorMessage = 'Fill in at least one field';
      return false;
    } else {
      params['module'] = 'catalogs_v2';
      params['offset'] = 50;
      params['criteria[0][$operator]'] = 'and';
      return params;
    }
  }

  /**
   *
   * @param $event
   * @param field
   */
   function openDatePicker($event, field) {
    $event.preventDefault();
    $event.stopPropagation();
    if (field === 'effectiveDateFrom') {
      $scope.datePickerOptions.effectiveDateFrom.maxDate = $scope.effectiveDateTo || null;
      $scope.datepickerOpened.effectiveDateFrom = true;
      delete $scope.errors.effectiveDate;
    } else if (field === 'effectiveDateTo') {
      $scope.datePickerOptions.effectiveDateTo.minDate = $scope.effectiveDateFrom || null;
      $scope.datepickerOpened.effectiveDateTo = true;
      delete $scope.errors.effectiveDate;
    } else if (field === 'createdDateFrom') {
      $scope.datePickerOptions.createdDateFrom.maxDate = $scope.createdDateTo || null;
      $scope.datepickerOpened.createdDateFrom = true;
      delete $scope.errors.createdDate;
    } else if (field === 'createdDateTo') {
      $scope.datePickerOptions.createdDateTo.minDate = $scope.createdDateFrom || null;
      $scope.datepickerOpened.createdDateTo = true;
      delete $scope.errors.createdDate;
    } if (field === 'createdDateFrom') {
      $scope.datePickerOptions.createdDateFrom.maxDate = $scope.createdDateTo || null;
      $scope.datepickerOpened.createdDateFrom = true;
      delete $scope.errors.createdDate;
    } else if (field === 'createdDateTo') {
      $scope.datePickerOptions.createdDateTo.minDate = $scope.createdDateFrom || null;
      $scope.datepickerOpened.createdDateTo = true;
      delete $scope.errors.createdDate;
    } else if (field === 'updatedDateFrom') {
      $scope.datePickerOptions.updatedDateFrom.maxDate = $scope.updatedDateTo || null;
      $scope.datepickerOpened.updatedDateFrom = true;
      delete $scope.errors.updatedDate;
    } else if (field === 'updatedDateTo') {
      $scope.datePickerOptions.updatedDateTo.minDate = $scope.updatedDateFrom || null;
      $scope.datepickerOpened.updatedDateTo = true;
      delete $scope.errors.updatedDate;
    } else if (field === 'approvedDateFrom') {
      $scope.datePickerOptions.approvedDateFrom.maxDate = $scope.approvedDateTo || null;
      $scope.datepickerOpened.approvedDateFrom = true;
      delete $scope.errors.approvedDate;
    } else if (field === 'approvedDateTo') {
      $scope.datePickerOptions.approvedDateTo.minDate = $scope.approvedDateFrom || null;
      $scope.datepickerOpened.approvedDateTo = true;
      delete $scope.errors.approvedDate;
    }
  }

  /**
   * returns the status name
   * @param status
   * @returns {*}
   */
  function getImportStatusName(status) {
    /**
     Compose = 0
     Pending = 1
     Approved = 2
     Deleted = 3
     Expired = 4
     Rejected = 5
     Active = 6
     Deactivated = 7
     Error = 8
     */
    if (status === 'composed') {
      return 'Compose'
    }
    if (status === 'pending') {
      return 'Pending'
    }
    if (status === 'approved') {
      return 'Approved'
    }
    if (status === 'deleted') {
      return 'Deleted'
    }
    if (status === 'expired') {
      return 'Expired'
    }
    if (status === 'rejected') {
      return 'Rejected'
    }
    if (status === 'active') {
      return 'Active'
    }
    if (status === 'deactivated') {
      return 'Deactivated'
    }
    if (status === 'error') {
      return 'Error'
    }
    if (status === 'cancelled') {
      return 'Cancelled'
    }
    return 'N/A';
  }

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  /**
   * function to submit search form
   */
  function submitAdvSearch() {
    var searchParam = validateForm();

    if (!!searchParam) {
      $scope.embeddedParams = searchParam;

      /**
       * Setting the params to prepare for exporting
       */
      exportSearch.setParams(searchParam);

      advancedSearch.get(
        searchParam,
        function (resource) {
          $scope.dataList = resource.content.data;
          $scope.meta = resource.content.meta;
          $scope.emptyInput = false;
        },
        function (error) {
          toastr.error('Something went wrong');
          $scope.dataList = [];
        }
      );
    } else {
      toastr.error(toastrErrorMessage);
      toastrErrorMessage = null;
      $scope.emptyInput = true;
    }

    $('#show-search-form').removeClass('hidden');
    $('#hide-search-form').addClass('hidden');
  }

  /**
   * function to reset the search form
   */
  function resetForm() {
    $scope.searchFields = _.cloneDeep(initialSearchFields);
    $scope.dataList = [];
  }

  function initialize() {
    $scope.resetForm();
  }
  initialize();
}

catalogV2AdvancedSearchCtrl.$inject = [
  '$scope', 'toastr', '$rootScope', 'advancedSearch', 'exportSearch'
];

angular.module('metabuyer')
  .controller('catalogV2AdvancedSearchCtrl', catalogV2AdvancedSearchCtrl);
