'use strict';

/**
 *
 * @param $scope
 * @param toastr
 * @param globalFunc
 * @param $uibModalInstance
 * @param companyOpexIoService
 * @param company
 */
function addOpexIOCtrl($scope, toastr, globalFunc, $uibModalInstance, companyOpexIoService, company) {
  $scope.submitted = false;
  $scope.company = company;
  $scope.opexIO = {};
  $scope.opexIO.statusSwitch = true;
  $scope.cancel = cancel;
  $scope.submitOpexIO = submitOpexIO;

  function cancel() {
    $uibModalInstance.close();
  }

  function submitOpexIO() {
    $scope.submitted = true;
    if (!validate($scope.opexIO)) {
      var data = {
        company_code: $scope.company.code,
        code: $scope.opexIO.code,
        name: $scope.opexIO.name,
        is_active: $scope.opexIO.statusSwitch
      };
      companyOpexIoService.post(
        data,
        function (success) {
          toastr.success('Opex IO is successfully added!');
          $uibModalInstance.close(success);
          $scope.submitted = false;
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    }
  }

  function validate(opexIODetails) {
    var anyError = false;
    if (!opexIODetails.code) {
      toastr.error('Opex IO code is required.');
      anyError = true;
    }
    if (!opexIODetails.name) {
      toastr.error('Opex IO name is required.');
      anyError = true;
    }
    return anyError;
  }
}

addOpexIOCtrl.$inject = ['$scope', 'toastr', 'globalFunc', '$uibModalInstance', 'companyOpexIoService', 'company'];

angular
  .module('metabuyer')
  .controller('addOpexIOCtrl', addOpexIOCtrl);
