'use strict';

/**
 * @name manageReactivateExpiredTender
 * @desc Controller to handle reactivate
 */
function manageReactivateExpiredTender($scope, $state, tender, toastr, globalFunc, $uibModalInstance, tenderService) {
  $scope.closeModal = closeModal;
  $scope.submitReactivateExpired = submitReactivateExpired;

  if (!!$scope.validity_start_date) {
    $scope.validity_start_date = new Date($scope.validity_start_date);
  }

  if (!!$scope.validity_end_date) {
    $scope.validity_end_date = new Date($scope.validity_end_date);
  }

  $scope.checkValidityErr = function(startDate, endDate) {
    var curDate = new Date();

    if (startDate === null || startDate === undefined || startDate === "" || startDate.lenght === 0) {
      toastr.error('Please select validity start date');
      $scope.validity_end_date = '';
      return false;
    }

    if (new Date(startDate) > new Date(endDate)) {
      toastr.error('Validity end date should be greater than start date');
      return false;
    }
  };

  function submitReactivateExpired() {

    if ($scope.validity_start_date === undefined || $scope.validity_end_date === undefined) {
      toastr.error('The validity start date field is required');
      toastr.error('The validity end date field is required');

      return false;
    }

    var validity_start_date = moment($scope.validity_start_date).format('DD/MM/YYYY');
    var validity_end_date = moment($scope.validity_end_date).format('DD/MM/YYYY');

    return tenderService.reactivateExpired({
        id: tender._id
      }, {
        validity_start_date: validity_start_date,
        validity_end_date: validity_end_date
      },
      function(res) {
        toastr.success(res.message);
        $state.go('main.user.tender.manage', { status: 'active' });
      },
      function(error) {
        if (!!error.data && !!error.data.content && !!error.data.content.error) {
          if (typeof error.data.content.error === 'string' &&
            !globalFunc.hasJsonStructure(error.data.content.error)) {
            toastr.error(error.data.content.error);
          } else {
            globalFunc.laravelValidatorErrDisplay(JSON.parse(error.data.content.error));
          }
        }
      }
    ).$promise

  }

  function closeModal() {
    $uibModalInstance.close();
  }

  function initialize() {

    $scope.$watch('validity_end_date', function(newVal, oldVal) {
      var startDate = new Date($scope.validity_start_date);

      if (new Date(startDate) > new Date(newVal)) {
        $scope.validity_end_date = oldVal;
      }

    }, true)

  }

  initialize();

}

manageReactivateExpiredTender.$inject = [
  '$scope', '$state', 'tender', 'toastr', 'globalFunc', '$uibModalInstance', 'tenderService'
];

angular
  .module('metabuyer')
  .controller('manageReactivateExpiredTender', manageReactivateExpiredTender);
