'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function($stateProvider, pathConstants) {
    $stateProvider
      .state('main.user.tender', {
        url: '/tender',
        controller: 'tenderCtrl',
        templateUrl: 'app/tender/tender.html',
        resolve: {
          accessRights: function(profile, UserPermissions, $state) {
            var check = true;

            if (!check) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.tender.manage', {
        url: '/manage?{status:[a-zA-Z0-9]+}&{tab:[a-zA-Z0-9]+}&{extraFilterKey:[a-zA-Z0-9]+}&' +
          '{extraFilterValue:[a-zA-Z0-9]+}&{page:[0-9]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}&' +
          '{query:[a-zA-Z0-9]+}&{order:[0-9]+}&{order_by:[a-zA-Z0-9]+}',
        templateUrl: 'app/tender/manage/manage.html',
        controller: 'tenderManageCtrl',
        resolve: {
          dataList: function($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();
            var status = $stateParams.status;

            var params = {
              module: 'tender',
              offset: 50,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            if (!!$stateParams.query) {

              if (!!$stateParams.filter) {

                params['criteria_operator'] = 'and';
                params['criteria[0][' + $stateParams.filter + ']'] = $stateParams.query;

              } else {
                params['criteria[1][code]'] = $stateParams.query;
                params['criteria[1][detail|name]'] = $stateParams.query;
                params['criteria[1][detail|description]'] = $stateParams.query;
                params['criteria[1][detail|type]'] = $stateParams.query;
                params['criteria[1][detail|category]'] = $stateParams.query;
                params['criteria[1][detail|total_amount]'] = $stateParams.query;
                params['criteria[1][groups|code]'] = $stateParams.query;
                params['criteria[1][waiting_on|display_name]'] = $stateParams.query;
                params['criteria[1][creator_info|display_name]'] = $stateParams.query;
                params['criteria[1][updater_info|display_name]'] = $stateParams.query;
                params['criteria[1][suppliers|supplier_id]'] = $stateParams.query;
                params['criteria[1][$operator]'] = 'or';
                params['criteria_operator'] = !!$stateParams.query ? 'and' : null;
              }

              // status criteria
              if (!!status && !!status.length && status !== 'all') {

                if (status.toUpperCase() === 'PENDING') {
                  params['criteria[0][detail|status][0]'] = status.toUpperCase();
                  params['criteria[0][detail|status][1]'] = 'ACTIVE_PENDING';
                } else if (status.toUpperCase() === 'DRAFT') {
                  params['criteria[0][detail|status][0]'] = status.toUpperCase();
                  params['criteria[0][detail|status][1]'] = 'VO_DRAFT';
                } else {
                  params['criteria[0][detail|status]'] = status.toUpperCase();
                }

              } else if ($stateParams.status === 'all') {
                params['criteria[0][detail|status][0]'] = 'draft';
                params['criteria[0][detail|status][1]'] = 'vo_draft';
                params['criteria[0][detail|status][2]'] = 'pending';
                params['criteria[0][detail|status][3]'] = 'approved';
                params['criteria[0][detail|status][4]'] = 'deleted';
                params['criteria[0][detail|status][5]'] = 'withdrawn';
                params['criteria[0][detail|status][6]'] = 'rejected';
                params['criteria[0][detail|status][7]'] = 'expired';
                params['criteria[0][detail|status][8]'] = 'deactivated';
                params['criteria_operator'] = 'and';
              }

            }
            /**
             * For listing
             */
            else {
              if (!!status && !!status.length && status !== 'all') {

                if (status === 'pending') {
                  params['criteria[0][detail|status][0]'] = status.toUpperCase();
                  params['criteria[0][detail|status][1]'] = 'ACTIVE_PENDING';
                } else if (status.toUpperCase() === 'DRAFT') {
                  params['criteria[0][detail|status][0]'] = status.toUpperCase();
                  params['criteria[0][detail|status][1]'] = 'VO_DRAFT';
                } else {
                  params['criteria[0][detail|status]'] = status.toUpperCase();
                }

                params['criteria[0][$strict]'] = true;

              } else if ($stateParams.status === 'all') {
                params['criteria[0][detail|status][0]'] = 'draft';
                params['criteria[0][detail|status][1]'] = 'vo_draft';
                params['criteria[0][detail|status][2]'] = 'pending';
                params['criteria[0][detail|status][3]'] = 'approved';
                params['criteria[0][detail|status][4]'] = 'deleted';
                params['criteria[0][detail|status][5]'] = 'withdrawn';
                params['criteria[0][detail|status][6]'] = 'rejected';
                params['criteria[0][detail|status][7]'] = 'expired';
                params['criteria[0][detail|status][8]'] = 'deactivated';
                params['criteria_operator'] = 'and';
              }
            }

            /**
             * check for 'yours' & 'waiting_on'
             */
            if (!!$stateParams.extraFilterKey && !!$stateParams.extraFilterValue) {
              params['criteria[0]' + $stateParams.extraFilterKey] = $stateParams.extraFilterValue;
            }

            exportSearch.setParams(params);
            searchModule.get(
              params,
              function(resource) {
                deferred.resolve(resource.content);
              },
              function(error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          status: function($stateParams) {
            return !!$stateParams.status ? $stateParams.status.toLowerCase() : '';
          },
          $title: function($stateParams) {
            return "Tender Listing";
          }
        }
      })
      /**
       * @description
       *
       * details state for the purchase requisitions,
       * Resolves the main Tender in which passed to the rest of the components
       *
       */
      .state('main.user.tender.detail', {
        url: '/{id:[a-zA-Z0-9]+}',
        templateUrl: 'app/tender/detail/detail.html',
        controller: 'tenderDetailCtrl',
        params: { tender: null },
        resolve: {
          initialData: function($q, $stateParams, tenderService, tenderFunction) {
            var deferred = $q.defer();
            tenderService.initial(
              function(resource) {
                deferred.resolve(resource.content.data || { data: [] });
                tenderFunction.setInitialData(resource.content.data);
              },
              function(error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          tender: function($q, tenderService, $stateParams, tenderFunction) {
            var deferred = $q.defer();

            tenderService.detail({
                id: $stateParams.id
              },
              function(resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content);
                  tenderFunction.initialize(resource.content.data);
                } else {
                  deferred.resolve([]);
                }
              },
              function(error) {
                if ($auth.isAuthenticated())
                  globalFunc.objectErrorMessage(error);
                else
                  globalFunc.loggingWarning();
              }
            );

            return deferred.promise;
          },
          revisions: function($q, tenderService, $stateParams, tenderFunction) {
            var deferred = $q.defer();

            tenderService.revisions({
                id: $stateParams.id
              },
              function(resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content);
                } else {
                  deferred.resolve([]);
                }
              },
              function(error) {
                if ($auth.isAuthenticated())
                  globalFunc.objectErrorMessage(error);
                else
                  globalFunc.loggingWarning();
              }
            );

            return deferred.promise;
          },
          $title: function(tender) {
            return 'Tender - Details ' + !!tender.data ? tender.data.code : '';
          }
        }
      })
      .state('main.user.tender.voHistory', {
        url: '/voHistory/{id:.+}',
        templateUrl: 'app/tender/voHistory/voHistoryTemplate.html',
        controller: 'voHistoryCtrl',
        params: { tender: null },
        resolve: {
          $title: function() {
            return "View Tender Variation Orders";
          },
          tender: function($q, tenderService, $stateParams, tenderFunction) {
            var deferred = $q.defer();

            tenderService.detail({
                id: $stateParams.id
              },
              function(resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content);
                  tenderFunction.initialize(resource.content.data);
                } else {
                  deferred.resolve([]);
                }
              },
              function(error) {
                if ($auth.isAuthenticated())
                  globalFunc.objectErrorMessage(error);
                else
                  globalFunc.loggingWarning();
              }
            );

            return deferred.promise;
          },
          histories: function($q, tenderService, $stateParams, tenderFunction) {
            var deferred = $q.defer();

            tenderService.voHistories({
                id: $stateParams.id
              },
              function(resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content);
                  tenderFunction.initialize(resource.content.data);
                } else {
                  deferred.resolve([]);
                }
              },
              function(error) {
                if ($auth.isAuthenticated())
                  globalFunc.objectErrorMessage(error);
                else
                  globalFunc.loggingWarning();
              }
            );

            return deferred.promise;
          }
        }
      })
      .state('main.user.tender.group', {
        url: '/group?{query:[a-zA-Z0-9]+}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        templateUrl: 'app/tender/group/group.html',
        controller: 'tenderGroupCtrl',
        resolve: {
          dataList: function($q, searchModule, $stateParams) {
            var deferred = $q.defer();

            var params = {
              module: 'tender-group',
              offset: 50,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            if (!!$stateParams.query) {

              if (!!$stateParams.filter) {
                params['criteria_operator'] = 'and';
                params['criteria[0][' + $stateParams.filter + ']'] = $stateParams.query;
              } else {
                params['criteria_operator'] = !!$stateParams.query ? 'and' : null;
                params['criteria[0][code]'] = $stateParams.query;
                params['criteria[0][description]'] = $stateParams.query;
                params['criteria[0][creator_info|display_name]'] = $stateParams.query;
                params['criteria[0][$operator]'] = !!$stateParams.query ? 'or' : null;
              }

            }

            searchModule.get(
              params,
              function(resource) {
                deferred.resolve(resource.content);
              },
              function(error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function() {
            return "Tender Group";
          }
        }
      })
      .state('main.user.tender.group.manage', {
        url: '/manage',
        templateUrl: 'app/tender/group/manage/manage.html',
        controller: 'tenderGroupManageCtrl',
        resolve: {
          $title: function() {
            return "Tender Group";
          }
        }
      })
      .state('main.user.tender.group.add', {
        url: '/add',
        templateUrl: 'app/tender/group/add/add.html',
        controller: 'tenderAddGroupCtrl',
        resolve: {
          tenderGroup: function() {
            return {};
          },
          companyList: function($q, $stateParams, searchModule) {
            var deferred = $q.defer();
            searchModule.get({
                module: 'companies',
                offset: 0
              },
              function(resource) {
                deferred.resolve(resource.content || { data: [] });
              },
              function() {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function($stateParams) {
            return "Create Tender Group";
          }
        }
      })
      .state('main.user.tender.group.detail', {
        url: '/{id:.+}',
        templateUrl: 'app/tender/group/detail/detail.html',
        controller: 'tenderGroupDetailCtrl',
        resolve: {
          tenderGroup: function() {
            return {};
          },
          tenderGroupDetail: function($q, $stateParams, tenderGroupService) {
            var deferred = $q.defer();
            tenderGroupService.detail({
                id: $stateParams.id
              },
              function(resource) {
                deferred.resolve(resource.content || { data: [] });
              },
              function(error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          companyList: function($q, $stateParams, searchModule) {
            var deferred = $q.defer();
            searchModule.get({
                module: 'companies',
                offset: 0
              },
              function(resource) {
                deferred.resolve(resource.content || { data: [] });
              },
              function() {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function() {
            return "Tender Group - Details";
          }
        }
      })
  }]);
