'use strict';

/**
 * @name Item Tax Controller
 * @desc Controller for items tax list in companies
 */
function companyItemDetailCtrl($scope, managedItem, companyItem, $state, $rootScope) {
    $scope.item = managedItem;
    $scope.updateItem = updateItem;
    $scope.toggleTab = toggleTab;
    $scope.back = back;
    $scope.historyDependencies = {
      embeddedParams: {
        'object[0][object_id]': $scope.item._id,
        'object[0][object_type]': 'App\\Metabuyer\\CompanyItem\\Models\\CompanyItem',
        offset: 5,
        order_by: '-created_at'
      }
    };

    $scope.tab = 1;
    function toggleTab(tab) {
      if(tab === 2)
        $rootScope.$broadcast('reloadAuditData');
      return $scope.tab = tab;
    }

    function back() {
      if ($scope.item && $scope.item.company && $scope.item.company._id)
        $state.go('main.user.companiesMgmt.companyItems', {companyId: $scope.item.company._id});
      else
        window.history.back();
    }

    function prepareItem() {
      return {
        item_master_code: $scope.item.item_master_code,
        descr: $scope.item.descr,
        is_pcm: $scope.is_pcm,
        is_stock: $scope.item.is_stock ? 1 : 0,
        item_category_code: $scope.item.item_category.code,
        company_code: $scope.item.company.code,
        expense_type_category: $scope.item.expense_type_category,
        uom_code: $scope.item.uom_code,
        account_code: $scope.item.account_code,
        is_active: $scope.item.is_active ? 1 : 0
      }
    }

    function updateItem() {
      var data = prepareItem()
      companyItem.update(
        {
          uuid: $scope.item.uuid
        },
          data
        ,
        function (resource) {
        },
        function () {
        }
      );
    }
}

companyItemDetailCtrl.$inject = [
  '$scope', 'managedItem', 'companyItem', '$state', '$rootScope'
];

angular.module('metabuyer')
  .controller('companyItemDetailCtrl', companyItemDetailCtrl);
