'use strict';
/**
 * @name UsersCtrl
 * @desc Controller for users
 */
function UsersCtrl($scope, $state, Users, profile, $rootScope, UserPermissions, globalFunc) {
  $scope.$emit('pageTitleChanged', 'Manage Users');


  var adminDelegationMenuV2 = [
    {
      heading: 'All',
      route: 'main.user.users.delegation',
      key: 'delegation',
      params: {
        status: 'all',
        cursor: null,
        filter: null,
        query: null,
        type: null
      }
    },
    {
      heading: 'Active',
      route: 'main.user.users.delegation',
      key: 'delegation',
      params: {
        status: 'active',
        cursor: null,
        filter: null,
        query: null,
        type: null
      }
    },
    {
      heading: 'Pending Delegation',
      route: 'main.user.users.pendingDelegation',
      key: 'pendingDelegation',
      params: {
        status: 'pending',
        cursor: null,
        filter: null,
        query: null,
        type: null
      }
    },
    {
      heading: 'Planned',
      route: 'main.user.users.delegation',
      key: 'delegation',
      params: {
        status: 'planned',
        cursor: null,
        filter: null,
        query: null,
        type: null
      }
    },
    {
      heading: 'Rejected',
      route: 'main.user.users.delegation',
      key: 'delegation',
      params: {
        status: 'rejected',
        cursor: null,
        filter: null,
        query: null,
        type: null
      }
    },
    {
      heading: 'Finished',
      route: 'main.user.users.delegation',
      key: 'delegation',
      params: {
        status: 'finished',
        cursor: null,
        filter: null,
        query: null,
        type: null
      }
    },
    {
      heading: 'Cancelled',
      route: 'main.user.users.delegation',
      key: 'delegation',
      params: {
        status: 'cancelled',
        cursor: null,
        filter: null,
        query: null,
        type: null
      }
    }
  ];

  // for sunway menu
  var adminDelegationMenu = [
    {
      heading: 'All',
      route: 'main.user.users.delegation',
      key: 'delegation',
      params: {
        status: 'all',
        cursor: null,
        filter: null,
        query: null,
        type: null
      }
    },
    {
      heading: 'Active',
      route: 'main.user.users.delegation',
      key: 'delegation',
      params: {
        status: 'active',
        cursor: null,
        filter: null,
        query: null,
        type: null
      }
    },
    {
      heading: 'Planned',
      route: 'main.user.users.delegation',
      key: 'delegation',
      params: {
        status: 'planned',
        cursor: null,
        filter: null,
        query: null,
        type: null
      }
    },
    {
      heading: 'Finished',
      route: 'main.user.users.delegation',
      key: 'delegation',
      params: {
        status: 'finished',
        cursor: null,
        filter: null,
        query: null,
        type: null
      }
    },
    {
      heading: 'Cancelled',
      route: 'main.user.users.delegation',
      key: 'delegation',
      params: {
        status: 'cancelled',
        cursor: null,
        filter: null,
        query: null,
        type: null
      }
    }
  ];

  $scope.tabData = [
    {
      heading: 'Manage Users',
      route: 'main.user.users.manage',
      key: 'manageUsers',
      menu: [
        {
          heading: 'All Users',
          route: 'main.user.users.manage',
          key: 'manageUsers',
          params: {
            status: 'all',
            cursor: null,
            filter: null,
            query: null,
            type: null
          }
        },
        {
          heading: 'Active Users',
          route: 'main.user.users.manage',
          key: 'manageUsers',
          params: {
            status: 'active',
            cursor: null,
            filter: null,
            query: null,
            type: null
          }
        },
        {
          heading: 'Inactive Users',
          route: 'main.user.users.manage',
          key: 'manageUsers',
          params: {
            status: 'inactive',
            cursor: null,
            filter: null,
            query: null,
            type: null
          }
        }
      ]
    },
    {
      heading: 'Add',
      route: 'main.user.users.add',
      key: 'addUser',
      menu: [
        {
          heading: 'Add a User',
          key: 'addUser',
          route: 'main.user.users.add'
        },
        {
          heading: 'Import Users',
          key: 'addUser',
          route: 'main.user.users.import'
        }
      ]
    },
    {
      heading: 'Escalation',
      route: 'main.user.users.escalation',
      key: 'escalation',
      cursor: null,
      filter: null,
      query: null,
      type: null
    },
    {
      heading: 'Delegation',
      route: 'main.user.users.delegation',
      key: 'delegation',
      menu: !!$rootScope.isV2Enabled ? adminDelegationMenuV2 : adminDelegationMenu
    }
  ];

  if (!!$rootScope.isOffline) {
    _.remove($scope.tabData, function(e) {
      return e.key === 'addUser';
    });
  }

  var detailDelegation = {
    heading: 'Detail Delegation',
    route : 'main.user.detailDelegation',
    key: 'pendingDelegation'
  };

  var onlineUsersTab = {
    heading: 'Online Users',
    route: 'main.user.users.onlineUsers',
    key: 'online'
  };

  if (!UserPermissions.checkPermissionsAccess(profile, 'Users', 'Update')) {
    $scope.tabData = _.filter($scope.tabData, function(e) {
      return e.key === 'manageUsers'
    });
  }

  if (!!UserPermissions.checkPermissionsAccess(profile, 'UserSession', 'Create') || !!UserPermissions.checkPermissionsAccess(profile, 'UserSession', 'Update')
    || !!UserPermissions.checkPermissionsAccess(profile, 'UserSession', 'Delete')) {
    $scope.tabData.push(onlineUsersTab);
  }
  else if (!!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Auditor')) {
    $scope.tabData.push(onlineUsersTab);
  }

  /**
   * making sure the Stats and placeHolder changes if the Child scope changed
   */

  $scope.$on('$stateChangeStart', function( event, data, toParams ) {

    $scope.advancedSearchState = undefined;

    if (!!data.name && data.name.indexOf('escalation')> -1) {
      $scope.searchState = 'main.user.users.escalation';
      $scope.searchPlaceholder = 'Search users in escalation';
      $scope.parentState = 'main.user.users.escalation';
      $scope.advancedSearchState = 'main.user.advancedSearch.escalation';
      $scope.parentStateParams = toParams;
      $scope.columns = escalationColumns;

    }
    else if (!!data.name && data.name.indexOf('delegation')> -1) {
      $scope.searchState = 'main.user.users.delegation';
      $scope.searchPlaceholder = 'Search delegations';
      $scope.parentState = 'main.user.users.delegation';
      $scope.advancedSearchState = 'main.user.advancedSearch.delegation';
      $scope.parentStateParams = toParams;
      $scope.columns = delegationColumns;

    }
    else if (!!data.name && data.name.indexOf('pendingDelegation')> -1) {
      $scope.searchState = 'main.user.users.pendingDelegation';
      $scope.searchPlaceholder = 'Search pending delegation';
      $scope.parentState = 'main.user.users.pendingDelegation';
      $scope.parentStateParams = $state.params;
      $scope.columns = delegationV2Columns;

    }
    else {
      $scope.searchState = 'main.user.users.manage';
      $scope.searchPlaceholder = 'Search users';
      $scope.parentState = 'main.user.users.manage';
      $scope.parentStateParams = toParams;
      $scope.columns = userColumns;
    }

    if(!!toParams.status){
      $scope.status = toParams.status;
      $scope.parentStateParams = toParams;
    }
  });

  var userColumns = [
    {id: 'display_name', label: 'Full Name'},
    {id: 'email', label: 'Email'},
    {id: 'company', label: 'Company(s)', unsortable: true,  unsearchable: true},
    {id: 'status', label: 'Status', conditional: true, condition: 'all'},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {id: 'creator.display_name', label: 'Created By'},
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    },
    {id: 'updater.display_name', label: 'Updated By'},
    {
      id: 'last_login',
      label: 'Last Login At',
      type: 'date',
      unsearchable: true
    },
    {
      id: 'sub_category',
      label: 'Partner'
    },
    {id: 'phone', label:'Phone Number', unsearchable:true},
    {id: 'hand_phone', label: 'Mobile Number', selected: false},
    {id: 'country' ,label: 'Country', unsearchable: true},
    {id: 'currency|descr', label: 'Currency', selected: false},
    {id: 'config|TZ', label: 'Time Zone', selected: false},
    {id: 'config|locale', label: 'Locale', selected: false}
  ];

  var escalationColumns = [
    {id: 'display_name', label: 'Full Name'},
    {id: 'email', label: 'Email'},
    {id: 'company_descr', label: 'Company'},
    {id: 'superior_display_name', label: 'Superior Name'},
    {id: 'superior_email', label: 'Superior Email'}
    ];

  var delegationColumns = [
    {id: 'creator_display_name', label: 'Creator'},
    {id: 'user_display_name', label: 'Delegator'},
    {id: 'delegatee_display_name', label: 'Delegatee'},
    {id: 'company_code', label: 'Company Code'},
    {id: 'company_descr', label: 'Company Name'},
    {
       id: 'created_at',
       label: 'Created At',
       type: 'date',
       unsearchable: true
    },
    {
      id: 'start_date',
      label: 'Start Date',
      type: 'date',
      unsearchable: true
    },
    {
      id: 'end_date',
      label: 'End Date',
      type: 'date',
      unsearchable: true
    },
    {id: 'reason', label: 'Reason', unsortable: true,  unsearchable: true},
    {id: 'status', label: 'Status', unsearchable: true}
  ];

  var delegationV2Columns = [
    {id: 'creator_display_name', label: 'Creator'},
    {id: 'user_display_name', label: 'Delegator'},
    {id: 'delegatee_display_name', label: 'Delegatee'},
    {id: 'waiting_on', label: 'Waiting on'},
    {id: 'company_code', label: 'Company Code'},
    {id: 'company_descr', label: 'Company Name'},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {
      id: 'start_date',
      label: 'Start Date',
      type: 'date',
      unsearchable: true
    },
    {
      id: 'end_date',
      label: 'End Date',
      type: 'date',
      unsearchable: true
    },
    {id: 'reason', label: 'Reason', unsortable: true,  unsearchable: true},
    {id: 'status', label: 'Status', unsearchable: true}
  ];

  function initialize(){

    $scope.advancedSearchState = undefined;

    if (!!$state.current.name && $state.current.name.indexOf('escalation')> -1) {
      $scope.searchState = 'main.user.users.escalation';
      $scope.searchPlaceholder = 'Search users in escalation';
      $scope.advancedSearchState = 'main.user.advancedSearch.escalation';
      $scope.parentState = 'main.user.users.escalation';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[2];
      $scope.columns = escalationColumns;
    }
    else if (!!$state.current.name && $state.current.name.indexOf('add')> -1) {
      $scope.searchState = 'main.user.users.manage';
      $scope.searchPlaceholder = 'Search users';
      $scope.parentState = 'main.user.users.manage';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[1];
      $scope.columns = userColumns;
    }
    else if (!!$state.current.name && $state.current.name.indexOf('delegation')> -1) {
      $scope.searchState = 'main.user.users.delegation';
      $scope.searchPlaceholder = 'Search delegations';
      $scope.advancedSearchState = 'main.user.advancedSearch.delegation';
      $scope.parentState = 'main.user.users.delegation';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[3];
      $scope.columns = delegationColumns;
    }
    else if (!!$state.current.name && $state.current.name.indexOf('pendingDelegation')> -1) {
      $scope.searchState = 'main.user.users.pendingDelegation';
      $scope.searchPlaceholder = 'Search pending delegation';
      $scope.parentState = 'main.user.users.pendingDelegation';
      $scope.parentStateParams = 'Pending';
      $scope.activatedTab = adminDelegationMenuV2;
      $scope.columns = delegationColumns;
    }
    else if (!!$state.current.name && $state.current.name.indexOf('detailDelegation') > -1) {
      $scope.searchState = 'main.user.detailDelegation';
      $scope.searchPlaceholder = 'Search pending delegation';
      $scope.parentState = 'main.user.detailDelegation';
      $scope.activatedTab = detailDelegation;
    }
    else if (!!$state.current.name && $state.current.name.indexOf('onlineUsers')> -1) {
      $scope.searchState = 'main.user.users.manage';
      $scope.searchPlaceholder = 'Search users';
      $scope.parentState = 'main.user.users.manage';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = onlineUsersTab;
      $scope.columns = userColumns;
    }
    else {
      $scope.searchState = 'main.user.users.manage';
      $scope.searchPlaceholder = 'Search users';
      $scope.parentState = 'main.user.users.manage';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[0];
      $scope.columns = userColumns;
    }

    if(!!$state.params && !!$state.params.status){
      $scope.status = $state.params.status;
    }
    if(!!$state.params && !!$state.params.filter){
      $scope.searchFilter = $state.params.filter;
    }

    if(!!$state.params && !!$state.params.query){
      $scope.searchText = $state.params.query;
    }
    else{
      $scope.searchText = '';
    }
  }

  initialize();
}
UsersCtrl.$inject = ['$scope', '$state', 'Users', 'profile', '$rootScope', 'UserPermissions', 'globalFunc'];

angular
  .module('metabuyer')
  .controller('UsersCtrl', UsersCtrl);
