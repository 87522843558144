'use strict';

function suppliersDashboardController($scope, $rootScope, $state, profile, UserPermissions, BouncedEmailLog,
                                      userHistory, statistics) {
  $scope.$emit('pageTitleChanged', 'Suppliers Dashboard');
  $scope.bouncedEmailLog = [];
  $scope.userHistory = userHistory;
  $scope.suppliersCount = statistics.suppliers;

  function initialize() {
    getBouncedEmailLog();
  }

  function getBouncedEmailLog() {
    BouncedEmailLog.get(
      {},
      function (resource) {
        if (!!resource.content) {
          $scope.bouncedEmailLog = resource.content.data;
        }
      }
    );
  }

  initialize();
}

suppliersDashboardController.$inject = ['$scope', '$rootScope', '$state', 'profile', 'UserPermissions',
  'BouncedEmailLog', 'userHistory', 'statistics'];

angular.module('metabuyer')
  .controller('suppliersDashboardController', suppliersDashboardController);
