'use strict';

/**
 *
 * @param $scope
 * @param items
 * @param globalFunc
 * @param prV2Function
 * @param pathConstants
 * @param $http
 */

function priceComparisonItemDetailsCtrl($scope, globalFunc, pathConstants, $http, $filter, loadCacheableData, calculateComparison, $rootScope, priceComparisonFunction, purchaseRequisitionsV2Services, priceComparisonResource) {
  $scope.isTenantSuperAdminOrAdmin = $rootScope.isTenantSuperAdminOrAdmin;
  $scope.checkIfUserCanCreatePC = $rootScope.checkIfUserCanCreatePC;
  $scope.isCreatedPC = $rootScope.isCreatedPC;
  $scope.isPcCreatorRole = $rootScope.isPcCreatorRole;
  $scope.searchSuppliers = searchSuppliers;
  $scope.deleteSelectedSupplier = deleteSelectedSupplier;
  $scope.onSelectSupplier = onSelectSupplier;
  $scope.recalculateComparison = recalculateComparison;
  $scope.selectOtherCharge = selectOtherCharge;
  $scope.displayOtherCharges = displayOtherCharges;
  $scope.deleteOtherCharge = deleteOtherCharge;
  $scope.checkForeignCurrency = checkForeignCurrency;
  $scope.isPercentageUntick = isPercentageUntick;
  $scope.isNoQuotationUntick = isNoQuotationUntick;
  $scope.selectComparison = selectComparison;
  $scope.dynamicHeight = dynamicHeight;
  $scope.getDeliveryAddress = getDeliveryAddress;
  $scope.taxData = [];
  $scope.currencyData = [];
  $scope.listDeliveryAddresses = [];
  $scope.companyContract = [];
  $scope.loadAvailableAddresses = loadAvailableAddresses;
  $scope.onSelectDeliveryAddress = onSelectDeliveryAddress;
  $scope.itemSelectReadonlyCheck = itemSelectReadonlyCheck;
  $scope.populateItemTax = populateItemTax;
  $scope.checkItemComparisonNumber = checkItemComparisonNumber;
  $scope.editSelectedComparison = editSelectedComparison;
  $scope.saveSelectedComparison = saveSelectedComparison;
  $scope.checkQuotationNumber = checkQuotationNumber;
  $scope.cancelEditQuotation = cancelEditQuotation;
  $scope.checkLowestPrice = checkLowestPrice;
  $scope.getOtherChargesGroupSearch = getOtherChargesGroupSearch;
  $scope.onSelectOtherChargesGroup = onSelectOtherChargesGroup;
  $scope.prepareOtherChargesGroup = prepareOtherChargesGroup;
  $scope.assignOtherChargesGroup = assignOtherChargesGroup;
  $scope.calculateOtherChargesGroupAmount = calculateOtherChargesGroupAmount;
  $scope.getTax = getTax;
  $scope.resetOtherCharges = resetOtherCharges;
  $scope.removeOtherChargesGroup = removeOtherChargesGroup;
  $scope.checkCurrencyDifference = checkCurrencyDifference;

  $scope.otherChargesFields = [
    'insurance',
    'transportation',
    'freight',
    'withholding_tax',
    'bahan_bakar_tax',
    'miscellaneous'
  ];
  $scope.selectedOtherCharge = '';
  $scope.availabilities = [
    'Yes',
    'No'
  ];
  // set toggle to false (hide all other charges by default)
  $scope.otherCharges = {
    toggleShowPosition: false
  };

  // load taxes
  loadCacheableData.loadData({
    module: 'tax',
    'criteria[is_active]': 1,
    offset: 0
  }, 'taxes', 'localStorage').then(function(data) {
    var taxes = data;
    $scope.taxData = [];
    _.forEach(taxes, function(tax) {
      if (tax.country_code === $scope.pr.company.company_country) {
        tax.value = parseFloat(tax.rate);
        $scope.taxData.push(tax);
      }
    });
  });

  // load currencies
  loadCacheableData.loadData({
    module: 'currencies',
    'criteria[is_active]': 1,
    offset: 0
  }, 'currencies', 'localStorage').then(function(data) {
    $scope.currencyData = data;
  });

  loadAvailableAddresses();

  function loadAvailableAddresses() {

    purchaseRequisitionsV2Services.getAvailableDeliveryAddresses({
        id: $scope.priceComparison.pr_info.company._id
      },
      function(resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.listDeliveryAddresses = resource.content.data;
        } else {
          globalFunc.objectErrorMessage('Loading addresses failed.');
        }

      },
      function(error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function onSelectDeliveryAddress(address) {
    $scope.priceComparison.pr_info.delivery_to_address = address;
  }

  function getDeliveryAddress() {
    return ($scope.priceComparison.pr_info.delivery_to_address.line1.length ?
        $scope.priceComparison.pr_info.delivery_to_address.line1 + "\n" : "") +
      ($scope.priceComparison.pr_info.delivery_to_address.line2.length ?
        $scope.priceComparison.pr_info.delivery_to_address.line2 + "\n" : "") +
      ($scope.priceComparison.pr_info.delivery_to_address.line3.length ?
        $scope.priceComparison.pr_info.delivery_to_address.line3 + "\n" : "") +
      ($scope.priceComparison.pr_info.delivery_to_address.postal_code.length ?
        $scope.priceComparison.pr_info.delivery_to_address.postal_code : "") +
      ($scope.priceComparison.pr_info.delivery_to_address.city.length ?
        ($scope.priceComparison.pr_info.delivery_to_address.postal_code.length ? " " : "") +
        $scope.priceComparison.pr_info.delivery_to_address.city + "\n" :
        ($scope.priceComparison.pr_info.delivery_to_address.postal_code.length ? "\n" : "")) +
      ($scope.priceComparison.pr_info.delivery_to_address.state.length ?
        $scope.priceComparison.pr_info.delivery_to_address.state : "") +
      ($scope.priceComparison.pr_info.delivery_to_address.country.length ?
        ($scope.priceComparison.pr_info.delivery_to_address.state.length ? ", " : "") +
        $scope.priceComparison.pr_info.delivery_to_address.country : "");
  }

  function itemSelectReadonlyCheck(supplier) {
    // here is to display supplier status for both billing company contract and company contract for that supplier
    if (supplier.billing_company_contract !== undefined && supplier.company_contract !== undefined) {
      if (supplier.billing_company_contract.status === 'ACTIVE' && supplier.company_contract.status === 'ACTIVE') {
        supplier.supplier_status = 'ACTIVE';
      } else {
        supplier.supplier_status = 'INACTIVE';
      }
    }

    // cannot select supplier if supplier does not exist in database
    if (supplier._id === undefined || supplier._id === null) {
      supplier.supplier_status = undefined;
      return true;
    }

    // cannot select supplier if company contract for both company and billing company is not ACTIVE
    if (supplier.billing_company_contract !== undefined && supplier.company_contract !== undefined) {
      if (!(supplier.billing_company_contract.status === 'ACTIVE' && supplier.company_contract.status === 'ACTIVE')) {
        return true;
      }
    }

    // cannot select if current supplier is DEACTIVATED
    if (supplier.basic_info.status === 'DEACTIVATED') {
      return true;
    }

    // cannot select if current supplier's supplier_status is undefined
    if (supplier.supplier_status === undefined) {
      return true;
    }

    return false;
  }

  function selectComparison(item, itemIndex, comparisonIndex) {
    item.is_selected = !item.is_selected;

    _.forEach($scope.priceComparison.item_comparisons, function(comparison, index) {
      if (comparisonIndex !== index) {
        comparison.items[itemIndex].is_selected = false;
      }
    });

    recalculateComparison();
  }

  function dynamicHeight(itemIndex) {
    var elem = document.getElementById(('item-detail-label-' + itemIndex));
    return elem.offsetHeight + 2 + 57;
  }

  function checkForeignCurrency(comparison) {
    return comparison.price_comparison_currency.code !== comparison.requisition_currency.code;
  }

  function isPercentageUntick(item) {
    if (item.is_percentage === false) {
      item.discount = 0;
      item.discount_amount = 0;
    }
  }

  function isNoQuotationUntick(item, comparisonIndex) {
    if (item.no_quotation_available === true) {
      item.unit_price = 0;
      item.discount = 0;
      item.discount_amount = 0;
      item.tax = [];
      item.tax_amount = 0;
      item.tax_amount_pr_currency = 0;
      item.remarks = '';

      item.is_selected = false;
      item.is_percentage = false;

      delete item.other_charges_group;
      resetOtherCharges(item, comparisonIndex);
    }
  }

  function getItemComparison(selectedItem)
  {
    var itemComparison = null;
    var itemExists = false;

    if ($scope.priceComparison && $scope.priceComparison.item_comparisons) {
      _.forEach($scope.priceComparison.item_comparisons, function(comparison) {
        _.forEach(comparison.items, function(item) {
          if (item.item_id === selectedItem.item_id) {
            itemComparison = comparison;
            itemExists = true;
            return false;
          }
        });
        if (itemExists) {
          return false;
        }
      });
    }

    return itemComparison;
  }

  /**
   * function to reset other charges
   * @param  {array} item [Current Item in item comparison]
   * @param  {array} comparisonIndex [Current Item comparison]
   */
  function resetOtherCharges(item, comparisonIndex) {
      var itemComparison = getItemComparison(item);
      var price_comparison_currency = itemComparison.price_comparison_currency;
      
      item.insurance.tax = [];
      item.insurance.tax_amount = 0;
      item.insurance.tax_amount_pr_currency = 0;
      item.insurance.total_amount = 0;
      item.insurance.total_amount_pr_currency = 0;
      delete item.insurance.name;
      delete item.insurance.original_unit_price;
      delete item.insurance.amount;
      delete item.insurance.percentage;
      delete item.insurance.calculation_method;
      delete item.insurance.isSelected;
      delete item.insurance.other_charges_group;
      delete item.insurance.is_other_charges_group_tax;
      delete item.insurance.taxEdit;
      item.insurance.currency = price_comparison_currency;

      item.transportation.tax = [];
      item.transportation.tax_amount = 0;
      item.transportation.tax_amount_pr_currency = 0;
      item.transportation.total_amount = 0;
      item.transportation.total_amount_pr_currency = 0;
      delete item.transportation.name;
      delete item.transportation.original_unit_price;
      delete item.transportation.amount;
      delete item.transportation.percentage;
      delete item.transportation.calculation_method;
      delete item.transportation.isSelected;
      delete item.transportation.other_charges_group;
      delete item.transportation.is_other_charges_group_tax;
      delete item.transportation.taxEdit;
      item.transportation.currency = price_comparison_currency;

      item.freight.tax = [];
      item.freight.tax_amount = 0;
      item.freight.tax_amount_pr_currency = 0;
      item.freight.total_amount = 0;
      item.freight.total_amount_pr_currency = 0;
      delete item.freight.name;
      delete item.freight.original_unit_price;
      delete item.freight.amount;
      delete item.freight.percentage;
      delete item.freight.calculation_method;
      delete item.freight.isSelected;
      delete item.freight.other_charges_group;
      delete item.freight.is_other_charges_group_tax;
      delete item.freight.taxEdit;
      item.freight.currency = price_comparison_currency;

      item.withholding_tax.tax = [];
      item.withholding_tax.tax_amount = 0;
      item.withholding_tax.tax_amount_pr_currency = 0;
      item.withholding_tax.total_amount = 0;
      item.withholding_tax.total_amount_pr_currency = 0;
      delete item.withholding_tax.name;
      delete item.withholding_tax.original_unit_price;
      delete item.withholding_tax.amount;
      delete item.withholding_tax.percentage;
      delete item.withholding_tax.calculation_method;
      delete item.withholding_tax.isSelected;
      delete item.withholding_tax.other_charges_group;
      delete item.withholding_tax.is_other_charges_group_tax;
      delete item.withholding_tax.taxEdit;
      item.withholding_tax.currency = price_comparison_currency;

      item.bahan_bakar_tax.tax = [];
      item.bahan_bakar_tax.tax_amount = 0;
      item.bahan_bakar_tax.tax_amount_pr_currency = 0;
      item.bahan_bakar_tax.total_amount = 0;
      item.bahan_bakar_tax.total_amount_pr_currency = 0;
      delete item.bahan_bakar_tax.name;
      delete item.bahan_bakar_tax.original_unit_price;
      delete item.bahan_bakar_tax.amount;
      delete item.bahan_bakar_tax.percentage;
      delete item.bahan_bakar_tax.calculation_method;
      delete item.bahan_bakar_tax.isSelected;
      delete item.bahan_bakar_tax.other_charges_group;
      delete item.bahan_bakar_tax.is_other_charges_group_tax;
      delete item.bahan_bakar_tax.taxEdit;
      item.bahan_bakar_tax.currency = price_comparison_currency;

      item.miscellaneous.tax = [];
      item.miscellaneous.tax_amount = 0;
      item.miscellaneous.tax_amount_pr_currency = 0;
      item.miscellaneous.total_amount = 0;
      item.miscellaneous.total_amount_pr_currency = 0;
      delete item.miscellaneous.name;
      delete item.miscellaneous.original_unit_price;
      delete item.miscellaneous.amount;
      delete item.miscellaneous.percentage;
      delete item.miscellaneous.calculation_method;
      delete item.miscellaneous.isSelected;
      delete item.miscellaneous.other_charges_group;
      delete item.miscellaneous.is_other_charges_group_tax;
      delete item.miscellaneous.taxEdit;
      item.miscellaneous.currency = price_comparison_currency;
  }

  function checkItemComparisonNumber(item) {
    if ($scope.priceComparison.item_comparisons.length > 1) {
      return false;
    } else {
      item.no_quotation_available = false;
      return true;
    }
  }

  function deleteOtherCharge(charge, itemIndex) {
    _.forEach($scope.priceComparison.item_comparisons, function(comparison) {
      delete comparison.items[itemIndex][charge];
    });

    recalculateComparison();
    delete $scope.pr.pr_items[itemIndex][charge];
  }

  function displayOtherCharges(item) {
    return _.filter($scope.otherChargesFields, function(charge) {
      if (_.isEmpty(item[charge])) {
        return true;
      }
    });
  }

  function selectOtherCharge(charge, itemIndex) {
    _.forEach($scope.priceComparison.item_comparisons, function(comparison) {
      comparison.items[itemIndex][charge] = {
        total_amount: 0,
        total_amount_pr_currency: 0,
        tax_amount: 0,
        tax_amount_pr_currency: 0
      };
    });

    $scope.pr.pr_items[itemIndex][charge] = {};
  }

  /**
   * function to check price comparison currency and requisition currency and change currency if they dont match
   * @param  {array} comparison [Current Item comparison]
   * @param  {array} item [Current Item in item comparison]
   */
  function checkCurrencyDifference(comparison, items) {
    if (comparison.price_comparison_currency.code != items.insurance.currency.code) {
      items.insurance.currency = comparison.price_comparison_currency;
    }
    if (comparison.price_comparison_currency.code != items.transportation.currency.code) {
      items.transportation.currency = comparison.price_comparison_currency;
    }
    if (comparison.price_comparison_currency.code != items.freight.currency.code) {
      items.freight.currency = comparison.price_comparison_currency;
    }
    if (comparison.price_comparison_currency.code != items.withholding_tax.currency.code) {
      items.withholding_tax.currency = comparison.price_comparison_currency;
    }
    if (comparison.price_comparison_currency.code != items.bahan_bakar_tax.currency.code) {
      items.bahan_bakar_tax.currency = comparison.price_comparison_currency;
    }
    if (comparison.price_comparison_currency.code != items.miscellaneous.currency.code) {
      items.miscellaneous.currency = comparison.price_comparison_currency;
    }
  }

  function recalculateComparison() {
    var data = {
      'item_comparisons': $scope.priceComparison.item_comparisons,
      'pr_info': $scope.priceComparison.pr_info
    };

    // before post data to backend, have to recalculate other charges group again if unit price was edited
    _.forEach($scope.priceComparison.item_comparisons, function(comparison) {
      _.forEach(comparison.items, function(items) {
        // check if current items has other charges group
        if (!_.isEmpty(items.other_charges_group)) {
          onSelectOtherChargesGroup(items.other_charges_group, items, comparison, false);
        } else {
          // if it is not other charges group, check if price comparison currency is same with requisition currency
          checkCurrencyDifference(comparison, items);
        }
      });
    });

    calculateComparison.post(
      data,
      function(resource) {
        if (!!resource.data) {
          var data = resource.data;
          $scope.priceComparison.item_comparisons = data.item_comparisons;
          $scope.priceComparison.total_amount_before_tax = data.total_amount_before_tax;
          $scope.priceComparison.total_tax_amount = data.total_tax_amount;
          $scope.priceComparison.grand_total = data.grand_total;
        }
      },
      function(error) {
        // handle this
        globalFunc.objectErrorMessage(error)
      }
    )
  }

  function onSelectSupplier(index, supplier) {
    var contract_param = {
      id: supplier._id,
      code: $scope.priceComparison.pr_info.company.code
    };

    var billing_company_contract_param = {
      id: supplier._id,
      code: $scope.priceComparison.pr_info.shadow_company.code
    };

    // company contract
    $http.get($filter('format')(pathConstants.apiUrls.supplier.singleCompanyContract, contract_param)).then(function(response) {

      if (response.data.content.data.status) {
        supplier.company_contract = response.data.content.data;
        $scope.priceComparison.item_comparisons[index].supplier = supplier;
      }
    });

    // company contract
    $http.get($filter('format')(pathConstants.apiUrls.supplier.singleCompanyContract, billing_company_contract_param)).then(function(response) {

      if (response.data.content.data.status) {
        supplier.billing_company_contract = response.data.content.data;
      }
    });
  }

  function deleteSelectedSupplier(index) {
    $scope.priceComparison.item_comparisons.splice(index, 1);
    if ($scope.priceComparison.item_comparisons.length === 0) {
      $scope.priceComparison.remarks = "";
    }
  }

  function editSelectedComparison(index) {
    var item = $scope.priceComparison.item_comparisons[index];
    var backupAttachments = _.cloneDeep(item.attachments);

    $scope.priceComparison.item_comparisons[index].editable = true;

    $scope.priceComparison.item_comparisons[index].backup_attachments = backupAttachments;

    _.forEach($scope.priceComparison.item_comparisons[index].items, function(item) {
      item.editable = true;
    });

    $scope.priceComparison.item_comparisons[index].itemIndex = index;
    $rootScope.$broadcast('checkEdit', $scope.priceComparison.item_comparisons[index]);
  }

  function saveSelectedComparison(index) {
    $scope.priceComparison.item_comparisons[index].editable = false;

    _.forEach($scope.priceComparison.item_comparisons[index].items, function(item) {
      item.editable = false;
    });

    $scope.priceComparison.item_comparisons[index].quotation.quotation_number = "";
    $scope.priceComparison.item_comparisons[index].itemIndex = index;
    $rootScope.$broadcast('checkEdit', $scope.priceComparison.item_comparisons[index]);
  }

  function cancelEditQuotation(index) {
    swal({
        title: 'Confirm cancel edit',
        text: 'All changes made in this quotation will be reverted',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function(isConfirm) {
        // get the original quotation items from PC database thru PC ID
        if (isConfirm) {
          priceComparisonResource.get({
              id: $scope.priceComparison._id
            },
            function(resource) {
              if (!!resource.content) {
                $scope.priceComparison.item_comparisons[index] = resource.content.data.item_comparisons[index];
              }
            },
            function(error) {
              globalFunc.objectErrorMessage(error);
            }
          );
        }
        $scope.priceComparison.item_comparisons[index].editable = false;
        _.forEach($scope.priceComparison.item_comparisons[index].items, function(item) {
          item.editable = false;
        });
        $scope.priceComparison.item_comparisons[index].itemIndex = index;
        $rootScope.$broadcast('checkEdit', $scope.priceComparison.item_comparisons[index]);
      }
    );
  }

  function checkQuotationNumber(index) {
    if ($scope.priceComparison.item_comparisons[index].quotation === undefined) {
      return false;
    } else {
      return true;
    }
  }

  function checkLowestPrice() {
    // only draft status will skip this function
    if ($scope.priceComparison.status !== 'DRAFT') {
      var items = 0;
      // get total items present in this PC
      items = $scope.priceComparison.pr_info.items.length;

      // loop items present in the PC
      for (var i = 0; i < items; i++) {
        var lowestPrice = [];
        var lowestIndex = [];
        // this includes total of amount b4 tax and other charges b4 tax
        var total = 0;

        // loop each item comparison to compare the items
        _.forEach($scope.priceComparison.item_comparisons, function(item, index) {
          // amount before tax + other charges amount before tax
          total = item.items[i].amount_before_tax_pr_currency +
            item.items[i].insurance.total_amount_pr_currency +
            item.items[i].transportation.total_amount_pr_currency +
            item.items[i].freight.total_amount_pr_currency +
            item.items[i].withholding_tax.total_amount_pr_currency +
            item.items[i].bahan_bakar_tax.total_amount_pr_currency +
            item.items[i].miscellaneous.total_amount_pr_currency;

          // assign the first price as lowest price
          if (_.isEmpty(lowestPrice)) {
            // ignore items that has zero amount due to no quotation available
            if (item.items[i].total_amount_with_other_charges_pr_currency !== 0) {
              lowestPrice.push(total);
              lowestIndex.push(index);
            }
          } else if (item.items[i].total_amount_with_other_charges_pr_currency !== 0 && (total < lowestPrice[0])) {
            // if there is price lower than current lowestPrice, overide the array
            lowestPrice = [];
            lowestIndex = [];
            lowestPrice.push(total);
            lowestIndex.push(index);
          } else if (total === lowestPrice[0]) {
            // if price matches lowestPrice, push it into array
            lowestPrice.push(total);
            lowestIndex.push(index);
          }
        });

        // assign flag to true for the lowest price item among the quotations
        _.forEach(lowestIndex, function(index) {
          $scope.priceComparison.item_comparisons[index].items[i].lowestItemPrice = true;
        });
      }
    }
  }

  function searchSuppliers(val, comparisonIndex) {
    if (!!val && val.length > 2) {
      $scope.supplierSearching = true;

      var moduleParam = {
        module: 'suppliers'
      };

      var additionalParams = {
        'criteria[0][basic_info|descr]': val,
        'criteria[0][basic_info|reg_no]': val,
        'criteria[0][$operator]': 'or',
        'criteria[1][basic_info|status][0]': 'active',
        'criteria[1][basic_info|status][1]': 'deactivated',
        'criteria[1][basic_info|status][2]': 'inactive',
        'criteria[1][basic_info|status][3]': 'active_pending',
        criteria_operator: 'and',
        company_contract: $scope.pr.company.code,
        offset: 10
      };

      return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: additionalParams,
        ignoreLoadingBar: true
      }).then(function(response) {
        if (response.data.content.data.length === 0) {
          $scope.priceComparison.item_comparisons[comparisonIndex].supplier = {};
          $scope.priceComparison.item_comparisons[comparisonIndex].supplier._id = null;
          $scope.priceComparison.item_comparisons[comparisonIndex].supplier.basic_info = {
            descr: val,
          };

          var items = $scope.priceComparison.item_comparisons[comparisonIndex].items;
          angular.forEach(items, function(value, key) {
            value.is_selected = false;
          });

          $scope.priceComparison.item_comparisons[comparisonIndex].items = items;

          $scope.supplierSearching = false;
          item = [];
        } else {
          var modifiedSupplier = _.filter(response.data.content.data, function(item) {
            return item;
          });
        }

        return modifiedSupplier;
      });
    }
  }

  function getTemplateUrl(state) {

    if ($scope.priceComparison.status === 'DRAFT') {
      $scope.priceComparisonTemplateUrl = 'components/priceComparisonComponents/itemDetails/priceComparisonItemDetails.html';
    } else {
      switch (state) {
        case 'editable':
          $scope.priceComparisonTemplateUrl = 'components/priceComparisonComponents/itemDetails/priceComparisonItemDetails.html';
          break;
        case 'notEditable':
          $scope.priceComparisonTemplateUrl = 'components/priceComparisonComponents/itemDetails/priceComparisonItemDetails.nonEditable.html';
          break;
        default:
          $scope.priceComparisonTemplateUrl = 'components/priceComparisonComponents/itemDetails/priceComparisonItemDetails.nonEditable.html';
          break;
      }
    }
  }

  function populateItemTax(comparisonIndex) {
    var tempTax = '';
    // get the current comparison tax
    tempTax = $scope.priceComparison.item_comparisons[comparisonIndex].tax;

    // loop the items to update the tax from comparison tax
    _.forEach($scope.priceComparison.item_comparisons[comparisonIndex].items, function(item) {
      if (!!item.tax) {
        item.tax = tempTax;
      }
    });

    recalculateComparison();
  }

  /**
   * function to list down other charges group based on what user typed
   * @param  {field} query [Text entered by user]
   * @param  {number} index [Index of Current Item Comparison]
   * @return {array}
   */
  function getOtherChargesGroupSearch(query, index) {
    var moduleParam = {
      module: 'other-charges-groups'
    };

    var additionalParams = {
      'criteria[0][code]': query,
      'criteria[0][name]': query,
      'criteria[1][company_code]': $scope.priceComparison.pr_info.company.code,
      'criteria[2][is_active]': true,
      'criteria[3][supplier|code]': $scope.priceComparison.item_comparisons[index].supplier.basic_info.code,
    };
    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams,
      ignoreLoadingBar: true
    }).then(function(response) {
      if (response.data.content.data.length === 0) {
        return [];
      } else {
        return response.data.content.data.map(function(item) {
          return item;
        });
      }
    });
  }

  /**
   * function to prepare other charges group
   * @param  {array} otherChargesGroup [Other Charges Group Selected]
   * @param  {array} item [Current Item in item comparison]
   * @param  {array} comparisonIndex [Current Item comparison]
   */
  function onSelectOtherChargesGroup(otherChargesGroup, item, comparisonIndex, reset) {
    if (reset) {
      resetOtherCharges(item, comparisonIndex);
    }
    item.other_charges_group = otherChargesGroup;
    prepareOtherChargesGroup(otherChargesGroup.other_charges, item);
  }

  /**
   * function to cross check other charges with other charges group
   * @param  {array} otherChargesGroup [Other Charges Group Selected]
   * @param  {array} item [Current Item in item comparison]
   */
  function prepareOtherChargesGroup(otherChargesGroup, item) {
    _.forEach(otherChargesGroup, function(charge) {
      if (charge.code.toLowerCase() === 'bahan_bakar_tax') {
        var currentCharge = item.bahan_bakar_tax;
        assignOtherChargesGroup(charge, currentCharge, item);
      } else if (charge.code.toLowerCase() === 'freight') {
        var currentCharge = item.freight;
        assignOtherChargesGroup(charge, currentCharge, item);
      } else if (charge.code.toLowerCase() === 'insurance') {
        var currentCharge = item.insurance;
        assignOtherChargesGroup(charge, currentCharge, item);
      } else if (charge.code.toLowerCase() === 'miscellaneous') {
        var currentCharge = item.miscellaneous;
        assignOtherChargesGroup(charge, currentCharge, item);
      } else if (charge.code.toLowerCase() === 'transportation') {
        var currentCharge = item.transportation;
        assignOtherChargesGroup(charge, currentCharge, item);
      } else if (charge.code.toLowerCase() === 'withholding_tax') {
        var currentCharge = item.withholding_tax;
        assignOtherChargesGroup(charge, currentCharge, item);
      }
    });
  }

  /**
   * function to assign other charges group new fields into current other charges
   * @param  {array} otherChargesGroup [Other Charges Group Selected]
   * @param  {array} currentCharge [Current 'other charge' in other charges group]
   * @param  {array} item [Current Item in item comparison]
   */
  function assignOtherChargesGroup(otherChargesGroup, currentCharge, item) {
    if (!_.isEmpty(otherChargesGroup)) {
      if (otherChargesGroup.calculation_method.code === 'FLAT_AMOUNT_PER_UNIT') {
        currentCharge['original_unit_price'] = otherChargesGroup['unit_price'];
      }

      currentCharge['name'] = otherChargesGroup['name'];
      currentCharge['amount'] = calculateOtherChargesGroupAmount(otherChargesGroup, currentCharge, item);
      currentCharge['total_amount'] = currentCharge['amount'];
      currentCharge['tax'] = getTax(otherChargesGroup, currentCharge);
      if (currentCharge['tax'] === null) {
        // this flag allows user to constantly edit tax selection
        currentCharge['taxEdit'] = true;
      }
      currentCharge['percentage'] = otherChargesGroup['percentage'];
      currentCharge['calculation_method'] = otherChargesGroup['calculation_method'];
      currentCharge['isSelected'] = true;
      currentCharge['other_charges_group'] = true;
      currentCharge['currency'] = otherChargesGroup['currency'];
      if (!_.isEmpty(otherChargesGroup['tax'])) {
        currentCharge['is_other_charges_group_tax'] = true;
      }
    }
  }

  /**
   * function to recalculate other charges group when selected
   * @param  {array} otherChargesGroup [Other Charges Group Selected]
   * @param  {array} charge [Current 'other charge' in other charges group]
   * @param  {array} item [Current Item in item comparison]
   * @return {int} amount [Unit Price]
   */
  function calculateOtherChargesGroupAmount(otherChargesGroup, charge, item) {
    var amount = 0;
    var precision = 2;
    if (!!$scope.priceComparison.currency && !$scope.priceComparison.currency.allow_decimal) {
      precision = 0;
    }
    if (otherChargesGroup.calculation_method.code === 'FLAT_AMOUNT_PER_UNIT') {
      if (!!item.qty) {
        amount = math.round((!!charge.original_unit_price ? charge.original_unit_price : charge['unit_price']) * item.qty, precision);
      }
    } else if (otherChargesGroup.calculation_method.code === 'PERCENT_OF_EXTENDED_COST') {
      if (!!item.unit_price && item.qty) {
        var itemAmount = item.unit_price * item.qty;
        var percentage = otherChargesGroup.percentage / 100;
        amount = math.round(itemAmount * percentage, precision);
      }
    } else {
      amount = otherChargesGroup['unit_price'];
    }
    return amount;
  }

  /**
   * function to remove other charges group data
   * @param  {array} item [Current Item in item comparison]
   * @param  {array} comparisonIndex [Current Item Comparison]
   */
  function removeOtherChargesGroup(item, comparisonIndex) {
    delete item.other_charges_group;
    resetOtherCharges(item, comparisonIndex);
  }

  /**
   * function to assign tax for current other charges from other charges group
   * @param  {array} otherChargesGroup [Other Charges Group Selected]
   * @param  {array} charge [Current 'other charge' in other charges group]
   * @return {array} charge.tax [Tax]
   */
  function getTax(otherChargesGroup, charge) {
    if (_.isEmpty(charge.tax)) {
      charge.tax = otherChargesGroup.tax;
    }
    return charge.tax;
  }

  function initialize() {
    checkLowestPrice();
    getTemplateUrl('notEditable');
  }

  initialize();
}

priceComparisonItemDetailsCtrl.$inject = ['$scope', 'globalFunc', 'pathConstants', '$http', '$filter', 'loadCacheableData', 'calculateComparison', '$rootScope', 'priceComparisonFunction', 'purchaseRequisitionsV2Services', 'priceComparisonResource'];

angular
  .module('metabuyer')
  .directive('priceComparisonItemDetails', function() {
    return {
      restrict: 'AE',
      scope: true,
      template: '<div ng-include="priceComparisonTemplateUrl"></div>',
      controller: 'priceComparisonItemDetailsCtrl'
    }
  })
  .controller('priceComparisonItemDetailsCtrl', priceComparisonItemDetailsCtrl);
