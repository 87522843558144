'use strict';

function tenderGroupInfoCtrl(
  $scope, globalFunc, $rootScope, $uibModal, $state, HighlightId, pathConstants, $filter, tenderFunction, tenderService, toastr, $http
) {

  function initialize() {
    $scope.tenderGroups = tenderFunction.getTenderGroupsByComponents();
  }

  initialize();

  $scope.$on('updateTenderGroupsByComponents', function(event, result) {
    $scope.tenderGroups = result.data;
  });
}

tenderGroupInfoCtrl.$inject = [
  '$scope', 'globalFunc', '$rootScope', '$uibModal', '$state', 'HighlightId', 'pathConstants', '$filter', 'tenderFunction', 'tenderService', 'toastr', '$http'
];

angular
  .module('metabuyer')
  .controller('tenderGroupInfoCtrl', tenderGroupInfoCtrl);
