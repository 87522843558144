'use strict';

/**
 * Controller for PO Consolidation listing
 *
 * @param $scope
 * @param dataList
 * @param $state
 * @param globalFunc
 * @param $title
 * @param $stateParams
 * @param updateConsolidationStatus
 * @param toastr
 * @param massUpdateConsolidationStatus
 */
function poConsolidationManageCtrl($scope, dataList, $state, globalFunc, $title, $stateParams,
                                   updateConsolidationStatus, toastr, massUpdateConsolidationStatus) {
  $scope.dataList = dataList.data;
  $scope.processPr = false;
  $scope.selectedPrs = [];
  $scope.meta = dataList.meta;
  $scope.showColumn = showColumn;
  $scope.storageKey = 'po-consolidation-selected-column';
  $scope.goToPR = goToPR;
  $scope.selectedPr = selectedPr;
  $scope.closePoConsolidation = closePoConsolidation;
  $scope.massClosePoConsolidation = massClosePoConsolidation;
  $scope.processPendingPr = processPendingPr;
  $scope.selectAllCurrentPage = selectAllCurrentPage;

  function showColumn (id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function goToPR(id) {
    if (!id)
      return;

      $state.go('main.user.purchaseRequisitionsV2.details', {id: id});
  }

  function selectAllCurrentPage(status) {
    $scope.dataList.forEach(function (singleData) {
      if (status) {
        singleData.selected = true;
        $scope.processPr = true;

        var inArray = _.findIndex($scope.selectedPrs, function(e){
          return e._id === singleData._id;
        });

        // Only push if not in array
        if (inArray === -1) {
          $scope.selectedPrs.push(singleData);
        }
      } else {
        singleData.selected = false;
        $scope.processPr = false;
        _.remove($scope.selectedPrs, function(e){
          return e._id === singleData._id;
        });
      }
    })
  }

  function processPendingPr() {
    if ($scope.selectedPrs.length === 0) {
      toastr.error("Choose PR before consolidate");
    } else {
      var reference = [];
      var prDeliveryInfo = null;
      _.forEach($scope.selectedPrs, function (pr) {

        if (!_.isEmpty(pr.delivery_info)){
          prDeliveryInfo = pr.delivery_info;
        }

        if (!!pr.requisition.custom_reference && !_.isEmpty(pr.requisition.custom_reference)) {
          reference.push(pr.requisition.custom_reference);
        } else {
          reference.push(pr.requisition.reference);
        }
      });

      swal({
          title: 'Process Pending PR',
          text: 'Would you like to process Pending PR?',
          type: 'info',
          showCancelButton: true,
          confirmButtonColor: '#1ab394',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true,
          closeOnCancel: true
        },
        function (isConfirm) {
          if (!!isConfirm)
            $state.go('main.user.poConsolidation.itemListing', {
              references : reference,
              status: 'items',
              defaultDeliveryInfo: prDeliveryInfo
            });
        }
      );
    }
  }

  function massClosePoConsolidation() {
    if ($scope.selectedPrs.length === 0) {
      toastr.error("Choose PR to close before proceeding");
    } else {
      var ids = [];
      var prNos = '';
      _.forEach($scope.selectedPrs, function (pr) {
        ids.push(pr._id);
        prNos += '<b>' + pr.requisition.custom_reference + '</b><br>';
      });

      swal({
          html: true,
          title: 'Mass Close Pending Process PR',
          text: 'Would you like to mass close Pending Process PR? <br>' + prNos,
          type: 'input',
          showCancelButton: true,
          confirmButtonColor: '#1ab394',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true,
          closeOnCancel: true,
          inputType: 'text',
          inputPlaceholder: 'Write a reason'
        },
        function (inputValue) {
          if (inputValue === false) {
            return false;
          }

          if (inputValue === "") {
            toastr.error('You need to write a reason for closing');
            return false;
          }

          if (!!inputValue) {
            var trimmedValue = inputValue.trim();
          }

          if (trimmedValue === '') {
            toastr.error('You need to write a reason for closing');
            return false
          }

          massUpdateConsolidationStatus.put(
            {},
            {
              status: 'CLOSED',
              ids: ids,
              comment: inputValue
            },
            function (response) {
              toastr.success(response.content.message);
              $state.reload();
            }, function (error) {
              globalFunc.objectErrorMessage(error);
            });
        }
      );
    }
  }

  function selectedPr(selectedPr, selected) {
    $scope.processPr = true;
    if (selected === false) {
      _.remove($scope.selectedPrs, function(e){
        return e._id === selectedPr._id;
      });
    } else {
      $scope.selectedPrs.push(selectedPr);
    }
  }

  function closePoConsolidation(id) {
    swal({
        title: 'Close Pending Processed PR',
        text: 'Would you like to close Pending Processed PR?',
        type: 'input',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true,
        inputType: 'text',
        inputPlaceholder: 'Write a reason'
      },
      function (inputValue) {
        if (inputValue === false) {
          return false;
        }

        if (inputValue === "") {
          toastr.error('You need to write a reason for closing');
          return false;
        }

        if (!!inputValue) {
          var trimmedValue = inputValue.trim();
        }

        if (trimmedValue === '') {
          toastr.error('You need to write a reason for closing');
          return false
        }
        updateConsolidationStatus.put(
          {
            id: id
          },
          {
            status: 'CLOSED',
            comment: inputValue
          },
          function (response) {
            toastr.success(response.content.message);
            $state.reload();
          }, function (error) {
            globalFunc.objectErrorMessage(error);
          });
      }
    );
  }

  function initialize() {
    $scope.selected = false;
    $scope.status = $stateParams.status;
    $scope.$parent.status = $stateParams.status;
  }

  initialize();

}

poConsolidationManageCtrl.$inject = ['$scope', 'dataList', '$state', 'globalFunc', '$title', '$stateParams',
  'updateConsolidationStatus', 'toastr', 'massUpdateConsolidationStatus'];

angular.module('metabuyer')
  .controller('poConsolidationManageCtrl', poConsolidationManageCtrl);
