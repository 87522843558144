'use strict';

angular.module('metabuyer')
  .controller('supplierUserManageCtrl', supplierUserManageCtrl)

supplierUserManageCtrl.$inject = [
  '$scope', '$rootScope', '$stateParams', 'globalFunc', 'dataList', 'userFunctions', 'action', '$translate'
];

function supplierUserManageCtrl ($scope, $rootScope, $stateParams, globalFunc, dataList, userFunctions, action, $translate)
{
  $scope.getUserStatus = getUserStatus;
  $scope.getStatusDisplayName = getStatusDisplayName;
  $scope.getLastAction = getLastAction;

  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.status = $stateParams.status;
  $scope.actionType = action;
  $scope.query = $stateParams.query;

  function getUserStatus(status) {
    var status = userFunctions.convertUserStatus(status, true);

    return status.toUpperCase()
  }

  function getStatusDisplayName(status) {
    var translateArr = [
      'statuses.all',
      'statuses.active',
      'statuses.pending',
      'statuses.rejected',
      'statuses.withdrawn',
      'statuses.deactivated',
    ];
    status = status === 'inactive' ? 'deactivated' : status;
    $scope.statusDesc = status;
    $translate(translateArr).then(function (trans) {
      $scope.statusDesc = trans["statuses." + status];
    });
  }

  function getLastAction(actionHistory) {
    if (actionHistory.length === 0) {
      return "";
    }
    return _.last(actionHistory).toUpperCase();
  }

  function initialize() {
    if ($scope.status === 'pending') {
      $scope.storageKey = 'supplier-users-pending-management-selected-columns';
    } else {
      $scope.storageKey = 'supplier-users-management-selected-columns';
    }
    getStatusDisplayName($scope.status);
  }
  initialize();
}
