'use strict';

angular.module('metabuyer')
  .directive('supplierUserDetail', function() {
    return {
      templateUrl: 'components/supplierComponents/supplierUserDetail/supplierUserDetail.html',
      controller: supplierUserDetailDirectiveCtrl,
      scope: {
        timezones: '=',
        currencies: '=',
        user: '=',
        supplier: '<',
        saveAs: '<',
        index: '<',
        disableConfig: '<'
      }
    }
  }
)

supplierUserDetailDirectiveCtrl.$inject = ['$scope', '$http', '$filter', 'globalFunc', 'pathConstants', 'supplierUserSearchService', 'supplierFunctions', '$rootScope'];

function supplierUserDetailDirectiveCtrl($scope, $http, $filter, globalFunc, pathConstants, supplierUserSearchService, supplierFunctions ,$rootScope) {

  $scope.disableConfigEmail = disableConfigEmail;
  $scope.onSelectCurrency = onSelectCurrency;
  $scope.onSelectTimezone = onSelectTimezone;
  $scope.onSelectedSupplier = onSelectedSupplier;
  $scope.onSelectSupplierUser = onSelectSupplierUser;
  $scope.deselectSupplierUser = deselectSupplierUser;
  $scope.duplicationCheck = duplicationCheck;
  $scope.validateNumbers = validateNumbers;
  $scope.searchSuppliers = searchSuppliers;
  $scope.validateFieldSelection = validateFieldSelection;
  $scope.searchSupplierUser = searchSupplierUser;

  $scope.selectedSupplier = null;
  $scope.supplierSearchText = '';
  $scope.supplierUsers = [];

  function disableConfigEmail(supplierUser) {
    if (!$scope.disableConfig) {
      return false;
    }
    if (!!$scope.disableConfig.email && $scope.disableConfig.email === 'optional:sourcing_supplier_id') {
      if (!!supplierUser.sourcing_supplier_id) {
        return true;
      } else {
        return false;
      }
    }
    return !!$scope.disableConfig.email;
  }

  function onSelectCurrency(value) {
    $scope.user.currency = value.code;
    $scope.user.selectedCurrency = value;
  }

  function onSelectTimezone(value) {
    $scope.user.config.TZ = value;
  }

  function onSelectedSupplier(supplier) {
    $scope.selectedSupplier = supplier;
    $scope.user.sourcing_supplier_id = supplier._id;
    searchSupplierUser();
  }

  function onSelectSupplierUser(supplierUser) {
    $scope.user.supplier_user_delegate = _.clone(supplierUser);
  }

  function deselectSupplierUser() {
    $scope.user.supplier_user_delegate = [];
  }

  function duplicationCheck(model,field,value,checker){
    globalFunc.duplicationCheck(model,field,value,checker, function(callback){
      switch (callback){
        case 'emailCheckTrue':
          $scope.user.isExistingEmail = true;
          break;
        case 'emailCheckFalse':
          $scope.user.isExistingEmail = false;
          break;
      }
      if (!$scope.user.isExistingEmail) {
        duplicationCheckDraft(value, $scope.index);
      }
    });
  }

  function validateNumbers(elementId, fieldName) {
    $scope.user[fieldName] = false;
    if (!!$scope.user.phone) {
      $scope.user[fieldName] = globalFunc.numberFormatValidation(elementId + $scope.index);
    }
  }

  function searchSuppliers(val) {
    if (!!val && val.length > 2) {
      $scope.supplierSearching = true;

      var moduleParam = {
        module: 'suppliers'
      };

      var additionalParams = {
        'criteria[0][basic_info|descr]': val,
        'criteria[0][basic_info|reg_no]': val,
        'criteria[0][$operator]': 'or',
        'criteria[1][basic_info|status][0]': 'active',
        criteria_operator: 'and',
        offset: 100
      };

      return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: additionalParams,
        ignoreLoadingBar: true
      }).then(function (response) {
        if (response.data.content.data.length === 0) {
          return [];
        } else {
          // decrypt reg_no
          if (!$rootScope.isV2Enabled) {
            response.data.content.data.forEach(function (item, index) {
              response.data.content.data[index].basic_info.reg_no = atob(item.basic_info.reg_no);
            });
          }
          return response.data.content.data;
        }
      });
    }
  }

  function searchSupplierUser() {
    var params = {}

    if (!!$scope.user.sourcing_supplier_id) {
      params['supplierId'] = $scope.user.sourcing_supplier_id;
    } else {
      params['supplierId'] = $scope.supplier._id;
    }
    params['extraParams[status][0]'] = 1;
    params['extraParams[hide_selected_delegator]'] = 1;

    supplierUserSearchService.get(
      params,
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          var supplierUsers = resource.content.data;
          supplierUsers = _.filter(supplierUsers, function(user) {
            return user._id !== $scope.user._id;
          });
          $scope.supplierUsers = supplierUsers;
        } else {
          $scope.supplierUsers = [];
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function validateFieldSelection() {
    if(!!$scope.supplierSearchText && $scope.supplierSearchText.length > 0 && _.isEmpty($scope.selectedSupplier)) {
      $scope.supplierSearchText = '';
      $scope.user.sourcing_supplier_id = null;
    }
  }

  function duplicationCheckDraft(email, index) {
    if (!!$scope.supplier && !!$scope.supplier._id) {
      return true;
    }
    supplierFunctions.setSupplierUser({email: email}, index);
    var supplierUsers = supplierFunctions.getSupplierUser();
    var checkDuplicate = [];
    var isExistingEmail = false;
    _.forEach(supplierUsers, function(supplierUser, key){
      isExistingEmail = false;
      if (checkDuplicate.includes(supplierUser.email)) {
        isExistingEmail = true;
        return;
      }
      checkDuplicate.push(supplierUser.email);
    });
    if (isExistingEmail) {
      $scope.user.isExistingEmail = true;
      return false;
    }
    return true;
  }

  function initialize() {
    if (!!$scope.user.currency) {
      $scope.user.selectedCurrency = globalFunc.findInArray($scope.currencies, 'code', $scope.user.currency);
    }

    $scope.user.isExistingEmail = false;
    $scope.user.isValidPhone = true;

    if (!!$scope.supplier && !!$scope.supplier._id) {
      searchSupplierUser();
    }
    duplicationCheckDraft($scope.user.email, $scope.index);
  }

  initialize()
}
