'use strict';
/**
 * @name UsersManageCtrl
 * @desc Controller for users manage
 */
function UsersManageCtrl(
  $scope, $stateParams, usersList, Users, pathConstants, $uibModal, action, $filter, HighlightId, globalFunc, $rootScope
) {
  $scope.importAssignments = importAssignments;
  $scope.exportAssignments = exportAssignments;
  $scope.listItem = listItem;
  $scope.getStatusName = getStatusName;
  $scope.importLink = pathConstants.apiUrls.users.importAssignments;
  $scope.showColumn = showColumn;
  $scope.moduleName = 'User Assignments';
  $scope.hasUserPermission = globalFunc.checkUserPermission($scope.$root.currentUser, $scope.moduleName);
  $scope.getLocaleName = getLocaleName;

  $scope.storageKey = 'users-management-selected-columns';
  $scope.locales = {
    'en_MY': 'English - Malaysia',
    'zh_CN': 'Chinese - China'
  };

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

   function listItem (list, property) {
    return _.unique(_.map(list, function (item) {
      return item[property];
    })).toString();
  }


  function importAssignments() {
    var modalInstance = $uibModal.open({
      templateUrl: "app/masterDataManagement/Import-Template.html",
      backdrop: 'static',
      keyboard: false,
      resolve: {
        importLink: function () {
          return $scope.importLink;
        },
        title: function () {
          return "User Assignment";
        }
      },
      controller: function ($scope, $uibModalInstance, importLink, title) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        $scope.importLink = importLink;
        $scope.title = title;
      }
    });
  }

  function exportAssignments() {
    var modalInstance = $uibModal.open({
      templateUrl: 'components/exportUserAssignments/exportUserAssignmentsTemplate.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'exportUserAssignmentsCtrl'
    });
  }

  function getStatusName(number){
    /**
     * Pending = 0
     *
     * Active = 1
     *
     * Inactive = 2
     *
     * Rejected = 3
     */
    if(number === 0){
      return 'Pending';
    }
    if(number === 1){
      return 'Active';
    }
    if(number === 2){
      return 'Inactive';
    }
    if(number === 3){
      return 'Rejected';
    }
    return 'N/A';
  }

  function getLocaleName(code){
    return $scope.locales[code];
  }

  function initialize(){
    $scope.dataList = usersList.data;
    $scope.meta = usersList.meta;
    $scope.userManageService = Users;
    $scope.status = $stateParams.status;
    $scope.$parent.status = $stateParams.status;
    $scope.actionType = action;
    if($stateParams.type === 'date'){
      var ms = Number($stateParams.query[1]*1000);
      $scope.title = $filter('date')(new Date(ms), 'dd MMM yyyy');
    }
    else{
      $scope.title = $stateParams.query;
    }
    $scope.highlightId = HighlightId.getId();
    if (!!$scope.highlightId) {
      HighlightId.setId(null);
    }
  }

  initialize();
}

UsersManageCtrl.$inject = [
  '$scope', '$stateParams', 'usersList', 'Users', 'pathConstants', '$uibModal', 'action', '$filter', 'HighlightId', 'globalFunc', '$rootScope'
];

angular
  .module('metabuyer')
  .controller('UsersManageCtrl', UsersManageCtrl);
