'use strict';

function sourcingProjectDetailsCtrl($scope, $uibModalInstance, item,
                              sourcingProjectMasterUpdateStatus, globalFunc, toastr, sourcingProjectMasterResource) {
    $scope.editClicked = false;
    $scope.sourcingProject = _.cloneDeep(item);
    $scope.tab = 1;
    $scope.cancel = cancel;
    $scope.editSourcingProject = editSourcingProject;
    $scope.updateSourcingProject = updateSourcingProject;
    $scope.updateStatus = updateStatus;
    $scope.editing = false;


    $scope.historyDependencies = {
        embeddedParams: {
            'object[0][object_id]': $scope.sourcingProject._id,
            'object[0][object_type]': 'App\\Metabuyer\\SourcingProject\\Models\\SourcingProject',
            offset: 5,
            order_by: '-created_at'
        }
    };
    $scope.toggleTab = toggleTab;

    function toggleTab(tab) {
        return $scope.tab = tab;
    }

    function cancel() {
        $uibModalInstance.close();
    }

    function editSourcingProject() {
        $scope.editClicked = true;
    }

    function updateSourcingProject() {
        var data = preparePayload();
        sourcingProjectMasterResource.put(
            { uuid: $scope.sourcingProject.uuid },
            data,
            function (success) {
                toastr.success('Sourcing Project successfully created.');
                $uibModalInstance.close(success);
            },
            function (error) {
                globalFunc.objectErrorMessage(error);
            }
        );
    }

    function updateStatus() {
        sourcingProjectMasterUpdateStatus.put(
            { uuid: $scope.sourcingProject.uuid },
            { is_active: $scope.sourcingProject.is_active },
            function () {
                toastr.success('Status updated successfully');
            },
            function (err) {
                //revert
                $scope.sourcingProject.is_active = !$scope.sourcingProject.is_active;
                globalFunc.objectErrorMessage(err);
            }
        );
    }

    function preparePayload() {
        return {
            descr: $scope.sourcingProject.descr,
            is_active: $scope.sourcingProject.is_active
        };
    }

    function init() {

    }

    init();
}

sourcingProjectDetailsCtrl.$inject = ['$scope', '$uibModalInstance', 'item',
                                'sourcingProjectMasterUpdateStatus', 'globalFunc', 'toastr', 'sourcingProjectMasterResource'];

angular
  .module('metabuyer')
  .controller('sourcingProjectDetailsCtrl', sourcingProjectDetailsCtrl);
