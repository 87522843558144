'use strict';

function glCodeCtrl($scope, $http, $filter, pathConstants, glAccounts, toastr, Companies, globalFunc, searchModule) {
  $scope.glAccountCodes = [];
  $scope.glAccountCodeQuery = '';
  $scope.glAccountCodeGroupQuery = '';
  $scope.glCodeDisableTextbox =
    ($scope.budget.status.toLowerCase() === 'draft') ? false : true;
  $scope.glAccountCodeList = [];
  
  $scope.loadBudgetGlAccountCodes = loadBudgetGlAccountCodes;
  $scope.getAccountCodeSearch = getAccountCodeSearch;
  $scope.getAccountCodeGroupSearch = getAccountCodeGroupSearch;
  $scope.updateGlAccountCodes = updateGlAccountCodes;
  $scope.removeGlAccountCode = removeGlAccountCode;
  $scope.submitGlAccount = submitGlAccount;
  $scope.exportGlAccount = exportGlAccount;
  $scope.searchService = searchModule;

  function exportGlAccount() {
    var params = {
      budget_id: $scope.budget._id
    };

    $http.post($filter('format')(pathConstants.apiUrls.budget.glAccountsExport, null), {
      data: params,
      ignoreLoadingBar: true
    }).then(function (response) {
      var blob = new Blob([response.data]);
      var link = document.createElement('a');
      document.body.appendChild(link);
      link.href = window.URL.createObjectURL(blob);
      link.download = 'GL-Account-' +
        $filter('date')(new Date(), 'dd-MMM-yyyy') +'.csv';
      link.click();
      toastr.success('GL Account has been successfully exported.');
    }, function () {
      toastr.error('Failed to generate file.');
    });
  }

  function submitGlAccount() {
    var params = {
      budget_id: $scope.budget._id,
      company_code: $scope.budget.company.code,
      erp_budget_id: $scope.budget.erp_budget_id,
      gl_accounts: $scope.budget.gl_accounts
    };

    glAccounts.post({
        data: params
      }, function (resource) {
        toastr.success('GL Account Code has been submitted.')
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function removeGlAccountCode(index){
    $scope.glAccountCodes.splice(index,1);
  }

  function updateGlAccountCodes(newAccountCode){
    var accountCode = prepareAccountCode(newAccountCode);
    if (_.findIndex($scope.glAccountCodes, accountCode) === -1)
      $scope.glAccountCodes.push(accountCode);

    $scope.glAccountCodeQuery = '';
  }

  function prepareAccountCode(accountCode){
    return {
      'account_code': accountCode.code,
      'descr': accountCode.descr,
      'name': accountCode.name
    }
  }

  function getAccountCodeSearch(query){
    var moduleParam = {
      id: $scope.budget.company._id,
      query: query
    };
    return $http.get($filter('format')(pathConstants.apiUrls.accountCode.search, moduleParam), {}).then(function (response) {
      if (response.data.content.data.length === 0) {
        return [];
      } else {
        return _.filter(response.data.content.data, function (item) {
          var isFound = _.findIndex($scope.glAccountCodes, function(e){
            return item.code === e.account_code;
          });

          if (isFound === -1)
            return true;
        });
      }
    });
  }

  function getAccountCodeGroupSearch(query) {
    return $http.get($filter('format')(pathConstants.apiUrls.accountCodeGroup.search), {
      params: {
        module: 'budget',
        query: query,
        company_code: $scope.budget.company.code
      }
    }).then(function (response) {
      if (response.data.content.length === 0) {
        toastr.info('Account code group input has no account code data');
      } else {
        $scope.glAccountCodes = response.data.content.data.account_codes;
        $scope.budget.gl_accounts = response.data.content.data.account_codes;
        $scope.budget.enable_opex_grouping = true;
      }
    });
  }

  function loadBudgetGlAccountCodes(query) {
    $scope.embeddedParams = {
      'module': 'budget-gl-account',
      'criteria[0][code]': $scope.budget.code,
      'criteria[0][uuid]': $scope.budget.uuid,
      'criteria[0][$operator]': 'and',
      'offset': 20,
      'order_by': 'gl_accounts|name',
      'order': 1,
    };

    if (query !== null) {
      $scope.embeddedParams['criteria[1][gl_accounts|account_code]'] = query;
      $scope.embeddedParams['criteria[1][gl_accounts|name]'] = query;
      $scope.embeddedParams['criteria[1][gl_accounts|amount]'] = query;
      $scope.embeddedParams['criteria[1][$operator]'] = 'or';
    }

    searchModule.get(
      $scope.embeddedParams, function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.glAccountCodes = resource.content.data;
          $scope.metaData = resource.content.meta.cursor;
        }
      }, function (error) {
        globalFunc.objectErrorMessage(error);
        $scope.glAccountCodes = [];
        $scope.metaData = {};
      });
  }

  function init() {
    var status = $scope.budget.status.toLowerCase();
    if (status === 'approved' || status === 'pending' || status === 'withdraw') {
      loadBudgetGlAccountCodes(null);
    } else {
      $scope.glAccountCodes = $scope.originalBudgetData.gl_accounts;
    }
    $scope.opexBudgetGrouping = $scope.budget.enable_opex_grouping;
    $scope.glAccountCodeGroupQuery = $scope.originalBudgetData.gl_account_code_group_code;
    $scope.budget.gl_accounts = $scope.glAccountCodes;
    $scope.importGlAccountParams = {
      'class': 'Metabuyer\\Budget\\Models\\BudgetGlAccount',
      'additional_params[budget_uuid]': $scope.budget.uuid
    };
  }

  init();
}

glCodeCtrl.$inject = ['$scope', '$http', '$filter', 'pathConstants', 'glAccounts', 'toastr', 'Companies', 'globalFunc', 'searchModule'];

angular
  .module('metabuyer')
  .directive('glCode', function () {
    return {
      restrict: 'E',
      templateUrl: 'components/budgetV2/glCode/glCode.html',
      controller: 'glCodeCtrl'
    }
  })
  .controller('glCodeCtrl', glCodeCtrl);

