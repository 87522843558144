'use strict';

/**
 *
 * @param $scope
 * @param $rootScope
 * @param sourcingCategoryMasterResource
 * @param $uibModalInstance
 * @param toastr
 * @param globalFunc
 */
function addSourcingCategoryCtrl($scope, $rootScope, $uibModalInstance, toastr, globalFunc, sourcingCategoryMasterResource, $http, $filter, pathConstants) {

  $scope.submitted = false;
  $scope.closeModal = closeModal;
  $scope.submitSourcingCategory = submitSourcingCategory;

  $scope.sourcingCategory = {
    is_active: true
  };

  function closeModal() {
    $uibModalInstance.close();
  }

  function preparePayload() {
    var data = {
      code: $scope.sourcingCategory.code,
      descr: $scope.sourcingCategory.descr,
      descr_chinese: $scope.sourcingCategory.descr_chinese || null,
      is_active: $scope.sourcingCategory.is_active,
    };

    return data;
  }

  function submitSourcingCategory() {
    $scope.submitted = true;
    if (!validate($scope.sourcingCategory)) {
      var data = preparePayload();
      sourcingCategoryMasterResource.post(
        data,
        function (success) {
          toastr.success('Sourcing Category successfully created.');
          $uibModalInstance.close(success);
          $scope.submitted = false;
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    }
  }

  function validate(sourcingCategoryDetails) {
    var anyError = false;
    if (!sourcingCategoryDetails.code) {
      toastr.error('Sourcing Category code is required.');
      anyError = true;
    }
    if (!sourcingCategoryDetails.descr) {
      toastr.error('Sourcing Category description is required.');
      anyError = true;
    }
    return anyError;
  }
}



addSourcingCategoryCtrl.$inject = ['$scope', '$rootScope', '$uibModalInstance', 'toastr', 'globalFunc', 'sourcingCategoryMasterResource', '$http', '$filter', 'pathConstants'];
angular.module('metabuyer')
  .controller('addSourcingCategoryCtrl', addSourcingCategoryCtrl);
