'use strict';

/**
 * Controller for IFCA data outbound info
 * @description
 * Contains functions for handling tabs and table columns logic
 *
 * @param $scope
 * @param $state
 * @param dataList
 * @param $http
 * @param pathConstants
 * @param $filter
 * @param toastr
 * @param globalFunc
 * @watch $stateChangeStart
 * Watches on the start of state change, set status and params
 *
 * @author Ling Nai Shin <ling_naishin@hotmail.com>
 * @copyright 2019 Metacloud Sdn. Bhd.
 */
function bifrostIfcaDataOutboundInfoCtrl($scope, $state, dataList, $http, pathConstants, $filter, toastr, globalFunc) {
  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.showColumn = showColumn;
  $scope.downloadFileFromS3 = downloadFileFromS3;

  function showColumn(id) {
    var i;
    var columns = $scope.columns;
    for (i in columns) {
      if(columns.hasOwnProperty(i)) {
        if (id === columns[i].id) {
          if(!!columns[i].conditional) {
            if($scope.status === columns[i].condition)
              return columns[i].selected;
            if(columns[i].condition instanceof Array) {
              var j;
              var columnCondition = $scope.columns[i].condition;
              for(j in columnCondition) {
                if(columnCondition.hasOwnProperty(j)) {
                  if($scope.status === columnCondition[j]) {
                    return columns[i].selected;
                  }
                }
              }
            }
          }
          else
            return columns[i].selected;
        }
      }
    }
  }

  function downloadFileFromS3(data) {
    var filename = (data.file_path).split('/')[(data.file_path).split('/').length - 1];

    $http.get(pathConstants.apiUrls.bifrost.downloadOutboundFile, {params: data}).then(
      function (response) {
        if (typeof response.data === 'object') {
          var blob = new Blob([JSON.stringify(response.data)]);
        } else {
          var blob = new Blob([response.data]);
        }
        var link = document.createElement('a');
        document.body.appendChild(link);
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  initialize();

  function initialize() {
  }

}

bifrostIfcaDataOutboundInfoCtrl.$inject = ['$scope', '$state', 'dataList', '$http', 'pathConstants', '$filter', 'toastr', 'globalFunc'];

angular.module('metabuyer')
  .controller('bifrostIfcaDataOutboundInfoCtrl', bifrostIfcaDataOutboundInfoCtrl);
