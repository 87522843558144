'use strict';

/**
 * @name createBudgetButton v2 Directive
 * @desc Directive for handling create budget button logic
 *
 * @author Ameer Asraf aka Jamal <ameerasraf_92@yahoo.com>
 * @copyright 2020 Metacloud Sdn. Bhd.
 */
angular
  .module('metabuyer')
  .directive('createBudgetV2Button', function () {
    return {
      restrict: 'E',
      templateUrl: 'components/budgetV2/createBudgetButton/createBudgetButton.html',
      scope: {},
      controller: function ($scope, $rootScope, $state, UserPermissions, budgets, globalFunc) {
        $scope.disabled = false;
        $scope.title = 'Create Budget';
        $scope.listOfCompanies = UserPermissions.getBudgetCompanies($rootScope.currentUser, 'Create');
        $scope.createBudget = createBudget;
        $scope.showCreateButton = showCreateButton;
        $scope.checkOffline = checkOffline;

        function checkOffline(company) {
          if (!!company.budget_setting.offline_budget === !!$rootScope.isOffline) {
            return true;
          }

          return false;
        }

        /**
         * navigate to creation page
         */
        function createBudget(company) {
          $scope.title = 'Loading ...';
          $scope.disabled = true;
          budgets.post(
            {
              company_code: company.code
            },
            function (resource) {
              enableCreateButton();
              if (!!resource.content && !!resource.content.data) {
                var data = resource.content.data;
                $state.go('main.user.budgetV2.details', {id: data._id});
              }
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
              enableCreateButton();
            }
          );
        }

        /**
         * This function enable the create button
         */
        function enableCreateButton () {
          $scope.title = 'Create Budget';
          $scope.disabled = false;
        }

        /**
         * This function return a boolean to show or hiding the create budget button
         * it will allow based on Anubis settings (if V2 is enabled), or if the user is Developer
         * @returns boolean
         */
        function showCreateButton() {
          return ($state.current.name.indexOf('advancedSearch') === -1) &&
            (($state.current.name.indexOf('budget') > -1 && !!$rootScope.isV2Enabled) ||
            ($state.current.name.indexOf('budget') > -1 && !!UserPermissions.isDeveloper($rootScope.currentUser)));
        }
      }
    };
  });
