'use strict';


function budgetIo() {
  return {
    restrict: 'AE',
    templateUrl: 'components/purchaseRequisitionV2Components/budgetIo/budgetIoTemplate.html',
    controller: function ($scope, prV2Function, $http, $filter, pathConstants) {
      $scope.PR = prV2Function.getPRData();
      $scope.loadBudgets = loadBudgets;
      $scope.submissionValidation = submissionValidation;
      $scope.isBudgetFromAccountCode = isBudgetFromAccountCode;
      $scope.disableBudgetEdit = disableBudgetEdit;
      $scope.budget = !_.isEmpty($scope.item.budget) ? $scope.item.budget : null;

      /**
       * check errors field from prV2Function.getErrorOnSubmit
       * @param field
       */
      function submissionValidation(field) {
        var errors = prV2Function.getErrorsOnSubmit();
        return (errors.indexOf(field) > -1);
      }

      function isBudgetFromAccountCode(){
        return $scope.PR.expense_type.category === 'OPEX' || ($scope.PR.expense_type.category === 'CAPEX' && !!$scope.PR.document_rule.is_project_code_mandatory);
      }

      function disableBudgetEdit(){
        return !!$scope.PR.document_rule.is_project_code_mandatory;
      }

      // refresh budget drop-down listing
      function loadBudgets(query) {
        if (!$scope.PR.expense_type || !$scope.PR.expense_type.category) {
          return [];
        }

        return $http.get($filter('format')(pathConstants.apiUrls.budget.list), {
          params: {
            query: query,
            type: 'budget_listing_for_pr_draft',
            company_code: $scope.PR.company.code,
            shadow_company_code: $scope.PR.shadow_company.code,
            expense_type_category: $scope.PR.expense_type.category,
            cost_center: !!$scope.PR.cost_center ? $scope.PR.cost_center.code : undefined
          }
        }).then(function (response) {
          if (response.data.content.data.length === 0) {
            return [];
          } else {
            return response.data.content.data.map(function (item) {
              return item;
            });
          }
        });
      }

    }
  }
}

angular
  .module('metabuyer')
  .directive('budgetIo', budgetIo);
