angular
  .module('metabuyer')
  .directive('supplierWebTermsAndConditionsFooter',function() {
    return {
      restrict: 'E',
      templateUrl: 'app/suppliersWeb/termsAndConditions/termsAndConditionsTemplate.html',
      scope: {},
      controller: function ($scope, $uibModal) {
        $scope.viewSupplierWebTnC = viewSupplierWebTnC;

        /**
         * View the supplier web terms and conditions
         */
        function viewSupplierWebTnC() {
          var modalInstance = $uibModal.open({
            templateUrl: 'app/suppliersWeb/termsAndConditions/termsAndConditionsModalTemplate.html',
            backdrop: 'static',
            keyboard: false,
            resolve: {
              title: function () {
                return 'Supplier Web Terms and Conditions';
              }
            },
            controller: function ($scope, $uibModalInstance, getSupplierWebTnc, globalFunc) {
              $scope.close = function () {
                $uibModalInstance.close();
              };

              getSupplierWebTnc.get(
                function (resource) {
                  $scope.supplierWebTnC = resource.data;
                },
                function (error) {
                  globalFunc.objectErrorMessage(error);
                }
              );
            }
          });
        }
      }
    }
  });
