'use strict';

/**
 * @name SuppliersWebUserListCtrl
 * @desc Controller for users
 */
function SuppliersWebUserListCtrl($scope, $state, users, $stateParams) {
  $scope.meta = users.meta;
  $scope.users = users.data;
  $scope.usersService = '';
  $scope.showColumn = showColumn;
  $scope.storageKey = 'supplier-web-users-list';

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional){
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function initialize() {
    $scope.status = $stateParams.status;
  }

  initialize();
}

SuppliersWebUserListCtrl.$inject = ['$scope', '$state', 'users', '$stateParams'];

angular
  .module('metabuyer')
  .controller('SuppliersWebUserListCtrl', SuppliersWebUserListCtrl);
