'use strict';

/**
 * Controller for bifrost management
 * @description
 * Contains functions for handling tabs and table columns logic
 *
 * @param $scope
 * @param $state
 *
 * @watch $stateChangeStart
 * Watches on the start of state change, set status and params
 *
 * @author Ahmed Saleh <a.s.alsalali@gmail.com>
 * @copyright 2018 Metacloud Sdn. Bhd.
 */
function bifrostManagementCtrl($scope, $state, globalFunc) {
  $scope.$emit('pageTitleChanged', 'Bifrost Listing');
  $scope.tabData = [];
  $scope.tabData.more = [];

  var ifcaDataOutboundInfo = {
    heading: 'IFCA Data Outbound Info',
    route: 'main.user.bifrost.ifcaDataOutboundInfo',
    key: 'ifcaDataOutboundInfo',
    id: 'ifcaDataOutboundInfoTab'
  };
  var jdeDataOutboundInfo = {
    heading: 'JDE Data Outbound Info',
    route: 'main.user.bifrost.jdeDataOutboundInfo',
    key: 'jdeDataOutboundInfo',
    id: 'jdeDataOutboundInfoTab'
  };
  var dataOutbound = {
    heading: 'Data Outbound',
    route: 'main.user.bifrost.dataOutbound',
    key: 'dataOutbound',
    id: 'dataOutboundTab'
  };
  var dataOutboundMore = {
    heading: 'Data Outbound',
    key: 'dataOutbound',
    id: 'dataOutbound',
    menu: [
      dataOutbound,
      jdeDataOutboundInfo,
      ifcaDataOutboundInfo
    ]
  };
  var purchaseOrder = {
    heading: 'Purchase Order',
    route: 'main.user.bifrost.purchaseOrder',
    key: 'purchaseOrder',
    id: 'purchaseOrder'
  };
  var jdeDataInboundInfo = {
    heading: 'JDE Data Inbound Info',
    route: 'main.user.bifrost.jdeDataInboundInfo',
    key: 'dataInboundInfo',
    id: 'dataInboundInfoTab'
  };
  var dataInbound = {
    heading: 'Data Inbound Listing',
    route: 'main.user.bifrost.dataInbound',
    key: 'dataInbound',
    id: 'dataInboundTab',
  };
  var dataInboundMore = {
    heading: 'Data Inbound',
    key: 'dataInbound',
    id: 'dataInbound',
    menu: [
      dataInbound,
      jdeDataInboundInfo
    ]
  };
  var supplier = {
    heading: 'Supplier',
    route: 'main.user.bifrost.supplier',
    key: 'supplier',
    id: 'supplier',
    type: 'more'
  };
  var mappedSupplier = {
    heading: 'Mapped Supplier',
    route: 'main.user.bifrost.mappedSupplier',
    key: 'mappedSupplier',
    id: 'mappedSupplier'
  };
  var dataSync = {
    heading: 'Data sync',
    route: 'main.user.bifrost.dataSync',
    key: 'dataSync',
    id: 'dataSyncTab'
  };
  var dataServiceColumns = [
    {id: 'id', label: 'ID'},
    {id: 'started_by', label: 'Started By', unsearchable: true, unsortable: true},
    {id: 'started_at', label: 'Started At', unsearchable: true},
    {id: 'stopped_at', label: 'Stopped At', unsearchable: true},
    {id: 'status', label: 'Status', unsearchable: true},
    {id: 'details', label: 'Details', unsortable: true},
    {id: 'created_at', label: 'Created At', unsearchable: true},
    {id: 'updated_at', label: 'Updated At', unsearchable: true}
  ];
  // Disable some columns for now because the query in bifrost is slow, will enable this after a solution is found
  var purchaseOrderColumns = [
    {id: 'po_number', label: 'PO#', unsearchable: false, unsortable: true},
    // {id: 'success', label: 'Success', unsearchable: true, unsortable: true},
    {id: 'po_status', label: 'PO Status', unsearchable: true, unsortable:true},
    // {id: 'po_outbound_date', label: 'Outbound Date', unsearchable: true, unsortable:true},
    // {id: 'outbound_error', label: 'Error message', unsearchable:true, unsortable:true},
    {id: 'interaction_disabled', label: 'Interaction Disabled', unsearchable: true, unsortable: false},
    {id: 'delete_button', label: '', unsortable: true, unsearchable: true}
  ];
  var supplierColumns = [
    {id: 'filename', label: 'File Name', unsearchable: false, unsortable: true},
    {id: 'Mapping list', label: '', unsearchable: true, unsortable: true},
    {id: 'supplier_code', label: 'Supplier Code', unsearchable: true, unsortable: true},
    {id: 'reg_no', label: 'Registration No.', unsearchable: false, unsortable: true},
    {id: 'success', label: 'Success', unsearchable: true, unsortable: true},
    {id: 'inbound_date', label: 'Inbound Date', unsearchable: true, unsortable: true},
    {id: 'updated_at', label: 'Updated At', unsearchable: true, unsortable: true}
  ];
  // Disabled UpdateStatus column for now, because the feature is not being used at the moment and there is nothing displayed on it.
  var mappedSupplierColumns = [
    // {id: 'UpdateStatus', label: 'Updated', unsearchable: true, unsortable: true},
    {id: 'MetabuyerNumber', label: 'MetabuyerNumber', unsearchable: true, unsortable: true},
    {id: 'SupplierName', label: 'SupplierName', unsearchable: true, unsortable: true},
    {id: 'ROC', label: 'ROC', unsearchable: true, unsortable: true},
    {id: 'ERPSupplierNumber', label: 'ERPSupplierNumber', unsearchable: true, unsortable: true},
    {id: 'Environment', label: 'Environment', unsearchable: true, unsortable: true},
    {id: 'CompanyCode', label: 'CompanyCode', unsearchable: true, unsortable: true},
    {id: 'ERPCompanyCode', label: 'ERPCompanyCode', unsearchable: true, unsortable: true},
    {id: 'SICCode', label: 'SICCode', unsearchable: true, unsortable: true},
  ];
  var jdeInboundInfoColumns = [
    {id: 'id', label: 'ID', unsearchable: true, unsortable: false},
    {id: 'data_inbound_id', label: 'Data Inbound Id', unsearchable: true, unsortable: false},
    {id: 'provider', label: 'Provider Name', unsearchable: true, unsortable: false},
    {id: 'module', label: 'Module Name', unsearchable: true, unsortable: false},
    {id: 'reference', label: 'Reference', unsearchable:false, unsortable:false},
    {id: 'file_path', label: 'File Name', unsearchable: true, unsortable: false},
    {id: 'created_at', label: 'Created At', unsearchable: true, unsortable: false}
  ];
  var outboundInfoColumns = [
    {id: 'id', label: 'ID', unsearchable: true, unsortable: false},
    {id: 'data_outbound_id', label: 'Data Outbound Id', unsearchable: true, unsortable: false},
    {id: 'provider', label: 'Provider Name', unsearchable: true, unsortable: false},
    {id: 'module', label: 'Module Name', unsearchable: true, unsortable: false},
    {id: 'reference', label: 'Reference', unsearchable:false, unsortable:false},
    {id: 'file_path', label: 'File Name', unsearchable: true, unsortable: false},
    {id: 'status', label: 'Status', unsearchable: true, unsortable: false},
    {id: 'error_message', label: 'Error Message', unsearchable:true, unsortable: false},
    {id: 'created_at', label: 'Created At', unsearchable: true, unsortable: false}
  ];
  var ifcaOutboundInfoColumns = [
    {id: 'id', label: 'ID', unsearchable: false, unsortable: false},
    {id: 'data_outbound_id', label: 'Data Outbound Id', unsearchable: true, unsortable: false},
    {id: 'provider', label: 'Provider Name', unsearchable: true, unsortable: false},
    {id: 'module', label: 'Module Name', unsearchable: true, unsortable: false},
    {id: 'file_path', label: 'File Name', unsearchable: true, unsortable: false},
    {id: 'status', label: 'Status', unsearchable: true, unsortable: false},
    {id: 'error_message', label: 'Error Message', unsearchable:true, unsortable: false},
    {id: 'created_at', label: 'Created At', unsearchable: true, unsortable: false}
  ];

  $scope.tabData.push(dataOutboundMore);
  $scope.tabData.push(dataInboundMore);
  $scope.tabData.push(dataSync);
  $scope.tabData.push(purchaseOrder);
  $scope.tabData.push(mappedSupplier);
  $scope.tabData.more.push(supplier);

  function initialize() {
    setScope($state.current);
  }

  $scope.$on('$stateChangeStart', function( event, data, toParams ) {
    setScope(data);
    if (!!toParams.status) {
      $scope.parentStateParams = toParams;
    }

    if (!!toParams && !!toParams.query){
      $scope.searchText = toParams.query;
    }
    else {
      $scope.searchText = '';
    }

  });

  function setScope(state) {
    $scope.storageKey = 'data-services-selected-columns';
    $scope.allFilter = true;
    if (!!state.name && state.name.indexOf('ifcaDataOutboundInfo') > -1) {
      $scope.storageKey = 'ifca-data-outbound-info-selected-columns';
      $scope.columns = ifcaOutboundInfoColumns;
      $scope.searchPlaceholder = 'Search Data Outbound Info';
      $scope.searchState = 'main.user.bifrost.ifcaDataOutboundInfo';
      $scope.parentState = 'main.user.bifrost.ifcaDataOutboundInfo';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = dataOutboundMore;
      $scope.allFilter = false;
    }
    else if (!!state.name && state.name.indexOf('jdeDataOutboundInfo') > -1) {
      $scope.storageKey = 'data-outbound-info-selected-columns';
      $scope.columns = outboundInfoColumns;
      $scope.searchPlaceholder = 'Search Data Outbound Info';
      $scope.searchState = 'main.user.bifrost.jdeDataOutboundInfo';
      $scope.parentState = 'main.user.bifrost.jdeDataOutboundInfo';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = dataOutboundMore;
      $scope.allFilter = false;
    }
    else if (!!state.name && state.name.indexOf('jdeDataInboundInfo') > -1) {
      $scope.storageKey = 'jde-data-inbound-info-selected-columns';
      $scope.columns = jdeInboundInfoColumns;
      $scope.searchPlaceholder = 'Search Data Inbound Info';
      $scope.searchState = 'main.user.bifrost.jdeDataInboundInfo';
      $scope.parentState = 'main.user.bifrost.jdeDataInboundInfo';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = dataInboundMore;
      $scope.allFilter = false;
    }
    else if (!!state.name && state.name.indexOf('dataInbound') > -1) {
      $scope.columns = dataServiceColumns;
      $scope.searchPlaceholder = 'Search Data Inbound';
      $scope.searchState = 'main.user.bifrost.dataInbound';
      $scope.parentState = 'main.user.bifrost.dataInbound';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = dataInboundMore;
    }
    else if (!!state.name && state.name.indexOf('dataSync') > -1) {
      $scope.columns = dataServiceColumns;
      $scope.searchPlaceholder = 'Search Data Sync';
      $scope.searchState = 'main.user.bifrost.dataSync';
      $scope.parentState = 'main.user.bifrost.dataSync';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = dataSync;
    }
    else if (!!state.name && state.name.indexOf('purchaseOrder') > -1) {
      $scope.storageKey = 'purchase-order-selected-columns';
      $scope.columns = purchaseOrderColumns;
      $scope.searchPlaceholder = 'Search Purchase Order';
      $scope.searchState = 'main.user.bifrost.purchaseOrder';
      $scope.parentState = 'main.user.bifrost.purchaseOrder';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = purchaseOrder;
      $scope.allFilter = false;
    }
    else if (!!state.name && state.name.indexOf('mappedSupplier') > -1) {
      $scope.storageKey = 'mapped-supplier-selected-columns';
      $scope.columns = mappedSupplierColumns;
      $scope.searchPlaceholder = 'Search Mapped Supplier';
      $scope.searchState = 'main.user.bifrost.mappedSupplier';
      $scope.parentState = 'main.user.bifrost.mappedSupplier';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = mappedSupplier;
    }
    else if (!!state.name && state.name.indexOf('supplier') > -1) {
      $scope.storageKey = 'supplier-selected-columns';
      $scope.columns = supplierColumns;
      $scope.searchPlaceholder = 'Search Supplier';
      $scope.searchState = 'main.user.bifrost.supplier';
      $scope.parentState = 'main.user.bifrost.supplier';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = supplier;
      $scope.allFilter = false;
    }
    else {
      $scope.columns = dataServiceColumns;
      $scope.searchPlaceholder = 'Search Data Outbound';
      $scope.searchState = 'main.user.bifrost.dataOutbound';
      $scope.parentState = 'main.user.bifrost.dataOutbound';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = dataOutboundMore;
    }
  }

  initialize();
}

bifrostManagementCtrl.$inject = ['$scope', '$state', 'globalFunc'];

angular.module('metabuyer')
  .controller('bifrostManagementCtrl', bifrostManagementCtrl);
