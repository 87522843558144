'use strict';

/**
 * references
 *
 * details.html
 * details.controller.js
 */

angular.module('metabuyer')
  .controller('supplierDetailsCtrl', supplierDetailsCtrl);

supplierDetailsCtrl.$inject = [
  '$scope', '$state', 'toastr', 'HighlightId', 'supplierDetails', 'currencies', 'paymentTerms',
  'paymentMethods', 'taxes', 'globalFunc', 'commodities', 'searchAllCommodity', 'listChild', 'supplierFunctions',
  'updatedSupplierDetails', 'deleteWithdrawnSupplier', 'searchModule', '$rootScope', 'companies', 'Approvals', 'profile', 'approvalSupplier', 'supplierStatus',
  '$uibModal', 'singleAttachment', '$http', '$filter', 'pathConstants', 'supplierAttachment', '$q', 'resubmitSupplier', '$document',
  'pushSupplierToBifrost', 'pushToKharon', 'exportSearch', 'supplier', 'featureToggle', 'timezones', '$translate'
];

function supplierDetailsCtrl(
  $scope, $state, toastr, HighlightId, supplierDetails, currencies, paymentTerms,
  paymentMethods, taxes, globalFunc, commodities, searchAllCommodity, listChild, supplierFunctions,
  updatedSupplierDetails, deleteWithdrawnSupplier, searchModule, $rootScope, companies, Approvals, profile, approvalSupplier, supplierStatus,
  $uibModal, singleAttachment, $http, $filter, pathConstants, supplierAttachment, $q, resubmitSupplier, $document,
  pushSupplierToBifrost, pushToKharon, exportSearch, supplier, featureToggle, timezones, $translate
) {

  $scope.bumiputraStatus = [
    {descr: 'Yes', value: 1},
    {descr: 'No', value: 0}
  ];

  $scope.businessNatures = [
    {descr: 'Unspecified Business Nature', shortDescr: 'UNSPECIFIED', value: 0},
    {descr: 'Agent', shortDescr: 'AGENT', value: 1},
    {descr: 'Banking & Financial Services', shortDescr: 'BANKING', value: 2},
    {descr: 'Consultancy, Advisory & Other Professional Bodies', shortDescr: 'CONSULTANCY', value: 3},
    {descr: 'Contractor', shortDescr: 'CONTRACTOR', value: 4},
    {descr: 'Dealer', shortDescr: 'DEALER', value: 5},
    {descr: 'Distributor', shortDescr: 'DISTRIBUTOR', value: 6},
    {descr: 'Manufacturer', shortDescr: 'MANUFACTURER', value: 7},
    {descr: 'Marketing', shortDescr: 'MARKETING', value: 8},
    {descr: 'Retailer', shortDescr: 'RETAILER', value: 9},
    {descr: 'Service Provider', shortDescr: 'SERVICE_PROVIDER', value: 10},
    {descr: 'Sub-Contractor', shortDescr: 'SUB_CONTRACTOR', value: 11},
    {descr: 'Trading', shortDescr: 'TRADING', value: 12},
    {descr: 'Other', shortDescr: 'OTHERS', value: 13}
  ];
  $scope.businessEntities = [
    {descr: 'Berhad (Public Limited)', shortDescr: 'PUBLIC_LIMITED', value: '1'},
    {descr: 'Sendirian Berhad (Private Limited)', shortDescr: 'PRIVATE_LIMITED', value: '2'},
    {descr: 'Sole Proprietor & Enterprise', shortDescr: 'SOLE', value: '3'},
    {descr: 'Partnership', shortDescr: 'PARTNERSHIP', value: '4'}
  ];

  $scope.supplierTypes = supplierFunctions.getSupplierTypes();
  $scope.languages = supplierFunctions.getLanguages();

  $scope.isValidPhoneNo = true;
  $scope.isValidFaxNo = true;
  $scope.editClicked = false;
  $scope.isResubmit = false;

  $scope.supplier = supplierDetails.data;
  $scope.currencies = currencies;
  $scope.paymentTerms = paymentTerms.data;
  $scope.paymentMethods = paymentMethods.data;
  $scope.companies = [];
  $scope.taxes = taxes;
  $scope.countries = globalFunc.countryCode();
  $scope.myStates = globalFunc.malaysiaState();
  $scope.commodityLevel = [3, 4];
  $scope.approvalFlowData = {};
  $scope.waitingOnUser = [];
  $scope.statusUpdatePayload = {};
  $scope.approvalStatusInfo = {};
  $scope.attachments = [];
  $scope.supplierCopy= {};
  $scope.toggleList = {
    kharon: false
  };
  $scope.isSourcingSupplierEnabled = featureToggle['system.enableSourcingSupplier'].is_enabled;
  $scope.selectedSupplierType = {};
  $scope.timezones = timezones;
  $scope.selectedLanguage = {};
  $scope.supplierUsers = [];
  // disable configuration for supplier user detail
  $scope.disableConfig = {
    supplier: true,
    email: "optional:sourcing_supplier_id",
    supplier_user_delegate: true,
  }

  $scope.back = back;
  $scope.selectReceivingMode = selectReceivingMode;
  $scope.onUpdatedCountry = onUpdatedCountry;
  $scope.onUpdatedState = onUpdatedState;
  $scope.openChildCommodityCodePrimary = openChildCommodityCodePrimary;
  $scope.openChildCommodityCodeSecondary = openChildCommodityCodeSecondary;
  $scope.searchPrimaryCommodityCode = searchPrimaryCommodityCode;
  $scope.searchSecondaryCommodityCode = searchSecondaryCommodityCode;
  $scope.removeSecondaryCommodity = removeSecondaryCommodity;
  $scope.getPaidUpCategory = getPaidUpCategory;
  $scope.checkWaitingOn = checkWaitingOn;
  $scope.approve = approve;
  $scope.reject = reject;
  $scope.withdrawSupplier = withdrawSupplier;
  $scope.submitEditedSupplier = submitEditedSupplier;
  $scope.validatePrimaryBusinessNature = validatePrimaryBusinessNature;
  $scope.validateSecondaryBusinessNature = validateSecondaryBusinessNature;
  $scope.allowEditingSupplier = allowEditingSupplier;
  $scope.onAddedContact = onAddedContact;
  $scope.removeContact = removeContact;
  $scope.onUpdatedContact = onUpdatedContact;
  $scope.onSelectedTaggedToCompany = onSelectedTaggedToCompany;
  $scope.cancel = cancel;
  $scope.submitChanges = submitChanges;
  $scope.checkHyphenFirstCharacter = checkHyphenFirstCharacter;
  $scope.selectedBumiputra = selectedBumiputra;
  $scope.updateSupplier = updateSupplier;
  $scope.deleteSupplier = deleteSupplier;
  $scope.validateNumbers = validateNumbers;
  $scope.validateEmail = validateEmail;
  $scope.checkSupplierRequesterRole = checkSupplierRequesterRole;
  $scope.updateSupplierStatus = updateSupplierStatus;
  $scope.showVersionList = showVersionList;
  $scope.downloadAttachment = downloadAttachment;
  $scope.addAttachment = addAttachment;
  $scope.deleteAttachment = deleteAttachment;
  $scope.canWithdraw = canWithdraw;
  $scope.canDelete = canDelete;
  $scope.enableEdit = enableEdit;
  $scope.toggleUpdateAllContract = toggleUpdateAllContract;
  $scope.checkCommodityCode = checkCommodityCode;
  $scope.duplicationCheck = duplicationCheck;
  $scope.isStatus = isStatus;
  $scope.getPrefilterParam = getPrefilterParam;
  $scope.goToCompanyContract = goToCompanyContract;
  $scope.isTenantSuperAdmin = isTenantSuperAdmin;
  $scope.syncToBifrost = syncToBifrost;
  $scope.syncToKharon = syncToKharon;
  $scope.getApproveSupplierMessage = getApproveSupplierMessage;
  $scope.disableRestrictedFields = disableRestrictedFields;
  $scope.openSageIntegrationModal = openSageIntegrationModal;
  $scope.isKharonEnabled = isKharonEnabled;
  $scope.openCompanyContractModal = openCompanyContractModal;
  $scope.onSelectedSupplierType = onSelectedSupplierType;
  $scope.openSupplierUserModal = openSupplierUserModal;
  $scope.loadValidationRules = loadValidationRules;
  $scope.onSelectedLanguage = onSelectedLanguage;
  $scope.isV2Enabled = $rootScope.isV2Enabled;
  $scope.allowUpdateContractApi = true;
  $scope.isSourcingAdmin = isSourcingAdmin;
  $scope.isSupplierTypeAllow = isSupplierTypeAllow;
  $scope.addSupplierUser = addSupplierUser;
  $scope.removeSupplierUser = removeSupplierUser;

  function openSageIntegrationModal(){
    $uibModal.open({
      templateUrl: 'app/suppliersManagement/sageIntegrationModal/sageIntegrationModal.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'sageIntegrationModalCtrl',
      size: 'lg',
      resolve: {
        companies: function($q, searchModule){
          var deferred = $q.defer();
          var params = {
            module: 'companies',
            offset: 20,
            'criteria[0][config|integration_solution]': 'MB-SAGE-ESKER',
            criteria_operator: 'or',
            order_by: 'descr',
            order: 1
          }

          searchModule.get(
            params,
            function (resource) {
              if(!!resource && !!resource.content && !!resource.content.data){
                deferred.resolve(resource.content);
              } else {
                deferred.resolve({data: []});
              }
            },
            function () {
              deferred.resolve({data: []});
            }
          );

          return deferred.promise;
        },
        supplierMapping: function ($q, supplierMappingList) {
          var deferred = $q.defer();
          supplierMappingList.get(
            {
              supplier_code: $scope.supplier.basic_info.code
            },
            function (resource) {
              deferred.resolve(resource.content || []);
              $scope.buttonClicked = false;
            },
            function (error) {
              deferred.resolve([]);
              $scope.buttonClicked = false;
            }
          );
          return deferred.promise;
        },
        supplier: function (){
          return $scope.supplier;
        },
        action: function(){
          return 'update';
        }
      }
    })
  }

  /**
   * function to enable editing
   */
  function enableEdit() {
    var deferred = $q.defer();
    //TODO: instead of a button switch them into templates (Ahmed Saleh, 24/7/2019)
    $scope.editClicked = true;
    $scope.approvalStatusInfo.current = 'EDITING'; //To change the status to editing
    if ($scope.supplier.basic_info.status === 'WITHDRAWN' || $scope.supplier.basic_info.status === 'REJECTED') {
      $scope.isResubmit = true;
    }
    deferred.resolve('done');
    $scope.supplierCopy = _.cloneDeep($scope.supplier);//make a deep copy without binding for checking whether changes have been made.
    $scope.allowUpdateContractApi = false;
    return deferred.promise;
  }

  function initializeRevisions() {
    $scope.supplierRevisions = [];
    if (!!$scope.supplier.revisions && $scope.supplier.revisions_merged) {
      $scope.supplierRevisions = $scope.supplier.revisions_merged;
    }

    if (isStatus($scope.supplier, 'ACTIVE_PENDING') && !!$scope.supplier.edit && supplierFunctions.pendingEditPersonal($scope.supplier)) {
      $scope.supplierRevisions.push($scope.supplier.edit_merged);
    }
  }

  /**
   * prepare the revisions and adding the main one for display
   * @returns {*}
   */
  function prepareRevisions(){
    if(!$scope.supplier.revisions_merged && !$scope.supplier.edit_merged) {
      return [];
    }

    var revisions = [];
    var supplier_current = _.cloneDeep($scope.supplier);
    if (!!$scope.supplier.edit_merged && !supplierFunctions.pendingEditCompanyContract($scope.supplier)) {
      var edit_merged = _.cloneDeep($scope.supplier.edit_merged);

      revisions.push(edit_merged);
      supplier_current = supplierFunctions.getSupplierBackup();
    }

    revisions.push(supplier_current);

    if (!!$scope.supplier.revisions_merged) {

      revisions = revisions.concat(supplier_current.revisions_merged);
    }

    return revisions;
  }

  function checkCommodityCode() {
    if (!!$scope.supplier.primary_commodity && !!$scope.supplier.secondary_commodity) {
      if ($scope.supplier.primary_commodity.code === $scope.supplier.secondary_commodity.code) {
        return true;
      }
    }
    return false;
  }

  function duplicationCheck(model, field, value, checker) {
    globalFunc.duplicationCheck(model, field, value, checker, function (callback) {
      switch (callback) {
        case 'rocCheckTrue':
          $scope.rocCheck = true;
          break;
        case 'rocCheckFalse':
          $scope.rocCheck = false;
          break;
        case 'supplierDescrCheckTrue':
          $scope.descrCheck = true;
          break;
        case 'supplierDescrCheckFalse':
          $scope.descrCheck = false;
          break;
      }
    });
  }

  function showVersionList() {
    $uibModal.open({
      templateUrl: 'app/suppliersManagement/versions/supplier-versions.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        supplierVersions: function () {
          return prepareRevisions();
        },
        supplierStatus: function () {
          return $scope.supplier.basic_info.status;
        }
      },
      controller: 'supplierVersionsController'
    });
  }

  function validateEmail(email) {
    return globalFunc.emailValidation(email);
  }

  function validateNumbers(elementId, fieldName) {
    $scope[fieldName] = globalFunc.numberFormatValidation(elementId);
  }

  function selectedBumiputra(value) {
    $scope.supplier.default_contract.bumi_status = value;
    $scope.noBumiStatus = false;
  }

  function onSelectedTaggedToCompany(company) {
    $scope.supplier.selectedCompany = {
      code: $scope.supplier.default_contract.tagged_to_company_code
    };
    $scope.supplier.selectedCompany = company;
  }

  function onSelectedSupplierType(supplierType) {
    $scope.selectedSupplierType = supplierType;
    $scope.supplier.basic_info.is_sourcing_supplier = $scope.selectedSupplierType.value;
  }

  function onSelectedLanguage(language) {
    $scope.selectedLanguage = language;
  }

  function onUpdatedContact(contact, index) {
    $scope.supplier.default_contract.contacts.splice(index, 1, contact);
  }

  function removeContact(index) {
    $scope.supplier.default_contract.contacts.splice(index, 1);
  }

  function onAddedContact(contact) {
    $scope.supplier.default_contract.contacts.splice($scope.supplier.default_contract.contacts.length, 0, contact);
  }

  function isTenantSuperAdmin() {
    return globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN');
  }

  function isSourcingAdmin() {
    var isSourcingMetabuyerAdmin = !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Sourcing Metabuyer Admin');
    var isSourcingTenantAdmin = !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Sourcing Tenant Admin');
    var isSourcingBiddingAdmin = !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Sourcing and Bidding Admin');
    return isSourcingMetabuyerAdmin || isSourcingTenantAdmin || isSourcingBiddingAdmin;
  }

  function isSupplierTypeAllow() {
    if (!!$scope.isV2Enabled) {
      return true;
    }
    var supplierTypes = supplierFunctions.getSupplierTypes(true, $rootScope.currentUser);
    var isP2OSupplier = $scope.selectedSupplierType.isP2OSupplier;
    var isSourcingSupplier = $scope.selectedSupplierType.isSourcingSupplier;
    var isAllow = false;
    for(var supplierTypeIndex in supplierTypes){
      if (isP2OSupplier === supplierTypes[supplierTypeIndex].isP2OSupplier &&
          isSourcingSupplier === supplierTypes[supplierTypeIndex].isSourcingSupplier) {
        isAllow = true;
      }
    }
    return isAllow;
  }

  function allowEditingSupplier() {
    var isAdmin = globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Admin');
    var isSupplierManager = globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Supplier Manager');
    var isSupplierRequester = globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Supplier Requester');
    var isSourcingRequestor = globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Sourcing Requestor');
    var isBiddingRequestor = globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Bidding Requestor');
    var isSupplierApprover = !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Supplier Approver') && !$scope.isV2Enabled;
    var catalog = $scope.supplier.has_catalog;

    // Allow editing if it's withdrawn + the supplier is self created
    var isWithdrawn = ($scope.supplier.basic_info.status === 'WITHDRAWN');
    var isSelfCreated = ($scope.supplier.created_by === $rootScope.currentUser._id);

    return isAdmin ||
      isTenantSuperAdmin() ||
      isSupplierManager ||
      isSourcingRequestor ||
      isBiddingRequestor ||
      isSupplierApprover ||
      (!catalog ? isSupplierRequester : null) ||
      (isWithdrawn && isSelfCreated);
  }

  function validatePrimaryBusinessNature(businessNature) {
    $scope.supplier.default_contract.primary_business_nature = businessNature.shortDescr;

    if (angular.isDefined($scope.supplier.default_contract.secondary_business_nature)) {
      if (($scope.supplier.default_contract.secondary_business_nature === $scope.supplier.default_contract.primary_business_nature)) {
        toastr.error('Primary business nature cannot be the same as secondary business nature');
        $scope.validatedBN = true;
      } else {
        $scope.validatedBN = false;
      }
    }
  }

  function validateSecondaryBusinessNature(businessNature) {
    $scope.supplier.default_contract.secondary_business_nature = businessNature.shortDescr;

    if (angular.isDefined($scope.supplier.default_contract.primary_business_nature)) {
      if (($scope.supplier.default_contract.primary_business_nature === $scope.supplier.default_contract.secondary_business_nature)) {
        toastr.error('Primary business nature cannot be the same as secondary business nature');
        $scope.validatedBN = true;
      } else {
        $scope.validatedBN = false;
      }
    }
  }

  /** Search for the commodity code*/
  function searchPrimaryCommodityCode(keyword) {
    if (!!keyword && keyword.length > 1) {
      searchAllCommodity.get({
        keywords: keyword
      }, function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data)
          $scope.primaryCommodityCodes = resource.content.data;
      }, function () {
        $scope.primaryCommodityCodes = [];
      });

      return $scope.primaryCommodityCodes;
    }
  }

  function searchSecondaryCommodityCode(keyword) {
    if (!!keyword && keyword.length > 1) {
      searchAllCommodity.get({
        keywords: keyword
      }, function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data)
          $scope.secondaryCommodityCodes = resource.content.data;
      }, function () {
        $scope.secondaryCommodityCodes = [];
      });

      return $scope.secondaryCommodityCodes;
    }
  }

  function openChildCommodityCodePrimary(cc) {
    listChild.get({
        code: cc
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data.length > 0) {
          $scope.primaryCommodityCodes = resource.content.data;
        } else {
          toastr.error('There are no child on this commodity code');
        }
      },
      function () {
        toastr.error('Failed to get commodity codes data');
      })
  }

  function openChildCommodityCodeSecondary(cc) {
    listChild.get({
        code: cc
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data.length > 0) {
          $scope.secondaryCommodityCodes = resource.content.data;
        } else {
          toastr.error('There are no child on this commodity code');
        }
      },
      function () {
        toastr.error('Failed to get commodity codes data');
      })
  }

  function removeSecondaryCommodity() {
    $scope.supplier.secondary_commodity = null;
    $scope.secondaryCommodityKeyword = null;
  }

  function selectReceivingMode(poMethod) {
    if (poMethod === 0)
      return;

    if ([2, 6, 10, 14].indexOf(poMethod) > -1)
      $scope.receive_via_email = true;

    if ([4, 6, 12, 14].indexOf(poMethod) > -1)
      $scope.receive_via_fax = true;

    if ([8, 10, 12, 14].indexOf(poMethod) > -1)
      $scope.receive_via_post = true;
  }

  selectReceivingMode($scope.supplier.default_contract.receiving_mode);


  function getPaidUpCategory() {
    $scope.paidUpCategoryDescr = supplierFunctions.getPaidUpCategory($scope.supplier.basic_info.paid_up_capital);
  }

  //TODO: break this function so the transition makes sense (Ahmed Saleh, 27/6/2019)
  function back() {
    if (!!$scope.supplier && !!$scope.supplier._id) {
      HighlightId.setId($scope.supplier._id);
    }
    if ($scope.supplier.basic_info.status === 'ACTIVE_PENDING') {
      $state.go('main.user.supplier.manage', {status: 'pending'})
    } else {
      $state.go('main.user.supplier.manage', {status: $scope.supplier.basic_info.status.toLowerCase()});
    }
  }

  function onUpdatedCountry(country) {
    $scope.supplier.basic_info.corresponding_address.state = undefined;
    if (!!country.descr) {
      $scope.supplier.basic_info.corresponding_address.country = country;
      $scope.selectedMalaysia = (country.descr === 'Malaysia');
    }

    if (!$scope.selectedMalaysia) {
      $scope.supplier.basic_info.corresponding_address.state  = null;
    }

    try {
      $('#editSupplierCompanyFax').intlTelInput('setCountry', country.code.toLowerCase());//update country in Fax field
      $('#editSupplierCompanyPhone').intlTelInput('setCountry', country.code.toLowerCase());//update country in Company phone field
    } catch (e) {
      // ignore the error for some country that does not exist in the plugin
    }
  }

  function onUpdatedState(state) {
    if (!!$scope.selectedMalaysia) {
      $scope.supplier.basic_info.corresponding_address.state = state;
    }
  }

  function checkHyphenFirstCharacter(string) {
    if (!!string) {
      $scope.descrFirstCharHyphen = supplierFunctions.checkHyphenFirstCharacter(string);
    }
    return $scope.descrFirstCharHyphen;
  }

  function validation() {
    if ($scope.isSourcingSupplierEnabled) {
      if (_.isEmpty($scope.selectedSupplierType)) {
        return false;
      }

      if (!!$scope.selectedSupplierType.isSourcingSupplier) {
        var supplierUserValid = true;
        var checkDuplicate = [];
        _.forEach($scope.supplierUsers, function (user) {
          if (!user.display_name || !user.display_name.length) {
            supplierUserValid = false;
            return;
          }

          if (!user.email || !user.email.length) {
            supplierUserValid = false;
            return;
          }

          if (user.isExistingEmail) {
            supplierUserValid = false;
            return;
          }

          if (!user.isValidPhone) {
            supplierUserValid = false;
            return;
          }

          if (!user.currency || !user.currency.length) {
            supplierUserValid = false;
            return;
          }

          if (!user.config || !user.config.TZ.length) {
            supplierUserValid = false;
            return;
          }
          if (checkDuplicate.includes(user.email)) {
            supplierUserValid = false;
            return;
          }
          checkDuplicate.push(user.email);
        });
        if (!supplierUserValid) {
          return false;
        }
      }
    }

    if (angular.isUndefined($scope.supplier.default_contract.bumi_status) && $scope.mandatoryRules.bumi_status) {
      $scope.noBumiStatus = true;
      return false;
    }
    if ($scope.validatedBN === true) {
      return false;
    }
    if (checkHyphenFirstCharacter($scope.supplier.basic_info.descr, 'descr')) {
      return false;
    }
    if (!$scope.supplier.basic_info.descr || !$scope.supplier.basic_info.descr.length) {
      return false;
    }
    if (!$scope.supplier.basic_info.reg_no || $scope.supplier.basic_info.reg_no.length < 3) {
      return false;
    }
    if (!$scope.supplier.selectedTax && $scope.mandatoryRules.tax_code) {
      return false;
    }
    if (angular.isDefined($scope.supplier.selectedTax) && !!$scope.supplier.selectedTax) {
      if (($scope.supplier.selectedTax.code !== 'NR' && $scope.supplier.selectedTax.code !== 'NRC' && $scope.supplier.selectedTax.code !== 'NT0') &&
        (!$scope.supplier.basic_info.gst_code || $scope.supplier.basic_info.gst_code.length < 3) && $scope.mandatoryRules.gst_code) {
        return false;
      }
    }
    if (!$scope.supplier.primary_commodity && $scope.mandatoryRules.primary_commodity_code) {
      return false;
    }
    if (!$scope.supplier.default_contract.primary_business_nature && $scope.mandatoryRules.primary_business_nature) {
      return false;
    }
    if (!$scope.receive_via_email && !$scope.receive_via_fax && !$scope.receive_via_post && $scope.mandatoryRules.receiving_mode) {
      return false;
    }
    if (!$scope.supplier.selectedBusinessEntity && $scope.mandatoryRules.business_entity) {
      return false;
    }
    if (!$scope.supplier.selectedPaymentTerms && $scope.mandatoryRules.payment_term_code) {
      return false;
    }
    if (!$scope.supplier.selectedCurrency && $scope.mandatoryRules.currency_code) {
      return false;
    }
    if (!$scope.isValidPhoneNo) {
      return false;
    }
    if (!$scope.isValidFaxNo) {
      return false;
    }
    if (!$scope.supplier.basic_info.phone || !$scope.supplier.basic_info.phone.length) {
      return false;
    }

    if(!!$scope.receive_via_email){
      if (!$scope.supplier.basic_info.email || !$scope.supplier.basic_info.email.length) {
        return false;
      }
    }

    if (!!$scope.receive_via_fax) {
      if (!$scope.supplier.basic_info.fax || !$scope.supplier.basic_info.fax.length) {
        return false;
      }
    }

    if ( !$scope.supplier.basic_info.corresponding_address.state && $scope.mandatoryRules.state) {
      return false;
    }

    if (!$scope.supplier.basic_info.corresponding_address || !$scope.supplier.basic_info.corresponding_address.line1 || !$scope.supplier.basic_info.corresponding_address.city || !$scope.supplier.basic_info.corresponding_address.country || !$scope.supplier.basic_info.corresponding_address.postal_code) {
      return false;
    }
    if (!$scope.supplier.default_contract.contacts || !$scope.supplier.default_contract.contacts.length && $scope.mandatoryRules.contacts) {
      return false;
    }
    if (_.isEmpty($scope.selectedLanguage) && $scope.mandatoryRules.language) {
      return false;
    }
    return true;
  }

  function loadApprovalFlow() {
    var approvalId;

    if (!$scope.supplier || !$scope.supplier.approval_id) {
      $scope.approvals = [];
    }

    approvalId = supplierFunctions.getApprovalId($scope.supplier);

    Approvals.get(
      {
        id: approvalId
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.approvals = resource.content.data;
          $scope.approvalFlowData = resource.content.data;
          globalFunc.getWaitingOnUser(resource.content.data, $scope.waitingOnUser);
        } else {
          $scope.approvals = [];
        }
      },
      function () {
        toastr.error('Error loading Approval Flow');
        $scope.approvals = [];
      });
  }

  function loadValidationRules() {
    var type = 'P2O Supplier';

    if (!!$rootScope.isV2Enabled) {
      type = 'v2Enabled';
    } else if ($scope.isSourcingSupplierEnabled) {
      if (!_.isEmpty($scope.selectedSupplierType)) {
        type = $scope.selectedSupplierType.descr;
      }
    }

    $scope.mandatoryRules = supplierFunctions.getMandatoryValidationRules(type);

    if (!!$scope.isSourcingSupplierEnabled) {
      $scope.mandatoryRules['language'] = true;
    }
  }

  function canWithdraw() {
    var checkSupplierStatus = ['PENDING', 'ACTIVE_PENDING'].indexOf($scope.supplier.basic_info.status) > -1;

    var isSubmitter = (!!$scope.supplier.submission_info && $scope.supplier.submission_info.submitter) &&
      $scope.supplier.submission_info.submitter._id === $scope.currentUser._id;

    if (supplierFunctions.pendingEditCompanyContract($scope.supplier)) {
      // hardcoded key to [0]. Currently only 1 company_contract edit can exist at a time
      isSubmitter = supplierFunctions.pendingEditCompanyContract($scope.supplier) &&
        $scope.supplier.edit.company_contracts[0].submission_info.submitter._id === $scope.currentUser._id;
    }

    return (checkSupplierStatus && isSubmitter);
  }

  function loadApprovalStatusInfo() {
    // if status is ACTIVE_PENDING, approval id should be from 'edit.personal' or 'edit.status'
    if (isStatus($scope.supplier, 'ACTIVE_PENDING')) {
      if ($scope.isEditCompanyContract) {
        isEditCompanyContract();
      } else if ($scope.isEditStatus) {
        isEditStatus('ACTIVE_PENDING');
      } else if ($scope.isEditPersonal) {
        isEditPersonal();
      }
      $scope.approvalStatusInfo.current = 'PENDING';

      // if status is PENDING, approval id is from either 'edit.status' or top level in supplier object
    } else if (isStatus($scope.supplier, 'PENDING')) {
      if ($scope.isEditStatus) {
        isEditStatus('PENDING');
      } else if ($scope.isEditPersonal) {
        isEditPersonal();
      } else {
        $scope.approvalStatusInfo.original = 'CREATE';
        $scope.approvalStatusInfo.new = 'ACTIVE';
      }
      $scope.approvalStatusInfo.current = $scope.supplier.basic_info.status;
    } else if (isStatus($scope.supplier, 'INACTIVE')) {
      $scope.approvalStatusInfo.current = 'DEACTIVATED';
    } else if (isStatus($scope.supplier, 'EDITING')) {
      $scope.approvalStatusInfo.current = 'PROCESSING';
    } else {
      $scope.approvalStatusInfo.current = $scope.supplier.basic_info.status;
    }
    var translateArr = [
      'statuses.all',
      'statuses.active',
      'statuses.pending',
      'statuses.rejected',
      'statuses.withdrawn',
      'statuses.deactivated',
    ];
    $scope.statusDesc = $scope.approvalStatusInfo.current;
    $translate(translateArr).then(function (trans) {
      $scope.statusDesc = trans["statuses." + $scope.statusDesc.toLowerCase()];
    });
  }

  function checkWaitingOn() {
    var find_user = _.find($scope.waitingOnUser, function (approver) {
      return (approver._id === profile._id);
    });

    if (find_user !== undefined) {
      return true;
    }
    return false;
  }

  function checkSupplierRequesterRole() {
    var isSupplierRequester = globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments,
      'Supplier Requester');

    return isSupplierRequester;
  }

  /**
   * Function to approve supplier to move to next status
   */
  function approve() {
    var statusReason = $scope.statusUpdatePayload.status_reason;
    var approvalId = supplierFunctions.getApprovalId($scope.supplier);

    return approvalSupplier.post(
      {
        approval_id: approvalId,
        status: 'approve'
      },
      {
        comment: statusReason,
        attachment_id: (!!$scope.statusUpdatePayload.attachment) ? $scope.statusUpdatePayload.attachment._id : null
      },
      function () {
        $translate("messages.supplierApproved").then(function (trans) {
          toastr.success(trans);
        });
        HighlightId.setId($scope.supplier._id);
        $state.go('main.user.supplier.manage', {status: 'active'});
      }, function () {
        toastr.error('Supplier cannot be approved.');
      }
    ).$promise;
  }

  function reject() {
    var statusReason = $scope.statusUpdatePayload.status_reason;

    var approvalId = supplierFunctions.getApprovalId($scope.supplier);
    return approvalSupplier.post(
      {
        approval_id: approvalId,
        status: 'reject'
      },
      {
        comment: statusReason,
        attachment_id: (!!$scope.statusUpdatePayload.attachment) ? $scope.statusUpdatePayload.attachment._id : null
      },
      function () {
        $translate("messages.supplierRejected").then(function (trans) {
          toastr.success(trans);
        });
        HighlightId.setId($scope.supplier._id);
        $state.go('main.user.supplier.manage', {status: 'rejected'});
      }, function () {
        toastr.error('Supplier cannot be rejected.');
      }
    ).$promise;
  }

  function withdrawSupplier() {
    var approvalId = supplierFunctions.getApprovalId($scope.supplier);

    var successHandler = function () {
      toastr.success('Successfully withdrawn');
      $state.go('main.user.supplier.manage', {status: 'active'});
    };

    var editStatusSuccessHandler = function () {
      toastr.success('The supplier status update is withdrawn');
      HighlightId.setId($scope.supplier._id);
      $state.go('main.user.supplier.manage', {status: 'active'});
    };

    if ($scope.isEditCompanyContract) {
      var companyContractChanges = $scope.supplier.edit.company_contracts[0];
      var companyCode = companyContractChanges.company.company_code;
      var cacheKey = $scope.supplier.basic_info.code + companyCode;
      window.localStorage.setItem(cacheKey, JSON.stringify(companyContractChanges));

      successHandler = function () {
        toastr.options = {timeOut: 10};
        toastr.success(
          'Successfully withdrawn company supplier update. Go to the company supplier to restore ' +
          'the changes and resubmit'
        );
        $scope.supplier = supplierFunctions.getSupplierBackup();
        $scope.supplier.basic_info.status = 'ACTIVE';
        $scope.supplier.edit = undefined;
        initialize();
        $scope.preFilterParam = "";
      };
    } else if ($scope.isEditPersonal) {
      var originalStatus = $scope.supplier.edit.personal.original_status;

      if (originalStatus !== 'ACTIVE') {
        successHandler = editStatusSuccessHandler;
      } else {
        successHandler = function () {
          $translate("messages.supplierUpdateWithdrawnDiscardChange").then(function (trans) {
            toastr.success(trans);
          });
          supplierFunctions.resetSupplierObj();
          $scope.supplier = supplierFunctions.getSupplier();
          initialize();
          $scope.editClicked = true;
        }
      }
    } else if ($scope.isEditStatus) {
      successHandler = editStatusSuccessHandler;
    } else if ($scope.supplier.submission_info.action === 'CREATE') {
      successHandler = function () {
        toastr.success('The supplier has been successfully withdrawn');
        HighlightId.setId($scope.supplier._id);
        $state.go('main.user.supplier.manage', {status: 'active'});
      }
    }

    approvalSupplier.post(
      {
        approval_id: approvalId,
        status: 'withdraw'
      },
      {},
      successHandler(),
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function updateSupplierStatus(status) {
    return supplierStatus.put(
      {
        id: $scope.supplier._id
      },
      {
        status: status,
        status_reason: $scope.statusUpdatePayload.status_reason,
        attachment_id: (!!$scope.statusUpdatePayload.attachment) ? $scope.statusUpdatePayload.attachment._id : ''
      },
      function (res) {
        toastr.success(res.message);
        $state.go('main.user.supplier.manage', {status: 'active'});
      },
      function (error) {
        if (error.data.content.error) {
          toastr.error(error.data.content.error);
        } else {
          toastr.error(error.data.message);
        }
      }
    ).$promise
  }

  /**
   * Open modal to add attachment
   */
  function addAttachment() {
    var modalInstance = $uibModal.open({
      templateUrl: 'app/suppliersManagement/details/addAttachment/addSupplierAttachment.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        supplier: $scope.supplier
      },
      controller: 'addSupplierAttachmentCtrl'
    });
    modalInstance.result.then(function (newAttachment) {
      if (!!newAttachment) {
        $scope.attachments.push(newAttachment);
      }
    });
  }

  /**
   * Delete attachment
   *
   * @param attachmentId attachment id
   */
  function deleteAttachment(attachmentId) {
    if (!!attachmentId) {
      supplierAttachment.deleteAttachment({
        supplier_id: $scope.supplier._id,
        attachment_id: attachmentId
      }, function (resource) {
        _.remove($scope.attachments, function(attachment) {
          return attachment.attachment_id === attachmentId;
        });
        toastr.success('Attachment deleted')
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * Download attachment
   *
   * @param {object} attachmentId
   */
  function downloadAttachment(attachmentId) {
    if (!!attachmentId) {
      singleAttachment.get({
        attachmentId: attachmentId
      }, function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data)
          getAttachmentFile(resource.content.data.temporary_url);
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * Get the attachment file
   *
   * @param {string} url
   */
  function getAttachmentFile(url) {
    var newUrl = $filter('format')(pathConstants.apiUrls.attachments.download, {
      hash: url
    });
    $http({
      url: newUrl
    }).then(function () {
      window.open(newUrl, '_self');
    }, function (error) {
      globalFunc.objectErrorMessage(error);
    });
  }

  /**
   * Replaces $scope.supplier with edit_merged if supplier' status is ACTIVE_PENDING
   */
  function checkSupplierActivePending() {
    var editStatus = ['BLACKLISTED', 'INACTIVE'];
    var isUnblacklistOrReactivate =
      ($scope.supplier.basic_info.status === 'PENDING' &&
        !!$scope.supplier.edit &&
        !!$scope.supplier.edit.personal &&
        (editStatus.indexOf($scope.supplier.edit.personal.original_status) > -1)
      );

    if (($scope.supplier.basic_info.status === 'ACTIVE_PENDING' && !$scope.isEditCompanyContract) ||
      isUnblacklistOrReactivate)
      supplierFunctions.replaceSupplierEditedFields();
  }

  function loadCompaniesContract() {

    var embeddedParams = {
      id: _.isEmpty($scope.supplier._id) ? 0 : $scope.supplier._id,
      disable_paginate: 1
    };

    supplierFunctions.getCompanyContracts(embeddedParams).then(function (success) {
      $scope.companies = success.data;
    });
  }

  function openCompanyContractModal() {

    if (!_.isEmpty($scope.companies)) {
      var modalInstance = $uibModal.open({
        templateUrl: 'app/suppliersManagement/companyContract/companyContract.html',
        backdrop: 'static',
        keyboard: false,
        controller: 'companyContractModalCtrl',
        size: 'lg',
        resolve: {
          companies: function () {
            return $scope.companies;
          },
          companyContracts: function () {
            return [];
          },
          supplierData: function (){
            return $scope.supplier;
          },
          editClicked: function () {
            return $scope.editClicked
          },
          draftId: function () {
            return null;
          },
          allowUpdateContractApi: function () {
            return $scope.allowUpdateContractApi;
          },
        }
      });
    }else{
      return false;
    }

    modalInstance.result.then(function (newValue) {
      if (!_.isEmpty(newValue)) {
        $scope.supplier.company_contracts = {
          company_codes: newValue
        }
      }
    }, function () {
    });
  }

  function openSupplierUserModal() {
    var modalInstance = $uibModal.open({
      templateUrl: 'components/supplierComponents/supplierUserDetail/supplierUserDetailModal.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'supplierUserDetailModalCtrl',
      size: 'lg',
      resolve: {
        currencies: function () {
          return $scope.currencies;
        },
        timezones: function () {
          return $scope.timezones;
        },
        user: function (){
          return {
            display_name: '',
            email: '',
            phone: ''
          };
        },
        supplier: function () {
          return $scope.supplier;
        },
      }
    });

    modalInstance.result.then(function (toRefresh) {
      if(!!toRefresh) {
        $state.reload();
      }
    }, function () {
    });
  }

  function initialize() {
    $scope.isV2FeatureEnabled = $rootScope.isV2Enabled;
    $scope.supplier.update_all_contracts = true;
    $scope.isEditPersonal = supplierFunctions.pendingEditPersonal($scope.supplier);
    $scope.isEditStatus = supplierFunctions.pendingEditStatus($scope.supplier);
    $scope.isEditCompanyContract = supplierFunctions.pendingEditCompanyContract($scope.supplier);

    if ($scope.isEditCompanyContract) {
      $scope.preFilterParam = getPrefilterParam();
    }

    $scope.isV2FeatureEnabled = $rootScope.isV2Enabled;

    //TODO: move assignment and check of data to a module level (Ahmed Saleh, 18/6/2019)
    supplierFunctions.setSupplier($scope.supplier);
    supplierFunctions.setSupplierBackup($scope.supplier);

    // THIS REPLACES ALL FIELDS WITH VALUES FROM EDIT MERGE IF STATUS IS ACTIVE_PENDING
    checkSupplierActivePending();

    initializeRevisions();
    supplierFunctions.setCurrencies($scope.currencies);
    supplierFunctions.setPaymentTerms($scope.paymentTerms);
    supplierFunctions.setPaymentMethods($scope.paymentMethods);
    supplierFunctions.setTaxes($scope.taxes);
    supplierFunctions.setTaggedCompanies($scope.companies);
    getPaidUpCategory();

    $scope.branches = [];
    if (angular.isDefined($scope.supplier.branches)) {
      $scope.branches = $scope.supplier.branches;
    }

    if ((angular.isDefined($scope.supplier.edit_merged) && $scope.supplier.edit_merged !== null) && $scope.supplier.edit_merged.branches) {
      $scope.branches = _.values($scope.supplier.edit_merged.branches);
    }

    $scope.supplier.selectedCurrency = null;
    if (!!$scope.supplier && !!$scope.supplier.default_contract.currency_code) {
      var currCode = _.find($scope.currencies, function (item) {
        return $scope.supplier.default_contract.currency_code == item.code;
      });
      if (!!currCode) {
        $scope.supplier.selectedCurrency = currCode;
      }
    }
    /** To initialize business nature by looping and comparing the short description */
    if (angular.isDefined($scope.supplier.default_contract.primary_business_nature)) {
      for (var i = 0; i < $scope.businessNatures.length; i++) {
        if ($scope.businessNatures[i].shortDescr === $scope.supplier.default_contract.primary_business_nature) {
          $scope.supplier.selectedPrimaryBusinessNature = $scope.businessNatures[i];
        }
      }
    } else {
      $scope.supplier.selectedPrimaryBusinessNature = !$scope.isV2FeatureEnabled ? $scope.businessNatures[0] : null;
    }

    if (angular.isDefined($scope.supplier.default_contract.secondary_business_nature)) {
      for (var j = 0; j < $scope.businessNatures.length; j++) {
        if ($scope.businessNatures[j].shortDescr === $scope.supplier.default_contract.secondary_business_nature) {
          $scope.supplier.selectedSecondaryBusinessNature = $scope.businessNatures[j];
        }
      }
    } else {
      $scope.supplier.selectedSecondaryBusinessNature = !$scope.isV2FeatureEnabled ? $scope.businessNatures[0] : null;
    }

    if (angular.isDefined($scope.supplier.basic_info.business_entity)) {
      for (var k = 0; k < $scope.businessEntities.length; k++) {
        if ($scope.businessEntities[k].shortDescr === $scope.supplier.basic_info.business_entity) {
          $scope.supplier.selectedBusinessEntity = $scope.businessEntities[k];
        }
      }
    } else {
      $scope.supplier.selectedBusinessEntity = !$scope.isV2FeatureEnabled ? $scope.businessEntities[0] : null;
    }


    if (angular.isDefined($scope.supplier.basic_info.corresponding_address.country)) {
      $scope.supplier.basic_info.corresponding_address.country =
        globalFunc.findInArray($scope.countries, 'descr', $scope.supplier.basic_info.corresponding_address.country);
      $scope.selectedMalaysia = ($scope.supplier.basic_info.corresponding_address.country.descr === 'Malaysia');
    }

    if (angular.isDefined($scope.supplier.basic_info.corresponding_address.state)) {
      if (!!$scope.selectedMalaysia) {
        $scope.supplier.basic_info.corresponding_address.state =
          $scope.supplier.basic_info.corresponding_address.state ?
          $scope.supplier.basic_info.corresponding_address.state.replace("Wp ", "WP ") : '';
        $scope.supplier.basic_info.corresponding_address.state =
          globalFunc.findInArray($scope.myStates, 'descr', $scope.supplier.basic_info.corresponding_address.state);
      }
    }

    $scope.supplier.selectedPaymentTerms = null;
    if (!!$scope.supplier && !!$scope.supplier.default_contract.payment_term_code) {
      $scope.supplier.selectedPaymentTerms =
        globalFunc.findInArray($scope.paymentTerms, 'code', $scope.supplier.default_contract.payment_term_code);
    }

    $scope.supplier.selectedPaymentMethod = null;
    if (!!$scope.supplier && !!$scope.supplier.default_contract.payment_method_code) {
      $scope.supplier.selectedPaymentMethod =
        globalFunc.findInArray($scope.paymentMethods, 'code', $scope.supplier.default_contract.payment_method_code);
    }

    $scope.supplier.selectedTax = null;
    if (!!$scope.supplier && !!$scope.supplier.default_contract.tax_code) {
      $scope.supplier.selectedTax =
        globalFunc.findInArray($scope.taxes, 'code', $scope.supplier.default_contract.tax_code, true);
    }

    $scope.supplier.selectedCompany = null;
    if (!!$scope.supplier && !!$scope.supplier.basic_info.tagged_to_company_code) {
      $scope.supplier.selectedCompany =
        globalFunc.findInArray($scope.companies, 'code', $scope.supplier.basic_info.tagged_to_company_code);
    }

    if (!!$scope.supplier && $scope.supplier.default_contract.primary_commodity_code) {
      $scope.supplier.primary_commodity = {
        code: $scope.supplier.default_contract.primary_commodity_code,
        descr: $scope.supplier.default_contract.primary_commodity_descr
      };
      $scope.primaryCommodityKeyword = $scope.supplier.default_contract.primary_commodity_code + ' ' + $scope.supplier.default_contract.primary_commodity_descr;
    }

    if (!!$scope.supplier && $scope.supplier.default_contract.secondary_commodity_code) {
      $scope.supplier.secondary_commodity = {
        code: $scope.supplier.default_contract.secondary_commodity_code,
        descr: $scope.supplier.default_contract.secondary_commodity_descr
      };
      $scope.secondaryCommodityKeyword = $scope.supplier.default_contract.secondary_commodity_code + ' ' + $scope.supplier.default_contract.secondary_commodity_descr;
    }

    if (!!$scope.supplier.basic_info.descr) {
      $scope.supplier.basic_info.descr = $scope.supplier.basic_info.descr.toUpperCase();
    }

    // for displaying supplier attachments
    if (!!$scope.supplier.attachments) {
      $scope.attachments = [];
      $scope.supplier.attachments.forEach(function (attachment) {
        if (attachment.type === 'MAIN') {
          $scope.attachments.push(attachment);
        }
      })
    }

    if ($scope.supplier.basic_info.status === 'PENDING' || $scope.supplier.basic_info.status === 'ACTIVE_PENDING') {
      loadApprovalFlow();
    }

    loadApprovalStatusInfo();

    if (supplierFunctions.pendingEditCompanyContract($scope.supplier)) {
      // hardcoded key to [0]. Currently only 1 company_contract edit can exist at a time
      var company = $scope.supplier.edit.company_contracts[0].company;
      $scope.pendingCompanyContractName = company.company_descr;
    } else {
      $scope.pendingCompanyContractName = "";
    }

    exportSearch.setParams({'criteria[supplier_code]': $scope.supplier.basic_info.code});

    if (!!featureToggle['syncEngine']) {
      $scope.toggleList.kharon = featureToggle['syncEngine'].is_enabled;
    }

    if ($scope.isSourcingSupplierEnabled) {
      $scope.selectedSupplierType = supplierFunctions.findSupplierType($scope.supplier.basic_info);
      $scope.selectedLanguage = supplierFunctions.findLanguage($scope.supplier.basic_info.language);
    }
    if (!!$scope.supplier.basic_info.is_sourcing_supplier) {
      if(!_.isEmpty($scope.supplier.supplier_users)) {
        $scope.supplierUsers = $scope.supplier.supplier_users;
        var supplierUsers = [];
        var statusPending = 0;
        var statusActive = 1;
        var statusDraft = 5;
        _.forEach ($scope.supplier.supplier_users, function(supplierUser, index){
          if (supplierUser.status === statusPending ||
              supplierUser.status === statusActive ||
              supplierUser.status === statusDraft)
          {
            supplierUsers.push(supplierUser);
          }
        });
        $scope.supplierUsers = supplierUsers;
        $scope.supplier.supplier_users = supplierUsers;
      }
    }

    loadValidationRules();
    loadCompaniesContract();


    // encrypt reg_no
    if (!$rootScope.isV2Enabled) {
      $scope.supplier.basic_info.reg_no = atob($scope.supplier.basic_info.reg_no);
    }
  }

  function getPrefilterParam() {
    if (supplierFunctions.pendingEditCompanyContract($scope.supplier)) {
      // hardcoded key to [0]. Currently only 1 company_contract edit can exist at a time
      var company = $scope.supplier.edit.company_contracts[0].company;
      return company.company_descr;
    } else {
      return "";
    }
  }

  function syncToBifrost() {
    pushSupplierToBifrost.post({
        id: $scope.supplier._id
      }, function (resource) {
        toastr.success('Request to push supplier to Bifrost succeeded.');
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function syncToKharon() {
    pushToKharon.post({
        module: 'SUPPLIER',
        id: $scope.supplier._id
      }, function (resource) {
        toastr.success(resource.content.message);
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function isKharonEnabled() {
    return $scope.toggleList.kharon === true;
  }

  function getApproveSupplierMessage(supplier) {
    // This supplier will go through approval to be
    // {{ (!!supplier.original_status) ? supplier.original_status.toLowerCase() : supplier.basic_info.status.toLowerCase() }}"
    var newStatus = !!supplier.original_status ? supplier.original_status : supplier.basic_info.status;
    newStatus = (newStatus === 'ACTIVE_PENDING') ? 'PENDING' : newStatus;

    return 'This supplier will go through approval to be ' + newStatus.toLowerCase();
  }

  function goToCompanyContract() {
    var duration = 500;
    var offset = angular.element('#fixHeaderDirective')[0].offsetHeight + 100;
    var someElement = angular.element('#supplier-company-list');

    $document.scrollToElement(someElement, offset, duration);
  }

  // Disable restricted fields for non-admin and non-supplier manager
  function disableRestrictedFields() {
    return (
      $scope.editClicked &&  // Edit button is clicked
      checkSupplierRequesterRole() && // user is a supplier requester
      !isStatus($scope.supplier, 'WITHDRAWN') // supplier status is withdrawn
    )
  }

  function cancel() {
    swal(
      {
        title: 'Are you sure you want to leave this page?',
        text: 'Changes will be discarded',
        showCancelButton: true,
        confirmButtonColor: '#ff6666',
        confirmButtonText: 'Discard',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (isConfirm) {

          _.forEach($scope.companies, function (company) {
            company.selected = false;
          });

          $scope.editClicked = false;
          $scope.descrCheck = false;
          $scope.rocCheck = false;
          $scope.allowUpdateContractApi = true;
          supplierFunctions.resetSupplierObj();
          supplier.get(
            {
              id: $scope.supplier._id
            },
            function (resource) {
              $scope.supplier = resource.content.data;
              initialize();
            }, function (error) {

            }
          );
        }
      }
    );
  }



  function submitEditedSupplier(supplierObject) {
    var supplier = supplierFunctions.getSupplier();
    if ($scope.isResubmit) {
      swal({
          title: 'Confirm re-submit supplier?',
          text: 'This supplier will be submitted by you. Please state a reason.',
          showCancelButton: true,
          type: 'input',
          confirmButtonColor: '#1ab394',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true,
          closeOnCancel: true,
          inputType: 'text',
          inputPlaceholder: 'Write a reason'
        },
        function (reason) {
          if (reason !== false) {
            resubmitSupplier.post({
                id: supplier._id,
                comment: reason
              },
              supplierObject,
              function (resource) {
                toastr.success(resource.message);
                $state.go('main.user.supplier.manage', {status: 'active'});
              },
              function (error) {
                if (!!error.data && !!error.data.content && !!error.data.content.error) {
                  if (typeof error.data.content.error === 'string' &&
                    !globalFunc.hasJsonStructure(error.data.content.error)) {
                    toastr.error(error.data.content.error);
                  }
                  else {
                    globalFunc.laravelValidatorErrDisplay(JSON.parse(error.data.content.error));
                  }
                }
              }
            );
          }
        }
      );
    } else {
      swal({
          title: 'Confirm update supplier?',
          text: 'This supplier will be submitted by you. Please state a reason.',
          showCancelButton: true,
          type: 'input',
          confirmButtonColor: '#1ab394',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true,
          closeOnCancel: true,
          inputType: 'text',
          inputPlaceholder: 'Write a reason'
        },
        function (reason) {
          if (reason !== false) {
            updatedSupplierDetails.put({
                id: supplier._id,
                comment: reason,
                ori_status: supplierObject.original_status
              },
              supplierObject,
              function (resource) {
                $translate("messages.supplierUpdatedSuccessfully").then(function (trans) {
                  toastr.success(trans);
                });
                $state.go('main.user.supplier.manage', {status: 'active'});
              },
              function (error) {
                if (!!error.data && !!error.data.content && !!error.data.content.error) {
                  if (typeof error.data.content.error === 'string' &&
                    !globalFunc.hasJsonStructure(error.data.content.error)) {
                    toastr.error(error.data.content.error);
                  }
                  else {
                    globalFunc.laravelValidatorErrDisplay(JSON.parse(error.data.content.error));
                  }
                }
              }
            );
          }
        }
      );

    }
  }

  function toggleUpdateAllContract() {
    var translateArr = [
      "actionModalSupplier.updateAllCompanySuppliers.title",
      "actionModalSupplier.updateAllCompanySuppliers.message",
      "actionModalSupplier.disableAllCompanySuppliers.title",
      "actionModalSupplier.disableAllCompanySuppliers.message",
      "buttons.confirm",
      "buttons.cancel",
    ];
    $translate(translateArr).then(function (trans) {
      var title = trans["actionModalSupplier.updateAllCompanySuppliers.title"];
      var text = trans["actionModalSupplier.updateAllCompanySuppliers.message"];

      if ($scope.supplier.update_all_contracts){
        var title = trans["actionModalSupplier.disableAllCompanySuppliers.title"];
        var text = trans["actionModalSupplier.disableAllCompanySuppliers.message"];
      }

      swal({
          title: title,
          text: text,
          showCancelButton: true,
          confirmButtonColor: '#1ab394',
          confirmButtonText: trans["buttons.confirm"],
          cancelButtonText: trans["buttons.cancel"],
          closeOnConfirm: true,
          closeOnCancel: true
        },
        function (isConfirm) {
          if (!!isConfirm) {
            $scope.supplier.update_all_contracts = !$scope.supplier.update_all_contracts;
          }
        }
      );
    });
  }

  function updateSupplier(supplier) {
    if(noChangesMade(supplier)){
      $translate("messages.noChangesHaveBeenMade").then(function (trans) {
        toastr.error(trans);
      });
      return;
    }

    if (!validation(supplier)) {
      toastr.error('Please complete all required fields before proceeding');
      return;
    }

    if (!!$scope.isEditPersonal) {
      supplier.basic_info.status = supplier.edit.personal.original_status;
    }

    var supplierObject = preparePayload(supplier);

    submitEditedSupplier(supplierObject);
  }

  //TODO: SUPPLIER V2 CHECK THIS FUNCTION IS EVER CALLED | 11 JULY 19
  function submitChanges(supplier) {
    swal({
        title: 'Confirm submit supplier?',
        text: 'This supplier will be submitted by you',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (!!isConfirm) {
          submitEditedSupplier(supplier)
        }
      }
    );
  }

  function preparePayload(supplier) {
    var temp = 0;

    if ($scope.receive_via_email) {
      temp += 2;
    }
    if ($scope.receive_via_fax) {
      temp += 4;
    }
    if ($scope.receive_via_post) {
      temp += 8;
    }

    var stateDescr = '';
    if ($scope.selectedMalaysia && !!supplier.basic_info.corresponding_address.state) {
      stateDescr = supplier.basic_info.corresponding_address.state.descr;
    }

    var companyContractData  = [];
    if (!_.isEmpty(supplier.company_contracts))
      _.forEach(supplier.company_contracts, function (companyContract) {
        if (!_.isEmpty(companyContract.company) && !_.isEmpty(companyContract.company.company_code)) {
          companyContractData = [];
        } else {

          //Check the uuid if the company object is there but the code and id are blank. 
          //When companyContract is returned as a string, this block should be executed. not pick an object from the database
          if (!_.isEmpty(companyContract.uuid)) {
            companyContractData = [];
          } else {
            companyContractData = companyContract;
          }
        }
      });

    var supplierData = {
      basic_info: {
        descr: supplier.basic_info.descr,
        reg_no: supplier.basic_info.reg_no,
        old_reg_no: !!supplier.basic_info.old_reg_no ? supplier.basic_info.old_reg_no : undefined,
        gst_code: supplier.basic_info.gst_code || "",
        email: supplier.basic_info.email,
        phone: !!supplier.basic_info.phone ? supplier.basic_info.phone : undefined,
        fax: !!supplier.basic_info.fax ? supplier.basic_info.fax : undefined,
        business_entity: !!supplier.selectedBusinessEntity ? supplier.selectedBusinessEntity.shortDescr : undefined,
        paid_up_capital: !!supplier.basic_info.paid_up_capital ? supplier.basic_info.paid_up_capital : undefined,
        tagged_to_company_code: (!!supplier.selectedCompany && !!supplier.selectedCompany.code) ? supplier.selectedCompany.code : undefined,
        code: supplier.basic_info.code,
        corresponding_address: {
          line1: $scope.supplier.basic_info.corresponding_address.line1,
          line2: $scope.supplier.basic_info.corresponding_address.line2,
          line3: $scope.supplier.basic_info.corresponding_address.line3,
          postal_code: $scope.supplier.basic_info.corresponding_address.postal_code,
          city: $scope.supplier.basic_info.corresponding_address.city,
          state: $scope.selectedMalaysia ? stateDescr : supplier.basic_info.corresponding_address.state,
          country: $scope.supplier.basic_info.corresponding_address.country.descr
        }
      },
      default_contract: {
        currency_code: !! supplier.selectedCurrency ?supplier.selectedCurrency.code : undefined,
        tax_code: !!supplier.selectedTax ? supplier.selectedTax.code : undefined,
        receiving_mode: temp,
        payment_method_code: !!supplier.selectedPaymentMethod ? supplier.selectedPaymentMethod.code : undefined,
        payment_term_code: !!supplier.selectedPaymentTerms ? supplier.selectedPaymentTerms.code : undefined,
        primary_business_nature: !!supplier.default_contract.primary_business_nature ? supplier.default_contract.primary_business_nature : undefined,
        secondary_business_nature: !!supplier.default_contract.secondary_business_nature ?
          supplier.default_contract.secondary_business_nature : undefined,
        primary_commodity_code: !!supplier.primary_commodity ? supplier.primary_commodity.code : undefined,
        secondary_commodity_code: !!supplier.secondary_commodity ?
          supplier.secondary_commodity.code : undefined,
        bumi_status: !angular.isUndefined(supplier.default_contract.bumi_status) ? supplier.default_contract.bumi_status : undefined,
        contacts: !!supplier.default_contract.contacts ? supplier.default_contract.contacts : undefined
      },
      branches: $scope.branches,
      update_all_contracts: supplier.update_all_contracts,
      original_status: supplier.basic_info.status,
      company_contracts: {'company_codes': companyContractData}
    }

    if ($scope.isSourcingSupplierEnabled) {
      supplierData.basic_info.is_sourcing_supplier = $scope.supplier.basic_info.is_sourcing_supplier
      supplierData.basic_info.is_p2o_supplier = $scope.supplier.basic_info.is_p2o_supplier
      supplierData.basic_info.language = !_.isEmpty($scope.selectedLanguage) ? $scope.selectedLanguage.value : undefined;
      if (!!supplierData.basic_info.is_sourcing_supplier && !_.isEmpty($scope.supplierUsers)) {
        var supplierUsers = _.cloneDeep($scope.supplierUsers);
        _.forEach(supplierUsers, function(user) {
          delete user.isExistingEmail;
          delete user.isValidPhone;
          delete user.selectedCurrency;
        });
        supplierData.supplier_users = supplierUsers;
      }
    }

    return supplierData;
  }

  function deleteSupplier(){
    var supplier = supplierFunctions.getSupplier();
    swal(
      {
        title: 'Confirm Delete Supplier',
        text: 'This supplier will be permanently deleted. This action cannot be undone.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Delete',
        closeOnConfirm: true
      },
      function (isConfirm) {
        if (isConfirm) {
          deleteWithdrawnSupplier.delete({
            id: supplier._id
          }, function () {
            toastr.success('Supplier successfully deleted');
            $state.go('main.user.supplier.manage', {status: 'withdrawn'});
          }, function (error) {
            toastr.error(error.data.content.error);
          })
        }
      }
    );
  }

  function isStatus(supplier, status) {
    return supplier.basic_info.status === status;
  }

  function isEditStatus(status) {
    switch (status) {
      case 'ACTIVE_PENDING':
        var newStatus = $scope.supplier.edit.status.new_status;
        $scope.approvalStatusInfo.original = $scope.supplier.edit.status.original_status;
        $scope.approvalStatusInfo.new = newStatus === 'INACTIVE' ? 'DEACTIVATED' : newStatus;
        break;
      case 'PENDING':
        $scope.approvalStatusInfo.original = $scope.supplier.edit.status.original_status;
        $scope.approvalStatusInfo.new = $scope.supplier.edit.status.new_status;
        break;
    }
  }

  function isEditPersonal() {
    var status = $scope.supplier.edit.personal.original_status;
    switch (status) {
      case 'INACTIVE':
        $scope.approvalStatusInfo.original = 'DEACTIVATED';
        break;
      case 'BLACKLISTED':
        $scope.approvalStatusInfo.original = 'BLACKLISTED';
        break;
      default:
        $scope.approvalStatusInfo.original = 'ACTIVE';
    }
    $scope.approvalStatusInfo.new = 'ACTIVE';
  }

  function isEditCompanyContract() {
    $scope.approvalStatusInfo.original = 'ACTIVE';
    $scope.approvalStatusInfo.new = 'ACTIVE';
  }

  function canDelete() {
    var checkSupplierStatus = (['WITHDRAWN'].indexOf($scope.supplier.basic_info.status) > -1);

    var isSubmitter = (!!$scope.supplier.submission_info && !!$scope.supplier.submission_info.submitter) &&
      $scope.supplier.submission_info.submitter._id === $scope.currentUser._id;

    return (checkSupplierStatus && isSubmitter);
  }

  function noChangesMade(supplier) {
    return angular.equals($scope.supplierCopy, supplier);
  }

  function addSupplierUser() {
    var statusDraft = 5;
    $scope.supplierUsers.push({
      display_name: '',
      email: '',
      phone: '',
      status: statusDraft,
    });
  }

  function removeSupplierUser(index) {
    $scope.supplierUsers.splice(index, 1);
  }

  initialize();
}
