'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('faq', {
        templateUrl: 'app/faq/faq.html',
        url: '/faq',
        controller: 'faqCtrl',
        resolve: {
          style: function ($rootScope) {
            $rootScope.bodyClasses = '';
          },
          Authorization: function ($auth, toastr, $state) {
            if (!$auth.isAuthenticated()) {
              toastr.error('Please log in.');
              $state.go('login');
            }
          }
        }
      });
  }]);
