'use strict';

function compareSupplierVersionCtrl(
  $scope, $uibModalInstance, $uibModal, versions, globalFunc, selectedVersion, currentStatus, supplierFunctions,
  $translatePartialLoader
) {
  $translatePartialLoader.addPart('main/lang');
  $translatePartialLoader.addPart('suppliersManagement/newSupplier/lang');
  $translatePartialLoader.addPart('suppliersManagement/versions/lang');
  $scope.closeComparisonModal = closeComparisonModal;
  $scope.compareArrayValue = compareArrayValue;
  $scope.currentStatus = currentStatus;

  $scope.businessEntities = [
    {descr: 'Berhad (Public Limited)', shortDescr: 'PUBLIC_LIMITED', value: '1'},
    {descr: 'Sendirian Berhad (Private Limited)', shortDescr: 'PRIVATE_LIMITED', value: '2'},
    {descr: 'Sole Proprietor & Enterprise', shortDescr: 'SOLE', value: '3'},
    {descr: 'Partnership', shortDescr: 'PARTNERSHIP', value: '4'}
  ];

  function closeModal() {
    $uibModalInstance.close();
  }

  function closeComparisonModal() {
    // Remove row highlighting
    closeModal();
    $uibModal.open({
      templateUrl: 'app/suppliersManagement/versions/supplier-versions.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        supplierVersions: function () {
          return versions;
        },
        supplierStatus: function () {
          return $scope.currentStatus;
        }
      },
      controller: 'supplierVersionsController'
    });
  }


  /**
   * compare Arrays value
   * @param array1
   * @param array2
   * @param fieldToCompare
   * @returns {boolean}
   */
  function compareArrayValue(array1, array2, fieldToCompare) {
    var arrIteration;

    if (!array1 || !array2)
      return false;

    if (array1.length !== array2.length)
      return false;

    for (arrIteration in array1) {
      if (array1.hasOwnProperty(arrIteration)) {
        var checkValInArray2 = globalFunc.findInArray(array2, fieldToCompare, array1[arrIteration][fieldToCompare]);
        if (!checkValInArray2)
          return false;
      }
    }

    return true;
  }

  function checkBranchDifferences(array1, array2) {
    // Remove row highlighting
    clearHasUpdateFlag();

    var hasDifferences = !_.isEqual(array1, array2);
    if (!!hasDifferences) {
      _.forEach($scope.supplierVersions[0].branches, function (branch, index) {
        if (!_.isEqual(branch, $scope.supplierVersions[1].branches[index])) {
          branch.hasUpdate = true;
        }
      });

      _.forEach($scope.supplierVersions[1].branches, function (branch, index) {
        if (!_.isEqual(branch, $scope.supplierVersions[0].branches[index])) {
          branch.hasUpdate = true;
        }
      });

    }
    return hasDifferences;
  }

  /**
   * Set hasUpdate attribute for branch to false to clear the flag
   *
   * @returns void
   */
  function clearHasUpdateFlag() {
    _.forEach($scope.supplierVersions[0].branches, function (branch) {
      branch.hasUpdate = false;
    });

    _.forEach($scope.supplierVersions[1].branches, function (branch) {
      branch.hasUpdate = false;
    });
  }

  function initialize() {
    $scope.paymentTerms = supplierFunctions.getPaymentTerms();
    $scope.paymentMethods = supplierFunctions.getPaymentMethods();
    $scope.currencies = supplierFunctions.getCurrencies();
    $scope.taxes = supplierFunctions.getTaxes();
    $scope.companies = supplierFunctions.getTaggedCompanies();
    $scope.businessNatures = supplierFunctions.getSupplierBusinessNatureList();
    $scope.supplierVersions = selectedVersion;
    $scope.totalVersions = versions.length;

    $scope.branchChanges = checkBranchDifferences($scope.supplierVersions[0].branches, $scope.supplierVersions[1].branches);

    if (!!$scope.supplierVersions) {
      $scope.paymentTerm1 =
        globalFunc.findInArray($scope.paymentTerms, 'code', $scope.supplierVersions[0].default_contract.payment_term_code);
      $scope.paymentTerm2 =
        globalFunc.findInArray($scope.paymentTerms, 'code', $scope.supplierVersions[1].default_contract.payment_term_code);
      $scope.paymentMethod1 =
        globalFunc.findInArray($scope.paymentMethods, 'code', $scope.supplierVersions[0].default_contract.payment_method_code);
      $scope.paymentMethod2 =
        globalFunc.findInArray($scope.paymentMethods, 'code', $scope.supplierVersions[1].default_contract.payment_method_code);
      $scope.currency1 =
        globalFunc.findInArray($scope.currencies, 'code', $scope.supplierVersions[0].default_contract.currency_code);
      $scope.currency2 =
        globalFunc.findInArray($scope.currencies, 'code', $scope.supplierVersions[1].default_contract.currency_code);
      $scope.tax1 =
        globalFunc.findInArray($scope.taxes, 'code', $scope.supplierVersions[0].default_contract.tax_code);
      $scope.tax2 =
        globalFunc.findInArray($scope.taxes, 'code', $scope.supplierVersions[1].default_contract.tax_code);
      $scope.taggedCompany1 =
        globalFunc.findInArray($scope.companies, 'code', $scope.supplierVersions[0].basic_info.tagged_to_company_code);
      $scope.taggedCompany2 =
        globalFunc.findInArray($scope.companies, 'code', $scope.supplierVersions[1].basic_info.tagged_to_company_code);
      $scope.priBusinessNature1 =
        globalFunc.findInArray($scope.businessNatures, 'shortDescr', $scope.supplierVersions[0].default_contract.primary_business_nature);
      $scope.priBusinessNature2 =
        globalFunc.findInArray($scope.businessNatures, 'shortDescr', $scope.supplierVersions[1].default_contract.primary_business_nature);
      $scope.secBusinessNature1 =
        globalFunc.findInArray($scope.businessNatures, 'shortDescr', $scope.supplierVersions[0].default_contract.secondary_business_nature);
      $scope.secBusinessNature2 =
        globalFunc.findInArray($scope.businessNatures, 'shortDescr', $scope.supplierVersions[1].default_contract.secondary_business_nature);
      $scope.businesEntity1 =
        globalFunc.findInArray($scope.businessEntities, 'shortDescr', $scope.supplierVersions[0].basic_info.business_entity);
      $scope.businesEntity2 =
        globalFunc.findInArray($scope.businessEntities, 'shortDescr', $scope.supplierVersions[1].basic_info.business_entity);
    }
  }

  initialize();
}

compareSupplierVersionCtrl.$inject = [
  '$scope', '$uibModalInstance', '$uibModal', 'versions', 'globalFunc', 'selectedVersion', 'currentStatus', 'supplierFunctions',
  '$translatePartialLoader'
];

angular.module('metabuyer')
  .controller('compareSupplierVersionCtrl', compareSupplierVersionCtrl);
