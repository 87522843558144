/**
 * @name metabuyerCache
 *
 * @description
 *
 * This service will create the cache variable with only one ID
 *
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

function metabuyerCache ($cacheFactory) {

  return $cacheFactory('metabuyer');
}
metabuyerCache.$inject = ['$cacheFactory'];

angular
  .module('metabuyer')
  .factory('metabuyerCache',metabuyerCache);
