'use strict';
/**
 * @name companyAssetDetailsCategory
 * @desc Controller for asset master details
 */
function companyAssetDetailsCategory(
  $scope, $uibModalInstance, toastr, $state, companyId,
  globalFunc, companyAssetDetails, $rootScope
) {
  $scope.editClicked = false;
  $scope.companyAssetDetails = _.cloneDeep(companyAssetDetails.data);
  $scope.companyId = companyId;
  $scope.close = close;
  $scope.toggleTab = toggleTab;

  $scope.tab = 1;
  function toggleTab(tab) {
    if(tab === 2)
      $rootScope.$broadcast('reloadAuditData');
    return $scope.tab = tab;
  }

  $scope.historyDependencies = {
    embeddedParams: {
      'object[0][object_id]': $scope.companyAssetDetails._id,
        'object[0][object_type]': 'App\\Metabuyer\\CompanyAsset\\Models\\CompanyAsset',
        offset: 5,
        order_by: '-created_at'
    }
  };

  function close() {
    $uibModalInstance.close();
  }

  function initialize(){
    if (!!$scope.companyAssetDetails.updated_at) {
      $scope.companyAssetDetails.updated_at = new Date(Number($scope.companyAssetDetails.updated_at)).toDateString();
    }
    if (!!$scope.companyAssetDetails.created_at) {
      $scope.companyAssetDetails.created_at = new Date(Number($scope.companyAssetDetails.created_at)).toDateString();
    }

    _.forEach($scope.accountCodes, function(e){
      if ($scope.companyAssetDetails.account_code === e.code){
        $scope.companyAssetDetails.account_code = e;
      }
    });
  }

  initialize();

}

companyAssetDetailsCategory.$inject = [
  '$scope', '$uibModalInstance', 'toastr', '$state', 'companyId',
  'globalFunc', 'companyAssetDetails', '$rootScope'
];

angular
  .module('metabuyer')
  .controller('companyAssetDetailsCategory', companyAssetDetailsCategory);
