'use strict';
/**
 * @name logViewerManagementCtrl
 * @desc Controller for listing of positions
 */
function logViewerManagementCtrl($scope, logViewer, toastr, pathConstants, $http) {
  $scope.laravelLogData = null;
  $scope.openFile = openFile;
  $scope.downloadLogFile = downloadLogFile;

  function initialize() {
    getLogs();
  }

  function openFile(fileName) {
    logViewer.get(
      {
        l: fileName
      },
      function (resource) {
        if (!!resource && !!resource.current_file) {
          $scope.laravelLogData = resource;
        }
      },
      function () {
        toastr.error('Failed to get laravel logs.');
      }
    )
  }

  function downloadLogFile(fileName) {
    var url = pathConstants.apiUrls.laravelLog + '?dl=' + fileName;

    $http({
      method: 'GET',
      url: url
    }).then(function (response) {
      if (!!response && !!response.data) {
        var blob = new Blob([response.data]);
        var link = document.createElement('a');
        document.body.appendChild(link);
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      }
    }, function () {
      toastr.error('Failed to download file.');
    });
  }

  function getLogs() {
    logViewer.get(
      {},
      function (resource) {
        if (!!resource && !!resource.current_file) {
          $scope.laravelLogData = resource;
        }
      },
      function () {
        toastr.error('Failed to get laravel logs.');
      });
  }

  initialize();
}

logViewerManagementCtrl.$inject = ['$scope', 'logViewer', 'toastr', 'pathConstants', '$http'];

angular
  .module('metabuyer')
  .controller('logViewerManagementCtrl', logViewerManagementCtrl);

