'use strict';
angular
  .module('metabuyer')
  .directive('reverseGoods',function($uibModal, globalFunc, $rootScope) {
    return{
      restrict: 'A',
      scope: true,
      link: function(scope) {

        var panel = function () {
          var modalInstance = $uibModal.open({
            templateUrl: 'components/reverseGoods/reverseGoodsTemplate.html',
            backdrop: 'static',
            keyboard: false,
            controller: 'ReverseGoodsCtrl',
            scope: scope,
            windowClass: 'budget-apportion-details',
            size: 'lg',
            resolve: {
              goodsReceived: function(Orders, $q, globalFunc){
                var deferred = $q.defer();
                Orders.getPOGRNs(
                  {
                    id: scope.po._id
                  },
                  function (resource) {
                    deferred.resolve(resource.content);
                  },
                  function (error) {
                    globalFunc.objectErrorMessage(error);
                  }
                );
                return deferred.promise;
              }
            }
          });
        };

        //loading the panel
        scope.showReverseGoods = function () {
          if (scope.po.integration_solution === 'MB-JDE-JDE') {
            if (globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN')) {
              swal(
                {
                  title: 'Confirm reverse goods',
                  text: 'By executing this, you are aware this feature is to resolve the JDE Integration PO timing ' +
                  'issue. Any processes done are logged in the system. ',
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#1ab394  ',
                  confirmButtonText: 'Confirm',
                  cancelButtonText: 'Cancel',
                  closeOnConfirm: true,
                  closeOnCancel: true
                },
                function (confirmed) {
                  if (!!confirmed) {
                    panel();
                  } else {
                    return false;
                  }
                }
              );
            }
          }
          else
            panel();
        };
      }
    };
  });
