/**
 * @name detailDelegationCtrl
 *
 * @description
 * List all the pending delegations and only accessible to Delegation Approver role user
 *
 * @author Ameer Asraf <ameerasraf_92@yahoo.com>
 * @copyright 2019 Metacloud Sdn. Bhd.
 */

'use strict';

function detailDelegationCtrl($state, $scope, detailDelegation, HighlightId, Approvals, approvalDelegation, toastr, $rootScope, globalFunc, profile) {

  $scope.getDelegationStatus = getDelegationStatus;
  $scope.approve = approve;
  $scope.reject = reject;
  $scope.back = back;
  $scope.reasonReject = {};

  function getDelegationStatus() {
    return 'Pending';
  }

  function loadApprovalFlow() {
    $scope.approvals = [];
    var approvalId = $scope.dataList.approval_id;

    Approvals.get(
      {
        id: approvalId
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.approvals = resource.content.data;
          $scope.approvalFlowData = resource.content.data;
          $scope.allowedApprovedAction =
            !!globalFunc.findInArray($scope.approvals.waiting_on, '_id', $rootScope.currentUser._id)
        } else {
          $scope.approvals = [];
        }
      },
      function () {
        toastr.error('Error loading Approval Flow');
        $scope.approvals = [];
      });
  }

  /**
   * Back to the pending delegation list
   */
  function back() {
    var isTenantAdmin = globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN');
    var isAdmin = globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Admin');
    if (!!$rootScope.isV2Enabled && !isAdmin && !isTenantAdmin) {
      $state.go('main.user.mainMenu.waiting-on-you');
    } else {
      $state.go('main.user.users.pendingDelegation');
    }
  }

  /**
   * Function to approve delegation to move to next status
   */
  function approve() {
    var approvalId = $scope.dataList.approval_id;

    return approvalDelegation.post(
      {
        approval_id: approvalId,
        status: 'approve'
      },
      function () {
        toastr.success('Delegation is approved.');
        HighlightId.setId(approvalId);

        if (!!$rootScope.isV2Enabled) {
          $state.go('main.user.mainMenu.waiting-on-you');
        } else {
          $state.go('main.user.users.delegation', {status: 'all'});
        }
      },
      function () {
        toastr.error('Delegation has error.');
      }
    ).$promise;
  }

  /**
   * Function to reject delegation to move to next status
   */
  function reject() {
    var approvalId = $scope.dataList.approval_id;

    return approvalDelegation.post(
      {
        approval_id: approvalId,
        status: 'reject'
      },
      {
        comment: $scope.reasonReject.status_reason
      },
      function () {
        toastr.success('Delegation rejected.');
        $state.go('main.user.mainMenu.waiting-on-you');
      },
      function () {
        toastr.error('Delegation has error.');
      }
    ).$promise;
  }

  function initialize() {
    $scope.dataList = detailDelegation;
    loadApprovalFlow();

    $scope.highlightId = HighlightId.getId();
    if (!!$scope.highlightId) {
      HighlightId.setId(null);
    }
  }

  initialize();

}

detailDelegationCtrl.$inject = ['$state', '$scope', 'detailDelegation', 'HighlightId', 'Approvals',
  'approvalDelegation', 'toastr', '$rootScope', 'globalFunc', 'profile'];

angular
  .module('metabuyer')
  .controller('detailDelegationCtrl', detailDelegationCtrl);
