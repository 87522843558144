'use strict';

/**
 *
 * @param $scope
 * @param $rootScope
 * @param $state
 * @param companyDetails
 * @param globalFunc
 * @param toastr
 * @param $uibModal
 * @param companyProjectList
 * @param companyOpexIoService
 */
function projectCodeCtrl($scope, $rootScope, $state, companyDetails, globalFunc, toastr, $uibModal, companyProjectList,
                    companyOpexIoService, $location) {
  $scope.companyProjectList = companyProjectList.data;
  $scope.meta = companyProjectList.meta;
  $scope.company = companyDetails.data;
  $scope.companyOpexIoService = companyOpexIoService;
  $scope.search = search;
  $scope.back = back;
  $scope.showColumn = showColumn;
  $scope.addProject = addProject;
  $scope.updateCompanyProject = updateCompanyProject;
  $scope.getActiveStatusName = getActiveStatusName;
  $scope.storageKey = 'companies-opex-io-selected-columns';
  $scope.params = {
    'class': 'App\\Metabuyer\\Project\\Models\\Project'
  };

  // $scope.history = itemTaxesHistory.data;

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional) {
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function getActiveStatusName(val) {
    return !!val ? 'Active' : 'Inactive';
  }

  function prepareHistory() {
    var version = 1;
    _.forEach($scope.history, function (value) {
      value.version = version++;
    });
  }

  function updateCompanyProject(companyProject) {
    $scope.projectModalTitle  = 'Update Project';
    $scope.singleProject = _.cloneDeep(companyProject);
    $scope.projectModifyMode = 'update';
    var project = $uibModal.open({
      templateUrl: 'app/companiesManagement/companies/project/add.html',
      controller: 'companyProjectCtrl',
      backdrop: 'static',
      keyboard: false,
      scope: $scope,
      resolve: {
        companies: function(){
          return $scope.company.shadow_companies;
        },
        companyDetails: function(){
          return $scope.company;
        }
      }
    });

    project.result.then(function (result) {
      var index = _.findIndex($scope.companyProjectList, function(e){
        return e.uuid === result.uuid;
      });
      $scope.companyProjectList[index] = result;
    });
  }

  function addProject() {
    $scope.projectModalTitle  = 'Add new Project';
    $scope.singleProject = {};
    $scope.projectModifyMode = 'create';
    var project = $uibModal.open({
      templateUrl: 'app/companiesManagement/companies/project/add.html',
      controller: 'companyProjectCtrl',
      backdrop: 'static',
      keyboard: false,
      scope: $scope,
      resolve: {
        companies: function(){
          return $scope.company.shadow_companies;
        },
        companyDetails: function(){
          return $scope.company;
        }
      }
    });

    project.result.then(function (result) {
      if (!_.isEmpty(result))
        $scope.companyProjectList.unshift(result);
    });
  }

  /**
   * Goes back to company details
   */
  function back() {
    if (!!$scope.company && !!$scope.company._id)
      $state.go('main.user.companiesMgmt.detailCompanies', {id: $scope.company._id});
    else
      window.history.back();
  }

  function search(query) {
    $location.search({query: query});
  }

  function initialize() {
    var tabData = {
      tab: $scope.$parent.tabData[0],
      noTransition: true
    };

    $rootScope.$broadcast('loadSpecificTab', tabData);
    prepareHistory();
  }

  initialize();
}

projectCodeCtrl.$inject = ['$scope', '$rootScope', '$state', 'companyDetails', 'globalFunc', 'toastr', '$uibModal',
  'companyProjectList', 'companyOpexIoService', '$location'];
angular.module('metabuyer')
  .controller('projectCodeCtrl', projectCodeCtrl);
