'use strict';

/**
 * Controller for Modal to display Branch Listing for branch PR selection
 *
 * @param $scope
 * @param $uibModalInstance
 * @param branchSelection
 *
 * @author Khor Jin Ee <jinee585@gmail.com>
 * @copyright 2017 Metacloud Sdn. Bhd.
 */
function branchPrV2ListingCtrl($scope, $uibModalInstance, branchSelection) {
  $scope.branchSelection = branchSelection;
  $scope.closeBranchListing = closeBranchListing;
  $scope.callBackFunction = callBackFunction;

  function closeBranchListing() {
    $uibModalInstance.close();
  }

  function callBackFunction(data) {
    var startNext = $scope.$resolve.onSelectSupplierBranch(data, $scope.$resolve.itemIndex);
    if(!!startNext) {
      $uibModalInstance.close();
    }
  }
}
branchPrV2ListingCtrl.$inject = [
  '$scope', '$uibModalInstance', 'branchSelection'
];
angular.module('metabuyer')
  .controller('branchPrV2ListingCtrl', branchPrV2ListingCtrl);
