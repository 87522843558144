'use strict';
angular
  .module('metabuyer')
  .directive('resendPo',function($uibModal, toastr) {
    return{
      restrict: 'A',
      scope: true,
      link: function(scope) {

        var panel = function () {
          var supplierId = scope.po.supplier.supplier_id;
          var companyCode = scope.po.billing.company.code;

          if (!supplierId) {
            toastr.error("Supplier not found.");
          }
          else {
            var modalInstance = $uibModal.open({
              templateUrl: 'components/resendPO/resendPOTemplate.html',
              backdrop: 'static',
              keyboard: false,
              controller: 'ResendPOCtrl',
              scope: scope,
              resolve: {
                companyContract: function ($q, singleCompanyContract, globalFunc) {
                  var deferred = $q.defer();
                  singleCompanyContract.get(
                    {
                      id: supplierId,
                      code: companyCode
                    },
                    function (resource) {
                      if (!!resource && !!resource.content && !!resource.content.data) {
                        deferred.resolve(resource.content.data);
                      } else {
                        deferred.resolve({});
                      }
                    },
                    function (error) {
                      globalFunc.objectErrorMessage(error);
                      deferred.resolve({});
                    }
                  );
                  return deferred.promise;
                }
              }
            });
          }
        };

        //loading the panel to comment
        scope.showResendPO = function () {
          panel();
        };
      }
    };
  });
