'use strict';

angular.module('metabuyer')
  .filter('adddressBookFilter', ['$filter', function ($filter) {
    return function (address_books, values, scope) {
      var filters = [];
      _.forEach(address_books, function(address_book){

        //company city, state filter
        if(!!values.city_state){

          if(!address_book.city || !address_book.state) {
            return;
          }

          var is_found = false;
          if(address_book.city.toLowerCase().indexOf(values.city_state.toLowerCase()) !== -1){
            is_found = true;
          }

          if(address_book.state.toLowerCase().indexOf(values.city_state.toLowerCase()) !== -1){
            is_found = true;
          }

          if(!is_found){
            return;
          }
        }

        //Address name filter
        if(!!values.name) {
          if(!address_book.name) {
            return;
          }

          var isFound = false;
          if(address_book.name.toLowerCase().indexOf(values.name.toLowerCase()) !== -1){
            isFound = true;
          }

          if(!isFound){
            return;
          }
        }

        //Company postal code filter
        if(!!values.postal_code){

          if(!address_book.postal_code) {
            return;
          }

          var is_found = false;
          var address_postal = address_book.postal_code.toString();

          if(address_postal.toLowerCase().indexOf(values.postal_code.toLowerCase()) !== -1){
            is_found = true;
          }

          if(!is_found){
            return;
          }
        }

        //company address filter
        if(!!values.address){

          if(!address_book) {
            return;
          }

          if(!address_book.line1 && !address_book.line2
            && !address_book.line3) {
            return;
          }

          var is_found = false;
          if(values.isShownAddressLine("address_line_1") && !!address_book.line1 &&
             address_book.line1.toLowerCase().indexOf(values.address.toLowerCase()) !== -1){
            is_found = true;
          }

          if(values.isShownAddressLine("address_line_2") && !!address_book.line2 &&
             address_book.line2.toLowerCase().indexOf(values.address.toLowerCase()) !== -1){
            is_found = true;
          }

          if(values.isShownAddressLine("address_line_3") && !!address_book.line3 &&
             address_book.line3.toLowerCase().indexOf(values.address.toLowerCase()) !== -1){
            is_found = true;
          }

          if(!is_found){
            return;
          }
        }

        filters.push(address_book);
      });

      return filters;
    }
  }]);
