'use strict';

function tenderSupplierCtrl(
  $scope, globalFunc, $rootScope, $uibModal, $state, HighlightId, pathConstants, $filter, tenderFunction, tenderService, toastr, $http
) {
  $scope.suppliers = [];
  $scope.supplierNameQuery = {
    selected: ''
  };
  $scope.getSupplierNameSearch = getSupplierNameSearch;
  $scope.updateSupplierName = updateSupplierName;
  $scope.removeSupplier = removeSupplier;
  $scope.loadSupplierSearch = loadSupplierSearch;
  $scope.showHideCond = showHideCond;

  function showHideCond() {

    var canEdit = true;

    if ($scope.tender.is_vo && $scope.tender.status === 'draft' && tenderFunction.hasRoleRequester()) {

      if (['ADDITIONAL_SUM', 'DATE_CHANGE'].includes($scope.tender.detail.type.key)) {
        canEdit = false;
      }

    } else {
      canEdit = $scope.editableStatus;
    }

    return canEdit;
  }

  function loadSupplierSearch() {
    document.querySelector("#supplierNameQuery").focus();
  }

  function updateSupplierName(selected) {

    var payload = [{
      supplier_id: selected.key
    }];

    tenderFunction.saveApi({ suppliers: payload, return_single_supplier: true }, '').then(function(response) {

      if (response.http_code === 200) {
        $scope.suppliers.push(response.content.data);
      }
    })

    $scope.supplierNameQuery = {
      selected: ''
    };

  }

  function getSupplierNameSearch(query) {
    return tenderService.findSupplier({
      id: tenderFunction.getTenderId(),
      keywords: query
    }).$promise.then(function(response) {
      if (response.content.data.length === 0) {
        return [];
      } else {
        return response.content.data;
      }
    });
  }

  function removeSupplier(index) {

    swal({
      title: 'Confirmation',
      text: 'Confirm Delete This Supplier?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Confirm',
      closeOnConfirm: true
    }, function(isConfirm) {
      if (isConfirm) {
        var value = $scope.suppliers[index];
        var payload = {};
        payload[index] = {
          _id: value._id,
          supplier_id: value.supplier_id,
          is_deleted: true
        };

        tenderFunction.saveApi({ suppliers: payload }, 'Successfully deleted').then(function(response) {
          if (response.http_code === 200) {
            $scope.suppliers.splice(index, 1);
          }
        })
      }
    })
  }

  function initialize() {
    $scope.suppliers = tenderFunction.getSupplierLists();
  }

  initialize();

  $scope.$on('updateTenderSuppliers', function(event, result) {
    $scope.suppliers = result.data;
  });
}

tenderSupplierCtrl.$inject = [
  '$scope', 'globalFunc', '$rootScope', '$uibModal', '$state', 'HighlightId', 'pathConstants', '$filter', 'tenderFunction', 'tenderService', 'toastr', '$http'
];

angular
  .module('metabuyer')
  .controller('tenderSupplierCtrl', tenderSupplierCtrl);
