'use strict';

angular.module('metabuyer.services.accountCodes', ['ngResource'])
  .factory('accountCodesList',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.accountCode.list,
        {
          company_id: '@company_id'
        },
        {
          'get': {method: 'GET'}
        }
      );
    }
  )
  .factory('accountCodeSearch',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.accountCode.search,
        {
          company_id: '@company_id',
          query: '@query'
        },
        {
          'get': {method: 'GET'}
        }
      );
    }
  )
  .factory('singleAccountCode',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.accountCode.single,
        {
          id: '@id',
          company_id: '@company_id'
        },
        {
          'get': {method: 'GET'},
          'put': {method: 'PUT'},
          'post': {method: 'POST'}
        }
      );
    }
  )
  .factory('PRAccountCodeList',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.accountCode.prList,
        {
        },
        {
          'get': {method: 'GET'}
        }
      );
    }
  )
  .factory('singleAccountCodeByCode',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.accountCode.singleCode,
        {
          code: '@code',
          company_code: '@company_code'
        },
        {
          'get': {method: 'GET'}
        }
      );
    }
  );
