'use strict';

function privateAccessCtrl($scope, $state, $window, dataList, privateAccess, toastr, globalFunc) {
  $scope.dataList = dataList.data;
  $scope.meta = dataList;
  $scope.submitted = false;
  $scope.description = undefined;
  $scope.generateToken = generateToken;
  $scope.deactivateToken = deactivateToken;
  $scope.onSelectTaggedTo = onSelectTaggedTo;
  $scope.editable = {};

  function generateToken() {
    $scope.submitted = true;
    if (!$scope.description) {
      toastr.error('Description must not be empty');
      return;
    }

    var params = {
      description: $scope.description
    };

    if (!!$scope.taggedTo) {
      params['tagged_to'] = $scope.taggedTo;
    }

    privateAccess.post(
      params,
      function (resource) {
        toastr.success(resource.content.message);
        $state.reload();
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function deactivateToken(id) {
    privateAccess.put(
      {id: id},
      function (resource) {
        toastr.success(resource.content.message);
        $state.reload();
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function onSelectTaggedTo(person) {
    if (!!person && !!person._id && !$scope.taggedTo) {
      $scope.taggedTo = person._id;
    }
  }

  function loadAllActiveUser() {
    $scope.activeUserList = [];
    privateAccess.activeUsers(
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.activeUserList = resource.content.data;
        }
      });
  }

  function initialize() {
    loadAllActiveUser();
  }

  initialize();

  $scope.$emit('pageTitleChanged', 'Private Access Settings');
}
privateAccessCtrl.$inject = ['$scope', '$state', '$window', 'dataList', 'privateAccess', 'toastr', 'globalFunc'];

angular.module('metabuyer')
  .controller('privateAccessCtrl', privateAccessCtrl);
