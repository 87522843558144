'use strict';

/**
 * @name Address Insertion
 *
 * @description
 * Directive to insert and update address
 *
 * @param data
 * data is two ways binding variable that used to fill in the address data
 * and can be used in the controller
 *
 * @param country
 * @param selector
 * selector is country selector 'key' to get state data
 * @param state
 * State must be object and array inside the object
 * state has relation with country selection.
 * example state data
 * {
 *    'MY': [], // state data of MY
 *    'SG': [] // state data of SG
 * }
 * with this data when user select the country it will load the array of the selected object
 *
 * @copyright Sunway Metacloud &copy; 2016
 */

angular
  .module('metabuyer')
  .directive('addressInsertion', function() {
    return {
      restrict: 'E',
      scope: {
        data: '=',
        countries: '=',
        selector: '@',
        states: '='
      },
      replace: true,
      templateUrl: 'components/addressInsertion/addressInsertion.html',
      link: function($scope) {
        $scope.countryStates = [];
        $scope.onSelectedCountry = onSelectedCountry;
        $scope.stateIsExist = false;

        function onSelectedCountry(selectedCountry) {
          var code = selectedCountry[$scope.selector];
          $scope.stateIsExist = validateStateIsExist($scope.states, code);
          $scope.countryStates = $scope.stateIsExist;
          $scope.data.state = '';
          if(!!$scope.stateIsExist)
            $scope.data.state = {};
        }

        function validateStateIsExist(states, selector) {
          return states[selector];
        }
      }
    }
  });
