'use strict';
/***
 * @name exportBudgetAccountCodeCtrl
 * @desc Controller for export budget account code
 */
function exportBudgetAccountCodeCtrl(
  $scope, $uibModalInstance, globalFunc, $rootScope
) {
  var companyList = globalFunc.getUserCompanyListForRole($rootScope.currentUser, ['Company Budget Requester', 'Budget Requester']);
  $scope.companyList = _.unique(companyList, function(company) {
    return company._id;
  });
  $scope.selectedCompanyList = [];
  $scope.selectedCompanyCodes = [];
  $scope.multiselectTranslation = {
    selectAll: 'Select all',
    selectNone: 'Select none',
    reset: 'Reset',
    search: 'Type here to search...',
    nothingSelected: 'Select'
  };

  $scope.budgetStatusOptions = [
    {
      key: 'all',
      value: 'All'
    },
    {
      key: 4,
      value: 'Draft'
    },
    {
      key: 1,
      value: 'Approved'
    }
  ];

  $scope.budgetStatus = {
    selected: $scope.budgetStatusOptions[0]
  };

  $scope.advancedSearch = {
    status: $scope.budgetStatus.selected
  };

  $scope.cancel = cancel;
  $scope.onSelectSingle = onSelectSingle;
  $scope.onSelectAll = onSelectAll;
  $scope.onSelectNone = onSelectNone;
  $scope.getCompanyCode = getCompanyCode;
  $scope.statusUpdated = statusUpdated;

  function statusUpdated(newStatus) {
    $scope.advancedSearch = newStatus;
  }

  function cancel() {
    $uibModalInstance.close();
  }

  function onSelectSingle(selection) {
    $scope.selectedCompanyList = selection;
    getCompanyCode();
  }

  function onSelectAll() {
    $scope.selectedCompanyList = $scope.companyList;
    getCompanyCode();
  }

  function onSelectNone() {
    $scope.selectedCompanyList = [];
    getCompanyCode();
  }

  function getCompanyCode() {
    $scope.selectedCompanyCodes = _.pluck($scope.selectedCompanyList, 'code');
  }
}

exportBudgetAccountCodeCtrl.$inject = ['$scope', '$uibModalInstance', 'globalFunc', '$rootScope'];

angular
  .module('metabuyer')
  .controller('exportBudgetAccountCodeCtrl', exportBudgetAccountCodeCtrl);
