'use strict';
angular
  .module('metabuyer')
  .directive('editContact',function($uibModal, toastr) {
    return{
      restrict: 'A',
      scope: {
        editContact: '=editContact',
        updatedContact: '&'
      },
      link: function(scope, element, attrs) {

        var panel = function () {
          var modalInstance = $uibModal.open({
            templateUrl: 'components/editContact/editContactTemplate.html',
            backdrop: 'static',
            keyboard: false,
            controller: 'editContactCtrl',
            scope: scope
          });
        };
        //loading the panel
        scope.showEditAddress = function () {
          panel();
        };

        element.bind('click', function($event){
          scope.showEditAddress();
        });
      }
    };
  });
