'use strict';
/**
 * @name tenderAddGroupCtrl
 * @desc Controller for add tender group
 */
function tenderAddGroupCtrl(
  $scope, $stateParams, $state, pathConstants, $uibModal, $filter, HighlightId, globalFunc, $rootScope, tenderGroup, companyList,
  tenderGroupService, toastr
) {

  $scope.tenderGroup = tenderGroup;
  $scope.tenderGroup.status = true;
  $scope.back = back;
  $scope.multiselectTranslation = {
    selectAll: 'Select all',
    selectNone: 'Select none',
    reset: 'Reset',
    search: 'Type here to search...',
    nothingSelected: 'Select Company(s)'
  };
  $scope.companyList = companyList.data;
  $scope.selectedCompanies = [];
  $scope.onSelectSingle = onSelectSingle;
  $scope.onSelectAll = onSelectAll;
  $scope.onSelectNone = onSelectNone;
  $scope.submitTenderGroup = submitTenderGroup;
  $scope.tenderGroupStatus = 'Active';
  $scope.statusChange = statusChange;

  function back() {
    $state.go('main.user.tender.group.manage')
  }

  function statusChange() {
    $scope.tenderGroup.status ? $scope.tenderGroupStatus = 'Active' : $scope.tenderGroupStatus = 'Inactive';
  }

  function onSelectSingle(selection) {
    $scope.selectedCompanies = selection;
  }

  function onSelectAll(selectionList) {
    $scope.selectedCompanies = selectionList;
  }

  function onSelectNone() {
    $scope.selectedCompanies = [];
  }

  function transitionToListing() {
    $state.go('main.user.tender.group.manage', {}, { reload: true });
  }

  function submitValidation() {
    var tenderGroup = $scope.tenderGroup;
    var errors = [];

    if (!tenderGroup.code) {
      errors.push('code');
      toastr.error('Tender code is required.');
    }

    if (!tenderGroup.description) {
      errors.push('description');
      toastr.error('Tender description is required.');
    }

    if (_.isEmpty($scope.selectedCompanies)) {
      errors.push('selectedCompanies');
      toastr.error('Tender companies is required.');
    }

    return !!(errors.length) ? false : true;
  }

  function submitTenderGroup() {

    if (!submitValidation()) {
      toastr.error('Please complete all required fields before proceeding');
      return;
    }

    var companyIds = [];

    _.forEach($scope.selectedCompanies, function(company) {
      companyIds.push(company._id);
    });

    var params = {
      code: $scope.tenderGroup.code,
      description: $scope.tenderGroup.description,
      companies: companyIds,
      status: $scope.tenderGroup.status ? 'ACTIVE' : 'INACTIVE'
    };

    return tenderGroupService.create(params, function(response) {
      toastr.success(response.msg);
      transitionToListing();
    }, function(error) {
      if (!!error.data && !!error.data.content && !!error.data.content.error) {
        if (typeof error.data.content.error === 'string' &&
          !globalFunc.hasJsonStructure(error.data.content.error)) {
          toastr.error(error.data.content.error);
        } else {
          globalFunc.laravelValidatorErrDisplay(JSON.parse(error.data.content.error));
        }
      }
    }).$promise;
  }

  function initialize() {

  }

  initialize();
}

tenderAddGroupCtrl.$inject = [
  '$scope', '$stateParams', '$state', 'pathConstants', '$uibModal', '$filter', 'HighlightId', 'globalFunc', '$rootScope', 'tenderGroup',
  'companyList', 'tenderGroupService', 'toastr'
];

angular
  .module('metabuyer')
  .controller('tenderAddGroupCtrl', tenderAddGroupCtrl);
