'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function ($stateProvider) {
    $stateProvider
      .state('policyDisclaimer', {
        url: '/securityPolicy',
        controller: 'MinionCtrl',
        templateUrl: 'app/minion/minion.html'
      })
      .state('main.user.policyDisclaimer', {
        url: '/securityPolicy',
        controller: 'PolicyDisclaimerCtrl',
        templateUrl: 'app/policyDisclaimer/policyDisclaimer.html',
        resolve: {
          accessRights: function (profile, globalFunc, $state, $rootScope) {
            if (!$rootScope.isSourcingSupplierEnabled ||
                  (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN') &&
                  !globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Sourcing Metabuyer Admin'))
            ) {
              $state.go('notAuthorized');
            }
          },
          $title: function () {
            return 'Security Policy';
          }
        }
      })
  }]);
