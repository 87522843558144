'use strict';

angular
  .module('metabuyer')
  .directive('reopenPo',function($uibModal) {
    return{
      restrict: 'A',
      scope: true,
      link: function(scope) {
        var panel = function () {
          var modalInstance = $uibModal.open({
            templateUrl: 'components/reopenPO/reopenPOTemplate.html',
            backdrop: 'static',
            keyboard: false,
            controller: 'ReopenPOCtrl',
            scope: scope
          });
        };

        //loading the panel
        scope.showReopenPO = function () {
          panel();
        };
      }
    };
  });
