'use strict';

function DeliveryNoteCtrl($scope, $state, $rootScope, deliveryNote, po, $uibModalInstance, $timeout, toastr,
                          $http, pathConstants, $filter, profile) {
  $scope.cancel = cancel;
  $scope.deliveryNote = deliveryNote;
  $scope.openDatePicker = openDatePicker;
  $scope.doDatepickerOpened = false;
  $scope.checkAllItemReceived = checkAllItemReceived;
  $scope.checkForAll = false;
  $scope.doDate = {};
  $scope.submitData = {};
  $scope.showSwalConfirmation = false;
  $scope.submitDeliveryNote = submitDeliveryNote;
  $scope.po = po;
  $scope.profile = profile;
  $scope.userBasePath = $rootScope.userImageBasePath;

  function cancel() {
    $uibModalInstance.close();
  }

  $scope.dateOptions = {
    formatYear: 'yy',
    startingDay: 1,
    maxDate: new Date
  };

  $scope.datePicker = {
    openDatepicker: function($event,index) {
      if($scope.deliveryNote.items[index].datepickerOpened) {
        $event.preventDefault();
        $event.stopPropagation();
      } else {
        $scope.deliveryNote.items[index].datepickerOpened = true;
      }
    }
  };

  function openDatePicker($event) {
    if($scope.doDatepickerOpened){
      $event.preventDefault();
      $event.stopPropagation();
    }
    else{
      $timeout(function(){
        $scope.doDatepickerOpened = true;
      })
    }
  }

  //Variable to capture current date
  var currentDate = new Date();
  $scope.tempcurrentDate = currentDate;
  $scope.formattedCurrentDate = currentDate; //Formatting the current date to MMM dd, yyyy
  $scope.doDate = currentDate;

  function checkAllItemReceived() {
    _.forEach($scope.deliveryNote.items, function (item) {
      item.received_quantity = item.shipping_quantity;
    });
  }

  function validateFileType() {
    if(angular.isDefined($scope.submitData.do_file)){
      var extensions = globalFunc.validDoFileType();
      var fileType = $scope.submitData.do_file.type;
      return (extensions.indexOf(fileType) > -1);
    }

    /**
     * no file is uploaded, its not Mandatory so no need to verify
     */
    return true;
  }

  function validateEntries() {
    if (!$scope.submitData.do_number || !$scope.submitData.do_number.length) {
      toastr.error('DO number is required.');
      return false;
    }

    if ($scope.deliveryNote.items.length === 1 && Number($scope.deliveryNote.items[0].received_quantity) === 0) {
      toastr.error('Amount of good received cannot be empty.');
      return false;
    }

    for (var i in $scope.deliveryNote.items) {
      if (Number($scope.deliveryNote.items[i].received_quantity) === 0) {
        toastr.error('Received Quantity amount of good received cannot be empty.');
        return false;
      }

      if (Number($scope.deliveryNote.items[i].received_quantity) >
        Number($scope.deliveryNote.items[i].shipping_quantity)) {
        toastr.error('Received Quantity amount of good received is invalid.');
        return false;
      }

      if (!$scope.deliveryNote.items[i].uom.is_fraction || $scope.deliveryNote.items[i].uom.is_fraction === 0) {
        if (Number($scope.deliveryNote.items[i].received_quantity) % 1 !== 0) {
          toastr.error('Unit of Measurement does not allow Received Quantity amount in fraction.');
          return false;
        }
      }

      // set confirmation if split by amount
      if (!!$scope.deliveryNote.items[i].split_type && $scope.deliveryNote.items[i].split_type === 'amount') {
        $scope.showSwalConfirmation = true;
      }
    }

    return true;
  }

  function processDeliveryNote() {
    var fd = new FormData();

    if (angular.isDefined($scope.doDate)) {
      var doDate = new Date($scope.doDate);
      fd.append('do_date', doDate.getTime());
    }

    if (angular.isDefined($scope.submitData.do_file)) {
      fd.append('do_file', $scope.submitData.do_file);
    }

    if (angular.isDefined($scope.submitData.do_number)) {
      fd.append('do_number', $scope.submitData.do_number);
    }

    if (angular.isDefined($scope.deliveryNote.dn_number)) {
      fd.append('dn_number', $scope.deliveryNote.dn_number);
    }

    _.forEach($scope.deliveryNote.items, function (item) {
      fd.append('items[' + item.index + ']', item.received_quantity);

      var receivedDate = new Date(item.received_date);
      fd.append('items_dates[' + item.index + ']', receivedDate.getTime());
    });

    if (angular.isDefined($scope.submitData.comment)) {
      fd.append('comment', $scope.submitData.comment);
    }

    $http.post($filter('format')(pathConstants.apiUrls.po.receive, {po_id: $scope.po._id}), fd, {
      transformRequest: angular.identity,
      headers: {'Content-Type': undefined}
    })
      .then(function onSuccess() {
        toastr.success('DO has been submitted.');
        $uibModalInstance.close();
        $state.reload();
      })
      .catch(function onError(error) {
        if (!!error.data && !!error.data.content && !!error.data.content.error) {
          toastr.error(error.data.content.error);
        }
      });
  }

  function submitDeliveryNote() {
    // validate file
    if (validateFileType() === false) {
      toastr.error('Invalid file format! Allowed extensions are: .pdf,.png,.doc,.jpg,.jpeg,.txt');
      $scope.submitData.do_file = {};
      return;
    }

    // validate input data
    if (validateEntries() === false) {
      return;
    }

    // Reconfirm if item is split by amount
    if ($scope.showSwalConfirmation) {
      // Sweet alert confirmation if receiving item that has linked PO
      swal({
        title: 'Confirm receive item(s)?',
        text: 'Item(s) on related PO(s) will also be marked as received.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true
      }, function (isConfirm) {
        if (!!isConfirm) {
          // Process delivery note
          processDeliveryNote();
        }
      });
    } else {
      // Process delivery note for non split item
      processDeliveryNote();
    }
  }

  function initialize(){
    _.forEach($scope.deliveryNote.items, function (item) {
      if ($scope.deliveryNote.status === 'new') {
        item.received_date = new Date();
        item.received_quantity = item.shipping_quantity;
      } else {
        item.received_quantity = Number(item.received_quantity);
        item.rejected_quantity = item.shipping_quantity - item.received_quantity;
      }

      item.index = item.po_item_line_number - 1;
    });

    if (!!$scope.deliveryNote.do_number) {
      $scope.submitData.do_number = $scope.deliveryNote.do_number;
    }

    if (!!$scope.deliveryNote.do_date) {
      $scope.doDate = $scope.deliveryNote.do_date;
    }

    if ($scope.deliveryNote.status === 'new') {
      $scope.enableForm = true;
    } else {
      $scope.enableForm = false;
    }
  }

  initialize();
}

DeliveryNoteCtrl.$inject = ['$scope', '$state', '$rootScope', 'deliveryNote', 'po', '$uibModalInstance',
  '$timeout', 'toastr', '$http', 'pathConstants', '$filter', 'profile'];

angular.module('metabuyer')
  .controller('DeliveryNoteCtrl', DeliveryNoteCtrl);
