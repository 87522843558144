'use strict';

/**
 * @name securityManageCtrl
 * @desc Controller for security for Viewing and Adding Roles
 */
function permissionMatrixCtrl($scope, globalFunc, $state, toastr, securityFunction, listTitle, $stateParams, rolePermissions) {

  $scope.back = back;
  $scope.selectedCompany = securityFunction.getSelectedCompany();
  $scope.selectedCostCenter = securityFunction.getSelectedCostCenter();

  $scope.permissions = [];
  $scope.actionExists = actionExists;
  $scope.limitedPermission = limitedPermission;
  $scope.limitedPermissionLabel = limitedPermissionLabel;
  $scope.showList = showList;
  $scope.getOrganizationCode = getOrganizationCode;
  $scope.title = listTitle;

  function actionExists(allowedAction, action) {
    return (allowedAction.indexOf(action) > -1);
  }

  function back() {
    window.history.back();
  }

  function limitedPermission(permissionClass) {
    return (permissionClass === 'Budget Limited' || permissionClass === 'PR Self');
  }

  function limitedPermissionLabel(permissionClass) {
    if (permissionClass === 'Budget Limited') {
      return 'Limited';
    } else if (permissionClass === 'PR Self') {
      return 'Self';
    }
  }

  function showList() {
    return (Object.keys($scope.selectedCompany).length > 0);
  }

  function getOrganizationCode() {
    if (Object.keys($scope.selectedCostCenter).length > 0) {
      return $scope.selectedCostCenter.code;
    } else {
      return $scope.selectedCompany.code;
    }
  }

  function getPermissions() {
      rolePermissions.get({
              roleName: $stateParams.roleName
          },
          function (resource) {
              $scope.permissions = resource.content;
          },
          function (error) {
              toastr.error('Something went wrong. Please refresh the page');
          });
  }

  function initialize() {
    getPermissions();
  }

  initialize();
}

permissionMatrixCtrl.$inject = [
  '$scope', 'globalFunc', '$state', 'toastr', 'securityFunction', 'listTitle', '$stateParams', 'rolePermissions'
];

angular
    .module('metabuyer')
    .controller('permissionMatrixCtrl', permissionMatrixCtrl);