'use strict';

/**
 * @name addSupplierBranches
 * @desc Controller to add branch to supplier
 */
function addSupplierBranches($scope, toastr, branches, globalFunc, $uibModalInstance, $timeout) {
  $scope.closeModal = closeModal;
  $scope.newBranch = newBranch;
  $scope.validateEmail = validateEmail;
  $scope.onUpdatedCountry = onUpdatedCountry;
  $scope.onUpdatedState = onUpdatedState;
  $scope.addContact = addContact;
  $scope.removeContact = removeContact;
  $scope.onUpdatedContact = onUpdatedContact;
  $scope.branches = {};
  $scope.branches.contacts = [];
  $scope.branches.corresponding_address = {};

  function closeModal() {
    $uibModalInstance.close();
  }

  $scope.branchStatus = [{
    descr: 'Active',
    value: 1,
    passValue: 'ACTIVE'
  }, {
    descr: 'Inactive',
    value: 0,
    passValue: 'INACTIVE'
  }];

  function newBranch() {

    if (validate().decision) {

      var temp = 0;
      if ($scope.receive_via_email) {
        temp += 2;
      }
      if ($scope.receive_via_fax) {
        temp += 4;
      }
      if ($scope.receive_via_post) {
        temp += 8;
      }
      $scope.branches.receiving_mode = temp;

      $uibModalInstance.close($scope.branches);
    } else {
      toastr.error('Please ' + validate().reason + ' to create a new branch');
    }
  }

  function onUpdatedCountry(country) {
    $scope.branches.corresponding_address.state = '';
    if (!!country.descr) {
      $scope.branches.corresponding_address.country = country.descr;
      $scope.selectedMalaysia = (country.descr === 'Malaysia');
    }
  }

  function onUpdatedState(state) {
    if (!!state) {
      $scope.branches.corresponding_address.state = state.descr;
    }
  }

  function addContact(contact) {
    var spliceIndex = $scope.branches.contacts[0];
    if (!!spliceIndex) {
      $scope.branches.contacts.push(contact);
    }
    /**
     * If the first index is null, undefined or empty
     */
    else {
      $scope.branches.contacts.splice(spliceIndex, 1, contact);
    }
  }

  function removeContact(index) {
    $scope.branches.contacts.splice(index, 1);
  }

  function onUpdatedContact(contact, index) {
    $scope.branches.contacts.splice(index, 1, contact);
  }

  function validateEmail(email) {
    return globalFunc.emailValidation(email);
  }

  function validatePostalCode(postalCode) {
    var regex = /^[a-z0-9]+$/i;
    return regex.test(postalCode);
  }

  function validateBranchUnique(name) {
    var sameBranchName = globalFunc.findInArray(branches, 'descr', name);
    return (!sameBranchName);
  }

  function validate() {

    var result = [];
    result.decision = false;
    if (!$scope.branches.descr) {
      result.reason = 'fill in Supplier Branch Name';
      return result;
    }

    if (!validateBranchUnique($scope.branches.descr)) {
      result.reason = 'use unique Supplier Branch Name';
      return result;
    }

    if ((!$scope.receive_via_fax && !$scope.receive_via_email
      && !$scope.receive_via_post)) {
      result.reason = 'select one method of receiving PO';
      return result;
    }

    if (!!$scope.receive_via_email) {
      if (!$scope.branches.email) {
        result.reason = 'fill in Branch Email Address';
        return result;
      }
    }

    if (!!$scope.branches.email && !validateEmail($scope.branches.email)) {
      result.reason = 'enter Branch Email Address with the correct format';
      return result;
    }

    if (!!$scope.receive_via_fax) {
      if (!$scope.branches.fax) {

        result.reason = 'fill in Branch Fax Number';
        return result;
      }
    }

    if (!$scope.branches.phone) {
      result.reason = 'fill in Branch Phone Number';
      return result;
    }

    if (!$scope.branches.corresponding_address || !$scope.branches.corresponding_address.line1 || !$scope.branches.corresponding_address.city ||
      !$scope.branches.corresponding_address.state || !$scope.branches.corresponding_address.country ||
      !$scope.branches.corresponding_address.postal_code) {
      result.reason = 'fill in Branch Corresponding Address';
      return result;
    }

    if (!validatePostalCode($scope.branches.corresponding_address.postal_code)) {
      result.reason = 'enter a correct postal code (alphanumeric only)';
      return result;
    }

    if ($scope.branches.contacts.length == 0) {
      result.reason = 'create a Branch contact person';
      return result;
    }

    if ($scope.branches.status !== 'ACTIVE' && $scope.branches.status !== 'INACTIVE') {
      result.reason = 'select Branch Status';
      return result;
    }

    result.decision = true;
    return result;
  }

  function initialize() {

    $scope.countries = globalFunc.countryCode();
    $scope.MYStates = globalFunc.malaysiaState();

    /**
     * To initialize country by looping and comparing descr with returned data
     */
    if (!!$scope.branches.corresponding_address.country) {
      if (!!globalFunc.findInArray($scope.countries, 'descr', $scope.branches.corresponding_address.country)
        && globalFunc.findInArray($scope.countries, 'descr', $scope.branches.corresponding_address.country).descr ===
        $scope.branches.corresponding_address.country) {
        $scope.selectedMalaysia = ($scope.address.branches.country === 'Malaysia');
      }
    }
  }

  initialize();

}

addSupplierBranches.$inject = [
  '$scope', 'toastr', 'branches', 'globalFunc', '$uibModalInstance', '$timeout'
];

angular
  .module('metabuyer')
  .controller('addSupplierBranches', addSupplierBranches);
