'use strict';


/**
 * Controller for budget v2 management
 * @description
 * Contains functions for handling tabs and table columns logic
 *
 * @param $scope
 * @param $state
 *
 * @watch $stateChangeStart
 * Watches on the start of state change, set status and params
 *
 * @author Ameer Asraf aka Jamal <ameerasraf_92@yahoo.com>
 * @copyright 2020 Metacloud Sdn. Bhd.
 */
function budgetManagementCtrlV2($scope, $state, globalFunc, $rootScope) {
  $scope.$emit('pageTitleChanged', 'Budgets List');

  $scope.tabData = [
    {
      heading: 'All',
      route: 'main.user.budgetV2.manage',
      key: 'all',
      id: 'allBudgetsTab',
      params: {
        status: 'all',
        cursor: null,
        order_by: 'created_at',
        order: 0,
        filter: null,
        query: null,
        type: null,
        page: 0
      }
    },
    {
      heading: 'Draft',
      route: 'main.user.budgetV2.manage',
      key: 'draft',
      id: 'draftBudgetsTab',
      params: {
        status: 'draft',
        cursor: null,
        order_by: 'created_at',
        order: 0,
        filter: null,
        query: null,
        type: null,
        page: 0
      }
    },
    {
      heading: 'Pending',
      route: 'main.user.budgetV2.manage',
      key: 'pending',
      id: 'pendingBudgetsTab',
      params: {
        status: 'pending',
        cursor: null,
        order_by: 'created_at',
        order: 0,
        filter: null,
        query: null,
        type: null,
        page: 0
      }
    },
    {
      heading: 'Approved',
      route: 'main.user.budgetV2.manage',
      key: 'approved',
      id: 'approvedBudgetsTab',
      params: {
        status: 'approved',
        cursor: null,
        order_by: 'created_at',
        order: 0,
        filter: null,
        query: null,
        type: null,
        page: 0
      }
    }
  ];

  $scope.tabData.more = [
    {
      heading: 'Expired',
      route: 'main.user.budgetV2.manage',
      key: 'expired',
      id: 'expiredBudgetsTab',
      type: 'more',
      params: {
        status: 'expired',
        cursor: null,
        order_by: 'descr',
        order: 0,
        filter: null,
        query: null,
        page: 0
      }
    },
    {
      heading: 'Withdrawn',
      route: 'main.user.budgetV2.manage',
      key: 'withdraw',
      id: 'withdrawnBudgetsTab',
      type: 'more',
      params: {
        status: 'withdraw',
        cursor: null,
        order_by: 'descr',
        order: 1,
        filter: null,
        query: null,
        page: null
      }
    },
    {
      heading: 'Rejected',
      route: 'main.user.budgetV2.manage',
      key: 'rejected',
      id: 'rejectedBudgetsTab',
      type: 'more',
      params: {
        status: 'rejected',
        cursor: null,
        order_by: 'descr',
        order: 0,
        filter: null,
        query: null,
        page: 0
      }
    },
    {
      heading: 'Inactive',
      route: 'main.user.budgetV2.manage',
      key: 'inactive',
      id: 'inactiveBudgetsTab',
      type: 'more',
      params: {
        status: 'inactive',
        cursor: null,
        order_by: 'descr',
        order: 0,
        filter: null,
        query: null,
        page: 0
      }
    },
    {
      heading: 'Deleted',
      route: 'main.user.budgetV2.manage',
      key: 'deleted',
      id: 'deletedBudgetsTab',
      type: 'more',
      params: {
        status: 'deleted',
        cursor: null,
        order_by: 'descr',
        order: 0,
        filter: null,
        query: null,
        page: 0
      }
    }
  ];

  function initialize() {

    if (!!$state.params && !!$state.params.query) {
      $scope.searchText = $state.params.query;
    }
    else {
      $scope.searchText = '';
    }
    if (!!$state.params && !!$state.params.filter) {
      $scope.searchFilter = $state.params.filter;
    }

    if (!!$state.params && !!$state.params.status) {
      $scope.status = $state.params.status;
      //set the default tab on refreshing
      var tempIndex = _.findIndex($scope.tabData, function(tab){
        return tab.params.status === $state.params.status.toLowerCase();
      });

      if(tempIndex < 0) {
        var tempIndex2 = _.findIndex($scope.tabData.more, function (tab) {
          return tab.params.status === $state.params.status
        });
        if(tempIndex2 >= 0) {
          $scope.activatedTab = $scope.tabData.more[tempIndex2];
        }
      }
      else
        $scope.activatedTab = $scope.tabData[tempIndex];
    }

    $scope.searchPlaceholder = 'Search Budgets';
    $scope.searchState = 'main.user.budgetV2.manage';
    $scope.advancedSearchState = 'main.user.advancedSearch.budgetV2';
    $scope.parentState = 'main.user.budgetV2.manage';
    $scope.parentStateParams = $state.params;
  }

  $scope.columns = [
    {
      id: 'check box',
      title: ' ',
      unsortable: true,
      unsearchable: true,
      conditional: true,
      condition: ['pending'],
      unSelectable: true
    },
    {id: 'code', label: 'Budget Code'},
    {id: 'descr', label: 'Title'},
    {
      id: 'waiting_on.display_name',
      label: 'Waiting on',
      conditional: true,
      condition: ['pending'],
      unsortable: true
    },
    {id: 'company|descr', label: 'Company'},
    {id: 'shadow_companies|descr', label: 'Billing Company(s)', unsortable: true},
    {id: 'cost_center|descr', label: 'Cost Center(s)', unsortable: true},
    {id: 'expense_type_category', label: 'Budget Type'},
    {id: 'status', label: 'Status', unsearchable: true},
    {id: 'progress', label: 'Progress',
      condition: 'pending', conditional: true},
    {id: 'start_date', label: 'Start Date', type: 'date', unsearchable: true},
    {id: 'end_date', label: 'End Date', type: 'date', unsearchable: true},
    {id: 'amount', label: 'Amount', type: 'double', unsearchable: true},
    {id: 'used_amount', label: 'Used Amount',type: 'double', unsearchable: true,
      condition: 'approved', conditional: true},
    {id: 'committed_amount', label: 'Committed Amount',type: 'double', unsearchable: true,
      condition: 'approved', conditional: true},
    {id: 'block_amount', label: 'Blocked Amount',type: 'double', unsearchable: true,
      condition: 'approved', conditional: true},
    {id: 'available_balance', label: 'Available Balance',type: 'double', unsearchable: true,
      condition: 'approved', conditional: true},
    {id: 'total_non_purchasing_cost', label: 'Non Purchasing Cost', type: 'double', unsearchable: true},
    {id: 'created_at', label: 'Created At', type: 'date', unsearchable: true},
    {id: 'creator_info|display_name', label: 'Created By'},
    {id: 'updated_at', label: 'Updated At', type: 'date', unsearchable: true},
    {id: 'updater_info|display_name', label: 'Updated By'},
    { id: 'years|value', label: 'Budget Year' },
    {
      id: 'delete_button',
      label: '',
      unsortable: true,
      unsearchable: true,
      unSelectable: true
    }
  ];

  if ($rootScope.isV2Enabled){
    $scope.columns.splice.apply($scope.columns, [1, 0].concat([{id: 'erp_budget_id', label: 'Erp Budget ID'}]));
  }

  $scope.$on('$stateChangeStart', function( event, toState, toParams ) {
    if(!!toParams.status){
      $scope.status = toParams.status;
      $scope.parentStateParams = toParams;
    }
  });

  initialize();
}
budgetManagementCtrlV2.$inject = ['$scope', '$state', 'globalFunc', '$rootScope'];

angular.module('metabuyer')
  .controller('budgetManagementCtrlV2', budgetManagementCtrlV2);
