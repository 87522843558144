'use strict';

function grnManageCtrl($scope, grns) {

  $scope.dataList = grns.data;
  $scope.showColumn = showColumn;
  $scope.meta = grns.meta;

  $scope.storageKey = 'grn-management-selected-columns';

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional) {
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }

}

grnManageCtrl.$inject = ['$scope', 'grns'];

angular.module('metabuyer')
  .controller('grnManageCtrl', grnManageCtrl);
