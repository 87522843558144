'use strict';

function editAddressCtrl($scope, $uibModalInstance, toastr, $q, addressList, singleAddress) {
  $scope.edit_data = {};
  if(!_.isArray($scope.editAddress) && !!$scope.editAddress){
    $scope.edit_data = _.cloneDeep($scope.editAddress);
  }

  //cancel action
  $scope.cancel = function(){
    $uibModalInstance.close();
  };

  $scope.validateEditAddress = function(){
    if(!$scope.edit_data.line1){
      return false;
    }

    if(!$scope.edit_data.state){
      return false;
    }

    if(!$scope.edit_data.city){
      return false;
    }

    if(!$scope.edit_data.country){
      return false;
    }

    return true;
  };

  function getUpdateData(){
    var update_data = {line1: $scope.edit_data.line1
      , city: $scope.edit_data.city
      , state: $scope.edit_data.state
      , country: $scope.edit_data.country};

    if(!!$scope.edit_data.line2){
      update_data.line2 = $scope.edit_data.line2;
    }

    if(!!$scope.edit_data.line3){
      update_data.line3 = $scope.edit_data.line3;
    }

    if(!!$scope.edit_data.postal_code){
      update_data.postal_code = $scope.edit_data.postal_code;
    }

    return update_data;
  }

  //proceed action
  $scope.update = function(){
    if($scope.validateEditAddress() == false){
      toastr.error("Please fill all required fields");
      return;
    }

    if(!$scope.editAddress || !$scope.editAddress._id){
      addressList.post(getUpdateData(),
        function(resource){

          toastr.success('Updated address');
          $uibModalInstance.close();

          if(!!resource && !!resource.content && !!resource.content.data && !!resource.content.data._id){
            $scope.edit_data._id = resource.content.data._id;
          }

          $scope.updatedAddress({address: $scope.edit_data});

        }, function(error){

        });

      return;
    }

    singleAddress.put({id: $scope.editAddress._id}, getUpdateData(),
      function(success){
        toastr.success("Updated address");

        $uibModalInstance.close();

        $scope.updatedAddress({address: $scope.edit_data});

      }, function(error){
        toastr.error('Failed to update address');
        $uibModalInstance.close();
      });
  };
}

editAddressCtrl.$inject = ['$scope', '$uibModalInstance', 'toastr', '$q', 'addressList', 'singleAddress'];

angular.module('metabuyer')
  .controller('editAddressCtrl', editAddressCtrl);
