'use strict';


function importItemsCtrl(
  $rootScope, $scope, toastr, pathConstants, $http, $filter, $q, updateStatusCatalog, BUs, $state, Approvals,
  checkImportCatalog, searchModule, globalFunc, supplier, catalogCode, $window, UserPermissions, profile
) {

  $scope.disableSubmitting = true;
  $scope.success = false;
  $scope.error = false;
  $scope.saveImage = saveImage;
  $scope.saveFile = saveFile;
  $scope.checkImportFile = checkImportFile;
  $scope.importingItems = importingItems;
  $scope.getItemList = getItemList;
  $scope.selectCatalogGroup = selectCatalogGroup;
  $scope.selectSupplier = selectSupplier;
  $scope.getCatalogCodeList = getCatalogCodeList;
  $scope.backToSelectSupplier = backToSelectSupplier;
  $scope.onSelectedCatalogCode = onSelectedCatalogCode;
  $scope.openDatePicker = openDatePicker;
  $scope.setEffectiveDate = setEffectiveDate;
  $scope.supplierListing = supplierListing;
  $scope.requestorListing = requestorListing;
  $scope.clearSupplierSearch = clearSupplierSearch;
  $scope.formatModel = formatModel;
  $scope.uploadButtonIsDisabled = uploadButtonIsDisabled;
  $scope.totalCatalogItems = 0;
  $scope.input = {
    importRequestor:null
  };

  $scope.selectedSupplierObject = {};
  $scope.invalidSupplierSelected = false;

  // Pagination setting
  $scope.filteredItems = [];
  $scope.currentPage = {
    number: 1
  };
  $scope.numPerPage = 20;
  $scope.maxSize = 5;
  $scope.pageChanged = pageChanged;

  function pageChanged() {
    var begin = (($scope.currentPage.number - 1) * $scope.numPerPage);
    var end = begin + $scope.numPerPage;
    $scope.filteredItems = $scope.itemDetailsList.slice(begin, end);
  }
  // Pagination

  $scope.approvals = [];
  $scope.limit = 10;

  $scope.dateOptions = {
    formatYear: 'yy',
    minDate: new Date(),
    startingDay: 1
  };
  $scope.datepickerOpened = {
    startDate: false
  };

  $scope.catalogCodeSelected = false;

  //TODO: remove this to a service of its own (Ahmed Saleh, 9/5/2016)
  $scope.column = {
    columns: [
      {id: 'item_name', label: 'Item Name'},
      {id: 'supplier_part_no', label: 'Supplier Part Number'},
      {id: 'tax_rate', label: 'Tax Rate'},
      {id: 'payment_terms', label: 'Payment Term'},
      {id: 'currency', label: 'Currency'},
      {id: 'unit_price', label: 'Unit Price'},
      {id: 'uom', label: 'UOM'},
      {id: 'effective_at', label: 'Effective Date'},
      {id: 'expiry_at', label: 'Expiry Date'}
    ],
    selectedColumns: [],
    buttonText: {buttonDefaultText: 'Columns'},
    setting: {
      buttonClasses: 'btn btn-white',
      smartButtonMaxItems: 3,
      showCheckAll: false,
      showUncheckAll: false
    },
    events: {
      onItemSelect: function (item) {
        $scope.column.saveSelectedColumn($scope.column.selectedColumns);
      },
      onItemDeselect: function (item) {
        $scope.column.saveSelectedColumn($scope.column.selectedColumns);
      }
    },
    showColumn: function (name) {
      for (var index = 0; index < $scope.column.selectedColumns.length; ++index) {
        var item = $scope.column.selectedColumns[index];
        if (item.id == name) {
          return true;
        }
      }
      return false
    },
    saveSelectedColumn: function (columns) {
      window.localStorage.setItem('item-listing-selected-columns',
        JSON.stringify(_.map(columns, function (item) {
          return item.id || item
        })));
    },
    loadSelectedColumn: function () {
      return JSON.parse(window.localStorage.getItem('item-listing-selected-columns')) || [];
    }
  };
  // Apply selected column
  var selectedColumn = $scope.column.loadSelectedColumn();
  if (selectedColumn.length == 0) { // Default column
    for (var index = 0; index < $scope.column.columns.length; ++index) {
      $scope.column.selectedColumns.push($scope.column.columns[index]);
    }
  }
  else {
    for (index = 0; index < selectedColumn.length; ++index) {
      var column = _.find($scope.column.columns, function (item) {
        return item.id == selectedColumn[index];
      });
      if (!!column)
        $scope.column.selectedColumns.push(column);
    }
  }

  var valid = function () {
    if (!$scope.selectedSupplier) {
      toastr.error('Please Choose a Supplier');
      return false;
    }
    if (!$scope.selectedCatalogGroup || (!!$scope.selectedCatalogGroup && !$scope.selectedCatalogGroup._id)) {
      toastr.error('Please Choose a Catalog Group');
      return false
    }
    return true;
  };

  $scope.cancelCatalog = function()
  {
    updateStatusCatalog.put(
      {
        status: 'cancel',
        id: $scope.importId
      },
      function(resource)
      {
        $state.go('main.user.items.import.catalog');
        toastr.success('Catalog successfully cancelled.');
      },
      function(error)      {
        toastr.error('Cancelling the catalog failed');
      }
    );

  };

  $scope.submitCatalog = function()
  {
    if(!$scope.importId){
      toastr.error('Please reupload the catalog!');
    }
    else{
      updateStatusCatalog.put(
        {
          status: 'submit',
          id: $scope.importId
        },
        function (resource) {
          $state.go('main.user.items.manageCatalog', {'status': 'pending'});
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    }
  };

  function selectCatalogGroup(catalogGroupID){
    $scope.selectedCatalogGroup = globalFunc.findInArray($scope.BUs, '_id', catalogGroupID);
  }

  function backToSelectSupplier(){
    $scope.selectedCatalogCode = null;
    $scope.catalogCodeSelected = false;
    $scope.newCatalogCodeSelected = false;
    $state.go('main.user.items.import.select_supplier');
  }

  /**
   * the type ahead for the supplier search
   * @param val
   * @returns {*|webdriver.promise}
   */
  function supplierListing(val) {
    if (!!val && val.length > 2) {
      $scope.loading = true;

      var moduleParam = {
        module: 'suppliers'
      };

      var additionalParams = {
        'criteria[0][basic_info|descr]': val,
        criteria_operator: 'and',
        offset: 10
      };

      if (!!$rootScope.isSourcingSupplierEnabled) {
        additionalParams['criteria[1][basic_info|is_p2o_supplier]'] = true;
        additionalParams['criteria[2][basic_info|status][0]'] = 'active';
        additionalParams['criteria[2][basic_info|status][1]'] = 'inactive';
        additionalParams['criteria[2][basic_info|status][2]'] = 'blacklisted';
        additionalParams['criteria[2][$strict]'] = true;
      } else {
        additionalParams['criteria[1][basic_info|status][0]'] = 'active';
        additionalParams['criteria[1][basic_info|status][1]'] = 'inactive';
        additionalParams['criteria[1][basic_info|status][2]'] = 'blacklisted';
        additionalParams['criteria[1][$strict]'] = true;
      }

      return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: additionalParams
      }).then(function (response) {
        $scope.loading = false;
        return response.data.content.data.map(function (item) {
          $scope.loading = false;
          return modifySupplierObject(item);
        });
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * Check if button upload is disabled
   * Due to incomplete form input
   *
   * @returns {boolean}
   */
  function uploadButtonIsDisabled() {
    return (!$scope.selectedCatalogGroup ||
      !$scope.selectedSupplier ||
      !$scope.selectedCatalogCode ||
      !$scope.importEffectiveDate);
  }

  /**
   * Function to modify supplier object and pass label class as html
   */
  function modifySupplierObject(supplier) {
    supplier.labelStatus = 'warning';
    if (supplier.basic_info.status.toLowerCase() === 'active') {
      supplier.labelStatus = 'primary';
    }

    return supplier;
  }

  /**
   * Function to format the ng-model after typeahead selected
   */
  function formatModel(model) {
    var indexOfHtml = model.indexOf('<');
    $scope.selectedSupplierObject.supplierName = model.substring(0, indexOfHtml);
  }

  function clearSupplierSearch(){
    document.getElementById("searchSupplier").value = "";
    $scope.invalidSupplierSelected = false;
    $scope.selectedSupplierObject = {};
    $scope.selectedSupplier = undefined;
  }

  function requestorListing(val) {
    var moduleParam = {
      module: 'users'
    };

    var additionalParams = {
      'criteria[0][display_name]': val,
      'criteria[0][is_metabuyer_user]': true,
      'criteria[0][status]': 1,
      criteria_operator: 'and',
      offset: 10
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams
    }).then(function (response) {
      $scope.loading = false;
      return response.data.content.data.map(function (item) {
        $scope.loading = false;
        return item;
      });
    }, function (error) {
      globalFunc.objectErrorMessage(error);
    });

  }

  function getCatalogCodeList(){
    searchModule.get(
      {
        module: 'catalog-code',
        offset: 0,
        'criteria[is_active]': 1
      },
      function(resource){
        $scope.catalogCodeList = resource.content.data;
      },
      function(error){
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function onSelectedCatalogCode(selectedCatCode){
    $scope.selectedCatalogCode = selectedCatCode;
    $scope.catalogCodeSelected = true;
    $scope.newCatalogCodeSelected = false;
  }

  function openDatePicker($event, which) {
    $event.preventDefault();
    $event.stopPropagation();

    $scope.datepickerOpened[which] = true;
  }

  function setEffectiveDate(effective_at){
    $scope.importEffectiveDate = globalFunc.convertDateToTimestamp(effective_at);
  }

  /**
   *  Receive broadcast for updated embedded pagination data to update on controller side
   */
  $scope.$on('updateEmbeddedPagination', function(events, content){
    if(!!content && !!content.data)
      $scope.itemDetailsList = content.data;
  });

  function selectSupplier(supplier) {
    var text = '';

    $scope.invalidSupplierSelected = false;
    if (!supplier || (!!supplier.basic_info.status && supplier.basic_info.status.toLowerCase() !== 'active')) {
      if (supplier.basic_info.status.toLowerCase() === 'inactive') {
        text = 'The selected Supplier has already been deactivated. Please select another Supplier.';
      } else if (supplier.basic_info.status.toLowerCase() === 'blacklisted') {
        text = 'The selected Supplier has already been blacklisted. Please select another Supplier.';
      } else {
        text = 'The selected Supplier is not valid, please select another supplier.';
      }

      globalFunc.objectErrorMessage(text);
      $scope.item = undefined;
      $scope.invalidSupplierSelected = true;
      return false;
    }

    $scope.selectedSupplier = supplier;
  }


  // Check Radio button has a value or not.
  $scope.isRadioSelected = function(index) {
    return index === 1;
  };

  // Import and save CSV file
  function saveFile(items, images, effective_date, catalog_code_id) {


    var fd = new FormData();
    fd.append('file', items);
    fd.append('supplier_id', $scope.selectedSupplier._id);
    fd.append('catalog_group_id', $scope.selectedCatalogGroup._id);
    fd.append('catalog_code_id', catalog_code_id);
    fd.append('import_effective_date', effective_date);
    if (!!$scope.input.importRequestor) {
      fd.append('requestor', $scope.input.importRequestor);
    }
    if(!!$scope.errorImportID){
      fd.append('import_summary_id', $scope.errorImportID);
    }

    $http.post($filter('format')(pathConstants.apiUrls.catalogs.import), fd, {
      transformRequest: angular.identity,
      headers: {'Content-Type': undefined}
    })
      .then(function onSuccess (dataCatalog, status) {
        $scope.success = true;
        $scope.error = false;
        $scope.status = status;

        //prepare the success Message
        var data = dataCatalog.data;
        $scope.message = {};
        if (!!data && !!data.content) {
          $scope.data = data.content.data;
          $scope.message.header = $scope.data.file_header;
          $scope.message.version = $scope.data.import_version;
          $scope.message.importInfo = $scope.data.import_summary;
          $scope.importId = $scope.data._id;
          $scope.totalCatalogItems = calculateTotalCatalogItem(data.content.data);
          getItemList(13);

          if($scope.data.attachment.length === 0) {
            toastr.error('Failed to upload file.');
          } else {
            $scope.catalogId = $scope.data.attachment[0].reference_id;

            toastr.success('File successfully uploaded');
            $scope.disableSubmitting = false;

            // Check image empty or not
            // If empty, redirected to next step
            if(!!images) {
              $scope.saveImage(images, $scope.catalogId);
            }else{
              $state.go('main.user.items.import.confirmation');
              //checkConfirmationPage();
            }

            // Run loadApprovalFlow function
            loadApprovalFlow($scope.data.approval_id);
            $scope.requestor = {
              '_id': '',
              'display_name': $scope.data.created_by,
              'img_url':''
            };
          }

        } else {
          // No data received
          toastr.error('Failed to upload file.');
        }

      }, function onError (errorMessage) {

        $scope.success = false;
        $scope.error = true;
        $scope.data = null;

        if (!!errorMessage.data && !!errorMessage.data.content && !!errorMessage.data.content.error) {
          $scope.errors = errorMessage.data.content.error;
          if (_.isString(errorMessage.data.content.error)) {
            $scope.errors = [errorMessage.data.content.error];
          }
          $window.scrollTo(0,0);
          toastr.error('Failed to upload catalog.');
          $scope.disableSubmitting = true;
        }
      });
  }

  function calculateTotalCatalogItem(catalog) {
    var importSummary = catalog.import_summary;
    var total = 0;
    total += importSummary.new.total;
    total += importSummary.update.total;
    total += importSummary.no_update.total;
    return total;
  }

  /**
   * Populate item list
   *
   * @params status: 10 New, 11 Updated, 12 Deleted, 13 Total
   */
  function getItemList(status) {
    $scope.itemDetailsList = [];
    $scope.showItemDetails = true;
    $scope.itemDetailsStatus = status;
    $scope.filteredItems = [];
    $scope.currentPage = {
      number: 1
    };
    var itemIds = [];

    switch (status) {
      case 10:
        $scope.itemDetailTitle = 'New Item(s)';
        if ($scope.data.import_summary.new.total > 0) {
          itemIds = $scope.data.import_summary.new.items;
          $scope.itemDetailsList = _.filter($scope.data.items, function(item){
            return itemIds.indexOf(item._id) !== -1;
          });
          $scope.filteredItems = $scope.itemDetailsList.slice(0, $scope.numPerPage);
        }
        break;
      case 11:
        $scope.itemDetailTitle = 'Updated Item(s)';
        if ($scope.data.import_summary.update.total > 0) {
          itemIds = $scope.data.import_summary.update.items;
          $scope.itemDetailsList = _.filter($scope.data.items, function(item){
            return itemIds.indexOf(item._id) !== -1;
          });
          $scope.filteredItems = $scope.itemDetailsList.slice(0, $scope.numPerPage);
        }
        break;
      case 12:
        $scope.itemDetailTitle = 'Deleted Item(s)';
        if ($scope.data.import_summary.delete.total > 0) {
          $scope.itemDetailsList = $scope.data.import_summary.delete.items;
          $scope.filteredItems = $scope.itemDetailsList.slice(0, $scope.numPerPage);
        }
        break;
      case 13:
        $scope.itemDetailTitle = 'Total Items';
        $scope.itemDetailsList = $scope.data.items;
        $scope.filteredItems = $scope.itemDetailsList.slice(0, $scope.numPerPage);
        break;
    }
  }

  // Import and save Image file
  function saveImage(images, summaryId) {
    if(!!images) {
      var fd = new FormData();
      fd.append('file', images);
      $http.post($filter('format')(pathConstants.apiUrls.items.imagesImport + summaryId), fd, {
        transformRequest: angular.identity,
        headers: {'Content-Type': undefined}
      })
        .then(function onSuccess () {
          toastr.success('Images successfully uploaded');
          $state.go('main.user.items.import.confirmation');
          //checkConfirmationPage();
        })
        .catch(function onError () {
          toastr.error('Failed to submit the Images');
        });
    }
  }

  // Action to save Import file
  function importingItems(items, images, effective_date, catalog_code_id) {
    $scope.error = null;
    $scope.status = null;

    //uploading the CSV file
    if(valid())
      saveFile(items, images, effective_date, catalog_code_id);
  }

  // Approval Flow Data
  function loadApprovalFlow(approvalId){

    var deferred = $q.defer();

    if(!approvalId){
      $scope.approvals = [];
      deferred.resolve([]);
      return deferred.promise;
    }

    Approvals.get(
      {
        id: approvalId
      },
      function(resource){
        if(!!resource && !!resource.content && !!resource.content.data &&
          !!resource.content.data.flow){
          $scope.approvals = resource.content.data.flow;
          deferred.resolve(resource.content.data.flow);
        } else {
          $scope.approvals = [];
          deferred.resolve([]);
        }
      },
      function (error){
        $scope.approvals = [];
        deferred.resolve([]);
      });

    return deferred.promise;
  }

  // Check the same Import file
  // that not approved yet
  function checkImportFile(item, images){
    var catalog_code_id;

    if(!!$scope.catalogCodeSelected)
      catalog_code_id = $scope.selectedCatalogCode._id;

    if(valid()) {
      checkImportCatalog.post(
        {
          supplier_id: $scope.selectedSupplier._id,
          catalog_group_id: $scope.selectedCatalogGroup._id,
          catalog_code_id: catalog_code_id
        },
        function (resource) {
          // Check the message
          // If empty
          if (!!resource.content  && !!resource.content.message) {
            // Show the message in alert Confirmation
            // if the user choose ok then continue to import file
            // if the user choose cancel redirect to catalog list
            swal(
              {
                title: 'Import alert!',
                text: resource.content.message,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1ab394',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                closeOnConfirm: true,
                closeOnCancel: true
              },
              function (is_confirm) {
                if (is_confirm) {
                  importingItems(item, images, $scope.importEffectiveDate, catalog_code_id);
                } else {
                  $state.go('main.user.items.manageCatalog', {'status': 'pending'});
                }
              }
            );
          }
          else{
            importingItems(item, images, $scope.importEffectiveDate, catalog_code_id);
          }
        },
        function (error) {
          //failed to load
          toastr.error('Failed to check the import file');
        }
      );
    }
  }

  function initialize(){
    $scope.loading = false;
    $scope.BUs = BUs.data;
    if (UserPermissions.hasCmmsUserRole(profile) && !UserPermissions.hasTenantSuperAdminRole(profile)) {
      $scope.BUs = _.filter(BUs.data, function(catalog) {
        return catalog.code === 'CMMS';
      });
    }

    $scope.importEffectiveDate = undefined;

    // Load catalog code list
    getCatalogCodeList();

    if(!!$state.params.importID && !!$state.params.catalogGroupID &&
      !!$state.params.supplierID && !!$state.params.catalogCodeID){
      $scope.errorImportID = $state.params.importID;
      selectCatalogGroup($state.params.catalogGroupID);
      supplier.get(
        {id: $state.params.supplierID},
        function(resource){
          if(!!resource && !!resource.content && !!resource.content.data){
            selectSupplier(resource.content.data);
          }
          else{
            toastr.error('Invalid supplier');
            window.history.back();
          }
        }, function(error){
          globalFunc.objectErrorMessage(error);
        });

      catalogCode.get(
        {
          id: $state.params.catalogCodeID
        },
        function(resource){
          if(!!resource && !!resource.content && !!resource.content.data){
            onSelectedCatalogCode(resource.content.data);
          }
          else{
            toastr.error('Invalid catalog code');
            window.history.back();
          }
        },
        function(error){
          globalFunc.objectErrorMessage(error);
        }
      );
    }
    else{
      $scope.errorImportID = undefined;
      $scope.selectedCatalogGroup = undefined;
      $scope.selectedSupplier = undefined;
      $scope.selectedCatalogCode = undefined;
    }
  }

  initialize();
}

importItemsCtrl.$inject = [
  '$rootScope', '$scope', 'toastr', 'pathConstants', '$http', '$filter', '$q', 'updateStatusCatalog', 'BUs',
  '$state', 'Approvals', 'checkImportCatalog', 'searchModule', 'globalFunc', 'supplier', 'catalogCode', '$window',
  'UserPermissions', 'profile'
];

angular.module('metabuyer')
  .controller('importItemsCtrl', importItemsCtrl);
