'use strict';
/**
 * @name countdownTimer Directive
 * @desc Directive to show a countdown timer based on an input for needed by date
 *
 * @author Ahmed Saleh <a.s.alsalali@gmail.com>
 * @copyright Sunway Metacloud &copy; 2020
 */
angular
  .module('metabuyer')
  .directive('countdownTimer',function() {
    return {
      restrict: 'E',
      templateUrl: 'components/purchaseRequisitionV2Components/countDownTimer/countdownTimerTemplate.html',
      scope: {
        value: '=',
        refreshInterval: '=?'
      },
      controller: function ($scope, $interval) {
        var interval = 60000; //1 min interval
        if (!!$scope.refreshInterval || typeof $scope.refreshInterval === Number) {
          interval = $scope.refreshInterval;
        }
        var id = $interval(function () {
          $scope.value -= 60;
          convertSeconds($scope.value);
        }, interval, 0);

        function convertSeconds(seconds) {
          if (seconds <= 0) {
            $scope.days = '0';
            $scope.hours = '0';
            $scope.minutes = '0';
            $interval.cancel(id);
          } else {
            // moment day format has 1 day as minimum, so using maths here
            $scope.days = Math.floor(seconds / (3600*24));
            $scope.hours = moment.utc(seconds*1000).format('HH');
            $scope.minutes = moment.utc(seconds*1000).format('mm');
          }
        }

        function initialize() {
          convertSeconds($scope.value);
        }

        initialize()
      }
    }
  });
