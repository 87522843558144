'use strict';

angular.module('metabuyer.services.purchaseRequisitions', ['ngResource'])
  .factory('purchaseRequisitionsServices', function($resource, pathConstants){
    return $resource(
      pathConstants.apiUrls.purchaseRequisitions,
      {},
      {
        'getSinglePR': {
          method: 'GET',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.getSinglePR
        },
        'deleteSinglePR': {
          method: 'DELETE',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.getSinglePR
        },
        'searchPRs': {
          method: 'GET',
          params: {},
          url: pathConstants.apiUrls.purchaseRequisitions.searchPRs
        },
        'createPR': {
          method: 'POST',
          params: {},
          url: pathConstants.apiUrls.purchaseRequisitions.createPR
        },
        'createPRWithItems': {
          method: 'POST',
          params: {},
          url: pathConstants.apiUrls.purchaseRequisitions.createPRWithItems
        },
        'updatePRCompanyInfo': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.updatePRCompanyInfo
        },
        'checkPRCompanyInfo': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.checkPRCompanyInfo
        },
        'setPPContactPerson': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.setPRContactPerson
        },
        'getContactPersonsList': {
          method: 'GET',
          ignoreLoadingBar: true,
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.getPRContactPersons
        },
        'getAvailableDeliveryAddresses': {
          method: 'GET',
          ignoreLoadingBar: true,
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.getAvailableDeliveryAddresses
        },
        'getAvailableRequestorsList': {
          method: 'GET',
          ignoreLoadingBar: true,
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.getAvailableRequestorsList
        },
        'setExpenseType': {
          method: 'POST',
          ignoreLoadingBar: true,
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.setExpenseType
        },
        'setPaymentTerm': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.setPaymentTerm
        },
        'setRequestedBy': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.setRequestedBy
        },
        'setNeededByDate': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.setNeededByDate
        },
        'updateTitle': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.setPRTitle
        },
        'setRemarks': {
          method: 'PUT',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.setRemarks
        },
        'submitPR': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.submitPR
        },
        'withdrawPR': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.withdrawPR
        },
        'setItem': {
          method: 'PUT',
          params: {
            id: '@id',
            action: '@action'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.item
        },
        'deleteItemField': {
          method: 'DELETE',
          params: {
            id: '@id',
            action: '@action'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.item
        },
        'addLineItem': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.item
        },
        'deleteLineItem': {
          method: 'DELETE',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.item
        },
        'createNonCatalogItem': {
          method: 'POST',
          params: {
            id: '@user_id'
          },
          url: pathConstants.apiUrls.items.nonCatalog
        },
        'setLumpSum': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.lumpSum
        },
        'removeLumpSum': {
          method: 'DELETE',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.lumpSum
        },
        'setLumpSumAccountCode': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.lumpSumAccountCode
        },
        'addCollaborator': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.collaborators
        },
        'removeCollaborator': {
          method: 'DELETE',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.collaborators
        },
        'approvalAction': {
          method: 'POST',
          params: {
            approval_id: '@approval_id',
            status: '@status'
          },
          url: pathConstants.apiUrls.approvals.status
        },
        'printPdf': {
          method: 'GET',
          params: {
            approval_id: '@approval_id',
            status: '@status'
          },
          url: pathConstants.apiUrls.approvals.status
        },
        'duplicate': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.duplicate
        },
        'addSupplierNote': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.supplierNotes
        },
        'updateSupplierNote': {
          method: 'PUT',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.supplierNotes
        },
        'updateSkipAutoSendPO': {
          method: 'PUT',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.updateSkipAutoSendPO
        },
        'moveToDraft': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.moveToDraft
        },
        'requestEditableCopy': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.requestEditableCopy
        },
        'submitEditableCopy': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.submitEditedCopy
        },
        'cancelEditableCopy': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.cancelEditedCopy
        },
        'resetApproval': {
          method: 'POST',
          params: {
            approval_id: '@approval_id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.resetApproval
        },
       'resendPendingPrEmail' : {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.resendPendingPrEmail
        },
        'updateIntegrationInfo': {
          method: 'PUT',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.updateIntegrationInfo
        },
        'getPrConfig': {
          method: 'GET',
          url: pathConstants.apiUrls.purchaseRequisitions.config
        },
        'getResearch': {
          method: 'GET',
          params: {
            company_code: '@company_code'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.research
        },
        'getStudentLifeEvent': {
          method: 'GET',
          params: {
            company_code: '@company_code'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.studentLifeEvent
        },
        'getSpecialEvent': {
          method: 'GET',
          params: {
            company_code: '@company_code'
          },
          url: pathConstants.apiUrls.purchaseRequisitions.specialEvent
        }
      }
    );

  })
  .factory('getPRPDFHash',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.purchaseRequisitions.pdfHash,
        {
          id: '@id'
        },
        {
          'get': { method: 'GET', ignoreLoadingBar: true }
        }
      );
    });
