'use strict';

angular.module('metabuyer')
  .filter('toDate', ['$filter', function ($filter) {
    return function (value) {
      if (!value) return value;
      return Date.parse(value);
    }
  }])
  .filter('aging', ['$filter', function ($filter) {
    return function (value) {
      if (!value) return value;
      var hours = $filter('amDifference')(value, null, 'hours') * -1
      if (hours > 24)
        return $filter('amDifference')(value, null, 'days') * -1 + "d";
      return hours + "h"
    }
  }])
  .filter('unique', [function () {
    return function (value, property, alternateProperty) {
      var properties = !!property ? property.split('.') : [];
      var alternateProperties = !!alternateProperty ? alternateProperty.split('.') : [];
      return _.unique(_.map(value, function (item) {
        var newValue = item;
        for (var index = 0; index < properties.length && !!newValue; ++index)
          newValue = newValue[properties[index]];
        if (!!newValue)
          return newValue;
        newValue = item;
        for (var index = 0; index < alternateProperties.length && !!newValue; ++index)
          newValue = newValue[alternateProperties[index]];
        return newValue
      }));
    }
  }])
  .filter('list', [function () {
    return function (value, property, alternateProperty) {
      var properties = !!property ? property.split('.') : [];
      var alternateProperties = !!alternateProperty ? alternateProperty.split('.') : [];
      return _.map(value, function (item) {
        var newValue = item;
        for (var index = 0; index < properties.length && !!newValue; ++index)
          newValue = newValue[properties[index]];
        if (!!newValue)
          return newValue;
        newValue = item;
        for (var index = 0; index < alternateProperties.length && !!newValue; ++index)
          newValue = newValue[alternateProperties[index]];
        return newValue
      });
    }
  }])
  .filter('max', [function () {
    return function (value, property, alternateProperty) {
      var properties = !!property ? property.split('.') : [];
      var alternateProperties = !!alternateProperty ? alternateProperty.split('.') : [];
      return _.max(_.map(value, function (item) {
        var newValue = item;
        for (var index = 0; index < properties.length && !!newValue; ++index)
          newValue = newValue[properties[index]];
        if (!!newValue)
          return newValue;
        newValue = item;
        for (var index = 0; index < alternateProperties.length && !!newValue; ++index)
          newValue = newValue[alternateProperties[index]];
        return newValue
      }));
    }
  }])
  .filter('min', [function () {
    return function (value, property, alternateProperty) {
      var properties = !!property ? property.split('.') : [];
      var alternateProperties = !!alternateProperty ? alternateProperty.split('.') : [];
      return _.max(_.map(value, function (item) {
        var newValue = item;
        for (var index = 0; index < properties.length && !!newValue; ++index)
          newValue = newValue[properties[index]];
        if (!!newValue)
          return newValue;
        newValue = item;
        for (var index = 0; index < alternateProperties.length && !!newValue; ++index)
          newValue = newValue[alternateProperties[index]];
        return newValue
      }));
    }
  }])
  .filter('unixTime', [function () {
    return function (value) {
      if (!value)
        return value;
      var mili_time = new Date(value * 1000);
      var ret = mili_time.getTime();
      return ret;
    };
  }])
  .filter('emptyStringPlaceholder', [function() {
    return function(msg, placeholderString) {
      if (msg.trim().length === 0 || msg === null) {
        msg = placeholderString;
      }
      return msg;
    };
  }])
  .filter('newlineToBr', ['$sanitize', function($sanitize) {
    var tag = (/xhtml/i).test(document.doctype) ? '<br />' : '<br>';
    return function(msg) {
      // ngSanitize's linky filter changes \r and \n to &#10; and &#13; respectively
      msg = (msg + '').replace(/(\r\n|\n\r|\r|\n|&#10;&#13;|&#13;&#10;|&#10;|&#13;)/g, tag + '$1');
      return $sanitize(msg);
    };
  }])
  .filter('newlines', function () {
    return function (text) {
      return text.replace(/,/g, '<br>');
    };
  })
  .filter('startsWithOrEndsWithWhitespace', function() {
    return function(input) {
        // Check if the input starts or ends with whitespace
        return /^\s|\s$/.test(input);
    };
  })
  .filter('isoCurrency', ['$filter', function($filter) {
    var formatDecimalFilter = $filter('formatDecimal');
    return function(amount, currencyCode, fractionSize, allowDecimal) {

      // default to 2 decimal places
      if (fractionSize === null) {
        fractionSize = 2;
      }

      // if currency doesn't allow decimal, set to 0
      if (allowDecimal === false){
        fractionSize = 0;
      }

      // if currency code is not passed in, set to empty string otherwise will show undefined
      if (!currencyCode) {
        currencyCode = '';
      }

      // if null or undefined pass it through
      return (amount == null || amount === 0)
        ? '0' + ' ' + currencyCode
        : $filter('number')(amount, fractionSize) + ' ' + currencyCode;
    };
  }])
  .filter('joinWaitingCondition', function(){
    return function (array) {

      var format_display_name = [];

      format_display_name = _.map(array, function(item){
        return item.display_name;
      });

      return format_display_name.join(" & ");
    }
  })
  .filter('titleCase', function(){
    return function(input) {
      input = input || '';
      return input.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() +
        txt.substr(1).toLowerCase();});
    };
  })

  .filter('formatDecimalForCurrency', function($filter) {
  return function(input, maxDecimal, allowDecimal) {
    if (!!input || input === 0) {
      // if doesn't allow decimal, return a rounded whole number
        if (allowDecimal === false){
          input = Math.round(input);
        } else {
          // decimal format
          input = parseFloat(input);
          input = Number(input).toFixed(maxDecimal || 2);
        }

        return input;
      }
    };
  })
  
  .filter('formatDecimal', function($filter) {
    // TODO: use $locale to get $locale.NUMBER_FORMATS.GROUP_SEP & .DECIMAL_SEP
    return function(input, maxDecimal, allowDecimal) {
      if (!!input || input === 0) {
        // Check if the input is object
        if (typeof input === 'object') {
          input = input.active;
        }

        // if doesn't allow decimal, return a rounded whole number
        if (allowDecimal === false){
          return $filter('thousandSeparator')(Math.round(input));
        }

        // decimal format
        input = parseFloat(input);

        input = Number(input).toFixed(maxDecimal || 4);
        return $filter('thousandSeparator')(input);
      }
    };
  })
  .filter('thousandSeparator', function() {
    return function(number) {

      if (number === undefined) {
        return 0;
      }

      number += '';
      var wholeNumber = number.split('.');
      var integerPart = wholeNumber[0];
      var factionalPart = wholeNumber.length > 1 ? '.' + wholeNumber[1] : '';
      var regex = /(\d+)(\d{3})/;
      while (regex.test(integerPart)) {
        integerPart = integerPart.replace(regex, '$1' + ',' + '$2');
      }
      return integerPart + factionalPart;
    }
  })
  .filter('escapeHtml', function() {
    return function(input) {
      if (!!input) {

        // TODO: check other characters if needed
        if (input.indexOf('<') > -1) {
          input = input.replace(/\</g, '&lt;')
        }
        if (input.indexOf('>') > -1) {
          input = input.replace(/\>/g, '&gt;')
        }
      }

      return input;
    }
  })
  .filter('reverse', function() {
    return function(items) {
      return items.slice().reverse();
    };
  })
  .filter('positive', function() {
    return function(input) {
      if (!input) {
        return 0;
      }

      return Math.abs(input);
    };
  })
  .filter('capitalize', function() {
    return function (input) {
      if (input != null)
        return input.toLowerCase().substring(0, 1).toUpperCase() + input.substring(1);
    }
  })
  .filter('changeStringCase', function($filter) {
    return function(input){
      var newString = input.replace(/_/g, ' ');
      newString = $filter('titleCase')(newString);
      return newString;
    }
  })
  .filter('toArray', function() {
    return function(obj) {
      if (!(obj instanceof Object)) return obj;
      return _.map(obj, function(val, key) {
        return Object.defineProperty(val, '$key', {__proto__: null, value: key});
      });
    }
  })
  .filter('roundDecimals', function(){
    return function(number){
      return math.round(number, 4);
    }
  })
  /**
   * Same with default Angular filter but with OR condition instead of an AND condition
   */
  .filter('orFilter', function () {
    return function (data, param, properties) {
      if (!param) {
        return data;
      }

      return data.filter(function (obj) {
        var found = false;
        for (var prop in obj) {
          if (obj.hasOwnProperty(prop) && (properties.indexOf(prop) > -1) && (obj[prop].toString().toLowerCase().indexOf(param.toString().toLowerCase()) > -1)) {
            found = true;
            break;
          }
        }
        return found;
      });
    };
  })
  .filter('excludeMetabuyerModel', function(){
    return function(input){
      if (!!input) {
        var newString = input.replace('Metabuyer\\Models\\', '');
        newString = newString.replace('Metabuyer\\PR\\', '');
        newString = newString.replace('App\\Metabuyer\\Supplier\\Models\\', '');

        if (newString === 'ImportItemSummary') {
          newString = 'Catalog';
        }
        return newString;
      }

      return input;
    }
  })
  .filter('underscoreless', function () {
    return function (input) {
      if (typeof input !== 'string') {
        return input;
      }

      return input.replace(/_/g, ' ');
    }
  })
  .filter('formatNumber', function ($filter, globalFunc) {
    return function (number, currencyCode, showCurrency) {
      var result;
      if (!!currencyCode) {
        var config = globalFunc.getCurrencyConfig(currencyCode);
        var decimalPlaces = !!config ? config.decimal_places : null;
        var allowDecimal = !!config ? config.allow_decimal : null;

        if (!!showCurrency){
          result = $filter('isoCurrency')(number, currencyCode, decimalPlaces, allowDecimal);
        } else {
          result =  $filter('formatDecimal')(number, decimalPlaces, allowDecimal);
        }

      } else {
        result = $filter('thousandSeparator')(number) !== 'null' ? $filter('thousandSeparator')(number) : 0;
      }

      return result;
    }
  })
  .filter('parseArray', function () {
    return function (input) {
      if(!input)
        return;

      if(Array.isArray(input))
        return input[0];

      return input;
    }
  })
  .filter('thousandSeparatorForInput', function ($filter) {
    return function(number) {
      if (typeof number === 'string' && number.includes(',')) {
        number = number.replaceAll(',', '');
      }
      if (!_.isEmpty(number)) {
        number = $filter('thousandSeparator')(number);
      }
      return number;
    }
  })
  .filter('translateBudgetStatus', function () {
    return function (number) {
      var status = 'PENDING';
      switch (number) {
        case 0:
          status = 'PENDING';
          break;
        case 1:
          status = 'APPROVED';
          break;
        case 2:
          status = 'REJECTED';
          break;
        case 3:
          status = 'ON HOLD';
          break;
        case 4:
          status = 'DRAFT';
          break;
        case 5:
          status = 'WITHDRAW';
          break;
        case 6:
          status = 'INACTIVE';
          break;
        case 7:
          status = 'EXPIRED';
          break;
        case 8:
          status = 'DELETED';
          break;
        case 9:
          status = 'REVISION';
          break;
      }
      return status;
    }
  })
  .filter('convertStock', function() {
    return function (input) {
      var stock = [1, true];
      var nonStock = [0, 5, false]
      if (stock.includes(input))
        return "Stock";

      if (nonStock.includes(input))
        return "Non-Stock";

      return "NaN";
    }
  });
