angular
  .module('metabuyer')
  .directive('poSplit', function () {
    return {
      restrict: 'E',
      template: '<div ng-include="myTemplateUrl"></div>',
      scope: {
        splitType: '=',
        splitBalance: '=?',
        splitData: '=',
        prData: '=',
        itemData: '=',
        itemIndex: '=',
        differentCurrencyChecker: '&',
        updateItem: '&?',
        removeSplit: '&?'
      },
      controller: function ($scope, searchModule, globalFunc, prFunction, purchaseRequisitionsServices,
                            metabuyerCache, $q, singleAddress, toastr, $http, pathConstants) {

        // Scoped functions declaration
        $scope.getTemplateUrl = getTemplateUrl;
        $scope.addSplit = addSplit;
        $scope.deleteSplit = deleteSplit;
        $scope.removeAllSplit = removeAllSplit;
        $scope.onSelectSplitCompany = onSelectSplitCompany;
        $scope.onSelectSplitCostCenter = onSelectSplitCostCenter;
        $scope.onSelectSplitBillingAddress = onSelectSplitBillingAddress;
        $scope.onSelectSplitDeliveryAddress = onSelectSplitDeliveryAddress;
        $scope.saveSplitDetails = saveSplitDetails;
        $scope.addSplitOption = addSplitOption;
        $scope.differentCurrencyChecker = differentCurrencyChecker;
        $scope.accountCodeListing = accountCodeListing;
        $scope.onSelectAccountCode = onSelectAccountCode;
        $scope.removeAccountCodeForSplit = removeAccountCodeForSplit;
        $scope.displayAccountCode = displayAccountCode;
        $scope.validateFreightTransportationDiscount = validateFreightTransportationDiscount;

        function displayAccountCode(accountCode) {
          if (!!accountCode) {
            return accountCode.code +" - "+accountCode.descr;
          } else {
            return 'N/A';
          }
        }

        function removeAccountCodeForSplit(split) {
          split.account_code = undefined;
          saveSplitDetails(split, 'account_code');
        }

        function onSelectAccountCode(item, split) {
          split.account_code = item;
          saveSplitDetails(split);
        }

        function accountCodeListing(val, split) {
          if (!!val && val.length > 2) {
            $scope.loading = true;

            return $http.get(pathConstants.apiUrls.accountCode.prList, {
              params: {
                query: val,
                company_code: split.company_code,
                cost_center_id: split.cost_center._id,
                offset: 100
              }
            }).then(function (response) {
              $scope.loading = false;
              if (!!response.data && !!response.data.content && !!response.data.content.data) {
                return response.data.content.data.map(function (item) {
                  $scope.loading = false;
                  return item;
                });
              } else {
                return null;
              }
            });
          }
        }

        /**
         * Check param currency code with pr currency code
         * @param currencyCode
         * @returns {boolean}
         */
        function differentCurrencyChecker(currencyCode) {
          return ($scope.prData.currency.code !== currencyCode);
        }

        function getTemplateUrl() {
          var state = prFunction.getEditingState();
          switch (state) {
            case 'editable':
              $scope.myTemplateUrl = 'components/purchaseRequisitionComponents/poSplit/poSplit.editableTemplate.html';
              break;
            case 'notEditable':
              $scope.myTemplateUrl = 'components/purchaseRequisitionComponents/poSplit/poSplit.nonEditableTemplate.html';
              break;
          }
        }

        function addSplit() {
          var splittingData = {
            qty: ($scope.splitType.toLowerCase() === 'amount') ? 1 : 0,
            amount: 0,
            company: undefined,
            cost_center: undefined,
            account_code: undefined,
            billing_to_address: undefined,
            delivery_to_address: undefined
          };

          $scope.splitData.push(splittingData);
        }

        /**
         * Delete a single split
         *
         * @param {array}   split Single split data
         * @param {integer} index Index of the split
         */
        function deleteSplit(split, index) {
          $scope.splitData.splice(index, 1);
          if (!!split.company) {
            saveSplitDetails(split);
          }
        }

        /**
         * Remove all split from the current item
         */
        function removeAllSplit() {
          $scope.removeSplit(
            {
              item: $scope.itemData,
              itemIndex: $scope.itemIndex
            }
          );
        }

        function onSelectSplitCompany(companyCode, split) {
          split.cost_center = undefined;
          split.billing_to_address = undefined;
          split.delivery_to_address = undefined;
          saveSplitDetails(split);
        }

        function loadCostCenters(companyCode, split) {
          var deferred = $q.defer();
          var params = {
            module: 'cost-center',
            order_by: 'descr',
            order: 1,
            offset: 0,
            'criteria[company_code]': companyCode
          };

          searchModule.get(
            params,
            function (resource) {
              if (!!resource.content && !!resource.content.data) {
                split.costCenters = resource.content.data;
                deferred.resolve(resource.content.data);
              }
            });
          return deferred.promise;
        }

        function onSelectSplitCostCenter(split) {
          split.billing_to_address = undefined;
          saveSplitDetails(split, 'cost_center');
        }

        function loadAccountCodes(companyCode, split) {
          var deferred = $q.defer();
          var params = {
            module: 'account-code',
            order_by: 'descr',
            order: 1,
            offset: 0,
            'criteria[company_code]': companyCode
          };

          searchModule.get(
            params,
            function (resource) {
              if (!!resource.content && !!resource.content.data) {
                split.accountCodes = resource.content.data;
                split.account_code = undefined;
                deferred.resolve(resource.content.data);
              }
            });
          return deferred.promise;
        }

        function loadAvailableAddresses(split, ccObject) {

          if (!ccObject)
            return;

          var costCenterBillingAddressesIds = _.uniq(ccObject.billing_addresses_ids);

          loadAddressesData(costCenterBillingAddressesIds, 'billing', split);

          purchaseRequisitionsServices.getAvailableDeliveryAddresses(
            {
              id: split.company._id
            },
            function (resource) {
              if (!!resource && !!resource.content && !!resource.content.data) {
                split.splitDeliveryAddresses = resource.content.data;
              }
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
            }
          );
        }

        /**
         * Load the Addresses and Caching them
         * @param {array} addressIds
         * @param {string} type
         * @param {Object} split
         */
        function loadAddressesData(addressIds, type, split) {
          var promises = [];
          var cleanedAddress = [];
          _.forEach(addressIds, function (addressId) {
            if (!!metabuyerCache.get(addressId)) {
              cleanedAddress.push(metabuyerCache.get(addressId));
            } else {
              var promise = singleAddress.get({
                  id: addressId
                }
              );
              promises.push(promise.$promise);
            }
          });
          $q.all(promises).then(function (resource) {
            _.forEach(resource, function (address) {
              if (!!address && !!address.content && !!address.content.data) {
                cleanedAddress.push(address.content.data);
                metabuyerCache.put(address.content.data._id, address.content.data);
              }
            });
          }).catch().finally(function () {
            if (type === 'billing') {
              split.splitBillingAddresses = _.clone(cleanedAddress);
            }
          });
        }

        function onSelectSplitBillingAddress(address, split) {
          split.billing_to_address = address;
          saveSplitDetails(split);
        }

        function onSelectSplitDeliveryAddress(address, split) {
          split.delivery_to_address = address;
          saveSplitDetails(split);
        }

        function validateField(split, field) {
          if (field === 'amount' && !split.amount) {
            split.amount = undefined;
            return 'Split amount is required and cannot be zero.';
          }
          else if (field === 'qty' && !split.qty) {
            split.qty = undefined;
            return 'Split quantity is required and cannot be zero.';
          }
          else {
            return false;
          }
        }

        function prepareFreightAmount(split, freights, field) {
          //TODO: cater for percentage (Justin 12/3/2018)
          switch (field) {
            case 'freights.amount':
              // if (freights.amount.indexOf('%') > -1) {
              //   var amountPercentage = Number(freights.amount.replace('%', ''));
              //   freights.amount = (amountPercentage / 100) * split.amount;
              // }
              freights.amount = Number(freights.amount);
              break;
            case 'freights.discount_amount':
              // if (freights.discount.indexOf('%') > -1) {
              //   var discountPercentage = Number(freights.discount.replace('%', ''));
              //   freights.amount = (discountPercentage / 100) * freights.amount;
              // }
              freights.discount_amount = Number(freights.discount_amount);
              break;
          }
          return freights;
        }

        function prepareTransportationAmount(split, transportation, field) {
          //TODO: cater for percentage (Justin 12/3/2018)
          switch (field) {
            case 'transportation.amount':
              transportation.amount = Number(transportation.amount);
              break;
            case 'transportation.discount_amount':
              transportation.discount_amount = Number(transportation.discount_amount);
              break;
          }
          return transportation;
        }

        function validateFreightTransportationDiscount(amount, discount) {

          if(!!amount && !!discount ){
            if(amount < discount){
              return true;
            }
          }

          return false;
        }

        function saveSplitDetails(split, field) {
          var backupSplitData = _.clone($scope.splitData);

          var splittingData = [];

          // Clearing freights discount and tax
          if (field === 'freights.amount' && !!split.freights &&
            (!split.freights.amount || split.freights.amount === '0')) {
            split.freights.amount = undefined;
            split.freights.discount_amount = undefined;
            split.freights.tax = undefined;
          }

          // Clearing transportation discount and tax
          if (field === 'transportation.amount' && !!split.transportation &&
            (!split.transportation.amount || split.transportation.amount === '0')) {
            split.transportation.amount = undefined;
            split.transportation.discount_amount = undefined;
            split.transportation.tax = undefined;
          }

          if (validateField(split, field)) {
            toastr.error(validateField(split,field));
            //setBackUpSplit()
          }
          else {

            _.forEach($scope.splitData ,function(split) {
              var singleSplit = {
                qty: $scope.splitType.toLowerCase() === 'quantity' ? split.qty : 1,
                amount: $scope.splitType.toLowerCase() === 'amount' ? split.amount : undefined,
                cost_center_code: !!split.cost_center ? split.cost_center.code : undefined,
                company_code: !!split.company ? split.company.code : undefined,
                account_code_id: !!split.account_code ? split.account_code._id : undefined,
                contact_person: !!split.contact_person ? split.contact_person : undefined,
                billing_to_address: !!split.billing_to_address ? split.billing_to_address._id : undefined,
                delivery_to_address: !!split.delivery_to_address ? split.delivery_to_address._id : undefined,
                freights: (!!split.freights && !!split.freights.amount) ?
                  prepareFreightAmount(split, split.freights, field) : undefined,
                transportation: (!!split.transportation && !!split.transportation.amount) ?
                  prepareTransportationAmount(split, split.transportation, field) : undefined
              };

              if (!!singleSplit.company_code)
                splittingData.push(singleSplit);
            });

            // if (!!splittingData.length) {
              var submitData = {
                type: $scope.splitType.toLowerCase(),
                cost_centers: splittingData
              };

              $scope.updateItem(
                {
                  itemIndex: $scope.itemIndex,
                  field: 'split-po-responsive',
                  isDecimal: false,
                  newValue: submitData,
                  backupValue: backupSplitData
                }
              );

          }
        }

        function addSplitOption(field, splitIndex) {
          $scope.splitData[splitIndex][field] = {
            amount: 0
          };
        }

        function initialize() {
          $scope.splitCompanies = $scope.prData.company.split_po_companies;
          getTemplateUrl();
          $scope.taxList = prFunction.getTaxData();

          $scope.splitTypes = [
            'amount',
            'quantity'
          ];
        }

        initialize();

        // Watch the splitData and perform loading data
        $scope.$watch('splitData', function(newSplitData) {
          _.forEach(newSplitData, function(split) {
            loadCostCenters(split.company_code, split);
            loadAvailableAddresses(split, split.cost_center);
          });
        });
      }
    }
  });
