'use strict';

function listingFilter() {
    return {
      restrict: 'E',
      templateUrl: 'components/poConsolidation/listingFilter/listingFilter.html',
      controller: 'listingFilterCtrl'
    }
  }

function listingFilterCtrl($scope, $state, searchModule, metabuyerCache, toastr, globalFunc) {
    $scope.searchItems = searchItems;
    $scope.query = '';
    $scope.setConsolidationItemConfig = setConsolidationItemConfig;
    $scope.selectFilter = selectFilter;
    $scope.selectedFilter = {};

    function setConsolidationItemConfig() {
        $scope.searchPlaceholder = 'Search';
        $scope.searchState = 'main.user.poConsolidation.itemListing';
        $scope.parentState = 'main.user.poConsolidation.itemListing';
        $scope.parentStateParams = $state.params;

        $scope.columns = [
          {id: 'check box', title: '', unsortable: true, condition: ['items'] , unsearchable: true, unSelectable: true},
          {id: 'pr_custom_reference', label: 'PR#'},
          {id: 'item_line_number', label: 'PR LINE ID', unsearchable: true, unSelectable: true},
          {id: 'pr_title', label: 'TITLE'},
          {id: 'supplier|descr', label: 'SUPPLIER', unsearchable: true, unSelectable: true},
          {id: 'company|descr', label: 'COMPANY', unsearchable: true, unSelectable: true},
          {id: 'shadow_company|descr', label: 'BILLING COMPANY', unsearchable: true, unSelectable: true},
          {id: 'delivery_address', label: 'DELIVERY ADDRESS', unsearchable: true, unsortable: true},
          {id: 'payment_term', label: 'PAYMENT TERM', unsearchable: true, unsortable: true},
          {id: 'name', label: 'ITEM NAME'},
          {id: 'originalAmount', label: 'ORIGINAL AMOUNT', unsearchable: true, unsortable: true },
          {id: 'originalQuantity', label: 'ORIGINAL QUANTITY', unsearchable: true, unsortable: true},
          {id: 'remainingQuantity', label: 'REMAINING QUANTITY', unsearchable: true, unsortable: true},
          {id: 'progress', label: 'PROGRESS', unsearchable: true, unsortable: true},
          {id: 'creator', label: 'CREATOR', unsearchable: true, unsortable: true},
          {id: 'requestor', label: 'REQUESTOR', unsearchable: true, unsortable: true},
          {id: 'pr_last_approvers|display_name', label: 'PR FINAL APPROVER', unsearchable: true, unsortable: true},
          {id: 'pr_approved_at', label: 'PR APPROVED AT', unsearchable: true, unsortable: true},
          {id: 'currency', label: 'CURRENCY', unsearchable: true, unsortable: true},
          {id: 'item_code', label: 'ITEM CODE'},
          {id: 'mode_of_purchase.code', label: 'MODE OF PURCHASE'}
        ];

        $scope.filters = _.filter($scope.columns, function(col){
            return !col.unsearchable
        })
      }

    function searchItems(){
        var params = _.cloneDeep(metabuyerCache.get('POConsolidationItemParams'));
        if (!!params){
          if (!params['criteria[0][company|code]']){
            toastr.error('Company field cannot be empty');
            return;
          }
          else if (!params['criteria[1][shadow_company|code]']){
            toastr.error('Billing Company field cannot be empty');
            return;
          }
          else if (!params['criteria[2][items|supplier|code]']){
            toastr.error('Supplier field cannot be empty');
            return;
          }
          else{

            $scope.parentStateParams = _.merge(params, $state.params);
            //reset filters
            _.forEach($scope.filters, function (filter){
              !!$scope.parentStateParams['criteria[2]' + '[' + filter.id + ']'] ?
                $scope.parentStateParams['criteria[2]' + '[' + filter.id + ']'] = undefined : '';
            });
            $scope.parentStateParams['criteria[2][$operator]'] = 'or';
            $scope.parentStateParams['criteria[2]' + '[' + $scope.selectedFilter.id + ']'] = $scope.query;
            $scope.parentStateParams['query'] = $scope.query;
            $scope.parentStateParams['search_by'] = $scope.selectedFilter.id;

            searchModule.get(
                $scope.parentStateParams,
                function (resource) {
                    if (!!resource && !!resource.content && !!resource.content.data){
                        $scope.$emit("poItemsFetched", resource.content);
                    }
                },
                function (err) {
                    globalFunc.objectErrorMessage(err);
                }
            );
          }
        }
        else {
          toastr.error('Company and Supplier field cannot be empty');
        }
    }

    function selectFilter(filter){
      $scope.selectedFilter = filter;
    }

    $scope.$on('poNewParams', function(){
      $scope.query = '';
      $scope.selectedFilter = {};
    });

    function initialize(){
      setConsolidationItemConfig();
    }

    initialize();

}
listingFilterCtrl.$inject = [ '$scope', '$state', 'searchModule', 'metabuyerCache', 'toastr', 'globalFunc'];

angular
    .module('metabuyer')
    .directive('listingFilter', listingFilter)
    .controller('listingFilterCtrl', listingFilterCtrl);
