'use strict';

/**
 * @name currencyDetailsCtrl
 * @desc Controller for master data Currencies details controller
 */
function currencyDetailsCtrl($scope, $uibModalInstance, currencyDetails,
                                   currenciesMasterDataList, globalFunc, toastr, $rootScope) {
  $scope.currencyDetails = currencyDetails.data;
  $scope.editClicked = false;
  $scope.currencyDetails.allowDecimal = (!!$scope.currencyDetails.allow_decimal) ? 'Allowed' : 'Disallowed';
  // Create new variable to replace currencyDetails.status
  // with object to load and selected the right status
  // on the dropdown list
  if(!!$scope.currencyDetails.is_active) {
    $scope.currencyDetails.currencyStatus = {isActive: globalFunc.capitalizeFirstCharacter($scope.currencyDetails.is_active)};
  }

  // Statuses for dropdown in object
  $scope.currencyStatuses = [
    { isActive: 'Active' },
    { isActive: 'Deactivated' }
  ];

  $scope.decimalStatuses = [
    'Allowed',
    'Disallowed'
  ];

  $scope.tab = 1;
  $scope.historyDependencies = {
    embeddedParams: {
      'object[0][object_id]': $scope.currencyDetails._id,
      'object[0][object_type]': 'Metabuyer\\Models\\Currencies',
      offset: 5,
      order_by: '-created_at' // Created at, descending
    }
  };

  $scope.toggleTab = toggleTab;
  $scope.cancel = cancel;
  $scope.editCurrency = editCurrency;
  $scope.updateCurrency = updateCurrency;
  $scope.duplicationCheck = duplicationCheck;
  $scope.originalCode = $scope.currencyDetails.code;

  function cancel() {
    $uibModalInstance.close();
  }

  function toggleTab(tab) {
    return $scope.tab = tab;
  }

  function editCurrency(){
    $scope.editClicked = true;
  }

  function validate(currency){
    if (!currency){
      return false;
    }
    if (!currency.code){
      return false;
    }
    if (!currency.descr){
      return false;
    }
    if (!currency.currencyStatus){
      return false;
    }
    return true;
  }

  function updateCurrency() {
    if(!!$scope.codeCheck){
      toastr.error($scope.currencyDetails.code + ' is already in use');
    }
    else if(validate($scope.currencyDetails)){
      swal({
          title: 'Confirm Updating the Currency',
          text: 'This will update the details for this Currency',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#1ab394',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true,
          closeOnCancel: true
        },
        function (confirmed) {
          if (!!confirmed) {

            // set status in integer to pass it as parameter
            var tempStatus;
            switch($scope.currencyDetails.currencyStatus.isActive.toLowerCase()){
              case 'active':
                tempStatus = 1;
                break;
              case 'deactivated':
                tempStatus = 0;
                break;
              case 'disabled':
                tempStatus = 3;
                break;
            }

            $scope.currencyDetails.is_active = $scope.currencyDetails.currencyStatus.isActive.toUpperCase();
            $scope.currencyDetails.updated_at = new Date();

            currenciesMasterDataList.put(
              {
                id: $scope.currencyDetails._id
              },
              {
                code: $scope.currencyDetails.code,
                descr: $scope.currencyDetails.descr,
                is_active: tempStatus,
                allow_decimal: $scope.currencyDetails.allowDecimal === 'Allowed' ? true : false
              },
              function (resource) {
                toastr.success('Currency has been updated');
                $uibModalInstance.close(resource.content.data);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                return false;
              }
            );
          } else {
            return false;
          }
        }
      );
    }
  }

  /**
   * @function duplicationCheck
   * Calls the global function's duplication check
   * Checks callback function return for assigning scope values for validation
   **/
  function duplicationCheck(model,field,value,checker){
    globalFunc.duplicationCheck(model,field,value,checker, function(callback){
      switch (callback){
        case 'codeCheckTrue':
          if($scope.currencyDetails.code != $scope.originalCode){
            $scope.codeCheck = true;
          }
          else{
            $scope.codeCheck = false;
          }
          break;
        case 'codeCheckFalse':
          $scope.codeCheck = false;
          break;
      }
    });
  }
}

currencyDetailsCtrl.$inject = [
  '$scope', '$uibModalInstance', 'currencyDetails', 'currenciesMasterDataList', 'globalFunc', 'toastr', '$rootScope'
];

angular
  .module('metabuyer')
  .controller('currencyDetailsCtrl', currencyDetailsCtrl);
