'use strict';

angular
  .module('metabuyer')
  .directive('tenderGroupInfo', function($uibModal) {
    return {
      restrict: 'E',
      scope: {
        initialData: '=',
        tender: '=',
        editableStatus: '='
      },
      template: '<div ng-include="templateUrlTenderGroupInfo"></div>',
      controller: 'tenderGroupInfoCtrl',
      link: function($scope) {
        $scope.templateUrlTenderGroupInfo = 'components/tenderComponents/tenderGroupInfo/tenderGroupInfoTemplate.html';
      }
    };
  });
