function tenderMemoCtrl(
  $scope, globalFunc, $rootScope, $uibModal, $state, HighlightId, pathConstants, $filter, tenderFunction, tenderService, toastr, $http, searchModule
) {
  $scope.memoAttachments = [];
  $scope.emailList = [];
  $scope.emailQuery = {
    selected: ''
  };

  $scope.addAttachment = addAttachment;
  $scope.updateAttachmentTitle = updateAttachmentTitle;
  $scope.downloadAttachment = downloadAttachment;
  $scope.removeAttachment = removeAttachment;
  $scope.loadEmailSearch = loadEmailSearch;
  $scope.getEmailSearch = getEmailSearch;
  $scope.updateEmailList = updateEmailList;
  $scope.removeEmail = removeEmail;
  $scope.sendMemo = sendMemo;
  $scope.memoEditableStatus = false;

  function addAttachment(element) {

    var maxSize = 10000000;

    var fileSize = element.target.files[0].size;

    if (fileSize > maxSize) {
      toastr.error('File size uploaded has exceeded 10MB');
      return false;
    }

    var validFormats = ['.xls', '.doc', '.ppt', '.txt', '.pdf', '.jpg', '.png', '.gif', '.bmp', '.xlsx', '.docx', '.pptx', '.jpeg', '.zip', '.msg'];
    var filename = element.target.files[0].name;
    var index = filename.lastIndexOf(".");
    var strsubstring = filename.substring(index, filename.length);

    if (!validFormats.includes(strsubstring)) {
      toastr.error('The file must be a file of type: xls, doc, ppt, txt, pdf, jpg, png, gif, bmp, xlsx, docx, pptx, jpeg, msg, zip.');
      return false;
    }

    var formData = new FormData();
    formData.append('tender_id', tenderFunction.getTenderId());
    formData.append('file', element.target.files[0]);

    $http.post($filter('format')(pathConstants.apiUrls.tender.uploadMemoAttachment), formData, {
        transformRequest: angular.identity,
        headers: { 'Content-Type': undefined }
      })
      .then(function onSuccess(response) {
        $scope.memoAttachments.push(response.data.content.data)
        $rootScope.$broadcast('refreshComments');
      })
      .catch(function onError(error) {
        globalFunc.objectErrorMessage(error);
      });
  }

  function updateAttachmentTitle(index) {

    var value = $scope.memoAttachments[index];

    var payload = {};
    payload[index] = {
      _id: value._id,
      attachment_id: value.attachment_id,
      title: value.attachment.description
    };

    tenderService.manageMemoAttachment({
      tenderId: tenderFunction.getTenderId(),
      memo_attachments: payload
    }).$promise.then(
      function(resource) {
        toastr.success('Successfully update');
      },
      function() {
        toastr.error('Failed to update memo attachment');
      }
    );

  }

  function downloadAttachment(index) {
    var memoAttachment = $scope.memoAttachments[index];

    tenderService.getMemoAttachment({
      tenderId: tenderFunction.getTenderId(),
      memoId: memoAttachment._id
    }).$promise.then(
      function(resource) {
        if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data.attachment) {
          var newUrl = $filter('format')(pathConstants.apiUrls.attachments.downloadAttachment, {
            hash: resource.content.data.attachment.temporary_url
          });
        }

        $http({
          url: newUrl
        }).then(function() {
          window.open(newUrl, '_self');
        }, function(response) {});
      },
      function() {
        toastr.error('Failed to download attachment.');
      }
    );

  }

  function removeAttachment(index) {

    swal({
      title: 'Confirmation',
      text: 'Delete This Attachment?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Confirm',
      closeOnConfirm: true
    }, function(isConfirm) {
      if (isConfirm) {

        var value = $scope.memoAttachments[index];

        var payload = {};
        payload[index] = {
          _id: value._id,
          attachment_id: value.attachment_id,
          is_deleted: true
        };

        tenderService.manageMemoAttachment({
          tenderId: tenderFunction.getTenderId(),
          memo_attachments: payload
        }).$promise.then(
          function(resource) {
            $scope.memoAttachments.splice(index, 1);
            $rootScope.$broadcast('refreshComments');
            toastr.success('Successfully deleted');
          },
          function() {
            toastr.error('Failed to delete memo attachment');
          }
        );


      }
    })
  }

  function loadEmailSearch() {
    document.querySelector("#emailQuery").focus();
  }

  function getEmailSearch(query) {

    var params = {
      module: 'users',
      'criteria[0][$operator]': 'or',
      'criteria[0][display_name]': query,
      'criteria[0][email]': query,
      'criteria[1][is_metabuyer_user]': true,
      'criteria_operator': 'and'
    };

    return searchModule.get(params).$promise.then(function(response) {
      if (response.content.data.length === 0) {
        return [];
      } else {
        return response.content.data;
      }
    });
  }

  function updateEmailList(selected) {

    var payload = [{
      user_id: selected._id
    }];

    tenderService.manageMemoAttachment({
      tenderId: tenderFunction.getTenderId(),
      memo_users: payload,
      return_single_memo_users: true
    }).$promise.then(
      function(resource) {
        $scope.memoUsers.push(resource.content.data);
        $scope.emailQuery = {
          selected: ''
        };
        $rootScope.$broadcast('refreshComments');
      },
      function(error) {
        var msg = (error.data === undefined) ? 'Failed to update memo email list' : error.data.content.error;
        toastr.error(msg);
        $scope.emailQuery = {
          selected: ''
        };
      }
    );
  }

  function removeEmail(index) {

    swal({
      title: 'Confirmation',
      text: 'Confirm Delete This Email?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Confirm',
      closeOnConfirm: true
    }, function(isConfirm) {
      if (isConfirm) {

        var value = $scope.memoUsers[index];
        var payload = {};
        payload[index] = {
          _id: value._id,
          user_id: value.user_id,
          is_deleted: true
        };

        tenderService.manageMemoAttachment({
          tenderId: tenderFunction.getTenderId(),
          memo_users: payload
        }).$promise.then(
          function(resource) {
            $scope.memoUsers.splice(index, 1);
            $rootScope.$broadcast('refreshComments');
            toastr.success('Successfully deleted');
          },
          function() {
            toastr.error('Failed to update memo email list');
          }
        );

      }
    })
  }

  function sendMemo() {

    tenderService.sendEmailMemo({
      tenderId: tenderFunction.getTenderId()
    }).$promise.then(
      function(response) {
        toastr.success(response.content.message);
        $rootScope.$broadcast('refreshComments');
      },
      function(error) {
        if (!!error.data && !!error.data.content && !!error.data.content.error) {
          toastr.error(error.data.content.error);
        }
      }
    );
  }

  function initialize() {
    $scope.memoAttachments = tenderFunction.getMemoAttachments();
    $scope.memoUsers = tenderFunction.getMemoUsers();

  if ((tenderFunction.hasRoleApprover() || tenderFunction.hasRoleRequester()) && ['pending', 'approved', 'draft'].includes(tenderFunction.getTenderStatus())) {
      $scope.memoEditableStatus = true;
    }
  }

  initialize();
}

tenderMemoCtrl.$inject = [
  '$scope', 'globalFunc', '$rootScope', '$uibModal', '$state', 'HighlightId', 'pathConstants', '$filter', 'tenderFunction', 'tenderService', 'toastr', '$http', 'searchModule'
];

angular
  .module('metabuyer')
  .controller('tenderMemoCtrl', tenderMemoCtrl);
