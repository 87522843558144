'use strict';

/**
 * @name Item Tax Controller
 * @desc Controller for items tax list in companies
 */
function companyItemCtrl($scope, $filter, companyDetails, items, $uibModal, $state, $rootScope, pathConstants) {
  $scope.back = back;
  $scope.companyDetails = companyDetails.data;
  $scope.importCompanyItems = importCompanyItems;
  $scope.items = items.data;
  $scope.meta = items.meta;
  $scope.showColumn = showColumn;
  $scope.storageKey = 'companies-item-selected-columns';
  $scope.convertBoolean = convertBoolean;
  $scope.getActiveStatusName = getActiveStatusName;

  function convertBoolean(value) {
    return value === true ? 'Yes' : 'No';
  }

  function getActiveStatusName(value) {
    return !value ? 'Deactivated' : 'Active';
  }

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional) {
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function importCompanyItems() {
    $uibModal.open({
      templateUrl: 'app/masterDataManagement/Import-Template.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        importLink: function () {
          return pathConstants.apiUrls.imports.now;
        },
        params: function () {
          return {
            'class': 'App\\Metabuyer\\CompanyItem\\Models\\CompanyItem',
            'additional_params[company_code]': $scope.companyDetails.code
          }
        },

        title: function () {
          return 'Company Item';
        }
      },
      controller: function ($scope, $uibModalInstance, importLink, title, params) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        $scope.importLink = importLink;
        $scope.title = title;
        $scope.params = params;
      }
    });
  }

  function back() {
    if (!!$scope.companyDetails && !!$scope.companyDetails._id) {
      $state.go('main.user.companiesMgmt.detailCompanies', {id: $scope.companyDetails._id});
    } else {
      window.history.back();
    }
  }

  function initialize() {
    var tabData = {
      tab: $scope.$parent.tabData[0],
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);
  }

  initialize();
}

companyItemCtrl.$inject = [
  '$scope', '$filter', 'companyDetails', 'items', '$uibModal', '$state', '$rootScope', 'pathConstants'
];

angular.module('metabuyer')
  .controller('companyItemCtrl', companyItemCtrl);
