'use strict';

angular.module('metabuyer').directive('masterImportAttachment', function(){
  return {
    restrict: 'E',
    templateUrl: 'components/masterImportAttachment/masterImport-attachmentTemplate.html',
    scope: {
      uploadlink: '=',
      companyid: '=',
      params: '='
    },
    controller: function(
      $scope, Attachments, AttachmentSingle, $uibModal, $stateParams, $auth, toastr, pathConstants, $filter, $http,
      globalFunc, $state
    ) {

      $scope.file_id = '';
      $scope.showDropZone = false;

      function importErrorMessages(err) {
        var errorText = '';
        if (!!err && !!err.content) {
          var errorMessages = err.content.error,
              errorHash,
              errorLine = 0,
              errorRow = 0,
              downloadLink,
              errorLimit = 5;

          if (typeof errorMessages !== 'string') {
            if (!!errorMessages.hash){
              errorHash = errorMessages.hash;
              delete errorMessages.hash;

              downloadLink = $filter('format')(pathConstants.apiUrls.attachments.downloadErrorLog, {
                hash: errorHash
              });
            }

            _.forEach(errorMessages, function (errorData, key) {
              if (errorLine < errorLimit) {
                if (typeof errorData !== 'string') {
                  _.forEach(errorData, function (val, objKey) {
                    if (errorRow < errorLimit)
                      errorText += objKey + ' in line ' + key + ': ' + val + '<br>';
                    errorRow++;
                  });
                }
              }
              errorLine++;
            });

            if (!!errorHash && (errorRow > errorLimit || errorLine > errorLimit))
              errorText += '<br><a target="_self" href="' + downloadLink + '">Click here to download full error log file</a>';

          } else {
            errorText = errorMessages;
          }
        }

        swal({
          title: 'Oops! An error has occurred',
          text: errorText,
          type: 'warning',
          html: true,
          confirmButtonColor: "#1ab394",
          confirmButtonText: "Close",
          closeOnConfirm: true
        });
      }

      window.submitAttachment = function(elem){

        var file = elem.files[0];
        var fd = new FormData();

        if(!!file){
          fd.append('file', file);
        } else {
          return;
        }

        // todo: fix this code. redundant code
        if(!!$scope.companyid) {
          $http.post($filter('format')($scope.uploadlink, {id: $scope.companyid }), fd, {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
          })
            .then(function onSuccess (response) {
              $(elem).val("");
              swal({
                title: "CSV uploaded",
                text: response.data.message,
                type: "info",
                confirmButtonColor: "#1ab394",
                confirmButtonText: "Close",
                closeOnConfirm: true
              });
              $scope.file_id = response.data.message._id;
            })
            .catch(function onError (error) {
              if (!!error.data.message)
                toastr.error(error.data.message);
              else
                toastr.error("Failed to submit the file");

              importErrorMessages(error.data);
            });
        } else {
          if (!!$scope.params){
            var paramIteration;
            for (paramIteration in $scope.params) {
              if ($scope.params.hasOwnProperty(paramIteration)) {
                fd.append(paramIteration, $scope.params[paramIteration]);
              }
            }
          }

          $http.post($filter('format')($scope.uploadlink), fd, {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
          }).then(function onSuccess (response) {
            $scope.message = response.data.message;
            var limit = 5,
                errorLine = 0;

            if(!!response.data.content){
              if(!!response.data.content.message){
                toastr.success(response.data.content.message);
                $scope.message = response.data.content.message;
                $scope.messageData = response.data.content.data;
              }else{
                toastr.success("File successfully uploaded");
              }
            }

            if (!!$scope.messageData) {
              $scope.message += '<br>';
              _.forEach($scope.messageData, function (errorData, key) {
                if (errorLine < limit) {
                  $scope.message += 'Line ' + key + ': ' + errorData;
                }
                errorLine++;
              });
            }

            swal({
              title: "CSV uploaded",
              text: $scope.message,
              type: "info",
              html: true,
              confirmButtonColor: "#1ab394",
              confirmButtonText: "Close",
              closeOnConfirm: true
            });
            if(!!response.data.message && !!response.data.message._id)
              $scope.file_id = response.data.message._id;
            $state.reload();
          })
            .catch(function onError (error) {
              if (!!error.data && !!error.data.message)
                toastr.error(error.data.message);
              else
                toastr.error("Failed to submit the file");

              importErrorMessages(error.data);
            });
        }
      };
    }
  };
});
