'use strict';

function tenderGroupCtrl() {
}

tenderGroupCtrl.$inject = [];

angular
  .module('metabuyer')
  .controller('tenderGroupCtrl', tenderGroupCtrl);
