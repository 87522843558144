/**
 * @name usersDelegationCtrl
 *
 * @description
 * List all the delegations and redirected to user details page
 * when clicked on the list
 *
 * @copyright Verve Technologies Sdn. Bhd. &copy; 2015
 */

'use strict';

function usersDelegationCtrl($scope, delegationList, delegations, HighlightId, $uibModal) {
  $scope.showColumn = showColumn;
  $scope.showHistory = showHistory;
  $scope.getDelegationStatus = getDelegationStatus;

  $scope.storageKey = 'delegation-management-selected-columns';

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function showHistory(delegation) {
      $uibModal.open({
          templateUrl: 'app/companiesManagement/companies/details/viewHistory.html',
          backdrop: 'static',
          keyboard: false,
          controller: 'viewHistoryController',
          size: 'lg',
          resolve: {
              company: function(){
                  return delegation;
              },
              histories: function ($q, commentsHistory){
                  var deferred = $q.defer();
                  commentsHistory.get(
                      {
                          referenceId: delegation._id,
                          offset: 5,
                          order_by: '-created_at' // Created at, descending
                      },
                      function (resource) {
                          deferred.resolve(resource.content || []);
                      },
                      function (error) {
                          if (error.status === 404) {
                              deferred.resolve([]);
                          }
                      }
                  );
                  return deferred.promise;
              }
          }
      });
  }

  function getDelegationStatus(status) {
    switch(status) {
        case 0:
            return 'Planned';
        case 1:
            return 'Active';
        case 2:
            return 'Cancelled';
        case 3:
            return 'Finished';
        case 4:
            return 'Pending';
        case 5:
            return 'Rejected';
    }
  }

  function initialize(){
    $scope.dataList = delegationList.data;
    $scope.meta = delegationList.meta;
    $scope.delegationsService = delegations;
    $scope.highlightId = HighlightId.getId();
    if (!!$scope.highlightId) {
      HighlightId.setId(null);
    }
  }

  initialize();

}

usersDelegationCtrl.$inject = ['$scope', 'delegationList', 'delegations', 'HighlightId', '$uibModal'];

angular
  .module('metabuyer')
  .controller('usersDelegationCtrl', usersDelegationCtrl);
