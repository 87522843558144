'use strict';
/**
 * @name BUDetailCtrl
 * @desc Controller for BU detail
 */
function BUDetailCtrl($scope, $state, toastr, BUDetails, singleBU, globalFunc, $rootScope, HighlightId) {

  $scope.BUDetails = BUDetails.data;
  $scope.editBu = false;
  $scope.setActiveTab = setActiveTab;
  $scope.updateChanges = updateChanges;
  $scope.revertUpdate = revertUpdate;
  $scope.cancelUpdate = cancelUpdate;
  $scope.back = back;
  $scope.editableBu = editableBu;
  $scope.deleteBu = deleteBu;
  $scope.updateBU = updateBU;
  $scope.newBUDetails = {};
  $scope.getStatusTextualRepresentation = getStatusTextualRepresentation;

  // Statuses for dropdown in object
  $scope.buStatuses = [
    { id: 1, label: 'Active' },
    { id: 0, label: 'Deactivated' }
  ];

  function back() {
    if (!!$scope.BUDetails && !!$scope.BUDetails._id) {
      HighlightId.setId($scope.BUDetails._id);
    }
    window.history.back();
  }

  /**
   * returns the is_active name
   * @param code
   * @returns {*}
   */
  function getStatusTextualRepresentation(code){
    /**
     * DEACTIVATED = 0; 
     * ACTIVE = 1; 
     */
    if (code === 0 || code === 'DEACTIVATED') {
      return 'Deactivated'
    }
    if (code === 1 || code === 'ACTIVE') {
      return 'Active'
    }
    return 'N/A';
  }

  function editableBu() {
    angular.copy($scope.BUDetails, $scope.newBUDetails);
    $scope.editBu = true;
  }

  // Set Manage tab active
  function setActiveTab() {
    $scope.tabData.active = _.find($scope.tabData, function (tab) {
      if (!!tab.menu && tab.menu.length){
        return _.find(tab.menu, function (item) {
          return item.params.action == "businessunit";
        });
      }
      else{
        return tab.params.action == "businessunit";
      }
    });

    if (!!$scope.tabData){
      $scope.tabData.active.active = true;
    }
  }

  // Run setActiveTab()
  setActiveTab();

  function validateInputData(){
    if(!$scope.newBUDetails.descr || !$scope.newBUDetails.descr.length || $scope.newBUDetails.descr.trim() === '') {
      return false;
    }

    else if(!$scope.newBUDetails.code || !$scope.newBUDetails.code.length || $scope.newBUDetails.code.trim() === '') {
      return false;
    }

    return true;
  }

  function updateChanges(){

    $scope.submitted = true;
    if(validateInputData() === false){
      toastr.error('Please fill the required fields before proceeding');
    }else {
      updateBU();
    }
  }

  function revertUpdate() {
    angular.copy($scope.BUDetails, $scope.newBUDetails);
  }

  function cancelUpdate() {
    $scope.editBu = false;
  }
  function getUpdateData(){
    var update_data = {
      _id: $scope.BUDetails._id
    };

    if(!!$scope.newBUDetails.descr){
      update_data.descr = $scope.newBUDetails.descr;
    }
    if(!!$scope.newBUDetails.code && $scope.newBUDetails.code.trim() !== ''){
      update_data.code = $scope.newBUDetails.code;
    }

    // Get new status value
    update_data.is_active = $scope.newBUDetails.is_active;

    return update_data;
  }

  function updateBU() {

    singleBU.put(
      {
        id: $scope.BUDetails._id
      }, getUpdateData() ,
      function(){
        toastr.success('The Business Unit was updated successfully');
        $state.go('main.user.companiesMgmt.manageBusinessUnits');
      },
      function(e){
        globalFunc.objectErrorMessage(e);
        revertUpdate();
        cancelUpdate();
      }
    );
  }

  function deleteBu() {
    swal(
      {
        title: "Confirm delete Business Unit?",
        text: "This Business Unit will be deleted by you",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1ab394",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function(isConfirm) {
        if(isConfirm){
          singleBU.delete(
            {
              id: $scope.BUDetails._id
            },
            function(){
              toastr.success('The Business Unit was deleted successfully');
              $state.go('main.user.companiesMgmt.manageBusinessUnits');
            },
            function(error){
              globalFunc.objectErrorMessage(error);
            }
          );
        }
      }
    );
  }

  function initialize(){
    var tabData = {
      tab: $scope.$parent.tabData[1],
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);
  }

  initialize();
}

BUDetailCtrl.$inject = [
  '$scope', '$state', 'toastr', 'BUDetails', 'singleBU', 'globalFunc', '$rootScope', 'HighlightId'
];

angular
  .module('metabuyer')
  .controller('BUDetailCtrl', BUDetailCtrl);
