'use strict';
angular
  .module('metabuyer')
  .directive('pagePaginationService',function() {
    return {
      restrict: 'E',
      templateUrl: 'components/pagePagination/page-paginationTemplate.html',
      scope: {
        meta: '='
      },
      controller: function ($scope, $location, $state, $rootScope) {
        $scope.disablePaginationButtons = false;
        $scope.pageCount = 0;
        $scope.goNextPage = goNextPage;
        $scope.goPreviousPage = goPreviousPage;
        $scope.paginationRange = 2; // range number before and after the current page
        $scope.gotoPage = gotoPage;
        $scope.state = $state.current.name;
        $scope.status = $state.params.status;
        $scope.tab = $state.params.tab;
        $scope.page = page;
        $scope.extraFilterKey = $state.params.extraFilterKey;
        $scope.extraFilterValue = $state.params.extraFilterValue;
        $scope.filter = $state.params.filter;
        $scope.type = $state.params.type;
        $scope.query = $state.params.query;
        $scope.order = $state.params.order;
        $scope.order_by = $state.params.order_by;
        $scope.parentUniqueName = $state.params.parentUniqueName;

        function initialize(){
          if (!!$scope.meta) {
            $scope.currentPage = Number($scope.meta.current_page);
            $scope.lastPage = Number($scope.meta.last_page);
            $scope.offset = $scope.meta.per_page;
            $scope.nextPage = $scope.currentPage + 1 <= $scope.lastPage; // next button availability
            $scope.totalCount = $scope.meta.total;
            $scope.from = $scope.meta.from;
            $scope.to = $scope.meta.to;
            $scope.range = _.range;
            $scope.startPager = ($scope.currentPage - $scope.paginationRange > 0) ? $scope.currentPage - $scope.paginationRange : 1;
            $scope.lastPager = ($scope.currentPage + $scope.paginationRange <= $scope.lastPage) ? $scope.currentPage + $scope.paginationRange + 1 : $scope.lastPage + 1;
            pageReset();

          } else {
            $scope.currentPage = null;
          }
        }

        function goNextPage() {
          $scope.disablePaginationButtons = true;
          $location.search('page', ($scope.currentPage + 1));
        }

        function goPreviousPage() {
          $scope.disablePaginationButtons = true;
          $location.search('page', ($scope.currentPage - 1));
        }

        function gotoPage(page) {
          if ($scope.currentPage === page)
            return false;

          $scope.disablePaginationButtons = true;
          $location.search('page', page);
        }

        function page(page) {
          if ($scope.currentPage === page)
            return false;

          $scope.disablePaginationButtons = true;
          $location.search('page', page);
        }

        function pageReset() {
          if (!!$rootScope.previousState.params.status && $rootScope.previousState.params.status !== $state.params.status) {
            gotoPage(1);
          }
        }

        // Broadcast this key if you need to update your cursor
        $scope.$on('reinitializePagePagination',function(event, meta){
          $scope.meta = meta;
          initialize();
        });

        // Run initialize function in the first load
        initialize();
      }
    };
  });
