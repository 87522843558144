/**
 * @name escalationBlock
 *
 *
 * @requires $scope
 * @requires $http
 * @requires pathConstants (Metabuyer service)
 *
 * @description
 *
 * The Escalation directive in the User Profile, Will list the users from the depending on the company
 * adding an escalation option is Not mandatory
 *
 * @variable data
 * in data the user is passed, the naming 'data' is not to have the variables confusion as in the user profile
 * the variable 'user' is already taken
 *
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Verve Technologies Sdn. Bhd. &copy; 2015
 */

'use strict';

angular
  .module('metabuyer')
  .directive('escalationBlock',function() {
    return {
      restrict: 'E',
      templateUrl: 'components/escalationBlock/escalationBlockTemplate.html',
      scope: {
        data: '='
      },
      controller: function ($scope, pathConstants, $http) {
        $scope.delay = 500;
        $scope.onSelect = onSelect;
        $scope.users = function (val, loading) {
          loading = true;
          return $http.get(pathConstants.apiUrls.users.search, {
            params: {
              query: val
            }
          }).then(function (response) {
            loading = false;
            return response.data.content.data.map(function (item) {
              $scope.loading = false;
              return item;
            });
          });
        };
        function onSelect(user, company){
          console.log(user, company);
        }
      }
    }
  });
