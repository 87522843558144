'use strict';
/**
 * @name addIndustryCtrl
 * @desc Controller for add Industry
 */
function addIndustryCtrl($scope, $state, newIndustry, toastr, globalFunc, $rootScope) {

  $scope.submitted = false;
  $scope.duplicationCheck = duplicationCheck;
  $scope.addIndustry = addIndustry;
  $scope.back = back;
  $scope.cancelAddIndustry = cancelAddIndustry;

  $scope.industry = {};

  function validate(){

    if(!$scope.industry.name || !$scope.industry.name.length) {
      return false;
    }

    if(!$scope.industry.code || !$scope.industry.code.length) {
      return false;
    }

    return true;
  }

  function back() {
    window.history.back();
  }

  //upload company detail
  function addIndustry(){

    $scope.submitted = true;

    if(validate() == false){
      toastr.error('Please fill all required fields before proceeding');
      return;
    }

    var submit_data = {
      descr: $scope.industry.name,
      code: $scope.industry.code
    };

    newIndustry.post(
      {},
      submit_data,
      function (resource) {
        toastr.success("Industry has been added");
        $state.go("main.user.companiesMgmt.manageIndustries");
      },
      function (err) {
        globalFunc.objectErrorMessage(err);
      }
    )
  }
  //cancel button
  function cancelAddIndustry(){
    $state.go('main.user.companiesMgmt.manageIndustries');
  }

  /**
   * @function duplicationCheck
   * Calls the global function's duplication check
   * Checks callback function return for assigning scope values for validation
   **/
  function duplicationCheck(model,field,value,checker){
    globalFunc.duplicationCheck(model,field,value,checker, function(callback){
      switch (callback){
        case 'codeCheckTrue':
          $scope.codeCheck = true;
          break;
        case 'codeCheckFalse':
          $scope.codeCheck = false;
          break;
      }
    });
  }

  function initialize(){
    var tabData = {
      tab: $scope.$parent.tabData[3],
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);
  }

  initialize();

}

addIndustryCtrl.$inject = ['$scope', '$state', 'newIndustry', 'toastr', 'globalFunc', '$rootScope'];

angular
  .module('metabuyer')
  .controller('addIndustryCtrl', addIndustryCtrl);


