'use strict';

function bifrostSettingsCtrl($scope, $state) {

  $scope.tabs = [
    {
      label: 'Environment',
      route: 'main.user.settings.bifrost.environments',
      key: 'environment',
    },
    {
      label: 'Schedule',
      route: 'main.user.settings.bifrost.schedule',
      key: 'schedule',
    },
    {
      label: 'Storage Path',
      route: 'main.user.settings.bifrost.storagePaths',
      key: 'storagePath'
    }
  ];

  $scope.selectTab = function (tab) {
    $state.go(tab.route);
  };

  $scope.$emit('pageTitleChanged', 'Bifrost Settings');
}
bifrostSettingsCtrl.$inject = ['$scope', '$state'];

angular.module('metabuyer')
  .controller('bifrostSettingsCtrl', bifrostSettingsCtrl);
