'use strict';

function massUpdateBudgetOwnerCtrl($scope, $state, toastr, globalFunc, $http, pathConstants, $filter, searchModule, $uibModal, $timeout) {

  $scope.searchFields = {
    budgetType: null,
    budgetStatus: null,
    budgetYear: null,
    budgetOwner: null,
    noBudgetOwner: false,
    companyCode: null,
    billingCompanyCode: null
  };

  $scope.budgetOwnerSearchText = '';
  $scope.validBudgetOwnerIsSelected = true;
  $scope.invalidBudgetOwnerSelected = false;
  $scope.companyList = getCompanies('all');
  $scope.submitSearchForm = submitSearchForm;
  $scope.searchModule = searchModule;
  $scope.selectAllCurrentPage = selectAllCurrentPage;
  $scope.selectedBudgets = [];
  $scope.selectedBudget = selectedBudget;
  $scope.processBudget = false;
  $scope.massUpdate = massUpdate;
  $scope.openDatepicker = openDatepicker;

  $scope.budgetStatusOptions = [{
      id: 1,
      key: 'all',
      value: 'All'
    },
    {
      id: 2,
      key: '4',
      value: 'Draft'
    },
    {
      id: 3,
      key: '0',
      value: 'Pending Approval'
    },
    {
      id: 4,
      key: '1',
      value: 'Approved'
    },
    {
      id: 5,
      key: '7',
      value: 'Expired'
    },
    {
      id: 6,
      key: '5',
      value: 'Withdrawn'
    },
    {
      id: 7,
      key: '2',
      value: 'Rejected'
    },
    {
      id: 8,
      key: '6',
      value: 'Inactive'
    },
    {
      id: 9,
      key: '8',
      value: 'Deleted'
    }
  ];

  $scope.budgetYearOptions = [{
      key: 2023,
      value: 2023
    },
    {
      key: 2022,
      value: 2022
    },
    {
      key: 2021,
      value: 2021
    },
  ];

  $scope.budgetTypeOptions = [{
      key: 'CAPEX',
      value: 'CAPEX'
    },
    {
      key: 'OPEX',
      value: 'OPEX'
    }
  ];

  $scope.columns = [
    { id: 'check box', title: '', conditional: false, unsortable: true, unsearchable: true, unSelectable: true },
    { id: 'erp_budget_id', label: 'Erp Budget ID', selected: true },
    { id: 'code', label: 'Budget Code', selected: true },
    { id: 'descr', label: 'Title', selected: true },
    { id: 'company', label: 'Company', selected: true },
    { id: 'billingCompany', label: 'Billing Company(s)', selected: true },
    { id: 'budgetType', label: 'Budget Type', selected: true },
    { id: 'budgetStatus', label: 'Status', selected: true },
    { id: 'startDate', label: 'Start Date', selected: true },
    { id: 'endDate', label: 'End Date', selected: true },
    { id: 'amount', label: 'Amount', selected: true },
    { id: 'createdBy', label: 'Created By', selected: true },
    { id: 'budgetOwner', label: 'Budget Owner', selected: true }
  ];

  $scope.onBudgetOwnerSelect = onBudgetOwnerSelect;
  $scope.searchBudgetOwner = searchBudgetOwner;
  $scope.validateFieldSelection = validateFieldSelection;
  $scope.budgetOwnerCheck = budgetOwnerCheck;
  $scope.listItem = listItem;
  $scope.budgetYearOptions = {
      formatYear: 'yyyy',
      minMode: 'year',
      maxMode: 'year'
    }
  $scope.datepickerOpened = {
    startDate: false,
    endDate: false,
    monthYear: false,
    year: false,
  };

  function openDatepicker($event, which) {
    if ($scope.datepickerOpened[which]) {
      $event.preventDefault();
      $event.stopPropagation();
    } else {
      $timeout(function () {
        $scope.datepickerOpened[which] = true;
      })
    }
  }

  function selectedBudget(selectedBudget, selected) {
    $scope.processBudget = true;
    if (selected === false) {
      _.remove($scope.selectedBudgets, function(e) {
        return e._id === selectedBudget._id;
      });
    } else {
      $scope.selectedBudgets.push(selectedBudget);
    }
  }

  function selectAllCurrentPage(status) {

    if (_.isEmpty($scope.dataList)) {
      return;
    }

    $scope.dataList.forEach(function(singleData) {
      if (status) {
        singleData.selected = true;
        $scope.processBudget = true;

        var inArray = _.findIndex($scope.selectedBudgets, function(e) {
          return e._id === singleData._id;
        });

        // Only push if not in array
        if (inArray === -1) {
          $scope.selectedBudgets.push(singleData);
        }
      } else {
        singleData.selected = false;
        $scope.processBudget = false;
        _.remove($scope.selectedBudgets, function(e) {
          return e._id === singleData._id;
        });
      }
    })
  }

  function listItem(list, property) {
    return _.unique(_.map(list, function(item) {
      return item[property];
    })).toString();
  }

  function getCompanies(businessUnit) {
    var param = {};
    var resource;
    if (businessUnit === 'all') {
      resource = searchModule;
      param.offset = 0;
      param.module = 'companies';
      param.order = 1;
      param.order_by = 'descr';
    } else {
      resource = singleBU;
      param.id = businessUnit;
    }

    $scope.loading = true;

    resource.get(param,
      function(success) {

        var companyList = [];

        if (!!success.content.data.companies) {
          $scope.companyList = success.content.data.companies;
        } else {
          $scope.companyList = success.content.data;
        }

        companyList.push({
          key: 'all',
          value: 'All'
        });

        _.forEach($scope.companyList, function(company) {
          companyList.push({
            key: company.code,
            value: company.code + ' - ' + company.descr
          })
        })

        $scope.companyList = companyList;
        $scope.loading = false;
      },
      function(error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function budgetOwnerCheck(data) {

    if (data.noBudgetOwner) {
      $scope.searchFields.budgetOwner = null;
      $scope.budgetOwnerSearchText = '';
    }

  }

  function onBudgetOwnerSelect(budgetOwner) {
    return $scope.searchFields.budgetOwner = budgetOwner;
  }

  function searchBudgetOwner(val) {
    if (!!val && val.length > 2) {

      var moduleParam = {
        module: 'users'
      };

      var additionalParams = {
        'criteria[0][display_name]': val,
        'criteria[0][is_metabuyer_user]': true,
        'criteria[0][status]': 1,
        criteria_operator: 'and',
        offset: 100
      };

      return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: additionalParams
      }).then(function(response) {
        $scope.loading = false;
        return response.data.content.data.map(function(item) {
          $scope.loading = false;
          return item;
        });
      }, function(error) {
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  function validateFieldSelection(field) {}

  function submitSearchForm() {
    var searchParam = validateForm();

    if (!!searchParam) {
      $scope.dataList = [];
      $scope.meta = {};
      $scope.selectedBudgets = [];
      $scope.processBudget = false;
      $scope.embeddedParams = searchParam;

      var moduleParam = {
        module: 'budgets'
      };

      $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: searchParam
      }).then(function(resource) {
        if (resource.data.content.data.length > 0) {
          $scope.dataList = resource.data.content.data;
          $scope.meta = resource.data.content.meta;
          $scope.emptyInput = false;
        }
      }, function(error) {
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  function validateForm() {
    var throwErr = false;

    if (_.isNull($scope.searchFields.budgetType)) {
      throwErr = true;
      toastr.error('Please select budget type');
    }

    if (_.isNull($scope.searchFields.budgetStatus)) {
      throwErr = true;
      toastr.error('Please select budget status');
    }

    if (_.isNull($scope.searchFields.budgetYear)) {
      throwErr = true;
      toastr.error('Please select budget year');
    }

    if (_.isNull($scope.searchFields.budgetOwner) && !$scope.searchFields.noBudgetOwner) {
      throwErr = true;
      toastr.error('Please select budget owner');
    }

    if (!throwErr) {
      var searchData = _.clone($scope.searchFields);
      return buildParam(searchData);
    }
  }

  function buildParam(data) {
    var params = {};

    params['criteria[1][maintenance_tools]'] = true

    if (!!data.budgetType) {
      params['criteria[1][expense_type_category]'] = data.budgetType.key
    }

    if (!!data.budgetStatus) {
      params['criteria[0][status]'] = data.budgetStatus.key
    }

    if (!!data.budgetYear) {
      params['criteria[1][years|value]'] = data.budgetYear.getFullYear()
    }

    if (data.noBudgetOwner) {
      params['criteria[1][no_budget_owner]'] = true;
    }

    if (!!data.budgetOwner) {
      params['criteria[1][owner]'] = data.budgetOwner._id
    }

    if (!!data.companyCode && data.companyCode.key !== 'all') {
      params['criteria[1][company|code]'] = data.companyCode.key
    }

    if (!!data.billingCompanyCode && data.billingCompanyCode.key !== 'all') {
      params['criteria[1][shadow_companies|code]'] = data.billingCompanyCode.key
    }

    // if status 'all' is selected, the form cannot be empty. so the criteria for 'all' status
    // will only be added if `params` is not empty.
    if (_.isEmpty(params)) {
      toastr.error('Please fill in at least one field');
      return false;
    } else {
      params['module'] = 'budgets';
      params['offset'] = 50;
      params['criteria_operator'] = 'and';
      params['criteria[1][$operator]'] = 'and';
      return params;
    }
  }

  function massUpdate() {
    $uibModal.open({
      templateUrl: 'app/maintenance/massUpdateBudgetOwner/massUpdateModal/massUpdateModal.html',
      backdrop: 'static',
      keyboard: false,
      scope: $scope,
      resolve: {
        data: function() {
          return $scope.searchFields;
        },
        selectedBudgets: function() {
          return $scope.selectedBudgets;
        }
      },
      controller: 'massUpdateModalCtrl'
    });
  }

  function initialize() {
    $scope.dataList = [];
    $scope.embeddedParams = [];
    $scope.meta = {};
  }

}
massUpdateBudgetOwnerCtrl.$inject = ['$scope', '$state', 'toastr', 'globalFunc', '$http', 'pathConstants', '$filter', 'searchModule', '$uibModal', '$timeout'];

angular
  .module('metabuyer')
  .controller('massUpdateBudgetOwnerCtrl', massUpdateBudgetOwnerCtrl);
