'use strict';

/**
 * @name itemListing Directive
 * @desc item listing that is used in the budget
 *
 * @isolatedScope data
 * To synchronize items data
 *
 * @isolatedScope budget
 * To accept budget id that using this directive
 *
 * @isolatedScope assignment
 * To accept budget assignment id that using this directive, optional
 *
 * @author H.W. Liew <liewhanwahwork@gmail.com>
 * @copyright 2017 Metacloud Sdn. Bhd.
 */
angular
  .module('metabuyer')
  .directive('budgetItemListing', function () {
    return {
      restrict: 'E',
      templateUrl: 'components/budgetItemListing/budgetItemListing.html',
      scope: {
        budget: '=',
        assignment: '=',
        listingType: '@?'
      },
      controller: function($scope, globalFunc, toastr, budgets, $http, pathConstants, $filter) {
        $scope.budgets = budgets;
        $scope.itemData = [];
        $scope.selectedTab = 'used';
        $scope.itemMeta = {};

        $scope.selectTab = selectTab;
        $scope.getItemListing = getItemListing;
        $scope.exportItem = exportItem;

        function exportItem() {
          if ($scope.itemData.length > 0) {
            var path = pathConstants.apiUrls.budget.export;
            path = path.replace(':id', $scope.budget);
            path = path.replace(':type', $scope.selectedTab);

            var assignmentId = !!$scope.assignment ? $scope.assignment : '';
            path = path.replace(':assignmentId', assignmentId);

            $http({
              method: 'GET',
              url: path
            }).then(function(response){
              var blob = new Blob([response.data]);
              var link = document.createElement('a');
              document.body.appendChild(link);
              link.href = window.URL.createObjectURL(blob);
              link.download = 'Budget-Items-'+ $scope.selectedTab.toUpperCase()+ '-' + $filter('date')(new Date(), 'dd-MMM-yyyy') +'.csv';
              link.click();
              toastr.success('Item(s) has been successfully exported.');
            }, function() {
              toastr.error('Unable to export item(s).');
            });
          } else {
            toastr.info('Item is empty.');
          }
        }

        // called when switching type of items
        function selectTab(tab) {
          $scope.selectedTab = tab;
          getItemListing();
        }

        // retrieve items data by type
        function getItemListing() {
          $scope.embeddedParams = {
            id: $scope.budget,
            assignment_id: $scope.assignment,
            item_type: $scope.selectedTab,
            listing_type: !!$scope.listingType ? $scope.listingType : 'budget_details',
            action: 'get-items',
            offset: 10,
            page: null,
            cursor: null
          };

          budgets.get(
            $scope.embeddedParams,
            function (resource) {
              if (!!resource.content && !!resource.content.data) {
                $scope.itemData = resource.content.data;
                $scope.itemMeta = resource.content.meta;
              }
            },
            function (error) {
              if (!!error.data.content && !!error.data.content.message) {
                toastr.error(error.data.content.message.join('</br>'));
              } else {
                globalFunc.objectErrorMessage(error);
              }
            }
          );
        }

        function initialize() {
          getItemListing();
        }

        initialize();
      }
    };
  });
