'use strict';

angular
  .module('metabuyer')
  .directive('tenderVoHistory', function($uibModal) {
    return {
      restrict: 'E',
      scope: {
        tender: '=',
        histories: '=',
      },
      template: '<div ng-include="templateUrlTenderVoHistory"></div>',
      controller: 'tenderVoHistory',
      link: function($scope) {
        $scope.templateUrlTenderVoHistory = 'components/tenderComponents/voHistory/tenderVoHistoryTemplate.html';
      }
    };
  });
