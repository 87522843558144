'use strict';

angular.module('metabuyer.services.faq', ['ngResource'])
  .factory('faqCommentService',
    function($resource, $filter, pathConstants) {
      return $resource(
        pathConstants.apiUrls.faq.v2.comments, {}, {
          get: {
            method: 'GET',
            ignoreLoadingBar: true
          },
          post: {
            method: 'POST',
            params: {},
            url: pathConstants.apiUrls.faq.v2.comments
          },
        }
      )
    })
    .factory('faqDocumentService', function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.faq.v2.documents, {}, {
          'upload' : {
            method: 'POST',
            url: pathConstants.apiUrls.faq.v2.documents.upload
          },
          'get': {
            method: 'GET',
            params: {
              id: '@id'
            },
            url: pathConstants.apiUrls.faq.v2.documents.get
          },
          'delete': {
            method: 'DELETE',
            params: {
              id: '@id'
            },
            url: pathConstants.apiUrls.faq.v2.documents.delete
          },
        }
      );
    });
