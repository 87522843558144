'use strict';

angular
  .module('metabuyer')
  .directive('ucwords', function(uppercaseFilter, $parse, globalFunc) {
    return {
      require: 'ngModel',
      link: function(scope, element, attrs, modelCtrl) {

        if(attrs.disableucwords == "true"){
          return ;
        }

        var capitalize = function(inputValue) {
          //save caret position
          var caretPos = element[0].selectionStart;
          if (!!inputValue) {
            var capitalized = globalFunc.capitalizeFirstCharacter(inputValue);
            if(capitalized !== inputValue) {
              modelCtrl.$setViewValue(capitalized);
              modelCtrl.$render();
              //restore caret position
              element[0].setSelectionRange(caretPos, caretPos);
            }
            return capitalized;
          }
        }
        var model = $parse(attrs.ngModel);
        modelCtrl.$parsers.push(capitalize);
        capitalize(model(scope));
      }
    };
  });
