'use strict';

function purchaseRequisitionsV2DetailsHeaderCtrl(
  $scope, metabuyerCache, subscriptionCheck, singleAddress, $q, companyExpenseTypes,
  purchaseRequisitionsV2Services, globalFunc, prV2Function, PRAccountCodeList, $rootScope, budgetList,
  companyErpCostCenterList, companyErpOrderTypeList, companyModeOfPurchase, priceComparisonResource, tenderService,
  UserPermissions

) {

  $scope.openDatePicker           = openDatePicker;
  $scope.clearNeededByDate        = clearNeededByDate;
  $scope.onSelectDeliveryAddress  = onSelectDeliveryAddress;
  $scope.onSelectBillingAddress   = onSelectBillingAddress;
  $scope.onSelectCostCenter       = onSelectCostCenter;
  $scope.onSelectBillingCompany   = onSelectBillingCompany;
  $scope.onSelectTender           = onSelectTender;
  $scope.onSelectModePurchase     = onSelectModePurchase;
  $scope.onSelectExpenseType      = onSelectExpenseType;
  $scope.onSelectCurrency         = onSelectCurrency;
  $scope.onSelectContactPerson    = onSelectContactPerson;
  $scope.onSelectPRRequestor      = onSelectPRRequestor;
  $scope.submissionValidation     = submissionValidation;
  $scope.getCurrencyList          = getCurrencyList;
  $scope.approvalFlowData         = approvalFlowData;
  $scope.setShowing               = setShowing;
  $scope.onSelectBudget           = onSelectBudget;
  $scope.checkAvailableBudget     = checkAvailableBudget;
  $scope.onChangeRemarks          = onChangeRemarks;
  $scope.onSelectErpCostCenter    = onSelectErpCostCenter;
  $scope.onSelectErpOrderType     = onSelectErpOrderType;
  $scope.getIntegrationStatus     = getIntegrationStatus;
  $scope.getEditingPRState        = getEditingPrState;
  $scope.hasCmmsIntegration       = hasCmmsIntegration;
  $scope.PRTotalAmount            = PRTotalAmount;
  $scope.findClosestNeededAtDate  = findClosestNeededAtDate;
  $scope.PRTotalAmountBeforeTax   = PRTotalAmountBeforeTax;
  $scope.prExpiryChecked          = prExpiryChecked;
  $scope.isNoPricingAllowed       = isNoPricingAllowed;
  $scope.classIds                 = [1,2,3];
  $scope.tenderIds                = [];
  $scope.noPricingAvailableChecked = noPricingAvailableChecked;
  $scope.selectedTender = {
    'selected'  : null
  };
  $scope.noPricingAvailableChecked = noPricingAvailableChecked;
  $scope.pcStatus = false;
  $scope.pcNumber = null;
  $scope.isPriceComparisonEnabledinCompanySettings = prV2Function.isPriceComparisonEnabledinCompanySettings();

  var defaultModeOfPurchase = {
    '_id': null,
    'code': 'COMPANY',
    'name': 'Company',
    'uuid': 'COMPANY'
  };

  var defaultCostCenter = {
    '_id': null,
    'code': 'MULTIPLE',
    'descr': 'Multiple'
  };

  function loadTenderData() {

    tenderService.findByCompany(
      {
        companyCode: $scope.PR.company.code
      },
      function (resource) {
        if (!!resource && !!resource.data) {
          $scope.tenderIds = resource.data;

          if (!!$scope.editableData.tender_id) {
            _.forEach($scope.tenderIds, function(value) {
              if (value.key === $scope.editableData.tender_id) {
                $scope.selectedTender.selected = value;
              }
            });
          }
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )

    $scope.noPricingAvailableCheckedPrItem = false;

    if ($scope.PR.pr_items !== undefined && $scope.PR.pr_items.length > 0) {
      _.forEach($scope.PR.pr_items, function (prItems) {
        if (prItems.no_pricing_available) {
          return $scope.noPricingAvailableCheckedPrItem = true;
        }
      });
    }

  }

  function checkAvailableBudget(budgetListing) {
    var emptyArray = false;

    _.forEach(budgetListing, function(value) {
      if (!!value._id) {
        emptyArray = true;
      }
    });

    return emptyArray;
  }

  function prExpiryChecked() {
    $scope.editableData.pr_no_expiry = !!$scope.editableData.pr_no_expiry ? $scope.editableData.pr_no_expiry : false;
    updateHeader('pr_no_expiry', $scope.editableData.pr_no_expiry).then(function (resource) {
      if (!!resource && !!resource.content && !!resource.content.data) {
        prV2Function.setPRData(resource.content.data);
      }
    }, function () {
      $scope.editableData.pr_no_expiry = prV2Function.getPRData().pr_no_expiry;
    });
  }

  function hasCmmsIntegration() {
    return prV2Function.hasCmmsIntegration();
  }

  function loadErpCostCenter() {
    $scope.erpCostCentersList = [];
    companyErpCostCenterList.get(
      {
        id: $scope.PR.company._id
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.erpCostCentersList = resource.content.data;
          prV2Function.setErpCostCentersList(resource.content.data);
          if ($scope.erpCostCentersList.length < 1) {
            $scope.editableData.erp_cost_center = {
              '_id': null,
              'descr': 'N/A ( Not Applicable )'
            };
          }
          prV2Function.setIntegrationDependencies('erp_cost_center', $scope.erpCostCentersList);
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )

  }

  function onSelectErpCostCenter(erp) {
    updateHeader('erp-cost-center', erp._id).then(function (resource) {
      if (!!resource && !!resource.content && !!resource.content.data) {
        prV2Function.setPRData(resource.content.data);
        $scope.editableData.erp_cost_center = erp;
      }
    }, function () {
      $scope.editableData.erp_cost_center = prV2Function.getPRData().erp_cost_center;
    });
  }

  function loadErpOrderType() {
    $scope.erpOrderTypesList = [];
    companyErpOrderTypeList.get(
      {
        id: $scope.PR.company._id
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.erpOrderTypesList = resource.content.data;
          prV2Function.setErpOrderTypesList(resource.content.data);
          if ($scope.erpOrderTypesList.length < 1) {
            $scope.editableData.erp_order_type = {
              '_id': null,
              'descr': 'N/A ( Not Applicable )'
            };
          }
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )

  }

  function onSelectErpOrderType(orderType) {
    updateHeader('erp-order-type', orderType._id).then(function (resource) {
      if (!!resource && !!resource.content && !!resource.content.data) {
        prV2Function.setPRData(resource.content.data);
        $scope.editableData.erp_order_type = orderType;
      }
    }, function () {
      $scope.editableData.erp_order_type = prV2Function.getPRData().erp_order_type;
    });
  }

  function copyTheDataToTemporaryModes() {
    $scope.editableData = angular.copy(prV2Function.getPRData());
    if (!!$scope.editableData.budget && !$scope.editableData.budget._id) {
      $scope.editableData.budget = $scope.defaultBudgetOption
    }
  }

  /**
   * checking the Subscription of the PR
   * @param requisition
   */
  function checkSubscription(requisition) {
    subscriptionCheck.get(
      {
        object_id: requisition._id,
        class: 'Metabuyer\\PR\\PR'
      },
      function (resource) {
        $scope.PR.notify = resource.subscribed;
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }


  /**
   * Load the Addresses and Caching them
   * @param addresses
   * @param type
   */
  function loadAddressesData (addresses, type) {
    var promises = [];
    var cleanedAddress = [];
    angular.forEach(addresses, function (id) {
      if (!!metabuyerCache.get(id)) {
        cleanedAddress.push(metabuyerCache.get(id));
      } else {
        var promise = singleAddress.get({
            id: id
          }
        );
        promises.push(promise.$promise);
      }
    });
    $q.all(promises).then(function (resource) {
      angular.forEach(resource, function (address) {
        if (!!address && !!address.content && !!address.content.data) {
          cleanedAddress.push(address.content.data);
          metabuyerCache.put(address.content.data._id, address.content.data);
        }
      });
    }).catch().finally(function () {
      if (type === 'billing') {
        $scope.costCenterBillingAddresses = angular.copy(cleanedAddress);
      }
    });
  }

  /**
   * store the available addresses for this PR in variables
   * Also, enables the selection of multiple addresses or not
   * adding the billing address from the Cost Center
   */
  function loadAvailableAddresses(CC) {
    $scope.enableSelectingBillingAddress = false;
    $scope.enableSelectingDelieveryAddress = false;

    purchaseRequisitionsV2Services.getAvailableDeliveryAddresses(
      {
        id: $scope.PR.company._id
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.costCenterDeliveryAddresses = resource.content.data;
          prV2Function.setCostCenterDeliveryAddresses(resource.content.data);
          _.forEach(resource.content.data, function (address) {
            metabuyerCache.put(address._id, address);
          });
          //Handle N/A case, by loading all available Addresses within the company
          if(!CC || !CC._id || prV2Function.getPRData().status ==='draft') {
            $scope.costCenterBillingAddresses = resource.content.data;
          }
        } else {
          globalFunc.objectErrorMessage('Loading addresses failed.');
        }

      }, function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }


  /**
   * loading the list of company expense types
   */
  function loadExpenseTypes() {
    companyExpenseTypes.get(
      {
        id: $scope.PR.company._id
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          var expenseTypes = resource.content.data;
          var isDeveloper = UserPermissions.isDeveloper($rootScope.currentUser);
          for(var index in expenseTypes) {
            var expenseType = expenseTypes[index];
            if (!isDeveloper && (expenseType['subtype'] === 'SPK' || expenseType['subtype'] === 'GWO')) {
              continue;
            }
            $scope.expenseTypes.push(expenseType);
          }
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  /**
   * Loads messages from the warning block field (warning_block)
   * See WarningBlock.php trait in the backend for more info
   */
  function loadWarningBlockData(pr) {
    if (!pr.warning_block || !pr.warning_block.messages) {
      return;
    }

    for (var messageType in pr.warning_block.messages) {
      prV2Function.setPRWarnings(messageType, pr.warning_block.messages[messageType]);
    }
  }

  /**
   * loading the List of available contact persons
   */
  function loadContactPersons() {
    $scope.contactPersonsList = [];
    purchaseRequisitionsV2Services.getContactPersonsList({
        id: $scope.PR.company._id
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.contactPersonsList = resource.content.data;
          $scope.requestorsList = resource.content.data;
          prV2Function.setContactPerson(resource.content.data);
        }
      });
  }

  /**
   * loading the List of available contact persons
   */
  function loadRequestorsList(CC) {
    if (!CC._id){
      $scope.requestorsList = $scope.contactPersonsList;
    }
    else{
      purchaseRequisitionsV2Services.getAvailableRequestorsList({
          id: CC._id
        },
        function (resource) {
          if (!!resource.content && !!resource.content.data) {
            $scope.requestorsList = resource.content.data;

            /**
             * remove the creator from the List and adding it to the start of the list
             */
            $scope.requestorsList = globalFunc.removeValueFromArray($scope.requestorsList, '_id', $scope.PR.creator_info._id);
            $scope.requestorsList.unshift($scope.PR.creator_info);

          }else{
            //error
          }

        },function(error){
          //error
        });
    }
  }


  function onSelectDeliveryAddress(address) {
    updateHeader('delivery-address', address._id).then(function (resource) {
      if (!!resource && !!resource.content && !!resource.content.data) {
        prV2Function.setPRData(resource.content.data);
        $scope.editableData.delivery_to_address = address;
      } else {
        //TODO: handle the else case;
      }
    }, function () {
      $scope.editableData.delivery_to_address = prV2Function.getPRData().delivery_to_address;
    });
  }

  function onSelectBillingAddress(address) {
    updateHeader('billing-address', address._id).then(function (resource) {
      if (!!resource && !!resource.content && !!resource.content.data) {
        prV2Function.setPRData(resource.content.data);
        $scope.editableData.billing_to_address = address;
      } else {
        //TODO: handle the else case;
      }
    }, function () {
      $scope.editableData.billing_to_address = prV2Function.getPRData().billing_to_address;
    });
  }

  /**
  function onSelectBillingCompany(company) {
   * Load All available costCenters fro the user profile and cache them
   * @returns {Array}
   */
  function loadCostCenters() {
    var allCostCenters = [];
    allCostCenters = $rootScope.currentUser.costCenters;
    return allCostCenters;
  }

  /**
   * refine the list of Cost Centers to the ones available within the Company of the PR
   * the cost centers object in the User only holds the company Code, not ID, so the matching is using the unique code of the Company
   * @param allCostCenters
   * @returns {Array}
   */
  function loadSelectableCostCenters(allCostCenters) {

    var costCenters = [];
    var filteredCostCenters = [];

    var default_cost_center = [];
    _.forEach(allCostCenters, function (CC) {
      if (!!$scope.PR && !!$scope.PR.company &&
        (CC.shadow_company.code === $scope.editableData.alternate_billing_company.code) && (CC.is_active === 1)) {
        costCenters.push(CC);
      }
      if (!!$scope.PR && !!$scope.PR.company &&
        (CC.shadow_company === '') && (CC.is_active === 1)) {
        costCenters.push(CC);
      }
      if (!!$scope.PR && !!$scope.PR.company &&
        (!CC.shadow_company._id) && (CC.is_active === 1)) {
        costCenters.push(CC);
      }

      if ($scope.company.default_cost_center) {
        if ($scope.company.default_cost_center._id == CC._id) {
          default_cost_center = CC;
        }
      }
    });

    _.forEach($rootScope.currentUser.role_assignments, function (roleAssignment) {
      _.forEach(costCenters, function (singleCostCenter) {
        if (singleCostCenter._id === roleAssignment.organization_id ||
            (singleCostCenter.company_id === $scope.PR.company._id &&
             singleCostCenter.company_id === roleAssignment.organization_id )) {

          if (UserPermissions.hasPermission($rootScope.currentUser, 'PR', 'Create', roleAssignment.organization_id)) {
            if (filteredCostCenters.indexOf(singleCostCenter) < 0) {
                filteredCostCenters.push(singleCostCenter);
            }
          }
        }
      });
    });

    var default_cost_center_select = false;
    _.forEach(filteredCostCenters, function (filter_cost_center) {
      if ($scope.company.default_cost_center._id == filter_cost_center._id) {
        default_cost_center_select = true;
      }
    });

    if (!default_cost_center_select && default_cost_center.shadow_company) {
      globalFunc.objectErrorMessage('Default Cost Center is assigned to Billing Company ' + default_cost_center.shadow_company.descr);
    }

    if(!!filteredCostCenters.length){
      return filteredCostCenters;
    }

    return costCenters;
  }

  function onSelectBillingCompany(company) {
    updateHeader('shadow-company', company._id).then(function (resource) {
      if (!!resource && !!resource.content && !!resource.content.data) {
        prV2Function.setPRData(resource.content.data);
        $scope.editableData.alternate_billing_company = company || null;
        metabuyerCache.remove('userCostCenters');
        var allCostCenters =  loadCostCenters();
        $scope.costCenters = loadSelectableCostCenters(allCostCenters);
        $scope.headerCostCenters = $scope.costCenters;
        $scope.editableData.cost_center = { _id: null, code: "MULTIPLE", descr: "Multiple", "$hashKey": "object:210" };
        $scope.headerCostCenters.unshift({ _id: null, code: "MULTIPLE", descr: "Multiple", "$hashKey": "object:210" })
        onSelectCostCenter($scope.editableData.cost_center);
      } else {
        //TODO: handle the else case;
      }
    }, function () {
      $scope.editableData.alternate_billing_company = prV2Function.getPRData().shadow_company;
    });
  }

  function billingCompany(company) {
    var billingCompany = globalFunc.findInArray($scope.PR.company.billingCompanies, 'code', $scope.editableData.billingCompanies);
    if (!!billingCompany) {
      $scope.editableData.billingCompanies = billingCompany;
    }
  }

  /**
   * validation to check account code in line item
   */
  function validateAccountCode(items) {
    var accountCodeExist = [];
    if (!!items && !!items.length) {
      _.forEach(items, function (item) {
        if (!!item.account_code)
          accountCodeExist.push(item._id);
      });
    }
    return accountCodeExist;
  }

  /**
   * on updating cost center
   * needs to validate the account code in line item,
   * warn the user if line item is having account code from existing cost center
   * @param CC
   */
  function onSelectCostCenter(CC){
    var pr = prV2Function.getPRData();
    // Validate item tax and account code
    if ((!!pr.cost_center && !!pr.cost_center._id && pr.cost_center._id !== CC._id) || pr.duplicated_from != null) {

      // todo ameer enhance this condition. by default cost center is null thus will not pass this condition ever  20 march 2020

      // check pr items when updating cost center
      if ((!!pr.pr_items && !!pr.pr_items.length ) || pr.duplicated_from != null) {
        swal({
          title: 'Item Tax & Account code would be updated!',
          text: 'Updating the Purchase Requisition Cost Center will update the assigned tax ' +
          'should the item has different tax setting and remove assigned account code (if any).',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Proceed',
          closeOnConfirm: true
        }, function(isConfirm){
          if (isConfirm) {
            var itemIds = validateAccountCode(pr.pr_items);
            if (!!itemIds && !!itemIds.length) {
              prV2Function.setLoading('lineItemTable', true);
              removeAccountCodes(itemIds, CC);
            } else {
              updatingCostCenter(CC);
            }
          } else {
            $scope.editableData.cost_center = pr.cost_center;
          }
        });
      } else {
        updatingCostCenter(CC);
      }
    } else {
      // for newly creation PR
      updatingCostCenter(CC);
    }
  }

  /**
   * remove account codes in all items
   * @param ids
   * @param CC
   */
  function removeAccountCodes(ids, CC) {
    purchaseRequisitionsV2Services.setItem({
      requisition_item_id: ids[0],
      action: 'account-code'
    }, {
      account_code_id : null
    }, function (resource) {
      if (!!resource && !!resource.content && !!resource.content.data)
        prV2Function.setPRData(resource.content.data);

      ids.shift();
      if (!!ids && !!ids.length)
        removeAccountCodes(ids, CC);
      else {
        updatingCostCenter(CC);
        prV2Function.setLoading('lineItemTable', false);
      }
    }, function (error) {
      globalFunc.objectErrorMessage(error);
      callback(false);
    });
  }

  /**
   * Updating cost center process
   * @param CC
   */
  function updatingCostCenter(CC) {
    loadRequestorsList(CC);

    if (!prV2Function.getPRData().cost_center || prV2Function.getPRData().cost_center._id ||
      prV2Function.getPRData().cost_center._id !== CC._id)
      loadAvailableAddresses(CC);

    updateHeader('cost-center', CC._id).then(function (resource) {
      if (!!resource && !!resource.content && !!resource.content.data) {
        prV2Function.setPRData(resource.content.data);
        $rootScope.$broadcast('checkIntegrationData');
      }
    }, function () {
      var backupCC = prV2Function.getPRData().cost_center;
      if(!backupCC)
        $scope.editableData.cost_center = defaultCostCenter;
      else
        $scope.editableData.cost_center = backupCC;
    });
  }

  function onSelectExpenseType(expenseType){
    if(!expenseType || !expenseType._id)
      return;

    updateHeader('expense-type', expenseType._id).then(function (resource) {
      if (!!resource && !!resource.content && !!resource.content.data) {
        prV2Function.setPRData(resource.content.data);
        //Remove budget selection when expense type is modified
        $scope.editableData.budget = $scope.defaultBudgetOption;
        $scope.PR.budget = $scope.defaultBudgetOption;
      }
    }, function () {
      $scope.editableData.expense_type = prV2Function.getPRData().expense_type;
    });
  }

  function onSelectModePurchase(mode) {
    updateHeader('mode-of-purchase', mode.uuid).then(function (resource) {
      if (!!resource && !!resource.content && !!resource.content.data) {
        prV2Function.setPRData(resource.content.data);
        checkApprovalWarning($scope.editableData.mode_of_purchase, prV2Function.getPRData().company);
      }
    }, function () {
      $scope.editableData.mode_of_purchase = prV2Function.getPRData().mode_of_purchase;
    });
  }

  function onSelectTender(tender, oldValue) {

    if (tender === null || $scope.editingState === 'notEditable') {
      return;
    }

    var oldValue = (oldValue === undefined) ? null : oldValue;

    updateHeader('tender-id', tender).then(function (resource) {
      //TODO: this is text only, once Tender is implemented update this (Ahmed Saleh, 21/5/2020)
      if (!!resource && !!resource.content && !!resource.content.data) {
        prV2Function.setPRData(resource.content.data);
        $scope.editableData.tender = tender === undefined ? null : tender.key;
        $scope.editableData.tender_id = tender;
        $scope.editableData.no_pricing_available = resource.content.data.no_pricing_available;
        $scope.selectedTender.selected = tender === undefined ? null : tender;
      } else {
        //TODO: handle the else case;
      }

      $rootScope.$broadcast('updateTenderUtilizationDashboard', {
        tenderId: $scope.editableData.tender,
        currencyCode: $scope.editableData.currency.code
      });

    }, function (error) {
      $scope.selectedTender.selected = oldValue;
    });
  }

  function onSelectCurrency(currency){

    if(!currency || !currency._id)
      return;

    updateHeader('currency', currency._id).then(function (resource) {
      if (!!resource && !!resource.content && !!resource.content.data) {
        prV2Function.setPRData(resource.content.data);

        $rootScope.$broadcast('updateTenderUtilizationDashboard', {
          tenderId: $scope.editableData.tender,
          currencyCode: prV2Function.getPRData().currency.code
        });

      }
    }, function () {
      $scope.editableData.currency = prV2Function.getPRData().currency;
    });
  }

  function onSelectBudget(budget) {
    if (!!$scope.PR.lump_sum_discount && !!budget.has_item_breakdown) {
      swal({
        title: 'Confirm select this budget?',
        text: 'Selecting budget with item breakdown will remove lump sum discount.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true
      }, function (isConfirm) {
        if (isConfirm) {
          checkPrItemsToBudget(budget);
        } else {
          checkPrItemsToBudget(prV2Function.getPRData().budget);
        }
      });
    } else {
      checkPrItemsToBudget(budget);
    }
  }

  function checkPrItemsToBudget(budget) {
    var info = {
      'budget_id': !!$scope.editableData.budget && !!$scope.editableData.budget._id ?
        $scope.editableData.budget._id : null,
      'budget_assignment': !!$scope.editableData.budget && !!$scope.editableData.budget.assignment_id ?
        $scope.editableData.budget.assignment_id : null
    };

    purchaseRequisitionsV2Services.checkPRCompanyInfo(
      {
        id: $scope.PR._id
      }, {
        budget: info
      }, function () {
        updateHeader('budget', budget._id).then(function (resource) {
          $scope.PR.budget = budget;
          if (!!resource && !!resource.content && !!resource.content.data) {
            prV2Function.setPRData(resource.content.data);
          }
        }, function () {
          $scope.editableData.budget = prV2Function.getPRData().budget;
        });
      }, function (error) {
        if (!!error && !!error.data && !!error.data.content && !!error.data.content.error) {
          if (error.data.content.error === 'InvalidPrItems') {
            userToConfirmRemovePrItems(error.data.content.message, budget);
          }
        }
      }
    );
  }

  function userToConfirmRemovePrItems(removedItems, budget) {
    swal(
      {
        title: 'Confirm select budget?',
        text: 'Selecting this budget will remove the following Items:  '.concat(removedItems),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        closeOnConfirm: true
      },
      function (isConfirm) {
        if (isConfirm) {
          $scope.PR.budget = budget;
          onUpdatingHeaderInfo();
        } else {
          $scope.editableData.budget = prV2Function.getPRData().budget;
        }
      }
    );
  }

  function onSelectContactPerson(person) {
    var text = null;
    if(typeof person === 'object'){
      text = person.display_name;
    } else if(!!person) {
      text = person;
    }

    purchaseRequisitionsV2Services.setPPContactPerson(
      {
        requisition_id: $scope.PR._id
      },{
        contact_person : text
      },function(resource){
        $scope.PR.contact_person = text;
      },function (error){
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function onSelectPRRequestor(person) {

    purchaseRequisitionsV2Services.setRequestedBy(
      {
        requisition_id: $scope.PR._id
      },{
        requested_by_id : person._id
      },function(resource){
        $scope.PR.requestor = person;
      },function (error){
       // console.log(error);
      }
    );
  }

  /**
   * Action when remarks is changed
   *
   * @param {string} remarks
   */
  function onChangeRemarks(remarks) {

    purchaseRequisitionsV2Services.setRemarks(
      {
        requisition_id: $scope.PR._id
      },{
        remarks : remarks
      },function(){
        $scope.PR.remarks = remarks;
      },function (error){
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function openDatePicker($event){
    $event.preventDefault();
    $event.stopPropagation();
    $scope.editableData.neededByDatePanel = true;
  }

  function updateHeader(field, value) {
    prV2Function.setLoading('updatePRField', true);
    var data ={};
    data[field]= value;
    return purchaseRequisitionsV2Services.updateHeaderInfo(
      {
        'requisition_id': prV2Function.getPRData()._id,
        'field': field
      },
      data,
      function() {
      },
      function(error) {
        globalFunc.objectErrorMessage(error);
      }).$promise;
  }

  /**
   * this function checks the models for the Billing Address, Delivery Address, Cost Center, Expense Type and Currency, then update them as 1 payload in the backend
   * @param billingAddress
   * @param deliveryAddress
   * @param CCId
   * @param expenseType
   * @param currencyId
   * @param alternateBillingCompanyCode
   * @param tenderId
   * @param modeOfPurchase
   * //TODO: nuke this
   */
  function onUpdatingHeaderInfo(billingAddress, deliveryAddress, CCId, expenseType, currencyId, alternateBillingCompanyCode, tenderId, modeOfPurchase){
    prV2Function.setLoading('updatePRField', true);
    var info = {
      'company_id': $scope.PR.company._id,
      'billing_to_address_id' : (!!billingAddress || billingAddress === '') ? billingAddress : (!!$scope.editableData.billing_address && !!$scope.editableData.billing_address._id) ?
        $scope.editableData.billing_address._id : $scope.editableData.billing_to_address._id,
      'cost_center_id' : (!!CCId || CCId ==='') ? CCId : (!!$scope.editableData.cost_center && !!$scope.editableData.cost_center._id) ?
        $scope.editableData.cost_center._id : null,
      'expense_type_id' : (!!expenseType || expenseType === '') ? expenseType :
        (!!$scope.editableData.expense_type && !!$scope.editableData.expense_type._id) ? $scope.editableData.expense_type._id : null,
      'currency_id' : (!!currencyId || currencyId === '') ? currencyId : (!!$scope.editableData.currency && !!$scope.editableData.currency._id) ? $scope.editableData.currency._id : null,
      'alternate_billing_company' : (!!alternateBillingCompanyCode || alternateBillingCompanyCode === '') ? alternateBillingCompanyCode :
        (!!$scope.editableData.alternate_billing_company) ? $scope.editableData.alternate_billing_company : null,
      'budget_id': !!$scope.editableData.budget && !!$scope.editableData.budget._id ?
        $scope.editableData.budget._id : null,
      'budget_assignment': !!$scope.editableData.budget && !!$scope.editableData.budget.assignment_id ?
        $scope.editableData.budget.assignment_id : null,
      'erp_cost_center_id': !!$scope.editableData.erp_cost_center ? $scope.editableData.erp_cost_center._id : null,
      'erp_order_type_id': !!$scope.editableData.erp_order_type ? $scope.editableData.erp_order_type._id : null,
      'tender_id' : (!!tenderId || tenderId === '') ? tenderId : $scope.editableData.tender_id,
      'mode_of_purchase' : (!!modeOfPurchase || modeOfPurchase === '') ? modeOfPurchase : $scope.editableData.mode_of_purchase
    };

    purchaseRequisitionsV2Services.updatePRCompanyInfo(
      {
        requisition_id: $scope.PR._id
      },{
        company : info
      },function(resource){
        $scope.PR.billing_address = $scope.editableData.billing_address;
        $scope.PR.delivery_address = $scope.editableData.delivery_address;
        $scope.PR.cost_center = $scope.editableData.cost_center;
        $scope.PR.expense_type = $scope.editableData.expense_type;
        $scope.PR.currency = $scope.editableData.currency;
        $scope.PR.alternate_billing_company = $scope.editableData.alternate_billing_company;
        $scope.PR.tender_id = $scope.editableData.tender_id;
        $scope.PR.mode_of_purchase = $scope.editableData.mode_of_purchase;
        if (!!resource && !!resource.content && !!resource.content.data) {
          prV2Function.setPRData(resource.content.data);
          $scope.editableData.billing_to_address = resource.content.data.billing_to_address;
          $scope.editableData.delivery_to_address = resource.content.data.delivery_to_address;
          $rootScope.$broadcast('checkIntegrationData');
        }
      },function (error){
        // TODO: revert other editableData if error occurs, Shin (13/5/2020)
        $scope.editableData.expense_type = $scope.PR.expense_type;
        globalFunc.objectErrorMessage(error);
      }
    );

  }

  $scope.$watch('editableData.needed_by_date', function (newValue, oldValue) {

    if (!newValue || (newValue === oldValue) || typeof newValue.setHours === "undefined") {
        // nothing is needed
    }
    else {
      newValue.setHours(0,0,0,0);
      var milliseconds = globalFunc.convertDateToTimestamp(newValue);

      purchaseRequisitionsV2Services.setNeededByDate(
        {
          requisition_id: $scope.PR._id
        },{
          needed_by_date : milliseconds
        },function(resource){

        },function (error){
          globalFunc.objectErrorMessage(error);
        }
      );
    }

  });

  function getCurrencyList() {
    return prV2Function.getCurrenciesData();
  }

  function clearNeededByDate($event){
    $event.preventDefault();
    $event.stopPropagation();

    purchaseRequisitionsV2Services.setNeededByDate(
      {
        requisition_id: $scope.PR._id
      },{
        needed_by_date : ''
      },function(){
        $scope.editableData.needed_by_date = null;
        $scope.PR.needed_by_date = null;
      },function (error){
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  /**
   * check errors field from prV2Function.getErrorOnSubmit
   * @param field
   */
  function submissionValidation(field) {
    var errors = prV2Function.getErrorsOnSubmit();
    return (errors.indexOf(field) > -1);
  }

  function PRTotalAmount() {
    var localPRData = prV2Function.getPRData();
    return localPRData.grand_total_pr_currency;
  }

  function PRTotalAmountBeforeTax() {
    var localPRData = prV2Function.getPRData();
    var amountBeforeTax = localPRData.grand_total_pr_currency - localPRData.tax_total_pr_currency;

    // this formula below handles cases for calcs like 1.23 - 1.01 = 0.21999999999999997 to be 0.22
    return (+(Math.round(amountBeforeTax + "e+2")  + "e-2")).toFixed(2);
  }

  function approvalFlowData() {
    $scope.localApprovalData = prV2Function.getApprovalFlow();
    return $scope.localApprovalData;
  }

  function getEditingPrState() {
    return prV2Function.getEditingState();
  }

  function setShowing(section){
    switch (section) {
      case 'bill_to':
        $scope.billToShow = ($scope.billToShow === true) ? false : true;
        break;
      case 'deliver_to':
        $scope.deliverToShow = ($scope.deliverToShow === true) ? false : true;
        break;
      case 'other_details':
        $scope.otherToShow = ($scope.otherToShow === true) ? false : true;
        break;
    }
  }

  function getIntegrationStatus(){
    return prV2Function.getIntegrationStatus() && !$rootScope.isV2Enabled;
  }

  function findClosestNeededAtDate() {
    return prV2Function.findClosestNeededAtDate();
  }

  function checkApprovalWarning(modeOfPurchase, headerCompany) {
    if (!modeOfPurchase.approval_company_code) {
      prV2Function.setPRWarnings('differentApprovalCompany', undefined);
      return;
    }
    if (modeOfPurchase.approval_company_code !== headerCompany.code) {
      if(!!modeOfPurchase.company && modeOfPurchase.company.length > 0)
        prV2Function.setPRWarnings('differentApprovalCompany', 'This PR is only for HQ procurement centralized purchase purposes, the approval will follow Company: ' +
          modeOfPurchase.company[0].descr);
    }
  }

  function getCompanyModeOfPurchase(pr) {
    companyModeOfPurchase.get({
        id: $scope.PR.company._id
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.mode_of_purchase = resource.content.data;
          $scope.mode_of_purchase.unshift(defaultModeOfPurchase);

          if (pr.mode_of_purchase === defaultModeOfPurchase.code) {
            $scope.editableData.mode_of_purchase = defaultModeOfPurchase;
          } else {
            $scope.editableData.mode_of_purchase =
              globalFunc.findInArray($scope.mode_of_purchase, 'uuid', pr.mode_of_purchase.uuid);
            checkApprovalWarning($scope.editableData.mode_of_purchase, pr.company);
          }
        }
      }
    )
  }

  function noPricingAvailableChecked(){
    $scope.editableData.no_pricing_available = !!$scope.editableData.no_pricing_available ? $scope.editableData.no_pricing_available : false;

    updateHeader('no-pricing-available', $scope.editableData.no_pricing_available).then(function (resource) {
      if (!!resource && !!resource.content && !!resource.content.data) {
        prV2Function.setPRData(resource.content.data);
      }
    }, function () {
      $scope.editableData.no_pricing_available = prV2Function.getPRData().no_pricing_available;
    });

  }

  function isNoPricingAllowed() {
    return prV2Function.getDocumentRule('allow_no_pricing_available');
  }

    /**
     * Check PC current status and to display PC number on linked PR
     */
  function checkPCstatus(){
     if ($scope.PR.pc_id !== undefined && $scope.PR.pc_id !== '')
     {
        priceComparisonResource.get(
        {
          id: prV2Function.getPRData().pc_id
        },
        function (resource) {
          $scope.pcStatus = false;
          if (!!resource.content) {
            if (resource.content.data.status == 'PENDING' || resource.content.data.status == 'APPROVED' || resource.content.data.status == 'REJECTED' || resource.content.data.status == 'WITHDRAWN')
            {
              $scope.pcNumber = resource.content.data.pc_number;
              $scope.pcStatus = true;
            }
          }
        }
      )
     }
  }

  function initialize(){
    /**
     * Assign variable for pr data
     */
    var pr = prV2Function.getPRData();

    $scope.dateOptions = {
      formatYear: 'yy',
      minDate: new Date(),
      startingDay: 1
    };
    $scope.datePickerTimezone = $rootScope.datePickerTimezone;

    $scope.requestorsList = [];
    $scope.expenseTypes = [];
    $scope.budgetListing = [];
    $scope.billToShow = false;
    $scope.deliverToShow = false;
    $scope.otherToShow = false;
    $scope.defaultBudgetOption = {
      '_id': null,
      'assignment_id': null,
      'descr': 'N/A',
      'code': 'Not Applicable',
      'type': null
    };

    copyTheDataToTemporaryModes();
    // Removing the unused feature to be fixed from backend (Ahmed Saleh, 19/07/2018)
    // checkSubscription($scope.PR);

    if (!!$scope.costCenters && !!$scope.costCenters.length){
      if(!!$scope.PR.cost_center && !!$scope.PR.cost_center._id){
        loadAvailableAddresses(globalFunc.findInArray($scope.costCenters, '_id', $scope.PR.cost_center._id));
      } else {
        loadAvailableAddresses(globalFunc.findInArray());
      }
    }

    loadExpenseTypes();
    loadContactPersons();
    if(prV2Function.getIntegrationStatus()) {
      loadErpCostCenter();
      loadErpOrderType();
    }

    if(!!pr.cost_center && !!pr.cost_center._id){
      loadRequestorsList(pr.cost_center);
    }
    else {
      $scope.editableData.cost_center = defaultCostCenter;
    }

    $scope.headerCostCenters = _.cloneDeep($scope.costCenters);
    $scope.headerCostCenters.unshift(defaultCostCenter);

    var billingCompanyArray = globalFunc.findInArray($scope.PR.company.shadow_companies, 'code', $scope.editableData.alternate_billing_company);
    if (!!billingCompanyArray) { // this company has multiple billing companies in company profile
      $scope.editableData.alternate_billing_company = billingCompanyArray;
    } else if ($scope.PR.company.shadow_companies.length === 1) { // this company has one billing company in company profile
      $scope.editableData.alternate_billing_company = $scope.PR.company.shadow_companies[0];
    } else if (!!$scope.PR.shadow_company) { // this company doesn't has billing company in company profile
      $scope.editableData.alternate_billing_company = $scope.PR.shadow_company;
    } else if (!_.isEmpty($scope.PR.company.shadow_companies)) {
      $scope.editableData.alternate_billing_company = $scope.PR.company.shadow_companies[0];
    }

    //TODO: this is text only, once Tender is implemented update this (Ahmed Saleh, 21/5/2020)
    $scope.editableData.tender = $scope.PR.tender_id;

    getCompanyModeOfPurchase(pr);
    loadWarningBlockData(pr);
    checkPCstatus();
    loadTenderData();
  }

  initialize();

  $scope.$watch('selectedTender.selected', function (newValue, oldValue) {
    onSelectTender(newValue, oldValue)
  });

  $scope.$on('noPricingAvailableCheckedPrItem', function(event, checked) {
    $scope.noPricingAvailableCheckedPrItem = checked;
  });

  $scope.$on('paEditMode', function(event, checked) {
    initialize()
  });

}


angular
  .module('metabuyer')
  .directive('purchaseRequisitionV2DetailsHeader',function($rootScope) {
      return {
        restrict: 'E',
        scope: {
          editingState: '=',
          PR: '=pr',
          costCenters: '=',
          company: '=',
          costCenters: '='
        },
        template: '<div ng-include="myTemplateUrl"></div>',
        controller: 'purchaseRequisitionsV2DetailsHeaderCtrl',
        link: function ($scope) {
          $scope.getTemplateUrl = getTemplateUrl;

          function getTemplateUrl(state) {
            if ($rootScope.isMobileMode){
              $scope.myTemplateUrl = 'components/purchaseRequisitionV2Components/detailsV2Header/purchaseRequisitionV2DetailsHeader.NonEditableTemplate-mobile.html';
            }

            if (!$rootScope.isMobileMode) {

              if (state === 'editable' && $scope.isPriceComparisonEnabledinCompanySettings && $scope.PR.status.toLowerCase() === 'edit') {
                $scope.myTemplateUrl = 'components/purchaseRequisitionV2Components/detailsV2Header/purchaseRequisitionV2DetailsHeader.NonEditableTemplate.html';
              } else {
                switch (state) {
                  case 'editable':
                    $scope.myTemplateUrl = 'components/purchaseRequisitionV2Components/detailsV2Header/purchaseRequisitionV2DetailsHeader.EditableTemplate.html';
                    break;
                  case 'notEditable' :
                    $scope.myTemplateUrl = 'components/purchaseRequisitionV2Components/detailsV2Header/purchaseRequisitionV2DetailsHeader.NonEditableTemplate.html';
                    break;
                }
              }
            }
          }
          $scope.$watch('editingState', function (newValue) {
            getTemplateUrl(newValue);
          });
        }
      }
    })
  .controller('purchaseRequisitionsV2DetailsHeaderCtrl',  purchaseRequisitionsV2DetailsHeaderCtrl);
