'use strict';

/**
 *
 * @param $scope
 * @param $rootScope
 * @param categoryTypeMasterDataList
 * @param $uibModalInstance
 * @param toastr
 * @param globalFunc
 */
function addCategoryTypeCtrl($scope, $rootScope, categoryTypeMasterDataList, $uibModalInstance, toastr,
                               globalFunc) {
  $scope.submitted = false;
  $scope.closeModal = closeModal;
  $scope.submitCategoryType = submitCategoryType;
  $scope.categoryType = {};
  $scope.categoryType.is_active = true;

  function closeModal() {
    $uibModalInstance.close();
  }

  function submitCategoryType() {
    $scope.submitted = true;
    if (!validate($scope.categoryType)) {
      var data = {
        code: $scope.categoryType.code,
        name: $scope.categoryType.name,
        is_active: $scope.categoryType.is_active
      };
      categoryTypeMasterDataList.post(
        data,
        function (success) {
          toastr.success('Category Type created');
          $uibModalInstance.close(success);
          $scope.submitted = false;
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    }
  }

  function validate(categoryTypeDetails) {
    var anyError = false;
    if (!categoryTypeDetails.code) {
      toastr.error('Category type code is required.');
      anyError = true;
    }
    if (!categoryTypeDetails.name) {
      toastr.error('Category type name is required.');
      anyError = true;
    }
    return anyError;
  }

}

addCategoryTypeCtrl.$inject = ['$scope', '$rootScope', 'categoryTypeMasterDataList', '$uibModalInstance', 'toastr',
  'globalFunc'];
angular.module('metabuyer')
  .controller('addCategoryTypeCtrl', addCategoryTypeCtrl);
