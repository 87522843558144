'use strict';

/**
 * @name budgetV2AdvancedSearchCtrl
 *
 * @description Controller for Budget V2 advanced search
 *
 * @author JayShen <khoojs@sunway.com>
 * @copyright Sunway Metacloud &copy; 2022
 */

function budgetV2AdvancedSearchCtrl($scope, searchModule, toastr, $rootScope, advancedSearch,
  exportSearch, metabuyerCache, $state) {
  var searchCacheKey = 'budgetV2AdvancedSearch';
  var searchFields = {
      budgetStatus : {selected: {id: 1, key: 'all', value: 'All'}},
      budgetType : null,
      assetCategory : null,
      budgetTitle : null,
      erpBudgetID : null,
      budgetCode : null,
      budgetOwner : null,
      company : null,
      costCenter : null,
      creator : null,
      updatedBy : null,
      approvedBy : null,
      startDateFrom : null,
      startDateTo : null,
      endDateFrom : null,
      endDateTo : null,
      createdAtDateFrom : null,
      createdAtDateTo : null,
      updatedAtDateFrom : null,
      updatedAtDateTo : null,
      budgetAmountFrom : null,
      budgetAmountTo : null,
      nonPurchasingCostFrom : null,
      nonPurchasingCostTo : null,
    };

    $scope.budgetCategories = [
      {
        descr: 'CAPEX',
        value: 'CAPEX'
      },
      {
        descr: 'OPEX',
        value: 'OPEX'
      }
    ];

  $scope.budgetStatusOptions = [
      {
        id: 1,
        key: 'all',
        value: 'All'
      },
      {
        id: 2,
        key: '4',
        value: 'Draft'
      },
      {
        id: 3,
        key: '0',
        value: 'Pending Approval'
      },
      {
        id: 4,
        key: '1',
        value: 'Approved'
      },
      {
        id: 5,
        key: '7',
        value: 'Expired'
      },
      {
        id: 6,
        key: '5',
        value: 'Withdrawn'
      },
      {
        id: 7,
        key: '2',
        value: 'Rejected'
      },
      {
        id: 8,
        key: '6',
        value: 'Inactive'
      },
      {
        id: 9,
        key: '8',
        value: 'Deleted'
      }
    ];

  $scope.columns = [
      {id: 'erp_budget_id', label: 'ERP Budget ID', selected: true},
      {id: 'code', label: 'Budget Code', selected: true},
      {id: 'descr', label: 'Title', selected: true},
      {id: 'company|descr', label: 'Company', selected: true},
      {id: 'shadow_companies|descr', label: 'Billing Company(s)', unsortable: true, selected: true},
      {id: 'cost_center|descr', label: 'Cost Center(s)', unsortable: true, selected: true},
      {id: 'expense_type_category', label: 'Budget Type', selected: true},
      {id: 'status', label: 'Status', unsearchable: true, selected: true},
      {id: 'start_date', label: 'Start Date', type: 'date', unsearchable: true, selected: true},
      {id: 'end_date', label: 'End Date', type: 'date', unsearchable: true, selected: true},
      {id: 'amount', label: 'Amount', type: 'double', unsearchable: true, selected: true},
      {id: 'used_amount', label: 'Used Amount',type: 'double', unsearchable: true, selected: true},
      {id: 'committed_amount', label: 'Committed Amount',type: 'double', unsearchable: true, selected: true},
      {id: 'block_amount', label: 'Blocked Amount',type: 'double', unsearchable: true, selected: true},
      {id: 'available_balance', label: 'Available Balance',type: 'double', unsearchable: true, selected: true},
      {id: 'total_non_purchasing_cost', label: 'Non Purchasing Cost', type: 'double', unsearchable: true, selected: true},
      {id: 'created_at', label: 'Created At', type: 'date', unsearchable: true, selected: true},
      {id: 'creator_info|display_name', label: 'Created By', selected: true},
      {id: 'updated_at', label: 'Updated At', type: 'date', unsearchable: true, selected: true},
      {id: 'updater_info|display_name', label: 'Updated By', selected: true},
      {id: 'years|value', label: 'Budget Year', selected: true}
    ];

  $scope.datePickerTimezone = $rootScope.datePickerTimezone;
  $scope.datepickerOpened = {
    startDateFrom: false,
    startDateTo: false,
    endDateFrom: false,
    endDateTo: false,
    createdAtDateFrom: false,
    createdAtDateTo: false,
    updatedAtDateFrom: false,
    updatedAtDateTo: false
  };
  var dateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };
  $scope.datePickerOptions = {
    startDateFrom: _.clone(dateOptions),
    startDateTo: _.clone(dateOptions),
    endDateFrom: _.clone(dateOptions),
    endDateTo: _.clone(dateOptions),
    createdAtDateFrom: _.clone(dateOptions),
    createdAtDateTo: _.clone(dateOptions),
    updatedAtDateFrom: _.clone(dateOptions),
    updatedAtDateTo: _.clone(dateOptions)
  };
  $scope.closeTreeSearch = false;

  $scope.errors = {};
  var toastrErrorMessage = null;

  $scope.resetForm = resetForm;
  $scope.submitAdvSearch = submitAdvSearch;
  $scope.openDatePicker = openDatePicker;
  $scope.validateAmountRange = validateAmountRange;
  $scope.showAssetCategory = showAssetCategory;
  $scope.hasError = hasError;
  $scope.addSearchCriteria = addSearchCriteria;
  $scope.viewBudgetDetail = viewBudgetDetail;
  $scope.showColumn = showColumn;
  $scope.getYearList = getYearList;
  $scope.listItem = listItem;

  /**
   * function to reset the search form
   */
  function resetForm() {
    $scope.budgetStatus = {selected: $scope.budgetStatusOptions[0]};
    $scope.budgetType = {selected: $scope.budgetCategories[0]};
    $scope.assetCategory = null;
    $scope.budgetTitle = null;
    $scope.erpBudgetID = null;
    $scope.budgetCode = null;
    $scope.budgetOwner = null;
    $scope.company = null;
    $scope.costCenter = null;
    $scope.creator = null;
    $scope.updatedBy = null;
    $scope.approvedBy = null;
    $scope.startDateFrom = null;
    $scope.startDateTo = null;
    $scope.endDateFrom = null;
    $scope.endDateTo = null;
    $scope.createdAtDateFrom = null;
    $scope.createdAtDateTo = null;
    $scope.updatedAtDateFrom = null;
    $scope.updatedAtDateTo = null;
    $scope.budgetAmountFrom = null;
    $scope.budgetAmountTo = null;
    $scope.nonPurchasingCostFrom = null;
    $scope.nonPurchasingCostTo = null;
    $scope.errors = {};
    $scope.dataList = [];
    metabuyerCache.remove(searchCacheKey);
  }

    /**
   * function to submit search form
   */
  function submitAdvSearch() {
    var searchParam = validateForm();

    if (!!searchParam) {
      $scope.embeddedParams = searchParam;

    /**
     * Setting the params to prepare for exporting
     */
      exportSearch.setParams(searchParam);

      advancedSearch.get(
      searchParam,
      function (resource) {
        $scope.dataList = resource.content.data;
        $scope.meta = resource.content.meta;
        $scope.emptyInput = false;
      },
      function (error) {
        toastr.error('Something went wrong');
        $scope.dataList = [];
      }
    );
    } else {
      toastr.error(toastrErrorMessage);
      toastrErrorMessage = null;
      $scope.emptyInput = true;
  }

    $('#show-search-form').removeClass('hidden');
    $('#hide-search-form').addClass('hidden');

  }

    /**
   * function to check if a specific field has error.
   * @param field field name
   * @returns {boolean}
   */
  function hasError(field) {
    return (field in $scope.errors);
  }

    /**
   * function to validate form input values
   * @returns {boolean|Object}
   */
  function validateForm() {
    /**
     * logic for range fields (budget amount, non-purchasing cost, and all date fields)
     * if `from` field is not empty, set `to` field to same value as `from` value
     * if `to` field is not empty, `from` field is mandatory.
     * if both are empty, skip validation
     *
     */

    // budget amount range
    if (!!$scope.budgetAmountTo) {
      if (!!$scope.budgetAmountFrom) {
        if ($scope.budgetAmountFrom > $scope.budgetAmountTo) {
          $scope.errors.budgetAmount = 'Invalid Range';
        }
      } else {
        if ($scope.budgetAmountFrom === 0) {
          $scope.errors.budgetAmount = '\'From\' field must be greater than 0';
        }
        else {
          $scope.errors.budgetAmount = '\'From\' field is mandatory';
        }
      }
    } else if (!!$scope.budgetAmountFrom) {
      $scope.budgetAmountTo = $scope.budgetAmountFrom;
    }

    // non-purchasing cost range
    if (!!$scope.nonPurchasingCostTo) {
      // to accept value 0 for NPC field range
      if (!!$scope.nonPurchasingCostFrom || $scope.nonPurchasingCostFrom === 0) {
        if ($scope.nonPurchasingCostFrom > $scope.nonPurchasingCostTo) {
          $scope.errors.nonPurchasingCost = 'Invalid Range';
        }
      } else {
        $scope.errors.nonPurchasingCost = '\'From\' field is mandatory';
      }
    } else if (!!$scope.nonPurchasingCostFrom) {
      $scope.nonPurchasingCostTo = $scope.nonPurchasingCostFrom;
    }

    // start date range
    if (!!$scope.startDateTo) {
      if (!!$scope.startDateFrom) {
        if ($scope.startDateFrom > $scope.startDateTo) {
          $scope.errors.startDate = 'Invalid Range';
        }
      } else {
        $scope.errors.startDate = '\'From\' field is mandatory';
      }
    } else if (!!$scope.startDateFrom) {
      $scope.startDateTo = $scope.startDateFrom;
    }

    // end date range
    if (!!$scope.endDateTo) {
      if (!!$scope.endDateFrom) {
        if ($scope.endDateFrom > $scope.endDateTo) {
          $scope.errors.endDate = 'Invalid Range';
        }
      } else {
        $scope.errors.endDate = '\'From\' field is mandatory';
      }
    } else if (!!$scope.endDateFrom) {
      $scope.endDateTo = $scope.endDateFrom;
    }

    // created at date range
    if (!!$scope.createdAtDateTo) {
      if (!!$scope.createdAtDateFrom) {
        if ($scope.createdAtDateFrom > $scope.createdAtDateTo) {
          $scope.errors.createdAtDate = 'Invalid Range';
        }
      } else {
        $scope.errors.createdAtDate = '\'From\' field is mandatory';
      }
    } else if (!!$scope.createdAtDateFrom) {
      $scope.createdAtDateTo = $scope.createdAtDateFrom;
    }

    // updated at date range
    if (!!$scope.updatedAtDateTo) {
      if (!!$scope.updatedAtDateFrom) {
        if ($scope.updatedAtDateFrom > $scope.updatedAtDateTo) {
          $scope.errors.updatedAtDate = 'Invalid Range';
        }
      } else {
        $scope.errors.updatedAtDate = '\'From\' field is mandatory';
      }
    } else if (!!$scope.updatedAtDateFrom) {
      $scope.updatedAtDateTo = $scope.updatedAtDateFrom;
    }

    if (_.isEmpty($scope.errors)) {
      var searchData = {
        budgetStatus: $scope.budgetStatus,
        budgetType: $scope.budgetType,
        assetCategory: $scope.assetCategory,
        budgetTitle: $scope.budgetTitle,
        erpBudgetID: $scope.erpBudgetID,
        budgetCode: $scope.budgetCode,
        budgetOwner: $scope.budgetOwner,
        company: $scope.company,
        costCenter: $scope.costCenter,
        creator: $scope.creator,
        updatedBy: $scope.updatedBy,
        approvedBy: $scope.approvedBy,
        startDateFrom: $scope.startDateFrom,
        startDateTo: $scope.startDateTo,
        endDateFrom: $scope.endDateFrom,
        endDateTo: $scope.endDateTo,
        createdAtDateFrom: $scope.createdAtDateFrom,
        createdAtDateTo: $scope.createdAtDateTo,
        updatedAtDateFrom: $scope.updatedAtDateFrom,
        updatedAtDateTo: $scope.updatedAtDateTo,
        budgetAmountFrom: $scope.budgetAmountFrom,
        budgetAmountTo: $scope.budgetAmountTo,
        nonPurchasingCostFrom: $scope.nonPurchasingCostFrom,
        nonPurchasingCostTo: $scope.nonPurchasingCostTo
      };
      return buildParam(searchData);
    } else {
      toastrErrorMessage = 'Invalid value in one or more fields';
      return false;
    }
  }

    /**
   * function to build criteria parameters
   * @param data object of form input values
   * @returns {boolean|Object}
   */
  function buildParam(data) {

    var params = {};

    if (!!data.budgetStatus) {
      params['criteria[0][status]'] = data.budgetStatus.selected.key;
    }
    if (!!data.budgetType) {
      params['criteria[0][expense_type_category]'] = data.budgetType.selected.value;
    }
    if (!!data.assetCategory) { // make changes
      params['criteria[0][asset_category|name]'] = data.assetCategory;
    }
    if (!!data.budgetTitle) {
      params['criteria[0][descr]'] = data.budgetTitle;
    }
    if (!!data.erpBudgetID) {
      params['criteria[0][erp_budget_id]'] = data.erpBudgetID;
    }
    if (!!data.budgetCode) {
      params['criteria[0][code]'] = data.budgetCode;
    }
    if (!!data.budgetOwner) {
      params['criteria[0][owner]'] = data.budgetOwner;
    }
    if (!!data.company) {
      params['criteria[0][company|descr]'] = data.company;
    }
    if (!!data.costCenter) {
      params['criteria[0][cost_center|descr]'] = data.costCenter;
    }
    if (!!data.creator) {
      params['criteria[0][creator_info|display_name]'] = data.creator;
    }
    if (!!data.updatedBy) {
      params['criteria[0][updater_info|display_name]'] = data.updatedBy;
    }
    if (!!data.approvedBy) { // no data for approved by lmao
      params['criteria[0][approved_by]'] = data.approvedBy;
    }
    if (!!data.startDateFrom) {
      params['criteria[0][start_date][from]'] = moment(data.startDateFrom).valueOf();
      params['criteria[0][start_date][to]'] = moment(data.startDateTo).endOf('day').valueOf();
    }
    if (!!data.endDateFrom) {
      params['criteria[0][end_date][from]'] = moment(data.endDateFrom).valueOf();
      params['criteria[0][end_date][to]'] = moment(data.endDateTo).endOf('day').valueOf();
    }
    if (!!data.createdAtDateFrom) {
      params['criteria[0][created_at][from]'] = moment(data.createdAtDateFrom).valueOf();
      params['criteria[0][created_at][to]'] = moment(data.createdAtDateTo).endOf('day').valueOf();
    }
    if (!!data.updatedAtDateFrom) {
      params['criteria[0][updated_at][from]'] = moment(data.updatedAtDateFrom).valueOf();
      params['criteria[0][updated_at][to]'] = moment(data.updatedAtDateTo).endOf('day').valueOf();
    }
    if (!!data.budgetAmountFrom) {
      params['criteria[0][amount][from]'] = data.budgetAmountFrom;
      params['criteria[0][amount][to]'] = data.budgetAmountTo;
    }
    if (!!data.nonPurchasingCostFrom) {
      params['criteria[0][total_non_purchasing_cost][from]'] = data.nonPurchasingCostFrom;
      params['criteria[0][total_non_purchasing_cost][to]'] = data.nonPurchasingCostTo;
    }

    // if status 'all' is selected, the form cannot be empty. so the criteria for 'all' status
    // will only be added if `params` is not empty.
    if (_.isEmpty(params)) {
      toastrErrorMessage = 'Fill in at least one field';
      return false;
    } else {
      if (data.budgetStatus === 'all') {
        params['criteria[0][status]'] = data.budgetStatus.selected.value;
      }
      params['module'] = 'budget_v2';
      params['offset'] = 50;
      params['criteria[0][$operator]'] = 'and';
      return params;
    }
  }

  function showAssetCategory() {
    if ($scope.budgetType.selected.descr === 'OPEX') {
      $scope.assetCategory = null;
      return true;
    }
      return false;
  }

  /**
   * function to validate amount range fields, and update/remove error message
   * @param field
   */
  function validateAmountRange(field) {
    if (field === 'budgetAmount') {
      if (!!$scope.budgetAmountFrom && !!$scope.budgetAmountTo && ($scope.budgetAmountFrom > $scope.budgetAmountTo)) {
        $scope.errors.budgetAmount = 'Invalid Range';
      } else {
        if ('budgetAmount' in $scope.errors) {
          delete $scope.errors.budgetAmount;
        }
      }
    } else if (field === 'nonPurchasingCost') {
      if (!!$scope.nonPurchasingCostFrom && !!$scope.nonPurchasingCostTo && ($scope.nonPurchasingCostFrom > $scope.nonPurchasingCostTo)) {
        $scope.errors.nonPurchasingCost = 'Invalid Range';
      } else {
        if ('nonPurchasingCost' in $scope.errors) {
          delete $scope.errors.nonPurchasingCost;
        }
      }
    }
  }

  /**
   * function to set datepicker fields' options.
   * set minDate/maxDate if the related date range field is already set and remove error message
   *
   * @param $event
   * @param field
   */
  function openDatePicker($event, field) {
    $event.preventDefault();
    $event.stopPropagation();

    if (field === 'startDateFrom') {
      $scope.datePickerOptions.startDateFrom.maxDate = $scope.startDateTo || null;
      $scope.datepickerOpened.startDateFrom = true;
      delete $scope.errors.startDate;
    } else if (field === 'startDateTo') {
      $scope.datePickerOptions.startDateTo.minDate = $scope.startDateFrom || null;
      $scope.datepickerOpened.startDateTo = true;
      delete $scope.errors.startDate;
    } else if (field === 'endDateFrom') {
      $scope.datePickerOptions.endDateFrom.maxDate = $scope.endDateTo || null;
      $scope.datepickerOpened.endDateFrom = true;
      delete $scope.errors.endDate;
    } else if (field === 'endDateTo') {
      $scope.datePickerOptions.endDateTo.minDate = $scope.endDateFrom || null;
      $scope.datepickerOpened.endDateTo = true;
      delete $scope.errors.endDate;
    } else if (field === 'createdAtDateFrom') {
      $scope.datePickerOptions.createdAtDateFrom.maxDate = $scope.createdAtDateTo || null;
      $scope.datepickerOpened.createdAtDateFrom = true;
      delete $scope.errors.createdAtDate;
    } else if (field === 'createdAtDateTo') {
      $scope.datePickerOptions.createdAtDateTo.minDate = $scope.createdAtDateFrom || null;
      $scope.datepickerOpened.createdAtDateTo = true;
      delete $scope.errors.createdAtDate;
    } else if (field === 'updatedAtDateFrom') {
      $scope.datePickerOptions.updatedAtDateFrom.maxDate = $scope.updatedAtDateTo || null;
      $scope.datepickerOpened.updatedAtDateFrom = true;
      delete $scope.errors.updatedAtDate;
    } else if (field === 'updatedAtDateTo') {
      $scope.datePickerOptions.updatedAtDateTo.minDate = $scope.updatedAtDateFrom || null;
      $scope.datepickerOpened.updatedAtDateTo = true;
      delete $scope.errors.updatedAtDate;
    }
  }

  function addSearchCriteria(key, value) {
    var tempSearchCriteriaCache = metabuyerCache.get(searchCacheKey);

    if(!!tempSearchCriteriaCache)
      searchFields = _.cloneDeep(tempSearchCriteriaCache);

    searchFields[key] = value;
    metabuyerCache.put(searchCacheKey, searchFields);
  }

  function retainSearchCriteria() {
    var searchCriteriaCache = metabuyerCache.get(searchCacheKey);
    $scope.budgetStatus.selected = searchCriteriaCache.budgetStatus;
    $scope.budgetType.selected = searchCriteriaCache.budgetType;
    $scope.assetCategory = searchCriteriaCache.assetCategory;
    $scope.budgetTitle = searchCriteriaCache.budgetTitle;
    $scope.erpBudgetID = searchCriteriaCache.erpBudgetID;
    $scope.budgetCode = searchCriteriaCache.budgetCode;
    $scope.budgetOwner = searchCriteriaCache.budgetOwner;
    $scope.company = searchCriteriaCache.company;
    $scope.costCenter = searchCriteriaCache.costCenter;
    $scope.creator = searchCriteriaCache.creator;
    $scope.updatedBy = searchCriteriaCache.updatedBy;
    $scope.approvedBy = searchCriteriaCache.approvedBy;
    $scope.startDateFrom = searchCriteriaCache.startDateFrom;
    $scope.startDateTo = searchCriteriaCache.startDateTo;
    $scope.endDateFrom = searchCriteriaCache.endDateFrom;
    $scope.endDateTo = searchCriteriaCache.endDateTo;
    $scope.createdAtDateFrom = searchCriteriaCache.createdAtDateFrom;
    $scope.createdAtDateTo = searchCriteriaCache.createdAtDateTo;
    $scope.updatedAtDateFrom = searchCriteriaCache.updatedAtDateFrom;
    $scope.updatedAtDateTo = searchCriteriaCache.updatedAtDateTo;
    $scope.budgetAmountFrom = searchCriteriaCache.budgetAmountFrom;
    $scope.budgetAmountTo = searchCriteriaCache.budgetAmountTo;
    $scope.nonPurchasingCostFrom = searchCriteriaCache.nonPurchasingCostFrom;
    $scope.nonPurchasingCostTo = searchCriteriaCache.nonPurchasingCostTo;
  }

  /**
   * Function to navigate to budget details by id state
   */
  function viewBudgetDetail(data) {
    $state.go('main.user.budgetV2.details' , {id : data._id});
  }

  /**
   * Function to display selected available columns
   */
  function showColumn(id) {
    var i;
    var columns = $scope.columns;
    for (i in columns) {
      if(columns.hasOwnProperty(i)) {
        if (id === columns[i].id) {
          if(!!columns[i].conditional) {
            if($scope.status === columns[i].condition)
              return columns[i].selected;
            if(columns[i].condition instanceof Array) {
              var j;
              var columnCondition = $scope.columns[i].condition;
              for(j in columnCondition) {
                if(columnCondition.hasOwnProperty(j)) {
                  if($scope.status === columnCondition[j]) {
                    return columns[i].selected;
                  }
                }
              }
            }
          }
          else
            return columns[i].selected;
        }
      }
    }
  }

  function getYearList(years){
    var yearList = '';
    _.forEach(years, function(year, index) {
      yearList += year.value + (index !== years.length-1 ? ', ' : '');
    });

    return yearList;
  }

  function listItem (list, property) {
    return _.unique(_.map(list, function (item) {
      return item[property];
    })).toString();
  }


  function initialize() {
    // initialize pagination parameters
    resetForm();
    $scope.dataList = [];
    $scope.embeddedParams = [];
    $scope.meta = {};
    $scope.searchModule = advancedSearch;
    var searchCriteriaCache = metabuyerCache.get(searchCacheKey);
    if(!!searchCriteriaCache && !_.isEmpty(searchCriteriaCache)){
      retainSearchCriteria();
    }
  }

  initialize();
}

budgetV2AdvancedSearchCtrl.$inject = [
  '$scope', 'searchModule', 'toastr', '$rootScope', 'advancedSearch', 'exportSearch', 'metabuyerCache', '$state'
];
angular.module('metabuyer')
  .controller('budgetV2AdvancedSearchCtrl', budgetV2AdvancedSearchCtrl);