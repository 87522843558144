'use strict';
/**
 * @name tenderGroupManageCtrl
 * @desc Controller for users manage
 */
function tenderGroupManageCtrl(
  $scope, $stateParams, $state, dataList, pathConstants, $uibModal, $filter, HighlightId, globalFunc, $rootScope
) {

  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.showColumn = showColumn;
  $scope.storageKey = 'tender-group-selected-columns';
  $scope.navigateToCreationPage = navigateToCreationPage;

  function navigateToCreationPage() {
    $state.go('main.user.tender.group.add');
  }

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function initialize(){
    $scope.highlightId = HighlightId.getId();
    if (!!$scope.highlightId) {
      HighlightId.setId(null);
    }
  }

  initialize();
}

tenderGroupManageCtrl.$inject = [
  '$scope', '$stateParams', '$state', 'dataList', 'pathConstants', '$uibModal', '$filter', 'HighlightId', 'globalFunc', '$rootScope'
];

angular
  .module('metabuyer')
  .controller('tenderGroupManageCtrl', tenderGroupManageCtrl);
