'use strict';

/**
 * Controller for bifrost management
 * @description
 * Contains functions for handling tabs and table columns logic
 *
 * @param $scope
 * @param $state
 * @param dataList
 * @param type
 * @param pathConstants
 * @param $http
 * @param toastr
 * @param $filter
 * @watch $stateChangeStart
 * Watches on the start of state change, set status and params
 *
 * @author Ling Nai Shin <ling_naishin@hotmail.com>
 * @copyright 2018 Metacloud Sdn. Bhd.
 */
function bifrostSupplierCtrl($scope, $state, dataList, type, pathConstants, $http, toastr, $filter) {
  $scope.$emit('pageTitleChanged', 'Bifrost Listing');
  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.title = type;
  $scope.showColumn = showColumn;

  function showColumn(id) {
    var i;
    var columns = $scope.columns;
    for (i in columns) {
      if(columns.hasOwnProperty(i)) {
        if (id === columns[i].id) {
          if(!!columns[i].conditional) {
            if($scope.status === columns[i].condition)
              return columns[i].selected;
            if(columns[i].condition instanceof Array) {
              var j;
              var columnCondition = $scope.columns[i].condition;
              for(j in columnCondition) {
                if(columnCondition.hasOwnProperty(j)) {
                  if($scope.status === columnCondition[j]) {
                    return columns[i].selected;
                  }
                }
              }
            }
          }
          else
            return columns[i].selected;
        }
      }
    }
  }

  $scope.$on('$stateChangeStart', function( event, data, toParams ) {
    if (!!toParams.status) {
      $scope.parentStateParams = toParams;
    }
  });

}

bifrostSupplierCtrl.$inject = ['$scope', '$state', 'dataList', 'type', 'pathConstants', '$http', 'toastr', '$filter'];

angular.module('metabuyer')
  .controller('bifrostSupplierCtrl', bifrostSupplierCtrl);
