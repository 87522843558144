'use strict';

function SupplierWebPoCtrl($scope, $state, $rootScope, Orders, starringPO, toastr, globalFunc) {

  $scope.starred = starred;

  var tempState = _.cloneDeep($state.params);
  $scope.tabData = [
    {
      heading: 'All',
      route: 'main.user.suppliersWeb.po.manage',
      key: 'all',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'ALL',
        cursor: null
      })
    },
    {
      heading: 'Pending For Response',
      route: 'main.user.suppliersWeb.po.manage',
      key: 'submitted',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'SUBMITTED',
        cursor: null
      })
    },
    {
      heading: 'Processing',
      route: 'main.user.suppliersWeb.po.manage',
      key: 'awaiting_delivery',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'AWAITING_DELIVERY',
        cursor: null
      })
    },
    {
      heading: 'Reversed',
      route: 'main.user.suppliersWeb.po.manage',
      key: 'reversed',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'REVERSED',
        cursor: null
      })
    },
    {
      heading: 'Closed',
      route: 'main.user.suppliersWeb.po.manage',
      key: 'closed',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'CLOSED',
        cursor: null
      })
    }
  ];

  $scope.tabData.more = [
    {
      heading: 'Declined',
      route: 'main.user.suppliersWeb.po.manage',
      key: 'declined',
      type: 'more',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'DECLINED',
        cursor: null
      })
    },
    {
      heading: 'Starred',
      route: 'main.user.suppliersWeb.po.manage',
      key: 'starred',
      type: 'more',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'STARRED',
        cursor: null
      })
    },
    {
      heading: 'Cancelled',
      route: 'main.user.suppliersWeb.po.manage',
      key: 'cancelled',
      type: 'more',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'CANCELLED',
        cursor: null
      })
    }
  ];



  function starred(po) {

    starringPO.update(
      {
        id: po._id,
        action: !!po.isStarred ? 'unstar' : 'star'
      }
    ).
    $promise.then(function (v) {
        //successful
        if (!!po.isStarred) {
          po.isStarred = false;
          po.starred_by = [];
        }
        else {
          po.isStarred = true;
          po.starred_by = [];
          po.starred_by.push($rootScope.currentUser._id);
        }
      },
      function (error) {
        toastr.error(error.message);
      }
    );
  }

  function initialize(){
    if(!!$state.params && !!$state.params.query){
      $scope.searchText = $state.params.query;
    }
    else{
      $scope.searchText = '';
    }
    if(!!$state.params && !!$state.params.status){
      $scope.status = $state.params.status;
      //set the default tab on refreshing
      var tempIndex = _.findIndex($scope.tabData, function(tab){return tab.key === $state.params.status.toLowerCase()});
      if(tempIndex < 0) {
        tempIndex = _.findIndex($scope.tabData.more, function (tab) {
          return tab.key === $state.params.status.toLowerCase()
        });
        if(!!tempIndex)
          $scope.activatedTab = $scope.tabData.more[tempIndex];
      }
      else
        $scope.activatedTab = $scope.tabData[tempIndex];
    }
    if(!!$state.params && !!$state.params.filter){
      $scope.searchFilter = $state.params.filter;
    }
    $scope.searchPlaceholder = 'Search Orders';
    $scope.searchState = 'main.user.suppliersWeb.po.manage';
    $scope.parentState = 'main.user.suppliersWeb.po.manage';
    $scope.parentStateParams = $state.params;
  }

  $scope.columns = [
    {
      id: 'check box',
      title: '',
      unsortable: true,
      unsearchable: true,
      conditional: true,
      condition: ['SUBMITTED'],
      unSelectable: true
    },
    {id: 'starred', label: 'Star', unsortable: true, unsearchable: true, unSelectable: true},
    {id: 'po_number', label: 'PO #'},
    {id: 'title', label: 'PO Title'},
    {id: 'billing|company|descr', label: 'Company'},
    {
      id: 'needed_at',
      label: 'Needed by',
      type: 'date',
      unsearchable: true
    },
    {
      id: 'created_at',
      label: 'PO Date',
      type: 'date',
      unsearchable: true
    },
    {id: 'received_percentage', label: 'Received %', conditional: true, condition: 'AWAITING_DELIVERY', unsearchable: true},
    {id: 'do_number', label: 'DO #', conditional: true, condition: ['CLOSED', 'FULLY_DELIVERED']},
    {
      id: 'status_date',
      label: 'Closed PO Date',
      conditional: true,
      condition: ['CLOSED', 'FULLY_DELIVERED'],
      type: 'date',
      unsearchable: true
    },
    {
      id: 'status_date',
      label: 'Cancelled PO Date',
      conditional: true,
      condition: 'CANCELLED',
      type: 'date',
      unsearchable: true
    },
    {
      id: 'net_total',
      label: 'Amount',
      type: 'double',
      unsearchable: true
    },
    {id: 'updated_at', label: 'Updated At', conditional: true, condition: 'AWAITING_DELIVERY'},
    {id: 'status', label: 'Status', conditional: true, condition: 'ALL'}
  ];

  initialize();

  $scope.$emit('pageTitleChanged', 'Purchase Orders');

  $scope.$on('$stateChangeStart', function(event, toState, toParams){
    if(!!toParams.status){
      $scope.parentStateParams = toParams;
    }
  });

}
SupplierWebPoCtrl.$inject = ['$scope', '$state', '$rootScope', 'Orders', 'starringPO', 'toastr', 'globalFunc'];

angular.module('metabuyer')
  .controller('SupplierWebPoCtrl', SupplierWebPoCtrl);
