'use strict';

angular
  .module('metabuyer')
  .directive('twofaOtpInput', function($timeout, $rootScope, $translate) {
    return {
      restrict: 'EA',
      scope: {
        otps: '=twofaOtp',
      },
      replace: true,
      templateUrl: 'components/twoFAOtp/twoFAOtpTemplate.html',
      link: function($scope, element) {
        element.children()[0].focus()

        $scope.onKeyDown = function(event) {
          var regex=/^[0-9]+$/;
          var validKeys = ["ArrowLeft", "ArrowRight", "Backspace", "Tab"];
          if (!event.key.match(regex)){
            if (!validKeys.includes(event.key)) {
              event.preventDefault();
              return;
            }
          }
        }

        $scope.onKeyUp = function(event, index) {
          var element = event.target;
          var regex=/^[0-9]+$/;
          if (event.key.match(regex)){
            element.value = event.key;
            $scope.otps[index] = event.key;
          }

          if (event.key == "ArrowLeft"  && index != 0) {
            element.previousElementSibling.focus();
            event.preventDefault();
            return;
          }
          if (event.key == "ArrowRight") {
            element.nextElementSibling.focus();
            event.preventDefault();
            return;
          }
          if (event.key == "Backspace" && index != 0) {
            if (element.value.length === 0) {
              element.previousElementSibling.focus();
              event.preventDefault();
              return;
            }
          }
          if (element.value.length == element.getAttribute("maxlength")) {
            element.nextElementSibling.value = null;
            element.nextElementSibling.focus();
          }
        }
      }
    }
  });
