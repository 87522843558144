/**
 * @name fixHeader
 *
 * @description Directive to make the header on fix position
 *
 * @param height
 * height limit to set as fix header
 * @param width
 * width (boolean), true if want to use the existing width, false to set to auto
 * @param padding
 * fill in with value. to add additional padding
 * @param top
 * set top value
 *
 * @author Deniel
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

angular.module('metabuyer')
  .directive('fixHeader', function ($window) {
    return {
      restrict: 'A',
      scope:{
        height: '=',
        width: '=',
        padding: '=',
        top: '=',
        config: '=?'
      },
      link: function ($scope, element, attrs) {
        var initBackground,
            initPadding,
            initTop,
            initWidth;

        var fixHeaderElement = document.getElementById('fixHeaderDirective');
        var toHide = document.getElementsByClassName('toHide');
        var actionButtons = document.getElementsByClassName('header-action-buttons');
        var config = (!!$scope.config && !!$scope.config.pr_header_size) ? $scope.config.pr_header_size : 'normal';

        function setFixHeader() {
          var result = document.getElementsByClassName('item-action-scrollfix');
          var wrappedResult = angular.element(result);

          angular.element(fixHeaderElement).css('transition', 'all linear .5s');
          angular.element(fixHeaderElement).css('position', 'fixed');
          angular.element(fixHeaderElement).css('background', 'white');
          angular.element(fixHeaderElement).css('top', '0');
          angular.element(fixHeaderElement).css('z-index', '100');
          angular.element(fixHeaderElement).css('padding', '10px');
          angular.element(fixHeaderElement).css('border-bottom', '#dcdcdc 2px solid');
          if(!!document.getElementById('fixHeaderViewWidth'))
            angular.element(fixHeaderElement).css('width', angular.element(document.getElementById('fixHeaderViewWidth')).prop('offsetWidth') + 'px');
          if (!!wrappedResult && !!wrappedResult.length)
            angular.element(fixHeaderElement).css('top', '62px');

          if (!!$scope.padding)
            angular.element(fixHeaderElement).css('padding', $scope.padding);
          if (!document.getElementById('fixHeaderViewWidth'))
            angular.element(fixHeaderElement).css('width', initWidth);
          if (!!$scope.top)
            angular.element(fixHeaderElement).css('top', $scope.top);

          if (config === 'mini') {
            angular.element(toHide).css('display', 'none');
            $("#mini-back-button").removeClass("hidden");
          }

          if (!! document.getElementById('fixedHeaderDynamicHide')) {
            var elem = document.getElementById('fixedHeaderDynamicHide');
            angular.element(elem).removeClass('hidden');
          }

          var waitingOnElem = document.getElementById('p-t--55');
          angular.element(waitingOnElem).css('margin-top', '-55px');
          var actionsElem = document.getElementById('p-t--30');
          angular.element(actionsElem).css('margin-top', '-30px');
        }

        function setFixHeaderPrItemHeader() {
          var tableCloneTo = document.getElementById("table-clone-to");
          var tableCloneFromTh = document.querySelectorAll("#table-clone-from > thead:first-of-type > tr > th");
          var tableCloneToTh = document.querySelectorAll("#table-clone-to > thead:first-of-type > tr > th");
          for (var i = 0; i < tableCloneFromTh.length; i++) {
            var tableCloneFromThWidth = tableCloneFromTh[i].offsetWidth + 'px';
            angular.element(tableCloneToTh[i]).css('width', tableCloneFromThWidth);
          }
          angular.element(tableCloneTo.parentElement).removeClass('hidden');
        }

        function removeFixHeader() {
          angular.element(fixHeaderElement).css('position', '');
          angular.element(fixHeaderElement).css('border-bottom', '');
          angular.element(fixHeaderElement).css('z-index', '');
          angular.element(fixHeaderElement).css('background', initBackground);
          angular.element(fixHeaderElement).css('width', '');

          if (!!$scope.padding)
            element.css('padding', initPadding);
          if (!!$scope.top)
            element.css('top', initTop);

          if (config === 'mini') {
            angular.element(toHide).css('display', 'inline');
            $("#mini-back-button").addClass("hidden");
          }

          if (!! document.getElementById('fixedHeaderDynamicHide')) {
            var elem = document.getElementById('fixedHeaderDynamicHide');
            angular.element(elem).addClass("hidden");
          }

          var waitingOnElem = document.getElementById('p-t--55');
          angular.element(waitingOnElem).css('margin-top', '');
          var actionsElem = document.getElementById('p-t--30');
          angular.element(actionsElem).css('margin-top', '');
        }

        function removeFixHeaderPrItemHeader() {
          var tableCloneTo = document.getElementById("table-clone-to");
          angular.element(tableCloneTo.parentElement).addClass('hidden');
        }

        function initialize() {
          initBackground = angular.element(fixHeaderElement).css('backgroundColor');
          initWidth = angular.element(fixHeaderElement).css('width');
          initPadding = angular.element(fixHeaderElement).css('padding');
          initTop = angular.element(fixHeaderElement).css('top');
        }

        initialize();

        /**
         * Event on window scroll
         */
        var timer;
        angular.element($window).bind('scroll', function() {
          var tableCloneFrom = document.getElementById("table-clone-from");
          var tableCloneToHidden = false;
          if (tableCloneFrom !== null) {
            tableCloneFrom = tableCloneFrom.closest(".itemlist");
            tableCloneToHidden = document.getElementById("table-clone-to").classList.contains('hidden');
          }
          
          if(timer) {
            $window.clearTimeout(timer);
          }

          /**
           * Set a delay of 100ms before calling the set/remove header
           * @type {number}
           */
          timer = window.setTimeout(function() {

            /**
             * Set fix header when scroll is more than 25% of window height
             */
            if (this.pageYOffset >= ($window.innerHeight/4)){
              setFixHeader();
            }
            /**
             * Remove fix header when scroll is less than 10% of window height
             */
            else if(this.pageYOffset < ($window.innerHeight/10)){
              removeFixHeader();
            }
            if (tableCloneFrom !== null) {
              if (!tableCloneToHidden &&
                  this.pageYOffset >= (tableCloneFrom.offsetTop + tableCloneFrom.offsetHeight - 300)) {
                removeFixHeaderPrItemHeader();
              } else if (this.pageYOffset >= (tableCloneFrom.offsetTop - 200)){
                setFixHeaderPrItemHeader();
              } else if (this.pageYOffset < (tableCloneFrom.offsetTop - 200)) {
                removeFixHeaderPrItemHeader();
              }
            }

            // to avoid error $digest already in progress
            _.defer(function() {
              $scope.$apply();
            });
          }, 100);

        });
      }
    }
  });
