'use strict';

function suppliersListManageCtrl(
  $scope, suppliersDataList, $rootScope, action, $stateParams, HighlightId, $window,
  $filter, supplierFunctions, $translate)
{

  $scope.showColumn           = showColumn;
  $scope.getStatusDisplayName = getStatusDisplayName;
  $scope.getSupplierType      = getSupplierType;

  $scope.storageKey = 'suppliers-list-selected-columns';

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function getStatusDisplayName(status) {
    var translateArr = [
      'statuses.all',
      'statuses.active',
      'statuses.draft',
      'statuses.pending',
      'statuses.pendingApproval',
      'statuses.blacklisted',
      'statuses.rejected',
      'statuses.withdrawn',
      'statuses.deactivated',
      'statuses.suspended',
    ];
    status = status === 'black_listed' ? 'blacklisted' : status;
    status = status === 'inactive' ? 'deactivated' : status;
    $scope.statusDesc = status;
    $translate(translateArr).then(function (trans) {
      $scope.statusDesc = trans["statuses." + status].toLowerCase();
    });
  }

  function getSupplierType(basicInfo) {
    if (!_.has(basicInfo, 'is_sourcing_supplier')) {
      return 'N/A';
    } else {
      return supplierFunctions.findSupplierType(basicInfo).descr;
    }
  }

  function initialize(){

    $scope.suppliers = suppliersDataList.data;
    $scope.actionType = action;

    if($stateParams.type === 'date'){
      var ms = Number($stateParams.query[1]*1000);
      $scope.title = $filter('date')(new Date(ms), 'dd MMM yyyy');
    }
    else{
      $scope.title = $stateParams.query;
    }
    $scope.meta = suppliersDataList.meta;
    $scope.status = $stateParams.status;
    $scope.$parent.status = $stateParams.status;

    //set status for parent
    if(!!HighlightId.getId() && HighlightId.getId() != undefined && HighlightId.getId().length) {

      $scope.highlightId = HighlightId.getId();

      if ($scope.highlightId != null && $scope.highlightId != undefined) {

        //scroll the window to top
        $window.scroll(0, 0);
      }

      //clear current id
      HighlightId.setId(null);
    }

    $scope.selectedListTab = $stateParams.tab;
    if(!$scope.selectedListTab){
      $scope.selectedListTab = 'all';
    }
    getStatusDisplayName($scope.status);

    // decrypt reg_no
    if (!$rootScope.isV2Enabled) {
      $scope.suppliers.forEach(function (item, index) {
        $scope.suppliers[index].basic_info.reg_no = atob(item.basic_info.reg_no);
      });
    }
  }

  initialize();
}

suppliersListManageCtrl.$inject = ['$scope','suppliersDataList', '$rootScope', 'action', '$stateParams',
  'HighlightId', '$window', '$filter', 'supplierFunctions', '$translate'];

angular.module('metabuyer')
  .controller('suppliersListManageCtrl', suppliersListManageCtrl);
