'use strict';

/**
 * @name SuppliersWebUserCtrl
 * @desc Controller for users
 */
function SuppliersWebUserCtrl($scope, $state, profile, $rootScope, UserPermissions) {
  $scope.$emit('pageTitleChanged', 'Manage Supplier Web Users');
  $scope.tabData = [
    {
      heading: 'All',
      route: 'main.user.suppliersWebUsers.list',
      key: 'all',
      params: {
        status: 'all',
        cursor: null,
        filter: null,
        query: null,
        type: null
      }
    },
    {
      heading: 'Active',
      route: 'main.user.suppliersWebUsers.list',
      key: 'active',
      params: {
        status: 'active',
        cursor: null,
        filter: null,
        query: null,
        type: null
      }
    },
    {
      heading: 'Inactive',
      route: 'main.user.suppliersWebUsers.list',
      key: 'inactive',
      params: {
        status: 'inactive',
        cursor: null,
        filter: null,
        query: null,
        type: null
      }
    },
    {
      heading: 'Removed',
      route: 'main.user.suppliersWebUsers.list',
      key: 'deleted',
      params: {
        status: 'deleted',
        cursor: null,
        filter: null,
        query: null,
        type: null
      }
    }
  ];

  /**
   * making sure the Stats and placeHolder changes if the Child scope changed
   */
  $scope.$on('$stateChangeStart', function( event, data, toParams ) {
    $scope.searchState = 'main.user.suppliersWebUsers.list';
    $scope.searchPlaceholder = 'Search users';
    $scope.parentState = 'main.user.suppliersWebUsers.list';
    $scope.parentStateParams = toParams;
    $scope.columns = userColumns;

    if(!!toParams.status){
      $scope.status = toParams.status;
      $scope.parentStateParams = toParams;
    }
  });

  var userColumns = [
    {id: 'display_name', label: 'Full Name'},
    {id: 'email', label: 'Email'},
    {id: 'phone', label:'Phone Number', unsearchable:true},
    {id: 'supplier.descr', label: 'Supplier'},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {id: 'creator.display_name', label: 'Created By', unsearchable: true},
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    },
    {id: 'updater.display_name', label: 'Updated By', unsearchable: true}
  ];


  function initialize() {
    $scope.searchState = 'main.user.suppliersWebUsers.list';
    $scope.searchPlaceholder = 'Search users';
    $scope.parentState = 'main.user.suppliersWebUsers.list';
    $scope.parentStateParams = $state.params;
    $scope.activatedTab = $scope.tabData[0];
    $scope.columns = userColumns;

    if(!!$state.params && !!$state.params.status){
      $scope.status = $state.params.status;
    }
    if(!!$state.params && !!$state.params.filter){
      $scope.searchFilter = $state.params.filter;
    }

    if(!!$state.params && !!$state.params.query){
      $scope.searchText = $state.params.query;
    }
    else{
      $scope.searchText = '';
    }

    if(!!$state.params && !!$state.params.status){
      $scope.status = $state.params.status;
      //set the default tab on refreshing
      var tempIndex = _.findIndex($scope.tabData, function(tab){return tab.key === $state.params.status.toLowerCase()});
      $scope.activatedTab = $scope.tabData[tempIndex];
    }
  }

  initialize();
}

SuppliersWebUserCtrl.$inject = ['$scope', '$state', 'profile', '$rootScope', 'UserPermissions'];

angular
  .module('metabuyer')
  .controller('SuppliersWebUserCtrl', SuppliersWebUserCtrl);
