'use strict';

function addApprovalLimitCtrl($scope, toastr, globalFunc, $uibModalInstance, loadCacheableData, approvalLimit) {

  $scope.submitApprovalLimit = submitApprovalLimit;
  $scope.closeModal = closeModal;
  $scope.openDatepicker = openDatepicker;

  $scope.approvalLimit = {};
  $scope.statuses = [
    'ACTIVE',
    'INACTIVE'
  ];
  $scope.datepickerOpened = {
    start_date: false,
    end_date: false,
    start_date_assignment: false,
    end_date_assignment: false
  };
  $scope.startDateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };
  $scope.endDateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };

  loadCacheableData.loadData({
    module: 'currencies',
    'criteria[is_active]': 1,
    offset: 0
  }, 'currencies', 'localStorage').then(function(data){
    $scope.currencyList = data;
  });

  /**
   * For date picker
   *
   * @param {Object} $event
   * @param {string} which - start or end date
   */
   function openDatepicker($event, which) {
    $event.preventDefault();
    $event.stopPropagation();

    if (!!$scope.user.end_date) {
      $scope.startDateOptions.maxDate = $scope.user.end_date;
    }

    $scope.endDateOptions.minDate = new Date();

    $scope.datepickerOpened[which] = true;
  }

  function prepareData() {
    var data = {
      'status': $scope.approvalLimit.status,
      'amount': $scope.approvalLimit.amount,
      'currency_code': $scope.approvalLimit.currency.code,
      'country_descr': $scope.approvalLimit.country.descr,
      'start_date': globalFunc.convertDateToTimestamp($scope.approvalLimit.start_date),
      'end_date': globalFunc.convertDateToTimestamp($scope.approvalLimit.end_date),
    };
    return data;
  }

  function submitApprovalLimit() {
    var data = prepareData();
    approvalLimit.post(
      {
        uuid: $scope.userData.uuid
      },
      data,
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          toastr.success("Approval Limit Added");
          $uibModalInstance.close(resource.content.data);
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function closeModal() {
    $uibModalInstance.close();
  }

  function initialize() {
    $scope.countries = globalFunc.countryCode();
    // defaults status to active
    $scope.approvalLimit.status = $scope.statuses[0];
  }

  initialize();
}

addApprovalLimitCtrl.$inject = ['$scope', 'toastr', 'globalFunc', '$uibModalInstance', 'loadCacheableData', 'approvalLimit'];

angular
  .module('metabuyer')
  .controller('addApprovalLimitCtrl', addApprovalLimitCtrl);
