'use strict';
/**
 * @name companiesDetailCtrl
 * @desc Controller for Companies detail
 */
function companyDetailCtrl(
  $scope, $state, companyDetails, Companies, $uibModal, toastr, currenciesList, BUs, industryList,
  metabuyerCache, singleAddress, $filter, pathConstants, companiesDetailImage, $auth, globalFunc,
  companyCurrencies, companies, $rootScope, companyGlobalFunc, expenseTypesList, exportSearch,
  companyCostCentersAddress, companyBudgetSetting, HighlightId, companyApprovalSettings,
  commentsHistory, companyErpIntegrationSettings, companyOtherInformation, companyPurchaseRequisitionSettings,
  companySupplierWebSetting, companyPurchaseOrderSettings, companyErpOrderType, companyErpCostCenter, deactivateCostCenters, activateCostCenters,
  toleranceLimitData, toleranceLimit, automatedPo, featureToggles, bifrostFeatureToggle, consolidationExpired, setStockInformationGroup, stockInformationGroup, cachedAddresses,
  modeOfPurchaseList, $http, priceComparisonCompanySetting
) {
  var backupCorrespondingAddress = {
    line1: companyDetails.data.corresponding_address.line1,
    line2: companyDetails.data.corresponding_address.line2,
    line3: companyDetails.data.corresponding_address.line3,
    postal_code: companyDetails.data.corresponding_address.postal_code,
    city: companyDetails.data.corresponding_address.city,
    state: companyDetails.data.corresponding_address.state,
    country: companyDetails.data.corresponding_address.country,
    phone: companyDetails.data.corresponding_address.phone
  };

  $scope.cachedAddresses = cachedAddresses;
  $scope.validationError = false;
  $scope.companyDetails = companyDetails.data;
  $scope.expenseTypesBackup = _.cloneDeep(companyDetails.data.expense_types);
  $scope.modesOfPurchaseBackup = _.cloneDeep(companyDetails.data.modes_of_purchase);
  $scope.countryBackup = _.cloneDeep(companyDetails.data.corresponding_address.country);
  $scope.stateBackup = _.cloneDeep(companyDetails.data.corresponding_address.state);
  $scope.allowedToHoldPRBackup = _.cloneDeep(companyDetails.data.config.allowedToHoldPR);
  $scope.tabActive = "companies";
  $scope.parentCompany = [];
  $scope.BUs = BUs.data;
  $scope.industries = industryList.data;
  $scope.currencies = currenciesList;
  $scope.expenseTypes = expenseTypesList;
  $scope.modeOfPurchases = modeOfPurchaseList.data;
  $scope.stockInformationGroup = stockInformationGroup;
  $scope.companyCurrencies = companyCurrencies.data;
  $scope.companyDetails.corresponding_address_id = "";
  $scope.preferredCurrencyList = [];
  $scope.selectedCompanyCurrencies = [];
  $scope.selectedPreferredCurrency = {};
  $scope.importLink = pathConstants.apiUrls.costCenters.import;
  $scope.countries = [];
  $scope.MYStates = [];
  $scope.extraSettingModesOfPurchase = {
    idProp: "_id",
    externalIdProp: "",
    displayProp: "name",
    scrollable: true,
    scrollableHeight: '200px',
    showCheckAll: true,
    showUncheckAll: true,
    enableSearch: true,
    buttonDefaultText: 'Select mode of purchase'
  };
  $scope.extraSettingCurrencies = {
    idProp: "_id",
    externalIdProp: "",
    displayProp: "descr",
    scrollable: true,
    scrollableHeight: '200px',
    showCheckAll: true,
    showUncheckAll: true,
    enableSearch: true,
    buttonDefaultText: 'Select currency'
  };
  $scope.extraSettingExpenseTypes = {
    idProp: "_id",
    externalIdProp: "",
    displayProp: "descr",
    scrollable: true,
    scrollableHeight: '200px',
    showCheckAll: true,
    showUncheckAll: true,
    enableSearch: true,
    buttonDefaultText: 'Select Expense type'
  };
  $scope.extraSettingPreferredCurrencies = {
    idProp: "_id",
    externalIdProp: "",
    displayProp: "descr",
    scrollable: true,
    scrollableHeight: '200px',
    enableSearch: true,
    selectionLimit: 1
  };
  $scope.POInitialStatuses = companyGlobalFunc.getPOInitialStatuses();
  $scope.budgetModuleToggle = null;
  $scope.budgetExceedToggle = null;
  $scope.budgetNotificationToggle = null;
  $scope.budgetOfflineToggle = null;
  $scope.companyItemModified = false;
  $scope.toleranceLimitMopModified = false;
  $scope.opexBudgetGrouping = null;
  $scope.refreshItem = refreshItem;
  $scope.onSelectItem = onSelectItem;
  $scope.onRemoveItem = onRemoveItem;
  $scope.companySelectedMop = _.cloneDeep($scope.companyDetails.modes_of_purchase);
    var defaultModeOfPurchase = {
      '_id': 'COMPANY',
      'code': 'COMPANY',
      'name': 'Company',
      'uuid': 'COMPANY'
    };
  $scope.selectedToleranceLimitMop = !!toleranceLimitData.data.toleranceLimit ? toleranceLimitData.data.toleranceLimit.modes_of_purchase : undefined
  $scope.onSelectToleranceLimitMOP = {
    onItemSelect: function (selectedMop) {
      var selectedModeOfPurchase = globalFunc.findInArray($scope.companySelectedMop, '_id', selectedMop._id);
      $scope.selectedToleranceLimitMop = globalFunc.removeValueFromArray($scope.selectedToleranceLimitMop, '_id', selectedMop._id);
      $scope.selectedToleranceLimitMop.push(selectedModeOfPurchase);
      $scope.toleranceLimitMopModified = true;
    },
    onItemDeselect: function (deselectedMop) {
      $scope.selectedToleranceLimitMop = globalFunc.removeValueFromArray(
        $scope.selectedToleranceLimitMop, '_id', deselectedMop._id
      );
      $scope.toleranceLimitMopModified = true;
    },
    onDeselectAll: function () {
      $scope.selectedToleranceLimitMop = [];
      $scope.toleranceLimitMopModified = true;
    }
  };

  $scope.searchCompanyItem = searchCompanyItem;
  $scope.budgetThreshold = {
    normal: {
      low: null
    },
    range: {}
  };
  $scope.purchaseOrderSetting = {
    pa_allowed_to_cancel_po: !!$scope.companyDetails.config.pa_allowed_to_cancel_po,
    allow_grn_receive: !!$scope.companyDetails.config.allow_grn_receive,
    allow_grn_reverse: !!$scope.companyDetails.config.allow_grn_reverse
  };
  $scope.purchaseOrderSettingBackup = _.cloneDeep($scope.purchaseOrderSetting);
  $scope.toleranceLimitDataBackup = _.cloneDeep(toleranceLimitData.data);
  $scope.automatedPoDataBackup = _.cloneDeep($scope.companyDetails.config.automated_po);
  $scope.approvalSettings = {
    reroute_approval: (!!$scope.companyDetails.config.approval_settings &&
    $scope.companyDetails.config.approval_settings.hasOwnProperty('amount_increase')),
    amount_change: $scope.companyDetails.config.approval_settings
  };
  $scope.approvalSettingsBackup = _.cloneDeep($scope.approvalSettings);

  $scope.purchaseRequisitionSetting = {
    account_code: {
      is_mandatory: (!!$scope.companyDetails.requisition_settings.account_code &&
        !!$scope.companyDetails.requisition_settings.account_code.is_mandatory)
    },
    pa_override_hold_pr: {
      is_allowed: (!!$scope.companyDetails.requisition_settings.pa_override_hold_pr &&
        !!$scope.companyDetails.requisition_settings.pa_override_hold_pr.is_allowed)
    },
    pa_override_withdraw_pr: {
      is_allowed: (!!$scope.companyDetails.requisition_settings.pa_override_withdraw_pr &&
        !!$scope.companyDetails.requisition_settings.pa_override_withdraw_pr.is_allowed)
    },
    erp_cost_center: {
      is_mandatory: (!!$scope.companyDetails.requisition_settings.erp_cost_center &&
        !!$scope.companyDetails.requisition_settings.erp_cost_center.is_mandatory)
    },
    lump_sum_discount_account_code: {
      is_allowed: (!!$scope.companyDetails.requisition_settings.lump_sum_discount_account_code &&
        !!$scope.companyDetails.requisition_settings.lump_sum_discount_account_code.is_allowed)
    },
    duplicate_others_pr_within_cost_center: {
      is_allowed: (!!$scope.companyDetails.requisition_settings.duplicate_others_pr_within_cost_center &&
        !!$scope.companyDetails.requisition_settings.duplicate_others_pr_within_cost_center.is_allowed)
    },
    freight_transportation: {
      is_disabled: (!!$scope.companyDetails.requisition_settings.freight_transportation &&
        !!$scope.companyDetails.requisition_settings.freight_transportation.is_disabled)
    },
    skip_auto_send_po: {
      is_enabled: (!!$scope.companyDetails.requisition_settings.skip_auto_send_po &&
        !!$scope.companyDetails.requisition_settings.skip_auto_send_po.is_enabled)
    },
    resubmit_withdrawn_pr: {
      is_enabled: (!!$scope.companyDetails.requisition_settings.resubmit_withdrawn_pr &&
        !!$scope.companyDetails.requisition_settings.resubmit_withdrawn_pr.is_enabled)
    },
    no_pricing_available: {
      is_enabled: (!!$scope.companyDetails.requisition_settings.no_pricing_available &&
        !!$scope.companyDetails.requisition_settings.no_pricing_available.is_enabled)
    },
    project_code_capex_mandatory: {
      is_enabled: (!!$scope.companyDetails.requisition_settings.project_code_capex_mandatory &&
        !!$scope.companyDetails.requisition_settings.project_code_capex_mandatory.is_enabled)
    }
  };
  $scope.purchaseRequisitionSettingBackup = _.cloneDeep($scope.purchaseRequisitionSetting);

  // Supplier web setting
  $scope.supplierWebSetting = $scope.companyDetails.config.supplier_web;
  $scope.supplierWebSettingBackup = _.cloneDeep($scope.supplierWebSetting);

  $scope.integrationSettings = {
    isIntegrated: !!$scope.companyDetails.config.erp_integration,

  };
  $scope.selectedIntegrationSolution = [
    {selected: []}
  ];
  $scope.selectedIntegrationSolutionBackup = [
    {selected: []}
  ];

  $scope.integrationSettingsBackup = _.cloneDeep($scope.integrationSettings);

  var consolidationExpiredDataBackup = {};
  consolidationExpiredDataBackup.consolidationExpired = {
    is_active: !!$scope.companyDetails.config.consolidationExpired.is_active ? $scope.companyDetails.config.consolidationExpired.is_active : false,
    days: !!$scope.companyDetails.config.consolidationExpired.days ? $scope.companyDetails.config.consolidationExpired.days : '',
  };
  $scope.consolidationExpiredDataBackup = consolidationExpiredDataBackup;
  $scope.toggleList = {
    modeOfPurchaseToggle: false,
    disableExchangeRateToggle: false,
    disableItemTaxToggle: false,
    toleranceLimitToggle: false,
    automatedPoToggle: false,
    stockInformationGroupToggle: false,
    priceComparisonToggle: false
  };

  $scope.genpAdminFeatureEnable = $rootScope.genpAdminFeatureEnable;

  var formatToPercentage = function (value) {
    return value + '%';
  };

  $scope.budgetThreshold.normal.options = {
    floor: 0,
    ceil: 100,
    translate: formatToPercentage,
    showSelectionBar: true,
    getSelectionBarColor: function (value) {
      if (value <= 30)
        return '#2AE02A';
      if (value <= 60)
        return 'yellow';
      if (value <= 80)
        return 'orange';
      return 'red';
    }
  };

  $scope.budgetThreshold.range.options = {
    floor: 0,
    ceil: 100,
    translate: formatToPercentage
  };

  // config flowjs
  $scope.flowConfig = new Flow({
    target: companiesDetailImage.uploadImagePath($scope.companyDetails._id, 'companylogo'),
    singleFile: true,
    testChunks: false,
    chunkSize: 10000000,
    headers: {
      'Authorization': 'Bearer ' + $auth.getToken()
    }
  });

  /**
   * provide the preferred currency data
   * from selectedCurrencies
   */
  $scope.selectCurrencies = {
    onItemSelect: function (selectedCurrencies) {
      var selectedCurrency = globalFunc.findInArray($scope.currencies, '_id', selectedCurrencies._id);
      $scope.companyCurrencies = globalFunc.removeValueFromArray($scope.companyCurrencies, '_id', selectedCurrencies._id);
      $scope.companyCurrencies.push(selectedCurrency);
      $scope.preferredCurrencyList = $scope.companyCurrencies;
    },
    onItemDeselect: function (deselectedCurrencies) {
      $scope.preferredCurrencyList = globalFunc.removeValueFromArray(
        $scope.preferredCurrencyList, '_id', deselectedCurrencies._id
      );

      if (!!$scope.selectedPreferredCurrency && !!$scope.selectedPreferredCurrency._id) {
        if ($scope.selectedPreferredCurrency._id === deselectedCurrencies._id) {
          $scope.selectedPreferredCurrency = {};
        }
      }
    },
    onDeselectAll: function () {
      $scope.preferredCurrencyList = [];
      $scope.selectedPreferredCurrency = {};
    }
  };

  $scope.accountCodeAlertToggle = null;

  //$scope.selectPreferredCurrencies = {
  //  onItemSelect: function(selectedCurrencies){
  //    var selectedCurrency = globalFunc.findInArray($scope.currencies, 'code', selectedCurrencies.id);
  //    $scope.selectedPreferredCurrency = selectedCurrency;
  //  }
  //};

  $scope.tenant = $rootScope.currentUser.tenant;
  $scope.setActiveTab = setActiveTab;
  $scope.back = back;
  $scope.onSaveCompanyDetails = onSaveCompanyDetails;
  $scope.saveCorrespondingAddressChanges = saveCorrespondingAddressChanges;
  $scope.addCostCenterModal = addCostCenterModal;
  $scope.costCenterDetails = costCenterDetails;
  $scope.viewCurrencies = viewCurrencies;
  $scope.loadParentCompany = loadParentCompany;
  $scope.loadCostCenters = loadCostCenters;
  $scope.loadBUs = loadBUs;
  $scope.loadIndustry = loadIndustry;
  $scope.loadApproverOptions = loadApproverOptions;
  $scope.saveBu = saveBu;
  $scope.isUploadImageSuccess = isUploadImageSuccess;
  $scope.importCostCentersModal = importCostCentersModal;
  $scope.onSelectedState = onSelectedState;
  $scope.onSelectedCountry = onSelectedCountry;
  $scope.getPrintLogoSetting = getPrintLogoSetting;
  $scope.duplicationCheck = duplicationCheck;
  $scope.budgetSettingChanges = budgetSettingChanges;
  $scope.purchaseOrderSettingChanges = purchaseOrderSettingChanges;
  $scope.budgetSettingToggle = budgetSettingToggle;
  $scope.savePurchaseOrderSetting = savePurchaseOrderSetting;
  $scope.saveStockInformationGroup = saveStockInformationGroup;
  $scope.cancelPurchaseOrderSettings = cancelPurchaseOrderSettings;
  $scope.saveBudgetSetting = saveBudgetSetting;
  $scope.exportCostCenterAddresses = exportCostCenterAddresses;
  $scope.showChildState = showChildState;
  $scope.updateApprovalSettings = updateApprovalSettings;
  $scope.cancelUpdateApprovalSettings = cancelUpdateApprovalSettings;
  $scope.showCompanyHistory = showCompanyHistory;
  $scope.setIntegrationSettings = setIntegrationSettings;
  $scope.cancelUpdateIntegrationSettings = cancelUpdateIntegrationSettings;
  $scope.savePurchaseRequisitionSetting = savePurchaseRequisitionSetting;
  $scope.cancelPurchaseRequisitionSettings = cancelPurchaseRequisitionSettings;
  $scope.saveSupplierWebSetting = saveSupplierWebSetting;
  $scope.cancelSupplierWebSetting = cancelSupplierWebSetting;
  $scope.isPoSetToExpired = isPoSetToExpired;
  $scope.deleteErpOrderType = deleteErpOrderType;
  $scope.deleteErpCostCenter = deleteErpCostCenter;
  $scope.addErpOrderTypeModal = addErpOrderTypeModal;
  $scope.addErpCostCenterModal = addErpCostCenterModal;
  $scope.resetFieldsValue = resetFieldsValue;
  $scope.submitToleranceLimit = submitToleranceLimit;
  $scope.validateToleranceLimit = validateToleranceLimit;
  $scope.cancelUpdateToleranceSettings = cancelUpdateToleranceSettings;
  $scope.resetValues = resetValues;
  $scope.submitAutomatedPo = submitAutomatedPo;
  $scope.cancelUpdateAutomatedPoSettings = cancelUpdateAutomatedPoSettings;
  $scope.validateConsolidationExpired = validateConsolidationExpired;
  $scope.submitConsolidationExpiredDays = submitConsolidationExpiredDays;
  $scope.cancelUpdateExpiredSettings = cancelUpdateExpiredSettings;
  $scope.setConsolidationExpiredSettings = setConsolidationExpiredSettings;
  $scope.resetConsolidationExpiredSetting = resetConsolidationExpiredSetting;
  $scope.loadStockInformationGroup = loadStockInformationGroup;
  $scope.opexGroupingSettingChanges = opexGroupingSettingChanges;
  $scope.UpdatePriceComparisonSettings = UpdatePriceComparisonSettings;
  $scope.cancelUpdatePriceComparisonSettings = cancelUpdatePriceComparisonSettings;
  $scope.bifrostFeatureToggle = bifrostFeatureToggle.is_enabled;

  function resetFieldsValue(){
    $scope.companyDetails.corresponding_address.country = $scope.countryBackup;
    $scope.companyDetails.corresponding_address.state = $scope.stateBackup;
    $scope.companyDetails.config.allowedToHoldPR = $scope.allowedToHoldPRBackup;
    $scope.companyDetails.expense_types = _.cloneDeep($scope.expenseTypesBackup);
    $scope.companyDetails.modes_of_purchase = _.cloneDeep($scope.modesOfPurchaseBackup);
    $scope.companyCurrencies= _.cloneDeep($scope.companyDetails.currencies);
    preferredCurrencyInitValue();
  }
  $scope.deactivateCostCenter = deactivateCostCenter;
  $scope.activateCostCenter = activateCostCenter;

  function isPoSetToExpired() {
    return $scope.companyDetails.config.po_expired_after_day > 0 ?
      $scope.companyDetails.config.po_expired_after_day + ' Day(s)' : 'Disabled';
  }

  function saveSupplierWebSetting() {
    var supplierWebSetting = {
      'supplier_web': $scope.supplierWebSetting
    };

    companySupplierWebSetting.put(
      {
        id: $scope.companyDetails._id
      },
      supplierWebSetting,
      function (resource) {
        if (!!resource.content && !!resource.content.message) {
          resetSupplierWebSetting();
          toastr.success("Successfully updated.");
        }
      },
      function (error) {
        cancelSupplierWebSetting();
        globalFunc.objectErrorMessage(error);
      });
  }

  function resetSupplierWebSetting() {
    $scope.supplierWebSettingForm.$setPristine();
    $scope.supplierWebSettingBackup = _.cloneDeep($scope.supplierWebSetting);
  }

  function cancelSupplierWebSetting() {
    $scope.supplierWebSetting = _.cloneDeep($scope.supplierWebSettingBackup);
    $scope.supplierWebSettingForm.$setPristine();
  }

  function showCompanyHistory() {
    $uibModal.open({
      templateUrl: 'app/companiesManagement/companies/details/viewHistory.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'viewHistoryController',
      size: 'lg',
      windowClass: 'revision-wrapper',
      resolve: {
        company: function(){
          return $scope.companyDetails;
        },
        histories: function ($q, commentsHistory){
          var deferred = $q.defer();
          commentsHistory.get(
            {
              referenceId: $scope.companyDetails._id,
              offset: 5,
              order_by: '-created_at' // Created at, descending
            },
            function (resource) {
              deferred.resolve(resource.content || []);
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        }
      }
    });
  }

  /**
   * Generate CSV for address of all cost centers
   */
  function exportCostCenterAddresses() {
    companyCostCentersAddress.get({
        companyId: $scope.companyDetails._id
      },
      function(resource) {
        if (!!resource.content && !!resource.content.data) {
          var newUrl = $filter('format')(pathConstants.apiUrls.reports.download, {
            id: resource.content.data
          });
          window.open(newUrl, '_self');
        } else {
          globalFunc.objectErrorMessage('An error occurred.');
        }
      }, function(error) {
        globalFunc.objectErrorMessage(error);
      }
    )

  }

  function back() {
    if (!!$scope.companyDetails && !!$scope.companyDetails._id) {
      HighlightId.setId($scope.companyDetails._id);
    }
    window.history.back();
  }

  // Set Manage tab active
  function setActiveTab() {
    $scope.tabData.active = _.find($scope.tabData, function (tab) {
      if (!!tab.menu && tab.menu.length) {
        return _.find(tab.menu, function (item) {
          return item.params.action === "companies";
        });
      }
      else {
        return tab.params.action === "companies";
      }
    });

    if (!!$scope.tabData) {
      $scope.tabData.active.active = true;
    }
  }

  function onSelectedCountry(self,country){
    $scope.companyDetails.corresponding_address.state = undefined;
    if(!!self.$data) {
      $scope.companyDetails.corresponding_address.country = self.$data;
      if (self.$data === "Malaysia") {
        $scope.selectedMalaysia = true;
      }
      else {
        $scope.selectedMalaysia = false;
      }
    }
  }

  function onSelectedState(self,state){
    if(!!state) {
      $scope.companyDetails.corresponding_address.state = self.$data;
    }
  }

  function saveCorrespondingAddressChanges() {
    if(validate()) {
      var updateData = {
        name: $scope.companyDetails.corresponding_address.name,
        line1: $scope.companyDetails.corresponding_address.line1,
        line2: $scope.companyDetails.corresponding_address.line2,
        line3: $scope.companyDetails.corresponding_address.line3,
        postal_code: $scope.companyDetails.corresponding_address.postal_code,
        city: $scope.companyDetails.corresponding_address.city,
        state: $scope.companyDetails.corresponding_address.state,
        country: $scope.companyDetails.corresponding_address.country,
        phone: $scope.companyDetails.phone,
        fax: $scope.companyDetails.fax,
        company_code : $scope.companyDetails.code
      };

      if (!_.isEqual(updateData, backupCorrespondingAddress)) {
        singleAddress.put(
          {
            id: $scope.companyDetails.corresponding_address._id
          },
          updateData,
          function () {
            onSaveCompanyDetails($scope.companyDetails);
          },
          function (error) {
            globalFunc.objectErrorMessage(error);
          }
        )
      } else {
        onSaveCompanyDetails($scope.companyDetails);
      }
    }
  }

  function prepareCompanyChangedData(companyData, updateBudgetSettings) {
    /**
     * check if the GST was update and if its available
     */
    var companyDataChanges = {};
    var companyCurrency = [];
    var preferredCurrency = '';
    if (!!$scope.companyCurrencies && !!$scope.selectedPreferredCurrency) {
      companyCurrency = reformatCurrenciesSelected();
      preferredCurrency = $scope.selectedPreferredCurrency.code;
    }
    if ($scope.companyDetails.gst_code !== $scope.tempGST) {
      companyDataChanges.gst_code = $scope.companyDetails.gst_code;
    }
    if ($scope.companyDetails.reg_no !== $scope.tempRegNo) {
      companyDataChanges.reg_no = $scope.companyDetails.reg_no;
    }
    companyDataChanges.descr = companyData.descr;
    companyDataChanges.print_logo = companyData.print_logo.value;
    companyDataChanges.parent_company_id = (!!companyData.parent_company && !!companyData.parent_company.length > 0) ? companyData.parent_company[0]._id : null;
    companyDataChanges.currencies = companyCurrency;
    companyDataChanges.currency_code = preferredCurrency;
    companyDataChanges.fax = companyData.fax;
    companyDataChanges.email = companyData.email;
    companyDataChanges.phone = companyData.phone;
    companyDataChanges.corresponding_address_id = $scope.companyDetails.corresponding_address._id;
    companyDataChanges.PO_initial_status = !!companyData.PO_initial_status ? companyData.PO_initial_status.value : undefined;
    companyDataChanges.expense_types = globalFunc.getArrayOfKeyValues(companyData.expense_types, '_id');
    companyDataChanges.allowedToHoldPR = $scope.companyDetails.config.allowedToHoldPR;
    companyDataChanges.poExpiredAfterDay = $scope.companyDetails.config.po_expired_after_day;
    companyDataChanges.billingCompanies = $scope.companyDetails.billingCompanies;
    companyDataChanges.defaultBillingCompany = $scope.companyDetails.defaultBillingCompany;
    companyDataChanges.budget_setting = $scope.companyDetails.budget_setting;
    companyDataChanges.account_code_setting = $scope.companyDetails.account_code_setting;
    companyDataChanges.modes_of_purchase = globalFunc.getArrayOfKeyValues(companyData.modes_of_purchase, 'code');
    companyDataChanges.default_cost_center_code = companyData.default_cost_center.code;
    companyDataChanges.company_country = !!$scope.companyDetails.selectedCountryCode ? $scope.companyDetails.selectedCountryCode.descr : '';
    companyDataChanges.update_budget_settings = updateBudgetSettings;
    companyDataChanges.po_text = companyData.po_text;
    companyDataChanges.website_url = companyData.website_url;

    return companyDataChanges;
  }

  function onSaveCompanyDetails(companyData) {
    var companyDataChanges = prepareCompanyChangedData(companyData, false);
    Companies.put(
      {
        id: companyData._id
      }, companyDataChanges,
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.message) {
          toastr.success(resource.content.message);
          //todo: update addresses correctly without using state reload
          $state.reload();
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
        $state.reload();
      }
    );
  }

  /**
   * Navigate to add cost center page
   */
  function addCostCenterModal() {
    $state.go('main.user.companiesMgmt.detailCompanies.addCostCenter', {shadowCompanies: $scope.companyDetails.shadow_companies});
  }

  /**
   * To check if any of the child states are loaded, to hide the main company state
   */
  function showChildState() {
    var childStates = [
      'main.user.companiesMgmt.detailCompanies.addCostCenter',
      'main.user.companiesMgmt.detailCompanies.updateCostCenter',
      'main.user.companiesMgmt.detailCompanies.activities'
    ];

    for (var i in childStates) {
      if ($state.includes(childStates[i]))
        return true;
    }

    return false;
  }

  function costCenterDetails(costCenterId) {
    var modalInstance = $uibModal.open({
      templateUrl: 'app/companiesManagement/costcenters/details/details.html',
      controller: 'costCenterDetailCtrl',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'receive-goods-modal',
      scope: $scope,
      resolve: {
        costCenterDetails: function ($q, CostCenters) {
          var deferred = $q.defer();
          CostCenters.get(
            {
              id: costCenterId
            },
            function (resource) {
              deferred.resolve(resource.content.data || []);
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        },
        companyDetails: function(){
          return $scope.companyDetails;
        },
        shadowCompanies: function(){
          var shadowCompanies = _.cloneDeep($scope.companyDetails.shadow_companies);
          shadowCompanies.unshift({
            _id: '',
            code: '',
            descr: ''
          });
          return shadowCompanies;
        }
      }
    });
    modalInstance.result.then(function (newValue) {
      if (!!newValue) {
        _.forEach($scope.companyDetails.costCenters, function (dataList) {
          if (dataList._id === newValue._id) {
            dataList.descr = newValue.descr;
            dataList.corresponding_address_id = newValue.delivery_address_id;
          }
        });
      }
    }, function () {
    });
  }

  function viewCurrencies(companyCurrencies, company){
    var modalInstance = $uibModal.open({
      templateUrl: 'app/companiesManagement/companies/details/viewCurrencies-modalTemplate.html',
      controller: 'viewCurrenciesCtrl',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        viewCurrencies: function (){
          return companyCurrencies;
        },
        company: function (){
          return company.descr;
        }
      }
    });
  }

  function saveBu(dataChanges) {
    var newData = {};
    newData.bu_code = dataChanges.business_unit.code;
    newData.industry_code = dataChanges.industry.code;

    companyOtherInformation.put(
      {
        id: dataChanges._id
      }, newData,
      function (resource) {
        toastr.success((!!resource.content && !!resource.content.message && _.isString(resource.content.message))
          ? resource.content.message : 'Successfully updated' );
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function saveStockInformationGroup(dataChanges){
    if (!!$rootScope.isOffline) {
      toastr.error('Updating company settings in offline is disabled.');
      return
    }

    var selectedStockInfoGroup = globalFunc.findInArray($scope.stockInformationGroup, 'code', dataChanges.stock_information_group.code);
    setStockInformationGroup.post(
      {
        company_code: dataChanges.code,
        _id: selectedStockInfoGroup._id
      },
      function (resource) {
        if (!!resource.content && !!resource.content.message)
          toastr.success(resource.content.message)
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  /**
   * To send request to backend and save budget setting
   */
  function saveBudgetSetting(dataChanges) {
    if (!!$rootScope.isOffline) {
      toastr.error('Updating company settings in offline is disabled.');
      return
    }

    var newData = prepareCompanyChangedData(dataChanges, true);
    newData.budget_setting = {
      'enable': $scope.budgetModuleToggle,
      'allow_pr_overbudget': $scope.budgetExceedToggle,
      'opex_budget_grouping' : $scope.opexBudgetGrouping,
      'offline_budget': $scope.budgetOfflineToggle,
      'threshold_notification': {
        'enable': $scope.budgetNotificationToggle,
        'threshold': $scope.budgetThreshold.normal.low
      }
    };

    Companies.put(
      {
        id: dataChanges._id
      }, newData,
      function (resource) {
        toastr.success((!!resource.content && !!resource.content.message && _.isString(resource.content.message))
          ? resource.content.message : 'Successfully updated');
        $scope.companyDetails.budget_setting.enable = $scope.budgetModuleToggle;
        $scope.companyDetails.budget_setting.allow_pr_overbudget = $scope.budgetExceedToggle;
        $scope.companyDetails.budget_setting.threshold_notification.enable = $scope.budgetNotificationToggle;
        $scope.companyDetails.budget_setting.threshold_notification.threshold = $scope.budgetThreshold.normal.low;
        $scope.companyDetails.budget_setting.opex_budget_grouping = $scope.opexBudgetGrouping;
        $scope.companyDetails.budget_setting.offline_budget = $scope.budgetOfflineToggle;
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function savePurchaseOrderSetting() {
    if (!!$rootScope.isOffline) {
      toastr.error('Updating company settings in offline is disabled.');
      return
    }

    var purchaseOrderSetting = $scope.purchaseOrderSetting;

    companyPurchaseOrderSettings.put(
      {
        id: $scope.companyDetails._id
      },
      purchaseOrderSetting,
      function (resource) {
        if (!!resource.content && !!resource.content.message) {
          resetPurchaseOrderSettings();
          toastr.success("Successfully updated.");
        }
      },
      function (error) {
        cancelPurchaseOrderSettings();
        globalFunc.objectErrorMessage(error);
      });
  }

  /**
   * To send request to backend and save purchase requisition setting
   */

  function savePurchaseRequisitionSetting() {
    if (!!$rootScope.isOffline) {
      toastr.error('Updating company settings in offline is disabled.');
      return
    }

    var purchaseRequisitionSetting = $scope.purchaseRequisitionSetting;

    companyPurchaseRequisitionSettings.put(
      {
        id: $scope.companyDetails._id
      },
      purchaseRequisitionSetting,
      function (resource) {
        if (!!resource.content && !!resource.content.message) {
          resetPurchaseRequisitionSettings();
          toastr.success("Successfully updated.");
        }
      },
      function (error) {
        cancelPurchaseRequisitionSettings();
        globalFunc.objectErrorMessage(error);
      });
  }

  function setIntegrationSettings(){
      if (!!$rootScope.isOffline) {
        toastr.error('Updating company settings in offline is disabled.');
        return
      }

      var newData = {};
      newData.setCompanyIntegration = !!$scope.integrationSettings.isIntegrated ? 1 : 0;
      if (newData.setCompanyIntegration === 1 && !$scope.selectedIntegrationSolution.selected) {
        globalFunc.objectErrorMessage('Integration solution is not set');
        return;
      } else {
        newData.integration_solution = $scope.selectedIntegrationSolution.selected.value;
      }

      companyErpIntegrationSettings.put(
          {
              id: $scope.companyDetails._id
          }, newData,
          function (resource) {
              toastr.success((!!resource.content && !!resource.content.message && _.isString(resource.content.message))
                  ? resource.content.message : 'Successfully updated');
              resetIntegrationSettingsForm();
          },
          function (error) {
              globalFunc.objectErrorMessage(error);
          }
      );
  }

  function getPrintLogoSetting(printLogoValue){
     switch(printLogoValue){
       case 0:
         return 'No logo';
         break;
       case 1:
         return 'Tenant Logo';
         break;
       case 2:
         return 'Company Logo';
         break;
     }
  }

  function loadParentCompany() {
    if (!!$scope.companyDetails.parent_company) {
      return $scope.parentCompany = companies.data;
    }
    else {
      return '-';
    }
  }

  function loadCostCenters() {
    if (!!$scope.companyDetails.costCenters) {
      var x;
      $scope.costCenters = [
        {
          value: null,
          descr: 'N/A',
          code: null
        }
      ];

      for (x in $scope.companyDetails.costCenters) {
        if ($scope.companyDetails.costCenters[x].is_active == 1) {
          $scope.costCenters.push($scope.companyDetails.costCenters[x]);
        }
      }

      return $scope.costCenters;
    }
    else {
      return '-';
    }
  }

  function loadStockInformationGroup() {
    var returnText = "";
    if (!!$scope.companyDetails.stock_information_group && !!$scope.companyDetails.stock_information_group.code){
      var selected = globalFunc.findInArray(stockInformationGroup, 'code', $scope.companyDetails.stock_information_group.code);

      if ($scope.companyDetails.stock_information_group.code && !!selected)
        returnText = selected.code + " (" + selected.descr + ")";
    }
    return returnText;
  }

  function loadBUs() {
    var returnText = "Not set";

    if (!!$scope.companyDetails.business_unit && !!$scope.companyDetails.business_unit.code) {
      var selected = globalFunc.findInArray(BUs.data, 'code', $scope.companyDetails.business_unit.code);

      if ($scope.companyDetails.business_unit.code && !!selected)
        returnText = selected.code + " (" + selected.descr + ")";
    }
    return returnText;
  }

  function loadIndustry() {
    var returnText = "Not set";
    if (!!$scope.companyDetails.industry && !!$scope.companyDetails.industry.code) {
      var selected = globalFunc.findInArray(industryList.data, 'code', $scope.companyDetails.industry.code);

      if ($scope.companyDetails.industry.code && !!selected)
        returnText = selected.code + " (" + selected.descr + ")";
    }
    return returnText;
  }

  function loadApproverOptions() {
    var returnText = 'No';
    if(!!$scope.companyDetails.config.allowedToHoldPR) {
      returnText = 'Yes';
    }
    return returnText;
  }

  function isUploadImageSuccess(file, message) {
    if (message) {
      var response = JSON.parse(message);
      toastr.success(response.content.message);
    }
  }

  function initialize() {
    $scope.toleranceLimitData = toleranceLimitData.data;
    $scope.automatedPo = $scope.companyDetails.config.automated_po;
    $scope.priceComparison = {
      toggle: $scope.companyDetails.config.price_comparison
    };
    $scope.priceComparisonBackup = _.cloneDeep($scope.companyDetails.config.price_comparison);

    var consolidationExpiredData = {};
    consolidationExpiredData.consolidationExpired = {
      is_active: !!$scope.companyDetails.config.consolidationExpired.is_active ? $scope.companyDetails.config.consolidationExpired.is_active : false,
      days: !!$scope.companyDetails.config.consolidationExpired.days ? $scope.companyDetails.config.consolidationExpired.days : '',
    };
    $scope.consolidationExpiredData = consolidationExpiredData;

    $scope.erpImportParams = {
      'class': 'App\\Metabuyer\\Models\\ErpCostCenter',
      'additional_params[company_code]': $scope.companyDetails.code
    };

    $scope.erpOrderTypeImportParams = {
      'class': 'App\\Metabuyer\\Models\\ErpOrderType',
      'additional_params[company_code]': $scope.companyDetails.code
    };

    budgetSettingToggle('reset');
    var tabData = {
      tab: $scope.$parent.tabData[0],
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);

    $scope.printLogoOptions = [
      {
        descr: 'No Logo',
        value: 0
      },
      {
        descr: 'Tenant Logo',
        value: 1
      },
      {
        descr: 'Company Logo',
        value: 2
      }
    ];

    $scope.companyIntegrationOptions = [
      {
        descr: 'MB-JDE-ESKER',
        value: 'MB-JDE-ESKER'
      },
      {
        descr: 'MB-IFCA-ESKER',
        value: 'MB-IFCA-ESKER'
      },
      {
        descr: 'MB-JDE-JDE',
        value: 'MB-JDE-JDE'
      },
      {
        descr: 'MB-CMMS-ESKER',
        value: 'MB-CMMS-ESKER'
      },
      {
        descr: 'MB-SAGE-ESKER',
        value: 'MB-SAGE-ESKER'
      },
      {
        descr: 'MB-GENP-MSGP-MSGP',
        value: 'MB-GENP-MSGP-MSGP'
      },
      {
        descr: 'MB-GENP-MSGP-INDEPENDENT',
        value: 'MB-GENP-MSGP-INDEPENDENT'
      },
      {
        descr: 'MB-GENP-MSGP-DEPENDENT',
        value: 'MB-GENP-MSGP-DEPENDENT'
      },
      {
        descr: 'MB-GENP-SAP',
        value: 'MB-GENP-SAP'
      },
      {
        descr: 'MB-GENP-LINTRAMAX',
        value: 'MB-GENP-LINTRAMAX'
      },
      {
        descr: 'MB-GENP-MSGP-MY',
        value: 'MB-GENP-MSGP-MY'
      }
    ];
    $scope.selectedIntegrationSolution.selected =
      _.find($scope.companyIntegrationOptions, {'value': $scope.companyDetails.config.integration_solution});
    $scope.selectedIntegrationSolutionBackup.selected = _.cloneDeep($scope.selectedIntegrationSolution.selected);

    $scope.countries = globalFunc.countryCode();
    $scope.MYStates = globalFunc.malaysiaState();

    $scope.companyDetails.currency_code = globalFunc.findInArray($scope.companyCurrencies, 'code', $scope.companyDetails.currency_code);

    // storing the initial GST to use it for duplication check later on.
    $scope.tempGST = null;
    if(!!$scope.companyDetails.gst_code){
      $scope.tempGST = $scope.companyDetails.gst_code;
    }

    // storing the initial Registration number to use it for duplication check later on.
    $scope.tempRegNo = null;
    $scope.tempRegNo = $scope.companyDetails.reg_no;

    $scope.selectedMalaysia = ($scope.companyDetails.corresponding_address && $scope.companyDetails.corresponding_address.country === 'Malaysia');



    $scope.imageBasePath = pathConstants.apiUrls.image.user;

    if (!!$scope.countries) {
      var temp = _.find($scope.countries, function (item) {
        return $scope.companyDetails.company_country === item.descr
      });

      if (!!temp) {
        $scope.companyDetails.selectedCountryCode = temp;
      }
    }

    setActiveTab();
//    companyCurrenciesData();
    preferredCurrencyInitValue();
    loadApproverOptions();

    //set params to send company id for export
    var params = {
      module: 'cost-centers',
      offset: 0,
      'criteria[company_code]': $scope.companyDetails.code
    };
    exportSearch.setParams(params);

    if (!!featureToggles['company.config.disableExchangeRate']) {
      $scope.toggleList.disableExchangeRateToggle = featureToggles['company.config.disableExchangeRate'].is_enabled;
    }

    if (!!featureToggles['company.config.disableItemTax']) {
      $scope.toggleList.disableItemTaxToggle = featureToggles['company.config.disableItemTax'].is_enabled;
    }

    if (!!featureToggles['company.config.modeOfPurchase']) {
      $scope.toggleList.modeOfPurchaseToggle = featureToggles['company.config.modeOfPurchase'].is_enabled;
    }

    if (!!featureToggles['company.config.toleranceLimit']) {
      $scope.toggleList.toleranceLimitToggle = featureToggles['company.config.toleranceLimit'].is_enabled;
    }

    if (!!featureToggles['company.config.automatedPo']) {
      $scope.toggleList.automatedPoToggle = featureToggles['company.config.automatedPo'].is_enabled;
      if (featureToggles['company.config.automatedPo'].is_enabled === true) {
        $scope.POInitialStatuses = companyGlobalFunc.getPOV2InitialStatuses();
      }
    }

    if (!!featureToggles['company.config.stockInformationGroup']) {
      $scope.toggleList.stockInformationGroupToggle = featureToggles['company.config.stockInformationGroup'].is_enabled;
      $scope.stockInformationGroup.unshift({
        _id: '',
        code: '',
        descr: '-',
        name: '-'
      })
    }

    if (!!featureToggles['company.config.enablePriceComparison']) {
      $scope.toggleList.enablePriceComparisonToggle = featureToggles['company.config.enablePriceComparison'].is_enabled;
    }

    $scope.companyDetails.PO_initial_status = globalFunc.findInArray(
      $scope.POInitialStatuses, 'value', $scope.companyDetails.PO_initial_status
    );

    if (!!$scope.companySelectedMop) {
      $scope.companySelectedMop.unshift(defaultModeOfPurchase);
    }
  }

  /**
   * initial value for preferred currency
   */
  function preferredCurrencyInitValue(){
    $scope.preferredCurrencyList = _.cloneDeep($scope.companyCurrencies);
    $scope.selectedPreferredCurrency = _.cloneDeep(globalFunc.findInArray(
      $scope.preferredCurrencyList, 'code', $scope.companyDetails.currency_code.code
    ));
  }

  /**
   * Reformat Currencies Selected
   * reformat after the ng-dropdown-multiselect
   * to follow the endpoint format
   */
  function reformatCurrenciesSelected(){
    var companyCurrency = [];
    _.forEach($scope.companyCurrencies, function(currency){
      companyCurrency.push(currency.code);
    });

    return companyCurrency;
  }

  /**
   * To import Cost Centers by uploading csv file
   */
  function importCostCentersModal() {
    var modalInstance = $uibModal.open({
      templateUrl: "app/masterDataManagement/Import-Template.html",
      backdrop: 'static',
      keyboard: false,
      resolve: {
        importLink: function () {
          return $scope.importLink.replace(":companyId", $scope.companyDetails._id);
        },
        title: function () {
          return "Cost Centers";
        }
      },
      controller: function ($scope, $uibModalInstance, importLink, title) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        $scope.importLink = importLink;
        $scope.title = title;
      }
    });
  }

  /**
   * @function duplicationCheck
   * Calls the global function's duplication check
   * Checks callback function return for assigning scope values for validation
   **/
  function duplicationCheck(model,field,value,checker){
    globalFunc.duplicationCheck(model,field,value,checker, function(callback){
      switch (callback){
        case 'codeCheckTrue':
          $scope.codeCheck = true;
          break;
        case 'rocCheckTrue':
          value !== $scope.tempRegNo ? $scope.rocCheck = true : $scope.rocCheck = false;
          break;
        case 'gstCheckTrue':
          value !== $scope.tempGST ? $scope.gstCheck = true : $scope.gstCheck = false;
          break;
        case 'codeCheckFalse':
          $scope.codeCheck = false;
          break;
        case 'rocCheckFalse':
          $scope.rocCheck = false;
          break;
        case 'gstCheckFalse':
          $scope.gstCheck = false;
          break;
      }
    });
  }

  /**
   * To check if budget setting is changed by the user
   */
  function budgetSettingChanges() {
    return ($scope.budgetModuleToggle !== $scope.companyDetails.budget_setting.enable) ||
    ($scope.budgetExceedToggle !== $scope.companyDetails.budget_setting.allow_pr_overbudget) ||
    ($scope.budgetNotificationToggle !== $scope.companyDetails.budget_setting.threshold_notification.enable) ||
    ($scope.budgetThreshold.normal.low !== ($scope.companyDetails.budget_setting.threshold_notification.threshold || 0)) || $scope.budgetOfflineToggle !== $scope.companyDetails.budget_setting.offline_budget
      ? true : false;
  }

  /**
   * To check if opex grouping budget setting is changed by the user
   * @returns {boolean}
   */
  function opexGroupingSettingChanges() {
   return  ($scope.opexBudgetGrouping !== $scope.companyDetails.budget_setting.opex_budget_grouping);
  }

  /**
   * To check if budget setting is changed by the user
   */
  function purchaseOrderSettingChanges() {
    return ($scope.purchaseOrderSetting.pa_allowed_to_cancel_po !== $scope.purchaseOrderSettingBackup.pa_allowed_to_cancel_po) ||
    ($scope.purchaseOrderSetting.allow_grn_receive !== $scope.purchaseOrderSettingBackup.allow_grn_receive) ||
    ($scope.purchaseOrderSetting.allow_grn_reverse !== $scope.purchaseOrderSettingBackup.allow_grn_reverse) ?
      true : false;
  }

  /**
   * Events to trigger when budget setting update by user
   * module : if budget module is set to off, sub setting will be set to null
   * notification : if budget notification is turn off, threshold will be set to null
   * reset : reset the initial setting of the budget setting
   */
  function budgetSettingToggle(type) {
    if ($scope.budgetModuleToggle === false && type === 'module') {
      $scope.budgetExceedToggle = false;
      $scope.budgetNotificationToggle = false;
      $scope.budgetThreshold.normal.low = null;
      $scope.opexBudgetGrouping = null;
      $scope.budgetOfflineToggle = null;
    }
    if (type === 'notification') {
      if ($scope.budgetNotificationToggle === false) {
        $scope.budgetThreshold.normal.low = null;
      } else {
        $scope.budgetThreshold.normal.low =
          (!!$scope.companyDetails.budget_setting &&
          !!$scope.companyDetails.budget_setting.threshold_notification &&
          !!$scope.companyDetails.budget_setting.threshold_notification.threshold) ?
            !!$scope.companyDetails.budget_setting.threshold_notification.threshold : 80; // initialize value to 80
      }
    }
    if (type === 'reset') {
      $scope.budgetModuleToggle = $scope.companyDetails.budget_setting.enable;
      $scope.budgetExceedToggle = $scope.companyDetails.budget_setting.allow_pr_overbudget;
      $scope.budgetNotificationToggle = $scope.companyDetails.budget_setting.threshold_notification.enable;
      $scope.budgetThreshold.normal.low = $scope.companyDetails.budget_setting.threshold_notification.threshold;
      $scope.opexBudgetGrouping = $scope.companyDetails.budget_setting.opex_budget_grouping;
      $scope.budgetOfflineToggle = $scope.companyDetails.budget_setting.offline_budget;
    }

    if (type === 'opex-budget-grouping') {
      $scope.opexBudgetGrouping = !$scope.opexBudgetGrouping ? null : true;
    }

    if (type === 'offline-budget') {
      $scope.budgetOfflineToggle = !$scope.budgetOfflineToggle ? null : true;
    }
  }

  /**
   * Submit updated approval settings
   */
  function updateApprovalSettings() {
    if (!!$rootScope.isOffline) {
      toastr.error('Updating company settings in offline is disabled.');
      return
    }

    if (validateApprovalSettings()) {
      var approvalSettings = {
        amount_increase: !!$scope.approvalSettings.amount_change.amount_increase,
        total_amount_gt: $scope.approvalSettings.amount_change.total_amount_gt || 0
      };

      if (!$scope.approvalSettings.reroute_approval) {
        approvalSettings.amount_increase = false;
        approvalSettings.total_amount_gt = 0;
      }

      companyApprovalSettings.put({
          id: $scope.companyDetails._id
        },
        approvalSettings,
        function (resource) {
          if (!!resource.content && !!resource.content.message) {
            resetApprovalSettingsForm();
            toastr.success(resource.content.message);
          }
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        });
    }
  }

  /**
   * Reset approval settings form
   */
  function resetApprovalSettingsForm() {
    $scope.approvalSettingsForm.$setPristine();
    $scope.approvalSettings.reroute_approval = ($scope.approvalSettings.amount_change.total_amount_gt > 0 ||
        !!$scope.approvalSettings.amount_change.amount_increase);
    $scope.approvalSettingsBackup = _.cloneDeep($scope.approvalSettings);
  }

  /**
   * Validate approval settings
   */
  function validateApprovalSettings() {
    if (!$scope.approvalSettings.amount_change.amount_increase &&
        ($scope.approvalSettings.amount_change.total_amount_gt === 0 ||
          _.isUndefined($scope.approvalSettings.amount_change.total_amount_gt))) {
      toastr.error('Please fill in one of the fields.');
      return false;
    }

    return true;
  }

  /**
   * Cancel update approval settings
   */
  function cancelUpdateApprovalSettings() {
    $scope.approvalSettings = _.cloneDeep($scope.approvalSettingsBackup);
    $scope.approvalSettingsForm.$setPristine();
  }

  /**
   * Cancel updating integration settings
  */
  function cancelUpdateIntegrationSettings() {
    $scope.integrationSettings = _.cloneDeep($scope.integrationSettingsBackup);
    $scope.selectedIntegrationSolution.selected = _.cloneDeep($scope.selectedIntegrationSolutionBackup.selected);
    $scope.integrationSettingsForm.$setPristine();
  }
  /**
   * Reset approval settings form
   */
  function resetIntegrationSettingsForm() {
    $scope.integrationSettingsBackup = _.cloneDeep($scope.integrationSettings);
    $scope.selectedIntegrationSolutionBackup.selected = _.cloneDeep($scope.selectedIntegrationSolution.selected);
    $scope.integrationSettingsForm.$setPristine();
    $scope.approvalSettingsBackup = _.cloneDeep($scope.approvalSettings);
  }

  /**
   * To clear all changes
   */
  function cancelPurchaseRequisitionSettings() {
    $scope.purchaseRequisitionSetting = _.cloneDeep($scope.purchaseRequisitionSettingBackup);
    $scope.purchaseRequisitionSettingForm.$setPristine();
  }

  function resetPurchaseRequisitionSettings() {
    $scope.purchaseRequisitionSettingForm.$setPristine();
    $scope.purchaseRequisitionSettingBackup = _.cloneDeep($scope.purchaseRequisitionSetting);
  }

  function cancelPurchaseOrderSettings() {
    $scope.purchaseOrderSetting = _.cloneDeep($scope.purchaseOrderSettingBackup);
    $scope.purchaseOrderSettingForm.$setPristine();

  }

  function resetPurchaseOrderSettings() {
    $scope.purchaseOrderSettingForm.$setPristine();
    $scope.purchaseOrderSettingBackup = _.cloneDeep($scope.purchaseOrderSetting);
  }

  function resetToleranceLimitSettings(updatedTolerance) {
    $scope.toleranceLimitDataBackup.toleranceLimit.is_active = _.cloneDeep(updatedTolerance.toleranceLimit.is_active);
    $scope.toleranceLimitDataBackup.toleranceLimit.percentage = _.cloneDeep(updatedTolerance.toleranceLimit.percentage);
    $scope.toleranceLimitDataBackup.toleranceLimit.amount = _.cloneDeep(updatedTolerance.toleranceLimit.amount);
    $scope.toleranceLimitDataBackup.toleranceLimit.company_items = _.cloneDeep(updatedTolerance.toleranceLimit.company_items);
    $scope.toleranceLimitDataBackup.toleranceLimit.modes_of_purchase = _.cloneDeep(updatedTolerance.toleranceLimit.modes_of_purchase);
  }

  function setAutomatedPoSettings(updatedAutomatePo) {
    $scope.automatedPoDataBackup = _.cloneDeep(updatedAutomatePo.automated_po);
  }

  function setPriceComparisonSettings(updatedPriceComparison) {
    $scope.priceComparisonBackup = _.cloneDeep(updatedPriceComparison);
  }

  function setConsolidationExpiredSettings(updatedConsolidationExpired) {
    $scope.consolidationExpiredDataBackup.consolidationExpired.is_active = _.cloneDeep(updatedConsolidationExpired.consolidationExpired.is_active);
    $scope.consolidationExpiredDataBackup.consolidationExpired.days = _.cloneDeep(updatedConsolidationExpired.consolidationExpired.days);
  }

  /**
   * validate the entries of the company
   * @returns {boolean}
   */
  function validate() {
    /**
     * validate the gst code
     */
    if ($scope.gstCheck === true) {
      $scope.companyDetails.gst_code = $scope.tempGST;
      toastr.error('The GST number is registered to another company');
      return false;
    }
    if ($scope.rocCheck === true) {
      $scope.companyDetails.reg_no = $scope.tempRegNo;
      toastr.error('The Registration number is assigned to another company');
      return false;
    }

    if(!$scope.companyDetails.corresponding_address){
      toastr.error('The Address is required');
      return false;
    }
    if(!$scope.companyDetails.corresponding_address.line1){
      toastr.error('The Address First line field is required');
      return false;
    }
    if(!$scope.companyDetails.corresponding_address.postal_code){
      toastr.error('The Address Postal code field is required');
      return false;
    }
    if(!$scope.companyDetails.corresponding_address.city){
      toastr.error('The Address City field is required');
      return false;
    }
    if(!$scope.companyDetails.corresponding_address.state){
      toastr.error('The Address State field is required');
      return false;
    }
    if(!$scope.companyDetails.corresponding_address.country){
      toastr.error('The Address Country field is required');
      return false;
    }
    if(!$scope.companyDetails.corresponding_address.state){
      toastr.error('The Address State field is required');
      return false;
    }
    if (!$scope.companyCurrencies ||
      (!!$scope.companyCurrencies && !$scope.companyCurrencies.length)
    ){
      toastr.error('The Company Currency is required');
      return false;
    }
    if (!$scope.selectedPreferredCurrency ||
      (!!$scope.selectedPreferredCurrency && !$scope.selectedPreferredCurrency.code)
    ){
      toastr.error('The Preferred Currency is required');
      return false;
    }
    if(!$scope.companyDetails.expense_types || !$scope.companyDetails.expense_types.length){
      toastr.error('The expense type is required');
      return false;
    }
    return true;
  }

  function deleteErpOrderType(id) {
    swal({
      title: "Confirm delete ERP Order Type?",
      text: "This ERP Order Type will be deleted. Please confirm",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1ab394",
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      closeOnConfirm: true,
      closeOnCancel: true
    }, function () {
      companyErpOrderType.delete(
        {
          id: id
        },
        function () {
          toastr.success('Deleted ERP Order Type');
          for (var i in $scope.companyDetails.erp_order_types) {
            if ($scope.companyDetails.erp_order_types[i]._id === id) {
              $scope.companyDetails.erp_order_types.splice(i, 1);
            }
          }
          $uibModalInstance.close();
        },
        function () {
          toastr.error('Failed to delete ERP Order Type');
        }
      );
    });
  }

  function deleteErpCostCenter(id) {
    swal({
      title: "Confirm delete ERP Cost Center?",
      text: "This ERP Cost Center will be deleted. Please confirm",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1ab394",
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      closeOnConfirm: true,
      closeOnCancel: true
    }, function () {
      companyErpCostCenter.delete(
        {
          id: id
        },
        function () {
          toastr.success('Deleted ERP Cost Center');
          for (var i in $scope.companyDetails.erp_cost_centers) {
            if ($scope.companyDetails.erp_cost_centers[i]._id === id) {
              $scope.companyDetails.erp_cost_centers.splice(i, 1);
            }
          }
          $uibModalInstance.close();
        },
        function () {
          toastr.error('Failed to delete ERP Cost Center');
        }
      );
    });
  }

  function deactivateCostCenter(costCenterId) {
    swal({
      title: "Confirm deactivate Cost Center?",
      text: "This Cost Center will be deactivated. Please confirm",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1ab394",
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      closeOnConfirm: true,
      closeOnCancel: true
    }, function () {
      deactivateCostCenters.put(
        {
          id: costCenterId
        },
        function () {
          toastr.success('Deactivated Cost Center');
          $state.reload();
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    });
  }

  function activateCostCenter(costCenterId) {
    swal({
      title: "Confirm activate Cost Center?",
      text: "This Cost Center will be activated. Please confirm",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1ab394",
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      closeOnConfirm: true,
      closeOnCancel: true
    }, function () {
      activateCostCenters.put(
        {
          id: costCenterId
        },
        function () {
          toastr.success('Activated Cost Center');
          $state.reload();
        },
        function () {
          toastr.error('Failed to activate Cost Center');
        }
      );
    });
  }

  function addErpCostCenterModal() {
    var addErpModalInstance = $uibModal.open({
      templateUrl: "app/companiesManagement/companies/erp/erpCostCenter/add.html",
      controller: 'addErpCostCenterCtrl',
      backdrop: 'static',
      keyboard: false,
      scope: $scope
    });

    addErpModalInstance.result.then(function (result) {
      $scope.companyDetails.erp_cost_centers.push(result.data);
    });
  }

  function addErpOrderTypeModal() {
    var addErpModalInstance = $uibModal.open({
      templateUrl: "app/companiesManagement/companies/erp/erpOrderType/add.html",
      controller: 'addErpOrderTypeCtrl',
      backdrop: 'static',
      keyboard: false,
      scope: $scope
    });

    addErpModalInstance.result.then(function (result) {
      $scope.companyDetails.erp_order_types.push(result.data);
    })
  }

  function submitToleranceLimit(toleranceLimitData) {
    if (!!$rootScope.isOffline) {
      toastr.error('Updating company settings in offline is disabled.');
      return
    }

    $scope.validationError = false;

    if (!toleranceLimitData.toleranceLimit.is_active) {
      var payload = {};
      payload.toleranceLimit = {
        is_active: toleranceLimitData.toleranceLimit.is_active,
        percentage: undefined,
        amount: undefined,
        company_code: $scope.companyDetails.code
      };
    } else {
      if (!validateToleranceLimit()) {
        $scope.validationError = true;
        toastr.error('Please Fill both Percentage and Amount tolerance value');
        return;
      }

      if (!!toleranceLimitData.toleranceLimit.percentage && toleranceLimitData.toleranceLimit.amount === 0) {
        $scope.validationError = true;
        toastr.error('Amount can not be zero');
        return;
      }

      if (!!toleranceLimitData.toleranceLimit.amount && toleranceLimitData.toleranceLimit.percentage === 0) {
        $scope.validationError = true;
        toastr.error('Percentage can not be zero');
        return;
      }

      var payload = {};
      payload.toleranceLimit = {
        is_active: toleranceLimitData.toleranceLimit.is_active,
        percentage: (!toleranceLimitData.toleranceLimit.percentage &&
          (typeof toleranceLimitData.toleranceLimit.percentage !== 'number'))  ?
          0 : toleranceLimitData.toleranceLimit.percentage,
          amount: (!toleranceLimitData.toleranceLimit.amount &&
          (typeof toleranceLimitData.toleranceLimit.amount !== 'number')) ?
            0 : toleranceLimitData.toleranceLimit.amount,
        company_items: toleranceLimitData.toleranceLimit.company_items || undefined,
        company_code: $scope.companyDetails.code,
        modes_of_purchase: $scope.selectedToleranceLimitMop || undefined
      };
    }

    toleranceLimit.put(
      {
        id: $scope.companyDetails._id
      }, payload,
      function (resource) {
        $scope.companyItemModified = false;
        $scope.toleranceLimitMopModified = false;
        resetToleranceLimitSettings(resource.content.data);
        toastr.success("Company's tolerance limit updated");
      },
      function (error) {
        toastr.error('Failed to update company tolerance limit');
      }
    );
  }

  function submitConsolidationExpiredDays(consolidationExpiredData) {
    if (!!$rootScope.isOffline) {
      toastr.error('Updating company settings in offline is disabled.');
      return
    }

    $scope.validationError = false;

    if (!consolidationExpiredData.consolidationExpired.is_active) {
      var payload = {};
      payload.consolidationExpired = {
        is_active: consolidationExpiredData.consolidationExpired.is_active,
        days: null
      };
    } else {
      if (!validateConsolidationExpired()) {
        $scope.validationError = true;
        toastr.error('Please Fill Pending Process PR Expired Settings');
        return;
      }

      var payload = {};
      payload.consolidationExpired = {
        is_active: consolidationExpiredData.consolidationExpired.is_active,
        days: parseInt(consolidationExpiredData.consolidationExpired.days)
      };
    }

    consolidationExpired.put(
      {
        id: $scope.companyDetails._id
      }, payload,
      function (resource) {
        setConsolidationExpiredSettings(resource.content.data);
        toastr.success("PO Consolidation expire updated");
      },
      function (error) {
        toastr.error('Failed to update PO Consolidation expire');
      }
    );
  }

  function submitAutomatedPo(data) {
    if (!!$rootScope.isOffline) {
      toastr.error('Updating company settings in offline is disabled.');
      return
    }

    var payload = {
      automatedPo: data
    };

    automatedPo.put(
      {
        id: $scope.companyDetails._id
      }, payload,
      function (resource) {
        setAutomatedPoSettings(resource.content.data);
        toastr.success('Company\'s automated PO generation updated');
      },
      function (error) {
        toastr.error('Failed to update company automated PO generation');
      }
    );
  }

  function cancelUpdatePriceComparisonSettings() {
    $scope.priceComparison.toggle = _.cloneDeep($scope.priceComparisonBackup);
  }

  function UpdatePriceComparisonSettings(priceComparisonToggle) {
    priceComparisonCompanySetting.put(
      {
        id: $scope.companyDetails._id
      },
      {
        is_price_comparison_enabled: priceComparisonToggle
      },
      function (resource) {
        setPriceComparisonSettings(priceComparisonToggle);
        toastr.success('Company\'s price comparison settings updated');
      },
      function (error) {
        toastr.error('Failed to update price comparison settings');
      }
    )
  }

  function validateToleranceLimit() {
    var percentage = $scope.toleranceLimitData.toleranceLimit.percentage;
    var amount = $scope.toleranceLimitData.toleranceLimit.amount;

    if (percentage === null || amount === null) {
      return false;
    }

    return true;
  }

  function validateConsolidationExpired() {
    var days = $scope.consolidationExpiredData.consolidationExpired.days;

    if (days === null) {
      return false;
    }

    return true;
  }

  function cancelUpdateToleranceSettings() {
    $scope.toleranceLimitData.toleranceLimit.is_active = _.cloneDeep($scope.toleranceLimitDataBackup.toleranceLimit.is_active);
    $scope.toleranceLimitData.toleranceLimit.percentage = _.cloneDeep($scope.toleranceLimitDataBackup.toleranceLimit.percentage);
    $scope.toleranceLimitData.toleranceLimit.amount = _.cloneDeep($scope.toleranceLimitDataBackup.toleranceLimit.amount);
    $scope.toleranceLimitData.toleranceLimit.company_items = _.cloneDeep($scope.toleranceLimitDataBackup.toleranceLimit.company_items);
    $scope.toleranceLimitData.toleranceLimit.modes_of_purchase = _.cloneDeep($scope.toleranceLimitDataBackup.toleranceLimit.modes_of_purchase);
    $scope.companyItemModified = false;
    $scope.toleranceLimitMopModified = false;
  }

  function cancelUpdateExpiredSettings() {
    $scope.consolidationExpiredData.consolidationExpired.is_active =
      _.cloneDeep($scope.consolidationExpiredDataBackup.consolidationExpired.is_active);
    $scope.consolidationExpiredData.consolidationExpired.days =
      _.cloneDeep($scope.consolidationExpiredDataBackup.consolidationExpired.days);
  }

  function cancelUpdateAutomatedPoSettings() {
    $scope.automatedPo = _.cloneDeep($scope.automatedPoDataBackup);
  }

  function resetValues(isActive) {
    if (!isActive) {
      $scope.toleranceLimitData.toleranceLimit.percentage = null;
      $scope.toleranceLimitData.toleranceLimit.amount = null;
      $scope.toleranceLimitData.toleranceLimit.company_items = null;
    }
  }

  function resetConsolidationExpiredSetting(isActive) {
    if (!isActive) {
      $scope.consolidationExpiredData.consolidationExpired.days = null;
    }
  }

  function refreshItem(val){
    if (!!val && val.length > 2){
      searchCompanyItem(val).then(function(response){
        $scope.companyItemList = _.filter(response, function(e){
          var index = _.findIndex($scope.toleranceLimitData.toleranceLimit.company_items, function(item){
            return item.item_master_code === e.item_master_code;
          })
          if (index === -1){
            return true;
          }

          return false;
        });
      })
    }
  }

  function onSelectItem(item){
    $scope.companyItemModified = true;
    if (_.isEmpty($scope.toleranceLimitData.toleranceLimit.company_items)){
      $scope.toleranceLimitData.toleranceLimit.company_items = [];
    }

    var index = _.findIndex($scope.toleranceLimitData.toleranceLimit.company_items, function(e){
      return e.item_master_code === item.item_master_code;
    });

    if (index !== -1){
      $scope.toleranceLimitData.toleranceLimit.company_items[index] = {
        item_master_code: item.item_master_code,
        name: item.name
      };
    }

    // Clear dropdown list from search result
    $scope.companyItemList = [];
  }

  function onRemoveItem(){
    $scope.companyItemModified = true;
  }

  function searchCompanyItem(query) {
    $scope.meta = {};

    var additionalParams = {
      'criteria[0][company|code]': $scope.companyDetails.code,
      'criteria[1][item_master_code]': query,
      'criteria[1][name]': query,
      'criteria[1][$operator]': 'or',
      'criteria_operator': 'and',
      offset: 10
    };

    var moduleParam = {
      module: 'company-item'
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams
    }).then(function (response) {
      if (response.data.content.data.length === 0) {
        return [];
      } else {
        return response.data.content.data.map(function (item) {
          return item;
        });
      }
    }, function (error) {
      globalFunc.objectErrorMessage(error);
    });
};

  initialize();

}
companyDetailCtrl.$inject = [
  '$scope', '$state', 'companyDetails', 'Companies', '$uibModal', 'toastr', 'currenciesList', 'BUs',
  'industryList', 'metabuyerCache', 'singleAddress', '$filter', 'pathConstants', 'companiesDetailImage',
  '$auth', 'globalFunc', 'companyCurrencies', 'companies', '$rootScope', 'companyGlobalFunc',
  'expenseTypesList', 'exportSearch', 'companyCostCentersAddress', 'companyBudgetSetting',
  'HighlightId', 'companyApprovalSettings', 'commentsHistory', 'companyErpIntegrationSettings',
  'companyOtherInformation', 'companyPurchaseRequisitionSettings', 'companySupplierWebSetting',
  'companyPurchaseOrderSettings', 'companyErpOrderType', 'companyErpCostCenter', 'deactivateCostCenters', 'activateCostCenters',
  'toleranceLimitData', 'toleranceLimit', 'automatedPo', 'featureToggles', 'bifrostFeatureToggle', 'consolidationExpired', 'setStockInformationGroup',
  'stockInformationGroup', 'cachedAddresses', 'modeOfPurchaseList', '$http', 'priceComparisonCompanySetting'
];

angular
  .module('metabuyer')
  .controller('companyDetailCtrl', companyDetailCtrl);
