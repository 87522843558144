'use strict';
/**
 * @name itemManagementCompaniesList
 * @desc Controller for listing companies in company items module
 */
function itemManagementCompaniesList($scope, $stateParams, companies, HighlightId) {
  $scope.status = $stateParams.status;
  $scope.back = back;
  $scope.showColumn = showColumn;
  $scope.companies = companies.data;
  $scope.meta = companies.meta;

  $scope.highlightId = HighlightId.getId();
  if (!!$scope.highlightId) {
    HighlightId.setId(null);
  }

  function back(){
    window.history.back();
  }

  $scope.storageKey = 'item-management-company-listing-selected-columns';

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }
}

itemManagementCompaniesList.$inject = [
  '$scope','$stateParams', 'companies', 'HighlightId'
];

angular.module('metabuyer')
  .controller('itemManagementCompaniesList', itemManagementCompaniesList);
