'use strict';


function catalogCategoriesCtrl(
  $scope, $rootScope, globalFunc, metabuyerCache, catalogShoppingSearch, itemsCategories, catalogFunction, prConfig,
  toastr, $state) {
  $scope.advancedSearchState = 'main.user.advancedSearch.manageCatalogCategories';
  $scope.goAdvancedSearch = goAdvancedSearch;

  function setCategoriesView() {
    if (!metabuyerCache.get('categoriesData')) {
      itemsCategories.get(
        {
          code: 'all',
          groupIds: [groupIds]
        },
        function (resource) {
          if (!!resource.content && !!resource.content.data) {
            metabuyerCache.put('categoriesData', resource.content.data);
            $scope.categories = resource.content.data;
            divideCategories();
          }
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    } else {
      $scope.categories = metabuyerCache.get('categoriesData');
      divideCategories();
    }
  }

  function setCatalogDisplay(value, code) {
    $scope.supplierSearchDisplay = (code === 'supplier');
    $scope.catalogDisplayHandler = value;
  }

  function refreshPaginationParam(type) {
    $scope.paramsSource = {
      user_id: $rootScope.currentUser._id,
      type: type,
      offset: 20,
      groupIds: [groupIds]
    };
  }

  function browseCatalog(code, label, doNotLoadItems, type, makeAllAsDefault) {
    refreshSearchFilters(code, label, type, makeAllAsDefault);

    if ($scope.search.title.length === 0) {
      refreshPaginationParam(code);
    }

    if (!doNotLoadItems) {
      $scope.paramsSource.type = code;
      $scope.paramsSource.mb_supplier_code = null;
      loadItems(code);
    }
    if (type === 'all_categories') {
      setCategoriesView(true);
    } else if (type === 'category') {
      $scope.currentCategory = prepareBreadCrumb(code, $scope.currentCategory);
    }

    $scope.searchActivated = false;
  }

  function refreshSearchFilters(code, label, type, makeAllAsDefault) {
    $scope.searchFilter = [];
    /**
     * add the All option and setting it for the search filter
     */
    $scope.searchFilter.push(
      {
        label: 'All',
        code: '00000000',
        type: 'root'
      }
    );
    selectFilter($scope.searchFilter[0]);

    if (!!label && !!code) {
      $scope.searchFilter.push({
        label: label,
        code: code,
        type: type
      });

      if (!makeAllAsDefault)
        selectFilter($scope.searchFilter[1]);
    }
  }

  function selectFilter(filter) {
    $scope.selectedFilter = filter;
    $scope.selectedFilter.text = 'Search ' + filter.label + ' catalog';
  }

  function divideCategories() {
    if ($scope.categories) {
      $scope.segments = [];
      _.forEach($scope.categories, function (category) {
        var temp = {
          'code': category.code,
          'title': category.title,
          'families': category.families
        };
        $scope.segments.push(temp);
      });
      setCatalogDisplay('showCategories');
    }
  }

  function prepareBreadCrumb(code, previousBreadCrumb) {
    var breadCrumb = {};
    var familyCode = '';

    var segmentCode = code.substring(0, 2).concat('000000');
    var segment = globalFunc.findInArray($scope.categories, 'code', segmentCode);

    // type Segment
    if (code.substring(2) === '000000') {
      breadCrumb.current = segment;
      breadCrumb.childern = segment.families;
    }
    // type Family
    else if (code.substring(4) === '0000') {
      breadCrumb.parent = segment;
      breadCrumb.current = globalFunc.findInArray(segment.families, 'code', code);
      breadCrumb.childern = breadCrumb.current.classes;
    }
    // type Class
    else if (code.substring(6) === '00') {
      familyCode = code.substring(0, 4).concat('0000');
      breadCrumb.grandParent = segment;
      breadCrumb.parent = globalFunc.findInArray(segment.families, 'code', familyCode);
      breadCrumb.current = globalFunc.findInArray(globalFunc.findInArray(segment.families, 'code', familyCode).classes, 'code', code);
      breadCrumb.childern = globalFunc.findInArray(globalFunc.findInArray(segment.families, 'code', familyCode).classes, 'code', code).commodities;

    }
    // type Commodity
    else
      breadCrumb = previousBreadCrumb;

    return breadCrumb;
  }

  function loadItems(type, supplierCode, query, tab, code) {
    $scope.catalogList = [];
    $scope.meta = {};
    $scope.catalogDisplayHandler = 'showMessage';
    $scope.message = 'Loading items';
    catalogShoppingSearch.get(
      {
        user_id: $rootScope.currentUser._id,
        type: type,
        mb_supplier_code: !!supplierCode ? supplierCode : null,
        query: !!query ? query : null,
        group: !!tab ? tab : null,
        code: !!code ? code : null,
        groupIds: [groupIds]
      },
      function (resource) {
        $scope.showLoadingMessage = false;

        if (!!resource.content && !!resource.content.data) {
          $scope.catalogDisplayHandler = 'showItems';
          $scope.meta = resource.content.meta;
          $scope.catalogList = resource.content.data;
        } else {
          //TODO: handle the error
        }

        if (!$scope.catalogList || $scope.catalogList.length < 1) {
          $scope.catalogDisplayHandler = 'showMessage';
          $scope.message = 'There are no Items under the selected category at the moment!';
        }
      },
      function (error) {
        $scope.catalogDisplayHandler = '';
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function setDefaultQuantity() {
    for (var key in $scope.catalogList) {
      $scope.catalogList[key].quantityOrdered = 1;
    }
  }

  function goAdvancedSearch() {
    $state.go($scope.advancedSearchState);
}

  function plusItemQty(index, count) {
    if (!count || count < 1) {
      count = 0
    }

    count = parseInt(count) + 1;
    $scope.catalogList[index].quantityOrdered = count;
  }

  function minusItemQty(index, count) {
    if (!count || count < 1) {
      count = 2
    }

    count = parseInt(count) - 1;
    if (!count < 1) {
      $scope.catalogList[index].quantityOrdered = count;
    }
  }

  function addItemToCart(item, qty, isItemAddedFromPopUpModal) {
    if (!qty || qty < 1) {
      globalFunc.objectErrorMessage("Cannot be empty or negative value");
      return;
    }

    // check pr item limit
    if ($scope.itemLimit > 0 && !!$scope.cart && $scope.cart.length >= $scope.itemLimit) {
      globalFunc.objectErrorMessage("Cart item limit reached. Only " + $scope.itemLimit + " items allowed per cart");
      return;
    }

    var clonedItem = _.cloneDeep(item);
    clonedItem.quantityOrdered = qty;
    $scope.cart.push(clonedItem);
    catalogFunction.setCatalogCartItems($scope.cart);

    if(!isItemAddedFromPopUpModal) {
      $rootScope.$broadcast('catalogItemFromListAdded');
    } else {
      toastr.success('Item has been successfully added.');
    }
    refreshAddCartButton();
  }

  function refreshAddCartButton() {
    _.forEach($scope.catalogList, function (product) {
      if (checkItemInCart(product._id)) {
        product.selected = true;
      } else {
        product.selected = false;
      }
    })
  }

  function checkItemInCart(id) {
    var cartItems = $scope.cart;
    var exist = false;
    for (var x = 0; x < cartItems.length; x++) {
      if (cartItems[x]._id === id) {
        exist = true;
      }
    }
    return exist;
  }


  function searchCatalog(query) {
    $scope.searchActivated = true;
    $scope.selectedTab = 'search_results';
    $scope.paramsSource.code = $scope.selectedFilter.code;
    $scope.paramsSource.query = query;
    $scope.paramsSource.mb_supplier_code = null;

    if ($scope.selectedFilter.type === 'root')
      $scope.paramsSource.type = 'search';

    if ($scope.selectedFilter.type === 'tab') {
      $scope.paramsSource.type = $scope.selectedFilter.code;
      loadItems($scope.paramsSource.type, false, query, $scope.selectedFilter.code);
    } else {
      loadItems('search', false, query, false, $scope.selectedFilter.code);
    }
  }

  function changeCategoriesViewWidth() {
    $scope.disableScrollMenu = false;
    $(".standalone-catalog-item-view").css("width",
      "83%");
  }

  function initialize() {

    /*
      Get all user's catalog group
    */
    _.forEach($rootScope.currentUser.companies, function (company) {
      _.forEach(company.catalog_groups, function (group) {
        if (groupIds.indexOf(group._id) < 0) {
          groupIds.push(group._id);
        }
      })
    });

    $scope.selectedTab = 'latest';
    browseCatalog('latest', 'Latest Items', false, 'tab');
  }

  $scope.$watch('catalogList', function () {
    setDefaultQuantity();
    refreshAddCartButton();
  });

  $scope.$on('updateEmbeddedPagination', function (events, content) {
    if (!!content && !!content.meta)
      $scope.meta = content.meta;

    $anchorScroll();
  });

  $rootScope.$on('catalogCartItemDeleted', function () {
    refreshAddCartButton();
  });

  $rootScope.$on('addItemFromPopUpModal', function (events, item) {
    addItemToCart(item, 1, true);
  });

  $scope.browseCatalog = browseCatalog;
  $scope.setCategoriesView = setCategoriesView;
  $scope.plusItemQty = plusItemQty;
  $scope.minusItemQty = minusItemQty;
  $scope.addItemToCart = addItemToCart;
  $scope.selectFilter = selectFilter;
  $scope.searchCatalog = searchCatalog;
  $scope.changeCategoriesViewWidth = changeCategoriesViewWidth;

  var groupIds = [];
  $scope.disableScrollMenu = true;
  $scope.searchFilter = [];
  $scope.selectedFilter = {};
  $scope.catalogDisplayHandler = '';
  $scope.segments = [];
  $scope.categories = [];
  $scope.itemsService = catalogShoppingSearch;
  $scope.searchActivated = false;
  $scope.paramsSource = {
    user_id: $rootScope.currentUser._id,
    type: 'starred',
    offset: 20,
    groupIds: [groupIds]
  };
  $scope.cart = [];

  $scope.search = {
    title: ''
  };
  $scope.itemLimit = prConfig.pr_item_limit;

  initialize();

  $scope.$emit('pageTitleChanged', 'Catalog Categories');

}

catalogCategoriesCtrl.$inject = [
  '$scope', '$rootScope', 'globalFunc', 'metabuyerCache', 'catalogShoppingSearch', 'itemsCategories', 'catalogFunction',
  'prConfig', 'toastr', '$state'
];

angular
  .module('metabuyer')
  .controller('catalogCategoriesCtrl', catalogCategoriesCtrl);
