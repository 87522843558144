'use strict';

angular.module('metabuyer.services.expenseTypes', ['ngResource'])
  .factory('expenseTypeMasterDataList',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.expenseTypes.masterList,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'},
        'post': {method: 'POST'},
        'put': {method: 'PUT'}
      }
    );
  })
  .factory('expenseTypeSearch',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.expenseTypes.search,
      {
        query: '@query'
      },
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('expenseTypeUserList',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.expenseTypes.list,
      {},
      {
        ignoreLoadingBar: true,
        'get': {method: 'GET'}
      }
    );
  })
  .factory('companyExpenseTypes',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.companies.expenseTypes,
        {
          id: '@id',
          category: '@category'
        },
        {
          'get': { method: 'GET', ignoreLoadingBar: true }
        }
      );
    }
  );
