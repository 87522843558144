'use strict';

/**
 * @name diffVersion directive
 *
 * @description
 * to display diff between two value
 *
 * @copyright Sunway Metacloud &copy; 2016
 */

angular
  .module('metabuyer')
  .directive('diffVersion', function() {
    return {
      restrict: 'E',
      scope: {
        prevVal: '=',
        newVal: '=',
        filterDecimal: '=',
        lineBreak: '='
      },
      replace: true,
      templateUrl: 'components/diffVersion/diffVersion.html',
      link: function($scope) {

      }
    }
  });
