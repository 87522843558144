'use strict';
/**
 * @name SuppliersWebTncCtrl
 * @desc Controller for TnC controller
 */
function SuppliersWebTncCtrl($scope, $rootScope, globalFunc, toastr, $filter, pathConstants, supplierWebTnc) {

  $scope.back = back;
  $scope.saveTermsAndConditions = saveTermsAndConditions;
  $scope.cancelEditMode = cancelEditMode;

  function back() {
    window.history.back();
  }

  function cancelEditMode() {
    $scope.editClicked = false;
  }

  function saveTermsAndConditions() {
    var submitData = {};
    submitData.tnc = $scope.supplierWebTnc;

    swal(
      {
        title: 'Confirm update supplier web terms and conditions.',
        text: 'Updating the terms and conditions will require users to accept the new terms and conditions.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (!!isConfirm) {
          supplierWebTnc.put(
            {}, submitData,
            function (resource) {
              $scope.editClicked = false;
              toastr.success(
                (!!resource.content && !!resource.content.message && _.isString(resource.content.message))
                ? resource.content.message : 'Terms and Conditions is updated.'
              );
              $rootScope.currentUser.tenant.config.supplier_web_tnc = $scope.supplierWebTnc;
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
            }
          );
        }
      }
    );
  }

  function initialize(){
    $scope.supplierWebTnc = $rootScope.currentUser.tenant.config.supplier_web_tnc;
  }

  initialize();
}

SuppliersWebTncCtrl.$inject = [
  '$scope', '$rootScope', 'globalFunc', 'toastr', '$filter', 'pathConstants', 'supplierWebTnc'
];

angular
  .module('metabuyer')
  .controller('SuppliersWebTncCtrl', SuppliersWebTncCtrl);
