'use strict';

/**
 * @name budget services
 *
 * @description service for budget endpoint calls
 * This service is calling budget endpoints
 *
 * @requires $resource
 * @requires $filter
 * @requires pathConstants
 *
 * @authors Justin Cheong Tian Yee <justin.cty90@gmail.com>
 * @author H.W. Liew <liewhanwahwork@gmail.com>
 * @copyright 2017 Metacloud Sdn. Bhd.
 */

angular.module('metabuyer.services.budgets', ['ngResource'])
  .factory('budgets', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.budget.single,
      {
        id: '@id',
        action: '@action'
      },
      {
        'get': {method: 'GET'},
        'put': {method: 'PUT'},
        'delete': {method: 'DELETE'},
        'post': {method: 'POST'}
      }
    );
  })
  .factory('budgetCommentList',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.comments.listBudgetComment,
        {
          referenceId: '@referenceId'
        },
        {
          get: {method: 'GET', ignoreLoadingBar: true},
          post: {
            method: 'POST',
            params: {
              referenceId: '@referenceId'
            },
            url: pathConstants.apiUrls.comments.saveBudgetComment
          }
        }
      )
    })
  .factory('budgetList', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.budget.list,
      {
        action: '@action'
      },
      {
        'get': {method: 'GET', ignoreLoadingBar: true}
      }
    );
  })
  .factory('apportionAllocation', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.budget.apportionAllocation,
      {},
      {
        'post': {method: 'POST'}
      }
    );
  })
  .factory('nonPurchasingCost', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.budget.nonPurchasingCost,
      {},
      {
        'post': {method: 'POST'}
      }
    );
  })
  .factory('budgetItems', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.budget.getItems,
      {
        id: '@id',
        type: '@type'
      },
      {
        'get': {method: 'GET', ignoreLoadingBar: true}
      }
    )
  })
  .factory('glAccounts', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.budget.glAccounts,
      {},
      {
        'post': {method: 'POST'}
      }
    );
  })
  .factory('projectCodes', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.budget.projectCodes,
      {},
      {
        'post': {method: 'POST'}
      }
    );
  })
  .factory('budgetApprovalAction', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.approvals.status,
      {
        approval_id: '@approval_id',
        status: '@status',
        comment: '@comment'
      },
      {
        'post': {method: 'POST'}
      }
    );
  })
  .factory('glAccountsExport', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.budget.glAccountsExport,
      {
        budget_id: '@budget_id'
      },
      {
        'post': {method: 'POST'}
      }
    );
  })
  .factory('nonPurchasingCostExport', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.budget.nonPurchasingCostExport,
      {
        budget_id: '@budget_id'
      },
      {
        'post': {method: 'POST'}
      }
    );
  })
  .factory('projectCodesExport', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.budget.projectCodesExport,
      {
        budget_id: '@budget_id'
      },
      {
        'post': {method: 'POST'}
      }
    );
  })
  .factory('glCodesList', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.budget.glAccountsList,
      {
        budget_id: '@budget_id',
        company_code: '@company_code'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('revisionSummary', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.budget.revisionSummary,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('massUpdateBudgetOwner', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.budget.massUpdateBudgetOwner,
      {
        user_id: '@user_id',
        budget_ids: '@budget_ids'
      },
      {
        'post': {method: 'POST'}
      }
    );
  })
  .factory('resendPendingBudgetEmail', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.budget.resendPendingBudgetEmail,
      {
        id: '@id'
      },
      {
        'post': {method: 'POST'}
      }
    );
  });
