'use strict';

function purchaseRequisitionsItemListCtrl (
  $scope, prFunction, purchaseRequisitionsServices, globalFunc, pathConstants, toastr, lang,
  $rootScope, $http, $uibModal
) {

  // PR data used in local controller
  var localPRData = {};

  $scope.imageBasePath            = pathConstants.apiUrls.image.pr;
  $scope.minDateItem              = new Date();
  $scope.currentUser              = $rootScope.currentUser || {_id: null};
  $scope.accountCodes             = [];
  $scope.itemMainCheckBox         = {
    selected : false
  };
  $scope.itemOthersDetail         = [
    {code: 'needed_by_date', name: 'needed_by_date'},
    {code: 'delivery_address', name: 'delivery_address'},
    {code: 'note', name: 'note'},
    {code: 'account_code', name: 'account_code'},
    {code: 'freights', name: 'freight'},
    {code: 'transportation', name: 'transportation'},
    {code: 'branch', name: 'supplier_branch'},
    {code: 'supplier_contact_person', name:'supplier_contact_person'},
    {code: 'research', name:'research'},
    {code: 'special_event', name:'special_event'},
    {code: 'student_life_event', name:'student_life_event'}
  ];
  $scope.itemMainCheckBox = {};
  var fieldNameConversion = {
    qty: {
      name: 'qty',
      type: 'number'
    },
    price: {
      name: 'price',
      type: 'number'
    },
    discount: {
      name: 'discount',
      type: 'numberPercentage'
    },
    tax: {
      name: 'tax_id',
      type: 'string'
    },
    'supplier-contact-person': {
      name: 'contact_person',
      type: 'object'
    },
    'supplier-branch-contact-person': {
      name: 'branch',
      type: 'string'
    },
    'needed-by': {
      name: 'needed_by_date',
      type: 'string'
    },
    note: {
      name: 'note',
      type: 'string'
    },
    'delivery-address': {
      name: 'delivery_address_id',
      type: 'string'
    },
    'set-supplier-branch': {
      name: 'branch',
      type: 'string'
    },
    'unset-supplier-branch': {
      name: 'branch',
      type: 'string'
    },
    'account-code': {
      name: 'account_code_id',
      type: 'string'
    },
    'research': {
      name: 'research_id',
      type: 'string'
    },
    'special-event': {
      name: 'special_event_id',
      type: 'string'
    },
    'student-life-event': {
      name: 'student_life_event_id',
      type: 'string'
    },
    'split-po-responsive': {
      name: 'split',
      type: 'string'
    },
    freights: {
      amount: {
        name: 'amount',
        type: 'numberPercentage'
      },
      discount: {
        name: 'discount',
        type: 'numberPercentage'
      },
      tax_id: {
        name: 'tax_id',
        type: 'string'
      }
    },
    transportation: {
      amount: {
        name: 'amount',
        type: 'numberPercentage'
      },
      discount: {
        name: 'discount',
        type: 'numberPercentage'
      },
      tax_id: {
        name: 'tax_id',
        type: 'string'
      }
    }
  };

  $scope.getTemplateUrl = getTemplateUrl;
  $scope.PRData = PRData;
  $scope.differentCurrencyChecker = differentCurrencyChecker;
  $scope.setLoading = setLoading;
  $scope.checkLoading = checkLoading;
  $scope.updateItem = updateItem;
  $scope.removeItems = removeItems;
  $scope.duplicateItem = duplicateItem;
  $scope.addingDiscount = addingDiscount;
  $scope.addLumpSum = addLumpSum;
  $scope.updateLumpSum = updateLumpSum;
  $scope.addingLumpSum = addingLumpSum;
  $scope.addOthers = addOthers;
  $scope.checkOthersValue = checkOthersValue;
  $scope.isPRCreator = isPRCreator;
  $scope.allowEditingPR = allowEditingPR;
  $scope.openDatePicker = openDatePicker;
  $scope.updateNeededByDate = updateNeededByDate;
  $scope.removeOtherValue = removeOtherValue;
  $scope.getDeliveryAddresses = getDeliveryAddresses;
  $scope.onSelectDeliveryAddress = onSelectDeliveryAddress;
  $scope.getAccountCodes = getAccountCodes;
  $scope.onSelectSupplierContactPerson = onSelectSupplierContactPerson;
  $scope.onSelectBranchSupplierContactPerson = onSelectBranchSupplierContactPerson;
  $scope.onSelectAccountCode = onSelectAccountCode;
  $scope.onSelectResearch = onSelectResearch;
  $scope.onSelectSpecialEvent = onSelectSpecialEvent;
  $scope.onSelectStudentLifeEvent = onSelectStudentLifeEvent;
  $scope.itemMainCheckboxClicked = itemMainCheckboxClicked;
  $scope.updateMultiItems = updateMultiItems;
  $scope.addingFreightsTransport = addingFreightsTransport;
  $scope.updateFreightTransport = updateFreightTransport;
  $scope.removeFreightOrTransportation = removeFreightOrTransportation;
  $scope.removeSplit = removeSplit;
  $scope.removeTax = removeTax;
  $scope.showLumpSumButton = showLumpSumButton;
  $scope.updateItemTax = updateItemTax;
  $scope.taxList = getTaxData;
  $scope.canEditPR = canEditPR;
  $scope.supplierBranchModal = supplierBranchModal;
  $scope.onSelectSupplierBranch = onSelectSupplierBranch;
  $scope.updateDiscountInputFocus = updateDiscountInputFocus;
  $scope.Unchecked = Unchecked;
  $scope.accountCodeListing = accountCodeListing;
  $scope.checkItemExpiryDate = checkItemExpiryDate;
  $scope.checkAccountCodeSettings = checkAccountCodeSettings;
  $scope.onSelectLumpSumDiscountAccountCode = onSelectLumpSumDiscountAccountCode;
  $scope.removeLumpSumDiscount = removeLumpSumDiscount;
  $scope.integrationSolutionAllowedForLumpSum = integrationSolutionAllowedForLumpSum;
  $scope.addLumpSumDiscountAccountCode = addLumpSumDiscountAccountCode;
  $scope.removeLumpSumDiscountAccountCode = removeLumpSumDiscountAccountCode;
  $scope.checkLumpSumDiscountAccountCode = checkLumpSumDiscountAccountCode;
  $scope.checkLineDiscount = checkLineDiscount;
  $scope.checkCompanyIntegrationStatus = checkCompanyIntegrationStatus;
  $scope.hasCmmsIntegration = hasCmmsIntegration;
  $scope.canEditQuantity = canEditQuantity;
  $scope.canEditTax = canEditTax;
  $scope.disableAddLineItem = disableAddLineItem;

  function disableAddLineItem() {
    return prFunction.getDocumentRule('allow_to_add_line_item') === false;
  }

  function canEditTax() {
    return !hasCmmsIntegration();
  }

  function canEditQuantity() {
    return !hasCmmsIntegration();
  }

  function hasCmmsIntegration() {
    return prFunction.hasCmmsIntegration();
  }

  function supplierBranchModal(pr, index, branch) {
    var modalInstance = $uibModal.open({
      templateUrl: 'components/purchaseRequisitionComponents/branchPrListing/branchPrListing.html',
      controller: 'branchPrListingCtrl',
      backdrop: 'static',
      keyboard: false,
      scope: $scope,
      size: 'lg',
      resolve: {
        branchSelection: function ($q, supplierBranches) {
          var deferred = $q.defer();
          var params = {
            id : pr,
            mode : 'active'
          };
          supplierBranches.get(
            params,
            function (resource) {
              if (!!resource && !!resource.content) {
                deferred.resolve(resource.content);
              }
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        },
        onSelectSupplierBranch : function() {
          return $scope.onSelectSupplierBranch;
        },
        itemIndex : index,
        backupBranch : branch
      }
    });
  }

  function onSelectSupplierBranch(supplierBranch, itemIndex, backupBranch) {

      if (!!backupBranch) {
        backupBranch = null;
      }

      var pr = prFunction.getPRData();

      pr.pr_items[itemIndex].branch = supplierBranch;

      var payload = {
        supplier_id: pr.pr_items[itemIndex].supplier._id,
        branch_id: supplierBranch._id
      };

      var validateLumpSum = prFunction.validateLumpSum(pr.pr_items[itemIndex], itemIndex);

      if (!!pr.lump_sum_discount && !validateLumpSum.status) {
        swal({
          title: 'Confirm add supplier branch?',
          text: validateLumpSum.message,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true
        }, function (isConfirm) {
          if (isConfirm) {
            // remove lump sum
            prFunction.removeLumpSum(pr._id, function (resource) {
              if (!!resource)
                updateItem(itemIndex, 'set-supplier-branch', false, payload, '');
            })
          }
          else
            $scope.PRData().pr_items[itemIndex].branch = backupBranch;
        });
      }
      else {
        updateItem(itemIndex, 'set-supplier-branch', false, payload, '');
      }
      return true;
  }

  /**
   * function to handle the main check box, in case of selected or unselected
   * @param value
   */
  function itemMainCheckboxClicked(value){
    if(value){
      selectAllItems();
    }else{
      $scope.itemMainCheckBox.selected = false;
      unSelectAllItems();
    }
  }

  /**
   * makes all the items' checkboxes selected
   */
  function selectAllItems(){
    for (var i in $scope.PRData().pr_items){
      $scope.PRData().pr_items[i].selected = true;
    }
  }

  /**
   * makes all the items' checkboxes unselected
   */
  function unSelectAllItems(){
    for (var i in $scope.PRData().pr_items){
      $scope.PRData().pr_items[i].selected = false;
    }
  }

  /**
   * makes the main checkbox uncheck if one item unselected
   */
  function Unchecked(){
    $scope.itemMainCheckBox.selected = true;
    _.forEach ($scope.PRData().pr_items, function(item){
      if (item.selected === false){
        $scope.itemMainCheckBox.selected = false;
        return;
      }
    });

  }

  function getTemplateUrl(state) {
    if (!$rootScope.isMobileMode) {
      switch (state) {
        case 'editable':
          $scope.myTemplateUrl = 'components/purchaseRequisitionComponents/itemList/prItemList.EditableTemplate.html';
          break;
        case 'notEditable' :
          $scope.myTemplateUrl = 'components/purchaseRequisitionComponents/itemList/prItemList.NonEditableTemplate.html';
          break;
      }
    }

    if ($rootScope.isMobileMode) {
      $scope.myTemplateUrl = 'components/purchaseRequisitionComponents/itemList/prItemList.NonEditableTemplate-mobile.html';
    }
  }

  $scope.$watch('editingState', function (newValue) {
    getTemplateUrl(newValue);
  });

  /**
   * function to open date picker
   * @param $event
   * @param itemIndex
   */
  function openDatePicker($event, itemIndex) {
    $event.preventDefault();
    $event.stopPropagation();
    $scope.PRData().pr_items[itemIndex].datePicker = true;
  }

  /**
   * get delivery addresses from prFunction
   * shared by pr header
   * @returns {*}
   */
  function getDeliveryAddresses() {
    return prFunction.getCostCenterDeliveryAddresses();
  }

  /**
   * on selecting delivery address in line item
   * @param address
   * @param itemIndex
   * @param backupAddress
   */
  function onSelectDeliveryAddress(address, itemIndex, backupAddress) {
    var pr = prFunction.getPRData();
    pr.pr_items[itemIndex].delivery_address = address;
    var validateLumpSum = prFunction.validateLumpSum(pr.pr_items[itemIndex], itemIndex);

    if (!!pr.lump_sum_discount && !validateLumpSum.status) {
      swal({
        title: 'Confirm add delivery address?',
        text: validateLumpSum.message,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true
      }, function(isConfirm){
        if (isConfirm) {
          // remove lump sum
          prFunction.removeLumpSum(pr._id, function(resource) {
            if (!!resource)
              updateItem(itemIndex, 'delivery-address', false, address._id, '');
          })
        }
        else
          $scope.PRData().pr_items[itemIndex].delivery_address = backupAddress;
      });
    }
    else
      updateItem(itemIndex, 'delivery-address', false, address._id, '');
  }

  /**
   * Updating supplier contact person for item
   */
  function onSelectSupplierContactPerson(selectedContactPerson, itemIndex) {
    var pr = prFunction.getPRData();

    var backupContactPerson = pr.pr_items[itemIndex].supplier.contact_person;

    var validateLumpSum = prFunction.validateLumpSum(pr.pr_items[itemIndex], itemIndex);
    if (!!pr.lump_sum_discount && !validateLumpSum.status && pr.pr_items.length > 1) {
      swal({
        title: 'Confirm change supplier contact person? ',
        text: validateLumpSum.message,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true
    }, function(isConfirm){
      if (isConfirm) {
            // remove lump sum
        prFunction.removeLumpSum(pr._id, function(resource) {
          if (!!resource)
            updateItem(itemIndex, 'supplier-contact-person', false, selectedContactPerson, '');
        });
      }
        else
          updateItem(itemIndex, 'supplier-contact-person', false, backupContactPerson, '');
      });
    }
    else {
      updateItem(itemIndex, 'supplier-contact-person', false, selectedContactPerson, '');
    }
  }

  function onSelectBranchSupplierContactPerson(branch, itemIndex) {
      var pr = prFunction.getPRData();

      var backupBranchContactPerson = branch.contact_person;

      var validateLumpSum = prFunction.validateLumpSum(pr.pr_items[itemIndex], itemIndex);
      if (!!pr.lump_sum_discount && !validateLumpSum.status && pr.pr_items.length > 1) {
          swal({
              title: 'Confirm change supplier branch contact person? ',
              text: validateLumpSum.message,
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#DD6B55',
              confirmButtonText: 'Confirm',
              cancelButtonText: 'Cancel',
              closeOnConfirm: true
          }, function(isConfirm){
              if (isConfirm) {
                  // remove lump sum
                  prFunction.removeLumpSum(pr._id, function(resource) {
                      if (!!resource)
                          updateItem(itemIndex, 'supplier-branch-contact-person', false, branch.contact_person._id, '');
                  });
              }
              else
                  updateItem(itemIndex, 'supplier-branch-contact-person', false, backupBranchContactPerson._id, '');
          });
      }
      else {
          updateItem(itemIndex, 'supplier-branch-contact-person', false, branch, '');
      }
  }

  /**
   * updating account code
   */
  function onSelectAccountCode(selectedAccountCode, itemIndex) {
    updateItem(itemIndex, 'account-code', false, selectedAccountCode._id, '');
  }

  /**
   * updating research
   */
  function onSelectResearch(selectedResearch, itemIndex) {
    updateItem(itemIndex, 'research', false, selectedResearch._id, '');
  }

  /**
   * updating special event
   */
  function onSelectSpecialEvent(selectedSpecialEvent, itemIndex) {
    updateItem(itemIndex, 'special-event', false, selectedSpecialEvent._id, '');
  }

  /**
   * updating student life event
   */
  function onSelectStudentLifeEvent(selectedStudentLifeEvent, itemIndex) {
    updateItem(itemIndex, 'student-life-event', false, selectedStudentLifeEvent._id, '');
  }

  /**
   * Update needed by date value
   * @param itemIndex
   * @param date
   * @param dateBackup
   */
  function updateNeededByDate(itemIndex, date, dateBackup) {
    var milliTime = globalFunc.convertDateToTimestamp(date),
        milliTimeBackup = globalFunc.convertDateToTimestamp(dateBackup);
    updateItem(itemIndex, 'needed-by', false, milliTime, milliTimeBackup);
  }

  /**
   * check if the user is PRCreator
   * @returns {boolean}
   */
  function isPRCreator() {
    return ($scope.currentUser._id === $scope.PRData().creator_info._id);
  }

  /**
   * Check if current approver is a PA
   * @returns {*}
   */
  function allowEditingPR() {
    if (prFunction.getPRData().status === 'pending') {
      var isProcurementSpecialist = prFunction.isProcurementSpecialist(
        !!$rootScope.currentUser ? $rootScope.currentUser.role_assignments : null
      );

      var isPA = prFunction.checkCurrentApproverIsPA(
        !!prFunction.getPRData() ? prFunction.getPRData().waiting_on : null,
        !!$scope.currentUser  ? $scope.currentUser._id : null,
        !!$scope.currentUser  ? $scope.currentUser.role_assignments : null
      );

      return isPA || isProcurementSpecialist;
    }

    return false
  }

  function removeOtherValue(item, field, itemIndex) {
    var tempFieldName = field;

    if (field === 'needed-by')
      tempFieldName = 'needed_by_date';
    if (field === 'account-code')
      tempFieldName = 'account_code';
    if (field === 'branch')
      tempFieldName = 'branch';

    if (!!item.selecting_others && !!item.selecting_others.length) {
      var arrayIndex = item.selecting_others.indexOf(tempFieldName);
      if (arrayIndex > -1)
        item.selecting_others.splice(arrayIndex, 1);
    }

    if (!!item[tempFieldName]) {
      if (field === 'student_life_event')
        field = 'student-life-event';
      if (field === 'special_event')
        field = 'special-event';
      if (field === 'delivery_address')
        field = 'delivery-address';
      if (field === 'branch')
        field = 'unset-supplier-branch';


      updateItem(itemIndex, field, false, null, item[tempFieldName]);
    }
  }

  function removeTax(itemIndex, othersType){
    updateFreightTransport(itemIndex, othersType, 'tax', 'tax_id', {_id:null});
  }

  function removeFreightOrTransportation(field, itemIndex) {

    var item = $scope.PRData().pr_items[itemIndex];
    var param = {};
    var backupField = 'backup_' + field;
    var backupValue = item[field][backupField];

    param.amount = null;
    param.discount = null;
    param.tax_id = null;

    sendingUpdateItem (itemIndex, item, field, param, backupValue);
  }

  function removeSplit (item, itemIndex) {
    deleteItemField(itemIndex, item, 'split-po-responsive');
  }

  /**
   * To get PR Data from service
   * and store the data also in localPRData
   * @returns {{}}
   */
  function PRData() {
    localPRData = prFunction.getPRData();
    return localPRData;
  }

  /**
   * adding state on adding others value
   * set an array called selecting_others
   * and push the field value to the array
   * @param field
   * @param itemIndex
   */
  function addOthers(field, itemIndex) {
    var pr = $scope.PRData();
    var item = pr.pr_items[itemIndex];

    if (!item.selecting_others)
      item.selecting_others = [];

    if (field === 'split') {
      validateOnAddingSplit(item, itemIndex);
    }

    var validateAddOthers = validateAddOthersWithLumpSum(field);

    if (!!pr.lump_sum_discount && validateAddOthers.status && pr.pr_items.length > 1) {
      swal({
        title: 'Confirm addition? ',
        text: validateAddOthers.message,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true
      }, function (isConfirm) {
        if (isConfirm) {
          // remove lump sum
          prFunction.removeLumpSum(pr._id, function (resource) {
            if (!!resource) {
              if (item.selecting_others.indexOf(field) === -1)
                item.selecting_others.push(field);

              localPRData.pr_items[itemIndex] = item;
              prFunction.setPRData(localPRData);
              $scope.PRData().pr_items[itemIndex] = item;
            }
          });
        }
      });
    }
    else {
      if (item.selecting_others.indexOf(field) === -1) {
        item.selecting_others.push(field);
      }

      if (field === 'supplier_contact_person') {
        // This is for the UI to show the contact person selection box
        localPRData.pr_items[itemIndex].supplier.contact_person = true
      }
      localPRData.pr_items[itemIndex] = item;
      prFunction.setPRData(localPRData);
      $scope.PRData().pr_items[itemIndex] = item;
    }
  }

  function validateAddOthersWithLumpSum(field) {
    if (field === 'branch') {
      return {
        status: true,
        message: 'The lump sum discount will be removed, due to having different supplier branch'
      };
    }
    else if (field === 'delivery_address') {
      return {
        status: true,
        message: 'The lump sum discount will be removed, due to having different delivery address'
      };
    }
    else {
      return {status: false};
    }
  }

  function validateOnAddingSplit(item, itemIndex) {
    if (!!item.freights.amount || !!item.transportation.amount || !!item.account_code) {
      swal({
        title: 'Confirm add split?',
        text: 'Adding split will remove freight, transportation and account code from line item.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true
      }, function (isConfirm) {
        if (!!isConfirm) {
          item.selecting_others.push('split');
        }
        else {
          removeSplit(item, itemIndex);
        }
      });
    }
    else {
      item.selecting_others.push('split');
    }
  }

  function updateItemTax(itemIndex, field, isDecimal, newValue, backupValue) {
    var pr = prFunction.getPRData();
    pr.pr_items[itemIndex].tax = newValue;
    var validateLumpSum = prFunction.validateLumpSum(pr.pr_items[itemIndex], itemIndex);

    if (!!pr.lump_sum_discount && !validateLumpSum.status) {
      swal({
        title: 'Confirm update tax?',
        text: validateLumpSum.message,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true
      }, function(isConfirm){
        if (isConfirm) {
          // remove lump sum
          prFunction.removeLumpSum(pr._id, function(resource) {
            if (!!resource)
              updateItem(itemIndex, field, isDecimal, newValue, backupValue);
              $rootScope.$broadcast('checkIntegrationData');
          })
        }
        // change back to the prev tax
        else
          $scope.PRData().pr_items[itemIndex].tax = backupValue;
          $rootScope.$broadcast('checkIntegrationData');
      });
    }
    else
      updateItem(itemIndex, field, isDecimal, newValue, backupValue);
      $rootScope.$broadcast('checkIntegrationData');
  }

  /**
   * Check if the others field in editing state
   * @param item
   * @param field
   * @returns {boolean}
   */
  function checkOthersValue(item, field) {
    // Hide split if has lump sum then hiding freights, transportation and account code from the options
    if (!!$scope.PRData().lump_sum_discount && field === 'split') {
      return true;
    } else if ((field === 'freights' || field === 'transportation' || field === 'account_code') &&
      (!!item.split)) {
      return true;
    }
    else if (field === 'supplier_contact_person') {
      return !!item.supplier.contact_person;
    }
    else if (field === 'freights' || field === 'transportation')
      return (item[field].amount > 0 ||
        (!!item.selecting_others && !!item.selecting_others.length && item.selecting_others.indexOf(field) > -1));
    else if (!!item[field])
      return (!!item[field]);
    else if (!!item.selecting_others && !!item.selecting_others.length)
      return (item.selecting_others.indexOf(field) > -1);
  }

  function checkAccountCodeSettings() {
    var pr = prFunction.getPRData();

    return (!!pr.company.requisition_settings.account_code &&
      !!pr.company.requisition_settings.account_code.is_mandatory);
  }

  /**
   * adding lumpsum state
   * to indicate user is adding or editing lumpsum
   * @param value
   */
  function addLumpSum(value) {
    localPRData.lump_sum_discount = value;
    localPRData.lump_sum_discount_entry = value;
    prFunction.setPRData(localPRData);
  }

  /**
   * Update lump sum value
   * @param value
   * @param backupValue
   */
  function updateLumpSum(value, backupValue) {
    var param = {};

    if (value == backupValue)
      return;

    if (value !== null) {
      var validateValue = lineItemValidation(value, 'numberPercentage', true);
      if (!!validateValue) {
        setLineItemBackupValue(false, 'lump_sum_discount', false, backupValue, false);
        return false;
      }

      var convertValToStr = value.toString();
      if (convertValToStr.indexOf('%') > -1)
        param.lump_sum_percent = Number(convertValToStr.replace('%', ''));
      else
        param.lump_sum_net = Number(value);
    }

    //check if items currency is different from PR currency
      if (differentCurrencyChecker(localPRData.pr_items[0].currency.code)) {
          param.lump_sum_item_currency = localPRData.pr_items[0].currency;
      }

    prFunction.setLoading('lineItemTable', true);
    prFunction.setLoading('updatePRField', true);
    purchaseRequisitionsServices.setLumpSum({
      id: localPRData._id
    }, param, function(resource) {
      if (!!resource && !!resource.content && !!resource.content.data)
        prFunction.setPRData(resource.content.data);

      prFunction.setLoading('lineItemTable', false);
    }, function(error) {
      setLineItemBackupValue(false, 'lump_sum_discount', false, backupValue, false);
      prFunction.setLoading('lineItemTable', false);
      globalFunc.objectErrorMessage(error);
    });
  }

  /**
   * Check param currency code with pr currency code
   * @param currencyCode
   * @returns {boolean}
   */
  function differentCurrencyChecker(currencyCode) {
    return (localPRData.currency.code !== currencyCode);
  }

  /**
   * Set loading value in prFunction service
   * @param loadingObject
   * @param loadingValue
   */
  function setLoading(loadingObject, loadingValue) {
    prFunction.setLoading(loadingObject, loadingValue);
  }

  /**
   * get loading value from prFunction service
   * @param loadingObject
   * @returns {*|{}}
   */
  function checkLoading(loadingObject) {
    return prFunction.getLoading(loadingObject);
  }

  function addingDiscount(itemIndex) {
    // Update data
    PRData();
    localPRData.pr_items[itemIndex].addingDiscount = true;
    prFunction.setPRData(localPRData);
  }

  /**
   * Check if item allow line discount
   * @returns {boolean}
   */
  function checkLineDiscount() {
    return prFunction.getDocumentRule('allow_line_discount') !== false;
  }

  /**
   * Updating the discount input field to blur
   */
  function updateDiscountInputFocus(index) {
    angular.element('#discountInput' + index).blur();
  }

  /**
   * state on adding freight or transportation
   * @param itemIndex
   * @param type
   * @param state
   */
  function addingFreightsTransport(itemIndex, type, state) {
    // Update data
    PRData();
    localPRData.pr_items[itemIndex][type][state] = true;
    prFunction.setPRData(localPRData);
  }

  function addingLumpSum() {
    // Update data
    PRData();
    localPRData.addingLumpSum = true;
    prFunction.setPRData(localPRData);
  }

  /**
   * Duplicating line item
   * @param item
   */
  function duplicateItem(item) {
    var pr = prFunction.getPRData();
    if ($scope.pritemlimit > 0 && !!$scope.pr.pr_items && $scope.pr.pr_items.length >= $scope.pritemlimit) {
      globalFunc.objectErrorMessage("PR line item limit reached. Only " + $scope.pritemlimit + " line items allowed per cart");
      return;
    }

    prFunction.setLoading('lineItemTable', true);
    prFunction.setLoading('updatePRField', true);

    if (!!item) {
      var itemId = item._id;
      purchaseRequisitionsServices.setItem({
        id: itemId,
        action: 'duplicate'
      }, function(resource) {
        if (!!resource && !!resource.content && !!resource.content.data)
          prFunction.setPRData(resource.content.data);

        prFunction.setLoading('lineItemTable', false);
      }, function(error) {
        prFunction.setLoading('lineItemTable', false);
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * Remove item(s) by id, the function receives an array
   * @param arr
   */
  function removeItems(arr) {
    if (!!arr && _.isArray(arr) && arr.length > 0) {
      var item = arr[arr.length - 1];
      if (!!item) {
        prFunction.setLoading('lineItemTable', true);
        prFunction.setLoading('updatePRField', true);
        prFunction.deleteLineItem(item._id).$promise.then(function (resource) {
          if (!!resource.content && !!resource.content.data) {
            prFunction.setPRData(resource.content.data);
            $rootScope.$broadcast('checkIntegrationData');
          }
          else {
            globalFunc.objectErrorMessage('There was an error in removing items.');
            return;
          }
          arr.pop();
          removeItems(arr);
        }, function (error) {
          globalFunc.objectErrorMessage(error);
        });
      }
      else {
        prFunction.setLoading('lineItemTable', false);
        prFunction.setLoading('updatePRField', false);
        $scope.itemMainCheckBox.selected = false;
      }
    } else {
      prFunction.setLoading('lineItemTable', false);
      prFunction.setLoading('updatePRField', false);
      $scope.itemMainCheckBox.selected = false;
    }
  }

  /**
   * Update Freight Item
   * @param itemIndex
   * @param othersType (freights or transportation)
   * @param fieldEntry - Field name for entry is using 'entry'
   * @param field
   * @param tax
   */
  function updateFreightTransport(itemIndex, othersType, fieldEntry, field, tax) {
    var item = $scope.PRData().pr_items[itemIndex];
    var param = {};
    var backupField = 'backup_' + othersType;
    var newValue = item[othersType][fieldEntry];
    var backupValue = item[backupField][fieldEntry];

    if (!!tax)
      newValue = tax._id;

    // validate input
    if (newValue == backupValue)
      return;

    if (!!lineItemValidation(newValue, fieldNameConversion[othersType][field].type, false)) {
      setLineItemBackupValue(item, field, itemIndex, backupValue, false);
      return false;
    }

    // send all the information (amount, discount, tax) in one request
    var amount_entry = item[othersType].entry.toString();
    if (amount_entry.indexOf('%') > -1)
      param.amount_percentage = Number(amount_entry.replace('%', ''));
    else
      param.amount = Number(amount_entry);

    param.discount = null;
    if (!!item[othersType].discount_entry) {
      var discount_entry = item[othersType].discount_entry.toString();
      if (discount_entry.indexOf('%') > -1)
        param.discount_percentage = Number(discount_entry.replace('%', ''));
      else
        param.discount = Number(discount_entry);
    }

    if (!!tax)
      param.tax_id = tax._id;
    else
      param.tax_id = item[othersType].tax_id;

    sendingUpdateItem (itemIndex, item, othersType, param, backupValue);
  }

  /**
   * Update Item value (QTY, discount, tax)
   * @param itemIndex
   * @param field
   * @param isDecimal
   * @param newValue
   * @param backupValue
   */
  function updateItem(itemIndex, field, isDecimal, newValue, backupValue) {
    var item = localPRData.pr_items[itemIndex];
    var fieldToSend = field;
    var param = {};
    var zeroAllowed = field === 'price';
    var fieldsThatAffectSplit = [
      'qty',
      'price',
      'tax',
      'discount',
      'freight',
      'transportation'
    ];

    if (newValue == backupValue)
      return;

    if (!!lineItemValidation(newValue, fieldNameConversion[field].type, isDecimal, zeroAllowed)) {
      setLineItemBackupValue(item, field, itemIndex, backupValue, false);
      return false;
    }

    if (!!item.split && !!item.split.cost_centers && field !== 'split-po-responsive' &&
      (fieldsThatAffectSplit.indexOf(field) > -1)) {
      poSplitValidation(item, itemIndex, field, newValue, backupValue);
      return false;
    }

    if (field in fieldNameConversion)
      fieldToSend = fieldNameConversion[field].name;

    param[fieldToSend] = newValue;

    if (field === 'tax')
      param[fieldToSend] = newValue._id;

    // set is_percentage for discount
    if (field === 'discount') {
      newValue = newValue.toString();
      param.is_percentage = (newValue.indexOf('%') > -1);
      param[fieldToSend] = Number(newValue.replace('%', ''));
    }

    if (field === 'freight' || field === 'transportation') {
      newValue = newValue.toString();
      if (!!param[fieldToSend])
        delete param[fieldToSend];
      if (newValue.indexOf('%') > -1)
        param.amount_percentage = Number(newValue.replace('%', ''));
      else
        param.amount = Number(newValue);
    }

    sendingUpdateItem (itemIndex, item, field, param, backupValue);
  }

  /**
   * Sending updateItem request
   * @param itemIndex
   * @param item
   * @param field
   * @param param
   * @param backupValue
   */
  function sendingUpdateItem(itemIndex, item, field, param, backupValue) {
    if (!!item) {
      prFunction.setLoading('lineItemTable', true);
      prFunction.setLoading('updatePRField', true);
      purchaseRequisitionsServices.setItem({
        id: item._id,
        action: field
      }, param, function(resource) {
        if (!!resource && !!resource.content && !!resource.content.data)
          prFunction.setPRData(resource.content.data);

        // Set backup value
        prFunction.setLoading('lineItemTable', false);
      }, function(error) {
        setLineItemBackupValue(item, field, itemIndex, backupValue, false);
        prFunction.setLoading('lineItemTable', false);
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * Sending deleteItem request
   * @param itemIndex
   * @param item
   * @param field
   */
  function deleteItemField(itemIndex, item, field) {
    if (!!item) {
      prFunction.setLoading('lineItemTable', true);
      prFunction.setLoading('updatePRField', true);
      purchaseRequisitionsServices.deleteItemField({
        id: item._id,
        action: field
      }, function(resource) {
        if (!!resource && !!resource.content && !!resource.content.data)
          prFunction.setPRData(resource.content.data);

        // Set backup value
        prFunction.setLoading('lineItemTable', false);
      }, function(error) {
        prFunction.setLoading('lineItemTable', false);
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * updating item for mass edit
   * using recursive function to send the request one by one
   *
   * @param items
   * @param field
   * @param newValue
   * @param itemIndex
   *
   * @returns {string}
   */
  function updateMultiItems(items, field, newValue, itemIndex){

    var fieldToSend = field;
    var param = {};
    var backupValue = '';
    var index = itemIndex || 0;
    var verified = true;
    var item = items[index];

    if (lineItemValidation(newValue, fieldNameConversion[field].type, item.UOM.is_fraction)) {
      verified = false;
      return 'error';
    }

    if (field in fieldNameConversion)
      fieldToSend = fieldNameConversion[field].name;

    param[fieldToSend] = newValue;

    if (field === 'tax')
      param[fieldToSend] = newValue._id;

    if (field === 'discount') {
      if (!!newValue) {
        newValue = newValue.toString();
        param.is_percentage = (newValue.indexOf('%') > -1);
        param[fieldToSend] = Number(newValue.replace('%', ''));

        if(newValue > item.amount){
          globalFunc.objectErrorMessage('The discount cannot be more than the Total amount');
          verified = false;
          prFunction.setLoading('lineItemTable', false);
          return 'error';
        }
      } else if (newValue === 0) {
        param.is_percentage = false;
        param[fieldToSend] = 0;
      }
    }

    if(verified) {
      prFunction.setLoading('lineItemTable', true);
      prFunction.setLoading('updatePRField', true);
      purchaseRequisitionsServices.setItem({
        id: item._id,
        action: field
      }, param, function(resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          prFunction.setPRData(resource.content.data);
          addSelectedStatus(items, resource.content.data);
        }

        // go to the next item index
        // and validate if the next index is the last index
        // if not the last, call the updateMultiItems with the new index
        index++;
        if (!!items && items.length !== index)
          updateMultiItems(items, field, newValue, index);
        else
          prFunction.setLoading('lineItemTable', false);
      }, function(error) {
        setLineItemBackupValue(item, field, itemIndex, backupValue, false);
        prFunction.setLoading('lineItemTable', false);
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * re-added selected value to the resource data
   * @param prevPRItem
   * @param newPR
   * @returns {*}
   */
  function addSelectedStatus(prevPRItem, newPR) {
    _.forEach(newPR.pr_items, function (item) {
      var checkPrevPR = globalFunc.findInArray(prevPRItem, '_id', item._id);
      if (!!checkPrevPR && !!checkPrevPR.selected)
        item.selected = checkPrevPR.selected;
    });
    return newPR;
  }

  /**
   * Set or Restore the item backup value
   * @param item
   * @param field
   * @param itemIndex
   * @param backupValue
   * @param setValue
   * boolean value to set or to restore
   */
  function setLineItemBackupValue(item, field, itemIndex, backupValue, setValue) {

    // replace the value with backupValue
    var newFieldName = field;
    if (!!setValue)
      newFieldName = 'backup_' + field;

    if (!!item) {
      item[newFieldName] = backupValue;

      if (newFieldName === 'price')
        item.unit_price = backupValue;

      if (newFieldName === 'discount')
        item.discount_entry = backupValue;

      if (newFieldName === 'freights' || newFieldName === 'transportation') {
        var backupField = 'backup_' + newFieldName;
        item[newFieldName] = item[backupField];
      }

      $scope.PRData().pr_items[itemIndex] = item;
    } else {
      $scope.PRData()[newFieldName] = backupValue;
    }
    prFunction.setPRData($scope.PRData());
  }

  /**
   * This validation can be used in general cases
   * todo: can be moved to general function (later)
   * Validate value based on the type
   * and calling function based on the type
   * @param value
   * @param type
   * @param isDecimal
   * @param zeroAllowed
   */
  function lineItemValidation(value, type, isDecimal, zeroAllowed) {
    var message = "";

    if (type === 'number')
      message = numberValidation(isDecimal, value, zeroAllowed);

    if (type === 'numberPercentage')
      message = percentageValidation(value);

    if (!!message)
      toastr.error(message);

    return !!message;
  }

  /**
   * Validate if split information will be removed
   *
   * @param {object} item
   * @param {number} itemIndex
   * @param {string} field
   * @param {*} newValue
   * @param {*} backupValue
   */
  function poSplitValidation(item, itemIndex, field, newValue, backupValue) {
    swal({
        title: 'Confirm update ' + field + '?',
        text: 'This will update existing split information.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true
      },
      function (isConfirm) {
        var fieldToSend = field;
        var param = {};

        if (field in fieldNameConversion) {
          fieldToSend = fieldNameConversion[field].name;
        }

        if (!!isConfirm) {
          if (field === 'tax') {
            param['tax_id'] = newValue._id;
          }
          else {
            param[fieldToSend] = newValue;
          }
          sendingUpdateItem(itemIndex, item, field, param, backupValue);
        }
        else {
          // TODO: need to have field name translation for fail update and backup
          if (field === 'price')
            field = 'unit_price';

          $scope.PRData().pr_items[itemIndex][field] = backupValue;
          toastr.info('Successfully cancelled.');
        }
      });
  }

  /**
   * listing the account codes
   * @param val
   */
  function accountCodeListing(val) {
    if (!!val && val.length > 2) {
      $scope.loading = true;

      return $http.get(pathConstants.apiUrls.accountCode.prList, {
        params: {
          query: val,
          company_code: $scope.PRData().company.code,
          cost_center_id: $scope.PRData().cost_center._id,
          offset: 100
        }
      }).then(function (response) {
        $scope.loading = false;
        if (!!response.data && !!response.data.content && !!response.data.content.data) {
          return response.data.content.data.map(function (item) {
            $scope.loading = false;
            return item;
          });
        } else {
          return null;
        }
      });
    }
  }

  function percentageValidation(entry) {
    if (!!entry) {
      entry = entry.toString();
      var percentageCount = (entry.match(/%/g) || []).length;
      var dotCount = (entry.match(/\./g) || []).length;

      if (dotCount === 1) {
        var decimalLimit = 4;

        if (percentageCount === 1) {
          decimalLimit++;
        }

        var decimal = entry.split(".")[1].length;
        if (decimal > decimalLimit) {
          return "Invalid decimal point."
        }
      }

      if (percentageCount > 1 ||
        (percentageCount === 1 && entry.length <= 1) ||
        (percentageCount === 1 && entry.indexOf("%") !== entry.length - 1) ||
        (dotCount > 1) ||
        (dotCount > 0 && entry.indexOf(".") == 0)
      ) {
        return lang.validation.percentage.invalid;
      }

      return false;
    }
  }

  /**
   * Number validation
   * - Positive number only
   * - check if valid Number
   * - check decimal value if isDecimal value is true
   * @param isDecimal
   * @param value
   * @param zeroAllowed
   */
  function numberValidation(isDecimal, value, zeroAllowed) {
    var validFraction = fractionValidation(value);

    if (isNaN(value))
      return lang.validation.number.isNumber;
    if(zeroAllowed) {
      if (!(value >= 0))
        return lang.validation.number.positive;
    }else{
      if (!(value > 0))
        return lang.validation.number.positive;
    }
    if (!isDecimal && !!validFraction)
      return lang.validation.number.noFraction;

    return false;
  }

  function getAccountCodes() {
    return prFunction.getAccountCodes();
  }

  /**
   * Decimal value validation
   * @param value
   */
  function fractionValidation(value) {
    return Number(value) % 1 !== 0;
  }

  function checkIfPrHasSplit() {
    var hasSplit = false;
    _.forEach($scope.PRData().pr_items, function(item){
      if (!!item.split) {
        hasSplit = true;
      } else if (!!item.selecting_others) {
        if (item.selecting_others.indexOf('split') > -1) {
          hasSplit = true;
        }
      }
    });

    return hasSplit;
  }

  function showLumpSumButton() {
    if(!prFunction.getDocumentRule('allow_lump_sum_discount')) {
      return false;
    }

    if (checkIfPrHasSplit()) {
      return false;
    }

    if (!!prFunction.getPRData().budget && !!prFunction.getPRData().budget.has_item_breakdown) {
      return false;
    }

    var validateLumpSum = prFunction.validateLumpSum(null, -1);
    return validateLumpSum.status;
  }

  function integrationSolutionAllowedForLumpSum() {
    var integrationSolution = prFunction.getIntegrationSolution();

    var allowedSolution = ['MB-JDE-JDE'];
    return allowedSolution.indexOf(integrationSolution) > -1;
  }

  function getTaxData() {
    return prFunction.getTaxData();
  }

  function canEditPR() {
    return prFunction.getPRData().status === 'edit';
  }

  $scope.$on('PRDataUpdated', function(){
    PRData();
  });

  function checkItemExpiryDate(expiryDate) {
    if (!(typeof expiryDate === 'number')) {
      expiryDate = Number(expiryDate);
    }

    var itemExpiryDate = moment(expiryDate);
    var currentDate = moment();
    var duration = moment.duration(itemExpiryDate.diff(currentDate));

    if(duration.asDays() <= 14)
      return true;
    else
      return false;
  }

  function checkLumpSumDiscountAccountCode() {
    var pr = prFunction.getPRData();

    return (!!pr.company.requisition_settings.lump_sum_discount_account_code &&
      pr.company.requisition_settings.lump_sum_discount_account_code.is_allowed)
  }

  function onSelectLumpSumDiscountAccountCode(selectedAccountCode) {
    prFunction.setLoading('lineItemTable', true);
    var param = {};
    param.account_code = selectedAccountCode._id;
    purchaseRequisitionsServices.setLumpSumAccountCode({
      id: localPRData._id
    }, param, function(resource) {
      if (!!resource && !!resource.content && !!resource.content.data) {
        prFunction.setLoading('lineItemTable', false);
        prFunction.setPRData(resource.content.data);
      }
    }, function(error) {
      prFunction.setLoading('lineItemTable', false);
      prFunction.setPRData($scope.pr);
      globalFunc.objectErrorMessage(error);
    });
  }

  function removeLumpSumDiscountAccountCode() {
    prFunction.setLoading('lineItemTable', true);
    var param = {};
    param.account_code = null;
    purchaseRequisitionsServices.setLumpSumAccountCode({
      id: localPRData._id
    }, param, function(resource) {
      if (!!resource && !!resource.content && !!resource.content.data) {
        prFunction.setLoading('lineItemTable', false);
        prFunction.setPRData(resource.content.data);
        $scope.add_lump_sum_discount_account_code = false;
      }
    }, function(error) {
      prFunction.setLoading('lineItemTable', false);
      prFunction.setPRData($scope.pr);
      globalFunc.objectErrorMessage(error);
    });
  }

  function removeLumpSumDiscount() {
    prFunction.setLoading('lineItemTable', true);
    purchaseRequisitionsServices.removeLumpSum({
      id: localPRData._id
    }, function(resource) {
      if (!!resource && !!resource.content && !!resource.content.data) {
        prFunction.setLoading('lineItemTable', false);
        prFunction.setPRData(resource.content.data);
        $scope.add_lump_sum_discount_account_code = false;
      }
    }, function(error) {
      prFunction.setLoading('lineItemTable', false);
      prFunction.setPRData($scope.pr);
      globalFunc.objectErrorMessage(error);
    });
  }

  function addLumpSumDiscountAccountCode() {
    $scope.add_lump_sum_discount_account_code = true;
  }

  function getSelectedItem() {
    var selectedItem = [];
    _.forEach ($scope.PRData().pr_items, function(item){
      if (item.selected === true){
        selectedItem.push(item);
      }
    });
    return selectedItem;
  }

  function checkCompanyIntegrationStatus() {
    return prFunction.getIntegrationStatus();
  }

  function populateListing(field) {
    if (field === 'research') {
      purchaseRequisitionsServices.getResearch(
        {
          company_code: $scope.PRData().company.code
        },
        function (resource) {
          if (!!resource && !!resource.data) {
            $scope.researchListing = resource.data;
          }
        }
      );
    } else if (field === 'student_life_event') {
      purchaseRequisitionsServices.getStudentLifeEvent(
        {
          company_code: $scope.PRData().company.code
        },
        function (resource) {
          if (!!resource && !!resource.data) {
            $scope.studentLifeEventListing = resource.data;
          }
        }
      );

    } else if (field === 'special_event') {
      purchaseRequisitionsServices.getSpecialEvent(
        {
          company_code: $scope.PRData().company.code
        },
        function (resource) {
          if (!!resource && !!resource.data) {
            $scope.specialEventListing = resource.data;
          }
        }
      );
    }
  }

  function initialize() {
    $scope.dateOptions = {
        formatYear: 'yy',
        minDate: new Date(),
        startingDay: 1
    };
    $scope.datePickerTimezone = $rootScope.datePickerTimezone;

    if (!!$scope.PRData().company && $scope.PRData().company.config && $scope.PRData().company.config.split_po) {
      $scope.itemOthersDetail.push({code: 'split', name: 'split'});
    }

    // Remove freights and transport if it is disabled in company settings
    if (!!$scope.PRData().company && !!$scope.PRData().company.requisition_settings &&
      !!$scope.PRData().company.requisition_settings.freight_transportation &&
      !!$scope.PRData().company.requisition_settings.freight_transportation.is_disabled &&
      $scope.PRData().company.requisition_settings.freight_transportation.is_disabled === true) {
      $scope.itemOthersDetail = $scope.itemOthersDetail.filter(function(option){
        if (option.code !== 'freights' && option.code !== 'transportation') {
          return option;
        }
      });
    }

    // Remove supplier branch option if company has CMMS integration
    if (hasCmmsIntegration()) {
      $scope.itemOthersDetail = $scope.itemOthersDetail.filter(function(option){
        if (option.code !== 'branch') {
          return option;
        }
      });
    }

    if (prFunction.getIntegrationSolution() !== 'MB-SAGE-ESKER') {
      $scope.itemOthersDetail = $scope.itemOthersDetail.filter(function(option){
        if (option.code !== 'research') {
          return option;
        }
      });
      $scope.itemOthersDetail = $scope.itemOthersDetail.filter(function(option){
        if (option.code !== 'special_event') {
          return option;
        }
      });
      $scope.itemOthersDetail = $scope.itemOthersDetail.filter(function(option){
        if (option.code !== 'student_life_event') {
          return option;
        }
      });
    }

    if (prFunction.getIntegrationSolution() === 'MB-SAGE-ESKER') {
      populateListing('research');
      populateListing('student_life_event');
      populateListing('special_event');
    }

    if (!prFunction.getPRData() || !prFunction.getPRData()._id)
      prFunction.setPRData($scope.pr);

    $scope.add_lump_sum_discount_account_code = !!$scope.pr.lump_sum_discount_account_code;
    $scope.allowLineDiscount = checkLineDiscount();
  }

  initialize();

}

purchaseRequisitionsItemListCtrl.$inject = [
  '$scope', 'prFunction', 'purchaseRequisitionsServices', 'globalFunc', 'pathConstants', 'toastr', 'lang', '$rootScope',
  '$http', '$uibModal'
];

angular
  .module('metabuyer')
  .directive('prItemList',function() {
    return {
      restrict: 'E',
      scope: {
        editingState: '=',
        pr: '=',
        pritemlimit: '=?'
      },
      template: '<div ng-include="myTemplateUrl"></div>',
      controller: 'purchaseRequisitionsItemListCtrl'
    }
  })
  .controller('purchaseRequisitionsItemListCtrl', purchaseRequisitionsItemListCtrl);
