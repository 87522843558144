'use strict';

function addressHistoryModalCtrl($scope, histories, $uibModalInstance, commentsHistory, addressDetail) {
  $scope.cancel = cancel;
  $scope.histories = histories.data;
  $scope.meta = histories.meta;
  $scope.commentsHistory = commentsHistory;
  $scope.prepareLogFields = prepareLogFields;
  $scope.embeddedParams = {
    referenceId: addressDetail._id,
    offset: 5
  };

  function cancel() {
    $uibModalInstance.close();
  }

  function prepareLogFields(related, action) {
    var fields = [];
    _.forEach(related, function(v, k) {
      var item = k + ': ' + v.new;
      if (action === 'updated') {
        item = k + ': ' + v.old + ' => ' + v.new;
      }
      fields.push(item);
    });
    return fields;
  }
}

addressHistoryModalCtrl.$inject = ['$scope', 'histories', '$uibModalInstance', 'commentsHistory', 'addressDetail'];

angular
  .module('metabuyer')
  .controller('addressHistoryModalCtrl', addressHistoryModalCtrl);
