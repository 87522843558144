'use strict';

angular.module('metabuyer.services.approvalEngine.positionManage', ['ngResource'])
  .factory('positionManagement', ['$resource', 'pathConstants',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.approvalEngine.position.get,
        {
          limit: '@limit',
          page: '@page',
          sort: '@sort',
          includes: '@includes',
          filter_groups: '@filter_groups',
          position: '@position'
        },
        {
          'get': { method: 'GET' },
          'post': { method: 'POST' },
          'put': { method: 'PUT' }
        }
      );
    }])
  .factory('positionManagementSingle', ['$resource', 'pathConstants',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.approvalEngine.position.single,
        {
          id: '@id'
        },
        {
          'get': { method: 'GET' },
          'put': { method: 'PUT' },
          'delete': { method: 'DELETE' }
        }
      );
    }])
  .factory('positionManagementExport', ['$resource', 'pathConstants',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.approvalEngine.position.export,
        {},
        {
          'post': { method: 'POST' }
        }
      );
    }])