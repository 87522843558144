'use strict';

function purchaseRequisitionsActionCtrl(
  $scope, pathConstants, purchaseRequisitionsServices, $state, HighlightId, prFunction, $rootScope, globalFunc, toastr,
  $stateParams, getPRPDFHash, $filter, UserPermissions, generatePO, notificationSubscriber, PRNewComments, $uibModal
){

  $scope.localApprovalData          = {};
  $scope.currentUser                = $rootScope.currentUser || { _id: null };
  $scope.nextPR                     = { context_id: 'loading' };
  $scope.userBasePath               = $rootScope.userImageBasePath;
  $scope.waitingOnUser              = [];
  $scope.templateUrlPath            = 'components/purchaseRequisitionComponents/actions/purchaseRequisitionActions.html';
  $scope.module                     = 'PR';
  $scope.disableGeneratePO          = false;

  if ($rootScope.isMobileMode) {
    $scope.templateUrlPath = 'components/purchaseRequisitionComponents/actions/purchaseRequisitionActions-mobile.html';
    if ($scope.actionType === 'button')
      $scope.templateUrlPath = 'components/purchaseRequisitionComponents/actions/purchaseRequisitionActions-mobile-button.html';
  }

  $scope.deletePR                   = deletePR;
  $scope.generatePurchaseOrder      = generatePurchaseOrder;
  $scope.approvalAction             = approvalAction;
  $scope.submitPurchaseRequisition  = submitPurchaseRequisition;
  $scope.transitionToListingPending = transitionToListingPending;
  $scope.approvalFlowData           = approvalFlowData;
  $scope.checkWaitingOn             = checkWaitingOn;
  $scope.isPRCreator                = isPRCreator;
  $scope.canSubmit                  = canSubmit;
  $scope.canUnHold                  = canUnHold;
  $scope.canApprove                 = canApprove;
  $scope.canDelete                  = canDelete;
  $scope.actionCondition            = actionCondition;
  $scope.refreshPRDetail            = refreshPRDetail;
  $scope.prAction                   = prAction;
  $scope.printPRViaPDF              = printPRViaPDF;
  $scope.notificationToggle         = notificationToggle;
  $scope.duplicatePR                = duplicatePR;
  $scope.canMoveToDraftPR           = canMoveToDraftPR;
  $scope.moveToDraftPR              = moveToDraftPR;
  $scope.allowEditingPR             = allowEditingPR;
  $scope.setEditableMode            = setEditableMode;
  $scope.canHold                    = canHold;
  $scope.canReject                  = canReject;
  $scope.canWithdraw                = canWithdraw;
  $scope.canGeneratePO              = canGeneratePO;
  $scope.submitEditableDraft        = submitEditableDraft;
  $scope.requestEditableDraft       = requestEditableDraft;
  $scope.cancelEditingPR            = cancelEditingPR;
  $scope.allowedToRevertApproval    = allowedToRevertApproval;
  $scope.revertApproval             = revertApproval;
  $scope.revertApprovalErrorHandler = revertApprovalErrorHandler;
  $scope.canUpdateCollaborators     = canUpdateCollaborators;
  $scope.getIntegrationData         = getIntegrationData;
  $scope.updateIntegrationInfo      = updateIntegrationInfo;
  $scope.resendPrEmail              = resendPrEmail;
  $scope.canResendPrEmail           = canResendPrEmail;
  $scope.isIntegrationDetailsEditable = isIntegrationDetailsEditable;
  $scope.openIntegrationModal = openIntegrationModal;
  $scope.broadcastBack = broadcastBack;
  $scope.hasCmmsIntegration = hasCmmsIntegration;

  function canMoveToDraftPR() {
    var allowResubmit = prFunction.getDocumentRule('allow_move_to_draft');
    // Cannot resubmit if has CMMS integration
    if (!allowResubmit) {
      return false;
    }

    if (($scope.PR.status.toLowerCase() === 'rejected' || $scope.PR.status.toLowerCase() === 'withdrawn') &&
      isPRCreator() && prFunction.companyCanResubmitWithdrawnPR()
    ) {
      return true;
    }

    return false;
  }

  function hasCmmsIntegration() {
    return prFunction.hasCmmsIntegration();
  }

  /**
   * Check if the user is currently eligible to resend PR Approval Email
   * */
  function canResendPrEmail() {
    return $scope.PR.status.toLowerCase() === 'pending' && globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN');
  }

  /**
   * Sends an email to the current waiting on PR Approver
   * */
  function resendPrEmail() {
    var email_text = "Approval email will be resent to the following approvers\n\n";
    $scope.waitingOnUser.forEach(function (currentVal) {
      email_text += currentVal.display_name + "\n";
    });

    swal ({
      title: "Email re-sent",
      text: email_text,
      type: "success",
      showCancelButton: false,
      confirmButtonColor: "#1ab394",
      confirmButtonText: "Ok",
      timer: 5000,
      closeOnConfirm: true
    });
    return purchaseRequisitionsServices.resendPendingPrEmail(
      {
        id: $scope.PR._id
      }
    ).$promise;
  }

  /**
   * Check if the current PR has valid status to update the collaborator
   *
   * @param string currentPrStatus String of PR status
   * @returns {boolean}
   */
  function canUpdateCollaborators(currentPrStatus) {
    var validPRStatus = [
      'draft',
      'pending',
      'edit',
      'on_hold',
      'withdrawn',
      'rejected',
      'approved'
    ];

    return !_.isEmpty(_.intersection(validPRStatus, [currentPrStatus]));
  }

  function allowedToRevertApproval () {
    return $scope.PR.status.toLowerCase() === 'pending' && globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN');
  }

  function revertApproval(extraPayload) {
    return purchaseRequisitionsServices.resetApproval(
      {
        approval_id: $scope.PR.approval_id
      },
      extraPayload
    ).$promise;
  }

  function revertApprovalErrorHandler(message){
    globalFunc.objectErrorMessage(message);
  }

  function approvalFlowData() {
    $scope.localApprovalData = prFunction.getApprovalFlow();
    if (!!$scope.localApprovalData && !!$scope.localApprovalData.waiting_on && !!$scope.localApprovalData.waiting_on.length)
      globalFunc.getWaitingOnUser($scope.localApprovalData, $scope.waitingOnUser);
    return $scope.localApprovalData;
  }

  function loadNextData() {
    if ($scope.PR.status === 'pending' || $scope.PR.status.toLowerCase() === 'on_hold') {
      /**
       * add the 2 extra params for Hold and Reject PRs
       * @type {any}
       */
      $scope.onHoldPRExtraParams = prepareOnHoldExtraParams();
      $scope.rejectPRExtraParams = {};
      $scope.revertExtraParams = {};
      globalFunc.nextAvailableAction('Metabuyer\\PR\\PR', $scope.PR._id).then(function (data) {
        if (!!data && !!data.waitingOnObject && !!data.waitingOnObject.context_id) {
          $scope.nextPR = data.waitingOnObject;
          $scope.totalPRs = data.count;
        }
        else
          $scope.nextPR = {};
      });
    }
  }

  /**
   * Refresh PR detail page
   */
  function refreshPRDetail(){
    $state.transitionTo($state.current, $stateParams, {
      reload: true,
      inherit: false,
      notify: true
    });
  }

  function notificationToggle() {

    if(!$scope.PR.notify) {
      notificationSubscriber.post({
          object_id: $scope.PR._id, class: 'Metabuyer\\PR\\PR', method: 'subscribe'
        }
        , function (data) {
          $scope.PR.notify = true;
          toastr.success('Turned on notifications successfully');
        }
        , function (error) {
          toastr.error('Failed to turn on notifications');
        });
    }
    else{
      notificationSubscriber.delete({
          object_id: $scope.PR._id, class: 'Metabuyer\\PR\\PR', method: 'unsubscribe'
        }
        , function (data) {
          $scope.PR.notify = false;
          toastr.success('Turned off notifications successfully');
        }
        , function (error) {
          toastr.error('Failed to turn off notifications');
        });
    }

  }

  function checkCanDuplicate() {
    $scope.canDuplicate = false;
    if (isPRCreator() || $scope.PR.requestor._id === $scope.currentUser._id) {
      $scope.canDuplicate = true;
    }
    if (!!UserPermissions.checkPermissionsAccess($scope.currentUser, 'PR', 'Create', [$scope.PR.company._id]) &&
      !!$scope.PR.company.requisition_settings &&
      !!$scope.PR.company.requisition_settings.duplicate_others_pr_within_cost_center &&
      $scope.PR.company.requisition_settings.duplicate_others_pr_within_cost_center.is_allowed
      ) {
      _.forEach($scope.currentUser.costCenters, function (costCenter) {
        if (costCenter._id === $scope.PR.cost_center._id) {
          $scope.canDuplicate = true;
        }
      })
    }
  }

  function duplicatePR() {
    if ($scope.pritemlimit > 0 && $scope.PR.pr_items.length > $scope.pritemlimit) {
      toastr.error('Unable to duplicate. PR line item count is over the line item limit. Only '
        + $scope.pritemlimit + ' line items allowed per PR');

      return;
    }

    purchaseRequisitionsServices.duplicate({
      id: $scope.PR._id
    }, function (resource) {
      if (!!resource && !!resource.content && !!resource.content.data)
        $state.go('main.user.purchaseRequisitions.details', { id: resource.content.data._id });
    }, function (error) {
      globalFunc.objectErrorMessage(error.data);
    })
  }

  function moveToDraftPR(){
    purchaseRequisitionsServices.moveToDraft(
      {
        id: $scope.PR._id
      }, function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data)
          $state.reload();
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      })
  }

  /**
   * Preparing on hold extra params
   * @returns {{user_ids: Array}}
   */
  function prepareOnHoldExtraParams() {
    var userIDArray = [];
    userIDArray.push($scope.PR.creator_info._id);
    if ($scope.PR.requestor._id !== $scope.PR.creator_info._id)
      userIDArray.push($scope.PR.requestor._id);

    return {user_ids: userIDArray};
  }

  function isPRCreator() {
    return ($scope.currentUser._id === $scope.PR.creator_info._id);
  }

  /**
   * check the statuses of the items in which the PR is still approvable
   * a list of string for all the statuses that can be approved
   * @returns {boolean}
   */
  function checkItemsApprovability() {
    var items = prFunction.getPRData().pr_items;
    var unavailableItems = prFunction.getPRData().unavailable_item_lines || [];
    var approvalableStatuses = ['0', '11', '12', '13', '14', '18', '19'];

    if (!!unavailableItems && unavailableItems.length > 0) {
      return false;
    }
    if (!items || items.length < 1) {
      return false;
    }
    for (var i in items) {
      if (_.isUndefined(items[i].item_validity_status))
        return false;

      if (approvalableStatuses.indexOf(items[i].item_validity_status.toString()) === -1)
        return false;
    }
    return true;
  }

  /**
   * Function to check PR data for changes and approvability
   * @returns {boolean}
   */
  function checkPRDataApprovability(){
    var pr = prFunction.getPRData();
    if(!!pr.pr_related_data_changes)
      return false;

    return true;
  }

  function canGeneratePO() {
    return (
      ($scope.PR.status.toLowerCase() === 'approved') &&
      (!$scope.PR.pos || (!!$scope.PR.pos && !$scope.PR.pos.length)) &&
      (UserPermissions.isDeveloper($scope.currentUser))
    );
  }

  function canApprove() {
    var checkPRStatus = ($scope.PR.status.toLowerCase() === 'pending');
    var isApprover    = globalFunc.findInArray($scope.localApprovalData.waiting_on, '_id', $rootScope.currentUser._id);
    var PRApprovable  = checkItemsApprovability();
    var PRDataChanged = checkPRDataApprovability();

    return (checkPRStatus && !!isApprover && PRDataChanged && PRApprovable && checkUserAccessToCompany());
  }

  function canUnHold() {
    var approverAbleToHoldPR = true;
    var checkPRStatus = ($scope.PR.status.toLowerCase() === 'on_hold');
    var isApprover = globalFunc.findInArray($scope.localApprovalData.waiting_on, '_id', $scope.currentUser._id);
    var selectedCompany = globalFunc.findInArray($scope.currentUser.companies, '_id', $scope.PR.company._id);

    if (!!selectedCompany && !!selectedCompany.config && !!selectedCompany.config.allowedToHoldPR) {
      approverAbleToHoldPR = selectedCompany.config.allowedToHoldPR;
    }

    return (
      checkPRStatus &&
      (
        (((approverAbleToHoldPR && !!isApprover) || canOverrideForAction('hold')) && checkUserAccessToCompany()) ||
        !!globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN')
      ));
  }

  function canHold() {
    var approverAbleToHoldPR = true;
    var checkPRStatus = ($scope.PR.status.toLowerCase() === 'pending');
    var isApprover = globalFunc.findInArray($scope.localApprovalData.waiting_on, '_id', $scope.currentUser._id);
    var selectedCompany = globalFunc.findInArray($scope.currentUser.companies, '_id', $scope.PR.company._id);
    if (!!selectedCompany && !!selectedCompany.config && !!selectedCompany.config.allowedToHoldPR) {
      approverAbleToHoldPR = selectedCompany.config.allowedToHoldPR;
    }

    return (
      checkPRStatus &&
      (
        (((approverAbleToHoldPR && !!isApprover) || canOverrideForAction('hold')) && checkUserAccessToCompany()) ||
        !!globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN')
      ));
  }

  function canReject() {
    var checkPRStatus = ($scope.PR.status.toLowerCase() === 'pending');
    var isApprover    = globalFunc.findInArray($scope.localApprovalData.waiting_on, '_id', $scope.currentUser._id);

    return (checkPRStatus && !!isApprover && checkUserAccessToCompany());
  }

  function canWithdraw() {
    var checkPRStatus = ($scope.PR.status.toLowerCase() === 'pending');
    return (
      checkPRStatus &&
      (
        $scope.PR.creator_info._id === $scope.currentUser._id ||
        (!!$scope.PR.requestor && ($scope.PR.requestor._id === $scope.currentUser._id)) ||
        canOverrideForAction('withdraw')
      ));
  }

  function canSubmit() {
    var pr = getUpdatedPRData();
    return (
      isPRCreator() &&
      (!!pr.pr_items && !!pr.pr_items.length) &&
      pr.status.toLowerCase() === 'draft'
    );
  }

  function checkAccountCode(pr) {
    var prItems = pr.pr_items;
    var requisitionSettings = pr.company.requisition_settings;

    if (!!requisitionSettings && !!requisitionSettings.account_code) {
      if (requisitionSettings.account_code.is_mandatory) {
        return !globalFunc.findInArray(prItems, 'account_code', null);
      }

      return true;
    }

    return true;
  }

  function checkErpOrderType(pr) {
    var allowErpOrderType = prFunction.getDocumentRule('allow_erp_order_type');

    if (prFunction.getIntegrationStatus() && allowErpOrderType) {
      return !!pr.erp_order_type;
    }

    return true;
  }

  function checkErpCostCenter(pr) {
    var requisitionSettings = pr.company.requisition_settings;

    if (!!prFunction.getDocumentRule('allow_erp_cost_center')) {
      if (!!requisitionSettings.erp_cost_center && requisitionSettings.erp_cost_center.is_mandatory) {
        return !!pr.erp_cost_center;
      }

      return true;
    }

    return true;
  }

  /**
   * check permission and pr status to delete
   * @returns {*|boolean}
   */
  function canDelete() {
    var pr = getUpdatedPRData();
    var allowDelete = prFunction.getDocumentRule('allow_delete') !== false;
    return (isPRCreator() && pr.status.toLowerCase() === 'draft' && allowDelete);
  }

  function getUpdatedPRData() {
    return prFunction.getPRData();
  }

  function checkWaitingOn() {
    return true;
  }

  function printPRViaPDF() {

    /**
     * Validate the pr items before proceeding
     */
    if (!$scope.PR.pr_items || $scope.PR.pr_items.length < 1) {
      toastr.error('Print PR failed because the PR has no items.');
    }
    else {

      /**
       * Pass current logged in user's timezone
       */
      getPRPDFHash.get(
        {
          id: $scope.PR._id,
          timezone: $scope.currentUser.config.TZ
        }, function (resource) {
          var newUrl = $filter('format')(pathConstants.apiUrls.globalPDFHashDownload, {
            pdf_hash: resource.url
          });

          setTimeout(function () {
            window.open(newUrl, '_blank')
          }, 1001);
        }, function () {
          toastr.error('Failed to print PR');
        }
      );
    }
  }

  /**
   * Function for deleting PR
   */
  function deletePR(){
    swal(
      {
        title: 'Confirm delete PR',
        text: 'This PR will be deleted',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Delete',
        closeOnConfirm: true
      },
      function (isConfirm) {
        if (isConfirm) {
          if (!!$scope.PR) {
            purchaseRequisitionsServices.deleteSinglePR({
              id: $scope.PR._id
            }, function () {
              toastr.success('PR deleted');
              $state.go('main.user.purchaseRequisitions.manage', {status: 'draft', tab: 'all'});
            }, function (error) {
              globalFunc.objectErrorMessage(error);
            })
          }
        }
      }
    );
  }

  /**
   * Function for generating PO manually in cases where PO failed to generate
   * @param PRId
   */
  function generatePurchaseOrder(PRId){
    $scope.disableGeneratePO = true;

    generatePO.post({
      id: PRId
    }, function (resource) {
      if (!!resource && !!resource.content && !!resource.content.message) {
        toastr.success(resource.content.message);
        $state.reload();
      }
    }, function (error) {
      $scope.disableGeneratePO = false;
      globalFunc.objectErrorMessage(error);
    })
  }

  /**
   * allow the editing button to appear
   * @returns {*}
   */
  function allowEditingPR() {
    if (prFunction.getPRData().status === 'pending') {
      var isPA = prFunction.checkCurrentApproverIsPA(
        !!prFunction.getPRData() ? prFunction.getPRData().waiting_on : null,
        !!$rootScope.currentUser ? $rootScope.currentUser._id : null,
        !!$rootScope.currentUser ? $rootScope.currentUser.role_assignments : null
      );

      var isProcurementSpecialist = prFunction.isProcurementSpecialist(
        !!$rootScope.currentUser ? $rootScope.currentUser.role_assignments : null
      );

      return isPA || isProcurementSpecialist;
    }

    return false;
  }

  function setEditableMode(mode){
    prFunction.setEditableState(mode);
  }

  function prAction(title, action) {
    swal(
      {
        title: title,
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (is_confirm) {
        if (is_confirm) {
          if (action === 1)
            approvalAction('approve', 'You have approved this purchase requisition');
          if (action === 3)
            approvalAction('release', 'You have removed the hold from this purchase requisition', {});
        }
      }
    );
  }

  /**
   * function returns the approval resource so it can be used on other module
   *
   * @param status
   * @param text
   * @param extraPayload in some cases the action needs a comment or such extra payload passed to the backend
   * @returns {*|{method, params, url}}
   */
  function approvalAction(status, text, extraPayload) {

    return purchaseRequisitionsServices.approvalAction(
      {
        approval_id: $scope.PR.approval_id,
        status: status
      },
      extraPayload,
      function () {
        if (!!text)
          toastr.success(text);
        else
          toastr.success('You have submitted this purchase requisition.');
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      }
    ).$promise;
  }

  /**
   * the condition to pass to the directive to check if the action is ready to fire
   * @returns {boolean}
   */
  function actionCondition(){
    // check if PA and missing Tax
    // if (checkPurchaseAgentRole() && checkIfMissingTax()) {
    //   toastr.error('Item(s) are missing a Tax. Please complete them before approving');
    //   return false;
    // }
    return true;
  }

  function submitNewComment() {
    var content = $scope.PR.comment.content;
    var userIds = $scope.PR.comment.mentionedIdArray;

    PRNewComments.post({
        referenceId: $scope.PR._id
      },
      {
        comment: content,
        mentionedUserIds: userIds
      });
  }

  /**
   * Submit PR
   * @param ignoreOverBudget (0-do not ignore over budget; 1-do ignore over budget)
   */
  function submitPurchaseRequisition(ignoreOverBudget) {
    submitValidation();
    $scope.grandTotal = prFunction.getPRData().grand_total;

    if (!!prFunction.getPRData().comment && prFunction.getPRData().comment !=='') {
      submitNewComment();
    }
    return purchaseRequisitionsServices.submitPR({
      id: $scope.PR._id,
      data: {
        ignore_over_budget: ignoreOverBudget
      }
    }, function (response) {
      if ($scope.grandTotal !== response.content.data.grand_total) {
        var message = 'Grand Total of ' + response.content.data.title + ' has changed due to exchange rate';
        toastr.warning(message);
      }

      transitionToListingPending();
    }, function (error) {
      var message = (!!error.data && !!error.data.content && !!error.data.content.message) ?
        error.data.content.message : null;
      switch (message) {
        // case where over budget detected, but is allowed
        case 'Overbudget is allowed.':
          userToConfirmOverbudget();
          break;
        // case where over budget detected, but is not allowed further action
        case 'Overbudget is not allowed.':
          alertUserOnOverBudgetNotAllowed();
          break;
      }
    }).$promise;
  }

  /**
   * pop up to ask user if the over budget pr submit is confirmed
   */
  function userToConfirmOverbudget() {
    swal(
      {
        title: 'Confirm submission?',
        text: 'This requisition exceeds the available budget amount, confirm the submission?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        closeOnConfirm: true
      },
      function (isConfirm) {
        if (isConfirm) {
          submitPurchaseRequisition(true);
        }
      }
    );
  }

  /**
   * pop to notify the user over-budget is not allowed
   */
  function alertUserOnOverBudgetNotAllowed() {
    swal(
      {
        title: 'Available budget exceeded.',
        text: 'This requisition exceeds the available budget amount.',
        type: 'info',
        confirmButtonColor: '#D0D0D0',
        confirmButtonText: 'Close',
        closeOnConfirm: true
      }
    );
  }

  function submitValidation() {
    var pr = getUpdatedPRData();
    var errors = [];
    if (!!pr) {
      // validate expense type
      if (!pr.expense_type || (!!pr.expense_type && !pr.expense_type.code)) {
        errors.push('expense_type');
        toastr.error('Expense type is required.');
      }

      if (!pr.contact_person) {
        errors.push('contact_person');
        toastr.error('Contact person is required.');
      }

      if (_.isEmpty(pr.budget))
        errors.push('budget');

      if (_.isEmpty(pr.title)) {
        errors.push('title');
        toastr.error('PR Title is required.');
      }

      if (!checkAccountCode(pr)) {
        errors.push('account_code');
        toastr.error('Account code is required.');
      }

      if (!checkErpOrderType(pr)) {
        errors.push('erp_order_type');
        toastr.error('ERP Order Type is required.');
      }

      if (!checkErpCostCenter(pr)) {
        errors.push('erp_cost_center');
        toastr.error('ERP Cost Center is required.');
      }
    }

    prFunction.setErrorsOnSubmit(errors);
    return !!(errors.length);
  }

  function transitionToListingPending(){
    $state.go('main.user.purchaseRequisitions.manage', {status: 'pending', tab: 'all'});
    HighlightId.setId($scope.PR._id);
  }

  function broadcastBack() {
    $rootScope.$broadcast('backFromPr');
  }

  function requestEditableDraft(){
    purchaseRequisitionsServices.requestEditableCopy({
      id: prFunction.getPRData()._id
    },{}, function (resource) {
      if(!!resource.content && !!resource.content.data) {
        prFunction.setPRData(resource.content.data);
        setEditableMode('editable');
        // Refresh attachment stuff
        $scope.$emit('loadAtt');
        $rootScope.$broadcast('thisBetterWork');

      }
      else
        globalFunc.objectErrorMessage('Something went wrong')
    }, function (e) {
      globalFunc.objectErrorMessage(e);
    });
  }

  function submitEditableDraft(){
    swal(
      {
        title: 'Confirm submit edited PR?',
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        closeOnConfirm: true
      },
      function (isConfirm) {
        if (isConfirm) {
          $scope.grandTotal = prFunction.getPRData().grand_total;
          purchaseRequisitionsServices.submitEditableCopy({
            id: prFunction.getPRData()._id
          }, {}, function (resource) {
            if (!!resource.content && !!resource.content.data) {
              if ($scope.grandTotal !== resource.content.data.grand_total) {
                var message = 'Grand Total of ' + resource.content.data.title + ' has changed due to exchange rate';
                toastr.warning(message);
              }
              prFunction.setPRData(resource.content.data);
              setEditableMode('notEditable');
              $state.reload();
            }
            else
              globalFunc.objectErrorMessage('Something went wrong')
          }, function (e) {
            globalFunc.objectErrorMessage(e);
          });
        }
      }
    );
  }

  function cancelEditingPR() {
    purchaseRequisitionsServices.cancelEditableCopy({
      id: prFunction.getPRData()._id
    }, {}, function (resource) {
      if(!!resource.content && !!resource.content.data) {
        prFunction.setPRData(resource.content.data);
        setEditableMode('notEditable');
        $state.reload();
      }
      else
        globalFunc.objectErrorMessage('Something went wrong')
    }, function (e) {
      globalFunc.objectErrorMessage(e);
    });
  }

  function getIntegrationData() {
    return prFunction.getIntegrationDependencies;
  }

  /**
   * The condition for the feature are:
   * 1- integrated company.
   * 2- Super tenant admin role.
   * 3- Erp cost center has not been selected.
   *
   * @returns {boolean}
   */
  function updateIntegrationInfo() {
    return (!!prFunction.getIntegrationStatus() &&
    !!globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN')
    && (!prFunction.getPRData().erp_cost_center || !prFunction.getPRData().erp_cost_center._id))
  }
  /**
   * Check user access to the company
   * @returns {boolean}
   */
  function checkUserAccessToCompany() {
    return !!globalFunc.findInArray(
      $scope.currentUser.companies,
      'code',
      prFunction.getPRData().company.code
    );
  }

  function canOverrideForAction(action) {
    var overrideIsAllowed = false;

    if (!!$scope.PR.company.requisition_settings) {
      if (action === 'withdraw' && !!$scope.PR.company.requisition_settings.pa_override_withdraw_pr) {
        overrideIsAllowed = $scope.PR.company.requisition_settings.pa_override_withdraw_pr.is_allowed;
      }
      else if (action === 'hold' && !!$scope.PR.company.requisition_settings.pa_override_hold_pr){
        overrideIsAllowed = $scope.PR.company.requisition_settings.pa_override_hold_pr.is_allowed;
      }

      return (
        (
          !!globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'PA') ||
          !!globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'PROCUREMENT_SPECIALIST')
        ) &&
        overrideIsAllowed);
    }

    return false;
  }

  function isIntegrationDetailsEditable() {
    return (!!prFunction.getIntegrationStatus() &&
      $scope.PR.status.toLowerCase() !== 'draft' &&
      !!globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN'));
  }

  function openIntegrationModal() {
    $uibModal.open({
      templateUrl: "components/purchaseRequisitionComponents/integrations/editInfo/editIntegrationModal.html",
      backdrop: 'static',
      keyboard: false,
      controller: editIntegrationInfoModal
    })
  }

  function initialize() {
    loadNextData();
    checkCanDuplicate();
  }

  initialize();

  $scope.$watch('editingState', function (newValue) {
    // Editing mode on the actions only need to change the title
    // and show hide the button
    $scope.editingState = newValue;
  });
}

purchaseRequisitionsActionCtrl.$inject = [
  '$scope', 'pathConstants', 'purchaseRequisitionsServices', '$state', 'HighlightId', 'prFunction', '$rootScope',
  'globalFunc', 'toastr', '$stateParams', 'getPRPDFHash', '$filter', 'UserPermissions', 'generatePO', 'notificationSubscriber',
  'PRNewComments', '$uibModal'
];

angular
  .module('metabuyer')
  .directive('purchaseRequisitionActions',function() {
    return {
      restrict: 'E',
      scope: {
        editingState: '=',
        PR: '=pr',
        actionType: '@',
        pritemlimit: '=?'
      },
      template: '<div ng-include="templateUrlPath"></div>',
      controller: 'purchaseRequisitionsActionCtrl'
    }
  })
  .controller('purchaseRequisitionsActionCtrl', purchaseRequisitionsActionCtrl);
