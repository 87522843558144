'use strict';

function catalogsDetailCtrl($scope, toastr, managedItem, $q, $stateParams, $state, catalogVersion,
                        $filter, pathConstants, $http, $uibModal, updateStatusCatalogsV2,
                        Approvals, profile, items, manageItemDetail,
                        HighlightId, itemDetailsListing, $rootScope, searchModule, globalFunc, historiesV2,
                        getCatalogComments, downloadCatalogV2Attachment, resendPendingCatalogEmail) {

  $scope.approvals = [];
  $scope.approvalFlowData = {};
  $scope.rejector = {};
  $scope.waiting_on_user = [];
  $scope.profile = profile;
  $scope.commentService = getCatalogComments;
  $scope.checkWaitingOn = checkWaitingOn;
  $scope.approveItem = approveItem;
  $scope.rejectItem = rejectItem;
  $scope.changeStatus = changeStatus;
  $scope.viewHistoryDetails = viewHistoryDetails;
  $scope.getItemListing = getItemListing;
  $scope.getItemList = getItemList;
  $scope.viewSingleItemDetails = viewSingleItemDetails;
  $scope.downloadImportCatalogCSV = downloadImportCatalogCSV;
  $scope.downloadImageFileZip = downloadImageFileZip;
  $scope.getImportCatalogCSVTitle = getImportCatalogCSVTitle;
  $scope.uploadImageFileModal = uploadImageFileModal;
  $scope.showColumn = showColumn;
  $scope.canResendCatalogEmail = canResendCatalogEmail;
  $scope.resendCatalogEmail = resendCatalogEmail;

  // Get Items Detail
  $scope.itemDetail = managedItem.data;
  $scope.status = $scope.itemDetail.status;
  $scope.next_status = $scope.itemDetail.next_status;
  $scope.requestor = {
    "_id": "",
    "display_name": $scope.itemDetail.created_by,
    "img_url": ""
  };

  $scope.filteredItems = [];
  $scope.currentPage = {
    number: 1
  };
  $scope.numPerPage = 20;
  $scope.maxSize = 5;
  $scope.pageChanged = pageChanged;
  $scope.approveLock = false;

  function pageChanged() {
    var begin = (($scope.currentPage.number - 1) * $scope.numPerPage);
    var end = begin + $scope.numPerPage;
    $scope.filteredItems = $scope.itemDetailsList.slice(begin, end);
  }

  $scope.columns = [
    {id: 'index', label: '#'},
    {id: 'item_name', label: 'Item Code & Item Name'},
    {id: 'item_sub_code', label: 'Item Sub Code'},
    {id: 'description', label: 'Item Description'},
    {id: 'category.code', label: 'Item Category'},
    {id: 'supplier.descr', label: 'Supplier'},
    {id: 'supplier.code', label: 'Supplier Code'},
    {id: 'supplier_part_no', label: 'Supplier Part Number'},
    {id: 'distributors', label: 'Distributor'},
    {id: 'quantity_limit', label: 'Quantity Approved'},
    {id: 'quantity_comitted', label: 'Quantity Committed'},
    {id: 'quantity_used', label: 'Quantity Used'},
    {id: 'quantity_available', label: 'Quantity Available'},
    {id: 'is_partial', label: 'Partial'},
    {id: 'tax.rate', label: 'Tax Rate'},
    {id: 'payment_term.descr', label: 'Payment Term'},
    {id: 'currency.code', label: 'Currency'},
    {id: 'unit_price', label: 'Unit Price'},
    {id: 'Uom.code', label: 'UOM'},
    {id: 'effective_at', label: 'Effective Date', type: 'date'},
    {id: 'expiry_at', label: 'Expiry Date', type: 'date'},
    {id: 'catalog_label', label: 'Catalog Label'},
  ];
  $scope.storageKey = 'item-details-management-selected-columns';

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  // Import History
  // Get from another endpoint
  function getItemsVersion() {
    historiesV2.get(
      {
        uuid: $scope.itemDetail.uuid
      },
      function (resource) {
        $scope.dataHistory = resource.content.data;
      },
      function (error) {

      }
    );
  }

  // Run the getItemsVersion function
  getItemsVersion();

  /**
   * Function to download the imported catalog csv
   * @param attachmentArray
   */
   function downloadImportCatalogCSV (attachmentArray) {
    _.forEach(attachmentArray, function(attachment){
      if(!!attachment.extension && attachment.extension.toLowerCase() === 'csv'){
        downloadCatalogV2Attachment.get(
          {
            catalog_id: attachment.reference_id,
            attachment_id: attachment._id
          }
        ).$promise.then(
          function (resource) {
            var newUrl = $filter('format')(pathConstants.apiUrls.catalogsV2.downloadWithHash, {
              hash: resource.hash
            });

            setTimeout(function () {
              window.open(newUrl, '_self')
            }, 1001);
          },
          function(error){
            if (!!error) {
              globalFunc.objectErrorMessage(error);
            } else {
              toastr.error('Failed to download attachment');
            }
          }
        );
      }
    });
  }

  /**
   * Function to download the uploaded image file
   *
   * @param attachmentArray
   */
  function downloadImageFileZip (attachmentArray) {
    _.forEach(attachmentArray, function(attachment){
      if(!!attachment.extension && attachment.extension.toLowerCase() === 'zip'){
        downloadCatalogV2Attachment.get(
          {
            catalog_id: attachment.reference_id,
            attachment_id: attachment._id
          }
        ).$promise.then(
          function (resource) {
            var newUrl = $filter('format')(pathConstants.apiUrls.catalogsV2.downloadWithHash, {
              hash: resource.hash
            });

            setTimeout(function () {
              window.open(newUrl, '_self')
            }, 1001);
          },
          function(error){
            if (!!error) {
              globalFunc.objectErrorMessage(error);
            } else {
              toastr.error('Failed to download attachment');
            }
          }
        );
      }
    });
  }

  /**
   * Function to get the import catalog title
   * @param attachmentArray
   * @returns {string}
   */
  function getImportCatalogCSVTitle(attachmentArray) {
    var title = '';
    _.forEach(attachmentArray, function(attachment){
      if(!!attachment.extension && attachment.extension.toLowerCase() === 'csv') {
        title = attachment.title;
        // Set a return here to break the forEach loop
        return true;
      }
    });
    return title;
  }

  /**
   * Function to upload the image file
   * @param summaryId
   */
  function uploadImageFileModal (summaryId) {
    var modalInstance = $uibModal.open({
      templateUrl: 'app/items/details/importImage.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        dataSummaryId: function () {
          return summaryId;
        }
      },
      controller: function (dataSummaryId, $scope, $uibModalInstance) {
        $scope.summaryId = angular.copy(dataSummaryId);
        $scope.cancel = cancel;
        $scope.importImage = importImage;

        function cancel () {
          $uibModalInstance.close();
        }

        // Save import image
        function importImage (images, summaryId) {
          if (!!images) {
            var fd = new FormData();
            fd.append('file', images);
            $http.post($filter('format')(pathConstants.apiUrls.items.imagesImport + summaryId), fd,
              {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
              })
              .then(function onSuccess () {
                toastr.success('Images successfully uploaded');
                getItemsVersion();
                $uibModalInstance.close();
              })
              .catch(function onError (error) {
                globalFunc.objectErrorMessage(error);
              });
          } else {
            toastr.error('No file chosen');
          }
        }
      }
    });
  }

  function viewHistoryDetails(dataHistory){
    var modalInstance = $uibModal.open({
      templateUrl: 'app/items/details/historyDetails.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        dataHistory: function() {
          return dataHistory;
        }
      },
      controller: function ($scope, $uibModalInstance, dataHistory) {
        $scope.submitCatalog = submitCatalog;
        $scope.itemHistory = dataHistory;

        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        function submitCatalog(){
          updateStatusCatalog.put(
            {
              status: 'submit',
              id: $scope.itemHistory._id
            },
            function (resource) {
              toastr.success('Catalog submitted');
              $uibModalInstance.close();
              for(var i = 0; i < $scope.dataHistory.length; i++){
                if($scope.dataHistory[i]._id === resource.content.data._id){
                  $scope.dataHistory[i].status = resource.content.data.status;
                }
                if($scope.dataHistory[i]._id === $scope.itemDetail._id){
                  $scope.status = resource.content.data.status;
                }
              }
            },
            function (error) {
              toastr.error('Catalog submit failed');
            }
          );
        }
      }
    });
  }

  /**
   * Populate item list
   *
   * @params status: 10 New, 11 Updated, 12 Deleted, 13 Total
   */
  function getItemList(status) {
    $scope.itemDetailsList = [];
    $scope.showItemDetails = true;
    $scope.itemDetailsStatus = status;
    $scope.itemDetailsLoaded = true;
    $scope.filteredItems = [];
    $scope.currentPage = {
      number: 1
    };
    var itemIds = [];

    switch (status) {
      case 10:
        $scope.itemDetailTitle = 'New/Latest';
        if ($scope.itemDetail.import_summary.new.total > 0) {
          itemIds = $scope.itemDetail.import_summary.new.items;
          $scope.itemDetailsList = _.filter($scope.itemDetail.items, function(item){
            return itemIds.indexOf(item._id) !== -1;
          });

          $scope.filteredItems = $scope.itemDetailsList.slice(0, $scope.numPerPage);
        }
        break;
      case 11:
        $scope.itemDetailTitle = 'Updated';
        if ($scope.itemDetail.import_summary.update.total > 0) {
          itemIds = $scope.itemDetail.import_summary.update.items;
          $scope.itemDetailsList = _.filter($scope.itemDetail.items, function(item){
            return itemIds.indexOf(item._id) !== -1;
          });

          $scope.filteredItems = $scope.itemDetailsList.slice(0, $scope.numPerPage);
        }
        break;
      case 12:
        $scope.itemDetailTitle = 'Deleted';
        if ($scope.itemDetail.import_summary.delete.total > 0) {
          $scope.itemDetailsList = $scope.itemDetail.import_summary.delete.items;
          $scope.filteredItems = $scope.itemDetailsList.slice(0, $scope.numPerPage);
        }
        break;
      case 13:
        $scope.itemDetailTitle = 'Total';
        $scope.itemDetailsList = $scope.itemDetail.items;
        $scope.filteredItems = $scope.itemDetailsList.slice(0, $scope.numPerPage);
        break;
    }
  }

  /**
   *  Function to call endpoint to list item details based on status
   *  @params status: 10 New, 11 Updated, 12 Deleted, 13 Total
   */

  function getItemListing(status) {

    $scope.showItemDetails = true;
    $scope.itemDetailsStatus = status;
    /**
     * If selected status is not get total items listing
     */
    if(status !== 13){
      searchModule.get(
        {
          module: 'catalog-details',
          offset: 20,
          'criteria[import_summary_id]': $scope.itemDetail._id,
          'criteria[data_status]': status,
          criteria_operator: 'and'
        },
        function (resource) {
          $scope.itemDetailService = searchModule;
          $scope.embeddedParams = {
            module: 'catalog-details',
            offset: 20,
            'criteria[import_summary_id]': $scope.itemDetail._id,
            'criteria[data_status]': status,
            criteria_operator: 'and'
          };
          $scope.itemDetailsList = resource.content.data;
          $scope.itemDetailsMeta = resource.content.meta;
          $scope.itemDetailsLoaded = true;
          switch (status) {
            case 'NEW_ITEM':
              $scope.itemDetailTitle = 'New/Latest';
              break;
            case 'UPDATED_ITEM':
              $scope.itemDetailTitle = 'Updated';
              break;
            case 'DELETED_ITEM':
              $scope.itemDetailTitle = 'Deleted';
              break;
            case 'TOTAL_ITEM':
              $scope.itemDetailTitle = 'Total';
              break;
          }
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    }
    /**
     * If selected status is get total items listing
     */
    else{
      searchModule.get(
        {
          module: 'catalog-details',
          offset: 20,
          'criteria[import_summary_id]': $scope.itemDetail._id,
          'criteria[data_status][0]': 10,
          'criteria[data_status][1]': 11,
          'criteria[data_status][2]': 14,
          criteria_operator: 'and'
        },
        function (resource) {
          $scope.itemDetailService = searchModule;
          $scope.embeddedParams = {
            module: 'catalog-details',
            offset: 20,
            'criteria[import_summary_id]': $scope.itemDetail._id,
            'criteria[data_status][0]': 10,
            'criteria[data_status][1]': 11,
            'criteria[data_status][2]': 14,
            criteria_operator: 'and'
          };
          $scope.itemDetailsList = resource.content.data;
          $scope.itemDetailsMeta = resource.content.meta;
          $scope.itemDetailsLoaded = true;
          switch (status) {
            case 10:
              $scope.itemDetailTitle = 'New/Latest';
              break;
            case 11:
              $scope.itemDetailTitle = 'Updated';
              break;
            case 12:
              $scope.itemDetailTitle = 'Deleted';
              break;
            case 13:
              $scope.itemDetailTitle = 'Total';
              break;
          }
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    }
  }

  /**
   *  Receive broadcast for updated embedded pagination data to update on controller side
   */
  $scope.$on('updateEmbeddedPagination', function(events, content){
    if(!!content && !!content.data)
    $scope.itemDetailsList = content.data;
  });

  function viewSingleItemDetails(data){
    var modalInstance = $uibModal.open({
      templateUrl: 'app/items/details/singleItemDetails.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        data: function(){
          return data;
        }
      },
      controller: function ($scope, $uibModalInstance, data) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        $scope.singleItemDetail = data;
      }
    });
  }

  // Change Status of the catalog
  function changeStatus(status, summaryId) {
    updateStatusCatalogsV2.put(
      {
        id: $scope.itemDetail.uuid,
        status: status
      },
      function () {
        toastr.success('Successfully changed status of catalog');
        HighlightId.setId(summaryId);
        if(status === 'activate' || status === 'deactivate' || status === 'submit' || status === 'cancel')
          $state.go('main.user.catalogs.manageCatalog', {
            'status'    : 'pending',
            'order_by'  : 'updated_at',
            'order'     : 0
          });
        else
          $state.go('main.user.catalogs.manageCatalog', {'status': status});
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  // Approval Flow Data
  function loadApprovalFlow() {

    if (!$scope.itemDetail || !$scope.itemDetail.approval_id) {
      $scope.approvals = [];
    }

    Approvals.get(
      {
        id: $scope.itemDetail.approval_id
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.approvals = resource.content.data;
          $scope.requestor.display_name = resource.content.data.requested_by.display_name;
          if ($scope.status === 'pending') {
            $scope.approvalFlowData = resource.content.data;
            globalFunc.getWaitingOnUser(resource.content.data, $scope.waiting_on_user);
          }
          else if($scope.status === 'rejected'){
            for(var i in $scope.approvals){
              if(!!$scope.approvals[i].step_status && $scope.approvals[i].step_status === 'rejected'){
                $scope.rejector = $scope.approvals[i].rejectedRoles[0];
              }
            }
          }
        } else {
          $scope.approvals = [];
        }
      },
      function () {
        $scope.approvals = [];
      });
  }

  // If the import status is pending approved
  // Run loadApprovalFlow function
  if ($scope.status === 'pending' || $scope.status === 'rejected') {
    loadApprovalFlow();
  }

  //check user waiting on
  function checkWaitingOn() {
    var find_user = _.find($scope.waiting_on_user, function (approver) {
      return (approver._id == profile._id);
    });

    if (find_user != undefined) {
      return true;
    }

    return false;
  }

  // Approve Item
  function approveItem() {
    $scope.approveLock = true;//lock the approve button
    items.approve(
      {
        approval_id: $scope.itemDetail.approval_id,
        status: 'approve'
      },
      {},
      function () {
        toastr.success('You have approved this catalog.');
        // Get the item details
        // and check the new status
        manageItemDetail.get({id: $scope.itemDetail._id},
          function (resource) {
            if (!!resource && !!resource.content && !!resource.content.data &&
                !!resource.content.data.status) {

              var status = resource.content.data.status;
              //Store item Import id to fadeout on approved table
              HighlightId.setId($scope.itemDetail._id);
              $state.go('main.user.catalogs.manageCatalog', { status: status.toLowerCase() });
            } else {
              $state.go('main.user.catalogs.manageCatalog', {status: 'pending'});
            }
          },
          function () {
            //Store catalog id to fadeout on pending table
            HighlightId.setId($scope.itemDetail._id);
            $state.go('main.user.catalogs.manageCatalog', {status: 'pending'});
          }
        );

      }, function () {
        toastr.error('Catalog cannot be approved.');
      }
    );
  }

  // Reject Item
  function rejectItem() {
    swal({
        title: 'Confirm reject item?',
        text: 'This item will be rejected by you. Please write a reason',
        type: 'input',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: false,
        closeOnCancel: true,
        inputType: 'text',
        inputPlaceholder: 'Write a reason'
      },
      function(inputValue) {
        if(!!inputValue){
          var trimmedValue = inputValue.trim();
        }

        if (!inputValue) {
          swal.showInputError('You need to write a reason for rejecting!');
          return false;
        }

        if(trimmedValue === '') {
          swal.showInputError('You need to write a reason for rejecting!');
          return false
        }
        items.approve(
          {
            approval_id: $scope.itemDetail.approval_id,
            status: 'reject'
          },
          {
            comment: inputValue
          },
          function () {
            toastr.success('Rejected catalog');
            swal.close();

            // Get the item details
            // and check the new status
            manageItemDetail.get({id: $scope.itemDetail._id},
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data &&
                  !!resource.content.data.status) {

                  //Store Item Import id to fadeout on approved table
                  HighlightId.setId($scope.itemDetail._id);

                  if (resource.content.data.status === 'approved') {
                    $state.go('main.user.catalogs.manageCatalog', {status: 'approved'});
                  } else if (resource.content.data.status === 'PENDING') {
                    $state.go('main.user.catalogs.manageCatalog', {status: 'pending'});
                  } else {
                    $state.go('main.user.catalogs.manageCatalog', {status: 'rejected'});
                  }
                } else {
                  $state.go('main.user.catalogs.manageCatalog', {status: 'pending'});
                }
              },
              function (error) {
                //Store catalog id to fadeout on pending table
                HighlightId.setId($scope.itemDetail._id);
                $state.go('main.user.catalogs.manageCatalog', {status: 'pending'});
              }
            );
          }, function (error) {
            toastr.error('Catalog cannot be rejected.');
          }
        );
    });
  }

  function canResendCatalogEmail() {
    return $scope.itemDetail.status.toLowerCase() === 'pending' &&
           (globalFunc.findRoleInRoleAssignments($scope.profile.role_assignments, 'TENANT_SUPER_ADMIN') ||
            globalFunc.findRoleInRoleAssignments($scope.profile.role_assignments, 'ADMIN') ||
            isCreator());
  }

  function resendCatalogEmail() {
    var email_text = "Approval email will be resent to the following approvers\n\n";
    $scope.approvals.waiting_on.forEach(function (currentVal) {
      if (currentVal.is_active)
        email_text += currentVal.display_name + "\n";
    });

    swal({
      title: "Email re-sent",
      text: email_text,
      type: "success",
      showCancelButton: false,
      confirmButtonColor: "#1ab394",
      confirmButtonText: "Ok",
      timer: 5000,
      closeOnConfirm: true
    });

    return resendPendingCatalogEmail.post(
      {
        id: $scope.itemDetail._id
      },
      function (response) {
        $rootScope.$broadcast('refreshComments');
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    ).$promise;
  }

  function initialize(){
    $scope.approveLock = false;
    $state.params.status = !!$scope.itemDetail.status ? $scope.itemDetail.status.toLowerCase() : 'all';

    // Load all catalog item
    getItemList(13);
    $scope.filteredItems = $scope.itemDetailsList.slice(0, $scope.numPerPage);

    var tabData = {
      tab: $scope.$parent.tabData[0],
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);
  }

  initialize();

}

catalogsDetailCtrl.$inject = ['$scope', 'toastr', 'managedItem', '$q', '$stateParams', '$state',
                          'catalogVersion', '$filter', 'pathConstants', '$http',
                          '$uibModal', 'updateStatusCatalogsV2', 'Approvals', 'profile',
                          'items', 'manageItemDetail', 'HighlightId', 'itemDetailsListing',
                          '$rootScope', 'searchModule', 'globalFunc', 'historiesV2', 'getCatalogComments', 
                          'downloadCatalogV2Attachment', 'resendPendingCatalogEmail'];

angular.module('metabuyer')
  .controller('catalogsDetailCtrl', catalogsDetailCtrl);