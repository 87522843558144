'use strict';

angular
  .module('metabuyer')
  .directive('tenderSummary', function($uibModal) {
    return {
      restrict: 'E',
      scope: {
        initialData: '=',
        tender: '=',
        editableStatus: '='
      },
      template: '<div ng-include="templateUrlTenderSummary"></div>',
      controller: 'tenderSummaryCtrl',
      link: function($scope) {
        $scope.templateUrlTenderSummary = 'components/tenderComponents/summary/tenderSummary.html';
      }
    };
  });
