/**
 * @name modalMore
 *
 * @requires $uibModal
 *
 * @description
 * This directive is modal to display more from limited content.
 * can contain String and Array (to be table)
 *
 * @attr title
 * @attr subtitle
 * @attr type (string or array)
 * @attr data
 * @attr filter
 * sample data for array
 * {
 *   name: 'Deniel',
 *   address: 'Petaling Jaya',
 *   age: 24
 * },
 * {
 *   name: 'ini ibu budi',
 *   address: 'Sunway Damansara',
 *   age: 26
 * }
 * array can has more key and value
 *
 *
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

angular
  .module('metabuyer')
  .directive('modalMore',function($uibModal) {
    return{
      restrict: 'E',
      scope: {
        title: '@',
        subtitle: '@',
        type: '@',
        data: '=',
        filter: '='
      },
      link: function(scope, element) {

        function panel() {
          $uibModal.open({
            templateUrl: 'components/modalMore/modal-more.html',
            backdrop: 'static',
            keyboard: false,
            controller: "modalMoreController",
            resolve: {
              title: function(){
                return scope.title;
              },
              subtitle: function(){
                return scope.subtitle;
              },
              type: function() {
                return scope.type;
              },
              modalData: function() {
                return scope.data;
              },
              filter: function() {
                return scope.filter;
              }
            }
          });
        }

        element.bind("click", function($event){
          panel();
        });

      }
    };
  });
