'use strict';

//TODO: move those actions to the Action Modal (Ahmed Saleh, 16/12/16)
angular
  .module('metabuyer')
  .directive('cancelPo',function($uibModal) {
    return{
      restrict: 'A',
      scope: true,
      link: function(scope) {
        var panel = function () {
          var modalInstance = $uibModal.open({
            templateUrl: 'components/cancelPO/cancelPOTemplate.html',
            backdrop: 'static',
            keyboard: false,
            controller: 'CancelPOCtrl',
            scope: scope
          });
        };

        //loading the panel
        scope.showCancelPO = function () {
          panel();
        };
      }
    };
  });
