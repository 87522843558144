'use strict';

angular.module('metabuyer.services.itemsCategoriesServices', ['ngResource'])
    .factory('itemsCategories', ['$resource', 'pathConstants',
        function($resource, pathConstants) {
          return $resource(
            pathConstants.apiUrls.itemsCategories.list,
            {
              code: '@code'
            }, {
              'get': {method: 'GET'}
            });
        }]);
