'use strict';

/**
 * Controller for bifrost management
 * @description
 * Contains functions for handling tabs and table columns logic
 *
 * @param $scope
 * @param $state
 * @param dataList
 * @param type
 * @param $http
 * @param pathConstants
 * @param $filter
 * @param toastr
 * @watch $stateChangeStart
 * Watches on the start of state change, set status and params
 *
 * @author Ling Nai Shin <ling_naishin@hotmail.com>
 * @copyright 2018 Metacloud Sdn. Bhd.
 */
function bifrostPurchaseOrderCtrl($scope, $state, dataList, type, $http, pathConstants, $filter, toastr) {
  $scope.$emit('pageTitleChanged', 'Bifrost Listing');
  $scope.tabData = [];
  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.title = type;
  $scope.showColumn = showColumn;
  $scope.exportPurchaseOrderOutboundInfo = exportPurchaseOrderOutboundInfo;
  $scope.deleteBifrostPurchaseOrder = deleteBifrostPurchaseOrder;
  $scope.updateBifrostPurchaseOrderInteractable = updateBifrostPurchaseOrderInteractable;

  function showColumn(id) {
    var i;
    var columns = $scope.columns;
    for (i in columns) {
      if(columns.hasOwnProperty(i)) {
        if (id === columns[i].id) {
          if(!!columns[i].conditional) {
            if($scope.status === columns[i].condition)
              return columns[i].selected;
            if(columns[i].condition instanceof Array) {
              var j;
              var columnCondition = $scope.columns[i].condition;
              for(j in columnCondition) {
                if(columnCondition.hasOwnProperty(j)) {
                  if($scope.status === columnCondition[j]) {
                    return columns[i].selected;
                  }
                }
              }
            }
          }
          else
            return columns[i].selected;
        }
      }
    }
  }

  function exportPurchaseOrderOutboundInfo() {
    $http.post(pathConstants.apiUrls.bifrost.export, {
      module: 'purchase-order'
    }).then(function (response) {
      var blob = new Blob([response.data]);
      var link = document.createElement('a');
      document.body.appendChild(link);
      link.href = window.URL.createObjectURL(blob);
      link.download = 'PurchaseOrderOutboundInfo-' +
        $filter('date')(new Date(), 'yyyyMMdd') +'.csv';
      link.click();
      toastr.success('Purchase Order Outbound Info file has been successfully generated.');
    }, function () {
      toastr.error('Failed to generate purchase order outbound info file.');
    });
  }

  function deleteBifrostPurchaseOrder(poNumber){
    swal({
      title: 'Confirm to delete this PO? ' + poNumber,
      text: 'This action is not revertable.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Confirm',
      closeOnConfirm: true,
    }, function (isConfirm) {
      if (isConfirm) {
        confirmDelete(poNumber);
      }
    });
  }

  function confirmDelete(poNumber) {
    var path = $filter('format')(pathConstants.apiUrls.bifrost.purchaseOrder);
    $http.delete(
      path,
      {
        params: {
          po_number: poNumber,
        }
      }
    ).then(function onSuccess(response){
      toastr.success("PO has been successfully deleted.");
      $state.reload();
      close();
    }).catch(function onError (error){
      toastr.error("Failed to delete PO.");
    });
  }

  $scope.$on('$stateChangeStart', function( event, data, toParams ) {
    if (!!toParams.status) {
      $scope.parentStateParams = toParams;
    }
  });

  function updateBifrostPurchaseOrderInteractable(data) {
    var path = $filter('format')(pathConstants.apiUrls.bifrost.purchaseOrder) + '/interactable';
    $http.put(
      path,
      {
          po_number: data.po_number,
          interaction_disabled: !!data.interaction_disabled_checked,
      }
    ).then(function onSuccess(response){
      toastr.success(response.data.message);
    }).catch(function onError (error){
      toastr.error("Failed to update PO interaction.");
    });
  }

  initialize();

  function initialize() {
    $scope.dataList.forEach(function (item) {
      item.interaction_disabled_checked = !!item.interaction_disabled;
    });
  }

}

bifrostPurchaseOrderCtrl.$inject = ['$scope', '$state', 'dataList', 'type', '$http', 'pathConstants', '$filter', 'toastr'];

angular.module('metabuyer')
  .controller('bifrostPurchaseOrderCtrl', bifrostPurchaseOrderCtrl);
