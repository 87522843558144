'use strict';
angular
  .module('metabuyer')
  .directive('tricheckbox', ['$filter', '$document', '$compile', '$parse',
    function ($filter, $document, $compile, $parse) {
      return {
        restrict: 'A',
        link: function (scope, element, attribute) {
          jQuery(element).checkboxX();
        }
      };
    }]);
