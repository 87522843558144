/**
 * @name companyGroupsManagement
 *
 * @requires $uibModal
 *
 * @description
 * A directive to display the company group management modal
 *
 *
 *
 * @authors Ahmed Saleh (a.s.alsalali@gmail.com
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

angular
  .module('metabuyer')
  .directive('companyGroupsManagement',function($uibModal) {
    return{
      restrict: 'EA',
      scope: {},
      link: function(scope, element) {

        function panel() {
          $uibModal.open({
            templateUrl: 'components/companyGroupsManagement/companyGroupsManagementTemplate.html',
            controller: 'companyGroupsManagementCtrl',
            size: 'lg',
            keyboard: false,
            backdrop: 'static',
            resolve: {
              dataList: function ($q, searchModule, globalFunc) {
                var deferred = $q.defer();
                searchModule.get({
                  module: 'catalog-groups',
                  offset: 0
                  },
                  function (resource) {
                    if (!!resource.content) {
                      deferred.resolve(resource.content);
                    } else {
                      deferred.resolve([]);
                    }
                  }, function (error) {
                    globalFunc.objectErrorMessage(error);
                    deferred.resolve([]);
                  });
                return deferred.promise;
              },
              companyGroup: function($q, searchModule, globalFunc) {
                var deferred = $q.defer();

                searchModule.get(
                  {
                    module: 'companies',
                    offset: 0
                  },
                  function (resource) {
                    if (!!resource.content) {
                      deferred.resolve(resource.content);
                    } else {
                      deferred.resolve([]);
                    }
                  },
                  function (error) {
                    globalFunc.objectErrorMessage(error);
                    deferred.resolve([]);
                  }
                );
                return deferred.promise;
              }
            }
          });
        }

        element.bind('click', function($event){
          panel();
        });

      }
    };
  });
