'use strict';

/**
 * Main activity module controller
 *
 * @param $scope
 * @param company
 * @param globalFunc
 * @param searchModule
 * @param $state
 */
function activitiesCtrl($scope, company, globalFunc, searchModule, $state, exportSearch, $rootScope) {
  $scope.companyDetails = company.data;
  $scope.firstLoad = false;
  $scope.dataList = [];

  $scope.loadActivities = loadActivities;
  $scope.getStatusName = getStatusName;
  $scope.showChildState = showChildState;
  $scope.searchService = searchModule;

  $scope.showColumn = showColumn;
  $scope.columns = [
    {id: 'reference', label: 'MB Code', unsearchable: true, unsortable: true},
    {id: 'code', label: 'Activity Code', unsearchable: true, unsortable: true},
    {id: 'name', label: 'Activity Name', unsearchable: true, unsortable: true},
    {id: 'group_code', label: 'Activity Group Code', unsearchable: true, unsortable: true},
    {id: 'cost_center.code', label: 'Cost Center Code', unsearchable: true, unsortable: true},
    {id: 'cost_center.descr', label: 'Cost Center Name', unsearchable: true, unsortable: true},
    {id: 'current_month_amount', label: 'Current Month Amount', unsearchable: true, unsortable: true},
    {id: 'year', label: 'Current Year', unsearchable: true, unsortable: true},
    {id: 'is_active', label: 'Status', unsearchable: true}
  ];

  $scope.storageKey = 'activities-cost-center-manage-view';

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {

        return $scope.columns[i].selected;
      }
    }
  }

  function showChildState() {
    return $state.includes('main.user.companiesMgmt.detailCompanies.activities.details');
  }

  function loadActivities(query, firstLoad) {
    $scope.embeddedParams = {
      module: 'activity',
      'criteria[1][cost_center|descr]': query,
      'criteria[1][cost_center|code]': query,
      'criteria[1][code]': query,
      'criteria[1][reference]': query,
      'criteria[1][name]': query,
      'criteria[1][year]': query,
      'criteria[1][$operator]': 'or',
      'criteria[0][company|code]': $scope.companyDetails.code
    };

    exportSearch.setParams($scope.embeddedParams);
    $rootScope.$broadcast('disablePaginationButtons', true);

    searchModule.get(
      $scope.embeddedParams, function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.dataList = resource.content.data;
          $scope.metaData = resource.content.meta.cursor;
          prepareImportParams();
          if (!firstLoad)
            $rootScope.$broadcast('resetPagePagination', resource.content);
        }
      }, function (error) {
        globalFunc.objectErrorMessage(error);
        $scope.dataList = [];
        $scope.metaData = {};
      }).$promise.finally(function () {
      $rootScope.$broadcast('disablePaginationButtons', false);
      if (firstLoad)
        $scope.firstLoad = false;
    });
  }

  function getStatusName(code) {
    return !!code ? 'Active' : 'Deactivated';
  }

  function prepareImportParams () {
    $scope.params = {
        'class': 'App\\Metabuyer\\Activity\\Models\\Activity'
    };
    _.forEach ($scope.dataList, function(activity, index){
        var temp = 'additional_params[]['+ index+ ']';
        $scope.params[temp] = activity.code;
    });
}

  $scope.$on('updatedActivityTitle', function (event, data) {
    $scope.activityTitle = data;
  });

  function init() {
    $scope.firstLoad = true;
    loadActivities(null, true);
  }

  init();
}

activitiesCtrl.$inject = ['$scope', 'company', 'globalFunc', 'searchModule', '$state', 'exportSearch', '$rootScope'];

angular.module('metabuyer')
.controller('activitiesCtrl', activitiesCtrl);
