'use strict';

angular
  .module('metabuyer')
  .directive('tenderApproverList', function($uibModal) {
    return {
      restrict: 'E',
      scope: {
        initialData: '=',
        tender: '=',
        editableStatus: '='
      },
      template: '<div ng-include="templateUrlTenderApproverList"></div>',
      controller: 'tenderApproverListCtrl',
      link: function($scope) {
        $scope.templateUrlTenderApproverList = 'components/tenderComponents/approverList/tenderApproverListTemplate.html';
      }
    };
  });
