'use strict';

function focModalCtrl($scope, $rootScope, $uibModalInstance, items, uomList, toastr, lang, pathConstants, $http, $filter) {
    $scope.focItems = [];
    $scope.item = {};
    $scope.removeImage = removeImage;
    $scope.close = close;
    $scope.addFocItem = addFocItem;
    $scope.searchCatalog = searchCatalog;
    $scope.loadUOMs = loadUOMs;
    $scope.formSubmitted = false;
    $scope.prepareFocItem = prepareFocItem;
    $scope.validateFocItem = validateFocItem;
    $scope.onItemSelect = onItemSelect;
    $scope.deselectItem = deselectItem;
    $scope.clearForm = clearForm;
    $scope.openDatepicker = openDatepicker;
    $scope.items = items;
    var groupIds = [];
    $scope.UOMList = [];

    $scope.startDateOptions = {
      formatYear: 'yy',
      startingDay: 1
    };

    $scope.endDateOptions = {
      formatYear: 'yy',
      startingDay: 1
    };

    $scope.datepickerOpened = false;

    $scope.neededByDateOptions = {
      formatYear: 'yy',
      startingDay: 1
    };

    /**
     * For date picker
     *
     * @param {Object} $event
     */
    function openDatepicker($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.datepickerOpened = true;
    }

    function clearForm(){
        $scope.item = {};
    }

    function onItemSelect(item){
        $scope.item.selectedUOM = _.find($scope.UOMList, function(e){
          return item.uom_code === e.code;
        });
        $scope.item.selected = item;
        $scope.item.uom_disabled = item.uom_disabled;
    }

    function deselectItem(){
        if (!!$scope.item.selected){
            $scope.item.selected = undefined;
            $scope.item.searchText = '';
        }
    }

    function searchCatalog(query) {
        $scope.meta = {};

        var additionalParams = {
          'criteria[0][$operator]': 'and',
          'criteria[0][company|code]': $scope.items[0].company.code,
          'criteria[1][item_master_code]': query,
          'criteria[1][name]': query,
          'criteria[1][$operator]': 'or',
          'criteria[2][expense_type_category]': 'OPEX',
          'criteria[2][$operator]': 'and',
          'criteria[3][is_active]': true,
          'criteria_operator': 'and',
          offset: 0
        };

        var moduleParam = {
          module: 'company-item'
        };

        return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
          params: additionalParams
        }).then(function (response) {
          $scope.loading = false;
          if (response.data.content.data.length === 0) {
            return [];
          } else {
            return response.data.content.data.map(function (item) {
              $scope.loading = false;
              return item;
            });
          }
        }, function (error) {
          globalFunc.objectErrorMessage(error);
        });
    };

    function loadUOMs() {
        var localStorageTaxes = JSON.parse(window.localStorage.getItem('UOMs'));
        if (!localStorageTaxes) {
            uomList.get(
            {},
            function (resource) {
                if (!!resource.content && !!resource.content.data) {
                window.localStorage.setItem('UOMs', JSON.stringify(resource.content.data));
                $scope.UOMList = resource.content.data;
                }
            }
            );
        }
        else
            $scope.UOMList = localStorageTaxes;
    }

    function removeImage(){
        $scope.item.selected.image = null;
        var fileElem = angular.element('#focImage');
        angular.element(fileElem).val(null);
    };

    function close(){
        $uibModalInstance.close();
    };

    function addFocItem(){
        $scope.formSubmitted = true;
        if (!validateFocItem()) {
            var newItem = prepareFocItem();
            items.push(newItem)
            $uibModalInstance.close();
        }
    };

    function validateFocItem() {
        var anyError = false;
        if (!$scope.item.selected.name) {
            toastr.error(lang.validation.required.input + 'item Name');
            anyError = true;
        }
        if (!$scope.item.selected.descr) {
            toastr.error(lang.validation.required.input + 'item Description');
            anyError = true;
        }
        if (!$scope.item.selectedUOM) {
            toastr.error(lang.validation.required.input + 'UOM');
            anyError = true;
        }
        if (!$scope.item.selected.qty) {
            toastr.error(lang.validation.required.input + 'quantity');
            anyError = true;
        }
        if (!$scope.item.needed_by_date) {
            toastr.error(lang.validation.required.input + 'needed by date');
            anyError = true;
        }
        if (!$scope.item.selected.lead_time) {
          toastr.error(lang.validation.required.input + 'lead time');
          anyError = true;
      }

        return anyError;
    }

    function prepareFocItem() {
        return {
            '_id': $scope.item.selected.id,
            'catalog_item_uuid': $scope.item.selected.item_uuid,
            'is_catalog_item' : false,
            'name': $scope.item.selected.name,
            'description': $scope.item.selected.descr ? $scope.item.selected.descr : '',
            'category': {
                'code': $scope.item.selected.item_category.code,
                'name': $scope.item.selected.item_category.name,
                'descr': $scope.item.selected.item_category.descr,
            },
            'item_uuid' : $scope.item.selected.uuid,
            'item_code': $scope.item.selected.item_master_code,
            'item_description': $scope.item.selected.descr,
            'uom': $scope.item.selectedUOM,
            'qty': Number($scope.item.selected.qty),
            'revised_unit_price': 0,
            'calculatedDiscount': 0,
            'foc_item': true,
            'discount': 0,
            'tax': {'value': 0},
            'lead_time': $scope.item.selected.lead_time ? $scope.item.selected.lead_time : '',
            'mode_of_purchase' : $scope.items[0].mode_of_purchase,
            'supplier': {
                '_id': $scope.items[0].supplier._id,
                'code': $scope.items[0].supplier.code,
                'descr': $scope.items[0].supplier.descr,
                'supplier_part_no': $scope.items[0].supplier.supplier_part_no ? $scope.items[0].supplier.supplier_part_no : '',
            },
            'note_to_supplier': $scope.item.selected.note ? $scope.item.selected.note : '',
            'packing_info': $scope.item.selected.packing_information ? $scope.item.selected.packing_information : '',
            'img_url' : !!$scope.item.selected.image ? $scope.item.selected.image : '',
            'consolidation_uuid' : items[0].consolidation_uuid,
            'company' : _.cloneDeep(items[0].company),
            'needed_by_date': $scope.item.needed_by_date,
            'is_limit_qty' : false,
            'newFoc': true,
            'is_stock': $scope.item.selected.is_stock,
            'foc_source': 'NEW_ITEM'
        }
    }

    function initialize() {
        /*
          Get all user's catalog group
        */
        _.forEach($rootScope.currentUser.companies, function (company) {
          _.forEach(company.catalog_groups, function (group) {
            if (groupIds.indexOf(group._id) < 0) {
              groupIds.push(group._id);
            }
          })
        });

        loadUOMs();
    }

    initialize()
}

focModalCtrl.$inject = [
    '$scope', '$rootScope', '$uibModalInstance', 'items', 'uomList', 'toastr', 'lang', 'pathConstants', '$http', '$filter'
]

angular
  .module('metabuyer')
  .controller('focModalCtrl', focModalCtrl);
