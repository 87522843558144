/**
 * @name embeddedTableHeader
 *
 *
 * @description
 *
 * Directive to handle the Sorting in listing (detail) pages
 *
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

angular.module('metabuyer')
  .directive('embeddedTableHeader', function () {
    return {
      scope: {
        availableHeaders: '=',
        list: '=',
        service: '=',
        paramsSource: '='
      },
      templateUrl: 'components/embeddedTableHeader/embeddedTableHeaderTemplate.html',
      restrict: 'A',
      controller: 'embeddedTableHeaderCtrl'
    }
  })

  /**
   * @name embeddedTableHeaderCtrl
   *
   * @description
   * The controller of the tableHeader directive handles sorting/resetting in an embedded page
   *
   * @author Ahmed Saleh <a.saleh@vventures.asia>
   * @copyright Sunway Metacloud &copy; 2016
   */
  .controller('embeddedTableHeaderCtrl', function($scope,$rootScope) {
    $scope.sort = sort;
    $scope.checkSelectionAndCondition = checkSelectionAndCondition;

    function sort(col) {
      /**
       * check if the header is a sortable type or not
       */
      if (!col.unsortable) {
        var orderBy;
        var order;
        if ($scope.orderBy !== col.id) {
          /**
           * a new header is selected
           */
          orderBy = col.id;
          order = 1;
        } else if ($scope.order === 0) {
          /**
           * the same header is selected and the sort needs to be discarded
           */
          orderBy = null;
          order = null;
        } else {
          /**
           * the same header is selected and the sort needs to be ascending
           */
          orderBy = col.id;
          order = ($scope.order === null ? 1 : 0);
        }
        var params = $scope.paramsSource;
        angular.extend(params, {order: order}, {order_by: orderBy});
        $scope.service.get(
          params,
          function (resource) {
            if (!!resource.content) {
              $scope.list = resource.content.data;
              setSortingInformation(orderBy, order);
              $rootScope.$broadcast('resetPagination', resource.content);
              $rootScope.$broadcast('updateListing', $scope.list);
            }
          },
          function () {
            setSortingInformation(orderBy, order);
          }
        );
      }
    }

    function checkSelectionAndCondition(col) {
      if (col.conditional === true) {
        if (col.condition === $scope.condition) {
          return col.selected;
        }
        return false;
      } else {
        return col.selected;
      }
    }


    function setSortingInformation(orderBy, order) {
      $scope.sortedCol = orderBy;
      $scope.orderBy = orderBy;
      $scope.order = Number(order);
      if ($scope.order !== 0 && $scope.order !== 1) {
        $scope.order = null;
      }
    }

  });
