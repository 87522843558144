'use strict';

/**
 * Controller for catalog items listing
 * @param $scope
 * @param $state
 * @param dataList
 * @param $uibModal
 * @param type
 */

function bifrostListCtrl($scope, $state, dataList, $uibModal, type, pathConstants, $http, $rootScope, globalFunc, toastr) {
  $scope.title = type;
  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.openDataEntries = openDataEntries;
  $scope.openDataDetails = openDataDetails;
  $scope.showColumn = showColumn;
  $scope.restartDataSync = restartDataSync;
  $scope.isTenantSuperAdmin = isTenantSuperAdmin;
  $scope.isAdmin = isAdmin;

  var templateUrl = 'app/bifrostManagement/detailsModal/detailsModal.html';
  if (_.isEqual($scope.title,'Data sync')) {
    templateUrl = 'app/bifrostManagement/detailsModal/dataSyncModal.html'
  }

  function openDataEntries(data) {
    $uibModal.open({
      templateUrl: 'app/bifrostManagement/entriesModal/entriesModal.html',
      backdrop: 'static',
      keyboard: false,
      controller: bifrostEntriesCtrl,
      size: 'lg',
      windowClass: 'revision-wrapper',
      resolve: {
        data:function(){
          return data;
        }
      }
    });
  }

  function openDataDetails(data) {
    $uibModal.open({
      templateUrl: templateUrl,
      backdrop: 'static',
      keyboard: false,
      controller: bifrostDetailsCtrl,
      size: 'lg',
      windowClass: 'revision-wrapper',
      resolve: {
        data:function(){
          return data;
        }
      }
    });
  }

  function showColumn (id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function restartDataSync() {
    if (!!isTenantSuperAdmin || !!isAdmin) {
      $http.put(pathConstants.apiUrls.maintenance.restartDataSync).then(function (response) {
        toastr.success(response.data.message);
      }, function (error) {
        if (parseInt(error.data.http_code) === 500) {
          toastr.warning('Data Sync is running');
        } else {
          toastr.error('Data Sync failed to restart');
        }
      });
    }
  }

  function isTenantSuperAdmin() {
    return globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN');
  }

  function isAdmin() {
    return globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'ADMIN');
  }


}

bifrostListCtrl.$inject = ['$scope', '$state', 'dataList', '$uibModal', 'type', 'pathConstants', '$http', '$rootScope', 'globalFunc', 'toastr'];

angular.module('metabuyer')
  .controller('bifrostListCtrl', bifrostListCtrl);
