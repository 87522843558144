'use strict';
angular
.module('metabuyer')
.directive('approvalFlowTemp', function(notify,$stateParams){
  return {
    restrict: 'E',
    scope: {
      data: "=data"
    },
    controller: function ($scope) {
      if (!!$scope.data) {

        var setCurrentStep = false;
        var steps = _.keys($scope.data);

        for (var index = steps.length - 1; index >= 0; --index) {
          var approvals = $scope.data[steps[index]];
          var currentStep = _.all(approvals, function (item) {
            return !!item.status
          });
          if (currentStep && !setCurrentStep) {
            approvals.current = true;
          }
          approvals.current = false;
        }

        if (!setCurrentStep && !!steps[0]) {
          $scope.data[steps[0]].current = true;
        }
      }
    },
    replace: true,
    templateUrl: 'components/approvalflow/approvalflow-panel.html'
  };
});
