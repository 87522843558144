'use strict';

/**
 * @name companyContentCtrl
 * @desc Controller for managing company placeholder
 *
 * @author Justin Cheong <justin.cty90@gmail.com>
 * @copyright 2018 Metacloud Sdn. Bhd.
 */
function companyContentCtrl($scope, $rootScope, $state, globalFunc, Companies,
                            companyGlobalFunc, toastr, pdfPreview, $filter, pathConstants, companiesDetailImage, $auth, allCompanies) {

  $scope.back = back;
  $scope.saveTermsAndConditions = saveTermsAndConditions;
  $scope.isUploadImageSuccess = isUploadImageSuccess;
  $scope.availableCompanies = [];

  /**
   * Goes back to company details
   */
  function back() {
    if (!!$scope.companyDetails && !!$scope.companyDetails._id)
      $state.go('main.user.companiesMgmt.detailCompanies', {id: $scope.companyDetails._id});
    else
      window.history.back();
  }

  /**
   * Save the terms and conditions
   *
   * @param {string} termsAndConditionsLevel
   * @param {string} customTermsAndConditions
   * @param {boolean} isPreview
   */
  function saveTermsAndConditions(termsAndConditionsLevel, customTermsAndConditions, isPreview) {
    var submitData = {};
    submitData.descr = $scope.companyDetails.descr;
    submitData.print_logo = $scope.companyDetails.print_logo.value;
    submitData.parent_company_id = (!!$scope.companyDetails.parent_company && !!$scope.companyDetails.parent_company.length > 0) ? companyData.parent_company[0]._id : null;
    submitData.currencies = companyGlobalFunc.reformatCurrenciesSelected($scope.companyDetails);
    submitData.currency_code = $scope.companyDetails.currency_code.code;
    submitData.fax = $scope.companyDetails.fax;
    submitData.email = $scope.companyDetails.email;
    submitData.phone = $scope.companyDetails.phone;
    submitData.corresponding_address_id = $scope.companyDetails.corresponding_address._id;
    submitData.PO_initial_status = $scope.companyDetails.PO_initial_status ? $scope.companyDetails.PO_initial_status.value : null;
    submitData.expense_types = globalFunc.getArrayOfKeyValues($scope.companyDetails.expense_types, '_id');
    submitData.allowedToHoldPR = $scope.companyDetails.config.allowedToHoldPR;
    submitData.billingCompanies = $scope.companyDetails.billingCompanies;
    submitData.defaultBillingCompany = $scope.companyDetails.defaultBillingCompany;
    submitData.budget_setting = $scope.companyDetails.budget_setting;
    submitData.terms_and_conditions_level = termsAndConditionsLevel;
    submitData.terms_and_conditions = customTermsAndConditions;
    submitData.company_country = !!$scope.companyDetails.selectedCountryCode ? $scope.companyDetails.selectedCountryCode.descr : '';
    submitData.po_text = $scope.companyDetails.po_text;
    submitData.website_url = $scope.companyDetails.website_url;
    submitData.po_footer_line_1 = $scope.companyDetails.po_footer_line_1;
    submitData.po_footer_line_2 = $scope.companyDetails.po_footer_line_2;
    submitData.po_billing_company_id = ($scope.companyDetails.po_billing_company && $scope.companyDetails.po_billing_company._id) || '';
    submitData.default_cost_center_code = !!$scope.companyDetails.default_cost_center ? $scope.companyDetails.default_cost_center.code : undefined;

    Companies.put(
      {
        id: $scope.companyDetails._id
      }, submitData,
      function (resource) {
        toastr.success((!!resource.content && !!resource.content.message && _.isString(resource.content.message))
          ? resource.content.message : 'Company updated.');
        if (!!isPreview) {
          previewPDF(termsAndConditionsLevel);
        }
        else {
          $state.go('main.user.companiesMgmt.detailCompanies', {id: $scope.companyDetails._id});
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  /**
   * Generates a preview pdf
   *
   * @param {string} termsAndConditionsLevel
   */
  function previewPDF(termsAndConditionsLevel) {
    pdfPreview.post(
      {
        id: termsAndConditionsLevel === 'tenant' ? $scope.companyDetails.tenant_id : $scope.companyDetails._id
      },
      {
        terms_and_conditions_level: termsAndConditionsLevel,
        company_code: $scope.companyDetails.code
      },
      function (resource) {
        var newUrl = $filter('format')(pathConstants.apiUrls.globalPDFHashDownload, {
          pdf_hash: resource.url
        });

        setTimeout(function () {
          window.open(newUrl, '_blank')
        }, 1001);
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function initialize() {
    var tabData = {
      tab: $scope.$parent.tabData[0],
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);

    $scope.companyDetails = companyGlobalFunc.getCompanyData();
    $scope.termsAndConditionsLevel = $scope.companyDetails.terms_and_conditions_level;
    $scope.tenantTermsAndConditions = $scope.companyDetails.tenant_terms_and_conditions;
    $scope.customTermsAndConditions = $scope.companyDetails.terms_and_conditions;

    $scope.genpAdminFeatureEnable = $rootScope.genpAdminFeatureEnable;

    $scope.imageBasePath = pathConstants.apiUrls.image.user;
    // config flowjs
    $scope.flowConfig = new Flow({
      target: companiesDetailImage.uploadImagePath($scope.companyDetails._id, 'pofooter'),
      singleFile: true,
      testChunks: false,
      chunkSize: 10000000,
      headers: {
        'Authorization': 'Bearer ' + $auth.getToken()
      }
    });

    var excludedCompany = [$scope.companyDetails._id];
    _.forEach($scope.companyDetails.shadow_companies, function (company) {
      excludedCompany.push(company._id);
    });

    $scope.availableCompanies = _.cloneDeep(allCompanies);
    // remove self and assigned shadow companies
    _.remove($scope.availableCompanies, function(company) {
      return excludedCompany.includes(company._id);
    });
    $scope.availableCompanies.unshift({
      _id: '',
      code: '',
      descr: ''
    });
  }

  function isUploadImageSuccess(file, message) {
    if (message) {
      var response = JSON.parse(message);
      toastr.success(response.content.message);
    }
  }

  initialize();
}

companyContentCtrl.$inject = [
  '$scope', '$rootScope', '$state', 'globalFunc', 'Companies', 'companyGlobalFunc',
  'toastr', 'pdfPreview', '$filter', 'pathConstants', 'companiesDetailImage', '$auth',
  'allCompanies'
];

angular.module('metabuyer')
  .controller('companyContentCtrl', companyContentCtrl);
