'use strict';
angular
  .module('metabuyer')
  .directive('progressItem', function(notify, $stateParams, $timeout) {
    return {
      restrict: 'EA',
      scope: {
        value: '=',
        default: '='
      },
      template: '<progressbar value="progress" type="{{checkType(progress)}}" animate="true">{{ progress }}%</progressbar>',
      link: function ($scope, $element, $attrs, $controller) {
        $scope.progress = 0;

        $timeout(function(){
          $scope.progress = !$scope.value ? 0 : parseDecimal($scope.value);
        }, 300);

        $scope.checkType = function(progress){
          var types = ['danger', 'warning', 'navy'];

          if(typeof($attrs.default) !== 'undefined'){
            return $attrs.default != '' ? $attrs.default : types[2];
          }

          var index = parseInt(progress/33);
          if(index > types.length-1){
            return types[types.length-1];
          }
          return types[index];
        }

        function parseDecimal(value){
          return parseInt(value.toFixed(0));
        }
      }
    };
  });

