'use strict';

angular.module('metabuyer.services.users', ['ngResource'])
  .factory('Users', function($resource, $filter, pathConstants){
    //TODO: refactor and simplify this part as well as update the function that uses this resource (Ahmed Saleh)
    var Users = $resource(
      pathConstants.apiUrls.users.list,
      {
        status: '@status'
      },
      {
        'get': {method: 'GET'},
        'post': {method: 'POST'}
      }
    );
    return Users;
  })
  .factory('searchUser', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.search,
      {
        query: '@text'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('singleUser', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.single,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'},
        'post': {method: 'POST'},
        'put': {method: 'PUT'}
      }
    );
  })
  .factory('singleDelegation', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.delegation.single,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('passwordUpdate',
  function($resource, pathConstants, $translate) {
    return $resource(
      pathConstants.apiUrls.users.updatingPassword,
      {
      },
      {
        'put': { method: 'PUT', headers: {'Accept-Language': $translate.use() } }
      }
    );
  })
  .factory('forgottenPassword',
  function($resource, pathConstants, $translate) {
    return $resource(
      pathConstants.apiUrls.users.forgottenPassword,
      {
      },
      {
        'post': { method: 'POST', headers: {'Accept-Language': $translate.use() } }
      }
    );
  })
  .factory('activeUsers', function($resource, $filter, pathConstants){
    return $resource(
      pathConstants.apiUrls.users.activeUsers,
      {

      },
      {
        'get': {method: 'GET'},
        'delete': {method: 'DELETE'}
      }
    );
  })
  .factory('adminUnlockUser', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.adminUnlockUser,
      {
        id: '@id'
      },
      {
        'put': { method: 'PUT' }
      }
    );
  })
  .factory('userImage', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.image,
      {
        id: '@id'
      },
      {
        'get': { method: 'get' }
      }
    );
  })
  .factory('uploadImage', function($resource, $filter, pathConstants) {
    return{
      uploadImagePath: function (userID) {
        var path = pathConstants.apiUrls.users.image;
        return path.replace(":id", userID);
      }
    }
  })
  .factory('exportUserAssignments', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.exportAssignments,
      {},
      {
        'get': { method: 'get' }
      }
    );
  })
  .factory('userBudget', function ($resource, $filter, pathConstants) {
    var budgetUsers = $resource(
      pathConstants.apiUrls.users.budgets,
      {},
      {
        'get': {method: 'GET', ignoreLoadingBar: true}
      }
    );
    return budgetUsers;
  })
  .factory('userPendingApproval', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.pendingApproval,
      {
        userId: '@userId',
        companyCode: '@companyCode'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('supplierWebUser', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.supplierWeb,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'},
        'put': {method: 'PUT'}
      }
    );
  })
  .factory('supplierWebUserTnc', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.supplierWebTnc,
      {
        id: '@id'
      },
      {
        'put': {method: 'PUT'}
      }
    );
  })
  .factory('supplierUserHistory', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.supplierWebUserHistory,
      {},
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('supplierWebUserStatus', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.supplierWebUserStatus,
      {
        id: '@id'
      },
      {
        'put': {method: 'PUT'}
      }
    );
  })
  .factory('supplierWebUserResetPassword', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.supplierWebUserResetPassword,
      {
        id: '@id'
      },
      {
        'put': {method: 'PUT'}
      }
    );
  })
  .factory('setAsMetabuyerUser', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.setAsMetabuyerUser,
      {
        id: '@id'
      },
      {
        'put': {method: 'PUT'}
      }
    );
  })
  .factory('userRoleAssignments', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.roleAssignments,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'},
        'put': {method: 'PUT'}
      }
    );
  })
  .factory('approvalDelegation', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.approvals.status,
      {
        approval_id: '@approval_id',
        status: '@status'
      },
      {
        'post': {method: 'POST'}
      }
    );
  })
  .factory('userApprovalTrack', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.approvalTrack,
      {
        uuid: '@uuid',
        approval_limit_uuid: '@approval_limit_uuid'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('loggedInUserApprovalTrack', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.loggedInUserApprovalTrack,
      {
        uuid: '@approval_limit_uuid'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('starItemUuid', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.starItems,
      {
      },
      {
        'put': {method: 'PUT', ignoreLoadingBar: true}
      }
    );
  })
  .factory('viewItemUuid', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.viewItems,
      {
      },
      {
        'put': {method: 'PUT', ignoreLoadingBar: true}
      }
    );
  })
  .factory('approvalLimit', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.addApprovalLimit,
      {
        uuid: '@uuid'
      },
      {
        'post': {method: 'POST'}
      }
    );
  })
  .factory('approvalLimitStatus', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.updateApprovalLimitStatus,
      {
        user_uuid: '@user_uuid',
        approval_limit_uuid: '@approval_limit_uuid'
      },
      {
        'put': {method: 'PUT'}
      }
    );
  })
  .factory('userApprovalLimitsBalance', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.getUserApprovalLimitsBalance,
      {
        user_uuid: '@user_uuid',
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('userCategories', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.categories,
      {
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('userTimezones', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.timezones,
      {
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('securityQuestion',
  function($resource, pathConstants, $translate) {
    return $resource(
      pathConstants.apiUrls.users.securityQuestion,
      {
      },
      {
        'put': { method: 'PUT', headers: {'Accept-Language': $translate.use() } },
      }
    )
  })
  .factory('getSecurityQuestionList',
  function($resource, pathConstants, $translate) {
    return $resource(
      pathConstants.apiUrls.users.getSecurityQuestionList,
      {
      },
      {
        'get': { method: 'GET', headers: {'Accept-Language': $translate.use() } },
      }
    )
  })
  .factory('getSecurityQuestionByEmail',
  function($resource, pathConstants, $translate) {
    return $resource(
      pathConstants.apiUrls.users.getSecurityQuestionByEmail,
      {
      },
      {
        'get': { method: 'GET', headers: {'Accept-Language': $translate.use() } }
      }
    );
  })
  .factory('submitSecurityQuestionByEmail',
  function($resource, pathConstants, $translate) {
    return $resource(
      pathConstants.apiUrls.users.submitSecurityQuestionByEmail,
      {
      },
      {
        'post': { method: 'POST', headers: {'Accept-Language': $translate.use() } }
      }
    );
  })
  .factory('twoFACheck',
  function($resource, pathConstants, $translate) {
    return $resource(
      pathConstants.apiUrls.twoFA.verifycheck,
      {
      },
      {
        'get': { method: 'GET', headers: {'Accept-Language': $translate.use() } }
      }
    )
  })
  .factory('twoFAVerifyNewKey',
  function($resource, pathConstants, $translate) {
    return $resource(
      pathConstants.apiUrls.twoFA.verifyNewKey,
      {
      },
      {
        'post': { method: 'POST', headers: {'Accept-Language': $translate.use() } }
      }
    )
  })
  .factory('twoFAVerifyOTP',
  function($resource, pathConstants, $translate) {
    return $resource(
      pathConstants.apiUrls.twoFA.verifyKey,
      {
      },
      {
        'post': { method: 'POST', headers: {'Accept-Language': $translate.use() } }
      }
    )
  });
