'use strict';

angular.module('metabuyer.services.stockInformationGroup', ['ngResource'])
  .factory('stockInformationGroupMasterDataList',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.stockInformationGroup.single,
      {
        uuid: '@uuid'
      },
      {
        'get': {method: 'GET'},
        'post': {method: 'POST'},
        'put': {method: 'PUT'}
      }
    );
  })
  .factory('setStockInformationGroup',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.stockInformationGroup.update,
      {
        company_code: '@companyCode',
        stock_info_grp_uuid: '@stockInfoGrpUuid'
      },
      {
        'post': {method: 'POST'}
      }
    );
  })
  .factory('getStockInformationGroup',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.stockInformationGroup.list,
      {},
      {
        'get': {method: 'GET'}
      }
    );
  });
