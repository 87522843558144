'use strict';

function faqV2Ctrl($scope, $rootScope, globalFunc, $http, $filter, dataList, toastr, pathConstants, searchModule, faqCommentService, faqDocumentService) {

  $scope.faqList = dataList.data;
  $scope.meta = dataList.meta.cursor;
  $scope.searchService = searchModule;
  $scope.offset = 50;
  $scope.embeddedParams = {
    module: 'faqV2',
    offset: $scope.offset,
    order_by: 'attachments|updated_at',
    order: -1,
  };
  $scope.commentService = faqCommentService;
  $scope.faqAttachments = [];
  $scope.isSubmitting = false;
  $scope.hasAccess = hasAccess();
  $scope.searchText = null;
  $scope.isLoading = false;
  $scope.orderBy = null;
  $scope.order = null;

  $scope.addAttachment = addAttachment;
  $scope.removeAttachment = removeAttachment;
  $scope.uploadCancel = uploadCancel;
  $scope.uploadConfirm = uploadConfirm;
  $scope.downloadAttachment = downloadAttachment;
  $scope.deleteAttachment = deleteAttachment;
  $scope.loadFaqList = loadFaqList;
  $scope.search = search;
  $scope.faq = {
    _id: null,
  }
  $scope.currentStatus = {
    col: null,
    order: null
  };


  $scope.columns = [
    {id: 'attachments|file_name', label: 'File Name', selected: true},
    {id: 'attachments|file_size', label: 'Size', selected: true},
    {id: 'attachments|creator_info|display_name', label: 'Uploaded By', selected: true},
    {id: 'attachments|created_at', label: 'Uploaded At', type: 'date', unsearchable: true, selected: true},
    {id: 'action', label: '', unsearchable: true, unsortable: true, selected: true}
  ];
  $scope.$emit('pageTitleChanged', 'Procurement Guidelines');

  function hasAccess() {
    var tenantSuperAdmin = globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN');
    var admin = globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'ADMIN');
    var documentManager = globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'DOCUMENT MANAGER');

    if(tenantSuperAdmin || admin || documentManager) {
      return true;
    }
    return false;
  }

  function addAttachment(element) {
    var maxSize = 20000000;
    var fileSize = element.files[0].size;

    if (fileSize > maxSize) {
      angular.element("#faqAttachment").val(null);
      toastr.error('File size uploaded has exceeded 20MB');
      return false;
    }

    var validFormats = ['.xls', '.doc', '.ppt', '.txt', '.pdf', '.jpg', '.png', '.gif', '.bmp', '.xlsx', '.docx', '.pptx', '.jpeg', '.zip', '.msg'];
    var filename = element.files[0].name;

    if (filename.length > 120) {
      angular.element("#faqAttachment").val(null);
      toastr.error('File name has exceeded 120 characters');
      return false;
    }

    var index = filename.lastIndexOf(".");
    var strsubstring = filename.substring(index, filename.length);

    if (!validFormats.includes(strsubstring)) {
      angular.element("#faqAttachment").val(null);
      toastr.error('The file must be a file of type: xls, doc, ppt, txt, pdf, jpg, png, gif, bmp, xlsx, docx, pptx, jpeg, msg, zip.');
      return false;
    }
    var attachment = element.files[0];
    attachment.createdBy = $rootScope.currentUser.display_name;
    attachment.createdAt = new Date();
    $scope.faqAttachments.push(attachment);
    $scope.$apply()
  }

  function removeAttachment(index) {
    $scope.faqAttachments.splice(index, 1);
  }

  function uploadCancel() {
    $scope.faqAttachments = [];
  }

  function uploadConfirm() {
    $scope.isSubmitting = true;
    var formData = new FormData();
    for (var i = 0; i < $scope.faqAttachments.length; i++) {
      formData.append('file[]', $scope.faqAttachments[i]);
    }

    $http.post($filter('format')(pathConstants.apiUrls.faq.v2.documents.upload), formData, {
        transformRequest: angular.identity,
        headers: { 'Content-Type': undefined }
      })
      .then(function onSuccess(response) {
        $scope.isSubmitting = false;
        angular.element("#faqAttachment").val(null);
        toastr.success('Documents published successfully.');
        $scope.faqAttachments = [];
        loadFaqList(null);
        $rootScope.$broadcast('refreshComments');
      })
      .catch(function onError(error) {
        $scope.isSubmitting = false;
        globalFunc.objectErrorMessage(error);
      });
  }

  function downloadAttachment(id) {
    faqDocumentService.get({
      id: id
    }).$promise.then(
      function(resource) {
        if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data.temporary_url) {
          var newUrl = $filter('format')(pathConstants.apiUrls.attachments.downloadAttachment, {
            hash: resource.content.data.temporary_url
          });
        }

        $http({
          url: newUrl
        }).then(function() {
          window.open(newUrl, '_self');
        }, function(response) {});
      },
      function() {
        toastr.error('Failed to download attachment.');
      }
    );

  }

  function deleteAttachment(id) {
    swal({
      title: 'Confirm to delete document?',
      text: 'This document will be permanently deleted.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#1ab394',
      confirmButtonText: 'Confirm',
      closeOnConfirm: true
    }, function(isConfirm) {
      if (isConfirm) {
        faqDocumentService.delete({
          id: id
        }).$promise.then(
          function(resource) {
            toastr.success('Successfully deleted');
            loadFaqList(null);
            $rootScope.$broadcast('refreshComments');
          },
          function() {
            toastr.error('Failed to delete attachment');
          }
        );
      }
    })
  }

  function loadFaqList(query) {
    $scope.isLoading = true;
    $scope.embeddedParams['order_by'] = $scope.orderBy;
    $scope.embeddedParams['order'] = $scope.order;

    if (query !== null) {
      $scope.embeddedParams['criteria[1][attachments.file_name]'] = query;
      $scope.embeddedParams['criteria[1][$operator]'] = 'or';
    }
    searchModule.get(
      $scope.embeddedParams,
      function (resource) {
        $scope.faqList = resource.content.data;
        $scope.meta = resource.content.meta.cursor;
        $scope.isLoading = false;
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
        $scope.loadingCompaniesList = false;
        $scope.faqList = [];
        $scope.meta = {};
        $scope.isLoading = false;
      }
    );
  }

  function search(page, sortingCol, sortingOrder) {
    $scope.orderBy = sortingCol;
    $scope.order = null;
    if (sortingOrder === 'ASC') {
      $scope.order = 1;
    } else if (sortingOrder === 'DESC') {
      $scope.order = -1;
    }
    loadFaqList($scope.searchText);
  }
}


faqV2Ctrl.$inject = ['$scope', '$rootScope', 'globalFunc', '$http', '$filter', 'dataList', 'toastr', 'pathConstants', 'searchModule', 'faqCommentService', 'faqDocumentService'];

angular
  .module('metabuyer')
  .controller('faqV2Ctrl', faqV2Ctrl);
