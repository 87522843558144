'use strict';
angular
  .module('metabuyer')
  .directive('ngrnPanel',function($uibModal) {
    return{
      restrict: 'A',
      scope: true,
      link: function(scope, element, attrs) {

        var panel = function(ngrn_id, selectedPo) {

          var modalInstance = $uibModal.open({
            templateUrl: 'components/ngrn/ngrnTemplate.html',
            backdrop: 'static',
            keyboard: false,
            controller: 'ngrnCtrl',
            scope: scope,
            size: "lg",
            resolve: {
              ngrn: function($q, getGRN){
                var defered = $q.defer();
                if(!ngrn_id){
                  defered.resolve({});
                  return defered.promise;
                }

                getGRN.get({id: ngrn_id},
                  function(resource){
                    if(!!resource && !!resource.content && !!resource.content.data){
                      defered.resolve(resource.content.data);
                    }else{
                      defered.resolve({});
                    }
                  }, function(error){
                    defered.resolve({});
                  });

                return defered.promise;
              },
              po: function(){
                return selectedPo;
              }            }
          });
        };

        //loading the panel to comment
        scope.showNGRNInfo = function(ngrn_id, selectedPo) {
          panel(ngrn_id, selectedPo);
        };
      }
    };
  });
