'use strict';

angular
  .module('metabuyer')
  .directive('tenderActionHeader', function($uibModal) {
    return {
      restrict: 'E',
      scope: {
        initialData: '=',
        tender: '=',
        editableStatus: '=',
        revisions: '='
      },
      template: '<div ng-include="templateUrl"></div>',
      controller: 'tenderActionHeaderCtrl',
      link: function($scope) {
        $scope.templateUrl = 'components/tenderComponents/actionHeader/tenderActionHeaderTemplate.html';
      }
    };
  });
