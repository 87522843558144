'use strict';
//TODO: remove safely (Ahmed Saleh, 16/3/2017)

angular.module('metabuyer')
    .factory('Cart', function($rootScope) {

        var cart = {
            items: [],

            //the case for default adding (not from details)
            defaultAddToCart: function (product, type, quantity, supplier) {
                    product.ordered_supplier = supplier;
                    product.ordered_type = product.default.pricing;
                    product.ordered_quantity = quantity;
                    product.calculations = {};
                    cart.items.push(product);

            },
            detailAddToCart: function (product, quantity, detail) {

                product.ordered_supplier = detail.supplier_name;
                product.ordered_type = detail.pricing;
                product.detailId = detail._id;
                product.ordered_quantity = quantity;
                product.calculations = {};
                cart.items.push(product);

            },
            removeFromCart: function(index){
                cart.items.splice(index,1);
            },
            emptyCart: function()
            {
                cart.items =[];
            },
            validateProduct: function(product, detail)
            {
                //checking for duplication
                //default item
                if(detail== null) {
                    for (var i = 0; i < cart.items.length; i++) {
                        if (product._id == cart.items[i]._id) {
                            return false;
                        }

                    }
                }
                //detailed Item
                else
                {
                    for (var i = 0; i < cart.items.length; i++) {
                        if (detail._id == cart.items[i].detailId) {

                            return false;
                        }

                    }

                }
                return true;
            }
        };

        return cart;
    });
