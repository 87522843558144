'use strict';

function consolidationAttachment() {
  return {
    restrict: 'E',
    templateUrl: 'components/poConsolidation/consolidationAttachment/consolidationAttachment.html',
    controller: 'consolidationAttachmentCtrl',
    scope: {
      poId: '='
    }
  }
}

function consolidationAttachmentCtrl($scope, $state, $rootScope, globalFunc, toastr, AttachmentSingle, $auth, pathConstants, $filter) {
  $scope.showDropZone = true;
  $scope.files = [];

  $scope.dropzoneConfig = {
    options: {
      // passed into the Dropzone constructor
      //maxFilesize in MB
      autoProcessQueue: false,
      maxFilesize: 20,
      url: $filter('format')(pathConstants.apiUrls.po.listAttachment, { po_id: $scope.poId }),
      // simultaneous uploads in a time
      parallelUploads: 1,
      init: function() {
        /**
         * Append attachment model name to the form data
         */
        this.on('sending', function(file, xhr, formData) {
          formData.append('model', $scope.attachmentModel);
        });

        this.on("queuecomplete", function() {
          this.options.autoProcessQueue = false;
        });

        this.on("processing", function() {
          this.options.autoProcessQueue = true;
        });

        var dropzone = this;
        $scope.$watch('poId', function(newValue, oldValue) {
          if (newValue !== oldValue) {
            if (dropzone.getQueuedFiles().length > 0) {
              dropzone.processQueue();
            } else {
              $state.go('main.user.poConsolidation.itemListing', { status: 'items' });
            }
          }
        })
      },
      headers: {
        'Authorization': 'Bearer ' + $auth.getToken()
      },
      previewTemplate: '<div class="dz-preview dz-file-preview">' +
        '<div class="dz-details">' +
        '<div class="dz-filename"><span data-dz-name></span></div>' +
        '<div class="dz-size" data-dz-size></div>' +
        '<img data-dz-thumbnail />' +
        '</div>' +
        '<div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>' +
        '<div style="z-index: 999; position: absolute; width: 30px; height: 30px; font-size: 25px; text-align: center; right: -10px; top: -10px;">' +
        '<a data-dz-remove type="button" class="btn-xs btn-white pull-right"><i class="fa fa-trash-o"></i></a>' +
        '</div>' +
        '<div class="dz-error-message"><span data-dz-errormessage></span></div>' +
        '</div>',
      acceptedFiles: '.xls, .doc, .ppt, .txt, .pdf, .jpg, .png, .gif, .bmp, .xlsx, .docx, .pptx, .jpeg, .msg'
    },
    eventHandlers: {
      processing: function(file) {
        this.options.url = $filter('format')(pathConstants.apiUrls.po.listAttachment, { po_id: $scope.poId });
      },
      sending: function(file, formData, xhr) {

      },
      error: function(file, error) {
        globalFunc.objectErrorMessage(error);
        this.removeFile(file);
      },
      success: function(file, resource) {
        if (this.getQueuedFiles().length === 0){
          toastr.success('Success');
          $state.go('main.user.poConsolidation.itemListing', { status: 'items' });
        }
      }
    }
  };

}

consolidationAttachmentCtrl.$inject = ['$scope', '$state', '$rootScope', 'globalFunc', 'toastr', 'AttachmentSingle', '$auth', 'pathConstants', '$filter'];

angular
  .module('metabuyer')
  .directive('consolidationAttachment', consolidationAttachment)
  .controller('consolidationAttachmentCtrl', consolidationAttachmentCtrl);
