'use strict';
/**
 * @name twoFAAuthenticateCtrl
 * @desc Controller for to update Security Question
 */
function twoFAAuthenticateCtrl($rootScope, $scope, $state, $location, $auth, globalFunc, userSettings, userId, ManageLocalStorage,
                              twoFACheck, twoFAVerifyNewKey, twoFAVerifyOTP) {
  $scope.user_id = userId;
  $scope.isLoading = false;
  $scope.showQrCode = false;
  $scope.twoFA = {
    qr_code: '',
    secret_key: '',
    otp: '',
    mode: '',
  }
  $scope.otps = [];


  if ($auth.isAuthenticated()) {
    getQrCode();
    $scope.showQrCode = true;
  }

  $scope.logout = function () {
    userSettings.loggedOut();
    $auth.logout();
    $state.go('login');
  }

  $scope.verifyOTP = function () {
    $scope.twoFA.otp = $scope.otps.join('');
    $scope.isLoading = true;
    if ($auth.isAuthenticated()) {
      verifyOTPNew();
      return;
    }
    verifyOTP($scope.user_id);
  }

  function verifyOTPNew() {
    $scope.isLoading = true;
    twoFAVerifyNewKey.post(
      {},
      {
        secretKey: $scope.twoFA.secret_key,
        totp: $scope.twoFA.otp,
      },
      function (response) {
        verifyOTP(response.user_id);
      },
      function (error) {
        $scope.isLoading = false;
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function verifyOTP(userId) {
    $scope.isLoading = true;
    twoFAVerifyOTP.post(
      {},
      {
        userId: userId,
        totp: $scope.twoFA.otp,
      },
      function (response) {
        var cachedEmail = window.localStorage.getItem('email');
        if (!cachedEmail || cachedEmail !== $scope.email) {
          ManageLocalStorage.clearLocalStorage('All');
        } else {
          ManageLocalStorage.clearLocalStorage();
        }

        window.localStorage.setItem('email', response.email);
        window.localStorage.setItem('userId', userId);
        if (!!response.token) {
          $auth.setToken(response.token)
        }
        var tempUrl = angular.copy($rootScope.redirectUrl);
        $rootScope.redirectUrl = null;
        if (tempUrl == null || tempUrl == '') {
          tempUrl = '/user/waiting-on-you';
        }
        $location.url(tempUrl);
      },
      function (error) {
        $scope.isLoading = false;
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function getQrCode() {
    $scope.isLoading = true;
    twoFACheck.get(
      {},
      function (response) {
        $scope.twoFA.qr_code = response.qrCode || '';
        $scope.twoFA.secret_key = response.secretKey || '';
        $scope.twoFA.mode = response.mode || '';
        $scope.isLoading = false;
      },
      function (error) {
        $scope.isLoading = false;
        globalFunc.objectErrorMessage(error);
      }
    )
  }
}

twoFAAuthenticateCtrl.$inject = ['$rootScope', '$scope', '$state', '$location', '$auth', 'globalFunc', 'userSettings', 'userId', 'ManageLocalStorage',
                                'twoFACheck', 'twoFAVerifyNewKey', 'twoFAVerifyOTP'];

angular
  .module('metabuyer')
  .controller('twoFAAuthenticateCtrl', twoFAAuthenticateCtrl);
