'use strict';

function MinionConfigCtrl($scope, reportsV2Config, reportTypes, toastr) {
  $scope.config = {};
  $scope.reports = {};
  $scope.reportTypes = reportTypes.getReportTypes();
  $scope.getReportName = getReportName;
  $scope.toggleReport = toggleReport;
  $scope.toggleMinion = toggleMinion;


  function getReportName(reportCode) {
    var reportType = _.find($scope.reportTypes, {code: reportCode.toUpperCase()});
    return reportType.descr;
  }

  function toggleMinion() {
    updateConfig($scope.config)
  }

  function toggleReport(report, value) {
    $scope.reports[report] = value;
    $scope.config.enabled_reports[report] = value;
    updateConfig($scope.config)
  }

  function updateConfig(config) {
    var payload = {
      config: config,
      isActive: true
    };
    reportsV2Config.put(payload, function (res) {
      toastr.success('Minion config updated');
    }, function (e) {
      toastr.error('Failed to update Minion config');
    })
  }

  function filterAvailableReports(reports) {
    var availableReportCodes = _.pluck($scope.reportTypes, 'code').map(function (code) {
      return code.toLowerCase();
    });

    return _.pick(reports, availableReportCodes);
  }

  function initialize() {
    reportsV2Config.get({}, function (res) {
      $scope.config = res.content.data.config;
      $scope.reports = filterAvailableReports($scope.config.enabled_reports);
    });
  }

  initialize();
}

MinionConfigCtrl.$inject = [
  '$scope', 'reportsV2Config', 'reportTypes', 'toastr'
];

angular.module('metabuyer')
  .controller('MinionConfigCtrl', MinionConfigCtrl);
