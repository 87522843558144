'use strict';


function accountAssignment (){
  return {
    restrict: 'E',
    template: '<div ng-include="myTemplateUrl"></div>',
    scope:{
      item: '=',
      editingState: '=',
      expenseType: '=',
      itemIndex: '=',
      removeOtherValue: '&?'
    },
   controller: function($scope, prV2Function, purchaseRequisitionsV2Services, toastr, $http, pathConstants,
                        PRAccountCodeList, singleAccountCodeByCode, globalFunc, $filter, glCodesList, companyCostCenters,
                        $rootScope) {
      $scope.addAccountAssignment = addAccountAssignment;
      $scope.deleteAccountAssignment = deleteAccountAssignment;
      $scope.onSelectCostCenter = onSelectCostCenter;
      $scope.onSelectAccountCode = onSelectAccountCode;
      $scope.onSelectOpexIO = onSelectOpexIO;
      $scope.updateAccountAssignment = updateAccountAssignment;
      $scope.updateAccountAssignmentPercentage = updateAccountAssignmentPercentage;
      $scope.updateAccountAssignmentAmount = updateAccountAssignmentAmount;
      $scope.accountCodeListing = accountCodeListing;
      $scope.getAccountCodes = getAccountCodes;
      $scope.getTemplateUrl = getTemplateUrl;
      $scope.searchOpexIo = searchOpexIo;
      $scope.enableAccCode = true;
      $scope.disableDropDown = true;
      var backUpItem = {};
      var maximumAccCode = 5;

      function getTemplateUrl(state) {
          switch (state) {
            case 'editable':
              $scope.myTemplateUrl = 'components/purchaseRequisitionV2Components/accountAssignment/accountAssignmentTemplate.html';
              break;
            case 'notEditable':
              $scope.myTemplateUrl ='components/purchaseRequisitionV2Components/accountAssignment/accountAssignmentNonEditableTemplate.html';
              break;
          }
      }

      $scope.$watch('editingState', function (newValue) {
        getTemplateUrl(newValue);
      });

     $scope.$watch('item',function (item, oldValue) {
       //avoid initial load
       if (typeof oldValue === 'undefined')
         return;

       $scope.accountAssignments = $scope.item.account_assignments;

       _.forEach($scope.accountAssignments, function (accountAssignment) {
         accountAssignment.costCenterList = loadCostCenters(accountAssignment);
         accountAssignment.amount_pr_currency_separated = $filter('thousandSeparator')(accountAssignment.amount_pr_currency);
       });

       if (_.isEmpty(item.costCenterList)) {
         item.costCenterList = prV2Function.getPRsCCs();
       }
     });

      function onSelectCostCenter() {
        updateAccountAssignment();
      }

      function onSelectAccountCode(selectedAccountCode, itemIndex) {
        $scope.accountAssignments[itemIndex].account_code = selectedAccountCode;
        if (!_.isEmpty(selectedAccountCode.category_type)) {
          var newCostCenterList = [];
          _.forEach(prV2Function.getPRsCCs(), function (costCenter) {
            if (!_.isEmpty(costCenter.category_type) && costCenter.category_type.code === selectedAccountCode.category_type.code) {
              newCostCenterList.push(costCenter);
            }
          });

          if (newCostCenterList.length === 0) {
            toastr.info("GL Account Code's Category Type is " + selectedAccountCode.category_type.code +
              " No cost center has " + selectedAccountCode.category_type.code + " type. Please check company master data");
          }

          $scope.accountAssignments[itemIndex].costCenterList = newCostCenterList;
        }

        updateDisabledDropdownToggle();
        updateAccountAssignment();
      }

      function updateDisabledDropdownToggle() {
        if ($scope.item.is_stock_boolean !== true && $scope.expenseType.category === 'OPEX' && prV2Function.isDefaultCC())
          $scope.disableDropDown = false;
      }

      function onSelectOpexIO(selectedOpexIO, itemIndex) {
        $scope.accountAssignments[itemIndex].opex_io = {
          code: selectedOpexIO.code,
          name: selectedOpexIO.name,
          _id: selectedOpexIO._id
        }
        updateAccountAssignment();
      }

      function getAccountCodes() {
        return prV2Function.getAccountCodes();
      }

      function addAccountAssignment () {
        if ($scope.item.is_stock_boolean === true || $scope.expenseType.category === 'CAPEX') {
          $scope.enableAccCode = false;
          toastr.info('Only Opex Non Stock item can add account code.');
          return;
        }

        if ($scope.accountAssignments.length >= maximumAccCode) {
          $scope.enableAccCode = false;
          toastr.info('You have reach maximum number of account codes.');
          return;
        }

        if ($scope.headerCostCenterDescr !== 'Multiple' && $scope.expenseType.category === 'OPEX'){
          $scope.accountAssignments.push({
            cost_center: $scope.pr.cost_center,
            costCenterList: prV2Function.getPRsCCs()
          });
        } else {
          $scope.accountAssignments.push({
            costCenterList: prV2Function.getPRsCCs()
          });
        }

        updateDisabledDropdownToggle();
      }

      function deleteAccountAssignment(index) {
        $scope.accountAssignments.splice(index,1);
        updateAccountAssignment(index);
      }

      function updateAccountAssignmentPercentage(newVal, index) {
        if(!!backUpItem.account_assignments && !!backUpItem.account_assignments[index]){
          if(backUpItem.account_assignments[index].percentage === newVal) return;
        }
        _.forEach($scope.accountAssignments, function(item) {
          item.amount_pr_currency = null;
        });
        updateAccountAssignment();
      }

      function updateAccountAssignmentAmount(newVal, index)  {
        if(!!backUpItem.account_assignments && !!backUpItem.account_assignments[index]){
          newVal = newVal.replace(/\,/g, '');
          if(backUpItem.account_assignments[index].amount_pr_currency === newVal) return;
        }
        updateAccountAssignment();
      }

      function updateAccountAssignment() {
        // validate if account assignments exceed 5 lines
        if(!!$scope.accountAssignments && $scope.accountAssignments.length < maximumAccCode) {
          $scope.enableAccCode = true; //TODO: remove this from here
        }

        var params = [];
        // prepare params
        _.forEach($scope.accountAssignments, function(accountAssignment) {
          var item = {};
          if (!!accountAssignment.cost_center && !!accountAssignment.cost_center._id) {
            item.cost_center = accountAssignment.cost_center;
          }

          if (!!accountAssignment.account_code && (!!accountAssignment.account_code._id || !!accountAssignment.account_code.code)) {
            item.account_code = accountAssignment.account_code;
          }

          if (!!accountAssignment.percentage) {
            item.percentage = accountAssignment.percentage;
          }
          if (!!accountAssignment.amount_pr_currency_separated) {
            item.amount_pr_currency = accountAssignment.amount_pr_currency_separated.replace(/\,/g, '');
          }

          if (!!accountAssignment.opex_io) {
            item.opex_io = accountAssignment.opex_io;
          }

          params.push(item);
        });

        purchaseRequisitionsV2Services.updateAccountAssignments(
          {
            'requisition_id': prV2Function.getPRData()._id,
            'requisition_item_id': $scope.item._id
          },
          {account_assignments: params},
          function(resource) {
            if (!!resource && !!resource.content && !!resource.content.data) {
              prV2Function.setPRData(resource.content.data);
              if ($scope.expenseType.category === 'OPEX') {
                var newItem = _.find(resource.content.data.pr_items, function(e){
                  return e._id === $scope.item._id
                });
                backUpItem = _.cloneDeep(newItem);
                if (_.isEmpty($scope.accountAssignments)){
                  $scope.accountCodesList = [];
                  $scope.removeOtherValue();
                } else {
                  getAccountCodesByBudget(newItem);
                }
              }
            }
          },
          function(error) {
            globalFunc.objectErrorMessage(error);
            prV2Function.setLoading('lineItemTable', false);
          })
      }

      function searchOpexIo(query) {
        var moduleParam = {
          module: 'opex-ios'
        };

        var additionalParams = {
          params: {
            'criteria[0][company|code]': prV2Function.getPRData().company.code,
            'criteria[0][$strict]': true,
            'criteria[1][code]': query,
            'criteria[2][is_active]': true
          }
        };

        return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), additionalParams)
        .then(function (response) {
          if (response.data.content.data.length === 0) {
            return [];
          } else {
            return response.data.content.data.map(function (item) {
              return item;
            });
          }
        });
      }

     function autoPopulateAccountCode(itemId) {
       if ($scope.expenseType.category === 'CAPEX') {
         var items = prV2Function.getPRData().pr_items;
         _.forEach(items, function (item) {
           if (item._id === itemId) {
             $scope.item = item;
           }
         });
         getAccountCode($scope.item.cerf.asset_category.account_code);
       } else if ($scope.expenseType.category === 'OPEX') {
         var additionalParams = {
           'criteria[0][company|code]': prV2Function.getPRData().company.code,
           'criteria[1][item_master_code]': $scope.item.item_code
         };

         var moduleParam = {
           module: 'company-item'
         };

         $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
           params: additionalParams
         }).then(function (response) {
           return response.data.content.data.map(function (item) {
               getAccountCode(item.account_code);
             }
           );
         }, function (error) {
           globalFunc.objectErrorMessage(error);
         });
       } else {
         toastr.error('PR expense type is undefined.');
       }
     }

     //TODO: nuke this
     function getAccountCode(accountCode, ignoreUpdate) {
       if (!!accountCode) {
         singleAccountCodeByCode.get(
           {
             code: accountCode,
             company_code: prV2Function.getPRData().company.code
           },
           function (resource) {
             if (!!resource.content && !!resource.content.data) {
               var accountCodeData = resource.content.data;
               $scope.accountAssignments[0].account_code = accountCodeData;

               if (!ignoreUpdate) {
                 $scope.accountAssignments[0].percentage = 100;
                 $scope.accountAssignments[0].cost_center = accountCodeData.default_cost_center;
                 //TODO: these 2 functions call the backend, combine into services
                 updateAccountAssignmentPercentage();
                 updateAccountAssignment();
               }
             }
           }
         )
       }
     }

     function getAccountCodesFromBudget(val) {
       var result = _.filter($scope.accountCodesList, function (e) {
        if (_.contains(e.code.toLowerCase(), val) || _.contains(e.descr.toLowerCase(), val)) {
          return true
        }
       });

       return result;
     }

      function accountCodeListing(val, cc, accountIndex) {
        // this only executes for opex
        if (!_.isEmpty($scope.accountCodesList) && accountIndex !== 0) {
          return getAccountCodesFromBudget(val);
        }

        if (!!val && val.length > 2) {
          $scope.loading = true;

          if (!cc || !cc._id) {
            var accCodeParams = {
              query: val,
              company_code: prV2Function.getPRData().company.code,
              billing_company:prV2Function.getPRData().shadow_company.code,
              offset: 100
            };
          } else {
            var accCodeParams = {
              query: val,
              company_code: prV2Function.getPRData().company.code,
              billing_company:prV2Function.getPRData().shadow_company.code,
              cost_center_id: cc._id,
              offset: 100
            };
          }
          return $http.get(pathConstants.apiUrls.accountCode.prList, {
            params: accCodeParams
          }).then(function (response) {
            $scope.loading = false;
            if (!!response.data && !!response.data.content && !!response.data.content.data) {
              $scope.accCodeList = [];
              _.map(response.data.content.data, function (item) {
                $scope.loading = false;

                if (!!cc && !_.isEmpty(cc.category_type)) {
                  if (!_.isEmpty(item.category_type) && (item.category_type.code === cc.category_type.code)) {
                    $scope.accCodeList.push(item);
                  }
                } else if (!!cc && _.isEmpty(cc.category_type)) {
                  if (_.isEmpty(item.category_type)) {
                    $scope.accCodeList.push(item);
                  }
                } else {
                  $scope.accCodeList.push(item);
                }
              });

              return $scope.accCodeList;
            } else {
              return null;
            }
          });
        }
      }

      function loadCostCenters(accountAssignment) {

        var filteredCostCenter = [];
        var costCenterIds = [];

        if (!!accountAssignment.account_code && !_.isEmpty(accountAssignment.account_code.category_type)) {
          _.map(prV2Function.getPRsCCs(), function (costCenter) {
            if (!!costCenter.category_type && !!costCenter.category_type.code && (costCenter.category_type.code === accountAssignment.account_code.category_type.code)) {
              filteredCostCenter.push(costCenter);
            }
          });

        } else if (!!accountAssignment.account_code && _.isEmpty(accountAssignment.account_code.category_type)) {
          if (!_.isEmpty(accountAssignment.account_code.cost_center_ids)) {
             costCenterIds = accountAssignment.account_code.cost_center_ids;
          }

          if (costCenterIds.length === 0) {
            filteredCostCenter = prV2Function.getPRsCCs();
          } else {
            _.map(prV2Function.getPRsCCs(), function (costCenter) {
              if (!!costCenter && _.isEmpty(costCenter.category_type)) {
                if (costCenterIds.length > 0 && costCenterIds.includes(costCenter._id) === true) {
                  filteredCostCenter.push(costCenter);
                } else if (costCenterIds.length < 1) {
                  filteredCostCenter.push(costCenter);
                }
              }
            });
          }
        } else {
          filteredCostCenter = prV2Function.getPRsCCs();
        }

        if (!_.isEmpty(accountAssignment.cost_center)) {
          if (_.isEmpty(filteredCostCenter)) {
            filteredCostCenter.push(accountAssignment.cost_center);
          } else {
            var defaultExists = false;
            _.map(filteredCostCenter, function (costCenter) {
              if (costCenter.code === accountAssignment.cost_center.code) {
                defaultExists = true;
              }
            });

            if (!defaultExists) {
              filteredCostCenter.push(accountAssignment.cost_center);
            }
          }
        }

        return filteredCostCenter;
      }

      function loadDefaultCostCenters(accountAssignment) {
        return prV2Function.getPRsCCs();
      }

      function getAccountCodesByBudget(item) {
        if (!_.isEmpty(item.budget)) {
          var pr = prV2Function.getPRData();
          glCodesList.get(
            {
              company_code: pr.company.code,
              budget_id: item.budget._id
            },
            function (resource) {
              if (!!resource.content) {
                $scope.accountCodesList = resource.content.gl_accounts;
                _.forEach($scope.accountCodesList, function(e){
                  e.code = e.account_code;
                })
              }
            }
          )
        }
      }

     function initialize() {
        if($scope.item.account_assignments && $scope.item.account_assignments.length > 0) {
          $scope.costCenters = prV2Function.getPRsCCs();
          /**
           * check if the user is allowed to update the cost center
           * @type {boolean}
           */
          if (prV2Function.isDefaultCC())
            $scope.disableDropDown = false;
        }

       /**
        * check if the cost center was assigned in the backend
        */
       _.forEach($scope.item.account_assignments, function (account) {
         if (_.has(account, 'cost_center.descr')) {
           account.headerCostCenterDescr = account.cost_center.descr;
         }
       });

       $scope.pr = prV2Function.getPRData();
       backUpItem = _.cloneDeep($scope.item);
     }

     $scope.$watch('item', function (item, oldV){
       backUpItem = _.cloneDeep(item);
     });

      // when update asset category in cerf
     $scope.$on('prAssetCategoryUpdated', function (event, itemId) {
       if (prV2Function.getPRData().status === 'draft')
         if ($scope.item._id === itemId) {
           autoPopulateAccountCode(itemId);
         }
     });

      initialize();
    }

  }

}


angular
  .module('metabuyer')
  .directive('accountAssignment', accountAssignment);
