/**
 * @name pendingDelegationCtrl
 *
 * @description
 * List all the pending delegations and only accessible to Delegation Approver role user
 *
 * @author Ameer Asraf <ameerasraf_92@yahoo.com>
 * @copyright 2019 Metacloud Sdn. Bhd.
 */

'use strict';

function pendingDelegationCtrl($scope, pendingDelegationList, delegations, HighlightId) {
  $scope.showColumn = showColumn;
  $scope.getDelegationStatus = getDelegationStatus;

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional) {
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function getDelegationStatus() {
    return 'Pending';
  }


  function initialize() {

    $scope.dataList = pendingDelegationList.data;
    $scope.meta = pendingDelegationList.meta;
    $scope.delegationsService = delegations;
    $scope.highlightId = HighlightId.getId();
    if (!!$scope.highlightId) {
      HighlightId.setId(null);
    }
  }

  initialize();

}

pendingDelegationCtrl.$inject = ['$scope', 'pendingDelegationList', 'delegations', 'HighlightId'];

angular
  .module('metabuyer')
  .controller('pendingDelegationCtrl', pendingDelegationCtrl);
