'use strict';

/**
 * Controller for creating new catalog group
 * @param $scope
 * @param toastr
 * @param globalFunc
 * @param $state
 * @param companyGroupsList
 * @param searchModule
 * @param HighlightId
 * @param $http
 * @param pathConstants
 * @param $filter
 * @param $rootScope
 */
function createCatalogsGroupCtrl($scope, toastr, globalFunc, $state, companyGroupsList, searchModule, HighlightId,
                                 $http, pathConstants, $filter, $rootScope) {
  $scope.submitCatalogGroup = submitCatalogGroup;
  $scope.cancel = cancel;
  $scope.loadCompanies = loadCompanies;
  $scope.selectCompany = selectCompany;
  $scope.duplicationCheck = duplicationCheck;
  $scope.selectUser = selectUser;
  $scope.loadUsers = loadUsers;
  $scope.clearUserLine = clearUserLine;

  $scope.catalogGroupStatuses = [
    { isActive: 'Active' },
    { isActive: 'Deactivated' }
  ];

  function cancel(){
    window.history.back();
  }

  function loadUsers(val) {
    var moduleParam = {
      module: 'users'
    };

    var additionalParams = {
      'criteria[0][display_name]': val,
      'criteria[0][is_metabuyer_user]': true,
      'criteria[0][status]': 1,
      criteria_operator: 'and',
      offset: 10
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams
    }).then(function (response) {
      $scope.loading = false;
      return response.data.content.data.map(function (item) {
        $scope.loading = false;
        return item;
      });
    }, function (error) {
      globalFunc.objectErrorMessage(error);
    });
  }

  function clearUserLine(index) {
    $scope.selectedUserArray.splice(index, 1);
  }

  function selectUser(user) {
    if(!!validateUser(user)) {
      $scope.selectedUserArray.push(user);
    }
  }

  function validateUser(user) {
    if ($scope.selectedUserArray.length !== 0) {
      for (var i = 0; i < $scope.selectedUserArray.length; i++) {
        if ($scope.selectedUserArray[i]._id === user._id) {
          toastr.error('This user is already added');
          return false;
        }
      }
    }
    return true;
  }

  function loadCompanies(){
    $scope.loadingCompaniesList = true;
    searchModule.get(
      {
        module: 'companies',
        offset: 0
      },
      function (resource) {
        $scope.loadingCompaniesList = false;
        if(!!resource.content && !!resource.content.data){
          $scope.companies = resource.content.data;
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
        $scope.loadingCompaniesList = false;
      }
    );
  }

  //TODO: have company assigning when adding catalog group (Justin 11/11/16)
  function selectCompany(company, action){
    if(action === 'assign'){
      $scope.selectedCompanyArray.push(company);
    }
    else{
      $scope.selectedCompanyArray = globalFunc.removeValueFromArray($scope.selectedCompanyArray, '_id', company._id);
    }
  }

  function validate() {
    if ($scope.selectedCompanyArray.length === 0) {
      return false;
    }

    if (!$scope.catalogGroup.code && !$scope.catalogGroup.descr) {
      return false;
    }

    return true;
  }

  function submitCatalogGroup(code, descr, status){
    $scope.submitted = true;
    if(validate()) {

      //TODO: pass tempStatus when status is affecting catalog group (Justin 14/11/16)
      // set status in integer to pass it as parameter
      // var tempStatus;
      // switch (status.isActive.toLowerCase()) {
      //   case 'active':
      //     tempStatus = 1;
      //     break;
      //   case 'deactivated':
      //     tempStatus = 0;
      //     break;
      //   case 'disabled':
      //     tempStatus = 3;
      //     break;
      // }

      companyGroupsList.post(
        {
          code: code,
          descr: descr,
          company: $scope.selectedCompanyArray,
          users: $scope.selectedUserArray,
          is_active: 1
        },
        function (resource) {
          toastr.success('Catalog group added');
          $state.go('main.user.catalogs.catalogGroup');
          HighlightId.setId(resource.content.data._id);
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      )
    }
    else{
      toastr.error('Please fill all the required fields before submitting');
    }
  }

  /**
   * @function duplicationCheck
   * Calls the global function's duplication check
   * Checks callback function return for assigning scope values for validation
   **/
  function duplicationCheck(model,field,value,checker){
    globalFunc.duplicationCheck(model,field,value,checker, function(callback){
      switch (callback){
        case 'codeCheckTrue':
          $scope.codeCheck = true;
          break;
        case 'codeCheckFalse':
          $scope.codeCheck = false;
          break;
      }
    });
  }

  function initialize(){
    $scope.catalogGroup = {};
    $scope.selectedCompanies = [];
    $scope.selectedCompanyArray = [];
    $scope.selectedUserArray = [];
    $scope.isCatalogAdmin = !!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Catalog Admin');
    loadCompanies();
  }

  initialize();
}

createCatalogsGroupCtrl.$inject = ['$scope', 'toastr', 'globalFunc', '$state', 'companyGroupsList', 'searchModule',
                                  'HighlightId', '$http', 'pathConstants', '$filter', '$rootScope'];

angular.module('metabuyer')
  .controller('createCatalogsGroupCtrl', createCatalogsGroupCtrl);
