'use strict';

function emailAnnouncementCtrl($scope, $http, pathConstants, globalFunc, sendAnnouncement, toastr) {

  $scope.loading = false;
  $scope.delay = 500;
  $scope.usersTextFiled = {};
  $scope.usersTextFiled.input = null;
  $scope.selectedUsers = [];
  $scope.content = { subject: '', text: '' };
  $scope.selectedAll = false;
  $scope.onSelect = onSelect;
  $scope.searchUsers = searchUsers;
  $scope.removeUser = removeUser;
  $scope.send = send;

  function searchUsers(query) {

    $scope.loading = true;
    return $http.get(pathConstants.apiUrls.genericSearch.searchBasePath + '/users', {
      params: {
        module: 'users',
        'criteria[1][display_name]': query,
        'criteria[1][email]': query,
        'criteria[1][$operator]': 'or',
        'criteria[0][status]': 1,
        criteria_operator: 'and'
      }
    }).then(function (response) {
      $scope.loading = false;
      return response.data.content.data.map(function (item) {
        $scope.loading = false;
        return item;
      });
    });

  }

  function onSelect(user) {
    if(!globalFunc.findInArray($scope.selectedUsers, 'email', user.email)){
      $scope.usersTextFiled.input = null;
      $scope.selectedUsers.push({
        'email': user.email,
        'display_name': user.display_name
      })
    }
  }

  function removeUser(user){
    if($scope.selectedAll)
      return;

    $scope.selectedUsers = globalFunc.removeValueFromArray($scope.selectedUsers, 'email', user.email);
  }

  function send() {

    if (valid()) {
      swal(
        {
          title: 'Confirm send email?',
          text: 'The email will be sent to the selected user(s).',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#1ab394',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true,
          closeOnCancel: true
        },
        function (confirmed) {
          if (confirmed) {
            var emails = [];
            _.forEach($scope.selectedUsers, function (singleUser) {
              emails.push(singleUser.email);
            });

            sendAnnouncement.post({},{
              content: $scope.content.text,
              sendAll: $scope.selectedAll,
              emails: emails,
              subject: $scope.content.subject
            }, function(resource){
              var message = (!!resource.content && !!resource.content.message) ? resource.content.message :
                'Successfully sent';
              toastr.success(message)
            }, function (error) {
              globalFunc.objectErrorMessage(error);
            });
          }
        }
      );
    }
  }

  function valid() {
    if($scope.selectedUsers.length < 1 && !$scope.selectedAll){
      globalFunc.objectErrorMessage('Please select at least 1 user');
      return false;
    }

    if($scope.content.text.length < 1){
      globalFunc.objectErrorMessage('Please add email contents');
      return false;
    }

    return true;
  }
}

emailAnnouncementCtrl.$inject = ['$scope', '$http', 'pathConstants', 'globalFunc', 'sendAnnouncement', 'toastr'];

angular
  .module('metabuyer')
  .controller('emailAnnouncementCtrl', emailAnnouncementCtrl);
