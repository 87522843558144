'use strict';

angular.module('metabuyer.services.supplier', ['ngResource'])
  .factory('supplier',
    function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.supplier.single,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'},
        'post': {method: 'POST'}
      }
    );
    })
  .factory('supplierListing',
    function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.supplier.list
    )
    })
  .factory('newDraft',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.supplier.newDraft,
        {},
        {
          'post': {method: 'POST'}
        }
      )
    })
  .factory('supplierDraft',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.supplier.supplierDraft,
        {
          id: '@id'
        },
        {
          'get': {method: 'GET'},
          'put': {method: 'PUT'},
          'delete': {method: 'DELETE'}
        }
      )
    })
  .factory('newSupplier',
    function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.supplier.new,
      {},
      {
        'post': {method: 'POST'}
      }
    )
    })
  .factory('companyContractResource',
    function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.supplier.companyContract,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'},
        'post': {method: 'POST'},
        'patch': {method: 'PATCH'}
      }
    )
    })
  .factory('listCompanyContract',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.supplier.listCompanyContracts,
        {
          id: '@id'
        },
        {
          'get': {method: 'GET'}
        }
      )
    })
  .factory('updatedSupplierDetails',
    function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.supplier.updatedSupplier,
      {
        id: '@id'
      },
      {
        'put' : {method: 'PUT'}
      }
    )
    })
  .factory('supplierStatus',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.supplier.status,
        {
          id: '@id'
        },
        {
          'put' : {method: 'PUT'}
        }
      )
    })
  .factory('supplierAttachment',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.supplier.attachment,
        {},
        {
          'deleteAttachment': {
            method: 'DELETE',
            params: {
              supplier_id: '@supplier_id',
              attachment_id: '@attachment_id'
            },
            url: pathConstants.apiUrls.supplier.attachment.delete
          }
        }
      )
    })
  .factory('supplierBranches',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.supplier.branches,
        {
          id: '@id'
        },
        {
          'get': {method: 'GET'}
        }
      );
    })
  .factory('mappingSupplier',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.bifrost.bifrostMapping,
        {
          id: '@id'
        },
        {
          'get': {method: 'GET'}
        }
      );
    })
  .factory('resubmitSupplier',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.supplier.resubmit,
        {
          id: '@id'
        },
        {
          'post': {method: 'POST'}
        }
      );
    })
.factory('singleCompanyContract',
  function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.supplier.singleCompanyContract,
      {},
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('companyContractRevisions',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.supplier.companyContractRevisions,
        {},
        {
          'get': {method: 'GET'}
        }
      );
    })
  .factory('supplierTagViaCompany',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.supplier.tagViaCompany,
        {},
        {
          'post': {method: 'POST'}
        }
      );
    })
  .factory('getTagsBySupplierCode',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.supplier.tagBySupplierCode,
        {
          supplierCode: '@supplierCode'
        },
        {
          'get': {method: 'GET'}
        }
      );
    })
  .factory('getTagsByCompanyCode',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.supplier.tagByCompanyCode,
        {
          companyCode: '@companyCode'
        },
        {
          'get': {method: 'GET'}
        }
      );
    })
  .factory('pushSupplierToBifrost',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.supplier.pushSupplierToBifrost,
        {
          id: '@id'
        },
        {
          'post': {method: 'POST'}
        }
      )
    })
  .factory('approvalSupplier',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.approvals.status,
        {
          approval_id: '@approval_id',
          status: '@status'
        },
        {
          'post': {method: 'POST'}
        }
      );
    })
  .factory('deleteWithdrawnSupplier',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.supplier.deleteSupplier,
        {
          id: '@id'
        },
        {
          'delete' : {method: 'DELETE'}
        }
      );
    })
  .factory('updateSageCompanyCode',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.supplier.updateSageCompanyCode,
        {
          id: '@id'
        },
        {
          'put' : {method: 'PUT'}
        }
      );
    })
  .factory('updateSupplierCompanyContract',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.supplier.updateSupplierCompanyContract,
        {
          id: '@id'
        },
        {
          'put' : {method: 'PUT'}
        }
      );
    })
    .factory('approvalFlowPreview',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.supplier.previewApproval,
        {},
        {
          'post' : {method: 'POST'}
        }
      );
    })
  .factory('supplierUserService',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.supplierUser.supplierUser,
        {
          id: '@id'
        },
        {
          'get': {method: 'GET'},
          'post': {method: 'POST'},
          'put' : {method: 'PUT'}
        }
      );
    })
  .factory('supplierUserSearchService',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.supplierUser.search,
        {},
        {
          'get': {method: 'GET'},
        }
      );
    })
  .factory('supplierUserActionService',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.supplierUser.performAction,
        {
          action: '@action'
        },
        {
          'put' : {method: 'PUT'}
        }
      );
    })
  .factory('supplierUserCommentsService',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.supplierUser.comments,
        {
          referenceId: '@referenceId'
        },
        {
          'get': {method: 'GET'}
        }
      );
    })
  .factory('supplierDraftAttachment',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.supplier.newDraftAttachment,
        {},
        {
          'deleteAttachment': {
            method: 'DELETE',
            params: {
              draft_id: '@draft_id',
              attachment_id: '@attachment_id'
            },
            url: pathConstants.apiUrls.supplier.newDraftAttachment.delete
          }
        }
      )
    });
