'use strict';

function PolicyDisclaimerCtrl($scope, $state, policyDisclaimerResource, toastr) {
  $scope.languages = [
    {
      descr: 'English',
      value: 'en'
    },
    {
      descr: 'Chinese',
      value: 'zh'
    }
  ];

  $scope.policyDisclaimer = {};
  $scope.clonedPolicyDisclaimer = {};
  $scope.selectedLanguage = $scope.languages[0];
  $scope.isEditMode = false;

  $scope.setEditMode = setEditMode;
  $scope.cancel = cancel;
  $scope.submit = submit;
  $scope.onSelectLanguage = onSelectLanguage;

  function setEditMode() {
    $scope.isEditMode = true;
  }

  function cancel() {
    $scope.isEditMode = false;
    $scope.clonedPolicyDisclaimer = _.clone($scope.policyDisclaimer);
  }

  function submit() {
    var payload = preparePayload();

    policyDisclaimerResource.put(
      payload,
      function (resource) {
        toastr.success('Security Policy successfully updated.');
        loadData(resource.content.data);
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function onSelectLanguage(language) {
    $scope.selectedLanguage = language;
  }

  function preparePayload() {
    return {
      descr: $scope.clonedPolicyDisclaimer.descr || '',
      descr_chinese: $scope.clonedPolicyDisclaimer.descr_chinese || '',
      is_active: $scope.clonedPolicyDisclaimer.is_active || false,
    }
  }

  function loadData(data) {
    $scope.isEditMode = false;
    $scope.policyDisclaimer = data.policy_disclaimer;
    $scope.clonedPolicyDisclaimer = _.clone($scope.policyDisclaimer);
  }

  function initialize() {
    policyDisclaimerResource.get(
      {},
      function(resource){
        loadData(resource.content.data);
      }, function(error){
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  initialize();

  $scope.$emit('pageTitleChanged', 'Metabuyer Policy');
}


angular.module('metabuyer')
  .controller('PolicyDisclaimerCtrl', PolicyDisclaimerCtrl);
