'use strict';

/**
 * @name itemBreakdowns Directive
 * @desc Directive for item breakdowns in budget
 *
 * @isolatedScope budgetData
 * To synchronize items data
 *
 * @isolatedScope budgetMaster
 * Budget master data
 *
 * @isolatedScope budgetItemDetailsError
 * Flag to indicate item details contains any error upon submit
 *
 * @isolatedScope editingMode
 * Whether the budget is in editing mode
 *
 * @author H.W. Liew <liewhanwahwork@gmail.com>
 * @copyright 2017 Metacloud Sdn. Bhd.
 */
angular
  .module('metabuyer')
  .directive('itemBreakdowns', function () {
    return {
      restrict: 'E',
      template: '<div ng-include="templateUrl"></div>',
      scope: {
        budgetData: '=',
        budgetMaster: '=',
        budgetItemDetailsError: '=',
        editingMode: '=',
        apportionList: '='
      },
      controller: function ($scope, searchModule, listChild, pathConstants, $http, globalFunc, budgetFunctions) {
        $scope.templateUrl = $scope.editingMode === 'editable' ?
          'components/budget/itemBreakdowns/itemBreakdowns.editableTemplate.html' :
          'components/budget/itemBreakdowns/itemBreakdowns.nonEditableTemplate.html';

        $scope.closeTreeSearch = false;
        $scope.commodityLevel = [1, 2, 3, 4];
        $scope.uomSearching = false;

        $scope.addItemBreakdown = addItemBreakdown;
        $scope.deleteItemBreakdown = deleteItemBreakdown;
        $scope.searchCommodityCode = searchCommodityCode;
        $scope.openChildCommodityCode = openChildCommodityCode;
        $scope.validationUOMSelection = validationUOMSelection;
        $scope.isQuantityValid = isQuantityValid;
        $scope.searchUOM = searchUOM;
        $scope.onUOMSelect = onUOMSelect;
        $scope.checkApportionAllocation = checkApportionAllocation;
        $scope.isItemAmountGreaterThanInherited = isItemAmountGreaterThanInherited;
        $scope.canEditOrDeleteItemBreakdown = canEditOrDeleteItemBreakdown;

        /**
         * Function to check if set amount is lower than used + committed amount
         * @returns {boolean}
         */
        function validateAmount(item) {
          if ((!!item.committed_amount || !!item.used_amount) && !!item.allocated_amount) {
            var committedAmount = !!item.committed_amount ? item.committed_amount : 0;
            var usedAmount = !!item.used_amount ? item.used_amount : 0;
            if (item.allocated_amount < (committedAmount + usedAmount)) {
              return true;
            }
          }
          return false
        }

        /**
         * Function to check if item breakdown can be edited
         * @returns {boolean}
         */
        function canEditOrDeleteItemBreakdown(item) {
          if (!item.committed_amount && !item.used_amount) {
            return false;
          }

          return true;
        }
        /**
         * This function is called when user try to add new item
         */
        function addItemBreakdown() {
          $scope.budgetData.item_breakdowns.push(
            {
              descr: null,
              commodity_code: null,
              apportion_type: !!$scope.budgetData.apportion_type ? $scope.budgetData.apportion_type : 'N/A',
              apportion_allocation: [],
              apportion_carry_forward: false,
              allocated_amount: null,
              quantity: null,
              used_amount: null,
              committed_amount: null,
              used_quantity: null,
              committed_quantity: null,
              UOM: null,
              UOMIsFraction: null,
              UOMText: '',
              justification: ''
            }
          );

          // Removing the parent level apportion data
          $scope.budgetData = budgetFunctions.removeParentApportionData($scope.budgetData);
        }

        /**
         * This function is called when user delete an item
         *
         * @param {number} item - index
         */
        function deleteItemBreakdown(item) {
          $scope.budgetData.item_breakdowns.splice(item, 1);
          $scope.commodityCodes[item] = [];
        }

        /**
         * Searching commodity code
         *
         * @param {string} keyword
         * @param {string} index
         * @returns {string}
         */
        function searchCommodityCode(keyword, index) {
          if (!!keyword && keyword.length > 1) {
            searchModule.get(
              {
                module: 'commodity',
                'criteria[0][code]': keyword,
                'criteria[0][descr]': keyword,
                'criteria[0][$operator]': 'or',
                'criteria[1][is_active]': 1,
                order: 1,
                order_by: 'code',
                offset: 0
              }, function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data)
                  $scope.commodityCodes[index] = resource.content.data;

                _.forEach($scope.commodityCodes[index], function (item) {
                  item.index = index;
                });
              }, function () {
                $scope.commodityCodes[index] = [];
              });

            return $scope.commodityCodes[index];
          }
        }

        /**
         * Searching child of the provided commodity code
         *
         * @param {string} cc - cost center
         * @param {number} index
         */
        function openChildCommodityCode(cc, index) {
          listChild.get({
              code: cc
            },
            function (resource) {
              if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data.length > 0) {
                $scope.commodityCodes[index] = resource.content.data;
                _.forEach($scope.commodityCodes[index], function (item) {
                  item.index = index;
                });
              }
              else {
                toastr.error('There are no child on this commodity code');
              }
            },
            function () {
              toastr.error('Failed to get commodity codes data');
            });
        }

        /**
         * Processing item breakdowns data in the beginning
         *
         * @param {array} data
         */
        function processData(data) {
          $scope.commodityCodes = [];
          var index = 0;
          _.forEach(data, function (item) {
            $scope.commodityCodes[index] = null;
            item.validUOMIsSelected = item.UOMText === '' ? false : true;

            //To initialize commodity code and populate drop downs accordingly
            if (!!item.commodity_code && !!item.commodity_code.code) {
              item.primaryCommodityKeyword = item.commodity_code.code + ' ' + item.commodity_code.descr;
            }
            index++;
          });
        }

        /**
         * Validate the Selection for the Type-a-head
         *
         * @param {array}  budget
         * @param {number} index
         */
        function validationUOMSelection(budget, index) {
          if (!budget.validUOMIsSelected || (!!budget.UOMText && budget.UOMText.length < 2)) {
            $scope.budgetData.item_breakdowns[index].UOM = null;
            $scope.budgetData.item_breakdowns[index].UOMText = '';
            $scope.budgetData.item_breakdowns[index].UOMIsFraction = null;
          }
        }

        /**
         * Check if quantity is valid
         *
         * @param {number} quantity
         * @param {number} index
         */
        function isQuantityValid(quantity, index) {
          var isDecimal = (quantity % 1 !== 0);
          return !($scope.budgetData.item_breakdowns[index].UOMIsFraction === 0 && isDecimal);
        }

        /**
         * Searching for UOM
         *
         * @param {string} val
         */
        function searchUOM(val) {
          if (!!val && val.length > 1) {
            $scope.uomSearching = true;

            return $http.get(pathConstants.apiUrls.uom.list, {
              params: {
                query: val
              },
              ignoreLoadingBar: true
            }).then(function (response) {
              $scope.uomSearching = false;
              return response.data.content.data.map(function (item) {
                return item;
              });
            }).catch(function (error) {
              $scope.uomSearching = false;
              globalFunc.objectErrorMessage(error);
            })
          }
        }

        /**
         * This function is called when UOM is selected
         *
         * @param {array}  UOM
         * @param {number} index
         */
        function onUOMSelect(UOM, index) {
          $scope.budgetData.item_breakdowns[index].validUOMIsSelected = true;
          $scope.budgetData.item_breakdowns[index].UOM = UOM.code;
          $scope.budgetData.item_breakdowns[index].UOMIsFraction = UOM.is_fraction;
        }

        /**
         * To check if apportion allocation values will change due to field changes
         *
         * @param {*}      oldValue   old value of the field
         * @param {*}      newValue   new value of the field
         * @param {string} fieldModel Name of the model to be modified
         * @param {number} index
         */
        function checkApportionAllocation(oldValue, newValue, fieldModel, index) {
          budgetFunctions.checkApportionAllocation(
            oldValue,
            newValue,
            $scope.budgetData.item_breakdowns[index].apportion_allocation).then(function (resource) {
            $scope.budgetData.item_breakdowns[index].apportionRelatedValues = resource;
            $scope.budgetData.item_breakdowns[index][fieldModel] = resource;
            $scope.originalItemBreakdownData[index][fieldModel] = resource;
          });
        }

        /**
         * To check if item breakdown total amounts greater than assignment/budget amount
         */
        function isItemAmountGreaterThanInherited() {
          var totalItemAmount = 0;
          _.forEach($scope.budgetData.item_breakdowns, function (item) {
            if (!!item.allocated_amount) {
              totalItemAmount = budgetFunctions.add(item.allocated_amount, totalItemAmount);
            }
          });

          var availableAmount = budgetFunctions.subtract($scope.budgetData.amount, $scope.budgetData.block_amount);
          if (availableAmount < totalItemAmount) {
            return true;
          }

          return false;
        }

        function initialize() {
          $scope.originalItemBreakdownData = {};
          _.forEach($scope.budgetData.item_breakdowns, function(itemBreakdowns, key){
            $scope.originalItemBreakdownData[key] = _.cloneDeep(itemBreakdowns);
          });

          processData($scope.budgetData.item_breakdowns);

          $scope.$watch('budgetData.apportion_type', function (newValue) {
            if (!!newValue) {
              _.forEach($scope.budgetData.item_breakdowns, function (item, index) {
                if (newValue !== 'N/A') {
                  $scope.budgetData.item_breakdowns[index].apportion_type = newValue;
                }
              });
            }
          });
        }

        initialize();

      }
    };
  });
