'use strict';

/**
 * @name compareBudgetVersionCtrl
 * @desc The controller for comparing budget versions data
 *
 * @author Justin Cheong <justin.cty90@gmail.com>
 * @copyright 2017 Metacloud Sdn. Bhd.
 */
function compareBudgetVersionCtrl($scope, $uibModal, $uibModalInstance, currentStatus,
                                  versions, selectedVersion) {

  $scope.close = close;

  /**
   * On modal close
   */
  function close() {
    $uibModalInstance.close();

    // Open budget version modal if currently viewing approved budget
    $uibModal.open({
      templateUrl: 'components/budget/versions/budgetVersions.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        budgetVersions: function () {
          return versions;
        },
        currentStatus: function () {
          return currentStatus;
        }
      },
      controller: 'budgetVersionsCtrl'
    });
  }

  /**
   * Create set of arrays based on given number, for looping purpose
   *
   * @param {number} number Number of arrays
   */
  function createRange(number) {
    var items = [];
    for (var i = 0; i < number; i++) {
      items.push(i);
    }
    return items;
  }

  /**
   * Create looping number for budget assignment
   */
  function createBudgetAssignmentRange() {
    if (!!selectedVersion[0].assignments && !!selectedVersion[1].assignments) {
      $scope.assignmentSize = selectedVersion[0].assignments.length > selectedVersion[1].assignments.length ?
        selectedVersion[0].assignments.length : selectedVersion[1].assignments.length;
    } else if (!!selectedVersion[0].assignments) {
      $scope.assignmentSize = selectedVersion[0].assignments.length;
    } else if (!!selectedVersion[1].assignments) {
      $scope.assignmentSize = selectedVersion[1].assignments.length;
    }

    $scope.assignmentSize = createRange($scope.assignmentSize);
  }

  /**
   * Create looping number for budget item breakdowns
   */
  function createItemBreakdownsRange() {
    if (!!selectedVersion[0].item_breakdowns && !!selectedVersion[1].item_breakdowns) {
      $scope.itemBreakdownsSize = selectedVersion[0].item_breakdowns.length > selectedVersion[1].item_breakdowns.length ?
        selectedVersion[0].item_breakdowns.length : selectedVersion[1].item_breakdowns.length;
    } else if (!!selectedVersion[0].item_breakdowns) {
      $scope.itemBreakdownsSize = selectedVersion[0].item_breakdowns.length;
    } else if (!!selectedVersion[1].item_breakdowns) {
      $scope.itemBreakdownsSize = selectedVersion[1].item_breakdowns.length;
    }

    $scope.itemBreakdownsSize = createRange($scope.itemBreakdownsSize);
  }

  /**
   * Create looping number for budget assignment item breakdowns
   */
  function createAssignmentItemBreakdownsRange() {

    $scope.assignmentItemSize = [];
    var sourceAsssignmentSize = !!selectedVersion[0].assignments ? selectedVersion[0].assignments.length : 0;
    var targetAsssignmentSize = !!selectedVersion[1].assignments ? selectedVersion[1].assignments.length : 0;
    var assignmentSize = 0;

    // Get the assignment size
    if (sourceAsssignmentSize > 0 && targetAsssignmentSize > 0) {
      assignmentSize = sourceAsssignmentSize > targetAsssignmentSize ? sourceAsssignmentSize : targetAsssignmentSize;
    } else if (sourceAsssignmentSize > 0) {
      assignmentSize = sourceAsssignmentSize;
    } else if (targetAsssignmentSize > 0) {
      assignmentSize = targetAsssignmentSize;
    }

    // Get the item breakdowns size for each assignment
    for (var i = 0; i < assignmentSize; i++) {
      $scope.assignmentItemSize[i] = 0;
      if (!!selectedVersion[0].assignments[i] && !!selectedVersion[0].assignments[i].item_breakdowns &&
        !!selectedVersion[1].assignments[i] && !!selectedVersion[1].assignments[i].item_breakdowns) {
        $scope.assignmentItemSize[i] = selectedVersion[0].assignments[i].item_breakdowns.length >
        selectedVersion[1].assignments[i].item_breakdowns.length ?
          selectedVersion[0].assignments[i].item_breakdowns.length :
          selectedVersion[1].assignments[i].item_breakdowns.length;
      } else if (!!selectedVersion[0].assignments[i] && !!selectedVersion[0].assignments[i].item_breakdowns) {
        $scope.assignmentItemSize[i] = selectedVersion[0].assignments[i].item_breakdowns.length;
      } else if (!!selectedVersion[1].assignments[i] && !!selectedVersion[1].assignments[i].item_breakdowns) {
        $scope.assignmentItemSize[i] = selectedVersion[1].assignments[i].item_breakdowns.length;
      }

      $scope.assignmentItemSize[i] = createRange($scope.assignmentItemSize[i]);
    }
  }

  /**
   * Implode the cost center values of budget master
   *
   * @param {array} data Budget data to be processed
   */
  function restructureCostCenterData(data) {
    if (!!data.cost_center && _.isArray(data.cost_center)) {
      data.cost_center = _.pluck(data.cost_center, 'descr').join(', ');
    }
  }

  function initialize() {
    $scope.selectedVersion = selectedVersion;
    $scope.assignmentSize = 0;
    createBudgetAssignmentRange();
    createItemBreakdownsRange();
    createAssignmentItemBreakdownsRange();
    restructureCostCenterData($scope.selectedVersion[0]);
    restructureCostCenterData($scope.selectedVersion[1]);
  }

  initialize();
}

compareBudgetVersionCtrl.$inject = [
  '$scope', '$uibModal', '$uibModalInstance', 'currentStatus', 'versions', 'selectedVersion'
];

angular.module('metabuyer')
  .controller('compareBudgetVersionCtrl', compareBudgetVersionCtrl);
