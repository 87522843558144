'use strict';

function AnalyticsCtrl($scope, $state, $rootScope,  globalFunc) {

  function initialize(){

  }

  initialize();

  $scope.$emit('pageTitleChanged', 'Dashboard Reporting');
}
AnalyticsCtrl.$inject = ['$scope', '$state', '$rootScope', 'globalFunc'];

angular.module('metabuyer')
  .controller('AnalyticsCtrl', AnalyticsCtrl);
