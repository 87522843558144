/**
 * @name userAssignment
 *
 * @description
 * Directive to assign user to company, cost center, roles, position, and superior
 *
 * @param setting
 * Setting is for multiselect setting.
 * Using multiselect inside a custom directive needs to define the setting in controller level
 *
 * @author Deniel Ariesta
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

angular.module('metabuyer')
  .directive('userAssignment', function (
    companyCostCenters, globalFunc, $q, rolesList, companyActiveUsers, userPendingApproval, $uibModal,
    organizationRoles, $rootScope, getAlcGroupActiveUsers, getCatalogGroupActiveUsers, $translatePartialLoader
  ) {
    return {
      restrict: 'E',
      template: '<div ng-include="myTemplateUrl"></div>',
      scope: {
        userId: '=?',
        groupedAssignments: '=',
        selectedRoleAssignments: '=',
        selectedCostCenters: '=?',
        companies: '=',
        selectedRoles: '=?',
        selectedSuperiors: '=?',
        saveDisabled: '=',
        tenantId: '=?',
        editableState: '@',
        catalogGroups: '=?',
        alcGroups: '=?'
      },
      controller: function ($scope, metabuyerCache) {
        $translatePartialLoader.addPart('user/settings/lang');

        $scope.query                        = '';
        $scope.groupedAssignments           = $scope.groupedAssignments || [];
        $scope.companies                    = $scope.companies || [];
        $scope.catalogGroups                = _.cloneDeep($scope.catalogGroups) || [];
        $scope.alcGroups                    = _.cloneDeep($scope.alcGroups) || [];
        $scope.selectedCompanies            = $scope.selectedCompanies || [];
        $scope.selectedCostCenters          = $scope.selectedCostCenters || [];
        $scope.selectedCompaniesForListing  = [];
        $scope.usersSuperior                = [];
        $scope.loading                      = false;
        $scope.promisesCount                = 0;
        $scope.multiselectTranslation       = {
          selectAll       : 'Select all',
          selectNone      : 'Select none',
          reset           : 'Reset',
          search          : 'Type here to search...',
          nothingSelected : 'Select'
        };

        $scope.updateQuery              = updateQuery;
        $scope.removeCompany            = removeCompany;
        $scope.onSelectCompany          = onSelectCompany;
        $scope.onSelectAssignment       = onSelectAssignment;
        $scope.onSelectCostCenter       = onSelectCostCenter;
        $scope.loadAvailableRoles       = loadAvailableRoles;
        $scope.loadCompanyUsers         = loadCompanyUsers;
        $scope.loadAlcUsers             = loadAlcUsers;
        $scope.loadCatalogGroupUsers    = loadCatalogGroupUsers;
        $scope.userId                   = $scope.userId || null;
        $scope.checkPendingApproval     = checkPendingApproval;
        $scope.modifyCompanyAssignment  = modifyCompanyAssignment;
        $scope.isV2Enabled              = $rootScope.isV2Enabled;


          /**
           * the Panel to modify the selection is active/inactive
           * @param company the company object
           */
        function modifyCompanyAssignment(company) {
            !!company.openPanel ? company.openPanel = false : company.openPanel = true;

            if (company.openPanel)
              company.bakup = _.cloneDeep(company);

            if (company.openPanel && company.code !== 'system') {
              company.costCenterList = [];
              var allCostCenters = {
                '_id': company._id,
                'code': company.code,
                'organization_id': company._id,
                'descr': 'All'
              };

              if (!!metabuyerCache.get('companyCostCenterKey-' + company._id)) {
                company.costCenterList = _.cloneDeep(metabuyerCache.get('companyCostCenterKey-' + company._id));
                company.costCenterList.unshift(allCostCenters);
                assignSelectedInfo(company);
              } else {
                getCompanyCostCenters(company._id).then(function (resource) {
                  metabuyerCache.put('companyCostCenterKey-' + company._id, resource);
                  company.costCenterList = _.cloneDeep(resource);
                  company.costCenterList.unshift(allCostCenters);
                }).catch().finally(function () {
                  assignSelectedInfo(company);
                });
              }
            } else {
              !!company.bakup.roleAssignments ? company.roleAssignments = _.cloneDeep(company.bakup.roleAssignments) :
                company.roleAssignments = [];
              assignSelectedInfo(company);
            }
          }

        /**
         * On select company
         * @param company The company object
         * @param group The single group object from groupedAssignments
         * @param autoFill To determine for initialize or for loading data
         * @param loadCostCenters HACK field to reuse the function
         */
        function onSelectCompany(company, group, autoFill, loadCostCenters) {
          //TODO: this load cost centers regardless (even for Alc or Catalog Groups)(Ahmed Saleh, 19/1/2021)
          if (!!company.ticked) {
            $scope.loading = true;
            ++$scope.promisesCount;
            var tempCompany = _.clone(company);
            var promises = {
              costCenters: ($scope.editableState === 'editable' && company.code !== 'system' && loadCostCenters) ?
                getCompanyCostCenters(tempCompany._id) : [],
              roleAssignments: !!group ? group.roleAssignments : []
            };

            // Load promises data
            $q.all(promises).then(
              function (resource) {
                tempCompany.costCenterList = resource.costCenters;
                // Unshift an All selection for cost centers list
                var allCostCenters = {
                  '_id': tempCompany._id,
                  'code': tempCompany.code,
                  'organization_id': tempCompany._id,
                  'descr': 'All'
                };
                tempCompany.costCenterList.unshift(allCostCenters);
                tempCompany.roleAssignments = resource.roleAssignments;
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
              }
            ).catch().finally(function () {
              // If autofill is true, assign selected information as initial information
              if (!!autoFill) {
                assignSelectedInfo(tempCompany);
              }

              $scope.selectedRoleAssignments.push(tempCompany);
              // wait for promises to finish load then only insert loaded data
              --$scope.promisesCount;
              if ($scope.promisesCount === 0) {
                $scope.loading = false;
                $scope.saveDisabled = false;
              }
            });

          } else if (!company.ticked) {
            removeCompany(company);
          }
        }

        /**
         * On select an assignment
         * @param assignment
         * @param company
         */
        function onSelectAssignment(assignment, company) {
          if (!!assignment.ticked) {
            assignment = globalFunc.findInArray(company.costCenterList, 'code', assignment.code);
            assignment.organization_id = assignment.descr === 'All' ? company._id : assignment._id;
            assignment.weight = assignment.descr === 'All' ? 3 : 4;
            //HACK: force the first account assignment object to have weight 11 for alc groups
            if (company.type === 'alcGroup') {
              assignment.weight = 11;
            }
            if (company.type === 'catalogGroup') {
              assignment.weight = 12;
            }
            company.roleAssignments.push(assignment);
          } else if (!assignment.ticked) {
            removeAssignment(assignment, company);
          }
        }

        /**
         * On select cost center, get available roles
         *
         * @param selectedAssignment
         * @param assignmentObject
         */
        function onSelectCostCenter(selectedAssignment, assignmentObject) {
          organizationRoles.get(
            {
              org_id: selectedAssignment._id
            },
            function (resource) {
              assignmentObject.availableRoles = resource.content.data;
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
            });
        }

        /**
         * Load available roles on click only if available roles are not loaded before
         * @param assignmentObject
         */
        function loadAvailableRoles(assignmentObject) {
          if (!assignmentObject.organization_id) {
            assignmentObject.organization_id = assignmentObject._id
          }

          if (!assignmentObject.availableRoles) {
            organizationRoles.get(
              {
                org_id: assignmentObject.organization_id
              },
              function (resource) {
                assignmentObject.availableRoles = resource.content.data;
                assignmentObject.selectedRoles = [];
                if (!!assignmentObject.roles) {
                  _.forEach(assignmentObject.roles, function(role) {
                    assignmentObject.selectedRoles.push(
                      globalFunc.findInArray(assignmentObject.availableRoles, '_id', role._id)
                    );
                  });
                }
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
              });
          }
        }

        // Load company users upon clicking
        function loadCompanyUsers(company) {
          if (!company.superiorList) {
            companyActiveUsers.get(
              {
                'id': company._id
              },
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data) {
                  company.superiorList = resource.content.data;
                }
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
              }
            );
          }
        }

        // Load ALC users upon clicking
        function loadAlcUsers(company) {
          if (!company.superiorList) {
            getAlcGroupActiveUsers.get(
              {
                'code': company.code
              },
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data) {
                  company.superiorList = resource.content.data;
                }
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
              }
            );
          }
        }

        // Load Catalog group users upon clicking superior
        function loadCatalogGroupUsers(company) {
          if (!company.superiorList) {
            getCatalogGroupActiveUsers.get(
              {
                'groupCode': company.code
              },
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data) {
                  company.superiorList = resource.content.data;
                }
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
              }
            );
          }
        }

        /**
         * added system to the companies list
         */
        function addSystemToCompanies() {
          var system = {
            _id: !!$scope.tenantId ? $scope.tenantId : 'system',
            code: 'system',
            descr: 'System'
          };
          $scope.companies.unshift(system);
        }

        /**
         * Check all pending approval flow
         * @param {string} company
         * @param {string} companyCode
         */
        function checkPendingApproval(company, companyCode) {
          userPendingApproval.get(
            {
              userId: $scope.userId,
              companyCode: companyCode
            }, function (resource) {
              if (!!resource && !!resource.content && !!resource.content.data &&
                resource.content.data.length > 0) {
                $uibModal.open({
                  templateUrl: 'components/userAssignment/pendingApprovalList.html',
                  backdrop: 'static',
                  keyboard: false,
                  resolve: {
                    approvalFlows: function () {
                      return resource.content.data;
                    }
                  },
                  controller: function ($scope, approvalFlows, $uibModalInstance) {
                    $scope.cancel = cancel;
                    $scope.approvalFlows = approvalFlows;

                    function cancel() {
                      $uibModalInstance.close();
                    }
                  }
                });
              } else {
                removeCompany(company);
              }
            }, function (error) {
              globalFunc.objectErrorMessage(error);
            }
          );
        }

        /**
         * Remove company manually from the list
         * @param company
         */
        function removeCompany(company) {
          _.forEach($scope.companies, function (singleCompany) {
            if (singleCompany._id === company._id) {
              singleCompany.ticked = false;

              // remove from selected companies in listing
              $scope.selectedRoleAssignments = globalFunc.removeValueFromArray(
                $scope.selectedRoleAssignments, '_id', company._id
              );
            }
          });
        }

        /**
         * Remove assignment from the list
         * @param assignment
         * @param company
         */
        function removeAssignment(assignment, company) {
          assignment.ticked = false;
          company.roleAssignments = globalFunc.removeValueFromArray(
            company.roleAssignments, 'organization_id', assignment.organization_id
          );
        }

        /**
         * To update the query value manually
         * @param query
         */
        function updateQuery(query) {
          $scope.query = query;
        }

        /**
         * Retrieve company's cost centers
         * @param companyId
         * @returns {Array}
         */
        function getCompanyCostCenters(companyId) {
          if (!!companyId && companyId !== 'system') {
            var deferred = $q.defer();
            companyCostCenters.get({
              id: companyId
            }, function (resource) {
              if (!!resource && !!resource.content && !!resource.content.data) {
                deferred.resolve(resource.content.data);
              }
            }, function () {
              deferred.resolve([]);
            });
            return deferred.promise;
          }
          else {
            return [];
          }
        }

        /**
         * Assign the selected company information
         * @param company
         */
        function assignSelectedInfo(company) {
          if (!!company.roleAssignments) {
            _.forEach(company.roleAssignments, function (assignment) {
              assignment.selectedRoles = [];
              _.forEach(company.costCenterList, function(costCenter) {
                if (assignment.descr === 'All') {
                  costCenter = globalFunc.findInArray(company.costCenterList, 'descr', 'All');
                } else {
                  costCenter = globalFunc.findInArray(company.costCenterList, '_id', assignment.organization_id);
                }
                if (!!costCenter) {
                  costCenter.ticked = true;
                  costCenter.organization_id = costCenter._id;
                }
              });

              assignment.selectedRoles = assignment.roles;
            });
          }
        }

        /**
         * Get the template url for the directive to load
         *
         * @param state
         */
        function getTemplateUrl(state) {
          switch (state) {
            case 'editable':
              $scope.myTemplateUrl = 'components/userAssignment/userAssignment.EditableTemplate.html';
              break;
            case 'notEditable' :
              $scope.myTemplateUrl = 'components/userAssignment/userAssignment.NonEditableTemplate.html';
              break;
          }
        }

        function loadGroupData(group) {
            var company = {};

              switch(group.code) {
                case 'system':
                  company = globalFunc.findInArray($scope.companies, 'code', 'system');
                  break;
                case 'Supplier':
                  company = group;
                  break;
                case 'Catalog':
                  company = group;
                  break;
                case 'catalog_group':
                  company = globalFunc.findInArray($scope.catalogGroups, '_id', group._id);
                  break;
                case 'alc_group':
                  company = globalFunc.findInArray($scope.alcGroup, '_id', group._id);
                  break;
                default:
                  company = globalFunc.findInArray($scope.companies, '_id', group._id);
                  break;
              }

              if (!!company) {
                company.ticked = true;
                company.manager = globalFunc.findInArray(
                  $scope.selectedSuperiors,
                  'company_code',
                  company.code
                );
                onSelectCompany(company, group, true);
              }
        }

        function getCatalogGroupRoles() {
          $scope.catalogGroupRoles = [];
          _.forEach($scope.groupedAssignments, function(group){
            _.forEach(group.roleAssignments, function (role){
              if (role.weight === 12) {
                var obj = _.cloneDeep(role);
                obj.name = group.name;
                obj.manager = globalFunc.findInArray(
                  $scope.selectedSuperiors,
                  'company_code',
                  role.code
                );
                $scope.catalogGroupRoles.push(obj);
              }
            })
          });
        }

        function getAlcGroupRoles() {
          $scope.alcGroupRoles = [];
          _.forEach($scope.groupedAssignments, function(group){
            _.forEach(group.roleAssignments, function (role){
              if (role.weight === 11) {
                var obj = _.cloneDeep(role);
                obj.name = group.name;
                obj.manager = globalFunc.findInArray(
                  $scope.selectedSuperiors,
                  'company_code',
                  role.code
                );
                $scope.alcGroupRoles.push(obj);
              }
            })
          });
        }

        function initialize() {
          //TEMP: as there is no UI for users assignments for Catalog Group or ALC Groups, for now they are combined in
          // the the company array

          $scope.companies = $scope.companies.concat($scope.catalogGroups);
          $scope.companies = $scope.companies.concat($scope.alcGroups);

          getTemplateUrl($scope.editableState);

          // Add system to companies list if doesn't exist
          if (!globalFunc.findInArray($scope.companies, 'code', 'system')) {
            addSystemToCompanies();
          }

          if (!!$scope.isV2Enabled) {
            getCatalogGroupRoles();
            getAlcGroupRoles();
          }

          $scope.selectedRoleAssignments = [];

          // Prepare the list and assigning the data
          if(!!$scope.groupedAssignments) {
            _.forEach($scope.groupedAssignments, function(group) {
              loadGroupData(group);
            });
          }
        }

        initialize();
      }
    }
  });
