'use strict';

/**
 *
 * @param $scope
 * @param $uibModal
 * @param $uibModalInstance
 * @param globalFunc
 * @param addressList
 * @param companyAddress
 * @param toastr
 * @param addressType
 */
function addNewAddressCtrl($scope, $uibModal, $uibModalInstance, globalFunc, addressList, companyAddress,
                           toastr, addressType) {
  $scope.cancel = cancel;
  $scope.saveNewAddress = saveNewAddress;
  $scope.onSelectedCountry = onSelectedCountry;
  $scope.onSelectedState = onSelectedState;
  $scope.address = {};
  $scope.addressType = addressType;

  function cancel() {
    $uibModalInstance.close();
  }

  function onSelectedCountry(country) {
    $scope.address.state = undefined;
    $scope.address.country = country.descr;
    $scope.selectedMalaysia = !!country.descr === "Malaysia";
  }

  function onSelectedState(state){
    $scope.address.state = state.descr;
  }

  function saveNewAddress(type) {
    if (validate()) {
      $scope.address.addressType = type.toLowerCase();
      $scope.address.companyCode = $scope.company.code;
      var data = angular.copy($scope.address);
      addressList.post(
        data,
        function (resource) {
          if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data._id) {
            var newAddress = resource.content.data;
            newAddress['type'] = $scope.address.addressType;
            saveAddressToCompany(newAddress);
          }
        }, function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    } else {
      toastr.error("Fill the required field");
    }
  }

  function saveAddressToCompany(addressData) {
    var data = {};
    data['address_id'] = addressData._id;
    data['address_type'] = addressData.type;
    companyAddress.put(
      {
        id: $scope.company._id
      },
      data,
      function () {
        toastr.success('The address were updated successfully');
        $uibModalInstance.close(addressData);
      },
      function () {
        toastr.error('Failed to update addresses');
      }
    );
  }

  function validate() {
    return ($scope.address.name && $scope.address.line1 && $scope.address.postal_code && $scope.address.state &&
      $scope.address.city && $scope.address.country && $scope.address.phone);
  }

  function init() {
    $scope.countries = globalFunc.countryCode();
  }

  init()
}

addNewAddressCtrl.$inject = ['$scope', '$uibModal', '$uibModalInstance', 'globalFunc', 'addressList',
  'companyAddress', 'toastr', 'addressType'];

angular
  .module('metabuyer')
  .controller('addNewAddressCtrl', addNewAddressCtrl);
