'use strict';

/**
 * @name prVersionCtrl
 * @desc The controller for PR version
 */
function prV2VersionCtrl($scope, currentPR, previousVersion, $uibModalInstance, versionNumber, globalFunc) {

  $scope.lineItemDiff     = [];

  $scope.version          = versionNumber;
  $scope.currentPR        = currentPR;
  $scope.prevPR           = previousVersion;

  $scope.otherCharges = [{
    key: 'insurance',
    descr: 'Insurance'
  },
  {
    key: 'freight',
    descr: 'Freight'
  },
  {
    key: 'transportation',
    descr: 'Transportation'
  },
  {
    key: 'bahan_bakar_tax',
    descr: 'Bahan Bakar Tax'
  },
  {
    key: 'withholding_tax',
    descr: 'Withholding Tax'
  },
  {
    key: 'miscellaneous',
    descr: 'Miscellaneous'
  }
  ];

  function prepareAccountAssignemnt(item) {
    var data = [];

    if (item.diffStatus === 'updated') {
      if (!!item.account_assignments) {
        _.forEach(item.account_assignments, function(current_account_assignment,index) {
          var temp = {};
          if (!!item.prevVersion.account_assignments) {
            if (item.prevVersion.account_assignments.length === item.account_assignments.length) {
              temp.currentVersion = current_account_assignment;
              temp.prevVersion = item.prevVersion.account_assignments[index];
              data.push(temp);
            } else if (item.account_assignments.length > item.prevVersion.account_assignments.length) {
              if (!!item.prevVersion.account_assignments[index]) {
                temp.currentVersion = current_account_assignment;
                temp.prevVersion = item.prevVersion.account_assignments[index];
                data.push(temp);
              } else {
                temp.currentVersion = current_account_assignment;
                data.push(temp);
              }
            } else {
              if (item.account_assignments.length < item.prevVersion.account_assignments.length) {
                _.forEach(item.prevVersion.account_assignments, function(account_assignment) {
                  var temp = {};
                  temp.prevVersion = account_assignment;
                  data.push(temp);
                })
                temp.currentVersion = current_account_assignment;
                data.push(temp);
              }
            }
          } else {
            temp.currentVersion = current_account_assignment;
            data.push(temp);
          }
        })
      } else {
        if (!!item.prevVersion.account_assignments) {
          _.forEach(item.prevVersion.account_assignments, function(prev_account_assignment) {
            var temp = {};
            temp.prevVersion = prev_account_assignment;
            data.push(temp);
          })
        }
      }
    } else if (item.diffStatus === 'deleted' || item.diffStatus === 'added') {
      _.forEach(item.account_assignments, function(this_item_account_assignment) {
        var temp = {};
        temp.thisVersion = this_item_account_assignment;
        data.push(temp);
      })
    }
    return data;
  }

  function prepareOtherCharges(item){
    var data = [];
    _.forEach($scope.otherCharges, function(otherCharge) {
      var temp = {};
      temp.descr = otherCharge.descr;
      if (_.has(item, otherCharge.key)) {
        temp.currentPR = item[otherCharge.key];
      }

      if (!!item.prevVersion) {
        if (_.has(item.prevVersion, otherCharge.key)) {
          temp.prevVersion = item.prevVersion[otherCharge.key];
        }
      }
      data.push(temp);
    })
    return data;
  }

  // Function list
  $scope.close = close;

  function close() {
    $uibModalInstance.close();
  }

  /**
   * diff two arrays with defining the field to compare
   * @param prevArr
   * @param newArr
   * @param fieldToCompare
   * @returns {Array}
   */
  function diffArray(prevArr, newArr, fieldToCompare) {
    var diff = [];

    // get deleted data
    // and get updated data
    _.forEach(prevArr, function(prev) {
      var deleted = globalFunc.findInArray(newArr, fieldToCompare, prev[fieldToCompare]);
      if (!deleted) {
        prev.diffStatus = 'deleted';
        diff.push(prev);
      } else {
        deleted.diffStatus = 'updated';
        deleted.prevVersion = prev;
        diff.push(deleted);
      }
    });

    // get added data
    // and get updated data
    _.forEach(newArr, function(item) {
      var added = globalFunc.findInArray(prevArr, fieldToCompare, item[fieldToCompare]);
      if (!added) {
        item.diffStatus = 'added';
        diff.push(item);
      }
      // if exist in prevArr (updated) and not exist in diff
      else if (!!added && !globalFunc.findInArray(diff, fieldToCompare, item[fieldToCompare])) {
        item.diffStatus = 'updated';
        item.prevVersion = added;
        diff.push(item);
      }
    });

    return diff;
  }

  function initialize(){
    if (!!$scope.currentPR && !!$scope.prevPR) {
      $scope.lineItemDiff = diffArray($scope.prevPR.pr_items, $scope.currentPR.pr_items, '_id');
    }

    if (!!$scope.lineItemDiff) {
      _.forEach($scope.lineItemDiff, function(item) {
        item.preparedOtherCharges = prepareOtherCharges(item);
        item.preparedAccountAssignments = prepareAccountAssignemnt(item);
      })
    }
  }
  initialize();
}

prV2VersionCtrl.$inject = ['$scope', 'currentPR', 'previousVersion', '$uibModalInstance', 'versionNumber', 'globalFunc'];

angular.module('metabuyer')
  .controller('prV2VersionCtrl', prV2VersionCtrl);
