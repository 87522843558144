'use strict';
/**
 * @name purchaseRequisitionsMainCtrl
 *
 * @description
 *
 * Main controller for the requisitions State
 *
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Sunway Metacloud &copy; 2016
 */

function purchaseRequisitionsV2MainCtrl($scope, $state, globalFunc, $rootScope) {
  var tempState = _.cloneDeep($state.params);

  if (!$rootScope.isMobileMode) {
    $scope.tabData = [
       {
        heading: 'All',
        route: 'main.user.purchaseRequisitionsV2.manage',
        key: 'all',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'all',
          tab: 'all',
          cursor: null
         })
       },
       {
        heading: 'Draft',
        route: 'main.user.purchaseRequisitionsV2.manage',
        key: 'draft',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'draft',
          tab: 'all',
          cursor: null
      })
      },
      {
        heading: 'Pending Approval',
        route: 'main.user.purchaseRequisitionsV2.manage',
        key: 'pending',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'pending',
          tab: 'waiting',
          extraFilterKey: 'criteria[waiting_on][]',
          extraFilterValue: $rootScope.currentUser._id,
          cursor: null
        })
      },
      {
        heading: 'On Hold',
        route: 'main.user.purchaseRequisitionsV2.manage',
        key: 'on_hold',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'on_hold',
          tab: 'all',
          cursor: null
      })
      },
      {
        heading: 'Approved',
        route: 'main.user.purchaseRequisitionsV2.manage',
        key: 'approved',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'approved',
          tab: 'all',
          cursor: null
        })
      }
    ];

    $scope.tabData.more = [
      {
        heading: 'Deleted',
        route: 'main.user.purchaseRequisitionsV2.manage',
        key: 'deleted',
        type: 'more',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'deleted',
          tab: null,
          cursor: null
        })
      },
      {
        heading: 'Withdrawn',
        route: 'main.user.purchaseRequisitionsV2.manage',
        key: 'withdrawn',
        type: 'more',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'withdrawn',
          tab: null,
          cursor: null
        })
      },
      {
        heading: 'Starred',
        route: 'main.user.purchaseRequisitionsV2.manage',
        key: 'starred',
        type: 'more',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'starred',
          tab: null,
          cursor: null
        })
      },
      {
        heading: 'Rejected',
        route: 'main.user.purchaseRequisitionsV2.manage',
        key: 'rejected',
        type: 'more',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'rejected',
          tab: null,
          cursor: null
        })
      }
    ];
  }

  if ($rootScope.isMobileMode) {
    $scope.tabData = [
      {
        heading: 'All',
        route: 'main.user.purchaseRequisitionsV2.manage',
        key: 'all',
        params: globalFunc.filterUrlParams(tempState, {
           status: 'all',
           tab: 'all',
           cursor: null
        })
      },
      {
        heading: 'Pending Approval',
        route: 'main.user.purchaseRequisitionsV2.manage',
        key: 'pending',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'pending',
          tab: 'waiting',
          extraFilterKey: 'criteria[waiting_on][]',
          extraFilterValue: $rootScope.currentUser._id,
          cursor: null
        })
      },
      {
        heading: 'On Hold',
        route: 'main.user.purchaseRequisitionsV2.manage',
        key: 'on_hold',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'on_hold',
          tab: 'all',
          cursor: null
        })
      },
      {
        heading: 'Approved',
        route: 'main.user.purchaseRequisitionsV2.manage',
        key: 'approved',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'approved',
          tab: 'all',
          cursor: null
        })
      },
      {
        heading: 'Deleted',
        route: 'main.user.purchaseRequisitionsV2.manage',
        key: 'deleted',
        type: 'more',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'deleted',
          tab: null,
          cursor: null
        })
      },
      {
        heading: 'Starred',
        route: 'main.user.purchaseRequisitionsV2.manage',
        key: 'starred',
        type: 'more',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'starred',
          tab: null,
          cursor: null
        })
      },
      {
        heading: 'Rejected',
        route: 'main.user.purchaseRequisitionsV2.manage',
        key: 'rejected',
        type: 'more',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'rejected',
          tab: null,
          cursor: null
        })
      }
    ];
  }


  function initialize() {
  //
    $scope.$emit('pageTitleChanged', 'Purchase Requisitions');
    if (!!$state.params && !!$state.params.query) {
      $scope.searchText = $state.params.query;
    }
    else {
      $scope.searchText = '';
    }
    if (!!$state.params && !!$state.params.status) {
      $scope.status = $state.params.status;

  //
  //     //set the default tab on refreshing
      var tempIndex = _.findIndex($scope.tabData, function(tab){return tab.key === $state.params.status.toLowerCase()});
      if(tempIndex < 0 && !$rootScope.isMobileMode) {
        tempIndex = _.findIndex($scope.tabData.more, function (tab) {
          return tab.key === $state.params.status.toLowerCase()
        });
        if(!!tempIndex)
          $scope.activatedTab = $scope.tabData.more[tempIndex];
          }
      else
        $scope.activatedTab = $scope.tabData[tempIndex];
    }
    if (!!$state.params && !!$state.params.filter) {
      $scope.searchFilter = $state.params.filter;
    }

    $scope.searchPlaceholder = 'Search Purchase Requisition';
    $scope.searchState = 'main.user.purchaseRequisitionsV2.manage';
    $scope.advancedSearchState = 'main.user.advancedSearch.purchaseRequisitionV2';
    $scope.parentState = 'main.user.purchaseRequisitionsV2.manage';
    $scope.parentStateParams = $state.params;

  }

  if (!$rootScope.isMobileMode) {
    $scope.columns = [
      {
        id: 'check box',
        title: '',
        unsortable: true,
        unsearchable: true,
        conditional: true,
        condition: ['pending', 'approved'],
        unSelectable: true
      },
      {id: 'pr_select', label: '', conditional: true, condition: ['approved']},
      {
        id: 'mass_update_status',
        label: '',
        unsortable: true,
        conditional: true,
        condition: ['approved'],
        unsearchable: true,
        unSelectable: true
      },
      {id: 'star', label: 'FAVORITE', unsortable: true, unsearchable: true, unSelectable: true},
      {id: 'header_info|reference', label: 'PR#'},
      {id: 'header_info|title', label: 'TITLE'},
      {id: 'attachment', unsortable: true, unsearchable: true, unSelectable: true},
      {
        id: 'waiting_on',
        label: 'WAITING ON',
        conditional: true,
        condition: ['pending'],
        unsortable: true,
        unsearchable: true
      },
      {
        id: 'on_hold',
        label: 'HOLD BY',
        conditional: true,
        condition: ['on_hold'],
        unsortable: true,
        unsearchable: true
      },
      {id: 'pos', label: 'PO#', conditional: true, condition: ['approved'], unsortable: true, unsearchable: true},
      {id: 'priceComparison_number|pc_number', label: 'PC#', unsortable: true, conditional: true, condition: ['pending', 'approved']},
      {id: 'priceComparison_status|status', label: 'PC STATUS', conditional: true, condition: ['all', 'pending'], unsearchable: true, unsortable: true},
      {
        id: 'grand_total',
        label: 'AMOUNT',
        type: 'double',
        unsearchable: true
      },
      {id: 'closed_at', label: 'APPROVED DATE', conditional: true, condition: ['approved'], unsearchable: true},
      {
        id: 'submitted_at',
        label: 'SUBMITTED DATE',
        conditional: true,
        condition: ['pending', 'approved'],
        type: 'date',
        unsearchable: true
      },
      {
        id: 'progress',
        label: 'PROGRESS',
        conditional: true,
        condition: ['pending', 'approved'],
        unsortable: true,
        unsearchable: true
      },
      {
        id: 'needed_by_date',
        label: 'NEEDED BY',
        type: 'date',
        unsearchable: true
      },
      {id: 'creator_info|display_name', label: 'CREATOR'},
      {id: 'requestor|display_name', label: 'REQUESTOR'},
      {
        id: 'created_at',
        label: 'CREATED AT',
        type: 'date',
        unsearchable: true
      },
      {
        id: 'updated_at',
        label: 'UPDATED AT',
        type: 'date',
        unsearchable: true
      },
      {id: 'header_info|company|descr', label: 'COMPANY'},
      {id: 'header_info|shadow_company|descr', label: 'BILLING COMPANY'},
      {id: 'header_info|cost_center|descr', label: 'COST CENTER'},
      {id: 'header_info|status', label: 'STATUS', unsearchable: true},
      {id: 'header_info|expense_type|descr',
        label: 'EXPENSE TYPE',
        unsearchable: true
      },
      {
        id: 'withdrawn_at',
        label: 'WITHDRAWN AT',
        conditional: true,
        condition: ['withdrawn'],
        type: 'date',
        unsearchable: true
      },
      {id: 'withdrawer|display_name', label: 'WITHDRAWN BY', conditional: true, condition: ['withdrawn']},
      {
        id: 'deleted_at',
        label: 'DELETED DATE',
        conditional: true,
        condition: ['deleted'],
        type: 'date',
        unsearchable: true
      },
      {
        id: 'delete_button',
        label: '',
        unsortable: true,
        conditional: true,
        condition: ['draft'],
        unsearchable: true,
        unSelectable: true
      },
      {
        id: 'Item search',
        label: 'ITEM NAME',
        unListable: true,
        unsortable: true,
        unSelectable: true,
        unsearchable: true
      },
      {
        id: 'pr_pending_process',
        label: 'PR PENDING PROCESS',
        conditional: true,
        condition: ['approved'],
        unsortable: false,
        unSelectable: true,
        unsearchable: true
      }
    ];
  }
  else {
    $scope.columns = [
      {
        id: 'check box',
        title: '',
        unsortable: true,
        unsearchable: true,
        conditional: true,
        condition: ['pending'],
        unSelectable: true
      },
      {id: 'star', label: 'FAVORITE', unsortable: true, unsearchable: true, unSelectable: true},
      {id: 'header_info|reference', label: 'COMPANY/PR#'},
      {id: 'title', label: 'TITLE'},
      {
        id: 'grand_total',
        label: 'AMOUNT',
        type: 'double',
        unsearchable: true
      },
      {id: 'showMore', label: '', type: 'boolean'}
    ];
  }

  $scope.$on('$stateChangeStart', function (event, toState, toParams) {
    if (!!toParams.status) {
      $scope.parentStateParams = toParams;
      $scope.status = toParams.status;
    }
  });

  initialize();
}
purchaseRequisitionsV2MainCtrl.$inject = ['$scope', '$state', 'globalFunc', '$rootScope'];

angular
  .module('metabuyer')
  .controller('purchaseRequisitionsV2MainCtrl', purchaseRequisitionsV2MainCtrl);
