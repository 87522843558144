'use strict';

/**
 * @name CategoryTypeCtrl
 */
function CategoryTypeCtrl($scope, $stateParams, $uibModal, dataList, categoryTypeMasterDataList, $http,
                            $filter, pathConstants, $location, $rootScope) {
  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.categoryTypeMasterDataList = categoryTypeMasterDataList;
  $scope.getStatus = getStatus;
  $scope.addCategoryType = addCategoryType;
  $scope.editCategoryType = editCategoryType;
  $scope.showColumn = showColumn;
  $scope.search = search;

  $scope.columns = [
    {id: 'code', label: 'CODE'},
    {id: 'name', label: 'NAME'},
    {id: 'created_at', label: 'CREATED AT'},
    {id: 'created_by', label: 'CREATED BY'},
    {id: 'updated_at', label: 'UPDATED AT'},
    {id: 'updated_by', label: 'UPDATED BY'},
    {id: 'is_active', label: 'STATUS'}
  ];

  $scope.storageKey = 'master-data-category-type-selected-columns';

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional) {
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function getStatus(isActive) {
    return !!isActive ? 'Active' : 'Inactive';
  }

  function editCategoryType(index) {
    var modalInstance = $uibModal.open({
      templateUrl: 'app/masterDataManagement/categoryType/detail/details.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'categoryTypeDetailsCtrl',
      resolve: {
        categoryType: function ($q, categoryTypeMasterDataList) {
          var deferred = $q.defer();
          categoryTypeMasterDataList.get(
            {
              id: $scope.dataList[index].uuid
            },
            function (resource) {
              deferred.resolve(resource.content || {data: []});
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        }
      }
    });

    modalInstance.result.then(function (newValue) {
      if (!!newValue) {
        _.forEach($scope.dataList, function (dataList) {
          if (dataList._id === newValue._id) {
            dataList.uuid = newValue.uuid;
            dataList.code = newValue.code;
            dataList.name = newValue.name;
            dataList.creator_info = newValue.creator_info;
            dataList.created_at = newValue.created_at;
            dataList.updater_info = newValue.updater_info;
            dataList.updated_at = newValue.updated_at;
            dataList.is_active = newValue.is_active;
          }
        });
      }
    }, function () {
    });
  }

  function addCategoryType() {
    var addCategoryType = $uibModal.open({
      templateUrl: 'app/masterDataManagement/categoryType/manage/add.html',
      backdrop: 'static',
      controller: 'addCategoryTypeCtrl',
      keyboard: false,
      scope: $scope
    });

    addCategoryType.result.then(function (result) {
      var categoryType = result.content.data;
      $scope.dataList.unshift({
        id: categoryType._id,
        uuid: categoryType.uuid,
        code: categoryType.code,
        name: categoryType.name,
        created_at: categoryType.created_at,
        updated_at: categoryType.updated_at,
        creator_info: categoryType.creator_info,
        updater_info: categoryType.updater_info,
        is_active: categoryType.is_active
      });
    });
  }

  function prepareImportParams () {
    $scope.params = {
        'class': 'App\\Metabuyer\\CategoryType\\Models\\CategoryType'
    };
    _.forEach ($scope.dataList, function(categoryType, index){
        var temp = 'additional_params[]['+ index+ ']';
        $scope.params[temp] = categoryType.code;
    });
  }

  function search(query) {
    $location.search({query: query});
  }

  function init() {
    if (!!$stateParams.search) {
      $scope.searchText = $stateParams.search;
    }
    else {
      $scope.searchText = '';
    }

    prepareImportParams();
  }

  init();
}

CategoryTypeCtrl.$inject = ['$scope', '$stateParams', '$uibModal', 'dataList', 'categoryTypeMasterDataList',
  '$http', '$filter', 'pathConstants', '$location', '$rootScope'];

angular
  .module('metabuyer')
  .controller('CategoryTypeCtrl', CategoryTypeCtrl);
