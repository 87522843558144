'use strict';
/**
 * @name exportUserAssignmentsCtrl
 *
 * @description
 * Controller for exporting multiple users' assignments
 *
 * @author Ahmed Saleh <a.s.alsalali@gmail.com>
 * @copyright 2017 Metacloud Sdn. Bhd.
 */

function exportUserAssignmentsCtrl(
  $scope, $http, $uibModalInstance, pathConstants, globalFunc, exportUserAssignments, $filter
) {
  $scope.selectedUsers = [];
  $scope.loading = false;

  $scope.searchUsers = searchUsers;
  $scope.close = close;
  $scope.onSelectUser = onSelectUser;
  $scope.exportAssignments = exportAssignments;
  $scope.removeUser = removeUser;

  function searchUsers(query) {

    $scope.loading = true;
    return $http.get(pathConstants.apiUrls.genericSearch.searchBasePath + '/users', {
      params: {
        module: 'users',
        'criteria[0][status]': 1,
        'criteria[1][display_name]': query,
        'criteria[1][email]': query,
        'criteria[1][$operator]' : 'or',
        criteria_operator: 'and'
      }
    }).then(function (response) {
      $scope.loading = false;
      if (!!response.data && !!response.data.content && !!response.data.content.data) {
        return response.data.content.data.map(function (item) {
          $scope.loading = false;
          return item;
        });
      } else {
        return null;
      }
    });

  }

  function onSelectUser(user) {
    if (!globalFunc.findInArray($scope.selectedUsers, '_id', user._id)) {
      $scope.selectedUsers.push(user);
      $scope.collaboratorsTextFiled = {
        input: ''
      }
    }
  }

  function exportAssignments() {
    if (!$scope.selectedUsers) {
      globalFunc.objectErrorMessage('Please select at least 1 user')
    }

    var ids = [];
    _.forEach($scope.selectedUsers, function (user) {
      ids.push(user._id);
    });

    exportUserAssignments.get({
      usersIds: [ids]
    }, function (resource) {
      if (!!resource.content && !!resource.content.data) {
        var newUrl = $filter('format')(pathConstants.apiUrls.reports.download, {
          id: resource.content.data
        });

        window.open(newUrl, '_self');
      } else {
        globalFunc.objectErrorMessage('An error occurred.');
      }
    }, function(error) {
      globalFunc.objectErrorMessage(error);
    });
  }

  function removeUser(user) {
    $scope.selectedUsers = globalFunc.removeValueFromArray($scope.selectedUsers, '_id', user._id);
  }

  function close() {
    $uibModalInstance.close();
  }

}

exportUserAssignmentsCtrl.$inject = [
  '$scope', '$http', '$uibModalInstance', 'pathConstants', 'globalFunc', 'exportUserAssignments', '$filter'
];

angular
  .module('metabuyer')
  .controller('exportUserAssignmentsCtrl', exportUserAssignmentsCtrl);
