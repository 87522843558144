'use strict';

/**
 * @name priceComparisonSearchQuotationModal
 * @desc The controller for price comparison quotation modal
 *
 * @author Nazrul Wazir <nazrulhmw@sunway.com.my>
 * @copyright 2023 Metacloud Sdn. Bhd.
 */
function priceComparisonSearchQuotationModal($rootScope, $scope, $uibModalInstance, $uibModal, $http, globalFunc, pathConstants, $filter, pc, pr, mode, toastr, searchModule) {

  $scope.pc = pc;
  $scope.pr = pr;
  $scope.mode = mode;
  $scope.close = close;
  $scope.supplierSearchText = '';
  $scope.validSupplierIsSelected = true;
  $scope.invalidSupplierSelected = false;
  $scope.searchSuppliers = searchSuppliers;
  $scope.onSupplierSelect = onSupplierSelect;
  $scope.validateFieldSelection = validateFieldSelection;
  $scope.validItemsSelected = true;
  $scope.onItemSelect = onItemSelect;
  $scope.itemSearchText = '';
  $scope.searchItems = searchItems;
  $scope.resetForm = resetForm;
  $scope.searchForm = searchForm;
  $scope.dataList = [];
  $scope.embeddedParams = [];
  $scope.meta = {
    current_page: 1,
    from: 0,
    to: 0,
    next: null,
    prev: null,
    last_page: 1,
    per_page: 20,
    total: 0
  };
  $scope.getPrNumber = getPrNumber;
  $scope.getPcNumber = getPcNumber;
  $scope.getItems = getItems;
  $scope.selectQuotation = selectQuotation;
  $scope.searchModule = searchModule;

  $scope.searchFields = {
    quotationNumber: null,
    prNumber: null,
    pcTitle: null,
    supplierId: null,
    itemCode: null,
  };

  $scope.columns = [
    { id: 'quotationNumber', label: 'Quotation', selected: true },
    { id: 'prNumber', label: 'PR Number', selected: true },
    { id: 'pcNumber', label: 'PC Number', selected: true },
    { id: 'supplier', label: 'Supplier', selected: true },
    { id: 'itemCode', label: 'Item', selected: true },
    { id: 'createdAt', label: 'Created At', selected: true }
  ];

  $scope.dataListPageChanged = dataListPageChanged;
  $scope.preparePagination = preparePagination;
  $scope.numPerPage = 20;
  $scope.maxSize = 5;
  $scope.currentQuotationPage = {
    number: 1
  };

  function dataListPageChanged() {
    var begin = (($scope.currentQuotationPage.number - 1) * $scope.numPerPage);
    var end = begin + $scope.numPerPage;
    $scope.filterDataList = $scope.dataList.slice(begin, end);
  }

  function preparePagination() {
    $scope.filterDataList = $scope.dataList.slice(0, $scope.numPerPage);
  }

  function selectQuotation(quotation) {
    swal({
        title: 'Confirm Select',
        text: quotation.quotation_number,
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function(isConfirm) {
        if (isConfirm) {

          if ($scope.mode === 'pc') {
            $rootScope.$broadcast('addSearchQuotation', {
              quotation: quotation,
              pc: $scope.pc
            });
            close();
          } else {
            addPrSearchQuotation(quotation);
          }
        }
      }
    );
  }

  /**
   * add pr search quotation
   * @param quotation
   */
  function addPrSearchQuotation(quotation) {
    if (!!quotation) {
      $scope.supplierSearching = true;

      var moduleParam = {
        module: 'suppliers'
      };

      var additionalParams = {
        'criteria[0][string_id]': quotation.supplier_id,
        company_contract: $scope.modelData.companyCode,
        offset: 1
      };

      return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: additionalParams
      }).then(function(response) {

        if (response.data.content.data.length === 0) {
          return [];
        }

        var supplier = _.filter(response.data.content.data, function(item) {
          if (!!item.selected_contract.status && item.selected_contract.status.toLowerCase() === 'active') {
            return item;
          }
        });

        if (!!supplier) {
          var supplier = supplier[0];

          $rootScope.$broadcast('addPrSearchQuotation', {
            quotation: quotation,
            pr: $scope.pr,
            supplier: supplier
          });

          close();
        } else {
          toastr.error("Company's supplier is not active");
        }
      });
    }
  }


  function getPrNumber(quotation) {
    if (!!quotation.pr[0]) {
      return quotation.pr[0].header_info.custom_reference;
    }
  }

  function getPcNumber(quotation) {
    if (!!quotation.pc[0]) {
      return quotation.pc[0].pc_number;
    }
  }

  function getItems(quotation) {

    var itemList = '<ul>';

    _.forEach(quotation.items, function(item, index) {
      if (item.unit_price !== undefined) {
        itemList += '<li>' + item.item_descr + ' - ' + item.item_code + ' (' + item.unit_price.toFixed(2) + ' ' + item.currency.code + ')' + (index !== quotation.items.length - 1 ? ', ' : '') + '</li>';
      }
    });

    itemList += '</ul>';

    return itemList;
  }

  function validateForm() {
    var throwErr = false;

    if (!throwErr) {
      var searchData = _.clone($scope.searchFields);
      return buildParam(searchData);
    }
  }

  function buildParam(data) {
    var params = {};

    if (!!data.quotationNumber) {
      params['criteria[1][quotation_number]'] = data.quotationNumber
    }

    if (!!data.prNumber) {
      params['criteria[1][pr|header_info|custom_reference]'] = data.prNumber
    }

    if (!!data.pcTitle) {
      params['criteria[1][pc|title]'] = data.pcTitle
    }

    if (!!data.supplierId) {
      params['criteria[1][supplier_id]'] = data.supplierId
    }

    if (!!data.itemCode) {
      params['criteria[1][items.item_code]'] = data.itemCode
    }

    if ($scope.mode === 'pr') {
      params['criteria[1][pr_id]'] = $scope.pr._id;
    } else {
      params['criteria[1][pr_id]'] = $scope.pc.pr_id;
    }

    if (_.isEmpty(params)) {
      toastr.error('Please fill in at least one field');
      return false;
    } else {
      params['module'] = 'quotation';
      params['offset'] = 20;
      params['criteria_operator'] = 'and';
      params['criteria[1][$operator]'] = 'and';
      params['company_contract'] = true;
      return params;
    }
  }

  function searchForm() {
    var searchParam = validateForm();

    if (!!searchParam) {
      $scope.dataList = [];
      $scope.filterDataList = [];
      $scope.embeddedParams = searchParam;

      var moduleParam = {
        module: 'quotation'
      };

      $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: searchParam
      }).then(function(resource) {
        if (resource.data.content.data.length > 0) {

          $scope.emptyInput = false;
          $scope.dataList = resource.data.content.data;
          $scope.meta = resource.data.content.meta.cursor;
          return;
        }
      }, function(error) {
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  function sanitizeSupplierStatus() {
    var indexs = [];
    var newDataList = [];

    _.forEach($scope.dataList, function(data, index) {
      var validSupplier = true;
      var billingCompany = data.pr[0].header_info.shadow_company.code;
      var company = data.pr[0].header_info.company.code;

      _.forEach(data.supplier[0].company_contracts, function(supplier) {

        if (supplier.company !== undefined && supplier.status !== undefined) {
          if (supplier.company.company_code === company || supplier.company.company_code === billingCompany) {
            if (supplier.status === 'ACTIVE') {
              newDataList.push(data);
            }
          }
        }
      });
    });

    $scope.dataList = newDataList;
  }

  function removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  function setModelData() {
    if ($scope.mode === 'pc') {
      var companyCode = $scope.pc.pr_info.company.code;
      var expenseTypeCat = $scope.pc.pr_info.expense_type.category;
      var isPcm = $scope.pc.pr_info.expense_type.is_pcm;
      var prItems = $scope.pc.pr_info.items;
    } else {
      var companyCode = $scope.pr.company.code;
      var expenseTypeCat = $scope.pr.expense_type.category;
      var isPcm = $scope.pr.expense_type.is_pcm;
      var prItems = $scope.pr.pr_items;
    }

    $scope.modelData = {
      companyCode: companyCode,
      expenseTypeCat: expenseTypeCat,
      isPcm: isPcm,
      prItems: prItems
    }
  }

  function resetForm() {
    $scope.searchFields = {
      quotationNumber: null,
      prNumber: null,
      pcTitle: null,
      supplierId: null,
      itemCode: null,
    };

    $scope.supplierSearchText = '';
    $scope.itemSearchText = '';

    $scope.dataList = [];
    $scope.filterDataList = [];
    $scope.embeddedParams = [];
    $scope.meta = {
      current_page: 1,
      from: 0,
      to: 0,
      next: null,
      prev: null,
      last_page: 1,
      per_page: 20,
      total: 0
    };
  }

  function validateFieldSelection(field) {}

  function onItemSelect(item) {
    $scope.validItemsSelected = true;
    return $scope.searchFields.itemCode = item.item_master_code;
  }

  function searchItems(query) {

    var additionalParams = {
      'criteria[0][company|code]': $scope.modelData.companyCode,
      'criteria[1][expense_type_category]': $scope.modelData.expenseTypeCat,
      'criteria[2][is_pcm]': $scope.modelData.isPcm,
      'criteria[3][item_master_code]': query,
      'criteria[3][name]': query,
      'criteria[3][$operator]': 'or',
      'criteria[4][is_active]': true,
      'criteria_operator': 'and',
      offset: 100
    };

    var moduleParam = {
      module: 'company-item'
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams
    }).then(function(response) {
      $scope.loading = false;
      return response.data.content.data.map(function(item) {
        $scope.loading = false;
        return item;
      });
    }, function(error) {
      globalFunc.objectErrorMessage(error);
    });
  }

  function onSupplierSelect(supplier) {
    return $scope.searchFields.supplierId = supplier._id;
  }

  function searchSuppliers(val) {
    if (!!val && val.length > 2) {

      var moduleParam = {
        module: 'suppliers'
      };

      var additionalParams = {
        'criteria[0][basic_info|descr]': val,
        'criteria[0][basic_info|reg_no]': val,
        'criteria[0][$operator]': 'or',
        'criteria[1][basic_info|status][0]': 'active',
        'criteria[1][basic_info|status][1]': 'blacklisted',
        'criteria[1][basic_info|status][2]': 'inactive',
        'criteria[1][basic_info|status][3]': 'active_pending',
        criteria_operator: 'and',
        company_contract: $scope.modelData.companyCode,
        offset: 100
      };

      return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: additionalParams
      }).then(function(response) {
        $scope.loading = false;
        return response.data.content.data.map(function(item) {
          $scope.loading = false;
          return item;
        });
      }, function(error) {
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  function initialize() {
    setModelData();
  }

  function close() {
    $uibModalInstance.close();
  }

  $scope.$on('embeddedPaginationResultsEmit', function(event, data) {

    $scope.dataList = [];
    $scope.emptyInput = false;
    $scope.dataList = data.content.data;
    $scope.meta = data.content.meta.cursor;
  });

  initialize();
}

priceComparisonSearchQuotationModal.$inject = ['$rootScope', '$scope', '$uibModalInstance', '$uibModal', '$http', 'globalFunc', 'pathConstants', '$filter', 'pc', 'pr', 'mode', 'toastr', 'searchModule'];

angular.module('metabuyer')
  .controller('priceComparisonSearchQuotationModal', priceComparisonSearchQuotationModal);
