'use strict';

angular.module('metabuyer')
    .directive('massEdit', massEdit)
    .controller('massEditCtrl', massEditCtrl);

function massEdit($uibModal){
    return {
        restrict: 'A',
        scope: {
            baseList: '=',
            selectedList: '=',
            searchFilter: '@',
            callback: '&'
        },
        link: function(scope, element, attrs) {
            var panel = function () {
                scope.modalInstance = $uibModal.open({
                    templateUrl: 'components/massEdit/massEditTemplate.html',
                    controller: 'massEditCtrl',
                    backdrop: 'static',
                    keyboard: true,
                    scope: scope,
                    size: 'lg'
                });
            };

            element.bind('click' , function($event){
                panel();
            });
        }
    }
}

function massEditCtrl($scope, $uibModalInstance, globalFunc, $uibModal, toastr, supplierFunctions) {

  $scope.tempSelectedList = (!!$scope.selectedList && !!$scope.selectedList.length) ?
    _.cloneDeep($scope.selectedList) : [];

  $scope.submitSelection = submitSelection;
  $scope.removeSelection = removeSelection;
  $scope.selectObject = selectObject;
  $scope.cancel = cancel;

  function setBaseData() {
    $scope.tempBaseList = (!!$scope.baseList && !!$scope.baseList.length) ?
      _.cloneDeep($scope.baseList) : [];
  }

  $scope.closePanel = function () {
    $uibModalInstance.close();
  };

  function submitSelection() {
    $scope.selectedList = _.cloneDeep($scope.tempSelectedList);
    $scope.callback($scope.selectedList);
    var selectedList = $scope.selectedList;
    supplierFunctions.setSelectedCompanyContract(selectedList);
    $uibModalInstance.dismiss();
  }

  function selectObject(obj) {
    if (!globalFunc.findInArray($scope.tempSelectedList, '_id', obj._id)) {
      $scope.tempSelectedList.push(obj);
      $scope.tempBaseList = globalFunc.removeValueFromArray($scope.tempBaseList, '_id', obj._id);
    }
  }

  function removeSelection(obj) {
    $scope.tempSelectedList = globalFunc.removeValueFromArray($scope.tempSelectedList, '_id', obj._id);
    if (!globalFunc.findInArray($scope.tempBaseList, '_id', obj._id)) {
      $scope.tempBaseList.push(obj);
    }
  }

  function cancel() {
    $uibModalInstance.dismiss();
  }

  $scope.$watch('baseList', function (newVal, oldValue) {
    if (!!newVal)
      setBaseData();
  });
}


massEditCtrl.$inject = ['$scope', '$uibModalInstance', 'globalFunc', '$uibModal', 'toastr', 'supplierFunctions'];
