'use strict';

angular.module('metabuyer.services.logViewer', ['ngResource'])
  .factory('logViewer', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.laravelLog,
      {},
      {
        'get': {
          method: 'GET',
        }
      }
    );
  });

