'use strict';

angular.module('metabuyer.services.companies', ['ngResource'])
  .factory('Companies', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.single,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET', ignoreLoadingBar: true},
        'put': {method: 'PUT'},
        'delete': {method: 'DELETE'},
        'post': {method: 'POST'}
      }
    );
  })
  .factory('companyCostCenters', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.costCenters,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('CompanyAccountCode', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.accountCode,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('companyActiveUsers', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.activeUsers,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('getUsersInCompanies', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.usersInCompanies,
      {},
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('companyCurrencies', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.currencies,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET', ignoreLoadingBar: true}
      }
    );
  })
  //companies image factory with function to upload
  .factory('companiesDetailImage', function ($resource, pathConstants) {
    return {
      uploadImagePath: function (companyID, type) {
        var path = pathConstants.apiUrls.companies.image;
        return path.replace(":id", companyID).replace(":type", type);
      }
    }
  })
  .factory('searchCompany', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.search,
      {
        query: '@query'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('companyReferenceCounter', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.referenceCounter,
      {
        companyId: '@companyId',
        referenceId: '@referenceId',
        action: '@action'
      },
      {
        'get': {method: 'GET'},
        'put': {method: 'PUT'},
        'delete': {method: 'DELETE'},
        'post': {method: 'POST'}
      }
    );
  })
  .factory('companyReferenceCounterSearch', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.referenceCounterSearch,
      {
        query: '@query'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('companyGroupsList',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.catalogGroups.list,
        {
          groupId: '@groupId',
          companyId: '@companyId'
        },
        {
          'get': {method: 'GET'},
          'put': {method: 'PUT'},
          'post': {method: 'POST'},
          'delete': {method: 'DELETE'}
        }
      );
    })
  .factory('companyBudgetSetting', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.budgetSetting,
      {
        id: '@id'
      },
      {
        'put': {method: 'PUT'}
      }
    );
  })
  .factory('companyGroupsAction',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.catalogGroups.assignment,
        {
          groupId: '@groupId',
          companyId: '@companyId'
        },
        {
          'get': {method: 'GET'},
          'put': {method: 'PUT'},
          'post': {method: 'POST'}
        }
      );
    })
  .factory('companyCostCentersAddress',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.companies.costCentersAddress,
        {
          companyId: '@companyId'
        },
        {
          'get': {method: 'GET'}
        }
      );
    })
  .factory('companyApprovalSettings',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.companies.approvalSettings,
        {
          id: '@id'
        },
        {
          'put': {method: 'PUT'}
        }
      );
    })
  .factory('companyErpCostCenterList',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.companies.erpCostCenterList,
        {
          companyId: '@companyId'
        },
        {
          'get': {method: 'GET', ignoreLoadingBar: true},
          'post': {method: 'POST'},
        }
      );
    })
    .factory('companyErpCostCenter',
      function($resource, pathConstants) {
        return $resource(
          pathConstants.apiUrls.companies.erpCostCenter,
          {
            id: '@id'
          },
          {
            'delete': {method: 'DELETE'}
          }
        );
      })
  .factory('companyErpOrderTypeList',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.companies.erpOrderTypeList,
        {
          companyId: '@companyId'
        },
        {
          'get': {method: 'GET', ignoreLoadingBar: true},
          'post': {method: 'POST'},
        }
      );
    })
  .factory('companyErpOrderType',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.companies.erpOrderType,
        {
          id: '@id'
        },
        {
          'delete': {method: 'DELETE'}
        }
      );
    })
  .factory('companyExportItemTax',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.companies.exportItemTax,
        {
          companyId: '@companyId'
        },
        {
          'get': {method: 'GET'}
        }
      );
    })
  .factory('companyItemTaxHistory',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.companies.itemTaxHistory,
        {
          companyId: '@companyId'
        },
        {
          'get': {method: 'GET'}
        }
      );
    })
  .factory('companyDownloadItemTaxFile',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.companies.downloadItemTax,
        {
          companyId: '@companyId',
          attachmentId: '@attachmentId'
        },
        {
          'get': {method: 'GET'},
          'download': {
            method: "GET",
            params: {
              companyId: "@companyId",
              attachmentId: "@attachmentId"
            },
            url: pathConstants.apiUrls.items.downloadItemTax
          }
        }
      );
    })
  .factory('companyPurchaseRequisitionSettings',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.companies.purchaseRequisitionSettings,
        {
          id: '@id'
        },
        {
          'put': {method: 'PUT'}
        }
      );
    })
  .factory('companyPurchaseOrderSettings',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.companies.purchaseOrderSettings,
        {
          id: '@id'
        },
        {
          'put': {method: 'PUT'}
        }
      );
    })
  .factory('companyErpIntegrationSettings',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.companies.erpIntegrationSettings,
        {
          id: '@id'
        },
        {
          'put': {method: 'PUT'}
        }
      );
    })
  .factory('companyOtherInformation',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.companies.otherInformation,
        {
          id: '@id'
        },
        {
          'put': {method: 'PUT'}
        }
      )
    })
  .factory('companyBillingInformation',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.companies.billingInformation,
        {
          id: '@id'
        },
        {
          'put': {method: 'PUT'}
        }
      )
    })
  .factory('companySupplierWebSetting',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.companies.supplierWeb,
        {
          id: '@id'
        },
        {
          'put': {method: 'PUT'}
        }
      )
    })
  .factory('exportCompanyUserAssignments',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.companies.exportUserAssignments,
        {
          id: '@id'
        },
        {
          'get': {method: 'GET'}
        }
      )
    })
  .factory('getAllCompanies',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.companies.allCompanies,
        {},
        {
          'get': {method: 'GET'}
        }
      )
    })
  .factory('toleranceLimit', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.toleranceLimit,
      {
        id: '@id'
      },
      {
        'get'   : {method: 'GET'},
        'put'   : {method: 'PUT'}
      }
    );
  })
  .factory('automatedPo', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.automatedPo,
      {
        id: '@id'
      },
      {
        'get'   : {method: 'GET'},
        'put'   : {method: 'PUT'}
      }
    );
  })
  .factory('setCompanyDefaultAddress', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.setDefaultAddress,
      {
        id: '@id'
      },
      {
        'put'   : {method: 'PUT'}
      }
    );
  })
  .factory('consolidationExpired', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.consolidationExpired,
      {
        id: '@id'
      },
      {
        'put'   : {method: 'PUT'}
      }
    );
  })
  .factory('companyProject', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companyProject.single,
      {
        uuid: '@uuid'
      },
      {
        'post'   : {method: 'POST'},
        'put'   : {method: 'PUT'},
        'get'   : {method: 'GET'}
      }
    );
  })
  .factory('companyAddress', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.address,
      {
        id: '@id'
      },
      {
        'post'  : {method: 'POST'},
        'get'   : {method: 'GET'},
        'put'   : {method: 'PUT'}
      }
    );
  })
  .factory('exportCompanyAddresses',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.companies.exportCompanyAddresses,
        {
          companyId: '@companyId',
          type: '@type'
        },
        {
          'get': {method: 'GET'}
        }
    );
  })
  .factory('companyModeOfPurchase',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.companies.modeOfPurchase,
        {
          id: '@id'
        },
        {
          'get': {method: 'GET'}
        }
    );
  })
  .factory('billingFromCompanies',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.companies.billingFromCompanies,
        {
          code: '@code'
        },
        {
          'get': {method: 'GET'}
        }
      );
    })
  .factory('priceComparisonCompanySetting', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.priceComparison,
      {
        id: '@id'
      },
      {
        'put'   : {method: 'PUT'}
      }
    );
  });
