'use strict';
/**
 * @name rolesListingCtrl
 * @desc Controller for listing the roles for given company
 */

function rolesListingCtrl($scope, $stateParams, $rootScope, securityFunction, companyRoles) {

  $scope.selectedCompany = securityFunction.getSelectedCompany();
  $scope.dataList = [];
  $scope.selectedRole = {};

  $scope.showColumn = showColumn;
  $scope.showList = showList;

  $scope.columns = [
    {id: 'name', label: 'Role Name', unsortable: true, unsearchable: true},
    // {id: 'type', label: 'Role Type', unsortable: true, unsearchable: true},
    {id: 'descr', label: 'Role Description', unsortable: true, unsearchable: true},
    {id: 'creator.display_name', label: 'Created By', unsortable: true, unsearchable: true},
    {id: 'created_at', label: 'Created At', unsortable: true, unsearchable: true},
    {id: 'updater.display_name', label: 'Updated By', unsortable: true, unsearchable: true},
    {id: 'updated_at', label: 'Updated At', unsortable: true, unsearchable: true}
  ];
  $scope.storageKey = 'roles-listing-selected-columns';

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional) {
          if($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array) {
            for(var j in $scope.columns[i].condition) {
              if($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function showList() {
    return Object.keys($scope.selectedCompany).length > 0;
  }

  function getRoles() {
    companyRoles.get(
      {
        code: $stateParams.code,
        organizationLevel: $stateParams.organizationLevel
      },
      function (resource) {
        if (!!resource.content) {
          $scope.dataList = resource.content;
        }
      },
      function (error) {
        toastr.error('Something went wrong. Please refresh the page');
      }
    );
  }

  function initialize() {
    getRoles();
  }

  initialize();
}

rolesListingCtrl.$inject = ['$scope', '$stateParams', '$rootScope', 'securityFunction', 'companyRoles'];

angular
    .module('metabuyer')
    .controller('rolesListingCtrl', rolesListingCtrl);
