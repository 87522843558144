'use strict';

function alcGroupListCtrl(
  $scope, alcGroupMasterDataList
) {

  function getAlcGroupCompanies(uuid){
    alcGroupMasterDataList.get({
      uuid: uuid
    },
    function(resource){
      if (!!resource.content && !!resource.content.data){
        $scope.alcData.alc_group.companies = resource.content.data.companies;
      }
    },
    function(error){

    })
  }

  function initialize() {
    if (!!$scope.alcData && $scope.alcData.alc_group && $scope.alcData.companies) {
      $scope.alcData.alc_group.companies = $scope.alcData.companies;
    }
  }
  initialize();
}

alcGroupListCtrl.$inject = [ '$scope', 'alcGroupMasterDataList' ];

angular
  .module('metabuyer')
  .directive('alcGroupList',function() {
    return {
      restrict: 'E',
      scope: true,
      templateUrl: 'components/alc/detailsAlcGroupList/detailsAlcGroupTemplate.html',
      controller: 'alcGroupListCtrl'
    }
  })
  .controller('alcGroupListCtrl', alcGroupListCtrl);
