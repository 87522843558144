'use strict';
/**
 * @name assetCategoryDetailsCtrl
 * @desc Controller for account codes details
 */
function assetCategoryDetailsCtrl(
  $scope, $uibModalInstance, toastr, $state, companyId, companyCode,
  globalFunc, assetCategoryDetails, singleAssetCategory, $http, $filter, pathConstants, $rootScope
) {
  $scope.editClicked = false;
  $scope.assetCategoryDetails = _.cloneDeep(assetCategoryDetails.data);
  $scope.companyId = companyId;
  $scope.companyCode = companyCode;
  $scope.tab = 1;
  $scope.close = close;
  $scope.toggleTab = toggleTab;
  $scope.editAssetCategory = editAssetCategory;
  $scope.updateAssetCategory = updateAssetCategory;
  $scope.selectAccountCode = selectAccountCode;
  $scope.searchAccountCodes = searchAccountCodes;

  function toggleTab(tab) {
    return $scope.tab = tab;
  }

  function close() {
    $uibModalInstance.close();
  }

  function editAssetCategory(){
    $scope.editClicked = true;
    $scope.selectedAccountCode = {};
  }

  $scope.tab = 1;
  $scope.historyDependencies = {
    embeddedParams: {
      'object[0][object_id]': $scope.assetCategoryDetails._id,
      'object[0][object_type]': 'App\\Metabuyer\\AssetCategory\\Models\\AssetCategory',
      offset: 5,
      order_by: '-created_at' // Created at, descending
    }
  };

  function prepareAssetCategory(){
    return {
      'code': $scope.assetCategoryDetails.code,
      'name': $scope.assetCategoryDetails.name,
      'account_code': $scope.assetCategoryDetails.account_code.code,
      'is_active': $scope.assetCategoryDetails.is_active,
      'asset_useful_life': $scope.assetCategoryDetails.asset_useful_life,
      'company_code': $scope.assetCategoryDetails.company.code
    };
  }

  function updateAssetCategory(){
    var newData = prepareAssetCategory();
    singleAssetCategory.put({
        uuid: $scope.assetCategoryDetails.uuid
      },
      newData,
      function (resource) {
        if (!!resource.content && !!resource.content.data){
          toastr.success("Asset Category updated successfully");
          $uibModalInstance.close(resource.content.data);
        }
      },
      function (error) {
        globalFunc.error(error);
      }
    )
  }

  function searchAccountCodes(val) {
    var moduleParam = {
      module: 'account-code'
    };

    var additionalParams = {
      'criteria[1][name]': val,
      'criteria[1][descr]': val,
      'criteria[1][code]': val,
      'criteria[1][$operator]': 'or',
      'criteria[0][company_code]': $scope.companyCode,
      limit: 5
    }

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams
    }).then(function (response) {
      if (response.data.content.data.length === 0) {
        return [];
      } else {
        return response.data.content.data.map(function (item) {
          return item
        });
      }
    });
  }

  function selectAccountCode(newAccountCode){
    $scope.assetCategoryDetails.account_code = newAccountCode;
  }

  function initialize(){
    if (!!$scope.assetCategoryDetails.updated_at) {
      $scope.assetCategoryDetails.updated_at = new Date(Number($scope.assetCategoryDetails.updated_at)).toDateString();
    }
    if (!!$scope.assetCategoryDetails.created_at) {
      $scope.assetCategoryDetails.created_at = new Date(Number($scope.assetCategoryDetails.created_at)).toDateString();
    }

    $scope.accountCodeSearch = $scope.assetCategoryDetails.account_code.code + ' - ' + $scope.assetCategoryDetails.account_code.name;
  }

  initialize();

}

assetCategoryDetailsCtrl.$inject = [
  '$scope', '$uibModalInstance', 'toastr', '$state', 'companyId', 'companyCode',
  'globalFunc', 'assetCategoryDetails', 'singleAssetCategory', '$http', '$filter', 'pathConstants', '$rootScope'
];

angular
  .module('metabuyer')
  .controller('assetCategoryDetailsCtrl', assetCategoryDetailsCtrl);
