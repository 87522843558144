'use strict';

/**
 * @name supplierAdvancedSearchCtrl
 *
 * @description Controller for Supplier advanced search
 *
 * @author Yul <yul_klj@hotmail.com>
 * @copyright Sunway Metacloud &copy; 2021
 */
function supplierAdvancedSearchCtrl($scope, searchModule, listChild, toastr, $rootScope,advancedSearch,
                                               exportSearch, metabuyerCache, supplierFunctions, $translate) {

  $scope.supplierStatusOption = [
    {
      id: 1,
      translationId: 'statuses.all',
      key: 'ALL',
      value: 'All'
    },
    {
      id: 2,
      translationId: 'statuses.active',
      key: 'ACTIVE',
      value: 'Active'
    },
    {
      id: 3,
      key: 'PENDING',
      translationId: 'statuses.pendingApproval',
      value: 'Pending Approval'
    },
    {
      id: 4,
      key: 'INACTIVE',
      translationId: 'statuses.deactivated',
      value: 'Deactivated'
    },
    {
      id: 5,
      key: 'BLACKLISTED',
      translationId: 'statuses.blacklisted',
      value: 'Blacklisted'
    },
    {
      id: 6,
      key: 'SUSPENDED',
      translationId: 'statuses.suspended',
      value: 'Suspended'
    },
    {
      id: 7,
      key: 'REJECTED',
      translationId: 'statuses.rejected',
      value: 'Rejected'
    }
  ];
  var translateArr = [
    "statuses.all",
    "statuses.active",
    "statuses.pendingApproval",
    "statuses.deactivated",
    "statuses.blacklisted",
    "statuses.suspended",
    "statuses.rejected",
  ];
  $translate(translateArr).then(function (trans) {
    for(var statusIndex in $scope.supplierStatusOption) {
      var status = $scope.supplierStatusOption[statusIndex];
      if (trans[status.translationId] === undefined)
        continue;
      $scope.supplierStatusOption[statusIndex].value = trans[status.translationId];
    }
    $scope.searchFields.supplierStatus = $scope.supplierStatusOption[0];
  });

  $scope.columns = [
    {id: 'basic_info|descr', label: 'Supplier Name', translationId: 'fields.supplierName'},
    {id: 'basic_info|code', label: 'Supplier Code', translationId: 'fields.supplierCode'},
    {id: 'basic_info|reg_no', label: 'ROC/ROB/NRIC', translationId: 'fields.rocRobNric'},
    {id: 'basic_info|currency_code', label: 'Preferred Currency', translationId: 'fields.preferredCurrency', unsortable: true, unsearchable: true},
    {id: 'basic_info|email', label: 'Company Email', translationId: 'fields.companyEmail'},
    {id: 'supplier_users|display_name', label: 'Supplier User Name', translationId: 'fields.supplierUserName'},
    {id: 'supplier_users|email', label: 'Supplier User Email', translationId: 'fields.supplierUserEmail'},
    {id: 'supplier_users|phone', label: 'Supplier User Phone', translationId: 'fields.supplierUserPhone'},
    {id: 'default_contract|primary_business_nature', label: 'Primary Business Nature', translationId: 'fields.primaryBusinessNature', unsortable: true, unsearchable: true},
  ];
  var translateColumnArr = [
    "fields.supplierName",
    "fields.supplierCode",
    "fields.rocRobNric",
    "fields.preferredCurrency",
    "fields.companyEmail",
    "fields.supplierUserName",
    "fields.supplierUserEmail",
    "fields.supplierUserPhone",
    "fields.primaryBusinessNature",
  ];
  $translate(translateColumnArr).then(function (trans) {
    for(var columnIndex in $scope.columns) {
      var columnData = $scope.columns[columnIndex];
      if (trans[columnData.translationId] === undefined)
        continue;
      $scope.columns[columnIndex].label = trans[columnData.translationId];
    }
  });


  $scope.fields = [
    {id: 'supplierName', label: 'Supplier Name'},
    {id: 'supplierStatus', label: 'Supplier Status'},
    {id: 'supplierType', label: 'Supplier Type'},
    {id: 'supplierCode', label: 'Supplier Code'},
    {id: 'businessEntity', label: 'Business Entity'},
    {id: 'regNo', label: 'ROC/ROB/NRIC'},
    {id: 'email', label: 'Supplier Company Email'},
    {id: 'primaryBusinessNature', label: 'Primary Business Nature'},
    {id: 'secondaryBusinessNature', label: 'Secondary Business Nature'},
    {id: 'primaryCommodity', label: 'Primary Commodity Code'},
    {id: 'secondaryCommodity', label: 'Secondary Commodity Code'},
    {id: 'createdBy', label: 'Created By'},
    {id: 'updatedBy', label: 'Updated By'},
    {id: 'approvedBy', label: 'Approved By'},
    {id: 'creationDate', label: 'Creation Date'},
    {id: 'updatedDate', label: 'Update Date'},
    {id: 'approvedDate', label: 'Approved Date'},
    {id: 'supplierUserName', label: 'Supplier User Name'},
    {id: 'supplierUserEmail', label: 'Supplier User Email'},
  ];

  var initialSearchFields = {
    supplierName: null,
    supplierStatus: $scope.supplierStatusOption[0],
    supplierCode: null,
    businessEntity: null,
    regNo: null,
    email: null,
    primaryBusinessNature: null,
    secondaryBusinessNature: null,
    primaryCommodity: null,
    secondaryCommodity: null,
    supplierUserName: null,
    supplierUserEmail: null,
    creationDateFrom: null,
    creationDateTo: null,
    updatedDateFrom: null,
    updatedDateTo: null,
    approvedDateFrom: null,
    approvedDateTo: null
  };

  $scope.datePickerTimezone = $rootScope.datePickerTimezone;
  $scope.datepickerOpened = {
    creationDateFrom: false,
    creationDateTo: false,
    updatedDateFrom: false,
    updatedDateTo: false,
    approvedDateFrom: false,
    approvedDateTo: false
  };
  var dateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };
  $scope.datePickerOptions = {
    creationDateFrom: _.clone(dateOptions),
    creationDateTo: _.clone(dateOptions),
    updatedDateFrom: _.clone(dateOptions),
    updatedDateTo: _.clone(dateOptions),
    approvedDateFrom: _.clone(dateOptions),
    approvedDateTo: _.clone(dateOptions)
  };

  $scope.errors = {};
  var toastrErrorMessage = null;
  // Standard list for businessNature and businessEntity
  $scope.businessNatures = [
    {descr: 'Unspecified Business Nature', shortDescr: 'UNSPECIFIED', value: 0},
    {descr: 'Agent', shortDescr: 'AGENT', value: 1},
    {descr: 'Banking & Financial Services', shortDescr: 'BANKING', value: 2},
    {descr: 'Consultancy, Advisory & Other Professional Bodies', shortDescr: 'CONSULTANCY', value: 3},
    {descr: 'Contractor', shortDescr: 'CONTRACTOR', value: 4},
    {descr: 'Dealer', shortDescr: 'DEALER', value: 5},
    {descr: 'Distribute', shortDescr: 'DISTRIBUTOR', value: 6},
    {descr: 'Manufacturer', shortDescr: 'MANUFACTURER', value: 7},
    {descr: 'Marketing', shortDescr: 'MARKETING', value: 8},
    {descr: 'Retailer', shortDescr: 'RETAILER', value: 9},
    {descr: 'Service Provider', shortDescr: 'SERVICE_PROVIDER', value: 10},
    {descr: 'Sub-Contractor', shortDescr: 'SUB_CONTRACTOR', value: 11},
    {descr: 'Trading', shortDescr: 'TRADING', value: 12},
    {descr: 'Other', shortDescr: 'OTHERS', value: 13}
  ];
  $scope.businessEntities = [
    {descr: 'Berhad (Public Limited)', shortDescr: 'PUBLIC_LIMITED', value: '1'},
    {descr: 'Sendirian Berhad (Private Limited)', shortDescr: 'PRIVATE_LIMITED', value: '2'},
    {descr: 'Sole Proprietor & Enterprise', shortDescr: 'SOLE', value: '3'},
    {descr: 'Partnership', shortDescr: 'PARTNERSHIP', value: '4'}
  ];
  $scope.commodityLevel = [3, 4];
  $scope.supplierTypes = supplierFunctions.getSupplierTypes();
  $scope.storageKey = 'suppliers-advanced-search-selected-columns';
  $scope.storageKeyFields = 'suppliers-advanced-search-selected-fields';

  $scope.resetForm = resetForm;
  $scope.submitAdvSearch = submitAdvSearch;
  $scope.openDatePicker = openDatePicker;
  $scope.checkIfDisabled = checkIfDisabled;
  $scope.hasError = hasError;
  $scope.addSearchCriteria = addSearchCriteria;
  $scope.searchPrimaryCommodityCode = searchPrimaryCommodityCode;
  $scope.openChildCommodityCodePrimary = openChildCommodityCodePrimary;
  $scope.searchSecondaryCommodityCode = searchSecondaryCommodityCode;
  $scope.openChildCommodityCodeSecondary = openChildCommodityCodeSecondary;
  $scope.removeSecondaryCommodity = removeSecondaryCommodity;
  $scope.showColumn = showColumn;
  $scope.showField = showField;

  /**
   * function to reset the search form
   */
  function resetForm() {
    $scope.searchFields = _.cloneDeep(initialSearchFields);
  }

  /**
   * function to submit search form
   */
  function submitAdvSearch() {
    var searchParam = validateForm();

    if (!!searchParam) {
      $scope.embeddedParams = searchParam;

      /**
       * Setting the params to prepare for exporting
       */
      exportSearch.setParams(searchParam);

      advancedSearch.get(
        searchParam,
        function (resource) {
          $scope.dataList = resource.content.data;
          $scope.meta = resource.content.meta;
          $scope.emptyInput = false;

          // decrypt reg_no
          if (!$rootScope.isV2Enabled) {
            $scope.dataList.forEach(function (item, index) {
              $scope.dataList[index].basic_info.reg_no = atob(item.basic_info.reg_no);
            });
          }
        },
        function (error) {
          toastr.error('Something went wrong');
          $scope.dataList = [];
        }
      );
    } else {
      toastr.error(toastrErrorMessage);
      toastrErrorMessage = null;
      $scope.emptyInput = true;
    }

    $('#show-search-form').removeClass('hidden');
    $('#hide-search-form').addClass('hidden');

  }

  /**
   * function to check if a specific field has error.
   * @param field field name
   * @returns {boolean}
   */
  function hasError(field) {
    return (field in $scope.errors);
  }

  /**
   * function to validate form input values
   * @returns {boolean|Object}
   */
  function validateForm() {

    /**
     * logic for range fields (total price, total cost of pr, and all date fields)
     * if `from` field is not empty, set `to` field to same value as `from` value
     * if `to` field is not empty, `from` field is mandatory.
     * if both are empty, skip validation
     *
     */

    //creation date range
    if (!!$scope.creationDateTo) {
      if (!!$scope.creationDateFrom) {
        if ($scope.creationDateFrom > $scope.creationDateTo) {
          $scope.errors.creationDate = 'Invalid Range';
        }
      } else {
        $scope.errors.creationDate = '\'From\' field is mandatory';
      }
    } else if (!!$scope.creationDateFrom) {
      $scope.creationDateTo = $scope.creationDateFrom;
    }

    //update date range
    if (!!$scope.updatedDateTo) {
      if (!!$scope.updatedDateFrom) {
        if ($scope.updatedDateFrom > $scope.updatedDateTo) {
          $scope.errors.updatedDate = 'Invalid Range';
        }
      } else {
        $scope.errors.updatedDate = '\'From\' field is mandatory';
      }
    } else if (!!$scope.updatedDateFrom) {
      $scope.updatedDateTo = $scope.updatedDateFrom;
    }

    // pr created date range
    if (!!$scope.approvedDateTo) {
      if (!!$scope.approvedDateFrom) {
        if ($scope.approvedDateFrom > $scope.approvedDateTo) {
          $scope.errors.approvedDate = 'Invalid Range';
        }
      } else {
        $scope.errors.approvedDate = '\'From\' field is mandatory';
      }
    } else if (!!$scope.approvedDateFrom) {
      $scope.approvedDateTo = $scope.approvedDateFrom;
    }

    if (_.isEmpty($scope.errors)) {
      var searchData = _.clone($scope.searchFields);
      return buildParam(searchData);
    } else {
      toastrErrorMessage = 'Invalid value in one or more fields';
      return false;
    }
  }

  /**
   * function to build criteria parameters
   * @param data object of form input values
   * @returns {boolean|Object}
   */
  function buildParam(data) {

    var params = {};

    if (!!data.supplierName) {
      params['criteria[0][basic_info|descr]'] = data.supplierName;
    }

    if (!!data.supplierStatus) {
      params['criteria[0][basic_info|status]'] = data.supplierStatus.key;
    }

    if (!!data.supplierType) {
      if (!!data.supplierType.isSourcingSupplier && !data.supplierType.isSourcingSupplier) {
        params['criteria[0][basic_info|is_sourcing_supplier]'] = data.supplierType.isSourcingSupplier;
      }
      if (!!data.supplierType.isP2OSupplier && !data.supplierType.isSourcingSupplier) {
        params['criteria[0][basic_info|is_p2o_supplier]'] = data.supplierType.isP2OSupplier;
      }
    }

    if (!!data.supplierCode) {
      params['criteria[0][basic_info|code]'] = data.supplierCode;
    }

    if (!!data.businessEntity) {
      params['criteria[0][basic_info|business_entity]'] = data.businessEntity.shortDescr;
    }

    if (!!data.regNo) {
      params['criteria[0][basic_info|reg_no]'] = data.regNo;
    }

    if (!!data.email) {
      params['criteria[0][basic_info|email]'] = data.email;
    }

    if (!!data.primaryBusinessNature) {
      params['criteria[0][default_contract|primary_business_nature]'] = data.primaryBusinessNature.shortDescr;
    }

    if (!!data.secondaryBusinessNature) {
      params['criteria[0][default_contract|secondary_business_nature]'] = data.secondaryBusinessNature.shortDescr;
    }

    if (!!data.primaryCommodity) {
      params['criteria[0][default_contract|primary_commodity_code]'] = data.primaryCommodity.code;
      params['criteria[0][default_contract|primary_commodity_descr]'] = data.primaryCommodity.descr;
    }

    if (!!data.secondaryCommodity) {
      params['criteria[0][default_contract|secondary_commodity_code]'] = data.secondaryCommodity.code;
      params['criteria[0][default_contract|secondary_commodity_code]'] = data.secondaryCommodity.descr;
    }

    if (!!data.supplierUserName) {
      params['criteria[0][supplier_users|display_name]'] = data.supplierUserName;
    }

    if (!!data.supplierUserEmail) {
      params['criteria[0][supplier_users|email]'] = data.supplierUserEmail;
    }

    if (!!data.createdBy) {
      params['criteria[0][creator_info|display_name]'] = data.createdBy;
    }

    if (!!data.updatedBy) {
      params['criteria[0][updater_info|display_name]'] = data.updatedBy;
    }

    if (!!data.approvedBy) {
      params['criteria[0][approved_by]'] = data.approvedBy;
    }

    if (!!data.creationDateFrom) {
      params['criteria[0][created_at][from]'] = moment(data.creationDateFrom).valueOf();
      params['criteria[0][created_at][to]'] = moment(data.creationDateTo).endOf('day').valueOf();
    }

    if (!!data.updatedDateFrom) {
      params['criteria[0][updated_at][from]'] = moment(data.updatedDateFrom).valueOf();
      params['criteria[0][updated_at][to]'] = moment(data.updatedDateTo).endOf('day').valueOf();
    }

    if (!!data.approvedDateFrom) {
      params['criteria[0][approved_at][from]'] = moment(data.approvedDateFrom).valueOf();
      params['criteria[0][approved_at][to]'] = moment(data.approvedDateTo).endOf('day').valueOf();
    }

    // if status 'all' is selected, the form cannot be empty. so the criteria for 'all' status
    // will only be added if `params` is not empty.
    if (_.isEmpty(params)) {
      toastrErrorMessage = 'Fill in at least one field';
      return false;
    } else {
      if (data.supplierStatus.key === 'ALL') {
        params['criteria[0][basic_info|status]'] = data.supplierStatus.key;
      }
      params['module'] = 'suppliers_v2';
      params['offset'] = 50;
      params['criteria[0][$operator]'] = 'and';
      return params;
    }
  }

  /**
   * check if a field should be disabled
   * @param field
   * @returns {boolean}
   */
  function checkIfDisabled(field) {
    if (!!$scope.searchFields.supplierStatus) {
      if (field === 'fullyApprovedDate') {
        if ($scope.searchFields.supplierStatus.key === 'ALL' ||
        $scope.searchFields.supplierStatus.key === 'ACTIVE'
        ) {
          return false;
        } else {
          // clear related fields if disabled
          $scope.searchFields.approvedDateFrom = null;
          $scope.searchFields.approvedDateTo = null;
          return true;
        }
      }
    }
  }

  /**
   *
   * @param $event
   * @param field
   */
  function openDatePicker($event, field) {
    $event.preventDefault();
    $event.stopPropagation();

    if (field === 'creationDateFrom') {
      $scope.datePickerOptions.creationDateFrom.maxDate = $scope.creationDateTo || null;
      $scope.datepickerOpened.creationDateFrom = true;
      delete $scope.errors.creationDate;
    } else if (field === 'creationDateTo') {
      $scope.datePickerOptions.creationDateTo.minDate = $scope.creationDateFrom || null;
      $scope.datepickerOpened.creationDateTo = true;
      delete $scope.errors.creationDate;
    } else if (field === 'updatedDateFrom') {
      $scope.datePickerOptions.updatedDateFrom.maxDate = $scope.updatedDateTo || null;
      $scope.datepickerOpened.updatedDateFrom = true;
      delete $scope.errors.updatedDate;
    } else if (field === 'updatedDateTo') {
      $scope.datePickerOptions.updatedDateTo.minDate = $scope.updatedDateFrom || null;
      $scope.datepickerOpened.updatedDateTo = true;
      delete $scope.errors.updatedDate;
    } else if (field === 'approvedDateFrom') {
      $scope.datePickerOptions.approvedDateFrom.maxDate = $scope.approvedDateTo || null;
      $scope.datepickerOpened.approvedDateFrom = true;
      delete $scope.errors.approvedDate;
    } else if (field === 'approvedDateTo') {
      $scope.datePickerOptions.approvedDateTo.minDate = $scope.approvedDateFrom || null;
      $scope.datepickerOpened.approvedDateTo = true;
      delete $scope.errors.approvedDate;
    }
  }

  function addSearchCriteria(key, value){
    $scope.searchFields[key] = value;
  }

  /** search for the commodity code*/
  function searchPrimaryCommodityCode(keyword) {
    if (!!keyword && keyword.length > 1) {
      searchModule.get(
        {
          module: 'commodity',
          'criteria[0][code]': keyword,
          'criteria[0][descr]': keyword,
          'criteria[0][descr_chinese]': keyword,
          'criteria[0][$operator]': 'or',
          'criteria[1][is_active]': 1,
          order: 1,
          order_by: 'code',
          offset: 0
        }, function (resource) {
          if (!!resource && !!resource.content && !!resource.content.data)
            $scope.primaryCommodityCodes = resource.content.data;
        }, function () {
          $scope.primaryCommodityCodes = [];
        });
      return $scope.primaryCommodityCodes;
    }
  }

  function searchSecondaryCommodityCode(keyword) {
    if (!!keyword && keyword.length > 1) {
      searchModule.get(
        {
          module: 'commodity',
          'criteria[0][code]': keyword,
          'criteria[0][descr]': keyword,
          'criteria[0][descr_chinese]': keyword,
          'criteria[0][$operator]': 'or',
          'criteria[1][is_active]': 1,
          order: 1,
          order_by: 'code',
          offset: 0
        }, function (resource) {
          if (!!resource && !!resource.content && !!resource.content.data)
            $scope.secondaryCommodityCodes = resource.content.data;
        }, function () {
          $scope.secondaryCommodityCodes = [];
        });
      return $scope.secondaryCommodityCodes;
    }
  }

  /** get commodity's child data*/
  function openChildCommodityCodePrimary(cc) {
    listChild.get({
        code: cc
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data.length > 0) {
          $scope.primaryCommodityCodes = resource.content.data;
        } else {
          toastr.error("There are no child on this commodity code");
        }
      },
      function () {
        toastr.error("Failed to get commodity codes data");
      })
  }

  function openChildCommodityCodeSecondary(cc) {
    listChild.get({
        code: cc
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data.length > 0) {
          $scope.secondaryCommodityCodes = resource.content.data;
        } else {
          toastr.error("There are no child on this commodity code");
        }
      },
      function () {
        toastr.error("Failed to get commodity codes data");
      })
  }

  function removeSecondaryCommodity() {
    $scope.supplier.default_contract.secondary_commodity_code = null;
    $scope.secondaryCommodityKeyword = null;
  }

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function showField(id){
    for (var i in $scope.fields) {
      if (id === $scope.fields[i].id) {
        if(!!$scope.fields[i].conditional){
          if($scope.status === $scope.fields[i].condition){
            return $scope.fields[i].selected;
          }
          if($scope.fields[i].condition instanceof Array){
            for(var j in $scope.fields[i].condition){
              if($scope.status === $scope.fields[i].condition[j]){
                return $scope.fields[i].selected;
              }
            }
          }
        }else{
          return $scope.fields[i].selected;
        }
      }
    }
  }

  $scope.$on("onItemDeselectFieldsEmpty", function(evt, fields){
    if (fields.id === 'supplierStatus') {
      $scope.searchFields[fields.id] = $scope.supplierStatusOption[0]
    } else if (fields.id === 'primaryCommodity') {
      $scope.primaryCommodityKeyword = null;
      $scope.searchFields[fields.id] = null;
    } else if (fields.id === 'secondaryCommodity') {
      $scope.secondaryCommodityKeyword = null;
      $scope.searchFields[fields.id] = null;
    } else if (fields.id === 'creationDate' || fields.id === 'updatedDate' || fields.id === 'approvedDate') {
      $scope.searchFields[fields.id + 'From'] = null;
      $scope.searchFields[fields.id + 'To'] = null;
    } else {
      $scope.searchFields[fields.id] = null;
    }
  });

  function initialize() {
    // initialize pagination parameters
    $scope.dataList = [];
    $scope.embeddedParams = [];
    $scope.meta = {};
    $scope.searchModule = advancedSearch;

    resetForm();

    var translateFieldArr = [
      "fields.supplierName",
      "fields.supplierStatus",
      "fields.supplierType",
      "fields.supplierCode",
      "fields.businessEntity",
      "fields.rocRobNric",
      "fields.supplierCompanyEmail",
      "fields.primaryBusinessNature",
      "fields.secondaryBusinessNature",
      "fields.primaryCommodityCode",
      "fields.secondaryCommodityCode",
      "fields.createdBy",
      "fields.updatedBy",
      "fields.approvedBy",
      "fields.creationDate",
      "fields.updateDate",
      "fields.approvedDate",
      "fields.supplierUserName",
      "fields.supplierUserEmail",
    ];
    $translate(translateFieldArr).then(function (trans) {
      for(var fieldIndex in $scope.fields) {
        var fieldData = $scope.fields[fieldIndex];
        var fieldId = fieldData.id;
        fieldId = fieldId === 'regNo' ? 'rocRobNric' : fieldId;
        fieldId = fieldId === 'email' ? 'supplierCompanyEmail' : fieldId;
        fieldId = fieldId === 'updatedDate' ? 'updateDate' : fieldId;
        fieldId = fieldId === 'primaryCommodity' ? 'primaryCommodityCode' : fieldId;
        fieldId = fieldId === 'secondaryCommodity' ? 'secondaryCommodityCode' : fieldId;
        fieldId = "fields." + fieldId;
        if (trans[fieldId] === undefined)
          continue;
        $scope.fields[fieldIndex].label = trans[fieldId];
      }
    });
  }

  initialize();
}

supplierAdvancedSearchCtrl.$inject = [
  '$scope', 'searchModule', 'listChild', 'toastr', '$rootScope', 'advancedSearch', 'exportSearch', 'metabuyerCache', 'supplierFunctions',
  '$translate'
];

angular.module('metabuyer')
  .controller('supplierAdvancedSearchCtrl', supplierAdvancedSearchCtrl);
