'use strict';

/**
 * @name invalidUrlCtrl
 *
 * @desc Controller for displaying page for invalid url
 *
 * @author Justin Cheong <justin.cty90@gmail.com>
 * @copyright  Metacloud Sdn. Bhd. &copy; 2016
 */

function invalidUrlCtrl($scope, $rootScope, pathConstants) {

  // applying CSS for the page on the controller level
  $rootScope.bodyClasses = 'gray-bg';

  // Get the environment's url for redirection
  $scope.environmentUrl = pathConstants.environmentUrl;
}

invalidUrlCtrl.$inject = ['$scope', '$rootScope', 'pathConstants'];

angular
  .module('metabuyer')
  .controller('invalidUrlCtrl', invalidUrlCtrl);
