'use strict';
/**
 * @name manageBUCtrl
 * @desc Controller for managing BU
 */
function manageBUCtrl($scope, $filter, $stateParams, BU, listingBusinessUnits, pathConstants, $uibModal, HighlightId, $rootScope) {
  $scope.status = $stateParams.status;
  $scope.title = $stateParams.title;
  $scope.dataList = BU.data;
  $scope.meta = BU.meta;
  $scope.listingBUService = listingBusinessUnits;
  $scope.highlightId = HighlightId.getId();
  if (!!$scope.highlightId) {
    HighlightId.setId(null);
  }
  $scope.showColumn = showColumn;
  $scope.importBU = importBU;
  $scope.importLink = pathConstants.apiUrls.BusinessUnits.import;
  $scope.getTextualIsActive = getTextualIsActive;

  function importBU() {
    var modalInstance = $uibModal.open({
      templateUrl: "app/masterDataManagement/Import-Template.html",
      backdrop: 'static',
      keyboard: false,
      resolve: {
        importLink: function () {
          return $scope.importLink;
        },
        title: function () {
          return "Business Unit";
        }
      },
      controller: function ($scope, $uibModalInstance, importLink, title) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };
        $scope.importLink = importLink;
        $scope.title = title;
      }
    });
  }

  $scope.storageKey = 'business-unit-listing-selected-columns';

  /**
   * returns the is_active name
   * @param code
   * @returns {*}
   */
  function getTextualIsActive(code){
    /**
     * DEACTIVATED = 0; 
     * ACTIVE = 1; 
     */
    if (code === 0 || code === 'DEACTIVATED') {
      return 'Deactivated'
    }
    if (code === 1 || code === 'ACTIVE') {
      return 'Active'
    }
    return 'N/A';
  }

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

}

manageBUCtrl.$inject = ['$scope', '$filter','$stateParams', 'BU', 'listingBusinessUnits',
                        'pathConstants','$uibModal', 'HighlightId', '$rootScope'];

angular.module('metabuyer')
  .controller('manageBUCtrl', manageBUCtrl);
