'use strict';
/**
 * @name companyAsset
 * @desc directive for the Company Assets
 */
angular
  .module('metabuyer')
  .directive('companyAsset',function() {
    return {
      restrict: 'E',
      templateUrl: 'components/companyAsset/companyAssetTemplate.html',
      scope: {
        data: '=',
        code: '=',
      },
      controller: function(
        $scope, toastr, $rootScope, $uibModal, pathConstants,
        globalFunc, searchModule, $location, $stateParams, singleCompanyAsset
      ) {
        $scope.dataList = $scope.data.data;
        $scope.meta = $scope.data.meta;
        $scope.isOffline = $rootScope.isOffline;
        $scope.parent = {};
        $scope.submitted = false;
        $scope.root = {
          id: '',
          code: ''
        };
        $scope.parentCode = {
          status: '',
          code: ''
        };

        $scope.search = search;
        $scope.showColumn = showColumn;
        $scope.query = $stateParams.query;
        $scope.searchText = $stateParams.query;
        $scope.getActiveStatusName = getActiveStatusName;
        $scope.companyAssetDetail = companyAssetDetail;

        $scope.importLink = pathConstants.apiUrls.accountCode.import;
        $scope.columns = [
            {id: 'asset_main_number', label: 'Asset Main Number'},
            {id: 'asset_sub_number', label: 'Asset Sub Number'},
            {id: 'name', label: 'Asset Name'},
            {id: 'asset_category|code', label: 'Asset Category'},
            {id: 'is_active', label: 'Status'}
          ];

        $scope.storageKey = 'company-management-company-asset-selected-columns';

        function showColumn(id){
          for (var i in $scope.columns) {
            if (id === $scope.columns[i].id) {
              if(!!$scope.columns[i].conditional){
                if($scope.status === $scope.columns[i].condition){
                  return $scope.columns[i].selected;
                }
                if($scope.columns[i].condition instanceof Array){
                  for(var j in $scope.columns[i].condition){
                    if($scope.status === $scope.columns[i].condition[j]){
                      return $scope.columns[i].selected;
                    }
                  }
                }
              }else{
                return $scope.columns[i].selected;
              }
            }
          }
        }

        function companyAssetDetail(index) {
          var companyAssetUuid = $scope.dataList[index].uuid;
          var modalInstance = $uibModal.open({
            templateUrl: 'components/companyAsset/details/details.html',
            backdrop: 'static',
            keyboard: false,
            controller: 'companyAssetDetailsCategory',
            size: 'lg',
            resolve: {
              companyAssetDetails: function($q, singleCompanyAsset) {
                var deferred = $q.defer();
                singleCompanyAsset.get(
                  {
                    uuid: companyAssetUuid
                  },
                  function (resource) {
                    deferred.resolve(resource.content || {data: []});
                  },
                  function (error) {
                    if (error.status === 404) {
                      deferred.resolve([]);
                    }
                  }
                );
                return deferred.promise;
              },
              companyId: function(){
                return $stateParams.id;
              }
            }
          });
          modalInstance.result.then(function (newValue) {
          });
        }

        /**
         * returns the is_active name
         * @param code
         * @returns {*}
         */
        function getActiveStatusName(code){
          if (code === false) {
            return 'Deactivated'
          }
          if (code === true) {
            return 'Active'
          }
          return 'N/A';
        }

        function prepareImportParams () {
            $scope.params = {
                'class': 'App\\Metabuyer\\CompanyAsset\\Models\\CompanyAsset'
            };
        }

        prepareImportParams();

        function search(query) {
          $location.search({query: query});
        }
      }
    }
  });
