'use strict';

/**
 * Controller for budget listing
 * @description
 * Contains functions for handling showing table columns and viewing details
 *
 * @param $scope
 * @param dataList
 * @param $stateParams
 * @param $state
 * @param $rootScope
 * @param budgets
 * @param toastr
 * @param HighlightId
 * @param $window
 *
 * @function viewBudgetDetail
 * Function to navigate to budget details by id state
 *
 * @function showColumn
 * Function to display selected available columns
 *
 * @author H.W. Liew <liewhanwahwork@gmail.com>
 * @author Justin Cheong <justin.cty90@gmail.com>
 * @author Khor Jin Ee <jinee585@gmail.com>
 * @copyright 2017 Metacloud Sdn. Bhd.
 */

function budgetListCtrl($scope, dataList, $stateParams, $state, $rootScope, budgets, toastr, HighlightId, $window) {

  $scope.currentUser = $rootScope.currentUser || {_id: null};

  $scope.viewBudgetDetail = viewBudgetDetail;
  $scope.showColumn = showColumn;
  $scope.canDelete = canDelete;
  $scope.deleteBudget = deleteBudget;
  $scope.checkWaitingOnUser = checkWaitingOnUser;
  $scope.multiSelectPendingItems = multiSelectPendingItems;
  $scope.getSelectedBudgets = getSelectedBudgets;

  function showColumn(id) {
    var i;
    var columns = $scope.columns;
    for (i in columns) {
      if(columns.hasOwnProperty(i)) {
        if (id === columns[i].id) {
          if(!!columns[i].conditional) {
            if($scope.status === columns[i].condition)
              return columns[i].selected;
            if(columns[i].condition instanceof Array) {
              var j;
              var columnCondition = $scope.columns[i].condition;
              for(j in columnCondition) {
                if(columnCondition.hasOwnProperty(j)) {
                  if($scope.status === columnCondition[j]) {
                    return columns[i].selected;
                  }
                }
              }
            }
          }
          else
            return columns[i].selected;
        }
      }
    }
  }

  function viewBudgetDetail(data) {
    $state.go('main.user.budget.details' , {id : data._id});
  }

  /**
   * Check if delete budget action can be done
   *
   * @param {string} creator_info Creator id
   * @param {string} status       Status of the budget
   */
  function canDelete(creator_info, status) {
    var checkBudgetStatus = (status === 'draft');
    var isApprover = (creator_info === $scope.currentUser._id);

    return (checkBudgetStatus && isApprover);
  }

  /**
   * To confirm if to delete the budget
   *
   * @param {string} budgetId Budget Id
   */
  function deleteBudget(budgetId) {
    swal({
      title: 'Confirm delete budget?',
      text: 'This action will remove the budget from draft.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Confirm',
      closeOnConfirm: true
    }, function (isConfirm) {
      if (isConfirm) {
        confirmDeleteBudget(budgetId);
      }
    });
  }

  /**
   * Delete budget by id
   *
   * @param {string} budgetId Budget Id
   */
  function confirmDeleteBudget(budgetId) {

    budgets.delete(
      {
        id: budgetId
      },
      function () {
        toastr.success('Budget draft deleted.');
        $state.reload();
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  /**
   * return if the user is part of the waiting on
   * @param {array} budget Budget data
   * @returns {boolean}
   */
  function checkWaitingOnUser(budget) {
    return budget.status === 'pending' && !!budget.waiting_on ?
      (budget.waiting_on.indexOf($scope.currentUser._id) > -1) : false;
  }

  /**
   *  Select/deselect all the items in listing for approval action
   * @param {string} status Status of the current tab
   */
  function multiSelectPendingItems(status) {
    _.forEach($scope.dataList, function (budget) {
      if (checkWaitingOnUser(budget)) {
        budget.selected = status;
      }
    });
  }

  /**
   * Return selected budgets
   * @returns {array}
   */
  function getSelectedBudgets() {
    var selectedBudgets = [];
    _.forEach($scope.dataList, function (budget) {
      if (!!budget.selected) {
        selectedBudgets.push(budget);
      }
    });

    return selectedBudgets;
  }

  function initialize() {
    if (!!HighlightId.getId() && HighlightId.getId() !== undefined && HighlightId.getId().length) {

      $scope.highlightId = HighlightId.getId();

      if ($scope.highlightId !== null && $scope.highlightId !== undefined) {

        //scroll the window to top
        $window.scroll(0, 0);
      }

      //clear current id
      HighlightId.setId(null);
    }

    $scope.$parent.status = $stateParams.status;
    $scope.status = $stateParams.status;
    $scope.dataList = dataList.data;
    $scope.meta = dataList.meta;
    $scope.budgetManagementService = dataList;
    $scope.storageKey = 'budget-management-selected-columns';
    $scope.params = 'budget';
  }

  initialize();

}

budgetListCtrl.$inject = ['$scope', 'dataList', '$stateParams', '$state', '$rootScope', 'budgets', 'toastr',
  'HighlightId', '$window'];

angular.module('metabuyer')
  .controller('budgetListCtrl', budgetListCtrl);
