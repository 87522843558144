'use strict';
/**
 * @name companyExchangeRateCtrl
 * @desc Controller for account code list in companies
 */
function companyExchangeRateCtrl($scope, companyDetails, currenciesList, $state, companyExchangeRate, $rootScope) {
  $scope.exchangeRate = companyExchangeRate;
  $scope.currenciesExchange = currenciesList;
  $scope.companyDetails = companyDetails.data;

  $scope.back = back;

  function back(){
    if (!!$scope.companyDetails && !!$scope.companyDetails._id)
      $state.go('main.user.companiesMgmt.detailCompanies', {id: $scope.companyDetails._id});
    else
      window.history.back();
  }

  function initialize(){
    var tabData = {
      tab: $scope.$parent.tabData[0],
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);
  }

  initialize();
}

companyExchangeRateCtrl.$inject = [
  '$scope', 'companyDetails', 'currenciesList', '$state', 'companyExchangeRate', '$rootScope'
];

angular.module('metabuyer')
  .controller('companyExchangeRateCtrl', companyExchangeRateCtrl);
