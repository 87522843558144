'use strict';
/**
 * @name acceptSupplierWebTncCtrl
 * @description Controller to prompt accept supplier web terms and conditions
 *
 * @author Justin Cheong <justin.cty90@gmail.com>
 * @copyright 2018 Metacloud Sdn. Bhd.
 */
function acceptSupplierWebTncCtrl($scope, $rootScope, $state, toastr, getSupplierWebTnc,
                                  supplierWebUserTnc, globalFunc) {

  /**
   * Get supplier web terms and conditions by current user's tenant
   */
  function getSupplierWebTermsAndConditions() {
    getSupplierWebTnc.get(
      function (resource) {
        $scope.supplierWebTnC = resource.data;
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function approveSupplierWebTnC() {
    supplierWebUserTnc.put(
      {
        id: $rootScope.currentUser._id
      },
      {
        accepted_supplier_web_tnc: true
      },
      function () {
        toastr.success('User accepted terms and conditions successfully.');
        $rootScope.currentUser.config.accepted_supplier_web_tnc = true;
        $state.go('main.user.suppliersWeb.dashboard');
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function declineSupplierWebTnC() {
    $state.go('main.user.mainMenu.waiting-on-you');
  }

  function initialize() {
    $scope.approveSupplierWebTnC = approveSupplierWebTnC;
    $scope.declineSupplierWebTnC = declineSupplierWebTnC;
    //applying CSS for the page on the controller level
    $rootScope.bodyClasses = 'gray-bg';
    getSupplierWebTermsAndConditions();
  }

  initialize();
}

acceptSupplierWebTncCtrl.$inject = ['$scope', '$rootScope', '$state', 'toastr',
  'getSupplierWebTnc', 'supplierWebUserTnc', 'globalFunc'];

angular
  .module('metabuyer')
  .controller('acceptSupplierWebTncCtrl', acceptSupplierWebTncCtrl);
