'use strict';
/**
 * @name mainPanelSettingsCtrl
 * @desc Controller for the Admin page panel
 */
function mainPanelSettingsCtrl ($scope, featureToggles) {
  $scope.bifrostFeatureToggle = featureToggles['bifrost'].is_enabled;
  $scope.KharonFeatureToggle = featureToggles['syncEngine'].is_enabled;

  $scope.settings = [
    {
      title: 'Company Settings',
      key: 'main.user.settings.PRPOSettings.prefix'
    },
    {
      title: 'Admin Settings',
      key: 'main.user.settings.adminSettings'
    },
    {
      title: 'Tenant Settings',
      key: 'main.user.settings.tenant'
    },
    {
      title: 'Email Announcement',
      key: 'main.user.settings.emailAnnouncement'
    },
    {
      title: 'Private Access',
      key: 'main.user.settings.privateAccess'
    }
  ];

  if (!!$scope.bifrostFeatureToggle) {
    $scope.settings.push({
      title: 'Bifrost Settings',
      key: 'main.user.settings.bifrost'
    });
  }

  if (!!$scope.KharonFeatureToggle) {
    $scope.settings.push({
      title: 'Kharon Settings',
      key: 'main.user.settings.kharon'
    });
  }


  //TODO: have this cleaned up with better engineering solution (Ahmed Saleh)
}
mainPanelSettingsCtrl.$inject = ['$scope', 'featureToggles'];

angular
  .module('metabuyer')
  .controller('mainPanelSettingsCtrl', mainPanelSettingsCtrl);
