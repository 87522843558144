'use strict';

angular.module('metabuyer.services.alcGroup', ['ngResource'])
  .factory('alcGroupMasterDataList',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.alcGroup.single,
      {
        uuid: '@uuid'
      },
      {
        'get': {method: 'GET'},
        'post': {method: 'POST'},
        'put': {method: 'PUT'}
      }
    );
  })
  .factory('setAlcGroup',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.alcGroup.update,
      {
        company_code: '@companyCode',
        alc_grp_uuid: '@alcGrpUuid'
      },
      {
        'post': {method: 'POST'}
      }
    );
  })
  .factory('getAlcGroup',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.alcGroup.list,
      {},
      {
        'get': {method: 'GET'}
      }
    );
  }).factory('getAlcGroupActiveUsers',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.alcGroup.activeUsers,
      {},
      {
        'get': {method: 'GET'}
      }
    );
  });
