'use strict';
/**
 * @name showCostCenterCtrl
 * @desc Controller to see cost center for a company
 */
function showCostCenterCtrl($scope, listCostCenters, company, $uibModalInstance) {
  $scope.closeModal = closeModal;
  $scope.listCostCenters = listCostCenters;
  $scope.company_name = company;

  function closeModal() {
    $uibModalInstance.close();
  }
}

showCostCenterCtrl.$inject = ['$scope', 'listCostCenters', 'company', '$uibModalInstance'];

angular
  .module('metabuyer')
  .controller('showCostCenterCtrl', showCostCenterCtrl);
