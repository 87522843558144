
'use strict';

/**
 * @name itemMultiSelectCtrl
 *
 * @requires $scope
 * @requires $uibModal
 * @requires prFunction
 * @requires globalFunc
 *
 *
 * @description the Controller for mass edit, to prepare and show the common selection in each type as well as removing them.
 *
 *
 * @authors Ahmed Saleh
 * @copyright Sunway Metacloud &copy; 2016
 */

function itemMultiSelectCtrl ($scope, prFunction, globalFunc, $uibModal, $rootScope, pathConstants, $http) {

  $scope.minDateItem              = new Date();
  $scope.datePickerTimezone       = $rootScope.datePickerTimezone;
  $scope.neededByInfo             = {};
  $scope.accountCodeInfo          = {};
  var selectedItemsIndexes        = [];
  var localPRData                 = {};
  $scope.loading                  = false;

  $scope.getSelectedItemsIndexes  = getSelectedItemsIndexes;
  $scope.openMultiOptions         = openMultiOptions;
  $scope.close                    = close;
  $scope.updateItemsInformation   = updateItemsInformation;
  $scope.multiDatePicker          = multiDatePicker;
  $scope.getDeliveryAddresses     = getDeliveryAddresses;
  $scope.getPR                    = getPR;
  $scope.getTaxesList             = getTaxesList;
  $scope.onSelectDeliveryAddress  = onSelectDeliveryAddress;
  $scope.onSelectNeededDate       = onSelectNeededDate;
  $scope.cancelSelectedItems      = cancelSelectedItems;
  $scope.deleteItems              = deleteItems;
  $scope.accountCodeListing       = accountCodeListing;
  $scope.PRData                   = PRData;
  $scope.onSelectAccountCode      = onSelectAccountCode;
  $scope.duplicateItems           = duplicateItems;
  $scope.allowLineDiscount        = checkLineDiscount();

  /**
   * Check if item allow line discount
   * @returns {boolean}
   */
  function checkLineDiscount() {
    return prFunction.getDocumentRule('allow_line_discount') !== false;
  }

  function PRData() {
    localPRData = prFunction.getPRData();
    return localPRData;
  }

  function accountCodeListing($select) {
    if (!!$select && $select.length > 2) {
      $scope.loading = true;
      return $http.get(pathConstants.apiUrls.accountCode.prList, {
        params: {
          query: $select,
          company_code: $scope.PRData().company.code,
          cost_center_id: $scope.PRData().cost_center._id,
          offset: 10
        }
      }).then(function (response) {
          $scope.loading = false;
          if (!!response.data && !!response.data.content && !!response.data.content.data) {
            return response.data.content.data;
          }
      });
    }
  }

  function onSelectDeliveryAddress(address){
    updateItemsInformation('delivery-address', address._id);
  }

  function getPR(){
    return prFunction.getPRData();
  }

  function getDeliveryAddresses(){
    return prFunction.getCostCenterDeliveryAddresses();
  }

  function getTaxesList (){
    return prFunction.getTaxData();
  }

  function onSelectNeededDate(date) {
    var miliTime = globalFunc.convertDateToTimestamp(date);
    updateItemsInformation('needed-by', miliTime);
  }

  function onSelectAccountCode(accountCode) {
    $scope.accountCodeInfo.multiAccountCode = accountCode;
  }

  /**
   * TODO: combine the getSelectedItemsIndexes and getSelectedItems into one and manipulate the return data (Ahmed Saleh)
   */

  /**
   * function to returns the selected items Indexes
   * @returns {Array}
   */
  function getSelectedItemsIndexes() {
    var result = document.getElementsByClassName("scroll-fix-header");
    var wrappedResult = angular.element(result);

    selectedItemsIndexes = [];
    if (!!$scope.items && $scope.items.length > 0) {
      for (var i in $scope.items) {
        if ($scope.items[i].selected === true) {
          selectedItemsIndexes.push(i);
        }
      }
    }

    if (selectedItemsIndexes.length > 0)
      wrappedResult.css('top', '62px');
    else
      wrappedResult.css('top', '0');

    return selectedItemsIndexes;
  }

  /**
   * returns the selected Items
   * @returns {Array}
   */
  function getSelectedItems() {
    var selectedItems = [];
    if (!!$scope.items && $scope.items.length > 0) {
      for (var i in $scope.items) {
        if ($scope.items[i].selected === true) {
          selectedItems.push($scope.items[i]);
        }
      }
    }
    return selectedItems;
  }

  /**
   * function for cancelling the Selection for items
   */
   function cancelSelectedItems () {
    $scope.unSelectAllItems({
      value: false
    });
  }

  /**
   * deleting items
   */
  function deleteItems () {
    $scope.removeItems({
      data: getSelectedItems()
    });
  }

  function duplicateItems() {
    var pr = PRData();
    var selectedItems = getSelectedItems();

    // check pr item limit
    var totalLineItemAfterDuplicate = selectedItems.length + pr.pr_items.length;
    if ($scope.pritemlimit > 0 && totalLineItemAfterDuplicate > $scope.pritemlimit) {
      globalFunc.objectErrorMessage("Only " + $scope.pritemlimit + " line items allowed per PR");
      return;
    }

    prFunction.setLoading('lineItemTable', true);
    prFunction.setLoading('updatePRField', true);

    var selectedItemsIds = selectedItems.map(function (item) {return item['_id']});
    var path = pathConstants.apiUrls.purchaseRequisitions.duplicateMultiItem;
    path = path.replace(':id', pr._id);
    $http.put(
      path,
      {
        pr_item_ids: selectedItemsIds
      }
    ).then(function onSuccess(response){
      var resource = response.data;
      if (!!resource && !!resource.content && !!resource.content.data)
        prFunction.setPRData(resource.content.data);

      prFunction.setLoading('lineItemTable', false);
    }).catch(function onError(error){
      prFunction.setLoading('lineItemTable', false);
      globalFunc.objectErrorMessage(error.data);
    });
  }


  /**
   * TODO: combine all the "check" function as they follow new structure, its possible to enhance them and it a single function (Ahmed Saleh)
   */

  /**
   *  function to check Notes on each item if they exist
   * @returns {{}}
   */
  function checkItemsNote() {

    var data = {};
    var indexes = getSelectedItemsIndexes();

    if (!indexes || indexes.length < 1) {
      data.sameNotesChecker = false;
      data.note = null;
      return data;
    }

    data.sameNotesChecker = true;
    var baseNote = $scope.items[indexes[0]].note;

    for (var i in indexes) {
      var index = Number(indexes[i]);
      if (!$scope.items[index].note || $scope.items[index].note !== baseNote) {
        data.sameNotesChecker = false;
        data.note = null;
        break;
      }
    }

    if (!!data.sameNotesChecker) {
      data.note = baseNote;
    }

    return data;

  }

  /**
   *  function to check needed by on each item if they exist
   * @returns {{}}
   */
  function checkItemsNeededBy() {


    var data = {};
    var indexes = getSelectedItemsIndexes();

    if (!indexes || indexes.length < 1) {
      data.sameNeededByChecker = false;
      data.neededBy = null;
      return data;
    }

    data.sameNeededByChecker = true;
    var baseDate = $scope.items[indexes[0]].needed_by_date;

    for (var i in indexes) {
      var index = Number(indexes[i]);
      if (!$scope.items[index].needed_by_date || $scope.items[index].needed_by_date !== baseDate) {
        data.sameNeededByChecker = false;
        data.neededBy = null;
        break;
      }
    }

    if (!!data.sameNeededByChecker) {
      data.neededBy = baseDate;
    }

    return data;
  }

  /**
   *  function to check Account Code on each item if they exist
   * @returns {{}}
   */
  function checkItemsAccountCode() {

    var data = {};
    var indexes = getSelectedItemsIndexes();

    if (!indexes || indexes.length < 1) {
      data.sameAccountCodeChecker = false;
      data.account_code = null;
      return data;
    }

    var baseAccountCode = $scope.items[indexes[0]].account_code;
    if (!baseAccountCode) {
      data.sameAccountCodeChecker = false;
      data.account_code = null;
      return data;
    }

    data.sameAccountCodeChecker = true;
    for (var i in indexes) {
      var index = Number(indexes[i]);
      if (!$scope.items[index].account_code || $scope.items[index].account_code._id !== baseAccountCode._id) {
        data.sameAccountCodeChecker = false;
        data.account_code = null;
        break;
      }
    }

    if (!!data.sameAccountCodeChecker) {
      data.account_code = baseAccountCode;
    }

    return data;
  }

  /**
   *  function to check Delivery Addresses on each item if they exist
   * @returns {{}}
   */
  function checkItemsDeliveryAddress() {
    var data = {};
    var indexes = getSelectedItemsIndexes();

    if (!indexes || indexes.length < 1) {
      data.sameDeliveryAddressChecker = false;
      data.deliveryAddress = null;
      return data;
    }

    data.sameDeliveryAddressChecker = true;
    var baseDeliveryAddress = $scope.items[indexes[0]].delivery_address;

    if (!baseDeliveryAddress || !baseDeliveryAddress._id) {
      data.sameDeliveryAddressChecker = false;
      data.deliveryAddress = null;
      return data;
    }

    for (var i in indexes) {
      var index = Number(indexes[i]);
      if (!$scope.items[index].delivery_address || $scope.items[index].delivery_address._id !== baseDeliveryAddress._id) {
        data.sameDeliveryAddressChecker = false;
        data.deliveryAddress = null;
        break;
      }
    }

    if (!!data.sameDeliveryAddressChecker) {
      data.deliveryAddress = baseDeliveryAddress;
    }

    return data;
  }

  /**
   *  function to check Delivery Addresses on each item if they exist
   * @returns {{}}
   */
  function checkItemsQuantity() {
    var data = {};
    var indexes = getSelectedItemsIndexes();

    if (!indexes || indexes.length < 1) {
      data.sameQtyChecker = false;
      data.qty = null;
      return data;
    }

    data.sameQtyChecker = true;
    var baseQty = $scope.items[indexes[0]].qty;

    for (var i in indexes) {
      var index = Number(indexes[i]);
      if (!$scope.items[index].qty || $scope.items[index].qty !== baseQty) {
        data.sameQtyChecker = false;
        data.qty = null;
        break;
      }
    }

    if (!!data.sameQtyChecker) {
      data.qty = baseQty;
    }

    return data;
  }

  /**
   * check for the Discount if its added and if its the same to the selected items
   * @returns {{}}
   */
  function checkItemsDiscount() {
    var data = {};
    var indexes = getSelectedItemsIndexes();

    if (!indexes || indexes.length < 1) {
      data.sameDiscountChecker = false;
      data.discount_entry = null;
      return data;
    }

    data.sameDiscountChecker = true;
    var baseQty = $scope.items[indexes[0]].discount;
    var basePercentage = $scope.items[indexes[0]].discount_percentage;
    var index;

    /**
     * validate in for both percentage and amount to be matched
     */
    if (!!basePercentage) {
      for (var i in indexes) {
        index = Number(indexes[i]);
        if (!$scope.items[index].discount || $scope.items[index].discount !== baseQty || (basePercentage !== $scope.items[indexes[0]].discount_percentage)) {
          data.sameDiscountChecker = false;
          data.discount_entry = null;
          break;
        }
      }
    } else {
      for (var i in indexes) {
        index = Number(indexes[i]);
        if (!$scope.items[index].discount || $scope.items[index].discount !== baseQty) {
          data.sameDiscountChecker = false;
          data.discount_entry = null;
          break;
        }
      }
    }

    if (!!data.sameDiscountChecker) {
      if(!!basePercentage){
        data.discount_entry = basePercentage + '%';
      }else{
        data.discount_entry = baseQty;
      }
    }

    return data;
  }

  /**
   * check if the Taxes has been selected and if they are the same to display 1 entry that represent all
   * @returns {{}}
   */
  function checkItemsTax() {
    var data = {};
    var indexes = getSelectedItemsIndexes();
    var baseTax = $scope.items[indexes[0]].tax;

    if (!indexes || indexes.length < 1 || !baseTax) {
      data.sameTaxChecker = false;
      data.tax = null;
      return data;
    }

    data.sameTaxChecker = true;

    for (var i in indexes) {
      var index = Number(indexes[i]);
      if (!$scope.items[index].tax || $scope.items[index].tax._id !== baseTax._id) {
        data.sameTaxChecker = false;
        data.tax = null;
        break;
      }
    }

    if (!!data.sameTaxChecker) {
      data.tax = baseTax;
    }

    return data;
  }

  /**
   * function that checks if the Prices are available in order to perform further actions
   * @returns {boolean}
   */
  function checkItemsPricesEntry() {
    var indexes = getSelectedItemsIndexes();
    var index;
    for (var i in indexes){
      index = indexes[i];
      if(!$scope.items[index].unit_price){
        return false;
      }
    }
    return true;
  }

  /**
   *  function to open each panel and load the needed data
   * @param option
   */
  function openMultiOptions(option) {
    var template = null;
    $scope.neededByInfo = null;
    $scope.deliverAddressInfo = null;
    $scope.acountCodeInfo = null;
    $scope.noteInfo = null;
    $scope.quantityInfo = null;

    switch (option) {
      case 1:
        template = 'components/itemMultiSelect/multiNeededByModalTemplate.html';
        $scope.dateOptions = {
            formatYear: 'yy',
            minDate: new Date(),
            startingDay: 1
        };
        $scope.neededByInfo = checkItemsNeededBy();
        $scope.neededByInfo.open = false;
        if ($scope.neededByInfo.sameNeededByChecker) {
          $scope.neededByInfo.multiNeededBy = $scope.neededByInfo.neededBy;
        }
        break;
      case 2:
        $scope.eventHandler = true;
        $scope.deliveryAddressInfo = checkItemsDeliveryAddress();
        if ($scope.deliveryAddressInfo.sameDeliveryAddressChecker) {
          $scope.deliveryAddressInfo.multiDeliveryAddress = $scope.deliveryAddressInfo.deliveryAddress;
        }
        break;
      case 3:
        $scope.eventHandler = false;
        template = 'components/itemMultiSelect/multiAccountCodModalTemplate.html';
        $scope.accountCodeInfo = checkItemsAccountCode();
        if ($scope.accountCodeInfo.sameAccountCodeChecker) {
          $scope.accountCodeInfo.multiAccountCode = $scope.accountCodeInfo.account_code;
        }
        break;
      case 4:
        template = 'components/itemMultiSelect/multiNoteModalTemplate.html';
        $scope.noteInfo = checkItemsNote();
        if ($scope.noteInfo.sameNotesChecker) {
          $scope.noteInfo.multiNote = $scope.noteInfo.note;
        }
        if (!$scope.noteInfo.note) {
          $scope.noteInfo.focus = true;
        }
        break;
      case 5:
        template = 'components/itemMultiSelect/multiQuantityModalTemplate.html';
        $scope.quantityInfo = checkItemsQuantity();
        if ($scope.quantityInfo.sameQtyChecker) {
          $scope.quantityInfo.multiQuantity = $scope.quantityInfo.qty;
        }
        break;
      case 6:
        template = 'components/itemMultiSelect/multiDiscountModalTemplate.html';
        $scope.discountInfo = checkItemsDiscount();
        $scope.allowEditing = checkItemsPricesEntry();
        if ($scope.discountInfo.sameDiscountChecker) {
          $scope.discountInfo.multiDiscount = $scope.discountInfo.discount_entry;
        }
        break;
      case 7:
        template = 'components/itemMultiSelect/multiTaxModalTemplate.html';
        $scope.taxInfo = checkItemsTax();
        $scope.allowEditing = checkItemsPricesEntry();
        if ($scope.taxInfo.sameTaxChecker) {
          $scope.taxInfo.multiTax = $scope.taxInfo.tax;
        }
        break;
    }
    if (!!template) {
      $scope.modal = $uibModal.open({
        backdrop: 'static',
        keyboard: false,
        templateUrl: template,
        scope: $scope
      });
    }
  }


  /**
   * dismissing the modal
   */
  function close() {
    $scope.modal.close();
  }


  /**
   * updating every time that is supported in the mass edit
   * @param field
   * @param newValue
   */
  function updateItemsInformation(field, newValue){
    if(!field){
      return;
    }

    $scope.updateMultiItems({
      items: getSelectedItems(),
      field: field,
      value: newValue
    });
    close();
  }

  /**
   * opening datepicker
   * @param $event
   */
  function multiDatePicker($event) {
    $event.preventDefault();
    $event.stopPropagation();
    $scope.neededByInfo.open = true;
  }
}

/**
 * @name itemMultiSelect
 *
 * @requires $uibModal
 *
 * @requires $scope
 *
 * @description This directive is for the header at the PR for selecting multiple options for multiple items
 *
 *
 * @authors Ahmed Saleh
 * @copyright Sunway Metacloud &copy; 2016
 */

angular
  .module('metabuyer')
  .controller(itemMultiSelectCtrl, 'itemMultiSelectCtrl')
  .directive('itemMultiSelect',function() {
    return {
      restrict: 'E',
      templateUrl: 'components/itemMultiSelect/itemMultiSelectTemplate.html',
      scope: {
        items: '=',
        updateItems: '&',
        addressList: '=',
        unSelectAllItems: '&',
        removeItems: '&',
        updateMultiItems: '&',
        pritemlimit: '=?'
      },
      controller: itemMultiSelectCtrl
    }
  });
