'use strict';

angular.module('metabuyer.services.companyAsset', ['ngResource'])
  .factory('singleCompanyAsset',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.companyAsset.single,
        {
          uuid: '@uuid'
        },
        {
          'get': {method: 'GET'},
          'put': {method: 'PUT'},
          'post': {method: 'POST'}
        }
      );
    })
  .factory('getCompanyAsset',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.companyAsset.get,
        {
          code: '@code'
        },
        {
          'get': {method: 'GET'}
        }
      );
    });
