'use strict';

function poConsolidationCtrl ( $scope, $state, globalFunc )
{
  var tempState = _.cloneDeep( $state.params );
  $scope.tabData = [
    {
      heading: "Pending Process PR",
      route: 'main.user.poConsolidation.manage',
      key: 'partially_utilized',
      params: globalFunc.filterUrlParams( tempState, {
        status: 'partially_utilized',
        cursor: null
      } )
    },
    {
      heading: "Consolidation",
      route: 'main.user.poConsolidation.itemListing',
      key: 'items',
      params: globalFunc.filterUrlParams( tempState, {
        status: 'items',
        cursor: null
      } )
    },
    {
      heading: "Fully processed PR",
      route: 'main.user.poConsolidation.manage',
      key: 'fully_utilized',
      params: globalFunc.filterUrlParams( tempState, {
        status: 'fully_utilized',
        cursor: null,
        order_by: 'updated_at'
      } )
    },
    {
      heading: "Expired PR",
      route: 'main.user.poConsolidation.manage',
      key: 'expired',
      params: globalFunc.filterUrlParams( tempState, {
        status: 'expired',
        cursor: null
      } )
    },
    {
      heading: 'Closed PR',
      route: 'main.user.poConsolidation.manage',
      key: 'closed',
      params: globalFunc.filterUrlParams( tempState, {
        status: 'closed',
        cursor: null
      } )
    }
  ];

  function initialize ()
  {
    if ( !!$state.params && !!$state.params.query )
    {
      $scope.searchText = $state.params.query;
    } else
    {
      $scope.searchText = '';
    }

    if ( !!$state.current && !!$state.current.name && $state.current.name.indexOf( 'itemListing' ) > -1 )
    {
      setConsolidationItemConfig();
    } else
    {
      setConsolidationConfig();
    }
  }

  $scope.$on( '$stateChangeStart', function ( event, toState, toParams )
  {
    if ( !!toParams.status )
    {
      $scope.parentStateParams = toParams;
      $scope.status = toParams.status;
    }

    if ( $state.current.name !== toState.name )
    {
      if ( !!toState.name && toState.name.indexOf( 'itemListing' ) > -1 )
      {
        setConsolidationItemConfig();
      } else
      {
        setConsolidationConfig();
      }
    }
  } );

  function setConsolidationConfig ()
  {
    $scope.searchPlaceholder = 'Search Consolidations';
    $scope.searchState = 'main.user.poConsolidation.manage';
    $scope.parentState = 'main.user.poConsolidation.manage';
    $scope.parentStateParams = $state.params;
    $scope.isConsolidation = false;

      $scope.columns = [
      { id: 'check box', title: '', unsortable: true, condition: ['partially_utilized'], unsearchable: true, unSelectable: true },
      { id: 'requisition|reference', label: 'PR#' },
      { id: 'po|reference', label: 'PO#', conditional: true, condition: 'fully_utilized', unsearchable: true, unsortable: true },
      { id: 'requisition|title', label: 'TITLE' },
      { id: 'company|descr', label: 'COMPANY' },
      { id: 'shadow_company|descr', label: 'BILLING COMPANY' },
      { id: 'item_line_number', label: 'NUMBER OF LINE ITEMS', unsearchable: true, unSelectable: true,  conditional: true, condition: ['closed', 'partially_utilized']},
      { id: 'grand_total', label: 'TOTAL AMOUNT', unsearchable: true },
      { id: 'currency|code', label: 'CURRENCY', unsearchable: true },
      { id: 'progress', label: 'PROGRESS', unsearchable: true, unsortable: true },
      { id: 'creator_info|display_name', label: 'CREATOR', unsearchable: true },
      { id: 'requester_info|display_name', label: 'REQUESTOR', unsearchable: true },
      { id: 'pr_last_approvers|display_name', label: 'PR FINAL APPROVER', unsearchable: true, conditional: true, condition: ['fully_utilized', 'expired', 'closed', 'partially_utilized'] },
      { id: 'pr_approved_at', label: 'PR APPROVED DATE', unsearchable: true },
      { id: 'needed_by_date', label: 'NEEDED BY DATE', unsearchable: true },
      { id: 'mode_of_purchase', label: 'MODE OF PURCHASE', conditional: true, condition: 'partially_utilized' },
      { id: 'action', label: 'ACTION', conditional: true, condition: 'partially_utilized', unsearchable: true, unsortable: true }
    ];
  }

  function setConsolidationItemConfig ()
  {
    $scope.searchPlaceholder = 'Search Consolidation Items';
    $scope.searchState = 'main.user.poConsolidation.itemListing';
    $scope.parentState = 'main.user.poConsolidation.itemListing';
    $scope.parentStateParams = $state.params;
    $scope.isConsolidation = true;

    $scope.columns = [
      { id: 'check box', title: '', unsortable: true, unsearchable: true, unSelectable: true },
      { id: 'pr_reference', label: 'PR#' },
      { id: 'item_line_number', label: 'PR LINE ID', unsearchable: true, unSelectable: true },
      { id: 'pr_title', label: 'TITLE' },
      { id: 'supplier|descr', label: 'SUPPLIER' },
      { id: 'company|descr', label: 'COMPANY' },
      { id: 'shadow_company|descr', label: 'BILLING COMPANY' },
      { id: 'delivery_address', label: 'DELIVERY ADDRESS', unsearchable: true, unsortable: true },
      { id: 'payment_term', label: 'PAYMENT TERM', unsearchable: true, unsortable: true },
      { id: 'name', label: 'ITEM NAME' },
      { id: 'originalQuantity', label: 'ORIGINAL QUANTITY', unsearchable: true, unsortable: true },
      { id: 'remainingQuantity', label: 'REMAINING QUANTITY', unsearchable: true, unsortable: true },
      { id: 'progress', label: 'PROGRESS', unsearchable: true, unsortable: true },
      { id: 'creator', label: 'CREATOR', unsearchable: true, unsortable: true },
      { id: 'requestor', label: 'REQUESTOR', unsearchable: true, unsortable: true },
      { id: 'finalApprover', label: 'PR FINAL APPROVER', unsearchable: true, unsortable: true },
      { id: 'pr_approved_at', label: 'PR APPROVED AT', unsearchable: true, unsortable: true },
      { id: 'currency', label: 'CURRENCY', unsearchable: true, unsortable: true }
    ];
  }

  $scope.peiChart = {
    options: {
      fill: [ '#1ab394', "#eeeeee" ]
    }
  };

  initialize();

  $scope.$emit( 'pageTitleChanged', 'PO Consolidation' );
}

poConsolidationCtrl.$inject = [ '$scope', '$state', 'globalFunc' ];

angular.module( 'metabuyer' )
  .controller( 'poConsolidationCtrl', poConsolidationCtrl );
