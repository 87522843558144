'use strict';

angular
  .module('metabuyer')
  .directive('tenderMemo', function($uibModal) {
    return {
      restrict: 'E',
      scope: {
        initialData: '=',
        tender: '=',
        editableStatus: '='
      },
      template: '<div ng-include="templateUrlTenderMemo"></div>',
      controller: 'tenderMemoCtrl',
      link: function($scope) {
        $scope.templateUrlTenderMemo = 'components/tenderComponents/memo/tenderMemoTemplate.html';
      }
    };
  })
  .directive("tenderMemoUploadChange", function() {
    return {
      scope: {
        tenderMemoUploadChange: "&"
      },
      link: function($scope, $element, $attrs) {
        $element.on("change", function(event) {
          $scope.$apply(function() {
            $scope.tenderMemoUploadChange({ $event: event })
          })
        })
        $scope.$on("$destroy", function() {
          $element.off();
        });
      }
    }
  });
