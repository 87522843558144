'use strict';

function poCreationCtrl($scope, items, $state, poApprovalPreview, $filter, globalFunc){
  $scope.poId = '';

  $scope.approvalsPreview = approvalsPreview;
  $scope.approvals = {};
  $scope.PurchaseOrder = {
    status: 'draft'
  };

  function initialize() {
    if (!items){
      $state.go('main.user.poConsolidation.itemListing', {status: 'items'});
    }
    else{
      var contactPersonsArray = [];
      var contactPersons = '';
      _.forEach(items, function (item) {
        var contactPersonExists  = _.findIndex(contactPersonsArray, function(e){
          return e === item.contact_person;
        }) == 0

        if (!contactPersonExists) {
          contactPersonsArray.push(item.contact_person);
        }
      });

      _.forEach(contactPersonsArray, function (contactPerson) {
        if (!_.isEmpty(contactPersons)) {
          contactPersons = contactPersons + '/' + contactPerson;
        } else {
          contactPersons = contactPerson;
        }
      });

      $scope.consolidationObject = {
        header: {
          company_info: items[0].company,
          shadow_company: !!items[0].shadow_company ? items[0].shadow_company : items[0].company,
          billing_info: items[0].billing_info,
          delivery_address: items[0].delivery_address,
          supplier: items[0].supplier,
          pr_creator: items[0].pr_creator,
          pr_requestor: items[0].pr_requestor,
          currency: items[0].currency,
          pr_references: [],
          payment_term: items[0].payment_term,
          contact_person: contactPersons,
          remarks: items[0].remarks,
          needed_by_date: items[0].needed_by_date,
          title: items[0].title,
          company_country: items[0].company_country,
          expenseTypeCategory: items[0].expense_type_category,
          pc: []
        },
        items: items
      };

      var neededByDates = [];
      items.forEach(function (e){
        if (!!e.pr_custom_reference && !_.isEmpty(e.pr_custom_reference)) {
          var prRefObject = {"number": e.pr_custom_reference, "_id" : e.pr_id};
        } else {
          var prRefObject = {"number": e.pr_reference, "_id" : e.pr_id};
        }
        if (!_.some($scope.consolidationObject.header.pr_references, prRefObject))
          $scope.consolidationObject.header.pr_references.push(prRefObject);

        if (!neededByDates.includes($filter('date')(e.needed_by_date, 'dd MMM yyyy')))
          neededByDates.push($filter('date')(e.needed_by_date, 'dd MMM yyyy'));

        if (!!e.pc.id && !_.isEmpty(e.pc.id)) {
          $scope.consolidationObject.header.pc.push(e.pc);
        }
      })

      if (neededByDates.length > 1)
        $scope.consolidationObject.header.multipleNeededByDate = true;
    }
  }

  function approvalsPreview() {
    var data = $scope.consolidationObject;

    poApprovalPreview.post(
      {
        company_code: items[0].company.code,
        context_type: 'Metabuyer\\PurchaseOrder\\Models\\PurchaseOrder'
      },
      {
        data: data
      },
      function (resource) {
        $scope.approvalFlowData = resource.content.data;
        $scope.approvals = resource.content.data;
        $timeout(function () {
          loadScrollbar();
        }, 100);
      }, function (error) {
        globalFunc.objectErrorMessage('Failed to preview the approval flow');
        globalFunc.objectErrorMessage(error);
      });
  }

  initialize();

  $scope.$on('poCreated', function(event, id) {
    $scope.poId = id;
  });
}

poCreationCtrl.$inject = ['$scope', 'items', '$state', 'poApprovalPreview', '$filter', 'globalFunc'];

angular
  .module('metabuyer')
  .controller('poCreationCtrl', poCreationCtrl);
