'use strict';
/**
 * @name expenseTypeDetailsCtrl
 * @desc Controller for master data ExpenseType details controller
 */
function expenseTypeDetailsCtrl($scope, $uibModalInstance, expenseTypeDetails,
                                expenseTypeMasterDataList, globalFunc, toastr, $state, UserPermissions, $rootScope) {
  $scope.editClicked = false;
  $scope.expenseTypeDetails = expenseTypeDetails.data;

  $scope.expenseTypeStatuses = [
    { isActive: 'Active' },
    { isActive: 'Deactivated' }
  ];
  $scope.originalCode = $scope.expenseTypeDetails.code;
  $scope.isDeveloper = UserPermissions.isDeveloper($rootScope.currentUser);
  $scope.tab = 1;
  $scope.historyDependencies = {
    embeddedParams: {
      'object[0][object_id]': $scope.expenseTypeDetails._id,
      'object[0][object_type]': 'Metabuyer\\Models\\ExpenseTypes',
      offset: 5,
      order_by: '-created_at' // Created at, descending
    }
  };

  $scope.expenseTypeCategories = [
    {
      value: null,
      descr: 'N/A'
    },
    {
      value: 'CAPEX',
      descr: 'CAPEX'
    },
    {
      value: 'OPEX',
      descr: 'OPEX'
    }
  ];

  $scope.budgetOptions = [
    {
      value: 'OPTIONAL',
      descr: 'Optional'
    },
    {
      value: 'REQUIRED',
      descr: 'Required'
    },
    {
      value: 'NOT_ALLOWED',
      descr: 'Not allowed'
    }
  ];

  $scope.pcmOptions = [
    {
      value: false,
      descr: 'NO'
    },
    {
      value: true,
      descr: 'YES'
    }
  ];

  $scope.subtypeOptions = [
    {
      value: null,
      descr: 'N/A'
    },
    {
      value: 'GWO',
      descr: 'GWO'
    },
    {
      value: 'SPK',
      descr: 'SPK'
    }
  ];

  $scope.toggleTab         = toggleTab;
  $scope.cancel            = cancel;
  $scope.editExpenseType   = editExpenseType;
  $scope.updateExpenseType = updateExpenseType;
  $scope.duplicationCheck  = duplicationCheck;

  function cancel() {
    $uibModalInstance.close();
  }

  function toggleTab(tab) {
    return $scope.tab = tab;
  }

  function editExpenseType(){
    $scope.editClicked = true;
  }

  function validate(expenseTypeDetails){
    if (!expenseTypeDetails){
      return false;
    }
    if (!expenseTypeDetails.code){
      return false;
    }
    if (!expenseTypeDetails.descr){
      return false;
    }
    if (!expenseTypeDetails.expenseTypeStatus){
      return false;
    }
    if (!expenseTypeDetails.budgetSetting){
      return false;
    }
    return true;
  }

  function updateExpenseType() {
    if(!!$scope.codeCheck){
      toastr.error($scope.expenseTypeDetails.code + ' is already in use');
    }
    else if(validate($scope.expenseTypeDetails)){
      swal({
          title: 'Confirm Updating the Expense Type',
          text: 'This will update the details for this Expense Type',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#1ab394',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true,
          closeOnCancel: true
        },
        function (confirmed) {
          if (!!confirmed) {

            // set status in integer to pass it as parameter
            var tempStatus;
            switch($scope.expenseTypeDetails.expenseTypeStatus.isActive.toLowerCase()){
              case 'active':
                tempStatus = 1;
                break;
              case 'deactivated':
                tempStatus = 0;
                break;
              case 'disabled':
                tempStatus = 3;
                break;
            }

            $scope.expenseTypeDetails.is_active = $scope.expenseTypeDetails.expenseTypeStatus.isActive;
            $scope.expenseTypeDetails.updated_at = new Date();

            expenseTypeMasterDataList.put(
              {
                id: $scope.expenseTypeDetails._id
              },
              {
                code: $scope.expenseTypeDetails.code,
                descr: $scope.expenseTypeDetails.descr,
                category: !!$scope.expenseTypeDetails.category ? $scope.expenseTypeDetails.category.value : undefined,
                budget_setting: !!$scope.expenseTypeDetails.budgetSetting ? $scope.expenseTypeDetails.budgetSetting.value : undefined,
                is_pcm: !!$scope.expenseTypeDetails.isPcm ? $scope.expenseTypeDetails.isPcm.value : false,
                subtype: !!$scope.expenseTypeDetails.subtype ? $scope.expenseTypeDetails.subtype.value : undefined,
                is_active: tempStatus
              },
              function (resource) {
                toastr.success('Expense Type has been updated');
                $uibModalInstance.close(resource.content.data);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                return false;
              }
            );
          } else {
            return false;
          }
        }
      );
    }
  }

  /**
   * @function duplicationCheck
   * Calls the global function's duplication check
   * Checks callback function return for assigning scope values for validation
   **/
  function duplicationCheck(model,field,value,checker){
    globalFunc.duplicationCheck(model,field,value,checker, function(callback){
      switch (callback){
        case 'codeCheckTrue':
          if($scope.expenseTypeDetails.code != $scope.originalCode){
          $scope.codeCheck = true;
          }
          else{
            $scope.codeCheck = false;
          }
          break;
        case 'codeCheckFalse':
          $scope.codeCheck = false;
          break;
      }
    });
  }

  function initialize() {
    if (!!$scope.expenseTypeDetails.is_active) {
      $scope.expenseTypeDetails.expenseTypeStatus =
        {isActive: globalFunc.capitalizeFirstCharacter($scope.expenseTypeDetails.is_active)};
    }
    if (!$scope.expenseTypeDetails.category) {
      $scope.expenseTypeDetails.category = $scope.expenseTypeCategories[0];
    } else {
      $scope.expenseTypeDetails.category = globalFunc.findInArray($scope.expenseTypeCategories,
        'value', $scope.expenseTypeDetails.category);
    }

    if($scope.expenseTypeDetails.budget_setting){
      $scope.expenseTypeDetails.budgetSetting = globalFunc.findInArray($scope.budgetOptions,
        'value', $scope.expenseTypeDetails.budget_setting);
    }

    if(!$scope.expenseTypeDetails.is_pcm) {
      $scope.expenseTypeDetails.isPcm = $scope.pcmOptions[0];
    } else {
      $scope.expenseTypeDetails.isPcm = globalFunc.findInArray($scope.pcmOptions,
        'value', $scope.expenseTypeDetails.is_pcm);
    }

    if (!$scope.expenseTypeDetails.subtype) {
      $scope.expenseTypeDetails.subtype = $scope.subtypeOptions[0];
    } else {
      $scope.expenseTypeDetails.subtype = globalFunc.findInArray($scope.subtypeOptions,
        'value', $scope.expenseTypeDetails.subtype);
    }
  }

  initialize();
}

expenseTypeDetailsCtrl.$inject = [
  '$scope', '$uibModalInstance', 'expenseTypeDetails', 'expenseTypeMasterDataList', 'globalFunc',
  'toastr', '$state', 'UserPermissions', '$rootScope'
];

angular
  .module('metabuyer')
  .controller('expenseTypeDetailsCtrl', expenseTypeDetailsCtrl);
