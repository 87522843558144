'use strict';

function supplierVersionsController(
  $scope, supplierVersions, $uibModalInstance, $uibModal, supplierStatus, $translatePartialLoader
) {
  $translatePartialLoader.addPart('main/lang');
  $translatePartialLoader.addPart('suppliersManagement/versions/lang');
  $scope.versions = supplierVersions;
  $scope.checkedVersion = [];
  $scope.currentStatus = supplierStatus;

  $scope.closeModal = closeModal;
  $scope.selectVersion = selectVersion;
  $scope.compareVersions = compareVersions;

  function selectVersion(version, checked, index) {
    if (!!checked) {
      version.index = index;
      $scope.checkedVersion.push(version);
    } else {
      var unCheckedObject = version;
      var index = $scope.checkedVersion.indexOf(unCheckedObject);
      $scope.checkedVersion.splice(index, 1);
    }
  }

  function compareVersions() {
    closeModal();
    $uibModal.open({
      templateUrl: 'app/suppliersManagement/versions/comparison.html',
      controller: 'compareSupplierVersionCtrl',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'revision-wrapper',
      size: 'lg',
      resolve: {
        versions: function () {
          return $scope.versions;
        },
        selectedVersion: function () {
          return $scope.checkedVersion.sort(function (a,b) {
            // sort latest first
            return a.index - b.index;
          });
        },
        currentStatus: function () {
          return $scope.currentStatus;
        }
      }
    });
  }

  function closeModal() {
    $uibModalInstance.close();
  }
}

supplierVersionsController.$inject = [
  '$scope', 'supplierVersions', '$uibModalInstance', '$uibModal', 'supplierStatus', '$translatePartialLoader'
];

angular.module('metabuyer')
  .controller('supplierVersionsController', supplierVersionsController);
