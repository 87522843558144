'use strict';
/**
 * @name approvalFlowCtrl
 * @desc Controller for Approval Flow Management Parent Controller
 */
function PRPOPrefixCtrl ($scope, companies, $uibModal, $q, companyReferenceCounter, toastr, $filter, globalFunc) {
  $scope.companies = companies.data;
  $scope.openPrefixPanel = openPrefixPanel;

  function openPrefixPanel(company) {
    $scope.prefixes = null;
    $scope.companyTitle = company.descr;
    var prefixModal = $uibModal.open({
        templateUrl: 'app/settings/PR-POSettings/prefix/prefixModalTemplate.html',
        backdrop: 'static',
        keyboard: false,
        scope: $scope,
//        windowClass: 'preFix-modal-window',
        controller: function($scope, prefix){
          $scope.closeModal = closeModal;
          $scope.onSelectedPrefixType = onSelectedPrefixType;
          $scope.addPrefix = addPrefix;
          $scope.updatePrefix = updatePrefix;
          $scope.validateXeditable = validateXeditable;
          $scope.replaceStringCase = replaceStringCase;
//          $scope.deletePrefix = deletePrefix;
          $scope.editMode = editMode;
          $scope.initialize = initialize;

          $scope.prefixTypes = [
            {descr: 'PR', value: 'purchase_requisition'},
            {descr: 'PO', value: 'purchase_order'},
            {descr: 'GRN', value: 'goods_received_note'}
          ];

          /**
           * To replace the Case of the status and remove the underscore
           * @param string
           * @returns {*}
           */
          //TODO: To put this function and the one in orders manage into global function (Justin 26/2/2016)
          function replaceStringCase(string){
            var newString = string.replace(/_/g, ' ');
            newString = $filter('titleCase')(newString);
            return newString;
          }

          $scope.editClicked = false;

          function closeModal() {
            $scope.$close();
          }

          function onSelectedPrefixType(prefixType){
            $scope.prefixType = prefixType.value;
          }

          function addPrefix(addPrefixModel, addPrefixCount){

              var submit_data =
              {
                prefix: addPrefixModel,
                suffix: 'MB',
                type: $scope.prefixType,
                count: addPrefixCount,
                length: 8,
                separator: '-',
                format: '#prefix#company_code#suffix#count',
                company_code: company.code
              };

              companyReferenceCounter.post(
                {
                  companyId: company._id
                },
                submit_data,
                function (resource) {
                  $scope.prefixes.push(resource.content.data);
                  $scope.addPrefixField = undefined;
                  $scope.addPrefixCount = undefined;
                  $scope.selectPrefixType = undefined;
                },
                function (error) {
                  toastr.error(error.data.message);
                }
              );
          }

          /**
           *
           * @param data
           * @param prefixObject
           * @returns {*}
           *
           * Function to validate the editable field before saving
           */
          function validateXeditable(data, prefixObject) {
            if (data === undefined) {
              return 'Please enter a prefix';
            }
            if(data === prefixObject.prefix){
              return false;
            }
          }

          function updatePrefix(prefixModel, prefixObject, index){
            if(!validateXeditable){
              return false;
            }
            else{
              var update_data =
              {
                prefix: prefixModel,
                suffix: 'MB',
                type: prefixObject.type,
                count: prefixObject.count,
                length: prefixObject.length,
                separator: prefixObject.separator,
                format: '#prefix#company_code#suffix#count',
                company_code: company.code
              };

              companyReferenceCounter.put(
                {
                  companyId: company._id,
                  referenceId: prefixObject._id
                },
                update_data,
                function(resource){
                  toastr.success('Successfully updated prefix');
                  $scope.prefixes[index] = resource.content.data;
                },
                function(error){
                  globalFunc.objectErrorMessage(error);
//                  $scope.prefixes[index] =
                }
              );
            }
          }

//          function deletePrefix(prefixObject, index){
//            companyReferenceCounter.delete(
//              {
//                companyId: company._id,
//                referenceId: prefixObject._id
//              },
//              function(){
//                toastr.success('Successfully deleted prefix');
//                $scope.prefixes.splice(index, 1);
//              },
//              function(error){
//                toastr.error(error.data.message);
//              }
//            );
//          }

          function editMode(){
            $scope.editClicked = true;
          }

          function initialize(){
            $scope.companyPrefix = prefix[0].prefix;
          }

        },
        resolve: {
          prefix: function () {
            var deferred = $q.defer();
            companyReferenceCounter.get(
              {
                companyId: company._id
              },
              function (resource) {
                if (!!resource.content) {
                  $scope.prefixes = resource.content.data;
                  deferred.resolve(resource.content.data);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          }
        }
      }
    );
  }
}
PRPOPrefixCtrl.$inject = ['$scope', 'companies', '$uibModal', '$q', 'companyReferenceCounter', 'toastr', '$filter',
  'globalFunc'];

angular
  .module('metabuyer')
  .controller('PRPOPrefixCtrl', PRPOPrefixCtrl);
