'use strict';

/**
 * Controller to handle the warning messages of missing integration information in PR header
 *
 * @param $scope
 * @param prFunction
 * @param pathConstants
 * @param $http
 * @param metabuyerCache
 */
function integrationWarningCtrl($scope, prFunction, pathConstants, $http, metabuyerCache) {

  /**
   * duplicate check the fields in order to minimize the calls to backend
   * @returns {{companyCode}}
   */
  function gatherFields() {
    var data = {};

    if (!$scope.items || $scope.items.length < 1) {
      return data;
    }

    var suppliersData = [];
    var suppliersCode = [];
    var taxesData = [];
    var taxesCode = [];
    var paymentTermsData = [];
    var paymentTermsCode = [];
    var uomsData = [];
    var uomsCode = [];
    $scope.showWarning = false;

    _.forEach($scope.items, function (item, index) {

      if (!!item.supplier && suppliersCode.indexOf(item.supplier.mb_supplier_code) === -1) {
        suppliersCode.push(item.supplier.mb_supplier_code);
        suppliersData.push({
          descr: item.supplier.descr,
          code: item.supplier.mb_supplier_code
        });
      }

      //HACK: PO items and PR are very different, just simple manipulation
      var tax = '';
      if ($scope.module === 'PO')
        tax = item.taxes[0];
      else
        tax = item.tax;

      if (!!tax && taxesCode.indexOf(tax.code) === -1) {
        taxesCode.push(tax.code);
        taxesData.push({
          descr: tax.descr,
          code: tax.code
        });
      }

      //HACK: PO items and PR are very different, just simple manipulation
      if ($scope.module === 'PO') {
        if(index === 0) {
          paymentTermsCode.push($scope.obj.payment_terms.code);
          paymentTermsData.push({
            descr: $scope.obj.payment_terms.descr,
            code: $scope.obj.payment_terms.code
          });
        }
      }
      else if (!!item.payment_term && paymentTermsCode.indexOf(item.payment_term.code) === -1) {
        paymentTermsCode.push(item.payment_term.code);
        paymentTermsData.push({
          descr: item.payment_term.descr,
          code: item.payment_term.code
        });
      }


      //HACK: PO items and PR are very different, just simple manipulation
      if ($scope.module === 'PO' && !!item.uom && uomsCode.indexOf(item.uom.code) === -1) {
        uomsCode.push(item.uom.code);
        uomsData.push({
          descr: item.uom.descr,
          code: item.uom.code
        });
      }
      else if (!!item.UOM && uomsCode.indexOf(item.UOM.code) === -1) {
        uomsCode.push(item.UOM.code);
        uomsData.push({
          descr: item.UOM.descr,
          code: item.UOM.code
        });
      }

      data.suppliers = suppliersData;
      data.taxes = taxesData;
      data.paymentTerms = paymentTermsData;
      data.uoms = uomsData;

    });

    return data;
  }

  /**
   * setting the combination key for the cache
   * @param value
   * @param ignoreCompanyCode
   * @returns {string}
   */
  function combinationKeys(value, ignoreCompanyCode) {
    if(!!ignoreCompanyCode)
      var key = 'Integration - ' + value;
    else
      key = 'Integration - ' + $scope.companyCode + ' - ' + value;

    return key;
  }

  /**
   * Checking from the cache or calling the backend to get the mapping information
   *
   * @param module
   * @param key
   * @param value
   * @param ignoreCompanyCode
   * @param warningObjectKey
   * @param warningObjectObject
   * @returns
   */
  function checkMappingInfo (module, key, value, ignoreCompanyCode, warningObjectKey, warningObjectObject) {
    var temp = metabuyerCache.get(combinationKeys(value, ignoreCompanyCode));
    if (!!temp) {
      if (temp.isMapped === false) {
        $scope.unmappedData[warningObjectKey].push(warningObjectObject);
        $scope.showWarning = true;
      }
      return;
    }


    //prepare the params
    var params = {};
    if (!ignoreCompanyCode) {
      params = {
        'company-code': $scope.companyCode
      };
    }
    params.module = module;
    params[key] = value;
    var pathUrl = '';
    switch (module) {
      case 'supplier-environment':
        pathUrl = pathConstants.apiUrls.bifrost.supplierEnvironment;
        break;
      case 'tax-environment':
        pathUrl = pathConstants.apiUrls.bifrost.taxEnvironment;
        break;
      case 'payment-term-environment':
        pathUrl = pathConstants.apiUrls.bifrost.paymentTermEnvironment;
        break;
      case 'uom-environment':
        pathUrl = pathConstants.apiUrls.bifrost.uomEnvironment;
        break;
    }

    $http({
        url: pathUrl,
        method: 'GET',
        params: params,
        ignoreLoadingBar: true
      }
    ).then(function (resource) {
      if (!!resource.data) {
        if (resource.data.length > 0) {
          metabuyerCache.put(combinationKeys(value, ignoreCompanyCode), {isMapped: true});
        }
        else {
          metabuyerCache.put(combinationKeys(value, ignoreCompanyCode), {isMapped: false});
          $scope.unmappedData[warningObjectKey].push(warningObjectObject);
          $scope.showWarning = true;
        }
      }
      return false;
    }, function (err) {
      metabuyerCache.put(combinationKeys(value, ignoreCompanyCode), {isMapped: false});
      $scope.unmappedData[warningObjectKey].push(warningObjectObject);
      $scope.showWarning = true;
    });

  }

  /**
   * check the 4 entities of an item
   */
  function runMappingCheck() {
    var mappingKeys = gatherFields();

    $scope.showWarning = false;
    $scope.unmappedData.suppliers = [];
    $scope.unmappedData.taxes = [];
    $scope.unmappedData.paymentTerms = [];
    $scope.unmappedData.uoms = [];
    var companyMap = metabuyerCache.get('Integration - ' + $scope.companyCode);

    //check the company
    if (!companyMap || companyMap.isMapped === false) {
      $scope.unmappedData.company = true;
      $scope.showWarning = true;
    }
    else if (!!$scope.items && $scope.items.length > 0) {

      var integrationThatChecksErpCostCenter = [
        'MB-JDE-ESKER',
        'MB-IFCA-ESKER',
        'MB-JDE-JDE',
        'MB-CMMS-ESKER'
      ];

      //Check for ERP Cost Centers
      if (!$scope.erp && !!$scope.integrationSolution && integrationThatChecksErpCostCenter.includes($scope.integrationSolution.toString())) {
        $scope.showWarning = true;
      }

      var integrationThatChecksPaymentTerm = [
        'MB-JDE-ESKER',
        'MB-JDE-JDE',
        'MB-CMMS-ESKER'
      ];

      var integrationThatChecksUom = [
        'MB-JDE-JDE',
        'MB-CMMS-ESKER'
      ];

      var integrationThatChecksTax = [
        'MB-JDE-ESKER',
        'MB-IFCA-ESKER',
        'MB-JDE-JDE',
        'MB-CMMS-ESKER'
      ];

      //checking suppliers
      _.forEach(mappingKeys.suppliers, function (singleSupplier) {
        checkMappingInfo('supplier-environment', 'supplier-code', singleSupplier.code, false, 'suppliers', singleSupplier)
      });

      //checking taxes
      if (!!$scope.integrationSolution && integrationThatChecksTax.includes($scope.integrationSolution.toString())) {
        _.forEach(mappingKeys.taxes, function (singleTaxes) {
          checkMappingInfo('tax-environment', 'tax-code', singleTaxes.code, false, 'taxes', singleTaxes);
        });
      }

      //checking payment terms
      if (!!$scope.integrationSolution && integrationThatChecksPaymentTerm.includes($scope.integrationSolution.toString())) {
        _.forEach(mappingKeys.paymentTerms, function (singlePaymentTerm) {
          checkMappingInfo('payment-term-environment', 'payment-term-code', singlePaymentTerm.code, false, 'paymentTerms', singlePaymentTerm);
        });
      }

      //checking uom
      if (!!$scope.integrationSolution && integrationThatChecksUom.includes($scope.integrationSolution.toString())) {
        _.forEach(mappingKeys.uoms, function (singleUom) {
          checkMappingInfo('uom-environment', 'uom-code', singleUom.code, false, 'uoms', singleUom);
        });
      }
    }

  }

  function isSageIntegration() {
    if (!!$scope.integrationSolution && $scope.integrationSolution.toString() === 'MB-SAGE-ESKER')
     $scope.isSageIntegration = true;
  }

  /**
   * run a manual check once needed
   */
  $scope.$on('PRDataUpdated', function () {
    runMappingCheck();
  });

  function initialize() {
    $scope.unmappedData = {};
    runMappingCheck();
    isSageIntegration();
  }

  initialize();
}



integrationWarningCtrl.$inject = [
  '$scope', 'prFunction', 'pathConstants', '$http', 'metabuyerCache'
];


angular
  .module('metabuyer')
  .directive('integrationWarning',function() {
    return {
      restrict: 'E',
      templateUrl: 'components/integrationWarning/integration-warningTemplate.html',
      controller: 'integrationWarningCtrl',
      scope: {
        companyCode: '=',
        items: '=',
        integrationSolution: '=',
        erp: '=',
        module: '@',
        obj: '=?'
      }
    }
  })
  .controller('integrationWarningCtrl',  integrationWarningCtrl);
