'use strict';

/**
 * @name purchaseRequisitionAdvancedSearchCtrl
 *
 * @description Controller for Purchase Requisition advanced search
 *
 * @author Harith <ainamirulharith@gmail.com>
 * @copyright Sunway Metacloud &copy; 2016
 */
function purchaseRequisitionAdvancedSearchCtrl($scope, searchModule, listChild, toastr, $rootScope,advancedSearch,
                                               exportSearch, metabuyerCache) {

  var searchCacheKey = 'requisitionAdvancedSearch';
  var searchFields = {
    prNumber : null,
    poNumber :null,
    prTitle : null,
    prStatus : {selected : {id: 1, key: 'all', value: 'All'}},
    totalPriceFrom : null,
    totalPriceTo : null,
    totalCostOfPrFrom : null,
    totalCostOfPrTo : null,
    company : null,
    costCenter : null,
    creator : null,
    createdOnBehalf : null,
    itemTitle : null,
    itemDescription : null,
    budgetCode : null,
    budgetTitle : null,
    commodityKeyword : null,
    approvedBy : null,
    waitingOn : null,
    supplier : null,
    neededByDateFrom : null,
    neededByDateTo : null,
    prFullyApprovedDateFrom : null,
    prFullyApprovedDateTo : null,
    prCreatedDateFrom : null,
    prCreatedDateTo : null,

  };


  $scope.prStatusOptions = [
    {
      id: 1,
      key: 'all',
      value: 'All',
    },
    {
      id: 2,
      key: 'draft',
      value: 'Draft',
    },
    {
      id: 3,
      key: 'pending',
      value: 'Pending Approval',
    },
    {
      id: 4,
      key: 'on_hold',
      value: 'On Hold',
    },
    {
      id: 5,
      key: 'approved',
      value: 'Approved',
    },
    {
      id: 6,
      key: 'deleted',
      value: 'Deleted',
    },
    {
      id: 7,
      key: 'withdrawn',
      value: 'Withdrawn',
    },
    {
      id: 8,
      key: 'rejected',
      value: 'Rejected',
    },
  ];

  $scope.columns = [
    {id: 'reference', label: 'PR#', selected: true},
    {id: 'title', label: 'Title', selected: true},
    {id: 'attachment', unsortable: true, unsearchable: true, unSelectable: true, selected: true},
    {
      id: 'waiting_on',
      label: 'Waiting on',
      condition: ['pending'],
      unsortable: true,
      unsearchable: true, selected: true
    },
    {id: 'pos', label: 'PO#',
      condition: ['approved'], unsortable: true, unsearchable: true, selected: true},
    {
      id: 'grand_total',
      label: 'Amount',
      type: 'double',
      unsearchable: true, selected: true
    },
    {id: 'closed_at', label: 'Approved Date',
      condition: ['approved'], unsearchable: true, selected: true},
    {
      id: 'submitted_at',
      label: 'Submitted Date',
      condition: ['pending', 'approved'],
      type: 'date',
      unsearchable: true, selected: true
    },
    {
      id: 'progress',
      label: 'Progress',
      condition: ['pending', 'approved'],
      unsortable: true,
      unsearchable: true, selected: true
    },
    {
      id: 'needed_by_date',
      label: 'Needed By',
      type: 'date',
      unsearchable: true, selected: true
    },
    {id: 'creator_info|display_name', label: 'Creator', selected: true},
    {id: 'requestor|display_name', label: 'Requestor', selected: true},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true, selected: true
    },
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true, selected: true
    },
    {id: 'company|descr', label: 'Company', selected: true},
    {id: 'cost_center|descr', label: 'Cost Center', selected: true},
    {
      id: 'deleted_at',
      label: 'Deleted Date',
      condition: ['deleted'],
      type: 'date',
      unsearchable: true, selected: true
    }
  ];

  $scope.prNumber = null;
  $scope.prTitle = null;
  $scope.totalPriceFrom = null;
  $scope.totalPriceTo = null;
  $scope.itemDescription = null;
  $scope.itemTitle = null;
  $scope.company = null;
  $scope.costCenter = null;
  $scope.neededByDateFrom = null;
  $scope.neededByDateTo = null;
  $scope.poNumber = null;
  $scope.totalCostOfPrFrom = null;
  $scope.totalCostOfPrTo = null;
  $scope.budgetCode = null;
  $scope.approvedBy = null;
  $scope.prFullyApprovedDateFrom = null;
  $scope.prFullyApprovedDateTo = null;
  $scope.commodityCode = {};
  $scope.budgetTitle = null;
  $scope.waitingOn = null;
  $scope.prCreatedDateFrom = null;
  $scope.prCreatedDateTo = null;
  $scope.prStatus = {selected: $scope.prStatusOptions[0]};
  $scope.creator = null;
  $scope.createdOnBehalf = null;
  $scope.supplier = null;
  $scope.commodityKeyword = null;

  $scope.commoditySearching = false;
  $scope.commodityLevel = [4];
  $scope.datePickerTimezone = $rootScope.datePickerTimezone;
  $scope.datepickerOpened = {
    neededByDateFrom: false,
    neededByDateTo: false,
    prFullyApprovedDateFrom: false,
    prFullyApprovedDateTo: false,
    prCreatedDateFrom: false,
    prCreatedDateTo: false
  };
  var dateOptions = {
    formatYear: 'yy',
    startingDay: 1,
  };
  $scope.datePickerOptions = {
    neededByDateFrom: _.clone(dateOptions),
    neededByDateTo: _.clone(dateOptions),
    prFullyApprovedDateFrom: _.clone(dateOptions),
    prFullyApprovedDateTo: _.clone(dateOptions),
    prCreatedDateFrom: _.clone(dateOptions),
    prCreatedDateTo: _.clone(dateOptions)
  };
  $scope.closeTreeSearch = false;

  $scope.errors = {};
  var toastrErrorMessage = null;

  $scope.resetForm = resetForm;
  $scope.submitAdvSearch = submitAdvSearch;
  $scope.searchCommodityCode = searchCommodityCode;
  $scope.openChildCommodityCode = openChildCommodityCode;
  $scope.openDatePicker = openDatePicker;
  $scope.checkIfDisabled = checkIfDisabled;
  $scope.validateAmountRange = validateAmountRange;
  $scope.clearCommodityField = clearCommodityField;
  $scope.hasError = hasError;
  $scope.addSearchCriteria = addSearchCriteria;

    /**
   * function to reset the search form
   */
  function resetForm() {
    $scope.prNumber = null;
    $scope.prTitle = null;
    $scope.totalPriceFrom = null;
    $scope.totalPriceTo = null;
    $scope.itemDescription = null;
    $scope.itemTitle = null;
    $scope.company = null;
    $scope.costCenter = null;
    $scope.neededByDateFrom = null;
    $scope.neededByDateTo = null;
    $scope.poNumber = null;
    $scope.totalCostOfPrFrom = null;
    $scope.totalCostOfPrTo = null;
    $scope.budgetCode = null;
    $scope.approvedBy = null;
    $scope.prFullyApprovedDateFrom = null;
    $scope.prFullyApprovedDateTo = null;
    $scope.commodityCode = {};
    $scope.budgetTitle = null;
    $scope.waitingOn = null;
    $scope.prCreatedDateFrom = null;
    $scope.prCreatedDateTo = null;
    $scope.prStatus = {selected: $scope.prStatusOptions[0]};
    $scope.creator = null;
    $scope.createdOnBehalf = null;
    $scope.supplier = null;
    $scope.commodityKeyword = null;
    $scope.errors = {};
    $scope.dataList = [];
    metabuyerCache.remove(searchCacheKey);
  }

  /**
   * function to submit search form
   */
  function submitAdvSearch() {
    var searchParam = validateForm();

    if (!!searchParam) {
      $scope.embeddedParams = searchParam;

      /**
       * Setting the params to prepare for exporting
       */
      exportSearch.setParams(searchParam);

      advancedSearch.get(
        searchParam,
        function (resource) {
          $scope.dataList = resource.content.data;
          $scope.meta = resource.content.meta;
          $scope.emptyInput = false;
        },
        function (error) {
          toastr.error('Something went wrong');
          $scope.dataList = [];
        }
      );
    } else {
      toastr.error(toastrErrorMessage);
      toastrErrorMessage = null;
      $scope.emptyInput = true;
    }

    $('#show-search-form').removeClass('hidden');
    $('#hide-search-form').addClass('hidden');

  }

  /**
   * function to check if a specific field has error.
   * @param field field name
   * @returns {boolean}
   */
  function hasError(field) {
    return (field in $scope.errors);
  }

  /**
   * function to validate form input values
   * @returns {boolean|Object}
   */
  function validateForm() {

    /**
     * logic for range fields (total price, total cost of pr, and all date fields)
     * if `from` field is not empty, set `to` field to same value as `from` value
     * if `to` field is not empty, `from` field is mandatory.
     * if both are empty, skip validation
     *
     */

    // total price range
    if (!!$scope.totalPriceTo) {
      if (!!$scope.totalPriceFrom) {
        if ($scope.totalPriceFrom > $scope.totalPriceTo) {
          $scope.errors.totalPrice = 'Invalid Range';
        }
      } else {
        $scope.errors.totalPrice = '\'From\' field is mandatory';
      }
    } else if (!!$scope.totalPriceFrom) {
      $scope.totalPriceTo = $scope.totalPriceFrom;
    }

    // total cost of pr range
    if (!!$scope.totalCostOfPrTo) {
      if (!!$scope.totalCostOfPrFrom) {
        if ($scope.totalCostOfPrFrom > $scope.totalCostOfPrTo) {
          $scope.errors.totalCostOfPr = 'Invalid Range';
        }
      } else {
        $scope.errors.totalCostOfPr = '\'From\' field is mandatory';
      }
    } else if (!!$scope.totalCostOfPrFrom) {
      $scope.totalCostOfPrTo = $scope.totalCostOfPrFrom;
    }

    //needed by date range
    if (!!$scope.neededByDateTo) {
      if (!!$scope.neededByDateFrom) {
        if ($scope.neededByDateFrom > $scope.neededByDateTo) {
          $scope.errors.neededByDate = 'Invalid Range';
        }
      } else {
        $scope.errors.neededByDate = '\'From\' field is mandatory';
      }
    } else if (!!$scope.neededByDateFrom) {
      $scope.neededByDateTo = $scope.neededByDateFrom;
    }

    //pr fully approved date range
    if (!!$scope.prFullyApprovedDateTo) {
      if (!!$scope.prFullyApprovedDateFrom) {
        if ($scope.prFullyApprovedDateFrom > $scope.prFullyApprovedDateTo) {
          $scope.errors.prFullyApprovedDate = 'Invalid Range';
        }
      } else {
        $scope.errors.prFullyApprovedDate = '\'From\' field is mandatory';
      }
    } else if (!!$scope.prFullyApprovedDateFrom) {
      $scope.prFullyApprovedDateTo = $scope.prFullyApprovedDateFrom;
    }

    // pr created date range
    if (!!$scope.prCreatedDateTo) {
      if (!!$scope.prCreatedDateFrom) {
        if ($scope.prCreatedDateFrom > $scope.prCreatedDateTo) {
          $scope.errors.prCreatedDate = 'Invalid Range';
        }
      } else {
        $scope.errors.prCreatedDate = '\'From\' field is mandatory';
      }
    } else if (!!$scope.prCreatedDateFrom) {
      $scope.prCreatedDateTo = $scope.prCreatedDateFrom;
    }

    if (_.isEmpty($scope.errors)) {
      var searchData = {
        prNumber: $scope.prNumber,
        prTitle: $scope.prTitle,
        totalPriceFrom: $scope.totalPriceFrom,
        totalPriceTo: $scope.totalPriceTo,
        itemDescription: $scope.itemDescription,
        itemTitle: $scope.itemTitle,
        company: $scope.company,
        costCenter: $scope.costCenter,
        neededByDateFrom: $scope.neededByDateFrom,
        neededByDateTo: $scope.neededByDateTo,
        poNumber: $scope.poNumber,
        totalCostOfPrFrom: $scope.totalCostOfPrFrom,
        totalCostOfPrTo: $scope.totalCostOfPrTo,
        budgetCode: $scope.budgetCode,
        approvedBy: $scope.approvedBy,
        prFullyApprovedDateFrom: $scope.prFullyApprovedDateFrom,
        prFullyApprovedDateTo: $scope.prFullyApprovedDateTo,
        commodityCode: $scope.commodityCode,
        budgetTitle: $scope.budgetTitle,
        waitingOn: $scope.waitingOn,
        prCreatedDateFrom: $scope.prCreatedDateFrom,
        prCreatedDateTo: $scope.prCreatedDateTo,
        prStatus: $scope.prStatus.selected.key,
        creator: $scope.creator,
        createdOnBehalf: $scope.createdOnBehalf,
        supplier: $scope.supplier
      };
      return buildParam(searchData);
    } else {
      toastrErrorMessage = 'Invalid value in one or more fields';
      return false;
    }
  }

  /**
   * function to build criteria parameters
   * @param data object of form input values
   * @returns {boolean|Object}
   */
  function buildParam(data) {

    var params = {};

    if (!!data.prNumber) {
      params['criteria[0][reference]'] = data.prNumber;
    }
    if (!!data.prTitle) {
      params['criteria[0][title]'] = data.prTitle;
    }
    if (!!data.totalPriceFrom) {
      params['criteria[0][pr_items|total_amount][from]'] = data.totalPriceFrom;
      params['criteria[0][pr_items|total_amount][to]'] = data.totalPriceTo;
    }
    if (!!data.itemDescription) {
      params['criteria[0][pr_items|item|description]'] = data.itemDescription;
    }
    if (!!data.itemTitle) {
      params['criteria[0][pr_items|item|item_name]'] = data.itemTitle;
    }
    if (!!data.company) {
      params['criteria[0][company|descr]'] = data.company;
    }
    if (!!data.costCenter) {
      params['criteria[0][cost_center|descr]'] = data.costCenter;
    }
    if (!!data.neededByDateFrom) {
      params['criteria[0][needed_by_date][from]'] = moment(data.neededByDateFrom).valueOf();
      params['criteria[0][needed_by_date][to]'] = moment(data.neededByDateTo).endOf('day').valueOf();
    }
    if (!!data.poNumber) {
      params['criteria[0][pr_items|po_number]'] = data.poNumber;
    }
    if (!!data.totalCostOfPrFrom) {
      params['criteria[0][grand_total][from]'] = data.totalCostOfPrFrom;
      params['criteria[0][grand_total][to]'] = data.totalCostOfPrTo;
    }
    if (!!data.budgetCode) {
      params['criteria[0][budget|code]'] = data.budgetCode;
    }
    if (!!data.budgetTitle) {
      params['criteria[0][budget|descr]'] = data.budgetTitle;
    }
    if (!!data.approvedBy) {
      params['criteria[0][approvers_info|display_name]'] = data.approvedBy;
    }
    if (!!data.prFullyApprovedDateFrom) {
      params['criteria[0][approved_at][from]'] = moment(data.prFullyApprovedDateFrom).valueOf();
      params['criteria[0][approved_at][to]'] = moment(data.prFullyApprovedDateTo).endOf('day').valueOf();
    }
    if (_.keys(data.commodityCode).length > 0) {
      params['criteria[0][pr_items|commodity|code]'] = data.commodityCode.code;
    }
    if (!!data.waitingOn) {
      params['criteria[0][waiting_on_info|display_name]'] = data.waitingOn;
    }
    if (!!data.prCreatedDateFrom ) {
      params['criteria[0][created_at][from]'] = moment(data.prCreatedDateFrom).valueOf();
      params['criteria[0][created_at][to]'] = moment(data.prCreatedDateTo).endOf('day').valueOf();
    }
    if (data.prStatus !== 'all') {
      params['criteria[0][status]'] = data.prStatus;
    }
    if (!!data.creator) {
      params['criteria[0][creator_info|display_name]'] = data.creator;
    }
    if (!!data.createdOnBehalf) {
      params['criteria[0][requestor|display_name]'] = data.createdOnBehalf;
    }
    if (!!data.supplier) {
      params['criteria[0][pr_items|supplier|descr]'] = data.supplier;
    }

    // if status 'all' is selected, the form cannot be empty. so the criteria for 'all' status
    // will only be added if `params` is not empty.
    if (_.isEmpty(params)) {
      toastrErrorMessage = 'Fill in at least one field';
      return false;
    } else {
      if (data.prStatus === 'all') {
        params['criteria[0][status]'] = data.prStatus;
      }
      params['module'] = 'PR';
      params['offset'] = 50;
      params['criteria[0][$operator]'] = 'and';
      return params;
    }
  }

  /**
   * function to search commodity code
   * @param keyword
   */
  function searchCommodityCode(keyword) {
    if (!!keyword && keyword.length > 1) {
      $scope.commoditySearching = true;
      $scope.commodityCodes = [];
      searchModule.get(
        {
          module: 'commodity',
          'criteria[0][code]': keyword,
          'criteria[0][descr]': keyword,
          'criteria[0][$operator]': 'or',
          'criteria[1][is_active]': 1,
          order: 1,
          order_by: 'code',
          offset: 0
        }, function (resource) {
          $scope.commoditySearching = false;
          if (!!resource && !!resource.content && !!resource.content.data)
            $scope.commodityCodes = resource.content.data;
        }, function () {
          $scope.commoditySearching = false;
          $scope.commodityCodes = [];
        }
      );
    }
  }

  /**
   * clear commodity field
   */
  function clearCommodityField() {
    if ($scope.commodityKeyword === '') {
      $scope.commodityCode = {};
      $scope.closeTreeSearch = true;
    }
  }

  /**
   * Get children data for commodity Code
   * @param cc
   */
  function openChildCommodityCode(cc) {
    listChild.get({
      code: cc
    }, function (resource) {
      if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data.length > 0)
        $scope.commodityCodes = resource.content.data;
      else
        toastr.error('There are no child on this commodity code');
    }, function () {
      toastr.error('Failed to get Commodity Codes data');
    })
  }

  /**
   * check if a field should be disabled
   * @param field
   * @returns {boolean}
   */
  function checkIfDisabled(field) {
    if (!!$scope.prStatus.selected) {
      if (field === 'poNumber' || field === 'prApprovedDate') {
        if ($scope.prStatus.selected.key === 'all' ||
          $scope.prStatus.selected.key === 'approved'
        ) {
          return false;
        } else {
          // clear related fields if disabled
          $scope.poNumber = null;
          $scope.prFullyApprovedDateFrom = null;
          $scope.prFullyApprovedDateTo = null;
          return true;
        }
      }
    }
  }

  /**
   * function to validate amount range fields, and update/remove error message
   * @param field
   */
  function validateAmountRange(field) {
    if (field === 'totalPrice') {
      if (!!$scope.totalPriceFrom && !!$scope.totalPriceTo && ($scope.totalPriceFrom > $scope.totalPriceTo)) {
        $scope.errors.totalPrice = 'Invalid Range';
      } else {
        if ('totalPrice' in $scope.errors) {
          delete $scope.errors.totalPrice;
        }
      }
    } else if (field === 'totalCostOfPr') {
      if (!!$scope.totalCostOfPrFrom && !!$scope.totalCostOfPrTo && ($scope.totalCostOfPrFrom > $scope.totalCostOfPrTo)) {
        $scope.errors.totalCostOfPr = 'Invalid Range';
      } else {
        if ('totalCostOfPr' in $scope.errors) {
          delete $scope.errors.totalCostOfPr
        }
      }
    }
  }

  /**
   * function to set datepicker fields' options.
   * set minDate/maxDate if the related date range field is already set and remove error message
   *
   * @param $event
   * @param field
   */
  function openDatePicker($event, field) {
    $event.preventDefault();
    $event.stopPropagation();

    if (field === 'neededByDateFrom') {
      $scope.datePickerOptions.neededByDateFrom.maxDate = $scope.neededByDateTo || null;
      $scope.datepickerOpened.neededByDateFrom = true;
      delete $scope.errors.neededByDate;
    } else if (field === 'neededByDateTo') {
      $scope.datePickerOptions.neededByDateTo.minDate = $scope.neededByDateFrom || null;
      $scope.datepickerOpened.neededByDateTo = true;
      delete $scope.errors.neededByDate;
    } else if (field === 'prFullyApprovedDateFrom') {
      $scope.datePickerOptions.prFullyApprovedDateFrom.maxDate = $scope.prFullyApprovedDateTo || null;
      $scope.datepickerOpened.prFullyApprovedDateFrom = true;
      delete $scope.errors.prFullyApprovedDate;
    } else if (field === 'prFullyApprovedDateTo') {
      $scope.datePickerOptions.prFullyApprovedDateTo.minDate = $scope.prFullyApprovedDateFrom || null;
      $scope.datepickerOpened.prFullyApprovedDateTo = true;
      delete $scope.errors.prFullyApprovedDate;
    } else if (field === 'prCreatedDateFrom') {
      $scope.datePickerOptions.prCreatedDateFrom.maxDate = $scope.prCreatedDateTo || null;
      $scope.datepickerOpened.prCreatedDateFrom = true;
      delete $scope.errors.prCreatedDate;
    } else if (field === 'prCreatedDateTo') {
      $scope.datePickerOptions.prCreatedDateTo.minDate = $scope.prCreatedDateFrom || null;
      $scope.datepickerOpened.prCreatedDateTo = true;
      delete $scope.errors.prCreatedDate;
    }
  }

  function addSearchCriteria(key, value){

      var tempSearchCriteriaCache= metabuyerCache.get(searchCacheKey);

      if(!!tempSearchCriteriaCache)
        searchFields = _.cloneDeep(tempSearchCriteriaCache);

      searchFields[key] = value;
      metabuyerCache.put(searchCacheKey, searchFields);

  }

  function initialize() {
    // initialize pagination parameters
    $scope.dataList = [];
    $scope.embeddedParams = [];
    $scope.meta = {};
    $scope.searchModule = advancedSearch;

    var searchCriteriaCache = metabuyerCache.get(searchCacheKey);
    if(!!searchCriteriaCache && !_.isEmpty(searchCriteriaCache)){
        retainSearchCriteria();
    }
  }

  function retainSearchCriteria(){

    var searchCriteriaCache = metabuyerCache.get(searchCacheKey);

    $scope.prNumber = searchCriteriaCache.prNumber;
    $scope.poNumber = searchCriteriaCache.poNumber;
    $scope.prTitle = searchCriteriaCache.prTitle;
    $scope.prStatus.selected = searchCriteriaCache.prStatus;
    $scope.totalPriceFrom = searchCriteriaCache.totalPriceFrom;
    $scope.totalPriceTo = searchCriteriaCache.totalPriceTo;
    $scope.totalCostOfPrFrom = searchCriteriaCache.totalCostOfPrFrom;
    $scope.totalCostOfPrTo = searchCriteriaCache.totalCostOfPrTo;
    $scope.company = searchCriteriaCache.company;
    $scope.costCenter = searchCriteriaCache.costCenter;
    $scope.creator = searchCriteriaCache.creator;
    $scope.createdOnBehalf = searchCriteriaCache.createdOnBehalf;
    $scope.itemTitle = searchCriteriaCache.itemTitle;
    $scope.itemDescription = searchCriteriaCache.itemDescription;
    $scope.budgetCode = searchCriteriaCache.budgetCode;
    $scope.budgetTitle = searchCriteriaCache.budgetTitle;
    $scope.commodityKeyword = searchCriteriaCache.commodityKeyword;
    $scope.approvedBy = searchCriteriaCache.approvedBy;
    $scope.waitingOn = searchCriteriaCache.waitingOn;
    $scope.supplier = searchCriteriaCache.supplier;
    $scope.neededByDateFrom = searchCriteriaCache.neededByDateFrom;
    $scope.neededByDateTo = searchCriteriaCache.neededByDateTo;
    $scope.prFullyApprovedDateFrom = searchCriteriaCache.prFullyApprovedDateFrom;
    $scope.prFullyApprovedDateTo = searchCriteriaCache.prFullyApprovedDateTo;
    $scope.prCreatedDateFrom = searchCriteriaCache.prCreatedDateFrom;
    $scope.prCreatedDateTo = searchCriteriaCache.prCreatedDateTo;

  }


  initialize();
}

purchaseRequisitionAdvancedSearchCtrl.$inject = [
  '$scope', 'searchModule', 'listChild', 'toastr', '$rootScope', 'advancedSearch', 'exportSearch', 'metabuyerCache'
];

angular.module('metabuyer')
  .controller('purchaseRequisitionAdvancedSearchCtrl', purchaseRequisitionAdvancedSearchCtrl);
