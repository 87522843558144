'use strict';

/**
 * Access Control service to Allow or block accessing certain states based on the rules loaded in metabuyerAppLoader
 *
 * @param metabuyerAppsLoader
 * @param globalFunc
 * @returns {{}}
 */
function metabuyerAppsAccessControl(metabuyerAppsLoader, globalFunc, $rootScope) {

  //Start the options and default config here
  var generateRedirectState = '';
  var IERedirectState = '';

  return {
    isBlockedState: function (stateName) {
      var blockingRule = globalFunc.findInArray(this.getUserRules(), 'stateName', stateName);

      if (!!blockingRule) {

        if (blockingRule.UserBlock)
          return { message: 'This module is not available for ' + $rootScope.currentUser.display_name,
            redirect: generateRedirectState };

        if (blockingRule.IEBlock && globalFunc.detectIEBrowser())
          return { message: 'This module is not available for IE browsers', redirect: IERedirectState };

        return false;
      }
      return false;
    },
    getUserRules: function () {
      return metabuyerAppsLoader.getRules();
    }
  }
}

metabuyerAppsAccessControl.$inject = ['metabuyerAppsLoader', 'globalFunc', '$rootScope'];


angular
  .module('metabuyer')
  .factory('metabuyerAppsAccessControl', metabuyerAppsAccessControl);
