'use strict';

function MinionDashboardCtrl($scope, $state, $rootScope, globalFunc, moment, checkMinionConnection) {
  $scope.checkConnection = checkConnection;

  function checkConnection() {
    getMinionStatus();
  }

  function getMinionStatus() {
    $scope.minion = {
      connectionStatus: 'reloading...',
      lastCheck: null
    };

    // Update minion service here
    checkMinionConnection.get(
      {},
      function(response) {
        $scope.minion.connectionStatus = response.message;
      },
      function(response) {
        $scope.minion.connectionStatus = 'Unable to connect';
      }
    );

    $scope.minion.lastCheck = moment();
  }

  function initialize() {
    getMinionStatus();
  }

  initialize();
}

MinionDashboardCtrl.$inject = [
  '$scope', '$state', '$rootScope', 'globalFunc', 'moment', 'checkMinionConnection'
];

angular.module('metabuyer')
  .controller('MinionDashboardCtrl', MinionDashboardCtrl);
