'use strict';

function tenderHeaderCtrl(
  $scope, globalFunc, $rootScope, $uibModal, $state, HighlightId, pathConstants, $filter, tenderFunction, toastr
) {
  $scope.currentUser = $rootScope.currentUser || { _id: null };
  $scope.headerDatePicker = {
    startDate: false,
    endDate: false,
    submissionDate: false
  };
  $scope.autoSaveHeader = autoSaveHeader;
  $scope.openDatePickerHeader = openDatePickerHeader;

  if (!!$scope.tender.detail.validity_start_date) {
    $scope.tender.detail.validity_start_date = new Date($scope.tender.detail.validity_start_date);
  }

  if (!!$scope.tender.detail.validity_end_date) {
    $scope.tender.detail.validity_end_date = new Date($scope.tender.detail.validity_end_date);
  }

  if (!!$scope.tender.detail.submission_date) {
    $scope.tender.detail.submission_date = new Date($scope.tender.detail.submission_date);
  }

  $scope.tender.approval_date = tenderFunction.getApprovalDate();
  $scope.tender.total_amount = tenderFunction.getTotalAmount();

  $scope.showHideCond = function(fieldName) {
    var fieldName = (fieldName === undefined) ? null : fieldName;

    var canEdit = true;

    if ($scope.tender.is_vo && $scope.tender.status === 'draft' && tenderFunction.hasRoleRequester()) {

      canEdit = false;

      var fields = [
        'description',
        'validity_start_date',
        'validity_end_date',
        'category',
        'submission_date',
        'type',
        'blocked_amount',
        'purpose',
        'non_purchase_amount',
        'remarks',
        'currency',
        'catalog_required',
        'amount_before_tax',
        'allow_exceed_tender_amount',
        'contingency_sum_vo',
      ];

      if (['type'].includes(fieldName)) {
        canEdit = true
      }

      var newWorksField = _.filter(fields, function(field) {
        return !['currency', 'category'].includes(field)
      });

      if (newWorksField.includes(fieldName) && ['NEW_WORKS'].includes($scope.tender.detail.type.key)) {
        canEdit = true;
      }

      var additionalSumField = _.filter(fields, function(field) {
        return !['currency', 'category'].includes(field)
      });

      if (additionalSumField.includes(fieldName) && ['ADDITIONAL_SUM'].includes($scope.tender.detail.type.key)) {
        canEdit = true;
      }

      if (['validity_start_date', 'validity_end_date', 'submission_date'].includes(fieldName) && ['DATE_CHANGE'].includes($scope.tender.detail.type.key)) {
        canEdit = true;
      }

    } else {
      canEdit = $scope.editableStatus;

      if (['type'].includes(fieldName)) {
        canEdit = false
      }
    }

    return canEdit;
  };

  $scope.currencyShowHideCond = function() {

    var canEdit = true;

    if ($scope.tender.is_vo && $scope.tender.status === 'draft') {

      if (['NEW_WORKS', 'CHANGE_VENDOR', 'DATE_CHANGE'].includes($scope.tender.detail.type.key)) {
        canEdit = false;
      }

    } else {
      canEdit = $scope.editableStatus;
    }

    return canEdit;
  };

  $scope.checkValidityErr = function(startDate, endDate) {
    var curDate = new Date();

    if (startDate === null || startDate === undefined || startDate === "" || startDate.lenght === 0) {
      toastr.error('Please select validity start date');
      $scope.tender.detail.validity_end_date = '';
      return false;
    }

    if (new Date(startDate) > new Date(endDate)) {
      toastr.error('Validity end date should be greater than start date');
      return false;
    }

    var date = moment(endDate).format('DD/MM/YYYY');

    if (date !== 'Invalid date') {
      autoSaveHeader('detail.validity_end_date', date)
    }

  };

  function autoSaveHeader(fieldName, fieldValue) {

    if (fieldName === 'detail.validity_start_date' || fieldName === 'detail.validity_end_date') {
      var fieldValue = moment(fieldValue).format('DD/MM/YYYY');
    }

    if (fieldName === 'detail.type') {
      var fieldValue = fieldValue.key;
    }

    if (fieldName === 'detail.currency') {
      var fieldValue = fieldValue.key;
    }

    if (fieldName === 'detail.category') {
      var fieldValue = fieldValue.key;
    }

    if (fieldName === 'detail.submission_date') {
      var fieldValue = moment(fieldValue).format('DD/MM/YYYY');

      if (fieldValue === 'Invalid date') {
        fieldValue = '';
      }
    }

    var payload = tenderFunction.convertDotToObj(fieldName, fieldValue);

    tenderFunction.saveApi(payload, '').then(function(response) {
      if (response.http_code === 200) {
        if (fieldName === 'detail.category') {
          tenderFunction.setCategory(response.content.data.detail.category);
          tenderFunction.updateTotalAmount(response.content.data.total_amount);
          tenderFunction.setAllowExceedAmount(response.content.data.detail.allow_exceed_tender_amount);
          tenderFunction.setAmountBeforeTax(response.content.data.detail.amount_before_tax);
          tenderFunction.resetUnitPriceAmountField(response.content.data.detail);
          $rootScope.$broadcast('updateTenderComponents', {
            data: response.content.data.components
          });
        }

        if (fieldName === 'detail.currency') {
          tenderFunction.updateCurrency(response.content.data.detail.currency);
          $rootScope.$broadcast('updateNumericPattern', {
            data: response.content.data.allow_decimal
          });

          $rootScope.$broadcast('updateTenderComponents', {
            data: response.content.data.components
          });

          tenderFunction.setAllowDecimal(response.content.data);
        }

        if (fieldName === 'detail.type') {
          tenderFunction.refreshDataChangeType(response.content.data)

          $rootScope.$broadcast('updateTenderGroupsByComponents', {
            data: response.content.data.groups
          });

          $rootScope.$broadcast('updateTenderComponents', {
            data: response.content.data.components
          });

          $rootScope.$broadcast('updateTenderApprovers', {
            data: response.content.data.approvers
          });

          tenderFunction.updateApprovalDate(response.content.data.approval_date);

          $rootScope.$broadcast('updateTenderSuppliers', {
            data: response.content.data.suppliers
          });

          tenderFunction.updateCumulativeContingencySumVOAmount(response.content.data.cumulative_vo_contingency_sum);
          tenderFunction.updateCumulativeVOAmount(response.content.data.cumulative_vo_amount);
          tenderFunction.updateRevisedTotalAmount(response.content.data.revised_total_amount);
        }

        if (fieldName === 'detail.contingency_sum_vo') {
          tenderFunction.updateCumulativeContingencySumVOAmount(response.content.data.cumulative_vo_contingency_sum);
        }
      }
    })

  }

  function openDatePickerHeader($event, which) {
    $event.preventDefault();
    $event.stopPropagation();
    Object.keys($scope.headerDatePicker).forEach(function(key) {
      $scope.headerDatePicker[key] = false;
    });
    $scope.headerDatePicker[which] = true;
  }

  function initialize() {
    $scope.datePickerTimezone = $rootScope.datePickerTimezone;
    _.forEach($scope.initialData.types, function(type, index) {

      if ($scope.tender.is_vo && type.key === 'ADDITIONAL_SUM' && $scope.tender.detail.category !== undefined && $scope.tender.detail.category.key === 'UNIT_PRICE') {
        delete $scope.initialData.types[index]
      }
    });

  }

  $scope.$watch('tender.detail.validity_end_date', function(newVal, oldVal) {
    var startDate = new Date($scope.tender.detail.validity_start_date);

    if (new Date(startDate) > new Date(newVal)) {
      $scope.tender.detail.validity_end_date = oldVal;
    }

  }, true)

  $scope.$watch('tender.detail.validity_start_date', function(newVal, oldVal) {

    if (new Date(newVal).getTime() > new Date($scope.tender.detail.validity_end_date).getTime()) {
      $scope.tender.detail.validity_start_date = oldVal;
    }

  }, true)

  initialize();
}

tenderHeaderCtrl.$inject = [
  '$scope', 'globalFunc', '$rootScope', '$uibModal', '$state', 'HighlightId', 'pathConstants', '$filter', 'tenderFunction', 'toastr'
];

angular
  .module('metabuyer')
  .controller('tenderHeaderCtrl', tenderHeaderCtrl);
