'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function ($stateProvider) {
    $stateProvider
      .state('main.user.analytics', {
        url: '/dashboard',
        controller: 'AnalyticsCtrl',
        templateUrl: 'app/analytics/analytics.html',
        resolve: {
          accessRights: function (profile, globalFunc, $state) {
            if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Analyst')) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.analytics.report', {
        url: '/report',
        controller: 'AnalyticsReportCtrl',
        templateUrl: 'app/analytics/report/report.html',
        resolve: {
          $title: function () {
            return 'Dashboard Reporting';
          },
          analyticsParam: function ($rootScope, $q, $state, $filter, analyticsMetrix) {
            var deferred = $q.defer();
            analyticsMetrix.get(
              {},
              function (resource) {
                if (!!resource.content && !!resource.content.data) {
                  deferred.resolve(resource.content.data);
                }
              }
            );
            return deferred.promise;
          }
        }
      });
  }]);
