'use strict';

angular.module('metabuyer.services.activities' , ['ngResource'])
  .factory('singleActivity',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.activities.single,
        {
          uuid: '@uuid'
        },
        {
          'get': {method: 'GET'},
          'put': {method: 'PUT'}
        }
      );
    });

