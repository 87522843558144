'use strict';

angular.module('metabuyer.services.policyDisclaimer', ['ngResource'])
  .factory('policyDisclaimerResource',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.tenant.policyDisclaimer,
        {},
        {
          'get': {method: 'GET'},
          'put': {method: 'PUT'}
        }
      );
    })
