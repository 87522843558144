'use strict';

function editIntegrationInfoV2Modal (
  $scope, toastr, $uibModalInstance, prV2Function, purchaseRequisitionsV2Services, globalFunc, $state
) {
  $scope.erpOrderTypes = prV2Function.getErpOrderTypesList();
  $scope.erpCostCenters = prV2Function.getErpCostCentersList();
  $scope.integrationInfo = {
    erp_order_type: null,
    erp_cost_center: null,
    is_integrated: null
  };

  $scope.cancel = cancel;
  $scope.update = update;

  function cancel() {
    $uibModalInstance.close();
  }

  function update() {
    var param = {
      erp_cost_center_id: !!$scope.integrationInfo.erp_cost_center && !!$scope.integrationInfo.erp_cost_center._id ?
        $scope.integrationInfo.erp_cost_center._id : null,
      erp_order_type_id: !!$scope.integrationInfo.erp_order_type && !!$scope.integrationInfo.erp_order_type._id ?
        $scope.integrationInfo.erp_order_type._id : null,
      is_integrated: !!$scope.integrationInfo.is_integrated
    };

      purchaseRequisitionsV2Services.updateIntegrationInfo(
        {
          requisition_id: prV2Function.getPRData()._id
        },
        param,
        function (response) {
          prV2Function.setPRData(response.content.data);
          toastr.success('Updated successfully.');
          $uibModalInstance.close();
          $state.reload();
        },
        function (error) {
          globalFunc.objectErrorMessage(error)
        }
      );
  }

  function initialize() {
    var pr = prV2Function.getPRData();

    $scope.integrationInfo = {
      erp_order_type: pr.erp_order_type,
      erp_cost_center: pr.erp_cost_center,
      is_integrated: !!pr.pos && !!pr.pos[0] && !!pr.pos[0].integration_type
    };

    $scope.showIntegratedOption = !!pr.pos && !_.isEmpty(pr.pos);
  }

  initialize()
}

angular
  .module('metabuyer')
  .controller('editIntegrationInfoV2Modal', editIntegrationInfoV2Modal);

editIntegrationInfoV2Modal.$inject = [
  '$scope', 'toastr', '$uibModalInstance', 'prV2Function', 'purchaseRequisitionsV2Services',
  'globalFunc', '$state'
];
