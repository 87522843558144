'use strict';
/**
 * @name MasterDataManageCommodityCodeCtrl
 * @desc Controller for master data commodity code
 */
function masterDataCommodityCodeCtrl(
  $scope, $state, $q, toastr, dataList, commodityList, $uibModal, pathConstants, $location, $window, $stateParams,
  HighlightId, $rootScope) {

  $scope.isV2Enabled = !!$rootScope.isV2Enabled;
  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.commodityService = commodityList;

  $scope.title = 'Segments';

  $scope.addCommodityModal = addCommodityModal;
  $scope.editCommodityModal = editCommodityModal;
  $scope.importCommodity = importCommodity;
  $scope.getActiveStatusName = getActiveStatusName;
  $scope.importLink = pathConstants.apiUrls.commodity.import;
  $scope.search = search;
  $scope.backToParent = backToParent;
  $scope.backToSegments = backToSegments;
  $scope.showParentButton = false;
  $scope.showSegmentButton = false;
  var rootCommodityCode = 0;

  $scope.showColumn = showColumn;
  $scope.params = { code: rootCommodityCode };

  $scope.columns =[
        {id: 'code', label: 'Code'},
        {id: 'descr', label: 'Description'},
        {id: 'updated_at', label: 'Updated At'},
        {id: 'updater.display_name', label: 'Updated By'},
        {id: 'created_at', label: 'Created At'},
        {id: 'creator.display_name', label: 'Created By'},
        {id: 'is_active', label: 'Status'}
      ];

  $scope.storageKey = 'master-data-commodity-management-selected-columns';

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }


  function backToParent() {
    if (!!$stateParams.parentUniqueName) {
      HighlightId.setId($stateParams.parentUniqueName);
    }
    $window.history.back();
  }

  function backToSegments() {
    $location.search({parentUniqueName: 'All'});
  }

  /**
   * returns the is_active name
   * @param code
   * @returns {*}
   */
  function getActiveStatusName(code){
    /**
     DEACTIVATED = 0; 
     ACTIVE = 1; 
     DELETED = 2; 
     DISABLED = 3; 
     */
    if (code === 0 || code === 'DEACTIVATED') {
      return 'Deactivated'
    }
    if (code === 1 || code === 'ACTIVE') {
      return 'Active'
    }
    if (code === 2 || code === 'DELETED') {
      return 'Deleted'
    }
    if (code === 3 || code === 'DISABLED') {
      return 'Disabled'
    }
    return 'N/A';
  }

  function search(query) {
    $location.search({query: query});
  }

  function importCommodity() {
    var modalInstance = $uibModal.open({
      templateUrl: 'app/masterDataManagement/Import-Template.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        importLink: function () {
          return $scope.importLink;
        },
        title: function () {
          return 'Commodity';
        }
      },
      controller: function ($scope, $uibModalInstance, importLink, title) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        $scope.importLink = importLink;
        $scope.title = title;
      }
    });
  }

  function addCommodityModal(){
    $scope.params = { code: rootCommodityCode };
    $uibModal.open({
      templateUrl: 'app/masterDataManagement/commodityCode/add/addCommodityTemplate.html',
        backdrop: 'static',
        keyboard: false,
      controller: 'addCommodityCtrl',
      scope: $scope,
      resolve:{
        segmentList: function ($q, commodityList) {
          var deferred = $q.defer();
          commodityList.get(
            {
              code: '00',
              offset: 0
            },
            function (resource) {
              deferred.resolve(resource.content);
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        }
      }
    })
  }

  function editCommodityModal(data, index){
    var modalInstance = $uibModal.open({
      templateUrl: 'app/masterDataManagement/commodityCode/details/details.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'commodityDetailsCtrl',
      scope: $scope,
      size: 'lg',
      resolve:{
        commodityDetails: function ($q, singleCommodity) {
          var deferred = $q.defer();
          singleCommodity.get(
            {
              id: data._id
            },
            function (resource) {
              deferred.resolve(resource.content);
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        },
        segmentList : function($q, commodityList){
          var deferred = $q.defer();
          commodityList.get(
            {
              code: '00',
              //TODO: have the backend return ALL data (Ahmed Saleh, 22/7/2016)
              offset: 1000
            },
            function (resource) {
              deferred.resolve(resource.content);
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        },
        dataList: function() {
          return $scope.dataList;
        }
      }
    });

    modalInstance.result.then(function (newValue) {
      if(!!newValue){
        _.forEach($scope.dataList, function(dataList){
          if(dataList._id === newValue._id){
            dataList.code = newValue.code;
            dataList.descr = newValue.descr;
            dataList.is_active = newValue.is_active;
            dataList.updater[0].display_name = newValue.updated_by;
            dataList.updated_at = newValue.updated_at;
          }
        });
      }
    }, function () {
    });
  }

  $scope.viewChild = function(data, index){
    $scope.params = { code: data.unique_name };
    if(data.unique_name.length < 8) {
      $location.search({parentUniqueName: data.unique_name});
    }
    else{
      editCommodityModal(data, index);
    }
  };

  function initialize(){
    if($stateParams.parentUniqueName !== 'All'){
      $scope.showParentButton = true;
      $scope.showSegmentButton = true;
    }
    else{
      $scope.showParentButton = false;
      $scope.showSegmentButton = false;
    }

    $scope.highlightId = HighlightId.getId();
    if (!!$scope.highlightId) {
      HighlightId.setId(null);
    }

    if (!$scope.isV2Enabled) {
      var column = {id: 'descr_chinese', label: 'Description (CN)'};
      $scope.columns.splice(2, 0, column);
    }
  }

  initialize();

}

masterDataCommodityCodeCtrl.$inject = [
  '$scope', '$state', '$q', 'toastr', 'dataList', 'commodityList', '$uibModal', 'pathConstants', '$location', '$window',
  '$stateParams', 'HighlightId', '$rootScope'];

angular
  .module('metabuyer')
  .controller('masterDataCommodityCodeCtrl', masterDataCommodityCodeCtrl);
