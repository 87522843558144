'use strict';

angular.module('metabuyer.services.delegation', ['ngResource'])
  .factory('massDelegate',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.delegation.massDelegate,
        {
          userId: '@userId'
        },
        {
          'post': { method: 'POST' }
        }
      );
    }
  )
  .factory('delegatedToAUser',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.delegation.delegatedToAUser,
        {
          userId: '@userId'
        },
        {
          'get': { method: 'GET' }
        }
      );
    }
  )
  .factory('delegations',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.delegation.list,
        {
          delegationId: '@delegationId'
        },
        {
          'get': { method: 'GET' },
          'delete': { method: 'DELETE'}
        }
      );
    }
  )
  .factory('massRemoveDelegation',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.delegation.list,
        {},
        {
          'delete': { method: 'DELETE'}
        }
      );
    }
  );
