'use strict';


/**
 * //TODO: document
 * @param $scope
 * @param $uibModal
 * @param $element
 * @param searchModule
 * @param metabuyerCache
 * @param $q
 */
function nonCatalogItemDirectiveCtrl($scope, $uibModal, $element, searchModule, metabuyerCache, $q, Currencies, toastr) {

  function panel() {
    $uibModal.open({
      templateUrl: 'components/purchaseRequisitionComponents/nonCatalog/nonCatalogItemTemplate.html',
      controller: catalogNonCatalogItemCtrl,
      backdrop :'static',
      keyboard: false,
      size: 'lg',
      resolve: {
        paymentTermsList: function () {
          return loadData({
            module: 'payment-term',
            'criteria[is_active]': 1,
            offset: 0
          }, 'payment-term');
        },
        //TODO: remove this, the data is already stored in the local storage called in the main PR controller
        //TODO: use storage instead (Ahmed Saleh, 31/7/2018)
        taxesList: function () {
          return loadData({
            module: 'tax',
            'criteria[is_active]': 1,
            offset: 0
          }, 'taxes');
        },
        item: function() {
          return $scope.item || {};
        },
        currencies: function ($q, Currencies) {
          var deferred = $q.defer();
          Currencies.get(function (resource) {
              deferred.resolve(resource.content);
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        },
        UOMs: function($q, prFunction, uomList) {
          var deferred = $q.defer();
          var localStorageTaxes = JSON.parse(window.localStorage.getItem('UOMs'));
          if (!localStorageTaxes) {
            uomList.get(
              {},
              function (resource) {
                if (!!resource.content && !!resource.content.data) {
                  window.localStorage.setItem('UOMs', JSON.stringify(resource.content.data));
                  prFunction.setUOMData(resource.content.data);
                  deferred.resolve(resource.content.data);
                }
              }
            );
          }
          else {
            prFunction.setUOMData(localStorageTaxes);
            deferred.resolve(localStorageTaxes);
          }
          return deferred.promise;
        }
      }
    });
  }

  $element.bind('click', function () {
    if ($scope.overitemlimit) {
      toastr.error("PR line item limit reached. Only " + $scope.itemlimit + " line items allowed per PR");
    } else {
      panel();
    }
  });


  function loadData(params, cacheKey) {

    var deferred = $q.defer();

    if (!!metabuyerCache.get(cacheKey)) {
      deferred.resolve(metabuyerCache.get(cacheKey));
    } else {
      searchModule.get(
        params
        ,
        function (resource) {
          if (!!resource.content) {
            metabuyerCache.put(cacheKey, resource.content.data);
            deferred.resolve(metabuyerCache.get(cacheKey));
          } else {
            //TODO: handle the error
            deferred.resolve([]);
          }

        },
        function (error) {
          deferred.resolve([]);
          //TODO: handle the error
        });
    }
    return deferred.promise;
  }


}

nonCatalogItemDirectiveCtrl.$inject = ['$scope', '$uibModal', '$element', 'searchModule', 'metabuyerCache', '$q', 'Currencies', 'toastr'];

angular
  .module('metabuyer')
  .directive('nonCatalogItem',function() {
    return {
      restrict: 'A',
      scope: {
        item: '=?',
        overitemlimit: '=?',
        itemlimit: '=?'
      },
      controller: 'nonCatalogItemDirectiveCtrl'
      }
  })
  .controller('nonCatalogItemDirectiveCtrl', nonCatalogItemDirectiveCtrl);
