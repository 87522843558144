'use strict';

angular.module('metabuyer')
  .controller('ReopenPOCtrl', function ($scope, $uibModalInstance, Orders, toastr, HighlightId, $state, globalFunc) {
    $scope.commentText = "";
    $scope.cancel = cancel;
    $scope.prepareReopenPurchaseOrder = prepareReopenPurchaseOrder;

    //cancel action
    function cancel(){
      $uibModalInstance.close();
    }

    /**
     * Prepare reopen purchase order with confirmations
     */
    function prepareReopenPurchaseOrder() {
      swal({
        title: 'Confirm reopen PO?',
        text: 'PO will be moved from Expired to Awaiting Delivery.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true
      }, function (isConfirm) {
        if (!!isConfirm) {
          reopenPurchaseOrder();
        }
      });
    }

    /**
     * Reopen purchase order
     */
    function reopenPurchaseOrder(){

      $uibModalInstance.close();

      Orders.setPOStatus(
        {
          id: $scope.po._id
        },
        {
          status: 5,
          comment: $scope.commentText
        }
      ).$promise.then(
        function (resource) {
          toastr.success('PO reopened successfully.');

          if (!!resource.content && !!resource.content.data) {
            HighlightId.setId(resource.content.data._id);
          }

          $state.go('main.user.orders.manage', {status: 'AWAITING_DELIVERY'});
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        });
    }
  });
