'use strict';
//TODO: remove the PR action directives and migrate to action-modal instead (Ahmed Saleh, 3/2/2017)
angular
  .module('metabuyer')
  .directive('submitPr',function($uibModal) {
    return{
      restrict: 'A',
      scope: true,
      link: function(scope, element, attrs) {

        var panel = function () {
          var modalInstance = $uibModal.open({
            templateUrl: 'components/submitPR/submitPRTemplate.html',
            backdrop: 'static',
            keyboard: false,
            controller: 'SubmitPRCtrl',
            scope: scope,
            size: "lg",
            resolve: {
              approvals: function($q, $state, $stateParams, Approvals){

                var deferred = $q.defer();

                Approvals.getReviewApproval(
                  {
                    id: $stateParams.id
                  },
                  function(resource){
                    if(!!resource && !!resource.content && !!resource.content.data){
                      deferred.resolve(resource.content.data);
                    } else {
                      deferred.resolve([]);
                    }
                  },
                  function (error){
                    deferred.resolve([]);
                  });

                return deferred.promise;
              }
            }
          });
        };

        function submitPrAction(){
          swal({title: "Submit requisition?",
              showCancelButton: true,
              confirmButtonColor: "#1ab394",
              confirmButtonText: "Yes",
              cancelButtonText: "No",
              closeOnConfirm: true,
              closeOnCancel: true
            },
            function(is_confirm){
              if (is_confirm) {
                scope.submitPR();
              }
            });
        }
        //loading the panel
        scope.showSubmitPR = function () {

          scope.onSubmitPR();

          if (scope.validatePR() == false) {

            return;
          }

          if(scope.checkPartialItem()){
            swal({
              title: "You have partial item",
              text: "Are you sure filled the correct data?",
              showCancelButton: true,
              confirmButtonColor: "#1ab394",
              confirmButtonText: "Yes",
              cancelButtonText: "No",
              closeOnConfirm: true,
              closeOnCancel: true
            }, function(is_confirm){
              if (is_confirm) {
                submitPrAction();
              }
            });
          } else {
            submitPrAction();
          }

          //panel();
        };
      }
    };
  });
