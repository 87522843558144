'use strict';

function SupplierWebGrnCtrl($scope, $state, $rootScope, toastr, globalFunc) {

  var tempState = _.cloneDeep($state.params);
  $scope.tabData = [
    {
      heading: 'All',
      route: 'main.user.suppliersWeb.grn.manage',
      key: 'all',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'ALL',
        cursor: null
      })
    }
  ];


  function initialize(){
    if(!!$state.params && !!$state.params.query){
      $scope.searchText = $state.params.query;
    }
    else{
      $scope.searchText = '';
    }
    if(!!$state.params && !!$state.params.status){
      $scope.status = $state.params.status;
      //set the default tab on refreshing
      var tempIndex = _.findIndex($scope.tabData, function(tab){return tab.key === $state.params.status.toLowerCase()});
      if(tempIndex < 0) {
        tempIndex = _.findIndex($scope.tabData.more, function (tab) {
          return tab.key === $state.params.status.toLowerCase()
        });
        if(!!tempIndex)
          $scope.activatedTab = $scope.tabData.more[tempIndex];
      }
      else
        $scope.activatedTab = $scope.tabData[tempIndex];
    }
    if(!!$state.params && !!$state.params.filter){
      $scope.searchFilter = $state.params.filter;
    }
    $scope.searchPlaceholder = 'Search Grn';
    $scope.searchState = 'main.user.suppliersWeb.grn.manage';
    $scope.parentState = 'main.user.suppliersWeb.grn.manage';
    $scope.parentStateParams = $state.params;
  }

  $scope.columns = [
    {id: 'grn_number', label: 'GRN #'},
    {id: 'po_number', label: 'PO #'},
    {id: 'title', label: 'PO Title', unsearchable: true},
    {id: 'billing|company|descr', label: 'Company', unsearchable: true},
    {
      id: 'created_at',
      label: 'PO Date',
      type: 'date',
      unsearchable: true
    }
  ];

  initialize();

  $scope.$emit('pageTitleChanged', 'Goods Receive Note');

  $scope.$on('$stateChangeStart', function(event, toState, toParams){
    if(!!toParams.status){
      $scope.parentStateParams = toParams;
    }
  });

}
SupplierWebGrnCtrl.$inject = ['$scope', '$state', '$rootScope', 'toastr', 'globalFunc'];

angular.module('metabuyer')
  .controller('SupplierWebGrnCtrl', SupplierWebGrnCtrl);
