'use strict';

function AnalyticsReportCtrl($scope, $state, $rootScope,  globalFunc, toastr, $uibModal, $q,
                             activeBusinessUnits, getAllCompanies, companyCostCenters, analyticsParam,
                             topSpendingByCommodity, topSpendingByCommodityLevel, totalSpendingByItemType, totalSpendingByItemTypeDrillDown,
                             trendOfSpendingReport, trendOfSpendingReportDrillDown, topPurchaseItem, topPurchaseItemDrillDown,
                             topSpendingBySupplier, analyticsDashboard, analyticsExclusionPo,
                             topSpendingBySupplierDrillDown, totalSpendingByExpenseType, totalSpendingByExpenseTypeDrillDown,
                             analyticsGlobalFunc) {
  $scope.exportAnalyticsData = exportAnalyticsData;
  $scope.onSelectedChart = onSelectedChart;
  $scope.showMetricsModal = showMetricsModal;

  /**
   * Scope for data
   *
   * @type {Array}
   */
  $scope.topSpendingByCommodity = [];
  $scope.totalSpendingByItemType = [];
  $scope.trendOfSpendingData = [];
  $scope.topPurchaseItemData = [];
  $scope.topSpendingBySupplierData = [];
  $scope.exclusionDataPo = [];
  $scope.totalSpendingByExpenseType = [];
  $scope.drillUpText = '< Back';

  $scope.search = {};
  $scope.metrics = {
    bu: [
      {
        code: "ALL",
        descr: "All"
      }
    ],
    company: [
      {
        code: "ALL",
        descr: "All"
      }
    ],
    cc: [
      {
        code: "ALL",
        descr: "All"
      }
    ],
    poStatus: [
      {
        code: "ALL",
        descr: "All"
      }
    ],
    start_date: moment().startOf('year').format('DD-MM-YYYY'),
    end_date: moment().format('DD-MM-YYYY')
  };

  $scope.buList = analyticsParam.business_units;
  $scope.companyList = analyticsParam.companies;
  $scope.companyListByBu = angular.copy($scope.companyList);
  $scope.ccList = analyticsParam.cost_centers;
  $scope.ccListByCompany = angular.copy($scope.ccList);

  // Chart titles
  $scope.chartTitle = {
    commodityCode: {
      title: [
        'Commodity Code'
      ],
      subTitle: [
        'Level 1/4',
        'Level 2/4',
        'Level 3/4',
        'Level 4/4'
      ]
    },
    expenseType: {
      title: [
        'Expense Type',
        'Total Spending by Company',
        'Total Spending by Cost Center',
        'Total Spending by Item'
      ],
      subTitle: [
        'Level 1/4',
        'Level 2/4',
        'Level 3/4',
        'Level 4/4'
      ]
    },
    itemType: {
      title: [
        'Item Type',
        'Total Spending by Company',
        'Total Spending by Cost Center',
        'Total Spending by Item'
      ],
      subTitle: [
        'Level 1/4',
        'Level 2/4',
        'Level 3/4',
        'Level 4/4'
      ]
    },
    item: {
      title: [
        'Total Spending by Item',
        'Total Spending by Supplier',
        'Total Spending by Company',
        'Total Spending by Cost Center'
      ],
      subTitle: [
        'Level 1/4',
        'Level 2/4',
        'Level 3/4',
        'Level 4/4'
      ]
    },
    supplier: {
       title: [
         'Total Spending by Supplier',
         'Total Spending by Company',
         'Total Spending by Cost Center',
         'Total Spending by Item'
       ],
      subTitle: [
        'Level 1/4',
        'Level 2/4',
        'Level 3/4',
        'Level 4/4'
      ]
    },
    trendSpending: {
      title: [
        'Total Spending by Month',
        'Total Spending by Company',
        'Total Spending by Cost Center',
        'Total Spending by Item'
      ],
      subTitle: [
        'Level 1/4',
        'Level 2/4',
        'Level 3/4',
        'Level 4/4'
      ]
    }
  };

  $scope.poStatusList = [
    {
      code: 'ALL',
      descr: 'All'
    },
    {
      code: [
        'SUBMITTED',
        'DELIVERY_DATE_UNCONFIRMED'
      ],
      descr: 'Submitted'
    },
    {
      code: [
        'AWAITING_DELIVERY',
        'PARTIALLY_DELIVERED'
      ],
      descr: 'Awaiting Delivery'
    },
    {
      code: [
        'FULLY_DELIVERED',
        'CLOSED'
      ],
      descr: 'Closed'
    },
    {
      code: [
        'EXPIRED'
      ],
      descr: 'Expired'
    },
    {
      code: [
        'DECLINED'
      ],
      descr: 'Declined'
    },
    {
      code: [
        'CANCELLED'
      ],
      descr: 'Cancelled'
    }
  ];
  $scope.showExclusionData = showExclusionData;

  function showExclusionData() {
    $uibModal.open({
      templateUrl: "app/analytics/report/exclusionModal.html",
      backdrop: 'static',
      keyboard: false,
      controller: function ($scope, $uibModalInstance) {
        $scope.cancel = closeModal;
        $scope.pos = [];

        function closeModal() {
          $uibModalInstance.close();
        }

        function initialize() {
          prepareExclusionData($scope);
        }

        initialize();
      }
    });
  }

  function exportAnalyticsData() {
    toastr.success('Exported');
  }

  $scope.chartTypeList = [
    {
      id:'commodity_chart',
      type: 'column',
      descr:'Top 10 Spending by Commodity Code',
      config: prepareSpendingCommodityConfig()
    },
    {
      id: 'catalog_chart',
      type: 'pie',
      descr:'Total Spending by Catalog/Non-Catalog',
      config: prepareSpendingCatalogConfig()
    },
    {
      id: 'spending_chart',
      type: 'column',
      descr:"Trend of Spending",
      config: prepareSpendingTrendConfig()
    },
    {
      id: 'supplier_chart',
      type: 'bar',
      descr:"Top 5 Spending by Supplier",
      config: prepareSpendingSupplierConfig()
    },
    {
      id: 'item_chart',
      type: 'bar',
      descr:"Top 10 Purchases by Item",
      config: prepareSpendingItemConfig()
    },
    {
      id: 'expense_type_chart',
      type: 'column',
      descr:"Total Spending by Expense Type",
      config: prepareExpenseTypeConfig()
    }
  ];

  function updateChart(chart, newChart) {
    var newData = {
      type: newChart.type,
      name: newChart.config.series[0].name,
      data: newChart.config.series[0].data,
      colorIndex: newChart.config.series[0].colorIndex,
      id: newChart.config.series[0].id
    };

    if (chart.series.length > 1) {
      for (var i = 0; i<chart.series.length; i++) {
        if (!!newChart.config.series[i]) {
          chart.series[i].chart.addSeries({
            type: newChart.type,
            name: newChart.config.series[i].name,
            data: newChart.config.series[i].data,
            colorIndex: newChart.config.series[i].colorIndex,
            id: newChart.config.series[0].id
          }, false);
        }
        chart.series[i].remove();
      }
    } else {
      if (newChart.id === 'commodity_chart') {
        newData.showInLegend = false;
      }

      chart.series[0].chart.addSeries(newData, false);
      chart.series[0].remove();
    }

    // new chart has extra series
    if (newChart.config.series.length > chart.series.length) {
      for (var i = 1; i<=chart.series.length; i++) {
        if (!!newChart.config.series[i]) {
          chart.series[0].chart.addSeries({
            type: newChart.type,
            name: newChart.config.series[i].name,
            data: newChart.config.series[i].data,
            colorIndex: newChart.config.series[i].colorIndex
          }, false);
        }
      }
    }

    if (newChart.id === 'commodity_chart') {
      chart.xAxis[0].update({
        categories: $scope.topSpendingByCommodity['name']
      });

      chart.inverted = false;
      chart.xAxis[0].update({}, false);
      chart.yAxis[0].update({}, false);
      chart.series[0].update({
        type: 'column',
        data: $scope.topSpendingByCommodity['value'],
        colorIndex: newChart.config.series[0].colorIndex
      });
    }

    if (newChart.id === 'catalog_chart') {
      if (chart.inverted) {
        chart.inverted = false;
      }
      // chart.series[0].chart.addSeries(newData, false);
      // chart.series[0].remove();
      chart.series[0].update({
        data: $scope.totalSpendingByItemType
      });

      chart.xAxis[0].update({lineWidth: 0});
      chart.yAxis[0].update({}, false);
      for (var i=0; i<chart.series.length; i++) {
        chart.series[i].update({
          type: 'pie',
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} % (MYR {point.y})"
          }
        });
      }
    }

    if (newChart.id === 'spending_chart') {
      chart.xAxis[0].update({
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      });

      chart.inverted = false;
      chart.xAxis[0].update({}, false);
      chart.yAxis[0].update({}, false);

      _.forEach($scope.trendOfSpendingData, function(year, key){
        chart.series[0].chart.addSeries({
          name: key,
          data: year,
          id: key
        }, false);
      });

      chart.series[0].remove();
    }

    if (newChart.id === 'supplier_chart') {
      chart.xAxis[0].update({
        categories: ["Just Supply Chain Sdn Bhd",
          "3D Printer Sdn Bhd",
          "AA Engineering Sdn Bhd",
          "Sunway Digital Wave Sdn Bhd",
          "You Lin Stationery Enterprise Sdn Bhd"]
      });

      chart.inverted = true;
      chart.xAxis[0].update({}, false);
      chart.yAxis[0].update({}, false);
      for (var i=0; i<chart.series.length; i++) {
        chart.series[i].update({
          type: 'bar'
        });
      }

      chart.series[0].update({
        name: "Total Spending",
        type: "bar",
        data: newChart.config.series[0].data,
        colorIndex: newChart.config.series[0].colorIndex
      });
    }

    if (newChart.id === 'item_chart') {
      chart.xAxis[0].update({
        categories: ["Bag Vacuum LDPE Plain",
          "Cover Coat W Steep Seal",
          "EC KC - General Cleanser with Hazard Certification",
          "Milo 1Kg",
          "International Air Ticket"]
      });

      chart.inverted = true;
      chart.xAxis[0].update({}, false);
      chart.yAxis[0].update({}, false);
      for (var i=0; i<chart.series.length; i++) {
        chart.series[i].update({
          type: 'bar'
        });
      }

      chart.series[0].update({
        name: "Total Spending",
        type: "bar",
        data: newChart.config.series[0].data,
        colorIndex: newChart.config.series[0].colorIndex
      });
    }
  }

  function onSelectedChart(selectedChart, model) {
    var chart = null;
    if (model === 'chart1') {
      chart = $scope.chartOne.config.getChartObj();
      updateChart(chart, selectedChart);
    }

    if (model === 'chart2') {
      chart = $scope.chartTwo.config.getChartObj();
      updateChart(chart, selectedChart);
    }

    if (model === 'chart3') {
      chart = $scope.chartThree.config.getChartObj();
      updateChart(chart, selectedChart);
    }

    if (model === 'chart4') {
      chart = $scope.chartFour.config.getChartObj();
      updateChart(chart, selectedChart);
    }

    if (model === 'chart5') {
      chart = $scope.chartFive.config.getChartObj();
      updateChart(chart, selectedChart);
    }
  }

  function prepareSpendingCommodityConfig() {
    return {
      "chart": {
        "type": "column",
        height: 890,
        events: {
          drilldown: function (e) {
            var param = prepareParam($scope.metrics);
            param.commodity_code = e.point.code;
            param.commodity_code_level = e.point.level;
            getTopSpendingByCommodityLevel(e, param);
          },
          drillup: function (e) {
            if (!!e.seriesOptions && !!e.seriesOptions.data) {
              var level = parseInt(e.seriesOptions.data[0].level) - 1;
              var chart = $scope.chartOne.config.getChartObj();
              chart.setTitle(null, { text: $scope.chartTitle.commodityCode.subTitle[level] });
            }
          },
          load: function () {
            this.redraw(); // redraw on load to prevent xAxis label overlap if too long
          }
        }
      },
      "title": {
        "text": null
      },
      "xAxis": {
        type: 'category',
        labels: {
          formatter: function () {
            var name = this.value;
            if (name.length > 25) {
              name = name.substring(0, 22) + "...";
            }
            return name;
          }
        }
      },
      "yAxis": {
        min: 0,
        title: {
          text: "Total (MYR)"
        }
      },
      "plotOptions": {
        "column": {
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.y.toLocaleString();
            }
          },
          showInLegend: false
        },
        "bar": {
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: "{point.y:,.2f}"
          }
        },
        line: {
          dataLabels: {
            enabled: true
          },
          enableMouseTracking: false
        }
      },
      "series": [
        {
          "name": "Total Spending",
          "data": [],
          "id": "spending_commodity",
          "color": "#ff6361",
          "_symbolIndex": 0
        }
      ],
      "drilldown": {
        "series": [],
        drillUpButton: {
          position: {
            x: 0,
            y: -30,
            align: 'right',
            verticalAlign: 'top'
          },
          theme: {
            fill: "#18a689"
          }
        }
      },
      "credits": {
        "enabled": false
      },
      lang: {
        drillUpText: $scope.drillUpText
      }
    };
  }

  function prepareSpendingCatalogConfig() {
    return {
      "chart": {
        "type": "pie",
        events: {
          drilldown: function (e) {
            var param = prepareParam($scope.metrics);
            param.item_type = e.point.type;
            param.level = e.point.level;
            param.type = e.point.type;

            if (param.level >= 2) {
              param['company_code[0]'] = e.point.company_code;
            }

            if (param.level > 2) {
              param['cost_center_code[0]'] = e.point.cost_center_code;
            }

            getTotalSpendingByItemTypeDrillDown(e, param);
          },
          drillup: function(e) {
            if (!!e.seriesOptions && !!e.seriesOptions.data) {
              var level = parseInt(e.seriesOptions.data[0].level) - 1;
              this.setTitle({ text: $scope.chartTitle.itemType.title[level] });
              this.setTitle(null, { text: $scope.chartTitle.itemType.subTitle[level] });
            }
          }
        }
      },
      "title": {
        "text": null
      },
      "plotOptions": {
        "column": {
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: "{point.y}"
          },
          showInLegend: false
        },
        "pie": {
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            formatter: function () {
              var name = this.key;
              if (name.length > 25) {
                name = name.substring(0, 22) + "...";
              }
              var percent = parseFloat(this.percentage).toFixed(2) + "%";
              var value = parseFloat(this.y).toLocaleString();
              return name + ": MYR " + value + " (" + percent + ")";
            }
          }
        },
        line: {
          dataLabels: {
            enabled: true
          },
          enableMouseTracking: false
        },
        "bar": {
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: "{point.y}"
          }
        },
        "series": {
          animation:false,
          turboThreshold: 0
        }
      },
      "yAxis": {
        title: {
          text: null
        }
      },
      "series": [
        {
          "name": "Total spending (MYR)",
          "colorByPoint": true,
          "data": [],
          "id": "spending_catalog",
          colorIndex: 0,
          "_symbolIndex": 0
        }
      ],
      "drilldown": {
        "series": [],
        drillUpButton: {
          position: {
            x: 0,
            y: -30,
            align: 'right',
            verticalAlign: 'top'
          },
          theme: {
            fill: "#18a689"
          }
        }
      },
      "credits": {
        "enabled": false
      },
      lang: {
        drillUpText: $scope.drillUpText
      }
    };
  }

  function prepareSpendingTrendConfig() {
    return {
      "chart": {
        type: 'column',
        events: {
          drilldown: function(e) {
            var param = prepareParam($scope.metrics);
            param.month = e.point.month;
            param.monthName = e.point.monthName;
            param.year = e.point.year;
            param.level = e.point.level;

            if (param.level > 1) {
              param['company_code[0]'] = e.point.company_code;
            }

            if (param.level > 2) {
              param['cost_center_code[0]'] = e.point.cost_center_code;
            }

            getTrendOfSpendingReportDrillDown(e, param);
          },
          drillup: function (e) {
            if (!!e.seriesOptions && !!e.seriesOptions.data) {
              var level = parseInt(e.seriesOptions.data[0].level) - 1;

              this.setTitle({ text: $scope.chartTitle.trendSpending.title[level] });
              this.setTitle(null, { text: $scope.chartTitle.trendSpending.subTitle[level] });
            }
          }
        }
      },
      "title": {
        text: null
      },
      "xAxis": {
        type: 'category',
        crosshair: true,
        labels: {
          formatter: function () {
            var name = this.value;
            if (name.length > 15) {
              name = name.substring(0, 14) + "...";
            }
            return name;
          }
        }
      },
      "yAxis": {
        title: {
          text: "Total (MYR)"
        }
      },
      "plotOptions": {
        "column": {
          cursor: 'pointer',
          showInLegend: true
        },
        line: {
          dataLabels: {
            enabled: true
          }
        },
        "bar": {
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: "{point.y}"
          }
        },
        "series": {
          cropThreshold: 3000
        }
      },
      tooltip: {
        shared: true,
        valuePrefix: 'MYR ',
        valueDecimals: 2
      },
      "series": [
        {
          "name": "2016",
          "data": [],
          "id": "spending_trend_2016",
          colorIndex: 5
        },
        {
          "name": "2017",
          "data": [],
          "id": "spending_trend_2017",
          colorIndex: 6
        },
        {
          "name": "2018",
          "data": [],
          "id": "spending_trend_2018",
          colorIndex: 7
        },
        {
          "name": "2019",
          "data": [],
          "id": "spending_trend_2019"
        }],
      "drilldown": {
        "series": [],
        drillUpButton: {
          position: {
            x: 0,
            y: -30,
            align: 'right',
            verticalAlign: 'top'
          },
          theme: {
            fill: "#18a689"
          }
        }
      },
      "credits": {
        "enabled": false
      },
      lang: {
        drillUpText: $scope.drillUpText
      }
    };
  }

  function prepareSpendingSupplierConfig() {
    return {
      chart: {
        type: 'bar',
        marginTop: 55,
        events: {
          drilldown: function (e) {
            var param = prepareParam($scope.metrics);
            param.level = e.point.level;
            if (param.level === 1) {
              param.supplier_code = e.point.code;
            }

            if (param.level === 2) {
              param.supplier_code = e.point.supplier_code;
              param.supplier_company_code = e.point.company_code;
            }

            if (param.level === 3) {
              param.supplier_code = e.point.supplier_code;
              param.supplier_company_code = e.point.company_code;
              param.supplier_cost_center_code = e.point.cost_center_code;
            }

            getTopSpendingBySupplierDrillDown(e, param);
          },
          drillup: function (e) {
            if (!!e.seriesOptions && !!e.seriesOptions.data) {
              var level = parseInt(e.seriesOptions.data[0].level) - 1;
              var name = [];
              _.forEach(e.seriesOptions.data, function (data) {
                name.push(data.name);
              });

              var chart = $scope.chartFour.config.getChartObj();
              chart.xAxis[0].update({
                categories: name
              }, false);

              chart.setTitle({ text: $scope.chartTitle.supplier.title[level] });
              chart.setTitle(null, { text: $scope.chartTitle.supplier.subTitle[level] });
              var height = 400;
              chart.setSize(undefined, height);
              if (e.seriesOptions.data.length > 10) {
                height = e.seriesOptions.data.length * 50;
                chart.setSize(undefined, height);
              }
            }
          }
        }
      },
      title: {
        text: null
      },
      xAxis: {
        categories: [],
        title: {
          text: null
        },
        labels: {
          formatter: function () {
            var name = this.value;
            if (name.length > 25) {
              name = name.substring(0, 24) + "...";
            }
            return name;
          }
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: null
        },
        labels: {
          overflow: 'justify'
        }
      },
      "plotOptions": {
        "column": {
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: "{point.y:,.2f}"
          },
          showInLegend: false
        },
        line: {
          dataLabels: {
            enabled: true
          },
          enableMouseTracking: false
        },
        "bar": {
          animation:false,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            crop: false,
            formatter: function () {
              return this.y.toLocaleString();
            }
          }
        },
        series: {
          animation:false
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Total Spending (MYR)',
        data: [],
        id: "total_spending_supplier",
        colorIndex: 4
      },
      {
        name: 'Total PO',
        data: [],
        id: "total_po_spending_supplier",
        colorIndex: 5
      }
      ],
      "drilldown": {
        animation:false,
        "series": [],
        drillUpButton: {
          position: {
            x: -5,
            y: -40,
            align: 'right',
            verticalAlign: 'top'
          },
          theme: {
            fill: "#18a689"
          }
        }
      },
      lang: {
        drillUpText: $scope.drillUpText
      }
    };
  }

  function prepareSpendingItemConfig() {
    return {
      chart: {
        type: 'bar',
        marginTop: 55,
        events: {
          drilldown: function (e) {
            var param = prepareParam($scope.metrics);
            param.item_id = e.point.item_id;
            param.level = e.point.level;

            if (param.level === 2) {
              param.supplier_code = e.point.supplier_code;
            }

            if (param.level === 3) {
              param.supplier_code = e.point.supplier_code;
              param.supplier_company_code = e.point.company_code;
            }

            getTopPurchaseItemDrillDown(e, param);
          },
          drillup: function (e) {
            if (!!e.seriesOptions && !!e.seriesOptions.data) {
              var name = [];
              var level = parseInt(e.seriesOptions.data[0].level) -1;
              _.forEach(e.seriesOptions.data, function (data) {
                name.push(data.name);
              });

              var chart = $scope.chartFive.config.getChartObj();
              chart.xAxis[0].update({
                categories: name
              }, false);

              chart.setTitle({ text: $scope.chartTitle.item.title[level] });
              chart.setTitle(null, { text: $scope.chartTitle.item.subTitle[level] });

              var height = 400;
              chart.setSize(undefined, height);
              if (e.seriesOptions.data.length > 10) {
                height = e.seriesOptions.data.length * 50;
                chart.setSize(undefined, height);
              }
            }
          }
        }
      },
      title: {
        text: null
      },
      xAxis: {
        categories: [],
        title: {
          text: null
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: null
        },
        labels: {
          overflow: 'justify'
        }
      },
      plotOptions: {
        "column": {
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: "{point.y:,.2f}"
          },
          showInLegend: false
        },
        bar: {
          animation: false,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.y.toLocaleString();
            }
          }
        },
        line: {
          dataLabels: {
            enabled: true
          },
          enableMouseTracking: false
        },
        series: {
          animation: false
        }
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: 'Total Spending (MYR)',
          data: [],
          id: "total_spending_item",
          colorIndex: 3
        },
        {
          name: 'Quantity',
          data: [],
          id: "quantity_item",
          colorIndex: 0
        }
      ],
      "drilldown": {
        animation: false,
        "series": [],
        drillUpButton: {
          theme: {
            fill: "#18a689"
          },
          position: {
            x: -5,
            y: -40,
            align: 'right',
            verticalAlign: 'top'
          }
        }
      },
      lang: {
        drillUpText: $scope.drillUpText
      }
    };
  }

  function prepareExpenseTypeConfig() {
    return {
      "chart": {
        "type": "column",
        height: 400,
        events: {
          drilldown: function (e) {
            var param = prepareParam($scope.metrics);
            param.expense_type = e.point.expense_type;
            param.level = e.point.level;

            if (param.level > 1) {
              param['company_code[0]'] = e.point.company_code;
            }

            if (param.level > 2) {
              param['cost_center_code[0]'] = e.point.cost_center_code;
            }

            getTotalSpendingByExpenseTypDrillDown(e, param);
          },
          drillup: function (e) {
            if (!!e.seriesOptions && !!e.seriesOptions.data) {
              var level = parseInt(e.seriesOptions.data[0].level) - 1;
              this.setTitle({ text: $scope.chartTitle.expenseType.title[level] });
              this.setTitle(null, { text: $scope.chartTitle.expenseType.subTitle[level] });
            }
          }
        }
      },
      "title": {
        "text": null
      },
      "xAxis": {
        type: 'category',
        labels: {
          formatter: function () {
            var name = this.value;
            if (name.length > 15) {
              name = name.substring(0, 14) + "...";
            }
            return name;
          }
        }
      },
      "yAxis": {
        min: 0,
        title: {
          text: "Total (MYR)"
        }
      },
      "plotOptions": {
        "column": {
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.y.toLocaleString();
            }
          },
          showInLegend: false
        },
        "series": {
          cropThreshold: 3000
        }
      },
      "series": [
        {
          "name": "Total Spending",
          "data": [],
          "id": "spending_expense_type",
          "color": "#58508d",
          "_symbolIndex": 0
        }
      ],
      "drilldown": {
        "series": [],
        drillUpButton: {
          position: {
            x: 0,
            y: -30,
            align: 'right',
            verticalAlign: 'top'
          },
          theme: {
            fill: "#18a689"
          }
        }
      },
      "credits": {
        "enabled": false
      },
      lang: {
        drillUpText: $scope.drillUpText
      }
    };
  }

  function prepareData() {
    $scope.chartOne = $scope.chartTypeList[0];
    $scope.chartTwo = $scope.chartTypeList[1];
    $scope.chartThree = $scope.chartTypeList[2];
    $scope.chartFour = $scope.chartTypeList[3];
    $scope.chartFive = $scope.chartTypeList[4];
    $scope.chartSix = $scope.chartTypeList[5];

    updateAllChart($scope.metrics);

    $scope.datePicker = {
      startDate: moment().startOf('year'),
      endDate: moment(),
      options: {
        autoApply: true,
        showDropdowns: true,
        alwaysShowCalendars: true,
        opens: 'left',
        minDate: '2016-02-17', // Fix date: start of the PO
        maxDate: moment(),
        ranges: {
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment()],
          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
          'Last 6 Months': [moment().subtract(6, 'month'), moment()],
          'This Year': [moment().startOf('year'), moment()],
          'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
          'All Time': [moment("2016-02-17"), moment()]
        },
        eventHandlers: {
          'apply.daterangepicker': function(event, picker) {
            $scope.metrics.start_date = event.model.startDate.format('DD-MM-YYYY');
            $scope.metrics.end_date = event.model.endDate.format('DD-MM-YYYY');

            updateAllChart($scope.metrics);
          }
        }
      }
    };
  }

  function getExclusionPo(param) {
    analyticsExclusionPo.get(
      param,
      function(resource) {
        if (!!resource && !!resource.data) {
          $scope.exclusionDataPo = resource.data;
        }
      },
      function(response) {
        var messages = analyticsGlobalFunc.getErrorMessages(response);
        if (messages.length > 0) {
          toastr.error(messages);
        }
      }
    ).$promise.then(function (resource) {
      $scope.exclusionDataPo = resource.data;
    });
  }

  function prepareExclusionData(child) {
    child.pos = angular.copy($scope.exclusionDataPo);
  }

  function getAnalyticsDashboard(param) {
    analyticsDashboard.get(
      param,
      function(resource) {
        if (!!resource && !!resource.data) {
          $scope.analyticsDashboard = resource.data;
        }
      },
      function(response) {
        var messages = analyticsGlobalFunc.getErrorMessages(response);
        if (messages.length > 0) {
          toastr.error(messages);
        }
      }
    ).$promise.then(function (resource) {
      $scope.analyticsDashboard = resource.data;
    });
  }

  function showMetricsModal() {
    $uibModal.open({
      templateUrl: "app/analytics/report/metricsModal.html",
      backdrop: 'static',
      keyboard: false,
      controller: function ($scope, $uibModalInstance) {
        $scope.cancel = closeModal;
        $scope.metrics = {};
        $scope.buList = [];
        $scope.buListAll = [];
        $scope.companyList = [];
        $scope.companyListByBu = [];
        $scope.ccList = [];
        $scope.ccListByCompany = [];
        $scope.selected = {
          costCenter: [],
          poStatus: []
        };
        $scope.poStatus = [];
        $scope.poStatusList = [];
        $scope.apply = apply;
        $scope.selectBusinessUnit = selectBusinessUnit;
        $scope.selectCompany = selectCompany;
        $scope.updateCcByCompany = updateCcByCompany;
        $scope.enableCcSelection = enableCcSelection;
        $scope.selectCostCenter = selectCostCenter;
        $scope.selectPoStatus = selectPoStatus;
        $scope.all = {
          code: 'ALL',
          descr: 'All'
        };

        function apply() {
          if (! validateMetrics()) {
            toastr.error("All input is required.");
            return;
          }

          // Update model
          updateAllChart($scope.metrics);

          // close modal
          closeModal();
        }

        function validateMetrics() {
          if ($scope.selected.businessUnit.length < 1) {
            return false;
          }

          if ($scope.selected.company.length < 1) {
            return false;
          }

          if ($scope.selected.costCenter.length < 1) {
            return false;
          }

          if ($scope.selected.poStatus.length < 1) {
            return false;
          }
          return true;
        }

        function selectBusinessUnit(data, action) {
          if (action === 'select' && data.code === "ALL") {
            $scope.selected.businessUnit = [];
            $scope.selected.businessUnit.push(data);
            $scope.buList = [];
          }

          if (action === 'remove' && data.code === "ALL") {
            $scope.buList = angular.copy($scope.buListAll);
          }

          $scope.metrics.bu = $scope.selected.businessUnit;

          // Update company select
          $scope.selected.company = [];
          $scope.selected.company.push($scope.all);
          $scope.metrics.company = angular.copy($scope.selected.company);
          // Update company option
          $scope.companyListByBu = [];
          // Update cc
          $scope.selected.costCenter = [];
          $scope.selected.costCenter.push($scope.all);
          $scope.metrics.cc = angular.copy($scope.selected.costCenter);
        }

        function selectCompany(data, action) {
          if (action === 'select' && data.code === "ALL") {
            $scope.selected.company = [];
            $scope.selected.company.push(data);
            $scope.companyListByBu = [];
          }

          if (action === 'remove' && data.code === "ALL") {
            $scope.companyListByBu = [];
            // generate company list based on selected BU
            if ($scope.buList.length === 0) {
              $scope.companyListByBu = angular.copy($scope.companyList);
            } else {
              _.forEach($scope.companyList, function(company){
                _.forEach($scope.selected.businessUnit, function (bu) {
                  if (bu.code === company.bu_code) {
                    $scope.companyListByBu.push(company);
                  }
                });
              });
            }
            $scope.companyListByBu.unshift($scope.all);
          }

          $scope.metrics.company = angular.copy($scope.selected.company);

          $scope.ccListByCompany = [];
          $scope.ccListByCompany.push($scope.all);
          $scope.selected.costCenter = [];
          $scope.selected.costCenter.push($scope.all);
          $scope.metrics.cc = angular.copy($scope.selected.costCenter);
        }

        function selectCostCenter(data, action) {
          if (action === 'remove' && data.code === "ALL") {
            $scope.companyCodeList = _.map($scope.selected.company, function(company){
              return company.code;
            });

            $scope.ccListByCompany = [];
            _.forEach($scope.ccList, function(cc) {
              if ($scope.companyCodeList.indexOf(cc.company_code) > -1) {
                $scope.ccListByCompany.push(cc);
              }
            });

            $scope.ccListByCompany.unshift($scope.all);
          }

          if (action === 'select' && data.code === "ALL") {
            $scope.ccListByCompany = [];
            $scope.ccListByCompany.push(data);
            $scope.selected.costCenter = [];
            $scope.selected.costCenter.push(data);
          }

          $scope.metrics.cc = $scope.selected.costCenter;
        }

        function enableCcSelection() {
          if (!!$scope.selected.company && $scope.selected.company.length > 0 && $scope.selected.company[0].code !== 'ALL') {
            return false;
          }
          return true;
        }

        function selectPoStatus(data, action) {
          if (action === 'remove' && data.code === "ALL") {
            $scope.poStatus = angular.copy($scope.poStatusList);
          }

          if (action === 'select' && data.code === "ALL") {
            $scope.poStatus = [];
            $scope.poStatus.push(data);
            $scope.selected.poStatus = [];
            $scope.selected.poStatus.push(data);
          }

          $scope.metrics.poStatus = $scope.selected.poStatus;
        }

        function updateCcByCompany(company) {
          // Reset cost center selection on company change
          var all = {
            code: 'ALL',
            descr: 'All'
          };

          $scope.ccList = [];
          $scope.selected.costCenter = [];
          $scope.selected.costCenter.push(all);
          $scope.ccListByCompany = angular.copy($scope.selected.costCenter);
          $scope.metrics.cc = [all];

          if (company.code !== 'ALL') {
            companyCostCenters.get(
              {
                id: company._id
              },
              function (resource) {
                // success
              }
            ).$promise.then(function (resource) {
              if (!!resource && !!resource.content && !!resource.content.data) {
                $scope.ccList = resource.content.data || [];
                $scope.ccList.unshift(all);
              }
            });
          }
        }

        function closeModal() {
          $uibModalInstance.close();
        }

        function initialize() {
          assignMetrics($scope);

          if ($scope.selected.businessUnit[0].code === 'ALL') {
            $scope.buList = [];
          }

          if ($scope.selected.businessUnit[0].code === 'ALL' && $scope.selected.company[0].code !== 'ALL') {
            $scope.companyListByBu = angular.copy($scope.companyList);
            $scope.companyListByBu.unshift($scope.all);
          }

          if ($scope.selected.businessUnit[0].code !== 'ALL' && $scope.selected.company[0].code !== 'ALL') {
            _.forEach($scope.companyList, function(company){
              _.forEach($scope.selected.businessUnit, function (bu) {
                if (bu.code === company.bu_code) {
                  $scope.companyListByBu.push(company);
                }
              });
            });

            $scope.companyListByBu.unshift($scope.all);
          }

          if ($scope.selected.company[0].code !== 'ALL' && $scope.selected.costCenter[0].code !== 'ALL') {
            $scope.companyCodeList = _.map($scope.selected.company, function(company){
              return company.code;
            });

            $scope.ccListByCompany = [];
            _.forEach($scope.ccList, function(cc) {
              if ($scope.companyCodeList.indexOf(cc.company_code) > -1) {
                $scope.ccListByCompany.push(cc);
              }
            });

            $scope.ccListByCompany.unshift($scope.all);
          }
        }

        initialize();
      }
    });
  }

  function assignMetrics(childScope) {
    var all = {
      code: "ALL",
      descr: "All"
    };

    childScope.metrics = angular.copy($scope.metrics);

    // PO Status
    childScope.selected.poStatus = angular.copy($scope.metrics.poStatus);
    childScope.poStatus.push(all);
    childScope.poStatusList = angular.copy($scope.poStatusList);

    // BU Listing
    childScope.buList = angular.copy($scope.buList);
    childScope.buList.unshift(all);
    childScope.buListAll = angular.copy(childScope.buList);
    childScope.selected.businessUnit = angular.copy($scope.metrics.bu);

    // Company Listing
    globalFunc.sortObjectsArray($scope.companyList, 'descr');
    childScope.companyList = angular.copy($scope.companyList);
    childScope.selected.company = angular.copy($scope.metrics.company);

    // CC list
    childScope.ccList = angular.copy($scope.ccList);
    childScope.selected.costCenter = angular.copy($scope.metrics.cc);
  }

  function updateAllChart(metrics) {
    // Assign metrics to parent
    $scope.metrics = metrics;
    var param = prepareParam(metrics);

    getAnalyticsDashboard(param);
    getTrendOfSpendingReport(param);
    getTopSpendingByCommodity(param);
    getTotalSpendingByItemType(param);
    getTotalSpendingByExpenseType(param);
    getTopPurchaseItem(param);
    getTopSpendingBySupplier(param);
    getExclusionPo(param);
  }

  function prepareParam(metrics) {
    var param = {};
    if (!!metrics.start_date) {
      param.start_date = metrics.start_date;
    }

    if (!!metrics.end_date) {
      param.end_date = metrics.end_date;
    }

    if (!!metrics.poStatus && metrics.poStatus[0].code !== 'ALL') {
      param.po_status = [];
      var codeList = [];
      _.forEach(metrics.poStatus, function (status) {
        codeList = codeList.concat(status.code);
      });

      _.forEach(codeList, function (code, index) {
        param['po_status['+index+']'] = code;
      });
    }

    if (!!metrics.bu && metrics.bu[0].code !== "ALL") {
      _.forEach(metrics.bu, function (bu, index) {
        param['bu_code['+index+']'] = bu.code;
      });
    } else {
      _.forEach($scope.buList, function (bu, index) {
        param['bu_code['+index+']'] = bu.code;
      });
    }

    if (!!metrics.company && metrics.company[0].code !== "ALL") {
      _.forEach(metrics.company, function (company, index) {
        param['company_code['+index+']'] = company.code;
      });
    } else {
      if (!!metrics.bu && metrics.bu[0].code !== "ALL") {
        // generate company by BU
        _.forEach(metrics.bu, function (bu, index) {
          _.forEach($scope.companyList, function (company, index) {
            if (bu.code === company.bu_code) {
              param['company_code['+index+']'] = company.code;
            }
          });
        });
      } else {
        _.forEach($scope.companyList, function (company, index) {
          param['company_code['+index+']'] = company.code;
        });
      }
    }

    if (!!metrics.cc && metrics.cc[0].code !== "ALL") {
      param.cost_center_code = [];
      _.forEach(metrics.cc, function (cc, index) {
        param['cost_center_code['+index+']'] = cc.code;
      });
    }

    if (!!$rootScope.currentUser &&
      !!$rootScope.currentUser.config &&
      !!$rootScope.currentUser.config.TZ) {
      param.timezone = $rootScope.currentUser.config.TZ;
    }

    return param;
  }

  function getTopSpendingByCommodityLevel(e, param) {
    var chart = $scope.chartOne.config.getChartObj();
    chart.showLoading();

    topSpendingByCommodityLevel.get(
      param,
      function(response) {
        if (!!response && !!response.data) {
          if (!response.data.length) {
            toastr.info('No data for top spending by commodity code at this level.');
          }
        }
      }
    ).$promise.then(function(response){
      var value = [];
      _.forEach(response.data, function(data) {
        var object = {
          name: data.commodity_name,
          code: data.commodity_code,
          y: parseFloat(data.total)
        };
        var level = parseInt(param.commodity_code_level) + 1;
        if (level < 4) {
          object.level = level;
          object.drilldown = data.commodity_code;
        }
        value.push(object);
      });

      var newSeries = {
        name: "Total Spending",
        data: value,
        color: "#ff6361"
      };

      var level = param.commodity_code_level;
      chart.setTitle(null, { text: $scope.chartTitle.commodityCode.subTitle[level] });
      chart.addSeriesAsDrilldown(e.point, newSeries);
      chart.hideLoading();
    });
  }

  function getTopSpendingByCommodity(param) {
    topSpendingByCommodity.get(
      param,
      function(response) {
        if (!!response && !!response.data) {
          if (!response.data.length) {
            toastr.info('No data for top spending by commodity code.');
          }

          var value = [];
          _.forEach(response.data, function(data) {
            var object = {
              name: data.commodity_name,
              code: data.commodity_code,
              y: parseFloat(data.total),
              drilldown: data.commodity_code,
              level: 1
            };
            value.push(object);
          });

          $scope.topSpendingByCommodity['value'] = value;
        }
      },
      function(response) {
        var messages = analyticsGlobalFunc.getErrorMessages(response);
        if (messages.length > 0) {
          toastr.error(messages);
        }
      }
    ).$promise.then(function(response){
      var chart = $scope.chartOne.config.getChartObj();

      //Drill up to initial series
      if (!!chart.drilldownLevels && chart.drilldownLevels.length > 0) {
        for (var i=0; i<chart.drilldownLevels.length; i++) {
          chart.drillUp();
        }
      }

      chart.series[0].update({
        data: $scope.topSpendingByCommodity['value']
      });

      chart.setTitle({ text: $scope.chartTitle.commodityCode.title[0] });
      chart.setTitle(null, { text: $scope.chartTitle.commodityCode.subTitle[0] });

      // Reset drill up button
      chart.drillUpButton = chart.drillUpButton.destroy();

    });
  }

  function getTotalSpendingByItemType(param) {
    totalSpendingByItemType.get(
      param,
      function(response) {
        if (!!response && !!response.data) {
          if (!response.data.length) {
            toastr.info('No data for total spending by item type.');
          }

          var arrayObject = [];
          _.forEach(response.data, function(data) {
            var object = {
              name: '',
              y: ''
            };
            if (parseInt(data.type) === 1) {
              object.name = "Catalog";
            } else {
              object.name = "Non-Catalog";
            }
            object.y = parseFloat(data.total);
            object.code = data.type;
            object.type = data.type;
            object.drilldown = data.type;
            object.level = 1;
            arrayObject.push(object);
          });

          $scope.totalSpendingByItemType = arrayObject;
        }
      },
      function(response) {
        var messages = analyticsGlobalFunc.getErrorMessages(response);
        if (messages.length > 0) {
          toastr.error(messages);
        }
      }
    ).$promise.then(function(response) {
      var chart = $scope.chartTwo.config.getChartObj();

      //Drill up to initial series
      if (!!chart.drilldownLevels && chart.drilldownLevels.length > 0) {
        for (var i=0; i<chart.drilldownLevels.length; i++) {
          chart.drillUp();
        }
      }

      chart.series[0].update({
        data: $scope.totalSpendingByItemType
      });

      chart.setTitle({ text: $scope.chartTitle.itemType.title[0] });
      chart.setTitle(null, { text: $scope.chartTitle.itemType.subTitle[0] });

      // Reset drill up button
      chart.drillUpButton = chart.drillUpButton.destroy();
    });
  }

  function getTotalSpendingByItemTypeDrillDown(e, param) {
    var chart = $scope.chartTwo.config.getChartObj();
    chart.showLoading();

    totalSpendingByItemTypeDrillDown.get(
      param,
      function(response) {
        if (!!response && !!response.data) {
          if (!response.data.length) {
            toastr.info('No data for total spending by catalog at this level.');
          }
        }
      }
    ).$promise.then(function(response) {
      var arrayObject = [];
      var level = param.level + 1;
      _.forEach(response.data, function(data) {
        var object = {
          name: data.name,
          y: parseFloat(data.total),
          type: param.type
        };

        // add company drilldown
        if (level >= 2) {
          object.company_code = (data.company_code !== undefined) ? data.company_code : param['company_code[0]'];
          object.code = object.company_code;
        }

        // add cost center drilldown
        if (level > 2) {
          object.cost_center_code = (data.cost_center_code !== undefined) ? data.cost_center_code : param['cost_center_code[0]'];
          object.code = object.cost_center_code;
        }

        // set drilldown
        if (level < 4) {
          object.level = level;
          object.drilldown = object.code;
        }

        arrayObject.push(object);
      });

      var newSeries = {
        name: "Total Spending",
        data: arrayObject
      };

      chart.setTitle({ text: $scope.chartTitle.itemType.title[param.level] });
      chart.setTitle(null, { text: $scope.chartTitle.itemType.subTitle[param.level] });
      chart.addSeriesAsDrilldown(e.point, newSeries);
      chart.hideLoading();
    });
  }

  function getTrendOfSpendingReport(param) {
    trendOfSpendingReport.get(
      param,
      function(response) {
        if (!!response && !!response.data) {
          $scope.trendOfSpendingData = response.data;
        }
      },
      function(response) {
        var messages = analyticsGlobalFunc.getErrorMessages(response);
        if (messages.length > 0) {
          toastr.error(messages);
        }
      }
    ).$promise.then(function(response) {
      var chart = $scope.chartThree.config.getChartObj();

      //Drill up to initial series
      if (!!chart.drilldownLevels && chart.drilldownLevels.length > 0) {
        for (var i=0; i<chart.drilldownLevels.length; i++) {
          chart.drillUp();
        }
      }

      var minYear = 2016;
      var currentYear = parseInt(moment().format("YYYY"));
      var yearRange = [];
      for (var i=0; i<= currentYear - minYear; i++) {
        yearRange.push(minYear + i);
      }

      _.forEach(yearRange, function (year, index) {
        if (!!$scope.trendOfSpendingData[year]) {
          var value = [];
          var monthName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          var monthArray = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
          for (var i=0; i<monthArray.length; i++) {
            var object = {
              name: monthName[i],
              y: $scope.trendOfSpendingData[year][i],
              drilldown: monthArray[i]+'-'+yearRange[index],
              month: monthArray[i],
              monthName: monthName[i],
              year: yearRange[index],
              level: 1
            };
            value.push(object);
          }

          chart.series[index].update({
            data: value
          });
        } else {
          chart.series[index].setData([0,0,0,0,0,0,0,0,0,0,0,0]);
        }
      });

      chart.setTitle({ text: $scope.chartTitle.trendSpending.title[0] });
      chart.setTitle(null, { text: $scope.chartTitle.trendSpending.subTitle[0] });
    });
  }

  function getTrendOfSpendingReportDrillDown(e, param) {
    var chart = $scope.chartThree.config.getChartObj();
    chart.showLoading();

    trendOfSpendingReportDrillDown.get(
      param,
      function(response) {
        if (!!response && !!response.data) {
          if (!response.data.length) {
            toastr.info('No data for trend of spending at this level.');
          }
        }
      }
    ).$promise.then(function (response) {
      var value = [];
      var level = parseInt(param.level) + 1;
      _.forEach(response.data, function (data) {
        var object = {
          name: data.name,
          y: parseFloat(data.total),
          month: param.month,
          monthName: param.monthName,
          year: param.year,
          level: level
        };

        // add company drilldown
        if (level >= 2) {
          object.company_code = (data.company_code !== undefined) ? data.company_code : param['company_code[0]'];
          object.code = object.company_code;
        }

        // add cost center drilldown
        if (level > 2) {
          object.cost_center_code = (data.cost_center_code !== undefined) ? data.cost_center_code : param['cost_center_code[0]'];
          object.code = object.cost_center_code;
        }

        // set drilldown
        if (level < 4) {
          object.level = level;
          object.drilldown = object.code;
        }

        value.push(object);
      });

      var newSeries = {
        name: 'Total Spending ('+param.monthName +' '+param.year+')',
        data: value
      };

      if (level >= 3) {
        newSeries.name = 'Total Spending ('+param.monthName +' '+param.year+') - ' + e.point.name;
      }

      chart.setTitle({ text: $scope.chartTitle.trendSpending.title[param.level] });
      chart.setTitle(null, { text: $scope.chartTitle.trendSpending.subTitle[param.level] });
      chart.addSeriesAsDrilldown(e.point, newSeries);
      chart.hideLoading();
    });
  }

  function getTopPurchaseItem(param) {
    topPurchaseItem.get(
      param,
      function(response) {
        if (!!response && !!response.data) {
          if (!response.data.length) {
            toastr.info('No data for top purchase by item.');
          }

          var name = [];
          var total = [];
          var quantity = [];
          _.forEach(response.data, function(data){
            name.push(data.name);

            var object = {
              name: data.name,
              y: parseFloat(data.total),
              drilldown: data.id,
              item_id: data.id,
              level: 1
            };
            total.push(object);

            var qtyObject = {
              name: data.name,
              y: parseFloat(data.quantity),
              drilldown: data.id,
              item_id: data.id,
              level: 1
            };
            quantity.push(qtyObject);

          });
          $scope.topPurchaseItemData['name'] = name;
          $scope.topPurchaseItemData['total'] = total;
          $scope.topPurchaseItemData['quantity'] = quantity;
        }
      },
      function(response) {
        var messages = analyticsGlobalFunc.getErrorMessages(response);
        if (messages.length > 0) {
          toastr.error(messages);
        }
      }
    ).$promise.then(function(response) {
      var chart = $scope.chartFive.config.getChartObj();

      //Drill up to initial series
      if (!!chart.drilldownLevels && chart.drilldownLevels.length > 0) {
        for (var i=0; i<chart.drilldownLevels.length; i++) {
          chart.drillUp();
        }
      }

      chart.xAxis[0].update({
        categories: $scope.topPurchaseItemData['name']
      });

      chart.series[0].setData($scope.topPurchaseItemData['total']);
      chart.series[1].setData($scope.topPurchaseItemData['quantity']);

      chart.setTitle({ text: $scope.chartTitle.item.title[0] });
      chart.setTitle(null, { text: $scope.chartTitle.item.subTitle[0] });

      // Reset drill up button
      chart.drillUpButton = chart.drillUpButton.destroy();
    });
  }

  function getTopPurchaseItemDrillDown(e, param) {
    var chart = $scope.chartFive.config.getChartObj();
    chart.showLoading();

    topPurchaseItemDrillDown.get(
      param,
      function (response) {
        if (!!response && !!response.data) {
          if (!response.data.length) {
            toastr.info('No data for top purchase item at this level.');
          }
        }
      }
    ).$promise.then(function (response) {
      var name = [];
      var total = [];
      var quantity = [];
      var level = parseInt(param.level);
      _.forEach(response.data, function(data){
        name.push(data.name);

        var object = {
          name: data.name,
          y: parseFloat(data.total),
          item_id: param.item_id,
          code: data.code
        };

        var item = {
          name: data.name,
          y: parseFloat(data.quantity),
          item_id: param.item_id,
          code: data.code
        };

        // Drilldown by supplier
        if (level === 1) {
          object.level = 2;
          object.drilldown = data.code;
          object.supplier_code = data.code;

          item.level = 2;
          item.drilldown = data.code;
          item.supplier_code = data.code;
        }

        // Drilldown by company
        if (level === 2) {
          object.level = 3;
          object.drilldown = data.code;
          object.supplier_code = e.point.supplier_code;
          object.company_code = data.code;

          item.level = 3;
          item.drilldown = data.code;
          item.supplier_code = e.point.supplier_code;
          item.company_code = data.code;
        }

        total.push(object);
        quantity.push(item);
      });

      var newSeries = {
        id: "top_item_drilldown",
        name: "Total Spending (MYR)",
        data: total,
        color: '#f7a35c'
      };

      var newQuantitySeries = {
        id: "top_item_quantity_drilldown",
        name: "Quantity",
        data: quantity,
        color: "#7cb5ec"
      };

      chart.xAxis[0].update({
        categories: name
      });

      chart.setTitle({ text: $scope.chartTitle.item.title[level] });
      chart.setTitle(null, { text: $scope.chartTitle.item.subTitle[level] });

      chart.addSingleSeriesAsDrilldown(e.point, newSeries);
      chart.addSingleSeriesAsDrilldown(e.point, newQuantitySeries);
      chart.applyDrilldown();
      chart.hideLoading();

      var height = 400;
      chart.setSize(undefined, 400);
      if (total.length > 10) {
        height = total.length * 50;
        chart.setSize(undefined, height);
      }
    });
  }

  function getTopSpendingBySupplier(param) {
    topSpendingBySupplier.get(
      param,
      function(response) {
        if (!!response && !!response.data) {
          if (!response.data.length) {
            toastr.info('No data for top purchase by supplier.');
          }

          var name = [];
          var total = [];
          var totalPo = [];
          _.forEach(response.data, function(data){
            name.push(data.supplier_name);

            var object = {
              name: data.supplier_name,
              y: parseFloat(data.total),
              drilldown: data.supplier_code,
              code: data.supplier_code,
              level: 1
            };
            total.push(object);

            var totalPoObject = {
              name: data.supplier_name,
              y: parseFloat(data.total_po),
              drilldown: data.supplier_code,
              code: data.supplier_code,
              level: 1
            };
            totalPo.push(totalPoObject);
          });
          $scope.topSpendingBySupplierData['name'] = name;
          $scope.topSpendingBySupplierData['total'] = total;
          $scope.topSpendingBySupplierData['total_po'] = totalPo;
        }
      },
      function(response) {
        var messages = analyticsGlobalFunc.getErrorMessages(response);
        if (messages.length > 0) {
          toastr.error(messages);
        }
      }
    ).$promise.then(function(response) {
      var chart = $scope.chartFour.config.getChartObj();

      //Drill up to initial series
      if (!!chart.drilldownLevels && chart.drilldownLevels.length > 0) {
        for (var i=0; i<chart.drilldownLevels.length; i++) {
          chart.drillUp();
        }
      }

      chart.xAxis[0].update({
        categories: $scope.topSpendingBySupplierData['name']
      });

      chart.series[0].setData($scope.topSpendingBySupplierData['total']);
      chart.series[1].setData($scope.topSpendingBySupplierData['total_po']);

      chart.setTitle({ text: $scope.chartTitle.supplier.title[0] });
      chart.setTitle(null, { text: $scope.chartTitle.supplier.subTitle[0] });

      // Reset drill up button
      chart.drillUpButton = chart.drillUpButton.destroy();
    });
  }

  function getTopSpendingBySupplierDrillDown(e, param) {
    var chart = $scope.chartFour.config.getChartObj();
    chart.showLoading();

    topSpendingBySupplierDrillDown.get(
      param,
      function (response) {
        if (!!response && !!response.data) {
          if (!response.data.length) {
            toastr.info('No data for top spending by supplier at this level.');
          }
        }
      }
    ).$promise.then(function(response) {
      var name = [];
      var total = [];
      var totalPo = [];
      var itemQuantity = [];
      _.forEach(response.data, function(data){
        name.push(data.name);

        var object = {
          name: data.name,
          y: parseFloat(data.total)
        };

        var totalPoObject = {
          name: data.name,
          y: parseFloat(data.total_po)
        };

        if (e.point.level === 1) {
          object.code = data.code;
          object.company_code = data.code;
          object.supplier_code = param.supplier_code;
          object.drilldown = data.code;
          object.level = 2;

          totalPoObject.code = data.code;
          totalPoObject.company_code = data.code;
          totalPoObject.supplier_code = param.supplier_code;
          totalPoObject.drilldown = data.code;
          totalPoObject.level = 2;

          totalPo.push(totalPoObject);
        }

        if (e.point.level === 2) {
          object.code = data.code;
          object.supplier_code = e.point.supplier_code;
          object.company_code = e.point.company_code;
          object.cost_center_code = data.code;
          object.drilldown = data.code;
          object.level = 3;

          totalPoObject.code = data.code;
          totalPoObject.supplier_code = e.point.supplier_code;
          totalPoObject.company_code = e.point.company_code;
          totalPoObject.cost_center_code = data.code;
          totalPoObject.drilldown = data.code;
          totalPoObject.level = 3;

          totalPo.push(totalPoObject);
        }

        if (e.point.level === 3) {
          itemQuantity.push(parseFloat(data.quantity));
        }

        total.push(object);
      });

      var newSeries = {
          id: "total_spending_drilldown",
          name: "Total Spending (MYR)",
          data: total,
          color: '#8085e9',
          animation:false
        };

      var newSecondSeries = {};
      if (e.point.level === 1) {
        newSecondSeries = {
          id: "total_spending_drilldown_company",
          name: "Total PO by Company",
          data: totalPo,
          color: "#f45b5b"
        };
      }

      if (e.point.level === 2) {
        newSecondSeries = {
          id: "total_spending_drilldown_cc",
          name: "Total PO by Cost Center",
          data: totalPo,
          color: "#f45b5b"
        };
      }

      if (e.point.level === 3) {
        newSecondSeries = {
          id: "total_spending_drilldown_item",
          name: "Quantity",
          data: itemQuantity,
          color: "#f45b5b"
        };
      }

      chart.xAxis[0].update({
        categories: name
      });

      chart.setTitle({ text: $scope.chartTitle.supplier.title[param.level] });
      chart.setTitle(null, { text: $scope.chartTitle.supplier.subTitle[param.level] });

      chart.addSingleSeriesAsDrilldown(e.point, newSeries);
      chart.addSingleSeriesAsDrilldown(e.point, newSecondSeries);
      chart.applyDrilldown();
      chart.hideLoading();

      var height = 400;
      chart.setSize(undefined, height);

      if (total.length > 10) {
        height = 50 * total.length;
        chart.setSize(undefined, height);
      }
    });
  }

  function getTotalSpendingByExpenseType(param) {
    totalSpendingByExpenseType.get(
      param,
      function (response) {
        if (!!response && !!response.data) {
          if (!response.data.length) {
            toastr.info('No data for total spending by expense type.');
          }

          var value = [];
          _.forEach(response.data, function(data) {
            var object = {
              name: data.expense_type,
              expense_type: data.expense_type,
              y: parseFloat(data.total),
              drilldown: data.expense_type,
              level: 1
            };
            value.push(object);
          });

          $scope.totalSpendingByExpenseType['value'] = value;
        }
      },
      function(response) {
        var messages = analyticsGlobalFunc.getErrorMessages(response);
        if (messages.length > 0) {
          toastr.error(messages);
        }
      }
    ).$promise.then(function(response){
      var chart = $scope.chartSix.config.getChartObj();

      //Drill up to initial series
      if (!!chart.drilldownLevels && chart.drilldownLevels.length > 0) {
        for (var i=0; i<chart.drilldownLevels.length; i++) {
          chart.drillUp();
        }
      }

      chart.series[0].update({
        data: $scope.totalSpendingByExpenseType['value']
      });

      chart.setTitle({ text: $scope.chartTitle.expenseType.title[0] });
      chart.setTitle(null, { text: $scope.chartTitle.expenseType.subTitle[0] });

      // Reset drill up button
      chart.drillUpButton = chart.drillUpButton.destroy();

    });
  }

  function getTotalSpendingByExpenseTypDrillDown(e, param) {
    var chart = $scope.chartSix.config.getChartObj();
    chart.showLoading();

    totalSpendingByExpenseTypeDrillDown.get(
      param,
      function (response) {
        if (!!response && !!response.data) {
          if (!response.data.length) {
            toastr.info('No data for total spending by expense type at this level.');
          }
        }
      }
    ).$promise.then(function(response){
      var value = [];
      var level = parseInt(param.level) + 1;
      _.forEach(response.data, function(data) {
        var object = {
          name: data.name,
          y: parseFloat(data.total),
          expense_type: param.expense_type
        };

        // add company drilldown
        if (level > 1) {
          object.company_code = (data.company_code !== undefined) ? data.company_code : param['company_code[0]'];
          object.code = object.company_code;
        }

        // add cost center drilldown
        if (level > 2) {
          object.cost_center_code = (data.cost_center_code !== undefined) ? data.cost_center_code : param['cost_center_code[0]'];
          object.code = object.cost_center_code;
        }

        // set drilldown
        if (level < 4) {
          object.level = level;
          object.drilldown = object.code;
        }

        value.push(object);
      });

      var newSeries = {
        name: "Total Spending",
        data: value
      };

      chart.setTitle({ text: $scope.chartTitle.expenseType.title[param.level] });
      chart.setTitle(null, { text: $scope.chartTitle.expenseType.subTitle[param.level] });
      chart.addSeriesAsDrilldown(e.point, newSeries);
      chart.hideLoading();
    });
  }

  function initialize(){
    prepareData();
  }

  initialize();

}
AnalyticsReportCtrl.$inject = [
  '$scope', '$state', '$rootScope', 'globalFunc', 'toastr', '$uibModal', '$q',
  'activeBusinessUnits', 'getAllCompanies', 'companyCostCenters', 'analyticsParam',
  'topSpendingByCommodity', 'topSpendingByCommodityLevel', 'totalSpendingByItemType', 'totalSpendingByItemTypeDrillDown',
  'trendOfSpendingReport', 'trendOfSpendingReportDrillDown', 'topPurchaseItem', 'topPurchaseItemDrillDown',
  'topSpendingBySupplier', 'analyticsDashboard', 'analyticsExclusionPo', 'topSpendingBySupplierDrillDown',
  'totalSpendingByExpenseType', 'totalSpendingByExpenseTypeDrillDown', 'analyticsGlobalFunc'
];

angular.module('metabuyer')
  .controller('AnalyticsReportCtrl', AnalyticsReportCtrl);
