'use strict';

/**
 * @name catalogAdvancedSearchCtrl
 *
 * @description Controller for Catalog advanced search
 *
 * @author Chie Yang Teh <tehcy@sunway.com.my>
 * @copyright Sunway Metacloud &copy; 2023
 */
function catalogAdvancedSearchCtrl($scope, toastr, $rootScope, advancedSearch, exportSearch, metabuyerCache, $state ) {

  var searchCacheKey = 'catalogAdvancedSearch';

  var toastrErrorMessage = null;
  $scope.errors = {};
  $scope.statuses = [
    { key: 'ALL', value: 'All'},
    { key: 'APPROVED', value: 'Approved'},
    { key: 'COMPOSE', value: 'Compose'},
    { key: 'EXPIRED', value: 'Expired'},
    { key: 'ERROR', value: 'Error'},
    { key: 'DEACTIVATED', value: 'Deactivated'},
    { key: 'PENDING', value: 'Pending Approval'},
    { key: 'REJECTED', value: 'Rejected'},
  ]
  $scope.columns = [
    {id: 'catalog_group|descr', label: 'Catalog Group', selected: true},
    {id: 'catalog_code|code', label: 'Catalog Code', selected: true},
    {id: 'catalog_code|descr', label: 'Catalog Description', selected: true},
    {id: 'supplier|descr', label: 'Supplier', selected: true},
    {id: 'item_number', label: '# item', selected: true},
    {id: 'import_effective_date', label: 'Effective At', selected: true},
    {id: 'creator_info|display_name', label: 'Creator', selected: true},
    {id: 'created_at', label: 'Created Date', selected: true},
    {id: 'updater_info|display_name', label: 'Updated By', selected: true},
    {id: 'updated_at', label: 'Updated Date', selected: true},
 
  ];

  $scope.storageKey = 'catalog-management-selected-columns';

  var initialSearchFields = {
    catalogGroupName: null,
    catalogCode: null,
    catalogDescr: null,
    supplierName: null,
    status: $scope.statuses[0],
    createdBy: null,
    updatedBy: null,
    effectiveDateFrom: null,
    effectiveDateTo: null,
  };
  $scope.datePickerTimezone = $rootScope.datePickerTimezone;
  $scope.datepickerOpened = {
    effectiveDateFrom: false,
    effectiveDateTo: false,
  };
  var dateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };
  $scope.datePickerOptions = {
    effectiveDateFrom: _.clone(dateOptions),
    effectiveDateTo: _.clone(dateOptions),
  };

  $scope.catalogTitleTransformer = catalogTitleTransformer;
  $scope.getImportStatusName = getImportStatusName;
  $scope.showColumn = showColumn;
  $scope.openDatePicker = openDatePicker;
  $scope.submitAdvSearch = submitAdvSearch;
  $scope.resetForm = resetForm;
  $scope.addSearchCriteria = addSearchCriteria;
  $scope.searchModule = advancedSearch;

  /**
   * catalog title transformer
   * @param title
   */
  function catalogTitleTransformer (title) {
    if (!title) {
      return title;
    }
    title = title.replace(".zip", "");
    return title.replace(".csv", "");
  }

  /**
   * function to validate form input values
   * @returns {boolean|Object}
   */
   function validateForm() {
    /**
     * logic for range fields (total price, total cost of pr, and all date fields)
     * if `from` field is not empty, set `to` field to same value as `from` value
     * if `to` field is not empty, `from` field is mandatory.
     * if both are empty, skip validation
     *
     */

    //effective date range
    if (!!$scope.effectiveDateTo) {
      if (!!$scope.effectiveDateFrom) {
        if ($scope.effectiveDateFrom > $scope.effectiveDateTo) {
          $scope.errors.effectiveDate = 'Invalid Range';
        }
      } else {
        $scope.errors.effectiveDate = '\'From\' field is mandatory';
      }
    } else if (!!$scope.effectiveDateFrom) {
      $scope.effectiveDateTo = $scope.effectiveDateFrom;
    }

    if (_.isEmpty($scope.errors)) {
      var searchData = $scope.searchFields ? _.clone($scope.searchFields) : [];
      return buildParam(searchData);
    } else {
      toastrErrorMessage = 'Invalid value in one or more fields';
      return false;
    }
  }

  /**
   * function to build criteria parameters
   * @param data object of form input values
   * @returns {boolean|Object}
   */
  function buildParam(data) {

    var params = {};

    if (!!data.catalogGroupName) {
      params['criteria[0][catalog_group|descr]'] = data.catalogGroupName;
    }

    if (!!data.catalogDescr) {
      params['criteria[0][catalog_code|descr]'] = data.catalogDescr;
    }

    if (!!data.catalogCode) {
      params['criteria[0][catalog_code|code]'] = data.catalogCode;
    }

    if (!!data.status) {
      params['criteria[0][import_status]'] = data.status.key;
    }

    if (!!data.supplierName) {
      params['criteria[0][supplier|descr]'] = data.supplierName;
    }

    if (!!data.createdBy) {
      params['criteria[0][creator|display_name]'] = data.createdBy;
    }

    if (!!data.updatedBy) {
      params['criteria[0][updater|display_name]'] = data.updatedBy;
    }

    if (!!data.effectiveDateFrom) {
      params['criteria[0][import_effective_date][from]'] = moment(data.effectiveDateFrom).valueOf();
      params['criteria[0][import_effective_date][to]'] = moment(data.effectiveDateTo).endOf('day').valueOf();
    }

    // if status 'all' is selected, the form cannot be empty. so the criteria for 'all' status
    // will only be added if `params` is not empty.
    if (_.isEmpty(params)) {
      toastrErrorMessage = 'Fill in at least one field';
      return false;
    } else {
      params['module'] = 'catalogs';
      params['offset'] = 50;
      params['criteria[0][$operator]'] = 'and';
      return params;
    }
  }

  /**
   *
   * @param $event
   * @param field
   */
   function openDatePicker($event, field) {
    $event.preventDefault();
    $event.stopPropagation();
    if (field === 'effectiveDateFrom') {
      $scope.datePickerOptions.effectiveDateFrom.maxDate = $scope.effectiveDateTo || null;
      $scope.datepickerOpened.effectiveDateFrom = true;
      delete $scope.errors.effectiveDate;
    } else if (field === 'effectiveDateTo') {
      $scope.datePickerOptions.effectiveDateTo.minDate = $scope.effectiveDateFrom || null;
      $scope.datepickerOpened.effectiveDateTo = true;
      delete $scope.errors.effectiveDate;
    }
  }

  /**
   * returns the status name
   * @param status
   * @returns {*}
   */
  function getImportStatusName(status) {
    /**
     Compose = 0
     Pending = 1
     Approved = 2
     Deleted = 3
     Expired = 4
     Rejected = 5
     Active = 6
     Deactivated = 7
     Error = 8
     */
    if (status === 'composed') {
      return 'Compose'
    }
    if (status === 'pending') {
      return 'Pending'
    }
    if (status === 'approved') {
      return 'Approved'
    }
    if (status === 'deleted') {
      return 'Deleted'
    }
    if (status === 'expired') {
      return 'Expired'
    }
    if (status === 'rejected') {
      return 'Rejected'
    }
    if (status === 'active') {
      return 'Active'
    }
    if (status === 'deactivated') {
      return 'Deactivated'
    }
    if (status === 'error') {
      return 'Error'
    }
    if (status === 'cancelled') {
      return 'Cancelled'
    }
    return 'N/A';
  }

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  /**
   * function to submit search form
   */
  function submitAdvSearch(isSkip) {
    var searchParam = validateForm();

    if (!!searchParam) {
      $scope.embeddedParams = searchParam;

      /**
       * Setting the params to prepare for exporting
       */
      exportSearch.setParams(searchParam);

      advancedSearch.get(
        searchParam,
        function (resource) {
          $scope.dataList = resource.content.data;
          $scope.meta = resource.content.meta;
          $scope.emptyInput = false;
        },
        function (error) {
          toastr.error('Something went wrong');
          $scope.dataList = [];
        }
      );
    } else if(!isSkip) {
      toastr.error(toastrErrorMessage);
      toastrErrorMessage = null;
      $scope.emptyInput = true;
    }
    checkCurrentState();
  }

  /**
   * function to reset the search form
   */
  function resetForm() {
    $scope.searchFields = _.cloneDeep(initialSearchFields);
    $scope.dataList = [];
    metabuyerCache.remove(searchCacheKey);
  }

  function addSearchCriteria(key, value) {
    var tempSearchCriteriaCache = metabuyerCache.get(searchCacheKey);
  
    if (!!tempSearchCriteriaCache) {
      $scope.searchFields = _.cloneDeep(tempSearchCriteriaCache);
    } else {
      $scope.searchFields = {};
    }  
    $scope.searchFields[key] = value;
    metabuyerCache.put(searchCacheKey, $scope.searchFields);
  }

  function retainSearchCriteria(){

    var searchCriteriaCache = metabuyerCache.get(searchCacheKey);
    $scope.searchFields = _.cloneDeep(searchCriteriaCache);
  }

  //check and save the current state
  function checkCurrentState() {
    var currentStateName = $state.current.name;
    $rootScope.advancedSearchState = currentStateName;
  };

  function initialize() {
    var searchCriteriaCache = metabuyerCache.get(searchCacheKey);
    if(!!searchCriteriaCache && !_.isEmpty(searchCriteriaCache)){
      retainSearchCriteria()
    }
    submitAdvSearch(true);
  }
  initialize();
}

catalogAdvancedSearchCtrl.$inject = [
  '$scope', 'toastr', '$rootScope', 'advancedSearch', 'exportSearch', 'metabuyerCache', '$state'
];

angular.module('metabuyer')
  .controller('catalogAdvancedSearchCtrl', catalogAdvancedSearchCtrl);
