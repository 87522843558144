'use strict';
angular.module('metabuyer')
  .directive('skipAutoSendPo', function()  {
    return {
      restrict: 'E',
      templateUrl: 'components/skipAutoSendPO/skipAutoSendPOTemplate.html',
      scope: {
        prid: '=',
        editable: '=',
        status: '='
      },
      controller: function ($scope, prFunction, purchaseRequisitionsServices, globalFunc, $rootScope) {
        $scope.skipAutoSendPO = false;
        var backupSkipSendingPO;

        $scope.updateSendingRules = updateSendingRules;

        /**
         * Send the option for the PO to be sent after generation automatically or not
         *
         * @param blockSendingPO
         */
        function updateSendingRules(blockSendingPO) {
          if(backupSkipSendingPO === blockSendingPO)
            return;

          purchaseRequisitionsServices.updateSkipAutoSendPO({
            id: prFunction.getPRData()._id
          }, {
            skip_auto_send_po: blockSendingPO
          }, function (resource) {
            if (!!resource.content && !!resource.content.data) {
              prFunction.setPRData(resource.content.data);
            }
            $rootScope.$broadcast('refreshComments');
            initialize();
          }, function (error) {
            if (error.content.error !== 'Supplier Ids should be set') {
              globalFunc.objectErrorMessage(error);
            }
          });
        }


        /**
         * init function to load the list of suppliers Then the notes to prepare the drop-down list
         */
        function initialize() {

          $scope.skipAutoSendPO = !!prFunction.getPRData().skip_auto_send_po;
          backupSkipSendingPO = _.cloneDeep($scope.skipAutoSendPO);

        }

        initialize();

      }
    }
  });
