'use strict';

function supplierUserDetailModalCtrl($scope, $uibModalInstance, currencies, timezones, user, supplier, toastr, supplierUserService) {
  $scope.saveAs = false;
  $scope.currencies = currencies;
  $scope.timezones = timezones;
  $scope.supplier = supplier;
  $scope.user = user;
  // disable configuration for supplier user detail
  $scope.disableConfig = {
    supplier: true,
    email: false
  }

  $scope.cancel = cancel;
  $scope.create = create;

  function cancel() {
    $uibModalInstance.close(false);
  }

  function create() {
    if (validateUser()) {
      var payload = _.clone($scope.user);

      delete payload.isExistingEmail;
      delete payload.isValidPhone;
      payload.sourcing_supplier_id = $scope.supplier._id;

      supplierUserService.post(
        {},
        payload,
        function (resource) {
          toastr.success('Supplier User successfully created');
          $uibModalInstance.close(true);
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      )
    } else {
      toastr.error('Please complete all required fields before proceeding');
    }
  }

  function validateUser() {
    $scope.saveAs = true;

    if (!$scope.user.display_name || !$scope.user.display_name.length) {
      return false;
    }

    if (!$scope.user.email || !$scope.user.email.length) {
      return false;
    }

    if ($scope.user.isExistingEmail) {
      return false;
    }

    if (!$scope.user.isValidPhone) {
      return false;
    }

    if (!$scope.user.currency || !$scope.user.currency.length) {
      return false;
    }

    if (!$scope.user.config || !$scope.user.config.TZ.length) {
      return false;
    }

    return true;
  }
}

supplierUserDetailModalCtrl.$inject = [
  '$scope', '$uibModalInstance', 'currencies', 'timezones', 'user', 'supplier', 'toastr', 'supplierUserService'
];

angular
  .module('metabuyer')
  .controller('supplierUserDetailModalCtrl', supplierUserDetailModalCtrl);
