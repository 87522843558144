'use strict';
/**
 * @name MasterDataManageItemMastersCtrl
 * @desc Controller for master data item masters controller
 */
function MasterDataManageItemMastersCtrl($scope, dataList, itemMasterMasterDataList,
                                          toastr, $uibModal, $location,pathConstants, globalFunc, $stateParams, $rootScope) {
  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.itemMasterMasterDataList = itemMasterMasterDataList;
  $scope.addNewItemMaster = addNewItemMaster;
  $scope.itemMastersDetails = itemMastersDetails;
  $scope.search = search;
  $scope.showColumn = showColumn;
  $scope.importItemMaster = importItemMaster;
  $scope.params = {};
  $scope.itemMaster = {};
  $scope.convertBoolean = convertBoolean;
  $scope.getActiveStatusName = getActiveStatusName;
  $scope.expenseTypeCategories = [
    {
      value: 'CAPEX',
      descr: 'CAPEX'
    },
    {
      value: 'OPEX',
      descr: 'OPEX'
    }
  ];
  $scope.yesNoOptions = [
    {
      value: '1',
      descr: 'Yes'
    },
    {
      value: '0',
      descr: 'No'
    }
  ];

  $scope.columns = [
    {id: 'code', label: 'Item Code'},
    {id: 'name', label: 'Item Name'},
    {id: 'is_pcm', label: 'Purchase Control Item'},
    {id: 'item_category|code', label: 'Item Category Code'},
    {id: 'item_category|name', label: 'Item Category Name'},
    {id: 'created_at', label: 'Created At'},
    {id: 'creator_info|display_name', label: 'Created By'},
    {id: 'updated_at', label: 'Updated At'},
    {id: 'updater_info|display_name', label: 'Updated By'},
    {id: 'is_active', label: 'Status'}
  ];

  $scope.storageKey = 'master-data-item-masters-management-selected-columns';

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function importItemMaster() {
    $uibModal.open({
      templateUrl: 'app/masterDataManagement/Import-Template.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        importLink: function () {
          return pathConstants.apiUrls.imports.now;
        },
        params: function () {
          return {
            'class': 'App\\Metabuyer\\ItemMaster\\Models\\ItemMaster',
          }
        },
        title: function () {
          return 'Item Master';
        }
      },
      controller: function ($scope, $uibModalInstance, importLink, title, params) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        $scope.importLink = importLink;
        $scope.title = title;
        $scope.params = params;
      }
    });
  }

  function addNewItemMaster() {
    $scope.submitted = true;
    if(!validate($scope.itemMaster)) {
      return;
    }

    itemMasterMasterDataList.post(
      {
        code: $scope.itemMaster.code,
        name: $scope.itemMaster.name,
        descr: $scope.itemMaster.descr,
        expense_type_category: !!$scope.itemMaster.expense_type_category.value ? $scope.itemMaster.expense_type_category.value : undefined,
        is_pcm: $scope.itemMaster.is_pcm.value,
        is_stock: $scope.itemMaster.is_stock.value
      },
      function (resource) {
        toastr.success('A new Item Master has been added successfully');
        var addedItemMaster = resource.content.data;
        $scope.dataList.unshift({
          _id: addedItemMaster._id,
          code: addedItemMaster.code,
          name: addedItemMaster.name,
          descr: addedItemMaster.descr,
          expense_type_category: addedItemMaster.expense_type_category,
          created_at: addedItemMaster.created_at,
          updated_at: addedItemMaster.updated_at,
          creator: [$scope.user],
          updater: [$scope.user]
        });
        $scope.submitted = false;
        $scope.itemMaster = {};
        $scope.itemMaster.expense_type_category = globalFunc.findInArray($scope.expenseTypeCategories, 'value', null);
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function search(query) {
    $location.search({query: query});
  }

  function validate(itemMaster) {
    if (!itemMaster) {
      return false;
    }
    if (!itemMaster.code) {
      return false;
    }
    if (!itemMaster.name) {
      return false;
    }
    if (!itemMaster.descr) {
      return false;
    }
    if (!itemMaster.expense_type_category) {
      return false;
    }
    if (!itemMaster.is_pcm) {
      return false;
    }
    if (!itemMaster.is_stock) {
      return false;
    }

    return true;
  }

  function itemMastersDetails(index) {
    var itemMasterId = $scope.dataList[index]._id;
    var modalInstance = $uibModal.open({
      templateUrl: 'app/masterDataManagement/itemMasters/details/details.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'itemMasterDetailsCtrl',
      size: 'lg',
      resolve: {
        itemMasterDetails: function($q, itemMasterMasterDataList) {
          var deferred = $q.defer();
          itemMasterMasterDataList.get(
            {
              id: itemMasterId
            },
            function (resource) {
              deferred.resolve(resource.content || {data: []});
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        }
      }
    });

    modalInstance.result.then(function (newValue) {
      if(!!newValue){
        _.forEach($scope.dataList, function(dataList){
          if(dataList._id === newValue._id){
            dataList.code = newValue.code;
            dataList.descr = newValue.descr;
            dataList.is_pcm = newValue.is_pcm;
            dataList.item_category.name = newValue.item_category.name;
            dataList.item_category.code = newValue.item_category.code;
            dataList.is_active = newValue.is_active;
            dataList.updater[0].display_name = newValue.updated_by;
            dataList.updated_at = newValue.updated_at;
          }
        });
      }
    }, function () {
    });
  }

  function convertBoolean(value) {
    return value === true ? 'Yes' : value === false ? 'No' : value;
  }

  function initialize(){
    $scope.itemMaster.expense_type_category = globalFunc.findInArray($scope.expenseTypeCategories, 'value', null);

    /**
     * setting the model with the searched text
     */
    if(!!$stateParams.search){
      $scope.searchText = $stateParams.search;
    }
    else{
      $scope.searchText = '';
    }
  }

  /**
   * returns the is_active name
   * @param code
   * @returns {*}
   */
  function getActiveStatusName(code){
    return code === true ? 'Active' : code === false ? 'Deactivated' : code;
  }

  initialize();
}

MasterDataManageItemMastersCtrl.$inject = [
  '$scope', 'dataList', 'itemMasterMasterDataList', 'toastr', '$uibModal',
  '$location', 'pathConstants', 'globalFunc', '$stateParams', '$rootScope'
];

angular
  .module('metabuyer')
  .controller('MasterDataManageItemMastersCtrl', MasterDataManageItemMastersCtrl);
