/**
 * @name metabuyer.services.escalation
 *
 * @description
 *
 * Service for adding and removing escalation
 *
 * @requires $resource
 * @requires pathConstants (Metabuyer service)
 *
 * @factory addEscalation
 * @param id
 * the ID of the intended user to have an escalation from
 * @param mid
 * the ID of the user to be escalated to
 * @payload company_code
 * the company code where the escalation is taking place
 *
 * @factory removeEscalation
 * @param id
 * the ID of the intended user to remove the escalation option
 * @payload company_code
 * the company code where the escalation is taking place
 *
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Verve Technologies Sdn. Bhd. &copy; 2015
 */

'use strict';

angular.module('metabuyer.services.escalation', ['ngResource'])
  .factory('addEscalation',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.escalation.add,
      {
        id: '@id',
        mid: '@mid'
      },
      {
        'post': {method: 'POST'}
      }
    );
  })
  .factory('removeEscalation',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.escalation.delete,
      {
        id: '@id'
      },
      {
        'delete': {method: 'DELETE'}
      }
    );
  });
