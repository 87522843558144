'use strict';

function kharonUserCtrl($scope, dataList, toastr, $uibModal, globalFunc, kharonUser, companyList) {
  $scope.dataList = dataList.content && dataList.content.data || [];
  $scope.companyList = companyList.content && companyList.content.data || [];

  $scope.editUser = editUser;

  function editUser(companyList, data) {
    $uibModal.open({
      templateUrl: 'app/settings/kharonSettings/user/editUserModal.html',
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      resolve:{
        data: function(){
          return data;
        },
        companyList: function(){
          return companyList;
        }
      },
      controller: function ($scope, $uibModalInstance, toastr, $state, kharonUser, globalFunc) {

        $scope.isCreation = true;

        if (!!data) {
          $scope.name = data.name;
          $scope.username = data.username;
          $scope.password = data.password;
          $scope.company_codes = data.company_codes;
          $scope.is_active = data.is_active;
          $scope.created_at = data.created_at;
          $scope.updated_at = data.updated_at;
          $scope.isCreation = false;
        }

        $scope.companyList = companyList;
        $scope.userType = {
          selected: !!data && !!data.type ? {
            value: data.type
          } : {}
        };
        $scope.selectedCompanies = [];
        $scope.userTypes = [
          {
            value: 'ADMIN'
          },
          {
            value: 'SYNERGY'
          },
          {
            value: 'KHARON'
          }
        ];

        $scope.submit = submit;
        $scope.companySelected = companySelected;
        $scope.companyRemoved = companyRemoved;
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        function validate() {
          if ($scope.isCreation) {
            if (!(!!$scope.name && !!$scope.userType.selected.value && !!$scope.username && !!$scope.password))
              return false;
          }

          return true
        }

        function submit(){
          $scope.submitted = true;
          // some validation here
          if (!validate()) {
            toastr.error('Please fill up all fields.');
            return;
          }
          swal({
            title: 'Confirm update Kharon User?',
            text: 'This action is not revertable.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Confirm',
            closeOnConfirm: true
          }, function (isConfirm) {
            if (isConfirm) {
              if ($scope.isCreation)
                confirmCreate();
              else
                confirmUpdate();
            }
          });
        }

        function companySelected(company) {
          if (!globalFunc.findInArray($scope.selectedCompanies, '_id', company._id))
            $scope.selectedCompanies.push(company);
        }

        function companyRemoved(company) {
          $scope.selectedCompanies =
            globalFunc.removeValueFromArray($scope.selectedCompanies, '_id', company._id);
        }

        function prepareCreationData() {
          var companyCodes = _.map($scope.selectedCompanies, function(com) {
            return com.code;
          });

          var preparedData = {
            name: $scope.name,
            type: $scope.userType.selected.value,
            username: $scope.username,
            password: $scope.password,
            company_codes: companyCodes,
            is_active: $scope.is_active
          };

          return preparedData;
        }

        function confirmCreate() {
          var preparedData = prepareCreationData();

          kharonUser.post(
            {},
            preparedData,
            function() {
              toastr.success("Created Kharon User.");
              $uibModalInstance.close();
              $state.reload();
              close();
            },
            function() {
              toastr.error("Failed to create Kharon User.");
            });
        }

        function confirmUpdate() {
          var companyCodes = _.map($scope.selectedCompanies, function(com) {
            return com.code;
          });

          kharonUser.put(
            {
              id: data.id
            },
            {
              company_codes: companyCodes,
              password: $scope.password !== data.password ? $scope.password : undefined,
              is_active: $scope.is_active
            },
            function() {
              toastr.success("Updated Kharon User.");
              $uibModalInstance.close();
              $state.reload();
              close();
            },
            function() {
              toastr.error("Failed to update Kharon User.");
            });
        }

        function initialize() {
          $scope.selectedCompanies = _.map($scope.company_codes, function(code) {
            return _.find($scope.companyList, function(e) {
              return e.code === code;
            });
          });
        }

        initialize();
      }
    });
  }
}

kharonUserCtrl.$inject = ['$scope', 'dataList', 'toastr', '$uibModal', 'globalFunc', 'kharonUser', 'companyList'];

angular
    .module('metabuyer')
    .controller('kharonUserCtrl', kharonUserCtrl);
