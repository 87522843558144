'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function ($stateProvider, pathConstants) {

    $stateProvider
      .state('main.user.masterData', {
        url: '/master-data',
        controller: 'MasterDataCtrl',
        abstract: true,
        templateUrl: 'app/masterDataManagement/master-data.html'
      })
      .state('main.user.masterData.currency', {
        templateUrl: 'app/masterDataManagement/currency/manage/manage.html',
        url: '/currency?{query:[^~,]}&{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'MasterDataManageCurrencyCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'Currencies', 'Create') ||
              !UserPermissions.checkPermissionsAccess(profile, 'Currencies', 'Update') ||
              !UserPermissions.checkPermissionsAccess(profile, 'Currencies', 'Delete'))  {
              $state.go('notAuthorized');
            }
          },
          isDeveloper: function (profile, UserPermissions){
            return !!UserPermissions.isDeveloper(profile);
          },
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();
            var params = {
              module: 'currencies',
              'criteria[code]': $stateParams.query,
              'criteria[descr]': $stateParams.query,
              criteria_operator: !!$stateParams.query ? 'or' : null,
              offset: 20,
              page: $stateParams.page,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'is_active',
              order: !!$stateParams.order ? $stateParams.order : 0
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Currencies - Master Data';
          }
        }
      })
      .state('main.user.masterData.exchangeRate', {
        templateUrl: 'app/masterDataManagement/exchangeRate/manage/manage.html',
        url: '/exchangerate?{query:[^~,]}&{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'MasterDataManageExchangeRateCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'ExchangeRate', 'Create') ||
              !UserPermissions.checkPermissionsAccess(profile, 'ExchangeRate', 'Update') ||
              !UserPermissions.checkPermissionsAccess(profile, 'ExchangeRate', 'Delete'))  {
              $state.go('notAuthorized');
            }
          },
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();
            var params = {
                module: 'exchange-rate',
                'criteria[1][code]': $stateParams.query,
                'criteria[1][descr]': $stateParams.query,
                'criteria[1][$operator]': !!$stateParams.query ? 'or' : null,
                criteria_operator: !!$stateParams.query ? 'and' : null,
                offset: 20,
                'criteria[0][company_id]': '',
                page: $stateParams.page,
                order_by: !!$stateParams.order_by ? $stateParams.order_by : 'is_active',
                order: !!$stateParams.order ? $stateParams.order : 0
              };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          currencies: function($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'currencies',
                offset: 0
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Exchange Rates - Master Data';
          }
        }
      })
      .state('main.user.masterData.uom', {
        templateUrl: 'app/masterDataManagement/uom/manage/manage.html',
        url: '/uom?{query:[^~,]}&{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'MasterDataManageUOMCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
          if (!UserPermissions.checkPermissionsAccess(profile, 'UOM', 'Create') ||
            !UserPermissions.checkPermissionsAccess(profile, 'UOM', 'Update') ||
            !UserPermissions.checkPermissionsAccess(profile, 'UOM', 'Delete'))  {
            $state.go('notAuthorized');
          }
        },
          uomList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();

            var params = {
                module: 'uom',
                'criteria[code]': $stateParams.query,
                'criteria[descr]': $stateParams.query,
                'criteria[descr_chinese]': $stateParams.query,
                criteria_operator: !!$stateParams.query ? 'or' : null,
                offset: 20,
                page: $stateParams.page,
                order_by: !!$stateParams.order_by ? $stateParams.order_by : 'is_active',
                order: !!$stateParams.order ? $stateParams.order : 0
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Units of Measurement - Master Data';
          }
        }
      })
      .state('main.user.masterData.paymentTerms', {
        templateUrl: 'app/masterDataManagement/paymentTerms/manage/manage.html',
        url: '/payment-terms?{query:[^~,]}&{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'masterDataManagePaymentTermsCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'PaymentTerm', 'Create') ||
              !UserPermissions.checkPermissionsAccess(profile, 'PaymentTerm', 'Update') ||
              !UserPermissions.checkPermissionsAccess(profile, 'PaymentTerm', 'Delete'))  {
              $state.go('notAuthorized');
            }
          },
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();

            var params = {
              module: 'payment-term',
              'criteria[code]': $stateParams.query,
              'criteria[descr]': $stateParams.query,
              criteria_operator: !!$stateParams.query ? 'or' : null,
              offset: 20,
              page: $stateParams.page,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'is_active',
              order: !!$stateParams.order ? $stateParams.order : 0
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Payment Terms - Master Data';
          }
        }
      })
      .state('main.user.masterData.paymentMethods', {
        templateUrl: 'app/masterDataManagement/paymentMethods/manage/manage.html',
        url: '/payment-methods?{query:[^~,]}&{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'masterDataPaymentMethodsCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'PaymentMethod', 'Create') ||
              !UserPermissions.checkPermissionsAccess(profile, 'PaymentMethod', 'Update') ||
              !UserPermissions.checkPermissionsAccess(profile, 'PaymentMethod', 'Delete'))  {
              $state.go('notAuthorized');
            }
          },
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();

            var params = {
              module: 'payment-method',
              'criteria[code]': $stateParams.query,
              'criteria[descr]': $stateParams.query,
              criteria_operator: !!$stateParams.query ? 'or' : null,
              offset: 20,
              page: $stateParams.page,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'is_active',
              order: !!$stateParams.order ? $stateParams.order : 0
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Payment Methods - Master Data';
          }
        }
      })
      .state('main.user.masterData.commodityCode', {
        templateUrl: 'app/masterDataManagement/commodityCode/manage/manage.html',
        url: '/commodity-code?{query:[^~,]}&{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}&{parentUniqueName:[^~,]}',
        controller: 'masterDataCommodityCodeCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'Commodity', 'Create') ||
              !UserPermissions.checkPermissionsAccess(profile, 'Commodity', 'Update') ||
              !UserPermissions.checkPermissionsAccess(profile, 'Commodity', 'Delete'))  {
              $state.go('notAuthorized');
            }
          },
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();

            /**
             * pass parent_unique_name as All in order to list segments
             * Strict field is for determining if search criteria is strict searching, 1 for true 0 for false(default)
             * If parentUniqueName was passed into state parameters, list child instead with strict 1
             */
            var params = {
              module: 'commodity',
              'criteria[code]': $stateParams.query,
              'criteria[descr]': $stateParams.query,
              'criteria[descr_chinese]': $stateParams.query,
              'criteria[parent_unique_name]': !!$stateParams.query ? null : ($stateParams.parentUniqueName ? $stateParams.parentUniqueName : 'All'),
              criteria_operator: !!$stateParams.query ? 'or' : null,
              strict: $stateParams.parentUniqueName ? 1: 0,
              offset: 20,
              page: $stateParams.page,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'is_active',
              order: !!$stateParams.order ? $stateParams.order : 0
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                if(!!resource.content) {
                  deferred.resolve(resource.content);
                }else{
                  deferred.resolve([]);
                }
              },
              function (error) {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Commodity Codes - Master Data';
          }
        }
      })
      .state('main.user.masterData.taxes', {
        templateUrl: 'app/masterDataManagement/taxes/manage/manage.html',
        url: '/taxes?{query:[^~,]}&{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'MasterDataManageTaxesCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'Tax', 'Create') ||
              !UserPermissions.checkPermissionsAccess(profile, 'Tax', 'Update') ||
              !UserPermissions.checkPermissionsAccess(profile, 'Tax', 'Delete'))  {
              $state.go('notAuthorized');
            }
          },
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();

            var params = {
              module: 'tax',
              'criteria[code]': $stateParams.query,
              'criteria[descr]': $stateParams.query,
              criteria_operator: !!$stateParams.query ? 'or' : null,
              offset: 20,
              page: $stateParams.page,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'is_active',
              order: !!$stateParams.order ? $stateParams.order : 0
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Taxes - Master Data';
          }
        }
      })
      .state('main.user.masterData.expenseTypes', {
        templateUrl: 'app/masterDataManagement/expenseTypes/manage/manage.html',
        url: '/expense-types?{query:[^~,]}&{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'MasterDataManageExpenseTypesCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'ExpenseTypes', 'Create') ||
              !UserPermissions.checkPermissionsAccess(profile, 'ExpenseTypes', 'Update') ||
              !UserPermissions.checkPermissionsAccess(profile, 'ExpenseTypes', 'Delete'))  {
              $state.go('notAuthorized');
            }
          },
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();

            var params = {
              module: 'expense-type',
              'criteria[code]': $stateParams.query,
              'criteria[descr]': $stateParams.query,
              criteria_operator: !!$stateParams.query ? 'or' : null,
              offset: 20,
              page: $stateParams.page,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'is_active',
              order: !!$stateParams.order ? $stateParams.order : 0
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Expense Types - Master Data';
          }
        }
      })
      .state('main.user.masterData.itemMasters', {
        templateUrl: 'app/masterDataManagement/itemMasters/manage/manage.html',
        url: '/item-masters?{query:[^~,]}&{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'MasterDataManageItemMastersCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'ItemMaster', 'Create') ||
              !UserPermissions.checkPermissionsAccess(profile, 'ItemMaster', 'Update') ||
              !UserPermissions.checkPermissionsAccess(profile, 'ItemMaster', 'Delete'))  {
              $state.go('notAuthorized');
            }
          },
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();

            var params = {
              module: 'item-master',
              offset: 20,
              page: $stateParams.page,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'is_active',
              order: !!$stateParams.order ? $stateParams.order : 0
            };

             //prepare search query
             if (!!$stateParams.query) {
              if (!!$stateParams.filter) {
                params['criteria[1][' + $stateParams.filter + ']'] = $stateParams.query;
              } else {
                params['criteria[1][code]'] = $stateParams.query;
                params['criteria[1][name]'] = $stateParams.query;
                params['criteria[1][item_category|code]'] = $stateParams.query;
                params['criteria[1][item_category|name]'] = $stateParams.query;
                params['criteria[1][$operator]'] = 'or';
              }
            }

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Item Masters - Master Data';
          }
        }
      })
      .state('main.user.masterData.itemCategories', {
        templateUrl: 'app/masterDataManagement/itemCategories/manage/manage.html',
        url: '/item-categories?{query:[^~,]}&{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'MasterDataManageItemCategoriesCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'ItemCategory', 'Create') ||
              !UserPermissions.checkPermissionsAccess(profile, 'ItemCategory', 'Update') ||
              !UserPermissions.checkPermissionsAccess(profile, 'ItemCategory', 'Delete'))  {
              $state.go('notAuthorized');
            }
          },
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();

            var params = {
              module: 'item-category',
              'criteria[0][name]': $stateParams.query,
              'criteria[0][code]': $stateParams.query,
              'criteria[0][descr]': $stateParams.query,
              'criteria[0][$operator]': !!$stateParams.query ? 'or' : null,
              criteria_operator: !!$stateParams.query ? 'or' : null,
              offset: 20,
              page: $stateParams.page,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'is_active',
              order: !!$stateParams.order ? $stateParams.order : 0
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Item Categories - Master Data';
          }
        }
      })
      .state('main.user.masterData.stockInformationGroups', {
        templateUrl: 'app/masterDataManagement/stockInformationGroups/manage/manage.html',
        url: '/stock-information-groups?{query:[^~,]}&{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'MasterDataManageStockInformationGroupsCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'StockInformationGroups', 'Create') ||
              !UserPermissions.checkPermissionsAccess(profile, 'StockInformationGroups', 'Update') ||
              !UserPermissions.checkPermissionsAccess(profile, 'StockInformationGroups', 'Delete'))  {
              $state.go('notAuthorized');
            }
          },
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();

            var params = {
              module: 'stock-information-group',
              'criteria[0][name]': $stateParams.query,
              'criteria[0][code]': $stateParams.query,
              'criteria[0][descr]': $stateParams.query,
              'criteria[0][$operator]': !!$stateParams.query ? 'or' : null,
              criteria_operator: !!$stateParams.query ? 'or' : null,
              offset: 20,
              page: $stateParams.page,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'is_active',
              order: !!$stateParams.order ? $stateParams.order : 0
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Stock Information Groups - Master Data';
          }
        }
      })
      .state('main.user.masterData.alcGroups', {
        templateUrl: 'app/masterDataManagement/alcGroups/manage/manage.html',
        url: '/alc-groups?{query:[^~,]}&{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'MasterDataManageAlcGroupsCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'AlcGroups', 'Create') ||
              !UserPermissions.checkPermissionsAccess(profile, 'AlcGroups', 'Update') ||
              !UserPermissions.checkPermissionsAccess(profile, 'AlcGroups', 'Delete'))  {
              $state.go('notAuthorized');
            }
          },
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();

            var params = {
              module: 'alc-group',
              'criteria[0][name]': $stateParams.query,
              'criteria[0][code]': $stateParams.query,
              'criteria[0][descr]': $stateParams.query,
              'criteria[0][$operator]': !!$stateParams.query ? 'or' : null,
              criteria_operator: !!$stateParams.query ? 'or' : null,
              offset: 20,
              page: $stateParams.page,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'is_active',
              order: !!$stateParams.order ? $stateParams.order : 0
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Approval Limit Control Groups - Master Data';
          }
        }
      })
      .state('main.user.masterData.modeOfPurchase', {
        templateUrl: 'app/masterDataManagement/modeOfPurchase/manage/manage.html',
        url: '/mode-of-purchase?{query:[^~,]}&{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'ModeOfPurchaseCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'ModeOfPurchase', 'Create') ||
              !UserPermissions.checkPermissionsAccess(profile, 'ModeOfPurchase', 'Update') ||
              !UserPermissions.checkPermissionsAccess(profile, 'ModeOfPurchase', 'Delete'))  {
              $state.go('notAuthorized');
            }
          },
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();

            var params = {
              module: 'mode-of-purchase',
              'criteria[0][name]': $stateParams.query,
              'criteria[0][code]': $stateParams.query,
              'criteria[0][$operator]': !!$stateParams.query ? 'or' : null,
              criteria_operator: !!$stateParams.query ? 'or' : null,
              offset: 20,
              page: $stateParams.page,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'is_active',
              order: !!$stateParams.order ? $stateParams.order : 0
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Mode Of Purchase - Master Data';
          }
        }
      })
      .state('main.user.masterData.categoryType', {
        templateUrl: 'app/masterDataManagement/categoryType/manage/manage.html',
        url: '/category-type?{query:[^~,]}&{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'CategoryTypeCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            return !!UserPermissions.isDeveloper(profile);
          },
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();

            var params = {
              module: 'category-type',
              'criteria[0][name]': $stateParams.query,
              'criteria[0][code]': $stateParams.query,
              'criteria[0][$operator]': !!$stateParams.query ? 'or' : null,
              criteria_operator: !!$stateParams.query ? 'or' : null,
              offset: 20,
              page: $stateParams.page,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'is_active',
              order: !!$stateParams.order ? $stateParams.order : 0
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Category Type - Master Data';
          }
        }
      })
      .state('main.user.masterData.itemGroup', {
        templateUrl: 'app/masterDataManagement/itemGroup/manage/manage.html',
        url: '/item-group?{query:[^~,]}&{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'ItemGroupCtrl',
        resolve: {
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();

            var params = {
              module: 'item-group',
              'criteria[0][name]': $stateParams.query,
              'criteria[0][code]': $stateParams.query,
              'criteria[0][item_categories|code]': $stateParams.query,
              'criteria[0][item_codes|code]': $stateParams.query,
              'criteria[0][$operator]': !!$stateParams.query ? 'or' : null,
              criteria_operator: !!$stateParams.query ? 'or' : null,
              offset: 20,
              page: $stateParams.page,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'is_active',
              order: !!$stateParams.order ? $stateParams.order : 0
            };

            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Item Group - Master Data';
          }
        }
      })
      .state('main.user.masterData.sourcingExpenseType', {
        templateUrl: 'app/masterDataManagement/sourcingExpenseType/manage/manage.html',
        url: '/sourcing-expense-type?{query:[^~,]}&{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'SourcingExpenseTypeCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!$rootScope.isSourcingSupplierEnabled)  {
              $state.go('notAuthorized');
            }
          },
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();

            var params = {
              module: 'sourcing-expense-type',
              'criteria[0][descr]': $stateParams.query,
              'criteria[0][descr_chinese]': $stateParams.query,
              'criteria[0][code]': $stateParams.query,
              'criteria[0][$operator]': !!$stateParams.query ? 'or' : null,
              criteria_operator: !!$stateParams.query ? 'or' : null,
              offset: 20,
              page: $stateParams.page,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'is_active',
              order: !!$stateParams.order ? $stateParams.order : 0
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Sourcing Expense Type - Master Data';
          }
        }
      })
      .state('main.user.masterData.sourcingCategory', {
        templateUrl: 'app/masterDataManagement/sourcingCategory/manage/manage.html',
        url: '/sourcing-category?{query:[^~,]}&{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'SourcingCategoryCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!$rootScope.isSourcingSupplierEnabled)  {
              $state.go('notAuthorized');
            }
          },
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();

            var params = {
              module: 'sourcing-category',
              'criteria[0][descr]': $stateParams.query,
              'criteria[0][descr_chinese]': $stateParams.query,
              'criteria[0][code]': $stateParams.query,
              'criteria[0][$operator]': !!$stateParams.query ? 'or' : null,
              criteria_operator: !!$stateParams.query ? 'or' : null,
              offset: 20,
              page: $stateParams.page,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'is_active',
              order: !!$stateParams.order ? $stateParams.order : 0
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Sourcing Category - Master Data';
          }
        }
      })
      .state('main.user.masterData.sourcingProject', {
        templateUrl: 'app/masterDataManagement/sourcingProject/manage/manage.html',
        url: '/sourcing-project?{query:[^~,]}&{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'SourcingProjectCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!$rootScope.isSourcingSupplierEnabled)  {
              $state.go('notAuthorized');
            }
          },
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();

            var params = {
              module: 'sourcing-project',
              'criteria[0][descr]': $stateParams.query,
              'criteria[0][code]': $stateParams.query,
              'criteria[0][$operator]': !!$stateParams.query ? 'or' : null,
              criteria_operator: !!$stateParams.query ? 'or' : null,
              offset: 20,
              page: $stateParams.page,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'is_active',
              order: !!$stateParams.order ? $stateParams.order : 0
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Sourcing Project - Master Data';
          }
        }
      })
  }]);
