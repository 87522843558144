'use strict';


function projectCode() {
  return {
    restrict: 'AE',
    templateUrl: 'components/purchaseRequisitionV2Components/projectCode/projectCodeTemplate.html',
    controller: function($scope, prV2Function, $http, $filter, pathConstants) {
      $scope.PR = prV2Function.getPRData();
      $scope.getProjectCodeSearch = getProjectCodeSearch;
      $scope.submissionValidation = submissionValidation;
      $scope.isProjectCodeMandatory = $scope.PR.expense_type.category === 'CAPEX' && !!$scope.PR.document_rule.is_project_code_mandatory;
      /**
       * check errors field from prV2Function.getErrorOnSubmit
       * @param field
       */
      function submissionValidation(field) {
        var errors = prV2Function.getErrorsOnSubmit();
        return (errors.indexOf(field) > -1);
      }
      // drop-down listing
      function getProjectCodeSearch(query){
        var moduleParam = {
          module: 'project'
        };

        var additionalParams = {
          'criteria[0][code]': query,
          'criteria[0][name]': query,
          'criteria[0][$operator]': 'or',
          'criteria[1][billing_company|code]': $scope.PR.shadow_company.code,
          'criteria[1][company|code]': $scope.PR.company.code,
          'criteria[1][$operator]':'and',
          'criteria[2][is_active]': true,
          criteria_operator: 'and',
          offset: 100
        };

        if (!!$scope.PR.document_rule.allow_add_budget){
          additionalParams['criteria[3][is_budgeted]'] = true;
        } else {
          additionalParams['criteria[3][is_budgeted]'] = false;
          additionalParams['criteria[4][billing_company|code]'] = $scope.PR.shadow_company.code;
        }

        return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam),{
          params: additionalParams,
          ignoreLoadingBar: true
        }).then(function (response) {
           if (response.data.content.data.length === 0) {
            return [];
          } else {
            if (($scope.PR.expense_type.budget_setting === 'REQUIRED' || !_.isEmpty($scope.item.budget)) && !$scope.isProjectCodeMandatory) {
              var projectCodes = _.filter(response.data.content.data, function (item) {
                var index = _.findIndex($scope.item.budget.project_codes, function(e){
                    return item.code === e.code;
                  });
                if (index > -1){
                  return item;
                }
                else{
                  return false;
                }
              });
              return projectCodes;
            }
            else {
              return response.data.content.data;
            }
          }
        });
      }
      }
    }
  }
angular
  .module('metabuyer')
  .directive('projectCode', projectCode);
