'use strict';
/**
 * @name viewCurrenciesCtrl
 * @desc Controller to see currencies for a company
 */
function viewCurrenciesCtrl($scope, viewCurrencies, company, $uibModalInstance) {
  $scope.closeModal = closeModal;
  $scope.viewCurrencies = viewCurrencies;
  $scope.company_name = company;

  function closeModal() {
    $uibModalInstance.close();
  }
}

viewCurrenciesCtrl.$inject = ['$scope', 'viewCurrencies', 'company', '$uibModalInstance'];

angular
  .module('metabuyer')
  .controller('viewCurrenciesCtrl', viewCurrenciesCtrl);
