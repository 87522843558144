'use strict';
/**
 * @name passwordResetModal Directive
 * @desc Directive for modal to change passwords
 * data is for profile data
 * oldPasswordField is to determine modal to have old password field
 */
angular
  .module('metabuyer')
  .directive('temporaryPasswordResetModal', function($uibModal, globalFunc) {
    return {
      restrict: 'A',
      scope: {
        data: '=',
        passwordSettings: '=',
        isV2FeatureEnabled: '=',
      },
      link: function ($scope, element) {

        var generatePassword = function(passwordLength) {
          var numberChars = "123456789";
          var upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
          var lowerChars = "abcdefghijklmnopqrstuvwxyz";
          var allChars = numberChars + upperChars + lowerChars;
          var randPasswordArray = Array(passwordLength);
          randPasswordArray[0] = numberChars;
          randPasswordArray[1] = upperChars;
          randPasswordArray[2] = lowerChars;
          randPasswordArray = randPasswordArray.fill(allChars, 3);
          return shuffleArray(randPasswordArray.map(function(x) { return x[Math.floor(Math.random() * x.length)] })).join('');
        }

        var shuffleArray = function(array) {
          for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
          }
          return array;
        }

        var panel = function () {
          $uibModal.open({
            templateUrl: 'components/temporaryPasswordResetModal/temporaryPasswordResetModalTemplate.html',
            backdrop: 'static',
            keyboard: false,
            controller: 'temporaryPasswordResetModalCtrl',
            scope: $scope,
            resolve: {
              profile: function(){
                $scope.password_new = globalFunc.generateRandomPasswordV2($scope.passwordSettings);
                return $scope.data;
              }
            }
          });
        };

        //loading the panel
        $scope.showTemporaryPasswordModal = function () {
          panel();
        };

        element.bind("click", function(){
          $scope.showTemporaryPasswordModal();
        });

      }
    }
  });
