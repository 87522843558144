
'use strict';
/**
 * @name priceComparisonManageCtrl
 *
 * @param $scope
 * @param dataList
 * @param status
 * @param purchaseRequisitionsV2Services
 * @param $rootScope
 * @param globalFunc
 * @param $state
 * @param $stateParams
 * @param HighlightId
 * @param $window
 * @param $filter
 * @param toastr
 * @param prV2Function
 * @param Approvals
 * @param $q
 * @param metabuyerCache
 */
function priceComparisonManageCtrl(
  $scope, dataList, status, purchaseRequisitionsV2Services, $rootScope, globalFunc, $state, $stateParams, HighlightId,
  $window, $filter, toastr, prV2Function, Approvals, $q, metabuyerCache
) {

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional) {
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }

  /**
   * function to navigate between the sub tabs
   * @param tab
   */
  function tabSelection(tab) {
    switch (tab) {
      case 'all': {
        $scope.selectedListTab = tab;
        $state.go('main.user.priceComparison.manage', {
          status: $scope.status,
          tab: tab,
          extraFilterKey: null,
          extraFilterValue: null,
          page: null
        });
        break;
      }
      case 'yours': {
        $scope.selectedListTab = tab;
        $state.go('main.user.priceComparison.manage', {
          status: $scope.status,
          tab: tab,
          extraFilterKey: 'criteria[creator_info|_id][][]',
          extraFilterValue: $rootScope.currentUser._id,
          page: null
        });
        break;
      }
      case 'shared': {
        $scope.selectedListTab = tab;
        $state.go('main.user.priceComparison.manage', {
          status: $scope.status,
          tab: tab,
          extraFilterKey: 'criteria[collaborators_ids][]',
          extraFilterValue: true,
          page: null
        });
        break;
      }
      case 'waiting': {
        $scope.selectedListTab = tab;
        $state.go('main.user.priceComparison.manage', {
          status: $scope.status,
          tab: tab,
          extraFilterKey: 'criteria[waiting_on][]',
          extraFilterValue: $rootScope.currentUser._id,
          page: null
        });
        break
      }
    }
  }

  /**
   * returns store the selected PRs
   * @returns {Array}
   */
  function getSelectedRequisitions() {
    var selectedPRs = [];
    _.forEach($scope.dataList, function (pr) {
      if (!!pr.selected) {
        selectedPRs.push(pr);
      }
    });
    return selectedPRs;
  }

  function getSupplierDisplay(comparisons) {
    var supplierDisplay = '';
    if (comparisons.length === 1 && !_.isEmpty(comparisons[0].supplier)) {
      supplierDisplay = comparisons[0].supplier.basic_info.descr;
    } else if (comparisons.length > 1 && !_.isEmpty(comparisons[0].supplier)) {
      supplierDisplay = comparisons[0].supplier.basic_info.descr + ' +' + (comparisons.length-1);
    }

    return supplierDisplay;
  }

  $scope.showColumn                   = showColumn;
  $scope.tabSelection                 = tabSelection;
  $scope.getSelectedRequisitions      = getSelectedRequisitions;
  $scope.getSupplierDisplay           = getSupplierDisplay;
  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.status = status;
  $scope.loadingPRMainParams = {
    module: 'Price Comparison',
    offset: 20,
    'criteria[status]': $scope.status,
    page: null
  };
  $scope.currentUser                  = $rootScope.currentUser;
  $scope.storageKey                   = 'price-comparison-management-selected-columns';
  $scope.selectedDetail               = '';
  $scope.priceComparison              = [];

  function initialize(){
    $scope.status = $stateParams.status;
    $scope.$parent.status = $stateParams.status;

    if($stateParams.type === 'date'){
      var ms = Number($stateParams.query[1]*1000);
      $scope.title = $filter('date')(new Date(ms), 'dd MMM yyyy');
    }
    else{
      $scope.title = $stateParams.query;
    }

    $scope.selectedListTab = $stateParams.tab;
    if(!$scope.selectedListTab){
      $scope.selectedListTab = 'draft';
    }
    /**
     * Id highlight
     */
    $scope.highlightId = HighlightId.getId();
    if(!!$scope.highlightId) {
      //scroll the window to top
      $window.scroll(0, 0);
      //clear current id
      HighlightId.setId(null);
    }

    if ($scope.status === undefined) {
      $state.go('main.user.priceComparison.manage', {status: 'all'});
    }
  }

  initialize();

}

priceComparisonManageCtrl.$inject = [
  '$scope', 'dataList', 'status', 'purchaseRequisitionsV2Services', '$rootScope', 'globalFunc', '$state', '$stateParams',
  'HighlightId', '$window', '$filter', 'toastr', 'prV2Function', 'Approvals', '$q', 'metabuyerCache'
];

angular
  .module('metabuyer')
  .controller('priceComparisonManageCtrl', priceComparisonManageCtrl);
