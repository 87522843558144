'use strict';
angular
  .module('metabuyer')
  .directive('scoreChart', [function() {
    return {
      restrict: 'AE',
      scope: {
        score: '='
      },
      templateUrl: 'components/scorechart/scorechartTemplate.html',
      link: function (scope, el, attrs, ctrl) {
        scope.score = parseInt(scope.score);

        if(isNaN(scope.score)){
          scope.score = 0;
        }

        scope.style = {
          height: scope.score + '%'
        };
      }
    };
  }]);
