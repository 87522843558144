'use strict';

/**
 * @name supplierWebReportsGenerationCtrl
 * @desc Controller for Reports generation
 */

function supplierWebReportsGenerationCtrl($scope, $filter, companies, $timeout, toastr, reports, checkReportStatus,
                                          globalFunc, $interval, pathConstants) {
  $scope.reports = {};
  $scope.generateOptionsBasedOnReportType = generateOptionsBasedOnReportType;
  $scope.selectedCompanies = [];
  $scope.onSelectSingle = onSelectSingle;
  $scope.onSelectAll = onSelectAll;
  $scope.onSelectNone = onSelectNone;
  $scope.openDatepicker = openDatepicker;
  $scope.generateReports = generateReports;
  $scope.validInput = validInput;
  $scope.canSubmit = false;

  $scope.$emit('pageTitleChanged', 'Supplier Web Reports');

  function downloadReport(hash){
    var newUrl = $filter('format')(pathConstants.apiUrls.reports.download, {
      id: hash
    });
    window.open(newUrl, '_self');
  }

  function checkReport(id) {
    checkReportStatus.get(
      {
        id: id
      },
      function(resource){
        if(!!resource.content && !!resource.content.data &&
          !!resource.content.message && !!resource.content.data.status){

          /**
           * Clearing the toastr before every call for status checking to simulate 'refreshing' the toastr
           */
          toastr.clear();

          var checkingStatus = true;

          /**
           * Status for report generation
           * 0 = PENDING
           * 1 = IN_PROGRESS
           * 2 = DONE
           * 3 = FAILED
           * 4 = CANCELLED
           */
          switch(resource.content.data.status){
            case 0:
              toastr.info(resource.content.message, {timeOut: 0, extendedTimeOut: 0});
              break;
            case 1:
              toastr.info(resource.content.message, {timeOut: 0, extendedTimeOut: 0});
              break;
            case 2:
              if (!!resource.content.data.hash) {
                toastr.success(resource.content.message,
                  {timeOut: 1500, extendedTimeOut: 0, onClose: downloadReport(resource.content.data.hash)});
              }
              else {
                toastr.error(resource.content.message, {timeOut: 0, extendedTimeOut: 0});
              }
              checkingStatus = false;
              $interval.cancel($scope.startChecking);
              break;
            case 3:
              toastr.error(resource.content.message, {timeOut: 0, extendedTimeOut: 0});
              checkingStatus = false;
              $interval.cancel($scope.startChecking);
              break;
            case 4:
              toastr.info(resource.content.message, {timeOut: 0, extendedTimeOut: 0});
              checkingStatus = false;
              $interval.cancel($scope.startChecking);
              break;
          }
        }
      },
      function(error){
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function extractCompanyCode() {
    $scope.companyCodes = [];
    _.forEach($scope.selectedCompanies, function(company){
      if (!!company && !!company.code)
        $scope.companyCodes.push(company.code);
    });
  }

  function extractCompanyIds() {
    $scope.companyIds = [];
    _.forEach($scope.selectedCompanies, function(company){
      if (!!company && !!company._id)
        $scope.companyIds.push(company._id);
    });
  }

  function validInput() {
    // Has valid report type
    if (!$scope.reports.type) {
      return false;
    }

    // Has selected companies
    if ($scope.selectedCompanies.length < 1) {
      return false;
    }

    return true;
  }

  function generateReports(startDate, endDate) {
    extractCompanyCode();
    extractCompanyIds();

    startDate = globalFunc.convertDateToTimestamp(startDate);
    endDate = globalFunc.convertDateToTimestamp(endDate);

    if (!validInput()) {
      toastr.error('Please fill all fields before proceeding.');
      return;
    }

    var submitData = {
      reportType: $scope.reports.type.code,
      dateFrom: startDate,
      dateTo: endDate,
      companyCodes: $scope.companyCodes,
      companyIDs: $scope.companyIds
    };

    reports.post({},
      submitData,
      function(resource) {
        toastr.info('Report is being generated. The download will start automatically', {timeOut: 0, extendedTimeOut: 0});
        $scope.startChecking = $interval(function () {
          checkReport(resource.content.data[0]);
        }, 5000);
        $scope.reports.type = '';
        $scope.selectedCompanies = '';
        $scope.companyListSelect = false;
        $scope.companiesListing = [];
        $scope.dateSelect = false;
        $scope.startingDate = {
          date: new Date()
        };
        $scope.endDate      = {
          date:  new Date()
        };

      },
      function(error) {
        if (!!error.data && !!error.data.content && !!error.data.content.error) {
          toastr.error(error.data.content.error);
        }
      }
    )
  }

  function openDatepicker($event, which) {
    if($scope.datepickerOpened[which]) {
      $event.preventDefault();
      $event.stopPropagation();
    } else {
      $timeout(function() {
        $scope.datepickerOpened[which] = true;
      });
    }

    if (!!$scope.endDate && !!$scope.endDate.date) {
      $scope.startDateOptions = {
        formatYear: 'yy',
        maxDate: $scope.endDate.date
      };
    }

    if (!!$scope.startingDate && !!$scope.startingDate.date) {
      $scope.endDateOptions = {
        formatYear: 'yy',
        minDate: $scope.startingDate.date
      };
    }

    $scope.startDateOptions.maxDate = new Date();
    $scope.endDateOptions.maxDate = new Date();
  }

  function onSelectNone(fieldType) {
    switch (fieldType) {
      case 'company':
        $scope.selectedCompanies = [];
        break;
    }
  }

  function onSelectAll(fieldType, selectionList) {
    switch (fieldType) {
      case 'company':
        $scope.selectedCompanies = selectionList;
        break;
    }
  }

  function onSelectSingle(fieldType, selection) {
    switch (fieldType) {
      case 'company':
        $scope.selectedCompanies = selection;
        break;
    }
  }

  function generateOptionsBasedOnReportType(report) {
    $scope.selectedCompanies = [];
    $scope.companiesListing = companies;
    switch (report.code) {
      case 'TOTAL_PO_BY_COMPANY':
        $scope.dateSelect = true;
        $scope.companyListSelect = true;
        break;
    }
  }

  function getReportTypes() {
    return [
      {
        'code': 'TOTAL_PO_BY_COMPANY',
        'descr': 'Total PO by Company'
      }
    ];
  }

  function initialize(){
    $scope.multiselectTranslation = {
      selectAll       : 'Select all',
      selectNone      : 'Select none',
      reset           : 'Reset',
      search          : 'Type here to search...',
      nothingSelected : 'Click here to select'
    };

    // init report type selection
    $scope.reportTypes = getReportTypes();

    $scope.startingDate = {
      date: new Date()
    };

    $scope.endDate = {
      date:  new Date()
    };

    $scope.startDateOptions = {
      formatYear: 'yy',
      maxDate: new Date(),
      startingDay: 1
    };

    $scope.endDateOptions = {
      formatYear: 'yy',
      maxDate: new Date(),
      startingDay: 1
    };

    $scope.datepickerOpened = {
      startDate: false,
      endDate: false
    };
  }

  initialize();
}

supplierWebReportsGenerationCtrl.$inject = [
  '$scope', '$filter', 'companies', '$timeout', 'toastr', 'reports', 'checkReportStatus',
  'globalFunc', '$interval', 'pathConstants'
];

angular
  .module('metabuyer')
  .controller('supplierWebReportsGenerationCtrl', supplierWebReportsGenerationCtrl);
