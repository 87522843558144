'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('main.user.exports', {
        url: '/exports',
        controller: 'exportsCtrl',
        templateUrl: 'app/exportsListing/exportsListing.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            //TODO: Only one function to check permission, not one by one
            if (!UserPermissions.checkPermissionsAccess(profile, 'ReportLog', 'Create') ||
              !UserPermissions.checkPermissionsAccess(profile, 'ReportLog', 'Read') ||
              !UserPermissions.checkPermissionsAccess(profile, 'ReportLog', 'Update') ||
              !UserPermissions.checkPermissionsAccess(profile, 'ReportLog', 'Delete')) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.exports.log', {
        templateUrl: 'app/exportsListing/logs/logs.html',
        url: '/logs',
        controller: 'exportsLogCtrl',
        resolve: {
          $title: function(){
            return 'Exports - Logs';
          }
        }
      })
  }]);

