'use strict';

function viewSupplierMappingCtrl($scope, supplierCode, supplierMapping, $uibModalInstance) {
  $scope.cancel = cancel;
  $scope.supplierCode = supplierCode;
  $scope.supplierMappings = supplierMapping.data;

  function cancel() {
    $uibModalInstance.close();
  }
}

viewSupplierMappingCtrl.$inject = ['$scope', 'supplierCode', 'supplierMapping', '$uibModalInstance'];

angular.module('metabuyer')
  .controller('viewSupplierMappingCtrl', viewSupplierMappingCtrl);
