'use strict';

/**
 * @name Item Tax Controller
 * @desc Controller for items tax list in companies
 */
function companyItemTaxCtrl(
  $scope, $filter, companyDetails, itemTaxes, $uibModal, $state, $rootScope, companyExportItemTax,
  pathConstants, itemTaxesHistory, companyDownloadItemTaxFile
) {
  $scope.back = back;
  $scope.companyDetails = companyDetails.data;
  $scope.exportItemTax = exportItemTax;
  $scope.itemTaxes = itemTaxes.data;
  $scope.meta = itemTaxes.meta;
  $scope.showColumn = showColumn;
  $scope.storageKey = 'companies-item-tax-selected-columns';
  $scope.history = itemTaxesHistory.data;
  $scope.downloadItemTaxFile = downloadItemTaxFile;

  function prepareHistory() {
    var version = 1;
    _.forEach($scope.history, function(value){
      value.version = version++;
    });
  }

  function downloadItemTaxFile(id) {
    companyDownloadItemTaxFile.download({
      companyId: $scope.companyDetails._id,
      attachmentId: id
    }).$promise.then(
      function (resource) {
        var newUrl = $filter('format')(pathConstants.apiUrls.companies.downloadItemTax, {
          companyId: $scope.companyDetails._id,
          attachmentId: id
        });
        window.open(newUrl, '_self');
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      });
  }

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function exportItemTax() {
    companyExportItemTax.get({
        companyId: $scope.companyDetails._id
      },
      function(resource) {
        if (!!resource.content && !!resource.content.data) {
          var newUrl = $filter('format')(pathConstants.apiUrls.reports.download, {
            id: resource.content.data
          });
          window.open(newUrl, '_self');
        } else {
          globalFunc.objectErrorMessage('An error occurred.');
        }
      }, function(error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function back() {
    if (!!$scope.companyDetails && !!$scope.companyDetails._id) {
      $state.go('main.user.companiesMgmt.detailCompanies', {id: $scope.companyDetails._id});
    } else {
      window.history.back();
    }
  }

  function prepareImportParam() {
    $scope.params = {
      'class': 'App\\Metabuyer\\Association\\ItemTax',
      'additional_params[company_code]': $scope.companyDetails.code
    }
  }

  function initialize(){
    var tabData = {
      tab: $scope.$parent.tabData[0],
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);
    prepareImportParam();
    prepareHistory();
  }

  initialize();
}

companyItemTaxCtrl.$inject = [
  '$scope', '$filter', 'companyDetails', 'itemTaxes', '$uibModal', '$state', '$rootScope', 'companyExportItemTax',
  'pathConstants', 'itemTaxesHistory', 'companyDownloadItemTaxFile'
];

angular.module('metabuyer')
  .controller('companyItemTaxCtrl', companyItemTaxCtrl);
