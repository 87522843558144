'use strict';

function bifrostFileManageCtrl($scope, toastr, pathConstants, $http, bifrostFileServices, $uibModal, dataList, $state) {
  $scope.fileList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.providers = [];
  $scope.modules = [];

  $scope.uploadFile = uploadFile;
  $scope.deleteFile = deleteFile;
  $scope.downloadFile = downloadFile;
  $scope.showColumn = showColumn;
  $scope.storageKey = 'bifrostFile-listing1-selected-columns';
  $scope.filter = {
    query: null
  };
  $scope.searchForm = searchForm;
  $scope.resetSearchForm = resetSearchForm;

  function resetSearchForm() {
    var params;
    params = _.cloneDeep($scope.parentStateParams);
    params.query = null;
    params.created_at = null;

    $state.go('main.user.bifrostFile.manage', params);
  }

  function searchForm() {
    var params;
    params = _.cloneDeep($scope.parentStateParams);
    params.query = $scope.filter.query;

    if (!!$scope.filter.created_at) {
      params.created_at = moment($scope.filter.created_at).valueOf();
    }

    $state.go('main.user.bifrostFile.manage', params);
  }

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional) {
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function getInputList() {
    $http.get(pathConstants.apiUrls.bifrost.uploadRule).then(
      function(response) {
        $scope.providers = response.data.provider;
        $scope.modules = response.data.module;
      },
      function(e) {
        toastr.error('Something went wrong');
      }
    )
  }

  function deleteFile(file) {
    swal({
        title: 'Confirm delete?',
        text: 'The file ' + file.name + ' will be deleted',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function(isConfirm) {
        if (isConfirm) {
          bifrostFileServices.delete({
              path: file.path,
              filename: file.name,
              integration_type: $scope.status
            },
            function() {
              toastr.success('File is successfully deleted');
              $state.reload();
            },
            function(e) {
              toastr.error('Something went wrong.');
            }
          );
        }
      });
  }

  function downloadFile(file) {
    $http.get(pathConstants.apiUrls.bifrost.storageDownload, {
      params: {
        path: file.path,
        filename: file.name,
        integration_type: $scope.status
      }
    }).then(function(response) {
      var data = typeof response.data === 'object' ? JSON.stringify(response.data) : response.data;
      var blob = new Blob([data]);
      var link = document.createElement('a');
      document.body.appendChild(link);
      link.href = window.URL.createObjectURL(blob);
      link.download = file.name;
      link.click();
      toastr.success('File ' + file.name + ' is downloaded.');
    }, function() {
      toastr.error('Failed to download file');
    });
  }

  function uploadFile() {
    $uibModal.open({
      templateUrl: 'app/bifrostFile/manage/bifrostFileUploadModal.html',
      backdrop: 'static',
      keyboard: false,
      scope: $scope,
      controller: function($scope, $uibModalInstance, toastr) {
        $scope.close = close;
        $scope.submit = submit;
        $scope.data = {};

        function close() {

          $uibModalInstance.close();
        }

        function submit() {
          var form = new FormData();

          form.append('inbound_file', $scope.file);
          form.append('provider', $scope.data.provider);
          form.append('module', $scope.data.module);

          $http.post(pathConstants.apiUrls.bifrost.inbound,
            form, {
              headers: { 'Content-Type': undefined }
            }
          ).then(
            function() {
              toastr.success('File is successfully uploaded');
              $state.reload();
              $scope.close();
            },
            function(response) {
              if (!!response.data.data) {
                toastr.error(response.data.data);
              } else {
                toastr.error('Something went wrong');
              }
            }
          )
        }
      }
    })
  }

  function initialize() {
    getInputList();

    if (!!$state.params && !!$state.params.query) {
      $scope.filter.query = $state.params.query;
    }

    if (!!$state.params && !!$state.params.created_at) {
      $scope.filter.created_at = moment.unix($state.params.created_at / 1000).toDate();
    }

    var columns = $scope.columns;

    if ($scope.status === 'AZURE') {
      columns[4] = { id: 'uploaded_at', label: 'Uploaded At', unsearchable: true, unsortable: true };
      $scope.columns = columns;
    } else {
      columns[4] = { id: 'created_at', label: 'Created At', unsearchable: true, unsortable: true };
    }
  }

  initialize();
}

bifrostFileManageCtrl.$inject = ['$scope', 'toastr', 'pathConstants', '$http', 'bifrostFileServices', '$uibModal', 'dataList', '$state'];

angular
  .module('metabuyer')
  .controller('bifrostFileManageCtrl', bifrostFileManageCtrl);
