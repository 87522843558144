'use strict';

/**
 * Controller for catalog items listing
 * @param $scope
 * @param dataList
 * @param $uibModal
 * @param toastr
 */

function viewCatalogItemsCtrl($scope, dataList, $uibModal, toastr) {

  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.catalogItemsManageService = dataList;
  $scope.viewCatalogItemDetails = viewCatalogItemDetails;

  $scope.showColumn = showColumn;
  $scope.storageKey = 'catalog-view-selected-columns';

  function showColumn(id) {
    var i;
    var columns = $scope.columns;
    for (i in columns) {
      if(columns.hasOwnProperty(i)) {
        if (id === columns[i].id) {
          if(!!columns[i].conditional) {
            if($scope.status === columns[i].condition)
              return columns[i].selected;
            if(columns[i].condition instanceof Array) {
              var j;
              var columnCondition = $scope.columns[i].condition;
              for(j in columnCondition) {
                if(columnCondition.hasOwnProperty(j)) {
                  if($scope.status === columnCondition[j]) {
                    return columns[i].selected;
                  }
                }
              }
            }
          }
          else
            return columns[i].selected;
        }
      }
    }
  }

  function viewCatalogItemDetails(data) {
    var modalInstance = $uibModal.open({
      templateUrl: 'app/items/details/singleItemDetails.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        data: function() {
          return data;
        }
      },
      controller: function ($scope, $uibModalInstance, data, singleItemDetails) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        singleItemDetails.get(
          {
            summary_id: data.import_summary_id,
            import_item_id: data._id
          },
          function(resource) {
            $scope.singleItemDetail = resource.content.data;
          },
          function() {
            toastr.error('Unable to view item detail');
          }
        )
      }
    });
  }

}

viewCatalogItemsCtrl.$inject = ['$scope', 'dataList', '$uibModal', 'toastr'];

angular.module('metabuyer')
  .controller('viewCatalogItemsCtrl', viewCatalogItemsCtrl);
