'use strict';
/**
 * @name CostCentersManageCtrl
 * @desc Controller for companies cost centers tabs
 */
function CostCentersManageCtrl($scope, $stateParams, costCenters , CostCenters) {
  $scope.status = $stateParams.status;
  $scope.title = $stateParams.title;
  $scope.costCenters = costCenters.data;
  //if ($scope.actionType == 'search')
  //  $scope.activeSearch(true);
  //else {
  //  $scope.activeSearch(false);
  //  $scope.tabData.more.activateTab($scope.status);
  //}
  // Store last open tab
  $scope.pagination = {
    index: 1,
    init: function (meta) {
      $scope.pagination.size = Math.min(CostCentersManageCtrl.PazeSize, meta.count),
        $scope.pagination.total = meta.count;
      $scope.pagination.meta = meta;
    },
    getTotalPage: function () {
      return $scope.pagination.total;
    },
    getPageIndex: function () {
      return $scope.pagination.index * $scope.pagination.size - $scope.pagination.size + 1;
    },
    getPageSize: function () {
      return Math.min($scope.pagination.index * $scope.pagination.size, $scope.pagination.total);
    },
    goPreviousPage: function () {
      if ($scope.pagination.getPageIndex() == 1) return;
      --$scope.pagination.index;
      $scope.pagination.cursor = $scope.pagination.meta.prev;
      $scope.action.refresh();
    },
    goNextPage: function () {
      if ($scope.pagination.getPageSize() == $scope.pagination.total) return;
      ++$scope.pagination.index;
      $scope.pagination.cursor = $scope.pagination.meta.next;
      $scope.action.refresh();
    }
  };

  if ($scope.costCenters.length && !!costCenters.meta) {
    $scope.pagination.init(costCenters.meta.cursor);
  }
  else {
    $scope.pagination.init({count: $scope.costCenters.length});
  }
  var checkedListItem = [];
  // Trigger if any item is checked. Display action button.
  $scope.check = function (requisition) {
    checkedListItem.splice(0, checkedListItem.length);
    $scope.showAction = false;
    for (var index = 0; index < $scope.costCenters.length; ++index) {
      var item = $scope.costCenters[index];
      if (!item.selected) {
        continue;
      }
      $scope.showAction = true;
      checkedListItem.push(item);
    }
  };
  // Check all item.
  $scope.checkAll = function () {
    if ($scope.selectedAll) {
      $scope.showAction = $scope.selectedAll = true;
    } else {
      $scope.showAction = $scope.selectedAll = false;
    }
    checkedListItem.splice(0, checkedListItem.length);
    angular.forEach($scope.costCenters, function (item) {
      item.selected = $scope.selectedAll;
      if (item.selected)
        checkedListItem.push(item);
    });
  };

  $scope.action = {
    refresh: function () {
      if ($scope.actionType == 'show') {
        var param = {
          status: $scope.status,
          offset: $scope.pagination.size
        };

        if(!!$scope.pagination.cursor){
          param.cursor = $scope.pagination.cursor;
        }

        CostCenters.get(
          param,
          function (resource) {
            if(!!resource && !!resource.content && !!resource.content.data){
              $scope.companies = resource.content.data;
            }

            if(!!resource && !!resource.content && !!resource.content.data && !! resource.content.meta.cursor){
              $scope.pagination.init(resource.content.meta.cursor);
            }

            $scope.pagination.pageLoad.finish();
          },
          function (error) {
            if (error.status === 404) {
              window.location = "404.html";
            }

            $scope.pagination.pageLoad.finish();
          }
        );
      } else if ($scope.actionType == 'search') {

      }
    }
  };

  $scope.column = {
    columns: [
      {id: 'company', label: 'Company'},
      {id: 'name', label: 'Name'},
      {id: 'code', label: 'Code'},
      {id: 'created', label: 'Created Date'}
    ],
    selectedColumns: [],
    buttonText: {buttonDefaultText: 'Columns'},
    setting: {
      buttonClasses: "btn btn-white",
      smartButtonMaxItems: 3,
      showCheckAll: false,
      showUncheckAll: false
    },
    events: {
      onItemSelect: function (item) {
        $scope.column.saveSelectedColumn($scope.column.selectedColumns);
      },
      onItemDeselect: function (item) {
        $scope.column.saveSelectedColumn($scope.column.selectedColumns);
      }
    },
    showColumn: function (name) {
      for (var index = 0; index < $scope.column.selectedColumns.length; ++index) {
        var item = $scope.column.selectedColumns[index];
        if (item.id == name) {
          return true;
        }
      }
      return false;
    },
    saveSelectedColumn: function (columns) {
      window.localStorage.setItem("costcenter-columns", JSON.stringify(_.map(columns, function (item) {
        return item.id || item
      })));
    },
    loadSelectedColumn: function () {
      return JSON.parse(window.localStorage.getItem("costcenter-columns")) || [];
    }
  };
  // Apply selected column
  var selectedColumn = $scope.column.loadSelectedColumn();
  if (selectedColumn.length == 0) { // Default column
    for (var index = 0; index < $scope.column.columns.length; ++index) {
      $scope.column.selectedColumns.push($scope.column.columns[index]);
    }
  }
  else {
    for (var index = 0; index < selectedColumn.length; ++index) {
      var column = _.find($scope.column.columns, function (item) {
        return item.id == selectedColumn[index];
      });
      if (!!column)
        $scope.column.selectedColumns.push(column);
    }
  }
}

CostCentersManageCtrl.$inject = ['$scope', '$stateParams', 'costCenters', 'CostCenters'];
CostCentersManageCtrl.PazeSize = 50;

angular
  .module('metabuyer')
  .controller('CostCentersManageCtrl', CostCentersManageCtrl);
