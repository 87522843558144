'use strict';

function reLogInCtrl($scope, $uibModalInstance, $uibModalStack, $auth, $state, currentStateData, email, ManageLocalStorage, $rootScope, message, checkSocialLogin, globalFunc, toastr, twoFAVerifyOTP) {
  $scope.currentStateData = currentStateData;
  $scope.email = email;
  $scope.message = message;
  $scope.attempt = true;
  $scope.isDomainUser = true;
  $scope.socialAuthUrl = null;
  $scope.socialAuth = false;
  if (!!$rootScope.currentUser) {
    $scope.socialAuth = $rootScope.currentUser.social_auth || false;
  }
  $scope.form = {
    password: "",
    twoFA_otp: null,
  };
  $scope.otps = [];

  $scope.relogin = function($event) {
    $scope.form.twoFA_otp = $scope.otps.join('');
    if (!$scope.socialAuth) {
      login();
    } else {
      openSocialAuth($event);
    }
  }

  function login() {
    $scope.error = null;
    $auth.login({ email: $scope.email, password: btoa($scope.form.password), twoFA_otp: $scope.form.twoFA_otp })
      .then(function() {
        $rootScope.reLoginDialogOpen = false;
        $uibModalInstance.close(false);
        $state.go(currentStateData.name, currentStateData.params);
      })
      .catch(function(response) {
        if (!!response && !!response.data && !!response.data.message) {
          $scope.error = response.data.message;
          $scope.errorCount = response.data.login_attempts_remaining;
        } else {
          $scope.error = 'An unexpected error has occurred. Please try again later.';
        }
      });
  };

  $scope.logout = function() {
    ManageLocalStorage.clearLocalStorage();
    $auth.logout().then(function() {
      $rootScope.reLoginDialogOpen = false;
      $uibModalStack.dismissAll();
    });
    $scope.password = '';
    $state.go('login');
  };

  function openSocialAuth() {

    var userId = window.localStorage.getItem('userId');

    twoFAVerifyOTP.post(
      {},
      {
        userId: userId,
        totp: $scope.form.twoFA_otp,
        relogin: 1
      },
      function (response) {
        window.open($scope.socialAuthUrl, 'Social Auth', 'height=800,width=1000');
      },
      function (error) {
        $scope.isLoading = false;
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function socialAuthCallback(e) {
    console.log(e)
    if (!e.data.status && !e.data.token) {
      toastr.error(e.data.message);
    } else {
      window.localStorage.setItem('email', e.data.email);
      window.localStorage.setItem('userId', e.data.userId);
      window.localStorage.setItem('socialLogin', true);
      $auth.setToken(e.data.token)
      $rootScope.reLoginDialogOpen = false;
      $uibModalInstance.close(false);

      if (currentStateData.url === null) {
        $state.go('main.user.mainMenu.waiting-on-you')
      } else {
        $state.go(currentStateData.name, currentStateData.params);
      }
    }
  }

  function initialize() {
    if (!!$scope.email) {
      checkSocialLogin.post({
          email: $scope.email,
          popup: 1
        },
        function(resource) {
          if (!!resource.normal_login) {
            $scope.isDomainUser = false;
          } else {
            $scope.socialAuthUrl = resource.redirect_uri;
          }
        },
        function(error) {
          globalFunc.objectErrorMessage(error);
          $scope.isDomainUser = false;
        }
      );
    }

    window.addEventListener('message', socialAuthCallback, false)
  }

  initialize();

}

reLogInCtrl.$inject = ['$scope', '$uibModalInstance', '$uibModalStack', '$auth', '$state', 'currentStateData', 'email', 'ManageLocalStorage', '$rootScope', 'message', 'checkSocialLogin', 'globalFunc', 'toastr', 'twoFAVerifyOTP'];

angular.module('metabuyer')
  .controller('reLogInCtrl', reLogInCtrl);
