'use strict';

/**
 * @name Item Tax Controller
 * @desc Controller for items tax list in companies
 */
function catalogsCompanyItemDetailCtrl($scope, managedItem, companyItem, $state) {
    $scope.item = managedItem;
    $scope.updateItem = updateItem;
    $scope.back = back;

    function back() {
      if ($scope.item && $scope.item.company && $scope.item.company._id)
        $state.go('main.user.items.company.companyItems', {company_id: $scope.item.company._id});
      else
        window.history.back();
    }

    function prepareItem() {
      return {
        item_master_code: $scope.item.item_master_code,
        descr: $scope.item.descr,
        is_pcm: $scope.is_pcm,
        is_stock: $scope.item.is_stock,
        item_category_code: $scope.item.item_category.code,
        company_code: $scope.item.company.code,
        expense_type_category: $scope.item.expense_type_category,
        uom_code: $scope.item.uom_code,
        account_code: $scope.item.account_code,
        is_active: $scope.item.is_active ? 1 : 0
      }
    }

    function updateItem() {
      var data = prepareItem()
      companyItem.update(
        {
          uuid: $scope.item.uuid
        },
          data
        ,
        function (resource) {
          if (!!resource.content && !!resource.content.data)
            deferred.resolve(resource.content.data);
        },
        function () {
          deferred.resolve([]);
        }
      );
    }
}

catalogsCompanyItemDetailCtrl.$inject = [
  '$scope', 'managedItem', 'companyItem', '$state'
];

angular.module('metabuyer')
  .controller('catalogsCompanyItemDetailCtrl', catalogsCompanyItemDetailCtrl);
