'use strict';
/**
 * @name securityQuestionModal Directive
 * @desc Directive for modal to change security question answer
 */
angular
  .module('metabuyer')
  .directive('securityQuestionModal',function($uibModal) {
    return {
      restrict: 'A',
      scope: {
        data: '='
      },
      link: function ($scope, element) {
        var panel = function () {
          $uibModal.open({
            templateUrl: 'app/user/securityQuestion/security-question-modal.html',
            backdrop: 'static',
            keyboard: false,
            controller: 'securityQuestionModalCtrl',
            scope: $scope,
            resolve: {
              profile: function(){
                return $scope.data;
              },
              prepareTranslation: function ($translatePartialLoader) {
                $translatePartialLoader.addPart('login/lang');
              },
            }
          });
        };

        //loading the panel
        $scope.showModal = function () {
          panel();
        };

        element.bind("click", function(){
          $scope.showModal();
        });

      }
    }
  });
