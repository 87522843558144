'use strict';
/**
 * @name MasterDataCtrl
 * @desc Controller for master data controller The tabs will be pushed according to the access rights of the user's profile
 */
function MasterDataCtrl($scope, profile, UserPermissions, $rootScope, $stateParams, $state) {

  $scope.$emit('pageTitleChanged', 'Master Data');
  $scope.status = true;
  $scope.$broadcast('setStatus', $stateParams.status);


  $scope.tabData = [];
  var currencyTab = {
    heading: 'Currencies',
    route: 'main.user.masterData.currency',
    key: 'currency',
    params: {
      cursor: null,
      order_by: 'is_active',
      order: 0
    }
  };
  if (!!UserPermissions.checkPermissionsAccess(profile, 'Currencies', 'Create') || !!UserPermissions.checkPermissionsAccess(profile, 'Currencies', 'Update')
    || !!UserPermissions.checkPermissionsAccess(profile, 'Currencies', 'Delete')) {
    $scope.tabData.push(currencyTab);
  }
  var exchangeRateTab = {
    heading: 'Exchange Rates',
    route: 'main.user.masterData.exchangeRate',
    key: 'exchangeRate',
    params: {
      cursor: null,
      order_by: 'is_active',
      order: 0
    }
  };
  if (!!UserPermissions.checkPermissionsAccess(profile, 'ExchangeRate', 'Create') || !!UserPermissions.checkPermissionsAccess(profile, 'ExchangeRate', 'Update')
    || !!UserPermissions.checkPermissionsAccess(profile, 'ExchangeRate', 'Delete')) {

    $scope.tabData.push(exchangeRateTab);
  }

  var uomTab = {
    heading: 'UOM',
    route: 'main.user.masterData.uom',
    key: 'uom',
    params: {
      cursor: null,
      order_by: 'is_active',
      order: 0
    }
  };
  if (!!UserPermissions.checkPermissionsAccess(profile, 'UOM', 'Create') || !!UserPermissions.checkPermissionsAccess(profile, 'UOM', 'Update')
    || !!UserPermissions.checkPermissionsAccess(profile, 'UOM', 'Delete')) {

    $scope.tabData.push(uomTab);
  }

  var paymentTermsTab = {
    heading: 'Payment Terms',
    route: 'main.user.masterData.paymentTerms',
    key: 'paymentTerms',
    params: {
      cursor: null,
      order_by: 'is_active',
      order: 0
    }
  };
  if (!!UserPermissions.checkPermissionsAccess(profile, 'PaymentTerm', 'Create') || !!UserPermissions.checkPermissionsAccess(profile, 'PaymentTerm', 'Update')
    || !!UserPermissions.checkPermissionsAccess(profile, 'PaymentTerm', 'Delete')) {

    $scope.tabData.push(paymentTermsTab);
  }

  var paymentMethodsTab = {
    heading: 'Payment Methods',
    route: 'main.user.masterData.paymentMethods',
    key: 'paymentMethods',
    params: {
      cursor: null,
      order_by: 'is_active',
      order: 0
    }
  };
  if (!!UserPermissions.checkPermissionsAccess(profile, 'PaymentMethod', 'Create') || !!UserPermissions.checkPermissionsAccess(profile, 'PaymentMethod', 'Update')
    || !!UserPermissions.checkPermissionsAccess(profile, 'PaymentMethod', 'Delete')) {

    $scope.tabData.push(paymentMethodsTab);
  }

  var sourcingExpenseTypeTab = {
    heading: 'Sourcing Expense Type',
    route: 'main.user.masterData.sourcingExpenseType',
    key: 'sourcingExpenseType',
    params: {
      cursor: null,
      order_by: 'is_active',
      order: 0
    }
  };

  var sourcingCategoryTab = {
    heading: 'Sourcing Category',
    route: 'main.user.masterData.sourcingCategory',
    key: 'sourcingCategory',
    params: {
      cursor: null,
      order_by: 'is_active',
      order: 0
    }
  };

  var sourcingProjectTab = {
    heading: 'Sourcing Project',
    route: 'main.user.masterData.sourcingProject',
    key: 'sourcingProject',
    params: {
      cursor: null,
      order_by: 'is_active',
      order: 0
    }
  };

  if(!!$rootScope.isSourcingSupplierEnabled){
    $scope.tabData.push(sourcingExpenseTypeTab);
    $scope.tabData.push(sourcingCategoryTab);
    $scope.tabData.push(sourcingProjectTab);
  }

  var commodityCodesTab = {
    heading: 'Commodity Codes',
    route: 'main.user.masterData.commodityCode',
    key: 'commodityCodes',
    params: {
      cursor: null,
      order_by: 'is_active',
      order: 0,
      parentUniqueName: 'All'
    }
  };
  if (!!UserPermissions.checkPermissionsAccess(profile, 'Commodity', 'Create') || !!UserPermissions.checkPermissionsAccess(profile, 'Commodity', 'Update')
    || !!UserPermissions.checkPermissionsAccess(profile, 'Commodity', 'Delete')) {
    $scope.tabData.push(commodityCodesTab);
  }

  var taxesTab = {
    heading: 'Taxes',
    route: 'main.user.masterData.taxes',
    key: 'taxes',
    params: {
      cursor: null,
      order_by: 'is_active',
      order: 0
    }
  };
  if (!!UserPermissions.checkPermissionsAccess(profile, 'Tax', 'Create') || !!UserPermissions.checkPermissionsAccess(profile, 'Tax', 'Update')
    || !!UserPermissions.checkPermissionsAccess(profile, 'Tax', 'Delete')) {

    $scope.tabData.push(taxesTab);
  }

  var expenseTypesTab = {
    heading: 'Expense Types',
    route: 'main.user.masterData.expenseTypes',
    key: 'expenseTypes',
    params: {
      cursor: null,
      order_by: 'is_active',
      order: 0
    }
  };
  if (!!UserPermissions.checkPermissionsAccess(profile, 'ExpenseTypes', 'Create') || !!UserPermissions.checkPermissionsAccess(profile, 'ExpenseTypes', 'Update')
    || !!UserPermissions.checkPermissionsAccess(profile, 'ExpenseTypes', 'Delete')) {

    $scope.tabData.push(expenseTypesTab);
  }

  var itemMastersTab = {
    heading: 'Item Masters',
    route: 'main.user.masterData.itemMasters',
    key: 'itemMasters',
    params: {
      cursor: null,
      order_by: 'is_active',
      order: 0
    }
  };

  var modeOfPurchaseTab = {
    heading: 'Mode Of Purchase',
    route: 'main.user.masterData.modeOfPurchase',
    key: 'modeOfPurchase',
    params: {
      cursor: null,
      order_by: 'is_active',
      order: 0
    }
  };

  // TODO: Actual implementation once this is done
  // if (!!UserPermissions.checkPermissionsAccess(profile, 'ModeOfPurchase', 'Create') ||
  //  !!UserPermissions.checkPermissionsAccess(profile, 'ModeOfPurchase', 'Update')
  //   || !!UserPermissions.checkPermissionsAccess(profile, 'ModeOfPurchase', 'Delete')) {

  //   $scope.tabData.push(modeOfPurchaseTab);
  // }

  var itemCategoriesTab = {
    heading: 'Item Categories',
    route: 'main.user.masterData.itemCategories',
    key: 'itemCategories',
    params: {
      cursor: null,
      order_by: 'is_active',
      order: 0
    }
  };

  var stockInformationGroupsTab = {
    heading: 'Stock Information Groups',
    route: 'main.user.masterData.stockInformationGroups',
    key: 'stockInformationGroups',
    params: {
      cursor: null,
      order_by: 'is_active',
      order: 0
    }
  };

  var alcGroupsTab = {
    heading: 'Approval Limit Control Groups',
    route: 'main.user.masterData.alcGroups',
    key: 'alcGroups',
    params: {
      cursor: null,
      order_by: 'is_active',
      order: 0
    }
  };

  var categoryTypeTab = {
    heading: 'Category Type',
    route: 'main.user.masterData.categoryType',
    key: 'categoryType',
    params: {
      cursor: null,
      order_by: 'is_active',
      order: 0
    }
  };

  var itemGroupTab = {
    heading: 'Item Group',
    route: 'main.user.masterData.itemGroup',
    key: 'itemGroup',
    params: {
      cursor: null,
      order_by: 'is_active',
      order: 0
    }
  };

  // TODO: Actual implementation once this is done
  // if (!!UserPermissions.checkPermissionsAccess(profile, 'ItemMaster', 'Create') ||
  //   !!UserPermissions.checkPermissionsAccess(profile, 'ItemMaster', 'Update') ||
  //   !!UserPermissions.checkPermissionsAccess(profile, 'ItemMaster', 'Delete')) {
  //
  //   $scope.tabData.push(itemMastersTab);
  // }

  // TODO: Actual implementation once this is done
  // if (!!UserPermissions.checkPermissionsAccess(profile, 'ItemCategories', 'Create') ||
  //   !!UserPermissions.checkPermissionsAccess(profile, 'ItemCategories', 'Update') ||
  //   !!UserPermissions.checkPermissionsAccess(profile, 'ItemCategories', 'Delete')) {
  //
  //   $scope.tabData.push(itemCategoriesTab);
  // }

  // TODO: Actual implementation once this is done
  // if (!!UserPermissions.checkPermissionsAccess(profile, 'StockInformationGroups', 'Create') ||
  //   !!UserPermissions.checkPermissionsAccess(profile, 'StockInformationGroups', 'Update') ||
  //   !!UserPermissions.checkPermissionsAccess(profile, 'StockInformationGroups', 'Delete')) {
  //
  //   $scope.tabData.push(stockInformationGroupsTab);
  // }

  // TODO: Actual implementation once this is done
  // if (!!UserPermissions.checkPermissionsAccess(profile, 'AlcGroups', 'Create') ||
  //   !!UserPermissions.checkPermissionsAccess(profile, 'AlcGroups', 'Update') ||
  //   !!UserPermissions.checkPermissionsAccess(profile, 'AlcGroups', 'Delete')) {
  //
  //   $scope.tabData.push(alcGroupsTab);
  // }

  if ($rootScope.isV2Enabled) {
    $scope.tabData.push(modeOfPurchaseTab);
    $scope.tabData.push(itemMastersTab);
    $scope.tabData.push(itemCategoriesTab);
    $scope.tabData.push(stockInformationGroupsTab);
    $scope.tabData.push(alcGroupsTab);
    $scope.tabData.push(categoryTypeTab);
    $scope.tabData.push(itemGroupTab);
  }

  function initialize() {
    if (!!$state.current.name) {
      if ($state.current.name.indexOf('currency') > -1) {
        $scope.activatedTab = currencyTab;
      } else if ($state.current.name.indexOf('exchangeRate') > -1) {
        $scope.activatedTab = exchangeRateTab;
      } else if ($state.current.name.indexOf('uom') > -1) {
        $scope.activatedTab = uomTab;
      } else if ($state.current.name.indexOf('paymentTerms') > -1) {
        $scope.activatedTab = paymentTermsTab;
      } else if ($state.current.name.indexOf('paymentMethods') > -1) {
        $scope.activatedTab = paymentMethodsTab;
      } else if ($state.current.name.indexOf('sourcingExpenseType') > -1) {
        $scope.activatedTab = sourcingExpenseTypeTab;
      } else if ($state.current.name.indexOf('sourcingCategory') > -1) {
        $scope.activatedTab = sourcingCategoryTab;
      } else if ($state.current.name.indexOf('sourcingProject') > -1) {
        $scope.activatedTab = sourcingProjectTab;
      } else if ($state.current.name.indexOf('commodityCode') > -1) {
        $scope.activatedTab = commodityCodesTab;
      } else if ($state.current.name.indexOf('taxes') > -1) {
        $scope.activatedTab = taxesTab;
      } else if ($state.current.name.indexOf('expenseTypes') > -1) {
        $scope.activatedTab = expenseTypesTab;
      } else if ($state.current.name.indexOf('itemMasters') > -1) {
        $scope.activatedTab = itemMastersTab;
      } else if ($state.current.name.indexOf('modeOfPurchase') > -1) {
        $scope.activatedTab = modeOfPurchaseTab;
      } else if ($state.current.name.indexOf('itemCategories') > -1) {
        $scope.activatedTab = itemCategoriesTab;
      } else if ($state.current.name.indexOf('stockInformationGroups') > -1) {
        $scope.activatedTab = stockInformationGroupsTab;
      } else if ($state.current.name.indexOf('alcGroups') > -1) {
        $scope.activatedTab = alcGroupsTab;
      } else if ($state.current.name.indexOf('categoryType') > -1) {
        $scope.activatedTab = categoryTypeTab;
      } else if ($state.current.name.indexOf('itemGroup') > -1) {
        $scope.activatedTab = itemGroupTab;
      }
    }
  }

  initialize();

}
MasterDataCtrl.$inject = ['$scope', 'profile', 'UserPermissions', '$rootScope', '$stateParams', '$state'];

angular
  .module('metabuyer')
  .controller('MasterDataCtrl', MasterDataCtrl);
