'use strict';

angular.module('metabuyer.services.catalogServices', ['ngResource'])
.factory('itemList', ['$resource', 'pathConstants',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.catalog.list,
        {
          user_id: '@user_id',
          code: '@code',
          cc: '@cc',
          supplier_id: '@supplier_id'
        });
    }])
  .factory('itemDetailsInformation', ['$resource', 'pathConstants',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.catalog.details,
        {
          user_id: '@user_id',
          item_id: '@item_id'
        });
    }])
  .factory('searching', ['$resource', 'pathConstants',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.catalog.searching,
        {
          user_id: '@user_id',
          supplier_id: '@supplier_id'
        });
    }])
  .factory('starringItem', ['$resource', 'pathConstants',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.catalog.starringItem,
        {
          user_id: '@user_id',
          item_id: '@item_id'
        }, {

          'update': { method: 'PUT' }

        });
    }])
  .factory('catalogShoppingSearch', ['$resource', 'pathConstants',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.catalog.search,
        {
          user_id: '@user_id',
          type: '@type'
        });
    }])
  .factory('catalogShoppingSearchV2', ['$resource', 'pathConstants',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.catalog.v2.search,
        {
          user_id: '@user_id',
          type: '@type'
        });
    }])
  .factory('itemDetailsInformationV2', ['$resource', 'pathConstants',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.catalog.v2.details,
        {
          uuid: '@uuid'
        });
    }])
  .factory('historiesV2', ['$resource', 'pathConstants',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.catalog.v2.histories,
      {
        uuid: '@uuid'
      });
  }])
  .factory('catalogCheckBeforeImport', ['$resource', 'pathConstants',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.catalogs.checkImport,
      {
        catalog_group_id: '@catalog_group_id',
        catalog_code_id: '@catalog_code_id',
        supplier_id: '@supplier_id'
      },
      {
        post: {method: 'post'}
      });
  }])
  .factory('catalogItemQuantityControlInfo', ['$resource', 'pathConstants',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.catalog.v2.quantityControlInfo,
        {
          uuid: '@uuid'
        },
        {
          get: {method: 'GET'}
        });
  }])
  .factory('getCatalogComments', ['$resource', 'pathConstants',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.catalog.v2.comments,
        {
          referenceId: '@referenceId'
        },
        {
          get: {method: 'GET'},
          post: {method: 'POST'}
        });
    }]);
