'use strict';

/**
 * @name holidayCtrl
 * @desc The controller for system holidays
 *
 * @param $scope
 * @param holidaysDataList
 * @param HighlightId
 * @param $window
 * @param action
 * @param $uibModal
 * @param holidayServices
 * @param toastr
 * @param $state
 */

function holidayCtrl($scope, holidaysDataList, HighlightId, $window, action, $uibModal,
  holidayServices, toastr, $state, $location){
  $scope.showColumn                   = showColumn;
  $scope.storageKey                   = 'holidays-list-selected-columns';
  $scope.showHolidayTemplate          = showHolidayTemplate;
  $scope.deleteHoliday                = deleteHoliday;
  $scope.openHolidayDetails           = openHolidayDetails;
  $scope.holidays                     = holidaysDataList.data;
  $scope.search                       = search;

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  /**
     * Function to delete holidays
  */
  function deleteHoliday(holiday) {
    swal({
        title: 'Confirm delete?',
        text: 'This holiday item will be deleted',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (isConfirm) {
          holidayServices.delete(
            {
              id: holiday._id
            },
            function () {
              toastr.success('Holiday deleted');
              $state.reload();
            }, function () {
              toastr.error('This holiday item cannot be deleted.');
            }
          );
        }
      });
  }

  function initialize(){

    $scope.columns                      = holidayColumns;
    $scope.holidays                     = holidaysDataList.data;
    $scope.actionType                   = action;
    $scope.meta                         = holidaysDataList.meta;
    $scope.parentStateParams            = $state.params;

    //set status for parent
    if(!!HighlightId.getId() && HighlightId.getId() != undefined && HighlightId.getId().length) {

      $scope.highlightId = HighlightId.getId();

      if ($scope.highlightId != null && $scope.highlightId != undefined) {
        //scroll the window to top
        $window.scroll(0, 0);
      }

      //clear current id
      HighlightId.setId(null);
    }

    if(!!$state.params && !!$state.params.query){
      $scope.searchText = $state.params.query;
    }
    else{
      $scope.searchText = '';
    }
  }

  function search(query){
    $location.search({query: query});
  }

  function openHolidayDetails(singleHoliday){
    var modalInstance = $uibModal.open({
      templateUrl: 'app/maintenance/holiday/holidayModal/holidayModalTemplate.html',
      controller: holidayModalCtrl,
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      resolve: {
        getsingleHoliday: function() {
          return singleHoliday;
      }
    }
  });
    modalInstance.result.then(function (newValue) {
      if(!!newValue){
        _.forEach($scope.holidays, function(holidays){
          if(holidays._id === newValue._id){
            holidays.date = newValue.holiday_date;
            holidays.description = newValue.holiday_description;
            holidays.company_code = newValue.company_code;
          }
        });
      }
    });
  }

  function showHolidayTemplate() {
    $uibModal.open({
      templateUrl: 'app/maintenance/holiday/holidayTemplate.html',
      controller: function($scope, toastr, lang, holidayServices, $uibModalInstance,$state){
        $scope.editingState                 = false;
        $scope.formSubmitted                = false;
        $scope.holiday                      = {};
        $scope.submitHoliday                = submitHoliday;
        $scope.close                        = close;

        /**
         * Function to create holidays
         */
        function createHoliday() {
          $scope.submitted = true;
          var params = prepareHoliday();
          holidayServices.post(
            params,
            function () {
              toastr.success('Holiday created successfully.');
              $scope.submitted = false;
              close();
              $state.reload();
            },
            function (e) {
              toastr.error('Something went wrong.');
              $scope.submitted = false;
            }
          )
        }

        $scope.holidayDateOptions = {
          formatYear: 'yy',
          startingDay: 1
        };

        $scope.datepickerOpened = {
          holiday_date: false,
        };

        $scope.openDatepicker = openDatepicker;

          /**
         * For date picker
         *
         * @param {Object} $event
         * @param {string} which - holiday date
         */
        function openDatepicker($event, which) {
          $event.preventDefault();
          $event.stopPropagation();

          $scope.holidayDateOptions.minDate = new Date();

          $scope.datepickerOpened[which] = true;
        }

        function prepareHoliday() {
          return {
            'date'            : $scope.holiday.holiday_date.getTime()/1000,
            'description'     : $scope.holiday.holiday_description,
            'company_code'    : $scope.holiday.company_code
          };
        }

        function holidayValidation() {
          if (!$scope.holiday.holiday_date) {
            toastr.error(lang.validation.required.input + 'holiday date.');

            return false;
          }

          if (!$scope.holiday.holiday_description) {
            toastr.error(lang.validation.required.input + 'holiday description.');

            return false;
          }

          return true;
        }

        function submitHoliday(action, item) {
          $scope.formSubmitted = true;
          if (holidayValidation(action)) {
            if (action === 'create')
            {
              createHoliday();
            }
            else
              prepareHoliday(item);
          }
        }

        function close(){
          $uibModalInstance.close();
        }
      },
      backdrop: 'static',
      keyboard: false,
      size: 'lg'
    });
  }

  var holidayColumns = [
    {id: 'date', label: 'Holiday Date', type: 'date', unsearchable: true},
    {id: 'description', label: 'Description', unsortable: true},
    {id: 'company_code', label: 'Company Code', unsortable: true, unsearchable: true},
    {id: 'created_at', label: 'Created At', unsortable: true, unsearchable: true},
    {id: 'edit_button', unSelectable: true, unsortable: true, unsearchable: true},
    {id: 'delete_button', unSelectable: true, unsortable: true, unsearchable: true}
  ];

  initialize();

}

holidayCtrl.$inject =  ['$scope', 'holidaysDataList', 'HighlightId',
'$window', 'action', '$uibModal', 'holidayServices', 'toastr', '$state', '$location'];

angular
  .module('metabuyer')
  .controller('holidayCtrl', holidayCtrl);
