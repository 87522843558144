'use strict';

function purchaseRequisitionsV2ItemListCtrl (
  $scope, prV2Function, purchaseRequisitionsV2Services, globalFunc, pathConstants, toastr, lang,
  $rootScope, $http, $uibModal, $state, companyItemShoppingDetails, metabuyerCache
) {

  // PR data used in local controller
  var localPRData = {};

  $scope.imageBasePath            = pathConstants.apiUrls.image.pr;
  $scope.minDateItem              = new Date();
  $scope.currentUser              = $rootScope.currentUser || {_id: null};
  $scope.accountCodes             = [];
  $scope.accountAssignment        = [];
  $scope.itemMainCheckBox         = {
    selected : false
  };
  var otherChargesFields = [
    'freight',
    'transportation',
    'insurance',
    'bahan_bakar_tax',
    'withholding_tax',
    'miscellaneous'
  ];
  $scope.defaultItemOthersDetail         = [
    {code: 'budget', name: 'budget_number'},
    {code: 'cost_center', name: 'cost_center'},
    {code: 'needed_by_date', name: 'needed_by_date'},
    {code: 'delivery_address', name: 'delivery_address'},
    {code: 'note_to_supplier', name: 'note_to_supplier'},
    {code: 'packing_info',name: 'packing_info'},
    {code: 'project_code',name: 'project_code'},
    {code: 'account_assignments',name: 'account_assignments'},
    {code: 'other_charges_group', name: 'other_charges_group'},
    {code: 'insurance', name: 'insurance'},
    {code: 'freight', name: 'freight'},
    {code: 'transportation', name: 'transportation'},
    {code: 'bahan_bakar_tax', name: 'bahan_bakar_tax_(7.5%)'},
    {code: 'withholding_tax', name: 'withholding_tax_(10%)'},
    {code: 'miscellaneous', name: 'miscellaneous'}
  ];
  $scope.itemOthersDetail = angular.copy($scope.defaultItemOthersDetail);
  $scope.itemMainCheckBox = {};
  var fieldNameConversion = {
    qty: {
      name: 'qty',
      type: 'number'
    },
    price: {
      name: 'price',
      type: 'number'
    },
    discount: {
      name: 'discount',
      type: 'numberPercentage'
    },
    tax: {
      name: 'tax_id',
      type: 'string'
    },
    'supplier-contact-person': {
      name: 'contact_person',
      type: 'object'
    },
    'supplier-branch-contact-person': {
      name: 'branch',
      type: 'string'
    },
    'needed-by': {
      name: 'needed_by_date',
      type: 'string'
    },
    'note-to-supplier': {
      name: 'note_to_supplier',
      type: 'string'
    },
    'packing-info': {
      name:'packing_info',
      type: 'string'
    },
    'delivery-address': {
      name: 'delivery_address_id',
      type: 'string'
    },
    'set-supplier-branch': {
      name: 'branch',
      type: 'string'
    },
    'unset-supplier-branch': {
      name: 'branch',
      type: 'string'
    },
    'account-code': {
      name: 'account_code_id',
      type: 'string'
    },
    'split-po-responsive': {
      name: 'split',
      type: 'string'
    },
    'account-assignments': {
      name: 'account_assignments',
      type: 'object'
    },
    freights: {
      amount: {
        name: 'amount',
        type: 'numberPercentage'
      },
      discount: {
        name: 'discount',
        type: 'numberPercentage'
      },
      tax_id: {
        name: 'tax_id',
        type: 'string'
      }
    },
    transportation: {
      amount: {
        name: 'amount',
        type: 'numberPercentage'
      },
      discount: {
        name: 'discount',
        type: 'numberPercentage'
      },
      tax_id: {
        name: 'tax_id',
        type: 'string'
      }
    },
      budget: {
      name: 'budget',
      type: 'object'
    },
    'cost-center': {
      name: 'cost_center',
      type: 'object'
    },
    'other-charges-group' : {
      name: 'other_charges_group',
      type: 'object'
    },
    'project-code': {
      name: 'project_code',
      type: 'object'
    },
    'distributor-code': {
      name: 'distributor_code',
      type: 'string'
    },
    'account-assignment': {
      name: 'account_assigment',
      type: 'string'
    },
    'justification': {
      name: 'justification',
      type: 'string'
    }
  };

  $scope.getTemplateUrl = getTemplateUrl;
  $scope.PRData = PRData;
  $scope.differentCurrencyChecker = differentCurrencyChecker;
  $scope.setLoading = setLoading;
  $scope.checkLoading = checkLoading;
  $scope.updateItem = updateItem;
  $scope.removeItems = removeItems;
  $scope.duplicateItem = duplicateItem;
  $scope.addingDiscount = addingDiscount;
  $scope.addLumpSum = addLumpSum;
  $scope.updateLumpSum = updateLumpSum;
  $scope.addingLumpSum = addingLumpSum;
  $scope.addOthers = addOthers;
  $scope.checkOthersValue = checkOthersValue;
  $scope.isPRCreator = isPRCreator;
  $scope.allowEditingPR = allowEditingPR;
  $scope.openDatePicker = openDatePicker;
  $scope.updateNeededByDate = updateNeededByDate;
  $scope.removeOtherValue = removeOtherValue;
  $scope.getDeliveryAddresses = getDeliveryAddresses;
  $scope.onSelectDeliveryAddress = onSelectDeliveryAddress;
  $scope.getAccountCodes = getAccountCodes;
  $scope.onSelectSupplierContactPerson = onSelectSupplierContactPerson;
  $scope.onSelectBranchSupplierContactPerson = onSelectBranchSupplierContactPerson;
  $scope.onSelectAccountCode = onSelectAccountCode;
  $scope.itemMainCheckboxClicked = itemMainCheckboxClicked;
  $scope.updateMultiItems = updateMultiItems;
  $scope.addingFreightsTransport = addingFreightsTransport;
  $scope.updateFreightTransport = updateFreightTransport;
  $scope.removeFreightOrTransportation = removeFreightOrTransportation;
  $scope.removeSplit = removeSplit;
  $scope.removeTax = removeTax;
  $scope.showLumpSumButton = showLumpSumButton;
  $scope.updateItemTax = updateItemTax;
  $scope.taxList = getTaxData;
  $scope.canEditPR = canEditPR;
  $scope.supplierBranchModal = supplierBranchModal;
  $scope.onSelectSupplierBranch = onSelectSupplierBranch;
  $scope.updateDiscountInputFocus = updateDiscountInputFocus;
  $scope.Unchecked = Unchecked;
  $scope.checkItemExpiryDate = checkItemExpiryDate;
  $scope.checkAccountCodeSettings = checkAccountCodeSettings;
  $scope.onSelectLumpSumDiscountAccountCode = onSelectLumpSumDiscountAccountCode;
  $scope.removeLumpSumDiscount = removeLumpSumDiscount;
  $scope.integrationSolutionAllowedForLumpSum = integrationSolutionAllowedForLumpSum;
  $scope.addLumpSumDiscountAccountCode = addLumpSumDiscountAccountCode;
  $scope.removeLumpSumDiscountAccountCode = removeLumpSumDiscountAccountCode;
  $scope.checkLumpSumDiscountAccountCode = checkLumpSumDiscountAccountCode;
  $scope.checkLineDiscount = checkLineDiscount;
  $scope.checkCompanyIntegrationStatus = checkCompanyIntegrationStatus;
  $scope.hasCmmsIntegration = hasCmmsIntegration;
  $scope.canEditQuantity = canEditQuantity;
  $scope.canEditTax = canEditTax;
  $scope.disableAddLineItem = disableAddLineItem;
  $scope.setOtherCharges = setOtherCharges;
  $scope.updateOtherChargesTax = updateOtherChargesTax;
  $scope.removeOtherCharges = removeOtherCharges;
  $scope.removeOtherChargesTax = removeOtherChargesTax;
  $scope.onSelectOtherChargesGroup = onSelectOtherChargesGroup;
  $scope.updateBudget = updateBudget;
  $scope.removeOtherChargesGroup = removeOtherChargesGroup;
  $scope.focChecked = focChecked;
  $scope.addItemCheck = addItemCheck;
  $scope.showGrayedOutAddButton = showGrayedOutAddButton;
  $scope.checkOtherCharges = checkOtherCharges;
  $scope.showAdtafButton = showAdtafButton;
  $scope.checkAllOtherCharges = checkAllOtherCharges;
  $scope.checkOtherChargesGroup = checkOtherChargesGroup;
  $scope.onSelectDistributor = onSelectDistributor;
  $scope.expenseType = prV2Function.getPRData().expense_type;
  $scope.checkOtherChargesGroupField = checkOtherChargesGroupField;
  $scope.isPriceComparisonProcessEnabled = isPriceComparisonProcessEnabled;

  function isPriceComparisonProcessEnabled() {
    var isPriceComparisonEnabled = $scope.PRData().company.config.price_comparison || false;
    var isPricingAvailable = $scope.PRData().no_pricing_available || false;

    return isPriceComparisonEnabled && isPricingAvailable;
  }

  function addItemCheck(){
    if (!!$scope.PRData().expense_type){
      $state.go('main.user.purchaseRequisitionsV2.details.catalog.tab');
    }
    else{
      toastr.error('Please select an Expense Type.')
    }
  }
  function focChecked(item, index){
    if(item.focOption === undefined)
      item.focOption= false;

    sendingUpdateItem(index, item, 'free-of-charge', {'foc' : item.focOption }, !item.focOption)
  }

  function removeOtherChargesGroup(itemIndex) {
    var item = localPRData.pr_items[itemIndex];
    updateItem(itemIndex, 'other-charges-group', false, {}, item.backup_other_charges_group)
  }

  function onSelectOtherChargesGroup(itemIndex) {
    var item = localPRData.pr_items[itemIndex];
    updateItem(itemIndex, 'other-charges-group', false, item.other_charges_group, item.backup_other_charges_group)
  }

  function otherChargesDiscountValidation(value, preventDecimal) {
    var message = "";
    message = percentageValidation(value);

    //verify the decimals for the discounts
    if(!message)
      message = checkDecimal(value, preventDecimal);

    if (!!message)
      toastr.error(message);

    return !!message;
  }

  function setOtherCharges(itemIndex, field, backupValue, action) {
    var item = localPRData.pr_items[itemIndex];

    var params = {};
    params.other_charges = field;

    // remark only for miscellaneous
    if (field === 'miscellaneous' && !!item[field].remark) {
      params.remark = item[field].remark;
    }

    if (!!item[field].amount) {
      params.amount = item[field].amount;
    }

    if (!!item[field].discount_amount) {
      if (!!otherChargesDiscountValidation(item[field].discount_amount, !item.currency.allow_decimal)){
        setLineItemBackupValue(item, field, itemIndex, backupValue);
        return false;
      }

      var discountString = String(item[field].discount_amount);
      var isNotPercentage = discountString.indexOf('%') === -1;
      discountString = Number(discountString.replace('%', ''));
      params.discount_percentage = discountString;

      if (isNotPercentage) {
        params.discount_amount = Number(discountString);
        if (item[field].discount_amount !== backupValue.discount_amount) {
          params.discount_percentage = undefined;
        } else {
          params.discount_percentage = item[field].discount_percentage;
        }
      }

      if (!!params.discount_percentage) {
        params.discount_amount = undefined;
      }
    }

    if (!!item[field].tax_id) {
      params.tax_id = item[field].tax_id;
    } else if (!!item[field].tax) {
      params.tax_id = item[field].tax._id;
    }

    if(!!item[field].calculation_method) {
      params.calculation_method = item[field].calculation_method;
    }

    if(!!item[field].other_charges_group) {
      params.other_charges_group = item[field].other_charges_group;
    }

    if(!!item[field].is_other_charges_group_tax) {
      params.is_other_charges_group_tax = item[field].is_other_charges_group_tax;
    }

    if (!!item[field].percent_of_extended_cost) {
      params.percent_of_extended_cost = item[field].percent_of_extended_cost;
    }

    if (!!item[field].original_amount) {
      params.original_amount = item[field].original_amount;
    }

    if (!!item[field].currency) {
      params.currency = item[field].currency;
    }

    if (action === 'remove') {
      purchaseRequisitionsV2Services.removeOtherCharges(
        {
          requisition_id: localPRData._id,
          requisition_item_id: item._id,
          field: field
        },
        function (resource) {
          if (!!resource && !!resource.content && !!resource.content.data) {
            prV2Function.setPRData(resource.content.data);
          }
        }, function (error) {
          setLineItemBackupValue(item, field, itemIndex, backupValue);
          globalFunc.objectErrorMessage(error);
        })
    } else {
      purchaseRequisitionsV2Services.setOtherCharges(
        {
          requisition_id: localPRData._id,
          requisition_item_id: item._id
        },
        params, function (resource) {
          if (!!resource && !!resource.content && !!resource.content.data) {
            prV2Function.setPRData(resource.content.data);
          }
        }, function (error) {
          setLineItemBackupValue(item, field, itemIndex, backupValue);
          globalFunc.objectErrorMessage(error);
        })
    }
  }
  function disableAddLineItem() {
    return prV2Function.getDocumentRule('allow_to_add_line_item') === false;
  }

  function canEditTax() {
    return !hasCmmsIntegration();
  }

  function canEditQuantity() {
    return !hasCmmsIntegration();
  }

  function hasCmmsIntegration() {
    return prV2Function.hasCmmsIntegration();
  }

  function supplierBranchModal(pr, index, branch) {
    var modalInstance = $uibModal.open({
      templateUrl: 'components/purchaseRequisitionV2Components/branchPrV2Listing/branchPrV2Listing.html',
      controller: 'branchPrV2ListingCtrl',
      backdrop: 'static',
      keyboard: false,
      scope: $scope,
      size: 'lg',
      resolve: {
        branchSelection: function ($q, supplierBranches) {
          var deferred = $q.defer();
          var params = {
            id : pr,
            mode : 'active'
          };
          supplierBranches.get(
            params,
            function (resource) {
              if (!!resource && !!resource.content) {
                deferred.resolve(resource.content);
              }
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        },
        onSelectSupplierBranch : function() {
          return $scope.onSelectSupplierBranch;
        },
        itemIndex : index,
        backupBranch : branch
      }
    });
  }

  function onSelectSupplierBranch(supplierBranch, itemIndex, backupBranch) {

    if (!!backupBranch) {
      backupBranch = null;
    }

    var pr = prV2Function.getPRData();

    pr.pr_items[itemIndex].branch = supplierBranch;

    var payload = {
      supplier_id: pr.pr_items[itemIndex].supplier._id,
      branch_id: supplierBranch._id
    };

    var validateLumpSum = prV2Function.validateLumpSum(pr.pr_items[itemIndex], itemIndex);

    if (!!pr.lump_sum_discount && !validateLumpSum.status) {
      swal({
        title: 'Confirm add supplier branch?',
        text: validateLumpSum.message,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true
      }, function (isConfirm) {
        if (isConfirm) {
          // remove lump sum
          prV2Function.removeLumpSum(pr._id, function (resource) {
            if (!!resource)
              updateItem(itemIndex, 'set-supplier-branch', false, payload, '');
          })
        }
        else
          $scope.PRData().pr_items[itemIndex].branch = backupBranch;
      });
    }
    else {
      updateItem(itemIndex, 'set-supplier-branch', false, payload, '');
    }
    return true;
  }

  /**
   * function to handle the main check box, in case of selected or unselected
   * @param value
   */
  function itemMainCheckboxClicked(value){
    if(value){
      selectAllItems();
    }else{
      $scope.itemMainCheckBox.selected = false;
      unSelectAllItems();
    }
    $rootScope.$emit('itemMainCheckboxClickedTo', value);
  }

  /**
   * makes all the items' checkboxes selected
   */
  function selectAllItems(){
    for (var i in $scope.PRData().pr_items){
      $scope.PRData().pr_items[i].selected = true;
    }
  }

  /**
   * makes all the items' checkboxes unselected
   */
  function unSelectAllItems(){
    for (var i in $scope.PRData().pr_items){
      $scope.PRData().pr_items[i].selected = false;
    }
  }

  /**
   * makes the main checkbox uncheck if one item unselected
   */
  function Unchecked(){
    $scope.itemMainCheckBox.selected = true;
    _.forEach ($scope.PRData().pr_items, function(item){
      if (item.selected === false){
        $scope.itemMainCheckBox.selected = false;
        return;
      }
    });

  }

  function getTemplateUrl(state) {
    if (!$rootScope.isMobileMode) {
      switch (state) {
        case 'editable':
          $scope.myTemplateUrl = 'components/purchaseRequisitionV2Components/itemV2List/prV2ItemList.EditableTemplate.html';
          break;
        case 'notEditable' :
          $scope.myTemplateUrl = 'components/purchaseRequisitionV2Components/itemV2List/prV2ItemList.NonEditableTemplate.html';
          break;
      }
    }

    if ($rootScope.isMobileMode) {
      $scope.myTemplateUrl = 'components/purchaseRequisitionV2Components/itemV2List/prV2ItemList.NonEditableTemplate-mobile.html';
    }
  }

  $scope.$watch('editingState', function (newValue) {
    getTemplateUrl(newValue);
  });

  /**
   * function to open date picker
   * @param $event
   * @param itemIndex
   */
  function openDatePicker($event, itemIndex) {
    $event.preventDefault();
    $event.stopPropagation();
    $scope.PRData().pr_items[itemIndex].datePicker = true;
  }

  /**
   * get delivery addresses from prV2Function
   * shared by pr header
   * @returns {*}
   */
  function getDeliveryAddresses() {
    return prV2Function.getCostCenterDeliveryAddresses();
  }

  /**
   * on selecting delivery address in line item
   * @param address
   * @param itemIndex
   * @param backupAddress
   */
  function onSelectDeliveryAddress(address, itemIndex, backupAddress) {
    var pr = prV2Function.getPRData();
    pr.pr_items[itemIndex].delivery_address = address;
    var validateLumpSum = prV2Function.validateLumpSum(pr.pr_items[itemIndex], itemIndex);

    if (!!pr.lump_sum_discount && !validateLumpSum.status) {
      swal({
        title: 'Confirm add delivery address?',
        text: validateLumpSum.message,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true
      }, function(isConfirm){
        if (isConfirm) {
          // remove lump sum
          prV2Function.removeLumpSum(pr._id, function(resource) {
            if (!!resource)
              updateItem(itemIndex, 'delivery-address', false, address._id, '');
          })
        }
        else
          $scope.PRData().pr_items[itemIndex].delivery_address = backupAddress;
      });
    }
    else
      updateItem(itemIndex, 'delivery-address', false, address._id, '');
  }

  /**
   * Updating supplier contact person for item
   */
  function onSelectSupplierContactPerson(selectedContactPerson, itemIndex) {
    var pr = prV2Function.getPRData();

    var backupContactPerson = pr.pr_items[itemIndex].supplier.contact_person;

    var validateLumpSum = prV2Function.validateLumpSum(pr.pr_items[itemIndex], itemIndex);
    if (!!pr.lump_sum_discount && !validateLumpSum.status && pr.pr_items.length > 1) {
      swal({
        title: 'Confirm change supplier contact person? ',
        text: validateLumpSum.message,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true
      }, function(isConfirm){
        if (isConfirm) {
          // remove lump sum
          prV2Function.removeLumpSum(pr._id, function(resource) {
            if (!!resource)
              updateItem(itemIndex, 'supplier-contact-person', false, selectedContactPerson, '');
          });
        }
        else
          updateItem(itemIndex, 'supplier-contact-person', false, backupContactPerson, '');
      });
    }
    else {
      updateItem(itemIndex, 'supplier-contact-person', false, selectedContactPerson, '');
    }
  }

  function onSelectBranchSupplierContactPerson(branch, itemIndex) {
    var pr = prV2Function.getPRData();

    var backupBranchContactPerson = branch.contact_person;

    var validateLumpSum = prV2Function.validateLumpSum(pr.pr_items[itemIndex], itemIndex);
    if (!!pr.lump_sum_discount && !validateLumpSum.status && pr.pr_items.length > 1) {
      swal({
        title: 'Confirm change supplier branch contact person? ',
        text: validateLumpSum.message,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true
      }, function(isConfirm){
        if (isConfirm) {
          // remove lump sum
          prV2Function.removeLumpSum(pr._id, function(resource) {
            if (!!resource)
              updateItem(itemIndex, 'supplier-branch-contact-person', false, branch.contact_person._id, '');
          });
        }
        else
          updateItem(itemIndex, 'supplier-branch-contact-person', false, backupBranchContactPerson._id, '');
      });
    }
    else {
      updateItem(itemIndex, 'supplier-branch-contact-person', false, branch, '');
    }
  }

  /**
   * updating account code
   */
  function onSelectAccountCode(selectedAccountCode, itemIndex) {
    updateItem(itemIndex, 'account-code', false, selectedAccountCode._id, '');
  }

  function onSelectDistributor(distributor, itemIndex) {
    updateItem(itemIndex, 'distributor-code', false, distributor.code, '');
  }

  /**
   * Update needed by date value
   * @param itemIndex
   * @param date
   * @param dateBackup
   */
  function updateNeededByDate(itemIndex, date, dateBackup) {
    var milliTime = globalFunc.convertDateToTimestamp(date),
      milliTimeBackup = globalFunc.convertDateToTimestamp(dateBackup);
    updateItem(itemIndex, 'needed-by', false, milliTime, milliTimeBackup);
  }

  /**
   * check if the user is PRCreator
   * @returns {boolean}
   */
  function isPRCreator() {
    return ($scope.currentUser._id === $scope.PRData().creator_info._id);
  }

  /**
   * Check if current approver is a PA
   * @returns {*}
   */
  function allowEditingPR() {
    if (prV2Function.getPRData().status === 'pending') {
      var isProcurementSpecialist = prV2Function.isProcurementSpecialist(
        !!$rootScope.currentUser ? $rootScope.currentUser.role_assignments : null
      );

      var isPA = prV2Function.checkCurrentApproverIsPA(
        !!prV2Function.getPRData() ? prV2Function.getPRData().waiting_on : null,
        !!$scope.currentUser  ? $scope.currentUser._id : null,
        !!$scope.currentUser  ? $scope.currentUser.role_assignments : null,
        prV2Function.getPRData().company._id
      );

      return isPA || isProcurementSpecialist;
    }

    return false
  }

  function removeOtherValue(item, field, itemIndex) {
    var tempFieldName = field;

    if (field === 'needed-by')
      tempFieldName = 'needed_by_date';
    if (field === 'account-code')
      tempFieldName = 'account_code';
    if (field === 'branch')
      tempFieldName = 'branch';
    if (field === 'account-assignment')
      tempFieldName = 'account_assignment';
    if (field === 'cost-center')
      tempFieldName = 'cost_center';
    if (field === 'packing-info')
      tempFieldName = 'packing_info';
    if (field === 'note-to-supplier')
      tempFieldName = 'note_to_supplier';
    if (field === 'project-code')
      tempFieldName = 'project_code';
    if (field === 'justification')
      tempFieldName = 'justification';

    if (!!item.selecting_others && !!item.selecting_others.length) {
      var arrayIndex = item.selecting_others.indexOf(tempFieldName);
      if (arrayIndex > -1)
        item.selecting_others.splice(arrayIndex, 1);
    }

    if (!!item[tempFieldName]) {
      if (field === 'delivery_address')
        field = 'delivery-address';

      if (field === 'branch')
        field = 'unset-supplier-branch';

      updateItem(itemIndex, field, false, null, item[tempFieldName]);
    }
  }

  function removeTax(itemIndex, othersType){
    updateFreightTransport(itemIndex, othersType, 'tax', 'tax_id', {_id:null});
  }

  function removeOtherChargesTax(itemIndex, field){
    var item = $scope.PRData().pr_items[itemIndex];
    item[field].tax = null;

    setOtherCharges(itemIndex, field);
  }

  function removeOtherCharges(itemIndex, field) {
    var item = $scope.PRData().pr_items[itemIndex];
    item[field] = {};

    prV2Function.removeOthers(field);
    setOtherCharges(itemIndex, field, null, 'remove');
  }

  function removeFreightOrTransportation(field, itemIndex) {

    var item = $scope.PRData().pr_items[itemIndex];
    var param = {};
    var backupField = 'backup_' + field;
    var backupValue = item[field][backupField];

    param.amount = null;
    param.discount = null;
    param.tax_id = null;

    sendingUpdateItem (itemIndex, item, field, param, backupValue);
  }

  function removeSplit (item, itemIndex) {
    deleteItemField(itemIndex, item, 'split-po-responsive');
  }

  /**
   * To get PR Data from service
   * and store the data also in localPRData
   * @returns {{}}
   */
  function PRData() {
    localPRData = prV2Function.getPRData();
    return localPRData;
  }

  /**
   * adding state on adding others value
   * set an array called selecting_others
   * and push the field value to the array
   * @param field
   * @param itemIndex
   */
  function addOthers(field, itemIndex) {
    var pr = $scope.PRData();
    var item = pr.pr_items[itemIndex];
    prV2Function.storeOthers(field, itemIndex);

    if (!item.selecting_others){
      item.selecting_others = [];
    }

    if (field === 'split') {
      validateOnAddingSplit(item, itemIndex);
    }

    var validateAddOthers = validateAddOthersWithLumpSum(field);

    if (!!pr.lump_sum_discount && validateAddOthers.status && pr.pr_items.length > 1) {
      swal({
        title: 'Confirm addition? ',
        text: validateAddOthers.message,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true
      }, function (isConfirm) {
        if (isConfirm) {
          // remove lump sum
          prV2Function.removeLumpSum(pr._id, function (resource) {
            if (!!resource) {
              if (item.selecting_others.indexOf(field) === -1)
                item.selecting_others.push(field);

              localPRData.pr_items[itemIndex] = item;
              prV2Function.setPRData(localPRData);
              $scope.PRData().pr_items[itemIndex] = item;
            }
          });
        }
      });
    }
    else {
      if (item.selecting_others.indexOf(field) === -1) {
        item.selecting_others.push(field);
      }
      if (field === 'supplier_contact_person') {
        // This is for the UI to show the contact person selection box
        localPRData.pr_items[itemIndex].supplier.contact_person = true
      }
      localPRData.pr_items[itemIndex] = item;
      prV2Function.setPRData(localPRData);
      $scope.PRData().pr_items[itemIndex] = item;
    }
  }

  function validateAddOthersWithLumpSum(field) {
    if (field === 'branch') {
      return {
        status: true,
        message: 'The lump sum discount will be removed, due to having different supplier branch'
      };
    }
    else if (field === 'delivery_address') {
      return {
        status: true,
        message: 'The lump sum discount will be removed, due to having different delivery address'
      };
    }
    else {
      return {status: false};
    }
  }

  function validateOnAddingSplit(item, itemIndex) {
    if (!!item.freights.amount || !!item.transportation.amount || !!item.account_code) {
      swal({
        title: 'Confirm add split?',
        text: 'Adding split will remove freight, transportation and account code from line item.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true
      }, function (isConfirm) {
        if (!!isConfirm) {
          item.selecting_others.push('split');
        }
        else {
          removeSplit(item, itemIndex);
        }
      });
    }
    else {
      item.selecting_others.push('split');
    }
  }

  function updateItemTax(itemIndex, field, isDecimal, newValue, backupValue) {
    var pr = prV2Function.getPRData();
    pr.pr_items[itemIndex].tax = newValue;
    var validateLumpSum = prV2Function.validateLumpSum(pr.pr_items[itemIndex], itemIndex);

    if (!!pr.lump_sum_discount && !validateLumpSum.status) {
      swal({
        title: 'Confirm update tax?',
        text: validateLumpSum.message,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true
      }, function(isConfirm){
        if (isConfirm) {
          // remove lump sum
          prV2Function.removeLumpSum(pr._id, function(resource) {
            if (!!resource)
              updateItem(itemIndex, field, isDecimal, newValue, backupValue);
            $rootScope.$broadcast('checkIntegrationData');
          })
        }
        // change back to the prev tax
        else
          $scope.PRData().pr_items[itemIndex].tax = backupValue;
        $rootScope.$broadcast('checkIntegrationData');
      });
    }
    else
      updateItem(itemIndex, field, isDecimal, newValue, backupValue);
    $rootScope.$broadcast('checkIntegrationData');
  }

  function checkOtherCharges(item, field) {

    // Hide split if has lump sum then hiding freights and transportation from the options
    if (!!$scope.PRData().lump_sum_discount && field === 'split') {
      return true;
    } else if ((field === 'freight' || field === 'transportation') &&
      (!!item.split)) {
      return true;
    }
    else if (otherChargesFields.indexOf(field) > -1 && !!item[field]) {
      return (!!item[field] && !_.isUndefined(item[field].amount) ||
        (!!item.selecting_others && !!item.selecting_others.length && item.selecting_others.indexOf(field) > -1));
    }
    else if (!!item.selecting_others && !!item.selecting_others.length) {
      return (item.selecting_others.indexOf(field) > -1);
    }
    else if (!!item[field]) {
      return (!!item[field]);
    }
  }

  function checkAllOtherCharges(item){
    var otherChargesExist = false;
    _.forEach(otherChargesFields, function(e){
      if (!!checkOtherCharges(item,e))
        otherChargesExist = true;
    })
    return otherChargesExist;
  }

  function checkOtherChargesGroup(item){
    if (item.otherChargesGroupSelected === true || (!!item.selecting_others && item.selecting_others.indexOf('other_charges_group') > -1)){
      return true
    }
    return false
  }

  function isOtherChargesGroupSelected(item, field){
    var isSelected = false;
    if (item !== undefined) {
      _.forEach(otherChargesFields, function(e){
        if (!!item[e] && !!item[e]['other_charges_group'] && _.contains(otherChargesFields, field))  {
          isSelected = true;
        }
      });
    }
    return isSelected;
  }

  function checkOtherChargesGroupField(item, field){
    if (isOtherChargesGroupSelected(item,field)) {
      if (!item[field]) {
        return false;
      }
    }
    return true;
  }

  /**
   * Check if the others field is available for listing or already selected for panels to display
   * @param item
   * @param field
   * @param type
   * @returns {boolean}
   */
  function checkOthersValue(item, field, type) {
    if (item === undefined) {
      return false;
    }
    if (isOtherChargesGroupSelected(item, field)) {
      return true;
    }

    if(type === 'list'){
      if(item.otherChargesGroupSelected === true)
        if(otherChargesFields.indexOf(field) > -1)
          return true;
    }

    // Hide split if has lump sum then hiding freights, transportation and account code from the options
    if (!!$scope.PRData().lump_sum_discount && field === 'split') {
      return true;
    } else if ((field === 'freight' || field === 'transportation' || field === 'account_code') &&
      (!!item.split)) {
      return true;
    }
    else if (item.supplier && field === 'supplier_contact_person') {
      return !!item.supplier.contact_person;
    }
    else if (otherChargesFields.indexOf(field) > -1 && !!item[field]) {
      return (item[field].amount >= 0 ||
        (!!item.selecting_others && !!item.selecting_others.length && item.selecting_others.indexOf(field) > -1));
    }
    else if ((!!item.selecting_others && !!item.selecting_others.length) && item.selecting_others.indexOf(field) > -1 && _.isEmpty(item[field])) {
      return true;
    }
    else if (field === 'account_assignments') {
      if (!!item.account_assignments && item.account_assignments.length > 0) {
        return true;
      }
      else {
        return false;
      }
    }
    else if (field === 'budget'){
      if ($scope.PRData().document_rule.hasOwnProperty('allow_add_budget')){
        // allow budget and not mandatory
        if (!!$scope.PRData().document_rule.allow_add_budget && !$scope.PRData().document_rule.is_budget_mandatory){
          return (!!item[field])
        }
        // dont allow budget or budget is mandatory
        else if (!$scope.PRData().document_rule.allow_add_budget || !!$scope.PRData().document_rule.is_budget_mandatory){
          return true
        }
      }
      else {
        return true
      }
    }
    else if (field === 'project_code') {
      var projectCodeSetting = _.isEmpty($scope.PRData().company.requisition_settings.project_code_capex_mandatory) ?
        null : $scope.PRData().company.requisition_settings.project_code_capex_mandatory.is_enabled;

      if (projectCodeSetting && $scope.PRData().expense_type.category === 'CAPEX') {
        return true;
      } else {
        return !!item[field] || item[field] === '' ? true : false;
      }
    }
    else if (field === 'justification') {
      // justification is mandatory for non-catalog and always show
      return !item.is_catalog_item ? true : false;
    }
    else if (!!item[field]) {
      return (!!item[field]);
    }
  }

  function checkAccountCodeSettings() {
    var pr = prV2Function.getPRData();

    return (!!pr.company.requisition_settings.account_code &&
      !!pr.company.requisition_settings.account_code.is_mandatory);
  }

  /**
   * adding lumpsum state
   * to indicate user is adding or editing lumpsum
   * @param value
   */
  function addLumpSum(value) {
    localPRData.lump_sum_discount = value;
    localPRData.lump_sum_discount_entry = value;
    prV2Function.setPRData(localPRData);
  }

  /**
   * Update lump sum value
   * @param value
   * @param backupValue
   */
  function updateLumpSum(value, backupValue) {
    var param = {};

    if (value == backupValue)
      return;

    if (value !== null) {
      var validateValue = lineItemValidation(value, 'numberPercentage', true);
      if (!!validateValue) {
        setLineItemBackupValue(false, 'lump_sum_discount', false, backupValue, false);
        return false;
      }

      var convertValToStr = value.toString();
      if (convertValToStr.indexOf('%') > -1)
        param.lump_sum_percent = Number(convertValToStr.replace('%', ''));
      else
        param.lump_sum_net = Number(value);
    }

    //check if items currency is different from PR currency
    if (differentCurrencyChecker(localPRData.pr_items[0].currency.code)) {
      param.lump_sum_item_currency = localPRData.pr_items[0].currency;
    }

    prV2Function.setLoading('lineItemTable', true);
    prV2Function.setLoading('updatePRField', true);
    purchaseRequisitionsV2Services.setLumpSum({
      requisition_id: localPRData._id
    }, param, function(resource) {
      if (!!resource && !!resource.content && !!resource.content.data)
        prV2Function.setPRData(resource.content.data);

      prV2Function.setLoading('lineItemTable', false);
    }, function(error) {
      setLineItemBackupValue(false, 'lump_sum_discount', false, backupValue, false);
      prV2Function.setLoading('lineItemTable', false);
      globalFunc.objectErrorMessage(error);
    });
  }

  /**
   * Check param currency code with pr currency code
   * @param itemCurrencyCode
   * @returns {boolean}
   */
  function differentCurrencyChecker(itemCurrencyCode) {
    // for company MYR/SGD/USD currency with different item's currency. default to 4 decimal places
    if (localPRData.currency.code !== itemCurrencyCode) {
      if (localPRData.currency.code === 'MYR' || localPRData.currency.code === 'USD' || localPRData.currency.code === 'SGD') {
        // 25 Nov 21 | only apply for unit price. todo: change all formal decimal if it applies to all numbers in line item
        $scope.currencyFormatDecimal = 4;
      }
    }
    return (localPRData.currency.code !== itemCurrencyCode);
  }

  /**
   * Set loading value in prV2Function service
   * @param loadingObject
   * @param loadingValue
   */
  function setLoading(loadingObject, loadingValue) {
    prV2Function.setLoading(loadingObject, loadingValue);
  }

  /**
   * get loading value from prV2Function service
   * @param loadingObject
   * @returns {*|{}}
   */
  function checkLoading(loadingObject) {
    return prV2Function.getLoading(loadingObject);
  }

  function addingDiscount(itemIndex) {
    // Update data
    PRData();
    localPRData.pr_items[itemIndex].addingDiscount = true;
    prV2Function.setPRData(localPRData);
  }

  /**
   * Check if item allow line discount
   * @returns {boolean}
   */
  function checkLineDiscount() {
    return prV2Function.getDocumentRule('allow_line_discount') !== false;
  }

  /**
   * Updating the discount input field to blur
   */
  function updateDiscountInputFocus(index) {
    angular.element('#discountInput' + index).blur();
  }

  /**
   * state on adding freight or transportation
   * @param itemIndex
   * @param type
   * @param state
   */
  function addingFreightsTransport(itemIndex, type, state) {
    // Update data
    PRData();
    localPRData.pr_items[itemIndex][type][state] = true;
    prV2Function.setPRData(localPRData);
  }

  function addingLumpSum() {
    // Update data
    PRData();
    localPRData.addingLumpSum = true;
    prV2Function.setPRData(localPRData);
  }

  /**
   * Duplicating line item
   * @param item
   */
  function duplicateItem(item) {
    var pr = prV2Function.getPRData();
    if ($scope.pritemlimit > 0 && !!$scope.pr.pr_items && $scope.pr.pr_items.length >= $scope.pritemlimit) {
      globalFunc.objectErrorMessage("PR line item limit reached. Only " + $scope.pritemlimit + " line items allowed per cart");
      return;
    }

    prV2Function.setLoading('lineItemTable', true);
    prV2Function.setLoading('updatePRField', true);

    if (!!item) {
      var itemId = item._id;
      purchaseRequisitionsV2Services.setItem({
        requisition_item_id: itemId,
        action: 'duplicate'
      }, function(resource) {
        if (!!resource && !!resource.content && !!resource.content.data)
          prV2Function.setPRData(resource.content.data);
        prV2Function.setLoading('lineItemTable', false);
      }, function(error) {
        prV2Function.setLoading('lineItemTable', false);
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * Remove item(s) by id, the function receives an array
   * @param arr
   */
  function removeItems(arr) {
    if (!!arr && _.isArray(arr) && arr.length > 0) {
      var item = arr[arr.length - 1];
      if (!!item) {
        prV2Function.setLoading('lineItemTable', true);
        prV2Function.setLoading('updatePRField', true);
        prV2Function.deleteLineItem(item._id).$promise.then(function (resource) {
          if (!!resource.content && !!resource.content.data) {
            prV2Function.setPRData(resource.content.data);
            $rootScope.$broadcast('checkIntegrationData');
          }
          else {
            globalFunc.objectErrorMessage('There was an error in removing items.');
            return;
          }
          arr.pop();
          removeItems(arr);

          var noPricingAvailableCheckedPrItem = false;

          if ($scope.PRData().pr_items !== undefined && $scope.PRData().pr_items.length > 0) {
            _.forEach($scope.PRData().pr_items, function (prItems) {
              if (prItems.no_pricing_available) {
                noPricingAvailableCheckedPrItem = true;
              }
            });
          }
          $rootScope.$broadcast("noPricingAvailableCheckedPrItem", noPricingAvailableCheckedPrItem);

        }, function (error) {
          globalFunc.objectErrorMessage(error);
        });
      }
      else {
        prV2Function.setLoading('lineItemTable', false);
        prV2Function.setLoading('updatePRField', false);
        $scope.itemMainCheckBox.selected = false;
      }
    } else {
      prV2Function.setLoading('lineItemTable', false);
      prV2Function.setLoading('updatePRField', false);
      $scope.itemMainCheckBox.selected = false;
    }
  }

  function updateOtherChargesTax(itemIndex, field, tax, backupValue) {
    var item = $scope.PRData().pr_items[itemIndex];
    item[field].tax_id = tax._id;

    setOtherCharges(itemIndex, field, backupValue);
  }

  function updateAccountAssignment (itemIndex)
  {
    var item =$scope.PRData().pr_items[itemIndex];

  }

  /**
   * Update Freight Item
   * @param itemIndex
   * @param othersType (freights or transportation)
   * @param fieldEntry - Field name for entry is using 'entry'
   * @param field
   * @param tax
   */
  function updateFreightTransport(itemIndex, othersType, fieldEntry, field, tax) {
    var item = $scope.PRData().pr_items[itemIndex];
    var param = {};
    var backupField = 'backup_' + othersType;
    var newValue = item[othersType][fieldEntry];
    var backupValue = item[backupField][fieldEntry];

    if (!!tax)
      newValue = tax._id;

    // validate input
    if (newValue == backupValue)
      return;

    if (!!lineItemValidation(newValue, fieldNameConversion[othersType][field].type, false)) {
      setLineItemBackupValue(item, field, itemIndex, backupValue, false);
      return false;
    }

    // send all the information (amount, discount, tax) in one request
    var amount_entry = item[othersType].entry.toString();
    if (amount_entry.indexOf('%') > -1)
      param.amount_percentage = Number(amount_entry.replace('%', ''));
    else
      param.amount = Number(amount_entry);

    param.discount = null;
    if (!!item[othersType].discount_entry) {
      var discount_entry = item[othersType].discount_entry.toString();
      if (discount_entry.indexOf('%') > -1)
        param.discount_percentage = Number(discount_entry.replace('%', ''));
      else
        param.discount = Number(discount_entry);
    }

    if (!!tax)
      param.tax_id = tax._id;
    else
      param.tax_id = item[othersType].tax_id;

    sendingUpdateItem (itemIndex, item, othersType, param, backupValue);
  }

  /**
   * Update Item value (QTY, discount, tax)
   * @param itemIndex
   * @param field
   * @param isDecimal
   * @param newValue
   * @param backupValue
   */
  function updateItem(itemIndex, field, isDecimal, newValue, backupValue) {
    var item = localPRData.pr_items[itemIndex];
    var fieldToSend = field;
    var param = {};
    var zeroAllowed = field === 'price';
    var fieldsThatAffectSplit = [
      'qty',
      'price',
      'tax',
      'discount',
      'freight',
      'transportation'
    ];

    if (newValue == backupValue)
      return;

    if (!!lineItemValidation(newValue, fieldNameConversion[field].type, isDecimal, zeroAllowed,
        !item.currency.allow_decimal)) {
      setLineItemBackupValue(item, field, itemIndex, backupValue, false);
      return false;
    }

    if (!!item.split && !!item.split.cost_centers && field !== 'split-po-responsive' &&
      (fieldsThatAffectSplit.indexOf(field) > -1)) {
      poSplitValidation(item, itemIndex, field, newValue, backupValue);
      return false;
    }

    if (field === 'budget' && !!item.cerf && !!newValue) {
      item.cerf.asset_category = newValue.asset_category;
    }

    if (field in fieldNameConversion)
      fieldToSend = fieldNameConversion[field].name;

    param[fieldToSend] = newValue;

    if (field === 'tax')
      param[fieldToSend] = newValue._id;

    // set is_percentage for discount
    if (field === 'discount') {
      newValue = newValue.toString();
      param.is_percentage = (newValue.indexOf('%') > -1);
      param[fieldToSend] = Number(newValue.replace('%', ''));
    }

    if (field === 'freight' || field === 'transportation') {
      newValue = newValue.toString();
      if (!!param[fieldToSend])
        delete param[fieldToSend];
      if (newValue.indexOf('%') > -1)
        param.amount_percentage = Number(newValue.replace('%', ''));
      else
        param.amount = Number(newValue);
    }

    if(field === 'project-code') {
      param.project_code = (newValue === null) ? null : prepareProjectCode(param.project_code);
      if (!!item.cerf && !!newValue) {
        item.cerf.asset_category = newValue.asset_category;
      }
    }

    sendingUpdateItem (itemIndex, item, field, param, backupValue);
  }

  /**
   * Sending updateItem request
   * @param itemIndex
   * @param item
   * @param field
   * @param param
   * @param backupValue
   */
  function sendingUpdateItem(itemIndex, item, field, param, backupValue) {
    if (!!item) {
      prV2Function.setLoading('lineItemTable', true);
      prV2Function.setLoading('updatePRField', true);
      purchaseRequisitionsV2Services.setItem({
        requisition_item_id: item._id,
        action: field
      }, param, function(resource) {
        if (!!resource && !!resource.content && !!resource.content.data)
          prV2Function.setPRData(resource.content.data);

        prV2Function.setLoading('lineItemTable', false);

      }, function(error) {
        setLineItemBackupValue(item, field, itemIndex, backupValue, false);
        prV2Function.setLoading('lineItemTable', false);
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * Sending deleteItem request
   * @param itemIndex
   * @param item
   * @param field
   */
  function deleteItemField(itemIndex, item, field) {
    if (!!item) {
      prV2Function.setLoading('lineItemTable', true);
      prV2Function.setLoading('updatePRField', true);
      purchaseRequisitionsV2Services.deleteItemField({
        id: item._id,
        action: field
      }, function(resource) {
        if (!!resource && !!resource.content && !!resource.content.data)
          prV2Function.setPRData(resource.content.data);

        // Set backup value
        prV2Function.setLoading('lineItemTable', false);
      }, function(error) {
        prV2Function.setLoading('lineItemTable', false);
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * updating item for mass edit
   * using recursive function to send the request one by one
   *
   * @param items
   * @param field
   * @param newValue
   * @param itemIndex
   *
   * @returns {string}
   */
  function updateMultiItems(items, field, newValue, itemIndex){

    var fieldToSend = field;
    var param = {};
    var backupValue = '';
    var index = itemIndex || 0;
    var verified = true;
    var item = items[index];

    if (lineItemValidation(newValue, fieldNameConversion[field].type, item.uom.is_fraction)) {
      verified = false;
      return 'error';
    }

    if (field in fieldNameConversion)
      fieldToSend = fieldNameConversion[field].name;

    param[fieldToSend] = newValue;

    if (field === 'tax')
      param[fieldToSend] = newValue._id;

    if (field === 'discount') {
      if (!!newValue) {
        newValue = newValue.toString();
        param.is_percentage = (newValue.indexOf('%') > -1);
        param[fieldToSend] = Number(newValue.replace('%', ''));

        if(newValue > item.amount){
          globalFunc.objectErrorMessage('The discount cannot be more than the Total amount');
          verified = false;
          prV2Function.setLoading('lineItemTable', false);
          return 'error';
        }
      } else if (newValue === 0) {
        param.is_percentage = false;
        param[fieldToSend] = 0;
      }
    }

    if (field === 'account-assignments') {
      var preparedData = [];
      _.forEach(newValue, function (value) {
        var item = {};
        if (!!value.cost_center && !!value.cost_center._id) {
          item.cost_center = value.cost_center;
        }

        if (!!value.account_code && !!value.account_code._id) {
          item.account_code = value.account_code;
        }

        if (!!value.percentage) {
          item.percentage = value.percentage;
        }
        if (!!value.amount_pr_currency) {
          item.amount_pr_currency = value.amount_pr_currency;
        }

        if (!!value.opex_io) {
        item.opex_io = value.opex_io;
      }

      preparedData.push(item);
        param[fieldToSend] = preparedData;
      });
    }

    if(verified) {
      prV2Function.setLoading('lineItemTable', true);
      prV2Function.setLoading('updatePRField', true);
      purchaseRequisitionsV2Services.setItem({
        requisition_item_id: item._id,
        action: field
      }, param, function(resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          prV2Function.setPRData(resource.content.data);
          addSelectedStatus(items, resource.content.data);
        }

        // go to the next item index
        // and validate if the next index is the last index
        // if not the last, call the updateMultiItems with the new index
        index++;
        if (!!items && items.length !== index)
          updateMultiItems(items, field, newValue, index);
        else
          prV2Function.setLoading('lineItemTable', false);
      }, function(error) {
        setLineItemBackupValue(item, field, itemIndex, backupValue, false);
        prV2Function.setLoading('lineItemTable', false);
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * re-added selected value to the resource data
   * @param prevPRItem
   * @param newPR
   * @returns {*}
   */
  function addSelectedStatus(prevPRItem, newPR) {
    _.forEach(newPR.pr_items, function (item) {
      var checkPrevPR = globalFunc.findInArray(prevPRItem, '_id', item._id);
      if (!!checkPrevPR && !!checkPrevPR.selected)
        item.selected = checkPrevPR.selected;
    });
    return newPR;
  }

  /**
   * Set or Restore the item backup value
   * @param item
   * @param field
   * @param itemIndex
   * @param backupValue
   * @param setValue
   * boolean value to set or to restore
   */
  function setLineItemBackupValue(item, field, itemIndex, backupValue, setValue) {

    // replace the value with backupValue
    var newFieldName = field;
    if (!!setValue)
      newFieldName = 'backup_' + field;

    if (!!item) {
      item[newFieldName] = backupValue;

      if (newFieldName === 'price')
        item.unit_price = backupValue;

      if (newFieldName === 'discount')
        item.discount_entry = backupValue;

      if (newFieldName === 'freight' || newFieldName === 'transportation' || newFieldName === 'insurance' || newFieldName === 'bahan_bakar_tax' || newFieldName === 'withholding_tax' || newFieldName === 'miscellaneous') {
        var backupField = 'backup_' + newFieldName;
        item[newFieldName] = item[backupField];
      }

      $scope.PRData().pr_items[itemIndex] = item;
    } else {
      $scope.PRData()[newFieldName] = backupValue;
    }
    prV2Function.setPRData($scope.PRData());
  }

  /**
   * This validation can be used in general cases
   * todo: can be moved to general function (later)
   * Validate value based on the type
   * and calling function based on the type
   * @param value
   * @param type
   * @param isDecimal
   * @param zeroAllowed
   * @param preventDecimal
   */
  function lineItemValidation(value, type, isDecimal, zeroAllowed, preventDecimal) {
    var message = "";

    if (type === 'number')
      message = numberValidation(isDecimal, value, zeroAllowed);

    if (type === 'numberPercentage') {
      message = percentageValidation(value);
      //verify the decimals for the discounts
      if(!message)
        message = checkDecimal(value, preventDecimal);
    }

    if (!!message)
      toastr.error(message);

    return !!message;
  }

  /**
   * Validate if split information will be removed
   *
   * @param {object} item
   * @param {number} itemIndex
   * @param {string} field
   * @param {*} newValue
   * @param {*} backupValue
   */
  function poSplitValidation(item, itemIndex, field, newValue, backupValue) {
    swal({
        title: 'Confirm update ' + field + '?',
        text: 'This will update existing split information.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true
      },
      function (isConfirm) {
        var fieldToSend = field;
        var param = {};

        if (field in fieldNameConversion) {
          fieldToSend = fieldNameConversion[field].name;
        }

        if (!!isConfirm) {
          if (field === 'tax') {
            param['tax_id'] = newValue._id;
          }
          else {
            param[fieldToSend] = newValue;
          }
          sendingUpdateItem(itemIndex, item, field, param, backupValue);
        }
        else {
          // TODO: need to have field name translation for fail update and backup
          if (field === 'price')
            field = 'unit_price';

          $scope.PRData().pr_items[itemIndex][field] = backupValue;
          toastr.info('Successfully cancelled.');
        }
      });
  }

  /**
   *
   * @param entry
   * @returns {*}
   */
  function percentageValidation(entry) {
    if (!!entry) {
      entry = entry.toString();
      var percentageCount = (entry.match(/%/g) || []).length;
      var dotCount = (entry.match(/\./g) || []).length;

      if (dotCount === 1) {
        var decimalLimit = 4;

        if (percentageCount === 1) {
          decimalLimit++;
        }

        var decimal = entry.split(".")[1].length;
        if (decimal > decimalLimit) {
          return "Invalid decimal point."
        }
      }

      if (percentageCount > 1 ||
        (percentageCount === 1 && entry.length <= 1) ||
        (dotCount > 1) ||
        (dotCount > 0 && entry.indexOf(".") === 0)
      ) {
        return lang.validation.percentage.invalid;
      }

      return false;
    }
  }

  /**
   * check the decimals depending on the currency settings
   *
   * @param entry
   * @param preventDecimal
   * @returns {*}
   */
  function checkDecimal(entry, preventDecimal) {
    entry = entry.toString();
    if (!!preventDecimal) {
      var percentageCount = (entry.match(/%/g) || []).length;
      if (percentageCount > 0) {
        return false;
      }
      else {
        var dotCount = (entry.match(/\./g) || []).length;
        if (dotCount > 0)
          return lang.validation.decimal.notAllowed;
      }

    }
    return false;
  }

  /**
   * Number validation
   * - Positive number only
   * - check if valid Number
   * - check decimal value if isDecimal value is true
   * @param isDecimal
   * @param value
   * @param zeroAllowed
   */
  function numberValidation(isDecimal, value, zeroAllowed) {
    var validFraction = fractionValidation(value);

    if (isNaN(value))
      return lang.validation.number.isNumber;

    if (zeroAllowed)
      if (!(value >= 0))
        return lang.validation.number.positive;
    else
      if (!(value > 0))
        return lang.validation.number.positive;

    if (!isDecimal && !!validFraction)
      return lang.validation.number.noFraction;

    if (String(value).includes('.'))
      if (String(value).split('.')[1].length > 4)
        return lang.validation.number.notValidDecimal;

    return false;
  }

  function getAccountCodes() {
    return prV2Function.getAccountCodes();
  }

  /**
   * Decimal value validation
   * @param value
   */
  function fractionValidation(value) {
    return Number(value) % 1 !== 0;
  }

  function checkIfPrHasSplit() {
    var hasSplit = false;
    _.forEach($scope.PRData().pr_items, function(item){
      if (!!item.split) {
        hasSplit = true;
      } else if (!!item.selecting_others) {
        if (item.selecting_others.indexOf('split') > -1) {
          hasSplit = true;
        }
      }
    });

    return hasSplit;
  }

  function showLumpSumButton() {
    if(!prV2Function.getDocumentRule('allow_lump_sum_discount')) {
      return false;
    }

    if (checkIfPrHasSplit()) {
      return false;
    }

    if (!!prV2Function.getPRData().budget && !!prV2Function.getPRData().budget.has_item_breakdown) {
      return false;
    }

    var validateLumpSum = prV2Function.validateLumpSum(null, -1);
    return validateLumpSum.status;
  }

  function integrationSolutionAllowedForLumpSum() {
    var integrationSolution = prV2Function.getIntegrationSolution();

    var allowedSolution = ['MB-JDE-JDE'];
    return allowedSolution.indexOf(integrationSolution) > -1;
  }

  function getTaxData() {
    var taxes = prV2Function.getTaxData();
    var res = [];
    _.forEach(taxes, function (tax) {
      if (tax.country_code === prV2Function.getPRData().company.company_country) {
        res.push(tax);
      }
    });

    return res;
  }

  function canEditPR() {
    return prV2Function.getPRData().status === 'edit';
  }

  $scope.$on('PRDataUpdated', function(){
    PRData();

    if (_.isEmpty(prV2Function.getPRData().pr_items)) {
      return;
    }

    loadCompanyItemInfo();
  });

  function loadCompanyItemInfo() {
    var requiresEndpoint = false;
    if(!prV2Function.getPRData().pr_items || prV2Function.getPRData().pr_items.length < 1)
      return;

    // PR stock items that have been submitted will have its own stock information
    // PR submitted will snapshop last purchase info and least purchase info in item level
    _.forEach(prV2Function.getPRData().pr_items, function (item) {
      if (_.isUndefined(item.stock_information) || _.isUndefined(item.last_purchase_info) || _.isUndefined(item.least_purchase_info)) {
        requiresEndpoint = true;
      }
    });

    if (requiresEndpoint === true) {
      var companyCode = prV2Function.getPRData().company.code;
      var codes = _.unique(_.pluck(prV2Function.getPRData().pr_items, 'item_code'));
      var itemInfo = [];
      var codesRequireBackend = [];
      _.forEach(codes, function (code) {
        codesRequireBackend.push(code);
      });

      if(codesRequireBackend.length > 0) {
        companyItemShoppingDetails.get(
          {
            'company-code': companyCode,
            'items-codes[]': codesRequireBackend
          },
          function (resource) {
            if (!!resource.content && !!resource.content.data) {
              _.forEach(resource.content.data, function (item) {
                itemInfo.push(item);
              });
              applyCompanyInfo(itemInfo);
            }
          }
        )
      } else {
        applyCompanyInfo(itemInfo);
      }
    }
  }

  /**
   * apply stock/last purchase price/least purchase price
   * @param list
   */
  function applyCompanyInfo(list) {
    _.forEach(list, function (itemInfo) {
      _.forEach($scope.PRData().pr_items, function(item) {
        if (item.item_code === itemInfo.item_master_code) {
          var stock = {
            reorder_quantity: itemInfo.reorder_quantity,
            safety_stock_level: itemInfo.safety_stock_level,
            stock_balance: itemInfo.stock_balance,
            quantity_pending_approval: itemInfo.quantity_pending_approval,
            quantity_pending_receive: itemInfo.quantity_pending_receive
          };

          if (!_.isEqual(item.stock, stock)) {
            item.stock = stock;
            item.last_purchase_info = itemInfo.last_purchase_info;
            item.least_purchase_info = itemInfo.least_purchase_info;
          }
        }
      })
    });
  }


  function checkItemExpiryDate(expiryDate) {
    if (!!expiryDate){
      if (!(typeof expiryDate === 'number')) {
        expiryDate = Number(expiryDate);
      }

      var itemExpiryDate = moment(expiryDate);
      var currentDate = moment();
      var duration = moment.duration(itemExpiryDate.diff(currentDate));

      if(duration.asDays() <= 14)
        return true;
      else
        return false;
    }
    return false
  }

  function checkLumpSumDiscountAccountCode() {
    var pr = prV2Function.getPRData();

    return (!!pr.company.requisition_settings.lump_sum_discount_account_code &&
      pr.company.requisition_settings.lump_sum_discount_account_code.is_allowed)
  }

  function onSelectLumpSumDiscountAccountCode(selectedAccountCode) {
    prV2Function.setLoading('lineItemTable', true);
    var param = {};
    param.account_code = selectedAccountCode._id;
    purchaseRequisitionsV2Services.setLumpSumAccountCode({
      id: localPRData._id
    }, param, function(resource) {
      if (!!resource && !!resource.content && !!resource.content.data) {
        prV2Function.setLoading('lineItemTable', false);
        prV2Function.setPRData(resource.content.data);
      }
    }, function(error) {
      prV2Function.setLoading('lineItemTable', false);
      prV2Function.setPRData($scope.pr);
      globalFunc.objectErrorMessage(error);
    });
  }

  function removeLumpSumDiscountAccountCode() {
    prV2Function.setLoading('lineItemTable', true);
    var param = {};
    param.account_code = null;
    purchaseRequisitionsV2Services.setLumpSumAccountCode({
      id: localPRData._id
    }, param, function(resource) {
      if (!!resource && !!resource.content && !!resource.content.data) {
        prV2Function.setLoading('lineItemTable', false);
        prV2Function.setPRData(resource.content.data);
        $scope.add_lump_sum_discount_account_code = false;
      }
    }, function(error) {
      prV2Function.setLoading('lineItemTable', false);
      prV2Function.setPRData($scope.pr);
      globalFunc.objectErrorMessage(error);
    });
  }

  function removeLumpSumDiscount() {
    prV2Function.setLoading('lineItemTable', true);
    purchaseRequisitionsV2Services.removeLumpSum({
      requisition_id: localPRData._id
    }, function(resource) {
      if (!!resource && !!resource.content && !!resource.content.data) {
        prV2Function.setLoading('lineItemTable', false);
        prV2Function.setPRData(resource.content.data);
        $scope.add_lump_sum_discount_account_code = false;
      }
    }, function(error) {
      prV2Function.setLoading('lineItemTable', false);
      prV2Function.setPRData($scope.pr);
      globalFunc.objectErrorMessage(error);
    });
  }

  function addLumpSumDiscountAccountCode() {
    $scope.add_lump_sum_discount_account_code = true;
  }

  function getSelectedItem() {
    var selectedItem = [];
    _.forEach ($scope.PRData().pr_items, function(item){
      if (item.selected === true){
        selectedItem.push(item);
      }
    });
    return selectedItem;
  }

  function checkCompanyIntegrationStatus() {
    return prV2Function.getIntegrationStatus();
  }

  function updateItemDetailList() {
    // remove opex capex elements
    $scope.itemOthersDetail = angular.copy($scope.defaultItemOthersDetail);
    _.forEach($scope.defaultItemOthersDetail, function(defaultItem) {
      if (prV2Function.getExpenseTypeMode() === 'OPEX') {
        _.forEach($scope.itemOthersDetail, function(item, index) {
          if (!!item && !!item.code && (item.code === 'budget_number' || item.code === 'cost_center' || item.code === 'project_code')) {
            $scope.itemOthersDetail.splice(index, 1);
          }
        });
      }
    });
  }

  $scope.$on('updateExpenseType', function() {
    updateItemDetailList();
  });

  function updateBudget(itemIndex, budget, budgetBackup) {
    updateItem(itemIndex, 'budget', false, budget, budgetBackup);
  }

  function prepareProjectCode(projectCode){
    return {
      'code': projectCode.code,
      'name': projectCode.name
    }
  }

  // Show the grayed-out add item button when there's no expense type
  function showGrayedOutAddButton() {
    return (isPRCreator() || allowEditingPR() || canEditPR()) && !hasCmmsIntegration() && !PRData().expense_type;
  }

  function initialize() {
    /**
     * This component (directive) initializes every time the state move within PR (eg: to Quotation and back to main PR)
     * So there should Not be any POST/PUT operations that calls the server API as they will be calling on transition
     * and not on Action by the user.
     * In case of data is needed for display (eg: status of item) all calls must be cached (in memory or local storage)
     */
    updateItemDetailList();

    $scope.dateOptions = {
      formatYear: 'yy',
      minDate: new Date(),
      startingDay: 1
    };
    $scope.datePickerTimezone = $rootScope.datePickerTimezone;
    // Remove freights and transport if it is disabled in company settings
    if (!!$scope.PRData().company && !!$scope.PRData().company.requisition_settings &&
      !!$scope.PRData().company.requisition_settings.freight_transportation &&
      !!$scope.PRData().company.requisition_settings.freight_transportation.is_disabled &&
      $scope.PRData().company.requisition_settings.freight_transportation.is_disabled === true) {
      $scope.itemOthersDetail = $scope.itemOthersDetail.filter(function(option){
        if (option.code !== 'freights' && option.code !== 'transportation') {
          return option;
        }
      });
    }


    // Remove budget number & project code option if opex PR
    if(!!$scope.pr.expense_type && $scope.pr.expense_type.category === 'OPEX') {
      $scope.itemOthersDetail = $scope.itemOthersDetail.filter(function(option){
        if (option.code !== 'budget' || option.code !== 'project_code') {
          return option;
        }
      });
    }

    $scope.add_lump_sum_discount_account_code = !!$scope.pr.lump_sum_discount_account_code;
    $scope.allowLineDiscount = checkLineDiscount();

    // Get Cerf data for each item
    _.forEach($scope.PRData().pr_items, function(item){
      if (_.isEmpty(item.costCenterList)) {
        item.costCenterList = $scope.costCenters;
      }
    });
    // Get the status of the items
    loadCompanyItemInfo();

    $scope.headerCostCenter = (prV2Function.getPRData().cost_center !== null) ?
    prV2Function.getPRData().cost_center.descr : null;
  }

  function showAdtafButton(item)
  {
    return $scope.pr.expense_type.category === 'CAPEX' &&
      !!item.cerf &&
      item.cerf.type_of_acquisition === 'Replacement';
  }

  // updates pr line item image if it's updated through non catalog form
  $scope.$on('updateDetailsDataV2', function(args, data) {
    if (!!data) {
      var item = _.find($scope.PRData().pr_items, function (item) {
        return item._id === data._id;
      });
      if(!!data.img_url) {
        var randomVal = Math.random();
        item.img_url = data.img_url + '?decache=' + randomVal;
      } else {
        item.img_url = undefined;
      }
    }
  });

  $rootScope.$on('itemMainCheckboxClickedFrom', function (event, value) {
    $scope.itemMainCheckBox.selected = value;
    itemMainCheckboxClicked(value);
  });

  $scope.$watch('pr', function (pr) {
    $scope.expenseType = pr.expense_type;
  });
  initialize();

}

purchaseRequisitionsV2ItemListCtrl.$inject = [
  '$scope', 'prV2Function', 'purchaseRequisitionsV2Services', 'globalFunc', 'pathConstants', 'toastr', 'lang', '$rootScope',
  '$http', '$uibModal', '$state', 'companyItemShoppingDetails', 'metabuyerCache'
];

angular
  .module('metabuyer')
  .directive('prV2ItemList',function() {
    return {
      restrict: 'E',
      scope: {
        editingState: '=',
        pr: '=',
        pritemlimit: '=?',
        costCenters: '='
      },
      template: '<div ng-include="myTemplateUrl"></div>',
      controller: 'purchaseRequisitionsV2ItemListCtrl'
    }
  })
  .controller('purchaseRequisitionsV2ItemListCtrl', purchaseRequisitionsV2ItemListCtrl);
