'use strict';
/**
 * @name showPositionsCtrl
 * @desc Controller to see positions for a user
 */
function showPositionsCtrl($scope, listPositions, company, $uibModalInstance) {
  $scope.closeModal = closeModal;
  $scope.listPositions = listPositions;
  $scope.company_name = company;

  function closeModal() {
    $uibModalInstance.close();
  }
}

showPositionsCtrl.$inject = ['$scope', 'listPositions', 'company', '$uibModalInstance'];

angular
  .module('metabuyer')
  .controller('showPositionsCtrl', showPositionsCtrl);
