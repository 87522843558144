'use strict';

/**
 *
 * @param $scope
 * @param $rootScope
 * @param sourcingProjectMasterResource
 * @param $uibModalInstance
 * @param toastr
 * @param globalFunc
 */
function addSourcingProjectCtrl($scope, $rootScope, $uibModalInstance, toastr, globalFunc, sourcingProjectMasterResource, $http, $filter, pathConstants) {

  $scope.submitted = false;
  $scope.closeModal = closeModal;
  $scope.submitSourcingProject = submitSourcingProject;

  $scope.sourcingProject = {
    is_active: true
  };

  function closeModal() {
    $uibModalInstance.close();
  }

  function preparePayload() {
    var data = {
      code: $scope.sourcingProject.code,
      descr: $scope.sourcingProject.descr,
      is_active: $scope.sourcingProject.is_active,
    };

    return data;
  }

  function submitSourcingProject() {
    $scope.submitted = true;
    if (!validate($scope.sourcingProject)) {
      var data = preparePayload();
      sourcingProjectMasterResource.post(
        data,
        function (success) {
          toastr.success('Sourcing Project successfully created.');
          $uibModalInstance.close(success);
          $scope.submitted = false;
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    }
  }

  function validate(sourcingProjectDetails) {
    var anyError = false;
    if (!sourcingProjectDetails.code) {
      toastr.error('Sourcing Project code is required.');
      anyError = true;
    }
    if (!sourcingProjectDetails.descr) {
      toastr.error('Sourcing Project description is required.');
      anyError = true;
    }
    return anyError;
  }
}



addSourcingProjectCtrl.$inject = ['$scope', '$rootScope', '$uibModalInstance', 'toastr', 'globalFunc', 'sourcingProjectMasterResource', '$http', '$filter', 'pathConstants'];
angular.module('metabuyer')
  .controller('addSourcingProjectCtrl', addSourcingProjectCtrl);
