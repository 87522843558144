'use strict';

function tenderSummaryCtrl(
  $scope, globalFunc, $rootScope, $uibModal, $state, HighlightId, pathConstants, $filter, tenderFunction, tenderService, toastr, $http
) {

  $scope.consumptionData = [];

  function initialize() {
    $scope.showTenderSummary = false;

    if (tenderFunction.getTenderStatus() === 'approved') {
      getTenderConsumptionData();
    }

  }

  function getTenderConsumptionData() {
    tenderService.consumption({
      id: tenderFunction.getTenderId()
    }).$promise.then(
      function(response) {
        $scope.showTenderSummary = true;
        $scope.consumptionData = response.content.data;
      },
      function(error) {
        if (!!error.data && !!error.data.content && !!error.data.content.error) {
          $scope.consumptionData = [];
          $scope.showTenderSummary = false;
        }
      }
    );
  }

  initialize();
}

tenderSummaryCtrl.$inject = [
  '$scope', 'globalFunc', '$rootScope', '$uibModal', '$state', 'HighlightId', 'pathConstants', '$filter', 'tenderFunction', 'tenderService', 'toastr', '$http'
];

angular
  .module('metabuyer')
  .controller('tenderSummaryCtrl', tenderSummaryCtrl);
