'use strict';

function changeGrnDoNumberCtrl ($scope, $state, toastr, changeGrnDoNumber, globalFunc) {

  $scope.submit = submit;
  $scope.confirmSubmit = confirmSubmit;

  function submit() {
    changeGrnDoNumber.put(
      {},
      {
        grn_number: $scope.grnNumber,
        do_number: $scope.newDoNumber
      }, function(response) {
        toastr.success(response.message);
      }, function(error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function confirmSubmit() {
    var error = false;
    if (!$scope.grnNumber) {
      toastr.error('Please fill in the GRN Number');
      error = true;
    }
    if (!$scope.newDoNumber) {
      toastr.error('Please fill in the new DO Number');
      error = true;
    }
    if (error) {
      return;
    }

    swal({
      title: 'Confirm to update\n' + $scope.grnNumber + "\nDO number to\n" + $scope.newDoNumber + ' ?',
      text: 'This action is not revertable and will be recorded.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Confirm',
      closeOnConfirm: true
    }, function (isConfirm) {
      if (isConfirm) {
        submit();
      }
    });
  }
}
changeGrnDoNumberCtrl.$inject = ['$scope', '$state', 'toastr', 'changeGrnDoNumber', 'globalFunc'];

angular
  .module('metabuyer')
  .controller('changeGrnDoNumberCtrl', changeGrnDoNumberCtrl);
