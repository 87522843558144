'use strict';

/**
 * @name ModeOfPurchaseCtrl
 */
function ModeOfPurchaseCtrl($scope, $stateParams, $uibModal, dataList, modeOfPurchaseMasterDataList, $location, $rootScope) {
  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.modeOfPurchaseMasterDataList = modeOfPurchaseMasterDataList;
  $scope.getStatus = getStatus;
  $scope.addModeOfPurchase = addModeOfPurchase;
  $scope.editModeOfPurchase = editModeOfPurchase;
  $scope.showColumn = showColumn;
  $scope.search = search;

  $scope.columns = [
    {id: 'code', label: 'CODE'},
    {id: 'prefix', label: 'PREFIX'},
    {id: 'name', label: 'MODE OF PURCHASE'},
    {id: 'po_creator', label: 'PO CREATOR', unsortable: true},
    {id: 'created_at', label: 'CREATED AT'},
    {id: 'created_by', label: 'CREATED BY'},
    {id: 'updated_at', label: 'UPDATED AT'},
    {id: 'updated_by', label: 'UPDATED BY'},
    {id: 'is_active', label: 'STATUS'}
  ];

  $scope.storageKey = 'master-data-mode-of-purchase-selected-columns';

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional) {
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function getStatus(isActive) {
    return !!isActive ? 'Active' : 'Inactive';
  }

  function editModeOfPurchase(index) {
    var modalInstance = $uibModal.open({
      templateUrl: 'app/masterDataManagement/modeOfPurchase/detail/details.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'modeOfPurchaseDetailsCtrl',
      size: 'lg',
      resolve: {
        modeOfPurchase: function ($q, modeOfPurchaseMasterDataList) {
          var deferred = $q.defer();
          modeOfPurchaseMasterDataList.get(
            {
              id: $scope.dataList[index].uuid
            },
            function (resource) {
              deferred.resolve(resource.content || {data: []});
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        }
      }
    });

    modalInstance.result.then(function (newValue) {
      if (!!newValue) {
        _.forEach($scope.dataList, function (dataList) {
          if (dataList._id === newValue._id) {
            dataList.uuid = newValue.uuid;
            dataList.code = newValue.code;
            dataList.prefix = newValue.prefix;
            dataList.name = newValue.name;
            dataList.po_creators = newValue.po_creators;
            dataList.creator_info = newValue.creator_info;
            dataList.created_at = newValue.created_at;
            dataList.updater_info = newValue.updater_info;
            dataList.updated_at = newValue.updated_at;
            dataList.is_active = newValue.is_active;
          }
        });
      }
    }, function () {
    });
  }

  function addModeOfPurchase() {
    var addModeOfPurchase = $uibModal.open({
      templateUrl: 'app/masterDataManagement/modeOfPurchase/manage/add.html',
      backdrop: 'static',
      controller: 'addModeOfPurchaseCtrl',
      keyboard: false,
      scope: $scope,
      size: 'lg'
    });

    addModeOfPurchase.result.then(function (result) {
      var modeOfPurchase = result.content.data;
      $scope.dataList.unshift({
        _id: modeOfPurchase._id,
        uuid: modeOfPurchase.uuid,
        code: modeOfPurchase.code,
        name: modeOfPurchase.name,
        prefix: modeOfPurchase.prefix,
        po_creators: modeOfPurchase.po_creators,
        created_at: modeOfPurchase.created_at,
        updated_at: modeOfPurchase.updated_at,
        creator_info: modeOfPurchase.creator_info,
        updater_info: modeOfPurchase.updater_info,
        is_active: modeOfPurchase.is_active
      });
    });
  }

  function search(query) {
    $location.search({query: query});
  }

  function init() {
    if (!!$stateParams.search) {
      $scope.searchText = $stateParams.search;
    }
    else {
      $scope.searchText = '';
    }
  }

  init();
}

ModeOfPurchaseCtrl.$inject = ['$scope', '$stateParams', '$uibModal', 'dataList', 'modeOfPurchaseMasterDataList', '$location', '$rootScope'];

angular
  .module('metabuyer')
  .controller('ModeOfPurchaseCtrl', ModeOfPurchaseCtrl);
