'use strict';

/**
 * @name companyBillingOptions directive
 *
 * @description
 * The directive is to set the additional billing options for purchase requisitions that belong to the company
 *
 * @author Ahmed Saleh (a.saleh@metabuyer.com)
 * @copyright Sunway Metacloud &copy; 2016
 */

angular
  .module('metabuyer')
  .directive('companyBillingOptions', function() {
    return {
      restrict: 'E',
      scope: {
        company: '=',
        addresses: '='
      },
      templateUrl: 'components/companyBillingOptions/companyBillingOptionsTemplate.html',
      controller: function ($scope, searchModule, globalFunc, companyBillingInformation, toastr, $state, $rootScope, UserPermissions) {

        $scope.isV2Enabled = $rootScope.isV2Enabled;
        $scope.availableCompanies = [];
        $scope.defaultBilledCompany = {
          selected: null
        };
        $scope.selectedBilledToCompanies = [];
        $scope.addToBillingCompanies = addToBillingCompanies;
        $scope.selectDefaultBillingCompany = selectDefaultBillingCompany;
        $scope.removeFromBillingCompanies = removeFromBillingCompanies;
        $scope.enableEditMode = enableEditMode;
        $scope.submit = submit;
        $scope.cancel = cancel;
        $scope.showSplitBillSetting = showSplitBillSetting;
        $scope.selectedSplitBilledToCompanies = [];
        $scope.addToSplitBillCompanies = addToSplitBillCompanies;
        $scope.removeSplitBillCompanies = removeSplitBillCompanies;
        $scope.saveShadowCompany = saveShadowCompany;
        $scope.removeShadowCompany = removeShadowCompany;
        $scope.isDeveloper = !!UserPermissions.isDeveloper($rootScope.currentUser);
        $scope.selectedBillingCompany = {};
        $scope.selectedBillingCompanies = [
          {selected: []}
        ];
        $scope.selectedShadowCompanies = [
          {selected: []}
        ];
        $scope.selectedSplitBilledToCompanies = [
          {selected: []}
        ];

        // Show split bill option status
        function showSplitBillSetting() {
          if (!!$scope.split_bill) {
            return (!!$scope.split_bill) ? 'Enabled' :  'Disabled';
          }
          else {
            return 'Disabled';
          }
        }


        // Add split bill companies
        function addToSplitBillCompanies(company) {
          if (!globalFunc.findInArray($scope.selectedSplitBilledToCompanies.selected, '_id', company._id))
            $scope.selectedSplitBilledToCompanies.selected.push(company);
        }

        // Remove split bill companies
        function removeSplitBillCompanies(company) {
          $scope.selectedSplitBilledToCompanies.selected =
            globalFunc.removeValueFromArray($scope.selectedSplitBilledToCompanies.selected, '_id', company._id);
        }

        function saveBillingInfo() {
          $scope.company.billingCompanies = [];
          $scope.company.split_po_companies = [];
          $scope.company.defaultBillingCompany = $scope.defaultBilledCompany.selected.code;
          $scope.company.config.split_po = $scope.split_bill;

          _.forEach($scope.selectedBillingCompanies.selected, function (company) {
            $scope.company.billingCompanies.push(company.code);
          });

          _.forEach($scope.selectedSplitBilledToCompanies.selected, function(company) {
            $scope.company.split_po_companies.push(company.code)
          });
        }

        function setBillingInfo() {
          $scope.selectedBillingCompanies.selected = [];
          $scope.selectedShadowCompanies.selected = [];
          $scope.defaultBilledCompany.selected = null;
          $scope.selectedSplitBilledToCompanies.selected = [];
          $scope.split_bill = $scope.company.config.split_po;
          if (!!$scope.company.billingCompanies) {
            _.forEach($scope.company.billingCompanies, function (companyCode) {
              var temp = globalFunc.findInArray($scope.availableCompanies,
                'code', companyCode);
              if (!!temp) {
                $scope.selectedBillingCompanies.selected.push(temp);
              }
            });
          }

          if (!!$scope.company.shadow_companies){
            _.forEach($scope.company.shadow_companies, function (company) {
              var temp = globalFunc.findInArray($scope.availableCompanies,
                'code', company.code);

              temp.address = globalFunc.findInArray($scope.addresses,
                '_id', company.address_id);
              if (!!temp) {
                $scope.selectedShadowCompanies.selected.push(temp);
              }
            });
          }

          if (!!$scope.company.split_po_companies) {
            _.forEach($scope.company.split_po_companies, function (companyCode) {
              var temp = globalFunc.findInArray($scope.availableCompanies,
                'code', companyCode);
              if (!!temp) {
                $scope.selectedSplitBilledToCompanies.selected.push(temp);
              }
            });
          }

          if (!!$scope.company.defaultBillingCompany) {
            $scope.defaultBilledCompany.selected =
              globalFunc.findInArray($scope.availableCompanies,
                'code', $scope.company.defaultBillingCompany);
          }
        }

        function initialize() {
          $scope.editMode = false;
          $scope.split_bill = $scope.company.config.split_po;

          /**
           * load all companies
           */
          searchModule.get(
            {
              module: 'companies',
              offset: 0,
              order: 1,
              order_by: 'descr'
            },
            function (resource) {
              if (!!resource.content) {
                var companies = resource.content.data;
                $scope.availableCompanies = resource.content.data;
                // Remove this company from the available split companies
                $scope.availableSplitCompanies = globalFunc.removeValueFromArray(companies, '_id', $scope.company._id);
                setBillingInfo();
              } else {
                globalFunc.objectErrorMessage('An error occurred');
              }
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
            }
          );
        }

        function addToBillingCompanies(com) {
          if (!globalFunc.findInArray($scope.selectedBillingCompanies.selected, '_id', com._id))
            $scope.selectedBillingCompanies.selected.push(com);

          if ($scope.selectedBillingCompanies.selected.length === 1 && $scope.defaultBilledCompany.selected == null) {
            $scope.defaultBilledCompany.selected = com;
          }
        }

        function removeFromBillingCompanies(com) {
          $scope.selectedBillingCompanies.selected =
            globalFunc.removeValueFromArray($scope.selectedBillingCompanies.selected, '_id', com._id);
          if (!!$scope.defaultBilledCompany.selected && $scope.defaultBilledCompany.selected._id === com._id) {
            $scope.defaultBilledCompany.selected = null;
          }

          if ($scope.selectedBillingCompanies.selected.length === 1 && $scope.defaultBilledCompany.selected == null) {
            $scope.defaultBilledCompany.selected = $scope.selectedBillingCompanies.selected[0];
          }
        }

        function selectDefaultBillingCompany(com) {
          $scope.defaultBilledCompany = com;
        }

        function enableEditMode() {
          $scope.editMode = true;
        }

        function prepareCompanyChangedData() {
          var companyCodes = [];
          var splitBillCompanyCodes = [];
          var shadowCompanyCodes = [];

          _.forEach($scope.selectedBillingCompanies.selected, function (company) {
            companyCodes.push(company.code);
          });

          _.forEach($scope.selectedSplitBilledToCompanies.selected, function (company) {
            splitBillCompanyCodes.push(company.code);
          });

          _.forEach($scope.selectedShadowCompanies.selected, function (company) {
            shadowCompanyCodes.push({
              code: company.code,
              address_id: !!company.address ? company.address._id : undefined
            });
          });

          /**
           * re-fill up the required data to avoid errors
           */
          var companyDataChanges = {};
          companyDataChanges.billingCompanies = companyCodes;
          companyDataChanges.split_po_companies = splitBillCompanyCodes;
          companyDataChanges.defaultBillingCompany = !!$scope.defaultBilledCompany.selected ?
            $scope.defaultBilledCompany.selected.code : null;
          companyDataChanges.split_po = $scope.split_bill;
          companyDataChanges.shadow_companies = shadowCompanyCodes;

          return companyDataChanges;
        }

        function saveShadowCompany(company) {
          if (!globalFunc.findInArray($scope.selectedShadowCompanies.selected, '_id', company._id)){
            $scope.selectedShadowCompanies.selected.push(company);
          }
        }

        function removeShadowCompany(com) {
          $scope.selectedShadowCompanies.selected =
            globalFunc.removeValueFromArray($scope.selectedShadowCompanies.selected, '_id', com._id);
        }

        function submit() {
          var newData = prepareCompanyChangedData();
          companyBillingInformation.put(
            {
              id: $scope.company._id
            }, newData,
            function (resource) {
              toastr.success((!!resource.content && !!resource.content.message && _.isString(resource.content.message))
                ? resource.content.message : 'Successfully updated');
              $scope.editMode = false;
              $scope.company.shadow_companies = resource.content.data.shadow_companies;
              saveBillingInfo();
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
            }
          );
        }

        function cancel() {
          $scope.editMode = false;
          setBillingInfo();
        }

        initialize();
      }
    }
  });
