/**
 * @name globalFunc
 *
 * @requires $state
 * @requires availability
 *
 * @description Service for global functions
 * This service is for functions that are re-used in multiple modules, to minimize redundancy
 *
 * @function objectErrorMessage
 * This function is to check if error is object or string and returns the toastr accordingly
 *
 * @function tryParseJson
 * Checks if a value is a json string, returns false if it isn't, returns the parsed object if it is
 *
 * @function requestErrorMessage
 * This function is to return request form validation error and toastr accordingly
 *
 * @function countryCode
 * This function is to populate the country code listing
 *
 * @function duplicationCheck
 * This function is to check for duplicate values for the model, field, value
 *
 * @function validateLumpSum
 * This function will return a message in case there is an error in the lump sum,
 * then the controllers will remove that from their respectively
 *
 * @function availableStatuses
 * This function will validate and return the available statuses for the Modules
 *
 * @function reformatFromObjectToArray
 * This function will return a formatted Array from an Object
 *
 * @function convertDateToTimestamp
 * convert Date into timestamp milisecond
 *
 * @function capitalizeFirstCharacter
 * gets a string capitalize the first character
 *
 * @function sortObjectsArray
 * sorts the arrays that are an Objects based
 *
 * @function translateSecurityClasses
 * Function returns a formatted name for the security classes
 *
 * @function nextAvailableAction
 * Find the next available action from each module and return the next object
 *
 * @function getStatusCode
 * function returns the code for each status
 *
 * @function starFavourites
 * function to return star favourites icon for PR PO etc
 *
 * function emailValidation
 * function to validate email
 *
 * @function getImportSummaryStatusCode
 * function to return the import status code
 *
 * @function filterUrlParams
 * function to prepare the tabData params
 *
 * @function removeDuplicateObjects
 * removed duplicated objects in arrays
 *
 * @function findObjectInArrayWithKeyValue
 * function returns an object that is inside an array that has a key
 *
 * @function getArrayOfKeyValues
 * function to return key values in an array, from an array of objects
 *
 * @function isMobileBrowser
 * function to return if current browser is on a mobile platform
 *
 * @function emailFormatValidation
 * function to return true or false if email has the correct format
 *
 * @function checkIfUserHasPARole
 * function to check in a user's roles for a PR role
 *
 * @function createStringBasedOnObjectKeyInArray
 * function to return a string based on selected object key in the array
 * join by comma separated
 *
 * @function toFixedDecimalPlaces
 * function to return a string for number to fixed decimal places (does not do any rounding)
 *
 * @function detectIEBrowser
 * function to return true or false for IE browers
 *
 * @function checkUserPermission
 * function to check user permission for import and export in each module
 *
 * @function
 * function to show taostr to request the users to login
 *
 * @function getNestedObjectKeys
 * function to get all nested keys for a json object getNestedObjectKeys
 *
 * @author Deniel Ariesta <deniel.ariesta@gmail.com>
 * @author Ahmed Saleh <a.s.alsalali@gmail.com>
 * @author Justin Cheong <justin.cty90@gmail.com>
 * @author Hafiz Suhaimi <pisyek@gmail.com>
 * @copyright 2017 Metacloud Sdn. Bhd.
 */

'use strict';

function globalFunc(toastr, availability, $q, waitingOnUserList, $filter, $rootScope, prStarring, starringPO, tenderStarring) {
  var data = {};

  // TODO: to make this config dynamic
  var currencyConfig = [
    {
      code: 'MYR',
      decimal_places: 2,
      allow_decimal: true
    },
    {
      code: 'IDR',
      decimal_places: 0,
      allow_decimal: false
    }
  ];

  var monthInText = [
    {k: 1, number: '01', name: 'January'}, {k: 2, number: '02', name: 'February'}, {k: 3, number: '03', name: 'March'},
    {k: 4, number: '04', name: 'April'}, {k: 5, number: '05', name: 'May'}, {k: 6, number: '06', name: 'June'},
    {k: 7, number: '07', name: 'July'}, {k: 8, number: '08', name: 'August'}, {k: 9, number: '09', name: 'September'},
    {k: 10, number: '10', name: 'October'}, {k: 11, number: '11', name: 'November'}, {k: 12, number: '12', name: 'December'}
  ];

  /**
   * other charges list
   * @type {*[]}
   */
  var allowedOtherCharges = [
    {
      code: 'insurance'
    },
    {
      code: 'transportation'
    },
    {
      code: 'freight'
    },
    {
      code: 'miscellaneous'
    },
    {
      code: 'bahan_bakar_tax'
    },
    {
      code: 'withholding_tax'
    }
  ];

  /**
   * function to return all keys and nested keys in a json object, it ignores all arrays
   * @param obj
   * @param prefix
   * @returns {Array}
   */
  var listKeysRecursively =function(obj, prefix) {
    return Object.keys(obj).reduce(function (res, el) {
      if (!prefix) {
        prefix = '';
      }

      if (Array.isArray(obj[el])) {
          return res;
      }
      else if (typeof obj[el] === 'object' && obj[el] !== null) {
        var flatArray = [].concat.apply([], listKeysRecursively(obj[el], prefix + el + '.'));
        return res.concat(flatArray);

      } else {
        return res.concat(prefix + el);
      }
    }, []);
  };

  return {
    objectErrorMessage: function (err) {
      // Check the error is Object or string
      // If String then load the message
      // else Loop the message object
      // added new condition to handle different format of error
      if (typeof err === 'string') {
        return toastr.error(err);
      } else if (!!err.data && !!err.data.message && (typeof err.data.message === 'string')) {
        return toastr.error(err.data.message);
      } else if (!!err && !!err.message && (typeof err.message === 'string')) {
        return toastr.error(err.message);
      }
      else if (!!err && !!err.content && !!err.content.error && (typeof err.content.error === 'string')) {
        return toastr.error(err.content.error);
      } else if (!!err.data && (!!err.data.content && (!!err.data.content.message || err.data.content.error) &&
          ((typeof err.data.content.message) === 'string' || (typeof err.data.content.error) === 'string'))) {

        // Try parsing the error to see if it's a JSON string
        // e.g. UnprocessableEntityHttpException errors (backend controller request class messages)
        var jsonParseResult = this.tryParseJson(err.data.content.error);
        if (jsonParseResult !== false && (typeof jsonParseResult) === 'object') {
          angular.forEach(jsonParseResult, function (val, key) {
            if ((typeof val) === 'string') {
              return toastr.error(val);
            } else if ((typeof val) === 'object') {
              angular.forEach(val, function (val, key) {
                return toastr.error(val);
              })
            }
          });
        } else {
          return toastr.error(err.data.content.message || err.data.content.error);
        }
      } else if (!!err.data && (!!err.data.error && (!!err.data.error.message || err.data.error.error) && ((typeof err.data.error.message) === 'string' || (typeof err.data.error.error) === 'string'))) {
        return toastr.error(err.data.error.message || err.data.error.error);
      }
      else {
        var errorObject = (!!err.content && !!err.content.error) ? err.content.error :
          (!!err.data && !!err.data.message) ? err.data.message :
            (!!err.data && !!err.data.content && !!err.data.content.error) ? err.data.content.error :
              (!!err.data && !!err.data.content && !!err.data.content.message) ? err.data.content.message : [];
        // loop the error object
        _.forEach(errorObject, function (errorData, key) {
          // Get the object key
          if (typeof errorData !== 'string') {
            angular.forEach(errorData, function (val, objKey) {
              // show the array value of the Error from the json
              if (!!objKey) {
                return toastr.error(objKey + ' line ' + key + ': ' + val);
              }
              else {
                return toastr.error(key + ': ' + val);
              }
            });
          } else {
            return toastr.error(errorData);
          }
        });
      }
    },

    // Taken from https://stackoverflow.com/a/20392392
    tryParseJson: function (str) {
      try {
        var o = JSON.parse(str);
        if (o && typeof o === 'object') {
          return o;
        }
      }
      catch (e) { }

      return false;
    },

    // list State code
    malaysiaState: function () {
      var malaysiaState = [];
      var malaysiaStateObject = {
        'MY-01': 'Johor',
        'MY-02': 'Kedah',
        'MY-03': 'Kelantan',
        'MY-04': 'Melaka',
        'MY-05': 'Negeri Sembilan',
        'MY-06': 'Pahang',
        'MY-07': 'Perak',
        'MY-08': 'Perlis',
        'MY-09': 'Penang',
        'MY-10': 'Selangor',
        'MY-11': 'Terengganu',
        'MY-12': 'Sabah',
        'MY-13': 'Sarawak',
        'MY-14': 'WP Kuala Lumpur',
        'MY-15': 'WP Labuan',
        'MY-16': 'WP Putrajaya'
      };

      for (var key in malaysiaStateObject) {
        if (malaysiaStateObject.hasOwnProperty(key)) {
          var temp = {
            code: key,
            descr: malaysiaStateObject[key]
          };
          malaysiaState.push(temp);
        }
      }

      return malaysiaState;

    },

    // list Country Code
    countryCode: function () {
      var countryCode = [];
      var countryCodeObject = {
        'AF': 'Afghanistan',
        'AX': 'Åland Islands',
        'AL': 'Albania',
        'DZ': 'Algeria',
        'AS': 'American Samoa',
        'AD': 'Andorra',
        'AO': 'Angola',
        'AI': 'Anguilla',
        'AQ': 'Antarctica',
        'AG': 'Antigua and Barbuda',
        'AR': 'Argentina',
        'AM': 'Armenia',
        'AW': 'Aruba',
        'AU': 'Australia',
        'AT': 'Austria',
        'AZ': 'Azerbaijan',
        'BS': 'Bahamas',
        'BH': 'Bahrain',
        'BD': 'Bangladesh',
        'BB': 'Barbados',
        'BY': 'Belarus',
        'BE': 'Belgium',
        'BZ': 'Belize',
        'BJ': 'Benin',
        'BM': 'Bermuda',
        'BT': 'Bhutan',
        'BO': 'Bolivia',
        'BA': 'Bosnia and Herzegovina',
        'BW': 'Botswana',
        'BV': 'Bouvet Island',
        'BR': 'Brazil',
        'IO': 'British Indian Ocean Territory',
        'BN': 'Brunei Darussalam',
        'BG': 'Bulgaria',
        'BF': 'Burkina Faso',
        'BI': 'Burundi',
        'KH': 'Cambodia',
        'CM': 'Cameroon',
        'CA': 'Canada',
        'CV': 'Cape Verde',
        'KY': 'Cayman Islands',
        'CF': 'Central African Republic',
        'TD': 'Chad',
        'CL': 'Chile',
        'CN': 'China',
        'CX': 'Christmas Island',
        'CC': 'Cocos (Keeling) Islands',
        'CO': 'Colombia',
        'KM': 'Comoros',
        'CG': 'Congo',
        'CD': 'Congo, The Democratic Republic of The',
        'CK': 'Cook Islands',
        'CR': 'Costa Rica',
        'CI': 'Cote D\'ivoire',
        'HR': 'Croatia',
        'CU': 'Cuba',
        'CY': 'Cyprus',
        'CZ': 'Czech Republic',
        'DK': 'Denmark',
        'DJ': 'Djibouti',
        'DM': 'Dominica',
        'DO': 'Dominican Republic',
        'EC': 'Ecuador',
        'EG': 'Egypt',
        'SV': 'El Salvador',
        'GQ': 'Equatorial Guinea',
        'ER': 'Eritrea',
        'EE': 'Estonia',
        'ET': 'Ethiopia',
        'FK': 'Falkland Islands (Malvinas)',
        'FO': 'Faroe Islands',
        'FJ': 'Fiji',
        'FI': 'Finland',
        'FR': 'France',
        'GF': 'French Guiana',
        'PF': 'French Polynesia',
        'TF': 'French Southern Territories',
        'GA': 'Gabon',
        'GM': 'Gambia',
        'GE': 'Georgia',
        'DE': 'Germany',
        'GH': 'Ghana',
        'GI': 'Gibraltar',
        'GR': 'Greece',
        'GL': 'Greenland',
        'GD': 'Grenada',
        'GP': 'Guadeloupe',
        'GU': 'Guam',
        'GT': 'Guatemala',
        'GG': 'Guernsey',
        'GN': 'Guinea',
        'GW': 'Guinea-bissau',
        'GY': 'Guyana',
        'HT': 'Haiti',
        'HM': 'Heard Island and Mcdonald Islands',
        'VA': 'Holy See (Vatican City State)',
        'HN': 'Honduras',
        'HK': 'Hong Kong',
        'HU': 'Hungary',
        'IS': 'Iceland',
        'IN': 'India',
        'ID': 'Indonesia',
        'IR': 'Iran, Islamic Republic of',
        'IQ': 'Iraq',
        'IE': 'Ireland',
        'IM': 'Isle of Man',
        'IL': 'Israel',
        'IT': 'Italy',
        'JM': 'Jamaica',
        'JP': 'Japan',
        'JE': 'Jersey',
        'JO': 'Jordan',
        'KZ': 'Kazakhstan',
        'KE': 'Kenya',
        'KI': 'Kiribati',
        'KP': 'Korea, Democratic People\'s Republic of',
        'KR': 'Korea, Republic of',
        'KW': 'Kuwait',
        'KG': 'Kyrgyzstan',
        'LA': 'Lao People\'s Democratic Republic',
        'LV': 'Latvia',
        'LB': 'Lebanon',
        'LS': 'Lesotho',
        'LR': 'Liberia',
        'LY': 'Libyan Arab Jamahiriya',
        'LI': 'Liechtenstein',
        'LT': 'Lithuania',
        'LU': 'Luxembourg',
        'MO': 'Macao',
        'MK': 'Macedonia, The Former Yugoslav Republic of',
        'MG': 'Madagascar',
        'MW': 'Malawi',
        'MY': 'Malaysia',
        'MV': 'Maldives',
        'ML': 'Mali',
        'MT': 'Malta',
        'MH': 'Marshall Islands',
        'MQ': 'Martinique',
        'MR': 'Mauritania',
        'MU': 'Mauritius',
        'YT': 'Mayotte',
        'MX': 'Mexico',
        'FM': 'Micronesia, Federated States of',
        'MD': 'Moldova, Republic of',
        'MC': 'Monaco',
        'MN': 'Mongolia',
        'ME': 'Montenegro',
        'MS': 'Montserrat',
        'MA': 'Morocco',
        'MZ': 'Mozambique',
        'MM': 'Myanmar',
        'NA': 'Namibia',
        'NR': 'Nauru',
        'NP': 'Nepal',
        'NL': 'Netherlands',
        'AN': 'Netherlands Antilles',
        'NC': 'New Caledonia',
        'NZ': 'New Zealand',
        'NI': 'Nicaragua',
        'NE': 'Niger',
        'NG': 'Nigeria',
        'NU': 'Niue',
        'NF': 'Norfolk Island',
        'MP': 'Northern Mariana Islands',
        'NO': 'Norway',
        'OM': 'Oman',
        'PK': 'Pakistan',
        'PW': 'Palau',
        'PS': 'Palestinian Territory, Occupied',
        'PA': 'Panama',
        'PG': 'Papua New Guinea',
        'PY': 'Paraguay',
        'PE': 'Peru',
        'PH': 'Philippines',
        'PN': 'Pitcairn',
        'PL': 'Poland',
        'PT': 'Portugal',
        'PR': 'Puerto Rico',
        'QA': 'Qatar',
        'RE': 'Reunion',
        'RO': 'Romania',
        'RU': 'Russian Federation',
        'RW': 'Rwanda',
        'SH': 'Saint Helena',
        'KN': 'Saint Kitts and Nevis',
        'LC': 'Saint Lucia',
        'PM': 'Saint Pierre and Miquelon',
        'VC': 'Saint Vincent and The Grenadines',
        'WS': 'Samoa',
        'SM': 'San Marino',
        'ST': 'Sao Tome and Principe',
        'SA': 'Saudi Arabia',
        'SN': 'Senegal',
        'RS': 'Serbia',
        'SC': 'Seychelles',
        'SL': 'Sierra Leone',
        'SG': 'Singapore',
        'SK': 'Slovakia',
        'SI': 'Slovenia',
        'SB': 'Solomon Islands',
        'SO': 'Somalia',
        'ZA': 'South Africa',
        'GS': 'South Georgia and The South Sandwich Islands',
        'ES': 'Spain',
        'LK': 'Sri Lanka',
        'SD': 'Sudan',
        'SR': 'Suriname',
        'SJ': 'Svalbard and Jan Mayen',
        'SZ': 'Swaziland',
        'SE': 'Sweden',
        'CH': 'Switzerland',
        'SY': 'Syrian Arab Republic',
        'TW': 'Taiwan, Province of China',
        'TJ': 'Tajikistan',
        'TZ': 'Tanzania, United Republic of',
        'TH': 'Thailand',
        'TL': 'Timor-leste',
        'TG': 'Togo',
        'TK': 'Tokelau',
        'TO': 'Tonga',
        'TT': 'Trinidad and Tobago',
        'TN': 'Tunisia',
        'TR': 'Turkey',
        'TM': 'Turkmenistan',
        'TC': 'Turks and Caicos Islands',
        'TV': 'Tuvalu',
        'UG': 'Uganda',
        'UA': 'Ukraine',
        'AE': 'United Arab Emirates',
        'GB': 'United Kingdom',
        'US': 'United States',
        'UM': 'United States Minor Outlying Islands',
        'UY': 'Uruguay',
        'UZ': 'Uzbekistan',
        'VU': 'Vanuatu',
        'VE': 'Venezuela',
        'VN': 'Vietnam',
        'VG': 'Virgin Islands, British',
        'VI': 'Virgin Islands, U.S.',
        'WF': 'Wallis and Futuna',
        'EH': 'Western Sahara',
        'YE': 'Yemen',
        'ZM': 'Zambia',
        'ZW': 'Zimbabwe'
      };

      for (var key in countryCodeObject) {
        if (countryCodeObject.hasOwnProperty(key)) {
          var temp = {
            code: key,
            descr: countryCodeObject[key]
          };
          countryCode.push(temp);
        }
      }

      return countryCode;
    },

    /**
     *  Function to validate the Statues for the Manage screens
     *  In case of the the status was not valid the first one will be selected from the available ones as standard
     *  All statuses are returned base on the information provided by the backend
     *
     * @param module
     * @param status
     * @returns {string}
     */
    availableStatuses: function (module, status) {
      var statusesList;
      var usersStatuses = [
        {
          name: 'pending',
          value: 0
        },
        {
          name: 'active',
          value: 1
        },
        {
          name: 'inactive',
          value: 2
        },
        {
          name: 'rejected',
          value: 3
        },
        {
          name: 'deleted',
          value: 4
        }
      ];

      var poStatuses = [
        {
          name: 'all',
          value: 'ALL'
        },
        {
          name: 'submitted',
          value: 'SUBMITTED'
        },
        {
          name: 'delivery_date_unconfirmed',
          value: 'DELIVERY_DATE_UNCONFIRMED'
        },
        {
          name: 'partially_delivered',
          value: 'PARTIALLY_DELIVERED'
        },
        {
          name: 'awaiting_delivery',
          value: 'AWAITING_DELIVERY'
        },
        {
          name: 'closed',
          value: 'CLOSED'
        },
        {
          name: 'delivered',
          value: 'DELIVERED'
        },
        {
          name: 'cancelled',
          value: 'CANCELLED'
        },
        {
          name: 'declined',
          value: 'DECLINED'
        },
        {
          name: 'starred',
          value: 'STARRED'
        },
        {
          name: 'reversed',
          value: 'REVERSED'
        },
        {
          name: 'expired',
          value: 'EXPIRED'
        },
        {
          name: 'pending',
          value: 'PENDING'
        },
        {
          name: 'approved',
          value: 'APPROVED'
        },
        {
          name: 'rejected',
          value: 'REJECTED'
        },
        {
          name: 'withdrawn',
          value: 'WITHDRAWN'
        }
      ];
      var prStatuses = [
        {
          name: 'draft',
          value: 'DRAFT'
        },
        {
          name: 'pending',
          value: 'PENDING'
        },
        {
          name: 'approved',
          value: 'APPROVED'
        },
        {
          name: 'deleted',
          value: 'DELETED'
        }, {
          name: 'withdrawn',
          value: 'WITHDRAWN'
        },
        {
          name: 'starred',
          value: 'STARRED'
        },
        {
          name: 'rejected',
          value: 'REJECTED'
        }
      ];

      var catalogImportStatuses = [
        {
          name: 'all',
          value: null
        },
        {
          name: 'compose',
          value: 0
        },
        {
          name: 'pending',
          value: 1
        },
        {
          name: 'approved',
          value: 2
        },
        {
          name: 'deleted',
          value: 3
        },
        {
          name: 'expired',
          value: 4
        },
        {
          name: 'rejected',
          value: 5
        },
        {
          name: 'active',
          value: 6
        },
        {
          name: 'deactivated',
          value: 7
        },
        {
          name: 'error',
          value: 8
        },
        {
          name: 'NEW_ITEM',
          value: 10
        },
        {
          name: 'UPDATED_ITEM',
          value: 11
        },
        {
          name: 'DELETED_ITEM',
          value: 12
        },
        {
          name: 'TOTAL_ITEM',
          value: 13
        },
        {
          name: 'NO_UPDATE',
          value: 14
        }
      ];

      var catalogV2ImportStatuses = [
        {
          name: 'all',
          value: null
        },
        {
          name: 'compose',
          value: 'compose'
        },
        {
          name: 'pending',
          value: 'pending'
        },
        {
          name: 'approved',
          value: 'approved'
        },
        {
          name: 'deleted',
          value: 'deleted'
        },
        {
          name: 'expired',
          value: 'expired'
        },
        {
          name: 'rejected',
          value: 'rejected'
        },
        {
          name: 'active',
          value: 'active'
        },
        {
          name: 'deactivated',
          value: 'deactivated'
        },
        {
          name: 'error',
          value: 'error'
        },
        {
          name: 'cancelled',
          value: 'cancelled'
        },
        {
          name: 'NEW_ITEM',
          value: 'NEW_ITEM'
        },
        {
          name: 'UPDATED_ITEM',
          value: 'UPDATED_ITEM'
        },
        {
          name: 'DELETED_ITEM',
          value: 'DELETED_ITEM'
        },
        {
          name: 'TOTAL_ITEM',
          value: 'TOTAL_ITEM'
        },
        {
          name: 'NO_UPDATE',
          value: 'NO_UPDATE'
        }
      ];

      //TODO: Pass the correct value for All when backend provides
      var supplierStatuses = [
        {
          name: 'all',
          value: 'ALL'
        },
        {
          name: 'active',
          value: 1
        },
        {
          name: 'draft',
          value: 4
        },
        {
          name: 'pending',
          value: 0
        },
        {
          name: 'black_listed',
          value: 2
        },
        {
          name: 'rejected',
          value: 3
        },
        {
          name: 'withdrawn',
          value: 5
        },
        {
          name: 'inactive',
          value: 6
        },
        {
          name: 'archived',
          value: 'ARCHIVED'
        }];

      var delegationStatuses = [
        {
          name: 'planned',
          value: 0
        },
        {
          name: 'active',
          value: 1
        },
        {
          name: 'cancelled',
          value: 2
        },
        {
          name: 'finished',
          value: 3
        },
        {
          name: 'pending',
          value: 4
        },
        {
          name:'rejected',
          value:5
        }];

      if(module === 'delegations') {
        statusesList = delegationStatuses;
      }

      if (module === 'users') {
        statusesList = usersStatuses;
      }
      if (module === 'po') {
        statusesList = poStatuses;
      }
      if (module === 'pr') {
        statusesList = prStatuses;
      }
      if (module === 'catalogs') {
        statusesList = catalogImportStatuses;
      }
      if (module === 'catalogsV2') {
        statusesList = catalogV2ImportStatuses;
      }
      if (module === 'suppliers') {
        statusesList = supplierStatuses;
      }

      if (!!status) {
        for (var i in statusesList) {
          if (status.toLowerCase() === statusesList[i].name) {
            return statusesList[i].value;
          }
        }
      }

      return statusesList[0].value;

    },

    /**
     * @function starFavourites
     * function for users to favourites PR, PO, etc
     **/
    starFavourites: function (isStarred, contextId, context) {

      //TODO AMEER REFACTOR ALL THIS | 2 APRIL

      if (context === 'PR') {
        var star = prStarring;
      }
      else if (context === 'PO') {
        var star = starringPO;
      } else if (context === 'Tender') {
        var star = tenderStarring;
      }
      
      if (!isStarred) {
        star.post(
          {
            id: contextId,
            user: $rootScope.currentUser._id
          }
        ).$promise.then(function () {
          $rootScope.currentUser.favourites[context].push(contextId);
        }, function (error) {
          globalFunc.objectErrorMessage(error);
        });
      } else {
        star.delete(
          {
            id: contextId,
            user: $rootScope.currentUser._id
          }
        ).$promise.then(function () {
          _.remove($rootScope.currentUser.favourites[context], function (starred) {
            return starred === contextId;
          });
        }, function (error) {
          globalFunc.objectErrorMessage(error);
        });
      }
    },

    /**
     * @function duplicationCheck
     * Uses availability service to check if value provided already exists for the field inside the model
     *
     * @params model
     * For the module/class to be validated
     *
     * @params field
     * For the field being validated for duplicated value
     *
     * @params value
     * For the value to be checked for duplicate
     *
     * @params checker
     * To determine what field for the html side for validations/error messages/red highlights
     *
     * @params callback
     * For callback function to pass value to controller to be used in html
     **/
    duplicationCheck: function (model, field, value, checker, callback) {
      if (!!value) {
        var params = {
          model: model,
          field: field,
          value: value
        };
        if (model.toLowerCase() === 'supplier' && field === 'basic_info.descr')
          params['ignore_dots'] = 1;

        availability.get(
          params,
          function (resource) {
            if (!!resource) {
              switch (checker) {
                case 'code':
                  callback('codeCheckFalse');
                  break;
                case 'roc':
                  callback('rocCheckFalse');
                  break;
                case 'gst':
                  callback('gstCheckFalse');
                  break;
                case 'email':
                  callback('emailCheckFalse');
                  break;
                case 'emp':
                  callback('empCheckFalse');
                  break;
                case 'supplierDescr':
                  callback('supplierDescrCheckFalse');
                  break;
              }
            }
          },
          function (error) {
            if (!!error) {
              switch (checker) {
                case 'code':
                  callback('codeCheckTrue');
                  break;
                case 'roc':
                  callback('rocCheckTrue');
                  break;
                case 'gst':
                  callback('gstCheckTrue');
                  break;
                case 'email':
                  callback('emailCheckTrue');
                  break;
                case 'emp':
                  callback('empCheckTrue');
                  break;
                case 'supplierDescr':
                  callback('supplierDescrCheckTrue');
                  break;
              }
            }
          }
        );
      }
    },
    /**
     * @function generateRandomPassword
     * Generates a random password string
     **/
    generateRandomPassword: function () {
      var numbers = "0123456789";
      var smallLetters = "abcdefghijklmnopqrstuvwxyz";
      var capitalLetters = smallLetters.toUpperCase();

      var length = 8;
      var generatedPassword = [];

      var series = [numbers, smallLetters, capitalLetters];

      function getRandomChar(string) {
        var position = Math.floor(Math.random() * string.length);
        return string.charAt(position);
      }

      generatedPassword.push(getRandomChar(smallLetters));
      generatedPassword.push(getRandomChar(capitalLetters));
      generatedPassword.push(getRandomChar(numbers));

      for (var i = 3; i < length; i++) {
        var position = Math.floor(Math.random() * series.length);
        generatedPassword.push(getRandomChar(series[position]));
      }
      return (generatedPassword).join('');

    },
    /**
     * @function generateRandomPassword
     * Generates a random password string
     **/
    generateRandomPasswordV2: function (passwordSettings) {
      var numbers = "0123456789";
      var smallLetters = "abcdefghijklmnopqrstuvwxyz";
      var capitalLetters = smallLetters.toUpperCase();
      var symbols = "!@#$%^&*()";

      var length = passwordSettings.len;
      var generatedPassword = [];

      var series = [numbers, smallLetters, capitalLetters];

      function getRandomChar(string) {
        var position = Math.floor(Math.random() * string.length);
        return string.charAt(position);
      }

      generatedPassword.push(getRandomChar(smallLetters));
      generatedPassword.push(getRandomChar(capitalLetters));
      generatedPassword.push(getRandomChar(numbers));
      generatedPassword.push(getRandomChar(symbols));

      for (var i = 3; i < length; i++) {
        var position = Math.floor(Math.random() * series.length);
        generatedPassword.push(getRandomChar(series[position]));
      }
      return (generatedPassword).join('');

    },
    securityConditions: function () {
      var conditions = [];
      var companiesCondition = {
        AccountCode: '(company_code==@__COMPANY_CODE__)',
        Address: null,
        Budget: '(company._id==@__COMPANY__)',
        BusinessUnit: null,
        Commodity: null,
        Contact: null,
        Currencies: null,
        ExchangeRate: '(company_id==@__COMPANY__)',
        ExpenseTypes: null,
        ImportItemDetail: null,
        ImportItemSummary: null,
        Item: null,
        ItemDetail: null,
        PaymentMethod: null,
        PaymentTerm: null,
        PO: '(billing.company._id==@__COMPANY__)',
        PR: '(company._id==@__COMPANY__)',
        Supplier: null,
        Tax: null,
        UNSPSC: null,
        UOM: null
      };
      for (var key in companiesCondition) {
        if (companiesCondition.hasOwnProperty(key)) {
          var temp = {
            class: key,
            value: companiesCondition[key]
          };
          conditions.push(temp);
        }
      }
      return conditions;
    },
    /**
     * To find key value in Array
     * Filter array by the key and the value (keyword)
     *
     * @param array
     * the array you want to filter
     *
     * @param key
     * array key
     *
     * @param value
     *
     * var arr = [
     *   key: value,
     *   key2: value2
     * ]
     *
     * @param checkLowerCase
     * Boolean flag to determine to check lower case or not, only applies to strings
     */
    findInArray: function (array, key, value, checkLowerCase) {
      return _.find(array, function (arr) {
        return (checkLowerCase && typeof arr[key] === 'string' && typeof value === 'string') ?
          arr[key].toLowerCase() === value.toLowerCase() : arr[key] === value;
      });
    },

    /**
     * Find the given role in user's role assignments
     *
     * @param roleAssignmentArray
     * @param role
     * @param weight
     * @param organizationId
     * @returns {boolean}
     */
    findRoleInRoleAssignments: function (roleAssignmentArray, role, weight, organizationId) {
      if (!roleAssignmentArray || (!!roleAssignmentArray && roleAssignmentArray.length === 0)) {
        return false;
      }

      for (var index in roleAssignmentArray) {
        if (!!organizationId && organizationId !== roleAssignmentArray[index].organization_id) {
          continue;
        }
        for (var i in roleAssignmentArray[index].roles) {
          if (roleAssignmentArray[index].roles[i].name.toLowerCase() !== role.toLowerCase()) {
            continue;
          }

          if (!!weight) {
            if (roleAssignmentArray[index].weight === weight) {
              return true;
            } else {
              return false;
            }
          }

          return true;
        }
      }

      return false;
    }
    ,
    /**
     * To remove item in Array
     *
     * the Params is the same with findArray
     *
     */
    removeValueFromArray: function (array, key, value) {
      return _.reject(array, function (arr) {
        return arr[key] === value;
      });
    },
    /**
     * Modify array value by key
     * @param array
     * @param key
     * @param keyword
     * @param newKey
     * @param newValue
     * @returns {*}
     */
    modifyArrayValue: function (array, key, keyword, newKey, newValue) {
      _.forEach(array, function (arr) {
        if (arr[key] === keyword)
          arr[newKey] = newValue
      });

      return array;
    },
    /**
     * format an object key to an Array of Key and Value
     *
     * @param toBeFormattedObject
     * the desired object
     *
     * @returns {Array}
     */
    reformatFromObjectToArray: function (toBeFormattedObject) {
      var tempArray = [];
      var tempIndex;
      for (var i in toBeFormattedObject) {
        tempIndex = {};
        tempIndex.key = i;
        tempIndex.value = toBeFormattedObject[i];
        tempArray.push(tempIndex);
      }
      return tempArray;
    },
    /**
     * Opposite function of reformatFromObjectToArray
     * based on the key and value in parameter
     * @param array
     * @param keyName
     * @param valuesField
     * @param existingObject
     *
     * @returns {{}}
     */
    reformatArrayToObject: function (array, keyName, valuesField, existingObject) {
      var tempObject = existingObject || {};
      _.forEach(array, function (arr) {
        tempObject[arr[keyName]] = arr[valuesField];
      });
      return tempObject;
    },
    convertDateToTimestamp: function (date) {
      var milliTime = new Date(date);
      /**
       * The getTimezoneOffset() method returns the time difference between UTC time and local time, in minutes.
       * getTimezoneOffset is for offsetting the time to UTC/GMT + 0
       */
      return (milliTime.getTime());
    },
    /**
     * convert array of object into array of string
     * @param array
     * @param keyToConvert
     */
    arrayOfObjectToString: function (array, keyToConvert) {
      var newArray = [];
      _.forEach(array, function (val) {
        newArray.push(val[keyToConvert]);
      });

      return newArray;
    },
    /**
     * Splits the input string by its delimiter and capitalize the first character of every split string and return
     * a recombined string
     *
     * @param input
     * @param delimiter
     * @returns {string}
     */
    capitalizeFirstCharacter: function (input, delimiter) {
      if (!delimiter) {
        delimiter = ' ';
      }

      if (!!input && typeof input === 'string') {
        input = input.toLowerCase().split(delimiter);
        for (var i in input) {
          input[i] = input[i].charAt(0).toUpperCase() + input[i].substring(1);
        }
        return input.join(' ');
      }

    },
    /**
     * sorting an array of objects based on the key selected
     * @param array
     * @param key
     * @returns {*}
     */
    sortObjectsArray: function (array, key) {
      function compare(first, second) {

        if (!!first[key] && !!second[key]) {

          if (typeof first[key] === 'number' || typeof second[key] === 'number') {
            if (first[key] < second[key]) {
              return -1;
            }
            if (first[key] > second[key]) {
              return 1;
            }
          }
          else if (typeof first[key] === 'string' && typeof second[key] === 'string') {
            if (first[key].toLowerCase() < second[key].toLowerCase()) {
              return -1;
            }
            if (first[key].toLowerCase() > second[key].toLowerCase()) {
              return 1;
            }
          }
          else {
            return 0;
          }

        }
        return 0;
      }

      array.sort(compare);

      return array;
    },
    /**
     * function to fix the inconsistency in the naming of the Security Classes
     * @param name
     * @returns {*}
     */
    translateSecurityClasses: function (name) {
      if (name === 'PR') {
        return 'Purchase Requisition';
      }
      if (name === 'PO') {
        return 'Purchase Order';
      }
      if (name.toLowerCase() === 'exchangerate') {
        return 'Exchange Rate';
      }
      if (name.toLowerCase() === 'importitemsummary') {
        return 'Import Catalog (Header)';
      }
      if (name.toLowerCase() === 'importitemdetail') {
        return 'Import Catalog (Details)';
      }
      if (name.toLowerCase() === 'itemdetail') {
        return 'Item Detail';
      }
      if (name.toLowerCase() === 'paymentterm') {
        return 'Payment Term';
      }
      if (name.toLowerCase() === 'paymentmethod') {
        return 'Payment Method';
      }
      if (name.toLowerCase() === 'address') {
        return 'Address Book';
      }
      if (name.toLowerCase() === 'accountcode') {
        return 'Account Code';
      }
      if (name.toLowerCase() === 'budget') {
        return 'Budget';
      }
      if (name.toLowerCase() === 'expensetypes') {
        return 'Expense Types';
      }
      if (name.toLowerCase() === 'businessunit') {
        return 'Business Unit';
      }
      if (name.toLowerCase() === 'reportlog') {
        return 'Reports';
      }
      if (name.toLowerCase() === 'grn') {
        return 'Goods Received Note';
      }
      if (name.toLowerCase() === 'suppliertag') {
        return 'Supplier Tag';
      }

      return name;
    },
    getWaitingOnUser: function (approval_flow_data, waiting_on_user_scope) {
      waiting_on_user_scope.splice(0, waiting_on_user_scope.length);
      for (var i in approval_flow_data.waiting_on) {
        waiting_on_user_scope.push(approval_flow_data.waiting_on[i]);
      }
    },
    /**
     * Find the next available action from each module and return the next object
     * as well as return the count of the action available
     * in case the current action was the last one, the first action is returned
     * this function uses the waiting-on endpoint
     *
     * @param category
     * @param currentId
     * @returns {*}
     */
    nextAvailableAction: function (category, currentId) {

      var deferred = $q.defer();

      waitingOnUserList.get(
        {
          category: category,
          offset: 0
        },
        function (resource) {
          var results = {};
          if (!!resource.content.data && !!resource.content.meta && !!resource.content.meta.cursor) {
            /**
             * store the count
             */
            results.count = resource.content.meta.cursor.count;
            /**
             * find the next action
             */
            var k = 0;
            for (var i in resource.content.data) {
              if (currentId === resource.content.data[i].context_id) {
                k = _.cloneDeep(i);
                k++;
                if (!!resource.content.data[k]) {
                  results.waitingOnObject = resource.content.data[k];
                } else {
                  /**
                   * ensure its not the first one and last one
                   */
                  if (!(currentId === resource.content.data[0].context_id))
                    results.waitingOnObject = resource.content.data[0];
                }
                break;
              }
            }
          }
          deferred.resolve(results);
        }, function () {
          deferred.resolve([]);
        });
      return deferred.promise;
    },
    /**
     * function returns the code for each status
     * @param name
     * @returns {*}
     */
    getStatusCode: function (name) {
      /**
       DEACTIVATED = 0; 
       ACTIVE = 1; 
       INACTIVE = 2; 
       DISABLED = 3; 
       */
      if (!!name && typeof name === 'string') {
        if (name.toLowerCase() === 'deactivated') {
          return 0;
        }
        if (name.toLowerCase() === 'active') {
          return 1;
        }
        if (name.toLowerCase() === 'inactive') {
          return 2;
        }
        if (name.toLowerCase() === 'disabled') {
          return 3;
        }
        return null;
      }
      return null;
    },
    /**
     * function to return the import status code
     * @param name
     * @returns {*}
     */
    getImportSummaryStatusCode: function (name) {
      /**
       Compose = 0;
       Pending = 1; 
       Approved = 2;
       Expired = 4;
       Rejected = 5;
       Inactive = 7; 
       */
      if (!!name && typeof name === 'string') {
        if (name.toLowerCase() === 'compose') {
          return 0;
        }
        if (name.toLowerCase() === 'pending') {
          return 1;
        }
        if (name.toLowerCase() === 'approved') {
          return 2;
        }
        if (name.toLowerCase() === 'expired') {
          return 4;
        }
        if (name.toLowerCase() === 'rejected') {
          return 5;
        }
        if (name.toLowerCase() === 'inactive') {
          return 7;
        }
        return null;
      }
      return null;
    },
    /**
     * function to clean up the params for the tabData and return the new params
     * @param oldParams
     * @param newParams
     * @returns {{}}
     */
    filterUrlParams: function (oldParams, newParams) {
      var filteredParams = {};

      for (var i in oldParams) {
        oldParams[i] = null
        filteredParams[i] = oldParams[i];
      }
      for (i in newParams) {
        filteredParams[i] = newParams[i];
      }
      return filteredParams;
    },
    /**
     * function to remove the duplicated objects in an array
     * those needs to have a key identifying the object in order to detect duplication
     * @param array
     * @param key
     * @returns {Array}
     */
    removeDuplicateObjects: function (array, key) {
      var target = [];

      for (var i in array) {
        if (!this.findInArray(target, key, array[i][key]))
          target.push(array[i]);
      }

      return target;

    },
    /**
     * function to find the object key if its inside an array
     * @param array
     * @param key
     * @returns {*}
     */
    findObjectInArrayWithKeyValue: function (array, key) {
      if (!array)
        return;
      if (!key)
        return;

      var object = null;
      _.forEach(array, function (item) {
        if (!!Object.keys(item) && Object.keys(item)[0] === key)
          object = item[key];
      });
      return object;
    },

    /**
     * function to return key values of array of objects
     * @param array
     * @param key
     * @returns {Array}
     */
    getArrayOfKeyValues: function (array, key) {
      var temp = [];
      _.forEach(array, function (arr) {
        temp.push(arr[key]);
      });
      return temp;
    },
    /**
     * function to identify if the browser is running on mobile resolution
     */
    isMobileBrowser: function () {
      window.mobilecheck = function () {
        var check = false;
        (function (a) {
          if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
      };
      return mobilecheck();
    },
    /**
     * function to validate email formatting
     * @param email
     * @returns {boolean}
     */
    emailValidation: function (email) {
      var regex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,})$/;
      return regex.test(email);
    },
    numberFormatValidation: function (elementId) {
      var elementIdSelector = $("#" + elementId);
      // only validate if the there is a value
      // return valid if there is no value
      if (!!elementIdSelector.val()) {
        return elementIdSelector.intlTelInput('isValidNumber');
      }

      return true;
    },
    getTimezoneDiff: function () {
      var minutesDiff = new Date().getTimezoneOffset();
      minutesDiff /= 60;

      var timezoneDiff = String(minutesDiff);
      timezoneDiff += '00';

      if (timezoneDiff.length === 4) {
        timezoneDiff = timezoneDiff.replace('-', '-0');
        timezoneDiff = timezoneDiff.replace('+', '+0');
      }
      return timezoneDiff;
    },
    convertToLocalDate: function (date) {
      if (!date)
        return '';
      date += 'Z';
      return new Date(date);
    },
    getCsvFilenameWithDate: function (title) {
      return title + ' ' + $filter('date')(new Date(), 'dd-MMM-yyyy') + '.csv';
    },
    /**
     * check if a specific user has a the role PA
     * @param user
     * @returns {boolean}
     */
    checkIfUserHasPARole: function (user) {
      if (!user || !user.roles) {
        return false;
      }

      var result = false;
      _.forEach(user.roles, function (role) {
        if (role.name === 'PA' && role.company_code === null) {
          result = true;
        }
      });
      return result;
    },
    /**
     * Return a string based on selected object key in the array
     * join by comma separated
     *
     * @param array
     * @param key
     * @returns {*}
     */
    createStringBasedOnObjectKeyInArray: function (array, key) {
      if (!!array && _.isArray(array)) {
        return array.map(function (elem) {
          return elem[key];
        }).join(', ');
      } else {
        return array;
      }
    },
    /**
     * Function to return a string for number to fixed decimal places (does not do any rounding)
     *
     * @param {number} number        the number to be truncated
     * @param {number} decimalPlaces number of decimal places to truncate the number
     * @returns {string} Returns the fixed number as string
     */
    toFixedDecimalPlaces: function (number, decimalPlaces) {
      var fixedNumber = new RegExp('^-?\\d+(?:\.\\d{0,' + (decimalPlaces || -1) + '})?');
      return number.toString().match(fixedNumber)[0];
    },
    /**
     * Translation for the bad comments of the supplier
     * @param comments
     * @returns {*}
     */
    translateSupplierHistory: function (comments) {

      if (!comments.data)
        return [];

      for (var i = 0; i < comments.data.length; i++) {
        //TODO: change the case for blacklist and pending to string when backend changes and also use toLowerCase
        switch (comments.data[i].action) {
          case 1:
            comments.data[i].actionText = ' approved this supplier to active ';
            comments.data[i].comment = ' Supplier changed to active ';
            break;
          case 2:
            comments.data[i].actionText = ' approved this supplier to blacklisted ';
            comments.data[i].comment = ' Supplier changed to blacklisted ';
            break;
          case 0:
            comments.data[i].actionText = ' approved this supplier to pending ';
            comments.data[i].comment = ' Supplier changed to pending ';
            break;
          case 6:
            comments.data[i].actionText = ' approved this supplier to inactive ';
            comments.data[i].comment = ' Supplier changed to inactive ';
            break;
          case 'CREATED':
            if (i === 0)
              comments.data[i].actionText = ' created this supplier';
            else {
              comments.data[i].actionText = ' updated this supplier';
              comments.data[i].comment = comments.data[i].comment.replace('created', 'updated');
            }
            break;
          case 'UPDATED':
            comments.data[i].actionText = ' updated this supplier';
            break;
          case 'REJECTED':
            var i2 = i - 1;
            var previousAction = comments.data[i2].action.split(' ');
            if (previousAction.length > 1) {
              if (previousAction[previousAction.length - 4] === 'from') {
                previousAction = previousAction.slice(previousAction.length - 4, previousAction.length);
                previousAction = previousAction.join(' ');
              } else {
                previousAction = 'status';
              }
            } else {
              previousAction = 'status';
            }
            comments.data[i].actionText = ' rejected the request to move this supplier ' + previousAction;
            break;
          case 'APPROVED':
            comments.data[i].actionText = ' approved this supplier';
            break;
          case 'FULLY_APPROVED':
            comments.data[i].actionText = ' fully approved this supplier';

            break;
          default:
            comments.data[i].actionText = comments.data[i].action;
            break;
        }
      }

      return comments;
    },

    checkDuplicateValueInArray: function (inputArray) {
      var counts = [];
      for (var i = 0; i <= inputArray.length; i++) {
        if (counts[inputArray[i]] === undefined) {
          counts[inputArray[i]] = 1;
        } else {
          return true;
        }
      }

      // no duplicate
      return false;
    },

    checkAllSame: function (inputArray) {
      return inputArray.every(function (v) {
        return v === inputArray[0];
      });
    },

    isRedirectable: function (target) {
      var urls = ['/faq'];

      return !(urls.indexOf(target) > -1);
    },
    /**
     * Return valid file type for DO
     *
     * @returns {string[]}
     */
    validDoFileType: function () {
      return [
        'application/pdf',
        'image/png',
        'application/msword',
        'image/jpg',
        'image/jpeg',
        'text/plain'
      ];
    },
    /**
     * Check is user has supplier web admin role
     *
     * @param user
     * @returns {boolean}
     */
    checkIfUserHasSupplierWebAdminRole: function (user) {
      if (!user || !user.roles) {
        return false;
      }
      return this.findInArray(user.roles, 'name', 'SUPPLIER_WEB_ADMIN');
    },

    /**
     * Check if the user has the Company Budget Requester
     *
     * @param user
     * @param companyId
     * @returns {boolean}
     */
    checkIfUserHasCompanyBudgetRole: function (user, companyId) {
      var companies = this.getUserCompanyListForRole(user, ['Company Budget Requester']);
      if(!companies)
        return false;

      for(var i in companies){
        if(companies[i]._id === companyId)
          return true;
      }

      return false;
    },

    /**
     * Return list of company object based on user role
     *
     * @param user     User object
     * @param roleName Role name
     * @returns {Array}
     */
    getUserCompanyListForRole: function (user, roleName) {
      var companyList = [];
      if (!!user.companies && user.companies.length > 0) {
        if (!!user.role_assignments && user.role_assignments.length > 0) {
          for (var index in user.role_assignments) {
            for (var i in user.role_assignments[index].roles) {
              if (roleName.indexOf(user.role_assignments[index].roles[i].name) > -1 && user.role_assignments[index].weight === 3) {
                var company = this.findInArray(user.companies, '_id', user.role_assignments[index].organization_id);
                companyList.push(company);
              }
            }
          }
        } // end if
      } // end if

      return companyList;
    },

    /**
     * Return list of cost center object based on user role
     *
     * @param user
     * @param roleName
     * @returns {Array}
     */
    getUserCostCenterListForRole: function (user, roleName) {
      var costCenterList = [];
      if (!!user.costCenters && user.costCenters.length > 0) {
        if (!!user.role_assignments && user.role_assignments.length > 0) {
          for (var index in user.role_assignments) {
            for (var i in user.role_assignments[index].roles) {
              if (user.role_assignments[index].roles[i].name.toLowerCase() == roleName.toLowerCase() &&
                user.role_assignments[index].weight === 4) {
                var costCenter = this.findInArray(user.costCenters, '_id', user.role_assignments[index].organization_id);
                costCenterList.push(costCenter);
              }
            }
          }
        } // end if
      } // end if

      return costCenterList;
    },
    /**
     * helper function to sort out the data for generateCSV to generate CSV file
     * @param args
     * @returns {*}
     */
    convertArrayOfObjectsToCSV: function (args) {
      var result, columnDelimiter, lineDelimiter, data;

      data = this.reformatFromObjectToArray(args.data) || null;

      if (data === null || !data.length) {
        return null;
      }

      columnDelimiter = args.columnDelimiter || ',';
      lineDelimiter = args.lineDelimiter || '\n';

      var headers = [];
      var body = [];
      _.forEach(data, function (record) {
        headers.push(record.key);
      });

      result = '';
      result += headers.join(columnDelimiter);
      result += lineDelimiter;

      //TODO: support multiple lines (if needed)
      data.forEach(function (item) {
        body.push(item.value);
      });
      result += body;
      return result;
    },
    /**
     * function to generate CSV files
     * @param args
     * @returns {string}
     */
    generateCSV: function (args) {
      var data;
      var csv = this.convertArrayOfObjectsToCSV(args);
      if (csv == null) return;

      if (!csv.match(/^data:text\/csv/i)) {
        csv = 'data:text/csv;charset=utf-8,' + csv;
      }
      return encodeURI(csv);
    },
    setDataByKey: function (inputData, key) {
      data[key] = inputData;
    },
    getDataByKey: function (key) {
      return data[key];
    },
    detectIEBrowser: function () {
      var ua = window.navigator.userAgent;
      /* MSIE used to detect old browsers and Trident used to newer ones*/
      return (ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1);
    },

    //TODO: Need to call hasPermission function from User.Permissions.service, Khaled, 02/06/2020
    checkUserPermission: function (profile, moduleName) {
      var permissionName;

      switch (moduleName) {
        case 'Purchase Requisitions':
          permissionName = "Create PR";
          break;
        case 'Budget':
          permissionName = "Create Budget";
          break;
        // Importing in Budget
        case 'Project Code':
          permissionName = "Create Budget";
          break;
        // Importing in Budget
        case 'GL Account':
          permissionName = "Create Budget";
          break;
        case 'Purchase Order':
          permissionName = "Create PO";
          break;
        case 'Approval Template':
        case 'Position':
        case 'Position Assignment':
          permissionName = "Create ApprovalTemplate";
          break;
        case 'Catalogs':
          permissionName = "Create ImportItemSummary";
          break;
        case 'Suppliers':
        case 'Supplier Tags':
          permissionName = "Create Supplier";
          break;
        case 'Companies':
          permissionName = "Create Company";
          break;
        case 'cost centers':
        case 'ERP Cost Center':
        case 'ERP Order Type':
          permissionName = "Create CostCenter";
          break;
        case 'Account Codes':
          permissionName = "Create AccountCode";
          break;
        case 'Company Exchange Rate':
          permissionName = "Create ExchangeRate";
          break;
        case 'Business Units':
          permissionName = "Create BusinessUnit";
          break;
        case 'Industries':
          permissionName = "Create Company";
          break;
        case 'Currencies':
          permissionName = "Create Currencies";
          break;
        case 'Exchange Rates':
          permissionName = "Create ExchangeRate";
          break;
        case 'UOMs':
          permissionName = "Create UOM";
          break;
        case 'Payment Terms':
          permissionName = "Create PaymentTerm";
          break;
        case 'Payment Methods':
          permissionName = "Create PaymentMethod";
          break;
        case 'Commodities':
          permissionName = "Create Commodity";
          break;
        case 'Taxes':
        case 'Item\'s Tax':
          permissionName = "Create Tax";
          break;
        case 'Expense Types':
          permissionName = "Create ExpenseTypes";
          break;
        case 'Users':
        case 'User Assignments':
        case 'Add User':
        case 'Superior':
        case 'Delegation':
          permissionName = "Create Users";
          break;
        case 'Alc':
          permissionName = "Create Alc";
          break;
        case 'Draft Budget Account Code':
          permissionName = "Create Budget";
          break;
      }

      if (this.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN') ||
        this.findRoleInRoleAssignments(profile.role_assignments, 'ADMIN') ||
          (this.findRoleInRoleAssignments(profile.role_assignments, 'Sourcing Metabuyer Admin') ||
          this.findRoleInRoleAssignments(profile.role_assignments, 'Sourcing Tenant Admin')||
          this.findRoleInRoleAssignments(profile.role_assignments, 'Sourcing and Bidding Admin'))) {
        return false;
      }

      for (var index in profile.role_assignments) {
        for (var i in profile.role_assignments[index].roles) {
          for (var j in profile.role_assignments[index].roles[i].permissions) {
            if (profile.role_assignments[index].roles[i].permissions[j].name === permissionName) {
              return false;
            }
          }
        }
      }

      return true;
    },

    loggingWarning: function(){
      toastr.info('Please log in.');
    },
    getNestedObjectKeys: function(obj, prefix) {
      return listKeysRecursively(obj, prefix);
    },
    laravelValidatorErrDisplay: function(err){
      var errors = this.reformatFromObjectToArray(err);
      for(var i=0;i<errors.length;i++){
        if(i > 10)
          return;

        toastr.error(errors[i].value[0])
      }

    },
    /**
     * Check if provided string has json structure
     * @param str
     * @returns {boolean}
     */
    hasJsonStructure: function (str) {
      if (typeof str !== 'string') return false;
      try {
        var result = JSON.parse(str);
        var type = Object.prototype.toString.call(result);
        return type === '[object Object]'
          || type === '[object Array]';
      } catch (err) {
        return false;
      }
    },
    /**
     * Function to get search and get the Month's info
     *
     * @param index
     * @param field
     * @returns {*}
     */
    getMonthText: function (index, field) {
      if(!field || ['name', 'number'].indexOf(field) < 0)
        return undefined;

      return this.findInArray(monthInText, 'k', index)[field];
    },
    getCurrencyConfig: function (currencyCode) {
      var config = _.find(currencyConfig, function(e){
        return e.code === currencyCode;
      });

      return config;
    },
    /**
     * Function to return allowed other charges
     *
     * @returns {*[]}
     */
    getAllowedOtherCharges: function () {
      return allowedOtherCharges;
    },

    generateRegexPassword: function (passwordSettings) {
      var regex = "";
      var multicase = ["(?=.*[a-z])", "(?=.*[A-Z])"];
      var numbers = "(?=.*[0-9])";
      var symbolList = " `~!@#$%^&*()_\\-+={}[\\]|\\\\:;\"'<>,.?/";
      var symbols = "(?=.*[" + symbolList + "])";
      var startWhitespace = "^(?!\\s)";
      var endWhitespace = "(?<!\\s)$";
    
      if (!!passwordSettings) {
        if (passwordSettings.multicase && passwordSettings.numbers && passwordSettings.symbols)
          regex += (startWhitespace + multicase.join("") + numbers + symbols);
        else if (passwordSettings.multicase && passwordSettings.numbers)
          regex += (startWhitespace + multicase.join("") + numbers + symbols + "?");
        else if (passwordSettings.multicase && passwordSettings.symbols)
          regex += (startWhitespace + multicase.join("") + numbers + "?" + symbols);
        else if (passwordSettings.numbers && passwordSettings.symbols)
          regex += (startWhitespace + multicase.join("?") + "?" + numbers + symbols);
        else if (passwordSettings.multicase)
          regex += (startWhitespace + multicase.join("") + numbers + "?" + symbols + "?");
        else if (passwordSettings.numbers)
          regex += (startWhitespace + multicase.join("?") + "?" + numbers + symbols + "?");
        else if (passwordSettings.symbols)
          regex += (startWhitespace + multicase.join("?") + "?" + numbers + "?" + symbols);
        else
          regex += (startWhitespace + multicase.join("?") + "?" + numbers + "?" + symbols + "?");
    
        regex += ("[A-Za-z0-9 " + symbolList + "\\s]{" + passwordSettings.len + ",30}" + endWhitespace);
      }

      return new RegExp(regex);
    }
  }
}

angular
  .module('metabuyer')
  .factory('globalFunc', globalFunc);
