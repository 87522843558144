'use strict';
angular
  .module('metabuyer')
  .directive('approvalEnginePagination', function () {
    return {
      restrict: 'E',
      templateUrl: 'components/pagination/paginationForAE.html',
      scope: {
        callbackSearch: '&callbackSearch',
        startRecNo: '=startRecNo',
        endRecNo: '=endRecNo',
        totalRec: '=totalRec',
        totalPageNumber: '=totalPageNumber',
        currentPageNumber: '=currentPageNumber'
      },
      controller: function ($scope) {

        $scope.goNextPage = goNextPage;
        $scope.goPreviousPage = goPreviousPage;

        function goNextPage() {
          $scope.currentPageNumber += 1;
          $scope.callbackSearch({page: $scope.currentPageNumber});
        }

        function goPreviousPage() {
          $scope.currentPageNumber -= 1;
          $scope.callbackSearch({page: $scope.currentPageNumber});
        }
      }
    };
  });
