'use strict';
/**
 * @name adminUnlockUserCtrl
 * @desc Controller for Admin to Unlock a locked User
 */
function adminUnlockUserCtrl($scope, adminUnlockUser, toastr, $state, $uibModalInstance, $rootScope, globalFunc, adminSettings) {

  $scope.closeModal = closeModal;
  $scope.unlockUser = unlockUser;
  $scope.confirmUnlockUser = confirmUnlockUser;
  $scope.error = false;
  $scope.unlockedLockedUser = false;
  $scope.wrongFormatError = false;
  $scope.isV2FeatureEnabled = isV2FeatureEnabled;
  $scope.isMulticasedPass = false;
  $scope.isNumberedPass = false;
  $scope.isSymboledPass = false;
  $scope.isWhitespaceStart = false;
  $scope.isWhitespaceEnd = false;

  function confirmUnlockUser() {
    //clearing all the errors
    $scope.wrongFormatError = false;
    $scope.error = false;
    //checks if they pass all the validation
    if (checkPasswordFormat()) {
    }
    else {
      //message about the required password format
      $scope.wrongFormatError = true;
      $scope.error = true;
    }
    if (!$scope.error) {
      adminUnlockUser.put({
          id: $scope.user._id
        }, {
          password: btoa($scope.password_new),
        },
        function (success) {
          $uibModalInstance.close();
          toastr.success("User successfully unlocked");
          $state.go($state.current, {}, {reload: true});
        },
        function (error) {
          if (!!error) {
            toastr.error("There was an error in unlocking this user.");
          }
        });
    }
    else {

    }

  }

  function checkPasswordFormat() {
    var reg = new RegExp($scope.regexPassword);
    return reg.test($scope.password_new);
  }

  function unlockUser() {
    $scope.unlockedLockedUser = true;
  }

  function closeModal() {
    $uibModalInstance.close();
  }

  function isV2FeatureEnabled() {
    return $rootScope.isV2Enabled;
  }

  function checkFields(value, field) {
    var reg;
    var test = false;
    switch (field) {
      case 'MULTICASE':
        reg = new RegExp("(?=.*[a-z])(?=.*[A-Z])")
        test = reg.test(value);
        break;
      case 'NUMBERS':
        reg = new RegExp("(?=.*[0-9])")
        test = reg.test(value);
        break;
      case 'SYMBOLS':
        reg = new RegExp("(?=.*[@#$!%*?&])")
        test = reg.test(value);
        break;
      case 'WHITESPACESTART':
      reg = new RegExp("^\\s")
      test = reg.test(value) ? false : true;   
        break;
      case 'WHITESPACEEND':
        reg = new RegExp("\\s$")
        test = reg.test(value) ? false : true;
        break;
    }
    return test;
  }

  function loadPasswordSettings() {
    adminSettings.get(
      {},
      function(resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.passwordSettings = resource.content.data.config.password;
          $scope.regexPassword = globalFunc.generateRegexPassword($scope.passwordSettings);
        }
      },
      function(error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  loadPasswordSettings();
  $scope.$watch('adminUnlock.password_new.$viewValue', function (newValue, oldValue) {
    $scope.isMulticasedPass = checkFields(newValue, 'MULTICASE')
    $scope.isNumberedPass = checkFields(newValue, 'NUMBERS')
    $scope.isSymboledPass = checkFields(newValue, 'SYMBOLS')
    $scope.isWhitespaceStart = checkFields(newValue, 'WHITESPACESTART')
    $scope.isWhitespaceEnd = checkFields(newValue, 'WHITESPACEEND')
  });
}

adminUnlockUserCtrl.$inject = ['$scope', 'adminUnlockUser', 'toastr', '$state', '$uibModalInstance', '$rootScope', 'globalFunc', 'adminSettings'];

angular
  .module('metabuyer')
  .controller('adminUnlockUserCtrl', adminUnlockUserCtrl);
