/**
 * @name tableHeader
 *
 *
 * @description
 *
 * Directive to handle the Sorting in listing (Manage) pages
 *
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

angular.module('metabuyer')
  .directive('tableHeader', function () {
    return {
      scope: {
        availableHeaders: '=',
        condition: '=?',
        multiSelectAction: '&?',
        searchFunction: '&',
        isApprovalEngine: '=',
        currentStatus: '='
      },
      templateUrl: 'components/tableHeader/tableHeaderTemplate.html',
      restrict: 'A',
      controller: 'tableHeaderCtrl'
    }
  })

  /**
   * @name tableHeaderCtrl
   *
   * @description
   * The controller of the tableHeader directive handles sorting/resetting
   *
   * @author Ahmed Saleh <a.saleh@vventures.asia>
   * @copyright Sunway Metacloud &copy; 2016
   */
  .controller('tableHeaderCtrl', function($scope, $state, $stateParams) {
    $scope.sort = sort;
    $scope.checkSelectionAndCondition = checkSelectionAndCondition;

    function sort(col) {
      /**
       * check if the header is a sortable type or not
       */
      if(!col.unsortable) {

        var orderBy;
        var order;
        if ($stateParams.order_by !== col.id) {
          /**
           * a new header is selected
           */
          orderBy = col.id;
          order = 1;
        } else if ($scope.order === 0) {
          /**
           * the same header is selected and the sort needs to be discarded
           */
          orderBy = null;
          order = null;
        } else {
          /**
           * the same header is selected and the sort needs to be ascending
           */
          orderBy = col.id;
          order = ($scope.order === null ? 1 : 0);
        }

        if ($scope.isApprovalEngine){
          if ($scope.currentStatus.order === 'ASC' && $scope.currentStatus.col === col.id ){
            $scope.currentStatus.order = 'DESC';//if same col press second time
            $scope.order = 0;
          } else if ($scope.currentStatus.order === 'DESC' && $scope.currentStatus.col === col.id ){
            $scope.currentStatus.order = null;//if same col press third time
            $scope.currentStatus.col = null;
            $scope.order = null;
          } else if (($scope.currentStatus.order === null && $scope.currentStatus.col === null) ||
            ($scope.currentStatus.col !== col.id)) {
            $scope.currentStatus.order = 'ASC';//if col first time being press
            $scope.currentStatus.col = col.id;
            $scope.order = 1;
          } else {
            $scope.currentStatus.order = null;
            $scope.currentStatus.col = null;
            $scope.order = null;
          }

          $scope.searchFunction({page: 0, sortingCol: $scope.currentStatus.col, sortingOrder: $scope.currentStatus.order});
          $scope.sortedCol = $scope.currentStatus.col;
        }
        else{
          $state.go($state.current, {order_by: orderBy, order: order, cursor: null});
          getSortingInformation();
        }
      }
    }

    function checkSelectionAndCondition(col) {
      if(col.id === 'check box')
        return false;
      
      if (col.id === 'mass_update_status')
        return false;

      if (col.id === 'pr_select')
        return false;

      if (col.unListable === true)
        return false;

      if (col.conditional === true) {
        if (col.condition === $scope.condition) {
          return col.selected;
        }

        if (col.condition instanceof Array){
          for (var i in col.condition){
            if (col.condition[i] === $scope.condition){
              return col.selected;
            }
          }
        }
        return false;
      } else {
        return col.selected;
      }
    }


    function getSortingInformation() {
      $scope.sortedCol = $stateParams.order_by;
      $scope.order = Number($stateParams.order);
      if ($scope.order !== 0 && $scope.order !== 1) {
        $scope.order = null;
      }
    }

    function initialize() {
      getSortingInformation();
    }

    initialize();
  });
