/**
 * @name usersEscalationCtrl
 *
 * @description
 *
 * This controller lists All the available users with their own escalation options
 * Some of the users work in multiple companies or none, The Html represents that.
 *
 * @requires $scope
 * @requires usersList (resolved from the State)
 * @requires $uibModal
 *
 * @function modify
 * This function opens the Modal for searching and selecting (as well as deselecting) a User to Escalate to
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Verve Technologies Sdn. Bhd. &copy; 2015
 */

'use strict';

function usersEscalationCtrl($scope, usersList, $uibModal, pathConstants, globalFunc) {
  $scope.dataList = usersList.data;
  $scope.meta = usersList.meta;
  $scope.offset = 25;
  $scope.modify = modify;
  $scope.importManager = importManager;
  $scope.importLink = pathConstants.apiUrls.users.importManager;
  $scope.showColumn = showColumn;

  $scope.storageKey = 'escalation-management-selected-columns';

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function modify(userObject) {
    $uibModal.open({
      templateUrl: 'app/usersManagement/escalation/escalationModificationPanelTemplate.html',
      controller: 'escalationModificationPanel',
      backdrop: 'static',
      keyboard: false,
      scope: $scope,
      resolve: {
        company: function () {
          return userObject.company_code;
        },
        userInfo: function () {
          return userObject;
        }
      }
    }).result.then(function (superiorSelected) {
      if (!!superiorSelected) {
          userObject.superior_display_name = superiorSelected[0];
          userObject.superior_email = superiorSelected[1];
        }
    });
  }

  function importManager() {
    var modalInstance = $uibModal.open({
      templateUrl: "app/masterDataManagement/Import-Template.html",
      backdrop: 'static',
      keyboard: false,
      resolve: {
        importLink: function () {
          return $scope.importLink;
        },
        title: function () {
          return "Managers";
        }
      },
      controller: function ($scope, $uibModalInstance, importLink, title) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        $scope.importLink = importLink;
        $scope.title = title;
      }
    });
  }

  function initialize(){
    // Initialize here
    $scope.params = {
      'class': 'Metabuyer\\Models\\UserSuperior'
    }
  }

  initialize();

}

usersEscalationCtrl.$inject = ['$scope', 'usersList', '$uibModal', 'pathConstants', 'globalFunc'];

angular
  .module('metabuyer')
  .controller('usersEscalationCtrl', usersEscalationCtrl);
