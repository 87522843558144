'use strict';

function addContactCtrl($scope, $uibModalInstance, toastr, $q, Contacts, globalFunc, $rootScope) {

  $scope.checkInput = checkInput;

  function checkInput() {
    if ($scope.isV2FeatureEnabled) {
      return !$scope.add_data.name && !$scope.add_data.email && !$scope.add_data.phone && !$scope.add_data.fax;
    }

    return false;
  }

  //cancel action
  $scope.cancel = function(){
    $uibModalInstance.close();
  };

  function validateContact(){
    if(!$scope.add_data.name && !$rootScope.isV2Enabled){
      return false;
    }

    if(!$scope.add_data.phone && !$rootScope.isV2Enabled){
      return false;
    }

    if (!$scope.isValidPhoneNo && !$rootScope.isV2Enabled) {
      return false;
    }

    // if (!$scope.isValidFaxNo) {
    //   return false;
    // }

    return true;
  }

  /**
   * Validate number format with given element id and returns boolean in field name
   *
   * @param {string} elementId
   * @param {string} fieldName
   */
  function validateNumbers(elementId, fieldName) {
    $scope[fieldName] = globalFunc.numberFormatValidation(elementId);
  }

  //proceed action
  $scope.addContact = function() {
    $scope.clicked_submit = true;
    if (!validateContact()) {
      toastr.error('Please fill all required fields');
      return;
    }

    if (!!$scope.add_data.email && !globalFunc.emailValidation($scope.add_data.email)) {
      toastr.error('Please enter a valid email address');
      return;
    }

    if (!$scope.isValidFaxNo) {
      toastr.error('Invalid Fax Number');
      return;
    }

    $scope.addedContact({contact: $scope.add_data});

    $uibModalInstance.close();
  };

  function initialize() {
    $scope.add_data = {};
    $scope.validateContact = validateContact;
    $scope.emailFormatValidation = globalFunc.emailValidation;
    $scope.validateNumbers = validateNumbers;
    $scope.isValidPhoneNo = true;
    $scope.isValidFaxNo = true;
    $scope.isV2FeatureEnabled = $rootScope.isV2Enabled;
  }

  initialize();
}

addContactCtrl.$inject = ['$scope', '$uibModalInstance', 'toastr', '$q', 'Contacts', 'globalFunc', '$rootScope'];

angular.module('metabuyer')
  .controller('addContactCtrl', addContactCtrl);
