'use strict';

function kharonScheduleCtrl($scope, dataList, toastr, $uibModal, kharonUpdateSchedulerConfig) {
  $scope.dataList = dataList.content && dataList.content.data || [];

  $scope.editSchedule = editSchedule;
  $scope.statusToggle = statusToggle;

  function editSchedule(data) {
    $uibModal.open({
      templateUrl: 'app/settings/kharonSettings/schedule/editScheduleModal.html',
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      resolve:{
        data: function(){
          return data;
        }
      },
      controller: function ($scope, $uibModalInstance, toastr, $state, kharonUpdateSchedulerConfig) {

        if (!!data) {
          $scope.id = data.id;
          $scope.name = data.name;
          $scope.cron_expression_time = data.cron_expression_time;
          $scope.is_enabled = data.is_enabled;
        }

        $scope.submit = submit;
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        function submit(){
          $scope.submitted = true;

          if (!$scope.name || !$scope.cron_expression_time || !$scope.is_enabled ) {
            toastr.error('Please fill up all fields.');
            return;
          }

          swal({
            title: 'Confirm update Kharon schedule?',
            text: 'This action is not revertable.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Confirm',
            closeOnConfirm: true
          }, function (isConfirm) {
            if (isConfirm) {
              confirmSubmit();
            }
          });
        }

        function confirmSubmit() {
          kharonUpdateSchedulerConfig.put(
            {
              id: data.id
            },
            {
              id: $scope.id ,
              name: $scope.name ,
              cron_expression_time: $scope.cron_expression_time,
              is_enabled: $scope.is_enabled,
            },
            function() {
              toastr.success("Updated Kharon Schedule.");
              $uibModalInstance.close();
              $state.reload();
              close();
            },
            function() {
              toastr.error("Failed to update Kharon Schedule.");
            });
        }
      }
    });
  }

  function statusToggle(data) {
    var newStatus = data.is_enabled;
    var statusLabel = newStatus ? 'ON':'OFF';

    kharonUpdateSchedulerConfig.put(
      {
        id: data.id
      },
      data,
      function() {
        toastr.success(data.name + " Kharon schedule is now " + statusLabel);
        data.updated_at = response.data.updated_at;
      },
      function() {
        toastr.error("Failed to update Kharon Schedule status.");
      });
  }

  function initialize() {
  }

  initialize();
}

kharonScheduleCtrl.$inject = ['$scope', 'dataList', 'toastr', '$uibModal', 'kharonUpdateSchedulerConfig'];

angular
    .module('metabuyer')
    .controller('kharonScheduleCtrl', kharonScheduleCtrl);
