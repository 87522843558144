'use strict';

/**
 *
 * @name voHistoryCtrl
 *
 * @description Controller for VO histories
 *
 * @author Nazrul Wazir <nazrulhmw@sunway.com.my>
 * @copyright Sunway Metacloud &copy; 2022
 */

function voHistoryCtrl(
  $scope, $rootScope, $state, tenderFunction, tender, histories, $uibModal
) {

  $scope.tender = tender.data;
  $scope.histories = histories.data
  $scope.back = back;
  $scope.showVersionList = showVersionList;

  function back() {
    $state.go('main.user.tender.detail', { id: $scope.tender._id });
  }

  /**
   * Display tender versions list in modal\
   */
  function showVersionList() {
    $uibModal.open({
      templateUrl: 'components/tenderComponents/versions/tenderVersions.html',
      backdrop: 'static',
      keyboard: false,
      scope: $scope,
      resolve: {
        tenderVersions: function() {
          return $scope.histories.histories;
        },
        tender: function() {
          return $scope.tender;
        },
        mode: function() {
          return 'vo';
        }
      },
      controller: 'tenderVersionsCtrl'
    });
  }

  function initialize() {

  }

  initialize();

}

voHistoryCtrl.$inject = [
  '$scope', '$rootScope', '$state', 'tenderFunction', 'tender', 'histories', '$uibModal'
];

angular
  .module('metabuyer')
  .controller('voHistoryCtrl', voHistoryCtrl);
