'use strict';

angular.module('metabuyer.services.purchasePrice', ['ngResource'])
  .factory('purchasePriceServices', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.purchasePrice,
      {
        id: '@id',
        company: '@company'
      },
      {
        'get': {method: 'GET'}
      }
    )
  });
