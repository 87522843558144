
'use strict';

function purchaseRequisitionsV2HeaderCtrl(
    $scope, prV2Function, purchaseRequisitionsV2Services, globalFunc, $rootScope, $uibModal, $state, HighlightId, getPRPDFHashV2, pathConstants, $filter
) {

    $scope.currentUser    = $rootScope.currentUser || { _id: null };
    $scope.back           = back;
    $scope.updatePRTitle  = updatePRTitle;
    $scope.PRData         = PRData;
    $scope.starringPR     = starringPR;
    $scope.checkStarred   = checkStarred;
    $scope.openRevision   = openRevision;
    $scope.printPRViaPDF  = printPRViaPDF;
    $scope.PRTotalAmount                = PRTotalAmount;
    $scope.PRTotalAmountBeforeTax       = PRTotalAmountBeforeTax;

    function PRTotalAmount() {
      var localPRData = prV2Function.getPRData();
      return localPRData.grand_total_pr_currency;
    }

    function PRTotalAmountBeforeTax() {
      var localPRData = prV2Function.getPRData();
      var amountBeforeTax = localPRData.grand_total_pr_currency - localPRData.tax_total_pr_currency;

      // this formula below handles cases for calcs like 1.23 - 1.01 = 0.21999999999999997 to be 0.22
      return (+(Math.round(amountBeforeTax + "e+2")  + "e-2")).toFixed(2);
    }

    $scope.originalPRTitle = angular.copy($scope.PR.title);

    $scope.templateUrl = $rootScope.isMobileMode?
        'components/purchaseRequisitionV2Components/prHeaderV2/purchaseRequisitionV2HeaderTemplate-mobile.html'
        :'components/purchaseRequisitionV2Components/prHeaderV2/purchaseRequisitionV2HeaderTemplate.html';


    function back() {
        if ($scope.shoppingModeStatus || prV2Function.hidePRHeader()){
            if(!!PRData().original_pr){
                window.history.back();
            }
            else {
                $state.go('main.user.purchaseRequisitionsV2.details', {id: PRData()._id, PR: {data: PRData()}});
            }
        }
        else {
            var prStatus = PRData().status;
            if(PRData().status === 'on_hold' || PRData().status === 'edit'){
                prStatus = 'pending';
            }
            $state.go('main.user.purchaseRequisitionsV2.manage', {
                status: prStatus,
                tab: prStatus === 'pending' ? 'waiting' : null,
                extraFilterKey: prStatus === 'pending' ? 'criteria[waiting_on][]' : null,
                extraFilterValue: prStatus === 'pending' ? $rootScope.currentUser._id : null,
                cursor: null
            });
            if(!!PRData().original_pr){
                HighlightId.setId(PRData().original_pr._id);
            }
            else{
                window.history.back();
                HighlightId.setId($scope.PR._id);
            }
        }
    }

    // function backToPRDetails() {
    //   console.log($scope.PRData._id);
    //   $state.go('main.user.purchaseRequisitionsV2.details', {id: $scope.PRData._id});
    // }

    function starringPR(prId) {
        var isStarred = checkStarred(prId);
        globalFunc.starFavourites(isStarred, prId, 'PR');
    }

    function checkStarred(prId) {
        if (!!$rootScope.currentUser.favourites && !!$rootScope.currentUser.favourites.PR) {
            var userFavourites = $rootScope.currentUser.favourites.PR;
            return userFavourites.indexOf(prId) > -1;
        }
        else {
            return false;
        }
    }

    function updatePRTitle(prTitle) {
        if (!!prTitle) {
            purchaseRequisitionsV2Services.updateTitle(
                {
                    requisition_id: $scope.PR._id
                },{
                    title : prTitle
                },function(){
                    $scope.PR.title = prTitle;
                    $scope.originalPRTitle = angular.copy($scope.PR.title);
                },function (error){
                    globalFunc.objectErrorMessage(error);
                }
            );
        } else {
            // If input field is empty, fill empty input by previous data
            $scope.PR.title = angular.copy($scope.originalPRTitle);
        }
    }

    function PRData(){
        return prV2Function.getPRData();
    }

    /**
     * Open PR comparison with the old version in modal
     * @param currentPR
     * @param previousId
     * @param version (version number)
     */
    function openRevision(currentPR, previousId, version) {
        $uibModal.open({
            templateUrl: 'components/purchaseRequisitionV2Components/revisionV2/revisionV2.html',
            backdrop: 'static',
            keyboard: false,
            controller: 'prV2VersionCtrl',
            size: 'lg',
            windowClass: 'revision-wrapper',
            resolve: {
                currentPR: function(){
                    return currentPR;
                },
                previousVersion: function($q, purchaseRequisitionsV2Services){
                    var deferred = $q.defer();
                    purchaseRequisitionsV2Services.getSinglePR(
                        {
                          requisition_id: previousId
                        },
                        function (resource) {
                            if (!!resource && !!resource.content && !!resource.content.data)
                                deferred.resolve(resource.content.data);
                            else
                                deferred.resolve({});
                        },
                        function (error) {
                            deferred.resolve({});
                            globalFunc.objectErrorMessage(error);
                        }
                    );

                    return deferred.promise;
                },
                versionNumber: function () {
                    return version;
                }
            }
        });
    }

    /**
     * need to reformat the revision data
     * because the data from backend is in object and hard to read by ng-repeat
     */
    function preparePRRevisions() {
        $scope.PRData().revisions = globalFunc.reformatFromObjectToArray($scope.PRData().revisions);
    }

    function printPRViaPDF() {
        /**
         * Validate the pr items before proceeding
         */
        if (!$scope.PR.pr_items || $scope.PR.pr_items.length < 1) {
          toastr.error('Print PR failed because the PR has no items.');
        }
        else {

          /**
           * Pass current logged in user's timezone
           */
          getPRPDFHashV2.get(
            {
              requisition_id: $scope.PR._id,
              timezone: $scope.currentUser.config.TZ
            }, function (resource) {
              var newUrl = $filter('format')(pathConstants.apiUrls.globalPDFHashDownload, {
                pdf_hash: resource.url
              });

              setTimeout(function () {
                window.open(newUrl, '_blank')
              }, 1001);
            }, function () {
              toastr.error('Failed to print PR');
            }
          );
        }
      }

    function initialize() {
        preparePRRevisions();
    }

    $scope.$on('backFromPr', function () {
        back();
    });

    initialize();

}

purchaseRequisitionsV2HeaderCtrl.$inject = [
    '$scope', 'prV2Function', 'purchaseRequisitionsV2Services', 'globalFunc', '$rootScope', '$uibModal', '$state', 'HighlightId', 'getPRPDFHashV2', 'pathConstants', '$filter'
];

angular
    .module('metabuyer')
    .directive('purchaseRequisitionV2Header',function() {
        return {
            restrict: 'E',
            scope: {
                editingState: '=',
                PR: '=pr',
                costCenters: '=',
                shoppingModeStatus: '='
            },
            template: '<div ng-include="templateUrl"></div>',
            controller: 'purchaseRequisitionsV2HeaderCtrl'
        }
    })
    .controller('purchaseRequisitionsV2HeaderCtrl',  purchaseRequisitionsV2HeaderCtrl);
