'use strict';


function faqVideosCtrl($scope, Lightbox) {

  $scope.Lightbox = Lightbox;
}

faqVideosCtrl.$inject = [
  '$scope', 'Lightbox'
];


angular
  .module('metabuyer')
  .directive('faqVideos', function () {
    return {
      restrict: 'E',
      templateUrl: 'components/faqVideos/videos.html',
      controller: 'faqVideosCtrl',
      scope: {
        videos: '='
      }
    };
  })
  .controller('faqVideosCtrl',  faqVideosCtrl);
