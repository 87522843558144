'use strict';

angular.module('metabuyer.services.catalogCode' , ['ngResource'])
  .factory('catalogCode',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.catalogCode.single,
        {
          id      : '@id',
          action  : '@action'
        },
        {
          'get'   : { method : 'GET' },
          'post'  : { method : 'POST' },
          'delete': { method : 'DELETE' },
          'put'   : { method : 'PUT' }
        }
      );
    })
  .factory('catalogCodeList',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.catalogCode.list, {},
        {
          'get'   : { method : 'GET' }
        }
      );
    });
