'use strict';
/**
 * @name requisitionsV2ManageCtrl
 *
 * @param $scope
 * @param dataList
 * @param status
 * @param purchaseRequisitionsV2Services
 * @param $rootScope
 * @param globalFunc
 * @param $state
 * @param $stateParams
 * @param HighlightId
 * @param $window
 * @param $filter
 * @param toastr
 * @param prV2Function
 * @param Approvals
 * @param $q
 * @param metabuyerCache
 * @param massUpdatePrstatus
 */
function requisitionsV2ManageCtrl(
  $scope, dataList, status, purchaseRequisitionsV2Services, $rootScope, globalFunc, $state, $stateParams, HighlightId,
  $window, $filter, toastr, prV2Function, Approvals, $q, metabuyerCache, massUpdatePrstatus
) {

  $scope.processPr = false;
  $scope.selectedPrs = [];
  $scope.selectedPr = selectedPr;

  /**
   * return true if show details is clicked
   * @param id
   * @returns boolean
   */
  function showDetails(id) {
    return (id === $scope.selectedDetail) ? true : false;
  }

  /**
   * set value of selectedDetail
   * @param id
   * @param option (set to show/hide details)
   */
  function setShowDetailsId(id, option) {
    if (option === 'show') {
      _.forEach($scope.dataList, function (item) {
        if (item['_id'] === id) {
          $scope.PR = item;
        }
      });
      $scope.selectedDetail = id;
      setPRItem();
      loadApprovalFlow();
      checkIfPRIsEditable();
    }
    if (option === 'hide')
      $scope.selectedDetail = null;
  }

  /**
   * function returns checker for the states to hide or show the PR details
   * @returns {*|boolean}
   */
  function isShoppingMode() {
    $scope.PR = prV2Function.getPRData();
    return $state.includes('main.user.purchaseRequisitionsV2.details.catalog');
  }

  function getEditingPRState() {
    return prV2Function.getEditingState();
  }

  /**
   * this function will check the state of the PR
   * as well as the Type of the logged in user
   */
  function checkIfPRIsEditable() {
    prV2Function.setEditableState(($scope.PR.status === 'draft') ? 'editable' : 'notEditable');
    if ($rootScope.currentUser._id !== $scope.PR.creator_info._id)
      prV2Function.setEditableState('notEditable');
  }

  function loadApprovalFlow() {
    if ($scope.PR.status !== 'draft') {
      Approvals.get({
        id: $scope.PR.approval_id
      }, function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          prV2Function.setApprovalFlow(resource.content.data);
        }
      });
    }
  }

  function setPRItem() {
    var deferred = $q.defer();
    purchaseRequisitionsV2Services.getSinglePR(
      {
        requisition_id: $scope.PR._id
      }, function (resource) {
        if (!!resource.content) {
          deferred.resolve(resource.content);
          prV2Function.initializePRData(resource.content.data);
        } else {
          deferred.resolve([]);
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      });
  }


  /**
   * cache the clicked checkboxes
   * @param requisition
   */
  function tickCheckBox(requisition) {
    if (!requisition || !checkWaitingOnUser(requisition))
      return;

    var list = metabuyerCache.get('PRClicked');
    if (!list) {
      list = [];
    }

    var index = list.indexOf(requisition._id);
    if (!!requisition.selected) {
      if (list.indexOf(requisition._id) === -1)
        list.push(requisition._id);
    } else {
      list.splice(index, 1);
    }
    metabuyerCache.put('PRClicked', list);
  }

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional) {
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }

  /**
   * function to navigate between the sub tabs
   * @param tab
   */
  function tabSelection(tab) {
    switch (tab) {
      case 'all': {
        $scope.selectedListTab = tab;
        $state.go('main.user.purchaseRequisitionsV2.manage', {
          status: $scope.status,
          tab: tab,
          extraFilterKey: null,
          extraFilterValue: null,
          page: null
        });
        break;
      }
      case 'yours': {
        $scope.selectedListTab = tab;
        $state.go('main.user.purchaseRequisitionsV2.manage', {
          status: $scope.status,
          tab: tab,
          extraFilterKey: 'criteria[creator_info|_id][][]',
          extraFilterValue: $rootScope.currentUser._id,
          page: null
        });
        break;
      }
      case 'shared': {
        $scope.selectedListTab = tab;
        $state.go('main.user.purchaseRequisitionsV2.manage', {
          status: $scope.status,
          tab: tab,
          extraFilterKey: 'criteria[collaborators_ids][]',
          extraFilterValue: true,
          page: null
        });
        break;
      }
      case 'waiting': {
        $scope.selectedListTab = tab;
        $state.go('main.user.purchaseRequisitionsV2.manage', {
          status: $scope.status,
          tab: tab,
          extraFilterKey: 'criteria[waiting_on][]',
          extraFilterValue: $rootScope.currentUser._id,
          page: null
        });
        break
      }
    }
  }

  /**
   * return if the user is part of the waiting on, for a specific PR
   * @param requisition
   * @returns {*}
   */
  function checkWaitingOnUser(requisition) {
    if(requisition.header_info.status === 'pending')
      return globalFunc.findInArray(requisition.waiting_on, '_id', $rootScope.currentUser._id);
    else
      return false;
  }


  /**
   * returns store the selected PRs
   * @returns {Array}
   */
  function getSelectedRequisitions() {
    var selectedPRs = [];
    _.forEach($scope.dataList, function (pr) {
      if (!!pr.selected) {
        selectedPRs.push(pr);
      }
    });
    return selectedPRs;
  }

  function starringPR(prId) {
    var isStarred = checkStarred(prId);
    globalFunc.starFavourites(isStarred, prId, 'PR');
  }

  function deletePR(prID) {
    swal({
        title: 'Confirm delete this Draft Purchase Requisition?',
        text: 'This Draft Purchase Requisition will be deleted',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (isConfirm) {
          purchaseRequisitionsV2Services.deleteSinglePR(
            {
              requisition_id: prID
            },
            function () {
              toastr.success('Draft Purchase Requisition deleted');
              $state.reload();
            }, function () {
              toastr.error('This Draft PR cannot be deleted.');
            }
          );
        }
      });
  }

  function checkStarred(prId) {
    if (!!$rootScope.currentUser.favourites && !!$rootScope.currentUser.favourites.PR) {
      var userFavourites = $rootScope.currentUser.favourites.PR;
      return userFavourites.indexOf(prId) > -1;
    }
      return false;
  }

  /**
   *  auto select/deselect the items for approval action
   * @param status
   */
  function multiSelectPendingItems (status) {
    // Multi select to close PR
    if ($scope.status === 'approved') {
      _.forEach($scope.dataList, function (requisition) {
        if ($scope.currentUser._id === requisition.creator_info._id && requisition.consolidation_status.length > 0 && getPOConsolidationStatusName(requisition.consolidation_status[0].status) == 'Open') {
          requisition.selected = status;
          selectedPr(requisition, status);
        }
      })
    }

    _.forEach($scope.dataList, function (requisition) {
      if (checkWaitingOnUser(requisition)) {
        requisition.selected = status;
        tickCheckBox(requisition);
      }
    });
  }

  function applyClickedPRs(){
    var list = metabuyerCache.get('PRClicked');
    _.forEach(list, function(singleId){
      var pr = globalFunc.findInArray($scope.dataList, '_id', singleId);
      if(!!pr){
        pr.selected = true;
      }
    });
  }

  function getPoId(requisition) {
    var po_id;
    if (Array.isArray(requisition.pr_items[0].po_id)) {
      po_id = requisition.pr_items[0].po_id[0];
    }
    else {
      po_id = requisition.pr_items[0].po_id;
    }
    return po_id;
  }

  function disableDeletePr(pr) {
    return !!pr.document_rule && pr.document_rule.allow_delete === false;
  }

  function prHasCmmsIntegration(pr) {
    return !!pr.integration_type && pr.integration_type === 'MB-CMMS-ESKER';
  }

  $scope.disableDeletePr              = disableDeletePr;
  $scope.deletePR                     = deletePR;
  $scope.showColumn                   = showColumn;
  $scope.tabSelection                 = tabSelection;
  $scope.starringPR                   = starringPR;
  $scope.checkStarred                 = checkStarred;
  $scope.checkWaitingOnUser           = checkWaitingOnUser;
  $scope.getSelectedRequisitions      = getSelectedRequisitions;
  $scope.multiSelectPendingItems      = multiSelectPendingItems;
  $scope.showDetails                  = showDetails;
  $scope.setShowDetailsId             = setShowDetailsId;
  $scope.isShoppingMode               = isShoppingMode;
  $scope.getEditingPRState            = getEditingPRState;
  $scope.tickCheckBox                 = tickCheckBox;
  $scope.getPOConsolidationStatusName = getPOConsolidationStatusName;
  $scope.selectedPr                   = selectedPr;
  $scope.massClosePR                  = massClosePR;
  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.status = status;
  $scope.getPoId = getPoId;
  $scope.loadingPRMainParams = {
    module: 'PR',
    offset: 20,
    'criteria[status]': $scope.status,
    page: null
  };
  /**
   * to be used in the HTML validation
   */
  $scope.currentUser                  = $rootScope.currentUser;
  $scope.storageKey                   = 'purchase-requisitions-v2-management-selected-columns';
  $scope.selectedDetail               = '';
  $scope.PR                           = [];

  function initialize(){
    $scope.selected = false;
    $scope.status = $stateParams.status;
    $scope.$parent.status = $stateParams.status;

    if($stateParams.type === 'date'){
      var ms = Number($stateParams.query[1]*1000);
      $scope.title = $filter('date')(new Date(ms), 'dd MMM yyyy');
    }
    else{
      $scope.title = $stateParams.query;
    }

    $scope.selectedListTab = $stateParams.tab;
    if(!$scope.selectedListTab){
      $scope.selectedListTab = 'all';
    }
    /**
     * Id highlight
     */
    $scope.highlightId = HighlightId.getId();
    if(!!$scope.highlightId) {
      //scroll the window to top
      $window.scroll(0, 0);
      //clear current id
      HighlightId.setId(null);
    }

    applyClickedPRs();
  }

  function selectedPr(selectedPr, selected) {
    $scope.processPr = true;
    if (selected === false) {
      _.remove($scope.selectedPrs, function(e){
        return e._id === selectedPr._id;
      });
    } else {
      $scope.selectedPrs.push(selectedPr);
    }
  }

  function selectAllCurrentPage(status) {
    $scope.dataList.forEach(function (singleData) {
      if (status) {
        singleData.selected = true;
        $scope.processPr = true;

        var inArray = _.findIndex($scope.selectedPrs, function(e){
          return e._id === singleData._id;
        });

        // Only push if not in array
        if (inArray === -1) {
          $scope.selectedPrs.push(singleData);
        }
      } else {
        singleData.selected = false;
        $scope.processPr = false;
        _.remove($scope.selectedPrs, function(e){
          return e._id === singleData._id;
        });
      }
    })
  }

  function massClosePR() {
    if ($scope.selectedPrs.length === 0) {
      toastr.error("Choose PR to close before proceeding");
    } else {
      var ids = [];
      var prNos = '';
      _.forEach($scope.selectedPrs, function (pr) {
        ids.push(pr._id);
        prNos += '<b>' + pr.header_info.custom_reference + '</b><br>';
      });

      swal({
          html: true,
          title: 'Mass Close Pending Processed PR',
          text: 'Would you like to mass close Pending Processed PR? <br>' + prNos,
          type: 'input',
          showCancelButton: true,
          confirmButtonColor: '#1ab394',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true,
          closeOnCancel: true,
          inputType: 'text',
          inputPlaceholder: 'Write a reason'
        },
        function (inputValue) {
          if (inputValue === false) {
            return false;
          }

          if (inputValue === "") {
            toastr.error('You need to write a reason for closing');
            return false;
          }

          if (!!inputValue) {
            var trimmedValue = inputValue.trim();
          }

          if (trimmedValue === '') {
            toastr.error('You need to write a reason for closing');
            return false
          }
          massUpdatePrstatus.put(
            {},
            {
              status: 'CLOSED',
              ids: ids,
              comment: inputValue
            },
            function (response) {
              toastr.success(response.content.message);
              $state.reload();
            }, function (error) {
              globalFunc.objectErrorMessage(error);
            });
        }
      );
    }
  }

  function getPOConsolidationStatusName(status) {
    var open = [
      "ACTIVE",
      "EDITING",
      "PARTIALLY_UTILIZED"
    ];

    var closed = [
      "CLOSED",
      "FULLY_UTILIZED"
    ];

    var expired = ["EXPIRED"];

    if(open.indexOf(status) > -1) {
      return 'Open';
    }
    if(closed.indexOf(status) > -1) {
      return 'Closed';
    }
    if(expired.indexOf(status) > -1) {
      return 'Expired';
    }
  }

  initialize();

}

requisitionsV2ManageCtrl.$inject = [
  '$scope', 'dataList', 'status', 'purchaseRequisitionsV2Services', '$rootScope', 'globalFunc', '$state', '$stateParams',
  'HighlightId', '$window', '$filter', 'toastr', 'prV2Function', 'Approvals', '$q', 'metabuyerCache', 'massUpdatePrstatus'
];

angular
  .module('metabuyer')
  .controller('requisitionsV2ManageCtrl', requisitionsV2ManageCtrl);
