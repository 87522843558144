'use strict';

function adtafCtrl($scope, addAdtaf) {

  $scope.data = 'this should be optional';
  $scope.submitAdtaf = submitAdtaf;
  $scope.reasonUpdated = reasonUpdated;
  $scope.showTransfereeCostCenter = showTransfereeCostCenter;
  $scope.othersError = othersError;
  $scope.modeOfDisposalUpdated = modeOfDisposalUpdated;
  $scope.formSubmitted = false;
  $scope.reasonsOfDisposal = [
    {
      value: 'Poor Condition / Beyond repair',
      descr: 'Poor Condition / Beyond repair'
    },
    {
      value: 'Not economical to repair',
      descr: 'Not economical to repair'
    },
    {
      value: 'Not sighted / Missing / Stolen',
      descr: 'Not sighted / Missing / Stolen'
    },
    {
      value: 'Obsolete / High maintenance cost',
      descr: 'Obsolete / High maintenance cost'
    },
    {
      value: 'Intercompany transfer (Please specify)',
      descr: 'Intercompany transfer (Please specify)'
    },
    {
      value: 'Others (Please specify)',
      descr: 'Others (Please specify)'
    }
  ]
  $scope.modeOfDisposals = [
    {
      value: 'Written Off',
      descr: 'Written Off'
    },
    {
      value: 'Disposal With Proceed',
      descr: 'Disposal With Proceed'
    },
    {
      value: 'Transfer',
      descr: 'Transfer'
    }
  ];

  function reasonUpdated() {
    delete $scope.adtafObject.reason_explanation;
  }

  function modeOfDisposalUpdated() {
    delete $scope.adtafObject.transfer_cost;
    delete $scope.adtafObject.transfer_quantity;
    delete $scope.adtafObject.transferee_cost_centre;
    delete $scope.adtafObject.transferee_company_code;
    delete $scope.adtafObject.estimate_sales;
  }

  function othersError(){
    if (!!$scope.adtafObject.reason && ($scope.adtafObject.reason.descr === 'Intercompany transfer (Please specify)' || $scope.adtafObject.reason.descr === 'Others (Please specify)')) {
      if (_.isEmpty($scope.adtafObject.reason_explanation)) {
        return true;
      }
    }
    return false;
  }

  function showTransfereeCostCenter() {
    return $scope.adtafObject.mode_of_disposal.value === 'Transfer';
  }

  function validate() {
    if (!$scope.adtafObject.mode_of_disposal){
      toastr.error(lang.validation.required.input + 'mode of disposal');
      return true;
    }
    if (!$scope.adtafObject.reason){
      toastr.error(lang.validation.required.input + 'reason for disposal');
      return true;
    }
    return false;
  }

  function submitAdtaf() {
    if (!validate()){
      $scope.formSubmitted = true;
      var data = {
        main_asset_number: $scope.main_asset_number,
        mode_of_disposal: $scope.mode_of_disposal,
        estimated_sales: $scope.estimated_sales,
        reason_of_disposal: $scope.reason,
        transferee_cost_centre: $scope.transferee_cost_centre,
        remarks: $scope.remarks
      };

      addAdtaf.post(
        {
          requisitionItemId: $scope.adtafObject.item_id,
        },
        data,
        function (response) {
          $scope.formSubmitted = false;
        },
        function (error) {
        }
      );
    }
  }

  function initialize() {
    $scope.adtafObject.mode_of_disposal = $scope.modeOfDisposals[0];
    $scope.adtafObject.net_book_value = 10000;
    $scope.adtafObject.date_of_purchase = new Date();
  }

    // this watcher makes sure there is only 2 decimal places for the field
    $scope.$watch('adtafObject.transfer_quantity', function (newValue, oldValue) {
      if (!!newValue && !(/^\d*(\.\d{0,2})?$/.test(newValue))){
        $scope.adtafObject.transfer_quantity = oldValue;
      }
    });

    $scope.$watch('adtafObject.transfer_cost', function (newValue, oldValue) {
      if (!!newValue && !!$scope.prCurrency.allow_decimal && !(/^\d*(\.\d{0,2})?$/.test(newValue))){
        $scope.adtafObject.transfer_cost = oldValue;
      }
    });

  initialize()
}

adtafCtrl.$inject = ['$scope', 'addAdtaf'];

angular
  .module('metabuyer')
  .directive('adtaf', function(loadCacheableData) {
    return {
      restrict: 'EA',
      templateUrl: 'components/purchaseRequisitionV2Components/adtaf/adtafTemplate.html',
      controller: 'adtafCtrl',
      scope: {
        cerfObject: '=?',
        adtafObject: '=?',
        prCurrency: '=?'
      }
    }
  })
  .controller('adtafCtrl', adtafCtrl);
