'use strict';

function supplierFunctions($q, listCompanyContract, globalFunc) {
   var supplierObject;
   var currencyList;
   var paymentTermList;
   var paymentMethodList;
   var taxList;
   var companyContract;
   var supplierObjectBackup;
   var taggedCompanyList;
   var supplierUserList;

  return {
    getPaidUpCategory: function(paidUpValue) {
      var paidUpCategoryDesc;

      if(!!paidUpValue) {
        if ((paidUpValue >= 1) && (paidUpValue <= 50000))
          paidUpCategoryDesc = '(from 1 to 50,000)';
        else if ((paidUpValue > 50000) && (paidUpValue <= 100000))
          paidUpCategoryDesc = '(from 50,001 to 100,000)';
        else if ((paidUpValue > 100000) && (paidUpValue <= 250000))
          paidUpCategoryDesc = '(from 100,001 to 250,000)';
        else if ((paidUpValue > 250000) && (paidUpValue <= 500000))
          paidUpCategoryDesc = '(from 250,001 to 500,000)';
        else if ((paidUpValue > 500000) && (paidUpValue <= 1000000))
          paidUpCategoryDesc = '(from 500,001 to 1,000,000)';
        else if (paidUpValue > 1000000)
          paidUpCategoryDesc = '(above 1,000,001)';
      }

      return paidUpCategoryDesc;
    },
    /**
     * Filter inactive payment code
     * @param data
     * @returns object
     */
    filterInactiveCode: function (data) {
      for (var i=0;data.length>i;i++) {
        if (data[i].is_active === 0) {
          data.splice(i,1);
        }
      }
      return data;
    },
    checkHyphenFirstCharacter: function (string) {
      return (!!string && string.substring(0, 1) === '-');
    },
    /**
     * Check if user has system role: Supplier Manager
     *
     * @param {object} user
     * @returns {boolean}
     */
    checkIfUserHasSupplierManagerRole: function (user) {
      if (!user || !user.role_assignments) {
        return false;
      }

      return globalFunc.findRoleInRoleAssignments(user.role_assignments, 'Supplier Manager');
    },
    getSupplierBusinessNatureList: function () {
      var businessNature = [
        {descr: 'Unspecified Business Nature', shortDescr: 'UNSPECIFIED', value: 0},
        {descr: 'Agent', shortDescr: 'AGENT', value: 1},
        {descr: 'Banking & Financial Services', shortDescr: 'BANKING',  value: 2},
        {descr: 'Consultancy, Advisory & Other Professional Bodies', shortDescr: 'CONSULTANCY',  value: 3},
        {descr: 'Contractor', shortDescr: 'CONTRACTOR',  value: 4},
        {descr: 'Dealer', shortDescr: 'DEALER',  value: 5},
        {descr: 'Distribute', shortDescr: 'DISTRIBUTOR',  value: 6},
        {descr: 'Manufacturer', shortDescr: 'MANUFACTURER',  value: 7},
        {descr: 'Marketing', shortDescr: 'MARKETING',  value: 8},
        {descr: 'Retailer', shortDescr: 'RETAILER',  value: 9},
        {descr: 'Service Provider', shortDescr: 'SERVICE_PROVIDER',  value: 10},
        {descr: 'Sub-Contractor', shortDescr: 'SUB_CONTRACTOR',  value: 11},
        {descr: 'Trading', shortDescr: 'TRADING',  value: 12},
        {descr: 'Other', shortDescr: 'OTHERS',  value: 13}
      ];

      return businessNature;
    },
    getSupplierBusinessEntityList: function () {
      var entity = [
        {descr: 'Unspecified Business Entity', shortDescr: 'UNSPECIFIED', value: 0},
        {descr: 'Berhad (Public Limited)', shortDescr: 'BERHAD',  value: 1},
        {descr: 'Sendirian Berhad (Private Limited)', shortDescr: 'SENDIRIAN_BERHAD',  value: 2},
        {descr: 'Sole Proprietor & Enterprise', shortDescr: 'SOLE', value: 3},
        {descr: 'Partnership', shortDescr: 'PARTNERSHIP', value: 4}
      ];

      return entity;
    },
    setSupplier: function (supplier) {
      supplierObject = supplier;
      this.forceNumbersDataInSupplier();
    },
    getSupplier: function () {
      return supplierObject;
    },
    getSupplierBackup: function () {
      return supplierObjectBackup;
    },
    setCurrencies: function (currencies) {
      currencyList = currencies;
    },
    getCurrencies: function () {
      return currencyList;
    },
    setPaymentTerms: function (paymentTerms) {
      paymentTermList = paymentTerms;
    },
    getPaymentTerms: function () {
      return paymentTermList;
    },
    setPaymentMethods: function (paymentMethods) {
      paymentMethodList = paymentMethods;
    },
    getPaymentMethods: function () {
      return paymentMethodList;
    },
    setTaxes: function (taxes) {
      taxList = taxes;
    },
    getTaxes: function () {
      return taxList;
    },
    setTaggedCompanies: function (companies) {
      taggedCompanyList = companies;
    },
    getTaggedCompanies: function () {
      return taggedCompanyList;
    },
    setSelectedCompanyContract: function (selectedCompanies) {
      companyContract = selectedCompanies;
    },
    getSelectedCompanyContract: function () {
      return companyContract;
    },
    getCompanyContracts: function (requestData) {
      var deferred = $q.defer();

      var params = {
        id: requestData.id,
        page: requestData.page,
        order_by: requestData.order_by,
        order:requestData.order,
        disable_paginate:requestData.disable_paginate,
        'search-term' : requestData['search-term']
      };

      listCompanyContract.get(
        params,
        function (resource) {
          deferred.resolve(resource);
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
          deferred.resolve([]);
        }
      );
      return deferred.promise;
    },
    replaceSupplierEditedFields: function () {
      if (!!supplierObject.edit_merged) {
        // for status field, use the original status
        var editMergedBasicInfo = _.cloneDeep(supplierObject.edit_merged.basic_info);
        editMergedBasicInfo.status = supplierObject.basic_info.status;

        supplierObject.basic_info = editMergedBasicInfo;
        supplierObject.default_contract = supplierObject.edit_merged.default_contract;
        supplierObject.submission_info = supplierObject.edit_merged.submission_info;
        supplierObject.approval_related_users = supplierObject.edit_merged.approval_related_users;
        supplierObject.supplier_users = supplierObject.edit_merged.supplier_users;
      }
    },
    forceNumbersDataInSupplier: function () {
      !!supplierObject.basic_info.paid_up_capital ? supplierObject.basic_info.paid_up_capital =
        Number(supplierObject.basic_info.paid_up_capital) : '';
      !!supplierObject.default_contract.bumi_status ? supplierObject.default_contract.bumi_status =
        Number(supplierObject.default_contract.bumi_status) : '';
    },
    setSupplierBackup: function (obj) {
      supplierObjectBackup = _.cloneDeep(obj);
    },
    resetSupplierObj: function () {
      if (!!supplierObjectBackup)
        supplierObject = _.cloneDeep(supplierObjectBackup);
    },
    getApprovalId: function (supplier) {
      // if status is ACTIVE_PENDING, approval id should be from 'edit.personal', 'edit.company_contracts' or 'edit.status'
      if (supplier.basic_info.status === 'ACTIVE_PENDING') {
        if (this.pendingEditPersonal(supplier)) {
          return supplier.edit.personal.approval_id;
        } else if (this.pendingEditStatus(supplier)) {
          return supplier.edit.status.approval_id;
        } else if (this.pendingEditCompanyContract(supplier)) {
          // hardcoded key to [0]. Currently only 1 company_contract edit can exist at a time
          return supplier.edit.company_contracts[0].approval_id;
        }

        // if status is PENDING, approval id is from either 'edit.status' or top level in supplier object
        // OR is unblacklist/reactivate
      } else if (supplier.basic_info.status === 'PENDING') {
        if (this.pendingEditStatus(supplier)) {
          return supplier.edit.status.approval_id
        } else if (this.pendingEditPersonal(supplier)) {
          return supplier.edit.personal.approval_id;
        } else {
          return supplier.approval_id
        }
      }
    },
    pendingEditStatus: function (supplier) {
      return !!supplier.edit && !!supplier.edit.status;
    },
    pendingEditPersonal: function (supplier) {
      return !!supplier.edit && !!supplier.edit.personal;
    },
    pendingEditCompanyContract: function (supplier) {
      return !!supplier.edit && !!supplier.edit.company_contracts;
    },
    getSupplierTypes: function(keepP20Sourcing, user) {
      var supplierTypes = [
        {descr: 'P2O Supplier', isP2OSupplier: true, isSourcingSupplier: false},
        {descr: 'Sourcing Supplier',  isP2OSupplier: false, isSourcingSupplier: true},
        {descr: 'P2O Supplier + Sourcing Supplier',  isP2OSupplier: true, isSourcingSupplier: true},
      ];
      keepP20Sourcing = keepP20Sourcing !== undefined ? keepP20Sourcing : true;
      supplierTypes = !!keepP20Sourcing ? supplierTypes : [supplierTypes[0], supplierTypes[1]];
      if (user !== undefined) {
        var isSupplierManager = !!globalFunc.findRoleInRoleAssignments(user.role_assignments, 'Supplier Manager');
        var isSupplierSupplierApprover = !!globalFunc.findRoleInRoleAssignments(user.role_assignments, 'Sourcing Supplier Approver');
        var isSupplierMetabuyerAdmin = !!globalFunc.findRoleInRoleAssignments(user.role_assignments, 'Sourcing Metabuyer Admin');
        var isSupplierTenantAdmin = !!globalFunc.findRoleInRoleAssignments(user.role_assignments, 'Sourcing Tenant Admin');
        var isSupplierBiddingAdmin = !!globalFunc.findRoleInRoleAssignments(user.role_assignments, 'Sourcing and Bidding Admin');
        if (isSupplierManager || isSupplierSupplierApprover || isSupplierMetabuyerAdmin ||isSupplierTenantAdmin || isSupplierBiddingAdmin) {
          // GP;
          return supplierTypes;
        }
        var isSupplierReqeuster = !!globalFunc.findRoleInRoleAssignments(user.role_assignments, 'Supplier Requester');
        var isSupplierApprover = !!globalFunc.findRoleInRoleAssignments(user.role_assignments, 'Supplier Approver');
        if (isSupplierReqeuster || isSupplierApprover) {
          // Sunway University
          return !!keepP20Sourcing ? [supplierTypes[0], supplierTypes[2]] : [supplierTypes[0]];
        }
        var isSourcingRequestor = !!globalFunc.findRoleInRoleAssignments(user.role_assignments, 'Sourcing Requestor');
        var isBiddingRequestor = !!globalFunc.findRoleInRoleAssignments(user.role_assignments, 'Bidding Requestor');
        if (isSourcingRequestor || isBiddingRequestor) {
          // esourcing
          return [supplierTypes[1]];
        }
      }
      return supplierTypes;
    },
    findSupplierType: function(basicInfo) {
      var supplierTypes = this.getSupplierTypes();
      return _.find(
        supplierTypes,
        {
          'isP2OSupplier': basicInfo.is_p2o_supplier,
          'isSourcingSupplier': typeof basicInfo.is_sourcing_supplier === 'boolean' ? basicInfo.is_sourcing_supplier : (basicInfo.is_sourcing_supplier === '0' ? false : true)
      });
    },
    getMandatoryValidationRules: function(type) {
      var rules = {
        'P2O Supplier': {
          'gst_code': true,
          'state': true,
          'business_entity': true,
          'language' : false,
          'currency_code': true,
          'primary_commodity_code': true,
          'bumi_status': true,
          'primary_business_nature': true,
          'payment_term_code': true,
          'payment_method_code': true,
          'receiving_mode': true,
          'tax_code': true,
          'contacts': true,
        },
        'Sourcing Supplier': {
          'gst_code': true,
          'state': true,
          'business_entity': true,
          'language' : false,
          'currency_code': true,
          'primary_commodity_code': true,
          'bumi_status': false,
          'primary_business_nature': true,
          'payment_term_code': false,
          'payment_method_code': false,
          'receiving_mode': false,
          'tax_code': false,
          'contacts': false,
        },
        'P2O Supplier + Sourcing Supplier': {
          'gst_code': true,
          'state': true,
          'business_entity': true,
          'language' : false,
          'currency_code': true,
          'primary_commodity_code': true,
          'bumi_status': true,
          'primary_business_nature': true,
          'payment_term_code': true,
          'payment_method_code': true,
          'receiving_mode': true,
          'tax_code': true,
          'contacts': true,
        },
        'v2Enabled': {
          'gst_code': false,
          'state': false,
          'business_entity': false,
          'language' : false,
          'currency_code': false,
          'primary_commodity_code': false,
          'bumi_status': false,
          'primary_business_nature': false,
          'payment_term_code': true,
          'payment_method_code': false,
          'receiving_mode': false,
          'tax_code': false,
          'contacts': false,
        }
      }

      return rules[type];
    },
    getLanguages: function() {
      return [
        {'descr': 'English', value: 'en'},
        {'descr': 'Chinese', value: 'zh'}
      ]
    },
    findLanguage: function(language) {
      var languages = this.getLanguages();
      return _.find(languages, { 'value' : language})
    },
    setSupplierUser: function(supplierUser, index){
      if (supplierUserList === undefined) {
        supplierUserList = {};
      }
      supplierUserList[index] = supplierUser;
    },
    getSupplierUser: function(){
      return supplierUserList;
    },
  }
}

supplierFunctions.$inject = ['$q', 'listCompanyContract', 'globalFunc'];


angular
  .module('metabuyer')
  .factory('supplierFunctions', supplierFunctions);
