'use strict';

angular.module('metabuyer.services.approvalEngine.approvalTemplateHistory', ['ngResource'])
  .factory('approvalTemplateHistory', ['$resource', 'pathConstants',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.approvalEngine.approvalTemplateHistory.get,
        {
          limit: '@limit',
          page: '@page',
          sort: '@sort',
          includes: '@includes',
          filter_groups: '@filter_groups'
        },
        {
          'get': {method: 'GET'},
          'post': {method: 'POST'},
          'put': {method: 'PUT'}
        }
      );
    }])
  .factory('approvalTemplateHistorySingle', ['$resource', 'pathConstants',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.approvalEngine.approvalTemplateHistory.single,
        {
          id: '@id'
        },
        {
          'get': {method: 'GET'},
          'put': {method: 'PUT'},
          'delete': {method: 'DELETE'}
        }
      );
    }])
  .factory('approvalTemplateInfo', ['$resource', 'pathConstants',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.approvalEngine.approvalTemplate.info,
        {},
        {
          'get': {method: 'GET'}
        }
      );
    }])

