'use strict';

/**
 *
 * @param $scope
 * @param items
 * @param globalFunc
 * @param $filter
 * @param prV2Function
 * @param pathConstants
 * @param $http
 */

function priceComparisonActionsCtrl($scope, globalFunc, $filter, prV2Function, pathConstants, $http, priceComparisonResource, submitPriceComparison, toastr, lang, $state, $rootScope, calculateComparison, priceComparisonFunction, $uibModal, HighlightId, loadCacheableData, quotationAttachments) {
  $scope.addPriceComparison = addPriceComparison;
  $scope.savePriceComparison = savePriceComparison;
  $scope.submit = submit;
  $scope.deletePriceComparison = deletePriceComparison;
  $scope.goToPr = goToPr;
  $scope.back = back;
  $scope.canSubmit = canSubmit;
  $scope.canSave = canSave;
  $scope.canDelete = canDelete;
  $scope.canInsertComparison = canInsertComparison;
  $scope.canApprove = canApprove;
  $scope.canReject = canReject;
  $scope.canWithdraw = canWithdraw;
  $scope.canViewPr = canViewPr;
  $scope.PoRequesterCanViewPR = PoRequesterCanViewPR;
  $scope.PrPoViewerViewPR = PrPoViewerViewPR;
  $scope.PrApproverCanViewPR = PrApproverCanViewPR;
  $scope.approvalAction = approvalAction;
  $scope.onQuotationSelect = onQuotationSelect;
  $scope.searchQuotations = searchQuotations;
  $scope.quotationSearching = false;
  $rootScope.isPcCreatorRole = isPcCreator();
  $scope.refreshPCDetail = refreshPCDetail;
  $scope.confirmWithdrawPC = confirmWithdrawPC;
  $scope.withdrawPCExtraParams = {};
  $scope.rejectPCExtraParams = {};
  $rootScope.checkIfUserCanCreatePC = checkIfUserCanCreatePC();
  $rootScope.isCreatedPC = isCreatedPC();
  $rootScope.isTenantSuperAdminOrAdmin = isTenantSuperAdminOrAdmin();
  $scope.canResubmit = canResubmit;
  $scope.openSearchQuotationModal = openSearchQuotationModal;
  $scope.canOpenSearchQuotationModal = canOpenSearchQuotationModal;
  $scope.printViaPDF = printViaPDF;

  function printViaPDF() {
    /**
     * Pass current logged in user's timezone
     */
    priceComparisonResource.downloadPdf({
      id: $scope.priceComparison._id,
    }, function(resource) {
      var newUrl = $filter('format')(pathConstants.apiUrls.globalPDFHashDownload, {
        pdf_hash: resource.url
      });

      setTimeout(function() {
        window.open(newUrl, '_blank')
      }, 1001);
    }, function() {
      toastr.error('Failed to print price comparison');
    });

  }

  function openSearchQuotationModal() {
    $uibModal.open({
      templateUrl: 'components/priceComparisonComponents/searchQuotationModal/priceComparisonSearchQuotationModal.html',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'revision-wrapper',
      size: 'lg',
      scope: $scope,
      resolve: {
        pc: function() {
          return preparePriceComparison();
        },
        pr: function() {
          return {};
        },
        mode: function() {
          return 'pc';
        },
      },
      controller: 'priceComparisonSearchQuotationModal'
    });
  }

  function canOpenSearchQuotationModal() {
    if (($scope.priceComparison.status === 'DRAFT' && isPcCreator() && checkIfUserCanCreatePC() && isCreatedPC())) {
      return true;
    }

    return false;
  }

  function confirmWithdrawPC() {
    return priceComparisonResource.withdrawn({}, {
        approval_id: $scope.priceComparison.approval_id,
        comment: $scope.withdrawPCExtraParams.comment
      },
      function() {
        toastr.success('The price comparison has been withdrawn.');
        $state.go('main.user.priceComparison.manage', { status: 'withdrawn' });
        HighlightId.setId($scope.priceComparison._id);
      },
      function(error) {
        globalFunc.objectErrorMessage(error);
      }
    ).$promise;
  }

  function getTaxData() {
    loadCacheableData.loadData({
      module: 'tax',
      'criteria[is_active]': 1,
      offset: 0
    }, 'taxes', 'localStorage').then(function(data) {
      $scope.taxesList = data;
    });
  }

  function onQuotationSelect(quotation) {
    //to only allow maximum 5 quotations for each PC
    if ($scope.priceComparison.item_comparisons[4] !== undefined) {
      toastr.error('Only maximum 5 quotations is allowed');
      return;
    }

    // default tax for quotation that dont have taxes for other charges
    var defaultTax = globalFunc.findInArray($scope.taxesList, 'code', 'B0');

    var cloneData = _.cloneDeep(basePriceComparisonObject);

    $scope.quotationSearching = true;

    // get company
    var company = $scope.priceComparison.pr_info.company;
    // get billing company
    var billingCompany = $scope.priceComparison.pr_info.shadow_company;

    var moduleParam = {
      module: 'suppliers'
    };

    var additionalParams = {
      'criteria[0][string_id]': quotation.supplier_id,
      'criteria_operator': 'and',
      offset: 100,
    };

    var param = {
      requisition_id: quotation.pr_id
    };

    // supplier
    $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams,
      ignoreLoadingBar: true
    }).then(function(response) {

      var contract_param = {
        id: response.data.content.data[0]._id,
        code: company.code
      };

      var billing_company_contract_param = {
        id: response.data.content.data[0]._id,
        code: billingCompany.code
      };

      // company contract
      $http.get($filter('format')(pathConstants.apiUrls.supplier.singleCompanyContract, contract_param)).then(function(response_3) {

        if (quotation.supplier_id === null) {

          if (quotation.supplier === undefined) {
            cloneData.supplier = {
              _id: null,
              basic_info: {
                descr: quotation.supplier_descr
              }
            }
          } else {
            cloneData.supplier = quotation.supplier;
          }

        } else {
          cloneData.supplier = response.data.content.data[0];
        }

        cloneData.supplier.company_contract = {};

        if (response_3.data.content.data.status) {
          cloneData.supplier.company_contract = response_3.data.content.data;
        }

        cloneData.supplier.billing_company_contract = {};

        // billing company contract
        $http.get($filter('format')(pathConstants.apiUrls.supplier.singleCompanyContract, billing_company_contract_param)).then(function(response_4) {

          if (response_4.data.content.data.status) {
            cloneData.supplier.billing_company_contract = response_4.data.content.data;
          }
        }, function() {
          toastr.error('Failed to get billing company contract');
        });

        quotationAttachments.get({ quotation_id: quotation._id }).$promise.then(
          function(resource) {
            if (!!resource && !!resource.content && !!resource.content.data) {
              cloneData.attachments = resource.content.data;
            }
          }
        );

        // pr
        $http.get($filter('format')(pathConstants.apiUrls.purchaseRequisitionsV2.getSinglePR, param)).then(function(response_2) {

          cloneData.price_comparison_currency = quotation.items[0].currency || $scope.pr.currency;

          if (!!quotation.other_details) {
            cloneData.other_details.lead_time = quotation.other_details.lead_time;
            cloneData.other_details.delivery_term = quotation.other_details.delivery_term;
            cloneData.other_details.payment_term = quotation.other_details.payment_term;
            cloneData.other_details.supplier_quotation_number = quotation.other_details.supplier_reference_number ? quotation.other_details.supplier_reference_number : quotation.other_details.supplier_quotation_number;
            cloneData.other_details.validity_from = quotation.other_details.validity_from;
            cloneData.other_details.validity_to = quotation.other_details.validity_to;
          }

          // condition when getting quotation from PR, which dont have delivery term
          // allow user to edit delivery term
          if (cloneData.other_details.delivery_term === null) {
            cloneData.other_details.delivery_term_edit = true;
          }

          cloneData.pr = response_2.data.content.data[0];
          cloneData.quotation = {
            id: quotation._id,
            quotation_number: quotation.quotation_number,
            edit: false
          };

          _.forEach(cloneData.items, function(item) {

            var exist = false;

            _.forEach(response_2.data.content.data.pr_items, function(pr_item, index) {
              if (item.item_name == pr_item.name) {
                item.by_quotation = true;

                if (quotation.items[index] !== undefined && quotation.items[index].insurance) {
                  item.insurance = {
                    total_amount: quotation.items[index].insurance.total_amount != null ?
                      quotation.items[index].insurance.total_amount : quotation.items[index].insurance,
                    total_amount_pr_currency: quotation.items[index].insurance.total_amount_pr_currency != null ?
                      quotation.items[index].insurance.total_amount_pr_currency : quotation.items[index].insurance,
                    tax_amount: quotation.items[index].insurance.tax_amount != null ?
                      quotation.items[index].insurance.tax_amount : 0,
                    tax_amount_pr_currency: quotation.items[index].insurance.tax_amount_pr_currency != null ?
                      quotation.items[index].insurance.tax_amount_pr_currency : 0,
                    tax: quotation.items[index].insurance.tax != null ? quotation.items[index].insurance.tax : defaultTax,
                    // this one to cater other charges group field
                    original_unit_price: quotation.items[index].insurance.original_unit_price != null ?
                      quotation.items[index].insurance.original_unit_price : null,
                    name: quotation.items[index].insurance.name != null ?
                      quotation.items[index].insurance.name : null,
                    taxEdit: quotation.items[index].insurance.taxEdit != null ?
                      quotation.items[index].insurance.taxEdit : null,
                    percentage: quotation.items[index].insurance.percentage != null ?
                      quotation.items[index].insurance.percentage : null,
                    calculation_method: quotation.items[index].insurance.calculation_method != null ?
                      quotation.items[index].insurance.calculation_method : null,
                    currency: quotation.items[index].insurance.currency != null ?
                      quotation.items[index].insurance.currency : null,
                    other_charges_group: quotation.items[index].insurance.other_charges_group != null ?
                      quotation.items[index].insurance.other_charges_group : null,
                  };
                }

                if (quotation.items[index] !== undefined && quotation.items[index].transportation) {
                  item.transportation = {
                    total_amount: quotation.items[index].transportation.total_amount != null ?
                      quotation.items[index].transportation.total_amount : quotation.items[index].transportation,
                    total_amount_pr_currency: quotation.items[index].transportation.total_amount_pr_currency != null ?
                      quotation.items[index].transportation.total_amount_pr_currency : quotation.items[index].transportation,
                    tax_amount: quotation.items[index].transportation.tax_amount != null ?
                      quotation.items[index].transportation.tax_amount : 0,
                    tax_amount_pr_currency: quotation.items[index].transportation.tax_amount_pr_currency != null ?
                      quotation.items[index].transportation.tax_amount_pr_currency : 0,
                    tax: quotation.items[index].transportation.tax != null ? quotation.items[index].transportation.tax : defaultTax,
                    // this one to cater other charges group field
                    original_unit_price: quotation.items[index].transportation.original_unit_price != null ?
                      quotation.items[index].transportation.original_unit_price : null,
                    name: quotation.items[index].transportation.name != null ?
                      quotation.items[index].transportation.name : null,
                    taxEdit: quotation.items[index].transportation.taxEdit != null ?
                      quotation.items[index].transportation.taxEdit : null,
                    percentage: quotation.items[index].transportation.percentage != null ?
                      quotation.items[index].transportation.percentage : null,
                    calculation_method: quotation.items[index].transportation.calculation_method != null ?
                      quotation.items[index].transportation.calculation_method : null,
                    currency: quotation.items[index].transportation.currency != null ?
                      quotation.items[index].transportation.currency : null,
                    other_charges_group: quotation.items[index].transportation.other_charges_group != null ?
                      quotation.items[index].transportation.other_charges_group : null,
                  };
                }

                if (quotation.items[index] !== undefined && quotation.items[index].freight) {
                  item.freight = {
                    total_amount: quotation.items[index].freight.total_amount != null ?
                      quotation.items[index].freight.total_amount : quotation.items[index].freight,
                    total_amount_pr_currency: quotation.items[index].freight.total_amount_pr_currency != null ?
                      quotation.items[index].freight.total_amount_pr_currency : quotation.items[index].freight,
                    tax_amount: quotation.items[index].freight.tax_amount != null ?
                      quotation.items[index].freight.tax_amount : 0,
                    tax_amount_pr_currency: quotation.items[index].freight.tax_amount_pr_currency != null ?
                      quotation.items[index].freight.tax_amount_pr_currency : 0,
                    tax: quotation.items[index].freight.tax != null ? quotation.items[index].freight.tax : defaultTax,
                    // this one to cater other charges group field
                    original_unit_price: quotation.items[index].freight.original_unit_price != null ?
                      quotation.items[index].freight.original_unit_price : null,
                    name: quotation.items[index].freight.name != null ?
                      quotation.items[index].freight.name : null,
                    taxEdit: quotation.items[index].freight.taxEdit != null ?
                      quotation.items[index].freight.taxEdit : null,
                    percentage: quotation.items[index].freight.percentage != null ?
                      quotation.items[index].freight.percentage : null,
                    calculation_method: quotation.items[index].freight.calculation_method != null ?
                      quotation.items[index].freight.calculation_method : null,
                    currency: quotation.items[index].freight.currency != null ?
                      quotation.items[index].freight.currency : null,
                    other_charges_group: quotation.items[index].freight.other_charges_group != null ?
                      quotation.items[index].freight.other_charges_group : null,
                  };
                }

                if (quotation.items[index] !== undefined && quotation.items[index].withholding_tax) {
                  item.withholding_tax = {
                    total_amount: quotation.items[index].withholding_tax.total_amount != null ?
                      quotation.items[index].withholding_tax.total_amount : quotation.items[index].withholding_tax,
                    total_amount_pr_currency: quotation.items[index].withholding_tax.total_amount_pr_currency != null ?
                      quotation.items[index].withholding_tax.total_amount_pr_currency : quotation.items[index].withholding_tax,
                    tax_amount: quotation.items[index].withholding_tax.tax_amount != null ?
                      quotation.items[index].withholding_tax.tax_amount : 0,
                    tax_amount_pr_currency: quotation.items[index].withholding_tax.tax_amount_pr_currency != null ?
                      quotation.items[index].withholding_tax.tax_amount_pr_currency : 0,
                    tax: quotation.items[index].withholding_tax.tax != null ? quotation.items[index].withholding_tax.tax : defaultTax,
                    // this one to cater other charges group field
                    original_unit_price: quotation.items[index].withholding_tax.original_unit_price != null ?
                      quotation.items[index].withholding_tax.original_unit_price : null,
                    name: quotation.items[index].withholding_tax.name != null ?
                      quotation.items[index].withholding_tax.name : null,
                    taxEdit: quotation.items[index].withholding_tax.taxEdit != null ?
                      quotation.items[index].withholding_tax.taxEdit : null,
                    percentage: quotation.items[index].withholding_tax.percentage != null ?
                      quotation.items[index].withholding_tax.percentage : null,
                    calculation_method: quotation.items[index].withholding_tax.calculation_method != null ?
                      quotation.items[index].withholding_tax.calculation_method : null,
                    currency: quotation.items[index].withholding_tax.currency != null ?
                      quotation.items[index].withholding_tax.currency : null,
                    other_charges_group: quotation.items[index].withholding_tax.other_charges_group != null ?
                      quotation.items[index].withholding_tax.other_charges_group : null,
                  };
                }

                if (quotation.items[index] !== undefined && quotation.items[index].bahan_bakar_tax) {
                  item.bahan_bakar_tax = {
                    total_amount: quotation.items[index].bahan_bakar_tax.total_amount != null ?
                      quotation.items[index].bahan_bakar_tax.total_amount : quotation.items[index].bahan_bakar_tax,
                    total_amount_pr_currency: quotation.items[index].bahan_bakar_tax.total_amount_pr_currency != null ?
                      quotation.items[index].bahan_bakar_tax.total_amount_pr_currency : quotation.items[index].bahan_bakar_tax,
                    tax_amount: quotation.items[index].bahan_bakar_tax.tax_amount != null ?
                      quotation.items[index].bahan_bakar_tax.tax_amount : 0,
                    tax_amount_pr_currency: quotation.items[index].bahan_bakar_tax.tax_amount_pr_currency != null ?
                      quotation.items[index].bahan_bakar_tax.tax_amount_pr_currency : 0,
                    tax: quotation.items[index].bahan_bakar_tax.tax != null ? quotation.items[index].bahan_bakar_tax.tax : defaultTax,
                    // this one to cater other charges group field
                    original_unit_price: quotation.items[index].bahan_bakar_tax.original_unit_price != null ?
                      quotation.items[index].bahan_bakar_tax.original_unit_price : null,
                    name: quotation.items[index].bahan_bakar_tax.name != null ?
                      quotation.items[index].bahan_bakar_tax.name : null,
                    taxEdit: quotation.items[index].bahan_bakar_tax.taxEdit != null ?
                      quotation.items[index].bahan_bakar_tax.taxEdit : null,
                    percentage: quotation.items[index].bahan_bakar_tax.percentage != null ?
                      quotation.items[index].bahan_bakar_tax.percentage : null,
                    calculation_method: quotation.items[index].bahan_bakar_tax.calculation_method != null ?
                      quotation.items[index].bahan_bakar_tax.calculation_method : null,
                    currency: quotation.items[index].bahan_bakar_tax.currency != null ?
                      quotation.items[index].bahan_bakar_tax.currency : null,
                    other_charges_group: quotation.items[index].bahan_bakar_tax.other_charges_group != null ?
                      quotation.items[index].bahan_bakar_tax.other_charges_group : null,
                  };
                }

                if (quotation.items[index] !== undefined && quotation.items[index].miscellaneous) {
                  item.miscellaneous = {
                    total_amount: quotation.items[index].miscellaneous.total_amount != null ?
                      quotation.items[index].miscellaneous.total_amount : quotation.items[index].miscellaneous,
                    total_amount_pr_currency: quotation.items[index].miscellaneous.total_amount_pr_currency != null ?
                      quotation.items[index].miscellaneous.total_amount_pr_currency : quotation.items[index].miscellaneous,
                    tax_amount: quotation.items[index].miscellaneous.tax_amount != null ?
                      quotation.items[index].miscellaneous.tax_amount : 0,
                    tax_amount_pr_currency: quotation.items[index].miscellaneous.tax_amount_pr_currency != null ?
                      quotation.items[index].miscellaneous.tax_amount_pr_currency : 0,
                    tax: quotation.items[index].miscellaneous.tax != null ? quotation.items[index].miscellaneous.tax : defaultTax,
                    // this one to cater other charges group field
                    original_unit_price: quotation.items[index].miscellaneous.original_unit_price != null ?
                      quotation.items[index].miscellaneous.original_unit_price : null,
                    name: quotation.items[index].miscellaneous.name != null ?
                      quotation.items[index].miscellaneous.name : null,
                    taxEdit: quotation.items[index].miscellaneous.taxEdit != null ?
                      quotation.items[index].miscellaneous.taxEdit : null,
                    percentage: quotation.items[index].miscellaneous.percentage != null ?
                      quotation.items[index].miscellaneous.percentage : null,
                    calculation_method: quotation.items[index].miscellaneous.calculation_method != null ?
                      quotation.items[index].miscellaneous.calculation_method : null,
                    currency: quotation.items[index].miscellaneous.currency != null ?
                      quotation.items[index].miscellaneous.currency : null,
                    other_charges_group: quotation.items[index].miscellaneous.other_charges_group != null ?
                      quotation.items[index].miscellaneous.other_charges_group : null,
                  };
                }

                _.forEach(quotation.items, function(q_item) {
                  if (q_item.item_id == pr_item._id && q_item.available_pc_item) {
                    item.tax = q_item.tax || 0;
                    item.unit_price = q_item.unit_price || 0;
                    item.amount = q_item.amount || 0;
                    item.discount = q_item.discount || 0;
                    item.no_quotation_available = q_item.no_quotation_available || false;
                    exist = true;
                    // this one to cater other charges group field
                    if (q_item.other_charges_group) {
                      item.other_charges_group = q_item.other_charges_group;
                    }
                  }
                });
              }
            });

            if (!exist) {
              item.exist = false;
            }
          });

          $scope.priceComparison.item_comparisons.push(cloneData);
          recalculateComparison();
          savePriceComparison();
        });
      });
    });
  }

  function recalculateComparison() {
    var itemData = {
      'item_comparisons': $scope.priceComparison.item_comparisons,
      'pr_info': $scope.priceComparison.pr_info
    };

    calculateComparison.post(
      itemData,
      function(resource) {
        if (!!resource.data) {
          var data = resource.data;
          $scope.priceComparison.item_comparisons = data.item_comparisons;
          $scope.priceComparison.total_amount_before_tax = data.total_amount_before_tax;
          $scope.priceComparison.total_tax_amount = data.total_tax_amount;
          $scope.priceComparison.grand_total = data.grand_total;
        }
      },
      function(error) {
        // handle this
        globalFunc.objectErrorMessage(error)
      }
    )
  }

  function searchQuotations(val) {
    if (!!val && val.length > 2) {
      $scope.quotationSearching = true;

      var moduleParam = {
        module: 'quotation'
      };

      var additionalParams = {
        'criteria[0][quotation_number]': val,
        'criteria[1][type]': 'NEW',
        'criteria_operator': 'and',
        offset: 100,
        'company_contract': true
      };

      return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: additionalParams,
        ignoreLoadingBar: true
      }).then(function(response) {
        if (response.data.content.data.length === 0) {
          $scope.quotationSearching = false;
          return [];
        } else {
          $scope.quotationSearching = false;

          var selectedQuotations = [];
          var prItems = [];

          _.forEach($scope.priceComparison.pr_info.items, function(item) {
            prItems.push(item.item_code);
          });

          var modifiedQuotation = _.filter(response.data.content.data, function(quotation) {
            _.forEach(quotation.items, function(item) {

              if (prItems.includes(item.item_code)) {
                selectedQuotations.push(quotation);
              }
            });

            return selectedQuotations;
          });

          var uniqueQuotations = removeDuplicates(selectedQuotations, '_id');
          return uniqueQuotations;
        }
      });
    }
  }

  // this function is to filter out duplicated quotations when searching the quotation
  function removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  function isTenantSuperAdminOrAdmin() {
    if ((!!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN')) ||
      (!!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'ADMIN'))) {
      return true;
    } else {
      return false;
    }
  }

  function canViewPr() {
    return isPrRequestor();
  }

  function PoRequesterCanViewPR() {
    return isPoRequester();
  }

  function PrPoViewerViewPR() {
    return isPrPoViewer();
  }

  function PrApproverCanViewPR() {
    return isPrApprover();
  }

  /**
   * Check if resubmit pc action can be done
   */
  function canResubmit() {
    var checkPcStatus = ($scope.priceComparison.status.toLowerCase() === 'withdrawn' ||
      $scope.priceComparison.status.toLowerCase() === 'rejected');
    var prStatus = $scope.pr.status;

    if (checkPcStatus && isPcCreator() && isCreatedPC() && prStatus === 'pending') {
      return true;
    } else {
      return false;
    }
  }

  function canWithdraw() {
    var checkPcStatus = $scope.priceComparison.status === 'PENDING';
    if (checkPcStatus && isPcCreator() && isCreatedPC()) {
      return true;
    } else {
      return false;
    }
  }

  function canReject() {
    var checkPcStatus = $scope.priceComparison.status === 'PENDING';
    var isApprover = globalFunc.findInArray($scope.waiting_on_user, '_id', $rootScope.currentUser._id);
    if ((checkPcStatus && !!isApprover)) {
      return true;
    } else {
      return false;
    }
  }

  function canApprove() {
    var checkPcStatus = $scope.priceComparison.status === 'PENDING';
    var isApprover = globalFunc.findInArray($scope.waiting_on_user, '_id', $rootScope.currentUser._id);
    if ((checkPcStatus && !!isApprover)) {
      return true;
    } else {
      return false;
    }
  }

  function canSubmit() {
    // this will probably get expanded leul
    if (($scope.priceComparison.status === 'DRAFT' && isPcCreator() && checkIfUserCanCreatePC() && isCreatedPC())) {
      return true;
    } else {
      return false;
    }
  }

  function canSave() {
    // this will probably get expanded leul
    if (($scope.priceComparison.status === 'DRAFT' && isPcCreator() && checkIfUserCanCreatePC() && isCreatedPC())) {
      return true;
    } else {
      return false;
    }
  }

  function canDelete() {
    // this will probably get expanded leul
    if (($scope.priceComparison.status === 'DRAFT' && isPcCreator() && checkIfUserCanCreatePC() && isCreatedPC())) {
      return true;
    } else {
      return false;
    }
  }

  function canInsertComparison() {
    // this will probably get expanded leul
    if (($scope.priceComparison.status === 'DRAFT' && isPcCreator() && checkIfUserCanCreatePC() && isCreatedPC()) || ($scope.priceComparison.status === 'DRAFT' && isTenantSuperAdminOrAdmin())) {
      return true;
    } else {
      return false;
    }
  }

  function isPcCreator() {
    var groupFound = _.find($rootScope.currentUser.role_assignments, function(assignment) {
      if (assignment.organization_id === $scope.priceComparison.pr_info.company._id) {
        var requesterFound = _.find(assignment.roles, function(role) {

          return role.name === 'Price Comparison Requester';
        });
        return !!requesterFound;
      }

    });
    return !!groupFound;
  }

  function isPrRequestor() {
    var groupFound = _.find($rootScope.currentUser.role_assignments, function(assignment) {
      if (assignment.organization_id === $scope.priceComparison.pr_info.company._id) {
        var requesterFound = _.find(assignment.roles, function(role) {
          return role.name === 'PR Requestor';
        });

        return !!requesterFound;
      }
    });

    return !!groupFound;

  }

  function isPoRequester() {
    var groupFound = _.find($rootScope.currentUser.role_assignments, function(assignment) {
      if (assignment.organization_id === $scope.priceComparison.pr_info.company._id) {
        var requesterFound = _.find(assignment.roles, function(role) {

          return role.name === 'PO Requester';
        });

        return !!requesterFound;
      }

    });
    return !!groupFound;
  }

  function isPrPoViewer() {
    var groupFound = _.find($rootScope.currentUser.role_assignments, function(assignment) {
      if (assignment.organization_id === $scope.priceComparison.pr_info.company._id) {
        var requesterFound = _.find(assignment.roles, function(role) {
          return role.name === 'PR-PO Viewer';
        });
        return !!requesterFound;
      }
    });

    return !!groupFound;
  }

  function isPrApprover() {
    var groupFound = _.find($rootScope.currentUser.role_assignments, function(assignment) {
      if (assignment.organization_id === $scope.priceComparison.pr_info.company._id) {
        var requesterFound = _.find(assignment.roles, function(role) {
          return role.name === 'PR Approver';
        });
        return !!requesterFound;
      }
    });

    return !!groupFound;
  }

  function isCreatedPC() {
    if ($scope.priceComparison.creator_info._id === $rootScope.currentUser._id) {
      return true;
    } else {
      return false;
    }
  }

  function checkIfUserCanCreatePC() {
    if (globalFunc.arrayOfObjectToString($scope.pr.approval_related_users, '_id').includes($rootScope.currentUser._id) || $rootScope.currentUser._id === $scope.pr.requestor._id) {
      return true;
    } else {
      return false;
    }
  }

  var otherChargesFields = [
    'insurance',
    'transportation',
    'freight',
    'withholding_tax',
    'bahan_bakar_tax',
    'miscellaneous'
  ];

  var basePriceComparisonObject = {
    items: prepareItemsArray(),
    grand_total: 0,
    grand_total_pr_currency: 0,
    tax_total: 0,
    tax_total_pr_currency: 0,
    total_amount_before_tax: 0,
    total_amount_before_tax_pr_currency: 0,
    requisition_currency: _.cloneDeep($scope.pr.currency),
    price_comparison_currency: _.cloneDeep($scope.pr.currency), // defaults to pr currency
    amount_before_tax_selected: 0,
    amount_before_tax_pr_currency_selected: 0,
    tax_amount_selected: 0,
    tax_amount_pr_currency_selected: 0,
    grand_total_selected: 0,
    grand_total_pr_currency_selected: 0,
    supplier: {},
    other_details: {
      lead_time: null,
      payment_term: null,
      delivery_term: null,
      note_to_supplier: null,
      supplier_quotation_number: null,
      validity_to: null,
      validity_from: null,
      remark: null,
      delivery_term_edit: false,
    },
    unsave: true,
    attachments: {}
  };

  function prepareItemsArray() {
    var items = _.map($scope.pr.pr_items, function(item) {
      var temp = {
        item_id: item._id,
        item_name: item.name,
        item_descr: item.descr,
        unit_price: 0,
        unit_price_pr_currency: 0,
        amount: 0,
        amount_pr_currency: 0,
        discount: 0,
        discount_amount: 0,
        is_percentage: false,
        discount_pr_currency: 0,
        tax: {},
        tax_amount: 0,
        tax_amount_pr_currency: 0,
        amount_before_tax: 0,
        amount_before_tax_pr_currency: 0,
        currency: _.cloneDeep($scope.pr.currency),
        qty: item.qty,
        total_amount: 0,
        total_amount_pr_currency: 0,
        remarks: '',
        product_availability: item.product_availability || 'Yes',
        is_selected: false,
        exist: true,
        by_quotation: false,
        no_quotation_available: false,
        insurance: {
          total_amount: 0,
          total_amount_pr_currency: 0,
          tax_amount: 0,
          tax_amount_pr_currency: 0,
          currency: _.cloneDeep($scope.pr.currency),
        },
        transportation: {
          total_amount: 0,
          total_amount_pr_currency: 0,
          tax_amount: 0,
          tax_amount_pr_currency: 0,
          currency: _.cloneDeep($scope.pr.currency),
        },
        freight: {
          total_amount: 0,
          total_amount_pr_currency: 0,
          tax_amount: 0,
          tax_amount_pr_currency: 0,
          currency: _.cloneDeep($scope.pr.currency),
        },
        withholding_tax: {
          total_amount: 0,
          total_amount_pr_currency: 0,
          tax_amount: 0,
          tax_amount_pr_currency: 0,
          currency: _.cloneDeep($scope.pr.currency),
        },
        bahan_bakar_tax: {
          total_amount: 0,
          total_amount_pr_currency: 0,
          tax_amount: 0,
          tax_amount_pr_currency: 0,
          currency: _.cloneDeep($scope.pr.currency),
        },
        miscellaneous: {
          total_amount: 0,
          total_amount_pr_currency: 0,
          tax_amount: 0,
          tax_amount_pr_currency: 0,
          currency: _.cloneDeep($scope.pr.currency),
        },
        original_item: item
      };

      temp['total_amount_with_other_charges'] = 0;
      temp['total_amount_with_other_charges_pr_currency'] = 0;

      return temp;
    });

    return items;
  }

  function approvalAction(status, text, extraPayload) {
    priceComparisonResource.approve({
        approval_id: $scope.priceComparison.approval_id,
        status: status,
        comment: $scope.rejectPCExtraParams.comment
      },
      extraPayload,
      function() {
        if (!!text)
          toastr.success(text);
        else
          toastr.success('Price Comparison is successfully approved.')
        var param = 'approved';
        if (status === 'reject') {
          param = 'rejected';
        }
        if (param = 'rejected') {
          $state.go('main.user.priceComparison.manage', { status: param });
          HighlightId.setId($scope.priceComparison._id);
        } else {
          $state.go('main.user.priceComparison.manage', { status: param });
        }
      },
      function(error) {
        globalFunc.objectErrorMessage(error);
      }
    ).$promise;
  }

  /**
   * Refresh PC detail page
   */
  function refreshPCDetail() {
    $state.transitionTo($state.current, $stateParams, {
      reload: true,
      inherit: false,
      notify: true
    });
  }

  function savePriceComparison(status) {

    var isReload = false;

    if (status !== undefined) {
      var isReload = true;
    }

    var status = (status === undefined) ? $scope.priceComparison.status : status;

    var data = preparePriceComparison(status);

    priceComparisonResource.put({
        id: $scope.priceComparison._id
      },
      data,
      function(resource) {
        toastr.success('Successfully saved price comparison.');

        if (isReload) {
          $state.reload();
        }

        $scope.$emit('refreshPcApprovalsPreview');
      },
      function(error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function preparePriceComparison(status) {
    var data = _.cloneDeep($scope.priceComparison);
    var status = (status === undefined) ? $scope.priceComparison.status : status;
    data.status = status;

    _.forEach(data.item_comparisons, function(e) {

      if (!!e.unsave) {
        delete e.unsave;
      }

      if (!!e.quotation && !!e.editable && !!e.backup_attachments) {
        e.attachments = e.backup_attachments;
      }

      // move to resubmit draft scenario (to cater for editing existing quotation)
      if (data.status === 'DRAFT') {
        _.forEach(e.items, function(item) {
          // edited exisitng quotation allow to edit after withdrawn
          if (item.editable === false) {
            // reset flags here, to prevent field from being disable
            item.by_quotation = false;
            delete item.editable;
          }
        });
      }

      if (!_.isEmpty(e.other_details)) {
        e.other_details.validity_to = !!e.other_details.validity_to ? globalFunc.convertDateToTimestamp(e.other_details.validity_to) : null;

        e.other_details.validity_from = !!e.other_details.validity_from ? globalFunc.convertDateToTimestamp(e.other_details.validity_from) : null;

        delete e.other_details['validity_from_is_open'];
        delete e.other_details['validity_to_is_open'];


        _.forEach(e.items, function(item) {
          delete item.lowestItemPrice;
          item.insurance.total_amount = parseFloat(item.insurance.total_amount);
          item.transportation.total_amount = parseFloat(item.transportation.total_amount);
          item.freight.total_amount = parseFloat(item.freight.total_amount);
          item.withholding_tax.total_amount = parseFloat(item.withholding_tax.total_amount);
          item.bahan_bakar_tax.total_amount = parseFloat(item.bahan_bakar_tax.total_amount);
          item.miscellaneous.total_amount = parseFloat(item.miscellaneous.total_amount);

          item.insurance.total_amount_pr_currency = parseFloat(item.insurance.total_amount_pr_currency);
          item.transportation.total_amount_pr_currency = parseFloat(item.transportation.total_amount_pr_currency);
          item.freight.total_amount_pr_currency = parseFloat(item.freight.total_amount_pr_currency);
          item.withholding_tax.total_amount_pr_currency = parseFloat(item.withholding_tax.total_amount_pr_currency);
          item.bahan_bakar_tax.total_amount_pr_currency = parseFloat(item.bahan_bakar_tax.total_amount_pr_currency);
          item.miscellaneous.total_amount_pr_currency = parseFloat(item.miscellaneous.total_amount_pr_currency);
        });
      }
    });

    data.pr_info.title = $scope.pr.title;
    return data;
  }

  function addPriceComparison() {
    //to only allow maximum 5 quotations for each PC
    if ($scope.priceComparison.item_comparisons[4] !== undefined) {
      toastr.error('Only maximum 5 quotations is allowed');
      return;
    }
    $scope.priceComparison.item_comparisons.push(_.cloneDeep(basePriceComparisonObject));
  }

  function submit() {
    if (!validateSubmission()) {
      $uibModal.open({
        templateUrl: 'components/priceComparisonComponents/confirmationModal/priceComparisonConfirmationModal.html',
        backdrop: 'static',
        keyboard: false,
        controller: 'priceComparisonConfirmationModalCtrl',
        windowClass: 'consolidation-confirmation-modal',
        scope: $scope,
        resolve: {
          data: function() {
            return preparePriceComparison();
          }
        }
      });
    }
  }

  function deletePriceComparison() {
    swal({
        title: 'Confirm delete PC',
        text: 'This PC will be deleted',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function(isConfirm) {
        if (isConfirm) {
          priceComparisonResource.delete({
              id: $scope.priceComparison._id
            },
            function(resource) {
              toastr.success('Successfully deleted price comparison.');
              $state.go('main.user.priceComparison.manage', { status: 'deleted' });
              HighlightId.setId($scope.priceComparison._id);
            },
            function(error) {
              globalFunc.objectErrorMessage(error);
            }
          );
        }
      });
  }

  function validateSubmission() {
    var selectionMade = 0;
    var selectedRequired = $scope.priceComparison.pr_info.items.length;
    var anyError = false;

    if (_.isEmpty($scope.priceComparison.title)) {
      toastr.error('PC title is required');
      anyError = true;
    }

    _.forEach($scope.priceComparison.item_comparisons, function(comparison, comparisonIndex) {
      if (_.isEmpty(comparison['supplier'])) {
        toastr.error(lang.validation.required.selection + 'supplier for column ' + (comparisonIndex + 1));
        anyError = true;
      }

      if (_.isEmpty(comparison['price_comparison_currency'])) {
        toastr.error(lang.validation.required.selection + 'currency for column ' + (comparisonIndex + 1));
        anyError = true;
      }

      if (!comparison['other_details']['lead_time']) {
        toastr.error(lang.validation.required.input + 'lead time for column ' + (comparisonIndex + 1));
        anyError = true;
      }

      if (!comparison['other_details']['delivery_term']) {
        toastr.error(lang.validation.required.input + 'delivery terms for column ' + (comparisonIndex + 1));
        anyError = true;
      }

      if (_.isEmpty(comparison['other_details']['payment_term'])) {
        toastr.error(lang.validation.required.selection + 'payment term for column ' + (comparisonIndex + 1));
        anyError = true;
      }

      if ((comparison['other_details']['validity_from'] === null) || ((comparison['other_details']['validity_to'] === null))) {
        toastr.error('Please select a Quotation date for column ' + (comparisonIndex + 1));
        anyError = true;
      }

      _.forEach(comparison.items, function(item, itemIndex) {
        if (item.exist === true) {
          if (comparisonIndex == 0 && item['no_quotation_available'] === true && $scope.priceComparison.item_comparisons.length <= 1) {
            toastr.error('Valid quotation is required for line item ' + (itemIndex + 1) + ' column ' + (comparisonIndex + 1));
            anyError = true;
          }

          if (_.isEmpty(item['item_descr'])) {
            toastr.error(lang.validation.required.input + 'item description for item ' + (itemIndex + 1) + ' column ' + (comparisonIndex + 1));
            anyError = true;
          }

          if ((item['unit_price'] === null || item['unit_price'] <= 0) && item['no_quotation_available'] === false) {
            toastr.error(lang.validation.required.input + 'unit price for item ' + (itemIndex + 1) + ' column ' + (comparisonIndex + 1));
            anyError = true;
          }

          if (_.isEmpty(item['tax']) && item['no_quotation_available'] === false) {
            toastr.error(lang.validation.required.selection + 'tax for item ' + (itemIndex + 1) + ' column ' + (comparisonIndex + 1));
            anyError = true;
          }

          if (_.isEmpty(item['product_availability'])) {
            toastr.error(lang.validation.required.selection + 'product availability for item ' + (itemIndex + 1) + ' column ' + (comparisonIndex + 1));
            anyError = true;
          }

          if ((item['bahan_bakar_tax']['total_amount'] !== 0) && (item['bahan_bakar_tax']['tax'] === undefined || _.isEmpty(item['bahan_bakar_tax']['tax']))) {
            toastr.error('Tax required for Bahan Bakar Tax for item ' + (itemIndex + 1) + ' column ' + (comparisonIndex + 1));
            anyError = true;
          }

          if ((item['freight']['total_amount'] !== 0) && (item['freight']['tax'] === undefined || _.isEmpty(item['freight']['tax']))) {
            toastr.error('Tax required for Freight for item ' + (itemIndex + 1) + ' column ' + (comparisonIndex + 1));
            anyError = true;
          }

          if ((item['insurance']['total_amount'] !== 0) && (item['insurance']['tax'] === undefined || _.isEmpty(item['insurance']['tax']))) {
            toastr.error('Tax required for Insurance for item ' + (itemIndex + 1) + ' column ' + (comparisonIndex + 1));
            anyError = true;
          }

          if ((item['miscellaneous']['total_amount'] !== 0) && (item['miscellaneous']['tax'] === undefined || _.isEmpty(item['miscellaneous']['tax']))) {
            toastr.error('Tax required for Miscellaneous for item ' + (itemIndex + 1) + ' column ' + (comparisonIndex + 1));
            anyError = true;
          }

          if ((item['transportation']['total_amount'] !== 0) && (item['transportation']['tax'] === undefined || _.isEmpty(item['transportation']['tax']))) {
            toastr.error('Tax required for Transportation for item ' + (itemIndex + 1) + ' column ' + (comparisonIndex + 1));
            anyError = true;
          }

          if ((item['withholding_tax']['total_amount'] !== 0) && (item['withholding_tax']['tax'] === undefined || _.isEmpty(item['withholding_tax']['tax']))) {
            toastr.error('Tax required for Withholding Tax for item ' + (itemIndex + 1) + ' column ' + (comparisonIndex + 1));
            anyError = true;
          }
        }
      })
    })

    _.forEach($scope.priceComparison.item_comparisons, function(comparison, comparisonIndex) {
      _.forEach(comparison.items, function(item, itemIndex) {
        if (item['is_selected'] === true) {
          selectionMade++;
        }
      })
    })

    if (selectedRequired !== selectionMade) {
      toastr.error(lang.validation.required.selection + 'a comparison for all the line item');
      anyError = true;
    }

    return anyError;
  }

  function goToPr() {
    $state.go('main.user.purchaseRequisitionsV2.details', { id: $scope.pr._id });
  }

  function back() {
    window.history.back();
    HighlightId.setId($scope.priceComparison._id);
  }

  $scope.$on('saveDraftPc', function () {
    savePriceComparison();
  });

  function getTemplateUrl(state) {

    if ($scope.priceComparison.status === 'DRAFT') {
      $scope.priceComparisonTemplateUrl = 'components/priceComparisonComponents/actions/priceComparisonActions.html';
    } else {
      switch (state) {
        case 'editable':
          $scope.priceComparisonTemplateUrl = 'components/priceComparisonComponents/actions/priceComparisonActions.html';
          break;
        case 'notEditable':
          $scope.priceComparisonTemplateUrl = 'components/priceComparisonComponents/actions/priceComparisonActions.nonEditable.html';
          break;
        default:
          $scope.priceComparisonTemplateUrl = 'components/priceComparisonComponents/actions/priceComparisonActions.nonEditable.html';
          break;
      }
    }
  }

  function initialize() {
    getTemplateUrl('notEditable');
    _.forEach($scope.pr.pr_items, function(item) {
      _.forEach(otherChargesFields, function(e) {
        if (!_.isEmpty(item[e])) {
          delete item[e];
        }
      });
    });
  }
  getTaxData();
  initialize();

  $scope.$on('addSearchQuotation', function(event, result) {
    onQuotationSelect(result.quotation)
  });
}

priceComparisonActionsCtrl.$inject = ['$scope', 'globalFunc', '$filter', 'prV2Function', 'pathConstants', '$http', 'priceComparisonResource', 'submitPriceComparison', 'toastr', 'lang', '$state', '$rootScope', 'calculateComparison', 'priceComparisonFunction', '$uibModal', 'HighlightId', 'loadCacheableData', 'quotationAttachments'];

angular
  .module('metabuyer')
  .directive('priceComparisonAction', function() {
    return {
      restrict: 'AE',
      scope: true,
      template: '<div ng-include="priceComparisonTemplateUrl"></div>',
      controller: 'priceComparisonActionsCtrl'
    }
  })
  .controller('priceComparisonActionsCtrl', priceComparisonActionsCtrl);
