'use strict';

angular.module('metabuyer.services.quotation', ['ngResource'])
  .factory('singleQuotation', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.quotation.single,
      {
        id: '@id'
      },
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('generateDefaultQuotation', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.quotation.default,
      {
        requisition_id: '@requisition_id'
      },
      {
        'post': { method: 'POST' }
      }
    )
  })
  .factory('createQuotation', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.quotation.create,
      {},
      {
        'post': { method: 'POST' }
      }
    )
  })
  .factory('updateQuotation', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.quotation.update,
      {
        quotation_id: '@quotation_id'
      },
      {
        'put': { method: 'PUT' }
      }
    )
  })
  .factory('deleteQuotation', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.quotation.update,
      {
        quotation_id: '@quotation_id'
      },
      {
        'put': { method: 'DELETE' }
      }
    )
  })
  .factory('quotationAttachments', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.quotation.attachments,
      {
        quotation_id: '@quotation_id'
      },
      {
        'post': { method: 'POST' },
        'get': { method: 'GET'}
      }
    )
  })
  .factory('quotationSingleAttachment', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.quotation.singleAttachment,
      {
        quotation_id: '@quotation_id'
      },
      {
        'get': { method: 'GET' },
        'delete': { method: 'DELETE'}
      }
    )
  });
