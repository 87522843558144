'use strict';

function suppliersListCtrl($scope ,$state, profile, UserPermissions, sourcingSupplierFeatureToggle, $translate) {
  $scope.showColumn = showColumn;

  $scope.isSourcingSupplierEnabled = sourcingSupplierFeatureToggle.is_enabled;
  $scope.tabData = [
    {
      translationId: 'tabs.suppliers',
      heading: 'Suppliers',
      route: 'main.user.suppliersList.manage',
      key: 'suppliers',
      menu: [
        {
          translationId: 'tabs.statusSuppliers',
          translationValue: "statuses.active",
          heading: 'Active Suppliers',
          route: 'main.user.suppliersList.manage',
          key: 'suppliers',
          params: {
            status: 'active',
            cursor: null,
            order_by: 'descr',
            order: 1,
            filter: null,
            query: null,
            type: null,
            page: null
          }
        },
        {
          translationId: 'tabs.statusSuppliers',
          translationValue: "statuses.pending",
          heading: 'Pending Suppliers',
          route: 'main.user.suppliersList.manage',
          key: 'suppliers',
          params: {
            status: 'pending',
            cursor: null,
            order_by: 'descr',
            order: 1,
            filter: null,
            query: null,
            type: null,
            page: null
          }
        },
        {
          translationId: 'tabs.statusSuppliers',
          translationValue: "statuses.blacklisted",
          heading: 'Blacklisted Suppliers',
          route: 'main.user.suppliersList.manage',
          key: 'suppliers',
          params: {
            status: 'blacklisted',
            cursor: null,
            order_by: 'descr',
            order: 1,
            filter: null,
            query: null,
            type: null,
            page: null
          }
        },
        {
          translationId: 'tabs.statusSuppliers',
          translationValue: "statuses.rejected",
          heading: 'Rejected Suppliers',
          route: 'main.user.suppliersList.manage',
          key: 'suppliers',
          params: {
            status: 'rejected',
            cursor: null,
            order_by: 'descr',
            order: 1,
            filter: null,
            query: null,
            type: null,
            page: null
          }
        },
        {
          translationId: 'tabs.statusSuppliers',
          translationValue: "statuses.deactivated",
          heading: 'Deactivated Suppliers',
          route: 'main.user.suppliersList.manage',
          key: 'suppliers',
          params: {
            status: 'inactive',
            cursor: null,
            order_by: 'descr',
            order: 1,
            filter: null,
            query: null,
            type: null,
            page: null
          }
        }
      ]
    },
  ];

  if (!!$scope.isSourcingSupplierEnabled) {
    var supplierUserManageTab = {
      translationId: 'tabs.supplerUser',
      heading: 'Supplier Users',
      route: 'main.user.suppliersList.manageSupplierUser',
      key: 'supplierUser',
      id: 'supplierUserTab',
      menu: [
        {
          translationId: 'statuses.all',
          heading: 'All',
          route: 'main.user.suppliersList.manageSupplierUser',
          key: 'supplierUser',
          id: 'allSupplierUserTab',
          params: {
            status: 'all',
            tab:undefined,
            cursor: null,
            order_by: undefined,
            order: undefined,
            filter: null,
            query: null,
            type: null,
            page: null,
            extraFilterValue: undefined
          }
        },
        {
          translationId: 'statuses.active',
          heading: 'Active',
          route: 'main.user.suppliersList.manageSupplierUser',
          key: 'supplierUser',
          id: 'activeSupplierUserTab',
          params: {
            status: 'active',
            tab:undefined,
            cursor: null,
            order_by: undefined,
            order: undefined,
            filter: null,
            query: null,
            type: null,
            page: null,
            extraFilterValue: undefined
          }
        },
        {
          translationId: 'statuses.pending',
          heading: 'Pending',
          route: 'main.user.suppliersList.manageSupplierUser',
          key: 'supplierUser',
          id: 'pendingSupplierUserTab',
          params: {
            status: 'pending',
            tab:undefined,
            cursor: null,
            order_by: undefined,
            order: undefined,
            filter: null,
            query: null,
            type: null,
            page: null,
            extraFilterValue: undefined
          }
        },
        {
          translationId: 'statuses.rejected',
          heading: 'Rejected',
          route: 'main.user.suppliersList.manageSupplierUser',
          key: 'supplierUser',
          id: 'rejectedSupplierUserTab',
          params: {
            status: 'rejected',
            tab:undefined,
            cursor: null,
            order_by: undefined,
            order: undefined,
            filter: null,
            query: null,
            type: null,
            page: null,
            extraFilterValue: undefined
          }
        },
        {
          translationId: 'statuses.deactivated',
          heading: 'Deactivated',
          route: 'main.user.suppliersList.manageSupplierUser',
          key: 'supplierUser',
          id: 'deactivatedSupplierUserTab',
          params: {
            status: 'inactive',
            tab:undefined,
            cursor: null,
            order_by: undefined,
            order: undefined,
            filter: null,
            query: null,
            type: null,
            page: null,
            extraFilterValue: undefined
          }
        },
        {
          translationId: 'statuses.withdrawn',
          heading: 'Withdrawn',
          route: 'main.user.suppliersList.manageSupplierUser',
          key: 'supplierUser',
          id: 'withdrawnSupplierUserTab',
          params: {
            status: 'withdrawn',
            tab:undefined,
            cursor: null,
            order_by: undefined,
            order: undefined,
            filter: null,
            query: null,
            type: null,
            page: null,
            extraFilterValue: undefined
          }
        }
      ]
    }
    $scope.tabData.push(supplierUserManageTab);
  }

  var userColumns = [
    {id: 'basic_info|descr', label: 'Supplier Name', translationId: 'columns.supplierName'},
    {id: 'supplier_users|display_name', label: 'Supplier User Name', translationId: 'columns.supplierUserName'},
    {id: 'supplier_users|email', label: 'Email', translationId: 'columns.email'},
    {id: 'supplier_users|status', label: 'Status', translationId: 'columns.status', unsearchable: true},{
      id: 'supplier_users|user_detail|created_at',
      label: 'Created At',
      translationId: 'columns.createdAt',
      type: 'date',
      unsearchable: true
    },
    {
      id: 'supplier_users|user_detail|creator|display_name',
      label: 'Created By',
      translationId: 'columns.createdBy',
    },
    {
      id: 'supplier_users|user_detail|updated_at',
      label: 'Updated At',
      translationId: 'columns.updatedAt',
      type: 'date',
      unsearchable: true
    },
    {
      id: 'supplier_users|user_detail|updater|display_name',
      label: 'Updated By',
      translationId: 'columns.updatedBy',
      unsearchable: true
    },
    {
      id: 'supplier_users|user_detail|last_login',
      label: 'Last Login At',
      translationId: 'columns.lastLoginAt',
      type: 'date',
      unsearchable: true
    },
    {id: 'supplier_users|phone', label:'Phone Number', translationId: 'columns.phoneNumber', unsearchable: true}
  ];

  var pendingUserColumns = [
    {id: 'supplier_users|actions_history', label: 'Type', translationId: 'columns.supplierName'},
    {id: 'basic_info|descr', label: 'Supplier Name', translationId: 'columns.supplierName'},
    {id: 'supplier_users|display_name', label: 'Supplier User Name', translationId: 'columns.supplierUserName'},
    {id: 'supplier_users|email', label: 'Email', translationId: 'columns.email'},
    {id: 'supplier_users|status', label: 'Status', unsearchable: true, translationId: 'columns.status'},{
      id: 'supplier_users|user_detail|created_at',
      label: 'Created At',
      translationId: 'columns.createdAt',
      type: 'date',
      unsearchable: true
    },
    {
      id: 'supplier_users|user_detail|creator|display_name',
      label: 'Created By',
      translationId: 'columns.createdBy'
    },
    {
      id: 'supplier_users|user_detail|updated_at',
      label: 'Updated At',
      translationId: 'columns.updatedAt',
      type: 'date',
      unsearchable: true
    },
    {
      id: 'supplier_users|user_detail|updater|display_name',
      label: 'Updated By',
      translationId: 'columns.updatedBy',
      unsearchable: true
    },
    {
      id: 'supplier_users|user_detail|last_login',
      label: 'Last Login At',
      translationId: 'columns.lastLoginAt',
      type: 'date',
      unsearchable: true
    },
    {id: 'supplier_users|phone', label:'Phone Number', translationId: 'columns.phoneNumber', unsearchable: true}
  ];

  function initialize(){
    var searchPlaceholder = null;
    if(!!$state.current.name && $state.current.name.indexOf('supplierTagsBySupplierDetail')> -1){
      $scope.columns = supplierTagsByCompanyColumns;
      $scope.searchPlaceholder = 'Search Companies Tagged to Supplier';
      $scope.searchState = 'main.user.suppliersList.supplierTagsBySupplierDetail';
      $scope.parentState = 'main.user.suppliersList.supplierTagsBySupplierDetail';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[1];
    }

    else if(!!$state.current.name && $state.current.name.indexOf('supplierTagsByCompanyDetail')> -1){
      $scope.columns = supplierTagsBySupplierColumns;
      $scope.searchPlaceholder = 'Search Suppliers Tagged to Company';
      $scope.searchState = 'main.user.suppliersList.supplierTagsByCompanyDetail';
      $scope.parentState = 'main.user.suppliersList.supplierTagsByCompanyDetail';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[1];
    }
    else if(!!$state.current.name && $state.current.name.indexOf('supplierTagging') > -1){
      searchPlaceholder = 'suppliers';
      $scope.searchPlaceholder = 'Search Suppliers';
      $scope.searchState = 'main.user.suppliersList.manage';
      $scope.parentState = 'main.user.suppliersList.manage';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[2];
    }
    else if(!!$state.current.name && $state.current.name.indexOf('manageSupplierUser')> -1) {
      if ($state.params.status === 'pending') {
        $scope.columns = pendingUserColumns;
      } else {
        $scope.columns = userColumns;
      }
      searchPlaceholder = 'supplierUsers';
      $scope.searchPlaceholder = 'Search Supplier Users';
      $scope.searchState = 'main.user.suppliersList.manageSupplierUser';
      $scope.parentState = 'main.user.suppliersList.manageSupplierUser';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[1];
    }
    else{
      searchPlaceholder = 'suppliers';
      $scope.columns = supplierColumns;
      $scope.searchPlaceholder = 'Search Suppliers';
      $scope.searchState = 'main.user.suppliersList.manage';
      $scope.parentState = 'main.user.suppliersList.manage';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[0];
    }

    if(!!$state.params && !!$state.params.query){
      $scope.searchText = $state.params.query;
    }
    else{
      $scope.searchText = '';
    }
    if(!!$state.params && !!$state.params.status){
      $scope.status = $state.params.status
    }
    if(!!$state.params && !!$state.params.filter){
      $scope.searchFilter = $state.params.filter;
    }

    if (!!$scope.isSourcingSupplierEnabled) {
      $scope.advancedSearchState = 'main.user.advancedSearch.supplier';
    }

    var translateArr = [
      'searchPlaceholder.suppliers',
      'searchPlaceholder.supplierUsers',
    ];
    $translate(translateArr).then(function (trans) {
      if (searchPlaceholder !== null && trans['searchPlaceholder.' + searchPlaceholder] !== undefined) {
        $scope.searchPlaceholder = trans['searchPlaceholder.' + searchPlaceholder];
      }
    });
    var translateColumnArr = [
      "columns.supplierName",
      "columns.supplierCode",
      "columns.rocRobNric",
      "columns.preferredCurrency",
      "columns.email",
      "columns.contactPersonName",
      "columns.contactPersonEmail",
      "columns.contactPersonPhone",
      "columns.primaryBusinessNature",
      "columns.primaryCommodity",
      "columns.secondaryCommodity",
      "columns.supplierType",
      "columns.createdAt",
      "columns.createdBy",
      "columns.updatedAt",
      "columns.updatedBy",
      "columns.supplierUserName",
      "columns.status",
      "columns.lastLoginAt",
      "columns.phoneNumber",
    ];
    $translate(translateColumnArr).then(function (trans) {
      for(var columnIndex in $scope.columns) {
        var columnData = $scope.columns[columnIndex];
        if (trans[columnData.translationId] === undefined)
          continue;
        $scope.columns[columnIndex].label = trans[columnData.translationId];
      }
    });
  }

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional) {
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }

  var supplierColumns = [
    {id: 'basic_info|descr', label: 'Supplier Name', translationId: 'columns.supplierName'},
    {id: 'basic_info|code', label: 'Supplier Code', translationId: 'columns.supplierCode'},
    {id: 'basic_info|reg_no', label: 'ROC/ROB/NRIC', translationId: 'columns.rocRobNric'},
    {id: 'basic_info|currency_code', label: 'Preferred Currency', translationId: 'columns.preferredCurrency', unsortable: true, unsearchable: true},
    {id: 'basic_info|email', label: 'Email', translationId: 'columns.email'},
    {id: 'default_contract|contacts|name', label:'Contact person\'s name', translationId: 'columns.contactPersonName', unsortable: true, unsearchable: true},
    {id: 'default_contract|contacts|email', label: 'Contact person\'s email', translationId: 'columns.contactPersonEmail', unsortable: true, unsearchable: true},
    {id: 'default_contract|contacts|phone', label: 'Contact person\'s phone', translationId: 'columns.contactPersonPhone', unsortable: true, unsearchable: true},
    {id: 'default_contract|primary_business_nature', label: 'Primary Business Nature', translationId: 'columns.primaryBusinessNature', unsortable: true, unsearchable: true},
    {id: 'default_contract|primary_commodity_descr', label: 'Primary Commodity', translationId: 'columns.primaryCommodity'},
    {id: 'default_contract|secondary_commodity_descr', label: 'Secondary Commodity', translationId: 'columns.secondaryCommodity'},
    {
      id: 'created_at',
      label: 'Created At',
      translationId: 'columns.createdAt',
      type: 'date',
      unsearchable: true
    },
    {id: 'creator_info|display_name', label: 'Created By', translationId: 'columns.createdBy'},
    {
      id: 'updated_at',
      label: 'Updated At',
      translationId: 'columns.updatedAt',
      type: 'date',
      unsearchable: true
    },
    {id: 'updater_info|display_name', label: 'Updated By', translationId: 'columns.updatedBy'},
    {id: 'blacklisted_by', label: 'Blacklisted By', translationId: 'columns.blacklistedBy', conditional: true, condition: 'black_listed'},
    {id: 'blacklisted_reason', label: 'Blacklisting Reason', conditional: true, condition: 'black_listed'},
    {id: 'submittedFrom', label: 'Submitted From', conditional: true, condition: 'black_listed'},
    {id: 'status', label: 'Status', conditional: true, condition: 'all'}
  ];

  if ($scope.isSourcingSupplierEnabled) {
    var supplierType = {
      id: 'basic_info|is_sourcing_supplier',
      label: 'Supplier Type',
      translationId: 'columns.supplierType',
      unsearchable: true,
      unsortable: true
    };

    supplierColumns.splice(11, 0, supplierType);
  }
  var translateTabArr = [
    'tabs.suppliers',
    'tabs.supplerUser',
    'tabs.statusSuppliers',
    'statuses.all',
    'statuses.active',
    'statuses.pending',
    'statuses.blacklisted',
    'statuses.rejected',
    'statuses.withdrawn',
    'statuses.deactivated',
  ];
  $translate(translateTabArr).then(function (trans) {
    for(var tabIndex in $scope.tabData) {
      var tabData = $scope.tabData[tabIndex];
      $scope.tabData[tabIndex].heading = trans[tabData.translationId];
      if (tabData.menu === undefined) {
        continue;
      }
      for(var menuIndex in tabData.menu) {
        var menuData = $scope.tabData[tabIndex].menu[menuIndex];
        if (trans[menuData.translationId] === undefined) {
          continue;
        }
        if (menuData.translationValue === undefined) {
          $scope.tabData[tabIndex].menu[menuIndex].heading = trans[menuData.translationId];
        } else {
          var heading = trans[menuData.translationId].replace('[[statuses]]', trans[menuData.translationValue]);
          $scope.tabData[tabIndex].menu[menuIndex].heading = heading;
        }
      }
    }
  });

  initialize();

  $scope.$emit('pageTitleChanged', 'Suppliers List');

  $scope.$on('$stateChangeStart', function( event, data, toParams ) {
    var searchPlaceholder = null;
    if(!!data.name && data.name.indexOf('manageSupplierUser')> -1) {
      if (toParams.status === 'pending') {
        $scope.columns = pendingUserColumns;
      } else {
        $scope.columns = userColumns;
      }
      searchPlaceholder = 'supplierUsers';
      $scope.searchPlaceholder = 'Search Supplier Users';
      $scope.searchState = 'main.user.suppliersList.manageSupplierUser';
      $scope.parentState = 'main.user.suppliersList.manageSupplierUser';
      $scope.parentStateParams = $state.params;
    }
    else{
      $scope.columns = supplierColumns;
      searchPlaceholder = 'suppliers';
      $scope.searchPlaceholder = 'Search Suppliers';
      $scope.searchState = 'main.user.suppliersList.manage';
      $scope.parentState = 'main.user.suppliersList.manage';
      $scope.parentStateParams = $state.params;
    }

    if(!!toParams.status){
      $scope.parentStateParams = toParams;
    }

    var translateArr = [
      'searchPlaceholder.suppliers',
      'searchPlaceholder.supplierUsers',
    ];
    $translate(translateArr).then(function (trans) {
      if (searchPlaceholder !== null && trans['searchPlaceholder.' + searchPlaceholder] !== undefined) {
        $scope.searchPlaceholder = trans['searchPlaceholder.' + searchPlaceholder];
      }
    });
    var translateColumnArr = [
      "columns.supplierName",
      "columns.supplierCode",
      "columns.rocRobNric",
      "columns.preferredCurrency",
      "columns.email",
      "columns.contactPersonName",
      "columns.contactPersonEmail",
      "columns.contactPersonPhone",
      "columns.primaryBusinessNature",
      "columns.primaryCommodity",
      "columns.secondaryCommodity",
      "columns.supplierType",
      "columns.createdAt",
      "columns.createdBy",
      "columns.updatedAt",
      "columns.updatedBy",
      "columns.supplierUserName",
      "columns.status",
      "columns.lastLoginAt",
      "columns.phoneNumber",
    ];
    $translate(translateColumnArr).then(function (trans) {
      for(var columnIndex in $scope.columns) {
        var columnData = $scope.columns[columnIndex];
        if (trans[columnData.translationId] === undefined)
          continue;
        $scope.columns[columnIndex].label = trans[columnData.translationId];
      }
    });
  });

}
suppliersListCtrl.$inject = ['$scope', '$state', 'profile', 'UserPermissions', 'sourcingSupplierFeatureToggle', '$translate'];

angular.module('metabuyer')
  .controller('suppliersListCtrl', suppliersListCtrl);
