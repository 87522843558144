'use strict';

angular.module('metabuyer.services.permissions', ['ngResource'])
  .factory('usersPermissions',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.permissions.userList,
      {
        id: '@id'
      },
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('permissionsList',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.permissions.list,
      {
      },
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('permissionsParameters',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.permissions.parameters,
      {
        class: '@class'
      },
      {
        'get': { method: 'GET' }
      }
    );
  });
