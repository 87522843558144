'use strict';

angular.module('metabuyer.services.address' , ['ngResource'])
  .factory('addressList',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.addresses.list,
      {
      },
      {
        'get' : {method: 'GET', ignoreLoadingBar: true},
        'post': {method: 'POST'}
      }
    );
  })
  .factory('singleAddress',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.addresses.single,
      {
        id: '@id'
      },
      {
        'put': {method: 'PUT', ignoreLoadingBar: true}
      }
    );
  });

