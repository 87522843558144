'use strict';

function SubmitPRCtrl($scope, $uibModalInstance, toastr, $q, approvals){

  $scope.approvals = approvals;

  //cancel action
  $scope.cancel = function(){
    $uibModalInstance.close();
  };

  //proceed action
  $scope.confirm = function(){
    $uibModalInstance.close();
    $scope.submitPR();
  };
}

SubmitPRCtrl.$inject = ["$scope", "$uibModalInstance", "toastr", "$q", "approvals"];

angular.module('metabuyer')
  .controller('SubmitPRCtrl', SubmitPRCtrl);
