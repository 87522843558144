'use strict';

/**
 *
 * @param $scope
 * @param $rootScope
 * @param $state
 * @param companyDetails
 * @param newStudentLifeService
 * @param globalFunc
 * @param toastr
 * @param dataList
 * @param purchaseRequisitionsServices
 * @param $uibModal
 * @param $location
 */
function studentLifeEventCtrl($scope, $rootScope, $state, companyDetails, newStudentLifeService, globalFunc, toastr, dataList,
                      purchaseRequisitionsServices, $uibModal, $location) {
  $scope.studentLife = {};
  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.companyDetails = companyDetails.data;
  $scope.newStudentLife = newStudentLife;
  $scope.studentLifeDetail = studentLifeDetail;
  $scope.search = search;
  $scope.back = back;
  $scope.showColumn = showColumn;
  $scope.storageKey = 'companies-student-life-selected-columns';
  $scope.columns= [
    {id: 'code', label: 'Code'},
    {id: 'descr', label: 'Description'},
    {id: 'updated_at', label: 'Updated At'},
    {id: 'created_at', label: 'Created At'}
  ];

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional) {
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function back(){
    $state.go('main.user.companiesMgmt.detailCompanies', {id: $scope.companyDetails._id});
  }

  function search(query) {
    $location.search({query: query});
  }

  function newStudentLife() {
    var data = {
      'company_code' : $scope.companyDetails.code,
      'items' : [{
        'code': $scope.studentLife.code,
        'description': $scope.studentLife.descr
      }]
    };
    newStudentLifeService.post(
      data,
      function (resource) {
        toastr.success('Student Life is successfully added!');
        purchaseRequisitionsServices.getStudentLifeEvent(
          {
            company_code: $scope.companyDetails.code
          },
          function (resource) {
            if (!!resource && !!resource.data) {
              $scope.dataList = resource.data;
            }
          }
        );
        clearForm();
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function clearForm() {
    $scope.studentLife.code = '';
    $scope.studentLife.descr = '';
  }

  function studentLifeDetail($index) {
    var updateStudentLife = $uibModal.open({
      templateUrl: "app/companiesManagement/companies/studentLifeEvent/detail.html",
      controller: 'detailStudentLife',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        studentLife: function ($q, studentLifeServices) {
          var deferred = $q.defer();
          studentLifeServices.get(
            {
              id: $scope.dataList[$index]._id
            },
            function (resource) {
              deferred.resolve(resource || {data: []});
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        }
      }
    });

    updateStudentLife.result.then(function (newValue) {
      if (!!newValue) {
        _.forEach($scope.dataList, function (dataList) {
          if (dataList._id === newValue._id) {
            dataList.code = newValue.code;
            dataList.descr = newValue.descr;
            dataList.created_at = newValue.created_at;
            dataList.updated_at = newValue.updated_at;
          }
        });
      }
    }, function () {
    });

  }
}

studentLifeEventCtrl.$inject = ['$scope', '$rootScope', '$state', 'companyDetails', 'newStudentLifeService', 'globalFunc', 'toastr',
  'dataList', 'purchaseRequisitionsServices', '$uibModal', '$location'];

angular.module('metabuyer')
  .controller('studentLifeEventCtrl', studentLifeEventCtrl);
