'use strict';

function purchasePriceCtrl($scope, prV2Function) {

  $scope.company = prV2Function.getPRData().company;

  $scope.$watch('item.last_purchase_info', function (newVal) {
    $scope.last_purchase_info = newVal;
  });

  $scope.$watch('item.least_purchase_info', function (newVal) {
    $scope.least_purchase_info = newVal;
  });

}

purchasePriceCtrl.$inject = ['$scope', 'prV2Function'];

angular
  .module('metabuyer')
  .directive('purchasePrice', function () {
    return {
      restrict: 'E',
      templateUrl: 'components/purchasePrice/purchasePriceTemplate.html',
      controller: 'purchasePriceCtrl'
    }

  })
  .controller('purchasePriceCtrl', purchasePriceCtrl);
