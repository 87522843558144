'use strict';

function neededByDate (){
  return {
    restrict: 'AE',
    templateUrl: 'components/purchaseRequisitionV2Components/neededByDate/neededByDateTemplate.html',
    controller: function($scope) {
    }
  }
}

angular
  .module('metabuyer')
  .directive('neededByDate',neededByDate);
