'use strict';

function detailHeader() {
  return {
    restrict: 'E',
    templateUrl: 'components/poConsolidation/detailsHeader/poConsolidationDetailsHeaderTemplate.html',
    controller: 'poConsolidationHeaderCtrl',
    scope: {
      header: '='
    }
  }
}

function poConsolidationHeaderCtrl($scope, poFunction, $rootScope) {
  $scope.currentUserDisplayName = $rootScope.currentUser.display_name;
  $scope.findClosestNeededAtDate = findClosestNeededAtDate;

  if (!!$scope.header) {
    if ($scope.header.pc.length > 0) {

    var cachedObject = {};

    $scope.header.pc.map(function(item) {
      cachedObject[item.id] = item
    })

    $scope.header.pc = Object.values(cachedObject)
    }
  }

  function findClosestNeededAtDate() {
    return poFunction.findClosestNeededAtDate();
  }
}

angular
  .module('metabuyer')
  .directive('detailHeader', detailHeader)
  .controller('poConsolidationHeaderCtrl', poConsolidationHeaderCtrl);
