'use strict';

angular
  .module('metabuyer')
  .directive('tenderUtilization', function($uibModal) {
    return {
      restrict: 'E',
      scope: {
        tenderId: '=',
        currencyCode: '=',
        showTenderDashboard: '=',
        prId: '=',
        prStatus: '='
      },
      template: '<div ng-include="templateUrlTenderUtilization"></div>',
      controller: 'tenderUtilizationCtrl',
      link: function($scope) {
        $scope.templateUrlTenderUtilization = 'components/tenderComponents/utilization/tenderUtilization.html';
      }
    };
  });
