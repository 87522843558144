'use strict';

angular.module('metabuyer')
  .controller('supplierCtrl', supplierCtrl);

supplierCtrl.$inject = [ '$scope', 'UserPermissions', 'profile', '$state', 'sourcingSupplierFeatureToggle', 'supplierFunctions', '$translate', 'globalFunc'];

function supplierCtrl ($scope, UserPermissions, profile, $state, sourcingSupplierFeatureToggle, supplierFunctions, $translate, globalFunc) {

  $scope.isSourcingSupplierEnabled = !!sourcingSupplierFeatureToggle.is_enabled;
  $scope.showColumn = showColumn;
  $scope.getSupplierType = getSupplierType;
  $scope.isSupplierMetabuyerAdmin = !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Sourcing Metabuyer Admin');
  $scope.isSupplierTenantAdmin = !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Sourcing Tenant Admin');
  $scope.isSupplierBiddingAdmin = !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Sourcing and Bidding Admin');

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional) {
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }

  $scope.tabData = [
    {
      translationId: 'tabs.manageSuppliers',
      heading: 'Manage Suppliers',
      route: 'main.user.supplier.manage',
      key: 'supplier',
      id: 'manageSuppliersTab',
      menu: [
        {
          translationId: 'statuses.draft',
          heading: 'Draft',
          route: 'main.user.supplier.manage',
          key: 'supplier',
          id: 'draftSuppliersTab',
          params: {
            status: 'draft',
            tab:undefined,
            cursor: null,
            order_by: undefined,
            order: undefined,
            filter: null,
            query: null,
            type: null,
            page: null,
            extraFilterValue: undefined
          }
        },
        {
          translationId: 'statuses.active',
          heading: 'Active',
          route: 'main.user.supplier.manage',
          key: 'supplier',
          id: 'activeSuppliersTab',
          params: {
            status: 'active',
            tab:undefined,
            cursor: null,
            order_by: undefined,
            order: undefined,
            filter: null,
            query: null,
            type: null,
            page: null,
            extraFilterValue: undefined
          }
        },
        {
          translationId: 'statuses.pendingApproval',
          heading: 'Pending Approval',
          route: 'main.user.supplier.manage',
          key: 'supplier',
          id: 'pendingSuppliersTab',
          params: {
            status: 'pending',
            tab:'all',
            cursor: null,
            order_by: undefined,
            order: undefined,
            filter: null,
            query: null,
            type: null,
            page: null,
            extraFilterValue: undefined
          }
        },
        {
          translationId: 'statuses.blacklisted',
          heading: 'Blacklisted',
          route: 'main.user.supplier.manage',
          key: 'supplier',
          id: 'blacklistedSuppliersTab',
          params: {
            status: 'blacklisted',
            tab:undefined,
            cursor: null,
            order_by: undefined,
            order: undefined,
            filter: null,
            query: null,
            type: null,
            page: null,
            extraFilterValue: undefined
          }
        },
        {
          translationId: 'statuses.rejected',
          heading: 'Rejected',
          route: 'main.user.supplier.manage',
          key: 'supplier',
          id: 'rejectedSuppliersTab',
          params: {
            status: 'rejected',
            cursor: null,
            tab:undefined,
            order_by: undefined,
            order: undefined,
            filter: null,
            query: null,
            type: null,
            page: null,
            extraFilterValue: undefined
          }
        },
        {
          translationId: 'statuses.withdrawn',
          heading: 'Withdrawn',
          route: 'main.user.supplier.manage',
          key: 'supplier',
          id: 'withdrawnSuppliersTab',
          params: {
            status: 'withdrawn',
            tab:undefined,
            cursor: null,
            order_by: undefined,
            order: undefined,
            query: null,
            filter: null,
            page: null,
            extraFilterValue: undefined
          }
        },
        {
          translationId: 'statuses.deactivated',
          heading: 'Deactivated',
          route: 'main.user.supplier.manage',
          key: 'supplier',
          id: 'inactiveSuppliersTab',
          params: {
            status: 'inactive',
            tab:undefined,
            cursor: null,
            order_by: undefined,
            order: undefined,
            filter: null,
            query: null,
            type: null,
            page: null,
            extraFilterValue: undefined
          }
        },
        {
          translationId: 'statuses.suspended',
          heading: 'Suspended',
          route: 'main.user.supplier.manage',
          key: 'supplier',
          id: 'suspendedSuppliersTab',
          params: {
            status: 'suspended',
            tab:undefined,
            cursor: null,
            order_by: undefined,
            order: undefined,
            filter: null,
            query: null,
            type: null,
            page: null,
            extraFilterValue: undefined
          }
        }
      ]
    },


  ];
  /** change route for add supplier tab*/
  if (!!UserPermissions.checkPermissionsAccess(profile, 'Supplier', 'Create')) {
    var menu = [
      {
        translationId: 'tabs.addSuppliersMenu.createSingleSupplier',
        heading: 'Create Single Supplier',
        route:  'main.user.supplier.newSupplier',
        key: 'createSupplier',
        id: 'createSupplierTab',
        params:{
          status: 'addNew',
          cursor: null,
          filter: null,
          query: null,
          type: null
        }
      },
    ];
    menu.push({
      translationId: 'tabs.addSuppliersMenu.uploadSuppliers',
      heading: 'Upload Suppliers',
      route:  'main.user.supplier.import',
      key: 'createSupplier',
      id: 'importSupplierTab',
      params:{
        status: 'import',
        cursor: null,
        filter: null,
        query: null,
        type: null
      }
    });
    menu.push({
      translationId: 'tabs.addSuppliersMenu.uploadSupplierContactPersons',
      heading: 'Upload Supplier Contact Persons',
      route:  'main.user.supplier.contactImport',
      key: 'createContact',
      id: 'importContactTab',
      params:{
        status: 'import',
        cursor: null,
        filter: null,
        query: null,
        type: null
      }
    });
    if (!!$scope.isSourcingSupplierEnabled && 
        (!!$scope.isSupplierBiddingAdmin || !!$scope.isSupplierMetabuyerAdmin || !!$scope.isSupplierTenantAdmin)) {
      menu.push({
        translationId: 'tabs.addSuppliersMenu.uploadSourcingSupplier',
        heading: 'Upload Sourcing Suppliers',
        route:  'main.user.supplier.sourcingSupplierImport',
        key: 'createSourcingSupplier',
        id: 'importSourcingSupplierTab',
        params:{
          status: 'import',
          cursor: null,
          filter: null,
          query: null,
          type: null
        }
      });
    }
    var addSupplierTab = {
      translationId: 'tabs.addSuppliers',
      heading: 'Add Suppliers',
      route: 'main.user.supplier.newSupplier',
      key: 'createSupplier',
      id: 'addSupplierTab',
      menu: menu
    };
    $scope.tabData.push(addSupplierTab);
  }

  // temporarily parked under supplier creation role
  // TODO: move code below to supplier user related role
  var isSupplierReqeuster = !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Supplier Requester');
  var isSupplierApprover = !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Supplier Approver');
  if (!!$scope.isSourcingSupplierEnabled) {
    if (!!UserPermissions.checkPermissionsAccess(profile, 'Supplier', 'Create') &&
        ((!isSupplierReqeuster && !isSupplierApprover) ||
         (!!$scope.isSupplierBiddingAdmin || !!$scope.isSupplierMetabuyerAdmin || !!$scope.isSupplierTenantAdmin)
        )) {
      var supplierUserManageTab = {
        translationId: 'tabs.manageSupplierUsers',
        heading: 'Manage Supplier Users',
        route: 'main.user.supplier.manageSupplierUser',
        key: 'supplierUser',
        id: 'supplierUserTab',
        menu: [
          {
            translationId: 'statuses.all',
            heading: 'All',
            route: 'main.user.supplier.manageSupplierUser',
            key: 'supplierUser',
            id: 'allSupplierUserTab',
            params: {
              status: 'all',
              tab:undefined,
              cursor: null,
              order_by: undefined,
              order: undefined,
              filter: null,
              query: null,
              type: null,
              page: null,
              extraFilterValue: undefined
            }
          },
          {
            translationId: 'statuses.active',
            heading: 'Active',
            route: 'main.user.supplier.manageSupplierUser',
            key: 'supplierUser',
            id: 'activeSupplierUserTab',
            params: {
              status: 'active',
              tab:undefined,
              cursor: null,
              order_by: undefined,
              order: undefined,
              filter: null,
              query: null,
              type: null,
              page: null,
              extraFilterValue: undefined
            }
          },
          {
            translationId: 'statuses.pending',
            heading: 'Pending',
            route: 'main.user.supplier.manageSupplierUser',
            key: 'supplierUser',
            id: 'pendingSupplierUserTab',
            params: {
              status: 'pending',
              tab:undefined,
              cursor: null,
              order_by: undefined,
              order: undefined,
              filter: null,
              query: null,
              type: null,
              page: null,
              extraFilterValue: undefined
            }
          },
          {
            translationId: 'statuses.rejected',
            heading: 'Rejected',
            route: 'main.user.supplier.manageSupplierUser',
            key: 'supplierUser',
            id: 'rejectedSupplierUserTab',
            params: {
              status: 'rejected',
              tab:undefined,
              cursor: null,
              order_by: undefined,
              order: undefined,
              filter: null,
              query: null,
              type: null,
              page: null,
              extraFilterValue: undefined
            }
          },
          {
            translationId: 'statuses.deactivated',
            heading: 'Deactivated',
            route: 'main.user.supplier.manageSupplierUser',
            key: 'supplierUser',
            id: 'deactivatedSupplierUserTab',
            params: {
              status: 'inactive',
              tab:undefined,
              cursor: null,
              order_by: undefined,
              order: undefined,
              filter: null,
              query: null,
              type: null,
              page: null,
              extraFilterValue: undefined
            }
          },
          {
            translationId: 'statuses.withdrawn',
            heading: 'Withdrawn',
            route: 'main.user.supplier.manageSupplierUser',
            key: 'supplierUser',
            id: 'withdrawnSupplierUserTab',
            params: {
              status: 'withdrawn',
              tab:undefined,
              cursor: null,
              order_by: undefined,
              order: undefined,
              filter: null,
              query: null,
              type: null,
              page: null,
              extraFilterValue: undefined
            }
          }
        ]
      }
      $scope.tabData.push(supplierUserManageTab);
    }
    var isSourcingSupplierApprover = !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Sourcing Supplier Approver');
    var isSourcingRequestor = !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Sourcing Requestor');
    var isBiddingRequestor = !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Bidding Requestor');
    if (!!UserPermissions.checkPermissionsAccess(profile, 'Supplier', 'Create') &&
         (!!$scope.isSupplierBiddingAdmin || !!$scope.isSupplierMetabuyerAdmin || !!$scope.isSupplierTenantAdmin ||
          !!isSourcingSupplierApprover || !!isSourcingRequestor || !!isBiddingRequestor)
        ) {
      var menu = [
        {
          translationId: 'tabs.addSupplierUsersMenu.addSupplierUser',
          heading: 'Add a Supplier User',
          route:  'main.user.supplier.addSupplierUser',
          key: 'addSupplierUser',
          id: 'createSupplierUserTab',
          params:{
            status: 'addNew',
            cursor: null,
            filter: null,
            query: null,
            type: null
          }
        },
      ];
      if (!!$scope.isSupplierBiddingAdmin || !!$scope.isSupplierMetabuyerAdmin || !!$scope.isSupplierTenantAdmin) {
        menu.push({
          translationId: 'tabs.addSupplierUsersMenu.importSupplierUser',
          heading: 'Import Supplier User',
          route:  'main.user.supplier.importSupplierUser',
          key: 'importSupplierUser',
          id: 'importSupplierUserTab',
          params:{
            status: 'import',
            cursor: null,
            filter: null,
            query: null,
            type: null
          }
        });
      }
      var addSuplierUserTab = {
        translationId: 'tabs.addSupplierUsers',
        heading: 'Add Supplier Users',
        route: 'main.user.supplier.addSupplierUser',
        key: 'addSupplierUser',
        id: 'addSupplierUserTab',
        menu: menu
      }

      $scope.tabData.push(addSuplierUserTab);
    }
  }
  var translateTabArr = [
    'tabs.manageSuppliers',
    'tabs.addSuppliers',
    'tabs.manageSupplierUsers',
    'tabs.addSupplierUsers',
    'tabs.addSuppliersMenu.createSingleSupplier',
    'tabs.addSuppliersMenu.uploadSuppliers',
    'tabs.addSuppliersMenu.uploadSupplierContactPersons',
    'tabs.addSupplierUsersMenu.addSupplierUser',
    'tabs.addSupplierUsersMenu.importSupplierUser',
    'statuses.all',
    'statuses.active',
    'statuses.draft',
    'statuses.pending',
    'statuses.pendingApproval',
    'statuses.blacklisted',
    'statuses.rejected',
    'statuses.withdrawn',
    'statuses.deactivated',
    'statuses.suspended',
  ];
  $translate(translateTabArr).then(function (trans) {
    for(var tabIndex in $scope.tabData) {
      var tabData = $scope.tabData[tabIndex];
      $scope.tabData[tabIndex].heading = trans[tabData.translationId];
      if (tabData.menu === undefined) {
        continue;
      }
      for(var menuIndex in tabData.menu) {
        var menuData = $scope.tabData[tabIndex].menu[menuIndex];
        if (trans[menuData.translationId] === undefined) {
          continue;
        }
        $scope.tabData[tabIndex].menu[menuIndex].heading = trans[menuData.translationId];
      }
    }
  });

  function getSupplierType(basicInfo) {
    if (!_.has(basicInfo, 'is_sourcing_supplier')) {
      return 'N/A';
    } else {
      return supplierFunctions.findSupplierType(basicInfo).descr;
    }
  }

  /** supplier listing table
   *  change path for every search state and parent state
   **/
  function initialize() {
    if(!!$state.params && !!$state.params.status){
      $scope.status = $state.params.status
    }
    var searchPlaceholder = null;
    if(!!$state.current.name && $state.current.name.indexOf('supplierTagsBySupplierDetail')> -1){
      $scope.columns = supplierTagsByCompanyColumns;
      $scope.searchPlaceholder = 'Search Companies Tagged to Supplier';
      $scope.searchState = 'main.user.supplier.supplierTagsBySupplierDetail';
      $scope.parentState = 'main.user.supplier.supplierTagsBySupplierDetail';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[1];
    }

    else if(!!$state.current.name && $state.current.name.indexOf('supplierTagsByCompanyDetail')> -1){
      $scope.columns = supplierTagsBySupplierColumns;
      $scope.searchPlaceholder = 'Search Suppliers Tagged to Company';
      $scope.searchState = 'main.user.supplier.supplierTagsByCompanyDetail';
      $scope.parentState = 'main.user.supplier.supplierTagsByCompanyDetail';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[1];
    }
    else if(!!$state.current.name && $state.current.name.indexOf('supplierTagging') > -1){
      searchPlaceholder = 'suppliers';
      $scope.searchPlaceholder = 'Search Suppliers';
      $scope.searchState = 'main.user.supplier.supplierTagging';
      $scope.parentState = 'main.user.suppliers.manage';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[3];
    }
    else if(!!$state.current.name && $state.current.name.indexOf('import') > -1){
      searchPlaceholder = 'suppliers';
      $scope.columns = supplierColumns;
      $scope.searchPlaceholder = 'Search Suppliers';
      $scope.searchState = 'main.user.suppliers.manage';
      $scope.parentState = 'main.user.suppliers.manage';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[2];
    }
    else if(!!$state.current.name && $state.current.name.indexOf('newSupplier') > -1){
      searchPlaceholder = 'suppliers';
      $scope.columns = supplierColumns;
      $scope.searchPlaceholder = 'Search Suppliers';
      $scope.searchState = 'main.user.supplier.manage';
      $scope.parentState = 'main.user.supplier.manage';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[2];
    }
    else if(!!$state.current.name && $state.current.name.indexOf('manageSupplierUser')> -1) {
      if ($state.params.status === 'pending') {
        $scope.columns = pendingUserColumns;
      } else {
        $scope.columns = userColumns;
      }
      searchPlaceholder = 'supplierUsers';
      $scope.searchPlaceholder = 'Search Supplier Users';
      $scope.searchState = 'main.user.supplier.manageSupplierUser';
      $scope.parentState = 'main.user.supplier.manageSupplierUser';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[2];
    }
    else if(!!$state.current.name && $state.current.name.indexOf('addSupplierUser')> -1) {
      searchPlaceholder = 'supplierUsers';
      $scope.searchPlaceholder = 'Search Supplier Users';
      $scope.searchState = 'main.user.supplier.manageSupplierUser';
      $scope.parentState = 'main.user.supplier.manageSupplierUser';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[3];
    }
    else if(!!$state.current.name && $state.current.name.indexOf('importSupplierUser')> -1) {
      searchPlaceholder = 'supplierUsers';
      $scope.searchPlaceholder = 'Search Supplier Users';
      $scope.searchState = 'main.user.supplier.manageSupplierUser';
      $scope.parentState = 'main.user.supplier.manageSupplierUser';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[3];
    }
    else{
      if(!!$state.params && $state.params.status === 'pending') {
        $scope.columns = pendingSupplierColumns;
      }
      else if (!!$state.params && $state.params.status === 'blacklisted') {
        $scope.columns = blacklistedSupplierColumns;
      }
      else {
        $scope.columns = supplierColumns;
      }
      searchPlaceholder = 'suppliers';
      $scope.searchPlaceholder = 'Search Suppliers';
      $scope.searchState = 'main.user.supplier.manage';
      $scope.parentState = 'main.user.supplier.manage';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[0];
    }

    if(!!$state.params && !!$state.params.query){
      $scope.searchText = $state.params.query;
    }
    else{
      $scope.searchText = '';
    }

    if(!!$state.params && !!$state.params.filter){
      $scope.searchFilter = $state.params.filter;
    }

    if (!!$scope.isSourcingSupplierEnabled) {
      $scope.advancedSearchState = 'main.user.advancedSearch.supplier';
    }

    var translateArr = [
      'searchPlaceholder.suppliers',
      'searchPlaceholder.supplierUsers',
    ];
    $translate(translateArr).then(function (trans) {
      if (searchPlaceholder !== null && trans['searchPlaceholder.' + searchPlaceholder] !== undefined) {
        $scope.searchPlaceholder = trans['searchPlaceholder.' + searchPlaceholder];
      }
    });
    var translateColumnArr = [
      "columns.supplierName",
      "columns.supplierCode",
      "columns.rocRobNric",
      "columns.preferredCurrency",
      "columns.email",
      "columns.contactPersonName",
      "columns.contactPersonEmail",
      "columns.contactPersonPhone",
      "columns.primaryBusinessNature",
      "columns.primaryCommodity",
      "columns.secondaryCommodity",
      "columns.supplierType",
      "columns.createdAt",
      "columns.createdBy",
      "columns.updatedAt",
      "columns.updatedBy",
      "columns.supplierUserName",
      "columns.status",
      "columns.lastLoginAt",
      "columns.phoneNumber",
      "columns.waitingOn",
      "columns.submitter",
      "columns.submisionDate",
      "columns.blacklistedAt",
      "columns.blacklistedBy",
    ];
    $translate(translateColumnArr).then(function (trans) {
      for(var columnIndex in $scope.columns) {
        var columnData = $scope.columns[columnIndex];
        if (trans[columnData.translationId] === undefined)
          continue;
        $scope.columns[columnIndex].label = trans[columnData.translationId];
      }
    });
  }

  var supplierColumns = [
      {id: 'check box', title: '', unsortable: true, unsearchable: true, conditional: true, condition: ['pending'], unSelectable: true},
      {id: 'basic_info|descr', label: 'Supplier Name', translationId: 'columns.supplierName'},
      {id: 'basic_info|code', label: 'Supplier Code', translationId: 'columns.supplierCode'},
      {id: 'basic_info|reg_no', label: 'ROC/ROB/NRIC', translationId: 'columns.rocRobNric'},
      {id: 'basic_info|currency_code', label: 'Preferred Currency', translationId: 'columns.preferredCurrency', unsortable: true, unsearchable: true},
      {id: 'basic_info|email', label: 'Email', translationId: 'columns.email'},
      {id: 'default_contract|contacts|name', label:'Contact person\'s name', translationId: 'columns.contactPersonName', unsortable: true, unsearchable: true},
      {id: 'default_contract|contacts|email', label: 'Contact person\'s email', translationId: 'columns.contactPersonEmail', unsortable: true, unsearchable: true},
      {id: 'default_contract|contacts|phone', label: 'Contact person\'s phone', translationId: 'columns.contactPersonPhone', unsortable: true, unsearchable: true},
      {id: 'default_contract|primary_business_nature', label: 'Primary Business Nature', translationId: 'columns.primaryBusinessNature'},
      {id: 'default_contract|primary_commodity_descr', label: 'Primary Commodity', translationId: 'columns.primaryCommodity'},
      {id: 'default_contract|secondary_commodity_descr', label: 'Secondary Commodity', translationId: 'columns.secondaryCommodity'},
      {
        id: 'created_at',
        label: 'Created At',
        translationId: 'columns.createdAt',
        type: 'date',
        unsearchable: true
      },
      {id: 'creator_info|display_name', label: 'Created By', translationId: 'columns.createdBy'},
      {
        id: 'updated_at',
        label: 'Updated At',
        translationId: 'columns.updatedAt',
        type: 'date',
        unsearchable: true
      }
      //TODO: check the usage for it (Ahmed Saleh, 15/7/2019)
      // {id: 'blacklisted_by', label: 'Blacklisted By', conditional: true, condition: 'black_listed'},
      // {id: 'blacklisted_reason', label: 'Blacklisting Reason', conditional: true, condition: 'black_listed'},
      // {id: 'submittedFrom', label: 'Submitted From', conditional: true, condition: 'black_listed'}
      ];

  var blacklistedSupplierColumns = [
    {id: 'basic_info|descr', label: 'Supplier Name', translationId: 'columns.supplierName'},
    {id: 'basic_info|code', label: 'Supplier Code', translationId: 'columns.supplierCode'},
    {id: 'basic_info|reg_no', label: 'ROC/ROB/NRIC', translationId: 'columns.rocRobNric'},
    {id: 'basic_info|currency_code', label: 'Preferred Currency', translationId: 'columns.preferredCurrency', unsortable: true, unsearchable: true},
    {id: 'basic_info|email', label: 'Email', translationId: 'columns.email'},
    {id: 'default_contract|contacts|name', label:'Contact person\'s name', translationId: 'columns.contactPersonName', unsortable: true, unsearchable: true},
    {id: 'default_contract|contacts|email', label: 'Contact person\'s email', translationId: 'columns.contactPersonEmail', unsortable: true, unsearchable: true},
    {id: 'default_contract|contacts|phone', label: 'Contact person\'s phone', translationId: 'columns.contactPersonPhone', unsortable: true, unsearchable: true},
    {id: 'default_contract|primary_business_nature', label: 'Primary Business Nature', translationId: 'columns.primaryBusinessNature'},
    {id: 'default_contract|primary_commodity_descr', label: 'Primary Commodity', translationId: 'columns.primaryCommodity'},
    {id: 'default_contract|secondary_commodity_descr', label: 'Secondary Commodity', translationId: 'columns.secondaryCommodity'},
    {
      id: 'updated_at',
      label: 'Blacklisted At',
      translationId: 'columns.blacklistedAt',
      type: 'date',
      unsearchable: true
    },
    {id: 'submission_info|submitter|display_name', label: 'Blacklisted By', translationId: 'columns.blacklistedBy'}
  ];

  var pendingSupplierColumns = [
    {id: 'check box', title: '', unsortable: true, unsearchable: true, conditional: true, condition: ['pending'], unSelectable: true},
    {id: 'edit_merged|basic_info|descr', label: 'Supplier Name', translationId: 'columns.supplierName'},
    {id: 'edit_merged|basic_info|code', label: 'Supplier Code', translationId: 'columns.supplierCode'},
    {id: 'edit_merged|waiting_on', label: 'Waiting on', translationId: 'columns.waitingOn', conditional: true, condition: ['pending'], unsortable: true, unsearchable: true},
    {id: 'edit_merged|basic_info|reg_no', label: 'ROC/ROB/NRIC', translationId: 'columns.rocRobNric'},
    {id: 'edit_merged|basic_info|currency_code', label: 'Preferred Currency', translationId: 'columns.preferredCurrency', unsortable: true, unsearchable: true},
    {id: 'edit_merged|basic_info|email', label: 'Email', translationId: 'columns.email'},
    {id: 'edit_merged|default_contract|contacts|name', label:'Contact person\'s name', translationId: 'columns.contactPersonName', unsortable: true, unsearchable: true},
    {id: 'edit_merged|default_contract|contacts|email', label: 'Contact person\'s email', translationId: 'columns.contactPersonEmail', unsortable: true, unsearchable: true},
    {id: 'edit_merged|default_contract|contacts|phone', label: 'Contact person\'s phone', translationId: 'columns.contactPersonPhone', unsortable: true, unsearchable: true},
    {id: 'edit_merged|default_contract|primary_business_nature', label: 'Primary Business Nature', translationId: 'columns.primaryBusinessNature'},
    {id: 'edit_merged|default_contract|primary_commodity_descr', label: 'Primary Commodity', translationId: 'columns.primaryCommodity'},
    {id: 'edit_merged|default_contract|secondary_commodity_descr', label: 'Secondary Commodity', translationId: 'columns.secondaryCommodity'},
    {
      id: 'edit_merged|submission_info|submitter|display_name',
      label: 'Submitter',
      translationId: 'columns.submitter',
    },
    {
      id: 'edit_merged|submission_info|submitter|time',
      label: 'Submision date',
      translationId: 'columns.submisionDate',
    }
  ];

  if ($scope.isSourcingSupplierEnabled) {
    var supplierType = {
      id: 'basic_info|is_sourcing_supplier',
      label: 'Supplier Type',
      translationId: 'columns.supplierType',
      unsearchable: true,
      unsortable: true
    };

    supplierColumns.splice(12, 0, supplierType);
    blacklistedSupplierColumns.splice(11, 0, supplierType);

    var pendingSupplierType = _.clone(supplierType);
    pendingSupplierType.id = 'edit_merged|' + pendingSupplierType.id;
    pendingSupplierColumns.splice(13, 0, pendingSupplierType);
  }

  var userColumns = [
    {id: 'basic_info|descr', label: 'Supplier Name', translationId: 'columns.supplierName'},
    {id: 'supplier_users|display_name', label: 'Supplier User Name', translationId: 'columns.supplierUserName'},
    {id: 'supplier_users|email', label: 'Email', translationId: 'columns.email'},
    {id: 'supplier_users|status', label: 'Status', translationId: 'columns.status', unsearchable: true},{
      id: 'supplier_users|user_detail|created_at',
      label: 'Created At',
      translationId: 'columns.createdAt',
      type: 'date',
      unsearchable: true
    },
    {
      id: 'supplier_users|user_detail|creator|display_name',
      label: 'Created By',
      translationId: 'columns.createdBy',
    },
    {
      id: 'supplier_users|user_detail|updated_at',
      label: 'Updated At',
      translationId: 'columns.updatedAt',
      type: 'date',
      unsearchable: true
    },
    {
      id: 'supplier_users|user_detail|updater|display_name',
      label: 'Updated By',
      translationId: 'columns.updatedBy',
      unsearchable: true
    },
    {
      id: 'supplier_users|user_detail|last_login',
      label: 'Last Login At',
      translationId: 'columns.lastLoginAt',
      type: 'date',
      unsearchable: true
    },
    {id: 'supplier_users|phone', label:'Phone Number', translationId: 'columns.phoneNumber', unsearchable: true}
  ];

  var pendingUserColumns = [
    {id: 'supplier_users|actions_history', label: 'Type', translationId: 'columns.supplierName'},
    {id: 'basic_info|descr', label: 'Supplier Name', translationId: 'columns.supplierName'},
    {id: 'supplier_users|display_name', label: 'Supplier User Name', translationId: 'columns.supplierUserName'},
    {id: 'supplier_users|email', label: 'Email', translationId: 'columns.email'},
    {id: 'supplier_users|status', label: 'Status', unsearchable: true, translationId: 'columns.status'},{
      id: 'supplier_users|user_detail|created_at',
      label: 'Created At',
      translationId: 'columns.createdAt',
      type: 'date',
      unsearchable: true
    },
    {
      id: 'supplier_users|user_detail|creator|display_name',
      label: 'Created By',
      translationId: 'columns.createdBy'
    },
    {
      id: 'supplier_users|user_detail|updated_at',
      label: 'Updated At',
      translationId: 'columns.updatedAt',
      type: 'date',
      unsearchable: true
    },
    {
      id: 'supplier_users|user_detail|updater|display_name',
      label: 'Updated By',
      translationId: 'columns.updatedBy',
      unsearchable: true
    },
    {
      id: 'supplier_users|user_detail|last_login',
      label: 'Last Login At',
      translationId: 'columns.lastLoginAt',
      type: 'date',
      unsearchable: true
    },
    {id: 'supplier_users|phone', label:'Phone Number', translationId: 'columns.phoneNumber', unsearchable: true}
  ];

  $scope.$on('$stateChangeStart', function( event, data, toParams ) {
    $scope.parentStateParams = toParams;
    if(!!toParams.status){
      $scope.status = toParams.status;
    }
    var searchPlaceholder = null;
    if(!!data.name && data.name.indexOf('supplierTagsBySupplierDetail')> -1){
      $scope.columns = supplierTagsByCompanyColumns;
      $scope.searchPlaceholder = 'Search Companies Tagged to Supplier';
      $scope.searchState = 'main.user.supplier.supplierTagsBySupplierDetail';
      $scope.parentState = 'main.user.supplier.supplierTagsBySupplierDetail';
    }
    else if(!!data.name && data.name.indexOf('supplierTagsByCompanyDetail')> -1){
      $scope.columns = supplierTagsBySupplierColumns;
      $scope.searchPlaceholder = 'Search Suppliers Tagged to Company';
      $scope.searchState = 'main.user.supplier.supplierTagsByCompanyDetail';
      $scope.parentState = 'main.user.supplier.supplierTagsByCompanyDetail';
    }
    else if(!!data.name && data.name.indexOf('manageSupplierUser')> -1) {
      if (toParams.status === 'pending') {
        $scope.columns = pendingUserColumns;
      } else {
        $scope.columns = userColumns;
      }
      searchPlaceholder = 'supplierUsers';
      $scope.searchPlaceholder = 'Search Supplier Users';
      $scope.searchState = 'main.user.supplier.manageSupplierUser';
      $scope.parentState = 'main.user.supplier.manageSupplierUser';
    }
    else if(!!data.name && data.name.indexOf('addSupplierUser')> -1) {
      searchPlaceholder = 'supplierUsers';
      $scope.searchPlaceholder = 'Search Supplier Users';
      $scope.searchState = 'main.user.supplier.manageSupplierUser';
      $scope.parentState = 'main.user.supplier.manageSupplierUser';
    }
    else if(!!data.name && data.name.indexOf('importSupplierUser')> -1) {
      searchPlaceholder = 'supplierUsers';
      $scope.searchPlaceholder = 'Search Supplier Users';
      $scope.searchState = 'main.user.supplier.manageSupplierUser';
      $scope.parentState = 'main.user.supplier.manageSupplierUser';
    }
    else{
      if(toParams.status === 'pending')
        $scope.columns = pendingSupplierColumns;
      else if (toParams.status === 'blacklisted')
        $scope.columns = blacklistedSupplierColumns;
      else
        $scope.columns = supplierColumns;

      searchPlaceholder = 'suppliers';
      $scope.searchPlaceholder = 'Search Suppliers';
      $scope.searchState = 'main.user.supplier.manage';
      $scope.parentState = 'main.user.supplier.manage';
    }

    var translateArr = [
      'searchPlaceholder.suppliers',
      'searchPlaceholder.supplierUsers',
    ];
    $translate(translateArr).then(function (trans) {
      if (searchPlaceholder !== null && trans['searchPlaceholder.' + searchPlaceholder] !== undefined) {
        $scope.searchPlaceholder = trans['searchPlaceholder.' + searchPlaceholder];
      }
    });
    var translateColumnArr = [
      "columns.supplierName",
      "columns.supplierCode",
      "columns.rocRobNric",
      "columns.preferredCurrency",
      "columns.email",
      "columns.contactPersonName",
      "columns.contactPersonEmail",
      "columns.contactPersonPhone",
      "columns.primaryBusinessNature",
      "columns.primaryCommodity",
      "columns.secondaryCommodity",
      "columns.supplierType",
      "columns.createdAt",
      "columns.createdBy",
      "columns.updatedAt",
      "columns.updatedBy",
      "columns.supplierUserName",
      "columns.status",
      "columns.lastLoginAt",
      "columns.phoneNumber",
      "columns.waitingOn",
      "columns.submitter",
      "columns.submisionDate",
      "columns.blacklistedAt",
      "columns.blacklistedBy",
    ];
    $translate(translateColumnArr).then(function (trans) {
      for(var columnIndex in $scope.columns) {
        var columnData = $scope.columns[columnIndex];
        if (trans[columnData.translationId] === undefined)
          continue;
        $scope.columns[columnIndex].label = trans[columnData.translationId];
      }
    });
  });

  initialize();

  $translate('pageTitle').then(function (trans) {
    $scope.$emit('pageTitleChanged', trans);
  }, function (translationId) {
    $scope.$emit('pageTitleChanged', translationId);
  });
  $scope.$emit('actionsChanged', []);
}
