'use strict';
angular
  .module('metabuyer')
  .directive('resetField', ['$compile', '$timeout', function ($compile, $timeout) {
    return {
      require: 'ngModel',
      restrict: 'A',
      scope: {
        right: '=?',
        resetField: '&resetField',
        ngModel: '=?'
      },
      link: function (scope, el, attrs, ctrl) {
        // limit to input element of specific types
        var inputTypes = /text|search|tel|url|email|password|number/i;
        if (el[0].nodeName !== "INPUT") {
          throw new Error("resetField is limited to input elements");
        }
        if (!inputTypes.test(attrs.type)) {
          throw new Error("Invalid input type for resetField: " + attrs.type);
        }
        // compiled reset icon template
        var template = $compile('<i ng-show="enabled" ng-mousedown="reset()" class="fa fa-remove" style="position: absolute; top: 0; bottom: 0; height: 14px; margin: auto auto auto -20px; font-size: 14px; cursor: pointer; color: #ccc; z-index: 999"></i>')(scope);
        el.after(template);

        scope.reset = function () {
          ctrl.$setViewValue(null);
          ctrl.$render();
          if (typeof scope.resetField == 'function') {
            scope.resetField();
          }
          $timeout(function () {
            el[0].focus();
          }, 0, false);
        };

        scope.watchNgModel = scope.$watch("ngModel", function(value) {
          scope.enabled = !ctrl.$isEmpty(value);
        });

        scope.$on('$destroy', function() {
          scope.watchNgModel();
        });

        el.bind('input', function () {
          scope.enabled = !ctrl.$isEmpty(el.val());
        })
          .bind('focus', function () {
            scope.enabled = !ctrl.$isEmpty(el.val());
            _.defer(function() {
              scope.$apply();
            });
          })
          .bind('blur', function () {
           // scope.enabled = false;
            _.defer(function() {
              scope.$apply();
            });
          });
      }
    };
  }]);
