'use strict';

/**
 *  @name loadCacheableData
 *
 * This service is for All cacheable data that used across the frontend.
 * This feature uses SearchaModule endpoints only
 * Many many master data is shared so to avoid calling the API unnecessarily we can cache data in 2 methods
 * 1- Local Storage (used for insensitive data, like master data)
 * 2- Memory (used for sensitive data, like list of Users)
 *
 * @requires $q, metabuyerCache, globalFunc
 * @returns {{loadData: loadData}}
 *
 * @authors Ahmed Saleh (a.s.alsalali@gmail.com)
 * @copyright Sunway Metacloud &copy; 2020
 */
function loadCacheableData($q, metabuyerCache, globalFunc, searchModule) {
  return {
    /**
     * loadData
     *
     * @param params      searchable parameters
     * @param cacheKey    the key for storing and retrieving data from the storage
     * @param storageType the type of storage, memory or localStorage
     */
    loadData: function (params, cacheKey, storageType) {
      var data;
      var deferred = $q.defer();
      if (storageType === 'localStorage')
        data = JSON.parse(window.localStorage.getItem(cacheKey));
      else
        data = metabuyerCache.get(cacheKey);

      if (!!data) {
        deferred.resolve(data);
      } else {
        searchModule.get(
          params,
          function (resource) {
            if (!!resource.content) {
              if (storageType === 'localStorage')
                window.localStorage.setItem(cacheKey, JSON.stringify(resource.content.data));
              else
                metabuyerCache.put(cacheKey, resource.content.data);

              deferred.resolve(resource.content.data);
            } else {
              deferred.resolve([]);
            }

          },
          function (error) {
            globalFunc.objectErrorMessage(error);
            deferred.resolve([]);
          });
      }
      return deferred.promise;
    }
  }
}

loadCacheableData.$inject = ['$q', 'metabuyerCache', 'globalFunc', 'searchModule'];

angular.module('metabuyer')
  .factory('loadCacheableData', loadCacheableData );
