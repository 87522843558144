'use strict';

/**
 *
 * A factory to control all sub Apps depending profile/browser/tenant
 *
 * @param $q
 * @param globalFunc
 * @param $rootScope
 * @param $ocLazyLoad
 * @returns {{}}
 */
function metabuyerAppsLoader($q, globalFunc, $rootScope, $ocLazyLoad) {

  //Start the options and default config here
  var basicConfig = {};
  var rules = [];
  var availableAppsUrls = [];

  //sample for blocking rules
  // rules.push({
  //   userBlock: false,
  //   IEBlock: true
  // });

  return {
    initLoad: function () {
      // This will be the Apps that loads in the initial run (used in index.js .run)
      // the purpose of this is we can specify tenant specific apps
      var configuration = [];
      configuration.push(basicConfig);
      return configuration;
    },
    userAppsLoad: function () {
      //This will be where the customization for the user apps loading is
      //All metabuyer Apps will be uploaded to S3 and can be loaded from there using the lazyLoader
      if(!rules)
      {
        this.requestUserAppRules().then(function (userRules) {
          this.prepareAppsUrl();
        })
      }
      //move outside to call after promise
      //this needs to be sync to be
      this.loadAllAvailableApps();

    },

    loadAllAvailableApps: function () {
      //load the apps based on the rules
      // availableAppsUrls.push('https://s3-ap-southeast-1.amazonaws.com/metabuyer-public-bucket/FrontEndBreakProject/testFile.js');
      // availableAppsUrls.push('https://s3-ap-southeast-1.amazonaws.com/metabuyer-public-bucket/FrontEndBreakProject/testFile.js');

      //We can add a callback or promise to control and check the apps loading
      $ocLazyLoad.load(availableAppsUrls);

    },

    loadAppsOnDemand: function(appsUrls){
      //load list of apps when needed
      $ocLazyLoad.load(appsUrls);
    },

    requestUserAppRules: function () {
      var deferred = $q.defer();
      //These should be loaded based on the System Admin configurations and stored in the backed server
      //The configuration can be stored in based on design (to group, to user, to tenant)
      //A default value will be provided
      rules = ['promise'];
      deferred.resolve('promise');
      return deferred.promise;
    },

    prepareAppsUrl: function () {
      //push the urls to be lazy loaded
      var availableAppsUrls = [];
    },

    getRules: function () {
      return rules;
    }
  }
}

metabuyerAppsLoader.$inject = ['$q','globalFunc', '$rootScope', '$ocLazyLoad'];


angular
  .module('metabuyer')
  .factory('metabuyerAppsLoader', metabuyerAppsLoader);
