'use strict';

/**
 * Controller for email items listing
 * @param $scope
 * @param emailLogs
 */

function SuppliersWebEmailLogManageCtrl($scope, emailLogs, $state) {

  $scope.dataList = emailLogs.data;
  $scope.meta = emailLogs.meta;
  $scope.showColumn = showColumn;
  $scope.storageKey = 'email-logs-view-selected-column';

  $scope.tabData = [
    {
      heading: 'Email Logs',
      route: 'main.user.suppliersWebEmailLog.manage',
      key: 'emails',
      params: {
        status: '',
        cursor: null,
        filter: null,
        query: null,
        type: null
      }
    }
  ];

  $scope.columns = [
    {id: 'receiver_email', label: 'Receiver Email'},
    {id: 'subject', label: 'Subject'},
    {id: 'created_at', label: 'Email sent', unsearchable: true},
    {id: 'updated_at', label: 'Email updated', unsearchable: true},
    {id: 'status', label: 'Status', unsearchable: true}
  ];

  function showColumn (id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function initialize() {
    if (!!$state.params && !!$state.params.query) {
      $scope.searchText = $state.params.query;
    }
    else {
      $scope.searchText = '';
    }

    $scope.searchPlaceholder = 'Search Email';
    $scope.searchState = 'main.user.suppliersWebEmailLog.manage';
    $scope.parentState = 'main.user.suppliersWebEmailLog.manage';
    $scope.parentStateParams = $state.params;
  }

  initialize();

}

SuppliersWebEmailLogManageCtrl.$inject = ['$scope', 'emailLogs', '$state'];

angular.module('metabuyer')
  .controller('SuppliersWebEmailLogManageCtrl', SuppliersWebEmailLogManageCtrl);
