'use strict';

/**
 * @name itemListing Directive
 * @desc item listing that is used in the budget
 *
 * @isolatedScope data
 * To synchronize items data
 *
 * @isolatedScope budget
 * To accept budget id that using this directive
 *
 * @isolatedScope assignment
 * To accept budget assignment id that using this directive, optional
 *
 * @author H.W. Liew <liewhanwahwork@gmail.com>
 * @copyright 2017 Metacloud Sdn. Bhd.
 */
angular
  .module('metabuyer')
  .directive('budgetItemListingV2', function () {
    return {
      restrict: 'E',
      templateUrl: 'components/budgetV2/budgetItemListing/budgetItemListing.html',
      scope: {
        budget: '=',
        assignment: '=',
        listingType: '@?'
      },
      controller: function($scope, globalFunc, toastr, budgets, $http, pathConstants, $filter, budgetItems, prV2Function) {
        $scope.budgets = budgets;
        $scope.itemData = [];
        $scope.selectedTab = 'used';
        $scope.itemMeta = {};
        $scope.budgetItems = budgetItems;
        $scope.emitResults = true;
        $scope.hidePaginationNumbers = true;
        $scope.$on('embeddedPaginationResultsEmit', function (event, data) {
          processItemsBreakDown(data);
        });
        $scope.embeddedParams = {
          id: $scope.budget,
          type: $scope.selectedTab,
          offset: 10,
          page: null,
          cursor: null
        };

        var allowedOtherCharger = globalFunc.getAllowedOtherCharges();

        $scope.selectTab = selectTab;
        $scope.getItemListing = getItemListing;
        $scope.exportItem = exportItem;
        $scope.checkLoading = checkLoading;

        function exportItem() {
          if ($scope.itemData.length > 0) {
            var path = pathConstants.apiUrls.budget.export;
            path = path.replace(':id', $scope.budget);
            path = path.replace(':type', $scope.selectedTab);

            var assignmentId = !!$scope.assignment ? $scope.assignment : '';
            path = path.replace(':assignmentId', assignmentId);

            $http({
              method: 'GET',
              url: path
            }).then(function(response){
              var blob = new Blob([response.data]);
              var link = document.createElement('a');
              document.body.appendChild(link);
              link.href = window.URL.createObjectURL(blob);
              link.download = 'Budget-Items-'+ $scope.selectedTab.toUpperCase()+ '-' + $filter('date')(new Date(), 'dd-MMM-yyyy') +'.csv';
              link.click();
              toastr.success('Item(s) has been successfully exported.');
            }, function() {
              toastr.error('Unable to export item(s).');
            });
          } else {
            toastr.info('Item is empty.');
          }
        }

        // called when switching type of items
        function selectTab(tab) {
          $scope.selectedTab = tab;
          $scope.embeddedParams.type = $scope.selectedTab

          getItemListing();
        }

        function checkLoading(loadingObject) {
          return prV2Function.getLoading(loadingObject);
        }


        function calculateTotalDiscountPerLineForPo(po) {
          var total = 0;
          var exchangeRate = _.isArray(po.items) ? po.items[0].exchange_rate : po.items.exchange_rate;
          var item = _.isArray(po.items) ? po.items[0] : po.items;
          total += (!!item.discount && !!item.discount[0].amount) ? item.discount[0].amount * exchangeRate : 0;
          _.forEach(allowedOtherCharger, function (other) {
            total += (!!item[other.code] && !!item[other.code].discount)
              ? item[other.code].discount.amount * exchangeRate : 0
          });

          return total;
        }

        function calculateTotalAmountPerLineForPo(po) {
          var total = 0;
          var exchangeRate = _.isArray(po.items) ? po.items[0].exchange_rate : po.items.exchange_rate;
          var item = _.isArray(po.items) ? po.items[0] : po.items;
          total += item.amount * exchangeRate;
          _.forEach(allowedOtherCharger, function (other) {
            total += (!!item[other.code] && !!item[other.code].amount)
              ? item[other.code].amount * exchangeRate : 0
          });

          return total;
        }

        function calculateTotalQtyPerLineForPo(po, consolidation) {
          var total = 0;
          total += _.isArray(po.items) ? po.items[0].quantity : po.items.quantity;
          total += !_.isEmpty(consolidation) ? consolidation.items[0].remaining_qty[0] : 0;

          return total;
        }

        function calculateTotalTaxPerLineForPo(po) {
          var total = 0;
          var exchangeRate = _.isArray(po.items) ? po.items[0].exchange_rate : po.items.exchange_rate;
          var item = _.isArray(po.items) ? po.items[0] : po.items;
          total += (!!item.tax_amount) ? item.tax_amount * exchangeRate : 0;
          _.forEach(allowedOtherCharger, function (other) {
            total += (!!item[other.code] && !!item[other.code].tax)
              ? item[other.code].tax.amount * exchangeRate : 0
          });

          return total;
        }

        function calculateTotalDiscountPerLineForPr(item) {
          var total = 0;
          var exchangeRate = item.exchange_rate;
          total += !!item.discount ? item.discount * exchangeRate : 0;
          _.forEach(allowedOtherCharger, function (other) {
            total += (!!item[other.code] && !!item[other.code].discount_amount_pr_currency)
              ? item[other.code].discount_amount_pr_currency : 0
          });

          return total;
        }

        function calculateTotalAmountPerLineForPr(item) {
          var total = 0;
          total += item.amount_pr_currency;
          _.forEach(allowedOtherCharger, function (other) {
            total += (!!item[other.code] && !!item[other.code].amount_pr_currency)
              ? item[other.code].amount_pr_currency : 0
          });

          return total;
        }

        function calculateTotalTaxPerLineForPr(item) {
          var total = 0;
          total += !!item.tax_amount_pr_currency ? item.tax_amount_pr_currency : 0;
          _.forEach(allowedOtherCharger, function (other) {
            total += (!!item[other.code] && !!item[other.code].tax_amount_pr_currency)
              ? item[other.code].tax_amount_pr_currency : 0
          });

          return total;
        }

        function processCommittedItems(items, tab) {
          var list = [];

          _.forEach(items, function (item) {
            if(item.pos && item.pos.length > 0){
              if (tab == 'committed' && item.consolidation.status === 'PARTIALLY_UTILIZED' && item.qty > 0) {
                list.push({
                  amount: item.amount_pr_currency,
                  quantity: item.qty,
                  unit_price: item.unit_price,
                  discount: [item.discount_pr_currency],
                  tax: [item.tax_amount_pr_currency],
                  pr_number: item.pr_number,
                  pr_id: item.pr_id,
                  po_number: '',
                  po_id: '',
                  item_name: item.item_name,
                  item_descr: item.item_descr
                });
              }
              _.forEach(item.pos, function (po) {
                if ((tab == 'committed')) {
                  list.push({
                    amount: po.items[0].amount_pr_currency,
                    quantity: po.items[0].quantity,
                    unit_price: po.items[0].unit_price_pr_currency,
                    discount: [po.items[0].discount[0].pr_currency_amount],
                    tax: [po.items[0].tax_amount_pr_currency],
                    pr_number: item.pr_number,
                    pr_id: item.pr_id,
                    po_number: po.po_number,
                    po_id: !!po.po_id ? po.po_id : po._id,
                    item_name: item.item_name,
                    item_descr: item.item_descr
                  });
                } else if (tab == 'used') {
                  list.push({
                    amount: item.amount_pr_currency,
                    quantity: item.qty,
                    unit_price: item.unit_price,
                    discount: [item.discount_pr_currency],
                    tax: [item.tax_amount_pr_currency],
                    pr_number: item.pr_number,
                    pr_id: item.pr_id,
                    po_number: po.po_number,
                    po_id: !!item.po_id ? item.po_id : po._id,
                    item_name: item.item_name,
                    item_descr: item.item_descr
                  });
                } else if (tab == 'refunded') {
                  list.push({
                    amount: po.items[0].amount_pr_currency,
                    quantity: !!po.items[0].quantity ? po.items[0].quantity : po.items[0].qty,
                    unit_price: po.items[0].unit_price_pr_currency,
                    discount: [po.items[0].discount_pr_currency],
                    tax: [po.items[0].tax_amount_pr_currency],
                    pr_number: item.pr_number,
                    pr_id: item.pr_id,
                    po_number: item.po_number,
                    po_id: !!po.po_id ? po.po_id : po._id,
                    item_name: item.item_name,
                    item_descr: item.item_descr
                  });
                }
              })

            } else {
              if (tab == 'committed') {
                if (item.qty > 0) {
                  list.push({
                    amount: item.amount_pr_currency,
                    quantity: item.qty,
                    unit_price: item.unit_price,
                    discount: [item.discount_pr_currency],
                    tax: [item.tax_amount_pr_currency],
                    pr_number: item.pr_number,
                    pr_id: item.pr_id,
                    po_number: '',
                    po_id: '',
                    item_name: item.item_name,
                    item_descr: item.item_descr
                  });
                }
              } else {
                list.push({
                  amount: calculateTotalAmountPerLineForPr(item),
                  quantity: !!item.consolidation && !item.consolidation.items ?
                    item.consolidation.items[0].remaining_qty : item.qty,
                  unit_price: item.unit_price,
                  discount: [calculateTotalDiscountPerLineForPr(item)],
                  tax: [calculateTotalTaxPerLineForPr(item)],
                  pr_number: item.pr_number,
                  pr_id: item.pr_id,
                  item_name: item.item_name,
                  item_descr: item.item_descr
                });
              }
            }
          });
          $scope.itemData = list;

        }

        // used and refunded items' PO is an object, while committed items PO is an array
        function prepareItems(items) {
          _.forEach(items, function(item) {
            // convert to object so we can reuse the calculation functions
            item.pos = [item.pos];
          });

          return items;
        }

        function processItemsBreakDown(resource) {
          //TODO: wrap this in a switch cases for all tabs
          switch ($scope.selectedTab) {
            case 'committed':
              $scope.itemMeta = resource.meta;
              processCommittedItems(resource.data, 'committed');
              break;
            case 'used':
              processCommittedItems(prepareItems(resource.data), 'used');
              $scope.itemMeta = resource.meta;
              break;
            case 'refunded':
              processCommittedItems(prepareItems(resource.data), 'refunded');
              $scope.itemMeta = resource.meta;
              break;
            default:
              $scope.itemData = resource.data;
              $scope.itemMeta = resource.meta;
          }
        }

        function getItemListing() {
          prV2Function.setLoading('budgetItemTable', true);
          $scope.itemData = [];
          $scope.itemMeta = [];
          budgetItems.get(
            {
              id: $scope.budget,
              type: $scope.selectedTab,
              offset: 10,
              page: null,
              cursor: null
            },
            function(resource){
              if (!!resource && !!resource.data) {
                prV2Function.setLoading('budgetItemTable', false);
                processItemsBreakDown(resource)
              }
            },
            function(error){
              if (!!error.data.content && !!error.data.content.message) {
                toastr.error(error.data.content.message.join('</br>'));
              } else {
                globalFunc.objectErrorMessage(error);
              }
            }
          )
        }

        function initialize() {
          getItemListing();
        }

        initialize();
      }
    };
  });
