'use strict';

/**
 * @name diffVersion directive
 *
 * @description
 * to display diff between two values in different currencies
 *
 * @copyright 2021 Metacloud Sdn. Bhd.
 */

angular
  .module('metabuyer')
  .directive('diffVersionV2', function() {
    return {
      restrict: 'E',
      scope: {
        prevVal: '=',
        newVal: '=',
        filterDecimal: '=?',
        lineBreak: '=',
        newValDecimal: '=',
        prevValDecimal: '=',
        currencyCode: '=?'
      },
      replace: true,
      templateUrl: 'components/diffVersionV2/diffVersionV2.html',
      link: function($scope) {
        $scope.filterDecimal = $scope.filterDecimal || 2;
      }
    }
  });
