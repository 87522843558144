'use strict';

function alcActionCtrl(
  $scope, $state, Alc, globalFunc, toastr, lang, UserPermissions, $rootScope, AlcDraft, HighlightId, updateStatusAlc, $uibModal
) {
  $scope.back = back;
  $scope.editMode = false;
  $scope.submitAlc = submitAlc;
  $scope.saveAlcDraft = saveAlcDraft;
  $scope.withdrawAlc = withdrawAlc;
  $scope.showUpdateDraft = showUpdateDraft;
  $scope.openRevision = openRevision;
  $scope.cancel = cancel;
  $scope.clonedAlc = {};
  $scope.isEditMode = false;
  $scope.draftUuid = '';
  $scope.currentUser = $rootScope.currentUser || {_id: null};
  $scope.isCreator = $scope.alcData.created_by === $scope.currentUser._id;

  function openRevision(currentAlc, previousUuid, version) {
    $uibModal.open({
        templateUrl: 'components/alc/revisionComparison/revisionComparison.html',
        backdrop: 'static',
        keyboard: false,
        controller: 'alcVersionCtrl',
        size: 'lg',
        windowClass: 'revision-wrapper',
        resolve: {
            currentVersion: function(){
                return currentAlc;
            },
            previousVersion: function($q, Alc){
                var deferred = $q.defer();
                Alc.get(
                  {uuid: previousUuid},
                  function (resource) {
                    if (!!resource.content && !!resource.content.data) {
                      deferred.resolve(resource.content.data);
                    }
                  },
                  function (error) {
                    globalFunc.objectErrorMessage(error);
                  }
                );

                return deferred.promise;
            },
            versionNumber: function () {
                return version;
            }
        }
    });
  }

  function validate(){
    if (!$scope.alcData.code){
      toastr.error(lang.validation.required.input + 'ALC code');
      return false
    }
    if (!$scope.alcData.name){
      toastr.error(lang.validation.required.input + 'ALC name');
      return false
    }
    if (!$scope.alcData.alc_group){
      toastr.error(lang.validation.required.input + 'ALC group');
      return false
    }
    if (!$scope.alcData.position){
      toastr.error(lang.validation.required.input + 'position');
      return false
    }
    if (!$scope.alcData.currency){
      toastr.error(lang.validation.required.input + 'currency');
      return false
    }
    if (!$scope.alcData.approval_limit_pr){
      toastr.error(lang.validation.required.input + 'Approval limit per PR');
      return false
    }
    if (!$scope.alcData.start_date){
      toastr.error(lang.validation.required.input + 'start date');
      return false
    }
    if (!$scope.alcData.end_date){
      toastr.error(lang.validation.required.input + 'end date');
      return false
    }

    return true
  }

  function prepareData() {
    var data = {
      code: !!$scope.alcData.code ? $scope.alcData.code : undefined,
      name: !!$scope.alcData.name ? $scope.alcData.name : undefined,
      alc_group_code: !!$scope.alcData.alc_group ? $scope.alcData.alc_group.code : undefined,
      item_master_code: !!$scope.alcData.item_master ? $scope.alcData.item_master.code : undefined,
      currency_code: !!$scope.alcData.currency ? $scope.alcData.currency.code : undefined ,
      approval_limit_pr: !!$scope.alcData.approval_limit_pr ? $scope.alcData.approval_limit_pr : undefined,
      approval_limit_month: !!$scope.alcData.approval_limit_month ? $scope.alcData.approval_limit_month : undefined,
      start_date: !!$scope.alcData.start_date ? globalFunc.convertDateToTimestamp($scope.alcData.start_date) : undefined,
      end_date: !!$scope.alcData.end_date ? globalFunc.convertDateToTimestamp($scope.alcData.end_date) : undefined,
      remarks: !!$scope.alcData.remarks ? $scope.alcData.remarks : undefined,
      status: $scope.alcData.status,
      is_revised: !!$scope.alcData.is_revised ? $scope.alcData.is_revised : undefined
    };

    if ($scope.alcData.status === 'DRAFT') {
      data.draft_uuid = !!$scope.alcData.uuid ? $scope.alcData.uuid : undefined
    }

    if (!!$scope.alcData.position) {
      data.position = {
        code: $scope.alcData.position.code,
        name: $scope.alcData.position.name
      };
    } else {
      data.position = undefined;
    }

    return data;
  }

  function withdrawAlc(id) {
    Alc.approve(
      {
        approval_id: $scope.alcData.approval_id,
        status: 'withdraw'
      },
      {
        comment: ''
      },
      function () {
        toastr.success('Successfully withdraw ALC');
        HighlightId.setId(id);
        $state.go('main.user.alc.manage', {
            'status'    : 'DRAFT',
            'order_by'  : 'updated_at',
            'order'     : 0
          });
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function submitAlc(type) {
    if (type === 'revision' ||
      _.isEmpty($scope.alcData.uuid) ||
      !_.isEmpty($scope.alcData.uuid) && $scope.alcData.status === 'DRAFT' ) {
      createAlc(type);
    } else {
      updateAlc($scope.alcData.status);
    }
  }

  function saveAlcDraft() {
    var alcData = prepareData();

    if (!$scope.alcData.uuid) {
      createAlcDraft(alcData);
    } else {
      updateAlcDraft(alcData);
    }
  }

  function createAlcDraft(alcData) {
    AlcDraft.post(
      alcData,
      function (resource) {
        $scope.draftUuid = resource.content.data.uuid;
        toastr.success('ALC draft successfully saved.');
        $state.go('main.user.alc.manage', {status: 'all'});
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function updateAlcDraft(alcData) {
    AlcDraft.put(
      {
        uuid: $scope.alcData.uuid
      },
      alcData,
      function (resource) {
        toastr.success('ALC draft update successfully saved.');
        $state.go('main.user.alc.manage', {status: 'all'});
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function createAlc(type){
    if (validate()){
      var newData = prepareData();
      if (!_.isEmpty(type) || $scope.alcData.is_revised) {
        newData.type = 'revision';
        newData.reference = $scope.alcData.reference;
      }

      if (!!newData.status && newData.status.toLowerCase() === 'draft') {
        updateAlc(newData.status);
      } else {
        Alc.post(
          newData,
          function(response){
            if (!!response.content && !!response.content.data){
              if (type === 'revision') {
                toastr.success("ALC successfully revised");
                $state.go('main.user.alc.details', {id: response.content.data.uuid});
              } else {
                toastr.success("ALC successfully created");
                $state.go('main.user.alc.manage', {status:'all'});
              }
            }
          },
          function(error){
            globalFunc.objectErrorMessage(error);
          }
        )
      }
    }
  }

  function cancel() {
    if ($scope.alcData.status === 'edit') {
      $scope.alcData.status = 'active';
      $rootScope.$broadcast('switchAlcTemplate', 'nonEditable', 'cancel');
    } else {
      goToListing()
    }
  }

  function updateAlc(status){
    if (validate()){
      var newData = prepareData();
      if (!!status && status.toLowerCase() === 'draft') {
        newData.status = 'PENDING';
      } else if (!!status && status.toLowerCase() === 'edit') {
        newData.status = 'PENDING'
      } else {
        newData.status = 'ACTIVE';
      }
      Alc.put(
        {
          uuid: $scope.alcData.uuid
        },
        newData,
        function(response){
          if (!!response.content && !!response.content.data){
            if (response.content.data.status.toLowerCase() === 'pending') {
              toastr.success("ALC successfully created");
              $state.go('main.user.alc.manage', {status:'all'});
            } else {
              toastr.success('ALC successfully updated');
              $scope.alcData.status = 'active';
              $rootScope.$broadcast('switchAlcTemplate', 'notEditable');
            }
          }
        },
        function(error){
          globalFunc.objectErrorMessage(error);
        }
      )
    }
  }


  function showUpdateDraft() {
    return !_.isEmpty($scope.alcData) && $scope.alcData.status === 'DRAFT';
  }

  function goToListing(){
    $state.go('main.user.alc.manage');
  }

  function back() {
    $state.go('main.user.alc.manage', {status: 'all'});
  }


  function initialize(){
    if ($scope.templateMode === 'new') {
      $scope.editMode = true;
    } else {
      if (['draft'].indexOf($scope.alcData.status.toLowerCase()) === -1) {
        $scope.editMode = false;
      } else {
        $scope.editMode = true;
      }
    }
  }

  initialize();

}

alcActionCtrl.$inject = [
  '$scope', '$state', 'Alc', 'globalFunc',
  'toastr', 'lang' , 'UserPermissions', '$rootScope',
  'AlcDraft', 'HighlightId', 'updateStatusAlc', '$uibModal'
];

angular
  .module('metabuyer')
  .directive('alcDetailsAction',function() {
    return {
      restrict: 'E',
      scope: {
        alcData: '=',
        isApproved: '=',
        isAlcRequester: '=',
        templateMode: '='
      },
      templateUrl: 'components/alc/detailsAction/detailsAction.html',
      controller: 'alcActionCtrl'
    }
  })
  .controller('alcActionCtrl', alcActionCtrl);
