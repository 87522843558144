'use strict'
/**
 *
 * @param {*} $scope
 * @param {*} toastr
 * @param {*} lang
 * @param {*} holidayServices
 * @param {*} getsingleHoliday
 * @param {*} $uibModalInstance
 * @param {*} $state
 */

function holidayModalCtrl($scope, toastr, lang, holidayServices, getsingleHoliday,
  $uibModalInstance, $state){
  $scope.holiday       = getsingleHoliday;
  $scope.editingState  = true;
  $scope.close         = close;
  $scope.submitHoliday = submitHoliday;
  $scope.deleteHoliday = deleteHoliday;

  $scope.holidayDateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };

  $scope.datepickerOpened = {
    holiday_date: false,
  };

  $scope.openDatepicker = openDatepicker;

  /**
   * For date picker
   *
   * @param {Object} $event
   * @param {string} which - holiday date
   */
  function openDatepicker($event, which) {
    $event.preventDefault();
    $event.stopPropagation();
    $scope.holidayDateOptions.minDate = new Date();
    $scope.datepickerOpened[which] = true;
  }

  function holidayValidation() {
    if (!$scope.holiday.date) {
      toastr.error(lang.validation.required.input + 'holiday date.');

      return false;
    }
    if (!$scope.holiday.description) {
      toastr.error(lang.validation.required.input + 'holiday description.');

      return false;
    }

    return true;
  }

  function updateHoliday(holiday) {
    swal({
        title: 'Confirm update?',
        text: 'This holiday item will be updated',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (!!isConfirm) {
          var submitData = submitHolidayData();
          holidayServices.put(
            {
              id: holiday._id
            },
            submitData,
            function () {
              toastr.success('Holiday has been updated');
              close();
              $state.reload();
            }, function () {
              toastr.error('This holiday item cannot be updated.');
            }
          );
        }
      });
  }

  function submitHolidayData() {
    return {
      'date'            : $scope.holiday.date.getTime()/1000,
      'description'     : $scope.holiday.description,
      'company_code'    : $scope.holiday.company_code
    };
  }

  function submitHoliday(action, holiday) {
    $scope.formSubmitted = false;
    if (holidayValidation(action)) {
    if (action === 'update')
    {
      updateHoliday(holiday);
    }
    else
      submitHolidayData(holiday);
    }
  }

  function deleteHoliday(holiday) {
    swal({
        title: 'Confirm delete?',
        text:  'This holiday item will be deleted',
        type:  'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (isConfirm) {
          holidayServices.delete(
          {
              id: holiday._id
          },
            function () {
              toastr.success('Holiday deleted');
              close();
              $state.reload();
            },
            function () {
              toastr.error('This holiday item cannot be deleted.');
            }
          );
        }
      });
  }

  function close(){
    $uibModalInstance.close();
  }

  initialize();

  function initialize(){
    $scope.holiday         = _.cloneDeep(getsingleHoliday);
    var DateClone          = _.cloneDeep($scope.holiday.date);
    var date               = new Date(DateClone).getTime();
    var holidaydate        = new Date(date);
    $scope.holiday.date    = holidaydate;
  }
}

holidayModalCtrl.$inject =   ['$scope', 'toastr', 'lang', 'holidayServices', 'getsingleHoliday',
'$uibModalInstance', '$state'];

angular
  .module('metabuyer')
  .controller(holidayModalCtrl, 'holidayModalCtrl');
