'use strict';

angular.module('metabuyer')
  .factory('ExchangeRate', function($resource, pathConstants) {
    var ExchangeRate = $resource(
      pathConstants.apiUrls.exchangeRate.single,
      {
        id: '@id',
        source_id: '@source_id',
        target_id: '@target_id'
      },
      {
        get: { method: 'GET' }
      }
    );

    return ExchangeRate;
  })
  /**
   * To get and post global level exchange rate, send 'global' for company_id
   */
  .factory('exchangeRateMasterDataList',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.exchangeRate.masterList,
      {
        company_id: '@company_id'
      },
      {
        'get': {method: 'GET'},
        'post': {method: 'POST'}
      }
    );
  })
  .factory('exchangeRateSpecificRate',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.exchangeRate.rate,
      {
        id: '@id',
        rate_id: '@rate_id'
      },
      {
        'get': {method: 'GET'},
        'put': {method: 'PUT'}
      }
    );
  })
  .factory('exchangeRateSearch',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.exchangeRate.search,
      {
        company_id: '@company_id',
        query: '@query'
      },
      {
        'get': { method: 'GET' }
      }
    );
  });
