'use strict';

angular.module('metabuyer.services.companyOtherChargesGroup', ['ngResource'])
  .factory('companyOtherChargesGroupService',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.companyOtherChargesGroup.single,
        {
          id: '@id'
        },
        {
          'post': {method: 'POST'}
        }
      );
    })
    .factory('getAllOtherCharges',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.getOtherCharges.all,
      {
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('getOtherChargesGroup',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.getOtherChargesGroup.single,
      {
        uuid: '@uuid'
      },
      {
        'get': {method: 'GET'},
        'put': {method: 'PUT'}
      }
    );
  });

