'use strict';

/**
 * @name SupplierWebUsersEditCtrl
 * @desc Controller for users edit
 */
function SupplierWebUsersEditCtrl($scope, $q, $resource, $state, globalFunc, toastr, editedUser, $uibModal,
                                  supplierWebRoles, supplierWebUser, userRoleAssignments) {
  $scope.user = editedUser;
  $scope.user.employee_number = $scope.user.emp_no;
  $scope.submitted = false;
  $scope.addToSelectedRole = addToSelectedRole;
  $scope.removeSelectedRole = removeSelectedRole;

  $scope.cancelEditUser = function() {
    window.history.back();
  };

  $scope.saveUser = function() {
    $scope.submitted = true;
    var submitData = {
      name: $scope.user.display_name,
      phone: $scope.user.phone,
      accepted_supplier_web_tnc: $scope.user.config.accepted_supplier_web_tnc
    };

    supplierWebUser.put(
      {
        id: $scope.user._id
      },
      submitData,
      function () {
        updateUserRoleAssignment();
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      });
  };

  function updateUserRoleAssignment() {
    var submitData = [];

    // Get the current user role assignments
    _.forEach($scope.user.role_assignments, function (roleAssignment) {
      var assignmentPayload = {};

      // Update system level role assignment if any
      if (roleAssignment.weight === 1) {
        // Remove supplier web role values from the roles array
        roleAssignment.roles = globalFunc.removeValueFromArray(
          roleAssignment.roles,
          'name',
          'SUPPLIER_WEB_ADMIN'
        );
        roleAssignment.roles = globalFunc.removeValueFromArray(
          roleAssignment.roles,
          'name',
          'SUPPLIER_WEB_USER'
        );
        roleAssignment.roles = globalFunc.removeValueFromArray(
          roleAssignment.roles,
          'name',
          'SUPPLIER_WEB_COMPANY_USER'
        );

        // Get system role assignment role names
        var systemAssignmentWithoutWebRoles = globalFunc.getArrayOfKeyValues(roleAssignment.roles, 'name');

        assignmentPayload = {
          'organization_id': roleAssignment.organization_id,
          'weight': roleAssignment.weight,
          'roles': _.union(systemAssignmentWithoutWebRoles,
            globalFunc.getArrayOfKeyValues($scope.selectedSupplierWebRoles, 'name'))
        };
      }

      // Get other role assignments
      if (roleAssignment.weight !== 1) {
        assignmentPayload = {
          'organization_id': roleAssignment.organization_id,
          'weight': roleAssignment.weight,
          'roles': globalFunc.getArrayOfKeyValues(roleAssignment.roles, 'name')
        };
      }

      submitData.push(assignmentPayload);
    });

    // If user role assignments do not have system level role assignment
    if (!globalFunc.findInArray($scope.user.role_assignments, 'weight', 1)) {
      var assignmentPayload = {
        'organization_id': $scope.user.tenant_id,
        'weight': 1,
        'roles': globalFunc.getArrayOfKeyValues($scope.selectedSupplierWebRoles, 'name')
      };
    submitData.push(assignmentPayload);
    }

    userRoleAssignments.put(
      {
        id: $scope.user._id
      },
      {
        'roleAssignments': submitData
      },
      function () {
        toastr.success('Changes has been saved.');
        window.history.back();
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function addToSelectedRole(role) {
    if (!globalFunc.findInArray($scope.selectedSupplierWebRoles, '_id', role._id)) {
      $scope.selectedSupplierWebRoles.push(role);
    }
  }

  function removeSelectedRole(role) {
    $scope.selectedSupplierWebRoles = globalFunc.removeValueFromArray($scope.selectedSupplierWebRoles, '_id', role._id);
  }

  /**
   * Get selected supplier web roles
   *
   * @param supplierWebRoles
   */
  function getSelectedSupplierWebRoles(supplierWebRoles) {
    $scope.selectedSupplierWebRoles = [];
    var selectedSupplierWebRole = {};
    // First get system role assignment if any
    var systemRoleAssignment = globalFunc.findInArray($scope.user.role_assignments, 'weight', 1);

    if (!!systemRoleAssignment) {
      _.forEach(supplierWebRoles, function(supplierWebRole) {
        selectedSupplierWebRole = globalFunc.findInArray(systemRoleAssignment.roles, 'name', supplierWebRole.name);

        // Push selected supplier web role if any
        if (!!selectedSupplierWebRole) {
          $scope.selectedSupplierWebRoles.push(selectedSupplierWebRole);
          $scope.supplierWebRoles = globalFunc.removeValueFromArray(
            $scope.supplierWebRoles,
            'name',
            supplierWebRole.name
          );
        }
      });
    }
  }

  function initialize(){
    $scope.supplierWebRoles = supplierWebRoles;
    getSelectedSupplierWebRoles($scope.supplierWebRoles);
  }

  initialize();
}

SupplierWebUsersEditCtrl.$inject = ['$scope', '$q', '$resource', '$state', 'globalFunc', 'toastr', 'editedUser',
  '$uibModal', 'supplierWebRoles', 'supplierWebUser', 'userRoleAssignments'];

angular
  .module('metabuyer')
  .controller('SupplierWebUsersEditCtrl', SupplierWebUsersEditCtrl);


