'use strict';

/**
 * @name compareTenderVersionCtrl
 * @desc The controller for comparing tender versions data
 *
 * @copyright 2022 Metacloud Sdn. Bhd.
 */
function compareTenderVersionCtrl($scope, $uibModal, $uibModalInstance, tender, tenderVersions, mode, selectedVersion) {
  $scope.close = close;
  $scope.tenderVersions = tenderVersions;
  $scope.tender = tender;
  $scope.mode = mode;
  $scope.selectedVersion = selectedVersion;
  $scope.tab = 1;
  $scope.toggleTab = toggleTab;
  $scope.compareVal = compareVal;
  $scope.approverList = [];
  $scope.componentList = [];
  $scope.supplierList = [];
  $scope.categoryV0 = $scope.selectedVersion[0].data.detail.category.key;
  $scope.categoryV1 = $scope.selectedVersion[1].data.detail.category.key;
  $scope.differentCatAmount = false;
  $scope.differentCatUnit = false;

  if ($scope.categoryV0 !== $scope.categoryV1) {
    if ($scope.categoryV1 === 'UNIT_PRICE') {
      $scope.differentCatAmount = true;
    } else {
      $scope.differentCatUnit = true;
    }
  }

  function toggleTab(tab) {
    return $scope.tab = tab;
  }

  function compareVal(date1, date2) {
    return date1 === date2;
  }

  function getApproverList() {
    $scope.approverList = _.cloneDeep($scope.selectedVersion[1].data.approvers);

    _.forEach($scope.approverList, function(e) {
      e.added = true;
    })

    // combine the previous list and current list without duplicates and add added/removed flag
    _.forEach($scope.selectedVersion[0].data.approvers, function(prevApproverList) {
      var approverListing = _.find($scope.approverList, function(e) {
        if (e._id === prevApproverList._id) {
          e.added = false;
          if (e.role !== prevApproverList.role) {
            e.previousRole = prevApproverList.role;
            e.updatedRole = true;
          }
          if (e.user._id !== prevApproverList.user._id) {
            e.previousUser = prevApproverList.user;
            e.updatedUser = true;
          }
          if (e.approval_date !== prevApproverList.approval_date) {
            e.previousApprovalDate = prevApproverList.approval_date;
            e.updatedApprovalDate = true;
          }
          if (e.remarks !== prevApproverList.remarks) {
            e.previousRemarks = prevApproverList.remarks;
            e.updatedRemarks = true;
          }
          return true
        }
      });

      if (_.isEmpty(approverListing)) {
        var removeApproverList = _.cloneDeep(prevApproverList);
        removeApproverList.removed = true;
        $scope.approverList.push(removeApproverList);
      }
    });
  }

  function getComponentList() {
    $scope.componentList = _.cloneDeep($scope.selectedVersion[1].data.components);

    _.forEach($scope.componentList, function(e) {
      e.added = true;
    })

    // combine the previous list and current list without duplicates and add added/removed flag
    _.forEach($scope.selectedVersion[0].data.components, function(prevComponentList) {
      var componentListing = _.find($scope.componentList, function(e) {

        if (e._id === prevComponentList._id) {
          e.added = false;
          if (e.name !== prevComponentList.name) {
            e.previousName = prevComponentList.name;
            e.updatedName = true;
          }
          if (e.description !== prevComponentList.description) {
            e.previousDesc = prevComponentList.description;
            e.updatedDesc = true;
          }
          if (e.group._id !== prevComponentList.group._id) {
            e.previousGroup = prevComponentList.group;
            e.updatedGroup = true;
          }
          if (e.price_per_unit !== prevComponentList.price_per_unit) {
            e.previousPerUnit = (['0', '0.0000']).includes(prevComponentList.price_per_unit) ? '' : prevComponentList.price_per_unit;
            e.updatedPerUnit = true;
          }
          if (e.amount !== prevComponentList.amount) {
            e.previousAmount = (['0.00']).includes(prevComponentList.amount) ? '' : prevComponentList.amount;
            e.updatedAmount = true;
          }

          if ($scope.categoryV0 !== $scope.categoryV1) {
            if ($scope.categoryV0 === 'AMOUNT') {
              e.previousAmountType = prevComponentList.amount;
            } else {
              e.previousUnitType = prevComponentList.price_per_unit;
            }
          }
          return true
        }
      });

      if (_.isEmpty(componentListing)) {
        var removeComponentList = _.cloneDeep(prevComponentList);
        removeComponentList.removed = true;
        $scope.componentList.push(removeComponentList);
      }
    });
  }

  function getSupplierList() {
    $scope.supplierList = _.cloneDeep($scope.selectedVersion[1].data.suppliers);

    _.forEach($scope.supplierList, function(e) {
      e.added = true;
    })

    // combine the previous list and current list without duplicates and add added/removed flag
    _.forEach($scope.selectedVersion[0].data.suppliers, function(prevComponentList) {
      var approverListing = _.find($scope.supplierList, function(e) {

        if (e._id === prevComponentList._id) {
          e.added = false;

          if (e.supplier.code !== prevComponentList.supplier.code) {
            e.previousCode = prevComponentList.supplier.code;
            e.update = true;
          }

          if (e.supplier.descr !== prevComponentList.supplier.descr) {
            e.previousDescr = prevComponentList.supplier.descr;
            e.update = true;
          }

          return true
        }
      });

      if (_.isEmpty(approverListing)) {
        var removeApproverList = _.cloneDeep(prevComponentList);
        removeApproverList.removed = true;
        $scope.supplierList.push(removeApproverList);
      }
    });
  }

  /**
   * On modal close
   */
  function close() {
    $uibModalInstance.close();

    // Open tender version modal if currently viewing approved tender
    $uibModal.open({
      templateUrl: 'components/tenderComponents/versions/tenderVersions.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        tenderVersions: function() {
          return tenderVersions;
        },
        tender: function() {
          return tender;
        },
        mode: function() {
          return mode;
        }
      },
      controller: 'tenderVersionsCtrl'
    });
  }

  function initialize() {
    getApproverList();
    getComponentList();
    getSupplierList();
  }

  initialize();
}

compareTenderVersionCtrl.$inject = [
  '$scope', '$uibModal', '$uibModalInstance', 'tender', 'tenderVersions', 'mode', 'selectedVersion'
];

angular.module('metabuyer')
  .controller('compareTenderVersionCtrl', compareTenderVersionCtrl);
