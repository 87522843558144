'use strict';
/**
 * @name addCostCenterCtrl
 * @desc Controller to add cost center for a company
 */
function addCostCenterCtrl(
  $scope, addCostCenters, toastr, globalFunc, costCenterData, companyUsers, companyRoles, cachedAddresses, searchModule, shadowCompanies, Companies, companyId, metabuyerCache
) {

  $scope.companyId = companyId;
  $scope.cachedAddresses = cachedAddresses;
  $scope.shadowCompanies = shadowCompanies;
  $scope.submitted = false;
  $scope.costcenter = {};
  $scope.delivery_address = {};
  $scope.costcenter.delivery_address_id = '';
  $scope.addNewAddress = false;
  $scope.billing = {};
  $scope.delivery = {};
  $scope.states = {
    MY: $scope.MYStates
  };
  $scope.duplicateAddress = {
    value: true
  };
  $scope.companyUsers = companyUsers;
  $scope.selectedUsers = [];
  $scope.selectedRoles = [];
  $scope.categoryTypes = [];
  $scope.costCenterData = costCenterData;
  $scope.getRoles = getRoles;
  $scope.isEditingMode = (!!$scope.costCenterData && !!$scope.costCenterData._id);
  $scope.followCompanyAddress = {
    billing: false,
    delivery: false
  };

  $scope.checkAddress = checkAddress;
  $scope.duplicationCheck = duplicationCheck;
  $scope.addCostCenter = addCostCenter;
  $scope.back = back;
  $scope.selectUser = selectUser;
  $scope.removeUser = removeUser;
  $scope.selectRole = selectRole;
  $scope.removeRole = removeRole;
  $scope.removeAllSelectedUsers = removeAllSelectedUsers;
  $scope.addAllCompanyUsers = addAllCompanyUsers;
  $scope.removeAllSelectedRoles = removeAllSelectedRoles;
  $scope.saveCostCenter = saveCostCenter;
  $scope.removeCategoryType = removeCategoryType;
  /**
   * @function duplicationCheck
   * Calls the global function's duplication check
   * Checks callback function return for assigning scope values for validation
   **/
  function duplicationCheck(model, field, value, checker) {
    // TODO: remove this after fixing auto append company code to cost center, Ling Nai Shin (8/7/19)
    if (model === 'CostCenter') {
      value = $scope.companyDetails.code + '.' + value;
    }
    globalFunc.duplicationCheck(model, field, value, checker, function (callback) {
      switch (callback) {
        case 'codeCheckTrue':
          $scope.codeCheck = true;
          break;
        case 'codeCheckFalse':
          $scope.codeCheck = false;
          break;
      }
    });
  }

  /**
   * Validation before adding cost center
   *
   * @returns {boolean}
   */
  function validateAddCostCenter() {
    $scope.selectedUserIds = _.chain($scope.selectedUsers).pluck('_id').unique().value();
    $scope.selectedRoleNames = _.chain($scope.selectedRoles).pluck('name').unique().value();

    if (!$scope.costcenter)
      return false;

    if (!$scope.costcenter.descr || !$scope.costcenter.code || !$scope.costcenter.billingAddressId ||
      !$scope.costcenter.deliveryAddressId) {
      globalFunc.objectErrorMessage('Please fill in the required fields.');
      return false;
    }

    if (!!$scope.codeCheck) {
      globalFunc.objectErrorMessage('The code is already taken.');
      return false;
    }

    return true;
  }

  /**
   * Validation for editing cost center
   *
   * @returns {boolean}
   */
  function validateEditedCostCenter() {

    if (!$scope.costCenterData || !$scope.costCenterData.code) {
      globalFunc.objectErrorMessage('Cost center code is required.');
      return false;
    }

    return true;
  }

  /**
   * Send request to add the new cost center
   */
  function addCostCenter() {
    $scope.submitted = true;

    var tempBillingArray = [];
    tempBillingArray.push($scope.costcenter.billingAddressId);
    var tempDeliveryArray = [$scope.costcenter.deliveryAddressId];
    if (!!validateAddCostCenter()) {
      var submit_data = {
        descr: $scope.costcenter.descr,
        code: $scope.costcenter.code,
        company_name: $scope.companyDetails.descr,
        company_code: $scope.companyDetails.code,
        billing_addresses_ids: tempBillingArray,
        delivery_addresses_ids: tempDeliveryArray,
        shadow_company_code: !!$scope.costcenter.shadow_company ? $scope.costcenter.shadow_company.code : undefined,
        category_type_code: !!$scope.costcenter.category_type ? $scope.costcenter.category_type.code : undefined,
        user_ids: $scope.selectedUserIds,
        role_names: $scope.selectedRoleNames,
        company_id: $scope.companyDetails._id
      };

      addCostCenters.post(
        {},
        submit_data,
        function (resource) {
          var temp = {};
          temp._id = resource._id;
          temp.descr = $scope.costcenter.descr;
          temp.code = $scope.costcenter.code;
          temp.is_active = 1;
          $scope.companyDetails.costCenters.push(temp);
          toastr.success('Cost Center added successfully.');
          metabuyerCache.remove('userCostCenters');
          back();
        },
        function (error) {
          $scope.submitted = false;
          metabuyerCache.remove('userCostCenters');
          globalFunc.objectErrorMessage(error)
        }
      );
    }
  }

  function checkAddress(type, value) {
    if (type === 'billing') {
      if (value) {
        $scope.costcenter.billingAddressId = $scope.companyDetails.corresponding_address._id;
      } else {
        $scope.costcenter.billingAddressId = null;
      }
    } else {
      if (value) {
        $scope.costcenter.deliveryAddressId = $scope.costcenter.billingAddressId;
      } else {
        $scope.costcenter.deliveryAddressId = null;
      }
    }
  }

  /**
   * Navigate back to previous page
   */
  function back(){
    window.history.back();
  }

  /**
   * Add user into selected user list
   *
   * @param {object} selectedUser User to be added
   */
  function selectUser(selectedUser) {
    $scope.companyUsers = globalFunc.removeValueFromArray($scope.companyUsers, '_id', selectedUser._id);
    $scope.selectedUsers.push(selectedUser);
  }

  /**
   * Remove user from selected user list
   *
   * @param {object} selectedUser User to be removed
   */
  function removeUser(selectedUser) {
    $scope.selectedUsers = globalFunc.removeValueFromArray($scope.selectedUsers, '_id', selectedUser._id);
    $scope.companyUsers.push(selectedUser);
  }

  function removeCategoryType() {
    $scope.costcenter.category_type = undefined;
  }

  function getRoles() {
    var costCenterCode;
    for (var index in $scope.companyDetails.costCenters) {
      costCenterCode = $scope.companyDetails.costCenters[index].code;
      break;
    }
    //get cost center level roles
    companyRoles.get(
      {
        code: costCenterCode,
        organizationLevel: 'cost_center'
      },
      function (resource) {
        if (!!resource.content) {
          $scope.roles = resource.content;
        }
      });
  }

  /**
   * Add role into selected role list
   *
   * @param {object} selectedRole Role to be added
   */
  function selectRole(selectedRole) {
    $scope.roles = globalFunc.removeValueFromArray($scope.roles, '_id', selectedRole._id);
    $scope.selectedRoles.push(selectedRole);
  }

  /**
   * Remove role from selected role list
   *
   * @param {object} selectedRole Role to be removed
   */
  function removeRole(selectedRole) {
    $scope.selectedRoles = globalFunc.removeValueFromArray($scope.selectedRoles, '_id', selectedRole._id);
    $scope.roles.push(selectedRole);
  }

  /**
   * Remove all company user(s) from selected user list
   */
  function removeAllSelectedUsers() {
    swal({
      title: 'Confirm remove all selected user(s)?',
      text: 'All selected user(s) will be deselected.',
      showCancelButton: true,
      confirmButtonColor: '#1ab394',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      closeOnConfirm: true,
      closeOnCancel: true
    }, function () {
      $scope.companyUsers = $scope.companyUsers.concat($scope.selectedUsers);
      $scope.selectedUsers = [];
      toastr.success('All selected user(s) was deselected.');
    });
  }

  /**
   * Add all company user(s) to the selected user list
   */
  function addAllCompanyUsers() {
    swal({
      title: 'Confirm add all user(s)?',
      text: 'All company user(s) will be selected.',
      showCancelButton: true,
      confirmButtonColor: '#1ab394',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      closeOnConfirm: true,
      closeOnCancel: true
    }, function () {
      $scope.selectedUsers = $scope.selectedUsers.concat($scope.companyUsers);
      $scope.companyUsers = [];
      toastr.success('All user(s) was selected.');
    });
  }

  /**
   * Remove all role(s) from selected role list
   */
  function removeAllSelectedRoles() {
    swal({
      title: 'Confirm remove all selected role(s)?',
      text: 'All selected role(s) will be deselected.',
      showCancelButton: true,
      confirmButtonColor: '#1ab394',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      closeOnConfirm: true,
      closeOnCancel: true
    }, function () {
      $scope.roles = $scope.roles.concat($scope.selectedRoles);
      $scope.selectedRoles = [];
      toastr.success('All selected role(s) was deselected.');
    });
  }


  /**
   * Send request to update the cost center
   */
  function saveCostCenter() {
    if (!!validateEditedCostCenter()) {
      var submitData = {
        code: $scope.costCenterData.code,
      };

      addCostCenters.put(
        {},
        submitData,
        function () {
          toastr.success('Changes saved successfully.');
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    }
  }

  function getCategoryTypes(){
    var params = {
      module: 'category-type',
      'criteria[0][is_active]': true,
      'offset': 0
    };

    searchModule.get(
      params,
      function (resource) {
        if (!!resource.content && !!resource.content.data){
          $scope.categoryTypes = resource.content.data;
        }
      },
      function (error) {
      }
    );
  }

  function getCompanyDetails(){
    Companies.get(
      {
        id: $scope.companyId
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data){
          $scope.shadowCompanies = resource.content.data.shadow_companies;
        }
      },
      function (error) {
      }
    );
  }

  /**
   * Function to be run on page load
   */
  function initialize() {
    getRoles();
    getCategoryTypes();

    if (!$scope.shadowCompanies){
      getCompanyDetails();
    }
    // if is editing cost center
    if (!!$scope.isEditingMode) {
      $scope.costcenter = $scope.costCenterData;
    }
  }

  initialize();
}

addCostCenterCtrl.$inject = [
  '$scope', 'addCostCenters', 'toastr', 'globalFunc', 'costCenterData', 'companyUsers', 'companyRoles', 'cachedAddresses', 'searchModule', 'shadowCompanies', 'Companies', 'companyId', 'metabuyerCache'
];

angular
  .module('metabuyer')
  .controller('addCostCenterCtrl', addCostCenterCtrl);
