'use strict';

/**
 * Controller for bifrost management
 * @description
 * Listing the mapped information for Editing purpose only
 *
 * @param $scope
 * @param $state
 * @param dataList
 * @param type
 * @param pathConstants
 * @param $http
 * @param toastr
 * @param $filter
 * @param $uibModal
 * @param mappingSupplier
 * @watch $stateChangeStart
 * Watches on the start of state change, set status and params
 *
 * @author Ling Nai Shin <ling_naishin@hotmail.com>
 * @copyright 2018 Metacloud Sdn. Bhd.
 */
function bifrostMappedSupplierCtrl($scope, $state, dataList, type, pathConstants, $http, toastr, $filter,
                                   $uibModal, mappingSupplier, $q) {
  $scope.$emit('pageTitleChanged', 'Bifrost Listing');
  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.title = type;
  $scope.showColumn = showColumn;
  $scope.exportSupplierMapping = exportSupplierMapping;
  $scope.showComparison = showComparison;
  $scope.compareArrayValue = compareArrayValue;

  function showColumn(id) {
    var i;
    var columns = $scope.columns;
    for (i in columns) {
      if(columns.hasOwnProperty(i)) {
        if (id === columns[i].id) {
          if(!!columns[i].conditional) {
            if($scope.status === columns[i].condition)
              return columns[i].selected;
            if(columns[i].condition instanceof Array) {
              var j;
              var columnCondition = $scope.columns[i].condition;
              for(j in columnCondition) {
                if(columnCondition.hasOwnProperty(j)) {
                  if($scope.status === columnCondition[j]) {
                    return columns[i].selected;
                  }
                }
              }
            }
          }
          else
            return columns[i].selected;
        }
      }
    }
  }

  function exportSupplierMapping() {
    $http.post(pathConstants.apiUrls.bifrost.export, {
      module: 'supplier'
    }).then(function (response) {
      var blob = new Blob([response.data]);
      var link = document.createElement('a');
      document.body.appendChild(link);
      link.href = window.URL.createObjectURL(blob);
      link.download = 'SupplierMappingInfo-' +
        $filter('date')(new Date(), 'yyyyMMdd') +'.csv';
      link.click();
      toastr.success('Supplier mapping file has been successfully generated.');
    }, function () {
      toastr.error('Failed to generate supplier mapping file.');
    });
  }

  function showComparison(data) {
    $uibModal.open({
      templateUrl: 'app/bifrostManagement/mappedSupplierModal/supplierInboundComparisonTemplate.html',
      backdrop: 'static',
      keyboard: false,
      controller: mappingSupplierModalCtrl,
      size: 'lg',
      windowClass: 'revision-wrapper',
      resolve: {
        data:function(){
          return data;
        },
        supplier: function ($q, mappingSupplier, toastr) {
          var deferred = $q.defer();
          mappingSupplier.get(
            {id: data.Id},
            function (resource) {
              if (!!resource) {
                deferred.resolve(resource);
              } else {
                deferred.resolve({});
              }
            },
            function (error) {
              toastr.error('Supplier not found');
            }
          );
          return deferred.promise;
        }
      }
    });
  }

  /**
   * compare Arrays value
   * @param array1
   * @param array2
   * @param fieldToCompare
   * @returns {boolean}
   */
  function compareArrayValue(array1, array2, fieldToCompare) {
    var arrIteration;

    if (!array1 || !array2)
      return false;

    if(array1.length !== array2.length)
      return false;

    for (arrIteration in array1) {
      if (array1.hasOwnProperty(arrIteration)) {
        var checkValInArray2 = globalFunc.findInArray(array2, fieldToCompare, array1[arrIteration][fieldToCompare]);
        if (!checkValInArray2)
          return false;
      }
    }

    return true;
  }

  $scope.$on('$stateChangeStart', function( event, data, toParams ) {
    if (!!toParams.status) {
      $scope.parentStateParams = toParams;
    }
  });

}

bifrostMappedSupplierCtrl.$inject = ['$scope', '$state', 'dataList', 'type', 'pathConstants', '$http', 'toastr', '$filter',
  '$uibModal', 'mappingSupplier', '$q'];

angular.module('metabuyer')
  .controller('bifrostMappedSupplierCtrl', bifrostMappedSupplierCtrl);
