'use strict';

function kharonSettingsCtrl($scope, $state) {

  $scope.tabs = [
    {
      label: 'Schedule',
      route: 'main.user.settings.kharon.schedule',
      key: 'schedule',
    },
    {
      label: 'User',
      route: 'main.user.settings.kharon.user',
      key: 'user',
    }
  ];

  $scope.selectTab = function (tab) {
    $state.go(tab.route);
  };

  $scope.$emit('pageTitleChanged', 'Kharon Settings');
}
kharonSettingsCtrl.$inject = ['$scope', '$state'];

angular.module('metabuyer')
  .controller('kharonSettingsCtrl', kharonSettingsCtrl);
