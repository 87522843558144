'use strict';

function SupplierDeliveryNoteCtrl($scope, deliveryNote, $uibModalInstance) {
  $scope.cancel = cancel;
  $scope.deliveryNote = deliveryNote;

  function cancel() {
    $uibModalInstance.close();
  }

  function initialize(){
    // Init something
  }

  initialize();
}

SupplierDeliveryNoteCtrl.$inject = ['$scope', 'deliveryNote', '$uibModalInstance'];

angular.module('metabuyer')
  .controller('SupplierDeliveryNoteCtrl', SupplierDeliveryNoteCtrl);
