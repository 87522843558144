'use strict';

angular.module('metabuyer')
  .controller('ReverseGoodsCtrl', function ($scope, $uibModalInstance, Orders, toastr, HighlightId,
                                            pathConstants, $http, $filter, $state, globalFunc,
                                            goodsReceived, $rootScope) {
    $scope.next_reverse_goods = true;
    $scope.back_reverse_goods = false;
    $scope.enable_next = false;
    $scope.submit_data = {};
    $scope.userBasePath = $rootScope.userImageBasePath;

    $scope.goodsReceived = [];
    if(!!goodsReceived.data){
      $scope.goodsReceived = goodsReceived.data;
      //reformat the key object into array
      _.forEach($scope.goodsReceived, function(singleGrn){
        singleGrn.items = globalFunc.reformatFromObjectToArray(singleGrn.items);
      });
    }

    $scope.reverse_goods_data = [];

    $scope.nextReverseGoods = nextReverseGoods;
    $scope.cancel = cancel;
    $scope.backReverseGoods = backReverseGoods;
    $scope.prepareReverseGoods = prepareReverseGoods;
    $scope.convertToInt = convertToInt;
    $scope.autoFillGRNsQuantity = autoFillGRNsQuantity;
    $scope.autoAddLineQuantity = autoAddLineQuantity;

    //next action
    function nextReverseGoods(){
      $scope.next_reverse_goods = false;
      $scope.back_reverse_goods = true;
    }

    function convertToInt(number) {
      return Number(number);
    }

    //cancel action
    function cancel(){
      $uibModalInstance.close();
    }

    //back action
    function backReverseGoods(){
      $scope.next_reverse_goods = true;
      $scope.back_reverse_goods = false;
    }

    $scope.$watch("reverse_goods_data", function(value){
      var fill_up_wrong = _.find($scope.reverse_goods_data, function(item){
        return Number(item.reversed_quantity) != 0 &&
          (Number(item.reversed_quantity) > Number(item.po_item.quantity) ||
          Number(item.reversed_quantity) > Number(item.po_item.order.delivered)
          || Number(item.reversed_quantity) > item.reversibleQuantity);
      });

      if(fill_up_wrong != undefined){
        $scope.enable_next = false;
      }
      else{
        var fill_up_right = _.find($scope.reverse_goods_data, function(item){
          return !!item.reversed_quantity && item.reversed_quantity != 0;
        });

        if(fill_up_right != undefined){
          $scope.enable_next = true;
        } else {
          $scope.enable_next = false;
        }
      }
    }, true);

    function checkFraction(item) {
      if(!!item.po_item && !!item.po_item.uom) {
        if (!item.po_item.uom.is_fraction || item.po_item.uom.is_fraction === 0) {
          if (item.reversed_quantity % 1 !== 0) {
            toastr.error('Unit of Measurement of item number ' + (Number(item.POIndex) + 1) +' does not allow amount in fraction');
            return false;
          }
        }
      }
      return true;
    }

    /**
     * Prepare reverse goods data and confirmation
     */
    function prepareReverseGoods() {
      var payload = {};
      payload.items_data = [];
      var showSwalConfirmation = false;

      var validFraction = true;
      for (var i in $scope.reverse_goods_data) {
        var singleItem = _.cloneDeep($scope.reverse_goods_data[i]);
        if (singleItem.reversed_quantity && (singleItem.reversed_quantity <= singleItem.po_item.quantity
          || singleItem.reversed_quantity <= singleItem.po_item.order.delivered)) {
          validFraction = checkFraction(singleItem);
          if (!validFraction) {
            break;
          }
          var data = {};
          data.item_id = singleItem.po_item._id;
          data.total_reversed = singleItem.reversed_quantity;
          data.grn_details = [];
          _.forEach(singleItem.received, function (singleGRN) {
            if(singleGRN.currentReversedQuantity > 0) {
              var temp = {};
              temp.grn_number = singleGRN.grnNumber;
              temp.current_reversed = singleGRN.currentReversedQuantity;
              data.grn_details.push(temp);
            }
          });
          payload.items_data.push(data);
        }

        if (!!singleItem.reversed_quantity && !!singleItem.po_item.split_type && singleItem.po_item.split_type === 'amount') {
          showSwalConfirmation = true;
        }
      }

      if (!!$scope.submit_data.comment) {
        payload['comment'] = $scope.submit_data.comment;
      }

      if (validFraction) {
        // If there is an item reversed that is split by amount, confirmation is needed
        if (showSwalConfirmation) {
          // Sweet alert confirmation if reversing item that has linked PO
          swal({
            title: 'Confirm reverse item(s)?',
            text: 'Item(s) on related PO(s) will also be reversed.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            closeOnConfirm: true
          }, function (isConfirm) {
            if (!!isConfirm) {
              reversePurchaseOrder(payload);
            }
          });
        }
        else {
          reversePurchaseOrder(payload);
        }
      }
    }


    /**
     *
     * @param payload
     */
    function reversePurchaseOrder(payload) {
      if (!!validate(payload)) {
        Orders.reverseItems({
            po_id: $scope.po._id
          }, payload,
          function (success) {
            $uibModalInstance.close();
            swal(success.content.message);
            toastr.success('Successfully reversed');
            HighlightId.setId($scope.po._id);
            $state.go('main.user.orders.manage', {status: 'AWAITING_DELIVERY'});
          }, function (error) {
            globalFunc.objectErrorMessage(error);
          });
      }
    }

    function validate(payload) {
      if (!payload.comment) {
        toastr.error('Please enter comment before reversing');
        return false;
      }
      return true;
    }

    /**
     * append the previous GRN data before reversing, and sort them by the created date
     * @param id
     * @returns {Array}
     */
    function findGRNsForAnItem(id) {
      var data = [];
      _.forEach($scope.goodsReceived, function (received) {
        _.forEach(received.items, function (singleItem) {
          if (singleItem.value._id === id) {
            var totalReversed = !!singleItem.value.order.total_reversed ?
              Number(singleItem.value.order.total_reversed) : 0;
            data.push({
              lastBatch: Number(singleItem.value.order.last_batch),
              quantityReceived: singleItem.value.quantity_received,
              totalReversed: totalReversed,
              grnNumber: received.grn_number,
              doNumber: received.do_number,
              doDate: received.do_date,
              _id: received._id,
              totalDelivered: singleItem.value.order.delivered,
              availableToReverse: singleItem.value.order.last_batch - totalReversed,
              date: received.created_at,
              currentReversedQuantity : 0,
              invoiced: (!!singleItem.value.invoice_quantity && singleItem.value.invoice_quantity > 0) ? true : false
            });
          }
        })
      });
      return _.sortBy(data, function(grn) { return grn.date});
    }

    /**
     * Automatically fill the reverse options from the last GRN to the newest
     *
     * @param item
     * @param val
     */
    function autoFillGRNsQuantity(item, val) {
      var quantity = _.cloneDeep(val);
      //validate the quantity first
      if (val > item.po_item.order.delivered)
        return;

      var i = item.received.length - 1;
      while (quantity > 0) {
        if(item.received[i].availableToReverse > 0) {
          if (item.received[i].availableToReverse >= quantity) {
            item.received[i].currentReversedQuantity = quantity;
            quantity = 0;
          } else {
            item.received[i].currentReversedQuantity = item.received[i].availableToReverse;
            quantity -= item.received[i].availableToReverse;
          }
        }
        i--;
      }
    }

    function calculateInvoicedQuantity(goodsReceived) {
      var quantity = 0;
      _.forEach(goodsReceived, function (singleGrn) {
        if (singleGrn.invoiced) {
          quantity += (singleGrn.lastBatch - singleGrn.totalReversed);
        }
      });
      return quantity;
    }

    /**
     * Calculate the total quantity
     *
     * @param item
     */
    function autoAddLineQuantity(item) {
      var total = 0;
      for (var i in item.received) {
        total += item.received[i].currentReversedQuantity;
      }
      item.reversed_quantity = total;
    }

    function initialize(){
      _.forEach($scope.po.items, function(item, i){
        var goodsReceived = findGRNsForAnItem(item._id);
        var invoicedQuantity = calculateInvoicedQuantity(goodsReceived);
        var reversibleQuantity = item.order.delivered - invoicedQuantity;
        $scope.reverse_goods_data.push({po_item: item, reversed_quantity: 0, POIndex: i, received: goodsReceived,
          reversibleQuantity: reversibleQuantity});
      });
    }


    initialize();
  });
