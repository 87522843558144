'use strict';
/***
 * @name addErpOrderTypeCtrl
 * @desc Controller to add Erp Order Type for a company
 */
function addErpOrderTypeCtrl (
  $scope, toastr, globalFunc, $uibModalInstance, companyErpOrderTypeList
) {
  $scope.submitted = false;
  $scope.addErpOrderType = addErpOrderType;
  $scope.cancel = cancel;

  function validateAddErpOrderType() {
    if (!$scope.erpOrderType) {
      globalFunc.objectErrorMessage('All fields are empty.');
      return false;
    }

    if (!$scope.erpOrderType.descr || !$scope.erpOrderType.code) {
      globalFunc.objectErrorMessage('Please fill in the required fields.');
      return false;
    }
    return true;
  }

  function addErpOrderType() {
    $scope.submitted = true;

    if (!!validateAddErpOrderType()) {
      var submit_data = {
        descr: $scope.erpOrderType.descr,
        code: $scope.erpOrderType.code,
        company_code: $scope.companyDetails.code
      };

      companyErpOrderTypeList.post(
        {
          id:$scope.companyDetails._id
        },
        submit_data,
        function (success) {
          toastr.success('New Erp Order Type successfully added!');
          $uibModalInstance.close(success);
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    }
  }

  function cancel() {
    $uibModalInstance.close();
  }

}

addErpOrderTypeCtrl.$inject = ['$scope', 'toastr', 'globalFunc', '$uibModalInstance', 'companyErpOrderTypeList'];

angular
    .module('metabuyer')
    .controller('addErpOrderTypeCtrl', addErpOrderTypeCtrl);
