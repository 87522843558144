'use strict';

angular.module('metabuyer.services.priceComparison', ['ngResource'])
  .factory('priceComparisonResource', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.priceComparison.single, {
        id: '@id'
      }, {
        'get': { method: 'GET' },
        'delete': { method: 'DELETE' },
        'put': { method: 'PUT' },
        'post': { method: 'POST' },
        'approve': {
          method: 'POST',
          params: {
            approval_id: '@approval_id',
            status: '@status'
          },
          url: pathConstants.apiUrls.approvals.status
        },
        'withdrawn': {
          method: 'POST',
          params: {
            approval_id: '@approval_id',
            status: 'withdraw'
          },
          url: pathConstants.apiUrls.approvals.status
        },
        'downloadPdf': {
          method: 'GET',
          ignoreLoadingBar: true,
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.priceComparison.generatePdf
        },
        'submitEditableCopy': {
          method: 'POST',
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.priceComparison.submitEditedCopy
        },
      }
    );
  })
  .factory('calculateComparison', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.priceComparison.calculateComparison, {}, {
        'post': { method: 'POST' }
      }
    );
  })
  .factory('submitPriceComparison', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.priceComparison.submit, {
        id: '@id'
      }, {
        'post': { method: 'POST' }
      }
    );
  })
  .factory('getPcAttachments', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.priceComparison.getAttachmentList, {
        id: '@id'
      }, {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('priceComparisonCommentService',
    function($resource, $filter, pathConstants) {
      return $resource(
        pathConstants.apiUrls.priceComparison.comments, {
          referenceId: '@referenceId'
        }, {
          get: {
            method: 'GET',
            ignoreLoadingBar: true
          },
          post: {
            method: 'POST',
            params: {
              referenceId: '@referenceId'
            },
            url: pathConstants.apiUrls.priceComparison.comments
          },
          delete: {
            method: 'DELETE',
            params: {
              referenceId: '@referenceId',
              commentId: '@commentId'
            },
            url: pathConstants.apiUrls.priceComparison.comments
          }
        }
      )
    })
  .factory('priceComparisonApprovalAction', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.approvals.status, {
        approval_id: '@approval_id',
        status: '@status',
        comment: '@comment'
      }, {
        'post': { method: 'POST' }
      }
    );
  });
