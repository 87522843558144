'use strict';

angular.module('metabuyer')
  .controller('massEditCompanyContractCtrl', massEditCompanyContractCtrl);

function massEditCompanyContractCtrl(
  $scope, $uibModalInstance, globalFunc, paymentTerms, paymentMethods, taxes, searchModule, listChild,
  supplierFunctions, toastr, companyContractResource, supplier
) {

  $scope.contract = [];
  $scope.contract.contacts = [];
  $scope.contract.company = {};
  $scope.closeTreeSearchPrimary = false;
  $scope.closeTreeSearchSecondary = false;
  $scope.commodityLevel = [3, 4];
  $scope.currencies = supplierFunctions.getCurrencies();
  $scope.paymentTerms = supplierFunctions.getPaymentTerms();
  $scope.paymentMethods = supplierFunctions.getPaymentMethods();
  $scope.taxes = supplierFunctions.getTaxes();
  $scope.selectedCompanyContract = supplierFunctions.getSelectedCompanyContract();

  $scope.bumiputraStatus = [
    {descr: 'Yes', value: 1},
    {descr: 'No', value: 0}
  ];

  $scope.businessNatures = [
    {descr: 'Unspecified Business Nature', shortDescr: 'UNSPECIFIED', value: 0},
    {descr: 'Agent', shortDescr: 'AGENT', value: 1},
    {descr: 'Banking & Financial Services', shortDescr: 'BANKING', value: 2},
    {descr: 'Consultancy, Advisory & Other Professional Bodies', shortDescr: 'CONSULTANCY', value: 3},
    {descr: 'Contractor', shortDescr: 'CONTRACTOR', value: 4},
    {descr: 'Dealer', shortDescr: 'DEALER', value: 5},
    {descr: 'Distribute', shortDescr: 'DISTRIBUTOR', value: 6},
    {descr: 'Manufacturer', shortDescr: 'MANUFACTURER', value: 7},
    {descr: 'Marketing', shortDescr: 'MARKETING', value: 8},
    {descr: 'Retailer', shortDescr: 'RETAILER', value: 9},
    {descr: 'Service Provider', shortDescr: 'SERVICE_PROVIDER', value: 10},
    {descr: 'Sub-Contractor', shortDescr: 'SUB_CONTRACTOR', value: 11},
    {descr: 'Trading', shortDescr: 'TRADING', value: 12},
    {descr: 'Other', shortDescr: 'OTHERS', value: 13}
  ];

  $scope.closeModal = closeModal;
  $scope.onSelectedPaymentTerm = onSelectedPaymentTerm;
  $scope.onSelectedPaymentMethod = onSelectedPaymentMethod;
  $scope.onSelectedTax = onSelectedTax;
  $scope.onSelectedCurrency = onSelectedCurrency;
  $scope.onAddedContact = onAddedContact;
  $scope.selectedBumiputra = selectedBumiputra;
  $scope.createCompanyContract = createCompanyContract;
  $scope.onUpdatedContact = onUpdatedContact;
  $scope.removeContact = removeContact;
  $scope.searchPrimaryCommodityCode = searchPrimaryCommodityCode;
  $scope.searchSecondaryCommodityCode = searchSecondaryCommodityCode;
  $scope.openChildCommodityCodePrimary = openChildCommodityCodePrimary;
  $scope.openChildCommodityCodeSecondary = openChildCommodityCodeSecondary;
  $scope.removeSecondaryCommodity = removeSecondaryCommodity;
  $scope.validatePrimaryBusinessNature = validatePrimaryBusinessNature;
  $scope.validateSecondaryBusinessNature = validateSecondaryBusinessNature;

  function validation() {
    if (!$scope.contract.currency_code) {
      return false;
    }

    if (!$scope.contract.primary_commodity) {
      return false;
    }

    if (angular.isUndefined($scope.contract.bumi_status)) {
      $scope.noBumiStatus = true;
      return false;
    }

    if (!$scope.contract.primary_business_nature) {
      return false;
    }

    if ($scope.validatedBN === true) {
      return false;
    }

    if (!$scope.contract.payment_term_code) {
      return false;
    }

    if (!$scope.contract.payment_method_code) {
      return false;
    }

    if (!$scope.receive_via_email && !$scope.receive_via_fax && !$scope.receive_via_post) {
      return false;
    }

    if (!$scope.contract.tax_code) {
        return false;
    }

    if (!$scope.contract.contacts || !$scope.contract.contacts.length) {
      return false;
    }
    return true;
  }

  function validatePrimaryBusinessNature(businessNature) {
    $scope.contract.primary_business_nature = businessNature;
    if (angular.isDefined($scope.contract.secondary_business_nature)){
      if (($scope.contract.secondary_business_nature === $scope.contract.primary_business_nature)) {
        toastr.error('Primary business nature cannot be the same as secondary business nature');
        $scope.validatedBN = true;
      } else {
        $scope.validatedBN = false;
      }
    }
  }

  function validateSecondaryBusinessNature(businessNature) {
    $scope.contract.secondary_business_nature = businessNature;
    if (angular.isDefined($scope.contract.primary_business_nature )) {
      if (($scope.contract.primary_business_nature === $scope.contract.secondary_business_nature)) {
        toastr.error('Primary business nature cannot be the same as secondary business nature');
        $scope.validatedBN = true;
      } else {
        $scope.validatedBN = false;
      }
    }
  }

  function searchPrimaryCommodityCode(keyword) {
    if (!!keyword && keyword.length > 1) {
      searchModule.get(
        {
          module: 'commodity',
          'criteria[0][code]': keyword,
          'criteria[0][descr]': keyword,
          'criteria[0][$operator]': 'or',
          'criteria[1][is_active]': 1,
          order: 1,
          order_by: 'code',
          offset: 0
        }, function (resource) {
          if (!!resource && !!resource.content && !!resource.content.data)
            $scope.primaryCommodityCodes = resource.content.data;
        }, function () {
          $scope.primaryCommodityCodes = [];
        });
      return $scope.primaryCommodityCodes;
    }
  }

  function searchSecondaryCommodityCode(keyword) {
    if (!!keyword && keyword.length > 1) {
      searchModule.get(
        {
          module: 'commodity',
          'criteria[0][code]': keyword,
          'criteria[0][descr]': keyword,
          'criteria[0][$operator]': 'or',
          'criteria[1][is_active]': 1,
          order: 1,
          order_by: 'code',
          offset: 0
        }, function (resource) {
          if (!!resource && !!resource.content && !!resource.content.data)
            $scope.secondaryCommodityCodes = resource.content.data;
        }, function () {
          $scope.secondaryCommodityCodes = [];
        });
      return $scope.secondaryCommodityCodes;
    }
  }

  function openChildCommodityCodePrimary(cc) {
    listChild.get({
        code: cc
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data.length > 0) {
          $scope.primaryCommodityCodes = resource.content.data;
        } else {
          toastr.error("There are no child on this commodity code");
        }
      },
      function () {
        toastr.error("Failed to get commodity codes data");
      })
  }

  function openChildCommodityCodeSecondary(cc) {
    listChild.get({
        code: cc
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data.length > 0) {
          $scope.secondaryCommodityCodes = resource.content.data;
        } else {
          toastr.error("There are no child on this commodity code");
        }
      },
      function () {
        toastr.error("Failed to get commodity codes data");
      })
  }

  function removeSecondaryCommodity() {
    $scope.contract.secondary_commodity_code = null;
    $scope.secondaryCommodityKeyword = null;
  }

  function removeContact(index) {
    $scope.contract.contacts.splice(index, 1);
  }

  function onUpdatedContact(contact, index) {
    $scope.contract.contacts.splice(index, 1, contact);
  }

  function onAddedContact(contact) {
    $scope.contract.contacts.splice($scope.contract.contacts.length, 0, contact);
  }

  function onSelectedPaymentTerm(paymentTerm) {
    $scope.contract.payment_term_code = paymentTerm;
  }

  function onSelectedPaymentMethod(paymentMethod) {
    $scope.contract.payment_method_code = paymentMethod;
  }

  function onSelectedTax(tax) {
    $scope.contract.tax_code = tax;
  }

  function onSelectedCurrency(currency)  {
    $scope.contract.currency_code = currency;
  }

  function selectedBumiputra(value) {
    $scope.contract.bumi_status = value;
    $scope.noBumiStatus = false;
  }

  function closeModal() {
    $uibModalInstance.close();
  }

  function initialize() {
    $scope.validatedBN = false;
    $scope.noBumiStatus = true;
    $scope.contract.currency_code = globalFunc.findInArray($scope.currencies, 'code', 'MYR');

    if (!!$scope.contract && $scope.contract.primary_commodity_code) {
      $scope.contract.primary_commodity = {
        code: $scope.contract.primary_commodity_code,
        descr: $scope.contract.primary_commodity_descr
      };
      $scope.primaryCommodityKeyword = $scope.contract.primary_commodity_code + ' ' + $scope.contract.primary_commodity_descr;
    }

    if (!!$scope.contract && $scope.contract.secondary_commodity_code) {
      $scope.contract.secondary_commodity = {
        code: $scope.contract.secondary_commodity_code,
        descr: $scope.contract.secondary_commodity_descr
      };
      $scope.secondaryCommodityKeyword = $scope.contract.secondary_commodity_code + ' ' + $scope.contract.secondary_commodity_descr;
    }
  }

  initialize();

  function createCompanyContract(contract, action) {

    $scope.saveAs = action;

    if(!validation(contract)) {
      toastr.error('Please complete all required fields before proceeding');
      return;
    }

    var newSupplierCompanyContract = preparePayload(contract);

    submitCompanyContract(newSupplierCompanyContract);
  }

  function submitCompanyContract(newSupplierCompanyContract) {
    var codes = [];

    $scope.selectedCompanyContract.forEach(function(contract){
      codes.push(contract.code);
    });

    newSupplierCompanyContract.company_codes = codes;

    companyContractResource.post({
        id: supplier._id
      },
      newSupplierCompanyContract,
      function(resource) {
        toastr.success(resource.message);
      },
      function(error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function preparePayload(contract) {
    var temp = 0;
    if ($scope.receive_via_email) {
      temp += 2;
    }
    if ($scope.receive_via_fax) {
      temp += 4;
    }
    if ($scope.receive_via_post) {
      temp += 8;
    }
    $scope.contract.receiving_mode = temp;

    return {
        payment_term_code: contract.payment_term_code.code,
        payment_method_code: contract.payment_method_code.code,
        receiving_mode: temp,
        tax_code: contract.tax_code.code,
        tax_rate: contract.tax_code.rate,
        contacts: contract.contacts,
        primary_business_nature: contract.primary_business_nature.value,
        secondary_business_nature: !!contract.secondary_business_nature ? contract.secondary_business_nature.value : '',
        primary_commodity_code: contract.primary_commodity.code,
        secondary_commodity_code: !!contract.secondary_commodity ? contract.secondary_commodity.code : '',
        bumi_status: contract.bumi_status,
        currency_code: contract.currency_code.code
    }
  }
}

massEditCompanyContractCtrl.$inject = [
  '$scope', '$uibModalInstance', 'globalFunc', 'paymentTerms', 'paymentMethods', 'taxes', 'searchModule', 'listChild',
  'supplierFunctions', 'toastr', 'companyContractResource', 'supplier'];
