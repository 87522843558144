'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function ($stateProvider) {

    $stateProvider
      .state('main.user.suppliersWeb', {
        url: '/supplier-web',
        templateUrl: 'app/suppliersWeb/index.html',
        abstract: true,
        resolve: {
          accessRights: function (profile, $rootScope, UserPermissions, $state) {
            if (!UserPermissions.hasSupplierWebUserRole(profile) ||
              !profile.tenant.config.supplier_web) {
                $state.go('notAuthorized');
            }
            // If current user has not accepted the terms and conditions,
            // redirect to accept terms and conditions
            else if (!$rootScope.currentUser.config.accepted_supplier_web_tnc) {
              $state.go('acceptSupplierWebTnC');
            }
          }
        }
      })
      .state('main.user.suppliersWeb.dashboard', {
        url: '/dashboard',
        controller: 'suppliersWebDashboardController',
        templateUrl: 'app/suppliersWeb/dashboard/dashboard.html',
        resolve: {
          poInProgress: function ($q, searchModule, profile, UserPermissions, $rootScope) {
            var deferred = $q.defer();
            var params = {
              module: 'PO',
              criteria_operator: 'and',
              offset: 10,
              order_by: 'needed_at',
              order: -1
            };

            params['criteria[0][status][0]'] = 'AWAITING_DELIVERY';
            params['criteria[0][status][1]'] = 'PARTIALLY_DELIVERED';
            params['criteria[0][$operator]'] = 'or';
            params['criteria[2][is_reversed][0]'] = '';
            params['criteria[2][is_reversed][1]'] = 0;
            params['criteria[2][$operator]'] = 'or';
            if ($rootScope.currentUser.supplier_web_enabled_companies.length > 0) {
              var index = 0;
              _.forEach($rootScope.currentUser.supplier_web_enabled_companies, function(code){
                params['criteria[3][billing|company|code][' + index + ']'] = code;
                index++;
              });
              params['criteria[3][$operator]'] = 'or';
            } else {
              params['criteria[3][billing|company|code][0]'] = '';
            }

            if (UserPermissions.hasSupplierCompanyUserRole(profile)) {
              params['criteria[1][supplier_object|_id]'] = profile.supplier_id;
            } else {
              params['criteria[1][supplier_object|contact_person|email]'] = profile.email;
            }

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function () {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          poSubmitted: function ($q, searchModule, profile, UserPermissions, $rootScope) {
            var deferred = $q.defer();
            var params = {
              module: 'PO',
              criteria_operator: 'and',
              offset: 10,
              order_by: 'created_at',
              order: -1
            };

            params['criteria[0][status][0]'] = 'SUBMITTED';
            params['criteria[0][status][1]'] = 'DELIVERY_DATE_UNCONFIRMED';
            params['criteria[0][$operator]'] = 'or';

            if (UserPermissions.hasSupplierCompanyUserRole(profile)) {
              params['criteria[1][supplier_object|_id]'] = profile.supplier_id;
            } else {
              params['criteria[1][supplier_object|contact_person|email]'] = profile.email;
            }

            // Exclude skip po
            params['criteria[2][skip_auto_send_po]'] = 0;

            if ($rootScope.currentUser.supplier_web_enabled_companies.length > 0) {
              var index = 0;
              _.forEach($rootScope.currentUser.supplier_web_enabled_companies, function(code){
                params['criteria[3][billing|company|code][' + index + ']'] = code;
                index++;
              });
              params['criteria[3][$operator]'] = 'or';
            } else {
              params['criteria[3][billing|company|code][0]'] = '';
            }

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function () {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          poReversed: function ($q, searchModule, profile, UserPermissions, $rootScope) {
            var deferred = $q.defer();
            var params = {
              module: 'PO',
              offset: 10,
              order_by: 'created_at',
              order: -1
            };

            params['criteria[0][is_reversed]'] = true;

            if (UserPermissions.hasSupplierCompanyUserRole(profile)) {
              params['criteria[1][supplier_object|_id]'] = profile.supplier_id;
            } else {
              params['criteria[1][supplier_object|contact_person|email]'] = profile.email;
            }

            if ($rootScope.currentUser.supplier_web_enabled_companies.length > 0) {
              var index = 0;
              _.forEach($rootScope.currentUser.supplier_web_enabled_companies, function(code){
                params['criteria[2][billing|company|code][' + index + ']'] = code;
                index++;
              });
              params['criteria[2][$operator]'] = 'or';
            } else {
              params['criteria[2][billing|company|code][0]'] = '';
            }

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function () {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Dashboard';
          }
        }
      })
      .state('main.user.suppliersWeb.po', {
        url: '/orders',
        controller: 'SupplierWebPoCtrl',
        templateUrl: 'app/suppliersWeb/po/po.html',
        resolve : {
          accessRights: function (profile, UserPermissions, $state) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'PO')) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.suppliersWeb.po.manage', {
        templateUrl: 'app/suppliersWeb/po/manage/manage.html',
        url: '/manage?{query:[a-zA-Z0-9]+}&{status:[a-zA-Z0-9]+}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'SupplierWebPoManageCtrl',
        resolve: {
          orders: function ($rootScope, $q, $state, $stateParams, Orders, searchModule, profile, globalFunc,
                            exportSearch, UserPermissions) {
            var deferred = $q.defer();
            var params = {};

            var status = globalFunc.availableStatuses('po', $stateParams.status);

            /**
             * First determine if search or listing
             */
            if (!!$stateParams.query) {
              /**
               * If searching , check if searching with filter
               */
              if (!!$stateParams.filter) {
                params = {
                  module: 'PO',
                  offset: 20,
                  criteria_operator: 'and',
                  page: $stateParams.page,
                  order_by: $stateParams.order_by,
                  order: $stateParams.order
                };

                var temp = '';
                if ($stateParams.type === 'date') {
                  temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                }
                else if ($stateParams.type === 'double') {
                  temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                }
                else {
                  params['criteria[1][' + $stateParams.filter + ']'] = $stateParams.query;
                }
              }
              /**
               * Else for listing and sorting without filter
               * Pass criteria[status] in an array with or condition based on status
               * Submitted tab will list submitted, delivery_date_unconfirmed and awaiting_confirmation status
               * Awaiting delivery tab will list awaiting_delivery, partially_delivered and in_port status
               * Closed tab will list closed and delivered status
               * Declined tab will list declined status
               * Cancelled tab will list cancelled status
               * Starred tab will list PO if stared_by current user
               */
              else {
                params = {
                  module: 'PO',
                  offset: 20,
                  'criteria[1][po_number]': $stateParams.query,
                  'criteria[1][pr_number]': $stateParams.query,
                  'criteria[1][title]': $stateParams.query,
                  'criteria[1][supplier.descr]': $stateParams.query,
                  'criteria[1][billing|company|descr]': $stateParams.query,
                  'criteria[1][do_number]': $stateParams.query,
                  criteria_operator: !!$stateParams.query ? 'and' : null,
                  'criteria[1][$operator]': 'or',
                  page: $stateParams.page,
                  order_by: $stateParams.order_by,
                  order: $stateParams.order
                };
              }
            }
            /**
             * Else for listing
             */
            else{
              params = {
                module: 'PO',
                criteria_operator: 'and',
                offset: 20,
                page: $stateParams.page,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              }
            }

            if (!!status) {
              /**
               * Pass param into status based on tab status
               */
              switch(status){
                case 'ALL':
                  params['criteria[0][$operator]'] = 'or';
                  // Exclude skip po
                  params['criteria[1][skip_auto_send_po]'] = 0;
                  if ($rootScope.currentUser.supplier_web_enabled_companies.length > 0) {
                    var index = 0;
                    _.forEach($rootScope.currentUser.supplier_web_enabled_companies, function(code){
                      params['criteria[2][billing|company|code][' + index + ']'] = code;
                      index++;
                    });
                    params['criteria[2][$operator]'] = 'or';
                  }
                  break;
                case 'SUBMITTED':
                  params['criteria[0][status][0]'] = 'SUBMITTED';
                  params['criteria[0][status][1]'] = 'DELIVERY_DATE_UNCONFIRMED';
                  params['criteria[0][$operator]'] = 'or';
                  // Exclude skip po
                  params['criteria[2][skip_auto_send_po]'] = 0;
                  if ($rootScope.currentUser.supplier_web_enabled_companies.length > 0) {
                    var index = 0;
                    _.forEach($rootScope.currentUser.supplier_web_enabled_companies, function(code){
                      params['criteria[3][billing|company|code][' + index + ']'] = code;
                      index++;
                    });
                    params['criteria[3][$operator]'] = 'or';
                  }
                  break;
                case 'AWAITING_DELIVERY':
                  params['criteria[0][status][0]'] = 'AWAITING_DELIVERY';
                  params['criteria[0][status][1]'] = 'PARTIALLY_DELIVERED';
                  params['criteria[0][$operator]'] = 'or';
                  params['criteria[2][is_reversed][0]'] = '';
                  params['criteria[2][is_reversed][1]'] = 0;
                  params['criteria[2][$operator]'] = 'or';
                  // Exclude skip po
                  params['criteria[2][skip_auto_send_po]'] = 0;
                  if ($rootScope.currentUser.supplier_web_enabled_companies.length > 0) {
                    var index = 0;
                    _.forEach($rootScope.currentUser.supplier_web_enabled_companies, function(code){
                      params['criteria[3][billing|company|code][' + index + ']'] = code;
                      index++;
                    });
                    params['criteria[3][$operator]'] = 'or';
                  }
                  params['criteria[3][billing|company|code][0]'] = 'META';
                  break;
                case 'CLOSED':
                  params['criteria[0][status][0]'] = 'CLOSED';
                  params['criteria[0][status][1]'] = 'FULLY_DELIVERED';
                  params['criteria[0][$operator]'] = 'or';
                  // Exclude skip po
                  params['criteria[1][skip_auto_send_po]'] = 0;
                  if ($rootScope.currentUser.supplier_web_enabled_companies.length > 0) {
                    var index = 0;
                    _.forEach($rootScope.currentUser.supplier_web_enabled_companies, function(code){
                      params['criteria[2][billing|company|code][' + index + ']'] = code;
                      index++;
                    });
                    params['criteria[2][$operator]'] = 'or';
                  }
                  break;
                case 'DECLINED':
                  params['criteria[0][status][0]'] = 'DECLINED';
                  if ($rootScope.currentUser.supplier_web_enabled_companies.length > 0) {
                    var index = 0;
                    _.forEach($rootScope.currentUser.supplier_web_enabled_companies, function(code){
                      params['criteria[2][billing|company|code][' + index + ']'] = code;
                      index++;
                    });
                    params['criteria[2][$operator]'] = 'or';
                  }
                  break;
                case 'CANCELLED':
                  params['criteria[0][status][0]'] = 'CANCELLED';
                  if ($rootScope.currentUser.supplier_web_enabled_companies.length > 0) {
                    var index = 0;
                    _.forEach($rootScope.currentUser.supplier_web_enabled_companies, function(code){
                      params['criteria[2][billing|company|code][' + index + ']'] = code;
                      index++;
                    });
                    params['criteria[2][$operator]'] = 'or';
                  }
                  break;
                case 'STARRED':
                  params['criteria[0][stared_by][]'] = profile._id;
                  if ($rootScope.currentUser.supplier_web_enabled_companies.length > 0) {
                    var index = 0;
                    _.forEach($rootScope.currentUser.supplier_web_enabled_companies, function(code){
                      params['criteria[2][billing|company|code][' + index + ']'] = code;
                      index++;
                    });
                    params['criteria[2][$operator]'] = 'or';
                  }
                  break;
                case 'REVERSED':
                  params['criteria[0][is_reversed]'] = true;
                  if ($rootScope.currentUser.supplier_web_enabled_companies.length > 0) {
                    var index = 0;
                    _.forEach($rootScope.currentUser.supplier_web_enabled_companies, function(code){
                      params['criteria[2][billing|company|code][' + index + ']'] = code;
                      index++;
                    });
                    params['criteria[2][$operator]'] = 'or';
                  }
                  break;
              }
            }

            // Get supplier user only
            if (UserPermissions.hasSupplierCompanyUserRole(profile)) {
              params['criteria[1][supplier_object|_id]'] = profile.supplier_id;
            } else {
              params['criteria[1][supplier_object|contact_person|email]'] = profile.email;
            }

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function () {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "PO - listing" + (!!$stateParams.status ? " - " +
              $stateParams.status.toLowerCase() : "");
          }
        }
      })
      .state('main.user.suppliersWeb.po.detail', {
        templateUrl: 'app/suppliersWeb/po/detail/detail.html',
        url: '/{id:[a-zA-Z0-9]+}?{title:[a-zA-Z0-9]*}&{action:[a-zA-Z0-9]*}',
        controller: 'SupplierWebPoDetailCtrl',
        resolve: {
          order: function ($rootScope, $q, $state, toastr, $stateParams, $filter, Orders,
                           authenticated) {
            var deferred = $q.defer();

            if (!authenticated) {
              deferred.resolve({});
              return deferred.promise;
            }

            var method = Orders.get;
            method(
              {id: $stateParams.id},
              function (resource) {
                if(!!resource && !!resource.content && !!resource.content.data){
                  deferred.resolve(resource.content.data);
                } else {
                  deferred.resolve({});
                }
              },
              function (error) {
                toastr.error("PO not found");
                if(!!$rootScope.previousState && !!$rootScope.previousState.name){
                  $state.go($rootScope.previousState.name, $rootScope.previousState.params);
                } else {
                  $state.go('main.user.orders.manage', {status: $stateParams.status || "SUBMITTED"});
                }
              }
            );
            return deferred.promise;
          },
          pendingOrder: function ($q, pendingSupplierPo) {
            var deferred = $q.defer();
            pendingSupplierPo.get(
              {},
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function () {
                deferred.resolve([]);
              });

            return deferred.promise;
          },
          POSubscriptionCheck: function($q, order, subscriptionCheck){
            var deferred = $q.defer();
            subscriptionCheck.get(
              {
                object_id: order._id,
                class: 'Metabuyer\\Models\\PO'
              },
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function($stateParams){
            return "PO - detail" + (!!$stateParams.id? " - " + $stateParams.id : "");
          }
        }
      })
      .state('main.user.suppliersWeb.reports', {
        templateUrl: 'app/suppliersWeb/reports/generate/generate.html',
        url: '/generate-report',
        controller: 'supplierWebReportsGenerationCtrl',
        resolve: {
          companies: function(searchModule, $q) {
            var deferred = $q.defer();
            var params = {
              offset: 0,
              module: 'companies',
              order: 1,
              order_by: 'descr'
            };

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content.data || {data: []});
              },
              function () {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function(){
            return 'Reports - Generate';
          }
        }
      })
      .state('main.user.suppliersWeb.grn', {
        templateUrl: 'app/suppliersWeb/grn/grn.html',
        url: '/grn',
        controller: 'SupplierWebGrnCtrl',
        resolve: {
          $title: function(){
            return 'Goods Receive Note';
          }
        }
      })
      .state('main.user.suppliersWeb.grn.manage', {
        templateUrl: 'app/suppliersWeb/grn/manage/manage.html',
        url: '/manage?{query:[a-zA-Z0-9]+}&{status:[a-zA-Z0-9]+}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'SupplierWebGrnManageCtrl',
        resolve: {
          grnListing: function ($rootScope, $q, $state, $stateParams, searchModule, profile, globalFunc, exportSearch) {
            var deferred = $q.defer();
            var params = {
              module: 'GRN',
              offset: 20,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            /**
             * First determine if search or listing
             */
            if (!!$stateParams.query) {
              /**
               * If searching , check if searching with filter
               */
              if (!!$stateParams.filter) {
                params['criteria[1][' + $stateParams.filter + ']'] = $stateParams.query;
              } else {
                params['criteria[1][po_number]'] = $stateParams.query;
                params['criteria[1][pr_number]'] = $stateParams.query;
                params['criteria[1][$operator]'] = 'or';
              }
            }

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function () {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Goods Receive Note';
          }
        }
      })
  }]);
