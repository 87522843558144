'use strict';
/**
 * @name approvalFlowManagementController
 * @desc Controller for Approval Flow Management for listing All the available tabs and functions of the Approval Flow
 */

function approvalFlowManagementController (
  $scope, approvalTemplates, globalFunc, approvalTemplateSingle, toastr, $state, searchModule,
  $uibModal, companies, companyExpenseTypes, HighlightId, getApprovalZip, $filter, pathConstants, $interval, checkApprovalZipStatus, profile,
  $rootScope, getAlcGroup, catalogGroupList, DataListService
) {

  $scope.isSourcingEnabled = !!$rootScope.isSourcingSupplierEnabled;

  $scope.columns = [
    { id: 'number', label: '#', unsortable: true, unsearchable: true, unSelectable: true },
    { id: 'module', label: 'Type' },
    { id: 'code', label: 'Code' },
    { id: 'title', label: 'Title' },
    { id: 'group', label: 'Group' , unsortable: true, unsearchable: true},
    { id: 'company', label: 'Company' , unsortable: true, unsearchable: true},
    { id: 'cost_center', label: 'Cost Center' , unsortable: true, unsearchable: true},
    { id: 'expense_type', label: 'Expense Type' , unsortable: true, unsearchable: true},
    { id: 'effective_at', label: 'Effective At', unsortable: true, unsearchable: true},
    { id: 'created_at', label: 'Created At' },
    { id: 'updated_at', label: 'Updated At' },
    { id: 'created_by', label: 'Created By', unsortable: true, unsearchable: true},
    { id: 'updated_by', label: 'Updated By', unsortable: true, unsearchable: true},
    { id: 'status', label: 'Status' },
    // { id: 'remove', label: 'Remove', unsortable: true, unsearchable: true, unSelectable: true }
  ];

  $scope.meta = [];
  $scope.page = 0;
  $scope.limit = 10;
  $scope.currentStatus = {
    col: null,
    order: null
  };
  $scope.timezoneDiff = '';
  $scope.colSorting = null;
  $scope.colOrder = null;
  $scope.storageKey = 'approval-templates-manage-selected-columns';
  $scope.companies = [];
  $scope.uiCompanySelect = {};
  $scope.uiCompanySelect.list = {};
  $scope.uiCostCenterSelect = {};
  $scope.uiCostCenterSelect.list = {};
  $scope.uiExpenseSelect = {};
  $scope.uiExpenseSelect.list = {};
  $scope.companyListing = [];
  $scope.expenseTypesListing = null;
  $scope.checkTemplateType = checkTemplateType;
  $scope.templateTypes = [];
  $scope.uiType = {};
  $scope.uiType.list = [];
  $scope.initializeSearchField = [];
  $scope.uiCode = null;
  $scope.uiTitle = null;
  $scope.uiExpense = null;
  $scope.statusListing = [
    {
      code: '0',
      descr: 'Active'
    },
    {
      code: '1',
      descr: 'Inactive'
    }
  ];
  $scope.status = {};
  $scope.status.list = [];
  $scope.moduleName = 'Approval Template';
  $scope.visibleColumn = visibleColumn;
  $scope.search = search;
  $scope.removeTemplate = removeTemplate;
  $scope.exportTemplate = exportTemplate;
  $scope.resetSelection = resetSelection;
  $scope.processCostCenterList = processCostCenterList;
  $scope.downloadApprovalTemplateZip = downloadApprovalTemplateZip;
  $scope.searched = false;

  function filterCompanyListing() {
    $scope.availableCompanies = profile.companies;
    if (globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Approval Flow Viewer')){
      companies.data.forEach(function(company){
        $scope.availableCompanies.forEach(function(availableCompany){
          if (company.descr === availableCompany.descr) {
            $scope.companyListing.push(company);
          }
        })
      })
    } else {
      $scope.companyListing = companies.data;
    }
  }

  function validateBeforeSearch() {
    var validData = true;
    if (!$scope.uiType.list) {
      toastr.error('Module type cannot be empty.');
      validData = false;
    }
    if (!$scope.uiCompanySelect || !$scope.uiCompanySelect.list || !$scope.uiCompanySelect.list.code) {
      toastr.error('Company cannot be empty.');
      validData = false;
    }
    return validData;
  }

  function initializeTemplateTypes() {
    $scope.templateTypes = [
      {
        code: 'PR',
        descr: 'PR'
      },
      {
        code: 'Budget',
        descr: 'Budget'
      },
      {
        code: 'SUPPLIERCOMPANYCONTRACT',
        descr: 'Company Supplier'
      },
      {
        code: 'PURCHASEORDER',
        descr: 'Purchase Order'
      },
      {
        code: 'Delegation',
        descr: 'Delegation'
      }
    ];

    var isApprovalFlowViewer = globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Approval Flow Viewer');
    var isTenantAdmin = globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN');
    var isAdmin = globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Admin');

    if (!isApprovalFlowViewer || isTenantAdmin || isAdmin){
      $scope.templateTypes.push({
        code: 'Supplier', descr: 'Supplier'
      },
      {
        code: 'Catalog', descr: 'Catalog'
      },
      {
        code: 'APPROVALLIMITCONTROL',
        descr: 'Approval Limit Control'
      });
    }
    if (!!isAdmin || !!isTenantAdmin) {
      $scope.templateTypes.push({
          code: 'BUDGETCREATE',
          descr: 'Budget Creation'
        },
        {
          code: 'BUDGETREVISION',
          descr: 'Budget Revision'
        });
    }
    if (!!$scope.isSourcingEnabled) {
      $scope.templateTypes.push({
        code: 'SOURCINGANDBIDDINGTEMPLATEPUBLISHING',
        descr: 'Sourcing and Bidding Template Publishing'
      },
      {
        code: 'SOURCINGANDBIDDINGEVENTPUBLISHING',
        descr: 'Sourcing and Bidding Event Publishing'
      },
      {
        code: 'SOURCINGANDBIDDINGEVENTAWARDING',
        descr: 'Sourcing and Bidding Event Awarding'
      });
    }

    if (!!$rootScope.isV2Enabled) {
      $scope.templateTypes.push({
        code: 'TENDER',
        descr: 'Tender'
      });
    }
  }

  function checkTemplateType() {
    switch($scope.uiType.list.code) {
      case 'Catalog':
        clearGroupCompany();
        loadCatalogGroup();
        break;
      case 'APPROVALLIMITCONTROL':
        clearGroupCompany();
        loadAlcGroup();
        break;
      default:
        clearGroupCompany();
        filterCompanyListing();
    }
  }

  function visibleColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function prepareMeta() {
    $scope.processedData = [];
    $scope.processedData = $scope.dataList;
    $scope.meta.currentPageNumber = $scope.dataList.meta.current;
    $scope.meta.totalPageNumber = $scope.dataList.meta.pages;
    $scope.meta.totalRec = $scope.dataList.meta.count;
    $scope.meta.startRecNo = $scope.limit * $scope.page + 1;
    $scope.meta.endRecNo = $scope.meta.totalRec - ((1 + $scope.meta.currentPageNumber) * $scope.limit) >= 0 ?
      (1 + $scope.meta.currentPageNumber) * $scope.limit :
      (($scope.meta.currentPageNumber) * $scope.limit) + $scope.meta.totalRec % $scope.limit;
    $scope.dataList = $scope.processedData;
  }

  function getStatus(status) {
    var statusString = '';
    switch (status) {
      case 0:
        statusString = 'Active';
        break;
      case 1:
        statusString = 'Inactive';
        break;
    }
    return statusString;
  }

  function search(page, sortingCol, sortingOrder) {
    if (globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Approval Flow Viewer')){
      if (validateBeforeSearch() === false){
        return;
      }
    }
    $scope.page = page;
    $scope.colSorting = sortingCol;
    $scope.colOrder = sortingOrder;

    approvalTemplates.get(
      {
        'sort[0][key]': sortingCol,
        'sort[0][direction]': sortingOrder,
        'sort[1][key]': 'status',
        'sort[1][direction]': 'ASC',
        'sort[2][key]': 'updated_at',
        'sort[2][direction]': 'DESC',
        'filter_groups[0][filters][0][key]': 'module',
        'filter_groups[0][filters][0][value]': !!$scope.uiType.list.code ? $scope.uiType.list.code : '',
        'filter_groups[0][filters][0][operator]': 'ct',
        'filter_groups[1][filters][0][key]': 'code',
        'filter_groups[1][filters][0][value]': !!$scope.uiCode ? $scope.uiCode : '',
        'filter_groups[1][filters][0][operator]': 'ct',
        'filter_groups[2][filters][0][key]': 'title',
        'filter_groups[2][filters][0][value]': !!$scope.uiTitle ? $scope.uiTitle : '',
        'filter_groups[2][filters][0][operator]': 'ct',
        'filter_groups[3][filters][0][key]': 'status',
        'filter_groups[3][filters][0][value]': !!$scope.status.list.code ? $scope.status.list.code : '',
        'filter_groups[3][filters][0][operator]': 'ct',
        'filter_groups[4][filters][0][key]': 'rule',
        'filter_groups[4][filters][0][value]': [
          ($scope.uiType.list.code == 'PR' || $scope.uiType.list.code == 'PURCHASEORDER' || $scope.uiType.list.code == 'Budget'
            || $scope.uiType.list.code == 'BUDGETCREATE' || $scope.uiType.list.code == 'BUDGETREVISION')
          && $scope.uiType.list.code != 'Catalog' && !!$scope.uiCompanySelect.list
          && !!$scope.uiCompanySelect.list.code ? 'company:' + $scope.uiCompanySelect.list.code : '',
          ($scope.uiType.list.code == 'Catalog' || $scope.uiType.list.code == 'APPROVALLIMITCONTROL')
          && !!$scope.uiCompanySelect.list && !!$scope.uiCompanySelect.list.code ? 'group:' + $scope.uiCompanySelect.list.code : '',
          !!$scope.uiCostCenterSelect.list && !!$scope.uiCostCenterSelect.list.code ? 'cost_center:' + $scope.uiCostCenterSelect.list.code : '',
          !!$scope.uiExpenseSelect.list && !!$scope.uiExpenseSelect.list.code ? 'expense_type:' + $scope.uiExpenseSelect.list.code : ''
        ].join(),
        'filter_groups[4][filters][0][operator]': 'ct',
        limit: $scope.limit,
        page: page
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data && !$rootScope.retainApprovalData) {
          $scope.dataList = resource.content.data;
          $scope.dataList.meta = resource.content.meta.cursor;
          prepareMeta();
          prepareDataList();
          $scope.searched = true;
          if (!$rootScope.isV2Enabled){
            $scope.initializeSearchField = [
              $scope.uiType.list, 
              $scope.uiCode,
              $scope.uiTitle,
              $scope.status.list,
              $scope.uiCompanySelect.list,
              $scope.uiCostCenterSelect.list,
              $scope.uiExpenseSelect.list
            ];
            DataListService.setDataList($scope.dataList, $scope.initializeSearchField);
          }
        }
      },
      function (resource) {
        if (
          !!resource.data &&
          !!resource.data.content &&
          !!resource.data.content.message &&
          !!resource.data.content.message.errors
        ) {
          toastr.error(_.map(resource.data.content.message.errors, _.property('detail')).toString());
        }
        else {
          toastr.error('Failed to search');
        }
      }
    );
  }

  function prepareDataList() {
    _.forEach($scope.dataList, function(approval) {
      approval.status = getStatus(approval.status);
      approval.created_at = globalFunc.convertToLocalDate(approval.created_at);
      approval.updated_at = globalFunc.convertToLocalDate(approval.updated_at);
      approval.effective_date = globalFunc.convertToLocalDate(approval.effective_date);
    });
  }

  function removeTemplate(approval) {
    swal(
      {
        title: 'Warning!',
        text: 'Are you sure you want to delete \'' + approval.title + '\' ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (isConfirm) {
          approvalTemplateSingle.delete({
            id: approval.id
          }, function (resource) {
            // search($scope.meta['currentPageNumber']);
            toastr.success('Approval template has been successfully deleted.');
            $state.reload();
          }, function (error) {
            if (
              !!resource.data &&
              !!resource.data.content &&
              !!resource.data.content.message &&
              !!resource.data.content.message.errors
            ) {
              toastr.error(_.map(resource.data.content.message.errors, _.property('detail')).toString());
            } else {
              toastr.error('Failed to delete selected approval template.');
            }
          });
        }
      }
    );
  }

  function exportTemplate() {
    $uibModal.open({
      templateUrl: 'app/approvalFlow/manage/export.html',
      controller: 'exportTemplateController',
      backdrop: 'static',
      keyboard: false,
      resolve:{
        companies: function() {
          return $scope.companies;
        }
      }
    });
  }

  function exportIsEnabled() {
    if (globalFunc.checkUserPermission($rootScope.currentUser, $scope.moduleName)){
      $scope.disableExportButton = true;
    } else {
      getCompanies();
    }
  }

  function getCompanies() {
    $scope.disableExportButton = true;
    searchModule.get(
      {
        module: 'companies',
        offset: 0,
        order_by: 'descr',
        order: 1
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.companies = resource.content.data;
          $scope.disableExportButton = false;
        }
      }
    );
  }

  function resetSelection() {
    $scope.uiType.list = [];
    $scope.uiCode = null;
    $scope.uiTitle = null;
    $scope.uiCompanySelect.list = null;
    $scope.uiCostCenterSelect.list = null;
    $scope.uiExpenseSelect.list = null;
    $scope.status.list = [];
    $scope.searched = false;
  }

  function processCostCenterList() {
    $scope.uiCostCenterSelect.list = {};
    $scope.uiExpenseSelect.list = [];

    //stop when no company is selected
    if (!$scope.uiCompanySelect.list.code) {
      $scope.costCenterListing = [];
      return;
    }

    loadExpenseTypes();

    var params = {
      module: 'cost-center',
      offset: 0,
      criteria_operator: 'and',
      order_by: 'descr',
      order: 1
    };

    if ($scope.uiCompanySelect.list.code !== 'SYSTEM')
      params['criteria[company_code]'] = $scope.uiCompanySelect.list.code;

    searchModule.get(
      params,
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.costCenterListing = resource.content.data;
          $scope.costCenterListing.unshift({'code':'all', 'descr':'All'});
        }
      }
    );
  }

  function loadCatalogGroup() {
    $scope.companyListing = [];
    catalogGroupList.get(
      {},
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.companyListing = resource.content.data;
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function loadAlcGroup() {
    $scope.companyListing = [];
    getAlcGroup.get(
        {
          offset: 0
        },
        function (resource) {
          if (!!resource.content && !!resource.content.data) {
            $scope.companyListing = resource.content.data;
          }
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    }

  function loadExpenseTypes() {
    companyExpenseTypes.get(
      {
        id: $scope.uiCompanySelect.list._id
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.expenseTypesListing = resource.content.data;
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function clearGroupCompany() {
    $scope.uiCompanySelect.list = null;
    $scope.uiCostCenterSelect.list = null;
    $scope.uiExpenseSelect.list = null;
  }

  /**
   * Function to check the export status with intervals
   * @param id
   */
  function checkExport(id){

    checkApprovalZipStatus.get(
      {
        id: id
      },
      function(resource){
        if(!!resource.content && !!resource.content.data &&
          !!resource.content.message && !!resource.content.data.status){

          /**
           * Clearing the toastr before every call for status checking to simulate 'refreshing' the toastr
           */
          toastr.clear();

          var checkingStatus = true;

          /**
           * Status for export CSV
           * 0 = PENDING
           * 1 = IN_PROGRESS
           * 2 = DONE
           * 3 = FAILED
           * 4 = CANCELLED
           */
          switch(resource.content.data.status){
            case 0:
              toastr.info(resource.content.message, {timeOut: 0, extendedTimeOut: 0});
              break;
            case 1:
              toastr.info(resource.content.message, {timeOut: 0, extendedTimeOut: 0});
              break;
            case 2:
              /**
               * If has is returned
               */
              if(!!resource.content.data.hash){
                toastr.success(resource.content.message,
                  {timeOut: 0, extendedTimeOut: 0, onClose: downloadZipExport(resource.content.data.hash)});
              }
              /**
               * Else condition to handle case if hash not returned by backend
               */
              else{
                toastr.error('There was an error with the export. Please try again');
              }
              checkingStatus = false;
              $interval.cancel($scope.startChecking);
              break;
            case 3:
              checkingStatus = false;
              toastr.error(resource.content.message, {timeOut: 0, extendedTimeOut: 0});
              $interval.cancel($scope.startChecking);
              break;
            case 4:
              checkingStatus = false;
              toastr.info(resource.content.message, {timeOut: 0, extendedTimeOut: 0});
              $interval.cancel($scope.startChecking);
              break;
          }

          $scope.checkingExportStatus = checkingStatus;
        }
      },
      function(error){
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function downloadApprovalTemplateZip() {
    if ($scope.searched && $scope.dataList.meta.count < 201) {
      swal(
        {
          title: 'Warning!',
          text: 'Are you sure you want to download ' + $scope.dataList.meta.count + ' approval templates?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#1ab394',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true,
          closeOnCancel: true
        },
        function (isConfirm) {
          if (isConfirm) {
            getApprovalZip.get(
              {
                page: $scope.page,
                'sort[0][key]': $scope.sortingCol,
                'sort[0][direction]': $scope.sortingOrder,
                'sort[1][key]': 'status',
                'sort[1][direction]': 'ASC',
                'sort[2][key]': 'updated_at',
                'sort[2][direction]': 'DESC',
                'filter_groups[0][filters][0][key]': 'module',
                'filter_groups[0][filters][0][value]': !!$scope.uiType.list.code ? $scope.uiType.list.code : '',
                'filter_groups[0][filters][0][operator]': 'ct',
                'filter_groups[1][filters][0][key]': 'code',
                'filter_groups[1][filters][0][value]': !!$scope.uiCode ? $scope.uiCode : '',
                'filter_groups[1][filters][0][operator]': 'ct',
                'filter_groups[2][filters][0][key]': 'title',
                'filter_groups[2][filters][0][value]': !!$scope.uiTitle ? $scope.uiTitle : '',
                'filter_groups[2][filters][0][operator]': 'ct',
                'filter_groups[3][filters][0][key]': 'status',
                'filter_groups[3][filters][0][value]': !!$scope.status.list.code ? $scope.status.list.code : '',
                'filter_groups[3][filters][0][operator]': 'ct',
                'filter_groups[4][filters][0][key]': 'rule',
                'filter_groups[4][filters][0][value]': [
                  !!$scope.uiCompanySelect.list && !!$scope.uiCompanySelect.list.code ? 'company:' + $scope.uiCompanySelect.list.code : '',
                  !!$scope.uiCostCenterSelect.list && !!$scope.uiCostCenterSelect.list.code ? 'cost_center:' + $scope.uiCostCenterSelect.list.code : '',
                  !!$scope.uiExpenseSelect.list && !!$scope.uiExpenseSelect.list.code ? 'expense_type:' + $scope.uiExpenseSelect.list.code : ''
                ].join(),
                'filter_groups[4][filters][0][operator]': 'ct'
              },
              function (resource) {
                if(!!resource.content && !!resource.content.data && !!resource.content.message) {

                  toastr.info('Pdf is being exported. The download will start automatically', {
                    timeOut: 0,
                    extendedTimeOut: 0
                  });

                  /**
                   * Calling the function checkExport with interval of 5 seconds
                   * Set the promise to a scope in order to cancel the call once generation is complete
                   */
                  //TODO: Support multiple interval calling
                  $scope.startChecking = $interval(function () {
                    checkExport(resource.content.data._id);
                  }, 5000);
                }
              },
              function (error) {
                $scope.checkingExportStatus = false;
                toastr.error('Pdf failed to be exported');
              }
            );
          }
        }
      );
    } else if ($scope.dataList.meta.count > 200 && $scope.searched === true) {
      return globalFunc.objectErrorMessage('Please narrow the results to under 200 approval templates!');
    } else {
      return globalFunc.objectErrorMessage('Please use the search function to narrow the results!');
    }
  }

  /**
   * Function to download the zip, this will be called automatically once generation is done
   * @param hash
   */
  function downloadZipExport(hash){
    var newUrl = $filter('format')(pathConstants.apiUrls.globalZipHashDownload, {
      zip_hash: hash
    });
    window.open(newUrl, '_self');
  }

  function initialize() {
    if (!!$rootScope.retainApprovalData && !$rootScope.isV2Enabled) {
      var dataList = DataListService.getDataList();
      var initializeSearchField = DataListService.getSearchField()
      if (!!dataList) {
          $scope.dataList = dataList;
          $scope.meta = $scope.dataList.meta;
          prepareDataList();
      }   
      if (!!$scope.meta) {
          prepareMeta();
      }
      if (!!initializeSearchField) {
        $scope.uiType.list = initializeSearchField[0];
        $scope.uiCode = initializeSearchField[1];
        $scope.uiTitle = initializeSearchField[2];
        $scope.status.list = initializeSearchField[3];
        $scope.uiCompanySelect.list = initializeSearchField[4];
        if (($scope.uiType.list.code == 'PR' || $scope.uiType.list.code == 'PURCHASEORDER') && !!$scope.uiCompanySelect.list) {
          processCostCenterList();
          $scope.uiCostCenterSelect.list = initializeSearchField[5];
          $scope.uiExpenseSelect.list = initializeSearchField[6];
          }
      
      }
    }
    initializeTemplateTypes();

    $scope.timezoneDiff = globalFunc.getTimezoneDiff();
    $scope.highlightId = HighlightId.getId();
    if (!!$scope.highlightId) {
      HighlightId.setId(null);
    }
    if (globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Approval Flow Viewer') === false && !(!!$rootScope.retainApprovalData)){
      search(0,null,null);
    }

    exportIsEnabled();
    filterCompanyListing();
    if(!$rootScope.retainApprovalData) {
      $scope.uiType.list = $scope.templateTypes[0];
      $scope.status.list = $scope.statusListing[0];
    }
    $rootScope.retainApprovalData = false;
    $scope.importParams = {
      class: 'Metabuyer\\ApprovalFlow\\Templates\\Models\\ApprovalTemplate',
      'companyListing': $scope.companyListing,
      'getAlcGroupFunc': getAlcGroup,
      'catalogGroupListFunc': catalogGroupList
    };
  }

  initialize();
}

approvalFlowManagementController.$inject = [
  '$scope', 'approvalTemplates', 'globalFunc', 'approvalTemplateSingle', 'toastr', '$state',
  'searchModule', '$uibModal', 'companies', 'companyExpenseTypes', 'HighlightId', 'getApprovalZip', '$filter','pathConstants', '$interval', 'checkApprovalZipStatus',
  'profile', '$rootScope', 'getAlcGroup', 'catalogGroupList', 'DataListService'
];

angular
  .module('metabuyer')
  .controller('approvalFlowManagementController', approvalFlowManagementController);
