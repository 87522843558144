'use strict';


/**
 * //TODO: document
 * @param $scope
 * @param pathConstants
 * @param $http
 * @param globalFunc
 * @param $filter
 * @param searchModule
 * @param purchaseRequisitionsV2Services
 * @param $rootScope
 * @param toastr
 * @param prV2Function
 * @param lang
 * @param loadCacheableData
 */
function catalogNonCatalogItemV2Ctrl(
  $scope, pathConstants, $http, globalFunc, $filter, searchModule, purchaseRequisitionsV2Services, $rootScope, toastr,
  prV2Function, lang, loadCacheableData, currenciesMasterDataList, Companies
) {
  $scope.paymentTermsList = [];
  $scope.taxList = getTaxData;
  $scope.nonCatalogOtherChargesList = [
    {code: 'insurance', name: 'Insurance',isSelected : false, quantity: 0, unitPrice: 0, amount: 0, discount:0, tax:0, total:0},
    {code: 'freight', name: 'Freight', isSelected :false, quantity: 0, unitPrice: 0, amount: 0, discount:0, tax:0, total:0},
    {code: 'transportation', name: 'Transportation', isSelected : false, quantity: 0, unitPrice: 0, amount:0, discount:0, tax:0, total:0},
    {code: 'bahan_bakar_tax', name: 'Bahan Bakar Tax 7.5%', isSelected : false, quantity: 0, unitPrice: 0, amount:0, discount:0, tax: 0, total:0},
    {code: 'withholding_tax', name: 'Withholding Tax 10%', isSelected : false,  quantity: 0, unitPrice: 0, amount:0, discount:0, tax:0,total:0},
    {code: 'miscellaneous', name: 'Miscellaneous', isSelected : false, remark:'', quantity: 0, unitPrice: 0, amount:0, discount:0, tax:0, total:0},
  ];

  $scope.originalNonCatalogOtherChargesList = _.cloneDeep($scope.nonCatalogOtherChargesList);

  var defaultOtherChargesList = _.cloneDeep($scope.nonCatalogOtherChargesList);

  loadCacheableData.loadData({
    module: 'payment-term',
    'criteria[is_active]': 1,
    offset: 0
  }, 'payment-term', 'localStorage').then(function(data){
    $scope.paymentTermsList = data;
  });
  loadCacheableData.loadData({
    module: 'tax',
    'criteria[is_active]': 1,
    'criteria[country_code]': prV2Function.getPRData().company.company_country,
    offset: 0
  }, 'taxes', 'localStorage').then(function(data){
    var x;

    $scope.taxesList = [];

    for (x in data) {
      if (data[x].country_code ===  prV2Function.getPRData().company.company_country) {
        $scope.taxesList.push(data[x]);
      }
    }
  });
  loadCacheableData.loadData({
    module: 'currencies',
    'criteria[is_active]': 1,
    offset: 0
  }, 'currencies', 'localStorage').then(function(data){
    $scope.currenciesList = data;
  });
  loadCacheableData.loadData({
    module: 'uom',
    'criteria[is_active]': 1,
    offset: 0
  }, 'UOMs', 'localStorage').then(function(data){
    $scope.UOMsList = data;
  });

  $scope.PR = prV2Function.getPRData();
  $scope.uomSearching = false;
  $scope.supplierSearching = false;
  $scope.commoditySearching = false;
  $scope.editingState = false;
  $scope.formSubmitted = false;
  $scope.commodityLevel = [4];
  $scope.invalidSupplierSelected = false;
  $scope.invalidBudgetSelected = false;
  $scope.costCenters = prV2Function.getPRsCCs();
  $scope.searchSuppliers              = searchSuppliers;
  // $scope.submitNonCatalogItem         = submitNonCatalogItem;
  $scope.onSupplierSelect             = onSupplierSelect;
  $scope.validateFieldSelection       = validateFieldSelection;
  // $scope.deselectAccountCode          = deselectAccountCode;
  // $scope.formatAccountCodeModel       = formatAccountCodeModel;
  // $scope.validateAccountSelection     = validateAccountSelection;
  // $scope.searchAccountCode            = searchAccountCode;
  // $scope.onAccountCodeSelect          = onAccountCodeSelect;
  // $scope.isAccountCodeRequired        = isAccountCodeRequired;
  $scope.removeImage = removeImage;
  $scope.searchItems = searchItems;
  $scope.clearForm = clearForm;
  $scope.onItemSelect = onItemSelect;
  $scope.loadBudgets = loadBudgets;
  $scope.onSelectOtherCharges = onSelectOtherCharges;
  $scope.selectedOtherCharges = selectedOtherCharges;
  $scope.removeOtherCharges = removeOtherCharges;
  $scope.updateBudget = updateBudget;
  $scope.updateFoc = updateFoc;
  $scope.hasCostCenter = hasCostCenter;
  $scope.validateItemFieldSelection = validateItemFieldSelection;
  $scope.getOtherChargesGroupSearch = getOtherChargesGroupSearch;
  $scope.onSelectOtherChargesGroup = onSelectOtherChargesGroup;
  $scope.updateOtherChargesGroup = updateOtherChargesGroup;
  $scope.removeOtherChargesGroup = removeOtherChargesGroup;


  $scope.disableOtherChargesGroup = disableOtherChargesGroup;
  $scope.currencySelected = currencySelected;
  $scope.getProjectCodeSearch = getProjectCodeSearch;
  $scope.updateProjectCode = updateProjectCode;
  $scope.refreshProjectCode = refreshProjectCode;
  $scope.isBudgetMandatory = isBudgetMandatory;
  $scope.showBudgetCode = showBudgetCode;
  $scope.imageUrl = pathConstants.apiUrls.purchaseRequisitionsV2.nonCatalogImage;
  $scope.imageFileTypes = '.jpg, .png, .jpeg';
  $scope.attachmentUrl = pathConstants.apiUrls.purchaseRequisitionsV2.attachment.list;
  $scope.attachmentParam = {requisition_id: $scope.PR._id};
  $scope.attachmentFileTypes = '.xls, .doc, .ppt, .txt, .pdf, .jpg, .png, .gif, .bmp, .xlsx, .docx, .pptx, .jpeg, .msg';
  $scope.itemData = {};
  $scope.allow_add_budget = prV2Function.getDocumentRule('allow_add_budget');
  $scope.is_budget_mandatory = $scope.allow_add_budget === true && prV2Function.getDocumentRule('is_budget_mandatory');
  $scope.expense_type = prV2Function.getExpenseTypeMode();
  $scope.allowNoPricingSelection = true;
  $scope.allowAddProjectCode = $scope.PR.expense_type.category === 'CAPEX';
  $scope.showProjectCodeAsterick = false;
  $scope.priceComparisonEnabled = $scope.PR.company.config.price_comparison || false;

  function isBudgetMandatory() {
    return ($scope.is_budget_mandatory && !$scope.nonCatalog.no_pricing_available)
      || ($scope.nonCatalog.no_pricing_available && $scope.priceComparisonEnabled && $scope.is_budget_mandatory);
  }

  function refreshProjectCode() {
    if (_.isEmpty($scope.nonCatalog.project_code))
      $scope.nonCatalog.project_code = '';
  }

  function currencySelected(currency){
    currenciesMasterDataList.get(
      { id: currency._id },
      function (resource) {
        if (!!resource.content && !!resource.content.data)
          $scope.nonCatalog.currency.allow_decimal = resource.content.data.allow_decimal;
          updateOtherChargesGroup();
      }
    )
  }

  function prepareOtherChargesGroup(otherChargesGroup) {
    $scope.otherChargesList = [];
    _.forEach(otherChargesGroup, function(charge){
      var currentCharge = _.find($scope.nonCatalogOtherChargesList, function(e){
        return e.code.toUpperCase() === charge.code.toUpperCase();
      });

      if (!_.isEmpty(currentCharge)) {
        if (charge.calculation_method.code === 'FLAT_AMOUNT_PER_UNIT'){
          currentCharge['original_unit_price'] = charge['unit_price'];
        }

        currentCharge['name'] = charge['name'];
        currentCharge['amount'] = calculateOtherChargesGroupAmount(charge);
        currentCharge['tax'] = getTax(charge);
        currentCharge['percentage'] = charge['percentage'];
        currentCharge['calculation_method'] = charge['calculation_method'];
        currentCharge['isSelected'] = true;
        currentCharge['other_charges_group'] = true;
        currentCharge['currency'] = charge['currency'];

        if (!_.isEmpty(charge['tax'])) {
          currentCharge['is_other_charges_group_tax'] = true;
        }

      }
      $scope.otherChargesList.push(currentCharge);
    });
  }

  function getTax(charge) {
    // for tax not set at master data
    if (_.isEmpty(charge.tax)) {
      var res = $scope.nonCatalog[charge.code.toLowerCase()];
      // check for tax has been set at pr line item. it will populate tax data in non catalog item.
      if (_.isEmpty(res)) {
        return null;
      } else {
        return _.isEmpty(res.tax) ? null : res.tax;
      }
    } else {
      // tax is set at master data. return array value
      return charge.tax;
    }
  }

  function calculateOtherChargesGroupAmount(charge){
    var amount = 0;
    var precision = 2;

    if (!!$scope.nonCatalog.currency && !$scope.nonCatalog.currency.allow_decimal) {
      precision = 0;
    }

    if (charge.calculation_method.code === 'FLAT_AMOUNT_PER_UNIT'){
      if (!!$scope.nonCatalog.qty){
        amount = math.round((!!charge.original_unit_price ? charge.original_unit_price : charge['unit_price']) * $scope.nonCatalog.qty, precision);
      }
    } else if (charge.calculation_method.code === 'PERCENT_OF_EXTENDED_COST'){
      if (!!$scope.nonCatalog.unit_price && $scope.nonCatalog.qty){
        var itemAmount = $scope.nonCatalog.unit_price * $scope.nonCatalog.qty;
        var percentage = charge['percentage'] / 100;
        amount = math.round(itemAmount * percentage, precision);
      }
    } else {
      amount = charge['unit_price'];
    }
    return amount;
  }

  function onSelectOtherChargesGroup(otherChargesGroup){
    $scope.nonCatalogOtherChargesList = _.cloneDeep($scope.originalNonCatalogOtherChargesList);
    prepareOtherChargesGroup(otherChargesGroup.other_charges);
    $scope.nonCatalog.other_charges_group = otherChargesGroup;
  }

  function updateOtherChargesGroup(){
    _.forEach($scope.otherChargesList, function(charge){
      if (charge.other_charges_group) {
        charge['unit_price'] = charge['amount']; //cos inconsitency in data
        charge['amount'] = calculateOtherChargesGroupAmount(charge);
      }
    });
  }

  function removeOtherChargesGroup(){
    $scope.nonCatalogOtherChargesList = _.cloneDeep($scope.originalNonCatalogOtherChargesList);
    $scope.nonCatalog.other_charges_group = null;
  }

  function disableOtherChargesGroup() {
    var individualOtherChargeExist = false;
    _.forEach($scope.nonCatalogOtherChargesList, function(charge) {
      if (!!charge.isSelected && !charge.other_charges_group) {
        individualOtherChargeExist = true;
      }
    });
    return individualOtherChargeExist;
  }

  // drop-down listing
  function getOtherChargesGroupSearch(query, item){

    var moduleParam = {
      module: 'other-charges-groups'
    };

    var additionalParams = {
      'criteria[0][code]': query,
      'criteria[0][name]': query,
      'criteria[1][company_code]': $scope.PR.company.code,
      'criteria[2][is_active]': true,
      'criteria[3][supplier|code]': !!$scope.nonCatalog.supplier.basic_info ? $scope.nonCatalog.supplier.basic_info.code : $scope.nonCatalog.supplier.code
    };
    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams,
      ignoreLoadingBar: true
    }).then(function (response) {
      if (response.data.content.data.length === 0) {
        return [];
      } else {
          return response.data.content.data.map(function (item) {
            return item;
          });
      }
    });
  }

  function onSelectOtherCharges(charges) {
    if(!!charges)
    charges.isSelected = true;

  }

  function selectedOtherCharges(code){
    var result = globalFunc.findInArray($scope.nonCatalogOtherChargesList, 'code', code);
    if(!!result)
      return result.isSelected;

    return false;
  }

  function removeOtherCharges (code){
    var result = globalFunc.findInArray($scope.nonCatalogOtherChargesList, 'code', code);
    if(!!result){
      result.remark = '';
      result.quantity = 0;
      result.unitPrice = 0;
      result.amount = 0;
      result.discount =0;
      result.tax = 0;
      result.total= 0;
      result.isSelected = false;
    }
  }

  function getTaxData() {
    return prV2Function.getTaxData();
  }


  function onItemSelect(item) {
    $scope.validItemsSelected = true;
    $scope.nonCatalog.item_code = item.item_master_code;
    $scope.nonCatalog.descr = item.descr;
    $scope.nonCatalog.category = item.item_category;
    $scope.nonCatalog.is_stock = item.is_stock;
    $scope.nonCatalog.uom = _.find($scope.UOMsList, function(e){
      return e.code === item.uom_code;
    });
    $scope.nonCatalog.uom_disabled = item.uom_disabled;
  }

  function updateBudget(budget){
    $scope.nonCatalog.budget = budget;
  }

  function updateProjectCode (projectCode) {
    $scope.nonCatalog.project_code = projectCode;
    $scope.nonCatalog.budget = projectCode.budget;
  }

  function updateFoc(){
    $scope.nonCatalog.unit_price = '';
    $scope.nonCatalog.tax = '';
    $scope.nonCatalog.no_pricing_available = '';
  }

  /**
   * search the items from company items, based on code and name
   * @param query
   */
  function searchItems(query) {
    var additionalParams = {
      'criteria[0][company|code]': prV2Function.getPRData().company.code,
      'criteria[1][expense_type_category]': prV2Function.getExpenseTypeMode(),
      'criteria[2][is_pcm]': prV2Function.getExpenseTypePcm(),
      'criteria[3][item_master_code]': query,
      'criteria[3][name]': query,
      'criteria[3][$operator]': 'or',
      'criteria[4][is_active]': true,
      'criteria_operator': 'and',
      offset: 100
    };

    var moduleParam = {
      module: 'company-item'
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams
    }).then(function (response) {
      $scope.loading = false;
      return response.data.content.data.map(function (item) {
        $scope.loading = false;
        return item;
      });
    }, function (error) {
      globalFunc.objectErrorMessage(error);
    });
  }

  function createNonCatalogItem() {
    $scope.submitted = true;
    var params = prepareNonCatalogData();
    purchaseRequisitionsV2Services.createNonCatalogItem(
      {
        requisition_id: prV2Function.getPRData()._id
      },
      params,
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          var item = resource.content.data.pr_items[resource.content.data.pr_items.length-1];
          $scope.nonCatalog.item_id = item._id;

          var itemDetails = {item_id : item._id};
          $rootScope.$broadcast('uploadNonCatalogFile', itemDetails);
          $rootScope.$broadcast('uploadNonCatalogAttachment', {item_id: $scope.nonCatalog.item_id});

          $scope.addedItem.item = item;
          $scope.nonCatalog._id = item._id;
          prV2Function.setPRData(resource.content.data);
          $scope.nonCatalog.name = item.name;
          $scope.nonCatalog.qty = item.qty;

          toastr.success(item.item_code + ' - ' + item.name + ' has been added.');
          if (prV2Function.getExpenseTypeMode() === 'OPEX') {
            $scope.$emit('close');
          }
          else{
            $scope.$emit('goToNextPage');
          }

        } else {
          globalFunc.objectErrorMessage('Failed to create a non catalog item');
        }
      }, function (error) {
        $scope.submitted = false;
        globalFunc.objectErrorMessage(error);
      });

  }

  function updateNonCatalogItem(nonCatalog) {
    var params = prepareNonCatalogData();
    $scope.submitted = true;
    purchaseRequisitionsV2Services.setItem({
        requisition_item_id: $scope.nonCatalog._id,
        action: 'item-details'
      }, params,
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          prV2Function.setPRData(resource.content.data);
          $rootScope.$broadcast('checkIntegrationData');
          $rootScope.$broadcast('uploadNonCatalogAttachment', {item_id: $scope.nonCatalog._id});


          // update the image in case the user added an image
          var item = globalFunc.findInArray(resource.content.data.pr_items, '_id', nonCatalog._id);
          $scope.addedItem.item = item;
          $rootScope.$broadcast('updateDetailsDataV2', item);
          var lineItem = 0;
          _.forEach(resource.content.data.pr_items, function (item) {
            lineItem++;
            if (item._id === nonCatalog._id) {
              return false;
            }
          });
          if (!!$scope.supplierTaxId && $scope.supplierTaxId !== item.tax._id) {
            toastr.warning('Tax code for Line item ' + lineItem + ' will not be updated as supplier tax code has been deactivated');
          }
          if (!!$scope.nonCatalog.image && item && item._id) {
            updateNonCatalogImage(item).then(function (resource) {

              if (!!resource.data && !!resource.data.content && !!resource.data.content.data) {
                prV2Function.setPRData(resource.data.content.data);
                $rootScope.$broadcast('updateDetailsDataV2', globalFunc.findInArray(
                  resource.data.content.data.pr_items, '_id', nonCatalog._id)
                );
              }

              if ($scope.action === 'update') {
                $scope.$emit('close');
              }
              else{
                $scope.$emit('goToNextPage');
              }
            }, function (error) {
              globalFunc.objectErrorMessage(error);
            });
          }
          else{
            if ($scope.action === 'update') {
              $scope.$emit('close');
            }
            else{
              $scope.$emit('goToNextPage');
            }
          }
        }
        else
          globalFunc.objectErrorMessage('An error occurred.');

        $scope.submitted = false;
        close();
      },
      function (error) {
        $scope.submitted = false;
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function prepareNonCatalogItem(item) {

    //find Index
    var index = null;
    var prItems = prV2Function.getPRData().pr_items;
    for (var i in prItems) {
      if (prItems[i]._id === item._id) {
        index = i;
      }
    }

    if (index === null) {
      globalFunc.objectErrorMessage('An error occurred.');
      return;
    }

    var validateLumpSum = prV2Function.validateLumpSum(item, index);
    if (!!prV2Function.getPRData().lump_sum_discount && !validateLumpSum.status) {
      swal({
        title: 'Confirm update the item?',
        text: validateLumpSum.message,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        closeOnConfirm: true
      }, function (isConfirm) {
        if (isConfirm) {
          // remove lump sum
          prV2Function.removeLumpSum(prV2Function.getPRData()._id, function (resource) {
            if (!!resource.content && !!resource.content.data) {
              prV2Function.setPRData(resource.content.data);
              updateNonCatalogItem(item);
            } else {
              globalFunc.objectErrorMessage('An error occurred.');

            }
          })
        }
      });
    }
    else
      updateNonCatalogItem(item);
  }

  /**
   * preparing the complicated other charges
   */
  function getOtherChargesPayload() {
    var obj = {};
    _.forEach($scope.nonCatalogOtherChargesList, function(charge){
      if(charge.isSelected){
        obj[charge.code] = {
          discount_amount: charge.discount,
          amount: charge.amount,
          calculation_method: charge.calculation_method,
          remark: !!charge.remark ? charge.remark :undefined,
          is_other_charges_group_tax: charge.is_other_charges_group_tax || undefined,
          other_charges_group: charge.other_charges_group || undefined,
          currency: charge.currency || $scope.nonCatalog.currency,
          percentage: charge.percentage || undefined,
        };
        if (!!charge.tax) {
          obj[charge.code]['tax_id'] = charge.tax._id;
        }
        $scope.nonCatalog[charge.code] = _.cloneDeep(obj[charge.code]);
        $scope.nonCatalog[charge.code]['tax'] = charge.tax;
      }
    });

    return !_.isEmpty(obj) ? obj : undefined;
  }

  function prepareNonCatalogData() {
    // prepare discount data for payload
    var discountString = String($scope.nonCatalog.discount);
    if (discountString.indexOf('%') > -1)
      var discountPercentage = Number(discountString.replace('%', ''));
    else
      var discountAmount = Number(discountString);

    // prepare other charges data for payload
    var otherCharges = {};
    for (var i = 0; i < $scope.nonCatalogOtherChargesList.length; i++) {
      var singleCharge = $scope.nonCatalogOtherChargesList[i];
      if (singleCharge.isSelected === true) {
        otherCharges[singleCharge.code] =
          {
            remark: singleCharge.remark,
            quantity: singleCharge.quantity,
            unit_price: singleCharge.unitPrice,
            amount: singleCharge.amount,
            discount: singleCharge.discount,
            total: singleCharge.total
          };

          if (!!singleCharge.tax) {
            otherCharges[singleCharge.code]['tax'] =
              {
                _id: singleCharge.tax._id
              };
          }
      }
    }

      return {
      'company_code': $scope.PR.company.code,
      'item_code': $scope.nonCatalog.item_code,
      'descr': $scope.nonCatalog.descr,
      'supplier_code': !!$scope.nonCatalog.supplier && $scope.nonCatalog.supplier.basic_info ? $scope.nonCatalog.supplier.basic_info.code : $scope.nonCatalog.supplier.code,
      'lead_time': $scope.nonCatalog.lead_time,
      'supplier_part_no': $scope.nonCatalog.supplier_part_no,
      'supplier_reference_no': $scope.nonCatalog.supplier_reference_no,
      'qty': !!$scope.nonCatalog.qty ? $scope.nonCatalog.qty : 1,
      'unit_price': !!$scope.nonCatalog.unit_price ? $scope.nonCatalog.unit_price : 0,
      'currency_code': (!!$scope.nonCatalog.currency && !!$scope.nonCatalog.currency.code) ?
        $scope.nonCatalog.currency.code : '',
      'uom_code': (!!$scope.nonCatalog.uom && !!$scope.nonCatalog.uom.code) ? $scope.nonCatalog.uom.code : '',
      'payment_term_code': (!!$scope.nonCatalog.payment_term && !!$scope.nonCatalog.payment_term.code) ?
        $scope.nonCatalog.payment_term.code : '',
      'tax_code': (!!$scope.nonCatalog.tax && !!$scope.nonCatalog.tax.code) ? $scope.nonCatalog.tax.code : '',
      'budget_id': (!!$scope.nonCatalog.budget && !!$scope.nonCatalog.budget._id) ? $scope.nonCatalog.budget._id : '',
      'category_code': (!!$scope.nonCatalog.category && !!$scope.nonCatalog.category.code ? $scope.nonCatalog.category.code : ''),
      'cost_center_code': (!!$scope.nonCatalog.cost_center ?
        $scope.nonCatalog.cost_center.code : {}),
      'supplier_part_number': (!!$scope.nonCatalog.supplier_part_number ?
        $scope.nonCatalog.supplier_part_number : ''),
      'justification': (!!$scope.nonCatalog.justification ?
        $scope.nonCatalog.justification : ''),
      'note_to_supplier': (!!$scope.nonCatalog.note_to_supplier ? $scope.nonCatalog.note_to_supplier : ''),
      'packing_info': (!!$scope.nonCatalog.packing_info ?
        $scope.nonCatalog.packing_info : ''),
      'other_charges': getOtherChargesPayload(),
      'discount_percentage': !!discountPercentage ? discountPercentage : undefined,
      'no_pricing_available': !!$scope.nonCatalog.no_pricing_available,
      'foc_item': !!$scope.nonCatalog.foc_item,
      'discount': !!discountAmount ? discountAmount : undefined,
      'quotation_validity_from': globalFunc.convertDateToTimestamp($scope.nonCatalog.quotation_validity_from),
      'quotation_validity_to': globalFunc.convertDateToTimestamp($scope.nonCatalog.quotation_validity_to),
      'product_availability': $scope.nonCatalog.product_availability,
      'other_charges_group_uuid': !_.isEmpty($scope.nonCatalog.other_charges_group)? $scope.nonCatalog.other_charges_group.uuid : undefined,
      'project_code': !_.isEmpty($scope.nonCatalog.project_code)  ? $scope.nonCatalog.project_code.code : null,
      'pr_expense_type' : $scope.PR.expense_type.category
    };
  }


  /**
   * update the nonCatalog image
   * @param itemData
   */
  function updateNonCatalogImage(itemData) {
    var fd = new FormData();
    fd.append('item_image', $scope.nonCatalog.image);
    return $http.post($filter('format')(pathConstants.apiUrls.purchaseRequisitions.updateNonCatalogImage, {
      id: itemData._id
    }), fd, {
      transformRequest: angular.identity,
      headers: {'Content-Type': undefined}
    });
  }

  function validateNonCatalog(action) {

    var anyError = false;

    if (!$scope.nonCatalog.item_code || !$scope.nonCatalog.descr || !$scope.nonCatalog.category) {
      toastr.error(lang.validation.required.input + 'Item Code & Item Name');
      anyError = true;
    }

    if (!$scope.nonCatalog.descr) {
      toastr.error(lang.validation.required.input + 'item Description');
      anyError = true;
    }

    if(!$scope.nonCatalog.supplier) {
      toastr.error(lang.validation.required.selection + 'a Supplier');
      anyError = true;
    }

    if (!!$scope.nonCatalog.supplierStatus) {
      if (!$scope.nonCatalog.supplierStatus || $scope.nonCatalog.supplierStatus.toLowerCase() !== 'active') {
        toastr.error('The selected Supplier is not Valid, please select other supplier');
        anyError = true;
      }
    }

    if (!$scope.nonCatalog.qty) {
      toastr.error(lang.validation.number.isNumber + ' for quantity');
      anyError = true;
    }

    if (!!$scope.nonCatalog.no_pricing_available === false) {
      // validate image type
      if (!!$scope.nonCatalog.image) {
        if (/^image\/*/.test($scope.nonCatalog.image.type) === false && $scope.nonCatalog.image !== 'emptyImage') {
          toastr.error('Item image' + lang.validation.upload.onlyImage);
          anyError = true;
        }
      }

      if (!!$scope.is_budget_mandatory && !$scope.nonCatalog.budget && $scope.projectCodeMandatory) {
        toastr.error(lang.validation.required.input + 'budget');
        anyError = true;
      }
      if (Number($scope.nonCatalog.qty) === 0) {
        toastr.error(lang.validation.required.input + 'more than 0');
        anyError = true;
      } else if (!$scope.nonCatalog.qty) {
        toastr.error(lang.validation.required.input + 'a Quantity');
        anyError = true;
      }
      if (!$scope.nonCatalog.uom) {
        toastr.error(lang.validation.required.input + 'a Unit of Measurement');
        anyError = true;
      }

      if (!!$scope.nonCatalog.uom && !$scope.nonCatalog.uom.is_fraction && $scope.nonCatalog.qty % 1 !== 0) {
        toastr.error('Unit of Measurement ' + lang.validation.number.noFraction);
        $scope.decimalError = true;
        anyError = true;
      }

      if (!$scope.nonCatalog.currency) {
        toastr.error(lang.validation.required.selection + 'a Currency');
        anyError = true;
      }

      if ((!$scope.nonCatalog.unit_price || !!$scope.nonCatalog.unit_price && $scope.nonCatalog.unit_price < 0) && !$scope.nonCatalog.foc_item) {
        toastr.error(lang.validation.required.input + 'a Unit Price');
        anyError = true;
      }

      if (!$scope.nonCatalog.tax && !$scope.nonCatalog.foc_item){
        toastr.error(lang.validation.required.input + 'tax');
        anyError = true;
      }

      if (!$scope.nonCatalog.lead_time) {
        toastr.error(lang.validation.required.input + 'lead Time');
        anyError = true;
      }

      if (!$scope.nonCatalog.payment_term) {
        toastr.error(lang.validation.required.selection + 'a Payment Term');
        anyError = true;
      }

      if (!$scope.nonCatalog.quotation_validity_from || !$scope.nonCatalog.quotation_validity_to) {
        toastr.error(lang.validation.required.selection + 'a Quotation date');
        anyError = true;
      }

      if (!!$scope.nonCatalog.quotation_validity_from && $scope.nonCatalog.quotation_validity_to){
        if ($scope.nonCatalog.quotation_validity_from.getTime() > $scope.nonCatalog.quotation_validity_to.getTime()){
          toastr.error('Quotation end date cannot be before start date');
          anyError = true;
        }
      }

      if (!$scope.nonCatalog.product_availability) {
        toastr.error(lang.validation.required.selection + 'Product Availability');
        anyError = true;
      }

      if (!$scope.nonCatalog.justification) {
        toastr.error(lang.validation.required.input + 'justification');
        anyError = true;
      }

      if (action === 'create' && prV2Function.isAccountCodeRequired()) {
        if (!$scope.nonCatalog.account_code_id && !$scope.nonCatalog.account_code_name) {
          toastr.error(lang.validation.required.selection + 'an Account Code');
          anyError = true;
        }

        if (!$scope.nonCatalog.account_code_id) {
          toastr.error('Account code is invalid.');
          anyError = true;
        }
      }

      if (isBudgetMandatory() && !$scope.nonCatalog.budget) {
        toastr.error(lang.validation.required.input + 'budget');
      }

      _.forEach($scope.nonCatalogOtherChargesList, function(charge){
        if (charge.isSelected) {
          if (_.isEmpty(charge.tax) && !$scope.nonCatalog.no_pricing_available) {
            toastr.error(lang.validation.required.selection + charge.code + "'s tax");
            anyError = true;
          }
        }
      });
    } else {
      if (isBudgetMandatory() && !$scope.nonCatalog.budget) {
        toastr.error(lang.validation.required.input + 'budget');
        anyError = true;
      }
    }

    if (anyError === true) {
      toastr.error('Non catalog form incomplete.');
      return false;
    }
    return true;
  }

  function submitNonCatalogItem(action, item) {
    $scope.formSubmitted = true;
    $rootScope.$broadcast("noPricingAvailableCheckedPrItem", item.no_pricing_available);

    if (!!validateNonCatalog(action)){
      if (action === 'create')
        createNonCatalogItem();
      else if (action === 'update')
        updateNonCatalogItem(item);
      else
        prepareNonCatalogItem(item);
    }
  }

  /**
   * 1- validate the status of the supplier
   * 2- auto select default Tax if not selected
   * 3- auto select default Payment Terms if not selected
   * @param supplier
   */
  function onSupplierSelect(supplier) {
    var text;
    $scope.invalidSupplierSelected = false;
    if (!supplier || (!!supplier.selected_contract.status && supplier.selected_contract.status.toLowerCase() !== 'active')) {
      if (supplier.selected_contract.status.toLowerCase() === 'active_pending') {
        text = 'The selected Supplier is pending for approval on updates. Please select another Supplier.';
      } else if (supplier.selected_contract.status.toLowerCase() === 'blacklisted') {
        text = 'The selected Supplier has already been blacklisted. Please select another Supplier.';
      } else if (supplier.selected_contract.status.toLowerCase() === 'inactive') {
        text = 'The selected Supplier has already been deactivated. Please select another Supplier.';
      } else {
        text = 'The selected Supplier is not valid, please select another supplier.';
      }

      globalFunc.objectErrorMessage(text);
      $scope.nonCatalog.supplier_display_name = '';
      $scope.supplierSearchText = '';
      $scope.nonCatalog.supplier = null;
      $scope.invalidSupplierSelected = true;
      return false;
    }

    $scope.supplierTax = globalFunc.findInArray($scope.taxesList, 'code', supplier.selected_contract.tax_code);
    $scope.nonCatalog.supplier = supplier;
    $scope.nonCatalog.supplier.descr = supplier.basic_info.descr;
    $scope.nonCatalog.supplier.reg_no = supplier.basic_info.reg_no;
    $scope.validSupplierIsSelected = true;
    $scope.nonCatalog.supplierStatus = supplier.selected_contract.status;

    // auto assign the default Tax if its not already selected
    if (!$scope.nonCatalog.tax && $scope.supplierTax) {
      $scope.nonCatalog.tax = $scope.supplierTax;
      $scope.supplierTaxId = $scope.supplierTax._id;
    }

    // select the Payment Term of the supplier as the supplier selection returns code only
    if (!!supplier.selected_contract.payment_term_code && !!$scope.paymentTermsList) {
      $scope.nonCatalog.payment_term = globalFunc.findInArray($scope.paymentTermsList, 'code', supplier.selected_contract.payment_term_code);
    }
  }

  /**
   * Set supplier to null when field's input is incomplete or empty
   *
   * @param field
   */
  function validateFieldSelection(field) {

    if(field && field.length > 0 && !!$scope.nonCatalog.supplier && $scope.nonCatalog.supplier.basic_info ? $scope.nonCatalog.supplier.basic_info.code : $scope.nonCatalog.supplier.code) {
      return;
    }

    if(field && field.length > 0 && !$scope.validSupplierIsSelected) {
      $scope.supplierSearchText = '';
    }

    if($scope.validSupplierIsSelected || !$scope.nonCatalog.supplier)
      return;

    if (!field && !!$scope.nonCatalog.supplier) {
      $scope.nonCatalog.supplier = null;
      $scope.supplierSearchText = '';
    }

    if(field !== $scope.nonCatalog.supplier.descr) {
      $scope.nonCatalog.supplier = null;
      $scope.supplierSearchText = '';
    }
  }

  /**
   * Set item to null when field's input is incomplete or empty
   *
   * @param field
   */
  function validateItemFieldSelection(field) {

    if (!!field && field.length > 0 && !$scope.validItemsSelected) {
      $scope.itemSearchText = '';
    }

    if ($scope.validItemsSelected || !$scope.nonCatalog.descr)
      return;

    if (!field && !!$scope.nonCatalog.descr) {
      $scope.nonCatalog.descr = null;
      $scope.nonCatalog.item_id = null;
      $scope.itemSearchText = '';
    }

    if (field !== ($scope.nonCatalog.item_id + ' - ' + $scope.nonCatalog.descr)) {
      $scope.nonCatalog.descr = null;
      $scope.nonCatalog.item_id = null;
      $scope.itemSearchText = '';
    }
  }

  /**
   * Function to modify supplier object and pass label class as html
   */
  function modifySupplierObject(supplier) {

    switch (supplier.basic_info.status.toLowerCase()) {
      case 'blacklisted':
        supplier.labelStatus = 'black';
        break;
      case 'inactive':
        supplier.labelStatus = 'warning';
        break;
    }

    return supplier;
  }


  function searchSuppliers(val) {
    if (!!val && val.length > 2) {
      $scope.supplierSearching = true;

      var moduleParam = {
        module: 'suppliers'
      };

      var additionalParams = {
        'criteria[0][basic_info|descr]': val,
        'criteria[0][basic_info|reg_no]': val,
        'criteria[0][$operator]': 'or',
        'criteria[1][basic_info|status][0]': 'active',
        'criteria[1][basic_info|status][1]': 'blacklisted',
        'criteria[1][basic_info|status][2]': 'inactive',
        'criteria[1][basic_info|status][3]': 'active_pending',
        'criteria[2][$operator]': 'and',
        'criteria[2][$strict]': true,
        'criteria[2][$is_pr_item_filter]': true,
        'criteria[2][company_contracts|company|company_code][0]': $scope.PR.company.code,
        'criteria[2][company_contracts|company|company_code][1]': $scope.PR.shadow_company.code,
        'criteria[3][$strict]': true,
        'criteria[3][$is_pr_item_filter]': true,
        'criteria[3][company_contracts|status]': 'ACTIVE',
        criteria_operator: 'and',
        company_contract: $scope.PR.company.code,
        offset: 100
      };

      if (!!$rootScope.isSourcingSupplierEnabled) {
        additionalParams['criteria[2][basic_info|is_p2o_supplier]'] = true;
      }

      return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: additionalParams,
        ignoreLoadingBar: true
      }).then(function (response) {
        if (response.data.content.data.length === 0) {
          $scope.supplierSearching = false;
          return [];
        } else {
            $scope.supplierSearching = false;
            var modifiedSupplier = _.filter(response.data.content.data, function (item) {
              if (!!item.selected_contract.status && item.selected_contract.status.toLowerCase() === 'active') {
                return item;
              }
            });

            return modifiedSupplier;
        }
      });
    }
  }


  function searchUOM(val) {

    if (!!val && val.length > 1) {
      $scope.uomSearching = true;

      return $http.get(pathConstants.apiUrls.uom.list, {
        params: {
          query: val
        },
        ignoreLoadingBar: true
      }).then(function (response) {
        $scope.uomSearching = false;
        return response.data.content.data.map(function (item) {
          return item;
        });
      }).catch(function (error) {
        $scope.uomSearching = false;
        globalFunc.objectErrorMessage(error);
      })
    }
  }

  function clearForm(){
    $rootScope.$broadcast('clearNonCatalogItem');
    $scope.nonCatalog.no_pricing_available = false;
    $scope.nonCatalog.foc_item = false;
    $scope.nonCatalogOtherChargesList  = _.cloneDeep(defaultOtherChargesList);
    $scope.selectedOtherChargesGroup = undefined;
  }

  /**
   * this implementation for make the submission intiated from the Item Form Ctrl
   */
  $scope.$on('submitNonCatalog', function(args, data){
    submitNonCatalogItem('create', $scope.nonCatalog)
  });

  $scope.$on('updateNonCatalog', function(args, data, next){
    submitNonCatalogItem('update', $scope.nonCatalog)
  });

  // refresh budget drop-down listing
  function loadBudgets(query) {
    return $http.get($filter('format')(pathConstants.apiUrls.budget.list), {
      params: {
        query: query,
        type: 'budget_listing_for_pr_draft',
        company_code: $scope.PR.company.code,
        shadow_company_code: $scope.PR.shadow_company.code,
        expense_type_category: $scope.PR.expense_type.category,
        cost_center: !!$scope.PR.cost_center ? $scope.PR.cost_center.code : undefined
      }
    }).then(function (response) {
      if (response.data.content.data.length === 0) {
        return [];
      } else {
        return response.data.content.data.map(function (item) {
          return item;
        });
      }
    });
  }

  function prepareNonCatalogLineItem(){
    var obj = _.cloneDeep($scope.nonCatalog);

    obj.uom = $scope.nonCatalog.uom;
    obj.description = $scope.nonCatalog.descr;
    obj.item_field = $scope.nonCatalog.name;

    return obj
  }

  function hasCostCenter(){
    return !_.isEmpty($scope.PR.cost_center)
  }

  function populateOtherCharges(){
    _.forEach($scope.nonCatalogOtherChargesList, function(charge){
      if (!_.isEmpty($scope.nonCatalog[charge.code])) {
        var currentCharge = $scope.nonCatalog[charge.code];
        charge.amount = currentCharge.amount;
        charge.discount = currentCharge.discount_amount;
        charge.tax = currentCharge.tax;
        charge.remark = currentCharge.remark;
        charge.total = currentCharge.total_amount;
        charge.other_charges_group = !!currentCharge.other_charges_group || false;
        $scope.isOtherChargesGroup = !!currentCharge.other_charges_group || false;
        charge.is_other_charges_group_tax = !!currentCharge.is_other_charges_group_tax || false;
        charge.isSelected = true;

        if (_.isEmpty(currentCharge.calculation_method)) {
          charge.calculation_method = [];
        } else {
          charge.calculation_method = currentCharge.calculation_method;
        }
        
      }
    });

    if (!!$scope.isOtherChargesGroup && !_.isEmpty($scope.nonCatalog.other_charges_group)) {
      prepareOtherChargesGroup($scope.nonCatalog.other_charges_group.other_charges);
    }
  }

  function checkProjectCodeSetting() {
    if ($scope.PR.expense_type.category !== 'CAPEX') {
      $scope.projectCodeMandatory = false;
      $scope.showProjectCodeAsterick = false;
    } else {
      var projectCodeSetting = _.isEmpty(prV2Function.getPRData().company.requisition_settings.project_code_capex_mandatory) ?
        null : prV2Function.getPRData().company.requisition_settings.project_code_capex_mandatory.is_enabled;

      if (projectCodeSetting) {
        $scope.projectCodeMandatory = true;
        $scope.showProjectCodeAsterick = true;
      } else {
        $scope.projectCodeMandatory = false;
        $scope.showProjectCodeAsterick = false;
      }
    }
  }

  function showBudgetCode() {
    // hide budget field for non-capex PR
    if ($scope.PR.expense_type.category === 'CAPEX' && $scope.PR.expense_type.budget_setting === 'REQUIRED') {
      return $scope.allow_add_budget && !$scope.projectCodeMandatory;
    }

    return false;
  }

  // drop-down listing
  function getProjectCodeSearch(query){
    var moduleParam = {
      module: 'project'
    };

    var additionalParams = {
      'criteria[0][code]': query,
      'criteria[0][name]': query,
      'criteria[0][$operator]': 'or',
      'criteria[1][billing_company|code]': $scope.PR.shadow_company.code,
      'criteria[1][company|code]': $scope.PR.company.code,
      'criteria[1][$operator]':'and',
      'criteria[2][is_active]': true,
      criteria_operator: 'and',
      offset: 100
    };

    if (!!$scope.PR.document_rule.allow_add_budget){
      additionalParams['criteria[3][is_budgeted]'] = true;
    } else {
      additionalParams['criteria[3][is_budgeted]'] = false;
      additionalParams['criteria[4][billing_company|code]'] = $scope.PR.shadow_company.code;
    }

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam),{
      params: additionalParams,
      ignoreLoadingBar: true
    }).then(function (response) {
      if (response.data.content.data.length === 0) {
        return [];
      } else {
        if (($scope.PR.expense_type.budget_setting === 'REQUIRED' || !_.isEmpty($scope.nonCatalog.budget)) && !$scope.projectCodeMandatory) {
          var projectCodes = _.filter(response.data.content.data, function (item) {
            var index = _.findIndex($scope.nonCatalog.budget.project_codes, function(e){
              return item.code === e.code;
            });
            if (index > -1){
              return item;
            }
            else{
              return false;
            }
          });
          return projectCodes;
        }
        else {
          return response.data.content.data;
        }
      }
    });
  }

  function initialize() {
    loadBudgets();
    $scope.showNoPricing = prV2Function.getDocumentRule('allow_no_pricing_available');
    if ($scope.action === 'update'){
      $scope.nonCatalog = prepareNonCatalogLineItem();
    }

    if (!!$scope.nonCatalog.item_code) {
      $scope.itemSearchText = $scope.nonCatalog.item_code + " - " + $scope.nonCatalog.name;
    }

    if (!_.isEmpty($scope.nonCatalog.supplier)) {
      $scope.supplierSearchText = $scope.nonCatalog.supplier.descr + ' ( ' + $scope.nonCatalog.supplier.reg_no + ' )';
    }

    if (!!$scope.nonCatalog.quotation_validity_from){
      $scope.nonCatalog.quotation_validity_from = new Date(Number($scope.nonCatalog.quotation_validity_from));
    }
    if (!!$scope.nonCatalog.quotation_validity_to){
      $scope.nonCatalog.quotation_validity_to = new Date(Number($scope.nonCatalog.quotation_validity_to));
    }

    $scope.nonCatalog.cost_center = !_.isEmpty($scope.PR.cost_center) ? $scope.PR.cost_center: {};
    if(!!$scope.PR.no_pricing_available) {
      $scope.nonCatalog.no_pricing_available =  true;
      $scope.allowNoPricingSelection = false;
    }

    $scope.isPriceComparisonEnabled = prV2Function.getDocumentRule('is_price_comparison_process')

    //disable 'no pricing available' checkbox
    if($scope.isPriceComparisonEnabled)
    {
      $scope.allowNoPricingSelection = false;
    }

    $scope.isPA = prV2Function.checkCurrentApproverIsPA(
      !!prV2Function.getPRData() ? prV2Function.getPRData().waiting_on : null,
      !!$rootScope.currentUser ? $rootScope.currentUser._id : null,
      !!$rootScope.currentUser ? $rootScope.currentUser.role_assignments : null
    );

    populateOtherCharges();
    checkProjectCodeSetting();
  }

  function removeImage() {

    $scope.nonCatalog.image = null;
    var fileElem = angular.element('#nonCatalogImage');
    angular.element(fileElem).val(null);
    $scope.imageDescription = null;

  }

  initialize();

}

catalogNonCatalogItemV2Ctrl.$inject =  [
  '$scope', 'pathConstants', '$http', 'globalFunc', '$filter', 'searchModule', 'purchaseRequisitionsV2Services',
  '$rootScope', 'toastr', 'prV2Function', 'lang', 'loadCacheableData', 'currenciesMasterDataList', 'Companies'
];

angular
  .module('metabuyer')
  .controller('catalogNonCatalogItemV2Ctrl', catalogNonCatalogItemV2Ctrl);
