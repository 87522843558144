'use strict';
/**
 * @name exchangeRate
 * @desc directive for the Exchange Rate within a Company
 */
angular
  .module('metabuyer')
  .directive('exchangeRate',function() {
    return {
      restrict: 'E',
      templateUrl: 'components/exchangeRate/exchangeRateTemplate.html',
      scope: {
        data: '=',
        currencies: '=',
        id: '='
      },
      controller: function($scope, $stateParams, $uibModal, toastr,
                           exchangeRateMasterDataList, exchangeRateSearch, ExchangeRate,
                           globalFunc, Currencies, $q, $filter, pathConstants, $rootScope) {

        function onSelectedFromCurrency(selected_data){
          $scope.exchangeRate.source_currency_id = selected_data._id;
        }

        function onSelectedToCurrency(selected_data){
          $scope.exchangeRate.target_currency_id = selected_data._id;
        }

        function openDatepicker($event, which) {
          /**
           * Setting minimum selection for start date to be today
           */
          $scope.startDateOptions.minDate = new Date();

          /**
           * Setting the minimum selection for end date to be one day ahead of selected start date
           */
          var startDateClone = _.cloneDeep($scope.exchangeRate.effective_at);
          var startDate = new Date(startDateClone);
          startDate.setDate(startDate.getDate() + 1);
          $scope.endDateOptions.minDate = startDate;

          $event.preventDefault();
          $event.stopPropagation();

          $scope.datepickerOpened[which] = true;
        }

        $scope.getExchangeRate = function (source_id, target_id) {
          var deferred = $q.defer();

          ExchangeRate.getExchangeRate({
            source_id: source_id,
            target_id: target_id
          }, function(resource) {
            // TODO: check !resource && !!resource.content && !!resource.content.data! && !!resource.content.data.status
            if(!!resource && !!resource.content && !!resource.content.data){
              source_id.rate = resource.content.data;
              deferred.resolve(source_id);
            }
          }, function() {
            deferred.resolve(source_id);
          });

          return deferred.promise;
        };


        $scope.columns= [
          {id: 'code', label: 'Code'},
          {id: 'descr', label: 'Description'},
          {id: 'multiplier_rate', label: 'Rate'},
          {id: 'updated_at', label: 'Updated At'},
          {id: 'updater.display_name', label: 'Updated By'},
          {id: 'created_at', label: 'Created At'},
          {id: 'creator.display_name', label: 'Created By'},
          {id: 'effective_at', label: 'Effective At'},
          {id: 'expiry_at', label: 'Expire At'},
          {id: 'is_active', label: 'Status'}
        ];

        $scope.storageKey = 'companies-exchange-rate-management-selected-columns';

        function showColumn(id){
          for (var i in $scope.columns) {
            if (id === $scope.columns[i].id) {
              if(!!$scope.columns[i].conditional){
                if($scope.status === $scope.columns[i].condition){
                  return $scope.columns[i].selected;
                }
                if($scope.columns[i].condition instanceof Array){
                  for(var j in $scope.columns[i].condition){
                    if($scope.status === $scope.columns[i].condition[j]){
                      return $scope.columns[i].selected;
                    }
                  }
                }
              }else{
                return $scope.columns[i].selected;
              }
            }
          }
        }


        /**
         * returns the is_active name
         * @param code
         * @returns {*}
         */
        function getActiveStatusName(code){
          /**
           DEACTIVATED = 0; 
           ACTIVE = 1; 
           DELETED = 2; 
           DISABLED = 3; 
           */
          if (code === 0 || code === 'DEACTIVATED') {
            return 'Deactivated'
          }
          if (code === 1 || code === 'ACTIVE') {
            return 'Active'
          }
          if (code === 2 || code === 'DELETED') {
            return 'Deleted'
          }
          if (code === 3 || code === 'DISABLED') {
            return 'Disabled'
          }
          return 'N/A';
        }

        function importExchangeRate() {
          var modalInstance = $uibModal.open({
            templateUrl: 'components/exchangeRate/Import-Template.html',
            backdrop: 'static',
            keyboard: false,
            resolve: {
              importLink: function () {
                return $scope.importLink;
              },
              title: function () {
                return 'Exchange Rate';
              },
              id: function() {
                return $scope.id;
              }
            },
            controller: function ($scope, $uibModalInstance, importLink, title, id) {
              $scope.cancel = function () {
                $uibModalInstance.close();
              };

              $scope.importLink = importLink;
              $scope.title = title;
              $scope.id = id;
            }
          });
        }

        function search() {
          // Do not support pagination yet
          var query = $scope.searchText;
          exchangeRateSearch.get(
            {
              id: $scope.id,
              query: query
            },
            function (resource) {
              if (!!resource.content) {
                $scope.dataList = resource.content.data;
                $scope.meta = resource.content.meta;
                $scope.exchangeRateService = exchangeRateMasterDataList;
                $scope.query = query;
              }
            },
            function (error) {
              // not found
            }
          );
        }

        function clearForm(){
          $scope.submitted = false;
          $scope.exchangeRate.descr = '';
          $scope.exchangeRate.status = '';
          $scope.exchangeRate.multiplier_rate = '';
          $scope.exchangeRate.effective_at = '';
          $scope.exchangeRate.expiry_at = '';
          $scope.exchangeRate.fromCurrency = undefined;
          $scope.exchangeRate.toCurrency = undefined;
          $scope.exchangeRate.source_currency_id = undefined;
          $scope.exchangeRate.target_currency_id = undefined;
        }

          function validate(exchangeRate){
            if(!exchangeRate.fromCurrency) {
              return 'Source currency is required.';
            }
            if(!exchangeRate.toCurrency) {
              return 'Target currency is required.';
            }
            if (exchangeRate.fromCurrency === exchangeRate.toCurrency) {
              return 'Source currency cannot be equal to target currency.';
            }
            if(!exchangeRate.multiplier_rate || Number(exchangeRate.multiplier_rate) === 0){
              return 'Multiplier rate is required.';
            }
            if(!exchangeRate.descr){
              return 'Description is required.';
            }
            if(!exchangeRate.effective_at){
              return 'Effective date is required.';
            }
            if(!exchangeRate.expiry_at){
              return 'Expiry date is required.';
            }
            if(exchangeRate.effective_at >= exchangeRate.expiry_at){
              return 'Expiry date must be later than effective date.';
            }

            return true;
          }

        $scope.validateExchangeRate = validateExchangeRate;
        function validateExchangeRate(multiplier_rate) {
            return Number(multiplier_rate) === 0;
        }

        function newExchangeRate() {
          $scope.submitted = true;

          var isValid = validate($scope.exchangeRate);

          if(isValid === true) {
            if(!!$scope.exchangeRate){
              $scope.exchangeRate.status = 2;
            }
            //Formatting the dates before posting
            $scope.exchangeRate.effective_at = globalFunc.convertDateToTimestamp($scope.exchangeRate.effective_at);
            $scope.exchangeRate.expiry_at = globalFunc.convertDateToTimestamp($scope.exchangeRate.expiry_at);

            var submit_data = {
              descr: $scope.exchangeRate.descr,
              status: $scope.exchangeRate.status,
              multiplier_rate: $scope.exchangeRate.multiplier_rate,
              effective_at: $scope.exchangeRate.effective_at,
              expiry_at: $scope.exchangeRate.expiry_at,
              source_currency_id: $scope.exchangeRate.source_currency_id,
              target_currency_id: $scope.exchangeRate.target_currency_id
            };

            exchangeRateMasterDataList.post(
              {
                id: $scope.id
              },
              submit_data,
              function (resource) {
                toastr.success('Exchange rate was added successfully');
                if (!!resource.content) {
                  if (!!resource.content.data) {
                    var addedExchangeRate = resource.content.data;
                    $scope.dataList.unshift({
                      _id: addedExchangeRate._id,
                      code: addedExchangeRate.code,
                      descr: addedExchangeRate.descr,
                      multiplier_rate: addedExchangeRate.rate,
                      effective_at: addedExchangeRate.effective_at,
                      expiry_at: addedExchangeRate.expiry_at,
                      created_at: addedExchangeRate.created_at,
                      updated_at: addedExchangeRate.updated_at,
                      updater: [$rootScope.currentUser],
                      creator: [$rootScope.currentUser],
                      is_active: 1
                    });
                  }
                }

                clearForm();
                $scope.submitted = false;
              },
              function (error) {
                clearForm();
                toastr.error('Failed to add exchange rate');
                globalFunc.objectErrorMessage(error);
              }
            )
          }
          else{
            toastr.error(isValid);
          }
        }

        function exchangeRateDetail(exchangeRate) {
          var exchangeRateId = $scope.dataList[exchangeRate]._id;
          var modalInstance = $uibModal.open({
            templateUrl: 'components/exchangeRate/details/details.html',
            backdrop: 'static',
            keyboard: false,
            controller: 'exchangeRateDetailsCtrl',
            size: 'lg',
            resolve: {
              currencyExchange: function(){
                return $scope.currencyExchange;
              },
              exchangeRateDetails: function($q, exchangeRateSpecificRate) {
                var deferred = $q.defer();
                exchangeRateSpecificRate.get(
                  {
                    rate_id: exchangeRateId,
                    id: $scope.id
                  },
                  function (resource) {
                    deferred.resolve(resource.content || {data: []});
                  },
                  function (error) {
                    if (error.status === 404) {
                      deferred.resolve([]);
                    }
                  }
                );
                return deferred.promise;
              }
            }
          });
          modalInstance.result.then(function (newValue) {
            if(!!newValue){
              _.forEach($scope.dataList, function(dataList){
                if(dataList._id === newValue._id){
                  dataList.code = newValue.code;
                  dataList.descr = newValue.descr;
                  dataList.multiplier_rate = newValue.rate;
                  dataList.effective_at = newValue.effective_at;
                  dataList.expiry_at = newValue.expiry_at;
                  dataList.is_active = newValue.is_active;
                  dataList.updater[0].display_name = newValue.updated_by;
                  dataList.updated_at = newValue.updated_at;
                }
              });
            }
          });
        }

        function reset (){
          $scope.query = false;
          exchangeRateMasterDataList.get(
            {
              id: $scope.id
            },
            function (resource) {
              if(!!resource.content){
                if(!!resource.content.data) {
                  $scope.dataList = resource.content.data;
                }
              }
            },
            function (error) {
              toastr.error(error);
            });
        }

        function initialize() {
          $scope.dataList = $scope.data.data;
          $scope.meta = $scope.data.meta;
          $scope.accountCodesListService = exchangeRateMasterDataList;
          $scope.newExchangeRate = newExchangeRate;
          $scope.exchangeRateDetail = exchangeRateDetail;
          $scope.search = search;
          $scope.reset = reset;
          $scope.showColumn = showColumn;
          $scope.getActiveStatusName = getActiveStatusName;
          $scope.importExchangeRate = importExchangeRate;
          $scope.importLink = pathConstants.apiUrls.exchangeRate.import;
          $scope.exchangeRate = {};
          $scope.datePickerTimezone = $rootScope.datePickerTimezone;

          // Datepicker
          $scope.openDatepicker = openDatepicker;
          $scope.startDateOptions = {
            formatYear: 'yy',

            startingDay: 1
          };

          $scope.endDateOptions = {
            formatYear: 'yy',
            startingDay: 1
          };

          $scope.datepickerOpened = {
            start_date: false,
            end_date: false
          };
          $scope.currencyExchange = $scope.currencies.data;

          $scope.onSelectedFromCurrency = onSelectedFromCurrency;
          $scope.onSelectedToCurrency = onSelectedToCurrency;
        }

        initialize();
      }
    }
  });
