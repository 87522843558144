'use strict';

function historyBasedAuditCtrl($scope, $uibModalInstance, commentsHistory) {
    $scope.cancel = cancel;
    $scope.commentsHistory = commentsHistory;
    $scope.prepareLogFields = prepareLogFields;
    $scope.onPaginateFunction = onPaginateFunction;
    $scope.embeddedParams = {
        referenceId: $scope.referenceId,
        offset: 5,
        order_by: '-created_at' // Created at, descending
    };

    function cancel() {
        $uibModalInstance.close();
    }

    function onPaginateFunction(items) {

        _.forEach(items, function (item) {
            item.updatedFields = prepareLogFields(item.related);
        });
        return items;
    }

    function prepareLogFields(related) {
        var fields = [];
        var item = {};
        Object.keys(related).forEach(function (k) {
            var v = related[k];

            item = {
                key: k,
                from: v.old,
                to: v.new,
                special: v.special
            };

            fields.push(item);
        });
        return fields;
    }

    function init() {
        commentsHistory.get(
            {
                referenceId: $scope.referenceId,
                offset: 5,
                order_by: '-created_at' // Created at, descending
            },
            function (resource) {
                if (!!resource.content) {
                    $scope.histories = onPaginateFunction(resource.content.data);
                    $scope.meta = resource.content.meta;
                }
            },
            function (error) {
                globalFunc.objectErrorMessage(error);
            }
        );
    }

    init();
}

function historyBasedAudit($uibModal) {
    return {
        scope: {
            referenceId: '@',
            customTemplateUrl: '='
        },
        link: function (scope, element) {
            var templateUrl = scope.customTemplateUrl === '' || scope.customTemplateUrl === undefined ?
                            'components/historyBasedAudit/historyBasedAuditTemplate.html':
                            scope.customTemplateUrl;
            var panel = function () {
                var modalInstance = $uibModal.open({
                    templateUrl: templateUrl,
                    controller: 'historyBasedAuditCtrl',
                    backdrop: 'static',
                    keyboard: false,
                    scope: scope,
                    size: 'lg'
                });
            };

            element.bind('click' , function(){
                panel();

            });
        }
    }
}


historyBasedAuditCtrl.$inject = ['$scope', '$uibModalInstance', 'commentsHistory'];

angular.module('metabuyer')
    .directive('historyBasedAudit', historyBasedAudit)
    .controller('historyBasedAuditCtrl', historyBasedAuditCtrl);