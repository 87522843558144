'use strict';

function suppliersWebDashboardController($scope, $rootScope, $state, $filter,
                                         profile, UserPermissions, poInProgress, poSubmitted, poReversed) {
  $scope.$emit('pageTitleChanged', 'Dashboard');
  $scope.changeStringCase = changeStringCase;

  function changeStringCase(string){
    var newString = string.replace('_', ' ');
    newString = $filter('titleCase')(newString);
    if (newString === 'Submitted') {
      newString = 'Pending For Response';
    }
    return newString;
  }

  function initialize() {
    $scope.poInProgress = poInProgress;
    $scope.poSubmitted = poSubmitted;
    $scope.poReversed = poReversed;
  }

  initialize();
}

suppliersWebDashboardController.$inject = ['$scope', '$rootScope', '$state', '$filter',
  'profile', 'UserPermissions', 'poInProgress', 'poSubmitted', 'poReversed'];

angular.module('metabuyer')
  .controller('suppliersWebDashboardController', suppliersWebDashboardController);
