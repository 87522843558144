'use strict';

angular.module('metabuyer.services.plustusServices', ['ngResource'])
.factory('plutusLogin', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.plutus.login,
      {},
      {
        'get': {method: 'GET'}
      }
    );
})
;
