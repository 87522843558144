'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function ($stateProvider) {

    $stateProvider
      .state('main.user.suppliersList', {
        url: '/suppliersList',
        controller: 'suppliersListCtrl',
        templateUrl: 'app/suppliersList/suppliersList.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'Supplier')) {
              $state.go('notAuthorized');
            }
          },
          sourcingSupplierFeatureToggle: function ($q, $stateParams, FeatureToggleService, toastr) {
            var deferred = $q.defer();

            FeatureToggleService.getOne(['system.enableSourcingSupplier'])
              .then(function (resource) {
                if (!!resource.data) {
                  deferred.resolve(resource.data);
                } else {
                  deferred.resolve({});
                  console.log("Failed to get toggle data.");
                }
              })
              .catch(function (e) {
                deferred.resolve({});
                toastr.error('Something went wrong')
              });

            return deferred.promise;
          },
          prepareTranslation: function ($translatePartialLoader) {
            $translatePartialLoader.addPart('suppliersList/lang');
            $translatePartialLoader.addPart('suppliersManagement/lang');
          },
        }
      })
      .state('main.user.suppliersList.manage', {
        templateUrl: 'app/suppliersList/manage/manage.html',
        url: '/manage?{status:[a-zA-Z0-9]+}&{query:[a-zA-Z0-9]*}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&' +
        '{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'suppliersListManageCtrl',
        resolve: {
          action: function ($stateParams) {
            if ($stateParams.query) {
              return 'search';
            } else {
              return 'show';
            }
          },
          suppliersDataList: function ($q, $state, $stateParams, searchModule, exportSearch) {
            var deferred = $q.defer();
            var status = $stateParams.status;

            var params = {
              module: 'suppliers',
              offset: 20,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };


            if (!!$stateParams.query) {

              if (!!$stateParams.filter) {

                params['criteria_operator'] = 'and';
                params['criteria[0][' + $stateParams.filter + ']'] = $stateParams.query;

              } else {
                params['criteria_operator'] = !!$stateParams.query ? 'and' : null;
                params['criteria[0][basic_info|descr]'] = $stateParams.query;
                params['criteria[0][basic_info|code]'] = $stateParams.query;
                params['criteria[0][basic_info|reg_no]'] = $stateParams.query;
                params['criteria[0][basic_info|email]'] = $stateParams.query;
                params['criteria[0][basic_info|primary_commodity|descr]'] = $stateParams.query;
                params['criteria[0][basic_info|secondary_commodity|descr]'] = $stateParams.query;
                params['criteria[0][default_contract|currency_code]'] = $stateParams.query;
                params['criteria[0][$operator]'] = !!$stateParams.query ? 'or' : null;
                params['criteria[0][creator_info|display_name]'] = $stateParams.query;
                params['criteria[0][updater_info|display_name]'] = $stateParams.query;
              }

              // status criteria
              if (!!status && !!status.length) {
                if (status !== '*') {
                  if (status.toUpperCase() === 'PENDING' || status.toUpperCase() === 'ACTIVE') {
                    params['criteria[1][basic_info|status][0]'] = status.toUpperCase();
                    params['criteria[1][basic_info|status][1]'] = 'ACTIVE_PENDING';
                  } else {
                    params['criteria[1][basic_info|status]'] = status.toUpperCase();
                  }

                  params['criteria[1][$strict]'] = true;
                }
              }
              else if ($stateParams.status === 'all') {
                params['criteria[1][basic_info|status]'] = null;
                params['criteria[1][$operator]'] = 'or';
              }
            }

            /**
             * For listing
             */
            else {
              if (!!status && !!status.length) {
                if (status !== '*') {

                  if (status.toUpperCase() === 'PENDING' || status.toUpperCase() === 'ACTIVE') {
                    params['criteria[0][basic_info|status][0]'] = status.toUpperCase();
                    params['criteria[0][basic_info|status][1]'] = 'ACTIVE_PENDING';
                  } else {
                    params['criteria[0][basic_info|status]'] = status.toUpperCase();
                  }

                  params['criteria[0][$strict]'] = true;
                }
              }
              else if ($stateParams.status === 'all') {
                params['criteria[0][basic_info|status]'] = null;
                params['criteria[1][$operator]'] = 'or';
              }
            }

            exportSearch.setParams(params);
            searchModule.get(
              params,
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data) {
                  deferred.resolve(resource.content);
                } else {
                  deferred.resolve({data: []});
                }
              },
              function (error) {
              }
            );

            return deferred.promise;
          },
          $title: function ($stateParams) {
            return 'Supplier - listing' + (!!$stateParams.status ? ' - ' + $stateParams.status : '');
          },
          prepareTranslation: function ($translatePartialLoader) {
            $translatePartialLoader.addPart('suppliersManagement/manage/lang');
          },
        }
      })
      /**
       * State to list supplier groups a.k.a. business units
       */
      .state('main.user.suppliersList.supplierGroups', {
        templateUrl: 'app/suppliersList/supplierGroups/manage/manage.html',
        url: '/supplierGroups?{query:[a-zA-Z0-9]*}&{page:[0-9]}&' +
        '{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'supplierListGroupsCtrl',
        resolve: {
          dataList: function ($q, searchModule, $stateParams) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'business-units',
                offset: 20,
                page: $stateParams.page,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              },
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return 'Supplier - Supplier Groups';
          }
        }
      })

      /**
       * State to list suppliers by a specific supplier group / global supplier
       */
      .state('main.user.suppliersList.supplierGroupDetail', {
        templateUrl: 'app/suppliersList/supplierGroups/detail/detail.html',
        url: '/supplierGroups/detail?{query:[a-zA-Z0-9]*}&{page:[0-9]}&' +
        '{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}&' +
        '{bu_code:[a-zA-Z0-9]+}&{bu_descr:[a-zA-Z0-9]+}&{status:[a-zA-Z0-9]+}',
        controller: 'supplierListGroupDetailCtrl',
        resolve: {
          suppliersByGroup: function ($q, searchModule, $stateParams) {
            var deferred = $q.defer();

            var params = {
              module: 'suppliers',
              'criteria[1][approval_status]': 1,
              'criteria[1][archive_of]': '',
              'criteria[1][draft_status]': 3,
              offset: 20,
              page: $stateParams.page,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'descr',
              order: !!$stateParams.order ? $stateParams.order : 1
            };

            /**
             * First determine if search or listing
             */
            if (!!$stateParams.query) {
              /**
               * If searching , check if searching with filter
               */
              if (!!$stateParams.filter) {

                params['criteria_operator'] = 'and';

                var temp = '';
                if ($stateParams.type === 'date') {
                  temp = 'criteria[2][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                } else {
                  params['criteria[2][' + $stateParams.filter + ']'] = $stateParams.query;
                }
              }
              /**
               * If searching without filter
               */
              else {
                params['criteria_operator'] = !!$stateParams.query ? 'and' : null;
                params['criteria[2][descr]'] = $stateParams.query;
                params['criteria[2][mb_supplier_code]'] = $stateParams.query;
                params['criteria[2][reg_no]'] = $stateParams.query;
                params['criteria[2][email]'] = $stateParams.query;
                params['criteria[2][main_contact_person|first_name]'] = $stateParams.query;
                params['criteria[2][main_contact_person|email]'] = $stateParams.query;
                params['criteria[2][primary_business_nature]'] = $stateParams.query;
                params['criteria[2][primary_commodity|descr]'] = $stateParams.query;
                params['criteria[2][secondary_commodity|descr]'] = $stateParams.query;
                params['criteria[2][currency_code]'] = $stateParams.query;
                params['criteria[2][creator.display_name]'] = $stateParams.query;
                params['criteria[2][updater.display_name]'] = $stateParams.query;
                params['criteria[2][$operator]'] = !!$stateParams.query ? 'or' : null;

              }
            }

            var bu_param = 'criteria[0][bu_status|' + $stateParams.bu_code + '|status]';

            /**
             * Check if selected business unit or global
             * Switch case to handle the bu status params
             */
            if ($stateParams.bu_code.toLowerCase() !== 'global') {
              /**
               * Params for business unit status
               */
              switch ($stateParams.status) {
                case 'all':
                  params['criteria[0][bu_status|' + $stateParams.bu_code + '|status][0]'] = 1;
                  params['criteria[0][bu_status|' + $stateParams.bu_code + '|status][1]'] = 2;
                  params['criteria[0][bu_status|' + $stateParams.bu_code + '|status][2]'] = 6;
                  params['criteria[0][$operator]'] = 'or';
                  break;
                case 'active':
                  params[bu_param] = 1;
                  break;
                case 'black_listed':
                  params[bu_param] = 2;
                  break;
                case 'inactive':
                  params[bu_param] = 6;
                  break;
              }
            }
            else {
              /**
               * Params for global suppliers
               */
              params['criteria[0][is_global_supplier]'] = 'true';
              switch ($stateParams.status) {
                case 'all':
                  break;
                case 'active':
                  params['criteria[0][status]'] = 1;
                  params['criteria[0][$operator]'] = 'and';
                  break;
                case 'black_listed':
                  params['criteria[0][status]'] = 2;
                  params['criteria[0][$operator]'] = 'and';
                  break;
                case 'inactive':
                  params['criteria[0][status]'] = 6;
                  params['criteria[0][$operator]'] = 'and';
                  break;
              }
            }

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return 'Supplier - Supplier Business Unit - Supplier Listing';
          }
        }
      })
      .state('main.user.suppliersList.detail', {
        templateUrl: 'app/suppliersList/detail/detail.html',
        url: '/detail?{id:[a-zA-Z0-9]}&{query:[a-zA-Z0-9]*}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&' +
        '{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'suppliersListDetailsCtrl',
        resolve: {
          supplierDetails : function (supplier, $q, $stateParams) {
            var deferred = $q.defer();
            supplier.get(
              {id: $stateParams.id},
              function(resource){
                deferred.resolve(resource.content);
              }, function(error){

              });
            return deferred.promise;
          },
          currencies: function ($stateParams, $q, Currencies) {
            var deferred = $q.defer();

            if (!!JSON.parse(window.localStorage.getItem('currencies'))) {
              deferred.resolve(JSON.parse(window.localStorage.getItem('currencies')));
            } else {
              Currencies.get(
                {},
                function (resource) {
                  if (!!resource && !!resource.content && !!resource.content.data) {
                    window.localStorage.setItem('currencies', JSON.stringify(resource.content.data));
                    deferred.resolve(resource.content.data);
                  } else {
                    deferred.resolve([]);
                  }
                },
                function (error) {
                  deferred.resolve([]);
                });
            }

            return deferred.promise;
          },
          paymentTerms: function (searchModule, $q) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'payment-term',
                'criteria[is_active]': 1,
                offset: 0
              },
              function (resource) {
                if (!!resource && !!resource.content) {
                  deferred.resolve(resource.content);
                } else {
                  deferred.resolve([]);
                }
              },
              function (error) {
                deferred.resolve([]);
              });
            return deferred.promise;
          },
          paymentMethods: function (searchModule, $q) {
            var deferred = $q.defer();
            //load the paymentMethods
            if (!JSON.parse(window.localStorage.getItem('paymentMethods'))) {
              searchModule.get(
                {
                  module: 'payment-method',
                  offset: 0
                },
                function (resource) {
                  if (!!resource && !!resource.content) {
                    deferred.resolve(resource.content);
                    window.localStorage.setItem('paymentMethods', JSON.stringify(resource.content));
                  } else {
                    deferred.resolve([]);
                  }
                },
                function (error) {
                  deferred.resolve([]);
                });
              return deferred.promise;
            }
            else {
              return JSON.parse(window.localStorage.getItem('paymentMethods'));
            }
          },
          taxes: function (searchModule, $q) {
            var deferred = $q.defer();
            if (!JSON.parse(window.localStorage.getItem('taxes'))) {
              searchModule.get(
                {
                  module: 'tax',
                  offset: 0,
                  'criteria[is_active]': 1
                },
                function (resource) {
                  if (!!resource.content && !!resource.content.data) {
                    deferred.resolve(resource.content.data);
                    window.localStorage.setItem('taxes', JSON.stringify(resource.content.data));
                  }
                  else {
                    deferred.resolve([]);
                  }
                },
                function () {
                  deferred.resolve([]);
                }
              );
            }
            else {
              deferred.resolve(JSON.parse(window.localStorage.getItem('taxes')));
            }
            return deferred.promise;
          },
          companies: function($q, searchModule, globalFunc) {
            var deferred = $q.defer();

            searchModule.get(
              {
                module: 'companies',
                offset: 0
              },
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data) {
                  deferred.resolve(resource.content);
                }
                else {
                  deferred.resolve([]);
                }
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              });
            return deferred.promise;
          },
          sourcingSupplierFeatureToggle: function ($q, $stateParams, FeatureToggleService, toastr) {
            var deferred = $q.defer();

            FeatureToggleService.getOne(['system.enableSourcingSupplier'])
              .then(function (resource) {
                if (!!resource.data) {
                  deferred.resolve(resource.data);
                } else {
                  deferred.resolve({});
                  console.log("Failed to get toggle data.");
                }
              })
              .catch(function (e) {
                deferred.resolve({});
                toastr.error('Something went wrong')
              });

            return deferred.promise;
          },
          $title: function ($stateParams) {
            return 'Supplier - Details' + (!!$stateParams.status ? ' - ' + $stateParams.status : '');
          },
          prepareTranslation: function ($translatePartialLoader) {
            $translatePartialLoader.addPart('suppliersManagement/newSupplier/lang');
          },
        }
      })
      .state('main.user.suppliersList.supplierTagging', {
        templateUrl: 'app/suppliersList/supplierTagging/supplierTagging-template.html',
        url: '/SupplierTagging?{companyCode:[a-zA-Z0-9]+}',
        controller: 'supplierTaggingCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'SupplierTag', 'Create') ||
              !UserPermissions.checkPermissionsAccess(profile, 'SupplierTag', 'Update')) {
              $state.go('notAuthorized');
            }
          },
          companiesList: function ($q, searchModule, authenticated) {
            var deferred = $q.defer();

            if (!authenticated) {
              deferred.resolve(undefined);
              return deferred.promise;
            }

            searchModule.get(
              {
                module: 'companies',
                offset: 0
              },
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          // Resolve the suppliers list for ui-select multiple
          suppliersList: function($q, $stateParams, searchModule) {
            var deferred = $q.defer();

            searchModule.get(
              {
                module: 'suppliers',
                offset: 0,
                'criteria[0][basic_info|status]': 'active'
              },
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Supplier - Tag Suppliers';
          }
        }
      })
      // TODO: rethink of a better state name to describe these lists (Justin 25/5/17)
      .state('main.user.suppliersList.tagsBySupplier', {
        templateUrl: 'app/suppliersList/supplierTagsBySupplier/manage/manage.html',
        url: '/tagsBySupplier?{query:[a-zA-Z0-9]*}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&' +
        '{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'supplierTagsBySupplierCtrl',
        resolve: {
          detailState: function () {
            return 'main.user.suppliersList.supplierTagsBySupplierDetail';
          },
          suppliersList: function ($q, searchModule, authenticated, $stateParams) {
            var deferred = $q.defer();

            var params = {
              module: 'suppliers',
              'criteria[0][basic_info|status]': 'active',
              criteria_operator: 'and',
              offset: 20,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            if (!!$stateParams.query) {

              if (!!$stateParams.filter) {
                var temp = '';
                if ($stateParams.type === 'date') {
                  temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                }
                else {
                  params['criteria[1][' + $stateParams.filter + ']'] = $stateParams.query;
                }
              }
              else {
                params['criteria[1][basic_info|descr]'] = $stateParams.query;
                params['criteria[1][basic_info|code]'] = $stateParams.query;
                params['criteria[1][basic_info|reg_no]'] = $stateParams.query;
                params['criteria[1][basic_info|email]'] = $stateParams.query;
                params['criteria[1][default_contract|contacts|name]'] = $stateParams.query;
                params['criteria[1][default_contract|contacts|email]'] = $stateParams.query;
                params['criteria[1][default_contract|primary_business_nature]'] = $stateParams.query;
                params['criteria[1][default_contract|primary_commodity_descr]'] = $stateParams.query;
                params['criteria[1][default_contract|secondary_commodity_descr]'] = $stateParams.query;
                params['criteria[1][default_contract|currency_code]'] = $stateParams.query;
                params['criteria[1][creator_info|display_name]'] = $stateParams.query;
                params['criteria[1][updater_info|display_name]'] = $stateParams.query;
                params['criteria[1][$operator]'] = !!$stateParams.query ? 'or' : null;
              }
            }

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Supplier - Supplier List';
          }
        }
      })
      .state('main.user.suppliersList.supplierTagsBySupplierDetail', {
        templateUrl: 'app/suppliersList/supplierTagsBySupplier/detail/detail.html',
        url: '/supplierTagsBySupplierDetail?{supplierCode:[a-zA-Z0-9]+}&{supplierName:[a-zA-Z0-9]+}&{query:[a-zA-Z0-9]*}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&' +
        '{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'supplierTagsBySupplierDetailCtrl',
        resolve: {
          supplierName: function ($stateParams) {
            return $stateParams.supplierName;
          },
          companiesTaggedToSupplier: function ($q, searchModule, getTagsBySupplierCode, $stateParams) {
            var deferred = $q.defer();

            /**
             * Setting the params to prepare for exporting
             */
            var exportParams = {
              module: 'supplier-tags',
              'criteria[supplier_code]': $stateParams.supplierCode
            };

            var params = {
              module: 'companies',
              offset: 20,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order,
              criteria_operator: 'and',
              'criteria[0][supplier_tags.supplier_code]': $stateParams.supplierCode
            };

            if (!!$stateParams.query) {
              if (!!$stateParams.filter) {
                var temp = 'criteria[1][' + $stateParams.filter + ']';
                if ($stateParams.type === 'date') {
                  temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                }
                else {
                  params[temp] = $stateParams.query;
                }
              }
              else {
                params['criteria[0][descr]'] = !!$stateParams.query ? $stateParams.query : null;
                params['criteria[0][reg_no]'] = !!$stateParams.query ? $stateParams.query : null;
                params['criteria[0][code]'] = !!$stateParams.query ? $stateParams.query : null;
                params['criteria[0][creator_info|display_name]'] = !!$stateParams.query ? $stateParams.query : null;
                params['criteria[0][updater_info|display_name]'] = !!$stateParams.query ? $stateParams.query : null;
                params['criteria[1][$operator]'] = !!$stateParams.query ? 'or' : null;
              }
            }

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Supplier - Companies Tagged to Supplier';
          }
        }
      })
      .state('main.user.suppliersList.tagsByCompany', {
        templateUrl: 'app/suppliersList/supplierTagsByCompany/manage/manage.html',
        url: '/tagsByCompany?{query:[a-zA-Z0-9]*}&{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}&' +
        '{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'supplierTagsByCompanyCtrl',
        resolve: {
          detailState: function () {
            return 'main.user.suppliersList.supplierTagsByCompanyDetail';
          },
          companiesList: function ($q, searchModule, profile, $stateParams, globalFunc) {
            var deferred = $q.defer();
            var userCompanyCodes = [];

            _.forEach(profile.companies, function (company) {
              userCompanyCodes.push(company.code);
            });

            var params = {
              module: 'companies',
              offset: 20,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            if (!!$stateParams.query) {
              if (!!$stateParams.filter) {
                var temp = 'criteria[0][' + $stateParams.filter + ']';
                if ($stateParams.type === 'date') {
                  temp = 'criteria[0][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                }
                else {
                  params[temp] = $stateParams.query;
                }
              }
              else {
                params['criteria[0][descr]'] = !!$stateParams.query ? $stateParams.query : null;
                params['criteria[0][reg_no]'] = !!$stateParams.query ? $stateParams.query : null;
                params['criteria[0][currency_code]'] = !!$stateParams.query ? $stateParams.query : null;
                params['criteria[0][$operator]'] = !!$stateParams.query ? 'or' : null;
              }

              if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN')) {
                params['criteria[1][code][]'] = userCompanyCodes;
                params['criteria_operator'] = 'and';
              }
            }
            else {
              if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN')) {
                params['criteria[0][code][]'] = userCompanyCodes;
                params['criteria_operator'] = 'and';
              }
            }

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Supplier - Company List';
          }
        }
      })
      .state('main.user.suppliersList.supplierTagsByCompanyDetail', {
        templateUrl: 'app/suppliersList/supplierTagsByCompany/detail/detail.html',
        url: '/supplierTagsByCompanyDetail?{companyId:[a-zA-Z0-9]+}&{companyCode:[a-zA-Z0-9]+}&' +
        '{companyName:[a-zA-Z0-9]+}&{query:[a-zA-Z0-9]*}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&' +
        '{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'supplierTagsByCompanyDetailCtrl',
        resolve: {
          canEditSupplierTag: function (profile, UserPermissions) {
            return (!!UserPermissions.checkPermissionsAccess(profile, 'SupplierTag', 'Create') &&
            !!UserPermissions.checkPermissionsAccess(profile, 'SupplierTag', 'Update'));
          },
          detailState: function () {
            return 'main.user.suppliersList.supplierTagging';
          },
          companyName: function ($stateParams) {
            return $stateParams.companyName;
          },
          suppliersTaggedToCompany: function ($q, searchModule, getTagsByCompanyCode, $stateParams) {
            var deferred = $q.defer();

            /**
             * Setting the params to prepare for exporting
             */
            var exportParams = {
              module: 'supplier-tags',
              'criteria[company_code]': $stateParams.companyCode
            };

            var params = {
              module: 'suppliers',
              offset: 20,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order,
              'criteria[0][basic_info|status]': 'active',
              criteria_operator: 'and',
              'criteria[0][supplier_tags.company_code]': $stateParams.companyCode
            };

            if (!!$stateParams.query) {
              if (!!$stateParams.filter) {
                var temp = '';
                if ($stateParams.type === 'date') {
                  temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                }
                else {
                  params['criteria[1][' + $stateParams.filter + ']'] = $stateParams.query;
                }
              }
              else {
                params['criteria[1][basic_info|descr]'] = $stateParams.query;
                params['criteria[1][basic_info|reg_no]'] = $stateParams.query;
                params['criteria[1][basic_info|email]'] = $stateParams.query;
                params['criteria[1][default_contract|contacts|name]'] = $stateParams.query;
                params['criteria[1][default_contract|contacts|email]'] = $stateParams.query;
                params['criteria[1][default_contract|primary_business_nature]'] = $stateParams.query;
                params['criteria[1][default_contract|primary_commodity_descr]'] = $stateParams.query;
                params['criteria[1][default_contract|secondary_commodity_descr]'] = $stateParams.query;
                params['criteria[1][default_contract|currency_code]'] = $stateParams.query;
                params['criteria[1][creator_info|display_name]'] = $stateParams.query;
                params['criteria[1][updater_info|display_name]'] = $stateParams.query;
                params['criteria[1][$operator]'] = !!$stateParams.query ? 'or' : null;
              }
            }

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Supplier - Suppliers Tagged to Company';
          }
        }
      })
      .state('main.user.suppliersList.manageSupplierUser', {
        url: '/supplierUser/manage?{status:[a-zA-Z0-9]+}&{query:[a-zA-Z0-9]*}&{page:[0-9]}&{extraFilterKey:[a-zA-Z0-9]+}&' +
        '{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}&{tab:[a-zA-Z0-9]+}&' +
        '{extraFilterValue:[a-zA-Z0-9]+}',
        templateUrl: 'app/suppliersList/supplierUser/manage.html',
        controller: 'supplierUserManageViewCtrl',
        resolve: {
          action: function ($stateParams) {
            if ($stateParams.query) {
              return 'search';
            } else {
              return 'show';
            }
          },
          dataList: function ($q, $state, $stateParams, searchModule, userFunctions, exportSearch) {
            var deferred = $q.defer();
            var status = $stateParams.status;

            var params = {
              module: 'supplier-user',
              offset: 20,
              page: $stateParams.page,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'updated_at',
              order: !!$stateParams.order ? $stateParams.order : 0
            };

            if (!!$stateParams.query) {
              if (!!$stateParams.filter) {
                params['criteria_operator'] = 'and';
                params['criteria[0][' + $stateParams.filter + ']'] = $stateParams.query;
              } else {
                params['criteria_operator'] = !!$stateParams.query ? 'and' : null;
                params['criteria[0][basic_info|descr]'] = $stateParams.query;
                params['criteria[0][supplier_users|display_name]'] = $stateParams.query;
                params['criteria[0][supplier_users|email]'] = $stateParams.query;
                params['criteria[0][$operator]'] = 'or';
              }

              if ($stateParams.status === 'all') {
                params['criteria[1][supplier_users|status]'] = 'all';
              } else {
                params['criteria[1][supplier_users|status]'] = userFunctions.convertUserStatus(status, false);
              }

            /**
             * For listing
             */
            } else {
              if ($stateParams.status === 'all') {
                params['criteria[0][supplier_users|status]'] = 'all';
              } else {
                params['criteria[0][supplier_users|status]'] = userFunctions.convertUserStatus(status, false);
              }
            }

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data) {
                  deferred.resolve(resource.content);
                } else {
                  deferred.resolve({data: []});
                }
              },
              function (error) {
                deferred.resolve({data: []});
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return 'Supplier User Listing - ' + (!!$stateParams.status ? ' - ' + $stateParams.status : '');
          }
        }
      })
      .state('main.user.suppliersList.supplierUserDetail', {
        templateUrl: 'app/suppliersList/supplierUser/detail/detail.html',
        url: '/supplierUser/{id:[a-zA-Z0-9]+}/{supplierId:[a-zA-Z0-9]+}',
        controller: 'supplierUserDetailViewCtrl',
        resolve: {
          user: function (supplierUserSearchService, $q, $stateParams) {
            var deferred = $q.defer();
            supplierUserSearchService.get(
              {
                supplierId: $stateParams.supplierId,
                userId: $stateParams.id
              },
              function (resource) {
                if (!!resource.content && !!resource.content.data && !_.isEmpty(resource.content.data)) {
                  deferred.resolve(resource.content.data[0]);
                } else {
                  deferred.resolve([]);
                }
              },
              function () {
                deferred.resolve([]);
              });
            return deferred.promise;
          },
          currencies: function ($stateParams, $q, searchModule) {
            var deferred = $q.defer();

            if (!!JSON.parse(window.localStorage.getItem('currencies'))) {
              deferred.resolve(JSON.parse(window.localStorage.getItem('currencies')));
            } else {
              searchModule.get(
                {
                  module: 'currencies',
                  offset: 0
                },
                function (resource) {
                  if (!!resource && !!resource.content && !!resource.content.data) {
                    window.localStorage.setItem('currencies', JSON.stringify(resource.content.data));
                    deferred.resolve(resource.content.data);
                  } else {
                    deferred.resolve([]);
                  }
                },
                function (error) {
                  deferred.resolve([]);
                });
            }

            return deferred.promise;
          },
          timezones: function ($q, userTimezones) {
            var deferred = $q.defer();
            userTimezones.get(
              {},
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content.data);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Supplier User Detail';
          }
        }
      });
  }]);
