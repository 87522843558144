'use strict';

function listingSearchBar() {
    return {
      restrict: 'E',
      templateUrl: 'components/poConsolidation/listingSearchbar/listingSearchBar.html',
      controller: 'listingSearchBarCtrl',
      scope:{
        items: '='
      }
    }
  }

function listingSearchBarCtrl($scope, $rootScope, pathConstants, $http, $filter, searchModule, metabuyerCache, toastr,
                              globalFunc, Companies, getAllCompanies) {
    $scope.searchSuppliers = {};
    $scope.searchCompanies = {};
    $scope.searchBillingCompanies = {};
    $scope.companies = [];
    $scope.billingCompanies = [];
    $scope.onSupplierSelect = onSupplierSelect;
    $scope.searchSuppliers = searchSuppliers;
    $scope.onCompanySelect = onCompanySelect;
    $scope.searchCompanies = searchCompanies;
    $scope.getBillingCompanies = getBillingCompanies;
    $scope.searchItems = searchItems;
    $scope.onBillingCompanySelect = onBillingCompanySelect;
    $scope.supplierSearching = false;
    $scope.companySearching = false;
    $scope.formSubmitted = false;

    function getCompanies(){
      getAllCompanies.get({
        'fields[0]': 'code',
        'fields[1]': 'shadow_companies'
      }, function (resource) {
        if (!!resource.content && !!resource.content.data) {
          _.forEach($scope.companies, function(e){
            _.forEach(resource.content.data, function(res){
              if (e.code === res.code){
                e.shadow_companies = res.shadow_companies;
              }
            })
          })
        }
      }, function (error) {
      })
    }

    function onCompanySelect(company){
        $scope.searchCompanies.selected = company;
        $scope.billingCompanies = company.shadow_companies;
    }

    function onBillingCompanySelect(company){
        $scope.searchBillingCompanies.selected = company;
    }

    function getBillingCompanies(val) {
      if (!!val && val.length > 2) {
        $scope.companySearching = true;
        var billingCompanies = [];
        _.forEach($scope.billingCompanies, function (e) {
          if (e.descr.toLowerCase().indexOf(val.toLowerCase()) !== -1 ||
            e.code.toLowerCase().indexOf(val.toLowerCase()) !== -1) {
            billingCompanies.push(e)
          }
        });
        return billingCompanies
      }
    }
    function searchCompanies(val){
        if (!!val && val.length > 2) {
          var moduleParam = {
            module: 'companies'
          };

          var additionalParams = {
            params: {
              'criteria[0][descr]': val,
              'criteria[0][code]': val,
              criteria_operator: 'or',
              'offset': 10
            }
          };

          return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), additionalParams)
            .then(function (response) {
              if (response.data.content.data.length === 0) {
                return [];
              }

              return response.data.content.data.map(function (company) {
                return company;
              });
            });
        }
    }

    function onSupplierSelect(supplier){
        $scope.searchSuppliers.selected = supplier
    }

    function searchItems(){
        var params = {
            module: 'purchaseOrderConsolidationItem',
            offset: 50,
            order_by: 'pr_approved_at',
            order: 0,
            'criteria[0][company|code]' : $scope.searchCompanies.selected.code,
            'criteria[1][shadow_company|code]' : $scope.searchBillingCompanies.selected.code,
            'criteria[2][items|supplier|code]' : $scope.searchSuppliers.selected.basic_info.code
        };

        searchModule.get(
            params,
            function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data){
                    metabuyerCache.put('POConsolidationItemParams', params);
                    $scope.$emit("poItemsFetched", resource.content, true)
                }
            },
            function (err) {
                globalFunc.objectErrorMessage(error);
            }
        );
    }

    function searchSuppliers(val) {
        if (!!val && val.length > 2 && !!$scope.searchCompanies.selected) {
          $scope.supplierSearching = true;

          var moduleParam = {
            module: 'suppliers'
          };

          var additionalParams = {
            'criteria[0][basic_info|descr]': val,
            'criteria[0][basic_info|status][0]': 'active',
            criteria_operator: 'and',
            company_contract: $scope.searchCompanies.selected.code,
            offset: 5
          };

          return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
            params: additionalParams,
            ignoreLoadingBar: true
          }).then(function (response) {
            if (response.data.content.data.length === 0) {
              $scope.supplierSearching = false;
              return [];
            } else {
              return response.data.content.data.map(function (item) {
                $scope.supplierSearching = false;
                return item
              });
            }
          });
        }
        else {
          toastr.error('Company cannot be empty')
        }
    }

    function initialize(){
        // list all the companies that this user can create a PO from
        _.forEach($rootScope.currentUser.role_assignments, function(e){
          var hasAccess = _.find(e.roles, function(role){
            return role.name === "PO Requester"
          });
          if (!!hasAccess){
            var companyObject = _.find($rootScope.currentUser.companies, function(company){
              return company._id === e.organization_id
            });
            $scope.companies.push(companyObject)
          }
        });
        getCompanies();
    }
    initialize()
}
listingSearchBarCtrl.$inject = [ '$scope', '$rootScope', 'pathConstants', '$http', '$filter', 'searchModule', 'metabuyerCache', 'toastr', 'globalFunc', 'Companies', 'getAllCompanies'];

angular
    .module('metabuyer')
    .directive('listingSearchBar', listingSearchBar)
    .controller('listingSearchBarCtrl', listingSearchBarCtrl);
