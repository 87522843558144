/**
 * @name escalationModificationPanel
 *
 * @description
 *
 * Controller for the Modal window to List the searched results
 *
 * @requires $scope
 * @requires user (resolved user from the previous state)
 * @requires company (resolved user from the previous state)
 * @requires searchUser (Metabuyer service)
 * @requires toastr
 * @requires $uibModalInstance
 * @requires addEscalation (Metabuyer service)
 * @requires removeEscalation (Metabuyer service)
 *
 *
 * @function selectingUser
 * Uses 'swal' to show a warning message, after the confirmation of Adding the user it confirmation
 * will have the Modal to dismiss as well the selected User will be added to the user's company
 * immediately to show in the HTML
 *
 * @function removeUser
 * Uses 'swal' to show a warning message, after the confirmation of Removing the user it will have the
 * Modal to dismiss as well the selected User will be removed from the user's company immediately
 * to remove it in the HTML
 *
 * @function validate
 * returns true or false, after validating, the User, Company and Selected User
 *
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Verve Technologies Sdn. Bhd. &copy; 2015
 */


'use strict';

function escalationModificationPanel(
  $scope, userInfo, company, searchUser, toastr, $uibModalInstance, addEscalation, removeEscalation, globalFunc
) {
  $scope.userInfo = userInfo;
  $scope.company = company;
  $scope.selectingUser = selectingUser;
  $scope.removeUser = removeUser;
  $scope.close = close;
  $scope.usersSearched = [];

  $scope.search = function (text) {
    if(text.length > 2){
      searchUser.get(
        {
          query: text
        },
        function (resource) {
          $scope.searchResults = 0;
          if(!!resource.content && !!resource.content.data){
            $scope.usersSearched = resource.content.data;
          }
        },
        function (error) {
          if(error.status === 404)
          {
            $scope.searchResult = 0;
            $scope.suppliersResults = [];
          }
          else {
            toastr.error("Failed to load a list of superiors");
          }
        }
      );
    }
  };

  function validate(userSelected){
    if(!userInfo) {
      toastr.error('Failed to access the main user');
      return false;
    }
    if(!userSelected.companies){
      toastr.error('Failed to load the desired company');
      return false;
    }
    if(!userSelected || !userSelected._id || userSelected._id === userInfo.user_id){
      toastr.error('Failed to access the escalated to user');
      return false;
    }
    return true;
  }

  function selectingUser(userSelected){

    if(userInfo.company_descr === 'App\\Metabuyer\\Supplier\\Models\\Supplier'){
      userInfo.company_descr = 'Supplier';
    }

    if(validate(userSelected)) {
      swal({
          title: 'Confirm Selecting an Escalation user in ' + userInfo.company_descr || userInfo.class,
          text: userSelected.display_name + ' will be selected !',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: "#1ab394",
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          closeOnConfirm: true,
          closeOnCancel: true
        },
        function (isConfirm) {
          if (isConfirm) {
            userInfo.superior_id = userSelected._id;
            addEscalation.post(
              {
                id: userInfo.user_id,
                mid: userSelected._id
              },
              {
                //place the company group on the alc field to clean up the backend first,
                company_code: userInfo.class !== 'App\\Metabuyer\\AlcGroup\\Models\\AlcGroup' ? userInfo.company_code : undefined,
                class: userInfo.class,
                alc_group_code: userInfo.class === 'App\\Metabuyer\\AlcGroup\\Models\\AlcGroup' ? userInfo.company_code : undefined
              },
              function (resource) {
                toastr.success('User was selected successfully');
                // Return selected user
                userSelected = [
                  userSelected.display_name,
                  userSelected.email,
                  userSelected._id
                ];
                $uibModalInstance.close(userSelected);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
              }
            );
          } else {
            $uibModalInstance.close();
          }
      });
    }
  }


  function close() {
    $uibModalInstance.close();
  }

  function removeUser(userSelected) {
    if(userInfo.company_descr === 'App\\Metabuyer\\Supplier\\Models\\Supplier'){
      userInfo.company_descr = 'Supplier';
    }
    if(validate(userSelected)) {
      swal({
          title: 'Confirm Removing an Escalation user in ' + userInfo.company_descr || userInfo.class,
          text: userSelected.display_name + ' will be removed !',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: "#1ab394",
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          closeOnConfirm: true,
          closeOnCancel: true
        },
        function (isConfirm) {
          if(isConfirm) {
            userInfo.superior_id = ' ';
            removeEscalation.delete(
              {
                id: userInfo.user_id,
                class: userInfo.class,
                company_code: userInfo.class !== 'App\\Metabuyer\\AlcGroup\\Models\\AlcGroup' ? userInfo.company_code : undefined,
                alc_group_code: userInfo.class === 'App\\Metabuyer\\AlcGroup\\Models\\AlcGroup' ? userInfo.company_code : undefined
              },
              function (success) {
                toastr.success('Escalation option has been removed successfully');
                // Return selected user
                userSelected = [
                  userSelected.display_name = null,
                  userSelected.email = null,
                  userSelected._id = null
                ];
                $uibModalInstance.close(userSelected);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
              }
            );
            $uibModalInstance.close(userSelected);
          } else {
            $uibModalInstance.close();
          }
        });
    }
  }

  function initialize() {

    if (!$scope.company) {
      var className = $scope.userInfo.class.replace('Metabuyer\\Models\\', '');

      if (className === 'ImportItemSummary') {
        className = 'Catalog';
      }

      $scope.userInfo.company_code = '';
      $scope.userInfo.company_descr = className;
    }
  }

  initialize();
}

escalationModificationPanel.$inject = [
  '$scope', 'userInfo', 'company', 'searchUser', 'toastr', '$uibModalInstance', 'addEscalation', 'removeEscalation', 'globalFunc'
];

angular
  .module('metabuyer')
  .controller('escalationModificationPanel', escalationModificationPanel);
