'use strict';

/**
 * @name prV2Function
 *
 * @requires $state
 *
 * @description Service for PR functions
 * This service is for functions that are re-used in PR modules, to minimize redundancy
 *
 * @function validateLumpSum
 * This function will return a message in case there is an error in the lump sum,
 * then the controllers will remove that from their respectively
 *
 * @authors Ahmed Saleh
 * @copyright Sunway Metacloud &copy; 2016
 */

function prV2Function(purchaseRequisitionsV2Services, $q, prStarring, lang, globalFunc, $rootScope, $state) {

  var globalPRData = {};
  var loading = {};
  var contactPerson = [];
  var mentionUsers = [];
  var approvalFlow = {};
  var taxes = [];
  var UOMs = [];
  var submitErrors = [];
  var costCenterDeliveryAddresses = [];
  var accountCodes = [];
  var currencyList = [];
  var PREditableState = 'editable';
  var isShoppingMode = false;
  var itemErrorsFound = false;
  var isIntegratedPR = false;
  var integrationSolution = null;
  var isIntegratedCompanyMapped = false;
  var integrationDependencies = {};
  var erpOrderTypesList = [];
  var erpCostCentersList = [];
  var expenseMode = '';
  var requisitonCCs = [];
  var selectedOtherCharges = [];
  var prWarnings = {};

  return {
    initializePRData: function (pr) {
      this.clearPRData();
      this.setPRData(pr);
    },
    clearPRData: function() {
      globalPRData = {};
      loading = {};
      contactPerson = [];
      mentionUsers = [];
      approvalFlow = {};
      taxes = [];
      submitErrors = [];
      costCenterDeliveryAddresses = [];
      accountCodes = [];
      currencyList = [];
      PREditableState = 'editable';
      isShoppingMode = false;
      itemErrorsFound = false;
      isIntegratedPR = false;
      integrationSolution = null;
      isIntegratedCompanyMapped = false;
      integrationDependencies = {};
      erpOrderTypesList = [];
      erpCostCentersList = [];
      expenseMode = '';
      requisitonCCs = [];
      selectedOtherCharges = [];
      prWarnings = {};
    },
    /**
     * @function validateLumpSum
     * this function will return a message in case there is an error in the lump sum,
     * then the controllers will remove that from their respectively
     *
     * @param newItem
     * new Item that is tested before adding
     * @param index
     * item index
     *
     * @returns {{}}
     */
    validateLumpSum: function (newItem, index) {
      var items = angular.copy(globalPRData.pr_items) || [];
      var prevSupplier = '';
      var prevPaymentTerm = '';
      var prevTax = '';
      var prevDeliveryAddress = '';
      var prevSupplierBranch = '';
      var prevCurrency = '';
      var errorMessage = {
        status: true,
        message: ''
      };

      // adding item temporally to the list to evaluate it
      if (!!newItem && index === -1) {
        if (!newItem.tax && !!newItem.tax_id)
          newItem.tax = {_id: newItem.tax_id};
        items.push(newItem);
      }
      else if (!!newItem && index > -1)
        items[index] = newItem;

      if (!items || (!!items && !items.length))
        return errorMessage;

      if (!!items && !!items.length) {
        var contactPersonId = [];
        var branchId = [];
        var branchContactPersonId = [];

        _.forEach(items, function (item) {
          // validate supplier
          // check if the supplier exist (non catalog)
          if (!item.supplier) {
            errorMessage.status = false;
            errorMessage.message = lang.validation.lumpSum.notHaving + 'supplier';
            return true;
          }
          else if (!!item && !!item.supplier && !prevSupplier)
            prevSupplier = item.supplier._id;

          // check if the items having the same supplier
          if (item.supplier._id !== prevSupplier) {
              errorMessage.status = false;
              errorMessage.message = lang.validation.lumpSum.different + 'supplier';
              return true;
          }

          // validate payment term
          // check if the payment term exist (non catalog)
          if (!item.payment_term) {
            errorMessage.status = false;
            errorMessage.message = lang.validation.lumpSum.notHaving + 'payment term';
            return true;
          }
          else if (!!item && !!item.payment_term && !prevPaymentTerm)
            prevPaymentTerm = item.payment_term._id;

          // check if the items having the same payment term
          if (item.payment_term._id !== prevPaymentTerm) {
            errorMessage.status = false;
            errorMessage.message = lang.validation.lumpSum.different + 'payment term';
            return true;
          }

          // validate tax
          // check if the tax exist (non catalog)
          if (!item.payment_term) {
            errorMessage.status = false;
            errorMessage.message = lang.validation.lumpSum.notHaving + 'tax';
            return true;
          }
          else if (!!item && !!item.tax && !prevTax)
            prevTax = item.tax._id;

          // check if the items having the same payment term
          if (!!item.tax && item.tax._id !== prevTax) {
            errorMessage.status = false;
            errorMessage.message = lang.validation.lumpSum.different + 'tax';
            return true;
          }

          // set initial delivery address
          if (!!item.delivery_address && !!item.delivery_address._id && !prevDeliveryAddress)
            prevDeliveryAddress = item.delivery_address;

          // check if the items having the same delivery address
          if ((!!item.delivery_address && item.delivery_address._id !== prevDeliveryAddress) ||
            (!item.delivery_address && !!prevDeliveryAddress)) {
            errorMessage.status = false;
            errorMessage.message = lang.validation.lumpSum.different + 'delivery address';
            return true;
          }

          // set initial currency
          if (!!item.currency && !!item.currency._id && !prevCurrency) {
              prevCurrency = item.currency._id;
          }

          //check if the items have the same currency
          if (!!item.currency && item.currency._id !== prevCurrency) {
              errorMessage.status = false;
          }

          // Generate contact person id array
          if (!!item.supplier && !!item.supplier.contact_person && !!item.supplier.contact_person._id) {
            contactPersonId.push(item.supplier.contact_person._id);
          }

          // Generate branch Id
          if (!!item.branch && !!item.branch._id) {
            branchId.push(item.branch._id);
            if (!!item.branch.contact_person && !!item.branch.contact_person._id) {
              branchContactPersonId.push(item.branch.contact_person._id);
            }
          }

          // set initial supplier branch
          if (!!item.branch && !!item.branch._id && !prevSupplierBranch) {
            prevSupplierBranch = item.branch._id;
          }

          // check if the items have the same branch
          if ((!!item.branch && item.branch._id !== prevSupplierBranch) ||
            (!item.branch && !!prevSupplierBranch)) {
            errorMessage.status = false;
            errorMessage.message = lang.validation.lumpSum.different + 'supplier branch';
            return true;
          }
        });

        // Lump Sump is enabled if same contact person only
        if (!globalFunc.checkAllSame(contactPersonId)) {
          errorMessage.status = false;
          errorMessage.message = lang.validation.lumpSum.different + 'supplier contact person';
          return errorMessage;
        }

        // Lump Sump is enable if same branch
        if (!!branchId.length && !globalFunc.checkAllSame(branchId)) {
          errorMessage.status = false;
          errorMessage.message = lang.validation.lumpSum.different + 'supplier branch';
          return errorMessage;
        }

        // Lump Sump is enable if same branch contact person
        if (!!branchId.length && !globalFunc.checkAllSame(branchContactPersonId)) {
          errorMessage.status = false;
          errorMessage.message = lang.validation.lumpSum.different + 'supplier contact person';
          return errorMessage;
        }

        return errorMessage;
      }
    },
    removeLumpSum: function (prId, callback) {
      var self = this;
      self.setLoading('lineItemTable', true);
      self.setLoading('updatePRField', true);
      purchaseRequisitionsV2Services.setLumpSum({
        id: prId
      }, {}, function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data)
          self.setPRData(resource.content.data);

        self.setLoading('lineItemTable', false);
        callback(true);
      }, function (error) {
        self.setLoading('lineItemTable', false);
        globalFunc.objectErrorMessage(error);
        callback(false);
      });
    },
    checkChargeType: function (type){
      var noneChargeTypes = ['needed_by_date', 'note_to_supplier', 'packing_info', 'delivery_address', 'project_code', 'budget', 'other_charges_group', 'justification'];
      var isNoneChargeType = false;
      _.forEach(noneChargeTypes, function(e){
        if (e === type){
          isNoneChargeType = true;
        }
      });
      return isNoneChargeType;
    },
    // To combine the other charges that selected
    combineSelectedOtherCharges: function (pr, self) {
     var list = selectedOtherCharges;
      _.forEach(pr.pr_items, function (item, itemIndex) {
        for (var i = 0; i < list.length; i++) {
          if (itemIndex === list[i].itemIndex) {
            var type = list[i].type;
            if (type === 'account_assignments'){
              if (!item[type].length){
                item[type] = [{
                  amount:0
                }];
              }
            }
            else if ((!item[type] || !item[type].amount) && !self.checkChargeType(type)) {
              item[type] = {
                amount:0,
                remark: !!item[type] && !!item[type].remark ? item[type].remark : undefined,
                currency: item.currency || undefined,
                exchange_rate: item.exchange_rate || 1
              };
            }
          }
        }
      });
    },
    /**
     * To set PR data and update to local variable
     * @param pr
     */
    setPRData: function (pr) {
      globalPRData = this.updatePRData(pr);
      var focFunc = this.assignFOCItem;
      // To populate account code selection if PR has items
      // To assign Foc items
      if (!!globalPRData.pr_items) {
        _.forEach(globalPRData.pr_items, function (item) {
          //account code
          if (!!item.account_code)
            item.accountCodeSelection = item.account_code.code + ' - ' + item.account_code.descr;

          //foc item
          if(!!item.foc_item)
            focFunc(item, true);
          else
            focFunc(item, false)
        });
      }

      var self = this;
      this.combineSelectedOtherCharges(globalPRData, self);
      $rootScope.$broadcast('PRDataUpdated');
    },

    /**
     * to return the globalPRData value
     * use this service to share data between directives in PR
     * @returns {{}}
     */
    getPRData: function () {
      return globalPRData;
    },

    /**
     * set loading object in PR
     * @param loadingObject
     * @param loadingValue
     */
    setLoading: function (loadingObject, loadingValue) {
      loading[loadingObject] = loadingValue;
    },

    /**
     * get loading data
     * @param loadingObject
     * @returns {{}}
     */
    getLoading: function (loadingObject) {
      return loading[loadingObject];
    },
    setContactPerson: function (contactPersons) {
      contactPerson = contactPersons;
    },
    getContactPerson: function () {
      return contactPerson;
    },
    setApprovalFlow: function (approvalFlowData) {
      approvalFlow = approvalFlowData;
    },
    getApprovalFlow: function () {
      return approvalFlow;
    },
    setTaxData: function (taxData) {
      taxes = taxData;
    },
    getTaxData: function () {
      return taxes;
    },
    setUOMData: function (UOMData) {
      UOMs = UOMData;
    },
    getUOMData: function () {
      return UOMs;
    },
    setCurrenciesData: function (currencyData) {
      currencyList = currencyData;
    },
    getCurrenciesData: function () {
      return currencyList;
    },
    setErrorsOnSubmit: function (errors) {
      submitErrors = errors;
    },
    getErrorsOnSubmit: function () {
      return submitErrors;
    },
    setCostCenterDeliveryAddresses: function (addresses) {
      costCenterDeliveryAddresses = addresses;
    },
    getCostCenterDeliveryAddresses: function () {
      return costCenterDeliveryAddresses;
    },
    setAccountCodes: function (accountCodesData) {
      accountCodes = accountCodesData;
    },
    getAccountCodes: function () {
      return accountCodes;
    },
    setEditableState: function (state) {
      PREditableState = state;
    },
    getEditingState: function () {
      return PREditableState;
    },
    setShoppingModeStatus: function (mode) {
      isShoppingMode = mode;
    },
    getShoppingModeStatus: function () {
      return isShoppingMode;
    },
    deleteLineItem: function (itemId) {
      return purchaseRequisitionsV2Services.deleteLineItem({
        requisition_item_id: itemId
      });
    },
    setItem: function (item, field, param) {
      return purchaseRequisitionsV2Services.setItem({
        requisition_item_id: item._id,
        action: field
      }, param);
    },
    deleteItemField: function (item, field, param) {
      return purchaseRequisitionsV2Services.deleteItemField({
        id: item._id,
        action: field
      }, param);
    },
    refreshPRData: function () {
      var deferred = $q.defer();
      purchaseRequisitionsV2Services.getSinglePR(
        {
          id: this.getPRData()._id
        }, function (resource) {
          deferred.resolve(resource);
        }, function (error) {
          deferred.resolve([]);
        });
      return deferred.promise;
    },
    /**
     * lis the the IDs into an array so that its easy to handle later on
     * @returns {Array}
     */
    getPRItemsIDs: function () {
      var tempArray = [];
      _.forEach(this.getPRData().pr_items, function (item) {
        if (!!item.item_details) {
          tempArray.push(item.item_details._id);
        }
      });
      return tempArray;
    },
    getPRSuppliers: function () {
      var items = angular.copy(globalPRData.pr_items) || [];
      var suppliers = [];
      var newFormat = {};
      _.forEach(items, function (item) {
        if (!!item.supplier) {
          newFormat = {
            id: item.supplier._id,
            label: item.supplier.descr,
            code: item.supplier.mb_supplier_code
          };

          if (!globalFunc.findInArray(suppliers, 'id', newFormat.id)) {
            suppliers.push(newFormat);
          }
        }
      });
      return suppliers;
    },
    /**
     * checked for the conditions:
     * 1- Waiting on user
     * 2- is PA
     * 3- is PA System Level
     * @param waitings
     * @param userId
     * @param roleAssignments
     * @param companyId
     * @returns {boolean}
     */
    checkCurrentApproverIsPA: function (waitings, userId, roleAssignments, companyId) {

      var isValid = false;

      if (waitings || _.isObject(waitings) || userId || roleAssignments || _.isObject(roleAssignments)) {

        var validWaitingId = _.filter(waitings, function(waitingId) {
          return waitingId === userId;
        });

        if (validWaitingId.length > 0) {
          isValid = true;
        }

      } else if (waitings || _.isArray(waitings) || userId || !roleAssignments || _.isArray(roleAssignments)) {

        if (waitings.indexOf(userId) !== -1) {
          isValid = true;
        }
      }

      if (isValid) {
        var validRole = false;

        if (globalFunc.findRoleInRoleAssignments(roleAssignments, 'PA', 3, companyId)) {
          validRole = true;
        }

        return validRole;
      }

      return isValid;
    },
    /**
     * Check if the user is a Procurement specialist
     *
     * @param roleAssignments
     * @returns {boolean}
     */
    isProcurementSpecialist: function (roleAssignments) {
      var procurementSpecialist = globalFunc.findRoleInRoleAssignments(roleAssignments,
        'PROCUREMENT_SPECIALIST');

      return !!procurementSpecialist;
    },
    /**
     * To modify pr data for backup and input entry
     * @param pr
     */
    updatePRData: function (pr) {
      var focFunc = this.assignFOCItem;
      _.forEach(pr.pr_items, function (item) {
        // Set discount_entry
        item.discount_entry = item.discount;
        if (!!item.discount_percentage)
          item.discount_entry = item.discount_percentage + '%';

        var otherChargesField = [
          'freight',
          'transportation',
          'insurance',
          'bahan_bakar_tax',
          'withholding_tax',
          'miscellaneous'
        ];

        _.forEach(otherChargesField, function(otherCharges) {
          if (!!item[otherCharges]) {
            // set freight entry
            item[otherCharges].entry = item[otherCharges].amount;
            if (!!item[otherCharges].amount_percentage)
              item[otherCharges].entry = item[otherCharges].amount_percentage + '%';

            // set freight discount
            item[otherCharges].discount_entry = item[otherCharges].discount_amount;
            if (!!item[otherCharges].discount_percentage)
              item[otherCharges].discount_entry = item[otherCharges].discount_percentage + '%';
          }
        });

        // set backup value
        item.backup_discount = angular.copy(item.discount_entry);
        item.backup_qty = angular.copy(Number(item.qty));
        item.backup_unit_price = angular.copy(Number(item.unit_price));
        item.backup_needed_by_date = angular.copy(item.needed_by_date);
        item.backup_note_to_supplier = angular.copy(item.note_to_supplier);
        item.backup_tax = angular.copy(item.tax);
        item.backup_freight = angular.copy(item.freight);
        item.backup_transportation = angular.copy(item.transportation);
        item.backup_insurance = angular.copy(item.insurance);
        item.backup_bahan_bakar_tax = angular.copy(item.bahan_bakar_tax);
        item.backup_withholding_tax = angular.copy(item.withholding_tax);
        item.backup_miscellaneous = angular.copy(item.miscellaneous);
        item.backup_justification = angular.copy(item.justification);

        //account code
        if (!!item.account_code)
          item.accountCodeSelection = item.account_code.code + ' - ' + item.account_code.descr;

        //foc item
        if(!!item.foc_item)
          focFunc(item, true);
        else
          focFunc(item, false);

        //set other charges group
        if(!!item.other_charges_group && !!item.other_charges_group.code)
          item.otherChargesGroupSelected = true;
      });

      // Update Lumpsum discount_entry
      pr.lump_sum_discount_entry = (pr.lump_sum_discount > -1 ? pr.lump_sum_discount : null);
      if (!!pr.lump_sum_discount_percentage)
        pr.lump_sum_discount_entry = pr.lump_sum_discount_percentage + '%';


      // set lump sum discount account code entry
      if (!!pr.lump_sum_discount_account_code)
        pr.lump_sum_discount_account_code_entry = pr.lump_sum_discount_account_code;

      pr.backup_lump_sum_discount = angular.copy(pr.lump_sum_discount);
      pr.backup_lump_sum_discount_account_code = angular.copy(pr.lump_sum_discount_account_code);

      return pr;
    },
    setDisplayWarningBlock: function (errors) {
      itemErrorsFound = errors;
    },
    getDisplayWarningBlock: function () {
      return itemErrorsFound;
    },
    /**
     * return an array of the catalog group ids within the PR company, by reading the User's companies
     * @returns {Array}
     */
    getCatalogGroup: function () {
      var company = globalFunc.findInArray($rootScope.currentUser.companies, '_id', this.getPRData().company._id);
      var ids = [];
      _.forEach(company.catalog_groups, function(group){
        ids.push(group._id);
      });
      return ids;
    },

    setCollaborators: function (newCollaborators) {
      globalPRData.collaborators = newCollaborators;
    },

    /**
     * set the integration status for a PR
     * @param status
     */
    setIntegrationStatus: function(status){
      isIntegratedPR = status;
    },

    /**
     * get the integration status for a PR
     * @returns {boolean}
     */
    getIntegrationStatus: function(){
      return isIntegratedPR;
    },

    setIntegrationSolution: function(solution){
      integrationSolution = solution;
    },

    getIntegrationSolution: function(){
      return integrationSolution;
    },

    /**
     * set the company mapping status for a PR
     * @param status
     */
    setCompanyMappingStatus: function(status){
      isIntegratedCompanyMapped = status;
    },

    /**
     * get the company mapping status for a PR
     * @returns {boolean}
     */
    getCompanyMappingStatus: function(){
      return isIntegratedCompanyMapped;
    },
    /**
     * set any integration related information
     * @param key
     * @param value
     */
    setIntegrationDependencies: function(key, value){
      integrationDependencies[key] = value;
    },
    /**
     * get the integration object
     * @returns {{}}
     */
    getIntegrationDependencies: function(){
      return integrationDependencies;
    },

    /**
     * Check if the PR has CMMS integration
     * @returns {boolean}
     */
    hasCmmsIntegration: function() {
      if (!!this.getPRData().company &&
        !!this.getPRData().company.config &&
        !!this.getPRData().company.config.integration_solution &&
        !!this.getPRData().is_generated_from_bifrost
      ) {
        return (this.getPRData().is_generated_from_bifrost === true &&
          this.getPRData().company.config.integration_solution === 'MB-CMMS-ESKER');
      }

      return false;
    },

    /**
     * Check if PR company account code setting is required
     * @returns {boolean}
     */
    isAccountCodeRequired: function() {
      var company = this.getPRData().company;
      return !!company.requisition_settings &&
        !!company.requisition_settings.account_code &&
        company.requisition_settings.account_code.is_mandatory;
    },
    /**
     * Check if company setting enable resubmit withdrawn PR
     *
     * @returns {boolean|*}
     */
    companyCanResubmitWithdrawnPR: function() {
      var company = this.getPRData().company;
      return !!company.requisition_settings &&
        !!company.requisition_settings.resubmit_withdrawn_pr &&
        company.requisition_settings.resubmit_withdrawn_pr.is_enabled;
    },
    setErpOrderTypesList: function (erpOrderTypes) {
      erpOrderTypesList = erpOrderTypes;
    },
    getErpOrderTypesList: function () {
      return erpOrderTypesList;
    },
    setErpCostCentersList: function (erpCostCenters) {
      erpCostCentersList = erpCostCenters;
    },
    getErpCostCentersList: function () {
      return erpCostCentersList;
    },
    getDocumentRule: function (key) {
      var pr = this.getPRData();

      if (!!pr.document_rule && !!pr.document_rule[key]) {
        return pr.document_rule[key];
      }

      return null;
    },
    getAllDocumentRules: function () {
      var pr = this.getPRData();

      return !!pr.document_rule ? pr.document_rule : null;

    },
    /**
     * this function set the expense type mode of PR Capex or Opax for template rendering and data preparation
     *
     * @returns {string}
     */
    setExpenseTypeMode: function (){
      //TODO: fill up this
      expenseMode = '';
    },
    /**
     * this function returns the expense type mode of PR Capex or Opax for template rendering and data preparation
     *
     * @returns {string|null}
     */
    getExpenseTypeMode: function (){
      var pr = this.getPRData();

      if (!!pr.expense_type && !!pr.expense_type.category) {
        return pr.expense_type.category;
      }

      return null;
    },
    /**
     * this function returns the expense type mode of PR Capex or Opax for template rendering and data preparation
     *
     * @returns {string|false}
     */
    getExpenseTypePcm: function (){
      var pr = this.getPRData();

      if (!!pr.expense_type && !!pr.expense_type.is_pcm) {
        return pr.expense_type.is_pcm;
      }

      return false;
    },
    /**
     * setter and getting for the PR's available cost centers
     * @param list
     */
    setPRsCCs: function(list){
      requisitonCCs = list;
    },
    getPRsCCs: function(){
      return requisitonCCs;
    },
    /**
     * generic function to hide the PR header for any state after pr.details
     */
    hidePRHeader: function () {
      //TODO: add the check function for isShoppingMode here for better implementation (Ahmed Saleh, 7/9/2020)
      return $state.includes('main.user.purchaseRequisitionsV2.details.quotation');
    },
    /**
     * function to assign FOC to item and restrict any additional option needed
     *
     * @param {*} item
     */
    assignFOCItem: function(item, value){
      item.focOption = value;
    },
    /**
     * function to calculate and find the near needed at date
     *
     * @returns {string|null|*}
     */
    findClosestNeededAtDate: function() {
      var pr = this.getPRData();
    var closestDate = pr.needed_by_date;

      _.forEach(pr.pr_items, function (item) {
        if (!!item.needed_by_date && closestDate > item.needed_by_date) {
          closestDate = item.needed_by_date;
        }
      });

      return closestDate;
    },
    storeOthers: function (other, itemIndex) {
      selectedOtherCharges.push({
        type: other,
        itemIndex: itemIndex
      })
    },
    removeOthers: function (other){
      if (!!selectedOtherCharges && selectedOtherCharges.length > 0){
        var selectedIndex = _.findIndex(selectedOtherCharges, function(e){
          return e.type === other;
        });
        selectedOtherCharges.splice(selectedIndex,1);
      }
    },
    /**
     * setting warning messages, this can be expanded using enums for the keys
     * @param key
     * @param value
     */
    setPRWarnings: function (key, value) {
      prWarnings[key] = value;
    },
    /**
     * iterate on the object using "for in" if we use the keys replace this loop with Object.keys()
     * @returns {Array}
     */
    getPRWarnings: function () {
      var warningArr = [];
      for(var i in prWarnings) {
        if(!!prWarnings[i]){
          if (prWarnings[i].constructor === Array) {
            warningArr.push.apply(warningArr, prWarnings[i]);
          } else {
            warningArr.push(prWarnings[i]);
          }
        }
      }
      return warningArr;
    },
    /**
     * check if the header cost center is default "multiple" or user input
     */
    isDefaultCC: function () {
      return (!this.getPRData().cost_center || !this.getPRData().cost_center._id);
    },

    /**
   * check if the Price Comparison is enabled in company settings
   * @returns {boolean}
   */
    isPriceComparisonEnabledinCompanySettings: function(){
     return this.getPRData().company.config.price_comparison === true ? true : false;
   }
  }
}

prV2Function.$inject = ['purchaseRequisitionsV2Services', '$q', 'prStarring', 'lang', 'globalFunc', '$rootScope',
                        '$state'];


angular
  .module('metabuyer')
  .factory('prV2Function', prV2Function);
