/**
 * @name embeddedPaginationService
 *
 * @requires $scope
 * @requires $rootScope
 *
 * @description
 *
 * Directive for embedded pagination used without supporting URL
 * This pagination is different from the Pagination service as it depends on loading the data within a different module
 *
 * $watcher
 * to check if new Data has been sent into the directive in order to refresh the count, as well as initializes it
 *
 * @author Ahmed Saleh <a.s.alsalali@gmail.com>
 * @copyright 2019 Metacloud Sdn. Bhd.
 */

'use strict';
angular
  .module('metabuyer')
  .directive('embeddedPagePaginationService', function () {
    return {
      restrict: 'E',
      templateUrl: 'components/embeddedPagePagination/embeddedPagePaginationTemplate.html',
      scope: {
        list: '=',
        service: '=',
        paramsSource: '=?',
        offset: '=?offset',
        meta: '=',
        emitResults: '=?',
        hidePaginationNumbers: '=?',
        cursorStructure: '=?',
        forceMinimize: '=?' //this to make it minimum without pages
      },
      controller: function ($scope) {
        $scope.disablePaginationButtons = false;
        $scope.pageCount = 0;
        $scope.goNextPage = goNextPage;
        $scope.goPreviousPage = goPreviousPage;
        $scope.paginationRange = 2; // range number before and after the current page
        $scope.goToPage = goToPage;

        function initialize() {
          if (!!$scope.meta) {
            $scope.currentPage = Number($scope.meta.current_page);
            $scope.lastPage = Number($scope.meta.last_page);
            $scope.offset = $scope.meta.per_page;
            if ($scope.currentPage + 1 < $scope.lastPage) {  // next button availability
              $scope.nextPage = $scope.currentPage + 1;
            } else if ($scope.currentPage + 1 === $scope.lastPage) {
              $scope.nextPage = $scope.currentPage;
            } else
              $scope.nextPage = undefined;

            $scope.previousPage = ($scope.currentPage - 1 >= 0) ? $scope.currentPage - 1 : undefined; // next button availability
            $scope.totalCount = $scope.meta.total;
            $scope.from = $scope.meta.from;
            $scope.to = $scope.meta.to;
            $scope.range = _.range;
            $scope.startPager = ($scope.currentPage - $scope.paginationRange > 0) ? $scope.currentPage - $scope.paginationRange : 1;
            $scope.lastPager = ($scope.currentPage + $scope.paginationRange <= $scope.lastPage) ? $scope.currentPage +
              $scope.paginationRange + 1 : $scope.lastPage + 1;
          } else {

            $scope.currentCountStart = 0;
            $scope.currentCountStart = 0;

          }
        }

        /**
         * function to support the different structure for the APIs
         * @param resource
         */
        function getList(resource) {
          if(!!$scope.cursorStructure)
            return resource.content.data;

          return resource.data;
        }

        /**
         * function to support the different structure for the APIs
         * @param resource
         */
        function getMeta(resource) {
          if(!!$scope.cursorStructure)
            return resource.content.meta.cursor;

          return resource.meta;
        }

        /**
         * Function to reload the data for the listing
         * this function does not support cursorStructure, build your own
         */
        function reloadData() {
          $scope.service.get($scope.paramsSource,
            function (resource) {
              if (!!resource) {
                $scope.list = resource.data;
                if (!!resource.meta && resource.meta.cursor) {
                  $scope.meta.cursor = resource.content.meta.cursor;
                  $scope.currentPageCode = resource.content.meta.cursor.current || resource.content.meta.cursor.current_page;

                  // to support number and encoded page number
                  if (typeof $scope.currentPageCode === 'number') {
                    $scope.currentCountStart = (
                      (Number($scope.currentPageCode) * $scope.offset) - $scope.offset
                    )  + 1;
                  } else {
                    $scope.currentCountStart = Number(atob($scope.currentPageCode)) + 1;
                  }
                  $scope.currentCountEnd = $scope.currentCountStart + $scope.list.length - 1;
                  $scope.nextPage = $scope.currentPage + 1 <= $scope.lastPage; // next button availability
                  $scope.previousPageCode = resource.meta.prev;
                }
              }
            });
        }

        function goNextPage() {
          var page = $scope.currentPage + 1;
          if(page <= $scope.lastPage)
            goToPage(page);
        }

        function goPreviousPage() {
          var page = $scope.currentPage - 1;
          if(page>0)
            goToPage(page);
        }

        function goToPage(page) {
          if ($scope.currentPage === page)
            return false;

          $scope.disablePaginationButtons = true;
          $scope.paramsSource['page'] = page;
          $scope.service.get($scope.paramsSource,
            function (resource) {
              if (!!resource) {
                if(!!$scope.emitResults){
                  $scope.$emit('embeddedPaginationResultsEmit', resource);
                } else {
                  $scope.list = getList(resource);
                }
                $scope.disablePaginationButtons = false;
                if (!!resource.meta || !!resource.content.meta) {
                  $scope.meta = getMeta(resource);
                  initialize();
                }
              }
            },
            function () {
              $scope.disablePaginationButtons = false;
            });

        }

        /**
         *  Receive broadcast for initializing the pagination
         */
        $scope.$on('initializePagination', function () {
          initialize();
        });

        /**
         * Receive broadcast for reloading data
         */
        $scope.$on('reloadData', function () {
          reloadData();
        });

        /**
         *  Receive broadcast for get the new list and reset the counts
         */
        $scope.$on('resetPagePagination', function (event, args) {
          if (!!args.data) {
            $scope.list = args.data;
          }
          if (!!args.meta) {
            $scope.meta = args.meta.cursor;
          }
          $scope.offset = args.offset;
          $scope.pageCount = 0;
          initialize();
        });

        /**
         *  Control pagniation button disabled or not
         */
        $scope.$on('disablePaginationButtons', function (event, boolean) {
          $scope.disablePaginationButtons = boolean;
        });

        $scope.$watch('meta', function (old, n) {
          initialize();
        });

        $scope.$watch('meta.current', function () {
          initialize();
        });
      }
    };
  });
