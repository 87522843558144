'use strict';

/**
 * @name tenderFunction
 *
 * @requires $state
 *
 * @description Service for Tender functions
 * This service is for functions that are re-used in Tender modules, to minimize redundancy
 *
 */

function tenderFunction(tenderService, lang, globalFunc, $rootScope, $state, $q, toastr) {

  var tenderObject = {};
  var initialData = {};
  var submitErrors = [];
  var tenderEditableStatus = true;

  return {
    initialize: function(tender) {
      this.clearData();
      this.setData(tender);
    },
    clearData: function() {
      tenderObject = {};
    },
    setData: function(tender) {
      tenderObject = tender;
    },
    getData: function() {
      return tenderObject;
    },
    setErrorsOnSubmit: function(errors) {
      submitErrors = errors;
    },
    getErrorsOnSubmit: function() {
      return submitErrors;
    },
    setInitialData: function(data) {
      initialData = data;
    },
    getInitialData: function() {
      return initialData;
    },
    getTenderStatus: function() {
      return tenderObject.detail.status;
    },
    isVo: function() {
      return tenderObject.is_vo;
    },
    getStatuses: function() {
      return initialData.statuses;
    },
    setEditableState: function() {
      if (this.getTenderStatus() === 'draft' && this.hasRoleRequester()) {
        tenderEditableStatus = true;
      } else {
        tenderEditableStatus = false;
      }
    },
    getTenderId: function() {
      return tenderObject._id;
    },
    isLastApprover: function() {
      return tenderObject.is_last_approver;
    },
    getApprovalId: function() {
      return tenderObject.approval_id;
    },
    getApproverLists: function() {
      return tenderObject.approvers;
    },
    getComponentLists: function() {
      return tenderObject.components;
    },
    getTotalAmount: function() {
      return tenderObject.total_amount;
    },
    getSupplierLists: function() {
      return tenderObject.suppliers;
    },
    getMemoAttachments: function() {
      return tenderObject.memo_attachments;
    },
    updateTotalAmount: function(amount) {
      return tenderObject.total_amount = amount;
    },
    updateCumulativeVOAmount: function(amount) {
      return tenderObject.cumulative_vo_amount = amount;
    },
    updateRevisedTotalAmount: function(amount) {
      return tenderObject.revised_total_amount = amount;
    },
    updateCumulativeContingencySumVOAmount: function(amount) {
      return tenderObject.cumulative_vo_contingency_sum = amount;
    },
    isEditable: function() {
      return tenderEditableStatus;
    },
    getApprovalDate: function() {
      return tenderObject.approval_date;
    },
    updateApprovalDate: function(date) {
      return tenderObject.approval_date = date;
    },
    isCategoryAmount: function() {
      return tenderObject.detail.category.key === 'AMOUNT' ? true : false;
    },
    isCategoryUnitPrice: function() {
      return tenderObject.detail.category.key === 'UNIT_PRICE' ? true : false;
    },
    isCatalogRequired: function() {
      return tenderObject.detail.catalog_required ? true : false;
    },
    setCategory: function(category) {
      return tenderObject.detail.category = category;
    },
    setAllowDecimal: function(tender) {
      tenderObject.detail.contingency_sum = tender.detail.contingency_sum;
      tenderObject.detail.blocked_amount = tender.detail.blocked_amount;
      tenderObject.detail.non_purchase_amount = tender.detail.non_purchase_amount;
      tenderObject.total_amount = tender.total_amount;
    },
    hasRoleRequester: function() {
      return globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Tender Requester');
    },
    hasRoleApprover: function() {
      return globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'Tender Approver');
    },
    hasAdminRole: function() {
      return (!!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'TENANT_SUPER_ADMIN')) ||
        (!!globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'ADMIN'));
    },
    isCreator: function() {
      return (this.getData().created_by !== undefined && this.getData().created_by._id === $rootScope.currentUser._id) || this.hasAdminRole();
    },
    getCategory: function() {
      return tenderObject.detail.category.key;
    },
    getCurrecy: function() {
      return tenderObject.detail.currency;
    },
    getTenderGroupsByComponents: function() {
      return tenderObject.groups;
    },
    getCatalogs: function() {
      return tenderObject.catalogs;
    },
    setAllowExceedAmount: function(status) {
      return tenderObject.detail.allow_exceed_tender_amount = status;
    },
    setAmountBeforeTax: function(status) {
      return tenderObject.detail.amount_before_tax = status;
    },
    resetUnitPriceAmountField: function(detail) {
      tenderObject.detail.contingency_sum = detail.contingency_sum;
      tenderObject.detail.blocked_amount = detail.blocked_amount;
      tenderObject.detail.non_purchase_amount = detail.non_purchase_amount;
    },
    refreshDataChangeType: function(newTenderObject) {
      tenderObject.detail = newTenderObject.detail;
      tenderObject.components = newTenderObject.components;
      tenderObject.suppliers = newTenderObject.suppliers;
      tenderObject.approvers = newTenderObject.approvers;

      if (!!newTenderObject.detail.validity_start_date) {
        tenderObject.detail.validity_start_date = new Date(newTenderObject.detail.validity_start_date);
      }

      if (!!newTenderObject.detail.validity_end_date) {
        tenderObject.detail.validity_end_date = new Date(newTenderObject.detail.validity_end_date);
      }

      if (!!newTenderObject.detail.submission_date) {
        tenderObject.detail.submission_date = new Date(newTenderObject.detail.submission_date);
      }

      if (newTenderObject.approvers.length > 0) {
        _.forEach(newTenderObject.approvers, function(value, key) {
          if (!!value.approval_date) {
            tenderObject.approvers[key].approval_date = new Date(value.approval_date);
          }
        });
      }
    },
    getMemoUsers: function() {
      return tenderObject.memo_users;
    },
    updateTenderGroupsByComponents: function(groups) {
      return tenderObject.groups = groups;
    },
    updateCurrency: function(currency) {
      return tenderObject.detail.currency = currency;
    },
    saveApi: function(params, msg) {
      var msg = (msg === undefined) ? 'Successfully saved' : msg;
      var deferred = $q.defer();

      tenderService.save({ id: tenderObject._id }, params, function(response) {
        deferred.resolve(response);
        if (msg !== '')
          toastr.success(msg);
        }, function(error) {
        if (!!error.data && !!error.data.content && !!error.data.content.error) {
          if (typeof error.data.content.error === 'string' &&
            !globalFunc.hasJsonStructure(error.data.content.error)) {
            toastr.error(error.data.content.error);
          } else {
            globalFunc.laravelValidatorErrDisplay(JSON.parse(error.data.content.error));
          }
        }

        deferred.resolve(error);
      });

      return deferred.promise;
    },
    convertDotToObj: function(keys, value) {
      var tempObject = {};
      var container = tempObject;

      keys.split('.').map(function(k, i, values) {
        container = (container[k] = (i == values.length - 1 ? value : {}))
      });

      return JSON.parse(JSON.stringify(tempObject, null, ' '));
    },
    actionCanSubmit: function() {
      var checkTenderStatus = this.isEditable();

      return (this.hasRoleRequester() && checkTenderStatus);
    },
    actionCanDelete: function() {
      var checkTenderStatus = (this.getData().status === 'draft' && !this.isVo());
      return (this.hasRoleRequester() && checkTenderStatus);
    },
    actionCanApprove: function() {

      var checkTenderStatus = (this.getData().status === 'pending');
      var isApprover = !!this.getData().waiting_on ? !!(globalFunc.findInArray(this.getData().waiting_on, '_id', $rootScope.currentUser._id)) : false;

      return (checkTenderStatus && isApprover);
    },
    actionCanReject: function() {

      var checkTenderStatus = (this.getData().status === 'pending');
      var isApprover = !!this.getData().waiting_on ? !!(globalFunc.findInArray(this.getData().waiting_on, '_id', $rootScope.currentUser._id)) : false;

      return (checkTenderStatus && isApprover);
    },
    actionCanResubmit: function() {

      var checkTenderStatus = (this.getData().status === 'withdrawn' || this.getData().status === 'rejected');

      return (this.hasRoleRequester() && checkTenderStatus);
    },
    actionCanWithdraw: function() {

      var checkTenderStatus = (this.getData().status === 'pending');
      return (
        this.hasRoleRequester() && checkTenderStatus);
    },
    actionCanDeactivate: function() {

      var checkTenderStatus = (this.getData().status === 'approved');
      return (
        this.hasRoleRequester() && checkTenderStatus);
    },
    actionCanReactivate: function() {

      var checkTenderStatus = (this.getData().status === 'deactivated');
      return (
        this.hasRoleRequester() && checkTenderStatus);
    },
    actionCanReactivateExpired: function() {

      var checkTenderStatus = (this.getData().status === 'expired');
      return (
        this.hasRoleRequester() && checkTenderStatus);
    },
    actionCanCreateVO: function() {

      var checkTenderStatus = (this.getData().status === 'approved');
      return (
        this.hasRoleRequester() && checkTenderStatus);
    },
    actionCanCancelVO: function() {

      var checkTenderStatus = ((this.getData().status === 'draft' || this.getData().status === 'rejected' || this.getData().status === 'withdrawn') && this.getData().is_vo);
      return (
        this.hasRoleRequester() && checkTenderStatus);
    },
  };
}

tenderFunction.$inject = ['tenderService', 'lang', 'globalFunc', '$rootScope', '$state', '$q', 'toastr'];


angular
  .module('metabuyer')
  .factory('tenderFunction', tenderFunction);
