'use strict'

angular
  .module('metabuyer')
  .controller('ExploreAppsCtrl', ExploreAppsCtrl);

function ExploreAppsCtrl($scope, $state){
  $scope.$emit('pageTitleChanged', 'Explore Apps');
  $scope.$emit('actionsChanged', []);

  $scope.apps = [
    { title: 'Analytic' },
    { title: 'Budget' },
    { title: 'Admin Console' },
    { title: 'Purchase Requisition' },
    { title: 'Purchase Order' },
    { title: 'Inventory' },
    { title: 'Receiving' },
    { title: 'Invoice' },
    { title: '' }
  ];

  $scope.sortableOptions = {
    'ui-floating': true,
    update: function(event, ui){
      console.log('update', ui);
    },
    stop: function(event, ui){
      console.log('stop', ui);
    }
  }
}
ExploreAppsCtrl.$inject = ['$scope', '$state'];
