'use strict';

angular.module('metabuyer.services.modeOfPurchase', ['ngResource'])
  .factory('modeOfPurchaseMasterDataList',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.modeOfPurchase.single,
        {
          uuid: '@id'
        },
        {
          'get': {method: 'GET'},
          'post': {method: 'POST'},
          'put': {method: 'PUT'}
        }
      );
    });
